import React, { useEffect, useState } from 'react'
import TopBar from './topBar'
import { Button, Input, Modal, Rate } from 'antd';
import "./styles.css";
import Footer from './footer';
import NoData from "../../Assets/aulasMarket/ill-no-data.svg"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AddToCart, RemoveSaveLater, SavedLaterList, setCoursePage, setLoader } from 'actions/aulasMarket';
import { connect } from 'react-redux';
import { Api } from 'services';
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';


function SavedLater({ cart, setCoursePage, coursePageData, setLoader, loginDetails }) {
    const token = loginDetails?.accessToken;
    const history = useHistory();
    const [removeModal, setRemoveModal] = useState();
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [shareUrl, setShareUrl] = useState(null);
    const [render, setRender] = useState(0);


    const fetchSavedList = () => {
        SavedLaterList(token, (res) => {
            setCoursePage(res)
        }, setLoader)
    }

    useEffect(() => {
        fetchSavedList();
    }, [removeModal, render])


    const handleRemoveOk = () => {
        console.log("uid", removeModal)
        setLoader(true);
        RemoveSaveLater(token, removeModal, (res) => {
            console.log("removed", res);
            if (res) {
                setRemoveModal(false);
            }
        }, setLoader)
    }

    const handleRemove = () => {
        setRemoveModal(false);
    }

    const CartList = ({ cartContent, key }) => {
        console.log("keysave", cartContent)

        const handleAddToCart = (itemType, id) => {
            console.log("hanlll", id, itemType)
            AddToCart(token, id, itemType, (res) => {
                console.log("addedd", res);
                setRender(1);
                // history.push("/shopping-cart");
            }, setLoader);
        }

        const name = cartContent ? (cartContent?.course?.courseName || cartContent?.videoResource?.name) : null;
        const courseDescription = cartContent ? (cartContent?.course?.brief || cartContent?.videoResource?.brief) : null;
        const courseImg = cartContent ? (cartContent?.course?.image || cartContent?.videoResource?.image) : null;
        const courseAmt = cartContent ? (cartContent?.course?.amount || cartContent?.videoResource?.amount) : null;
        const courseRating = cartContent ? (cartContent?.course?.rating || cartContent?.videoResource?.rating) : null;
        const courseRatingValue = cartContent ? (cartContent?.course?.ratingValue || cartContent?.videoResource?.ratingValue) : null;
        const courseRatingCount = cartContent ? (cartContent?.course?.ratingCount || cartContent?.videoResource?.ratingCount) : null;
        return (
            <div className='savedContainer' style={{}}>
                <div style={{}}>
                    <img className="cart-img" style={{}} src={`${Api.dlS3Url}${courseImg}`} />
                    <div className='cost-save font-bold' style={{ border: "1px solid black" }}>{cartContent?.costRs}</div>
                </div>
                <div className='m-l-30' style={{}} >
                    <div className='text-sm font-bold'>{name}
                        <span style={{ color: "#FFA931", fontSize: 16, marginLeft: 10 }}>NEW</span>
                    </div>
                    <div className='saved-description m-t-10' style={{}} >{courseDescription}</div>
                    <div className='saved-seller' style={{}}>
                        <div className='sellerButton2 m-t-5'>Bestseller</div>
                        <div className='r-c-c text-xmd m-l-10'><div style={{ color: "#FFA931" }}>{cartContent?.rating}</div><Rate disabled defaultValue={cartContent?.ratingValue} style={{ color: "#FFA931" }} /> <div>{cartContent?.ratingCount}</div></div>
                    </div>
                    <div className='r-c-fs m-t-10 text-xmd bold-600' style={{}}>
                        <div style={{ color: "#594099", cursor: "pointer" }} onClick={() => {
                            setShareModalVisible(true);
                            setShareUrl(`${Api?._base_url}/course-page?id=${cartContent?.id}&type=${cartContent?.itemType}`)
                        }} ><img style={{ width: 18 }} src={require("../../Assets/aulasMarket/shareIcon.svg").default} />&nbsp;&nbsp;SHARE</div>
                        <div className='m-l-20' style={{ cursor: "pointer", color: "#FF414D" }} onClick={() => setRemoveModal(cartContent?.id)}>REMOVE</div>
                    </div>
                </div>
                <div className='saved-btns' style={{}} >
                    {cartContent?.Live ? <div className='btn-reg-parent' style={{}}>
                        <Button className='btn-reg' style={{}}>REGISTER NOW</Button>
                    </div> :
                        <div className='btn-parent' style={{}} >
                            <Button className='btn-add' onClick={() => handleAddToCart(cartContent?.itemType, cartContent?.courseId)} style={{}}>ADD TO CART</Button>
                            <Button className='btn-buy' onClick={() => history.push("/checkout")} style={{}}>BUY NOW</Button>
                        </div>
                    }
                </div>
            </div >
        )
    }

    const NoItems = () => {
        return (
            <div>
                <div className='r-c-c-c'>
                    <div><img src={NoData} /></div>
                    <div className='font-bold text-sm' style={{ color: "#191919" }}>No course saved!</div>
                    <div className='text-sm' style={{ color: "#636363" }}>You have not saved any courses yet!</div>
                    <div className='r-c-c m-t-20'>
                        <Button className='font-bold cursor-pointer' onClick={() => { history.push("") }} style={{ color: "#594099", borderRadius: "100px" }}>EXPLORE COURSES</Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            {cart ? null :
                <div><TopBar /></div>
            }
            <div style={{ backgroundColor: "#FAFAFA" }}>
                <div className='main-container-parent'>
                    <div className={cart ? null : "main-container"} style={{ backgroundColor: "#FAFAFA", width: "100%" }}>
                        <div className='' style={{}} >
                            <div className='text-md font-bold m-b-10'>Saved for Later</div>
                            {Array.isArray(coursePageData) && coursePageData.length > 0 ? (
                                coursePageData.map((item) => (
                                    <CartList cartContent={item} key={item.id} />
                                ))
                            ) : (
                                <NoItems />
                            )}

                        </div>
                    </div>
                </div>
                {cart ? null :
                    <div className='m-t-100' style={{}}>
                        <Footer />
                    </div>
                }
            </div>

            {/* <Modal open={isShareModalOpen} footer={null} onOk={handleOk} className="modal-round-corner" onCancel={handleCancel} bodyStyle={{ borderRadius: "8px", height: "40vh" }} >
                <div className='r-c-c text-md bold-600'>Share this course</div>
                <div className='r-c-c text-xmd' style={{ color: "#636363" }}>Show your friends what they can learn on Vectors Academy</div>
                <div className='r-c-sa p-30' style={{ color: "#636363" }}>
                    <div className='r-c-c-c'>
                        <img src={require("../../Assets/aulasMarket/instagram.svg").default} />
                        Instagram
                    </div>
                    <div className='r-c-c-c'>
                        <img src={require("../../Assets/aulasMarket/whatsappColored.svg").default} />
                        WhatsApp
                    </div>
                    <div className='r-c-c-c'>
                        <img src={require("../../Assets/aulasMarket/facebook.svg").default} />
                        Facebook
                    </div>
                    <div className='r-c-c-c'>
                        <img src={require("../../Assets/aulasMarket/twitter Colored.svg").default} />
                        Twitter
                    </div>
                    <div className='r-c-c-c'>
                        <img src={require("../../Assets/aulasMarket/LinkedInColor.svg").default} />
                        LinkedIn
                    </div>
                </div>
                <div className='r-c-c m-t-1'>
                    <Input style={{ border: " 1px solid #E6E6E6" }}></Input><span className='text-sm bold-600' style={{ color: "#594099" }}> &nbsp;&nbsp;COPY</span>
                </div>
            </Modal> */}
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl} />}
            <Modal open={removeModal} footer={null} onCancel={handleRemove} onOk={handleRemoveOk} bodyStyle={{ borderRadius: "8px", height: "40vh" }}>
                <div className='r-c-c text-md bold-600'>Confirm</div>
                <div className='r-c-c text-xmd' style={{ color: "#636363" }}>Are you sure you want to remove this course?</div>
                <div className='r-c-sa p-30 m-t-60' style={{ color: "#636363" }}>
                    <div className='text-sm bold-600' style={{ color: "#FF414D", cursor: "pointer" }} onClick={handleRemoveOk}>REMOVE</div>
                    <Button className='r-c-c text-sm bold-600' onClick={handleRemove} style={{ backgroundColor: "#594099", width: "200px", borderRadius: "100px", color: "#FFFFFF" }}>CANCEL</Button>
                </div>
            </Modal>
        </div>
    )
}


const mapStateToProps = (state) => {
    const { coursePageData, loader, loginDetails } = state.aulasMarket
    // console.log("pppp", popularRenderPage)
    return {
        coursePageData, loader, loginDetails
    }
};


const mapDispatchToProps = (dispatch) => ({
    setCoursePage: (val) => dispatch(setCoursePage(val)),
    setLoader: (val) => dispatch(setLoader(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedLater);

