import React from 'react'
import './styles.css'

function ResultBySubject(props) {
    return (
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",height:"75px"}}>
         <div style={{display:"flex",flexDirection:"column" }}>
             <h3 style={{marginLeft:"8%",fontWeight:"700"}}>{props.subject}</h3>

                <div style={{display:"flex",flexDirection:"row",width:"200px",alignItems:"center",justifyContent:"space-around"}}>

                        <div className="righticons"><img style={{ width:"20px",height:"30px"}} src={require('Assets/images/ic-attempted (3).svg').default} alt="" /><p  className="statusClass">{props.attempted}</p></div>
                        <div  className="righticons"><img style={{ width:"20px",height:"30px"}} src={require('Assets/images/CloseCircle.svg').default} alt="" /><p className="statusClass">{props.incorrect}</p></div>
                        <div  className="righticons"><img style={{ width:"20px",height:"30px"}} src={require('Assets/images/ic-unattempted.svg').default} alt="" /><p className="statusClass">{props.unattempted}</p></div>

                </div>
         </div>
            <div style={{width:"365px",display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center",fontFamily:"roboto"}}>
            <h2 style={{fontWeight:"700"}}>{props.marksObtained}/{props.totalMarks}</h2>
            </div>
        </div>
    )
}

export default ResultBySubject
