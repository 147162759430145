import React, { useState } from 'react';
import { Button, Modal, Radio } from 'antd';

const DeleteInstallmentModal = ({ chooseModal, setChooseModal, deleteInstallment, getSpecificCourseData, storeInstallmentData, toggleFeeCard, isFilterClicked }) => {
    console.log("storeInstallmentData", storeInstallmentData);
    const handleOK = () => {
        deleteInstallment(
            storeInstallmentData?.id,
            () => {
                setChooseModal(false),
                    getSpecificCourseData(() => { toggleFeeCard(isFilterClicked) })
            },
            () => { }
        )
    };

    const handleCancel = () => {
        setChooseModal(false);
    };


    return (
        <>

            <Modal
                className="modal-round-corner"
                open={chooseModal}
                footer={null}
                //centered={true}
                // width={"50%"}
                closable={false}
                bodyStyle={{ borderRadius: "10px" }}
                onCancel={() => handleCancel()}
            >
                <div style={{ textAlign: "center", fontSize: "17px", color: "#191919", fontWeight: "bold", marginTop: '10px' }}>You are about to permanently delete fee payment term</div>
                <div style={{ fontSize: "13px", color: "#636363", textAlign: "center", marginTop: "10px" }}>Are you sure you want to delete <span style={{ fontSize: "13px", color: "black", fontWeight: 500 }}>{storeInstallmentData?.installments?.length} {storeInstallmentData?.installments?.length == 1 ? 'installment' : 'installments'} plan?</span></div>
                <div className='fee-promo-card1-details border-radius p-20'>
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-5px" }}>
                        <div style={{ color: "#636363", fontSize: "14px" }}>No. of installments : <span style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}>{storeInstallmentData?.installments?.length}</span> </div>
                        <div style={{ color: "#636363", fontSize: "14px" }}>Total payable : <span style={{ fontSize: "14px", color: "black", fontWeight: "bold" }}>{storeInstallmentData?.installmentTotal}</span></div></div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "30px",
                    }}
                >
                    <div>
                        <Button
                            type='link'

                        >
                            <div
                                style={{
                                    fontSize: "16px",
                                    color: "#594099",
                                    fontWeight: "bold",
                                }}
                                onClick={handleCancel}

                            >
                                CANCEL

                            </div>
                        </Button>
                    </div>
                    <div>
                        <Button
                            type='link'
                            onClick={() => { handleOK() }}


                        >
                            <div
                                style={{
                                    fontSize: "16px",
                                    color: "#FF414D",
                                    fontWeight: "bold",
                                }}
                                onClick={handleCancel}

                            >
                                CONFIRM

                            </div>
                        </Button>
                    </div>
                </div>
            </Modal>

        </>
    );
};
export default DeleteInstallmentModal;