import React from 'react';
import "./styles.css"

const CustomDots = ({ currentPage, totalSlides, onDotClick }) => {
    return (
        <div className="custom-dots">
            {Array.from({ length: totalSlides }).map((_, index) => (
                <div
                    key={index}
                    className="graph-carousel-indicator"
                    style={{
                        backgroundColor: currentPage === index ? "#594099" : "#AEAEAE",
                        cursor: 'pointer',
                    }}
                    onClick={() => onDotClick(index)}
                ></div>
            ))}
        </div>
    );
};

export default CustomDots;
