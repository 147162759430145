import React from "react";
import {  Divider } from 'antd';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { Api, Color } from "services";
import ReactHtmlParser from "react-html-parser";
import { submitModalFun, reviewModalFun, leaveModalFun, studentTestCollectQuesIndex,
  studentTestcollectMcq, studentTestcollectBookmark, postStudentTest, studentTestUpdateAns,
  studentTestcollectImage, deleteQuesImgModalFun, confromDeleteQuesImgModalFun,
  updateStudentTestAnswerIndex, imageZoomModalFun} from "actions/mobileTest";
import { connect } from "react-redux";
import {  TestTopBar } from "components";
import ImageZoomModal from './imageZoomModal';
import TeX from '@matejmazur/react-katex';
import "./styles.css";
import "design/layout.css";
import "design/assignment.css";


class MobileTestReviewAnswers extends React.Component {
  constructor() {
    super();
    this.state = {
      QnOimg:''
    }
  }
  componentDidMount() {}

  hitActionButton = (quesIndex, studentTestReviewAnsArr) => {
    if(quesIndex < 0 || (quesIndex >= this.props.studentTestReviewAnsArr.length)) return;
    // console.log('studentTestReviewAnsArr[quesIndex].index)', studentTestReviewAnsArr[quesIndex].index));
    this.props.updateStudentTestAnswerIndex(quesIndex, studentTestReviewAnsArr[quesIndex].index);
  }

  htmlParser = (text, index) => {
    return(
      ReactHtmlParser(text, { transform: (node) => {
        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
          return <TeX math={`${node.attribs['data-value']}`} />
        }
        if (node.type === 'tag' && node.name === 'img') {
          return <img
                    onClick={() => {
                        this.props.imageZoomModalFun(true, false);
                        this.setState({QnOimg:node.attribs.src});
                      }}
                    alt='questionImage'
                    style={{maxHeight: '40vh', maxWidth: '40vw'}}
                    src={node.attribs.src} />
        }
      }})
    )
  }


  render() {
    const {history, studentSubjectHeader, user,
       studentTestReviewAnsArr, studentTestReviewAnsindex, studentTestResult, studentTestReviewQNo} = this.props;
    const ansObj = studentTestReviewAnsArr[studentTestReviewAnsindex];
    return (
      <div className="greyBackTest" style={{ height: "100%" }}>
        <TestTopBar
          backPress={() => history.goBack()}
          title={studentSubjectHeader}
          listMode={false}
          precentshow={false}
        />
        <div className="m-l-10 m-r-10 m-t-10 r-c-sb">
          <div>{(ansObj.type === 'Subjective') ? 'Numeric' : (ansObj.type === 'Image') ? 'Subjective' : ansObj.type}</div>
        </div>

        <div className='test-borders m-10 p-10 test-ques-screen-wrap'>
          <div>
            <div className='r-c-sb'>
              <div className='r-ac'>
                <div className='lowgrey text-xmd'>Marks obtained: </div>
                <div className='seaGreen m-l-5'>
                  {ansObj.correct === null
                  ?
                   <div className='r-ac'>
                    <div className='text-xmd lowgrey'>0</div>
                    <div className='text-xmd lowgrey'>/{ansObj.positiveMarks}</div>
                   </div>
                  :
                    ansObj.correct
                    ?
                    <div className='r-ac'>
                      <div className='text-xmd seaGreen'>+{ansObj.marksObtained}</div>
                      <div className='text-xmd lowgrey'>/{ansObj.positiveMarks}</div>
                    </div>
                    :
                      (ansObj.type === 'Image')
                      ?
                        <span>
                          {(ansObj.marksObtained == 0)
                          ?
                              <div className='r-ac'>
                                <div className='text-xmd lowgrey'>0</div>
                                <div className='text-xmd lowgrey'>/{ansObj.positiveMarks}</div>
                              </div>
                          : (ansObj.marksObtained > 0)
                            ?
                              <div className='r-ac'>
                                <div className='text-xmd' style={{color:Color.seaGreen}}>+{ansObj.marksObtained}</div>
                                <div className='text-xmd lowgrey'>/ {ansObj.positiveMarks}</div>
                              </div>
                            :
                              <div className='r-ac'>
                                <div className='text-xmd' style={{color:Color.red}}>{ansObj.marksObtained}</div>
                                <div className='text-xmd lowgrey'>/ {ansObj.positiveMarks}</div>
                              </div>
                          }
                        </span>
                      :
                        <span>
                          {(ansObj.negativeMarks === null || ansObj.negativeMarks === 0)
                          ?
                            <div className='r-ac'>
                              <div className='lowgrey text-xmd'>0</div>
                              <div className='text-xmd lowgrey'>/{ansObj.positiveMarks}</div>
                            </div>
                          :
                            <div className='r-ac'>
                              <div className='text-xmd' style={{color:Color.red}}>-{ansObj.negativeMarks}</div>
                              <div className='text-xmd lowgrey'>/{ansObj.positiveMarks}</div>
                            </div>
                          }
                        </span>
                  }
                </div>
              </div>
              <div className='r-ac'>
                <div className='orange-yellow text-xmd'>{studentTestReviewQNo}</div>
                <div className='lowgrey text-xmd'>/{studentTestResult.totalQuestions}</div>
              </div>
              <div className='r-ac'>
                {ansObj.submitStatus === 'Bookmarked' || ansObj.submitStatus === 'AttemptedAndBookmarked'
                ?
                  <FaBookmark className='orange-yellow text-sm'/>
                :
                  <FaRegBookmark className='orange-yellow text-sm'/>
                }
                <div className='lowgrey m-l-2 text-xmd'>Bookmark</div>
              </div>
            </div>
            <Divider style={{margin:'15px 0px'}}/>
            <div className='text-md bold m-b-10 color-black'>{this.htmlParser(ansObj.question)}</div>
          </div>
          {ansObj.type === 'MCQ'
          ?
            <div className='m-t-10'>
              {ansObj.answer === null ? <div className="lowgrey m-b-20">You did not attempt this question.</div> : null}
              {ansObj.options.map((data, index) => (
                <div
                  className='r-ac p-t-10 p-b-10 relative'
                  style={{borderTop: '1px solid #EDEDED',
                          background: ansObj.answer === null ?
                          (ansObj.correctAnswer == index ? '#EAFCF5' : '#fff') :
                          ((ansObj.answer === ansObj.correctAnswer && index == ansObj.answer) ? '#EAFCF5' :
                          ansObj.answer == index ? '#FFECED' : '#fff' && ansObj.correctAnswer == index ? '#EAFCF5' : '#fff')}}>
                  <div
                    className='test-small-avtar r-c-c color-black'
                    style={{background: ansObj.answer === null ? (ansObj.correctAnswer == index ? Color.seaGreen : '#fff') : ((ansObj.answer === ansObj.correctAnswer && index == ansObj.answer) ? Color.seaGreen : ansObj.answer == index ? '#FF414D' : '#fff' && ansObj.correctAnswer == index ? Color.seaGreen : '#fff'),
                            color: ansObj.answer === null ? (ansObj.correctAnswer == index ? '#fff' : Color.black) : ((ansObj.answer === ansObj.correctAnswer && index == ansObj.answer) ? '#fff' : ansObj.answer == index ? '#fff' : Color.black && ansObj.correctAnswer == index ? '#fff' : Color.black)}}>
                    {index===0 ? 'A' : index===1 ? 'B' : index===2 ? 'C' : 'D'}
                    <div className='absolute text-xsm lowgrey' style={{right:'8px', top:'0px'}}>
                      {ansObj.answer === null ? (ansObj.correctAnswer == index ? 'Correct Answer' : '') : ((ansObj.answer === ansObj.correctAnswer && index == ansObj.answer) ? 'Your Answer' : ansObj.answer == index ? 'Your Answer' : 'Correct Answer' && ansObj.correctAnswer == index ? 'Correct Answer' : '')}
                    </div>
                  </div>
                  <div className='text-sm color-black m-l-10'>{this.htmlParser(data.value)}</div>
                </div>
              ))}
            </div>
          : (ansObj.type === 'Subjective')
            ?
              <div className="">
                {ansObj.answer === null
                ?
                  <div>
                    <div className="lowgrey m-b-20">You did not attempt this question.</div>
                    <div className="test-borders relative" style={{background:'#EAFCF5', borderColor:'#EAFCF5', minHeight:'60px', padding:'18px 20px'}}>
                      <div className="color-black">{ansObj.correctAnswer}</div>
                      <div className="lowgrey text-xs absolute" style={{top:'-1px', right:'10px'}}>Correct answer</div>
                    </div>
                  </div>
                :
                  <div>
                    <div className="test-borders relative m-b-20" style={{background:ansObj.answer === ansObj.correctAnswer ? '#EAFCF5' : '#FFECED', borderColor:ansObj.answer === ansObj.correctAnswer ? '#EAFCF5' : '#FFECED', minHeight:'60px', padding:'18px 20px'}}>
                      <div className="color-black">{ansObj.answer}</div>
                      <div className="lowgrey text-xs absolute" style={{top:'-1px', right:'10px'}}>Your answer</div>
                    </div>
                    <div className="test-borders relative" style={{background:'#EAFCF5', borderColor:'#EAFCF5', minHeight:'60px', padding:'18px 20px'}}>
                      <div className="color-black">{ansObj.correctAnswer}</div>
                      <div className="lowgrey text-xs absolute" style={{top:'-1px', right:'10px'}}>Correct answer</div>
                    </div>
                  </div>
                }
              </div>
            :
              <div>
                {ansObj.filename === null ? <div className="lowgrey m-b-20">You did not attempt this question.</div> : null}
                {ansObj.filename !== null
                  ?
                    <div>
                      <div className='m-b-20'>
                        <div className="test-borders relative m-b-20" style={{background:ansObj.correct  ? '#EAFCF5'
                                                                                        : ansObj.inCorrect && (parseInt(ansObj.marksObtained) <= 0)  ? '#FFECED'
                                                                                        : ansObj.inCorrect && (parseInt(ansObj.marksObtained) > 0)  ? '#EAFCF5' : null,
                                                                              borderColor:ansObj.correct ? '#EAFCF5'
                                                                                        : ansObj.inCorrect && (parseInt(ansObj.marksObtained) <= 0)  ? '#FFECED'
                                                                                        : ansObj.inCorrect && (parseInt(ansObj.marksObtained) > 0)  ? '#EAFCF5' : null, minHeight:'60px', padding:'18px 20px'}}>
                          <div className='display-flex flex-wrap'>
                            {ansObj.filename.split(',').map((data, index) => (
                              <img
                                alt='questionImage'
                                onClick={() => {
                                  this.setState({QnOimg:''});
                                  this.props.imageZoomModalFun(true, index);
                                }}
                                src={`https://s3.ap-south-1.amazonaws.com/prod.files.classroom.digital-0949/${data}`} style={{width:'90px', height:'115px', objectFit:'cover'}}
                                className='radius-10 m-r-10 m-b-10'/>
                            ))}
                          </div>
                          <div className="lowgrey text-xs absolute" style={{top:'-1px', right:'10px'}}>
                            {ansObj.correct ? 'Your answer' : ansObj.inCorrect && (parseInt(ansObj.marksObtained) > 0) ? 'Your answer is partially correct' : 'Your answer'}
                          </div>
                        </div>
                      </div>
                      {(ansObj.correctAnswer != '')
                        ?
                          ansObj.correctAnswer != null
                          ?
                          <div className="test-borders relative" style={{background:'#EAFCF5', borderColor:'#EAFCF5', minHeight:'60px', padding:'18px 20px'}}>
                            <div className="color-black">{ansObj.correctAnswer}</div>
                            <div className="lowgrey text-xs absolute" style={{top:'-1px', right:'10px'}}>Correct answer</div>
                          </div>
                          : null
                        : null
                      }
                      {(ansObj.comment != '')
                        ?
                          ansObj.comment != null
                          ?
                          <div>
                            <div className="lowgrey m-t-20 m-b-5">Teacher’s comment</div>
                            <div className="test-borders" style={{background:'#FAFAFA', borderColor:'#FAFAFA', minHeight:'60px', padding:'18px 20px'}}>
                              <div className="color-black">{ansObj.comment}</div>
                            </div>
                          </div>
                          : null
                        : null
                      }
                    </div>
                  :
                    ansObj.correctAnswer !== ''
                    ?
                      <div className="test-borders relative" style={{background:'#EAFCF5', borderColor:'#EAFCF5', minHeight:'60px', padding:'18px 20px'}}>
                        <div className="color-black">{ansObj.correctAnswer}</div>
                        <div className="lowgrey text-xs absolute" style={{top:'-1px', right:'10px'}}>Correct answer</div>
                      </div>
                    : null
                }
              </div>
          }
        </div>

        <div className='display-flex test-ques-footer' style={{backgroundColor:'#fff'}}>
          {studentTestReviewAnsindex === 0
          ?
            null
          :
            <div onClick={() => this.hitActionButton(studentTestReviewAnsindex-1, studentTestReviewAnsArr)} className='r-c-c flex-1 cursor-pointer' style={{height:'50px'}}>
              <FiChevronLeft className='lowgrey bold'/>
              <div className='m-l-10 lowgrey bold'>PREVIOUS</div>
            </div>
          }
          {studentTestReviewAnsindex === 0
          ?
            null
          :
            <Divider type="vertical" style={{height:'auto'}}/>
          }
          {studentTestReviewAnsindex+1 === studentTestReviewAnsArr.length
          ?
            null
          :
            <div onClick={() => this.hitActionButton(studentTestReviewAnsindex+1, studentTestReviewAnsArr)} className='r-c-c flex-1 cursor-pointer' style={{height:'50px'}}>
              <div className='lowgrey bold'>NEXT</div>
              <FiChevronRight className='lowgrey bold m-l-10 '/>
            </div>
          }



        </div>
        <ImageZoomModal QnOimg={this.state.QnOimg} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { studentTestDetialData, studentSubjectHeader, studentTestQuesArr, studentTestQuesIndex,
    studentTestAnsObj, testUploadedBase64Arr, deleteQuesImgModalBool, studentTestPostQuesLoader,
    testUploadedImgdeleteArr, reviewModalBool, studentTestReviewAnsArr, studentTestReviewAnsindex,
    studentTestResult, studentTestReviewQNo
    } = state.mobileTest;
  const { user } = state.session;
  return {
    user,studentTestDetialData, studentSubjectHeader, studentTestQuesArr, studentTestQuesIndex,
    studentTestAnsObj, testUploadedBase64Arr, deleteQuesImgModalBool, studentTestPostQuesLoader,
    testUploadedImgdeleteArr, reviewModalBool, studentTestReviewAnsArr, studentTestReviewAnsindex,
    studentTestResult, studentTestReviewQNo
  };
};

const mapDispatchToProps = dispatch => ({
  submitModalFun: (bool) => dispatch(submitModalFun(bool)),
  reviewModalFun: (bool) => dispatch(reviewModalFun(bool)),
  leaveModalFun: (bool) => dispatch(leaveModalFun(bool)),
  deleteQuesImgModalFun: (bool, index) => dispatch(deleteQuesImgModalFun(bool, index)),
  studentTestCollectQuesIndex: (index) => dispatch(studentTestCollectQuesIndex(index)),
  studentTestcollectMcq: (id) => dispatch(studentTestcollectMcq(id)),
  studentTestcollectBookmark: () => dispatch(studentTestcollectBookmark()),
  postStudentTest: (params, type, callback) => dispatch(postStudentTest(params, type, callback)),
  studentTestUpdateAns: () => dispatch(studentTestUpdateAns()),
  confromDeleteQuesImgModalFun: () => dispatch(confromDeleteQuesImgModalFun()),
  studentTestcollectImage: (imgObj, base64img) => dispatch(studentTestcollectImage(imgObj, base64img)),
  updateStudentTestAnswerIndex: (index, QNo) => dispatch(updateStudentTestAnswerIndex(index, QNo)),
  imageZoomModalFun: (bool, index) => dispatch(imageZoomModalFun(bool, index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileTestReviewAnswers);
