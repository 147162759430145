import { Button, Modal, Tag } from 'antd';
import React, { useState } from 'react'
import scheduleimg from '../../../Assets/dashBoard/sbj-physics.png'
import moment from 'moment';
import { RiWirelessChargingLine } from 'react-icons/ri';
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io';
import { MdCastConnected } from 'react-icons/md';
import { SubIcon } from 'components/subIcon';

const ScheduleModal = ({isScheduleModal,setIsScheduleModal,allData}) => {
console.log("AA-allData",allData);
  const showModal = () => {
    setIsScheduleModal(true);
  };
  const handleOk = () => {
    setIsScheduleModal(false);
  };
  const handleCancel = () => {
    setIsScheduleModal(false);
  };
  const formattedWeekdays = allData?.meta?.details?.weekdays?.map((weekday) => moment().day(weekday).format('ddd')).join(' • ');

  return (
    <>
      <Modal 
      className="modal-round-corner"
      open={isScheduleModal} 
      onOk={handleOk} 
      footer={null}
      onCancel={handleCancel}>
        <div style={{display:"flex",justifyContent:"center" ,fontSize:"21px",fontWeight:"bold",marginTop:"15px"}}>New schedule added</div>
        <div style={{width:"100%",height:"250px",backgroundColor:"#FAFAFA",marginTop:"15px"}}>
          <div style={{display:"flex",marginTop:"20px"}}> 
            <div style={{marginLeft:"20px",marginTop:"20px"}}><img src={scheduleimg}/></div>
            <div style={{marginLeft:"20px",marginTop:"20px"}}>
                <div>
                <Tag style={{
                    color:`${allData?.meta?.details?.mode==='Conference'?' #594099':
                    allData?.meta?.details?.mode==='Zoom 40'?' #1089FF':allData?.meta?.details?.mode==='Broadcast'?'#FFA931':allData?.meta?.details?.mode==='Offline'?' #28DF99':null }`,
                    backgroundColor: `${allData?.meta?.details?.mode==='Conference'?' #F6F4FE':
                    allData?.meta?.details?.mode==='Zoom 40'?' #E7F3FF':allData?.meta?.details?.mode==='Broadcast'?'#FFF4D5':allData?.meta?.details?.mode==='Offline'?' #E9FCF5':null}`,
                    borderColor:  `${allData?.meta?.details?.mode==='Conference'?' #F6F4FE':
                    allData?.meta?.details?.mode==='Zoom 40'?' #E7F3FF':allData?.meta?.details?.mode==='Broadcast'?'#FFF4D5':allData?.meta?.details?.mode==='Offline'?' #E9FCF5':null}`,
                    fontSize: "8px",
                    fontWeight: "bold",
                    
                    }}>
                    <div style={{display:"flex"}}>
                    <div>{allData?.meta?.details?.mode==='Conference'? <RiWirelessChargingLine style={{color:"#594099"}} />:
                        allData?.meta?.details?.mode==='Zoom 40'?<IoMdVideocam />:allData?.meta?.details?.mode==='Broadcast'? <MdCastConnected style={{color:"#FFA931"}} />:allData?.meta?.details?.mode==='Offline'?<IoMdChatbubbles style={{color:"#28DF99"}} />:null}
                    </div>
                    <div style={{marginLeft:"3px",marginTop:"-1px"}}>{allData?.meta?.details?.mode==='Conference'?'CONFERENCE':
                    allData?.meta?.details?.mode==='Zoom 40'?'ZOOM 40':allData?.meta?.details?.mode==='Broadcast'?'BROADCAST':allData?.meta?.details?.mode==='Offline'?'OFFLINE':null}</div>
                    </div>
                    </Tag>
                </div>

                <div style={{fontSize:"15px",fontWeight:"bold"}}>{allData?.meta?.details?.subject?.name}</div>
                <div style={{color:'#636363',fontSize:"13px"}}>{moment(allData?.meta?.details?.startTime,'h:mm a').format('h:mm a')}{" "}-{""}{moment(allData?.meta?.details?.endTime,'h:mm a').format('h:mm a')}</div>  
            </div>
          </div>
          <div style={{display:"flex",justifyContent:"space-between",width:"80%",marginTop:"10px"}}>
            <div style={{marginLeft:"20px"}}>
                <div style={{color:"#636363",fontSize:"11px"}}>Start date</div>
                <div style={{fontSize:"13px",fontWeight:"bold"}}>{moment(allData?.meta?.details?.startDate).format('DD-MM-YYYY')}</div>
            </div>
            <div>
                <div style={{color:"#636363",fontSize:"11px"}}>End date</div>
                <div style={{fontSize:"13px",fontWeight:"bold"}}>{moment(allData?.meta?.details?.endDate).format('DD-MM-YYYY')}</div>
            </div>
          </div>
          <div style={{display:"flex",justifyContent:"space-between",width:"80%",marginTop:"10px"}}>
            <div style={{marginLeft:"20px"}}>
                <div style={{color:"#636363",fontSize:"11px"}}>Teacher</div>
                <div style={{display:"flex",marginTop:"5px"}}>
                  <div><SubIcon icon={allData?.meta?.details?.teacher?.name} title={allData?.meta?.details?.teacher?.name}/></div>
                  <div style={{marginLeft:"10px",marginTop:"5px",color:"#636363",fontSize:"11px",fontWeight:"bold"}}>{allData?.meta?.details?.teacher?.name}</div>
                </div>
                
            </div>
            <div style={{marginRight:"20px"}} >
                <div style={{color:"#636363",fontSize:"11px"}}>Repeat on</div>
                <div style={{color:"#636363",fontSize:"13px",fontWeight:"normal",marginTop:"5px"}}>{formattedWeekdays}</div>
            </div>
          </div>
        </div>
        
        <div style={{display:"flex",justifyContent:"center",marginTop:"20px"}}>

        <Button 
            style={{
                borderRadius: "19px",
                width: "90px",
                height: "40px",
                fontWeight: "500",
                backgroundColor: "#FFFFFF",
                color: "#636363",
                fontSize:"13px",
             
            }}
            onClick={handleCancel}
            >CLOSE</Button>
        </div>

      </Modal>
    </>
  );
};
export default ScheduleModal;