import { Select, Tooltip, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { setBatch, getCareUsers,setSpecificStudentUseId } from 'actions/adminCare';
import './careStyles.css'



function SearchByBatch({ setBatch, batch, getCareUsers, role, date, name, togglePageLoader, listOfStanderd,setSpecificStudentUseId }) {
  const { Option } = Select;

  function handleChange(value) {
    setBatch(value);
    setSpecificStudentUseId(null)
    togglePageLoader(true);
    getCareUsers(value, role, date, name, () => togglePageLoader(false));
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "#E6E6E6 solid 1px", borderRadius: "5px" }}>
      <Select
        className="inputSelecter"
        style={{ width: 190, border: "none" }}
        onSelect={handleChange}
        bordered={false}
        removeIcon={true}
        value={batch}
        dropdownMatchSelectWidth={true}
      >
        <Option value={null}><h3>All Batches</h3></Option>
        {
        listOfStanderd?.map((item) =>
          <Option key={item.id} value={item.id}><Typography.Text style={{width:"150px"}} ellipsis={{tooltip:true}}>{item.std + ": " + item.section}</Typography.Text></Option>
        )}
      </Select>


    </div>

  )
}
// export default SearchByProfession;

const mapStateToProps = state => {
  const {
    role,
    batch,
    date,
    name,
    listOfStanderd
  } = state.adminCare;
  return {
    role,
    batch,
    date,
    name,
    listOfStanderd
  };
};
const mapDispatchToProps = dispatch => ({

  setBatch: (batch) => dispatch(setBatch(batch)),
  getCareUsers: (batch, role, date, name, callback) => dispatch(getCareUsers(batch, role, date, name, callback)),
  setSpecificStudentUseId: (userId) => dispatch(setSpecificStudentUseId(userId)),
});


export default connect(mapStateToProps, mapDispatchToProps)(SearchByBatch);

