import React, { useState } from "react";
import TeX from "@matejmazur/react-katex";
import ReactHtmlParser from "react-html-parser";
import { Input, Row, Upload } from "antd";
import { CloseOutlined, LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Color } from "services";

const QuestionStatement = ({ data, quesIndex, currQuesIndex, type, toggleImageModal, setCurrentImg }) => {
	const [isImageViewerVisible, setImageViewerVisibility] = useState(false);
	const [imgUrl, setImgUrl] = useState("");

	return (
		<div style={{  }}>
			{type == "OrQuestions" ? (
				<div style={{ fontWeight: 600, color: "#636363", marginBottom: 5 }}>
					{quesIndex == 0
						? `Question ${currQuesIndex}(a)`
						: `Question ${currQuesIndex}(b)`}
				</div>
			) : null}
			<div className="m-l-15">
			{ReactHtmlParser(data, {
				transform: (node) => {
					if (
						node.type === "tag" &&
						node.name === "span" &&
						node.attribs["data-value"]
					) {
						return <TeX math={`${node.attribs["data-value"]}`} />;
					}
					if (node.type === "tag" && node.name === "img") {
						return (
							<img
								alt="question"
								style={{ maxHeight: "160px", maxWidth: "160px" }}
								src={node.attribs.src}
								className="m-t-10 m-r-10 cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									setImgUrl(node.attribs.src);
									setImageViewerVisibility(true);
									toggleImageModal(true, -1);
									setCurrentImg(node.attribs.src);
								}}
							/>
						);
					}
				},
			})}
			</div>
		</div>
	);
};


const QuestionTypes = ({
	type,
	data,
	onChange,
	inputValue,
	quesContent,
	quesIndex,
	currQuesIndex,
	setAnswerObj,
	answerObj,
	comprehensive,
	orQuestion,
	isResult,
	currentImages,
	toggleImageModal,
	removeAnswerImage,
	imgLoader,
	beforeUpload,
	uploadImage,
	setCurrentImg
}) => {
	const [isImageViewerVisible, setImageViewerVisibility] = useState(false);
	const [imgUrl, setImgUrl] = useState("");

	// type = 'Numeric'

	// console.log('quesC', quesContent);
	console.log("type", type);
	console.log(answerObj, "obj");

	if (answerObj === null || answerObj === undefined) {
		if (comprehensive) {
			answerObj = [];
		} else {
			answerObj = {};
		}
	}

	const orQuestionSelectedStyling =
		answerObj?.orQuestionIndex === undefined ||
		answerObj?.orQuestionIndex === quesIndex;
	let comprehensiveSelectedStyling = false;

	if (comprehensive) {
		comprehensiveSelectedStyling = true;
	}

	let isSelected = (selectedAnswer) => {
		if (comprehensive && Array.isArray(answerObj)) {
			const subAnswerObj = answerObj?.find(
				(subAnswerObj) => subAnswerObj?.subQuestionIndex === quesIndex
			);
			if (!subAnswerObj) return false;
			if (type === "MCQ" || type === "T/F") {
				return subAnswerObj?.answer === selectedAnswer;
			} else if (type === "MSQ") {
				return (
					subAnswerObj?.answers?.find((answer) => answer === selectedAnswer) !==
					null &&
					subAnswerObj?.answers?.find((answer) => answer === selectedAnswer) !==
					undefined
				);
			}
		} else {
			if (type === "MCQ" || type === "T/F")
				return answerObj?.answer === selectedAnswer;
			else if (type === "MSQ")
				return (
					answerObj?.answers?.find((answer) => answer === selectedAnswer) !==
					null &&
					answerObj?.answers?.find((answer) => answer === selectedAnswer) !==
					undefined
				);
		}
	};

	const handleMSQClick = (index) => {
		let answers = [];
		if (comprehensive && Array.isArray(answerObj)) {
			const subQuestionAnswerObj = answerObj?.find(
				(el) => el.subQuestionIndex == quesIndex
			);
			answers = subQuestionAnswerObj?.answers;
		} else {
			answers = answerObj?.answers;
		}
		if (answers === null || answers === undefined) {
			answers = [];
		}
		if (answers.includes(index)) {
			answers = answers.filter((answer) => answer != index);
		} else {
			answers = [...answers, index];
		}
		setAnswerStateConditionally({ answers });
	};

	const handleNumericAnswer = (e) => {
		let answer = e.target.value.toString();
		setAnswerStateConditionally({ answer });
	};

	const handleMCQClick = (index) => {
		setAnswerStateConditionally({ answer: index });
	};

	const handleTFClick = (trueOrFalse) => {
		setAnswerStateConditionally({ answer: trueOrFalse })
	};

	const setAnswerStateConditionally = (answerParams) => {
		if (comprehensive) {
			const newAnswerObj = {
				...answerParams,
				subQuestionIndex: quesIndex,
			};
			const newAnswerObjArr = [...answerObj];
			let subQuestionAnswerId = -1;
			newAnswerObjArr.forEach((subQuestionAnswer, idx) => {
				if (subQuestionAnswer.subQuestionIndex == quesIndex) {
					subQuestionAnswerId = idx;
				}
			});
			if (subQuestionAnswerId == -1) {
				newAnswerObjArr.push(newAnswerObj);
			} else {
				newAnswerObjArr[subQuestionAnswerId] = newAnswerObj;
			}
			setAnswerObj(newAnswerObjArr);
		} else {
			const newAnswerObj = {
				...answerParams,
				orQuestionIndex: orQuestion ? quesIndex : undefined,
			};
			setAnswerObj(newAnswerObj);
		}
	};

	let numericAnswerVal = null;
	if (type == "Numeric") {
		if (orQuestion) {
			numericAnswerVal =
				answerObj?.orQuestionIndex == quesIndex ? answerObj?.answer : null;
		} else if (comprehensive && Array.isArray(answerObj)) {
			const foundSubQuestionAnswer = answerObj?.find(
				(subQues) => subQues?.subQuestionIndex == quesIndex
			);
			numericAnswerVal = foundSubQuestionAnswer?.answer;
		} else {
			numericAnswerVal = answerObj?.answer ?? null;
		}
	}
	console.log('vall', numericAnswerVal, type);

	return (
		<div>
			<div style={{ width: "95%", margin: 20, wordBreak: 'break-word' }}>
				<QuestionStatement
					data={quesContent?.statement}
					quesIndex={quesIndex}
					currQuesIndex={currQuesIndex}
					type={type}
					toggleImageModal={toggleImageModal}
					setCurrentImg={setCurrentImg}
				/>
			</div>
			{!isResult &&
			(type == "Numeric" ? (
				<>
					<div className="m-l-40">
						<span style={{ fontWeight: "bold" }}> Answer </span>
					</div>
					<Input
						style={{ width: "35%", marginLeft: 40, marginBottom: 20 }}
						value={numericAnswerVal == null ? '' :  numericAnswerVal}
						onChange={(e) => {
							handleNumericAnswer(e);
						}}
						pattern="/(^$)|(^[0-9\.\-\e\+\/]+$)/"
						className="numericInput"
						placeholder="Enter Answer"
						type="number"
					></Input>
				</>
			) : type == "MCQ" ? (
				<div>
					{quesContent?.options &&
						quesContent?.options?.length != 0 &&
						quesContent?.options?.map((item, index) => (
							<div
								className="options cursor-pointer"
								onClick={() => handleMCQClick(index)}
								key={index}
								style={
									orQuestionSelectedStyling && isSelected(index)
										? { backgroundColor: "#F6F4FE", borderRadius: 10 }
										: { borderRadius: 10 }
								}
							>
								<div className="optionId">
									{index === 0
										? "A"
										: index === 1
											? "B"
											: index === 2
												? "C"
												: index === 3
													? "D"
													: index === 4
														? "E"
													: null}
								</div>
								<div style={{wordBreak: 'break-word'}}>
									{ReactHtmlParser(item?.value, {
										transform: (node) => {
											if (
												node.type === "tag" &&
												node.name === "span" &&
												node.attribs["data-value"]
											) {
												return <TeX math={`${node.attribs["data-value"]}`} />;
											}
											if (node.type === "tag" && node.name === "img") {
												return (
													<img
													alt="question"
													style={{ maxHeight: "160px", maxWidth: "160px" }}
													src={node.attribs.src}
													className="m-t-10 m-r-10 cursor-pointer"
														onClick={(e) => {
															e.stopPropagation();
															setImgUrl(node.attribs.src);
															setImageViewerVisibility(true);
															toggleImageModal(true, -1);
															setCurrentImg(node.attribs.src);
														}}
													/>
												);
											}
										},
									})}
								</div>
							</div>
						))}
				</div>
			) : type == "MSQ" ? (
				<div>
					{quesContent?.options &&
						quesContent?.options?.length != 0 &&
						quesContent?.options?.map((item, index) => (
							<div
								className="options cursor-pointer"
								onClick={() => {
									handleMSQClick(index);
								}}
								key={index}
								style={
									orQuestionSelectedStyling && isSelected(index)
										? { backgroundColor: "#F6F4FE", borderRadius: 10 }
										: { borderRadius: 10 }
								}
							>
								<div className="optionId">
									{index === 0
										? "A"
										: index === 1
											? "B"
											: index === 2
												? "C"
												: index === 3
													? "D"
													: index === 4
														? "E"
													: null}
								</div>
								<div style={{wordBreak: 'break-word'}}>
									{ReactHtmlParser(item?.value, {
										transform: (node) => {
											if (
												node.type === "tag" &&
												node.name === "span" &&
												node.attribs["data-value"]
											) {
												return <TeX math={`${node.attribs["data-value"]}`} />;
											}
											if (node.type === "tag" && node.name === "img") {
												return (
													<img
														alt="question"
														style={{ maxHeight: "160px", maxWidth: "160px" }}
														src={node.attribs.src}
														className="m-t-10 m-r-10 cursor-pointer"
														onClick={(e) => {
															e.stopPropagation();
															setImgUrl(node.attribs.src);
															setImageViewerVisibility(true);
															toggleImageModal(true, -1);
															setCurrentImg(node.attribs.src);
														}}
													/>
												);
											}
										},
									})}
								</div>
							</div>
						))}
				</div>
			) : type == "T/F" ? (
				<div>
					<div
						className="options cursor-pointer"
						onClick={() => handleTFClick(true)}
						// onClick={() => {
						// 	setAnswerObj({
						// 		...answerObj,
						// 		answer: true,
						// 		orQuestionIndex: orQuestion ? quesIndex : undefined,
						// 	});
						// }}
						key={"true"}
						style={
							orQuestionSelectedStyling && isSelected(true)
								? { backgroundColor: "#F6F4FE", borderRadius: 10 }
								: { borderRadius: 10 }
						}
					>
						<div style={{ display: "flex" }}>
							<div className="optionId" style={{ marginTop: 5 }}>
								<div>A</div>
							</div>
							<div style={{ marginTop: 5 }}>TRUE</div>
						</div>
					</div>

					<div
						className="options cursor-pointer"
						onClick={() => handleTFClick(false)}
						key={"false"}
						style={
							orQuestionSelectedStyling && isSelected(false)
								? { backgroundColor: "#F6F4FE", borderRadius: 10 }
								: { borderRadius: 10 }
						}
					>
						<div style={{ display: "flex" }}>
							<div className="optionId" style={{ marginTop: 5 }}>
								<div>B</div>
							</div>
							<div style={{ marginTop: 5 }}>FALSE</div>
						</div>
					</div>
				</div>
			) : type == "Subjective" ? (
				<div>
					<div
						style={{ marginLeft: "35px", marginTop: "25px", fontWeight: 600 }}
					>
						Upload Answer
					</div>
				
					<div className="r-c-fs" style={{ marginTop: 0 }}>
						{currentImages && currentImages.length ? (
							<Row style={{ marginLeft: 20 }}>
								{currentImages?.map((item, index) => (
									<div
										key={index.toString()}
										style={{ margin: 20 }}
										className="cursor-pointer"
										onClick={() => toggleImageModal(true, index)}
									>
										<div style={{ width: "120px", height: "120px" }}>
											<img
												src={item}
												alt=""
												style={{ width: "100%", height: "100%" }}
											/>
										</div>
										<div
											className="closeImage"
											onClick={(e) => {
												e.stopPropagation();
												removeAnswerImage(index);
											}}
										>
											<CloseOutlined
												style={{ color: Color.wrong }}
												className="cursor-pointer"
											/>
										</div>
									</div>
								))}
								{imgLoader && (
									<div style={{ margin: 20 }} className="cursor-pointer">
										<div
											style={{
												width: "120px",
												height: "120px",
												border: `1px solid ${Color.midGrey}`,
												borderRadius: 10,
											}}
											className="r-c-c-c"
										>
											<LoadingOutlined />
										</div>
									</div>
								)}
								{currentImages?.length <= 4 && (
									<Upload
										name="avatar"
										listType="picture-card"
										className="answerImgUploader cursor-pointer"
										showUploadList={false}
										action="/"
										beforeUpload={beforeUpload}
										onChange={uploadImage}
									>
										<Row
											className="subjectiveAnswer"
											style={{ width: "55vw" }}
										>
											<PlusCircleOutlined
												style={{ fontSize: "1.2em", color: "blue" }}
											/>
										</Row>
									</Upload>
								)}
							</Row>
						) : (
							<Row style={{ marginLeft: 20 }}>
								{imgLoader && (
									<div style={{ margin: 20 }} className="cursor-pointer">
										<div
											style={{
												width: "120px",
												height: "120px",
												border: `1px solid ${Color.midGrey}`,
												borderRadius: 10,
											}}
											className="r-c-c-c"
										>
											<LoadingOutlined />
										</div>
									</div>
								)}
								<Upload
									name="avatar"
									listType="picture-card"
									disabled={currentImages?.length >= 5}
									className="answerImgUploader cursor-pointer"
									showUploadList={false}
									action="/"
									beforeUpload={beforeUpload}
									onChange={uploadImage}
								>
									<Row className="subjectiveAnswer" style={{ width: "55vw" }}>
										<PlusCircleOutlined
											style={{ fontSize: "1.2em", color: "blue" }}
										/>
									</Row>
								</Upload>
							</Row>
						)}
					</div>
				</div>
			) : null)}
		</div>
	);
};

export { QuestionStatement, QuestionTypes };
