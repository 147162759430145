import { Api, Color } from '../../services';
import React from 'react';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Avatar from 'antd/lib/avatar';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Input from 'antd/lib/input';
import Card from 'antd/lib/card';
import Modal from 'antd/lib/modal';
import Pagination from 'antd/lib/pagination';
import Tooltip from 'antd/lib/tooltip';
import Empty from 'antd/lib/empty';
import 'antd/lib/button/style/index';
import 'antd/lib/tooltip/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/row/style/index';
import 'antd/lib/col/style/index';
import 'antd/lib/input/style/index';
import 'antd/lib/card/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import {connect} from 'react-redux';
import { FaClipboard, FaRegBookmark, FaBookmark, FaRegClone } from "react-icons/fa";
import { FiMessageSquare } from "react-icons/fi";
import { EditOutlined, DeleteOutlined, EllipsisOutlined, QuestionCircleFilled, SaveOutlined, CloseCircleOutlined, ExclamationCircleOutlined,
  InfoCircleOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { GoPencil } from 'react-icons/go';
import { MdSend, MdPublish, MdDelete } from "react-icons/md";
import { toggleTestModal, setTestParams, toggleQuestionModal, setCurrentTestQuestion, updateQuestionFeedback, saveTestFeedback, 
  deleteTestQuestion, updateTestStatus, toggleViewQuestionModal, unmarkForReview, getTestById, handleTestInput } from '../../actions/test_old';
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';
const { confirm } = Modal;
const { Meta } = Card;
const pageSize = 20;


function getFilename(url){
  return url ? url.split('/').pop().split('#').shift().split('?').shift() : null;
}

class QuestionList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedQuestionId: -1,
      selectedQuestionIndex: -1,
      ToggleInstructionModal: false
    }
  }

  taskChange = (data, history, index = -1) => (
    <Menu className='assignmentddlmenu'>
     {this.props.currentTestDetails.status !=="Published" &&
     <>
      <Menu.Item key="0">
        <div onClick={() => {
            this.setState({selectedQuestionId: data.id, selectedQuestionIndex: index})
          }}>
          <FiMessageSquare /> Comment
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        <div onClick={(e) => {e.stopPropagation(); this.handleEditQuestionModal(data)}}>
          <EditOutlined /> Edit
        </div>
      </Menu.Item>
      </>}
      <Menu.Item key="4">
        <div onClick={(e) => {e.stopPropagation(); this.handleViewQuestionModal(data)}}>
          <EyeOutlined /> View
        </div>
      </Menu.Item>
      {this.props.currentTestDetails.status !=="Published" &&
      <Menu.Item key="2">
        <div onClick={(e) => {
            e.stopPropagation();
            this.showDeleteTaskConfirm(data.id, this)
          }}>
          <DeleteOutlined /> Delete
        </div>
      </Menu.Item>}
    </Menu>
  );

  handleQuestionClick(id, index){
    if(this.props.currentTestDetails.status !== "Published")
      this.setState({selectedQuestionId: id, selectedQuestionIndex: index})
    else return;
  }


  questionListItem = (data, index) => {
    const {orgId, questionsFeedback, updateQuestionFeedback, testQPageMeta, currentTestDetails} = this.props;
    const {selectedQuestionId} = this.state;
    return (
      <Row key={index.toString()} className='cursor-pointer'  onClick={() => this.handleQuestionClick(data.id, index)}
      style={{width: '98%', padding: 10, backgroundColor: selectedQuestionId === data.id ? Color.midGrey : 'transparent', borderRadius: 10}}>
        <Col xs={{span: 4}} sm={{span:3}} md={{span:3}} lg={{span:2}} xl={{span:2}} xxl={{span:1}} >
        <Avatar size={40} onClick={() => this.markForReview(index)} style={{backgroundColor: Color.lightGrey, color: Color.darkGrey
        }}>
          {testQPageMeta ?
          (parseInt(testQPageMeta.totalItems) - (parseInt(testQPageMeta.currentPage) - 1) * pageSize - (index)) : 0}
        </Avatar>
        </Col>
        <Col xs={{span: 20}} sm={{span:21}} md={{span:21}} lg={{span:22}}  xl={{span:22}} xxl={{span:23}}>
          <Row style={{width: '95%'}} >
            <Col sm={{span:22}} md={{span:22}} lg={{span:22}}  xl={{span:22}} xxl={{span:22}}>
              <div className='display-flex' style={{width: '100%'}}>
                {/*<MathJax math={`${data.statement}`} />*/}
                <div className="quest" style={{opacity:.7, pointerEvents:'none', width: '100%'}}>
                  {/*<CustomCKEditor disable={false} ckdata={data.statement} imagesList={[]} onChange={(val) => console.log(val)}/>*/}
                  {ReactHtmlParser(data.statement,
                    { transform: (node) => {
                    if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                      return <TeX math={`${node.attribs['data-value']}`} />;
                    }
                    if (node.type === 'tag' && node.name === 'img') {
                      return <img alt="question"style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} />;
                    }
                  }})
                }
                </div>
                {currentTestDetails.status !== "Published" &&
                <div style={{marginLeft: 20, fontSize: '1.2em', color: Color.primary}}>
                  {questionsFeedback && questionsFeedback[data.id] && questionsFeedback[data.id].markedForReview ?
                    <Tooltip title="Unmark">
                      <FaBookmark onClick={(e) => {e.stopPropagation(); this.setState({isEditing: true}); updateQuestionFeedback(false, 'markedForReview', data.id)}} />
                    </Tooltip>
                    :
                    <Tooltip title="Mark for review">
                      <FaRegBookmark onClick={(e) => {e.stopPropagation(); this.setState({isEditing: true}); updateQuestionFeedback(true, 'markedForReview', data.id)}}/>
                    </Tooltip>
                  }
                </div>}

              </div>
              <div>
                  {data.image && data.image !== 'null' && data.image !== 'false' && <img style={{height: 80}}
                  onClick={(e) => {e.stopPropagation(); this.setState({imageModal: true, imagePath: `${Api._base_url}/api/${orgId}/uploads/assignmentQuestion/${data.image}`})}}
                  src={`${Api._s3_url}${data.image}`} alt="question" />}
              </div>
              <div style={{fontSize: 12}}>
                {data.positiveMarks ? <>{data.positiveMarks} {data.positiveMarks == 1 ? "Mark" : "Marks"}</> :null}
              </div>
            </Col>
            <Col className='r-jsb' sm={{span:2}} md={{span:2}} lg={{span:2}} xl={{span:2}} xxl={{span:2}}>
              <div>
                <Dropdown
                    trigger={['hover', 'click']}
                    onClick={e => e.stopPropagation()}
                    overlay={() => this.taskChange({...data}, this.props.history, index)}
                  >
                    <EllipsisOutlined
                      className='m-l-10 text-sm color-purple cursor-pointer' style={{fontSize: 30}}
                  />
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Col>

      </Row>
    )
  }

  testChange = (data, history) => {
    return (
    <Menu className='assignmentddlmenu'>
      {data.status !== "Published" ? 
      <Menu.Item key="0">
        <div onClick={() => this.openEditModal()}>
          <EditOutlined /> Edit
        </div>
      </Menu.Item> : null }
      <Menu.Item key="1">
        <div onClick={() => {
            this.handleCloneClick(data)
          }}>
          <FaRegClone /> Clone
        </div>
      </Menu.Item>
    </Menu>
  )}

  handleCloneClick(data){
    const {handleTestInput, toggleTestModal } = this.props;
    // console.log(data);
    handleTestInput(data.id,'cloneTestId');
    handleTestInput(data.subjectNames,'subjectNames');
    handleTestInput(data.subjectIds,'subjectIds');
    handleTestInput(data.teacherName, 'teacherName');
    handleTestInput(data.teacherId,'teacherId');
    handleTestInput(data.teacherId,'selectedTeacher');
    handleTestInput(data.chapterNames,'chapterNames');
    handleTestInput(data.chapterIds,'chapterIds');
    toggleTestModal(true);
  }


  assignmentHeader = () => {
    const { currentTestDetails, openInstructions } = this.props;
      return (
        <div className='assignmentItem m-b-10 r-jsb' style={{backgroundColor: '#FFF'}}>
          <div className='r-ac flex-1'>
            <div className='assignmentIcon m-r-10' style={{border: currentTestDetails.markedForReview ? '2px solid red' : false}}/>
            <div>
              <div className='text-sm bold firstCapital color-greenblack display-flex'>
                {currentTestDetails.title}
                <span className='r-c-fs color-purple m-l-10 text-xmd '>
                <InfoCircleOutlined
                  className='cursor-pointer'
                  onClick={() => openInstructions()}
                />
                </span>
              </div>
              <div className='text-xs dark-grey bold-400'>
                 {currentTestDetails.teacherName}
              </div>
              <div className='text-xs dark-grey'>
                {currentTestDetails.chapterNames && currentTestDetails.chapterNames.map((chap, index, array) => (<>{chap} {index === array.length - 1 ? '' : '|'} </>))}
              </div>
            </div>
          </div>
          <div className='r-c-se flex-3'>
            <div className='text-center'>
              <div className='bold color-purple text-xs'>
                {currentTestDetails.publishDate ? moment(currentTestDetails.publishDate).format('DD-MM-YYYY') : 'NA'}
              </div>
              <div className='dark-grey text-xs'>
                Published Date
              </div>
            </div>
            <div className='text-center'>
                <div className='bold color-purple text-xs'>
                  {moment(currentTestDetails.testDate).format('DD-MM-YYYY')}
                </div>
                <div className='dark-grey text-xs'>
                  Test Date
                </div>
            </div>
            <div className='text-center'>
              <div className='bold color-purple text-xs'>
                {moment(currentTestDetails.resultDate).format('DD-MM-YYYY')}
              </div>
              <div className='dark-grey text-xs'>
                Result Date
              </div>
            </div>
            <div className='text-center'>
            <div className='color-orange bold text-md'>
              {currentTestDetails.submitted || 'NA'}
            </div>
            <div className='dark-grey text-xs'>Submitted</div>
            </div>
            <div className='text-center'>
              <div className='color-orange bold text-md'>
              <Dropdown
                trigger={['click', 'hover']}
                onClick={e => e.stopPropagation()}
                overlay={() => this.testChange(currentTestDetails, this.props.history)}
              >
                <EllipsisOutlined
                  className='m-l-10 text-sm color-purple cursor-pointer'
              />
              </Dropdown>
              {/* <Button type='link' onClick={() => this.openEditModal()} disabled={currentTestDetails.status === "Published"}>
                <GoPencil />
              </Button> */}
              </div>
              <div className='dark-grey text-xs'>
                Actions
              </div>
            </div>
          </div>
        </div>
      );
  }


  noQuestions = () => (
    <Empty
      description={
        <span style={{
            backgroundColor: '#EEEEEE',
            borderRadius: 10,
            padding: 10,
          }} className='bold dark-grey text-xmd'>
          No Questions Found!
        </span>
      }
    >
    <Button
      className='radius-10'
      onClick={() => this.props.toggleQuestionModal(true)}
      type="primary"
    >
      + Add Question
    </Button>
    </Empty>
  )


  handlePageChange(page){
    // this.props.updateTeacherCurrentPage(page);
    this.props.getTestById(this.props.currentTestDetails.id, page, 'assignment-teacher')
    // this.props.getAssignmentById(this.props.currentTestDetails.id, page, 'assignment-teacher')
  }


  rightPane = () => {
    const { currentTestDetails, questionsFeedback, reviewLoader, testQPageMeta, statusLoader, totalPoints, updateQuestionFeedback} = this.props;
    const {selectedQuestionId, comment, selectedQuestionIndex} = this.state;
    return (
      <div style={{height: '65vh'}}>
        <div style={{height: '70%', border: `1px solid #D9D9D9`, borderRadius: '9px', padding: 10, }}>
          <div className='flex-1 r-c-sb' style={{verticalAlign: 'center'}}>
            <div className="display-flex">
              <QuestionCircleFilled style={{fontSize: 20, color: Color.primary, marginRight: 10}}/> <div>Questions</div>
            </div>
            {testQPageMeta ? testQPageMeta.totalItems : 0}
          </div>
          <div className='flex-1 r-c-sb' style={{verticalAlign: 'center', marginTop: 20}}>
            <div className="display-flex">
              <FaClipboard style={{fontSize: 20, color: Color.primary, marginRight: 10}}/> <div>Marks</div>
            </div>
            <div>{totalPoints || 0}</div>
          </div>
          {selectedQuestionId !== -1 ?
            <div style={{ marginTop: '20%'}}>
              <Card
                 style={{ width: '95%', borderRadius: 5, border: '1px solid #D9D9D9' }}
                 actions={
                   [<Input value={comment} onPressEnter={() => {this.setComment(); updateQuestionFeedback(true, 'markedForReview', selectedQuestionId);}} onChange={(e) => this.setState({comment: e.target.value})} style={{width: '95%', borderRadius: 5}}
                   suffix={<MdSend className='cursor-pointer' onClick={() => {this.setComment();  updateQuestionFeedback(true, 'markedForReview', selectedQuestionId)}}/>}/> ]}
               >
                 <Meta
                   title={`Q${testQPageMeta ?
                   (parseInt(testQPageMeta.totalItems) - (parseInt(testQPageMeta.currentPage) - 1) * pageSize - (selectedQuestionIndex)) : 0} comments`}
                   description={<div id="comment" style={{maxHeight: '15vh', overflowY: 'auto'}}>
                    {questionsFeedback[selectedQuestionId] && questionsFeedback[selectedQuestionId].comment ?
                      questionsFeedback[selectedQuestionId].comment.map((item, i) =>
                      <div style={{backgroundColor: Color.midGrey, borderRadius: 5, margin: 3, padding: 4}} key={i.toString()}>
                        <div>{item.text}</div>
                        <div style={{fontSize: 8}}>{item.time}</div>
                      </div>)
                    : null}
                   </div>}
                 />
               </Card>
             </div>
             : null
          }
        </div>
        {/* {currentTestDetails.markedForReview ? <Button
        style={{width: '100%', borderRadius: 5, marginTop: 10}}
        loading={reviewLoader}
        disabled={currentTestDetails.status === "Published"}
        onClick={() => this.props.unmarkForReview(currentTestDetails.id)}>
        {reviewLoader ?  <LoadingOutlined />: null}
          Close Issues
        </Button> :  null} */}
        <Button
        type='primary'
        style={{width: '100%', borderRadius: 5, marginTop: 10}}
        onClick={() => this.props.toggleQuestionModal(true)}
        disabled={currentTestDetails.status === "Published"}>
          + Add Question
        </Button>
        <Button
        type='ghost'
        onClick={() => this.onSaveQuestionFeedback()}
        disabled={currentTestDetails.status === "Published"}
        style={{width: '100%', borderRadius: 5, marginTop: 10, backgroundColor: Color.midGrey}}>
          <SaveOutlined />Save Review
        </Button>
        <Button
        type='ghost'
        loading={statusLoader}
        onClick={() =>this.showConfirm("Publish", this)}
        style={{width: '100%', borderRadius: 5, marginTop: 10, color: Color.white, backgroundColor: Color.lightgreen}}
        disabled={currentTestDetails.status !== "Draft"}>
          {currentTestDetails.declared !== true ?
            <>
              <MdPublish style={{marginRight: 10}}/> {currentTestDetails.status === "Draft" ?  "Publish" : currentTestDetails.status}
            </>
          : "Results Declared"}
        </Button>
        <Button
        type='danger'
        onClick={() =>this.showConfirm("Delete", this)}
        disabled={currentTestDetails.status === "Published"}
        style={{width: '100%', borderRadius: 5, marginTop: 10}}>
          <MdDelete style={{marginRight: 10}}/> Delete
        </Button>
      </div>)
  }


  handleEditQuestionModal = (data) => {
    const { setCurrentTestQuestion, questionImagesList, optionsImagesList, oldImageList, toggleQuestionModal} =  this.props;
    setCurrentTestQuestion(data);
    toggleQuestionModal(true);
    Array.from( new DOMParser().parseFromString( data.statement, 'text/html' )
    .querySelectorAll( 'img' ) )
    .map( img => {
      questionImagesList.push(getFilename(img.getAttribute( 'src' )))
      oldImageList.push(getFilename(img.getAttribute( 'src' )))
      return 1
    })
    if(data.options){
      data.options.forEach((option, index) => {
        Array.from( new DOMParser().parseFromString( option.value, 'text/html' )
        .querySelectorAll( 'img' ) )
        .map( (img) => {
          optionsImagesList[index].push(getFilename(img.getAttribute( 'src' )))
          oldImageList.push(getFilename(img.getAttribute( 'src' )))
          return 1
        } )
      })
    }

  }

  handleViewQuestionModal = (data) => {
    const { toggleViewQuestionModal, setCurrentTestQuestion } =  this.props;
    setCurrentTestQuestion(data);
    toggleViewQuestionModal(true);
  }



  showConfirm(action, self) {
    const icon = action === "Delete" ? <CloseCircleOutlined style={{color: Color.red}}/> : <ExclamationCircleOutlined />
    return confirm({
      title: `Do you want to ${action} this test?`, //${text}
      icon: icon,
      content: 'Please click OK to confirm',
      onOk() {self.handleConfirmClick(action)},
      onCancel() {},
    })
  }

  handleConfirmClick(action){
    action = action === "Delete" ? "Deleted" : action === "Publish" ? "Published" : null
    const params =  this.props.currentTestDetails;
    this.props.updateTestStatus(params, action, this.props.history)
  }


  async openEditModal(){
    this.props.setTestParams();
    this.props.toggleTestModal(true)
  }

  setComment(){
    const {questionsFeedback, updateQuestionFeedback} = this.props;
    const {selectedQuestionId, comment} = this.state;
    const element = document.getElementById("comment");
    element.scrollTop = element.scrollHeight+10;
    if(comment === '') return
    const feedbackComment = questionsFeedback[selectedQuestionId] && questionsFeedback[selectedQuestionId].comment
                            ? questionsFeedback[selectedQuestionId].comment : [];
    const time = moment().format('HH:mm DD/MM/YYYY');
    feedbackComment.push({text: comment, time: time})
    updateQuestionFeedback(feedbackComment, 'comment', selectedQuestionId);
    this.setState({comment: '', isEditing: true})
  }


  onSaveQuestionFeedback(){
    const { saveTestFeedback, currentTestDetails, questionsFeedback } = this.props;
    const feedback = {}
    Object.keys(questionsFeedback).forEach(key => {
      feedback[key] = {...questionsFeedback[key], comment: JSON.stringify(questionsFeedback[key].comment)}
    })
    const params = {reviewedQuestions:  feedback, testId:currentTestDetails.id}
    saveTestFeedback(params);
    this.setState({isEditing: false})
  }


  showDeleteTaskConfirm(id, self) {
    return confirm({
      title: `Do you want to delete this question?`, //${text}
      icon: <CloseCircleOutlined style={{color: Color.red}}/>,
      content: 'Please click OK to confirm',
      onOk() {self.props.deleteTestQuestion(id);}, //self.props.deleteTestQuestion(id);
      onCancel() {},
    })
  }


  render(){
    const { testQList, testQPageMeta } = this.props;
    return (
      <div>
      {this.assignmentHeader()}
      <Row>
        <Col xs={{span: 24}} sm={{span:24}} md={{span:18}} lg={{span:18}} xl={{span:20}} xxl={{span:20}} >
          <div style={{height: '65vh', overflowY: 'auto'}}>
            {testQList && testQList.length ?
              testQList.map((data, index) => this.questionListItem(data, index))
            : <div style={{height: '100%'}} className='r-c-c-c'>{this.noQuestions()}</div>}
          </div>
          <div style={{height: '10%', textAlign: 'right'}}>
            <Pagination
              size="small"
              current={testQPageMeta ? parseInt(testQPageMeta.currentPage) : 1}
              onChange={(page) => this.handlePageChange(page)}
              pageSize={pageSize}
              total={testQPageMeta ? testQPageMeta.totalItems : 0}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              showSizeChanger={false}
            />
          </div>
        </Col>
        <Col xs={{span: 24}} sm={{span:24}} md={{span: 6}} lg={{span: 6}} xl={{span:4}} xxl={{span:4}} >
          {this.rightPane()}
        </Col>
    </Row>
    </div>
    )
  }
}

const mapStateToProps = state => {
  const { teacherCurrentPage, testParams, questionsFeedback, testQPageMeta, reviewLoader, currentTestDetails,
  statusLoader, questionImagesList, optionsImagesList, oldImageList, testQList, totalPoints  } = state.test
  const { subClasses } = state.calendar;
  const {orgId} = state.session.user

  return { orgId, teacherCurrentPage, testParams, questionsFeedback, testQPageMeta, reviewLoader,
  statusLoader, questionImagesList, optionsImagesList, oldImageList, subClasses, testQList, currentTestDetails, totalPoints };
};

const mapDispatchToProps = dispatch => ({

  toggleTestModal: (state) => dispatch(toggleTestModal(state)),
  setTestParams: () => dispatch(setTestParams()),
  toggleQuestionModal: (state) => dispatch(toggleQuestionModal(state)),
  setCurrentTestQuestion: data => dispatch(setCurrentTestQuestion(data)),
  updateQuestionFeedback: (value, key, index) => dispatch(updateQuestionFeedback(value, key, index)),
  saveTestFeedback: (params) => dispatch(saveTestFeedback(params)),
  deleteTestQuestion: (id) => dispatch(deleteTestQuestion(id)),
  updateTestStatus: (params, status, history) => dispatch(updateTestStatus(params, status, history)),
  toggleViewQuestionModal: (bool) => dispatch(toggleViewQuestionModal(bool)),
  unmarkForReview: (testId) => dispatch(unmarkForReview(testId)),
  getTestById: (id, page, view) => dispatch(getTestById(id,page,view)),
  handleTestInput: (val, key) => dispatch(handleTestInput(val, key)),

});



export default connect(mapStateToProps, mapDispatchToProps)(QuestionList);
