import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/plots";
import { subjectSummary } from "actions/attendanceNew";


const StudentDonutGraph = ({ summaryOfStudent }) => {
  const data = [
    {
      type: "Present",
      value: summaryOfStudent?.monthlySummary?.presentPercentage
    },
    {
      type: "Absent",
      value: summaryOfStudent?.monthlySummary?.absentPercentage
    },

  ];
  const config = {
    appendPadding: 8,
    data,
    angleField: "value",
    colorField: "type",
    color: ["#28DF99", "#FF414D"],
    radius: 0.7,
    innerRadius: 0.7,
    label: {
      type: "inner",
      offset: "-50%",
      content: "",

      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],

    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "18px",
        },
        customHtml: function customHtml() {
          return (
            <div>

              <div style={{ fontSize: "21px", fontWeight: "700" }}> {`${summaryOfStudent?.monthlySummary?.overallPercentage}`}%</div>
              <div style={{ fontSize: "14px", color: "#191919", fontWeight: "300", marginLeft: "5px", marginTop: "8px", fontFamily: "roboto" }}>Average</div>


            </div>)
        }

        // content: `${summaryOfStudent?.monthlySummary?.presentPercentage &&  summaryOfStudent?.monthlySummary?.absentPercentage  && summaryOfStudent?.monthlySummary?.overallPercentage}`+" "+"Average"
      },
    },

  };

  return <Pie {...config} />;
};

export default StudentDonutGraph;
