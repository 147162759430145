import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Typography } from 'antd';
import ViewAnnouncement from './modal/viewAnnouncement';
import moment from 'moment';
import HtmlParser from 'react-html-parser';
import ic_file_add from "../../Assets/images/ic_file_add.svg"


const { Text } = Typography
const Announced = styled.div`
    width:330px;
    height:115px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    box-shadow: 1px 1px 6px #302A5F14;
    margin: 10px 15px 15px 10px;
    &:hover {
        border: none; 
        box-shadow: 2px 3px 8px #c7cece;
    }

`;
const AnnouncedMain = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    align-items:center;
    justify-content:center;

`;

const DateAndTime = styled.div`
    width:90%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    margin-top:10px;
    margin-right:10px;
    margin-left:10px;
`;

const TitleAndDescription = styled.div`
    width:90%;
    display:flex;
    flex-direction:column;
    align-items:stretch;
    justify-content:center;

`;

const AnnouncedCard = ({ announcedItem, togglePageLoader, SetKey, setIsSearch, setSort, setSearch , setStdFilterValue}) => {
    const [viewModal, setViewModal] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false)
    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;

        for (var c = a.childNodes, i = c.length; i--;) {
            if (c[i].nodeType === 1) return true;
        }

        return false;
    }

    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    const htmlText = announcedItem?.description ? JSON.parse(announcedItem.description)?.mobile : ''
    return (
        <div>
            <Announced className='cursor-pointer' onClick={() => setViewModal(true)}>
                <AnnouncedMain>
                    {
                        announcedItem?.typeAnnouncement === 'file' ?
                            <>
                                <TitleAndDescription>
                                    <div>
                                        <Typography.Text ellipsis={{ tooltip: {tooltip:true, overlayClassName: "custom-tooltip"} }}>
                                            <div style={{ width: "250px", fontFamily: 'roboto', color: '#191919', fontSize: '19px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{announcedItem?.title}</div>
                                        </Typography.Text>
                                    </div>
                                    <div className='' style={{ paddingLeft: '10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {announcedItem?.fileName?.map((i) => (
                                            <div><img src={ic_file_add} />{i.key}</div>

                                        ))}</div>
                                </TitleAndDescription>
                                <DateAndTime>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "160px", marginTop: "12px" }}>
                                        <Text style={{ color: "#AEAEAE", marginRight: "5px" }}>{moment(announcedItem?.date, 'YYYY-MM-DD').format('DD/MM/YYYY')} </Text>
                                        <div style={{ width: "4px", height: "4px", backgroundColor: "grey", borderRadius: "50%" }}></div>
                                        <Text style={{ color: "#AEAEAE", marginRight: "5px" }}> {moment(announcedItem?.time, 'HH:mm:ss').format('hh:mm A')}</Text>
                                    </div>
                                    <Text style={{ color: "#AEAEAE", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: "10px" }}>{announcedItem?.target}</Text>

                                </DateAndTime>
                            </> :
                            <>

                                <TitleAndDescription>
                                    <Text ellipsis={{ tooltip: true }} style={{ fontFamily: 'roboto', color: '#191919', fontSize: '19px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 250, marginTop: "5px" }}>{announcedItem?.title}</Text>
                                    <div >
                                        <Typography.Text style={{ fontFamily: 'roboto', color: '#636363', fontSize: '14px', height: "45px", width: 250 }} ellipsis={{ tooltip: true }}>
                                            {!IsJsonString(JSON.parse(announcedItem?.description)?.mobile) && isHTML(JSON.parse(announcedItem?.description)?.mobile) ? HtmlParser(JSON.parse(announcedItem?.description)?.mobile) : HtmlParser(JSON.parse(announcedItem?.description)?.mobile)}
                                        </Typography.Text>
                                    </div>
                                </TitleAndDescription>
                                <DateAndTime>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "160px", marginTop: "-10px" }}>
                                        <Text style={{ color: "#AEAEAE", marginRight: "5px" }}>{moment(announcedItem?.date, 'YYYY-MM-DD').format('DD/MM/YYYY')} </Text>
                                        <div style={{ width: "4px", height: "4px", backgroundColor: "grey", borderRadius: "50%" }}></div>
                                        <Text style={{ color: "#AEAEAE", marginRight: "5px" }}> {moment(announcedItem?.time, 'HH:mm:ss').format('hh:mm A')}</Text>
                                    </div>
                                    <Text style={{ color: "#AEAEAE", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: "-10px" }}>{announcedItem?.target}</Text>

                                </DateAndTime>
                            </>


                    }

                </AnnouncedMain>
            </Announced>
            {(viewModal && <ViewAnnouncement isModalVisible={viewModal} setIsModalVisible={setViewModal} announcedData={announcedItem} togglePageLoader={togglePageLoader} SetKey={SetKey} setIsSearch={setIsSearch} setSort={setSort} setSearch={setSearch} setStdFilterValue={setStdFilterValue} />)}
        </div>
    )
}

export default AnnouncedCard