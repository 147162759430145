import { CloseCircleOutlined, MinusCircleOutlined, PlusCircleOutlined, FilePdfOutlined, QuestionCircleOutlined, ProfileOutlined, ClockCircleOutlined, UnlockOutlined, CloseOutlined, EditOutlined, LockOutlined, InfoCircleOutlined, SearchOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

import { UploadCourseDocument, deleteCourseDocument, getCoursesCategories, getCoursesSubCategories, getSpecificCourseData, getSpecificSMData, postCreateCategory, postCreateSubCategory, postLiveCourseCreate, postStudyMaterialsCreate, setCreateCourseObj, getSpecificVideoCourseData, postVideoResourceCreate, getOnlineTestSeriesCourseDetails, postOnlineTestSeries, getOnlineTestSeries, getTags, postCreateTags, storeSpecificCourseData, deleteQuiz, MergeChunk, uploadBlock2, fileKeyUpdate, updatepdf, getEditQuizData, storeQuizData, setQuizData } from 'actions/courseManagement';
import { getExaminer, setImportMeta } from 'actions/testAdmin';
import { AutoComplete, Checkbox, DatePicker, Input, Select, Space, Tag, Typography, Upload, message, Modal, Popover, Tooltip, Collapse } from 'antd';
import { Button } from 'components/Button';
import PageHeader from 'components/pageHeader';
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Api, Notification } from 'services';
import LiveCourseDatePicker from './liveCourseDatePickers';
import moment from 'moment';
import SuccessModal from 'pages/courseModule/successModal';
import PageDataLoader from 'components/pageDataLoader';
import { PDFDocument } from 'pdf-lib'
import { v4 as uuidv4 } from "uuid";

const blockSize = 1048576 * 20;

import video from '../../Assets/course/Video.png'
import createTestPic from '../../Assets/test/Test portal new-01.svg'

import quiz from '../../Assets/aulasMarket/ic-practice-quiz.svg'
import ConfirmPublishOrUnpublishModal from './confirmPublishOrUnpublishModal';
import { setIndividualTestId, setOnlineTestSeriesId } from 'actions/marketTest';
import CourseDeleteConfirmModal from 'pages/courseModule/courseDeleteConfirmModal';
import { Switch } from 'antd';
import './test.css'
import AddThumbnail from './addThumbnail';
import dragImage from '../../Assets/aulasMarket/Image 97.png'
import dragFile from '../../Assets/aulasMarket/Group 16705.svg'


const { Dragger } = Upload;
const { Option } = Select;
export const bodyStyle = {
    backgroundColor: "#FAFAFA",
    width: " calc(100% + 30px)",
    height: "calc(100% + 40px)",
    margin: "-20px",
    padding: "20px",
    overflow: "auto"
}
export const inputHeadingStyle = {
    font: "12px",
    color: "#636363",
    fontWeight: 600,
    fontFamily: "roboto",
}
const modeStyle = {
    fontWeight: 600,
    borderRadius: 4,
    height: 30,
    width: 90,
    marginRight: 20,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 13
}
const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
    ],
}

const tests = [
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
]

const ConfirmSaveDetailsModal = ({
    isConfirmSaveDetailsModalVisible,
    setConfirmSaveDetailsModalVisible,
    postOnlineTestSeries,
    subjects,
    handleBrowseClick,
    whichClick
}) => {

    const handleCancel = () => {
        setConfirmSaveDetailsModalVisible(false);
    };

    const [btnLoader, setBtnLoader] = useState(false);
    const history = useHistory();

    return (
        <>
            <Modal
                className="modal-round-corner"
                centered
                visible={isConfirmSaveDetailsModalVisible}
                onOk={handleCancel}
                onCancel={handleCancel}
                header={null}
                footer={null}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    <div
                        style={{
                            fontSize: "20px",
                            color: "#191919",
                            fontWeight: "bold",
                        }}
                    >
                        Save Changes
                    </div>
                    <div
                        style={{
                            fontSize: "16px",
                            color: "#636363",
                            fontFamily: "roboto",
                            marginTop: "10px",
                            textAlign: "center",
                        }}
                    >
                        Would you like to save test series details before proceeding further?
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "50px",
                        marginBottom: 20,
                    }}
                >
                    <Button
                        onClick={handleCancel}
                    >
                        NO
                    </Button>
                    <Button
                        loading={btnLoader}
                        type="primary"
                        onClick={() => {
                            setBtnLoader(true);

                            postOnlineTestSeries(
                                subjects,
                                location?.state?.key == "isFromEdit" ? true : false,
                                () => { setBtnLoader(false) },
                                () => {
                                    if (whichClick == "browse") handleBrowseClick()
                                    else history.push("/testSeries/createTest")
                                }
                            );
                        }}
                    >
                        YES
                    </Button>
                </div>
            </Modal>
        </>
    );
}



function AddCoursePage({ setImportMeta, setIndividualTestId, courseTypeName, setCreateCourseObj, courseCreateObj, getExaminer, getExaminerBool, getExaminerArr, getCoursesCategories, courseCategoryData, getCoursesSubCategories, courseSubCategoriesData, UploadCourseDocument, deleteCourseDocument, postLiveCourseCreate, getSpecificCourseData, specificCourseDataContainer, postCreateCategory, postCreateSubCategory,
    postStudyMaterialsCreate, getSpecificSMData, getSpecificVideoCourseData, postVideoResourceCreate, getOnlineTestSeriesCourseDetails, postOnlineTestSeries, storeOnlineTestData, getOnlineTestSeries, totalTagsData, getTags, storeOnlineTestDetails, MergeChunk, fileKeyUpdate, updatepdf, getEditQuizData, storeQuizData, Pdftoupload, postCreateTags, storeSpecificCourseData, deleteQuiz, uploadBlock2, fileKey }) {
    const [liveCourseLearningFields, setLiveCourseLearningFields] = useState([{ id: null, name: '' }]);
    const [liveCoursePrerequisitesFields, setLiveCoursePrerequisitesFields] = useState([{ id: null, name: '' }]);
    const [liveCourseIncludesFields, setLiveCourseIncludesFields] = useState([{ id: null, name: '' }]);
    const [activeKey, setActiveKey] = useState([]);
    const [customSubjects, setCustomSubjects] = useState([]);
    const [subjects, setSubjects] = useState([
        {
            id: null,
            name: '',
            chapters: [{ id: null, name: '' }],
        }
    ]);

    const [sections, setSections] = useState(
        [{
            name: '',
            topics: [{
                name: '',
                preview: false,
                fileIndex: 0,
                videoType: null,
                type: null,
                quizId: null,
                fileUrl: null,
                duration: null

            }]
        }]
    )
    useEffect(() => {
    }, [sections])
    let timer = false;
    const [handleRender, updateHandleRender] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchTextIndex, setSearchTextIndex] = useState('');
    const [courseStartDate, setCourseStartDate] = useState(null);
    const [courseEndDate, setCourseEndDate] = useState(null);
    const [newCourseEndDate, setNewCourseEndDate] = useState(null);
    const [renderEndDate, setRenderEndDate] = useState(false);
    const [isCreateBtnLoading, setCreateBtnLoading] = useState(false);
    const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
    let [CategoryListToShow, setCategoryListToShow] = useState([]);
    let [subCategoryListToShow, setSubCategoryListToShow] = useState([]);
    const [studyMaterialsTopics, setStudyMaterialsTopics] = useState([
        {
            name: "",
            file: null,
            fileIndex: 0,
            preview: false,
            type: "notes",
        }
    ]);
    const [discountType, setDiscountType] = useState("percent");
    const [SMfilesArray, setSMfilesArray] = useState([]);
    const [SMfileIndex, setSMfileIndex] = useState(-1);
    const [discountedPrice, setDiscountedPrice] = useState(null);
    const [deletedLearnings, setDeletedLearnings] = useState([]);
    const [deletedPrerequisites, setDeletedPrerequisites] = useState([]);
    const [deletedDetails, setDeletedDetails] = useState([]);
    const [increase, setIncrease] = useState(-1);
    const [tagsLoader, setTagsLoader] = useState(false);
    const [tagsList, setTagsList] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTag, setSearchTag] = useState(null);
    const [pageLoader, togglePageLoader] = useState(false);
    const [youtubeLink, setYouTubeLink] = useState('')
    const [progress, setProgress] = useState(0);
    const [isConfirmPublishModalVisible, setIsConfirmPublishModalVisible] = useState(false)
    const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
    const [isConfirmSaveDetailsModalVisible, setConfirmSaveDetailsModalVisible] = useState(false);
    const [whichClick, setWhichClick] = useState(null);
    const [defualThumbnail, setDefaultThumbnail] = useState(null)

    const location = useLocation();
    const [enteredTexts, setEnteredTexts] = useState([]);
    const [isToggled, setToggled] = useState(false);

    const onChangePremiun = (checked) => {
        setToggled(checked);
    };

    useEffect(() => {
        if (location?.state?.fromAddQuizPage) { }
        else {
            storeSpecificCourseData(null)
            getOnlineTestSeries()
        }
    }, [])
    useEffect(() => {
        fileKeyUpdate('')
    }, [])

    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

    useEffect(() => {
        if (location?.state?.fromVideoResourcesQuiz) {
            setSections(location?.state?.fromVideoResourcesQuiz?.quizUpdatedSections)
        }
        setTagsLoader(true)
        getTags(null, (tags) => { updateTagsList(tags) })
    }, [])


    const updateTagsList = (tags) => {
        setTagsList(tags?.map(item => Object.assign(item, { key: item.id, label: item.tag, value: item.id })))
        setTagsLoader(false)
    }
    const removeSelectedTags = (currTag) => {
        for (let i = 0; i < selectedTags?.length; i++) {
            if (currTag.id == selectedTags[i].id) {
                return false
            }
        }
        return true
    }

    const handleTagsSearch = (query) => {
        if (query?.trim().length > 0 || query?.trim().length === 0) {
            setTagsLoader(true)
            getTags(query, (tags) => updateTagsList(tags))
        }
    }
    const removeTag = (index) => {
        selectedTags.splice(index, 1);
        setSelectedTags([...selectedTags])
        setCreateCourseObj("tags", [...selectedTags])
        setTagsLoader(true)
        getTags("", (tags) => updateTagsList(tags))
    }
    useEffect(() => {
        if (courseCategoryData?.length != 0) {
            setCategoryListToShow(courseCategoryData)
        }
        else {
            setCategoryListToShow([])
        }
    }, [courseCategoryData])
    useEffect(() => {
        if (courseSubCategoriesData?.length != 0) {
            setSubCategoryListToShow(courseSubCategoriesData)
        }
        else {
            setSubCategoryListToShow([])
        }
    }, [courseSubCategoriesData])
    useEffect(() => {
        if (newCourseEndDate < courseStartDate) {
            setNewCourseEndDate(null);
        }
    }, [newCourseEndDate, courseStartDate]);
    useEffect(() => {
        if (renderEndDate) {
            const returnaDurationType = (val) => {
                if (val == "1") {
                    return "days";
                } else if (val == "30") {
                    return "months";
                } else if (val == "360") {
                    return "years";
                }
            };
            let temp = moment(courseStartDate)
                .add(courseCreateObj?.duration, returnaDurationType(courseCreateObj?.durationType))
                .subtract(1, "days")
                .format("DD-MM-YYYY");
            setCourseEndDate(temp !== NaN && temp != "Invalid date" ? temp : "");
            if (temp !== NaN && temp != "Invalid date") {
                setNewCourseEndDate(moment(temp, "DD-MM-YYYY").format("YYYY-MM-DD"));
                setCreateCourseObj("endDate", moment(temp, "DD-MM-YYYY").format("YYYY-MM-DD"));
            }
        }
    }, [courseStartDate, courseCreateObj?.duration, courseCreateObj?.durationType]);

    const clearValues = () => {
        setCreateCourseObj("id", null);
        setCreateCourseObj("mode", null);
        setCreateCourseObj("courseName", null);
        setCreateCourseObj("brief", null);
        setCreateCourseObj("courseDescription", null);
        setCreateCourseObj("image", null);
        setCreateCourseObj("difficulty", null);
        setCreateCourseObj("registrationFee", null);
        setCreateCourseObj("amount", null);
        setCreateCourseObj("discount", null);
        setCreateCourseObj("discountType", "percent");
        // setCreateCourseObj("discountType", "percentage");
        setCreateCourseObj("duration", null);
        setCreateCourseObj("durationType", null);
        setCreateCourseObj("startDate", null);
        setCreateCourseObj("endDate", null);
        setCreateCourseObj("teacherId", null);
        setCreateCourseObj("learnings", []);
        setCreateCourseObj("prerequisites", []);
        setCreateCourseObj("details", []);
        setCreateCourseObj("preview", null);
        setCreateCourseObj("previewLink", null);
        setCreateCourseObj("previewType", null);
        setCreateCourseObj("deletedCourseLearnings", []);
        setCreateCourseObj("examPattern", null);
        setCreateCourseObj("tags", []);
        setCreateCourseObj("premium", false);
        setCategoryListToShow([]);
        setSubCategoryListToShow([])
    }

    const returnDays = (val) => {
        if (val == "Day(s)") {
            return "1";
        } else if (val == "Month(s)") {
            return "30";
        } else if (val == "Year(s)") {
            return "360";
        }
    };
    useEffect(() => {
        if (location?.state?.fromBrodcastVR?.fromAddBroadcastPage) {
            setLiveCourseLearningFields(courseCreateObj?.learnings)
            setLiveCoursePrerequisitesFields(courseCreateObj?.prerequisites)
            setLiveCourseIncludesFields(courseCreateObj?.details)
            setSections(location?.state?.fromBrodcastVR?.sections)
            if (courseCreateObj?.discountType == "percent") {
                setDiscountedPrice(Math.round((courseCreateObj?.amount - (courseCreateObj?.amount * courseCreateObj?.discount) / 100) * 100) / 100)
            } else if (courseCreateObj?.discountType == "amount") {
                setDiscountedPrice(courseCreateObj?.amount - courseCreateObj?.discount)
            }
        }
    }, [location?.state?.fromBrodcastVR?.fromAddBroadcastPage])
    useEffect(() => {
        if (location?.state?.fromAddQuizPage) {
            setLiveCourseLearningFields(location?.state?.liveCourseLearningFields)
            setLiveCoursePrerequisitesFields(location?.state?.liveCoursePrerequisitesFields)
            setLiveCourseIncludesFields(location?.state?.liveCourseIncludesFields);
            setSelectedTags([...courseCreateObj?.tags]);
            if (courseTypeName == "studyMaterials") {
                const lastItem = location.state.retrieveQuizUpdatedTopics.slice(-1)[0];
                if (lastItem.quizId == null) {
                    location.state.retrieveQuizUpdatedTopics.pop();
                }
                location.state.retrieveQuizUpdatedTopics?.filter((item) => !item)
                setSMfileIndex(location?.state?.SMfileIndex)
                setSMfilesArray([...location?.state?.SMfilesArray])
                setStudyMaterialsTopics(location?.state?.retrieveQuizUpdatedTopics)
            } else {
                if (location.state.retrieveQuizUpdatedTopics.length > 0) {
                    const lastItem = location.state.retrieveQuizUpdatedTopics.slice(-1)[0];
                    if (lastItem.topics && lastItem.topics.length > 0 && lastItem.topics.slice(-1)[0].quizId === null) {
                        lastItem.topics.pop();
                    }
                }

                // location?.state?.retrieveQuizUpdatedTopics?.forEach(section => {
                //     if (section?.topics && section?.topics?.length > 0 && section?.topics[section?.topics?.length - 1]?.quizId === null) {
                //         section?.topics.pop();
                //     }
                // });
                setIncrease(location?.state?.increase)
                setSections(location?.state?.retrieveQuizUpdatedTopics)
            }
            if (courseCreateObj?.discountType == "percent") {
                setDiscountedPrice(Math.round((courseCreateObj?.amount - (courseCreateObj?.amount * courseCreateObj?.discount) / 100) * 100) / 100)
            } else if (courseCreateObj?.discountType == "amount") {
                setDiscountedPrice(courseCreateObj?.amount - courseCreateObj?.discount)
            }
        }
    }, [location?.state?.fromAddQuizPage])


    useEffect(() => {
        if ((location?.state?.key?.isEdit == "isFromEdit" && courseTypeName == "liveCourses")) {
            togglePageLoader(true);
            getSpecificCourseData(() => togglePageLoader(false));
            setCreateCourseObj("id", location?.state?.key?.isClone ? null : specificCourseDataContainer?.course?.id ? specificCourseDataContainer?.course?.id : null);
            setCreateCourseObj("mode", specificCourseDataContainer?.course?.mode);
            setCreateCourseObj("courseName", specificCourseDataContainer?.course?.courseName);
            setCreateCourseObj("brief", specificCourseDataContainer?.course?.brief);
            setCreateCourseObj("courseDescription", specificCourseDataContainer?.course?.courseDescription);
            setCreateCourseObj("image", specificCourseDataContainer?.course?.image);
            setCreateCourseObj("categoryId", { name: specificCourseDataContainer?.course?.category?.parent?.name, id: specificCourseDataContainer?.course?.category?.parent?.id });
            setCreateCourseObj("subcategoryId", { name: specificCourseDataContainer?.course?.category?.name, id: specificCourseDataContainer?.course?.category?.id });
            setCreateCourseObj("difficulty", specificCourseDataContainer?.course?.difficulty);
            setCreateCourseObj("amount", specificCourseDataContainer?.course?.amount);
            setCreateCourseObj("registrationFee", specificCourseDataContainer?.course?.registrationFee);
            setCreateCourseObj("discountType", specificCourseDataContainer?.course?.discountType);
            setCreateCourseObj("discount", specificCourseDataContainer?.course?.discount);
            setDiscountType(specificCourseDataContainer?.course?.discountType);
            if (specificCourseDataContainer?.course?.discountType == "percent") {
                setDiscountedPrice(Math.round((specificCourseDataContainer?.course?.amount - (specificCourseDataContainer?.course?.amount * specificCourseDataContainer?.course?.discount) / 100) * 100) / 100)
            } else if (specificCourseDataContainer?.course?.discountType == "amount") {
                setDiscountedPrice(specificCourseDataContainer?.course?.amount - specificCourseDataContainer?.course?.discount)
            }
            { console.log("specificCourseDataContainer234", specificCourseDataContainer) }
            setCreateCourseObj("duration", specificCourseDataContainer?.course?.duration);
            setCreateCourseObj("durationType", returnDays(specificCourseDataContainer?.course?.durationType));
            setCreateCourseObj("startDate", specificCourseDataContainer?.course?.startDate);
            setCreateCourseObj("endDate", specificCourseDataContainer?.course?.endDate);
            setCourseStartDate(specificCourseDataContainer?.course?.startDate);
            setNewCourseEndDate(specificCourseDataContainer?.course?.endDate);
            setCreateCourseObj("learnings", specificCourseDataContainer?.course?.learnings);
            setCreateCourseObj("prerequisites", specificCourseDataContainer?.course?.prerequisites);
            setCreateCourseObj("details", specificCourseDataContainer?.course?.details);
            setCreateCourseObj("premium", specificCourseDataContainer?.course?.premium);

            const learningsTemp = (specificCourseDataContainer?.course?.learnings || [])?.map((learning) => ({
                id: learning?.id,
                name: learning?.name,
            }));
            setLiveCourseLearningFields(specificCourseDataContainer?.course?.learnings?.length > 0 ? learningsTemp : [{ id: null, name: '' }]);
            const prerequisitesTemp = (specificCourseDataContainer?.course?.prerequisites || [])?.map((prerequisite) => ({
                id: prerequisite?.id,
                name: prerequisite?.name,
            }));
            setLiveCoursePrerequisitesFields(specificCourseDataContainer?.course?.prerequisites?.length > 0 ? prerequisitesTemp : [{ id: null, name: '' }]);
            let selectedTagsName = (specificCourseDataContainer?.course?.tags || [])?.map((tags) => ({
                id: tags?.id,
                value: tags?.tag,
            }))
            setSelectedTags(selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("tags", selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("previewType", specificCourseDataContainer?.course?.previewType);
            if (specificCourseDataContainer?.course?.previewType == "file") {
                setCreateCourseObj("preview", specificCourseDataContainer?.course?.preview);
            } else {
                setCreateCourseObj("previewLink", specificCourseDataContainer?.course?.preview);
            }
            const subjectsTemp = specificCourseDataContainer?.course?.subjects?.map((subject) => {
                const newSubject = {
                    name: subject.name,
                    chapters: subject.courseChapter?.length > 0
                        ? subject.courseChapter.map((chapter) => ({
                            id: chapter.id,
                            name: chapter.name,
                        }))
                        : [{ id: null, name: '' }],
                };
                return newSubject;
            });
            setSubjects(specificCourseDataContainer?.course?.subjects?.length > 0 ? subjectsTemp : [
                {
                    name: '',
                    chapters: [{ id: null, name: '' }],
                }
            ]);
        } else if ((location?.state?.key?.isEdit == "isFromEdit" && courseTypeName == "studyMaterials" && !location?.state?.fromAddQuizPage)) {
            togglePageLoader(true);
            getSpecificSMData(() => togglePageLoader(false))
            setCreateCourseObj("id", specificCourseDataContainer?.id ? specificCourseDataContainer?.id : null);
            setCreateCourseObj("courseName", specificCourseDataContainer?.name);
            setCreateCourseObj("courseDescription", specificCourseDataContainer?.description);
            setCreateCourseObj("categoryId", { name: specificCourseDataContainer?.category?.parent?.name, id: specificCourseDataContainer?.category?.parent?.id });
            setCreateCourseObj("subcategoryId", { name: specificCourseDataContainer?.category?.name, id: specificCourseDataContainer?.category?.id });
            setCreateCourseObj("difficulty", specificCourseDataContainer?.difficulty);
            setCreateCourseObj("amount", specificCourseDataContainer?.amount);
            setCreateCourseObj("discountType", specificCourseDataContainer?.discountType);
            setCreateCourseObj("discount", specificCourseDataContainer?.discount);
            setCreateCourseObj("learnings", specificCourseDataContainer?.learnings);

            setCreateCourseObj("premium", specificCourseDataContainer?.premium);

            setDiscountType(specificCourseDataContainer?.discountType);
            if (specificCourseDataContainer?.discountType == "percent") {
                setDiscountedPrice(Math.round((specificCourseDataContainer?.amount - (specificCourseDataContainer?.amount * specificCourseDataContainer?.discount) / 100) * 100) / 100)
            } else if (specificCourseDataContainer?.discountType == "amount") {
                setDiscountedPrice(specificCourseDataContainer?.amount - specificCourseDataContainer?.discount)
            }
            const learningsTemp = (specificCourseDataContainer?.learnings || [])?.map((learning) => ({
                id: learning?.id,
                name: learning?.name,
            }));
            setLiveCourseLearningFields(specificCourseDataContainer?.learnings?.length > 0 ? learningsTemp : [{ id: null, name: '' }]);
            let selectedTagsName = totalTagsData
                ?.filter((item) => specificCourseDataContainer?.tags?.some((tag) => tag.id === item.id))
                ?.map((item) => ({
                    id: item?.id,
                    value: item?.tag,
                }));
            setSelectedTags(selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("tags", selectedTagsName ? [...selectedTagsName] : []);
            const topicsTemp = specificCourseDataContainer?.topics?.map((topic, index) => {
                const newTopic = {
                    name: topic?.name,
                    file: topic?.file,
                    fileIndex: !topic?.quizId ? 0 : null,
                    preview: topic?.preview,
                    id: topic?.id,
                    pageCount: topic?.pageCount,
                    fileUrl: topic?.file?.url,
                    type: topic?.type,
                    quizId: topic?.quizId
                };
                return newTopic;
            });
            setStudyMaterialsTopics(specificCourseDataContainer?.topics?.length > 0 ? topicsTemp : [
                {
                    name: "",
                    file: null,
                    fileIndex: 0,
                    preview: false,
                    type: "notes",
                }
            ]);
        } else if ((location?.state?.key?.isEdit == 'isFromEdit' && courseTypeName == "videoResources" && !location?.state?.fromAddQuizPage)) {
            togglePageLoader(true);
            getSpecificVideoCourseData(() => togglePageLoader(false))
            setCreateCourseObj("id", specificCourseDataContainer?.id ? specificCourseDataContainer?.id : null);
            setCreateCourseObj("courseName", specificCourseDataContainer?.name);
            setCreateCourseObj("brief", specificCourseDataContainer?.brief);
            setCreateCourseObj("courseDescription", specificCourseDataContainer?.description);
            setCreateCourseObj("image", specificCourseDataContainer?.image);
            setCreateCourseObj("categoryId", { name: specificCourseDataContainer?.category?.parent?.name, id: specificCourseDataContainer?.category?.parent?.id });
            setCreateCourseObj("subcategoryId", { name: specificCourseDataContainer?.category?.name, id: specificCourseDataContainer?.category?.id });
            setCreateCourseObj("difficulty", specificCourseDataContainer?.difficulty);
            setCreateCourseObj("amount", specificCourseDataContainer?.amount);
            setCreateCourseObj("discountType", specificCourseDataContainer?.discountType);
            setCreateCourseObj("discount", specificCourseDataContainer?.discount);
            setCreateCourseObj("learnings", specificCourseDataContainer?.learnings);
            setCreateCourseObj("prerequisites", specificCourseDataContainer?.prerequisites);
            setCreateCourseObj("details", specificCourseDataContainer?.details);
            { console.log("specificCourseDataContainer209", specificCourseDataContainer) }

            setCreateCourseObj("premium", specificCourseDataContainer?.premium);

            setCreateCourseObj("teacherId", { name: specificCourseDataContainer?.teacher?.name, id: specificCourseDataContainer?.teacher?.id });
            setDiscountType(specificCourseDataContainer?.discountType);
            if (specificCourseDataContainer?.discountType == "percent") {
                setDiscountedPrice(Math.round((specificCourseDataContainer?.amount - (specificCourseDataContainer?.amount * specificCourseDataContainer?.discount) / 100) * 100) / 100)
            } else if (specificCourseDataContainer?.discountType == "amount") {
                setDiscountedPrice(specificCourseDataContainer?.amount - specificCourseDataContainer?.discount)
            }
            const learningsTemp = (specificCourseDataContainer?.learnings || [])?.map((learning) => ({
                id: learning?.id,
                name: learning?.name,
            }));
            setLiveCourseLearningFields(specificCourseDataContainer?.learnings?.length > 0 ? learningsTemp : [{ id: null, name: '' }]);
            const prerequisitesTemp = (specificCourseDataContainer?.prerequisites || [])?.map((prerequisite) => ({
                id: prerequisite?.id,
                name: prerequisite?.name,
            }));
            setLiveCoursePrerequisitesFields(specificCourseDataContainer?.prerequisites?.length > 0 ? prerequisitesTemp : [{ id: null, name: '' }]);
            const detailsTemp = (specificCourseDataContainer?.details || [])?.map((detail) => ({
                id: detail?.id,
                name: detail?.name,
            }));
            setLiveCourseIncludesFields(specificCourseDataContainer?.details?.length > 0 ? detailsTemp : [{ id: null, name: '' }]);
            let selectedTagsName = (specificCourseDataContainer?.tags || [])?.map((tags) => ({
                id: tags?.id,
                value: tags?.tag,
            }))
            setSelectedTags(selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("tags", selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("previewType", specificCourseDataContainer?.previewType);
            if (specificCourseDataContainer?.previewType == "file") {
                setCreateCourseObj("preview", specificCourseDataContainer?.preview);
            } else {
                setCreateCourseObj("previewLink", specificCourseDataContainer?.preview);
            }
            const sectionTemp = specificCourseDataContainer?.sections?.map((section) => {
                const newTopic = {
                    name: section?.name,
                    id: section?.id,
                    topics: section?.topics?.map((topic, index) => ({
                        id: topic?.id,
                        name: topic?.name,
                        preview: topic?.preview,
                        fileIndex: !topic?.quizId ? index : null,
                        videoType: topic?.videoType,
                        fileUrl: topic?.fileUrl,
                        duration: topic?.duration,
                        type: topic?.type,
                        quizId: topic?.quizId,
                        fileName: topic.fileName,
                        pageCount: topic?.pageCount,



                    })),
                };
                return newTopic;
            });
            setSections(specificCourseDataContainer?.sections?.length > 0 ? sectionTemp : [
                {
                    name: '',
                    topics: [{
                        name: '',
                        preview: false,
                        fileIndex: 0,
                        videoType: null,
                        type: null,
                        quizId: null,
                        fileUrl: null,
                        duration: null,
                        type: null
                    }]
                }
            ]);

        } else if (location?.state?.key?.isEdit == 'isFromEdit' && courseTypeName == "onlineTestSeries") {
            togglePageLoader(true);
            getOnlineTestSeriesCourseDetails(() => togglePageLoader(false))
            setCreateCourseObj("id", specificCourseDataContainer?.testSeriesDetails?.id ? specificCourseDataContainer?.testSeriesDetails?.id : null);
            setCreateCourseObj("courseName", specificCourseDataContainer?.testSeriesDetails?.name);
            // setCreateCourseObj("brief", specificCourseDataContainer?.brief);
            setCreateCourseObj("courseDescription", specificCourseDataContainer?.testSeriesDetails?.description);
            setCreateCourseObj("examPattern", specificCourseDataContainer?.testSeriesDetails?.examPattern);
            // setCreateCourseObj("image", specificCourseDataContainer?.image);
            setCreateCourseObj("categoryId", { name: specificCourseDataContainer?.testSeriesDetails?.category?.parent?.name, id: specificCourseDataContainer?.testSeriesDetails?.category?.parent?.id });
            setCreateCourseObj("subcategoryId", { name: specificCourseDataContainer?.testSeriesDetails?.category?.name, id: specificCourseDataContainer?.testSeriesDetails?.category?.id });
            setCreateCourseObj("difficulty", specificCourseDataContainer?.testSeriesDetails?.difficulty);
            setCreateCourseObj("amount", specificCourseDataContainer?.testSeriesDetails?.amount);
            setCreateCourseObj("discountType", specificCourseDataContainer?.testSeriesDetails?.discountType);
            setCreateCourseObj("discount", specificCourseDataContainer?.testSeriesDetails?.discount);
            setCreateCourseObj("premium", specificCourseDataContainer?.testSeriesDetails?.premium);
            setDiscountType(specificCourseDataContainer?.testSeriesDetails?.discountType === 'percentage' ? 'percent' : specificCourseDataContainer?.testSeriesDetails?.discountType);
            let selectedTagsName = totalTagsData
                ?.filter((item) => specificCourseDataContainer?.tags?.some((tag) => tag.id === item.id))
                ?.map((item) => ({
                    id: item?.id,
                    value: item?.tag,
                }));
            setSelectedTags(selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("tags", selectedTagsName ? [...selectedTagsName] : []);
            if (specificCourseDataContainer?.testSeriesDetails?.discountType == "percentage") {
                setDiscountedPrice(Math.round((specificCourseDataContainer?.testSeriesDetails?.amount - (specificCourseDataContainer?.testSeriesDetails?.amount * specificCourseDataContainer?.testSeriesDetails?.discount) / 100) * 100) / 100)
            } else if (specificCourseDataContainer?.testSeriesDetails?.discountType == "amount") {
                setDiscountedPrice(specificCourseDataContainer?.testSeriesDetails?.amount - specificCourseDataContainer?.testSeriesDetails?.discount)
            }
            const subjectsTemp = specificCourseDataContainer?.testSeriesDetails?.subjectTestSeries?.map((subject) => {
                const newSubject = {
                    id: subject.subjectId,
                    name: subject.subject.name,
                    chapters: subject.subject.testSeriesChapter.length > 0 ? subject.subject.testSeriesChapter.map((chapter) => ({
                        id: chapter.id,
                        name: chapter.name,
                    })) : [{ id: null, name: '' }],
                };
                return newSubject;
            });

            setSubjects(specificCourseDataContainer?.testSeriesDetails?.subjectTestSeries?.length > 0 ? subjectsTemp : [
                {
                    name: '',
                    chapters: [{ id: null, name: '' }],
                }
            ]);





            const tagsTemp = specificCourseDataContainer?.tags?.map((subject) => {
                const newTags = {
                    id: subject.id,
                    tag: subject.tag
                };
                return newTags;
            });


        } else if (location?.state?.key?.isEdit == 'isFromEdit' && courseTypeName == "onlineTestSeries" && location?.state?.key?.fromQuestionPage) {
            console.log('hhhh', specificCourseDataContainer);
            togglePageLoader(false);
            getOnlineTestSeriesCourseDetails(() => togglePageLoader(false))
            setCreateCourseObj("id", specificCourseDataContainer?.testSeriesDetails?.id ? specificCourseDataContainer?.testSeriesDetails?.id : null);
            setCreateCourseObj("courseName", specificCourseDataContainer?.testSeriesDetails?.name);
            // setCreateCourseObj("brief", specificCourseDataContainer?.brief);
            setCreateCourseObj("courseDescription", specificCourseDataContainer?.testSeriesDetails?.description);
            setCreateCourseObj("examPattern", specificCourseDataContainer?.testSeriesDetails?.examPattern);
            // setCreateCourseObj("image", specificCourseDataContainer?.image);
            setCreateCourseObj("categoryId", { name: specificCourseDataContainer?.testSeriesDetails?.category?.parent?.name, id: specificCourseDataContainer?.testSeriesDetails?.category?.parent?.id });
            setCreateCourseObj("subcategoryId", { name: specificCourseDataContainer?.testSeriesDetails?.category?.name, id: specificCourseDataContainer?.testSeriesDetails?.category?.id });
            setCreateCourseObj("difficulty", specificCourseDataContainer?.testSeriesDetails?.difficulty);
            setCreateCourseObj("amount", specificCourseDataContainer?.testSeriesDetails?.amount);
            setCreateCourseObj("discountType", specificCourseDataContainer?.testSeriesDetails?.discountType);
            setCreateCourseObj("discount", specificCourseDataContainer?.testSeriesDetails?.discount);
            setCreateCourseObj("premium", specificCourseDataContainer?.testSeriesDetails?.premium);

            setDiscountType(specificCourseDataContainer?.testSeriesDetails?.discountType);
            let selectedTagsName = totalTagsData
                ?.filter((item) => specificCourseDataContainer?.tags?.some((tag) => tag.id === item.id))
                ?.map((item) => ({
                    id: item?.id,
                    value: item?.tag,
                }));
            setSelectedTags(selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("tags", selectedTagsName ? [...selectedTagsName] : []);

            if (specificCourseDataContainer?.testSeriesDetails?.discountType == "percentage") {
                setDiscountedPrice(Math.round((specificCourseDataContainer?.testSeriesDetails?.amount - (specificCourseDataContainer?.testSeriesDetails?.amount * specificCourseDataContainer?.testSeriesDetails?.discount) / 100) * 100) / 100)
            } else if (specificCourseDataContainer?.testSeriesDetails?.discountType == "amount") {
                setDiscountedPrice(specificCourseDataContainer?.testSeriesDetails?.amount - specificCourseDataContainer?.testSeriesDetails?.discount)
            }
            const subjectsTemp = specificCourseDataContainer?.testSeriesDetails?.subjectTestSeries?.map((subject) => {
                const newSubject = {
                    id: subject.subjectId,
                    name: subject.subject.name,
                    chapters: subject.subject.testSeriesChapter.length > 0 ? subject.subject.testSeriesChapter.map((chapter) => ({
                        id: chapter.id,
                        name: chapter.name,
                    })) : [{ id: null, name: '' }],
                };
                return newSubject;
            });

            setSubjects(specificCourseDataContainer?.testSeriesDetails?.subjectTestSeries?.length > 0 ? subjectsTemp : [
                {
                    name: '',
                    chapters: [{ id: null, name: '' }],
                }
            ]);





            const tagsTemp = specificCourseDataContainer?.tags?.map((subject) => {
                const newTags = {
                    id: subject.id,
                    tag: subject.tag
                };
                return newTags;
            });


        } else if (!location?.state?.fromAddQuizPage && !location?.state?.fromBrodcastVR?.fromAddBroadcastPage) {

            clearValues();
            setCreateCourseObj("categoryId", null);
            setCreateCourseObj("subcategoryId", null);
        }
        getCoursesCategories()


        getExaminer('');
        if (courseTypeName == "videoResources") {
            getExaminer('');
        }
    }, [specificCourseDataContainer?.course?.id, specificCourseDataContainer?.id, specificCourseDataContainer?.testSeriesDetails?.id])

    const [reload, setReload] = useState(false)

    useEffect(() => {
        setReload(!reload)
    }, [])

    useEffect(() => {
        console.log("iamhere1", location?.state?.key, location?.state?.key?.fromCourseCard?.isCoursesCardsVisible, location?.state?.key?.fromCourseCard?.selectedCategory?.name, location?.state?.key?.fromCourseCard?.selectedSubCategory?.name)
        if (location?.state?.key?.fromSubcategory?.isSubCategoryVisible) {
            setCreateCourseObj("categoryId", { name: location?.state?.key?.fromSubcategory?.selectedCategory?.name ? location?.state?.key?.fromSubcategory?.selectedCategory?.name : null, id: location?.state?.key?.fromSubcategory?.selectedCategory?.id ? location?.state?.key?.fromSubcategory?.selectedCategory?.id : null });
            getCoursesSubCategories(location?.state?.key?.fromSubcategory?.selectedCategory?.id)
        }
        if (location?.state?.key?.fromCourseCard?.isCoursesCardsVisible) {
            setCreateCourseObj("categoryId", { name: location?.state?.key?.fromCourseCard?.selectedCategory?.name ? location?.state?.key?.fromCourseCard?.selectedCategory?.name : null, id: location?.state?.key?.fromCourseCard?.selectedCategory?.id ? location?.state?.key?.fromCourseCard?.selectedCategory?.id : null });
            setCreateCourseObj("subcategoryId", { name: location?.state?.key?.fromCourseCard?.selectedSubCategory?.name ? location?.state?.key?.fromCourseCard?.selectedSubCategory?.name : null, id: location?.state?.key?.fromCourseCard?.selectedSubCategory?.id ? location?.state?.key?.fromCourseCard?.selectedSubCategory?.id : null });
            setSubCategoryListToShow(courseSubCategoriesData)
        }
    }, [location?.state?.key?.fromSubcategory?.selectedCategory?.name, location?.state?.key?.fromCourseCard?.selectedSubCategory?.name, reload])

    useEffect(() => {
        if (handleRender) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                if (searchTextIndex === 'examiner') {
                    getExaminer(searchText)
                    updateHandleRender(false);
                }
                else {
                    updateHandleRender(false);
                }
            }, 800);
        }
    }, [searchText, searchTextIndex, handleRender])
    const history = useHistory();
    let disableCreateUpdateBtn = true;
    if (courseTypeName == "liveCourses" && (!courseCreateObj?.mode || !courseCreateObj?.courseName || !courseCreateObj?.brief || !courseCreateObj?.courseDescription || !courseCreateObj?.image || !courseCreateObj?.categoryId || !courseCreateObj?.subcategoryId || !courseCreateObj?.difficulty || !courseCreateObj?.duration || !courseCreateObj?.durationType)) {
        disableCreateUpdateBtn = true
    } else if (courseTypeName == "studyMaterials" && (!courseCreateObj?.courseName || !courseCreateObj?.courseDescription || !courseCreateObj?.categoryId || !courseCreateObj?.subcategoryId || !courseCreateObj?.difficulty)) {
        disableCreateUpdateBtn = true
    } else if (courseTypeName == "videoResources" && (!courseCreateObj?.teacherId || !courseCreateObj?.courseName || !courseCreateObj?.brief || !courseCreateObj?.courseDescription || !courseCreateObj?.image || !courseCreateObj?.categoryId || !courseCreateObj?.subcategoryId || !courseCreateObj?.difficulty)) {
        disableCreateUpdateBtn = true
    }
    else if (courseTypeName == "onlineTestSeries" && (!courseCreateObj?.courseName || !courseCreateObj?.courseDescription || !courseCreateObj?.categoryId || !courseCreateObj?.subcategoryId || !courseCreateObj?.difficulty || !courseCreateObj?.subcategoryId?.name)) {
        disableCreateUpdateBtn = true
    }
    else {
        disableCreateUpdateBtn = false
    }

    let disableCreateTestBtn = !subjects.some(
        (subject) =>
            subject.chapters.length > 0 &&
            subject.chapters.some((chapter) => chapter.name)
    );
    const menu = (
        <div onClick={(e) => { e.stopPropagation() }}>
            <div style={{ width: "110px", color: "#636363", fontSize: 12, fontWeight: "bold" }}>Free preview is availaible only for YouTube link</div>
        </div>
    );
    const onPageHeaderCancel = () => {
        if (location?.state?.key?.fromCourseDetails) {
            history.push({
                pathname: "/course-details",
                state: {
                    key: {
                        fromCourseCard: location?.state?.key?.fromCourseCard,
                        fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                        searchedValue: location?.state?.key?.searchedValue
                    }
                }
            })
        } else {
            history.push(courseTypeName == "liveCourses" ?
                {
                    pathname: "/live-courses-data", state: {
                        key: {
                            fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                            searchedValue: location?.state?.key?.searchedValue,
                            fromSubcategory: location?.state?.key?.fromSubcategory,
                            fromCourseCard: location?.state?.key?.fromCourseCard,
                            fromDashboardSearch: location?.state?.key?.fromDashboard
                        }
                    }
                } :
                courseTypeName == "videoResources" ?
                    {
                        pathname: "/video-resources-data", state: {
                            key: {
                                fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                searchedValue: location?.state?.key?.searchedValue,
                                fromSubcategory: location?.state?.key?.fromSubcategory,
                                fromCourseCard: location?.state?.key?.fromCourseCard,
                                fromDashboardSearch: location?.state?.key?.fromDashboard
                            }
                        }
                    } :
                    courseTypeName == "onlineTestSeries" ?
                        {
                            pathname: "/online-test-series-details", state: {
                                key: {
                                    fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                    searchedValue: location?.state?.key?.searchedValue,
                                    fromSubcategory: location?.state?.key?.fromSubcategory,
                                    fromCourseCard: location?.state?.key?.fromCourseCard,
                                    fromDashboardSearch: location?.state?.key?.fromDashboard
                                }
                            }
                        } :
                        courseTypeName == "studyMaterials" ?
                            {
                                pathname: "/study-materials-data", state: {
                                    key: {
                                        fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                        searchedValue: location?.state?.key?.searchedValue,
                                        fromSubcategory: location?.state?.key?.fromSubcategory,
                                        fromCourseCard: location?.state?.key?.fromCourseCard,
                                        fromDashboardSearch: location?.state?.key?.fromDashboard
                                    }
                                }
                            }
                            : "/live-courses-data"
            );
        }
        clearValues();
        storeSpecificCourseData([])
    }

    const actions = [
        (location?.state?.key?.isEdit == 'isFromEdit' ?
            <Button type="link" style={{ padding: 0, marginRight: 20, fontSize: 16 }}
                onClick={onPageHeaderCancel}>
                CANCEL</Button> : <></>),
        // <Button disabled={true} style={{ marginRight: 20 }}>PREVIEW</Button>,
        <Button
            loading={isCreateBtnLoading}
            disabled={disableCreateUpdateBtn}
            type="primary"
            style={{}} icon={<PlusCircleOutlined />}
            onClick={() => {
                setCreateCourseObj("deletedCourseLearnings", deletedLearnings);
                setCreateCourseObj("deletedCoursePrerequisites", deletedPrerequisites);
                setCreateCourseObj("deletedCourseDetails", deletedDetails);
                if (courseTypeName == "studyMaterials") {
                    setCreateBtnLoading(true);
                    postStudyMaterialsCreate(
                        studyMaterialsTopics,
                        SMfilesArray,
                        () => { },
                        () => {
                            setCreateBtnLoading(false)
                            if (location?.state?.key?.isEdit == "isFromEdit") {
                                history.push({
                                    pathname: "/course-details",
                                    state: {
                                        key: {
                                            fromCourseCard: location?.state?.key?.fromCourseCard
                                        }
                                    }
                                })
                            } else {
                                setSuccessModalVisible(true);
                                clearValues();
                                setStudyMaterialsTopics([{
                                    name: "",
                                    file: null,
                                    fileIndex: 0,
                                    preview: false,
                                    type: "notes",
                                }]);
                            }
                        }
                    )
                } else if (courseTypeName == 'videoResources') {
                    setCreateBtnLoading(true);
                    postVideoResourceCreate(
                        sections,
                        videoFile,
                        () => { setCreateBtnLoading(false) },
                        () => {
                            if (location?.state?.key?.isEdit == "isFromEdit") {
                                history.push({
                                    pathname: "/course-details",
                                    state: {
                                        key: {
                                            fromCourseCard: location?.state?.key?.fromCourseCard
                                        }
                                    }
                                })
                            } else {
                                setSuccessModalVisible(true);
                                clearValues();
                                setSections([{
                                    name: '',
                                    topics: [{
                                        name: '',
                                        preview: false,
                                        fileIndex: 0,
                                        videoType: null,
                                        type: null,
                                        quizId: null,
                                        fileUrl: null,
                                        duration: null
                                    }]
                                }]);
                            }

                        }
                    )
                }
                else if (courseTypeName == 'onlineTestSeries') {
                    let hasError = false;
                    for (const subject of subjects) {
                        if (subject.chapters.some(chapter => chapter.name && !subject.name)) {
                            hasError = true;
                            Notification.error("Error", 'Please fill the subject name for chapters with filled names.');
                            break;
                        }
                    }
                    if (!hasError) {
                        setCreateBtnLoading(true);
                        postOnlineTestSeries(
                            subjects,
                            (location?.state?.key?.isEdit == "isFromEdit") ? true : false,
                            () => { setCreateBtnLoading(false) },
                            () => {
                                if (location?.state?.key?.isEdit == "isFromEdit") {
                                    history.push({
                                        pathname: "/course-details",
                                        state: {
                                            key: {
                                                fromCourseCard: location?.state?.key?.fromCourseCard
                                            }
                                        }
                                    })
                                } else {
                                    setSuccessModalVisible(true);
                                    clearValues();
                                    setSubjects([{
                                        id: null,
                                        name: '',
                                        chapters: [{ id: null, name: '' }],
                                    }]);
                                }
                            }
                        );
                    }
                }
                else {
                    let hasError = false;
                    for (const subject of subjects) {
                        if (subject.chapters.some(chapter => chapter.name && !subject.name)) {
                            hasError = true;
                            Notification.error("Error", 'Please fill the subject name for chapters with filled names.');
                            break;
                        }
                    }
                    if (!hasError) {
                        setCreateBtnLoading(true);
                        postLiveCourseCreate(
                            subjects,
                            () => { setCreateBtnLoading(false) },
                            () => {
                                if (location?.state?.key?.isEdit == "isFromEdit") {
                                    history.push({
                                        pathname: "/course-details",
                                        state: {
                                            key: {
                                                fromCourseCard: location?.state?.key?.fromCourseCard
                                            }
                                        }
                                    })
                                } else {
                                    setSuccessModalVisible(true);
                                    clearValues();
                                    setSubjects([{
                                        name: '',
                                        chapters: [{ id: null, name: '' }],
                                    }]);
                                }
                            }
                        );
                    }
                }
            }}
        >
            {location?.state?.key?.isEdit == "isFromEdit" ? "UPDATE" : "CREATE"}</Button>,
    ]
    let quillReff = null;
    let quillExam = null
    var details = {}
    const handleDescription = (content, delta, source, editor) => {
        if (source == 'user') {
            details = {
                mobile: quillReff.getEditorContents(),
                web: quillReff.editor.getContents()
            }
            setCreateCourseObj("courseDescription", JSON.stringify(details))
        }
    }

    let quillBrief = null
    var briefDetails = {}

    const handleBrief = (content, delta, source, editor) => {
        if (source == 'user') {
            briefDetails = {
                mobile: quillBrief.getEditorContents(),
                web: quillBrief.editor.getContents()
            }
            setCreateCourseObj("brief", JSON.stringify(briefDetails))
        }
    }

    const customPanelStyle = {
        background: '#fff',
        border: '1px solid #d9d9d9',
        borderRadius: 4,
        marginBottom: 15,
        overflow: 'hidden',
    };

    const handleDescriptionkeyUp = (e) => {
        if (quillReff.getEditorContents() != '<p><br></p>' && quillReff.editor.getText().trim() != '') {
            details = {
                mobile: quillReff.getEditorContents(),
                web: quillReff.editor.getContents()
            }
            setCreateCourseObj("courseDescription", JSON.stringify(details))
        }
        else {
            setCreateCourseObj("courseDescription", '')
        }
    }

    const handleCourseBrief = (e) => {
        if (e.key === ' ') {
            // Allow the default spacebar behavior
            return;
        }
        if (quillBrief.getEditorContents() != '<p><br></p>' && quillBrief.editor.getText().trim() != '') {
            details = {
                mobile: quillBrief.getEditorContents(),
                web: quillBrief.editor.getContents()
            }
            setCreateCourseObj("brief", JSON.stringify(details))
        }
        else {
            setCreateCourseObj("brief", '')
        }
    }
    const handleOnlineTestDescription = (content, delta, source, editor) => {
        if (source == 'user') {
            details = {
                mobile: quillExam.getEditorContents(),
                web: quillExam.editor.getContents()
            }
            setCreateCourseObj("examPattern", JSON.stringify(details))
        }
    }
    const handleOnlineTestDescriptionkeyUp = (e) => {
        if (quillExam.getEditorContents() != '<p><br></p>' && quillExam.editor.getText().trim() != '') {
            details = {
                mobile: quillExam.getEditorContents(),
                web: quillExam.editor.getContents()
            }
            setCreateCourseObj("examPattern", JSON.stringify(details))
        }
        else {
            setCreateCourseObj("examPattern", '')
        }
    }
    const handleAddLearningField = () => {
        const lastField = liveCourseLearningFields[liveCourseLearningFields.length - 1];
        if (lastField.name !== '') {
            setLiveCourseLearningFields([...liveCourseLearningFields, { id: null, name: '' }]);
        }
    }

    const handleRemoveLearningField = (index) => {
        const updatedLearningFields = [...liveCourseLearningFields];
        const deletedLearning = updatedLearningFields.splice(index, 1);
        deletedLearning.forEach(learning => {
            setDeletedLearnings([...deletedLearnings, learning.id]);
        });
        setLiveCourseLearningFields(updatedLearningFields);
        setCreateCourseObj("learnings", updatedLearningFields);
    };

    const handleAddPrerequisitesField = () => {
        const lastField = liveCoursePrerequisitesFields[liveCoursePrerequisitesFields.length - 1];
        if (lastField.name !== '') {
            setLiveCoursePrerequisitesFields([...liveCoursePrerequisitesFields, { id: null, name: '' }]);
        }
    };

    const handleRemovePrerequisitesField = (index) => {
        const updatedPrerequisitesFields = [...liveCoursePrerequisitesFields];
        const deletedPrerequisite = updatedPrerequisitesFields.splice(index, 1);
        deletedPrerequisite.forEach(prerequisite => {
            setDeletedPrerequisites([...deletedPrerequisites, prerequisite.id]);
        });
        setLiveCoursePrerequisitesFields(updatedPrerequisitesFields);
        setCreateCourseObj("prerequisites", updatedPrerequisitesFields)
    };
    const handleAddIncludesField = () => {
        const lastField = liveCourseIncludesFields[liveCourseIncludesFields.length - 1];
        if (lastField.name !== '') {
            setLiveCourseIncludesFields([...liveCourseIncludesFields, { id: null, name: '' }]);
        }
    };

    const handleRemoveIncludesField = (index) => {
        const updatedIncludesFields = [...liveCourseIncludesFields];
        const deletedDetail = updatedIncludesFields.splice(index, 1);
        deletedDetail.forEach(detail => {
            setDeletedDetails([...deletedDetails, detail.id]);
        });
        setLiveCourseIncludesFields(updatedIncludesFields);
        setCreateCourseObj("details", updatedIncludesFields)
    };
    const addSubject = () => {
        if (subjects[subjects.length - 1].name !== '') {
            setSubjects([...subjects, { id: null, name: '', chapters: [{ id: null, name: '' }] }]);
        }
    };
    const addStudyMaterialsTopic = () => {
        if ((studyMaterialsTopics[studyMaterialsTopics.length - 1].name !== '' && studyMaterialsTopics[studyMaterialsTopics.length - 1].file != null) || (studyMaterialsTopics[studyMaterialsTopics.length - 1].name !== '' && studyMaterialsTopics[studyMaterialsTopics.length - 1].quizId != null)) {
            setStudyMaterialsTopics([...studyMaterialsTopics, { name: '', file: null, fileIndex: SMfileIndex, preview: false, type: "notes" }]);
        }
    };

    const addLesson = (index) => {
        const updateTopic = [...sections]
        updateTopic[index]?.topics.push({ name: '', preview: false, fileIndex: increase, videoType: null, type: null, quizId: null, fileUrl: null, duration: null })
        setSections(updateTopic)
    }
    const addSection = () => {
        if (sections[sections?.length - 1].name != '') {
            setSections([...sections, { name: "", topics: [{ name: '', preview: false, fileIndex: increase, videoType: null, type: null, quizId: null, fileUrl: null, duration: null }] }])
        }
    }
    const handleAddChaptersField = (subjectIndex) => {
        const updatedSubjects = [...subjects];
        const lastChapterIndex = updatedSubjects[subjectIndex].chapters.length - 1;
        if (lastChapterIndex < 0 || updatedSubjects[subjectIndex].chapters[lastChapterIndex].name !== '') {
            updatedSubjects[subjectIndex].chapters.push({ id: null, name: '' });
            setSubjects(updatedSubjects);
        }
    };

    const handleRemoveSMTopicField = (topicIndex, fromQuiz) => {
        if (fromQuiz) {
            const updatedStudyMaterialsTopics = [...studyMaterialsTopics];
            updatedStudyMaterialsTopics[topicIndex].type = null
            deleteQuiz(updatedStudyMaterialsTopics[topicIndex]?.quizId)
            updatedStudyMaterialsTopics[topicIndex].quizId = null
            updatedStudyMaterialsTopics[topicIndex].name = null
            updatedStudyMaterialsTopics[topicIndex].preview = null
            updatedStudyMaterialsTopics[topicIndex].pageCount = null
            updatedStudyMaterialsTopics[topicIndex].id = null
            updatedStudyMaterialsTopics[topicIndex].file = null
            updatedStudyMaterialsTopics[topicIndex].fileIndex = null
            updatedStudyMaterialsTopics[topicIndex].fileUrl = null
            // delete updatedStudyMaterialsTopics[topicIndex]
            setStudyMaterialsTopics(updatedStudyMaterialsTopics);
        } else {
            const updatedStudyMaterialsTopics = [...studyMaterialsTopics];
            updatedStudyMaterialsTopics[topicIndex].file = null;
            delete updatedStudyMaterialsTopics[topicIndex].fileUrl;
            updatedStudyMaterialsTopics[topicIndex].pageCount = 0;
            setStudyMaterialsTopics(updatedStudyMaterialsTopics);
            const updatedSMfilesArray = [...SMfilesArray];
            updatedSMfilesArray[topicIndex] = null;
            setSMfilesArray(updatedSMfilesArray);
        }
    };

    const handleRemoveVRTopicField = (index, topicIndex, fromQuiz) => {

        if (fromQuiz) {
            const updatedVideoResourcesTopics = [...sections];
            updatedVideoResourcesTopics[index].topics[topicIndex].name = null
            deleteQuiz(updatedVideoResourcesTopics[index].topics[topicIndex]?.quizId)
            if (updatedVideoResourcesTopics[index].topics.length === 1) {
                updatedVideoResourcesTopics[index].topics[topicIndex].name = null
                updatedVideoResourcesTopics[index].topics[topicIndex].file = null
                updatedVideoResourcesTopics[index].topics[topicIndex].fileUrl = null
                updatedVideoResourcesTopics[index].topics[topicIndex].fileName = null
                updatedVideoResourcesTopics[index].topics[topicIndex].type = null
            } else {
                delete updatedVideoResourcesTopics[index].topics[topicIndex];
                updatedVideoResourcesTopics[index].topics = updatedVideoResourcesTopics[index].topics.filter(topic => topic !== null && topic !== undefined);
            }
            setSections(updatedVideoResourcesTopics);
        } else {
            const updateVideoResourcesTopics = [...sections];
            deleteCourseDocument(updateVideoResourcesTopics[index].topics[topicIndex].fileUrl, isType)
            if (updateVideoResourcesTopics[index].topics.length == 1) {
                updateVideoResourcesTopics[index].topics[topicIndex].name = null
                updateVideoResourcesTopics[index].topics[topicIndex].file = null
                updateVideoResourcesTopics[index].topics[topicIndex].fileUrl = null
                updateVideoResourcesTopics[index].topics[topicIndex].fileName = null
                updateVideoResourcesTopics[index].topics[topicIndex].type = null
                setSections(updateVideoResourcesTopics)
                setYouTubeLink('')

            } else {
                const updateVideoResourcesTopics = [...sections];
                deleteCourseDocument(updateVideoResourcesTopics[index].topics[topicIndex].fileUrl,);
                if (updateVideoResourcesTopics[index].topics.length === 1) {
                    updateVideoResourcesTopics[index].topics = [];
                } else {
                    delete updateVideoResourcesTopics[index].topics[topicIndex];
                    updateVideoResourcesTopics[index].topics = updateVideoResourcesTopics[index].topics.filter(topic => topic !== null && topic !== undefined);
                }
                setSections(updateVideoResourcesTopics);
                setYouTubeLink('')

            }
            setSections(updateVideoResourcesTopics)
            setYouTubeLink('')

        }
    }
    const handleRemoveChaptersField = (subjectIndex, chapterIndex) => {
        const updatedSubjects = [...subjects];
        updatedSubjects[subjectIndex].chapters.splice(chapterIndex, 1);
        setSubjects(updatedSubjects);
    };
    const handleChapterNameChange = (subjectIndex, chapterIndex, newName) => {
        const updatedSubjects = [...subjects];
        updatedSubjects[subjectIndex].chapters[chapterIndex].name = newName;
        setSubjects(updatedSubjects);
    };
    const [isType, setIsType] = useState('')
    const handleUploadChange = (info, key) => {
        const { status } = info.file;
        const isLt5M = info.file.originFileObj?.size / 1024 / 1024 < 5;
        if (status === 'done' || status === 'error') {
            if (!isLt5M && (info.file.originFileObj.type === "image/jpeg" || info.file.originFileObj.type === "image/png" || info.file.originFileObj.type === "image/jpg")) {
                Notification.error('error', "Image must be smaller than 5MB!");
            } else if (info.file.originFileObj.type != "image/jpeg" && info.file.originFileObj.type != "image/png" && info.file.originFileObj.type != "image/jpg") {
                Notification.error('error', "File extension not supported");
            } else {
                if (courseTypeName == 'videoResources') {
                    uploadingProgress();
                    UploadCourseDocument(
                        info.file.originFileObj,
                        isType, true,
                        (key) => {
                            setCreateCourseObj("image", key)
                        }
                    )
                } else {
                    uploadingProgress();
                    UploadCourseDocument(
                        info.file.originFileObj,
                        isType, false,
                        (key) => {
                            setCreateCourseObj("image", key)
                        }
                    )
                }

            }
        } else if (status === 'error') {
            Notification.error('error', `${info.file.name} file upload failed.`);
        }
    }
    const uploadingProgress = () => {
        const hide = message.loading('Upload in progress..', 0);
        setTimeout(hide, 1500);
    };

    let uploadingMessage;

    console.log("uploadingMessage", uploadingMessage);
    const handleVideoUploadChange = (info, key) => {
        const { status } = info.file;
        const isLt200MB = info.file.originFileObj.size / 1024 / 1024 < 200;
        if (status === 'done' || status === 'error') {
            if (!isLt200MB && (info.file.originFileObj.type == "video/mp4" || info.file.originFileObj.type == "video/x-matroska" || info.file.originFileObj.type == "video/video/avi")) {
                Notification.error('error', "File must be smaller than 200MB!");
            } else if (info.file.originFileObj.type != "video/mp4" && info.file.originFileObj.type != "video/x-matroska" && info.file.originFileObj.type != "video/avi") {
                Notification.error('error', "File extension not supported");
            } else {
                uploadingMessage = message.loading('Video upload in progress..', 0);
                setCreateCourseObj("previewType", "file");
                if (courseTypeName == 'videoResources') {
                    UploadCourseDocument(
                        info.file.originFileObj,
                        isType,
                        true,
                        (key) => {
                            setCreateCourseObj("preview", key);
                            if (uploadingMessage) {
                                uploadingMessage()
                            }
                        }
                    );
                } else {
                    UploadCourseDocument(
                        info.file.originFileObj,
                        isType, false,
                        (key) => {
                            setCreateCourseObj("preview", key);
                            if (uploadingMessage) {
                                uploadingMessage()
                            }
                        }
                    );
                }

            }
        } else if (status === 'error') {
            Notification.error('error', `${info.file.name} file upload failed.`);
        }
    }

    const [videoFile, setVideoFile] = useState([])
    function formatDuration(durationInSeconds) {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    const [isStatus, setIsStatus] = useState(false)
    const handleResourceUpload = (info, index, topicIndex) => {
        setIsStatus(false)
        updatepdf(info.file.originFileObj);
        const { status } = info.file;
        if (status === 'done' || status === 'error') {
            uploadingMessage = message.loading('Video upload in progress..', 0);
            console.log("uploadingMessage1", message);
            uploadFunction(uploadingMessage, index, topicIndex, () => { }, () => { });
            setIncrease(increase + 1);

            const updateResourceFile = [...sections];
            const videoFile = info.file.originFileObj;
            info.file.originFileObj?.arrayBuffer().then((data) => {
                PDFDocument.load(data).then((doc) => {
                    const pageCount = doc.getPageCount();
                    const updateResourceFile = [...sections];

                    updateResourceFile[index].topics[topicIndex].pageCount = pageCount
                    setSections(updateResourceFile);

                }).catch((err) => {
                });
            })
            updateResourceFile[index].topics[topicIndex].file = videoFile;
            updateResourceFile[index].topics[topicIndex].fileName = videoFile?.name;
            updateResourceFile[index].topics[topicIndex].fileSize = videoFile?.size;
            updateResourceFile[index].topics[topicIndex].fileIndex = increase + 1;

            if (videoFile.type === "application/pdf") {

                updateResourceFile[index].topics[topicIndex].type = 'notes';
                updateResourceFile[index].topics[topicIndex].videoType = 'video';
            } else {
                updateResourceFile[index].topics[topicIndex].videoType = 'video';
                updateResourceFile[index].topics[topicIndex].type = 'video';
                if (videoFile.type.startsWith('video/') && videoFile.type !== 'video/quicktime') {
                    const videoElement = document.createElement('video');
                    videoElement.src = URL.createObjectURL(videoFile);
                    videoElement.onloadedmetadata = function () {
                        const durationInSeconds = Math.floor(videoElement.duration);
                        const formattedDuration = formatDuration(durationInSeconds);
                        updateResourceFile[index].topics[topicIndex].duration = formattedDuration;
                        URL.revokeObjectURL(videoElement.src);
                        setSections(updateResourceFile);
                    };
                }
            }
            setSections(updateResourceFile);
        } else if (status === 'error') {
            Notification.error('error', `${info.file.name} file upload failed.`);
        }
    };


    // merge chunk and upload
    let blockStart = null;
    let blockEnd = null;
    let currentBlockNumber = null;
    let fileId = null;
    let totalBlocksCount = null;
    const uploadFunction = async (uploadingMessage, index, topicIndex, callback, successCallback) => {
        totalBlocksCount = Pdftoupload?.size % blockSize === 0 ? Pdftoupload?.size / blockSize : Math.floor(Pdftoupload?.size / blockSize) + 1;
        fileId = uuidv4() + "." + Pdftoupload.name.split('.').pop();
        blockStart = 0;
        blockEnd = blockSize;
        for (currentBlockNumber = 1; currentBlockNumber <= totalBlocksCount; currentBlockNumber += 1) {

            var block = Pdftoupload.slice(blockStart, blockEnd);
            var blockWithType = new Blob([block], { type: Pdftoupload?.type });

            await uploadBlock(uploadingMessage, blockWithType, index, topicIndex, callback, successCallback);
        }
    };

    const uploadBlock = async (uploadingMessage, currentBlock, index, topicIndex, callback, successCallback) => {

        try {
            // currentBlock.type='application/pdf'
            var params = {
                file: currentBlock,

                orderId: currentBlockNumber,
                fileName: fileId,

            }
            await uploadBlock2(params, async (response) => {
                if (response != undefined) {
                    if (response?.data?.progress?.message === "uploaded") {
                        blockStart = blockEnd;
                        blockEnd += blockSize;
                        if (currentBlockNumber === totalBlocksCount) {
                            var percent = Math.round((currentBlockNumber / totalBlocksCount) * 100);
                            setProgress(percent);
                            MergeChunk(fileId, (key) => {
                                if (uploadingMessage) {
                                    uploadingMessage()
                                }
                                const updateResourceFile = [...sections]
                                updateResourceFile[index].topics[topicIndex].fileUrl = key;
                                setSections(updateResourceFile)
                                setIsStatus(true)

                            })
                        } else {
                            var percent = Math.round((currentBlockNumber / totalBlocksCount) * 100);
                            setProgress(percent);
                        }
                    }
                }
            });
        } catch (error) {
            console.log("error in block upload", error);
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleStudyMaterialUpload = (info, topicIndex) => {
        // const isLt10MB = info.file.originFileObj.size / 1024 / 1024 < 10;
        // if (!isLt10MB && info.file.originFileObj.type == "application/pdf") {
        //     Notification.error('error', "File must be smaller than 10MB!");
        // } else if (info.file.originFileObj.type != "application/pdf") {
        //     Notification.error('error', "File extension not supported");
        // } else {
        info.file.originFileObj?.arrayBuffer().then((data) => {
            PDFDocument.load(data).then((doc) => {
                const pageCount = doc.getPageCount();
                const updatedStudyMaterialFile = [...studyMaterialsTopics];
                updatedStudyMaterialFile[topicIndex].pageCount = pageCount
                setStudyMaterialsTopics(updatedStudyMaterialFile);
            }).catch((err) => {
            });
        })
        const updatedSMfilesArray = [...SMfilesArray];
        if (!updatedSMfilesArray[topicIndex]) {
            updatedSMfilesArray[topicIndex] = [];
        }
        updatedSMfilesArray[topicIndex].push(info.file.originFileObj);
        setSMfilesArray(updatedSMfilesArray);
        setSMfileIndex(SMfileIndex + 1)
        const updatedStudyMaterialFile = [...studyMaterialsTopics];
        updatedStudyMaterialFile[topicIndex].file = info.file.originFileObj;
        updatedStudyMaterialFile[topicIndex].fileIndex = SMfileIndex + 1;
        setStudyMaterialsTopics(updatedStudyMaterialFile);
        // }
    }

    const handleChangeCategory = (value, obj) => {
        if (obj?.key != -1) {
            setCreateCourseObj("categoryId", { name: value, id: obj?.key });
        }
        else if (obj?.key == -1) {
            setCreateCourseObj("categoryId", { name: obj?.children?.[1], id: null })
        }
        else {
            setCreateCourseObj("categoryId", { name: null, id: null })
        }
        getCoursesCategories();
        getCoursesSubCategories(obj?.key);
        setCreateCourseObj("subcategoryId", null);
    }

    const handleTagsSelect = (value, obj) => {
        if (value == -1) {
            postCreateTags(obj?.children?.[1],
                (res) => {
                    setTagsLoader(true)
                    getTags(null, (tags) => { updateTagsList(tags) })
                    selectedTags?.push({ value: obj?.children?.[1], id: res?.tag?.id })
                    setSelectedTags([...selectedTags])
                    setCreateCourseObj("tags", [...selectedTags])
                })
        }
        else if (!selectedTags?.map(tag => tag.id).includes(obj.id)) {
            selectedTags.push(obj);
            setSelectedTags([...selectedTags]);
            setCreateCourseObj("tags", [...selectedTags])
        }
        setSearchTag('');
        setTagsLoader(true)
        getTags(null, (tags) => updateTagsList(tags))
    }

    const onSelectCategory = (value, obj) => {
        if (value != -1) {
            setCreateCourseObj("categoryId", { name: value, id: obj?.key });
        }
        else if (value == -1) {
            postCreateCategory(
                obj?.children?.[1],
                () => { getCoursesCategories() },
                (res) => { setCreateCourseObj("categoryId", { name: res?.category?.name, id: res?.category?.id }) },
                null, null, true)
        }
        else {
            setCreateCourseObj("categoryId", { name: null, id: null })
        }
    }

    const handleSearchCategory = (value) => {
        if (value && value.trim() != '') {
            setCreateCourseObj("categoryId", { name: value, id: null })
            let category;
            category = courseCategoryData.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
            setCategoryListToShow(category)
        }
        else {
            setCreateCourseObj("categoryId", { name: null, id: null })
            setCategoryListToShow(courseCategoryData)
        }
    }

    const handleChangeSubCategory = (value, obj) => {
        if (obj?.key != -1) {
            setCreateCourseObj("subcategoryId", { name: value, id: obj?.key });
        }
        else if (obj?.key == -1) {
            setCreateCourseObj("subcategoryId", { name: obj?.children?.[1], id: null })
        }
        else {
            setCreateCourseObj("subcategoryId", { name: null, id: null })
        }
    }
    const onSelectSubCategory = (value, obj) => {
        if (value != -1) {
            setCreateCourseObj("subcategoryId", { name: value, id: obj?.key });
        }
        else if (value == -1) {
            postCreateSubCategory(
                obj?.children?.[1],
                () => { getCoursesSubCategories(courseCreateObj?.categoryId?.id) },
                (res) => { setCreateCourseObj("subcategoryId", { name: res?.category?.name, id: res?.category?.id }) },
                null, null, null, null, true)
        }
        else {
            setCreateCourseObj("subcategoryId", { name: null, id: null })
        }
    }

    const handleSearchSubCategory = (value) => {
        if (value && value.trim() != '') {
            setCreateCourseObj("subcategoryId", { name: value, id: null })
            let subCategory;
            subCategory = courseSubCategoriesData.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
            setSubCategoryListToShow(subCategory)
        }
        else {
            setCreateCourseObj("subcategoryId", { name: null, id: null })
            setSubCategoryListToShow(courseSubCategoriesData)
        }
    }

    const handleBrowseClick = () => {
        setImportMeta("selectedQBType", "sample question papers");
        setImportMeta('selectedTypeId', 1);
        history.push({
            pathname: '/test-import-classes',
            state: {
                testSeriesData: true
            }
        })
    }

    const calcDiscount = (e) => {
        if (discountType == "percent") {
            setDiscountedPrice(Math.round((courseCreateObj?.amount - (courseCreateObj?.amount * e.target.value) / 100) * 100) / 100)
        } else if (discountType == "amount") {
            setDiscountedPrice(courseCreateObj?.amount - e?.target?.value)
        }
        setCreateCourseObj("discount", parseInt(e.target.value))
    }
    function formatFileSize(sizeInBytes) {
        const kilobyte = 1024;
        const megabyte = kilobyte * 1024;
        const gigabyte = megabyte * 1024;
        if (sizeInBytes >= gigabyte) {
            return (sizeInBytes / gigabyte).toFixed(2) + ' GB';
        } else if (sizeInBytes >= megabyte) {
            return (sizeInBytes / megabyte).toFixed(2) + ' MB';
        } else if (sizeInBytes >= kilobyte) {
            return (sizeInBytes / kilobyte).toFixed(2) + ' KB';
        } else {
            return sizeInBytes + ' bytes';
        }
    }

    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;

        for (var c = a.childNodes, i = c.length; i--;) {
            if (c[i].nodeType === 1) return true;
        }

        return false;
    }

    const [isvalue, setValueData] = useState(false)

    const checkUpdateCardClick = () => {
        if (courseCreateObj?.courseName !== specificCourseDataContainer?.testSeriesDetails?.name ||
            JSON.parse(courseCreateObj?.courseDescription).mobile !== JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description).mobile ||
            courseCreateObj?.categoryId?.id !== specificCourseDataContainer?.testSeriesDetails?.category?.parentId ||
            courseCreateObj?.difficulty !== specificCourseDataContainer?.testSeriesDetails?.difficulty ||
            courseCreateObj?.amount !== specificCourseDataContainer?.testSeriesDetails?.amount ||
            courseCreateObj?.discount !== specificCourseDataContainer?.testSeriesDetails?.discount ||
            JSON.parse(courseCreateObj?.examPattern).mobile !== JSON.parse(specificCourseDataContainer?.testSeriesDetails?.examPattern).mobile ||
            isvalue
        ) {
            setConfirmSaveDetailsModalVisible(true)
        } else {
            history.push({
                pathname: "/testSeries/createTest",
                state: {
                    key: {
                        fromCourseCard: location?.state?.key?.fromCourseCard,
                        fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                        searchedValue: location?.state?.key?.searchedValue
                    }
                }
            })
        }
    }
    return (
        <div>
            <div style={{ marginBottom: "40px" }}>
                <PageHeader title={
                    courseTypeName == "liveCourses"
                        ? location?.state?.key?.isEdit == "isFromEdit" && location?.state?.key?.isClone ? `Clone ${specificCourseDataContainer?.course?.courseName}` : location?.state?.key?.isEdit == "isFromEdit" ? `Edit ${specificCourseDataContainer?.course?.courseName}` : "Add Live Courses"
                        : courseTypeName == "videoResources"
                            ? location?.state?.key?.isEdit == "isFromEdit" ? `Edit ${specificCourseDataContainer?.name}` : "Add Video Course"
                            : courseTypeName == "onlineTestSeries"
                                ? location?.state?.key?.isEdit == "isFromEdit" ? `Edit ${specificCourseDataContainer?.testSeriesDetails?.name}` : "Add Online Test Series"
                                : courseTypeName == "studyMaterials"
                                    ? location?.state?.key?.isEdit == "isFromEdit" ? `Edit ${specificCourseDataContainer?.name}` : "Add Study Materials"
                                    : "Add Live Courses"
                }
                    onBackPress={location?.state?.key?.isEdit == "isFromEdit" ? null : onPageHeaderCancel}
                    actions={actions} />
            </div>
            <div style={{ ...bodyStyle }}>
                <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold", marginTop: 30 }}>{courseTypeName == "studyMaterials" ? "Study material" : courseTypeName == "videoResources" ? "Video course" : courseTypeName == 'onlineTestSeries' ? 'Test Series' : "Live course"} details</div>
                <div className='m-t-20 m-b-5' style={inputHeadingStyle}>{courseTypeName == "studyMaterials" ? "Study material" : courseTypeName == "videoResources" ? "Video course" : courseTypeName == "onlineTestSeries" ? "Test Series" : "Live course"} name<span style={{ color: "red" }}> *</span></div>
                <Input placeholder={courseTypeName == "onlineTestSeries" ? "Enter Test Series name" : 'Enter resource name'} value={courseCreateObj?.courseName} onChange={(e) => { setCreateCourseObj("courseName", e.target.value) }} />
                {(courseTypeName == "studyMaterials" || courseTypeName == "videoResources" || courseTypeName == 'onlineTestSeries') ?
                    <></>
                    :
                    <>
                        <div className='m-t-20 m-b-5' style={inputHeadingStyle}>Mode of class<span style={{ color: "red" }}> *</span></div>
                        <div style={{ display: "flex" }}>
                            <div style={{ backgroundColor: courseCreateObj?.mode == "online" ? "#28DF99" : "#FFFFFF", border: "1px solid #28DF99", color: courseCreateObj?.mode == "online" ? "#FFFFFF" : "#28DF99", ...modeStyle }} onClick={() => { setCreateCourseObj("mode", "online") }}>ONLINE</div>
                            <div style={{ backgroundColor: courseCreateObj?.mode == "offline" ? "#FF414D" : "#FFFFFF", border: "1px solid #FF414D", color: courseCreateObj?.mode == "offline" ? "#FFFFFF" : "#FF414D", ...modeStyle }} onClick={() => { setCreateCourseObj("mode", "offline") }}>OFFLINE</div>
                            <div style={{ backgroundColor: courseCreateObj?.mode == "hybrid" ? "#FFA931" : "#FFFFFF", border: "1px solid #FFA931", color: courseCreateObj?.mode == "hybrid" ? "#FFFFFF" : "#FFA931", ...modeStyle }} onClick={() => { setCreateCourseObj("mode", "hybrid") }}>HYBRID</div>
                        </div>
                    </>
                }
                {courseTypeName == "studyMaterials" || courseTypeName == "onlineTestSeries" ?
                    <></>
                    :
                    <>
                        <div className='m-t-20 m-b-5' style={inputHeadingStyle}>{courseTypeName == "studyMaterials" ? "Study material" : courseTypeName == "videoResources" ? "Video course" : courseTypeName == "onlineTestSeries" ? "Test Series" : "Live course"} brief<span style={{ color: "red" }}> *</span></div>
                        <ReactQuill
                            className='annocnementDescription link-div'
                            theme='snow'
                            style={{ height: '100%' }}
                            modules={modules}
                            value={IsJsonString(courseCreateObj?.brief) ? JSON.parse(courseCreateObj?.brief)?.mobile : courseCreateObj?.brief || ''}
                            onChange={(content, delta, source, editor) => handleBrief(content, delta, source, editor)}
                            ref={el => quillBrief = el}
                            placeholder='Enter course brief'
                            bounds={'.annocnementDescription'}
                            onKeyUp={(e) => handleCourseBrief(e)}
                        />

                        {/* <Input placeholder={'Enter course brief'} value={courseCreateObj?.brief} onChange={(e) => { setCreateCourseObj("brief", e.target.value) }} /> */}
                    </>
                }
                {console.log("courseCreateObj", courseCreateObj?.courseDescription)}
                <div className='m-t-20 m-b-5' style={inputHeadingStyle}>Description<span style={{ color: "red" }}> *</span></div>
                <ReactQuill
                    className='annocnementDescription link-div'
                    theme='snow'
                    style={{ height: '100%' }}
                    modules={modules}
                    value={IsJsonString(courseCreateObj?.courseDescription) ? JSON.parse(courseCreateObj?.courseDescription)?.mobile : courseCreateObj?.courseDescription || ''}
                    onChange={(content, delta, source, editor) => handleDescription(content, delta, source, editor)}
                    ref={el => quillReff = el}
                    placeholder="Add description"
                    bounds={'.annocnementDescription'}
                    onKeyUp={(e) => handleDescriptionkeyUp(e)}
                />
                {courseTypeName == "studyMaterials" || courseTypeName == 'onlineTestSeries' ?
                    <></>
                    :
                    <div className='m-t-20' style={{ width: "100%", height: "fit-content", padding: 20, border: "1px solid #d9d9d9", backgroundColor: "#FFFFFF" }}>
                        <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold" }}>Add {courseTypeName == "videoResources" ? "video course" : "course"} thumbnail<span style={{ color: "red" }}> *</span></div>
                        <div style={{ ...inputHeadingStyle, fontWeight: "normal" }}>A captivating thumbnail is crucial for your online course, enticing learners to explore and enroll, impacting engagement and enrollment rates.</div>
                        {console.log("h2 data", defualThumbnail)}
                        {!courseCreateObj?.image ?
                            <div ><AddThumbnail
                                setCreateCourseObj={setCreateCourseObj}
                                defualThumbnail={defualThumbnail}
                                setDefaultThumbnail={setDefaultThumbnail} /></div> : null}
                        {courseCreateObj?.image ?
                            <div className='r-c-c' style={{ width: "100%" }}>
                                <div className='r-c-c m-t-10' style={{ position: "relative", width: "fit-content" }}>
                                    <div style={{ position: "absolute", top: -5, right: -20, cursor: "pointer" }} onClick={() => { if (defualThumbnail) { setCreateCourseObj("image", null) } else { deleteCourseDocument(courseCreateObj?.image, isType); setCreateCourseObj("image", null) } }}><CloseCircleOutlined style={{ color: "red" }} /></div>
                                    <img src={`${Api.dlS3Url}${courseCreateObj?.image}`} style={{ height: 150, width: 150 }} />
                                </div>
                            </div>
                            :
                            <>
                                <div style={{ ...inputHeadingStyle, fontWeight: "normal", marginTop: "10px" }}>I already have a thumbnail</div>
                                <Dragger
                                    showUploadList={false}
                                    onChange={(e) => { handleUploadChange(e); setIsType('image') }}
                                    accept={".jpeg,.png,.jpg"}
                                    style={{ borderColor: "#1089FF", cursor: "pointer", width: "100%", margin: "10px 0px" }}
                                >
                                    <div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginLeft: "30px" }}><img style={{ width: "100px" }} src={dragImage} /></div>
                                            <div style={{ marginTop: "10px" }}>
                                                <div className='r-c-c' style={{ color: "#636363", fontSize: "14", fontWeight: 600, marginLeft: "20px" }}>Drag and drop or <img src={dragFile} style={{ marginLeft: "10px" }} /><span style={{ color: "#1089FF", margin: "0px 5px ", marginLeft: "10px" }}> Choose file </span>to Upload</div>
                                                <div style={{ marginLeft: "-80px", marginTop: "5px", color: "#AEAEAE", fontSize: "12px" }}>Files supported: PNG, JPEG and JPG</div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* <div className='r-c-c' style={{ color: "#636363", fontSize: "14", fontWeight: 600 }}>Drag and drop or<span style={{ color: "#1089FF", margin: "0px 5px " }}> Choose file </span>to Upload</div> */}
                                </Dragger>

                            </>
                        }
                    </div>
                }
                <div className='m-t-20' style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ width: "30%" }}>
                        <div className='m-b-5' style={inputHeadingStyle}>Category <span style={{ color: "red" }}> *</span></div>
                        <Select
                            getPopupContainer={(trigger) => trigger.parentElement}
                            allowClear={courseCreateObj?.categoryId?.name ? true : false}
                            defaultActiveFirstOption={false}
                            notFoundContent={null}
                            showSearch
                            style={{ width: "100%", border: "1px solid #d9d9d9", backgroundColor: "#FFFFFF" }}
                            bordered={false}
                            placeholder="Select category"
                            value={courseCreateObj?.categoryId?.name}
                            filterOption={false}
                            onChange={handleChangeCategory}
                            onSelect={onSelectCategory}
                            onSearch={handleSearchCategory}
                        >
                            {CategoryListToShow?.length != 0 ?
                                CategoryListToShow?.map((item, index) => (
                                    <Select.Option key={item?.id} value={item?.name}>
                                        {item.name}
                                    </Select.Option>
                                ))
                                :
                                courseCreateObj?.categoryId?.name ?
                                    <Select.Option key={-1}>Create {courseCreateObj?.categoryId?.name}</Select.Option>
                                    : null
                            }
                        </Select>
                    </div>
                    <div style={{ width: "30%" }}>
                        {console.log("courseCreateObj?.subcategoryId?.name",courseCreateObj?.subcategoryId?.name)}
                        <div className='m-b-5' style={inputHeadingStyle}>Sub category <span style={{ color: "red" }}> *</span></div>
                        <Select
                            getPopupContainer={(trigger) => trigger.parentElement}
                            allowClear={courseCreateObj?.subcategoryId?.name ? true : false}
                            defaultActiveFirstOption={false}
                            notFoundContent={null}
                            showSearch
                            style={{ width: "100%", border: "1px solid #d9d9d9", backgroundColor: "#FFFFFF" }}
                            bordered={false}
                            placeholder="Select subcategory"
                            value={courseCreateObj?.subcategoryId?.name}
                            filterOption={false}
                            onChange={handleChangeSubCategory}
                            onSelect={onSelectSubCategory}
                            onSearch={handleSearchSubCategory}
                            disabled={!courseCreateObj?.categoryId?.name}
                        >
                            {subCategoryListToShow?.length != 0 ?
                                subCategoryListToShow?.map((item, index) => (
                                    <Select.Option key={item?.id} value={item?.name}>
                                        {item.name}
                                    </Select.Option>
                                ))
                                :
                                courseCreateObj?.subcategoryId?.name ?
                                    <Select.Option key={-1}>Create {courseCreateObj?.subcategoryId?.name}</Select.Option>
                                    : null
                            }
                        </Select>
                    </div>
                    <div style={{ width: "30%" }}>
                        <div className='m-b-5' style={inputHeadingStyle}>Course difficulty <span style={{ color: "red" }}> *</span></div>
                        <Select placeholder='Select difficulty' style={{ width: "100%" }} value={courseCreateObj?.difficulty} onChange={(value) => { setCreateCourseObj("difficulty", value) }}>
                            <Option value="easy">Beginner</Option>
                            <Option value="medium">Intermediate</Option>
                            <Option value="hard">Expert</Option>
                        </Select>
                    </div>
                </div>
                {(courseTypeName == "studyMaterials" || courseTypeName == "videoResources" || courseTypeName == 'onlineTestSeries') ?
                    <></>
                    :
                    <div className='m-t-20' style={{ display: "flex", justifyContent: "space-between", width: "100%", }}>
                        <div style={{ width: "100%" }}>
                            <div className='m-b-5' style={inputHeadingStyle}>Duration <span style={{ color: "red" }}> *</span></div>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }} >
                                <Input
                                    placeholder="Enter duration"
                                    style={{ width: "49%" }}
                                    value={courseCreateObj?.duration}
                                    onChange={(e) => {
                                        if ((parseInt(e.target.value) > 60 && parseInt(courseCreateObj?.durationType) == 30 && courseCreateObj?.durationType != '' && e.target.value != '') || (parseInt(e.target.value) > 5 && parseInt(courseCreateObj?.durationType) == 360 && courseCreateObj?.durationType != '' && e.target.value != '') || (parseInt(e.target.value) > 1825 && parseInt(courseCreateObj?.durationType) == 1 && courseCreateObj?.durationType != '' && e.target.value != '')) {
                                            Notification.error("Error", 'Duration should not be greater than 5 years');
                                        }
                                        setCreateCourseObj("duration",
                                            e.target.value != " " && e.target.value != "0" && /^[\d]*$/.test(e.target.value)
                                                ? e.target.value
                                                : courseCreateObj?.duration
                                                    ? courseCreateObj?.duration
                                                    : null
                                        );
                                        setRenderEndDate(true)
                                    }} />
                                <Select
                                    placeholder="Select plan"
                                    style={{ width: "49%" }}
                                    value={courseCreateObj?.durationType}
                                    onChange={(e) => {
                                        if ((parseInt(courseCreateObj?.duration) > 60 && courseCreateObj?.duration != '' && e == '30' && e != '') || (parseInt(courseCreateObj?.duration) > 5 && e == '360' && courseCreateObj?.duration != '' && e != '') || (parseInt(courseCreateObj?.duration) > 1825 && e == '1' && courseCreateObj?.duration != '' && e != '')) {
                                            Notification.error("Error", 'Duration should not be greater than 5 years');
                                        }
                                        setCreateCourseObj("durationType", e)
                                        setRenderEndDate(true)
                                    }}
                                >
                                    <Option value="1">Day(s)</Option>
                                    <Option value="30">Month(s)</Option>
                                    <Option value="360">Year(s)</Option>
                                </Select>
                            </div>
                        </div>
                        {/* <div style={{ width: "24%" }}>
                            <div className='m-b-5' style={inputHeadingStyle}>Start date <span style={{ color: "red" }}> *</span></div>
                            <LiveCourseDatePicker
                                value={courseStartDate}
                                setCourseStartDate={setCourseStartDate}
                                setRenderEndDate={setRenderEndDate}
                                setCreateCourseObj={setCreateCourseObj}
                            />
                        </div>
                        <div style={{ width: "24%" }}>
                            <div className='m-b-5' style={inputHeadingStyle}>End date<span style={{ color: "red" }}> *</span></div>
                            <LiveCourseDatePicker
                                value={newCourseEndDate}
                                setCourseStartDate={setNewCourseEndDate}
                                endDate={true}
                                courseStartDate={courseStartDate}
                                setRenderEndDate={setRenderEndDate}
                                setCreateCourseObj={setCreateCourseObj}
                            />
                        </div> */}
                    </div>
                }
                {courseTypeName == 'liveCourses' ? (<></>) :
                    <div className='m-t-20' style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        {courseTypeName == "liveCourses" ?
                            <div style={{ width: "21.5%" }}>
                                <div className='m-b-5' style={inputHeadingStyle}>Registration fee<span style={{ color: "red" }}> *</span> </div>
                                <Input
                                    type="number"
                                    placeholder="Enter amount"
                                    prefix="₹"
                                    value={courseCreateObj?.registrationFee}
                                    onChange={(e) => { setCreateCourseObj("registrationFee", parseInt(e.target.value)) }}
                                />
                            </div>
                            :
                            <></>
                        }

                        <div style={{ width: courseTypeName == "liveCourses" ? "21.5%" : "30%" }}>
                            <div className='m-b-5' style={inputHeadingStyle}>{'Total fee'} {courseTypeName == "liveCourses" ? <span style={{ color: "red" }}> *</span> : <></>} </div>
                            <Input
                                type="number"
                                placeholder="Enter amount"
                                prefix="₹"
                                value={courseCreateObj?.amount}
                                onChange={(e) => { setCreateCourseObj("amount", parseInt(e.target.value)); setCreateCourseObj("discount", null); setDiscountedPrice(null) }}
                            />
                        </div>
                        <div style={{ width: courseTypeName == "liveCourses" ? "24%" : "30%" }}>
                            <div className='m-b-5' style={inputHeadingStyle}>Discount</div>
                            <Input.Group compact>
                                <Select defaultValue="percentage" style={{ width: '50%' }} value={courseCreateObj?.discountType} onChange={(value) => { setDiscountType(value); setCreateCourseObj("discountType", value); setCreateCourseObj("discount", null); setDiscountedPrice(null) }}>
                                    <Option value="percent">Percentage</Option>
                                    <Option value="amount">Amount</Option>
                                </Select>
                                <Input style={{ width: '50%' }} placeholder={`Enter ${discountType}`} type="number" value={courseCreateObj?.discount} onChange={(e) => { calcDiscount(e) }} />
                            </Input.Group>
                        </div>
                        <div style={{ width: courseTypeName == "liveCourses" ? "24%" : "30%" }}>
                            <div className='m-b-5' style={inputHeadingStyle}>Discounted fee</div>
                            <Input
                                type="number"
                                placeholder="Enter amount"
                                prefix="₹"
                                readOnly
                                value={discountedPrice}
                            />
                        </div>
                    </div>}
                {(courseTypeName == "videoResources") ?
                    <>
                        <div className='m-t-20 m-b-5' style={inputHeadingStyle}>Select teacher<span style={{ color: "red" }}> *</span></div>
                        <Select
                            allowClear={true}
                            style={{ width: '100%' }}
                            placeholder={"Search teacher name"}
                            showSearch
                            loading={getExaminerBool}
                            filterOption={false}
                            defaultActiveFirstOption={false}
                            notFoundContent={null}
                            value={courseCreateObj?.teacherId?.name}
                            onSearch={(text) => { setSearchText(text); setSearchTextIndex('examiner'); updateHandleRender(true) }}
                            onChange={(text, value) => { value ? setCreateCourseObj('teacherId', { name: value.value, id: value.key }) : getExaminer('') }}
                        >
                            {!getExaminerBool && getExaminerArr?.map((item) => {
                                return <Option key={item?.id} className="createTestModalDropdown" value={item.value}>{item.value}</Option>
                            })}
                        </Select>
                    </>
                    :
                    <></>
                }
                {courseTypeName == 'onlineTestSeries' ? (<></>) :
                    (<>
                        <div className='m-t-20 m-b-5' style={inputHeadingStyle}>What your students learn from this {courseTypeName == "studyMaterials" ? "Study material" : courseTypeName == "videoResources" ? "Video course" : "Course"}?</div>
                        {liveCourseLearningFields?.map((field, index) => (
                            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: index === 0 ? '' : 10 }}>
                                <Input
                                    style={{ width: '97%' }}
                                    placeholder={`Sample: "Students learn coding for Ethical Hacking"`}
                                    value={field?.name}
                                    onChange={(e) => {
                                        const updatedLearningFields = [...liveCourseLearningFields];
                                        updatedLearningFields[index].name = e.target.value;
                                        setLiveCourseLearningFields(updatedLearningFields);
                                        setCreateCourseObj("learnings", updatedLearningFields);
                                    }}
                                />
                                {index === liveCourseLearningFields?.length - 1 ? (
                                    <PlusCircleOutlined
                                        className='m-t-5'
                                        style={{ color: '#1089FF', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                        onClick={handleAddLearningField}
                                    />
                                ) : (
                                    <MinusCircleOutlined
                                        className='m-t-5'
                                        style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                        onClick={() => handleRemoveLearningField(index)}
                                    />
                                )}
                            </div>
                        ))}
                    </>)}

                {courseTypeName == "studyMaterials" || courseTypeName == 'onlineTestSeries' ?
                    <></>
                    :
                    <>
                        <div className='m-t-20 m-b-5' style={inputHeadingStyle}>Prerequisites need for this {courseTypeName == "videoResources" ? "Video course" : "Course"}</div>
                        {liveCoursePrerequisitesFields?.map((field, index) => (
                            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: index === 0 ? '' : 10 }}>
                                <Input
                                    style={{ width: '97%' }}
                                    placeholder={`Sample: "Arduino uno Rev3"`}
                                    value={field?.name}
                                    onChange={(e) => {
                                        const updatedPrerequisitesFields = [...liveCoursePrerequisitesFields];
                                        updatedPrerequisitesFields[index].name = e.target.value;
                                        setLiveCoursePrerequisitesFields(updatedPrerequisitesFields);
                                        setCreateCourseObj("prerequisites", updatedPrerequisitesFields);
                                    }}
                                />
                                {index === liveCoursePrerequisitesFields?.length - 1 ? (
                                    <PlusCircleOutlined
                                        className='m-t-5'
                                        style={{ color: '#1089FF', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                        onClick={handleAddPrerequisitesField}
                                    />
                                ) : (
                                    <MinusCircleOutlined
                                        className='m-t-5'
                                        style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                        onClick={() => handleRemovePrerequisitesField(index)}
                                    />
                                )}
                            </div>
                        ))}
                    </>
                }
                {courseTypeName == "videoResources" ?
                    <>
                        <div className='m-t-20 m-b-5' style={inputHeadingStyle}>This Video course includes</div>
                        {liveCourseIncludesFields?.map((field, index) => (
                            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: index === 0 ? '' : 10 }}>
                                <Input
                                    style={{ width: '97%' }}
                                    placeholder={`Sample: "Arduino uno Rev3"`}
                                    value={field?.name}
                                    onChange={(e) => {
                                        const updatedIncludesFields = [...liveCourseIncludesFields];
                                        updatedIncludesFields[index].name = e.target.value;
                                        setLiveCourseIncludesFields(updatedIncludesFields);
                                        setCreateCourseObj("details", updatedIncludesFields)
                                    }}
                                />
                                {index === liveCourseIncludesFields?.length - 1 ? (
                                    <PlusCircleOutlined
                                        className='m-t-5'
                                        style={{ color: '#1089FF', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                        onClick={handleAddIncludesField}
                                    />
                                ) : (
                                    <MinusCircleOutlined
                                        className='m-t-5'
                                        style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                        onClick={() => handleRemoveIncludesField(index)}
                                    />
                                )}
                            </div>
                        ))}
                    </>
                    :
                    <></>
                }
                <div className='m-t-20 m-b-5' style={inputHeadingStyle}>Tags</div>
                <div style={{ ...inputHeadingStyle, fontWeight: "normal" }}>Tags helps to make your course more discoverable to students and helps the students find your class.</div>
                <>
                    <AutoComplete
                        getPopupContainer={(trigger) => trigger.parentElement}
                        notFoundContent={'No tags found, create one'}
                        dropdownMatchSelectWidth={252}
                        className="autocompletetoupload"
                        style={{ marginTop: 5, width: "100%" }}
                        onSelect={(value, obj) => handleTagsSelect(value, obj)}
                        onSearch={(query) => handleTagsSearch(query)}
                        value={searchTag}
                        onChange={e => { setSearchTag(e) }}
                        placeholder="Search tags Sample: Ethical Hacking"
                        loading={tagsLoader}
                        allowClear
                    >
                        {/* {searchTag && !tagsList?.some(tag => tag.label.toLowerCase().includes(searchTag.toLowerCase())) ?
                            <AutoComplete.Option key={-1}>Create {searchTag}</AutoComplete.Option>
                            :
                            <> */}
                        {tagsList?.length !== 0 ? (
                            <>
                                {tagsList?.filter(removeSelectedTags)?.map((item, index) => (
                                    <AutoComplete.Option key={item?.id} id={item?.id} value={item?.label}>
                                        {item.label}
                                    </AutoComplete.Option>
                                ))}
                                {searchTag && !tagsList?.some(tag => tag.label.toLowerCase().includes(searchTag.toLowerCase())) ?
                                    <AutoComplete.Option key={-1}>Create {searchTag}</AutoComplete.Option>
                                    :
                                    <></>}
                            </>
                        ) : null}
                        {/* </>
                        } */}
                    </AutoComplete>
                    {selectedTags?.length ?
                        <div className="flex-wrap m-t-10">
                            {selectedTags?.map((tag, index) =>
                                <Tag
                                    key={tag.id}
                                    style={{
                                        backgroundColor: '#FFFFFF',
                                        color: '#191919',
                                        padding: "5px 10px",
                                        borderRadius: 20,
                                        border: "1px solid #E6E6E6",
                                        fontSize: 14
                                    }}
                                    closable
                                    onClose={() => removeTag(index)}
                                >
                                    {tag?.value}
                                </Tag>
                            )}
                        </div>
                        :
                        <></>}
                </>
                <div className='m-t-20' style={{ width: "100%", height: "fit-contnet", padding: 20, border: "1px solid #d9d9d9", backgroundColor: "#FFFFFF" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ padding: 20 }}>
                            <div style={{ fontWeight: 'bold' }}>Make this course premium</div>
                            <div style={{ fontSize: 12, color: '#636363' }}>Once you make this course premium, promotional offer not applicable for this course.</div>
                        </div>
                        <div>
                            <Switch style={{ color: '#1089FF', marginTop: 20, marginRight: 20 }} size="small" checked={courseCreateObj?.premium} onChange={(checked) => {
                                setCreateCourseObj("premium", checked);
                                onChangePremiun(checked);
                            }} />
                        </div>
                    </div>

                </div>

                <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold", margin: "20px 0px" }}>{courseTypeName == "studyMaterials" ? "Resources" : courseTypeName == "videoResources" ? "Video course" : courseTypeName == "onlineTestSeries" ? 'Syllabus' : "Curriculum"}</div>
                {courseTypeName == "studyMaterials" || courseTypeName == 'onlineTestSeries' ?
                    <></>
                    :
                    <div className='m-t-20' style={{ width: "100%", height: "fit-contnet", padding: 20, border: "1px solid #d9d9d9", backgroundColor: "#FFFFFF" }}>
                        <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold" }}>Preview this {courseTypeName == "videoResources" ? "Video course" : "course"}</div>
                        <div style={{ ...inputHeadingStyle, fontWeight: "normal" }}>Capture the attention of prospective students by providing them with a captivating preview video section, showcasing the diverse and compelling educational content that not only piques their interest but also entices them to explore and engage with your platform.</div>
                        {courseCreateObj?.preview ?
                            <div className='r-c-c' style={{ width: "100%" }}>
                                <div className='r-c-c m-t-10' style={{ position: "relative", width: "fit-content" }}>
                                    <div style={{ position: "absolute", top: -5, right: -20, cursor: "pointer" }} onClick={() => { deleteCourseDocument(courseCreateObj?.preview, isType); setCreateCourseObj("preview", null); setCreateCourseObj("previewType", null) }}><CloseCircleOutlined style={{ color: "red" }} /></div>
                                    <iframe src={`${Api.proDlS3Url}${courseCreateObj?.preview}`} style={{ height: 150, width: 150 }} />
                                </div>
                            </div>
                            :
                            <>
                                <div className='r-jsb m-t-10 m-b-20'>
                                    {/* <div style={{ width: "50%", height: "40px" }} >
                                        <Dragger
                                            showUploadList={false}
                                            onChange={(e) => { handleVideoUploadChange(e); setIsType('preview') }}
                                            accept={".mp4,.avi,.mkv"}
                                            disabled={courseCreateObj?.previewType == "link" && courseCreateObj?.previewLink}
                                            style={{ borderColor: "#1089FF", cursor: "pointer", width: "100%", margin: "10px 0px" }}
                                        >
                                            <div className='r-c-c' style={{ color: "#636363", fontSize: "14", fontWeight: 600, marginTop: "-7px" }}>Drag and drop or<span style={{ color: "#1089FF", margin: "0px 5px " }}> Choose file </span>to Upload</div>
                                        </Dragger>
                                        <div style={{ display: "flex", justifyContent: "space-between", color: "#AEAEAE", marginTop: "-10px" }}>
                                            <div>Files supported: .mp4</div>
                                            <div>Maximum size: 200 MB</div>
                                        </div>
                                    </div> */}
                                    <div style={{ width: "100%" }}>
                                        <div style={{ color: "#636363", fontSize: "12px", fontWeight: "bold", margin: "0px 0px 5px 0px" }}>YouTube Link</div>
                                        <div>
                                            <Input
                                                // disabled={courseCreateObj?.previewType == "file"}
                                                value={courseCreateObj?.previewType == "link" ? courseCreateObj?.previewLink : null}
                                                placeholder='Enter YouTube link Example: https://www.youtube.com/watch?v=RlUOoCMnd'
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        setCreateCourseObj("previewLink", e.target.value)
                                                        setCreateCourseObj("previewType", "link")
                                                    } else {
                                                        setCreateCourseObj("previewLink", null)
                                                        setCreateCourseObj("previewType", null)
                                                    }
                                                }}
                                            />
                                            {courseCreateObj?.previewLink ?
                                                <div>
                                                    {courseCreateObj?.previewLink === null || courseCreateObj?.previewLink === "" || courseCreateObj?.previewLink?.match(regExp) ? null : (<h style={{ color: "#FF414D" }}>Invalid YouTube Link</h>)}
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }
                {courseTypeName == "studyMaterials" ?
                    <>
                        <div className='m-t-20' style={{ width: '100%', height: 'fit-content', padding: 30, border: '1px solid #d9d9d9', backgroundColor: '#F6F4FE' }}>
                            {studyMaterialsTopics?.map((topic, topicIndex) => (
                                <>
                                    {topic?.quizId ?
                                        <div className='m-b-20' style={{ width: '100%', height: 'fit-content', padding: '20px 30px', border: '1px solid #d9d9d9', backgroundColor: '#FFFFFF' }}>
                                            <div className='m-b-5' style={inputHeadingStyle}>Quiz name</div>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div style={{ border: "1px solid #E6E6E6", height: 50, width: "100%", padding: "5px 10px", display: "flex", alignItems: "center" }}>
                                                    <img src={quiz} style={{ width: "20px", height: "20px", marginRight: 10 }} />
                                                    <div style={{ color: "#636363", fontWeight: "bold" }}>{topic.name}</div>
                                                </div>
                                                <EditOutlined
                                                    onClick={() => {
                                                        storeQuizData([])
                                                        getEditQuizData(topic?.quizId, () => { })
                                                        history.push({
                                                            pathname: "/course/add-quiz",
                                                            state: {
                                                                key: {
                                                                    fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                                    searchedValue: location?.state?.key?.searchedValue,
                                                                    fromSubcategory: location?.state?.key?.fromSubcategory,
                                                                    fromCourseCard: location?.state?.key?.fromCourseCard,
                                                                    fromDashboardSearch: location?.state?.key?.fromDashboard,
                                                                    fromCourseDetails: location?.state?.key?.fromCourseDetails,
                                                                    isEdit: location?.state?.key?.isEdit,
                                                                },
                                                                fromStudyMaterial:
                                                                {
                                                                    studyMaterialsTopics: studyMaterialsTopics,
                                                                    editTrue: true,
                                                                    liveCourseLearningFields,
                                                                    SMfilesArray: [...SMfilesArray],
                                                                    SMfileIndex: SMfileIndex,
                                                                    topicIndex: topicIndex,
                                                                }
                                                            }
                                                        })
                                                        storeQuizData([])
                                                    }}
                                                    className='m-t-5'
                                                    style={{ color: '#1089FF', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                />
                                                <MinusCircleOutlined
                                                    className='m-t-5'
                                                    style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                    onClick={() => handleRemoveSMTopicField(topicIndex, true)}
                                                />
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {topic?.type && topic?.type != "quiz" ?
                                                <div className='m-b-20' style={{ width: '100%', height: 'fit-content', padding: '20px 30px', border: '1px solid #d9d9d9', backgroundColor: '#FFFFFF' }}>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div className='m-b-5' style={inputHeadingStyle}>Enter resource name</div>
                                                        <div style={{ ...inputHeadingStyle, fontSize: 11 }}>
                                                            <Checkbox
                                                                checked={studyMaterialsTopics[topicIndex]?.preview == 1 || studyMaterialsTopics[topicIndex]?.preview == true}
                                                                style={{ marginRight: 10 }}
                                                                onChange={(e) => {
                                                                    const updatedPreviw = [...studyMaterialsTopics];
                                                                    updatedPreviw[topicIndex].preview = e.target.checked;
                                                                    setStudyMaterialsTopics(updatedPreviw);
                                                                }} />
                                                            <span>FREE PREVIEW</span>
                                                        </div>
                                                    </div>
                                                    <Input
                                                        placeholder='Enter resource name'
                                                        value={topic?.name}
                                                        onChange={(e) => {
                                                            const updatedTopics = [...studyMaterialsTopics];
                                                            updatedTopics[topicIndex].name = e.target.value;
                                                            setStudyMaterialsTopics(updatedTopics);
                                                        }}
                                                    />
                                                    <div style={{ marginTop: 20 }}>
                                                        {studyMaterialsTopics?.[topicIndex]?.file ?
                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <div style={{ border: "1px solid #E6E6E6", height: 50, width: "100%", padding: "5px 10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                    <div style={{ display: "flex" }}>
                                                                        <FilePdfOutlined style={{ color: "#FF414D", marginRight: 10, fontSize: 20 }} />
                                                                        <div style={{ color: "#636363" }}>{studyMaterialsTopics?.[topicIndex]?.file?.name}</div>
                                                                    </div>
                                                                    <div style={{ color: "#AEAEAE" }}>{studyMaterialsTopics?.[topicIndex]?.pageCount} page(s)</div>
                                                                </div>
                                                                <MinusCircleOutlined
                                                                    className='m-t-5'
                                                                    style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                                    onClick={() => handleRemoveSMTopicField(topicIndex, false)}
                                                                />
                                                            </div>
                                                            :
                                                            <Dragger
                                                                showUploadList={false}
                                                                accept='.pdf'
                                                                onChange={(e) => handleStudyMaterialUpload(e, topicIndex)}
                                                                style={{ borderColor: "#1089FF", cursor: "pointer", width: "100%", margin: "10px 0px" }}
                                                            >
                                                                <div style={{ textAlign: "center", color: "#636363", fontSize: 14, fontWeight: 600 }}>
                                                                    Drag and drop or <span style={{ color: "#1089FF", margin: "0px 5px" }}>Choose file</span> to Upload
                                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <div style={{ marginRight: 20 }}>Supported file: Pdf</div>
                                                                        {/* <div>Max: 10Mb</div> */}
                                                                    </div>
                                                                </div>
                                                            </Dragger>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <></>}
                                        </>
                                    }
                                </>
                            ))}
                            <div className='r-c-c'>
                                <div style={{
                                    fontSize: 15, color: "#1089FF", fontWeight: "bold", cursor: (studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.name !== '' && studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.file != null) || (studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.name !== '' && studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.quizId != null) ? 'pointer' : 'not-allowed', marginRight: 10,
                                    opacity: (studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.name !== '' && studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.file != null) || (studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.name !== '' && studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.quizId != null) ? 1 : 0.5
                                }} onClick={addStudyMaterialsTopic}><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD TOPIC</span></div>
                                <div style={{ fontSize: 15, color: "#594099", fontWeight: "bold", cursor: "pointer", marginLeft: 10 }}
                                    onClick={() => {
                                        storeQuizData([])
                                        history.push({
                                            pathname: "/course/add-quiz",
                                            state: {
                                                key: {
                                                    fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                    searchedValue: location?.state?.key?.searchedValue,
                                                    fromSubcategory: location?.state?.key?.fromSubcategory,
                                                    fromCourseCard: location?.state?.key?.fromCourseCard,
                                                    fromDashboardSearch: location?.state?.key?.fromDashboard,
                                                    fromCourseDetails: location?.state?.key?.fromCourseDetails,
                                                    isEdit: location?.state?.key?.isEdit,
                                                },
                                                fromStudyMaterial:
                                                {
                                                    SMfilesArray: [...SMfilesArray],
                                                    SMfileIndex: SMfileIndex,
                                                    liveCourseLearningFields,
                                                    studyMaterialsTopics: [
                                                        ...studyMaterialsTopics,
                                                        {
                                                            type: "quiz",
                                                            quizId: null
                                                        }
                                                    ],
                                                }
                                            }
                                        })
                                    }}
                                ><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD QUIZ</span></div>
                            </div>
                        </div>
                    </>
                    :
                    courseTypeName == "videoResources" ?
                        <>
                            {sections?.map((section, index) => {
                                return (
                                    <div className='m-t-10' style={{ width: '100%', height: 'fit-content', padding: 30, border: '1px solid #D9D9D9', backgroundColor: '#F6F4FE' }}>
                                        <div style={{ color: '#636363', fontSize: "12px", fontWeight: "bold" }}>Section name</div>
                                        <div>
                                            <Input
                                                placeholder='Enter section name'
                                                value={section?.name}
                                                onChange={(e) => {
                                                    const updateSections = [...sections];
                                                    updateSections[index].name = e.target.value;
                                                    setSections(updateSections);
                                                }}
                                            /></div>
                                        {section?.topics?.map((item, topicIndex) => {
                                            return (
                                                <>
                                                    <div className='m-t-20' style={{ width: '100%', height: 'fit-content', padding: '20px 30px', border: '1px solid #D9D9D9', backgroundColor: '#FFFFFF' }}>
                                                        {item?.type != 'quiz' ?
                                                            (<>
                                                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                                                                    <div style={{ color: '#636363', fontSize: "12px", fontWeight: "bold" }}>Lecture name</div>

                                                                    <div>
                                                                        <Checkbox
                                                                            disabled={item?.videoType == 'link' ? false : true}
                                                                            onChange={(e) => {
                                                                                const updateTopics = [...sections];
                                                                                updateTopics[index].topics[topicIndex].preview = e.target.checked;
                                                                                setSections(updateTopics);
                                                                            }}
                                                                            checked={item?.preview == 1 || item?.preview == true}
                                                                        />
                                                                        <h style={{ color: '#636363', fontSize: "12px", fontWeight: "bold", marginLeft: "5px" }}>FREE PREVIEW
                                                                            {item?.videoType == 'link' ? null :
                                                                                <Popover content={menu}>
                                                                                    <InfoCircleOutlined style={{ color: "#1089FF", fontSize: '12px', fontWeight: "bold", marginLeft: '5px', marginTop: "2px" }} />
                                                                                </Popover>}</h>
                                                                    </div>
                                                                </div>
                                                                <div className='m-t-10'>
                                                                    <Input
                                                                        placeholder='Enter lesson name'
                                                                        value={item?.name}
                                                                        onChange={(e) => {
                                                                            const updateTopics = [...sections];
                                                                            updateTopics[index].topics[topicIndex].name = e.target.value;
                                                                            setSections(updateTopics);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </>)

                                                            : (<></>)}
                                                        {item?.file || item?.fileUrl || item?.type == 'quiz' ?
                                                            <>
                                                                <div style={{ display: "flex" }}>
                                                                    <div style={{ height: "35px", width: "100%", border: '1px solid #E6E6E6', marginTop: "13px", alignItems: "center" }}>
                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                            <div style={{ display: "flex" }}>
                                                                                {
                                                                                    item?.type == 'quiz' ?
                                                                                        (<>
                                                                                            <img src={quiz} style={{ width: "25px", height: "25px", marginLeft: "10px", marginTop: "5px" }} />
                                                                                        </>) : (<>
                                                                                            {item?.type == 'notes' ?
                                                                                                (<>
                                                                                                    <FilePdfOutlined style={{ color: "#FF414D", marginLeft: 10, fontSize: 20, marginTop: "5px" }} />
                                                                                                </>) :
                                                                                                (<>
                                                                                                    <img src={video} style={{ width: "30px", height: "30px", marginLeft: "10px" }} />
                                                                                                </>)}</>)}



                                                                                <div style={{ marginTop: "5px" }}>
                                                                                    <Typography.Text
                                                                                        ellipsis={{ tooltip: true }}
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            color: "#AEAEAE",
                                                                                            fontWeight: 700,
                                                                                            maxWidth: 600,
                                                                                            width: '200px',
                                                                                            marginLeft: "20px"
                                                                                        }}
                                                                                    >
                                                                                        {console.log("check", item?.type, item?.name)}
                                                                                        {item?.type == 'quiz' ?
                                                                                            (<>{item?.name}</>) :
                                                                                            (<>
                                                                                                {item?.fileName ? (<>File : {item?.fileName}</>) : item?.videoType === 'recorded' ? (<>Broadcast : {item?.fileName}</>) : item?.type == 'notes' ? (<>Pdf : {item?.fileName}</>) : (<>Youtube</>)}
                                                                                            </>)}
                                                                                    </Typography.Text>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ color: '#AEAEAE', fontSize: "12px", marginTop: "6px", marginRight: "20px" }}>
                                                                                {item?.file?.size ? (<>{formatFileSize(item?.file?.size)}</>) : (<></>)}

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    {item?.type == 'quiz' ?
                                                                        (<>
                                                                            <div style={{ display: "flex" }} >
                                                                                <EditOutlined
                                                                                    onClick={() => {
                                                                                        storeQuizData([])
                                                                                        getEditQuizData(item?.quizId, () => { })
                                                                                        history.push({
                                                                                            pathname: "/course/add-quiz",
                                                                                            state: {
                                                                                                fromVideoResourceSection:
                                                                                                {
                                                                                                    updatedSections: sections,
                                                                                                    editTrue: true,
                                                                                                    liveCourseLearningFields,
                                                                                                    liveCourseIncludesFields,
                                                                                                    liveCoursePrerequisitesFields,
                                                                                                    increase: increase,
                                                                                                    isEdit: location?.state?.key?.isEdit,
                                                                                                    topicIndex: topicIndex,
                                                                                                    quizId: item?.quizId
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                    style={{ color: '#1089FF', cursor: 'pointer', fontSize: 18, marginLeft: 20, marginTop: "20px" }}
                                                                                />
                                                                                < MinusCircleOutlined
                                                                                    className='m-t-5'
                                                                                    style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20, marginTop: "20px" }}
                                                                                    onClick={() => handleRemoveVRTopicField(index, topicIndex, true)}
                                                                                />
                                                                            </div>
                                                                        </>)
                                                                        :

                                                                        (<>
                                                                            {isStatus == true ? (<>
                                                                                <MinusCircleOutlined
                                                                                    style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20, marginTop: "20px" }}
                                                                                    onClick={() => handleRemoveVRTopicField(index, topicIndex, false)}
                                                                                /></>) : null}

                                                                        </>)
                                                                    }
                                                                    {console.log("isStatus", isStatus)}
                                                                </div>
                                                            </> :
                                                            <div className='m-t-20' style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>
                                                                <div style={{ height: "60px", width: '30%' }}>
                                                                    <Dragger
                                                                        showUploadList={false}
                                                                        onChange={(e) => { handleResourceUpload(e, index, topicIndex), setIsType('file'),setIsStatus(false) }}
                                                                        accept={".mp4,.avi,.mkv,.pdf,.mov"}
                                                                        style={{ borderColor: "#1089FF", cursor: "pointer", margin: "10px 0px", width: "100%" }}
                                                                    >
                                                                        <div className='r-c-c' style={{ color: "#636363", fontSize: "14", fontWeight: 600, marginTop: "-8px" }}>Drag & drop or <span style={{ color: "#1089FF", margin: "0px 2px " }}> Choose file </span></div>
                                                                        <div><span style={{ color: "#636363", opacity: '0.5' }}>(Pdf,mp4,avi,flv,mov,mkv)</span></div>
                                                                    </Dragger>
                                                                </div>
                                                                <div style={{ height: "60px", width: "30%", border: '1px solid #E6E6E6', marginTop: "10px" }}>
                                                                    <div style={{ textAlign: "center", marginTop: "5px", cursor: 'disabled' }}>
                                                                        <div
                                                                            onClick={(e) => {
                                                                                null
                                                                                // const updateTopics = [...sections];
                                                                                // updateTopics[index].topics[topicIndex].type = 'video';
                                                                                // updateTopics[index].topics[topicIndex].videoType = 'recorded';
                                                                                // setSections(updateTopics);
                                                                                // history.push({
                                                                                //     pathname: "/digital-library/division-card",
                                                                                //     state: {
                                                                                //         fromBrodcastVR:
                                                                                //         {
                                                                                //             redirectingPageDL: true,
                                                                                //             sections: sections,
                                                                                //         }
                                                                                //     }
                                                                                // })
                                                                            }}
                                                                        >
                                                                            <div style={{ color: "#636363", fontSize: "14", fontWeight: 600 }}>Choose files from</div>
                                                                            <div style={{ color: "#1089FF", fontSize: "14", fontWeight: 600 }}> Broadcast Recordings</div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div style={{ marginTop: "7px", width: "30%", }}>

                                                                    <div style={{ color: "#636363", fontSize: "12px", fontWeight: "bold" }}>YouTube Link</div>
                                                                    <Input
                                                                        style={{ marginTop: "8px", width: "100%" }}
                                                                        placeholder='Enter YouTube link'
                                                                        suffix={<PlusCircleOutlined
                                                                            onClick={() => {
                                                                                const updateTopics = [...sections];
                                                                                updateTopics[index].topics[topicIndex].fileUrl = youtubeLink;
                                                                                updateTopics[index].topics[topicIndex].fileIndex = -1;
                                                                                updateTopics[index].topics[topicIndex].type = 'video';
                                                                                updateTopics[index].topics[topicIndex].videoType = 'link';

                                                                                setSections(updateTopics)
                                                                            }} />}
                                                                        onChange={(e) => { setYouTubeLink(e.target.value) }}
                                                                    />
                                                                    <div>
                                                                        {youtubeLink === null || youtubeLink === "" || youtubeLink?.match(regExp) ? null : (<h style={{ color: "#FF414D" }}>Invalid YouTube Link</h>)}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        }
                                                    </div >
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        {topicIndex === section.topics.length - 1 && (
                                                            <>
                                                                <div className='m-t-20 m-b-20' style={{ fontSize: 15, color: "#1089FF", fontWeight: "bold", cursor: "pointer", textAlign: "center" }} onClick={() => { addLesson(index) }}><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD LECTURES</span></div>
                                                                <div className='m-t-20 m-b-20' style={{ textAlign: 'center', color: '#594099', fontSize: "14px", fontWeight: "bold", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        storeQuizData([])
                                                                        let targetSection = sections[index]
                                                                        let newTopic = {
                                                                            name: 'quiz',
                                                                            type: "quiz",
                                                                            quizId: null
                                                                        }
                                                                        let updatedTopics = [...targetSection.topics, newTopic];
                                                                        let updatedSection = {
                                                                            ...targetSection,
                                                                            topics: updatedTopics
                                                                        };
                                                                        history.push({
                                                                            pathname: "/course/add-quiz",
                                                                            state: {
                                                                                key: {
                                                                                    fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                                                    searchedValue: location?.state?.key?.searchedValue,
                                                                                    fromSubcategory: location?.state?.key?.fromSubcategory,
                                                                                    fromCourseCard: location?.state?.key?.fromCourseCard,
                                                                                    fromDashboardSearch: location?.state?.key?.fromDashboard,
                                                                                    fromCourseDetails: location?.state?.key?.fromCourseDetails,
                                                                                    isEdit: location?.state?.key?.isEdit,
                                                                                },
                                                                                fromVideoResourceSection:
                                                                                {
                                                                                    liveCourseLearningFields,
                                                                                    liveCourseIncludesFields,
                                                                                    liveCoursePrerequisitesFields,
                                                                                    videoFile: [...videoFile],
                                                                                    increase: increase,
                                                                                    updatedSections: [
                                                                                        ...sections.slice(0, index),
                                                                                        updatedSection,
                                                                                        ...sections.slice(index + 1)
                                                                                    ],
                                                                                }
                                                                            }
                                                                        })
                                                                    }}><PlusCircleOutlined style={{ color: '#594099', marginLeft: "15px", fontWeight: "bold" }} />{" "}{"  "}{""}ADD QUIZ</div>
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                            <div className='r-c-c m-t-20 m-b-20' style={{ fontSize: 15, color: "#1089FF", fontWeight: "bold", cursor: "pointer" }} onClick={addSection}><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD SECTION</span></div>
                        </>
                        :
                        <>
                            {/* {subjects?.map((subject, subjectIndex) => (
                                <div key={subjectIndex} className='m-t-20' style={{ width: '100%', height: 'fit-content', padding: 30, border: '1px solid #d9d9d9', backgroundColor: '#F6F4FE' }}>
                                    <div className='m-b-5' style={inputHeadingStyle}>Subject name</div>
                                    <Input
                                        placeholder='Enter subject name'
                                        value={subject.name}
                                        onChange={(e) => {
                                            const updatedSubjects = [...subjects];
                                            updatedSubjects[subjectIndex].name = e.target.value;
                                            setSubjects(updatedSubjects);
                                        }}
                                    />
                                    <div className='m-t-20' style={{ width: '100%', height: 'fit-content', padding: '20px 30px', border: '1px solid #d9d9d9', backgroundColor: '#FFFFFF' }}>
                                        <div className='m-b-5' style={inputHeadingStyle}>Chapter(s)</div>
                                        {subject.chapters.map((chapter, chapterIndex) => (
                                            <div key={chapterIndex} style={{ display: 'flex', justifyContent: 'space-between', marginTop: chapterIndex === 0 ? '' : 10 }}>
                                                <Input
                                                    style={{ width: '97%' }}
                                                    placeholder={`Sample: Students learn coding for Ethical Hacking`}
                                                    value={chapter.name}
                                                    onChange={(e) => handleChapterNameChange(subjectIndex, chapterIndex, e.target.value)}
                                                />
                                                {chapterIndex === subject.chapters.length - 1 ? (
                                                    <PlusCircleOutlined
                                                        className='m-t-5'
                                                        style={{ color: '#1089FF', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                        onClick={() => handleAddChaptersField(subjectIndex)}
                                                    />
                                                ) : (
                                                    <MinusCircleOutlined
                                                        className='m-t-5'
                                                        style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                        onClick={() => handleRemoveChaptersField(subjectIndex, chapterIndex)}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))} */}

                            {/* <Collapse
                                accordion
                                activeKey={activeKey}
                                onChange={(keys) => setActiveKey(keys)}
                            >
                                {subjects?.map((subject, subjectIndex) => (
                                    <Collapse.Panel
                                        key={subjectIndex.toString()}
                                        header={
                                            <div className='m-t-20' style={{ width: '100%', height: 'fit-content', padding: 30, border: '1px solid #d9d9d9', backgroundColor: '#F6F4FE' }}>
                                                <div className='m-b-5' style={inputHeadingStyle}>Subject name</div>
                                                <Input
                                                    placeholder='Enter subject name'
                                                    value={subject.name}
                                                    onChange={(e) => {
                                                        const updatedSubjects = [...subjects];
                                                        updatedSubjects[subjectIndex].name = e.target.value;
                                                        setSubjects(updatedSubjects);
                                                    }}
                                                />
                                            </div>
                                        }
                                        style={{ marginBottom: '15px' }}
                                    >
                                        <div className='m-t-20' style={{ width: '100%', height: 'fit-content', padding: '20px 30px', border: '1px solid #d9d9d9', backgroundColor: '#FFFFFF' }}>
                                            <div className='m-b-5' style={inputHeadingStyle}>Chapter(s)</div>
                                            {subject.chapters.map((chapter, chapterIndex) => (
                                                <div key={chapterIndex} style={{ display: 'flex', justifyContent: 'space-between', marginTop: chapterIndex === 0 ? '' : 10 }}>
                                                    <Input
                                                        style={{ width: '97%' }}
                                                        placeholder={`Sample: Students learn coding for Ethical Hacking`}
                                                        value={chapter.name}
                                                        onChange={(e) => handleChapterNameChange(subjectIndex, chapterIndex, e.target.value)}
                                                    />
                                                    {chapterIndex === subject.chapters.length - 1 ? (
                                                        <PlusCircleOutlined
                                                            className='m-t-5'
                                                            style={{ color: '#1089FF', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                            onClick={() => handleAddChaptersField(subjectIndex)}
                                                        />
                                                    ) : (
                                                        <MinusCircleOutlined
                                                            className='m-t-5'
                                                            style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                            onClick={() => handleRemoveChaptersField(subjectIndex, chapterIndex)}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </Collapse.Panel>
                                ))}
                            </Collapse> */}

                            <Collapse
                                accordion
                                activeKey={activeKey}
                                onChange={(keys) => setActiveKey(keys)}
                                className="custom-collapse" // Add a custom class for styling
                                style={{ border: 'none' }}
                            >

                                {subjects?.map((subject, subjectIndex) => (
                                    <Collapse.Panel
                                        // key={subjectIndex.toString()}
                                        header={
                                            <div className='m-t-20' style={{ width: '100%', height: 'fit-content', padding: 30, backgroundColor: '#F6F4FE' }}>
                                                <div className='m-b-5' style={inputHeadingStyle}>Subject name</div>
                                                <Input

                                                    placeholder='Enter subject name'
                                                    value={subject.name}
                                                    onClick={(e)=>{ e.stopPropagation()}}
                                                    onChange={(e) => {
                                                       
                                                        const updatedSubjects = [...subjects];
                                                        updatedSubjects[subjectIndex].name = e.target.value;
                                                        setSubjects(updatedSubjects);
                                                        setValueData(true)
                                                    }}
                                                />
                                            </div>
                                        }
                                        style={{ marginBottom: '20px', border: '1px solid #E6E6E6' }} // Remove the border around each panel
                                        extra={
                                            activeKey?.includes(subjectIndex.toString()) ? (
                                                <UpOutlined style={{ fontSize: 18, color: '#1890FF', cursor: 'pointer' }} />
                                            ) : (
                                                <DownOutlined style={{ fontSize: 18, color: '#1890FF', cursor: 'pointer' }} />
                                            )
                                        }
                                    >
                                        <div className='m-t-20' style={{ width: '100%', height: 'fit-content', padding: '20px 30px', backgroundColor: '#FFFFFF' }}>
                                            <div className='m-b-5' style={inputHeadingStyle}>Chapter(s)</div>
                                            {subject.chapters.map((chapter, chapterIndex) => (
                                                <div key={chapterIndex} style={{ display: 'flex', justifyContent: 'space-between', marginTop: chapterIndex === 0 ? '' : 10 }}>
                                                    <Input
                                                        style={{ width: '97%' }}
                                                        placeholder={`Sample: Students learn coding for Ethical Hacking`}
                                                        value={chapter.name}
                                                        onChange={(e) => handleChapterNameChange(subjectIndex, chapterIndex, e.target.value)}
                                                    />
                                                    {chapterIndex === subject.chapters.length - 1 ? (
                                                        <PlusCircleOutlined
                                                            className='m-t-5'
                                                            style={{ color: '#1089FF', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                            onClick={() => handleAddChaptersField(subjectIndex)}
                                                        />
                                                    ) : (
                                                        <MinusCircleOutlined
                                                            className='m-t-5'
                                                            style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                            onClick={() => handleRemoveChaptersField(subjectIndex, chapterIndex)}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </Collapse.Panel>
                                ))}
                            </Collapse>



                            <div className='r-c-c m-t-20 m-b-20' style={{ fontSize: 15, color: "#1089FF", fontWeight: "bold", cursor: "pointer" }} onClick={addSubject}><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD SUBJECT</span></div>
                        </>
                }
                {courseTypeName == "onlineTestSeries" ?
                    (<>
                        <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold", margin: "20px 0px" }}>Exam Pattern</div>
                        <ReactQuill
                            className='annocnementDescription link-div'
                            theme='snow'
                            style={{ height: '100%' }}
                            modules={modules}
                            value={courseCreateObj?.examPattern ? JSON.parse(courseCreateObj?.examPattern).mobile : ''}
                            onChange={(content, delta, source, editor) => handleOnlineTestDescription(content, delta, source, editor)}
                            ref={el => quillExam = el}
                            placeholder="Add description"
                            bounds={'.annocnementDescription'}
                            onKeyUp={(e) => handleOnlineTestDescriptionkeyUp(e)}
                        />
                        {!specificCourseDataContainer?.testData?.length ?
                            <>
                                <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold", margin: "20px 0px" }}>Online Tests</div>
                                <div className='m-t-20' style={{ width: '100%', height: 'fit-content', padding: 30, border: '1px solid #d9d9d9', backgroundColor: '#F6F4FE' }}>
                                    <div className='m-b-2' style={{ backgroundColor: '#fff', display: 'flex' }}>
                                        <div>
                                            <img src={createTestPic} style={{ margin: 30, height: 150 }} />
                                        </div>
                                        <div>
                                            <div style={{ color: '#636363', fontWeight: 'bold', fontSize: 18, marginTop: 45 }}>
                                                Start Creating Test
                                            </div>
                                            <div style={{ color: '#636363', marginTop: 5 }}>
                                                Start creating tests. You can create comprehensive tests, true/false tests, and multiple-choice tests. You can also import tests and marking schemes from the resource panel.
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{
                                                    color: '#594099', marginTop: 5, fontWeight: 'bold',
                                                    cursor: (disableCreateUpdateBtn || disableCreateTestBtn) ? 'default' : 'pointer',
                                                    opacity: (disableCreateUpdateBtn || disableCreateTestBtn) ? 0.6 : 1
                                                }}
                                                    onClick={(disableCreateTestBtn || disableCreateUpdateBtn) ? () => { } : () => {
                                                        let hasError = false;
                                                        if (!hasError) {
                                                            setIndividualTestId(null);
                                                            setWhichClick("test")
                                                            setConfirmSaveDetailsModalVisible(true);
                                                        }
                                                    }}
                                                >
                                                    <PlusCircleOutlined
                                                        className='m-t-5'
                                                        style={{ color: '#594099', fontSize: 13, marginRight: 7 }}

                                                    />
                                                    CREATE TEST</div>
                                                <div
                                                    style={{
                                                        color: '#594099',
                                                        fontWeight: 'bold',
                                                        marginTop: 7,
                                                        marginLeft: 20,
                                                        cursor: (disableCreateUpdateBtn) ? 'default' : 'pointer',
                                                        opacity: (disableCreateUpdateBtn) ? 0.6 : 1
                                                    }}
                                                    onClick={() => {
                                                        disableCreateUpdateBtn ?
                                                            null :
                                                            (setWhichClick("browse"), setConfirmSaveDetailsModalVisible(true))
                                                    }}
                                                >
                                                    <SearchOutlined style={{ marginRight: 7 }} />
                                                    BROWSE
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </>
                            :
                            <>
                                <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold", margin: "20px 0px" }}>Tests</div>

                                <div style={{ width: '100%', height: 'fit-content', border: '1px solid #d9d9d9', backgroundColor: '#F6F4FE', padding: 40, cursor: 'pointer' }}>
                                    <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'space-between', paddingLeft: 55, paddingRight: 55, paddingTop: 45 }}>
                                        {specificCourseDataContainer?.testData?.map((item) => {
                                            return (
                                                <div style={{ backgroundColor: "#fff", width: '48%', height: 'fit-content', marginBottom: 40, paddingBottom: 20 }}
                                                    onClick={() => {
                                                        setIndividualTestId(item.id)
                                                        setWhichClick("test")
                                                        // setConfirmSaveDetailsModalVisible(true)
                                                        checkUpdateCardClick()

                                                    }}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ marginTop: 20 }}>
                                                            <Tooltip title={item?.title}>
                                                                <span style={{
                                                                    fontWeight: 'bold',
                                                                    marginTop: 20,
                                                                    marginLeft: 20,
                                                                    width: '70%',

                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>
                                                                    {item?.title}
                                                                </span>


                                                            </Tooltip>
                                                            {/* <Tooltip title={"Test creation in progress"} placement='right'>
                                                                {item?.totalQuestions === 0 ? <span style={{ color: '#1089FF', marginLeft: '5px' }}><InfoCircleOutlined /></span> : <></>}

                                                            </Tooltip> */}
                                                        </div>
                                                        <div
                                                            style={{ fontSize: 15, color: '#AEAEAE', marginTop: 9, width: '5%' }}
                                                            onClick={(e) => {
                                                                setIndividualTestId(item.id)
                                                                e.stopPropagation()
                                                                setConfirmDeleteModalVisible(true)
                                                            }}
                                                        ><CloseOutlined />
                                                        </div>

                                                    </div>

                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div>
                                                            <div style={{ color: '#636363', marginTop: 20, marginLeft: 20 }}> <QuestionCircleOutlined /> <h style={{ marginLeft: 5 }}>{item?.totalQuestions} Questions</h></div>
                                                            <div style={{ color: '#636363', marginLeft: 20 }}> <ProfileOutlined /><h style={{ marginLeft: 9 }}>{item?.totalMarks} Marks</h></div>
                                                            <div style={{ color: '#636363', marginLeft: 20 }}><ClockCircleOutlined /><h style={{ marginLeft: 9 }}>{item?.duration} Minutes</h></div>
                                                        </div>
                                                        <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
                                                            {/* <div className={`custom-container ${item?.totalQuestions === 0 ? 'red-text' : 'blue-text'}`}>
                                                                {item?.freePreview || item?.totalQuestions === 0 ? (
                                                                    <div></div>
                                                                ) : (
                                                                    <LockOutlined style={{ marginLeft: '50%' }} />
                                                                )}
                                                           
                                                            </div> */}
                                                            <div style={{ marginLeft: '2%' }}>
                                                                {item?.publishDate && item?.totalQuestions > 0 ?


                                                                    new Date(`${item?.publishDate}T${item?.startTime}`) <= new Date() ?


                                                                        (
                                                                            <div style={{ fontSize: 12, marginLeft: '20%', marginTop: '60px', color: '#594099' }}>
                                                                                Published on {moment(item?.publishDate).format('DD-MM-YYYY')},{moment(item?.startTime, 'HH:mm').format('hh:mm A')}
                                                                            </div>
                                                                        )

                                                                        :

                                                                        (
                                                                            <div style={{ fontSize: 12, marginLeft: '20%', marginTop: '60px', color: '#594099' }}>
                                                                                Publishing on {moment(item?.publishDate).format('DD-MM-YYYY')},{moment(item?.startTime, 'HH:mm').format('hh:mm A')}
                                                                            </div>
                                                                        )

                                                                    :

                                                                    item?.totalQuestions === 0 ?
                                                                        <div style={{ fontSize: 12, marginLeft: '20%', marginTop: '60px', color: 'red' }}>
                                                                            Test creation in Progress
                                                                        </div>
                                                                        :
                                                                        <></>

                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                                        <div
                                            style={{
                                                color: '#594099',
                                                marginTop: 6, fontWeight: 'bold', textAlign: 'center',
                                                cursor: disableCreateUpdateBtn ? 'default' : 'pointer',
                                                opacity: disableCreateUpdateBtn ? 0.6 : 1
                                            }}
                                            onClick={disableCreateUpdateBtn ? () => { } : () => {
                                                setIndividualTestId(null)
                                                setWhichClick("test")
                                                setConfirmSaveDetailsModalVisible(true)
                                            }}
                                        >
                                            <PlusCircleOutlined className='m-t-5' style={{ color: '#594099', fontSize: 13, marginRight: 7 }} />
                                            CREATE TEST</div>

                                        <div style={{ color: '#594099', fontWeight: 'bold', marginTop: 7, marginLeft: 20, cursor: 'pointer' }}
                                            onClick={() => {
                                                setWhichClick("browse")
                                                setConfirmSaveDetailsModalVisible(true)
                                            }}
                                        >  <SearchOutlined style={{ marginRight: 7 }} />BROWSE</div>
                                    </div>

                                </div>
                            </>
                        }


                    </>)
                    : <></>
                }
            </div>
            {
                isSuccessModalVisible &&
                <SuccessModal
                    isSuccessModalVisible={isSuccessModalVisible}
                    setSuccessModalVisible={setSuccessModalVisible}
                    courseTypeName={courseTypeName} />
            }
            {isConfirmPublishModalVisible &&
                <ConfirmPublishOrUnpublishModal
                    isConfirmPublishModalVisible={isConfirmPublishModalVisible}
                    setIsConfirmPublishModalVisible={setIsConfirmPublishModalVisible}
                    togglePageLoader={togglePageLoader}
                    courseTypeName={courseTypeName}
                    getSpecificCourseData={getSpecificCourseData}
                    getSpecificSMData={getSpecificSMData}
                    getSpecificVideoCourseData={getSpecificVideoCourseData}
                    fromEditCoursePage={true}
                />}

            {isConfirmDeleteModalVisible &&
                <CourseDeleteConfirmModal
                    isConfirmDeleteModalVisible={isConfirmDeleteModalVisible}
                    setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
                    togglePageLoader={togglePageLoader}
                    isFromSubjects={true}
                    courseTypeName={courseTypeName}
                    fromTestSeries={true}
                />
            }

            {isConfirmSaveDetailsModalVisible &&
                <ConfirmSaveDetailsModal
                    isConfirmSaveDetailsModalVisible={isConfirmSaveDetailsModalVisible}
                    setConfirmSaveDetailsModalVisible={setConfirmSaveDetailsModalVisible}
                    togglePageLoader={togglePageLoader}
                    postOnlineTestSeries={postOnlineTestSeries}
                    subjects={subjects}
                    handleBrowseClick={handleBrowseClick}
                    whichClick={whichClick}
                />
            }
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}
const mapStateToProps = (state) => {
    const { storeStreamId } = state.digitalLibrary;
    const { courseTypeName, courseCreateObj, courseCategoryData, courseSubCategoriesData, specificCourseDataContainer, totalTagsData, Pdftoupload, fileKey, storeOnlineTestData, storeOnlineTestDetails } = state.courseManagement;
    const { getExaminerArr, getExaminerBool } = state.testAdmin

    return {
        courseTypeName, courseCreateObj, getExaminerArr, getExaminerBool, courseCategoryData, courseSubCategoriesData, specificCourseDataContainer, totalTagsData, storeStreamId, Pdftoupload, fileKey, storeOnlineTestData, storeOnlineTestDetails
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCreateCourseObj: (key, val) => dispatch(setCreateCourseObj(key, val)),
    getExaminer: (text) => dispatch(getExaminer(text)),
    getCoursesCategories: () => dispatch(getCoursesCategories()),
    getCoursesSubCategories: (id) => dispatch(getCoursesSubCategories(id)),
    UploadCourseDocument: (file, isType, value, successCallback) => dispatch(UploadCourseDocument(file, isType, value, successCallback)),
    deleteCourseDocument: (key, isType) => dispatch(deleteCourseDocument(key, isType)),
    postLiveCourseCreate: (subjects, callBack, successCallBack) => dispatch(postLiveCourseCreate(subjects, callBack, successCallBack)),
    getSpecificCourseData: (callback) => dispatch(getSpecificCourseData(callback)),
    postCreateCategory: (name, callBack, succesCallback, sendId, id, fromAddCourse) => dispatch(postCreateCategory(name, callBack, succesCallback, sendId, id, fromAddCourse)),
    postCreateSubCategory: (name, callBack, succesCallback, sendId, id, isEditSubCategoryModal, categoryId, fromAddCourse) => dispatch(postCreateSubCategory(name, callBack, succesCallback, sendId, id, isEditSubCategoryModal, categoryId, fromAddCourse)),
    postStudyMaterialsCreate: (studyMaterialsTopics, SMfilesArray, callBack, successCallBack) => dispatch(postStudyMaterialsCreate(studyMaterialsTopics, SMfilesArray, callBack, successCallBack)),
    getSpecificSMData: (callback) => dispatch(getSpecificSMData(callback)),
    postVideoResourceCreate: (sections, videoFile, callback, successCallBack) => dispatch(postVideoResourceCreate(sections, videoFile, callback, successCallBack)),
    getSpecificVideoCourseData: (callback) => dispatch(getSpecificVideoCourseData(callback)),
    getOnlineTestSeriesCourseDetails: (callback) => dispatch(getOnlineTestSeriesCourseDetails(callback)),
    postOnlineTestSeries: (subjects, isEdit, callBack, successCallBack) => dispatch(postOnlineTestSeries(subjects, isEdit, callBack, successCallBack)),
    getOnlineTestSeries: (callback) => dispatch(getOnlineTestSeries(callback)),
    getTags: (searchedTag, callBack) => dispatch(getTags(searchedTag, callBack)),
    postCreateTags: (searchedTag, callBack) => dispatch(postCreateTags(searchedTag, callBack)),
    storeSpecificCourseData: (val) => dispatch(storeSpecificCourseData(val)),
    deleteQuiz: (id) => dispatch(deleteQuiz(id)),
    uploadBlock2: (params, callback) => dispatch(uploadBlock2(params, callback)),
    MergeChunk: (fileId, callback) => dispatch(MergeChunk(fileId, callback)),
    fileKeyUpdate: (key) => dispatch(fileKeyUpdate(key)),
    updatepdf: (data) => dispatch(updatepdf(data)),
    getEditQuizData: (id, callback) => dispatch(getEditQuizData(id, callback)),
    storeQuizData: (val) => dispatch(storeQuizData(val)),
    setIndividualTestId: (data) => dispatch(setIndividualTestId(data)),
    setOnlineTestSeriesId: (data) => dispatch(setOnlineTestSeriesId(data)),
    setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddCoursePage);