import '../styles.css';
import React, { Component } from 'react';
import Typography from 'antd/lib/typography';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import 'antd/lib/typography/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/input/style/index';
import {  MdClose } from "react-icons/md";
import { Color } from 'services';
import {connect} from 'react-redux';
import {toggleAddChapterModal, postChapters} from 'actions/classRoom';


const { Title } = Typography;


class AddChapterModal extends Component {
    constructor(){
      super();
      this.state = {
        chapterNames: [''],
        buttonLoader: false
      }
    }

    componentWillUnmount(){
      // this.props.changeDetail({name: "", section: "", stream: ""})
    }

    handleChapterNameChange(e, index){
      const chapterNames = this.state.chapterNames;
      if(e.target.value!==' '){

        chapterNames[index] = e.target.value;
      }
      this.setState({chapterNames});
    }

    handleChapterAdd(){
      const chapterNames = this.state.chapterNames;
      chapterNames.push('');
      this.setState({chapterNames});
    }

    handleAddClick(){
      this.setState({buttonLoader: true})
      const chapters = this.state.chapterNames.filter(chapter => chapter !== "");
      this.props.postChapters(chapters, () => this.setState({buttonLoader: false}))
    }

    render () {
      const { toggleAddChapterModal, showAddChapterModal, selectedSubject } = this.props;
      const {chapterNames, buttonLoader} = this.state;

      return (
        <Modal
          visible={showAddChapterModal}
          className='modal-border-15'
          width={600}
          closable={false}
          onCancel={() => {}}
          footer={null}
          centered
        >
          <div>
            <div className='r-c-sb'>
              <Title style={{color: Color.primary, margin:0}} level={4}>Add Chapter(s)</Title>
              <div className='cursor-pointer' onClick={() => toggleAddChapterModal(false)}><MdClose className='text-md dark-grey'/></div>
            </div>
            <div style={{fontSize: 12, color: Color.darkGrey}}> <span className="bold-600">{selectedSubject?.name}</span></div>
            <div className='m-b-20 m-t-30'>
              <div className='flex-1'>
                <div className='color-black text-xs m-b-5 bold-600'>Chapter Name</div>
                <div style={{maxHeight: 200, overflowY: 'auto'}}>
                  {chapterNames.map((chapter, index) => 
                    <Input className=' m-t-10' placeholder="What’s the name of the chapter?" 
                    value={chapter} onChange={(e) => this.handleChapterNameChange(e, index)}/>)}
                  </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Button type='link' className='text-xmd bold-600 text-right blue cursor-pointer m-t-15'
                    onClick={() => this.handleChapterAdd()}
                    disabled={this.state.chapterNames?.some(chapter => chapter === '')}
                  >+ Add Another Chapter</Button>
                </div>
            </div>
            <div className='r-c-c m-t-40'>
              <Button loading={buttonLoader} type='primary' className='btn-radius-15__height-38'  
                style={{width:'120px'}}
                disabled={!this.state.chapterNames.filter(chapter => chapter !== "").length}
                onClick={() => this.handleAddClick()}
              >
                Add
              </Button>
            </div>
          </div>
        </Modal>
      )
    }
}


const mapStateToProps = state => {
  const {
    showAddChapterModal, selectedSubject
  } = state.classRoom;
  return {
    showAddChapterModal, selectedSubject
  };
};

const mapDispatchToProps = dispatch => ({
  toggleAddChapterModal: (bool) => dispatch(toggleAddChapterModal(bool)),
  postChapters: (chapters, callback) => dispatch(postChapters(chapters, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddChapterModal);