import React, { useEffect, useState } from 'react';
// import { NoData, newCardStyle } from "./courseInformationPage";
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button } from 'components/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SquareSubIcon } from 'components/subIcon';
import { Divider, Skeleton, Typography } from 'antd';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import CourseDeleteConfirmModal from 'pages/courseModule/courseDeleteConfirmModal';
import CreateSubjectsModal from 'pages/courseModule/createSubjectsModal';

export const newCardStyle = {
    backgroundColor: "#FFFFFF",
    padding: "24px",
    boxShadow: "4px 4px 16px #0000000F",
    borderRadius: "17px",
    width: "100%"
}
const SingleSubjectCard = ({ item, setCreateSubjectModalVisible, setEditSubjectVisible, setShowEditChapters, hasMarketingPrivilege  }) => {
    console.log("item", item)
    return (
        <div className="box-sequare__subject" style={{ height: 300, width: 235, }}>
            <div className="r-ac">
                <SquareSubIcon name={item?.subject?.name} size={24} />
                <div className="m-l-10">
                    <div className='text-xmd dark-black bold-600 text-overflow' style={{ width: 150, textTransform: 'uppercase' }}>{item?.subject?.name}</div>
                    <div style={{ color: "#636363", fontSize: 12 }}>
                        {item?.subject?.testSeriesChapter ? item?.subject?.testSeriesChapter?.length : 0} Chapters
                    </div>
                </div>
            </div>
            <Divider style={{ margin: "10px 0px" }} />
            <div className="box-sequare__subject-chapter-wrap">
                <div>
                    {item?.subject?.testSeriesChapter && item?.subject?.testSeriesChapter?.length
                        ? item?.subject?.testSeriesChapter
                            .filter((chap, i) => i < 6)
                            .map((chapter, chapIndex) => (
                                <div className="r-ac m-b-5">
                                    <div className=" m-r-15" style={{ color: "#636363", fontSize: 13 }}>
                                        Chapter {chapIndex + 1}
                                    </div>
                                    <div className="dark-black bold-600 text-overflow" style={{ fontSize: 13 }}>
                                        {chapter.name}
                                    </div>
                                </div>
                            ))
                        : null}
                </div>
                {item?.subject?.testSeriesChapter?.length > 4 ? (
                    <div
                        className=" bold-600 text-center blue cursor-pointer"
                        onClick={() => {
                            setEditSubjectVisible(true)
                            setCreateSubjectModalVisible(true);
                            setShowEditChapters(item)
                        }}
                        style={{ fontSize: 13 }}
                    >
                        VIEW ALL
                    </div>
                ) : (
                    hasMarketingPrivilege ? <></> :
                    <div
                        className="bold-600 text-center blue cursor-pointer"
                        style={{ fontSize: 13 }}
                        onClick={() => {
                            setEditSubjectVisible(true)
                            setCreateSubjectModalVisible(true)
                            setShowEditChapters(item)
                        }}
                    >
                        <PlusCircleOutlined /> ADD CHAPTERS
                    </div>
                )}
            </div>
        </div>
    )
}
const AddSubjectButton = ({ setCreateSubjectModalVisible, history, setEditSubjectVisible, kii }) => (
    <div key={1} style={{ display: "flex", justifyContent: "space-between" ,marginRight:"10px"}}>
        <div
            style={{ color: "#594099", backgroundColor: "#FFFFFF", marginRight: 20, height: 28, fontWeight: "bold", cursor: "pointer" }}
            onClick={() => history.push({ pathname: "/classroom/resources", state: { key: kii } })}><SearchOutlined style={{ marginRight: 5 }} />BROWSE</div>
        <div
            style={{ color: "#1089FF", height: 28, fontWeight: "bold", cursor: "pointer" }}
            onClick={() => { setEditSubjectVisible(false); setCreateSubjectModalVisible(true) }} >
            <PlusCircleOutlined style={{ color: "#1089FF", marginRight: 5 }} />CREATE</div>

    </div>
);

function TestSyllabus({ specificCourseDataContainer, isCoursePlanningEditClicked, setIsCoursePlanningEditClicked, getSpecificCourseData, deleteSubject, addSubjects, getOnlineTestSeriesCourseDetails, addSubjectFromOnlineTest, setIsExamPatern, isSyllabas, setIsSyllabas, courseTypeName, isTestExpan, setIsTestExpand, hasMarketingPrivilege  }) {
    const [isEditSubjectVisible, setEditSubjectVisible] = useState(false);
    const [isCreateSubjectModalVisible, setCreateSubjectModalVisible] = useState(false);
    const [showEditChapters, setShowEditChapters] = useState([]);
    const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
    const [isFromSubjects, setIsFromSubjects] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);



    const history = useHistory();
    return (
        <div>
            <div style={{ ...newCardStyle, marginTop: 20, height: isSyllabas ? '320px' : 76, transition: "height 0.2s ease" }} className='course-planning-card '>
                {isSyllabas ?
                    <>
                        <div className='r-c-sb'>
                            <div className='r-c-sb' style={{ width: "45%" }}>
                                <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>Syllabus</div>
                                <div style={{ color: "#636363", fontWeight: "normal" }}>{specificCourseDataContainer?.testSeriesDetails?.testSubjectCount} Subjects </div>
                                <div style={{ color: "#636363", fontWeight: "normal" }}>{specificCourseDataContainer?.testSeriesDetails?.testChapterCount} Chapters </div>
                            </div>
                            <div className='r-c-sb'>       
                            {hasMarketingPrivilege ? <></> :
                            <AddSubjectButton
                            setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                            history={history}
                            setEditSubjectVisible={setEditSubjectVisible}
                            kii={"fromTestSeries"}
                        />
                            }                     
                                
                                <Button
                                    type="link"
                                    style={{ fontSize: 14, color: '#636363' }}
                                    onClick={() => {
                                        setIsCoursePlanningEditClicked(false);
                                        setIsExamPatern(false)
                                        setIsSyllabas(false)
                                        setIsTestExpand(true)
                                    }}
                                ><FiChevronUp style={{ fontSize: 18, marginLeft: 5 }} /></Button>
                            </div>

                        </div>

                        <div className="r-ac flex-wrap scroll-bar-universal m-t-10" style={{ gap: 20, height: '250px' }}>
                            {specificCourseDataContainer?.testSeriesDetails?.subjectTestSeries?.map((item, index) => (

                                <SingleSubjectCard
                                    item={item}
                                    subject={item?.subject}
                                    setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                                    setEditSubjectVisible={setEditSubjectVisible}
                                    setShowEditChapters={setShowEditChapters}
                                    courseTypeName={courseTypeName}
                                    hasMarketingPrivilege={hasMarketingPrivilege}
                                />

                            )
                            )}

                        </div>
                    </> :
                    <div className='r-c-sb'>
                        <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>Syllabus</div>
                        <div style={{ color: "#636363", fontWeight: "normal" }}><span style={{ fontWeight: "bold", color: "#191919" }}>{specificCourseDataContainer?.testSeriesDetails?.testSubjectCount}</span>  Subjects </div>
                        <div style={{ color: "#636363", fontWeight: "normal" }}><span style={{ fontWeight: "bold", color: "#191919" }}>{specificCourseDataContainer?.testSeriesDetails?.testChapterCount}</span>  Chapters </div>



                        <div className='cursor-pointer show-course-planning-edit' style={{ color: '#636363', fontSize: 14, fontWeight: "bold" }} onClick={() => {
                            setIsCoursePlanningEditClicked(false);
                            setIsExamPatern(false)
                            setIsSyllabas(true)
                            setIsTestExpand(false)

                        }}>   <FiChevronDown style={{ fontSize: 18, marginLeft: 5 }} />
                        </div>
                    </div>
                }

                {isConfirmDeleteModalVisible &&
                    <CourseDeleteConfirmModal
                        isConfirmDeleteModalVisible={isConfirmDeleteModalVisible}
                        setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
                        togglePageLoader={togglePageLoader}
                        isFromSubjects={isFromSubjects}
                        showEditChapters={showEditChapters}
                        deleteSubject={deleteSubject}
                        getSpecificCourseData={getSpecificCourseData}
                        setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                        getOnlineTestSeriesCourseDetails={getOnlineTestSeriesCourseDetails}
                        courseTypeName={courseTypeName}
                    />
                }
                {isCreateSubjectModalVisible &&
                    <CreateSubjectsModal
                        isCreateSubjectModalVisible={isCreateSubjectModalVisible}
                        setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                        addSubjects={addSubjects}
                        addSubjectFromOnlineTest={addSubjectFromOnlineTest}
                        togglePageLoader={togglePageLoader}
                        getSpecificCourseData={getSpecificCourseData}
                        getOnlineTestSeriesCourseDetails={getOnlineTestSeriesCourseDetails}
                        isEditSubjectVisible={isEditSubjectVisible}
                        showEditChapters={showEditChapters}
                        setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
                        setIsFromSubjects={setIsFromSubjects}
                        fromClassroom={false}
                        courseTypeName={courseTypeName}
                        hasMarketingPrivilege={hasMarketingPrivilege}
                    />
                }

            </div>
        </div>
    )
}

export default TestSyllabus