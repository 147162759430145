import React from "react";
import { DatePicker, Space } from "antd";
import moment from "moment";

function LiveCourseDatePicker({
    value,
    setCourseStartDate,
    endDate,
    courseStartDate,
    setRenderEndDate,
    setCreateCourseObj
}) {
    function onChange(date, dateString) {
        if (moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD") !== "Invalid date") {
            setCourseStartDate(moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD"));
            setCreateCourseObj("startDate", moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD"));
            setRenderEndDate(true)
        } else {
        }
    }
    const disablePreviousDates = (current) => {
        return current && current < moment().startOf('day');
    };
    const disableEndDate = (current) => {
        return current && current < moment(courseStartDate);
    };
    return (
        <Space direction="vertical" style={{ width: "100%" }} size={50}>
            <DatePicker
                inputReadOnly={true}
                allowClear={false}
                className="datePicker"
                onChange={onChange}
                value={
                    value !== null
                        ? moment(
                            moment(value, "YYYY-MM-DD").format("DD-MM-YYYY"),
                            "DD-MM-YYYY"
                        )
                        : ""
                }
                placeholder={["Select a date"]}
                style={{ width: "100%" }}
                format={"DD-MM-YYYY"}
                disabledDate={endDate ? disableEndDate : null}
            />
        </Space>
    );
}

export default LiveCourseDatePicker
