import React from 'react'


const bodyStyle = {
    background: "#FAFAFA",
    margin: -20,
    padding: 20,
    overflowY: "auto",
  };

const image = require("../../Assets/user/ic-absent.svg").default;
const image1 = require("../../Assets/user/ic-overall.svg").default;
const image2 = require("../../Assets/user/ic-present.svg").default;

const image3 = require("../../Assets/user/ic-working-hrs.svg").default;
const image4 = require("../../Assets/user/ic-avg-working-hrs.svg").default;
const image5 = require("../../Assets/user/ic-leaves.svg").default;

const images6 = require("../../Assets/user/Group 11708.svg").default;

const images7 = require("../../Assets/user/ic-clock-in.svg").default;
const images8 = require("../../Assets/user/ic-clock-out.svg").default;
const images9 = require("../../Assets/user/ic-geo-fence.svg").default;


const overviewData=[{
    number:"24",
    attendance:"present day(s)",
    image:image2,
},
{
number:"24",
    attendance:"Absent day(s)",
    image:image1,
},
{
    number:"80%",
        attendance:"Overall percentage",
        image:image,
    },
]

const hourData=[{
    time:"102:67:45",
    hour:"Total working hours",
    images:image3,
},
{
    time:"102:67:45",
    hour:"Total working hours",
    lowhour:"Low working hours",
    images:image4,
},
{
    time:"102:67:45",
    hour:"Total working hours",
    images:image5,
}]

const AttenadnceOverviewCard=()=>{
    return(
        <div style={{
            width: "100%",
            alignItems: "center",
            border: "#E6E6E6 solid 1px",
            borderRadius: "4px",
            padding: "10px",
            paddingTop: "10px",
            height: "50vh",
            marginRight: 10,
            borderColor: "#E6E6E6",
            backgroundColor: "#FFFFFF",
            marginTop: "20px",
            cursor: "pointer",
          }}>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <div>
                <div>Attendance overview</div>
                <div>Since 12/12/2021</div>
                </div>
              <div style={{marginTop:"10px"}}>VIEW SUMMARY</div>
            </div>
            <div style={{display:"flex",flexWrap:"wrap"}}>
            {
                overviewData?.map((item)=>(
                    <OverviewCard
                    number={item.number}
                    attendance={item.attendance}
                    image={item.image}
                    
                    />

                ))
            }
            </div>
            <div style={{display:"flex",flexWrap:"wrap"}}>{
                hourData?.map((items)=>(
                    <TimeCard
                    time={items.time}
                    hour={items.hour}
                    images={items.images}/>
                ))
            }
            </div>
           
          </div>
    )
}

const OverviewCard=({number,attendance,image})=>{
    return(
        <div  style={{
            width: "31%",
            alignItems: "center",
            border: "#E6E6E6 solid 1px",
            borderRadius: "6px",
            padding: "10px",
            paddingTop: "10px",
            height: "15vh",
            marginRight: 10,
            backgroundColor: "#FFFFFF",
            marginTop: "20px",
            cursor: "pointer",
          }}>
              <div style={{display:"flex",marginTop:"10px"}}>
              <div><img style={{width:"70%"}} src={image}/></div>
              <div>
              <div>{number}</div>
              <div>{attendance}</div>
              </div>
              </div>
              
             
              </div>
    )
}

const TimeCard=({time,hour,images})=>{
    return(
        <div  style={{
            width: "31%",
            alignItems: "center",
            border: "#E6E6E6 solid 1px",
            borderRadius: "6px",
            padding: "10px",
            paddingTop: "10px",
            height: "15vh",
            marginRight: 10,
            backgroundColor: "#FFFFFF",
            marginTop: "20px",
            cursor: "pointer",
          }}>
              <div style={{display:"flex",marginTop:"10px"}}>
              <div><img style={{width:"70%"}}src={images}/></div>
              <div>
              <div>{time}</div>
              <div>{hour}</div>
              </div>
              </div>
              
              </div>
    )
}


const ClockCard=()=>{
    return(
        <div style={{
            width: "400px",
            alignItems: "center",
            border: "#E6E6E6 solid 1px",
            borderRadius: "6px",
            padding: "10px",
            paddingTop: "10px",
            height: "50vh",
            marginRight: 10,
            backgroundColor: "#FFFFFF",
            marginTop: "20px",
            cursor: "pointer",
          }}>
              <div>Your attendance today</div>
              <div>12/12/2021 {"•"} Monday</div>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div style={{flexDirection:"column"}}>
              <div className='m-t-30' style={{display:"flex"}}>
              <div><img style={{width:"70%"}}src={images7}/></div>
              <div className='m-t-10'style={{color:"#636363",fontSize:"11px",marginLeft:"-15px"}}> Clock in</div>
              </div>
              <div className='m-t-30' style={{display:"flex"}}>
              <div><img style={{width:"70%"}}src={images8}/></div>
              <div  className='m-t-10'style={{color:"#636363",fontSize:"11px",marginLeft:"-15px"}}>Clock out</div>
              </div>
              <div className='m-t-30' style={{display:"flex"}}>
              <div><img style={{width:"70%"}} src={images9}/></div>
              <div className='m-t-10'style={{color:"#636363",fontSize:"11px",marginLeft:"-15px"}}>Geo fence</div>
              </div>
              </div>
              <div className='' style={{backgroundImage:images6,height:"30px",width:"30px"}}>
              {/* <img src={images6}/> */}fegrlyyufgrf
              </div>
             
            
              </div>
              
          </div>
    )
}

function MyAttendancePopulated() {
  return (
      <div style={bodyStyle}>
          <div style={{display:"flex",}}>
          <div><AttenadnceOverviewCard/></div>
    <div style={{marginLeft:"10px"}}><ClockCard/></div>
          </div>
    
    </div>
  )
}

export default MyAttendancePopulated