import React from 'react';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import 'antd/lib/tabs/style/index';
import 'antd/lib/modal/style/index';
import moment from 'moment';
import { SyncOutlined, BellOutlined } from "@ant-design/icons";
import PullToRefresh from 'react-simple-pull-to-refresh';
import { MdNotifications } from 'react-icons/md';
import { TiFilter } from 'react-icons/ti';
import { IoMdSearch } from 'react-icons/io';
import {Api, Color} from 'services';
import Carousel from 'react-multi-carousel';
import { TestTopBar, TestSubjectsCard } from 'components';
import { PieChart } from 'react-minimal-pie-chart';
import { getStudentTestDetail, getStudentTestResult, getStudentTestData } from 'actions/mobileTest';

import { connect } from "react-redux";
import chemistryIcon from "Assets/test-assignment/chemistryIcon.svg";
// import mathsIcon from "Assets/test-assignment/mathsIcon.svg";
// import physicsIcon from "Assets/test-assignment/physicsIcon.svg";
// import sliderIndicator from "Assets/test-assignment/sliderIndicator.svg";
// import sliderIndicatorLight from "Assets/test-assignment/sliderIndicatorLight.svg";
import emptyTest from "Assets/test-assignment/emptyTest.svg";
import './styles.css';
import '../../design/layout.css';
import '../../design/assignment.css';
// import DonutChart from 'react-donut-chart';

const { TabPane } = Tabs;

// const ActivityButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
//   const { carouselState: { currentSlide, deviceType } } = rest;
//   return (
//     <div className="r-c-c m-t-30" >
//       <img
//         src={currentSlide === 0 ? sliderIndicator : sliderIndicatorLight}
//         alt=""
//         onClick={() => goToSlide(0)}/>
//       <img
//         src={currentSlide === 1 ? sliderIndicator : sliderIndicatorLight}
//         alt=""
//         className='m-l-10'
//         onClick={() => goToSlide(1)}/>
//       <img
//         src={currentSlide === 2 ? sliderIndicator : sliderIndicatorLight}
//         alt=""
//         className='m-l-10'
//         onClick={() => goToSlide(2)}/>
//     </div>
//   );
// };
// const activityResponsive = {
//   superLargeDesktop: {
//     breakpoint: { max: 4000, min: 3000 },
//     items: 1
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 1
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 1
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1
//   }
// };


class MobileTestSubjects extends React.Component {


  componentDidMount() {
    // localStorage.clear();
  }

  onRefresh = () => {
      return new Promise((resolve) => {
          this.props.getStudentTestData(this.props.studentSubjectId, resolve);
      });
  }


  render() {
    const {history, studentSubject,  studentSubjectHeader, studentTestDetailLoader,
    studentTestResultLoader, studentTestSubLoader } = this.props;

    const LoaderModal = ({loadingkey, loadingText}) => (
      <Modal
        visible={loadingkey}
        footer={null}
        centered
        closable={false}
        className="loadingModal"
      >
        <div className="r-c-c-c">
          <img src={require('Assets/dots-gif.gif').default} style={{maxWidth: "100%"}} width={150} alt=""/>
          {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} /> */}
          {/* <div className="text-xmd m-t-10">{loadingText}</div> */}
        </div>
      </Modal>
    )

    return (
      <div style={{height:'calc(100% - 46px)'}}>
        <TestTopBar
          backPress={() => history.goBack()}
          title={studentSubjectHeader}
          listMode={false}
          precentshow={false}
        />
        <PullToRefresh
          onRefresh={() => this.onRefresh()}
        >
        <Tabs defaultActiveKey="0" className='p-10'>
          <TabPane
            tab={<div
              onClick={() => {}}
              className='NutinFont bold-700 text-xmd'>YOUR TESTS ({studentSubject.tests.filter(item => (item.testProgress == 'SUBMITTED' || item.testProgress == 'UPCOMING' || item.testProgress == 'INPROGRESS' || item.testProgress == 'LIVE' || (item.testProgress === 'MISSED' && !item.declared))).length})</div>}
            key="0"
          >
            {studentSubject.tests.filter(item => (item.testProgress != 'GRADED')).length
              ?
              studentSubject.tests.filter(item => (item.testProgress == 'SUBMITTED' || item.testProgress == 'UPCOMING' || item.testProgress == 'INPROGRESS' || item.testProgress == 'LIVE' || (item.testProgress === 'MISSED' && !item.declared))).map((data, index) => (
                <div className='m-t-20 m-b-20'>
                  <TestSubjectsCard
                    subject={index === 1 ?'Physics' : index === 2 ? 'Chemistry' : 'Mathematics'}
                    topic={data.title}
                    icon={index === 1 ? chemistryIcon : index === 2 ?  chemistryIcon :  chemistryIcon}
                    testDate={moment(data.testDate).format('DD/MM/yyyy')}
                    startTime={data.startTime}
                    endTime={data.endTime}
                    cardType={'live'}
                    testProgress={data.testProgress}
                    declared={false}
                    onPress={data.testProgress === 'GRADED'
                            ?
                              () => {
                                this.props.getStudentTestResult(data.id, (response) => {
                                    history.push(`${history.location.pathname}/instructions/start/results`)
                                })
                              }
                            :
                              () => {
                                this.props.getStudentTestDetail(data.id, 'live', (response) => {
                                    history.push(`${history.location.pathname}/instructions`)
                                })
                              }
                          }
                  />
                </div>
            ))
            :
            <div className='m-10 text-center r-c-c-c' style={{height:'100%'}}>
              <img
                src={emptyTest}
                alt=""
                className=''
                style={{width:'auto'}}/>
              <div className='NutinFont bold-700 text-sm m-t-10'>No test available!</div>
              <div className='NutinFont text-xmd simgrey'>There are no tests available at the moment. Please check back later.</div>
            </div>
          }
          </TabPane>
          <TabPane
            tab={<div
              onClick={() => {}}
              className='NutinFont bold-700 text-xmd'>RESULTS ({studentSubject.tests.filter(item => (item.testProgress == 'GRADED' || (item.testProgress == 'MISSED' && item.declared))).length})</div>}
            key="1"
          >
            {studentSubject.tests.filter(item => (item.testProgress == 'GRADED' || (item.testProgress == 'MISSED' && item.declared))).length
              ?
              studentSubject.tests.filter(item => (item.testProgress == 'GRADED' || (item.testProgress == 'MISSED' && item.declared))).map((data, index) => (
                <div className='m-t-20 m-b-20'>
                  <TestSubjectsCard
                    subject={index === 1 ?'Physics' : index === 2 ? 'Chemistry' : 'Mathematics'}
                    topic={data.title}
                    icon={index === 1 ? chemistryIcon : index === 2 ?  chemistryIcon :  chemistryIcon}
                    testDate={moment(data.testDate).format('DD/MM/yyyy')}
                    startTime={data.startTime}
                    endTime={data.endTime}
                    cardType={'live'}
                    testProgress={data.testProgress}
                    declared={data.declared}
                    onPress={(data.testProgress === 'GRADED' || (data.testProgress == 'MISSED' && data.declared))
                            ?
                              () => {
                                this.props.getStudentTestResult(data.id, (response) => {
                                    history.push(`${history.location.pathname}/instructions/start/results`)
                                })
                              }
                            :
                              () => {
                                this.props.getStudentTestDetail(data.id, 'live', (response) => {
                                    history.push(`${history.location.pathname}/instructions`)
                                })
                              }
                          }
                  />
                </div>
              ))
              :
              <div className='m-10 text-center r-c-c-c' style={{height:'100%'}}>
                <img
                  src={emptyTest}
                  alt=""
                  className=''
                  style={{width:'auto'}}/>
                <div className='NutinFont bold-700 text-sm m-t-10'>No result available!</div>
                <div className='NutinFont text-xmd simgrey'>There are no results available at the moment. Please check back later.</div>
              </div>
            }
          </TabPane>
          {/*<TabPane
            tab={
              <div
                onClick={() => {}}
                className='NutinFont bold-700 text-xmd'>
                PRACTICE TEST ({studentSubject.practiceTests.length})
              </div>}
            key="1"
          >

              {studentSubject.practiceTests.length
                ?
                studentSubject.practiceTests.map((data, index) => (
                <div className='m-t-20'>
                  <TestSubjectsCard
                    subject={index === 1 ?'Physics' : index === 2 ? 'Chemistry' : 'Mathematics'}
                    topic={data.title}
                    icon={index === 1 ? chemistryIcon : index === 2 ?  chemistryIcon :  chemistryIcon}
                    cardType={'practice'}
                    testProgress={data.testProgress}
                    attempts={data.attempts}
                    onPress={() => {
                      this.props.getStudentTestDetail(data.id, 'practice', (response) => {
                          history.push(`${history.location.pathname}/instructions`)
                      })
                    }}
                  />
                </div>
              ))
              :
              <div className='m-10 text-center r-c-c-c' style={{height:'100%'}}>
                <img
                  src={emptyTest}
                  alt=""
                  className=''
                  style={{width:'auto'}}/>
                <div className='NutinFont bold-700 text-sm m-t-10'>No test available!</div>
                <div className='NutinFont text-xmd simgrey'>There are no tests available at the moment. Please check back later.</div>
              </div>
            }

          </TabPane>*/}
        </Tabs>
        </PullToRefresh>

        <LoaderModal loadingkey={studentTestSubLoader} loadingText={'Loading Tests'}/>
        <LoaderModal loadingkey={studentTestDetailLoader} loadingText={'Loading Tests'}/>
        <LoaderModal loadingkey={studentTestResultLoader} loadingText={'Loading Result'}/>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { studentSubject, studentSubjectHeader, studentTestDetailLoader, studentTestResultLoader,
   studentSubjectId, studentTestSubLoader} = state.mobileTest;
  return { studentSubject, studentSubjectHeader, studentTestDetailLoader, studentTestResultLoader,
   studentSubjectId, studentTestSubLoader}
};

const mapDispatchToProps = dispatch => ({
  getStudentTestDetail: (id, tabname, callback) => dispatch(getStudentTestDetail(id, tabname, callback)),
  getStudentTestResult: (id, callback) => dispatch(getStudentTestResult(id, callback)),
  getStudentTestData: (subjectId, callback) => dispatch(getStudentTestData(subjectId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileTestSubjects);
