import { Dropdown, Typography, Menu, Avatar, Tooltip } from 'antd'
import React from 'react'
import { MdCastConnected, MdMoreVert } from 'react-icons/md'
import { FaGraduationCap } from 'react-icons/fa'
import { ClockCircleFilled } from '@ant-design/icons'
import moment from 'moment'
import { connect } from 'react-redux'
import { Api } from 'services'
import { SquareSubIcon } from 'components/subIcon'
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import { RiWirelessChargingLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { fetchStudentListData, setCardData, setSelectedId } from 'actions/schedule'
import Conference from "Assets/schedule/confrence.svg"
import Broadcast from "Assets/schedule/Broadcast.svg"
import Offline from "Assets/schedule/ofline.svg"
import OtherColor from "Assets/schedule/other_color.svg"
const avattar = require("../../Assets/Group 11416.svg").default


const { Text } = Typography
const CompletedClassTandS = ({ user, completedItem, handleReplay, fetchStudentListData, setCardData, setSelectedId }) => {
    const history = useHistory()
    const handleContact = () => {
        history.push("/care/chat")
    }

    const markAttendanceHandler = () => {
        setSelectedId(completedItem?.id)
        setCardData(completedItem)
        fetchStudentListData()
        history.push('/schedule-attendance')

    }

    const markingTimeBool = () => {
        let endTimeMoment = moment(completedItem?.endTime, 'hh:mm:ss');
        let currentTimeMoment = moment();
        let durationSeconds = moment.duration(currentTimeMoment.diff(endTimeMoment)).asSeconds();
        console.log("endTime", completedItem?.endTime, durationSeconds);
        if (durationSeconds < 300) {
            return true
        }
        return false
    }


    const CustomMenu = ({ streamId, classMode, subject, isTeacher ,published}) => (
        <Menu style={{ width: "200px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
            {
                classMode == "Broadcast" && published
                    ?
                    <Menu.Item key="1" onClick={() => handleReplay(streamId, subject, completedItem)}>
                        Replay live class
                    </Menu.Item>
                    :
                    null
            }

            {/* commented due to aulas care is not in PROD */}
            {/* {
                isTeacher == true
                    ?
                    <Menu.Item key="2" onClick={() => handleContact()}>
                        Contact Your Admin
                    </Menu.Item>
                    :
                    null
            } */}
        </Menu>

    );
    if (user.access.includes("schedule-teacher")) {

        return (
            <div className="ScheduleClassTandS">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="scheduleclass-img-text">
                        <div>
                            <SquareSubIcon icon={completedItem?.subject?.icon ? completedItem?.subject?.icon : ''} name={completedItem?.subject?.name ? completedItem?.subject?.name : ''} size={40} />
                        </div>
                        <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', width: '200px' }}>
                            {completedItem.mode == "Zoom 40" ?
                                <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E7F3FF', borderRadius: '8px', padding: '2px' }}>
                                    <IoMdVideocam style={{ color: '#1089FF' }} />
                                    <Text style={{ fontSize: '10px', color: '#1089FF', marginLeft: '5px' }}>Zoom 40</Text>
                                </div>
                                :completedItem.mode == "Broadcast" ?
                                    <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF4D5', borderRadius: '8px', padding: '2px' }}>
                                        <img src={Broadcast} style={{ color: '#FFA931', textAlign: 'center', height: "18px", width: "18px" }} />
                                        <Text style={{ fontSize: '10px', color: '#FFA931', marginLeft: '5px' }}>Broadcast</Text>
                                    </div>
                                    :
                                    completedItem.mode == "Conference" ?
                                        <div style={{ width: '77px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F4FE', borderRadius: '8px', padding: '2px' }}>
                                            <img src={Conference} style={{ color: '#594099', height: "18px", width: "18px" }} />
                                            <Text style={{ color: '#594099', marginLeft: '5px', fontSize: '10px' }}>Conference</Text>
                                        </div>
                                        :
                                        completedItem.mode == 'Others' ?
                                        <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFE5F4', borderRadius: '8px', padding: '3px 7px' }}>
                                            <img src={OtherColor} style={{ color: '#E50087',height:"16px",width:"16px" }} />
                                            <Text style={{ color: '#E50087', marginLeft: '5px', fontSize: '10px' }}>Other</Text>
                                        </div>
                                        :
                                        <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '8px', padding: '2px' }}>
                                            <img src={Offline} style={{ color: '#28DF99', textAlign: 'center', height: "18px", width: "18px" }} />
                                            <Text style={{ fontSize: '10px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                                        </div>

                            }
                            <Tooltip title={completedItem?.subject?.name ? completedItem?.subject?.name : null}>
                                <Text style={{ fontWeight: 'bold', fontSize: '15px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{completedItem?.subject?.name}</Text>
                            </Tooltip>
                        </div>
                    </div>
                    {(completedItem?.mode === 'Broadcast') ?
                        <div>
                            <Dropdown overlay={<CustomMenu published ={completedItem?.teacherId==user?.id||completedItem?.published} streamId={completedItem.roomName} classMode={completedItem.mode} subject={completedItem.subject} isTeacher={true} />} disabled={false} placement="bottomRight" >
                                <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
                            </Dropdown>
                        </div> : null}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                    <div style={{ width: '50%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <Tooltip title={completedItem?.standards.length != 0 ? completedItem?.standards?.map((item, index, array) => (`${item?.std + "-" + item?.section}${index === array.length - 1 ? '' : ' | '}`)) : null}>
                            <FaGraduationCap style={{ color: 'purple', marginRight: '5px' }} />
                        </Tooltip>
                        {completedItem?.standards.length != 0 ? completedItem?.standards?.map((item, index, array) => (
                            <Text style={{ fontSize: '80%', fontFamily: 'roboto' }} key={index}>{item?.std + "-" + item?.section}{index === array.length - 1 ? '' : ' |'}</Text>
                        )) : null}
                    </div>
                    {(user?.role?.includes('Teacher')) && (completedItem?.teacher?.id === user?.id) && (completedItem?.mode === 'Offline') && (markingTimeBool()) ?
                        <div onClick={markAttendanceHandler} style={{ cursor: 'pointer' }}>
                            <Avatar shape='circle' size='small' src={avattar} />
                            <Text style={{ marginLeft: '5px', color: completedItem?.attendanceMarked ? '#636363' : '#1089FF', fontSize: '9px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 'bold', cursor: 'pointer' }} >{completedItem?.attendanceMarked ? `${completedItem?.attendanceData?.presentCount}/${completedItem?.attendanceData?.studentCount}` : 'MARK ATTENDANCE'}</Text>
                        </div>
                        :
                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <ClockCircleFilled style={{ fontSize: '11px', color: '#594099' }} />
                            <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(completedItem?.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(completedItem?.endTime, 'HH:mm:ss').format("hh:mm A")}`}</Text>
                        </div>
                    }
                    {/* <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <ClockCircleFilled style={{ fontSize: '11px', color: '#594099' }} />
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(completedItem?.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(completedItem?.endTime, 'HH:mm:ss').format("hh:mm A")}`}</Text>
                    </div> */}
                </div>
            </div>
        )
    }
    else if (user.access.includes("schedule-student")) {
        return (
            <div className="ScheduleClassTandS">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="scheduleclass-img-text">
                        <div>
                            <SquareSubIcon icon={completedItem?.subject?.icon ? completedItem?.subject?.icon : ''} name={completedItem?.subject?.name ? completedItem?.subject?.name : ''} size={40} />
                        </div>
                        <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', width: '200px' }}>
                            {completedItem.mode == "Zoom 40" ?
                                <div style={{ width: '74px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E7F3FF', borderRadius: '8px', padding: '2px' }}>
                                    <IoMdVideocam style={{ color: '#1089FF' }} />
                                    <Text style={{ fontSize: '10px', color: '#1089FF', marginLeft: '5px' }}>Zoom 40</Text>
                                </div>
                                : completedItem.mode == "Broadcast" ?
                                    <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF4D5', borderRadius: '8px', padding: '2px' }}>
                                        <MdCastConnected style={{ color: '#FFA931', textAlign: 'center' }} />
                                        <Text style={{ fontSize: '10px', color: '#FFA931', marginLeft: '5px' }}>Broadcast</Text>
                                    </div>
                                    :
                                    completedItem.mode == "Conference" ?
                                        <div style={{ width: '77px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F4FE', borderRadius: '8px', padding: '2px' }}>
                                            <RiWirelessChargingLine style={{ color: '#594099' }} />
                                            <Text style={{ color: '#594099', marginLeft: '5px', fontSize: '10px' }}>Conference</Text>
                                        </div>
                                        :
                                    completedItem.mode == 'Others' ?
                                    <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFE5F4', borderRadius: '8px', padding: '3px 7px' }}>
                                        <img src={OtherColor} style={{ color: '#E50087',height:"16px",width:"16px" }} />
                                        <Text style={{ color: '#E50087', marginLeft: '5px', fontSize: '10px' }}>Other</Text>
                                    </div>
                                    :
                                        <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '8px', padding: '2px' }}>
                                            <IoMdChatbubbles style={{ color: '#28DF99', textAlign: 'center' }} />
                                            <Text style={{ fontSize: '10px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                                        </div>
                            }
                            <Tooltip title={completedItem?.subject?.name ? completedItem?.subject?.name : null}>
                                <Text style={{ fontWeight: 'bold', fontSize: '15px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{completedItem?.subject?.name} </Text>
                            </Tooltip>
                        </div>
                    </div>
                    {(completedItem?.mode === 'Broadcast') ?
                        <div>
                            <Dropdown overlay={<CustomMenu published ={completedItem?.published} streamId={completedItem.roomName} classMode={completedItem.mode} subject={completedItem.subject} isTeacher={true} />} disabled={false} placement="bottomRight" >
                                <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
                            </Dropdown>
                        </div> : null}
                    {/* <div>
                        <Dropdown overlay={<CustomMenu streamId={completedItem.roomName} classMode={completedItem.mode} subject={completedItem.subject} isTeacher={false} />} disabled={false} placement="bottomRight" >
                            <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
                        </Dropdown>
                    </div> */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', padding: '10px' }}>
                    <div style={{ width: '50%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <Avatar shape='circle' size='small' src={`${Api._s3_url}${completedItem?.teacher?.image}`} />
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{completedItem?.teacher?.name}</Text>
                    </div>
                    <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <ClockCircleFilled style={{ fontSize: '11px', color: '#594099' }} />
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(completedItem?.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(completedItem?.endTime, 'HH:mm:ss').format("hh:mm A")}`}</Text>
                    </div>
                </div>
                <div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
    } = state.schedule;
    const { user } = state.session;
    return {
        user
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchStudentListData: (id) => dispatch(fetchStudentListData(id)),
    setSelectedId: (id) => dispatch(setSelectedId(id)),
    setCardData: (value) => dispatch(setCardData(value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(CompletedClassTandS);
