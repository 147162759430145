import { CloseOutlined } from '@ant-design/icons';
import { postCreateOffers } from 'actions/offers';
import { Drawer } from "antd";
import { Button } from 'components/Button';
import { Title } from "components/Typography";
import CreateOffers from "pages/offers/components/createOffers";
import React, { useState } from 'react';
import { connect } from 'react-redux';
import './styles.css';
function EditOfferDrawer({ open, handleClose, postCreateOffers, courseDetails, offersObj, handleSave, offerId}) {
    const [saveBtnLoader, setSaveBtnLoader] = useState(false);
    const [ formHasPromo, setFromHasPromo ] = useState(false);
    const [ formHasOfferDate, setFromHasOfferDate ] = useState(false);
    let disableSaveOffer = true;
    if (
        !offersObj?.title ||
        !offersObj?.discountType ||
        !offersObj?.discount > 0 ||
        (formHasPromo && !offersObj?.code) ||
        !offersObj?.type ||
        (!offersObj?.privateOffer && offersObj?.type == "course" &&
            !offersObj?.applicableLive && !offersObj?.applicableVideo && !offersObj?.applicableStudy && !offersObj?.applicableTest) ||
        (formHasOfferDate && (!offersObj?.startDate || !offersObj?.endDate))
    ) {
        disableSaveOffer = true;
    } else {
        disableSaveOffer = false;
    }
    return (
        <Drawer placement='right' open={open} width="40%" height="100%" closable={false}>
            <main className='course-offer-drawer'>
                <section className="course-offer-drawer-header" style={{ fontWeight: 700 }}>
                    <Title>{offerId ? 'Edit promotional offer' : 'Create promotional offer'}</Title>
                    <div><CloseOutlined onClick={() => handleClose()}></CloseOutlined></div>
                </section>
                <section className='course-offer-create-form'>
                    <CreateOffers
                        cardClassName='course-offer-create-card'
                        hideHeader={true}
                        courseDetails={courseDetails}//{{ id: 132, name: 'asd123', type: 'course' }}
                        setFormHasPromo={setFromHasPromo}
                        setFormHasOfferDate={setFromHasOfferDate}
                        selectedOfferId={offerId}
                    ></CreateOffers>
                </section>
                <section className='course-offer-create-footer'>
                    <Button style={{ marginRight: 20, backgroundColor: "#E1E3EA", border: "1px solid #E1E3EA", color: "#636363" }} onClick={() => {
                        handleClose();
                    }}>CANCEL</Button>
                    <Button
                        loading={saveBtnLoader}
                        type="primary"
                        disabled={disableSaveOffer}
                        style={{ alignText: 'center' }}
                        onClick={() => {
                            setSaveBtnLoader(true);
                            postCreateOffers(
                                null,
                                () => { setSaveBtnLoader(false) },
                                () => {
                                    handleSave();
                                }
                            )
                        }}>SAVE</Button>
                </section>
            </main>
        </Drawer >
    )
}
const mapStateToProps = (state) => {
    const { offersObj } = state.offers;
    return { offersObj };
}

const mapDispatchToProps = (dispatch) => ({
    postCreateOffers: (publish, callBack, successCallBack) => dispatch(postCreateOffers(publish, callBack, successCallBack)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOfferDrawer);