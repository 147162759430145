import {
  SUBMIT_MODAL_FUN, REVIEW_MODAL_FUN, LEAVE_MODAL_FUN, STUDENT_TEST_DATA,
  STUDENT_SUBJECT_DATA, STUDENT_SUBJECT_HEADER,
  STUDENT_TEST_DETAIL, STUDENT_TEST_COLLECT_QUES_INDEX, STUDENT_TEST_COLLECT_MCQ, STUDENT_TEST_COLLECT_BOOKMARK,
  STUDENT_TEST_UPDATE_ANS, STUDENT_TEST_COLLECT_IMAGE,
  DELETE_QUES_IMG_MODAL_FUN, CONFROM_DELETE_QUES_IMG_MODAL_FUN,
  GET_STUDENT_TEST_PUBLISH_DATE, STUDENT_TEST_RESULT_DATA,
  STUDENT_TEST_ANSWER_SHEET, UPDATE_STUDENT_TEST_ANSWER_INDEX, STUDENT_TEST_DURATION_TIMER,
  STUDENT_TEST_DURATION_DECREMENT, TIMER_OVER_MODAL_FUN, IMAGE_ZOOM_MODAL_FUN,
  SET_IMG_OF_REVIEW_QUESTION, TIMER_ALERT_MODAL_FUN, STUDENT_TEST_CURRENT_TIME_LOADER, UPDATE_STUDENT_TEST_CURRENT_TIME,
} from '../actions/mobileTest';
import moment from 'moment';

// import {
//  constAssignList,
//  assignmentDetails
// } from '../pages/Assignment/const';

const initialState = {
  submitModalBool: false,
  reviewModalBool: false,
  leaveModalBool: false,
  timerOverModalBool: false,
  timerAlertModalBool: false,
  imageZoomModalBool: false,
  imageZoomModalIndex: 0,
  deleteQuesImgModalBool: false,
  studentTestData: {},
  studentSubject: {},
  studentTestLoader:false,
  studentTestSubLoader:false,
  studentTestDetailLoader:false,
  studentTestPostQuesLoader:false,
  studentSubjectHeader:'',
  studentSubjectId:'',
  studentTestDetialData:{},
  studentTestQuesArr:[],
  studentTestQuesIndex: 0,
  studentTestAnsObj: {
    answer: '',
    testQuestionId: '',
    duration: 0,
    submitStatus: null
  },
  studentBookmarkBool: false,
  testUploadedBase64Arr: [],
  testUploadedImgArrNew: [],
  deleteQuesImgIndex: false,
  testUploadedImgdeleteArr:[],
  studentTestResultPublishDate:'',
  studentTestResult:{},
  studentTestResultLoader: false,
  studentTestReviewAnsArr: [],
  studentTestReviewAnsindex: 0,
  studentTestReviewQNo: 0,
  studentTestDurationInSecTotal:0,
  studentTestStartTimeInSec:0,
  studentTestDurationInSec:0,
  studentTestDurationTimer:0,
  studentTestType: '',
  studentTestLiveId: '',
  studentTestCurrentTimeLoader: false,
  studentTestPostSubmitLoader: false,
  testProgress:0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_MODAL_FUN:
      return {
        ...state,
        submitModalBool: action.bool,
      };
    case REVIEW_MODAL_FUN:
      return {
        ...state,
        reviewModalBool: action.bool,
      };
    case LEAVE_MODAL_FUN:
      return {
        ...state,
        leaveModalBool: action.bool,
      };
    case TIMER_OVER_MODAL_FUN:
      return {
        ...state,
        timerOverModalBool: action.bool,
      };
    case TIMER_ALERT_MODAL_FUN:
      return {
        ...state,
        timerAlertModalBool: action.bool,
      };
    case IMAGE_ZOOM_MODAL_FUN:
      return {
        ...state,
        imageZoomModalBool: action.bool,
        imageZoomModalIndex: action.index,
      };
    case UPDATE_STUDENT_TEST_CURRENT_TIME:
      let secFinal;
      const currentTime = moment(action.res.currentTime, "HH:mm:ss");
      const endTime = moment(state.studentTestDetialData.endTime, "HH:mm:ss");
      secFinal = endTime.diff(currentTime, 'seconds');
      console.log('secFinal', secFinal);
      // alert(secFinal)
    return {
      ...state,
        // studentTestDurationInSecTotal: secFinal,
        studentTestDurationInSec: secFinal,
        studentTestDurationTimer: secFinal,
    };
    case SET_IMG_OF_REVIEW_QUESTION:
      state.testUploadedBase64Arr = [];
      state.testUploadedBase64Arr = action.arr;
      return {
        ...state,
        // imageZoomModalBool: action.bool,
        testUploadedBase64Arr: [...state.testUploadedBase64Arr],
      };
    case DELETE_QUES_IMG_MODAL_FUN:
      return {
        ...state,
        deleteQuesImgModalBool: action.bool,
        deleteQuesImgIndex: action.index,
      };
    case CONFROM_DELETE_QUES_IMG_MODAL_FUN:
      const removeServerImg = state.testUploadedBase64Arr.splice(state.deleteQuesImgIndex, 1);
      removeServerImg.map((key, index) => {
        if(key.apiImg !== ''){
          state.testUploadedImgdeleteArr.push(key.apiImg)
        }
        return 1;
      })
      return {
        ...state,
        testUploadedBase64Arr: [...state.testUploadedBase64Arr],
        testUploadedImgdeleteArr: [...state.testUploadedImgdeleteArr]
      };
    case STUDENT_TEST_DATA:
      return {
        ...state,
        studentTestData: action.res,
        studentTestQuesIndex: 0,
        studentTestCurrentTimeLoader:false,
      };
    case STUDENT_SUBJECT_DATA:
      return {
        ...state,
        studentSubject: action.res,
        studentTestQuesIndex: 0,
      };
    case STUDENT_SUBJECT_HEADER:
      return {
        ...state,
        studentSubjectHeader: action.name,
        studentSubjectId: action.id,
      };
    case GET_STUDENT_TEST_PUBLISH_DATE:
      console.log('state.studentTestQuesIndex', state.studentTestQuesIndex);
      let updateLocalImgFromApi = [];
      state.studentTestQuesArr.map((data, index) => {
        if(index === state.studentTestQuesIndex){
          if(data.type === 'Image' && data.studentFiles !== null){
            action.res.studentFiles.map((key, i) => {
              updateLocalImgFromApi.push({localImg:'', localImgObj:'', apiImg:key});
            })
            data.studentFiles = updateLocalImgFromApi;
          }
        }
      })
      console.log('tate.studentTestQuesArr', state.studentTestQuesArr);
      return {
        ...state,
        testUploadedImgArrNew: [],
        testUploadedBase64Arr: [],
        studentTestResultPublishDate: action.res.resultDate,
        studentTestQuesArr: [...state.studentTestQuesArr],
      };
    case STUDENT_TEST_RESULT_DATA:
      return {
        ...state,
        studentTestResult: action.res,
      };
    case STUDENT_TEST_ANSWER_SHEET:
      let dummyArr = [];
      action.arr.map((key, index) => {
        if(index === action.index){
          if(key.type === 'Image' && key.filename !== null){
            key.filename.split(',').map((data, i) => {
              dummyArr.push({localImg:'', localImgObj:'', apiImg:data});
              return 1;
            });
          }
        }
        return 1;
      })
      return {
        ...state,
        studentTestReviewAnsArr: action.arr,
        studentTestReviewAnsindex: action.index,
        studentTestReviewQNo: action.qNo,
        testUploadedBase64Arr: dummyArr,
      };
    case UPDATE_STUDENT_TEST_ANSWER_INDEX:
      let newArr = [];
      state.studentTestReviewAnsArr.map((key, index) => {
        if(index === action.index){
          if(key.type === 'Image' && key.filename !== null){
            key.filename.split(',').map((data, i) => {
              newArr.push({localImg:'', localImgObj:'', apiImg:data});
              return 1;
            });
          }
        }
        return 1;
      })
      return {
        ...state,
        studentTestReviewAnsindex: action.index,
        studentTestReviewQNo: action.QNo+1,
        testUploadedBase64Arr: newArr,
      };
    case STUDENT_TEST_DURATION_TIMER:
      return {
        ...state,
        studentTestDurationTimer: action.time,
      };
    case STUDENT_TEST_DURATION_DECREMENT:
      return {
        ...state,
        studentTestDurationInSec: action.time,
      };
    case STUDENT_TEST_DETAIL:
      action.res.question.map((data, index) => {
        if(data.type === 'MCQ'){
          data.options.map((item, i) => {
            if(item.id == data.studentAnswer){
              item.correct = true;
            }else{
              item.correct = false;
            }
            return 1;
          })
        }
        if(index === 0){
          state.studentTestAnsObj.submitStatus = data.submitStatus === 'NotVisited' ? null : data.submitStatus;
          state.studentTestAnsObj.duration = 0;
          state.studentTestAnsObj.testQuestionId = data.id;
          state.studentBookmarkBool = (data.submitStatus === 'Bookmarked' || data.submitStatus === 'AttemptedAndBookmarked') ? true : false;
          if(data.type === 'Image'){
            if(data.studentFiles === null ){
              state.testUploadedBase64Arr = [];
              data.studentFiles = null;
            }else{
              let dummyArr = [];
              data.studentFiles.split(',').map((key, i) => {
                dummyArr.push({localImg:'', localImgObj:'', apiImg:key});
                return 1;
              });
              state.testUploadedBase64Arr = dummyArr;
              data.studentFiles = dummyArr;
            }
          }else{
            state.studentTestAnsObj.answer = data.studentAnswer === null ? null : data.studentAnswer;
            state.testUploadedBase64Arr = [];
          }
        }else{
          if(action.res.question.length > 1){
            if(data.type === 'Image'){
              if(data.studentFiles === null ){
                data.studentFiles = null;
              }else{
                let dummyArr = [];
                data.studentFiles.split(',').map((key, i) => {
                  dummyArr.push({localImg:'', localImgObj:'', apiImg:key});
                  return 1;
                });
                data.studentFiles = dummyArr;
              }
            }
          }
        }
        return 1;
      })
      // let datee = new Date();
      // let current = datee.toLocaleTimeString();
      let sec;
      let alertSecs;
      if(action.tabname === 'practice'){
        sec = parseInt(action.res.duration || 0) - parseInt(action.res.studentDuration || 0);
        console.log('sec', sec);
      }else{
        // const starttime = moment(current, 'HH:mm:ss').unix();
        // const endtime = moment(action.res.endTime, 'HH:mm:ss').unix();
        // sec = Math.abs(endtime - starttime);
        const currentTime = moment(action.res.currentTime, "HH:mm:ss");
        const startTime = moment(action.res.startTime, "HH:mm:ss");
        const endTime = moment(action.res.endTime, "HH:mm:ss");
        sec = endTime.diff(currentTime, 'seconds');
        alertSecs = endTime.diff(startTime, 'seconds');
      }

      return {
        ...state,
        studentTestDetialData: action.res,
        studentTestQuesArr: action.res.question,
        testUploadedBase64Arr: [...state.testUploadedBase64Arr],
        studentTestStartTimeInSec: alertSecs,
        studentTestDurationInSecTotal: sec,
        studentTestDurationInSec: sec,
        studentTestDurationTimer: sec,
        studentTestType: action.tabname,
        studentTestLiveId: action.testLiveId,
        // studentTestQuesIndex: 0,
        submitModalBool: false,
        reviewModalBool: false,
        leaveModalBool: false,
        timerOverModalBool: false,
        timerAlertModalBool: false,
        imageZoomModalBool: false,
        imageZoomModalIndex: 0,
        deleteQuesImgModalBool: false,
        testUploadedImgdeleteArr: [],
        testProgress:0,
      };
    case STUDENT_TEST_COLLECT_QUES_INDEX:
      state.studentTestQuesIndex = action.index;
      state.studentTestQuesArr.map((data, index) => {
        if(index === action.index){
          if(data.type === 'MCQ'){
            data.options.map((item, i) => {
              if(item.id == data.studentAnswer){
                item.correct = true;
              }else{
                item.correct = false;
              }
            })
          }
          state.studentTestAnsObj.submitStatus = data.submitStatus === 'NotVisited' ? null : data.submitStatus;
          state.studentTestAnsObj.duration = 0;
          state.studentTestAnsObj.testQuestionId = '';
          state.studentBookmarkBool = (data.submitStatus === 'Bookmarked' || data.submitStatus === 'AttemptedAndBookmarked') ? true : false;

          if(data.type === 'Image'){
            // state.testUploadedBase64Arr = data.studentFiles === null ? [] : data.studentFiles.split(',');
            // state.studentTestAnsObj.answer = null;
            if(data.studentFiles === null ){
              state.testUploadedBase64Arr = [];
            }else{
              state.testUploadedBase64Arr = data.studentFiles;
            }
            // if(data.studentFiles === null ){
            //   state.testUploadedBase64Arr = [];
            //   data.studentFiles = null;
            // }else{
            //   let dummyArr = [];
            //   data.studentFiles.split(',').map((key, i) => {
            //     dummyArr.push({localImg:'', localImgObj:'', apiImg:key});
            //   });
            //   state.testUploadedBase64Arr = dummyArr;
            //   data.studentFiles = dummyArr;
            // }
          }else{
            state.studentTestAnsObj.answer = data.studentAnswer === null ? null : data.studentAnswer;
            state.testUploadedBase64Arr = [];
          }
        }
        return 1;
      })
      return {
        ...state,
        studentTestQuesIndex: action.index,
        studentTestAnsObj: {...state.studentTestAnsObj},
        testUploadedBase64Arr: [...state.testUploadedBase64Arr],
        studentTestQuesArr: [...state.studentTestQuesArr],
        testUploadedImgdeleteArr: [],
      };
    case STUDENT_TEST_COLLECT_MCQ:
      state.studentTestQuesArr.map((data, index) => {
        if(state.studentTestQuesIndex === index){
          data.options.map((item, i) => {
            if(item.id === action.id){
              if(item.correct){
                item.correct = false;
                state.studentTestAnsObj.answer = null;
              }else{
                item.correct = true;
                state.studentTestAnsObj.answer = item.id.toString();
              }
            }
            if(item.id !== action.id){
              item.correct = false;
            }
            return 1;
          })
        }
        return 1;
      })
      return {
        ...state,
        studentTestQuesArr: [...state.studentTestQuesArr],
        studentTestAnsObj: {...state.studentTestAnsObj},
        // studentTestQuesIndex: state.studentTestQuesIndex,
      };
    case STUDENT_TEST_COLLECT_IMAGE:
      if(state.testUploadedBase64Arr.length < 5){
        state.studentTestQuesArr.map((data, index) => {
          if(state.studentTestQuesIndex === index){
            state.testUploadedBase64Arr.push({localImg:action.urlPath, localImgObj:action.imgObj, apiImg:''});
            state.testUploadedImgArrNew.push({localImg:action.urlPath, localImgObj:action.imgObj, apiImg:''});
            state.studentTestAnsObj.answer = null;
          }
          return 1;
        })
      }

      return {
        ...state,
        testUploadedBase64Arr: [...state.testUploadedBase64Arr],
        testUploadedImgArrNew: [...state.testUploadedImgArrNew],
        studentTestQuesArr: [...state.studentTestQuesArr],
        studentTestAnsObj: {...state.studentTestAnsObj},
        // studentTestQuesIndex: state.studentTestQuesIndex,
      };
    case STUDENT_TEST_COLLECT_BOOKMARK:
      return {
        ...state,
        studentBookmarkBool: !state.studentBookmarkBool,
      };
    case STUDENT_TEST_UPDATE_ANS:
      state.studentTestQuesArr.map((data, index) => {
        if(data.type === 'Image'){
          if(index === state.studentTestQuesIndex){
            if(!state.testUploadedBase64Arr.length && state.studentBookmarkBool){
              state.studentTestAnsObj.submitStatus = 'Bookmarked';
              data.submitStatus = 'Bookmarked';
              data.studentFiles = null ;
            }else if(!state.testUploadedBase64Arr.length && !state.studentBookmarkBool){
              state.studentTestAnsObj.submitStatus = 'Unanswered';
              data.submitStatus = 'Unanswered';
              data.studentFiles = null;
            }else if(state.testUploadedBase64Arr.length && state.studentBookmarkBool){
              state.studentTestAnsObj.submitStatus = 'AttemptedAndBookmarked';
              data.submitStatus = 'AttemptedAndBookmarked';
              data.studentFiles = state.testUploadedBase64Arr;
            }else if(state.testUploadedBase64Arr.length && !state.studentBookmarkBool){
              state.studentTestAnsObj.submitStatus = 'Attempted';
              data.submitStatus = 'Attempted';
              data.studentFiles = state.testUploadedBase64Arr;
            }
          }
        }else{
          if(index === state.studentTestQuesIndex){
            if(state.studentTestAnsObj.answer === ''){
              state.studentTestAnsObj.answer = null;
            }
            if(state.studentTestAnsObj.answer === null && state.studentBookmarkBool){
              state.studentTestAnsObj.submitStatus = 'Bookmarked';
              data.submitStatus = 'Bookmarked';
              data.studentAnswer = null;
            }else if(state.studentTestAnsObj.answer === null && !state.studentBookmarkBool){
              state.studentTestAnsObj.submitStatus = 'Unanswered';
              data.submitStatus = 'Unanswered';
              data.studentAnswer = null;
            }else if(state.studentTestAnsObj.answer !== null && state.studentBookmarkBool){
              state.studentTestAnsObj.submitStatus = 'AttemptedAndBookmarked';
              data.submitStatus = 'AttemptedAndBookmarked';
              data.studentAnswer = state.studentTestAnsObj.answer;
            }else if(state.studentTestAnsObj.answer !== null && !state.studentBookmarkBool){
              state.studentTestAnsObj.submitStatus = 'Attempted';
              data.submitStatus = 'Attempted';
              data.studentAnswer = state.studentTestAnsObj.answer;
            }

          }
        }
        return 1;
      })


      let AttemptedAndBookmarked = state.studentTestQuesArr.filter(item => item.submitStatus === 'AttemptedAndBookmarked').length;
      let Attempted = state.studentTestQuesArr.filter(item => item.submitStatus === 'Attempted').length;
      let testProgress = AttemptedAndBookmarked + Attempted;
      console.log('state.studentTestAnsObj__', state.studentTestAnsObj);
      console.log('state.studentTestQuesArr__', state.studentTestQuesArr);
      return {
        ...state,
        studentTestQuesArr: [...state.studentTestQuesArr],
        testUploadedBase64Arr: [...state.testUploadedBase64Arr],
        studentTestAnsObj: {...state.studentTestAnsObj},
        testProgress: testProgress,
        // studentTestQuesIndex: state.studentTestQuesIndex,
      };
    case action.type:
      return {
        ...state,
        [action.boolName]: action.bool,
      };


    default:
      return state;
  }
};

export default reducer;
