import { fetchGeoFenceAlloctionListByRole, fetchLocationList, fetchUserList, postAllocateGeoLocation } from 'actions/attendanceNew'
import { Modal, Typography, Select, Checkbox, Button, AutoComplete, Input, DatePicker, Tag } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import "../style.css";
import GeoFenceSuccessModal from './geoFenceSuccessModal'

const AssignGeoFenceModal = ({
    isModalVisible, setIsModalVisible, fetchUserList, userList, fetchLocationList, locationList, postAllocateGeoLocation, isSettingPage = false,
    selectedUserIds, fetchGeoFenceAlloctionListByRole, success
}) => {
    let [checked, setChecked] = useState([true, false])
    const [optionUsers, setOptionUser] = useState([])
    const [optionLocation, setOptionLocation] = useState([])
    const [userLoader, setUserLoader] = useState(false)
    const [userName, setUserName] = useState('')
    let [userIds, setUserIds] = useState([])
    let [selectedUsers, setSelectedUsers] = useState([])
    const [locationLoader, setLocationLoader] = useState(false)
    const [locationName, setLocationName] = useState('')
    let [locationIds, setLoctionIds] = useState([])
    let [selectedLocations, setSelectedLocations] = useState([])
    const [type, setType] = useState('Permanent')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [assignLoader, setAssignLoader] = useState(false)
    const [isGeoFenceModal, setIsGeoFenceModal] = useState(false)
    const [disableTemporary, setdisableTemporary] = useState([])

    console.log('data', userIds, selectedUsers, optionUsers)

    const handleCancle = () => {
        setIsModalVisible(false)
    }

    const handleClick = (index) => {
        if (index == 0) {
            checked[index] = true
            checked[1] = false
            setStartDate('')
            setEndDate('')
            setType('Permanent')
        }
        else if (index == 1) {
            checked[0] = false
            checked[index] = true
            setType('Temporary')

        }
        setChecked([...checked])
    }

    // console.log('checked', checked)

    const searchResultUser = (query) =>
        query.map((item, index) => {
            return {
                key: index,
                value: item.id,
                isGlobalAssigned: item?.isGlobalAssigned,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>
                            {item.name}
                        </span>
                    </div>
                ),
            };
        });

    const searchResultLocation = (query) =>
        query.map((item, index) => {
            return {
                key: index,
                value: item.id,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>
                            {item.title}
                        </span>
                    </div>
                ),
            };
        });

    useEffect(() => {
        if (selectedUserIds) {
            setUserIds([...selectedUserIds])
        }
        if (!isSettingPage) {
            setUserLoader(true)
            fetchUserList('', () => setUserLoader(false))
        }
        setLocationLoader(true)
        fetchLocationList('', () => setLocationLoader(false))
    }, [])
    // console.log(type, startDate, endDate, userIds, locationIds)
    useEffect(() => {
        setOptionUser(userList ? [...searchResultUser(userList)] : [])
    }, [userList])

    useEffect(() => {
        setOptionLocation(locationList ? [...searchResultLocation(locationList)] : [])
    }, [locationList])

    useEffect(() => {
        setUserName('')
        fetchUserList('', () => setUserLoader(false))
    }, [selectedUsers.length])

    useEffect(() => {
        setLocationName('')
        fetchLocationList('', () => setLocationLoader(false))
    }, [selectedLocations.length])

    const handleChangeUsers = (value, obj) => {
        // setUserIds([])
        // setSelectedUsers([])
        if (value) {
            setUserName(obj?.label?.props?.children?.props?.children)
        }
        else {
            setUserName('')
        }
    }

    const onSelectUsers = (value, obj) => {
        if (value) {
            if (!userIds.includes(value)) {
                userIds.push(value)
                setUserIds(userIds)
                setUserName(obj?.label?.props?.children?.props?.children)
                selectedUsers.push({ id: value, name: obj?.label?.props?.children?.props?.children })
                disableTemporary.push({ id: value, name: obj?.label?.props?.children?.props?.children, isGlobalAssigned: obj?.isGlobalAssigned })
                setSelectedUsers(selectedUsers)
                setdisableTemporary(disableTemporary)
            }
        }
        else {
            setUserIds([])
            setUserName('')
            setSelectedUsers([])
            setdisableTemporary([])
        }
    }
    const disable = disableTemporary.some(item => item.isGlobalAssigned)

    const handleSearchUsers = (value) => {
        if (value) {
            setUserName(value)
            setUserLoader(true)
            fetchUserList(value, () => setUserLoader(false))
        }
        else {
            setUserName('')
            fetchUserList('', () => setUserLoader(false))
        }
    }

    const handleChangeLocation = (value, obj) => {
        // setUserIds([])
        // setSelectedUsers([])
        if (value) {
            setLocationName(obj?.label?.props?.children?.props?.children)
        }
        else {
            setLocationName('')
        }
    }

    const onSelectLocation = (value, obj) => {
        if (value) {
            if (!locationIds.includes(value)) {
                locationIds.push(value)
                setLoctionIds(locationIds)
                setLocationName(obj?.label?.props?.children?.props?.children)
                selectedLocations.push({ id: value, title: obj?.label?.props?.children?.props?.children })
                setSelectedLocations(selectedLocations)
            }
        }
        else {
            setLoctionIds([])
            setLocationName('')
            setSelectedLocations([])
        }
    }

    const handleSearchLocation = (value) => {
        if (value) {
            setLocationName(value)
            setLocationLoader(true)
            fetchLocationList(value, () => setLocationLoader(false))
        }
        else {
            setLocationName('')
            fetchLocationList('', () => setLocationLoader(false))
        }
    }

    const onCloseUser = (value) => {
        const temp1 = disableTemporary
        selectedUsers = selectedUsers.filter((item) => {
            return item.id != value
        })
        userIds = userIds.filter((item) => {
            return item != value
        })
        const indexToRemove = temp1.findIndex(item => item?.id === value);
        if (indexToRemove !== -1) {
            temp1.splice(indexToRemove, 1);
        }
        setUserIds(userIds)
        setSelectedUsers(selectedUsers)
        setdisableTemporary([...temp1])
    }

    const onCloseLocation = (value) => {
        selectedLocations = selectedLocations.filter((item) => {
            return item.id != value
        })
        locationIds = locationIds.filter((item) => {
            return item != value
        })
        setLoctionIds(locationIds)
        setSelectedLocations(selectedLocations)
    }

    const handleStartDate = (date, dateString) => {
        setStartDate(moment(date, 'DD-MM-YYY').format('YYYY-MM-DD'))
    }

    const handleEndDate = (date, dateString) => {
        setEndDate(moment(date, 'DD-MM-YYY').format('YYYY-MM-DD'))
    }

    const handleAssign = () => {
        let object = {
            type: type,
            startDate: startDate,
            endDate: endDate,
            userIds: userIds,
            geoFenceIds: locationIds
        }

        const successCallback = (e) => {
            setIsModalVisible(false)
            if (e?.showModal) {
                success();
            };
        }

        setAssignLoader(true)
        postAllocateGeoLocation(object, () => { setAssignLoader(false) }, (e) => { successCallback(e) }
        )
    }

    const successCallback = () => {
        setIsModalVisible(false)
        if (isSettingPage) {
            fetchGeoFenceAlloctionListByRole('')
        }
    }

    const disabledDate = (current) => {
        return current && current < moment().startOf('day');

    }
    const disabledEndDate = (current) => {
        //disable end date based on start date
        return current && current <= moment(startDate, "YYYY-MM-DD").subtract(1, 'day').endOf('day');
    }

    // (startDate && endDate && userIds?.length != 0 && locationIds?.length != 0) : (userIds?.length != 0 && locationIds?.length != 0))

    let assignButtonLogic = () => {
        if (checked?.includes(true)) {
            if (type == 'Temporary' && (startDate && endDate && userIds?.length != 0 && locationIds?.length != 0)) {
                return true
            } else if (type == 'Permanent' && (userIds?.length != 0 && locationIds?.length != 0)) {
                return true
            }
        }
        return false
    };

    const removeSelectedUsers = (currUser) => {
        for (let user of selectedUsers) {
            if (currUser?.value == user?.id) {
                return false;
            }
        }
        return true;
    }

    return (
        <div>
            <Modal
                className='modal-round-corner'
                visible={isModalVisible}
                footer={null}
                //centered={true}
                // width={"33%"}
                closable={true}
                bodyStyle={{ height: "100%" }}
                onCancel={() => handleCancle()}
            >
                <div className='scroll-bar-universal'>
                    <div style={{ marginTop: "10px", fontSize: "24px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center", }}>Assign Geo fence</div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        {!isSettingPage ?
                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                <div style={{ fontSize: "13px", color: "#636363", marginTop: "20px", fontWeight: "bold", marginLeft: "10px" }}>User(s)</div>
                                <AutoComplete
                                    style={{ marginLeft: "10px" }}
                                    allowClear={true}
                                    dropdownMatchSelectWidth={252}
                                    className="autocompletetoupload"
                                    options={optionUsers ? optionUsers?.filter(removeSelectedUsers) : []}
                                    value={userName}
                                    // onChange={handleChangeUsers}
                                    onSelect={onSelectUsers}
                                    onSearch={handleSearchUsers}
                                    loading={userLoader}
                                // disabled={selectedUsers?.length == 3 ? true : false}
                                >
                                    <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: '1px solid #e6e6e6', marginTop: "5px" }} placeholder={selectedUsers?.length == 3 ? 'Max Limit(3 Users)' : 'Search a name'} />
                                </AutoComplete>
                                {selectedUsers?.length != 0 ?
                                    <div>
                                        {selectedUsers?.map((item, index) => (
                                            <Tag key={item.id} closable color="#594099" style={{ marginTop: '10px', height: '32px', fontFamily: 'roboto', padding: '5px', textAlign: 'center', borderRadius: '6px', marginLeft: "10px" }} onClose={() => onCloseUser(item.id)}>{item.name}</Tag>
                                        ))}
                                    </div> : null}
                            </div> : null}
                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                            <div style={{ fontSize: "13px", color: "#636363", marginLeft: "10px", marginTop: "20px", fontWeight: "bold" }}>Locations</div>
                            <AutoComplete
                                style={{ marginLeft: "10px" }}
                                allowClear={true}
                                dropdownMatchSelectWidth={252}
                                className="autocompletetoupload"
                                options={optionLocation}
                                value={locationName}
                                // onChange={handleChangeLocation}
                                onSelect={onSelectLocation}
                                onSearch={handleSearchLocation}
                                loading={locationLoader}
                            // disabled={selectedLocations?.length == 5 ? true : false}
                            >
                                <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: '1px solid #e6e6e6', marginTop: "5px" }} placeholder={selectedLocations?.length == 5 ? 'Max Limit(5 Locations)' : 'Search a location'} />
                            </AutoComplete>
                            {selectedLocations?.length != 0 ?
                                <div>
                                    {selectedLocations?.map((item, index) => (
                                        <Tag key={item.id} closable color="#594099" style={{ marginLeft: "10px", marginTop: '10px', height: '32px', fontFamily: 'roboto', padding: '5px', textAlign: 'center', borderRadius: '6px' }} onClose={() => onCloseLocation(item.id)}>{item.title}</Tag>
                                    ))}
                                </div> : null}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                            <div style={{ fontSize: "13px", color: "#636363", marginTop: "10px", fontWeight: "bold" }}>Duration</div>
                            <div style={{ width: '80%', marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Checkbox style={{ fontWeight: 'bold' }} checked={checked[0]} onClick={() => handleClick(0)}>Permanent</Checkbox>
                                <Checkbox style={{ fontWeight: 'bold' }} checked={checked[1]} onClick={() => handleClick(1)} disabled={disable}>Temporary</Checkbox>
                            </div>
                        </div>
                        {
                            disable ?
                                <div style={{ marginTop: 10, fontSize: 12, marginLeft: 10, color: "red" }}>
                                    Since permanent location is global, you are not allowed to assign temporary location.
                                </div> :
                                <></>
                        }
                        {checked[1] == true ?
                            <div style={{ width: '95%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                                <div style={{ marginBottom: '10px', width: '45%' }}>
                                    <Typography.Text style={{ marginBottom: '5px' }}>Start Date</Typography.Text><br></br>
                                    <DatePicker showToday={false} allowClear={false} disabledDate={disabledDate} style={{ width: "100%", background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder='Select Date' format={'DD-MM-YYYY'} onChange={handleStartDate} />
                                </div>
                                <div style={{ marginBottom: '10px', width: '45%' }}>
                                    <Typography.Text style={{ marginBottom: '5px' }}>End Date</Typography.Text><br></br>
                                    <DatePicker showToday={false} allowClear={false} disabled={startDate ? false : true} disabledDate={disabledEndDate} style={{ width: "100%", background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder='Select Date' format={'DD-MM-YYYY'} onChange={handleEndDate} />

                                </div>
                            </div>
                            : null}
                    </div>

                    <div style={{ width: '100%', marginTop: 250, display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', justifyContent: 'center' }}>
                        <Button type='default' style={{ width: '120px', borderRadius: '16px', marginRight: '5px' }} onClick={() => handleCancle()}>Cancel</Button>
                        {/* disabled={moment(storeClockInStartDate).format('YYYY-MM-DD') > moment(storeClockInEndDate).format('YYYY-MM-DD')} */}
                        <Button loading={assignLoader} type='primary' style={{ width: '120px', borderRadius: '16px', marginLeft: '5px' }} onClick={() => handleAssign()} disabled={(assignButtonLogic() ? false : true) || (moment(startDate).format('YYYY-MM-DD') > moment(endDate).format('YYYY-MM-DD'))}>Assign</Button>
                    </div>


                </div>
            </Modal>

        </div>
    )
}

const mapStateToProps = state => {
    const {
        userList,
        locationList
    } = state.attendanceNew

    return {
        userList,
        locationList
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchUserList: (search, callback) => dispatch(fetchUserList(search, callback)),
    fetchLocationList: (search, callback) => dispatch(fetchLocationList(search, callback)),
    postAllocateGeoLocation: (object, callback, successCallback) => dispatch(postAllocateGeoLocation(object, callback, successCallback)),
    fetchGeoFenceAlloctionListByRole: (search, callback) => dispatch(fetchGeoFenceAlloctionListByRole(search, callback))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps

)(AssignGeoFenceModal);

// export default AssignGeoFenceModal