import { Col, DatePicker, Input, Modal, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Heading } from 'components/Typography';
import React, { useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { connect } from 'react-redux';
import { Option } from 'antd/lib/mentions';
import moment from 'moment/moment';
import { calculateLeaveDays, requestLeaveOrAttendance } from 'actions/profileNew';

const MarkLeaveModal = ({ onClose, visible, leaveDetails, requestLeaveOrAttendance, leaveFormDetails,
    setLeaveDetails, calculateLeaveDays, user, setPageLoader, userRowDetails, fromWhere }) => {

    const [allowSelectEndDate, setAllowSelectEndDate] = useState(true);
    const [buttonLoader, setButtonLoader] = useState(false)

    const clearDates = () => {
        setLeaveDetails((prevState) => ({ ...prevState, startDate: null, endDate: null, duration: '' }));
    };

    useEffect(() => {
        if (leaveFormDetails?.subType === 'half-day') {
            setAllowSelectEndDate(false);
            if (leaveFormDetails?.startDate) {
                setLeaveDetails((prevState) => ({
                    ...prevState,
                    endDate: leaveFormDetails?.startDate,
                }));
            }
        } else {
            setAllowSelectEndDate(true);
            clearDates();
        }
    }, [leaveFormDetails?.subType]);

    useEffect(() => {
        if (leaveFormDetails?.subType === "half-day") {
            setAllowSelectEndDate(false);
            if (leaveFormDetails?.startDate) {
                setLeaveDetails(prevState => ({ ...prevState, endDate: leaveFormDetails?.startDate }));
            }
        } else {
            setAllowSelectEndDate(true);
        }
    }, [leaveFormDetails?.subType]);

    useEffect(() => {
        if (leaveFormDetails?.subType === "half-day") {
            leaveFormDetails.endDate = leaveFormDetails?.startDate
        }
        setLeaveDetails({ ...leaveFormDetails })
    }, [leaveFormDetails?.startDate])

    useEffect(() => {
        if (leaveFormDetails?.startDate && leaveFormDetails?.endDate) {
            const params = {
                halfDay: leaveFormDetails?.subType == 'half-day' ? true : false,
                startDate: moment(leaveFormDetails?.startDate).format("YYYY-MM-DD"),
                endDate: moment(leaveFormDetails?.endDate).format("YYYY-MM-DD"),
                userId: fromWhere == 'userManagementProfile' ? userRowDetails?.id : user?.id
            }
            calculateLeaveDays(params, data => setLeaveDetails({ ...leaveFormDetails, duration: data.size }))
        }
    }, [leaveFormDetails?.startDate, leaveFormDetails?.endDate])

    const handleSave = () => {
        const params = {
            ...leaveFormDetails,
            requestType: "Leave",
            startDate: moment(leaveFormDetails?.startDate).format("YYYY-MM-DD"),
            endDate: moment(leaveFormDetails?.endDate).format("YYYY-MM-DD"),
            noOfdays: leaveFormDetails?.duration,
            userId: fromWhere == 'userManagementProfile' ? userRowDetails?.id : user?.id
        }
        console.log({ params });
        setButtonLoader(true)
        requestLeaveOrAttendance(params, () => setButtonLoader(false), onClose, setPageLoader)
    }

    const disabledEndDate = (current) => {
        // Can not select days before today and today
        return current && current < leaveFormDetails?.startDate;
    };

    return (
        <Modal
            visible={visible}
            footer={false}
            className="modal-round-corner"
            onCancel={onClose}
        >
            <div className='r-c-c m-t-20'>
                <Heading>{user?.privilage?.includes('SuperAdmin') ? 'Mark leaves' : 'Apply Leaves'}</Heading>
            </div>
            <div className='p-20'>
                <div>Leave type</div>
                <Select style={{ width: "100%" }} placeholder="Select leave type" value={leaveFormDetails?.leaveType} onChange={val => setLeaveDetails({ ...leaveFormDetails, leaveType: val })}>
                    {leaveDetails?.leaveTypesForUser?.map((leave, index) => <Option value={leave?.leaveType} key={index}>{leave?.leaveType} leave</Option>)}
                </Select>
            </div>
            <div className='p-20' style={{ paddingTop: 0 }}>
                <div>Sub type</div>
                <Select style={{ width: "100%" }} placeholder="Select sub type" value={leaveFormDetails?.subType} onChange={val => setLeaveDetails({ ...leaveFormDetails, subType: val })} disabled={!leaveFormDetails.leaveType}>
                    <Option value={"half-day"} disabled={!leaveDetails?.leaveTypesForUser?.filter(item => item?.leaveType === leaveFormDetails?.leaveType)[0]?.halfDayApplicable}>Half day</Option>
                    <Option value={"full-day"} >Full day</Option>
                </Select>
            </div>
            <Row className='p-20' style={{ paddingTop: 0 }}>
                <Col span={12}>
                    <div>Start date</div>
                    <DatePicker style={{ width: "100%" }} value={leaveFormDetails?.startDate} onChange={val => setLeaveDetails({ ...leaveFormDetails, startDate: val })} />
                </Col>
                <Col span={11} offset={1}>
                    <div>End date</div>
                    <DatePicker style={{ width: "100%" }} disabledDate={disabledEndDate} value={leaveFormDetails?.endDate} disabled={!leaveFormDetails?.startDate || !allowSelectEndDate} onChange={val => setLeaveDetails({ ...leaveFormDetails, endDate: val })} />
                </Col>
            </Row>

            <div className='p-20' style={{ paddingTop: 0 }}>
                <div>Duration</div>
                <Input disabled style={{ color: "black" }} value={leaveFormDetails?.duration} />
            </div>
            <div className='p-20' style={{ paddingTop: 0 }}>
                <div>Reason</div>
                <TextArea
                    placeholder='Enter reason'
                    style={{ resize: "none", height: 100 }}
                    value={leaveFormDetails?.reason}
                    onChange={e => setLeaveDetails({ ...leaveFormDetails, reason: e.target.value })}
                />
            </div>
            <div className='r-c-c m-t-40'>
                <Button type="primary"
                    onClick={handleSave}
                    loading={buttonLoader}
                    disabled={!leaveFormDetails?.leaveType || !leaveFormDetails?.leaveType === "" ||
                        !leaveFormDetails?.subType || !leaveFormDetails?.subType === "" ||
                        !leaveFormDetails?.startDate || !leaveFormDetails?.startDate === "" ||
                        !leaveFormDetails?.endDate || !leaveFormDetails?.endDate === "" ||
                        !leaveFormDetails?.reason || !leaveFormDetails?.reason?.trim() === ""}
                >{user?.privilage?.includes('SuperAdmin') ? 'MARK' : 'APPLY'}</Button>
            </div>


        </Modal>
    );
}


const mapStateToProps = (state) => {
    const { user } = state.session;
    const { leaveDetails } = state.profileNew;
    const { userRowDetails } = state.userManagementV2;
    return {
        leaveDetails, user, userRowDetails
    }
}

const mapDispatchToProps = (dispatch) => ({
    requestLeaveOrAttendance: (params, callback, successCallback, pagecallback) => dispatch(requestLeaveOrAttendance(params, callback, successCallback, pagecallback)),
    calculateLeaveDays: (params, callback) => dispatch(calculateLeaveDays(params, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(MarkLeaveModal);


