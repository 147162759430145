import React from 'react'
import {Button, Dropdown} from "antd"
import CustomMenu from './customMenu';
import { MdMoreVert } from 'react-icons/md';


const ReadyToPublishCard = ({title, standardName, subjectName, totalQues, totalTime, totalPoints, createdAt, imgval})=>{
    return(
    <>
    <div className='readyTo_Publish display-flex full-width m-b-10 m-r-10 customCardShadow cursor-pointer shadow-box hover-shadow relative' style={{
                width: 400,
                backgroundColor: "#FFFFFF", 
                border: "1px solid #E6E6E6", 
                borderRadius: 10,
                height:145
    }}>
        <img className='m-t-30 m-l-20' style={{height: 60, width: 60}} src={imgval}/>
        <div style={{display: "flex", flexDirection: "column"}}>
    <div className='bold-800 m-t-20 m-l-10'>{title}</div>
        <div className='text-xs m-l-10 m-t-5'>{standardName} | {subjectName}</div>
            <div className='text-xs m-l-10'>{totalQues}Qs {"•"} {totalTime} mins {"•"} {totalPoints} marks</div>
                <div className='r-jsb text-xs m-l-10 m-t-5'>
                <div className='flex-column text-xs'>
                    <div style={{color: '#AEAEAE'}}> CREATED ON </div>
                <div style={{fontWeight: "bold"}} className='bold-300r'>{createdAt}</div>
    </div>
    <Button className='backBlue white radius-100 text-xmd' style={{width: 80, height: 27, marginLeft: 80}}>
        <div>Publish</div>
        </Button>   
    </div>
    </div>
    <Dropdown overlay={<CustomMenu type={true?"publishedResult":"notPublishedResult"} 
        // test={test} 
        // handleButtonClick={handleButtonClick}
                            // isCModalVisible={isCModalVisible}
                            //  createTestModalBool={createTestModalBool}
                            // generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                            // editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader}
                            />}
                                        placement="bottomRight" arrow onClick={(e)=>{e.stopPropagation();}}>
                                <MdMoreVert className='text-lg a2grey cursor-pointer' style={{position:"absolute", top: "7px", height:25, right:"5px", zIndex:5}}/>
                            </Dropdown>
    </div>
    </>
)
}

export default ReadyToPublishCard