import React from 'react';
import "./loginPage.css"
import NewCarouselComponent from './newCarouselComponent';
import FormComponent from './formComponent';
import { CloseOutlined } from '@ant-design/icons';

function LoginCardComponent({ isLoginModal, handleCancel }) {
    return (
        <div className={`login-card-parent ${isLoginModal ? 'modal-width' : 'default-width'}`}>
            {isLoginModal &&
                <div className='closable-icon-container'>
                    <CloseOutlined style={{ color: "#636363", fontSize:18 }} onClick={handleCancel} />
                </div>}
            <NewCarouselComponent />
            <FormComponent isLoginModal={isLoginModal}/>
        </div>
    )
}

export default LoginCardComponent