import { Checkbox, Col, Image, Row, Tabs, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { LabelDiv } from './detailFields';
import ProfileInput from './detailFields'
import { IMG_URL } from "../../env.json";
import { CgFileDocument } from 'react-icons/cg'
import { Button } from 'components/Button';
import { CameraFilled, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import EditProfilePic from './editProfilePic';
import DeletePicConfirmationModal from './deletePicConfirmationModal';
import TabPane from 'antd/lib/tabs/TabPane';
import { splitText } from './accountDetails';
import { Notification } from 'services';

const DetailField = ({ field, index }) => {

    return field ? (
        <Col key={index} md={{ span: field.space === 0.5 ? 12 : 24 }} lg={{ span: field.space === 0.5 ? 6 : 12 }} style={{ width: "100%", ...field.style }}>
            <div style={{ width: "95%" }}>
                {field.type === "bullet" ?
                    <>
                        <LabelDiv>
                            {field.label}{field.required && <span style={{ color: "red" }}> *</span>}
                        </LabelDiv>
                        <ul className='display-flex'>
                            {field.value?.map((item, index) =>
                                <li key={index} className="m-r-20">{splitText(item)}</li>
                            )}
                        </ul>
                    </>
                    :
                    field?.type == 'Checkbox' ?
                        <>
                            {console.log(field, 'field value')}
                            <LabelDiv>
                                {field.label}{field.required && <span style={{ color: "red" }}> *</span>}
                            </LabelDiv>
                            <Checkbox.Group options={field?.value} value={field?.value?.filter(item => item?.isSelected).map((item) => item?.value)} onChange={field.onChange}>
                            </Checkbox.Group>
                        </>
                        :
                        <ProfileInput
                            label={field.label}
                            type={field.type}
                            disabled={field.disabled}
                            required={field.required}
                            value={field.value}
                            onChange={e => field?.onChange ? field.onChange(e) : {}}
                            onSelect={(value) => { field.onSelect ? field.onSelect(value) : {} }}
                            options={field.options}
                            placeholder={field.placeholder}
                            error={field.error}
                            onFocusChange={field.onFocusChange}
                            disabledDate={field?.disabledDate}
                            phonInputWithSendOtp={field?.phonInputWithSendOtp}
                        />
                        }
            </div>
        </Col>
    ) : null

}


const DetailSection = ({ section, index, userProfileDetails, fromAulasWallet }) => {

    return (
        <div key={index}>
            {fromAulasWallet ?
                <></>
                :
                <div className="r-jsb" >
                    <div >
                        <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 5 }}>{section.heading}</div>
                        <div style={{ fontSize: 14, marginBottom: 20 }}>{section.subHeading}</div>
                    </div>
                    {section.checkbox ? <div className='m-r-40' >
                        <Checkbox checked={section.checkbox.value} onClick={section.checkbox.action}
                            disabled={userProfileDetails?.basicprofile?.currentAddress?.city === undefined || userProfileDetails?.basicprofile?.currentAddress?.city === '' || userProfileDetails?.basicprofile?.currentAddress?.country === undefined ||
                                userProfileDetails?.basicprofile?.currentAddress?.country === '' || userProfileDetails?.basicprofile?.currentAddress?.h_no === '' || userProfileDetails?.basicprofile?.currentAddress?.pincode === '' ||
                                userProfileDetails?.basicprofile?.currentAddress?.h_no === undefined || userProfileDetails?.basicprofile?.currentAddress?.pincode === undefined ? true : false} />&nbsp; {section.checkbox.text}
                    </div> : null}
                </div>
            }
            <Row>
                {section.fields.map((field, index) =>
                    <DetailField
                        field={field}
                        index={index}
                    />
                )}
            </Row>
            {fromAulasWallet ? <></> : <div style={{ width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 20, marginTop: 20 }} />}
        </div>
    )
}


const ImageComponent = React.forwardRef(({userProfileDetails, src, setfileInputValue, handleEditModal, editable = true, fromLoginRegisterForm ,imageRemove,defaultImg}, ref) => {

    const [imgUrl, setImgUrl] = useState("");
    const [showFileFromLocal, setShowFileFromLocal] = useState(false);

    useEffect(() => {
        setShowFileFromLocal(false)
   
    }, [src]);

    const onChangeFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!file.type.startsWith("image/")) {
                Notification.error("Please upload a valid image file.");
                e.target.value = ""; 
                return;
            }
            setfileInputValue(file);
            setImgUrl(URL.createObjectURL(file)); 
            setShowFileFromLocal(true); 
        }
    };
useEffect(()=>{
    if(!imageRemove){
        setShowFileFromLocal(false)

    }
},[imageRemove])
 

    return (
        <div className='r-c-c-c m-b-30 m-t-100'>
            {fromLoginRegisterForm ?
                <Image
                    src={typeof src == 'object' ? URL.createObjectURL(src) : src} alt="" width={80} height={80} style={{ borderRadius: 100 }} />
                :
                <Image
                    src={ userProfileDetails?. image == 'default.png' && (userProfileDetails?. file == 'default.png' || !userProfileDetails?. file) ? IMG_URL + '/' + 'default.png' : !showFileFromLocal ? IMG_URL + '/' + src : imgUrl} alt="" width={120} height={120} style={{ borderRadius: 120 }} />
            }
            <input
                type="file"
                id="file"
                onChange={(e) => {
                    onChangeFile(e);
                }}
                ref={ref}
                style={{ display: "none" }}
                accept="image/*"
                value=""
            />
          
            {editable ?
                <div className="box-shadow cursor-pointer"
                    style={{ position: 'relative', bottom: 36, right: -50, fontSize: 18, background: 'white', padding: "5px 10px", borderRadius: 20 }}
                    onClick={() => handleEditModal(true)}
                >
                    <CameraFilled />
                </div> : null} 
        </div>
    )

})


const IndividualDetails = ({ data, index, span, type = "text" }) => {
    console.log({ data })

    const [isViewEnabled, setIsViewEnabled] = useState(data?.onView);

    const toggleView = () => {
        setIsViewEnabled((prevIsViewEnabled) => !prevIsViewEnabled);
        data?.onView()
    };

    return data ? (
        <Col span={span || 12} key={index} style={{ marginTop: 24, width: "100%", }}>
            <div style={{ size: 12, color: "#636363" }}>{data.label}</div>
            {data.type !== "document" ?

                <div className='r-jsb'>
                    <div style={{ fontFamily: 'roboto', fontSize: 16, width: "95%", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <Tooltip title={data.value}>{data.value} </Tooltip>
                    </div>
                    {data.hidden ? (
                        <>
                            {isViewEnabled ? (
                                <EyeOutlined className="m-r-10 cursor-pointer" onClick={toggleView} />
                            ) : (
                                <EyeInvisibleOutlined className="m-r-10 cursor-pointer" onClick={toggleView} />
                            )}
                        </>
                    ) : null}
                </div>
                :
                <div style={{ backgroundColor: "#E6E6E6", height: 92, width: "95%", alignItems: 'center', padding: 24, }} className="r-jsb"  >
                    <div className='r-c-c'>
                        <CgFileDocument style={{ fontSize: 24, color: "#1089FF" }} />
                        <div className='m-l-10'>
                            <div style={{ fontWeight: 600 }}>Document.{data.value?.split('.')[1]}</div>
                        </div>
                    </div>
                    <div>
                        <Button type="link" style={{ fontSize: 14 }} ><a href={IMG_URL + '/' + data.value} target="_blank">VIEW</a> </Button>
                    </div>
                </div>

            }
        </Col>
    ) : null
}


const EditImage = ({ editModalVisible, handleEditOk, onEditCancel,setEditProfileModal,setdeleteConfirmation,fromUserProfile,setProfileDetails,
    deleteConfirm, deleteModalVisible, onDeleteCancel, onDeleteConfirm, imageUrl ,addUserFieldsData, userProfileDetails}) => {
  
    return (
        <>
            <EditProfilePic
                imageUrl={imageUrl}
                // userDetailsSaved={userDetailsSaved}
                // deletePicLoader={deletePicLoader}
                isModalVisible={editModalVisible}
                // handleCancel={handleCancel}
                // userDetailsById={userDetailsById}
                handleOk={handleEditOk}
                handleProfileModalCancel={onEditCancel}
                setdeleteConfirmation={deleteConfirm}
                userProfileDetails={userProfileDetails}
            />
            <DeletePicConfirmationModal
                isModalVisible={deleteModalVisible}
                handleCancel={onDeleteCancel}
                onDeleteConfirm={onDeleteConfirm}
                setEditProfileModal={setEditProfileModal}
                setdeleteConfirmation={setdeleteConfirmation}
                addUserFieldsData={addUserFieldsData}
                fromUserProfile={fromUserProfile}
                setProfileDetails={setProfileDetails}
                userProfileDetails={userProfileDetails}
            />
        </>
    )
}

const TabsComponent = ({ handleTabSelect, selectedTab, instituteProfile, updateSettings, tabs }) => {

    return (
        <Tabs onChange={handleTabSelect} activeKey={selectedTab}>
            {Object.keys(tabs).map((tab, index) => (
                <TabPane tab={tab} key={tab}>
                    {tabs[tab].body}
                </TabPane>
            )
            )}
        </Tabs>
    )
}

const easySwitch = (history, user, setGsSelectedTab, setSubscriptionSelectedTab, setOthersSelectedTab) => {
    const easySwitchOptions = [{
        label: 'General settings',
        action: () => {
            history.push({
                state: { from: window.location.pathname },
                pathname: '/general-settings',
            })
            if (user.privilage.includes("Admin") || user.privilage.includes("SuperAdmin")) {
                setGsSelectedTab("User management")
            } else if (user.privilage.includes("FeeManager")) {
                setGsSelectedTab("Fee management")
            } else if (user.privilage.includes("LeaveManager")) {
                setGsSelectedTab("Attendance")
            } else {
                setGsSelectedTab("Attendance reminder")
            }
        }
    }];
    if (user?.access?.includes('subscription-settings')) {
        easySwitchOptions.push({
            label: 'Subscriptions/ Plans',
            action: () => {
                history.push({
                    state: { from: window.location.pathname },
                    pathname: '/subscriptions'

                })
                if (user.privilage.includes("Admin") || user.privilage.includes("SuperAdmin")) {
                    setSubscriptionSelectedTab("aulas Plan")
                } else if (user?.role?.includes("Data Operator")) {
                    setSubscriptionSelectedTab("Storage")
                } else if (user.privilage.includes("Planner")) {
                    setSubscriptionSelectedTab("Broadcast")
                }
            }
        })
    }
    if (user?.access?.includes('fees')) {
        easySwitchOptions.push({
            label: 'KYC details', action: () => history.push({
                state: { from: window.location.pathname },
                pathname: '/view-kyc',
            })
        })
    }
    if (user.access.includes('support-settings')) {
        easySwitchOptions.push({
            label: 'aulas care support', action: () => history.push({
                state: { from: window.location.pathname },
                pathname: '/support-settings',
            })
        })
    }
    if (user.role.includes("Student")) {
        easySwitchOptions.push({
            label: "Others", action: () => {
                history.push({
                    state: { from: window.location.pathname },
                    pathname: '/user-profile/others',
                })
                setOthersSelectedTab("Classroom & course(s)")
            }
        })
    }
    return easySwitchOptions
}

export { DetailField, DetailSection, ImageComponent, IndividualDetails, EditImage, TabsComponent, easySwitch }