import React, { useEffect, useState } from 'react';
import "./styles.css"
import "./myAcc.css"
import TopBar from './topBar';
import Carousel from 'react-multi-carousel';
import { Link } from "react-router-dom";
import webBanner1 from '../../Assets/aulasMarket/webBanner1.png';
import webBanner2 from '../../Assets/aulasMarket/webBanner2.png';
import courseImage from '../../Assets/aulasMarket/teamgrp.png';
import img1 from '../../Assets/aulasMarket/teamgrp.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Footer from './footer';
import { Breadcrumb, Button, Input, Modal, Rate } from 'antd';
import { AddSaveLater, AddToCart, AllCoursesData, CoursePageApi, Enquiry, HomeApi, RemoveSaveLater, storeSingleBuyNow, fetchPromoCodes, setAllCourseData, setCoursePage, setHomePageData, setParentLink, setPopularRender } from 'actions/aulasMarket';
import { connect } from 'react-redux';
import SearchError from './searchError';
import CourseProfile from './courseProfile';
import Slider from 'react-slick';
import "./carousel.css"
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import saveOutlined from "../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import saveFill from "../../Assets/aulasMarket/saveFilledIcon.svg"
import { Api } from 'services';
import { useHistory } from 'react-router-dom';
import PageDataLoader from 'components/pageDataLoader';
import Paragraph from 'antd/lib/typography/Paragraph';
import CarouselCompo from './carouselCompo';
import noImage from "../../Assets/aulasMarket/no-image.svg"
import moment from 'moment';
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';
import checkIcon from "../../Assets/aulasMarket/ic-check-filled.svg";



function AulasMarketHome({ setCoursePage, coursePageData, setHomePageData, homePageData, setAllCourseData, setPopularRender, loginDetails, setParentLink, fetchPromoCodes, promoCodeDetials, storeSingleBuyNow }) {
    const [isShareModalOpen, setShareModalOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [promoCode, setPromoCode] = useState(promoCodeDetials)
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [shareUrl, setShareUrl] = useState(null);

    const token = loginDetails?.accessToken;
    const [checked, setChecked] = useState(false);
    // const [formData, setFormData] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });
    const { name, phone, email, message } = formData;



    useEffect(() => {
        setLoader(true);
        setParentLink(null);
        HomeApi((res) => { setHomePageData(res) }, setLoader, token)
    }, [])

    useEffect(() => {
        fetchPromoCodes(() => { })
    }, [])


    const handleInputChange = (e, type) => {
        const newValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        setFormData({
            ...formData,
            [e.target.name]: newValue,
        });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        Enquiry(token, formData, (res) => {
            if (res?.show?.type === "success") {
                setFormData(null);
            }
        }, setLoader)
    };


    const CustomPrevArrow = (props) => (
        <div onClick={props.onClick} className={`r-c-c ${props?.learners ? 'custom-prev-arrow-lll' : 'custom-prev-arrow'} mobile-hidden`}>
            <LeftOutlined />
        </div>

    );

    const CustomNextArrow = (props) => (
        <div onClick={props.onClick} className={`r-c-c ${props?.learners ? 'custom-next-arrow-lll' : 'custom-next-arrow'} mobile-hidden`}>
            <RightOutlined />
        </div>
    );
    const CustomPrevArrowHome = (props) => (
        <div onClick={props.onClick} className={`r-c-c ${props?.top ? 'custom-prev-arrow-top' : 'custom-prev-arrow-home'} mobile-hidden`}>
            <LeftOutlined />
        </div>

    );

    const CustomNextArrowHome = (props) => (
        <div onClick={props.onClick} className={`r-c-c ${props?.top ? 'custom-next-arrow-top' : 'custom-next-arrow-home'} mobile-hidden`}>
            <RightOutlined />
        </div>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: homePageData?.newlyLaunchedCourses?.length >= 4 ? 4 : homePageData?.newlyLaunchedCourses?.length,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        className: "sliderClass",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],
    };
    const settingsPop = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: homePageData?.popularCourses?.length >= 4 ? 4 : homePageData?.popularCourses?.length,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        className: "sliderClass",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],
    };
    const settingsLearner = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: homePageData?.testimonialsData?.length >= 3 ? 3 : homePageData?.testimonialsData?.length,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow learners={true} />,
        nextArrow: <CustomNextArrow learners={true} />,
        className: "sliderClass",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],
    };
    const settingsTop = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrowHome top={true} />,
        nextArrow: <CustomNextArrowHome top={true} />,
        className: "sliderClass",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],
    };
    const homeSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrowHome />,
        nextArrow: <CustomNextArrowHome />,
        className: "",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],
    };
    const settingsStudent = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        className: "student-slides",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],

        autoplay: true,
        autoplaySpeed: 3000,
    };

    const [saved, setSaved] = useState({});
    const history = useHistory();


    const handleSaveForLater = (id, itemType) => {
        console.log('handleSaveForLater', id, itemType);
        if (token) {
            if (saved[id]) {
                RemoveSaveLater(token, id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: false,
                        }));
                    }
                }, setLoader);
            } else {

                AddSaveLater(token, id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: true,
                        }));
                    }
                }, setLoader);
            }
        } else {
            history.push('/login');
        }
    };

    const handleAddToCart = (itemType, id) => {
        AddToCart(token, id, itemType, (res) => { }, setLoader);
    }

    const HoverCard = ({ course }) => {
        const [enroll, setEnroll] = useState(false);
        console.log({ course })
        const handleCourseClick = () => {
            setLoader(true);
            CoursePageApi(token, course?.id, (res) => {
                if (res) {
                    setCoursePage(res)
                    history.push('/course-page');
                } else {

                }
            },
                setLoader
            );
        };

        const handleBuyNow = () => {
            storeSingleBuyNow(course)
            history.push('/checkout');
        }

        const handleEnrollNow = () => {
            console.log("enrolled")
            setEnroll(true);
        }

        const onlineDes = "Classes to be conducted over the internet."
        const hybridlDes = "Classes to be held in physical classroom and also over the internet."
        const offlineDes = "Classes to be held in physical classroom."
        return (
            <div className=" hover-card-new p-15 mobile-hidden" >
                <div className="hover-title" style={{ color: "#191919", cursor: "pointer" }} onClick={handleCourseClick}>
                    {course?.name}
                    {console.log('cccc', course)}
                </div>
                <div className='hover-des' style={{}}>{course?.courseInfo}</div>
                <div className='r-c-fs text-sm'><div style={{ color: "#FFA931" }}>{course?.ratingValue}</div><Rate disabled defaultValue={course?.ratingValue} style={{ color: "#FFA931" }} /> <div style={{ color: "#636363", fontSize: "14px" }}>{course?.ratingCount}</div></div>
                <div className='r-c-sb m-t-10'>
                    <div className='sellerButton-home' style={{}}>Bestseller</div>
                    <div className='r-c-sb' style={{ width: "5vw" }}>
                        <div onClick={() => handleSaveForLater(course?.id, course?.resourceType)} style={{ cursor: "pointer" }} ><img style={{ width: "18px" }} src={saved[course?.id] ? saveFill : saveOutlined} /> </div>
                        <div onClick={() => {
                            setShareModalVisible(true);
                            setShareUrl(`${Api?._base_url}/course-page?id=${course?.id}&type=${course?.itemType}`)
                        }} style={{ cursor: "pointer" }}><img style={{ width: "18px" }} src={require("../../Assets/aulasMarket/shareIcon.svg").default} /></div>
                    </div>
                </div>
                <div className='hover-content' style={{}}>{course?.brief}</div>
                <div className={`font-bold r-c-c ${course?.mode === 'hybrid' ? 'hybridHover' : (course?.mode === 'online' ? 'onlineHover' : 'offlineHover')}`} style={{}}>{course?.mode?.toUpperCase()}</div>
                <div className='hover-course-mode' style={{ color: "#AEAEAE" }}>
                    {course?.mode === 'online' && onlineDes}
                    {course?.mode === 'hybrid' && hybridlDes}
                    {course?.mode === 'offline' && offlineDes}
                </div>

                {/* <div className='r-c-c text-sm bold-600 m-t-15'><Button style={{ borderRadius: "16px", color: "#FFFFFF", backgroundColor: "#594099", border: "1px solid #594099" }}>REGISTER NOW</Button></div> */}
                <div className='r-c-c m-t-10'  >
                    {enroll ? <>
                        <div className='r-c-fs' style={{ color: "#594099", fontWeight: "500", fontSize: "16px" }}> <img src={checkIcon} /> ENROLLED</div>
                    </> :
                        <>
                            <Button className='content-add-1' onClick={() => handleAddToCart(course?.resourceType, course?.id)} style={{}}>ADD TO CART</Button>
                            {course?.mode === "online" ?
                                <Button className='content-reg-1' onClick={handleEnrollNow} style={{}}>ENROLL NOW</Button>
                                :
                                <Button className='content-reg-1' onClick={handleBuyNow} style={{}}>BUY NOW</Button>
                            }
                        </>
                    }
                </div>
            </div>
        );
    };

    const NewlyCourse = ({ data }) => {
        const [isMobileView, setIsMobileView] = useState(false);

        useEffect(() => {

            const checkMobileView = () => {
                setIsMobileView(window.innerWidth < 768);
            };
            window.addEventListener('resize', checkMobileView);
            checkMobileView();
            return () => {
                window.removeEventListener('resize', checkMobileView);
            };
        }, []);
        const handleCourseClick = () => {
            setLoader(true);
            CoursePageApi(token, data?.id, (res) => {
                if (res) {

                    setCoursePage(res)
                    history.push('/course-page');
                } else {

                }
            }, setLoader);
        };
        let discountAmt = 0;
        const percent = 50;

        if (typeof data?.amount === 'number') {
            discountAmt = (data.amount * percent) / 100;
        }
        return (
            <>

                <div className='beta parent-carousel'>
                    <div className='carousel-slide'>
                        <div className='' style={{ width: "250px", height: "150px", padding: "0px 0px" }}>
                            {data?.amount === null || data?.amount === 0 ?
                                <div className='freeBanner1'><img src={require("../../Assets/aulasMarket/freePng (2).png").default} /></div>
                                : null}
                            <div className={`font-bold r-c-c ${data?.mode === 'hybrid' ? 'hybrid1' : (data?.mode === 'online' ? 'online1' : 'offline1')}`} style={{}}>{data?.mode}</div>
                            {data?.image !== null ?
                                <img className='carouselImg' style={{}} src={`${Api.dlS3Url}${data?.image}`} alt={data.name} />

                                :
                                <div className='r-c-c' style={{ width: "240px", border: "1px solid #E6E6E6", boxShadow: "4px 4px 16px #302A5F29", height: "135px" }}>
                                    <img className='popular-Img' style={{ width: "40px" }} src={noImage} />
                                </div>
                            }

                            {data?.classType === "Online" ? null :
                                <div className='cost1 r-c-c font-bold' style={{}}>
                                    ₹{data?.amount}
                                    {/* <span style={{ textDecoration: 'line-through', color: '#FF414D', fontSize: "12px", marginLeft: 5 }}>₹{discountAmt}</span> */}
                                </div>
                            }
                        </div>

                        <div className="card-title" style={{}} >{data?.name}</div>
                        <div className='card-description'>{data?.courseInfo}</div>
                        {data?.ratingCount === 0 || data?.ratingValue === null ?
                            null :
                            <div className='display-flex'>
                                <div className='font-bold' style={{ color: "#FFA931", fontSize: 16 }}>{data?.ratingValue}</div>
                                <div style={{ margin: "-8px 5px 0px 5px" }}><Rate disabled defaultValue={data?.ratingValue} style={{ color: "#FFA931" }} /></div>
                                <div style={{ color: "#636363", fontSize: 16 }}>{data?.ratingCount}</div>
                            </div>
                        }

                        <div className='mobile-hidden' >
                            <HoverCard course={data} />
                        </div>

                    </div>

                </div>

            </>
        )
    }
    const PropularData = ({ data }) => {
        const [isMobileView, setIsMobileView] = useState(false);

        useEffect(() => {

            const checkMobileView = () => {
                setIsMobileView(window.innerWidth < 768);
            };
            window.addEventListener('resize', checkMobileView);
            checkMobileView();
            return () => {
                window.removeEventListener('resize', checkMobileView);
            };
        }, []);
        const handleCourseClick = () => {
            setLoader(true);
            CoursePageApi(token, data?.id, (res) => {
                if (res) {

                    setCoursePage(res)
                    history.push('/course-page');
                } else {

                }
            }, setLoader);
        };
        let discountAmt = 0;
        const percent = 50;

        if (typeof data?.amount === 'number') {
            discountAmt = (data.amount * percent) / 100;
        }
        return (
            <>

                <div className='beta parent-carousel'>
                    <div className='carousel-slide' >

                        <div className='' style={{ width: "250px", height: "150px", padding: "0px 0px" }}>
                            {data?.amount === null || data?.amount === 0 ?
                                <div className='freeBanner1'><img src={require("../../Assets/aulasMarket/freePng (2).png").default} /></div>
                                : null}
                            <div className={`font-bold r-c-c ${data?.mode === 'hybrid' ? 'hybrid1' : (data?.mode === 'online' ? 'online1' : 'offline1')}`} style={{}}>{data?.mode}</div>
                            {data?.image !== null ?
                                <div className='r-c-c' style={{}}>
                                    <img className='carouselImg' style={{}} src={`${Api.dlS3Url}${data?.image}`} alt={data.name} />
                                </div>
                                :
                                <div className='r-c-c' style={{ width: "240px", border: "1px solid #E6E6E6", boxShadow: "4px 4px 16px #302A5F29", height: "135px" }}>
                                    <img className='popular-Img' style={{ width: "40px" }} src={noImage} />
                                </div>
                            }

                            {data?.classType === "Online" ? null :
                                <div className='cost1 r-c-c font-bold' style={{}}>
                                    ₹{data?.amount}
                                    {/* <span style={{ textDecoration: 'line-through', color: '#FF414D', fontSize: "12px", marginLeft: 5 }}>₹{discountAmt}</span> */}
                                </div>}
                        </div>

                        <div className="card-title" style={{}} >{data?.name}</div>
                        <div className='card-description'>{data?.courseInfo}</div>
                        {data?.ratingCount === 0 || data?.ratingValue === null ?
                            null :
                            <div className='display-flex'>
                                <div className='font-bold' style={{ color: "#FFA931", fontSize: 16 }}>{data?.ratingValue}</div>
                                <div style={{ margin: "-8px 5px 0px 5px" }}><Rate disabled defaultValue={data?.ratingValue} style={{ color: "#FFA931" }} /></div>
                                <div style={{ color: "#636363", fontSize: 16 }}>{data?.ratingCount}</div>
                            </div>
                        }

                        <div className='mobile-hidden' >
                            <HoverCard course={data} />
                        </div>

                    </div>

                </div>

            </>
        )
    }

    const TopCateg = ({ data }) => {
        return (
            <center className='topCategory m-t-30' style={{}}>
                {data?.name}
            </center>
        )
    }


    const ResultsComponent = ({ resultData }) => {
        console.log("results", resultData)

        const SliderImages = ({ item }) => {
            return (
                <>
                    <div>
                        {/* <img src={`${Api.dlS3Url}${item?.key}`} style={{ width: 100, height: 100 }} /> */}
                        <img src={noImage} style={{ width: 100, height: 100 }} />
                        <center className='font-bold r-c-c' style={{ background: "#594099", height: 20, color: "#FFFFFF", borderRadius: "0px 0px 8px 8px" }}>
                            {item?.rank}
                        </center>
                        <center style={{ fontSize: 12, color: "#636363", marginTop: 5, fontFamily: "roboto" }}>
                            {item.name}
                        </center>
                        <center className='font-bold' style={{ fontSize: 12, color: "#191919" }}>
                            {item?.scoreObtain}/{item?.totalMarks}
                        </center>
                    </div>
                </>
            )
        }

        return (
            <>
                <div className='outStanding-card-parent m-t-20'>
                    <center className='font-bold r-c-c' style={{ background: "#594099", height: 50, color: "#FFFFFF", width: "100%", fontSize: 20, borderRadius: "8px 8px 0px 0px" }}>
                        {resultData?.examName}
                    </center>
                    <div style={{ background: "#F6F4FE" }}>
                        <div className='results-parent m-l-10' style={{}}>
                            <div className='r-jsb'>
                                <Slider {...settingsStudent} >
                                    {resultData?.students?.map((item, itemIndex) => (
                                        <SliderImages item={item} />
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    };
    const LearnersCard = ({ item }) => {
        return (
            <div className='r-c-c-c learners-card' style={{}}>
                <img style={{ width: 50, height: 50 }} src={item?.key ? `${Api.dlS3Url}${item?.key}` : noImage} alt={item.key} />
                <center className='font-bold m-t-10' style={{ color: "#191919", fontSize: 16 }}>{item?.name}</center>
                <center className='m-t-10' style={{ fontSize: 14, textAlign: "justify", color: "#191919", fontFamily: "roboto", textAlign: "center" }}>"{item?.review}"</center>
            </div>
        )
    }

    const handleViewAll = () => {
        setLoader(true);
        AllCoursesData(token, "", (res) => {
            if (res) {

                setCoursePage(res)
                history.push('/popular-courses');
                setPopularRender(0)
            } else {


            }
        }, setLoader);
    }

    const BannerView = ({ data }) => {
        return (
            <>
                <div>
                    <img
                        className=''
                        style={{
                            width: "1500px",
                            height: "420px",
                            opacity: 1,
                        }} src={`${Api._s3_url}${data?.key}`} alt={data.key}
                    />
                </div>
            </>
        )
    }


    return (
        <>
            <div className="top-bar">
                <TopBar />
            </div>
            <div style={{ backgroundColor: "#FAFAFA" }}>
                <div className='main-container-parent'>
                    {promoCode?.length == 0 ? null :
                        <>
                            <div className='discount-banner' style={{}}>
                            </div>
                            <div className='r-c-sb discount-container' style={{}}>
                                {promoCode?.[0]?.discountType == 'percent' ?
                                    <div className='text-mdl font-bold' style={{ opacity: 1, color: "#594099" }}>Avail discount of {promoCode?.[0]?.discount}%</div>
                                    : <div className='text-mdl font-bold' style={{ opacity: 1, color: "#594099" }}>Avail discount of ₹{promoCode?.[0]?.discount}</div>
                                }
                                <div className='text-xmd' style={{ opacity: 1, color: "#594099", display: "flex", alignItems: "center" }}>
                                    <span className='m-r-10' style={{ display: "flex", alignItems: "center" }}>Use the promotional offer <Paragraph copyable style={{ fontWeight: 700, fontSize: 16, margin: "10px 5px", display: "flex", alignItems: "center", gap: 5, color: "#594099" }}>{promoCode?.[0]?.code}</Paragraph> to get an instant discount</span>
                                    <div className='r-c-c validity-tag'>Valid until {moment(promoCode?.[0]?.endDate).format('DD-MM-YYYY')}</div>
                                </div>
                            </div>
                        </>
                    }

                    <div className="main-container" style={{ marginTop: "0px" }}>
                        {homePageData?.bannerData && homePageData?.bannerData?.length > 0 ? (<>
                            <div className='carousel1' style={{}} >
                                <Slider {...homeSettings}>
                                    {homePageData?.bannerData?.map((item) => {
                                        return (
                                            <BannerView data={item} />
                                        )
                                    })}
                                </Slider>
                                <div className='reachus-Parent'>
                                    {homePageData?.enquiryForm ?
                                        <div className='reach-us'>
                                            <form onSubmit={handleSubmit}>
                                                <div className='r-c-c p-20 font-bold text-sm'>REACH US</div>
                                                <div className='form1' >
                                                    <div className='name-field'>
                                                        <label>Full Name</label>
                                                        <input type="text"
                                                            className="input-field-name"
                                                            name="name"
                                                            value={name}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className='name-field'>
                                                        <label>Mobile Number</label>
                                                        <input type="text"
                                                            className="input-field-mob"
                                                            name="phone"
                                                            value={phone}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className='name-field'>
                                                        <label>Email ID</label>
                                                        <input type="text" className="input-field-email"
                                                            name="email"
                                                            value={email}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className='r-c-c msg-form' >
                                                    <div className='form2'>
                                                        <label>Message</label>
                                                        <input type="text" className="input-field-msg"
                                                            name="message"
                                                            value={message}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='ack-field' >
                                                    <div className='field-btns'>
                                                        <label >
                                                            <input type="checkbox" className='m-r-10'
                                                                name="authorize"
                                                                checked={checked}
                                                                onChange={() => { setChecked(!checked) }}
                                                            />
                                                            I hereby authorize Vectors Academy to contact me.
                                                        </label>
                                                        <div>
                                                            <Button disabled={!checked} type="submit" onClick={handleSubmit} className='submit-button'>SUBMIT</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        : null}

                                </div>
                            </div>
                        </>) : null}

                        {homePageData?.newlyLaunchedCourses && homePageData.newlyLaunchedCourses.length > 0 ? (<>
                            <div className='carousel-containerrr m-t-20' style={{}}>
                                <div className='home-header'>Newly launched Courses</div>
                                <Slider {...settings}>
                                    {homePageData?.newlyLaunchedCourses?.map((item, index) => (
                                        <NewlyCourse data={item} index={index} />
                                    ))}
                                </Slider>

                                {/* <CarouselCompo data={homePageData?.newlyLaunchedCourses} /> */}
                            </div>
                        </>) : null}



                        {homePageData?.popularCourses && homePageData?.popularCourses?.length > 0 ? (<>
                            <div className="carousel-containerrr" style={{}}>
                                <div className="home-header">Popular Courses</div>
                                <Slider {...settingsPop}>
                                    {homePageData?.popularCourses?.map((item, index) => (
                                        <PropularData data={item} index={index} />
                                    ))}
                                </Slider>
                            </div>
                        </>) : null}



                        {homePageData?.newlyLaunchedCourses?.length > 4 && homePageData?.popularCourses?.length > 4 ? (
                            <center onClick={handleViewAll} className='font-bold cursor-pointer' style={{ color: "#594099", fontSize: 16, position: "relative", zIndex: 100 }}>
                                VIEW ALL COURSES
                            </center>
                        ) : null}



                        {homePageData?.topCategories && homePageData.topCategories.length > 0 ? (
                            <>
                                <div className="home-header">Top Categories</div>
                                <div className='topCate-Parent' style={{}}>
                                    <Slider {...settingsTop}>
                                        {homePageData?.topCategories?.map((item, index) => (
                                            <div className='silderClass' style={{}}>
                                                <TopCateg data={item} topCat={true} />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </>
                        ) : null}

                        {/* <center className='font-bold m-t-20' style={{ color: "#594099", fontSize: 16 }}>VIEW ALL CATEGORIES</center> */}
                        {homePageData?.outStandingResultDataArray && homePageData.outStandingResultDataArray.length > 0 ? (
                            <>
                                <div className="home-header m-t-50">Outstanding Results</div>
                                <div className='outStanding-parent'>
                                    {homePageData?.outStandingResultDataArray?.map((item) => {
                                        return (
                                            <div>
                                                <ResultsComponent resultData={item} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        ) : null}

                        {/* <center className='font-bold cursor-pointer m-t-60' style={{ color: "#594099", fontSize: 16 }}>VIEW ALL OUTSTANDING RESULTS</center> */}

                        {homePageData?.testimonialsData && homePageData.testimonialsData.length > 0 ? (
                            <>
                                <div className="home-header m-t-50">Listen from our Learners</div>
                                <div className='learners-parent m-t-30' style={{}}>
                                    <Slider {...settingsLearner}>
                                        {homePageData?.testimonialsData?.map((item, index) => (
                                            <LearnersCard
                                                item={item} />
                                        ))}
                                    </Slider>
                                </div>
                            </>
                        ) : null}

                    </div >
                </div>
                <div className='m-t-100' style={{}}>
                    <Footer />
                </div>
            </div >
            {/* share Modal  */}
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl} />}
            <PageDataLoader visible={loader} />
        </>

    )
}

const mapStateToProps = (state) => {
    const { popularRenderPage, coursePageData, homePageData, loginDetails, promoCodeDetials } = state.aulasMarket
    return { popularRenderPage, coursePageData, homePageData, loginDetails, promoCodeDetials }
};

const mapDispatchToProps = (dispatch) => ({
    setCoursePage: (item) => dispatch(setCoursePage(item)),
    setHomePageData: (item) => dispatch(setHomePageData(item)),
    HomeApi: (callback) => dispatch(HomeApi(callback)),
    setAllCourseData: (val) => dispatch(setAllCourseData(val)),
    setPopularRender: (val) => dispatch(setPopularRender(val)),
    setParentLink: (val) => dispatch(setParentLink(val)),
    fetchPromoCodes: (callback) => dispatch(fetchPromoCodes(callback)),
    storeSingleBuyNow: res => dispatch(storeSingleBuyNow(res))
});

export default connect(mapStateToProps, mapDispatchToProps)(AulasMarketHome)