import React, { useMemo } from "react";
import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { Select, Divider, Modal } from 'antd'
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import { addMessageToGroup, postChatImage, setRightView, setRightViewType, updateUserList, updateUserCount, setCurrentPrivateChatReciever, updateUnreadCount, postMessageOpenStatus } from 'actions/broadcast'
import { GiftedChat, Composer, Send } from 'react-web-gifted-chat'
import { renderTime, renderBubble, renderInputToolbar } from "./giftedChatComponenets";
import { v4 as uuidv4 } from 'uuid';
import { Notification, Api } from "services";
import { CountBadge } from "./actionButtons";
import { SmallHeading } from "components/Typography";

const UserCard = ({ userId, profilepic, name, joinedBefore, isMuted, imageUrl, user,
    setRightViewType, setCurrentPrivateChatReciever, sessionuser, statustoshow }) => {
    return (
        <div className="m-b-5" style={{ height: '10%', width: "100%", display: 'flex', flexDirection: 'row' }}>
            <div className="r-c-sb" style={{ width: "85%", height: "100% !important" }}>
                <div className="r-c-c" style={{ marginRight: "5%" }}>
                    <img style={{ borderRadius: "50%", height: 40, width: 40 }} src={`${Api._s3_url}${imageUrl}`} alt=""></img>
                </div>
                <div style={{ width: "100%" }}>
                    <div className="white rfbc0p8 bold">
                        {name}
                    </div>

                    <div className="white rfbc0p8 opacity50" style={{ whiteSpace: 'nowrap' }}>
                        {user.id == sessionuser.id ? "(You) " : ""}{user.access?.includes("schedule-teacher") ? "Teacher" : ""}
                        {/* {`Joined ${joinedBefore} mins ago`}z */}
                    </div>
                </div>
            </div>
            <div style={{ width: "15%", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div
                    style={{
                        height: '0.8vw', width: '0.8vw', borderRadius: "50%",
                        backgroundColor: statustoshow == "online" ? "#32cd32" : "#E83100",
                        marginRight: "20%"
                    }}>
                </div>
                {
                    userId != sessionuser.id
                        ?
                        <div className="r-c-c" style={{ height: '1.1vw', width: '1.1vw', cursor: sessionuser.access.includes('schedule-student') && user.isStudent ? null : 'pointer' }} onClick={() => {
                            if (sessionuser.access.includes('schedule-student') && user.isStudent) {
                                return
                            }
                            setRightViewType('messageStudent');
                            setCurrentPrivateChatReciever(user);
                        }}>
                            {
                                sessionuser.access.includes('schedule-student') && user.isStudent ?
                                    null
                                    : <img className="full-height full-width" src={require('./assets/Group 11870.svg').default} alt="" />
                            }
                        </div>
                        :
                        null
                }
            </div>
        </div>
    )
}

const UsersList = ({ usersList, setRightViewType, setCurrentPrivateChatReciever, sessionuser, setRightView }) => {
    return (
        <>
            {usersList && usersList.length > 0
                ?
                <div style={{ height: "95%", overflowY: 'auto' }} className="m-t-20">
                    {usersList?.map((userarr, index) => (

                        <UserCard key={index} userId={userarr.user.id} name={userarr.user.name}
                            joinedBefore={'1'} isMuted={true} imageUrl={userarr.user.image}
                            user={userarr.user} setRightViewType={setRightViewType}
                            setCurrentPrivateChatReciever={setCurrentPrivateChatReciever}
                            sessionuser={sessionuser} statustoshow={userarr.statustoshow}
                        />))}
                </div>
                :
                null
            }
        </>
    )
}

const PrivateChatProfileCard = ({ id, name, lastMessage, lastMessageTime,
    unreadmessages, setRightViewType, imageUrl,
    user, setCurrentPrivateChatReciever, unreadCount }) => {
    return (
        <div className="r-jsb p-10 m-b-20 radius-10 cursor-pointer privatechatprofilecard" style={{ width: "100%", backgroundColor: "#5d5d5d", position:"relative" }}
            onClick={() => {
                setRightViewType('messageStudent');
                setCurrentPrivateChatReciever(user);
            }}>
            <div style={{ maxWidth: "80%"}}>
                <div className="r-c-fs">
                    <div className="m-r-20 r-c-c" style={{}}>
                        <img style={{ borderRadius: "50%", height: 30, width: 30 }} src={`${Api._s3_url}${imageUrl}`} alt=""></img>
                    </div>
                    <div className="white bold" style={{ fontSize: '0.8vw', fontStyle:"Roboto", fontFamily:"Roboto"}}>
                        {name}
                    </div>
                </div>
                {
                    lastMessage!=undefined && lastMessage!=''?
                    <div className="white" style={{ marginTop:10, maxHeight: "50%", overflow: "hidden", textOverflow: 'ellipsis', opacity: "80%", maxHeight: 80, fontSize: "0.8vw" }}>
                        {lastMessage}
                    </div>
                    :null
                }


            </div>
            {unreadCount>0
            ?
            <CountBadge count={unreadCount} style={{top:lastMessage!=undefined && lastMessage!=''?"65%":"45%", right:"5%", zIndex:10}}/>
            :
            null}

        </div>
    )
}
const PrivateChatView = ({ usersList, setRightViewType, setCurrentPrivateChatReciever,
    sessionuser, privateMessages, setRightView, unreadCount, selectedSchedule }) => {
    const sortedList = useMemo(()=>{
        if (!privateMessages || Object.keys(privateMessages).length===0){
            return []
        }
        return usersList.sort((a, b) => {
            let left = a.user.id in unreadCount['private'] ? unreadCount['private'][a.user.id]: 0;
            let right = b.user.id in unreadCount['private'] ? unreadCount['private'][b.user.id]: 0;
            return right - left;
        }).filter((item)=>((privateMessages[item.user.id]&&privateMessages[item.user.id].length)||(sessionuser.access.includes("schedule-student") && item.user.id===selectedSchedule.teacherId)))
        }, [usersList, unreadCount, privateMessages])
    return (
        <>
            {
                privateMessages && sortedList && sortedList.length > 0 
                    ?
                    <div style={{ height: "95%", overflowY: 'auto' }} className="m-t-20">
                        {sortedList?.map((userarr, index) => (
                            userarr.user.id != sessionuser.id ?
                                sessionuser.access?.includes('schedule-student') && userarr.user?.isStudent ?
                                    null
                                    :
                                    <PrivateChatProfileCard id={userarr.user.id}
                                        user={userarr.user}
                                        name={userarr.user.name}
                                        lastMessage={(privateMessages != null && privateMessages[userarr.user.id] != null && privateMessages[userarr.user.id].length > 0) ? privateMessages[userarr.user.id][privateMessages[userarr.user.id].length - 1].text : ""}
                                        // privateMessages={privateMessages} 
                                        unreadmessages={7} lastMessageTime={6}
                                        setRightViewType={setRightViewType}
                                        imageUrl={userarr.user.image}
                                        unreadCount={(userarr.user.id in unreadCount['private'])? unreadCount['private'][userarr.user.id]:0}
                                        setCurrentPrivateChatReciever={setCurrentPrivateChatReciever}
                                    />
                                :
                                null
                        ))}
                    </div>
                    :
                    <div style={{ height:"100%"}} className='r-c-c'>
                        <NodataCard type="Private" />
                    </div>
            }
        </>
    )
}

export const NodataCard = ({ type }) => {
    return (
        <div style={{ height: "90%" }} className="r-c-c-c p-30">
            <div style={{ height: "40%", width: "50%" }}>
                <img className="full-height full-width" src={require('./assets/Group 11949.svg').default} alt=""></img>
            </div>
            {
                type == "Recordings"
                    ?
                    <div>
                        No online recordings found !
                    </div>
                    :
                    <div>
                        <div className="white text-center">
                            {type==='Private'?null:`Start a ${type} chat now!`}
                        </div>
                        <div className={`white ${type==='Private'?'':'opacity50'} text-center`}>
                            {
                                type == "Private"
                                    ?
                                    "No private chat is present!."
                                    :
                                    "Communicate with all the participants."
                            }
                        </div>
                        {
                            type==='Private'?
                            <SmallHeading color="white">
                                <div style={{opacity:"50%", textAlign:"center"}}>
                                    Please visit the participants list to begin private chat
                                </div>
                                </SmallHeading>
                            :null
                        }
                    </div>
            }
        </div>
    )
}

const MessageStudentViewTitle = ({ setRightViewType, currentPrivateChatReciever }) => {
    return (
        <div className="m-l-10" style={{display:"flex", alignItems:"center", paddingTop:10, paddingBottom:10}}>
            <ArrowLeftOutlined className="m-r-20" style={{ color: "white" }}
                onClick={() => setRightViewType('privateChatView')} />
            <div className="m-r-20" style={{ height: "10%", width: "10%" }}>
                <img style={{ borderRadius: "50%", height: 30, width: 30 }} src={`${Api._s3_url}${currentPrivateChatReciever.image}`} alt=""></img>
            </div>
            <div className="white" style={{fontSize:"1vw"}}> {currentPrivateChatReciever.name} </div>
        </div>
    )
}
const placeholder = 'Type something here …'
const MessageStudentView = ({ setRightViewType, currentPrivateChatReciever,
    chat, privateMessages, sessionuser, postChatImage, streamId, chatClient, updateUnreadCount, postMessageOpenStatus }) => {
    const [fileInputValuePrivate, setfileInputValuePrivate] = useState("")
    const [imageUrlPrivate, setImageUrlPrivate] = useState('')
    const [isModalVisiblePrivate, setIsModalVisiblePrivate] = useState(false);
    const showModalPrivate = () => {
        setIsModalVisiblePrivate(true);
    }
    useEffect(() => {
        updateUnreadCount('privateSpecific', 0, currentPrivateChatReciever.id)
        postMessageOpenStatus({type:'Private', roomName:streamId.split('xxxx')[1], senderId: currentPrivateChatReciever.id})
        return ()=>{
            updateUnreadCount('privateSpecific', 0, currentPrivateChatReciever.id)
            postMessageOpenStatus({type:'Private', roomName:streamId.split('xxxx')[1], senderId: currentPrivateChatReciever.id})
        }
    }, []);
    useEffect(()=>{
        updateUnreadCount('privateSpecific', 0, currentPrivateChatReciever.id)
    }, [privateMessages]);
    const inputFile2 = useRef(null);
    const user = {
        id: sessionuser.id,
        name: sessionuser.name,
        avatar: `${Api._s3_url}${sessionuser.image}`,
    }
    const onChangeFilePrivate = (e) => {
        e.preventDefault();
        const filePrivate = e.target.files[0];
        if (filePrivate != undefined) {
            const imgUrlPrivate = URL.createObjectURL(filePrivate);
            setfileInputValuePrivate(filePrivate)
            setImageUrlPrivate(imgUrlPrivate)
            showModalPrivate();
        }
    }
    const onImageUploadClickPrivate = () => {
        inputFile2.current.click();
    };
    const userprivate = {
        id: sessionuser.id,
        name: sessionuser.name,
        avatar: `${Api._s3_url}${sessionuser.image}`,
    }
    const handleImageUploadPrivate = (file) => {
        // const { user } = this.props;
        const params = {
            file,
            userId: userprivate.id,
            roomName: streamId?.split('xxxx')[1],
            //   roomName: 'fpyrpub0pug',
            //   roomName: '2de4fc06-f1b0-4ca7-9d97-8bc72c7a40e6',
            type: "image"
        };
        postChatImage(params, userprivate,
            (newMessage) => {
                // chat.sendPvtMessage(newMessage, 'Image', currentPrivateChatReciever.id)
                chatClient.sendPrivateChat(sessionuser.id, streamId, currentPrivateChatReciever.id, {
                    type: 'Image',
                    data: newMessage,
                    userId: sessionuser.id,
                    roomId: streamId,
                    user: sessionuser,
                    },)
            }, 'Private');
    }
    const imageBeforeUploadPrivate = file => {
        const isJpgOrPngPrivate = file.type === "image/jpeg" || file.type === "image/png";
        const isLt2MPrivate = file.size / 1024 / 1024 < 2;
        const readerPrivate = new FileReader();
        if (!isJpgOrPngPrivate) {
            Notification.error("error", "You can only upload JPG/PNG file!");
        } else if (!isLt2MPrivate) {
            Notification.error("error", "Image must smaller than 2MB!");
        } else {
            readerPrivate.addEventListener("load", () => {
                handleImageUploadPrivate(file);
            });
            readerPrivate.readAsDataURL(file);
        }
        return false;
        // return isJpgOrPng && isLt2M;
    };
    const handleOkPrivate = () => {
        setIsModalVisiblePrivate(false);
        inputFile2.current.value = ""
        imageBeforeUploadPrivate(fileInputValuePrivate);
    };

    const handleCancelPrivate = () => {
        inputFile2.current.value = ""
        setIsModalVisiblePrivate(false);
    };
    const onSendPrivate = (messages) => {
        if (messages[0].text?.trim() == "") {
            Notification.hide()
            Notification.error("error", "Please enter a message")
        }
        else {
            let uvid = uuidv4();
            chatClient.sendPrivateChat(sessionuser.id, streamId, currentPrivateChatReciever.id, {
                type: 'Text',
                data: {...messages[0], id:uvid},
                userId: sessionuser.id,
                roomId: streamId,
                user: sessionuser,
              },)
        }
        // chatClient.sendPrivateChat(sessionuser.id,  streamId, currentPrivateChatReciever.id, 'hi aman from private chat')
    }
    const renderSendPrivate = (props) => (
        <div className="r-c-c" style={{ height: 60, position: 'absolute', right: 5, bottom: -50 }}>
            <div>
                <label htmlFor="file-input-image">
                    <input type='file' id='fileprivate' ref={inputFile2} onChange={(e) => { onChangeFilePrivate(e) }} style={{ display: 'none' }} accept='image/*' />
                    <img className="m-r-10 cursor-pointer" style={{ width: 25, height: 25 }} onClick={onImageUploadClickPrivate} src={require('./assets/Group 11771.svg').default} />
                </label>
            </div>
            <Send {...props} containerStyle={{
                border: 'none'
            }}>
                <img style={{ width: 25, height: 25 }} onClick={() => { }} src={require('./assets/Group 11770.svg').default} />
            </Send>
            <PreviewModalPrivate
                image={imageUrlPrivate}
                isModalVisible={isModalVisiblePrivate}
                showModal={showModalPrivate}
                handleOk={handleOkPrivate}
                handleCancel={handleCancelPrivate}
                postChatImage={postChatImage}
            />
        </div>
    )
    return (
        <>
            <MessageStudentViewTitle setRightViewType={setRightViewType}
                currentPrivateChatReciever={currentPrivateChatReciever} />
            <Divider style={{ 'backgroundColor': "white", opacity: "15%", marginTop:5 }} />
            <div className="absolute" style={{ height: "85%", bottom: 20, width: "94%" }}>
                <GiftedChat
                    renderChatEmpty={<></>}
                    onLongPress={() => { }}
                    placeholder={placeholder}
                    selectable={true}
                    renderInputToolbar={renderInputToolbar}
                    renderBubble={renderBubble}
                    showUserAvatar={true}
                    renderTime={renderTime}
                    // renderUsernameOnMessage={!studentChat}
                    // loadEarlier={!disableLoadEarlier}
                    messages={privateMessages[currentPrivateChatReciever.id]?.slice().reverse()}
                    onSend={messages => onSendPrivate(messages)}
                    renderComposer={props1 => (<Composer {...props1}
                        textInputProps={{ blurOnSubmit: false }}
                        textInputStyle={{
                            position: 'absolute', bottom: -38, left: -8, color: "grey",
                            width: '80%', height: 'fit-content', border: 'none',
                            backgroundColor: '#3e3e3e'
                        }} />)}
                    user={userprivate}
                    minComposerHeight={40}
                    minInputToolbarHeight={60}
                    renderSend={renderSendPrivate}
                />
            </div>
        </>
    )
}

function PreviewModalPrivate({ postChatImage,
    isModalVisible,
    handleCancel,
    handleOk,
    image
}) {
    return (

        <Modal wrapClassName="imagePreview"
            centered={true}
            okText={"Send"}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}>
            <div style={{ width: "fit-content", height: "fit-content" }}>
                <img style={{ width: "100%", height: "100%" }} src={`${image}`} alt="" />
            </div>
        </Modal>
    );
}
function PreviewModalGroup({ postChatImage,
    isModalVisible,
    handleCancel,
    handleOk,
    image
}) {
    return (

        <Modal wrapClassName="imagePreview" centered={true} okText={"Send"} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ width: "fit-content", height: "fit-content" }}><img style={{ width: "100%", height: "100%" }} src={`${image}`} alt="" /></div>
        </Modal>
    );
}
const GroupChatView = ({ chat,
    inc,
    setInc,
    addMessageToGroup,
    groupMessages,
    postChatImage,
    sessionuser,
    roomId,
    setRightView,
    setRightViewType,
    streamId,
    chatClient,
    updateUnreadCount,
    postMessageOpenStatus,
}) => {
    const [fileInputValue, setfileInputValue] = useState("")
    const [imageUrl, setImageUrl] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    }
    useEffect(() => {
        updateUnreadCount('group', 0)
    }, [groupMessages]);
    useEffect(()=>{
        updateUnreadCount('group', 0)
        postMessageOpenStatus({type:'Group', roomName:streamId.split('xxxx')[1]})
        return ()=>{
            updateUnreadCount('group', 0)
            postMessageOpenStatus({type:'Group', roomName:streamId.split('xxxx')[1]})
        }
    }, [])
    const inputFile = useRef(null);
    const onChangeFile = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file != undefined) {
            const imgUrl = URL.createObjectURL(file);
            setfileInputValue(file)
            setImageUrl(imgUrl)
            showModal();
        }
    }
    const onImageUploadClick = () => {
        inputFile.current.click();
    };


    const user = {
        id: sessionuser.id,
        name: sessionuser.name,
        avatar: `${Api._s3_url}${sessionuser.image}`,
    }
    const handleImageUploadGroup = (file) => {

        const params = {
            file,
            userId: user.id,
            roomName: streamId?.split('xxxx')[1],
            type: "image"
        };
        postChatImage(params, user,
            (newMessage) => {
                // chat.sendmsg(newMessage, 'Image');
                chatClient.sendGroupChat(sessionuser.id, streamId, 
                    {
                        type: 'Image',
                        data: newMessage,
                        userId: sessionuser.id,
                        roomId: streamId,
                    }
                    )
            }, "Group");
    }
    const imageBeforeUpload = file => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 2;
        const reader = new FileReader();
        if (!isJpgOrPng) {
            Notification.error("error", "You can only upload JPG/PNG file!");
        } else if (!isLt2M) {
            Notification.error("error", "Image must smaller than 2MB!");
        } else {
            reader.addEventListener("load", () => {
                handleImageUploadGroup(file);
            });
            reader.readAsDataURL(file);
        }
        return false;
        // return isJpgOrPng && isLt2M;
    };
    const handleOk = () => {
        setIsModalVisible(false);
        inputFile.current.value = ""
        imageBeforeUpload(fileInputValue);
    };

    const handleCancel = () => {
        inputFile.current.value = ""
        setIsModalVisible(false);
    };
    const onSend = (messages) => {
        let uvid = uuidv4();
        if (messages[0].text == "") {
            Notification.hide()
            Notification.error("error", "Please enter a message")
        }
        else{
            chatClient.sendGroupChat(sessionuser.id, streamId, 
                {
                  type: 'Text',
                  data: messages[0],
                  userId: sessionuser.id,
                  roomId: streamId,
                }
              )
        }
    }
    const renderSend = (props) => (
        <div className="r-c-c" style={{ height: 60, position: 'absolute', right: 5, bottom: -50 }}>
            <div>
                <label htmlFor="file-input-image">
                    <input type='file' id='file' ref={inputFile}
                        onChange={(e) => { onChangeFile(e) }}
                        style={{ display: 'none' }} accept='image/*' />
                    <img className="m-r-10 cursor-pointer"
                        style={{ width: 25, height: 25 }}
                        onClick={onImageUploadClick}
                        src={require('./assets/Group 11771.svg').default} />
                </label>
            </div>
            <Send {...props} containerStyle={{
                border: 'none'
            }} onClick={() => { }}>
                <img style={{ width: 25, height: 25 }} onClick={() => { }}
                    src={require('./assets/Group 11770.svg').default} />
            </Send>
            <PreviewModalGroup
                image={imageUrl}
                isModalVisible={isModalVisible}
                showModal={showModal}
                handleOk={handleOk}
                handleCancel={handleCancel}
                postChatImage={postChatImage}
            />
        </div>
    )
    return (
        <>
            <div className="absolute" style={{ height: "90%", bottom: 20, width: "94%" }}>
                {
                    groupMessages && groupMessages.length > 0
                        ?
                        <GiftedChat
                            renderChatEmpty={<></>}
                            onLongPress={() => { }}
                            placeholder={placeholder}
                            selectable={true}
                            renderInputToolbar={renderInputToolbar}
                            renderBubble={renderBubble}
                            showUserAvatar={true}
                            renderTime={renderTime}
                            // renderUsernameOnMessage={!studentChat}
                            // loadEarlier={!disableLoadEarlier}
                            onSend={(messages) => { onSend(messages) }}
                            messages={groupMessages?.slice().reverse()}
                            renderComposer={props1 => (<Composer {...props1}
                                textInputProps={{ blurOnSubmit: false }}
                                textInputStyle={{
                                    position: 'absolute', bottom: -38, left: -8, color: "grey",
                                    width: '80%', height: 'fit-content', border: 'none',
                                    backgroundColor: '#3e3e3e'
                                }} />)}
                            user={user}
                            minComposerHeight={40}
                            renderUsernameOnMessage={true}
                            minInputToolbarHeight={60}
                            renderSend={renderSend}
                        />
                        :
                        <div style={{position:"relative", height:"100%"}}>
                            <NodataCard type="Group" />
                            <div style={{ height: "15%", position:"absolute", bottom:0, width:"100%" }}>
                                <GiftedChat
                                    renderChatEmpty={<></>}
                                    onLongPress={() => { }}
                                    placeholder={placeholder}
                                    selectable={true}
                                    renderInputToolbar={renderInputToolbar}
                                    renderBubble={renderBubble}
                                    showUserAvatar={true}
                                    renderTime={renderTime}
                                    // renderUsernameOnMessage={!studentChat}
                                    // loadEarlier={!disableLoadEarlier}
                                    messages={[]}
                                    onSend={(messages) => { onSend(messages) }}
                                    renderComposer={props1 => (<Composer {...props1}
                                        textInputProps={{ blurOnSubmit: false }}
                                        textInputStyle={{
                                            position: 'absolute', bottom: -38, left: -8, color: "grey",
                                            width: '80%', height: 'fit-content', border: 'none',
                                            backgroundColor: '#3e3e3e'
                                        }} />)}
                                    user={user}
                                    minComposerHeight={40}
                                    minInputToolbarHeight={60}
                                    renderSend={renderSend}
                                />
                            </div>
                        </div>
                }

            </div>
        </>
    )
}
const RightColumn = ({ rightViewType,
    setRightViewType,
    inc,
    setInc,
    addMessageToGroup,
    groupMessages,
    postChatImage,
    sessionuser,
    roomId,
    updateUserList,
    usersList,
    updateUserCount,
    chat,
    setCurrentPrivateChatReciever,
    currentPrivateChatReciever,
    privateMessages,
    setRightView,
    streamId,
    chatClient,
    postMessageOpenStatus,
    updateUnreadCount,
    unreadCount,
    activeClass
}) => {


    useEffect(() => {
        // chat.initsocket();


    }, []);
    
    useEffect(() => {

    }, [groupMessages]);
    return (
        <div data-html2canvas-ignore className='full-height full-width p-10 rightColumnBC' style={{width:"25%", backgroundColor:"white", zIndex:20}}>
        <div className="greyBackground p-15 relative full-height full-width" style={{borderRadius:15}}>
            {rightViewType == 'participantsView'
                ?
                <UsersList usersList={usersList} setRightViewType={setRightViewType}
                    setCurrentPrivateChatReciever={setCurrentPrivateChatReciever}
                    sessionuser={sessionuser} setRightView={setRightView} />
                :
                rightViewType == 'privateChatView'
                    ?
                    <PrivateChatView setRightViewType={setRightViewType}
                        usersList={usersList}
                        setCurrentPrivateChatReciever={setCurrentPrivateChatReciever}
                        sessionuser={sessionuser}
                        privateMessages={privateMessages}
                        setRightView={setRightView}
                        unreadCount={unreadCount}
                        selectedSchedule={activeClass.occurance}
                    />
                    :
                    rightViewType == 'messageStudent'
                        ?
                        <MessageStudentView setRightViewType={setRightViewType}
                            currentPrivateChatReciever={currentPrivateChatReciever}
                            chat={chat}
                            privateMessages={privateMessages}
                            sessionuser={sessionuser}
                            postChatImage={postChatImage}
                            streamId={streamId}
                            chatClient={chatClient}
                            postMessageOpenStatus={postMessageOpenStatus}
                            updateUnreadCount={updateUnreadCount}
                        />
                        :
                        rightViewType == 'groupChatView'
                            ?
                            <GroupChatView setRightViewType={setRightViewType}
                                addMessageToGroup={addMessageToGroup}
                                chat={chat}
                                inc={inc}
                                setInc={setInc}
                                groupMessages={groupMessages}
                                postChatImage={postChatImage}
                                sessionuser={sessionuser}
                                roomId={roomId}
                                setRightView={setRightView}
                                streamId={streamId}
                                chatClient={chatClient}
                                postMessageOpenStatus={postMessageOpenStatus}
                                updateUnreadCount={updateUnreadCount}
                            />
                            :
                            null
            }
        </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { rightViewType, groupMessages,
        roomId, usersList,
        currentPrivateChatReciever,
        privateMessages, streamId, unreadCount} = state.broadcast;
    const { user } = state.session;
    const {activeClass} = state.schedule;
    const sessionuser = user;
    return {
        rightViewType,
        groupMessages,
        sessionuser,
        roomId,
        usersList,
        currentPrivateChatReciever,
        privateMessages,
        streamId,
        unreadCount,
        activeClass
    }
}

const mapDispatchToProps = dispatch => ({
    setRightViewType: (viewType) => dispatch(setRightViewType(viewType)),
    addMessageToGroup: (newMessage) => dispatch(addMessageToGroup(newMessage)),
    postChatImage: (params, user, callback, type) => dispatch(postChatImage(params, user, callback, type)),
    setCurrentPrivateChatReciever: (newUser) => dispatch(setCurrentPrivateChatReciever(newUser)),
    setRightView: (bool) => dispatch(setRightView(bool)),
    postMessageOpenStatus: (params, value) => dispatch(postMessageOpenStatus(params, value)),
    updateUnreadCount: (key, value, id) => dispatch(updateUnreadCount(key, value, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(RightColumn)