import '../styles.css';
import React, { Component } from 'react';
import Typography from 'antd/lib/typography';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import 'antd/lib/typography/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/button/style/index';
import { Checkbox, Col, Row } from 'antd';
import { MdClose } from "react-icons/md";
import {connect} from 'react-redux';
import { Color } from 'services';
import { deleteSubjects } from 'actions/classRoom'
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Title } = Typography;

class EditCurriculumModal extends Component {
    constructor(){
      super();
      this.state = {
        selectedSubjects: [],
        buttonLoader: false
      }
    }
    componentWillUnmount(){
      // this.props.changeDetail({name: "", section: "", stream: ""})
    }

    handleSelectSubject(e, subjectId){
      const selectedSubjects = this.state.selectedSubjects;
      if(e.target.checked){
        selectedSubjects.push(subjectId)
      }else{
        const index = selectedSubjects.indexOf(subjectId);
        selectedSubjects.splice(index, 1)
      }
      this.setState({selectedSubjects});
    }

    handleDeleteClick(self){
      self.setState({buttonLoader: true})
      const subjects = self.state.selectedSubjects;
      self.props.deleteSubjects(subjects, () => self.props.onClose(), () => self.setState({buttonLoader: false}) )
    }

    confirmDelete(self){
      return Modal.confirm({
        title: "Delete Subjects",
        icon: <ExclamationCircleOutlined />,
        content: `Are you sure you want to delete the selected subjects from the curriculum?`,
        okText: 'Confirm',
        onOk() {self.handleDeleteClick(self)},
        onCancel() {},
    })
    }

    render () {
      const { visible, onClose, selectedSectionDetails } = this.props;
      const {buttonLoader, selectedSubjects} = this.state;
      return (
        <Modal
          visible={visible}
          className='modal-border-15'
          width={800}
          closable={false}
          // onCancel={() => toggleAddSectionModal(false)}
          footer={null}
          centered
        >
          <div>
            <div className='r-c-sb m-b-10'>
              <Title style={{color: Color.primary, margin:0}} level={4}>Edit Curriculum</Title>
              <div className='cursor-pointer' onClick={() => onClose()}><MdClose className='text-md dark-grey'/></div>
            </div>
            <div className='bold-600 text-xmd dark-grey m-t-30 m-b-10'>
                All Subjects ({selectedSectionDetails ? selectedSectionDetails.subjects.length : 0})</div>
            <div style={{background:'#FAFAFA'}} className='p-15 radius-10'>
              <Row style={{maxHeight: 200, overflowY: 'auto'}}>
                {selectedSectionDetails.subjects.map((item, index) =>
                  <Col span={8} key={index.toString()}>
                    <div className='color-black m-b-10' >
                        <Checkbox onChange={(e) => this.handleSelectSubject(e, item.id)}>
                          <div className='color-black text-xmd'>{item.name}</div>
                        </Checkbox>
                      </div>
                  </Col>
                )}
              </Row>
            </div>

            <div className='r-c-sb m-t-30'>
              <Button className='btn-radius-15__height-38 dark-grey' style={{width:'130px'}} onClick={() => onClose()}>
                Close
              </Button>
              <Button loading={false} type='primary' className='btn-radius-15__height-38'  style={{width:'120px'}}
                loading={buttonLoader}
                disabled={!selectedSubjects.length}
                onClick={() => this.confirmDelete(this)} >
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      )
    }
}


const mapStateToProps = state => {
  const {
    selectedSectionDetails,
  } = state.classRoom;
  return {
    selectedSectionDetails,
  };
};

const mapDispatchToProps = dispatch => ({
  // toggleAddSectionModal: bool => dispatch(toggleAddSectionModal(bool)),
  deleteSubjects: (subjects, successCallback, buttonCallback ) => dispatch(deleteSubjects(subjects, successCallback, buttonCallback ))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCurriculumModal);
