import React from 'react';
import { motion } from 'framer-motion';
import './styles.css';

const Loader = ({ color = 'white' }) => (
  <motion.div className='display-flex' transition={{ staggerChildren: 1 }}>
    <motion.div
      className="dot"
      animate={{ scale: [1.5, 1] }}
      style={{ backgroundColor: color }}
      transition={{
        repeat: Infinity,
        repeatType: 'reverse',
        duration: 0.5,
      }}
    />
    <motion.div
      className="dot m-l-5"
      animate={{ scale: [1.5, 1] }}
      style={{ backgroundColor: color }}
      transition={{
        repeat: Infinity,
        repeatType: 'reverse',
        duration: 0.5,
        delay: 0.33,
      }}
    />
    <motion.div
      className="dot m-l-5"
      animate={{ scale: [1.5, 1] }}
      style={{ backgroundColor: color }}
      transition={{
        repeat: Infinity,
        repeatType: 'reverse',
        duration: 0.5,
        delay: 0.66,
      }}
    />
  </motion.div>
);

export default Loader;