import React, { useState } from 'react'
import TopBar from './topBar'
import Footer from './footer'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api'
import { connect } from 'react-redux'
import './myCourses/location.css'
import NewFooter from './newFooter'




export const LocationList = ({ item }) => {
    console.log("item", item);
    return (
        <>
            <div className='location-design'>
                <div className='' style={{ margin: "15px 1rem" }}>
                    <div style={{ fontSize: "17px", fontWeight: "bold", fontFamily: "Roboto" }}>{item?.name}</div>
                    <div style={{ width: "70%", fontSize: "13px", fontFamily: "Roboto" }}>{item?.address}</div>
                </div>
            </div>
        </>
    )
}

function LocationDetails({ userMarketPlaceHomeData }) {
    const [map, setMap] = React.useState(null)
    const history = useHistory();

    const containerStyle = {
        width: "100%",
        height: "40vh"
    };
    const center = {
        lat: -3.745,
        lng: -38.523
    };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyANNuGsbyjTzqrtYc65aRapQ1fkfaVh0aI",
        libraries: ["places"]
    })
    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    const [isCategoryClicked, setIsCategoryClicked] = useState(false);
    const [isProfileClicked, setIsProfileClicked] = useState(false);
    return (
        <div onClick={(e)=>{e.stopPropagation(); setIsCategoryClicked(false); setIsProfileClicked(false)}}>
            <div className="top-bar">
                <TopBar isCategoryClicked={isCategoryClicked} setIsCategoryClicked={setIsCategoryClicked} isProfileClicked={isProfileClicked} setIsProfileClicked={setIsProfileClicked}/>
            </div>
            <div style={{ backgroundColor: "#FAFAFA", marginTop:100}}>
                <div className='main-container-parent'>
                    <div className="main-container" style={{}}>

                        <div className='' style={{}}>
                            <div style={{ display: "flex", gap: 20 }}>
                                <div className='text-sm font-bold' onClick={() => history.goBack()}  ><img src={require("../../Assets/aulasMarket/ic-back-arrow (1).svg").default} style={{ cursor: "pointer", marginTop: "20px" }} /></div>
                                    <div className='m-l-10' style={{ marginTop: "20px",fontSize:"16px",fontWeight:"bold" }}>All Locations</div>
                            
                            </div>
                            <div className='card-flex'>
                                {userMarketPlaceHomeData?.footerData?.locations?.map((item) => {
                                    return (
                                        <LocationList item={item} />
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                    <div>
                        {isLoaded ? <GoogleMap
                            mapContainerStyle={containerStyle}
                            onLoad={onLoad}
                            center={center}
                            onUnmount={onUnmount}
                            defaultZoom={15}
                            zoom={13}
                        >
                            {userMarketPlaceHomeData?.footerData?.locations?.map(marker => {
                                return (
                                    <Marker
                                        key={marker.id}
                                        position={{ lat: marker.latitude, lng: marker.longitude }}
                                    >
                                        <InfoWindow >
                                            <div style={{ fontFamily: "Roboto", fontSize: "12px", fontWeight: "600px", }}>
                                                {marker.name}
                                            </div>
                                        </InfoWindow>
                                    </Marker>
                                )
                            })}

                        </GoogleMap> : null}
                    </div>
                </div>
                <div className='m-t-100' style={{}}>
                    {/* <Footer /> */}
                    <NewFooter fromLocationDetails = {true}/>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    const { userMarketPlaceHomeData } = state.studentMarketPlace;
    return { userMarketPlaceHomeData };
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetails)
