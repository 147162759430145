import { ExclamationCircleOutlined, LeftOutlined, LoadingOutlined,  RightOutlined } from '@ant-design/icons';
import { Button, Col,  Input, PageHeader,  Row,  Modal } from 'antd';
import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router';
import { Api, Color, Notification } from 'services';
import {connect} from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';
// import './styles.css';
import { fetchTestSolutions,
         fetchTestSummary,
         handleSubjectSelection,
         setCurrentIndex,
         setCurrentStudentQ,
         } from 'actions/studentPracticeTest';
import PageDataLoader from 'components/pageDataLoader';
import ImageViewer from 'components/ImageViewer';


const { confirm } = Modal;

const answerStatus = [
    {
        title: "Correct",
        key: "correct",
        background: "#28DF99",
        border: `1px solid ${Color.midGrey}`,
        color: "white",
        gradient: false
    },
    {
        title: "Incorrect",
        key: "incorrect",
        background: Color.wrong,
        border: "none",
        color: "white",
        gradient: false
    },
    {
        title: "Unattempted",
        key: "unattempted",
        background: "#AEAEAE",
        border: 'none',
        color: "black",
        gradient: false
    }
]

const partialCorrectElement = (val, itemColor, caller) =>  <div
                                                        className={caller=="answerBox"?"r-c-c":"countBadge r-c-c"}
                                                        style={{   padding:0,
                                                        borderRadius:"50%",
                                                        maxWidth:caller=="legend"?"50%":"",
                                                        overflow: "hidden",
                                                        transform: caller=="legend"?"rotate(135deg)":"rotate(135deg)",
                                                        position: "relative",
                                                        margin: "0px",
                                                        width: caller=="legend"?"":"100%",
                                                        height: caller=="legend"?"":"100%",
                                                        marginRight: caller=="legend"?"5px":"0px",

                                                    }}
                                                        >
                                                            <div style={{margin:0, width: "50%", height:"100%", backgroundColor:"#94EFCC"}}>

                                                            </div>
                                                            <div style={{zIndex:"9", position: "absolute", transform:"rotate(-135deg)", color:itemColor}}>{val}
                                                            </div>
                                                            <div style={{margin:0, width: "50%", height:"100%", backgroundColor: "#28DF99"}}>

                                                            </div>
                                                    </div>
const legand = (solutionCounts, selectedTest, questionsContainer) => {
    const unseencount = solutionCounts ? questionsContainer?.length - (solutionCounts.correct+solutionCounts.incorrect+solutionCounts.partialCorrect+solutionCounts.unanswered): 0
return (
        <div className="legendPalette">
            {
                answerStatus?.map((item, index) => (
                    <>
                    {(item.gradient&&selectedTest.testType=="Objective")
                    ?
                    null
                    :
                    <div className="answerTypeContainer" key={index.toString()}>
                    <div className="countBadge r-c-c" style={{
                        background: item.background,
                        border: item.border,
                        color: item.color,
                        borderRadius:"50%",
                        maxWidth:"50%"}}>
                        <div>{solutionCounts!=null?solutionCounts[item.key]:0}
                        </div>
                    </div>
                    <div style={{maxWidth:"50%", fontSize:"1vw"}}> {item.title}
                    </div>
                </div>
                }
                </>
            ))
            }
        </div>
)}

const questionPalette = (currentQIndex, handlePaletteClick, questionsList, currentIndex) => {
        return (
        <div className="questionPalette">
            <span style= {{marginLeft:"2vh"}}> ALL QUESTIONS </span>
            <Row style={{margin: 10,  marginTop: '2vh'}}>
                {questionsList?.map((item, index) => (
                    <Col key={index}
                        className="questionSelector r-c-c cursor-pointer m-t-5"
                        onClick={()=> handlePaletteClick(index)}
                        style={{
                            borderRadius: "50%",
                            border: `${currentIndex===index?'2':'1'}px solid black`,
                            background: item.status === "Correct" ? Color.seaGreen :
                            item.status === "Incorrect" ? Color.wrong : 
                            item.status==="PartialCorrect"?'linear-gradient(36deg, #28DF99 50%, #94EFCC 50%)':
                            (item.status === "Unanswered"||item.status==='Bookmarked') ? "#AEAEAE" : item.status === "Unseen" ? "#FFFFFF":
                            "none",
                            color: (item.status === "Unanswered"||item.status==="Unseen"||item.status==='Bookmarked') ? Color.black : 'white'
                        }}
                    >
                        <span> {index + 1}</span>
                    </Col>
                ))}
            </Row>
        </div>
        )

            }

const singleQuestion = (currentQ, currentQIndex, setStudentAns, beforeUpload, uploadImage, base64Images, removeAnswerImage, toggleImageModal, imgLoader, testType,
    setImgUrl, setImageViewerVisibility) => {
    const isEmptyHtml = (content) => {
        let pattern = /^(?:\s|<br>|<p>|<\/p>)*$/;
        return pattern.test(content);
      }
    return (<>
    <div className="questionStatement m-l-10">
            {ReactHtmlParser(currentQ?.statement,
                { transform: (node) => {
                if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                    return <TeX math={`${node.attribs['data-value']}`} />;
                }
                if (node.type === 'tag' && node.name === 'img') {
                    return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} 
                    className="cursor-pointer"
                    alt=""
                    onClick={()=>{
                        setImgUrl(node.attribs.src);
                        setImageViewerVisibility(true);
                    }} />;
                }
                }})
            }
        </div>
            <div className="answerBox" id="solutionBox"> {answerBox(currentQ, setImgUrl, setImageViewerVisibility)}
            </div>
            {((testType!="Missed")&&(currentQ?.type=="Subjective"))
            ?
                currentQ && currentQ.filename!=null 
                ?
                <div className="answerBox m-t-20">
                    <div className='p-20 m-b-40'>
                        <div className='text-xmd bold-600 color-black'>Uploaded Answer</div>
                            <div className='r-ac' style={{overflowX:"auto"}}>
                            {currentQ?.filename?.split(',').map((item, index) => (
                                <img src={`${Api._s3_url}${item}`} alt={""} width="160" height="160" text-align="left" style={{display:'block'}} className = 'm-t-10 m-r-10' />
                            ))}
                            </div>
                    </div>
                </div>
                :
                null
            :
            null
            }

            {!isEmptyHtml(currentQ?.solution) ?
            <div className="answerBox m-t-20 m-l-10">
                <div className="r-c-fs m-b-20">  
                    <img style={{ width: "0.8rem", height: "0.8rem"}} src={require('Assets/test/FileText.svg').default}> 
                    </img>
                    <div className='text-xmd bold-600 color-black m-l-10'> SOLUTION</div>
                </div>
                <div className='test-ques-window p-10'>
                    {ReactHtmlParser(
                        currentQ?.solution                        ,
                        { transform: (node) => {
                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                            return <TeX math={`${node.attribs['data-value']}`} />;
                        }
                        if (node.type === 'tag' && node.name === 'img') {
                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt=""
                            onClick={()=>{
                                setImgUrl(node.attribs.src);
                                setImageViewerVisibility(true);
                            }}
                            className="cursor-pointer" />;
                        }
                        }})
                    }
                </div>
            </div>
            :null}
    </>


)}

const showConfirm = (onSave, history, status) => {
    return confirm({
      title: `Do you want to submit the test?`, /*${item.name}*/
      icon: <ExclamationCircleOutlined />,
      content: 'Please click OK to confirm',
      onOk() {onSave(status, history, true)},
      onCancel() {},
    })
  }


const customInputElement = (currentQ)=> <Input style = {{backgroundColor: (currentQ?.correct==="Correct"||currentQ?.correct==="PartialCorrect")? "#E9FCF5": currentQ?.correct==="Incorrect"?"#FFE4D9":""}}
                                        className="answerInput"
                                        placeholder={currentQ?.answer?currentQ?.answer:"Not Answered"}
                                        suffix = {   currentQ?.correct === "PartialCorrect"
                                                    ?
                                                        <div className="r-c-c">
                                                            <div style={{width:"0.5rem",
                                                            height:"0.5rem",
                                                            borderRadius:"50%",
                                                            marginRight: "5px"
                                                        }}>
                                                            {partialCorrectElement("", "none", "answerBox")}
                                                        </div>
                                                            <span> {currentQ?.correct==="Correct" ? "Correct":currentQ?.correct==="Incorrect"?"Incorrect":currentQ?.correct==="PartialCorrect"?"Partially Correct":""}
                                                            </span>
                                                        </div>

                                                    :
                                                    <div className="r-c-c">
                                                                <div style={{width:"0.5rem",
                                                                    height:"0.5rem",
                                                                    borderRadius:"50%",
                                                                    backgroundColor: currentQ?.correct==="Correct"?"#28DF99":currentQ?.correct==="Incorrect"?"#FF414D":"",
                                                                    marginRight: "5px"
                                                                }}>
                                                    </div>
                                                    <span> {currentQ?.correct==="Correct"? "Correct":currentQ?.correct==="Incorrect"?"Incorrect":currentQ?.correct==="PartialCorrect"?"Partially Correct":""} </span>
                                                </div>}
                                        disabled/>



const optionElement = (optionType, option, index, setImgUrl, setImageViewerVisibility) => {
    if (optionType=="correctanswer-but-notstudentanswer"){
        return   (
                    <div className="optionCard greenOptionCard" 
                            key={index}>
                            <div className="answerType">
                                <span>
                                    <img style={{ width: "0.5rem", height: "0.5rem"}} src={require('Assets/ic-attempted.svg').default}></img>
                                    <span style={{color:"green", fontSize: 12}}> Correct answer</span>
                                </span>
                            </div>
                            <div className="optionContainer">
                                <div className="optionKey r-c-c greenRadioButton"
                                >
                                {String.fromCharCode(65 + index)}
                                </div>
                                <div className="answerOptionKey">
                                    {ReactHtmlParser(option?.value,
                                    { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                    }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt=""
                                            className="cursor-pointer" onClick={()=>{
                                                setImgUrl(node.attribs.src);
                                                setImageViewerVisibility(true);
                                            }} />;
                                    }
                                }}
                                )}
                                </div>
                            </div>
                        </div>
        )
    }
    else if (optionType=="correctanswer-and-studentanswer"){
        return   (
            // <div className="optionCards">
                    <div className="optionCard greenOptionCard"
                            key={index}
                        >
                            <div className="answerType">
                                <span>
                                    <img style={{ width: "0.5rem", height: "0.5rem"}} src={require('Assets/ic-attempted.svg').default}></img>
                                    <span style={{color:"green", fontSize:12}}> Your answer </span>
                                </span>

                            </div>
                            <div className="optionContainer">
                                <div className="optionKey r-c-c greenRadioButton"
                                >
                                    {String.fromCharCode(65 + index)} </div>
                                <div className="answerOptionKey">
                                    {ReactHtmlParser(option?.value,
                                    { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                    }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt=""
                                            className="cursor-pointer" onClick={()=>{
                                                setImgUrl(node.attribs.src);
                                                setImageViewerVisibility(true);
                                            }}/>;
                                    }
                                }}
                                )}
                                </div>
                            </div>

                        </div>
            // </div>
        )
    }
    else if (optionType=="studentanswer-but-notcorrectanswer"){
        return   (
            // <div className="optionCards">
                    <div className="optionCard correctanswer-and-studentanswer redOptionCard"
                            key={index}
                        >
                            <div className="answerType">
                                <span>
                                    <img style={{ width: "0.5rem", height: "0.5rem", fontSize:12}} src={require('Assets/CloseCircle.svg').default}></img>
                                    <span style={{color:"red", fontSize:12}}> Your answer </span>
                                </span>
                            </div>
                            <div className="optionContainer">
                                <div className="optionKey r-c-c redRadioButton"
                                >
                                    {String.fromCharCode(65 + index)} </div>
                                <div className="answerOptionKey">
                                    {ReactHtmlParser(option?.value,
                                    { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                    }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt=""
                                            className="cursor-pointer" onClick={()=>{
                                                setImgUrl(node.attribs.src);
                                                setImageViewerVisibility(true);
                                            }}/>;
                                    }
                                }}
                                )}
                                </div>
                            </div>

                        </div>
            // </div>
        )
    }
    else{
        return   (
            // <div className="optionCards">
                    <div className="optionCard"
                            key={index}
                        >
                            <div className="answerType">
                                <span></span>
                            </div>
                            <div className="optionContainer">
                                <div className="optionKey r-c-c"

                                >
                                    {String.fromCharCode(65 + index)} </div>
                                <div className="answerOptionKey">
                                    {ReactHtmlParser(option?.value,
                                    { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                    }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt=""
                                            className="cursor-pointer" onClick={()=>{
                                                setImgUrl(node.attribs.src);
                                                setImageViewerVisibility(true);
                                            }}/>;
                                    }
                                }}
                                )}
                                </div>
                            </div>

                        </div>
            // </div>
        )
    }
}
const answerBox = (currentQ, setImgUrl, setImageViewerVisibility) => {
    
    if(currentQ?.type === "MCQ"){
        var optionTypes = [null, null, null, null]
        if (typeof currentQ.options=="string"){
            currentQ.options = JSON.parse(currentQ.options)
        }
        for (let index=0; index<=currentQ.options.length; index++){
            if (currentQ.skipped){
                if (index==currentQ.correctAnswer){
                    optionTypes[index] = "correctanswer-but-notstudentanswer"
            }}
            else{
                if (index==currentQ.answer){
                    if (currentQ.answer==currentQ.correctAnswer){
                        optionTypes[index] = "correctanswer-and-studentanswer"
                    }
                    else{
                        optionTypes[index] = "studentanswer-but-notcorrectanswer"
                    }
                }
                else{
                    if ((index==currentQ.correctAnswer)&&(currentQ.correctAnswer!=currentQ.answer)){
                        optionTypes[index]="correctanswer-but-notstudentanswer"
                    }
                }
            }
        }

        return (
            <div className="optionCards">
                {currentQ.options?.map((option, index)=>(
                    optionElement(optionTypes[index], option, index,setImgUrl, setImageViewerVisibility)
                ))
                }
            </div>
        )


    }else if(currentQ?.type === "Numeric" || true){
        return (
            <>
                <div style={{marginLeft: "20px", marginTop: "25px"}}>
                    <span style={{fontWeight:"bold"}}> Answer </span>
                </div>
                {customInputElement(currentQ)}
            </>
        )
    }else{
        return null;
    }
}



const warning = (count) => {
    Modal.warning({
      title: `You have only ${count - 1} chance left`,
      content: "Do not change screen or test will get auto submitted."
    });
  };



function PractiseTestSolution({
    currentQIndex, currentQ,
    setStudentAnswer,
    updateImage,
    base64Images, removeAnswerImage,
    fetchTestSolutions,
    fetchTestSummary,
     palateData, solutionCounts, selectedTest, solutionSubjects,
     currentIndex, setCurrentIndex, handleSubjectSelection,
     questionsContainer,
     setCurrentStudentQ,
     testType, solutionSubjectsList
}){

    const history = useHistory();
    const [showImgModal, toggleImgModal] = useState(false);
    // const [currentQ1, setCurrentQ1] = useState(currentQs[0]);
    const [currentImg, setCurrentImg] = useState("");
    const [imgLoader, toggleImageLoader] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false)
    const [buttonLoader, toggleButtonLoader] = useState(false);
    const [selectedStatus, updateSelectedStatus] = useState('');
    const [isImageViewerVisible, setImageViewerVisibility] = useState(false);
    const [imgUrl, setImgUrl] = useState("");

    useEffect(() => {
        setCurrentIndex(0);
        fetchTestSolutions(null, (bool) => togglePageLoader(bool), (bool)=>{});
        // fetchTestSummary((bool) => togglePageLoader(bool));
        return () => {

        }
    }, []);
    useEffect(()=>{
        setCurrentStudentQ(questionsContainer[currentIndex])
        updateSelectedStatus('')
    }, [currentIndex])


    const setStudentAns = (answer) => {
        setStudentAnswer(answer)
    }


    const handleNavigateClick = (direction, index) => {
        // navigateSolution(direction)
        updateSelectedStatus(direction)
        onQuestionSelect(index)
    }


    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
          Notification.error("error","You can only upload JPG/PNG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            Notification.error("error", "Image must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
      }

    const handlePaletteClick = (index) => {
        updateSelectedStatus('');
        onQuestionSelect(index);
    }
    const onQuestionSelect = (index) => {
        if(index === currentQIndex){
            return
        }
        toggleButtonLoader(true);
        setCurrentIndex(index);
        // fetchTestSolutions(index+1, (bool) => togglePageLoader(bool), (bool)=>{toggleButtonLoader(bool)})
    }
    const handleSubSelect = (index) =>{
        updateSelectedStatus('');
        handleSubjectSelection(index, (bool) => togglePageLoader(bool))
    }
    const buttonLoaderCallBack = () => {
        toggleButtonLoader(false);
        updateSelectedStatus('');
    }

    const uploadImage = info => {
      if (info.file?.status === "uploading") {
        toggleImageLoader(true)
        return;
      }
      if (info.file) {
        getBase64(info.file.originFileObj, imageUrl =>
           { updateImage(imageUrl, info.file.originFileObj)
            toggleImageLoader(false)}
        );
      }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
      }

    const toggleImageModal = (bool, index) => {
        toggleImgModal(bool);
        setCurrentImg(base64Images[index])
    }
    const formatTime = () => {
        return [Math.floor(currentQ?.duration/60).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        }), (currentQ?.duration%60).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })]
    }
    const selectedSubIndex = 0;
    return (
       <div className="TestSolutions" style={{height: "100%"}}>
          <PageHeader
            style={{backgroundColor:"#F6F4FE"}}
            title={
                <span> {selectedTest.title} </span>
                }
            onBack={() => history.goBack('/practise-result-screen')}
            extra={
                    <div className="leftInnerNav">
                        <div className="innerNavBtn">
                            {solutionSubjectsList?.map((item, index) => (
                                        <Button key={index}
                                            onClick={() => {handleSubSelect(index)}}
                                            style={
                                                questionsContainer && questionsContainer?.length && questionsContainer[currentIndex]?.subjectId === item?.id
                                                ? {
                                                    backgroundColor: "#E6E6E6",
                                                    color: "#191919",
                                                    border: "none",
                                                    cursor:solutionSubjectsList?.length>1?"pointer":"not-allowed"
                                                  }
                                                : {cursor:solutionSubjectsList?.length>1?"pointer":"not-allowed"}
                                            }
                                        >
                                        <div style={{width: "100%",
                                            overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} >
                                            {item.name}
                                        </div>
                                        </Button>
                                ))}
                        </div>
                     </div>
                }
          />
          <div className="testContainer">
              <div className="lqPanel" style={{backgroundColor:'#FAFAFA'}}>
                <div style={{height: "100%", borderRight: `1px solid ${Color.midGrey}`}}>
                    <div className="questionContainer">
                        <div className="questionHeader">
                            <span className="m-t-20">
                                {currentIndex+1}
                            </span>
                            <div className="leftText r-c-sa">
                                {
                                    (currentQ?.submitStatus=="Unanswered" || currentQ?.submitStatus=="Unseen" || currentQ?.submitStatus=="Bookmarked")
                                    ? <span style={{color:"#AEAEAE"}}> Marks obtained: {currentQ?.marksObtained??0} </span>
                                    :
                                        (currentQ?.submitStatus=="Attempted" || currentQ?.submitStatus=="AttemptedAndBookmarked")
                                        ?
                                            currentQ?.marksObtained
                                                ?
                                                    currentQ?.marksObtained>=0
                                                    ?

                                                    <span style={{color:"#AEAEAE"}}> Marks obtained: <span style={{color:"green", fontWeight:750}}>  {currentQ?.marksObtained}  </span></span>
                                                    :
                                                    <span style={{color:"#AEAEAE"}}> Marks obtained: <span style={{color:"red", fontWeight:750}}>  {currentQ?.marksObtained} </span> </span>
                                                :
                                            <span style={{color:"#AEAEAE"}}>  0.00 </span>
                                        :
                                        <span style={{color:"#AEAEAE"}}>  0.00 </span>
                                    }

                                <span> {formatTime()[0]!="NaN"?formatTime()[0]:"0"} mins : {formatTime()[1]!="NaN"?formatTime()[1]:"0"} secs </span>
                            </div>
                        </div>
                        <div className="qaSection">{
                            singleQuestion(currentQ, currentQIndex, setStudentAns, beforeUpload, uploadImage,
                            base64Images, removeAnswerImage, toggleImageModal, imgLoader, testType, setImgUrl, setImageViewerVisibility)}</div>
                    </div>
                    <div className="actionContainer" style={{backgroundColor:'white'}}>
                            <div className="footerBtns">
                                <div className="qNavBtn r-c-sb" style={{width:"100%"}}>
                                    <Button style={{border: 'none', backgroundColor: 'transparent'}}
                                        disabled={currentIndex <= 0}
                                        onClick={()=> {handleNavigateClick('prev', currentIndex-1)}}
                                    >
                                        {selectedStatus === "prev" && buttonLoader ? <LoadingOutlined /> : <LeftOutlined/>}
                                        PREVIOUS
                                    </Button>
                                    <Button style={{border: 'none', backgroundColor: 'transparent'}}
                                        disabled={currentIndex >= palateData?.length - 1}
                                        onClick={()=> {handleNavigateClick('next', currentIndex+1)}}
                                    >   {selectedStatus == "next" && buttonLoader ? <LoadingOutlined /> : <RightOutlined/>}
                                        NEXT
                                    </Button>
                                </div>
                            </div>
                    </div>
                </div>
              </div>
              <div className="rqPanel">
                <div style={{height: "100%"}}>
                    {legand(solutionCounts, selectedTest, questionsContainer)}
                    {questionPalette(currentQIndex, handlePaletteClick,palateData, currentIndex)}
                </div>
              </div>
          </div>
          {
          isImageViewerVisible && (
            <ImageViewer isPreviewModalVisible={isImageViewerVisible} 
            image={imgUrl} setIsPreviewModalVisible={setImageViewerVisibility}/>
          )
          }
          <PageDataLoader visible={pageLoader}/>
       </div>
    )

}

const mapStateToProps = state => {
    const {
     questionsList, currentQIndex, currentQ,  base64Images, durationSeconds,
    time, qCounts, palateData, solutionCounts, selectedTest, solutionSubjects, currentIndex,
    questionsContainer, testType,solutionSubjectsList
    } = state.studentPracticeTest;
    return {
     questionsList, currentQIndex, currentQ,  base64Images, durationSeconds,
         time, qCounts, palateData, solutionCounts, selectedTest, solutionSubjects, currentIndex,
         questionsContainer, testType,solutionSubjectsList
    };
  };

  const mapDispatchToProps = dispatch => ({
    fetchTestSolutions: (index, loaderCallback, buttonLoaderCallBack) => dispatch(fetchTestSolutions(index, loaderCallback, buttonLoaderCallBack)),
    setCurrentIndex: index => dispatch(setCurrentIndex(index)),
    fetchTestSummary: (loaderCallback) => dispatch(fetchTestSummary(loaderCallback)),
    handleSubjectSelection: (index, loaderCallback) => dispatch(handleSubjectSelection(index, loaderCallback,)),
    setCurrentStudentQ: (currentQuesData)=>dispatch(setCurrentStudentQ(currentQuesData))
  });


export default connect(mapStateToProps, mapDispatchToProps)(PractiseTestSolution);


{/* <div style={{fontSize: 20, fontWeight: 700}}> <ClockCircleOutlined />
                <span style={{color: Color.darkGrey}}> 45:12:43</span> </div> */}
