const Styles = {
  popUpContainer: {
    position: 'absolute',
    right: 40,
    bottom: 50,
    width: 300,
    height: 300,
    borderRadius: 25,
    padding: 0,
    margin: 0,
    boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)'
  },
  popUpControl: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
  }
}

export default Styles;
