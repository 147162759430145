import {
	UPDATE_CURRICULUM,
	UPDATE_CHECKED_TEACHERS,
} from '../actions/curriculum';


const initialState = {
	curriculum: {
		data: [],
		subjects: [],
		teachers: [],
	},
	checkedTeachers: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_CURRICULUM:
			return {
				...state,
				curriculum: action.data
			};
		case UPDATE_CHECKED_TEACHERS:
			return {
				...state,
				checkedTeachers: action.data
			};

		default:
			return state;
	}
};

export default reducer;
