import React, { useState, useEffect } from 'react'
import {Modal, Button,  Input, Select, DatePicker, TimePicker, Tooltip, Steps} from 'antd'
import "./modal.css"
import {connect} from 'react-redux';
import Notification from "services/Notification";
import { storeCreateTestModalData, getSubjects, getExaminer, removeSelectedSearchSubjs,
  postTestCreate, getIndexOfTest, createTestModal, showGenerateQuesListView, updateSelectedCardType, getTestClassList, getTestChapterList, removeSelectedChapter } from '../../../actions/testAdmin';
import { AiOutlineClose } from "react-icons/ai";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import moment from 'moment';

import { CloseOutlined, ExclamationCircleOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';


const { Step } = Steps;
const { Option } = Select;
let timer = false;
let selectedTestStartTime = null;
function CreateTestModal({
    createTestModalBool,
    createTestModalName,
    createTestModal,
    storeCreateTestModalData,
    testCreateObj,
    getSubjects,
    getExaminer,
    getExaminerArr,
    getExaminerBool,
    getSubjectsBool,
    getSubjectsArr,
    removeSelectedSearchSubjs,
    postTestCreate,
    universalLoaderBool,
    getIndexOfTest,
    getIndexOfTestBool,
    stdsForCreatingTest,
    history,
    userType,
    listViewType,
    createTestModalSource,
    updateSelectedCardType,
    getTestClassList,
    selectedClassroomId,
    getTestChapterList,
    chaptersForCreatingTest,
    removeSelectedChapter,
    selectedClassroom,
    loaders,
    groupedChapters,
    searchedGroupedChapters
}) {
    let selectedTypeNew = testCreateObj?.testType === '' ? 0 : testCreateObj?.testType === 'Objective' ? 1 : 2 ;
    const [selectedType, setSelectedType] = useState(selectedTypeNew);
    const [currentView, setCurrentView] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [searchTextIndex, setSearchTextIndex] = useState('');
    const [handleRender, updateHandleRender] = useState(false);
    const [chapterSearchSubjectId, setChapterSearchSubjectId] = useState(null);
    const isClassScreen =  window.location.pathname=='/test-std-to-subjects-screen' ? true: false;
    const syllabusTypes = [{id:0, type:"Chapter"}, {id:1, type:"Part"}, {id:2, type:"Full"}]
    const timerDisable = (dependency) => {
      for (let x of dependency){
        let bool = testCreateObj[x] === ''||testCreateObj[x]==='Invalid date'||testCreateObj[x]==undefined
        if (bool) return true
      }
      return false
    }
    let condition1 = testCreateObj?.title?.trim() === '' || testCreateObj?.tIndex === '' || testCreateObj?.class === '' || !testCreateObj?.subjectName?.length || testCreateObj?.teacherName === '' || testCreateObj?.syllabus === '' || testCreateObj?.syllabus==undefined;
    let condition2 = testCreateObj?.heldDate === '' || testCreateObj?.heldDate==='Invalid date' ||testCreateObj?.heldStartTime==='Invalid date' || testCreateObj?.heldStartTime === '' || testCreateObj?.heldEndTime === ''||testCreateObj?.heldEndTime==='Invalid date' || ((testCreateObj?.resultDate === ''||testCreateObj?.resultDate==='Invalid date') && testCreateObj?.testType !== 'Subjective') ||( (testCreateObj?.resultTime === ''||testCreateObj?.resultTime==='Invalid date') && testCreateObj?.testType !== 'Subjective') || testCreateObj?.instruction === '';
    const handleNext = () => {
        if(currentView === 1){
            if(condition1){
              Notification.error("Error", 'Please fill all details');
            } else if(testCreateObj?.syllabus != undefined && testCreateObj?.syllabus !== 'Full' && testCreateObj?.chapterList?.length < testCreateObj?.subjectName?.length ){
              Notification.error("Error", `Please select chapter(s) for the ${testCreateObj?.syllabus?.toLowerCase()} test`)
            }
            else{
              setCurrentView(currentView+1);
            }
        }else if(currentView === 2) {
            if(condition1 || condition2 || testCreateObj?.testType === ''|| testCreateObj?.instruction?.trim()===''){
              Notification.error("Error", 'Please fill all details');
            }else if(!moment(testCreateObj?.heldDate).isAfter(moment(new Date()).format('yyyy-MM-DD')) && testCreateObj?.heldDate !== moment(new Date()).format('yyyy-MM-DD')){
              Notification.error("Error", `Held date should not be less than today's date.`);
            }else if((moment(testCreateObj?.heldStartTime, "HH:mm:ss").format("HH:mm:ss") === moment(testCreateObj?.heldEndTime, "HH:mm:ss").format("HH:mm:ss") ) || (moment(testCreateObj?.heldStartTime, "HH:mm:ss").format("HH:mm:ss")  > moment(testCreateObj?.heldEndTime, "HH:mm:ss").format("HH:mm:ss") )){
              Notification.error("Error", `End time should be greater than the start time.`);
            }else if (testCreateObj?.testType==="Objective" && !moment(testCreateObj?.resultDate).isAfter(moment(new Date()).format('yyyy-MM-DD')) && testCreateObj?.resultDate !== moment(new Date()).format('yyyy-MM-DD') && testCreateObj?.testType !== 'Subjective') {
              Notification.error("Error", `Result date should not be less than the today's date.`);
            }else if (testCreateObj?.testType==="Objective" && (moment(testCreateObj?.heldDate).format('yyyy-MM-DD') === moment(testCreateObj?.resultDate).format('yyyy-MM-DD') && moment(testCreateObj?.heldEndTime, "HH:mm:ss").format("HH:mm:ss") === moment(testCreateObj?.resultTime, "hh:mm A").format("HH:mm:ss"))  || (moment(testCreateObj?.heldDate).format('yyyy-MM-DD') === moment(testCreateObj?.resultDate).format('yyyy-MM-DD') && (moment(testCreateObj?.heldEndTime, "HH:mm:ss").format("HH:mm:ss") > moment(testCreateObj?.resultTime, "hh:mm A").format("HH:mm:ss")))) {
              Notification.error("Error", `Result time should not be less than the end time.`);
            }else if (moment(testCreateObj?.heldDate).format('yyyy-MM-DD') > moment(testCreateObj?.resultDate).format('yyyy-MM-DD') && testCreateObj?.testType !== 'Subjective') {
              Notification.error("Error", `Result time can not be less than the held time.`);
            }else{
                if (createTestModalName!=='test-edit'){
                  updateSelectedCardType('');
                }
                postTestCreate((res) => {
                  if (listViewType=='edit'){
                      if (createTestModalSource!='navigation'){
                        history.push('admin-test');
                      }
                  }
                  else{
                    history.push('admin-test');
                  }
                  createTestModal(createTestModalName, userType, '');
                });
            }
        }else{
            if (testCreateObj?.testType===''){
              Notification.error("Error", "Please fill all the details");
            }
            else{
              setCurrentView(currentView+1);
            }
    }
  }

    useEffect(()=>{
      if (createTestModalName==='test-create'&& testCreateObj?.syllabus!=undefined&&testCreateObj?.syllabus!=''&&testCreateObj?.subjectName?.length&&testCreateObj?.class!='' && testCreateObj?.class!=undefined){
        getIndexOfTest();
      }
      getExaminer('');
      getSubjects('', isClassScreen?selectedClassroom?.id:'');
    }, []);

    useEffect(() => {
      if(handleRender){
        clearTimeout(timer);
        timer = setTimeout(() => {
           if(searchTextIndex === 'examiner'){
             getExaminer(searchText)
             updateHandleRender(false);
           }else if(searchTextIndex==='chapters'){
            getTestChapterList(searchText, chapterSearchSubjectId);
            updateHandleRender(false);
           }
           else{
             getSubjects(searchText, isClassScreen?selectedClassroom?.id:'')
             updateHandleRender(false);
           }
        },800);
      }
      
      if(selectedTypeNew !== selectedType){
        setSelectedType(selectedTypeNew);
      }
    }, [searchText, searchTextIndex, handleRender, selectedType, selectedTypeNew]);



    function disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().startOf('day');
    }
    function disabledResultDate(current) {
      return current && current < moment(testCreateObj?.heldDate).startOf('day');
    }
    const disabledStartTimeHours = () => {

      const hours = []
      if (moment().format('YYYY-MM-DD') === testCreateObj?.heldDate) {
          for (var i = 0; i < moment().hour(); i++) {
              hours.push(i)
          }
      }
      return hours
  }
    
    const disabledStartTimeMinutes = (selectedHour) => {
        const minutes= [];
        if (selectedHour === moment().hour()){
            for(var i =0; i <= moment().minute(); i++){
                minutes.push(i);
            }
        }
        return minutes;
    }
    const disabledEndTimeHours = () => {
      const hours = []
      for (var i = 0; i < moment(testCreateObj?.heldStartTime, 'HH:mm:ss').hour(); i++) {
              hours.push(i)
      }
      return hours
    }
    const disabledEndTimeMinutes = (selectedHour) => {
      // console.log("selected", selectedHour, )
      const minutes= [];
      if (selectedHour === moment(testCreateObj?.heldStartTime, 'HH:mm:ss').hour()){
          for(var i =0; i <= moment(testCreateObj?.heldStartTime, 'HH:mm:ss').minute(); i++){
              minutes.push(i);
          }
      }
      return minutes;
    }
    const disabledResultTimeHours = () => {
      const hours = [];
      if (testCreateObj?.heldDate===testCreateObj?.resultDate){
        for (var i = 0; i < moment(testCreateObj?.heldEndTime, 'HH:mm:ss').hour(); i++) {
          hours.push(i)
        }
      }
      return hours
    }

    const disabledResultTimeMinutes = (selectedHour) => {
      const minutes = [];
      if (testCreateObj?.heldDate===testCreateObj?.resultDate&&selectedHour===moment(testCreateObj?.heldEndTime, 'HH:mm:ss').hour()){
        for (var i = 0; i <= moment(testCreateObj?.heldEndTime, 'HH:mm:ss').minute(); i++) {
          minutes.push(i)
        }
      }
      return minutes
    }

    return (
        <Modal
          width={600}
          centered
          className='modal-round-corner'
          style={{padding:0}}
          visible={false}
          onCancel={null}
          closable={false}
          onOk={null}
          footer={null}
          >
          <div className='p-l-10 p-r-10 createTestModal'>
            <div className="r-c-c-c m-b-30">
              <div className="text-sm color-black bold-600"> { createTestModalName==='test-edit'||createTestModalName==='test-clone'?'Test details':'Create Test'}</div>
              <div className="text-xs color-black">Kindly fill in the information below</div>
            </div>
            <Steps progressDot current={currentView}>
              <Step title={<div className='text-xs bold'>Type</div>} onClick={() => setCurrentView(0)} className='cursor-pointer'/>
              <Step title={<div className='text-xs bold'>Details</div>} onClick={() => setCurrentView(1)} className='cursor-pointer' />
              <Step title={<div className='text-xs bold'>Time & Date </div>} onClick={() => setCurrentView(2)} className='cursor-pointer' />
            </Steps>
            {currentView==1
            ?
              <div className="m-t-20 p-l-5 p-r-5" style={{maxHeight:"60vh", overflowY:"auto"}}>
                <div className="m-t-20">
                  <div className="text-xs color-black bold-600 m-b-5 m-t-20">Title</div>
                  <Input value={testCreateObj?.title} style={{borderRadius:5}} onChange={(e) => storeCreateTestModalData('title', e.target.value)}  placeholder="Enter title" bordered={true}/>
                  <div className="r-jsb m-t-20">
                    <div className="flex-1 m-r-20">
                    <div className="text-xs color-black bold-600 m-b-5">Examiner</div>
                    <Select
                      disabled={(userType=="teacher"||createTestModalName === 'test-edit')?true:false}
                      showSearch
                      loading={getExaminerBool}
                      className='m-b-15 test-custom-select createTestModalValue'
                      value={(testCreateObj?.teacherName==''||testCreateObj?.teacherName==undefined)?null:testCreateObj?.teacherName}
                      placeholder={'Search examiner'}
                      style={{width:'100%'}}
                      defaultActiveFirstOption={false}
                      showArrow={userType==="teacher"?false:true}
                      filterOption={false}
                      onSearch={(text) =>  {setSearchText(text); setSearchTextIndex('examiner'); updateHandleRender(true)}}
                      onChange={(text,value) => storeCreateTestModalData('examiner', {name:value.value, id:value.key})}
                      notFoundContent={null}
                    >
                      {!getExaminerBool && getExaminerArr?.map((item, index) => {
                        if (item.id!=testCreateObj?.teacherId){
                          return <Option key={item.id} className="createTestModalDropdown" value={item.value}>{item.value}</Option>
                        }
                        return null
                        })}
                    </Select>
                    </div>
                    <div className="flex-1">
                      <div className="text-xs color-black bold-600 m-b-5">Syllabus</div>
                      <Select
                        onChange={(value) => {
                          storeCreateTestModalData('syllabus', value);
                          if (!isClassScreen){
                            storeCreateTestModalData('class', '');
                          }
                          storeCreateTestModalData('clearsubjects', null);
                          storeCreateTestModalData('clearchapters', null);
                          storeCreateTestModalData('tIndex', 0);
                          getSubjects('', isClassScreen?selectedClassroom?.id:'')
                        }}
                        disabled={testCreateObj?.totalQuestions>0?true:false}
                        placeholder={'Select syllabus'}
                        value={(testCreateObj?.syllabus === ''||testCreateObj?.syllabus==undefined) ? null : testCreateObj?.syllabus}
                        className="test-custom-select createTestModalValue"  style={{width:'100%', color:testCreateObj?.syllabus === '' ? '#a2a2a2' : ''}}>
                        {syllabusTypes?.map((item, index) => (
                          <Option key={item.id} value={item.type} className="createTestModalDropdown">{item.type}</Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="text-xs color-black bold-600 m-b-5 m-t-20"> {testCreateObj?.syllabus==='Chapter'?'Subject':testCreateObj?.testType==='Objective'?'Subject(s)':'Subject'}</div>
                  <Select
                    disabled={(testCreateObj?.syllabus==undefined||testCreateObj?.syllabus===""||(createTestModalName === 'test-edit' && testCreateObj?.totalQuestions>0)||(createTestModalName==='test-clone')||(testCreateObj?.syllabus==='Chapter'&& testCreateObj?.subjectName?.length>=1) ||(testCreateObj?.testType==='Subjective'&&testCreateObj?.subjectName?.length>=1)) ? true : false}
                    showSearch
                    loading={loaders?.modalSubjectLoader}
                    value={testCreateObj?.syllabus==='Chapter'?'Search subject':testCreateObj?.testType==='Objective'?'Search subject(s)':'Search subject'}
                    className='test-custom-select createTestModalValue'
                    // placeholder={'Search subject'}
                    style={{width:'100%', color:"#AEAEAE"}}
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={false}
                    onSearch={(text) => {setSearchText(text); setSearchTextIndex('subjects'); updateHandleRender(true)}}
                    onSelect={(text,value) => {
                      storeCreateTestModalData('clearchapters', null);
                      if (! testCreateObj?.subjectId?.includes(value.key)){
                        storeCreateTestModalData('subjects', {name:value.value, id:value.key});
                        if(!isClassScreen){
                          storeCreateTestModalData('class', '');
                        }
                        else{
                          getIndexOfTest(); 
                        }
                        getTestClassList(testCreateObj?.subjectId??[]);
                        if (isClassScreen && testCreateObj?.syllabus!=='Full'){
                          getTestChapterList();
                        }
                      }
                    }}
                    notFoundContent={null}
                  >
                    {!getSubjectsBool && getSubjectsArr?.map((item, index) => {
                      if (!testCreateObj?.subjectId?.includes(""+item.id)){
                        return <Option className="createTestModalDropdown" key={item.id} value={item.value}>{item.value}</Option>
                      }else{
                        return null
                      }
                  })}
                  </Select>
                  {testCreateObj?.subjectName?.length
                  ?
                    <div className='flex-wrap r-ac'>
                      {testCreateObj?.subjectName?.map((item, index) => (
                        <div key={index} className='shadow-box white p-l-10 p-r-10 p-t-5 p-b-5 m-r-15 m-t-15 r-ac' style={{background:'#594099'}}>
                          <div className='text-xs'>{item}</div>
                          {
                            (createTestModalName==='test-edit' && testCreateObj?.totalQuestions>0) || createTestModalName === 'test-clone'
                            ?
                            null
                            :
                            <AiOutlineClose onClick={((createTestModalName === 'test-edit' && testCreateObj?.totalQuestions>0)||(createTestModalName==='test-clone')) 
                                                          ? 
                                                          () => {} 
                                                          : 
                                                          () => {
                                                            storeCreateTestModalData('removesubjectchapters',index);
                                                            if (testCreateObj?.class!==0 && testCreateObj?.subjectId?.length!==1){
                                                              getIndexOfTest(); 
                                                            }
                                                            if (testCreateObj?.subjectId?.length!==1 && testCreateObj?.syllabus!=='Full'){
                                                              getTestClassList(testCreateObj?.subjectId??[]);
                                                              getTestChapterList();
                                                            } 
                                                            if (createTestModalSource!=='class' && testCreateObj?.subjectId?.length===1){
                                                              storeCreateTestModalData('class', '');
                                                              storeCreateTestModalData('tIndex', 0);
                                                            }
                                                            removeSelectedSearchSubjs(index, 'removeOne');
                                                          }}  
                                            className='m-l-10 text-xs cursor-pointer'/>
                          }
                        </div>
                      ))}
                    </div>
                  : null
                  }
                </div>
                  <div className='r-jsb m-t-20'>
                    <div className="flex-1 m-r-20">
                    <div className="text-xs color-black bold-600 m-l-5 m-b-5">Class</div>
                    <Select
                      disabled={((createTestModalName!=='test-clone') && (isClassScreen  || (createTestModalName === 'test-edit' && testCreateObj?.totalQuestions>0)
                      ||testCreateObj?.subjectName?.length===0 )
                      ) ? true : false}
                      onChange={(text, value) => {
                        if (createTestModalName !== 'test-clone'){
                        storeCreateTestModalData('clearchapters', null);
                      }
                        storeCreateTestModalData('standardId', value.key);
                        storeCreateTestModalData('class', value.value);
                        getIndexOfTest();
                        if (createTestModalName!=='test-clone' && testCreateObj?.syllabus !=='Full'){
                          getTestChapterList();
                        }
                      }}
                      loading={loaders?.modalClassLoader&&!isClassScreen}
                      value={testCreateObj?.class === '' ? 'Select class' : testCreateObj?.class}
                      className="test-custom-select createTestModalValue"  style={{width:'100%', color:testCreateObj?.class === '' ? '#a2a2a2' : ''}}>
                      {stdsForCreatingTest?.map((item, index) => (
                        <Option className="createTestModalDropdown" key={item.id} value={`${item.std} - ${item.section}`}>{`${item.std} - ${item.section}`}</Option>
                      ))}
                    </Select>
                    </div>
                    <div className='flex-1'>
                      <div className='r-ac m-b-5'>
                        <Tooltip placement="right" trigger="click" title={<span>A live count (automated) of how many tests have been conducted for any subject for a given classroom.</span>} overlayStyle={{borderRadius:"15px"}} overlayInnerStyle={{borderRadius:"15px", padding:"20px", width:350}}>
                            <ExclamationCircleOutlined style={{color:"#1089FF"}} className='text-xs'/>
                        </Tooltip>
                        <div className="text-xs color-black bold-600 m-l-5">Index</div>
                      </div>
                      <Input value={testCreateObj?.tIndex === '' ? 0 : testCreateObj?.tIndex} readOnly disabled={true} style={{borderRadius:5}}/>
                    </div>
                  </div>
                  {
                    testCreateObj?.syllabus!=''&&testCreateObj?.syllabus!=undefined &&testCreateObj?.syllabus!='Full' && testCreateObj?.subjectName?.length>0&& groupedChapters!=undefined && Object.keys(groupedChapters)?.length>0 && testCreateObj?.standardId?.length>0 ?
                    <>
                    {
                      Object.keys(groupedChapters)?.map((subjectId)=>(
                        <>
                          <div className="text-xs color-black bold-600 m-b-5 m-t-20">{testCreateObj?.syllabus==="Chapter" ?`${groupedChapters[subjectId].subjectName} Chapter`:`${groupedChapters[subjectId].subjectName} Chapter(s)`}</div>
                          <Select
                            disabled={((createTestModalName === 'test-edit'&&testCreateObj?.totalQuestions>0)||(createTestModalName==='test-clone')||testCreateObj?.class===''||testCreateObj?.class==undefined||(testCreateObj?.syllabus==="Chapter"&&testCreateObj?.chapterList?.length>=1)) ? true : false}
                            showSearch
                            value={testCreateObj?.syllabus==='Chapter' ?`Search ${groupedChapters[subjectId].subjectName} chapter`:`Search ${groupedChapters[subjectId].subjectName} Chapter(s)`}
                            className='test-custom-select createTestModalValue'
                            placeholder={testCreateObj?.syllabus==='Chapter' ?'Search chapter':'Search Chapter(s)'}
                            style={{width:'100%', color:"#AEAEAE"}}
                            defaultActiveFirstOption={false}
                            showArrow={true}
                            loading={loaders?.modalChapterLoader}
                            filterOption={false}
                            onSearch={(text) => {
                              setChapterSearchSubjectId(subjectId);
                              setSearchText(text); 
                              setSearchTextIndex('chapters'); 
                              updateHandleRender(true)
                            }}
                            onSelect={(text,value) => {
                                storeCreateTestModalData('chapters', {id:value.value[0], name:value.children, subjectId:value.value[1]});
                              }
                            }
                            notFoundContent={null}
                          >
                            {searchedGroupedChapters[subjectId]?.chapters?.map((item, index) => {
                                if (testCreateObj?.chapterList?.map((item)=>item.id).includes(item.id)){
                                  return null
                                }
                                return <Option className="createTestModalDropdown" key={item.id} value={[item.id, item.subjectId]}>{item.name}</Option>
                          })}
                          </Select>
                          {testCreateObj?.subjectName?.length
                          ?
                          <div className='flex-wrap r-ac'>
                          {testCreateObj?.chapterList?.map((item, index) => {
                            if (groupedChapters[subjectId].chapters?.map(chapterObj=>chapterObj.id)?.includes(item.id)){
                              return (
                                <div key={index} className='shadow-box white p-l-10 p-r-10 p-t-5 p-b-5 m-r-15 m-t-15 r-ac' style={{background:'#594099'}}>
                                <div className='text-xs'>{item.name}</div>
                                {
                                  (createTestModalName==='test-edit'&&testCreateObj?.totalQuestions>0) || createTestModalName === 'test-clone'
                                  ?null
                                  :
                                  <AiOutlineClose onClick={((createTestModalName === 'test-edit'&&testCreateObj?.totalQuestions>0)||(createTestModalName==='test-clone')) 
                                  ? () => {} 
                                  : () => {removeSelectedChapter(item.id);}}  
                                    className='m-l-10 text-xs cursor-pointer'/>
                                }
                              </div>
                              )
                            }
                              return null
                              })}
                            </div>
                            :null}
                        </>
                      ))
                    }
                    </>
                    :null
                  }
              </div>
            :
                currentView==2
                ?
                  <div className="m-t-20">
                    <div className="text-xs color-black bold-800 m-b-5">Test to be held on </div>
                      <div className="r-jsb m-t-10">
                        <div className='flex-1 m-r-20'>
                          <div className="text-xs color-black bold-600 m-b-5">Date</div>
                          <DatePicker
                            allowClear={false}
                            defaultValue={testCreateObj?.heldDate === '' ? '' : moment(testCreateObj?.heldDate)}
                            onChange={(e, date) => {storeCreateTestModalData('heldDate', date); selectedTestStartTime=date}}
                            dropdownClassName='legend-range-calendar-drpdwn'
                            className="createmodalbg" style={{ width:"100%"}}
                            disabledDate={disabledDate}
                            showToday={false} />
                        </div>
                        <div className='flex-1 r-ac'>
                          <div className='flex-1 m-r-20'>
                            <div className="text-xs color-black bold-600 m-b-5">Start Time</div>
                            <TimePicker
                              allowClear={false}
                              disabled={timerDisable(['heldDate'])}
                              defaultValue={testCreateObj?.heldStartTime === '' ? '' : moment(testCreateObj?.heldStartTime, 'HH:mm:ss')}
                              format={'HH:mm'}
                              minuteStep={30}
                              inputReadOnly={true}
                              showNow={false}
                              disabledHours={()=>disabledStartTimeHours()}
                              disabledMinutes={disabledStartTimeMinutes}
                              onChange={(e, time) => storeCreateTestModalData('heldStartTime', moment(time, 'HH:mm').format('HH:mm:ss'))} className="createmodalbg" style={{ width:"100%"}} />
                          </div>
                          <div className='flex-1'>
                            <div className="text-xs color-black bold-600 m-b-5">End Time</div>
                            <TimePicker
                              allowClear={false}
                              disabled={timerDisable(['heldDate', 'heldStartTime'])}
                              defaultValue={testCreateObj?.heldEndTime === '' ? '' : moment(testCreateObj?.heldEndTime, 'HH:mm:ss')}
                              format={'HH:mm'}
                              disabledHours={()=>disabledEndTimeHours()}
                              disabledMinutes={disabledEndTimeMinutes}
                              inputReadOnly={true}
                              showNow={false}
                              onChange={(e, time) => storeCreateTestModalData('heldEndTime', moment(time, 'HH:mm').format('HH:mm:ss'))} className="createmodalbg" style={{ width:"100%"}} />
                          </div>
                        </div>
                      </div>
                      {testCreateObj?.testType == 'Subjective'
                        ?
                        null
                        :
                          <div className="m-t-20">
                            <div className="text-xs color-black bold-800 m-b-5">Result to be published on</div>
                            <div className="r-jsb m-t-10">
                              <div className='flex-1 m-r-20'>
                                <div className="text-xs color-black bold-600 m-b-5">Date</div>
                                <DatePicker
                                  allowClear={false}
                                  disabled={timerDisable(['heldDate', 'heldStartTime', 'heldEndTime'])}
                                  defaultValue={testCreateObj?.resultDate === '' ? '' : moment(testCreateObj?.resultDate)}
                                  onChange={(e, date) => storeCreateTestModalData('resultDate', date)}
                                  dropdownClassName='legend-range-calendar-drpdwn'
                                  className="createmodalbg" style={{ width:"100%"}}
                                  disabledDate={disabledResultDate}
                                  showToday={false} />
                              </div>
                              <div className='flex-1'>
                                <div className="text-xs color-black bold-600 m-b-5">Time</div>
                                <TimePicker
                                  allowClear={false}
                                  disabled={timerDisable(['heldDate', 'heldStartTime', 'heldEndTime','resultDate'])}
                                  defaultValue={testCreateObj?.resultTime === '' ? '' : moment(moment(testCreateObj?.resultTime, "HH:mm A").format("HH:mm"), "HH:mm:ss")}
                                  format={'HH:mm'}
                                  minuteStep={30}
                                  inputReadOnly={true}
                                  showNow={false}
                                  disabledHours={()=>disabledResultTimeHours()}
                                  disabledMinutes={disabledResultTimeMinutes}
                                  onChange={(e, time) => storeCreateTestModalData('resultTime', moment(time, 'HH:mm').format('HH:mm:ss'))} className="createmodalbg" style={{ width:"50%"}} />
                              </div>
                            </div>
                          </div>
                      }
                      <div className="m-t-20">
                        <div className="text-xs color-black bold-600 m-b-5">Instructions</div>
                        <TextArea
                            placeholder="Add Instructions"
                            defaultValue={testCreateObj?.instruction} onChange={(e) => storeCreateTestModalData('instruction', e.target.value)}
                            autoSize={{ minRows: 2, maxRows: 5 }} className="createmodalbg" style={{color:"#191919"}}/>
                      </div>
                  </div>
                :
                    currentView==0
                    ?
                      <div className='m-t-20'>
                        <div className="text-xs color-black bold-600 m-b-5">Choose Test Type</div>
                        <div className="r-jsb m-t-20">
                          <div onClick={((createTestModalName === 'test-edit'&&testCreateObj?.totalQuestions>0)||(createTestModalName==='test-clone')) ? () => {} : ()=> {
                            setSelectedType(1); storeCreateTestModalData('testType', 'Objective');
                          }}
                                className='shadow-box p-20 position-relative cursor-pointer'>
                            <div className='text-right m-b-10' style={{marginTop:'-10px'}}>
                              {selectedType === 1 ? <MdRadioButtonChecked className='color-purple' /> : <MdRadioButtonUnchecked />}
                            </div>
                            <img src={require('Assets/Frame 2.svg').default} style={{width: "180px"}} />
                            <div className="text-xmd color-black bold-600 text-center m-t-30">Objective type questions</div>
                          </div>
                          <div onClick={((createTestModalName === 'test-edit'&&testCreateObj?.totalQuestions>0)||(createTestModalName==='test-clone')) ? () => {} : ()=> {
                            if (testCreateObj?.testType==='Objective'){
                              storeCreateTestModalData('syllabus', null);
                              storeCreateTestModalData('standardId', null);
                              storeCreateTestModalData('class', '');
                              storeCreateTestModalData('clearsubjects', null);
                              storeCreateTestModalData('clearchapters', null);
                              storeCreateTestModalData('tIndex', 0);
                              getSubjects('', isClassScreen?selectedClassroom?.id:'')
                            }
                            setSelectedType(2); storeCreateTestModalData('testType', 'Subjective');
                          }}
                                className='shadow-box p-20 position-relative cursor-pointer'>
                            <div className='text-right m-b-10' style={{marginTop:'-10px'}}>
                              {selectedType === 2 ? <MdRadioButtonChecked className='color-purple' /> : <MdRadioButtonUnchecked />}
                            </div>
                            <img src={require('Assets/Frame 1.svg').default} style={{width: "180px"}} />
                            <div className="text-xmd color-black bold-600 text-center m-t-30">Subjective type questions</div>
                          </div>
                        </div>
                      </div>
                    :
                    null
                }
                <div className="r-c-c m-t-30">
                  <Button onClick={() => {createTestModal(createTestModalName, userType, ''); setCurrentView(0);}} className='blankGreyBtnWrap radius-100 m-r-30'>
                      Cancel
                  </Button>
                  {currentView<2
                  ?
                    <Button onClick={handleNext} className='purpleBtnWrap radius-100 purpleButtonHoverEffect' disabled={currentView!=1?false:currentView===1&&loaders.indexLoader?true:false}>
                        NEXT
                    </Button>
                  :
                    <Button
                      loading={universalLoaderBool}
                      onClick={universalLoaderBool ? () => {} : () => handleNext()} className='purpleBtnWrap radius-100 purpleButtonHoverEffect'>
                        {createTestModalName === 'test-edit' ? 'UPDATE' : 'CREATE'}
                    </Button>
                  }
                </div>
            </div>
        </Modal>
    )
}


const mapStateToProps = state => {
    const {testCreateObj, getExaminerArr, getExaminerBool, getSubjectsBool, getSubjectsArr,
      universalLoaderBool, getIndexOfTestBool, stdsForCreatingTest, createTestModalBool, createTestModalName,
      generateQuesObj, userType, selectedTest, listViewType, createTestModalSource, selectedClassroomId, chaptersForCreatingTest, 
      selectedClassroom, loaders, groupedChapters, searchedGroupedChapters} = state.testAdmin;
    return {testCreateObj, getExaminerArr, getExaminerBool, getSubjectsBool, getSubjectsArr,
      universalLoaderBool, getIndexOfTestBool, stdsForCreatingTest, createTestModalBool, createTestModalName,
      generateQuesObj, userType, selectedTest, listViewType, createTestModalSource, selectedClassroomId, chaptersForCreatingTest, 
      selectedClassroom, loaders, groupedChapters, searchedGroupedChapters};
  };

  const mapDispatchToProps = dispatch => ({
    storeCreateTestModalData: (key, value) => dispatch(storeCreateTestModalData(key, value)),
    getSubjects: (text, standardId) => dispatch(getSubjects(text, standardId)),
    getExaminer: (text) => dispatch(getExaminer(text)),
    removeSelectedSearchSubjs: (index, text) => dispatch(removeSelectedSearchSubjs(index, text)),
    postTestCreate: (callback) => dispatch(postTestCreate(callback)),
    getIndexOfTest: () => dispatch(getIndexOfTest()),
    createTestModal: (name, userType, source) => dispatch(createTestModal(name, userType, source)),
    showGenerateQuesListView: (callback, pagesource) => dispatch(showGenerateQuesListView(callback, pagesource)),
    updateSelectedCardType: (type) => dispatch(updateSelectedCardType(type)),
    getTestClassList: (classIds) => dispatch(getTestClassList(classIds)),
    getTestChapterList: (search, subId, iscreate, stdid) =>  dispatch(getTestChapterList(search, subId, iscreate, stdid)),
    removeSelectedChapter: (id) => dispatch(removeSelectedChapter(id))
  });


export default connect(mapStateToProps, mapDispatchToProps)(CreateTestModal);
