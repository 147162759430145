import { Switch } from "antd";
import AnimatedDatePicker from "components/AnimatedDatePicker";
import AnimatedTextField from "components/AnimatedTextField";
import moment from "moment";
import React from 'react';
function CreateOfferPromo({ offersObj, setOffersObjKey, inputStyle, setOffersObj, hasPromo, setHasPromo, cardClassName, hidePrivate, setFormHasPromo, hasOfferDate, setHasOfferDate, setFormHasOfferDate }) {
    const disabledEndDate = (current) => {
        const today = moment().format('YYYY-MM-DD');
        const currentDate = current.format('YYYY-MM-DD');
        return current && (currentDate < today || (offersObj?.startDate && currentDate < moment(offersObj.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')));
    };
    return (
        <div className={cardClassName}>
            <div style={{ color: "#636363", fontSize: 18, fontWeight: 700 }}>Promo code details</div>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                <div style={{ fontSize: 14, color: "#636363" }}>User need to enter promo code to avail this promotional offer</div>
                <Switch checked={hasPromo} onChange={() => {
                    setHasPromo(!hasPromo);
                    if (setFormHasPromo) {
                        setFormHasPromo(!hasPromo);
                    }
                    setOffersObjKey('code', '');
                }} />
            </div>
            {hasPromo ?
                <>
                    <AnimatedTextField
                        label={"Promo code"}
                        isMandatory={true}
                        inputId={"-1004"}
                        value={offersObj?.code || ''}
                        handleInputChange={(e) => {
                            setOffersObjKey("code", e.target.value?.replaceAll(' ', '') || '')
                        }}
                        style={{ marginTop: 30 }}
                        inputstyle={inputStyle}
                    />
                    {!hidePrivate ? (
                        <>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                                <div style={{ fontSize: 14, color: "#636363" }}>This promo code is private</div>
                                <Switch checked={offersObj?.privateOffer} onChange={() => {
                                    if (!offersObj?.privateOffer) {
                                        setHasOfferDate(false);
                                        if (setFormHasOfferDate) {
                                            setFormHasOfferDate(false);
                                        }
                                        setOffersObj({
                                            ...offersObj, type: 'course', applicableLive: true, applicableVideo: true,
                                            applicableStudy: true, applicableTest: true, privateOffer: true, startDate: null, endDate: null, maxUses: 0,
                                        })
                                    } else {
                                        const {
                                            type,
                                            applicableLive,
                                            applicableVideo,
                                            applicableStudy,
                                            applicableTest,
                                            startDate,
                                            endDate,
                                            maxUses,
                                            ...others } = offersObj;
                                        setOffersObj({
                                            ...others,
                                        })
                                        setOffersObjKey('privateOffer', !offersObj?.privateOffer)
                                    }
                                }} />
                            </div>
                            {offersObj?.privateOffer ?
                                (
                                    <div className='r-c-fs' style={{ gap: "2rem", alignItems: 'flex-end' }}>
                                        <AnimatedTextField
                                            label={"Enter number of usage"}
                                            isMandatory={true}
                                            inputId={"-1006"}
                                            value={offersObj?.maxUses || null}
                                            handleInputChange={(e) => {
                                                let enteredValue = parseInt(e.target.value) || 0;
                                                enteredValue = enteredValue < 0 ? 0 : enteredValue;
                                                setOffersObjKey("maxUses", enteredValue)
                                            }}
                                            style={{ marginTop: 30, width: "50%" }}
                                            inputstyle={inputStyle}
                                            type="number"
                                        />
                                        <div style={{ width: "50%" }}>
                                            <AnimatedDatePicker
                                                label="Valid till"
                                                allowClear={true}
                                                style={{ marginLeft: "-10px", pointetEvents: 'none' }}
                                                value={offersObj?.endDate ? moment(offersObj.endDate, 'DD/MM/YYYY') : null}
                                                format={'DD/MM/YYYY'}
                                                disabledDate={disabledEndDate}
                                                handleDateChange={(date, dateString) => { setOffersObjKey('endDate', dateString) }}
                                                inputId={"-1081"}
                                            />
                                        </div>
                                    </div>
                                )
                                : null
                            }
                        </>
                    )
                        : null
                    }
                </>
                : null}
        </div>
    )
}
export default CreateOfferPromo;