import React from "react";
import Modal from "antd/lib/modal";
import "antd/lib/modal/style/index";
import { Badge, Avatar, Collapse } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { TestTopBar } from "components";
import { Color } from "services";
import { PieChart } from "react-minimal-pie-chart";
import { studentTestAnswerSheet } from "actions/mobileTest";

import { connect } from "react-redux";
// import chemistryIcon from "Assets/test-assignment/chemistryIcon.svg";
// import mathsIcon from "Assets/test-assignment/mathsIcon.svg";
// import physicsIcon from "Assets/test-assignment/physicsIcon.svg";
// import sliderIndicator from "Assets/test-assignment/sliderIndicator.svg";
// import sliderIndicatorLight from "Assets/test-assignment/sliderIndicatorLight.svg";
import emptyTest from "Assets/test-assignment/emptyTest.svg";
import "./styles.css";
import "../../design/layout.css";
import "../../design/assignment.css";
// import DonutChart from 'react-donut-chart';

const { Panel } = Collapse;

class MobileTestResults extends React.Component {
  componentDidMount() {
    localStorage.clear();
  }

  secondsToHms(d) {
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    if (h <= 9) {
      h = "0" + h;
    }
    if (m <= 9) {
      m = "0" + m;
    }
    if (s <= 9) {
      s = "0" + s;
    }
    // console.log(`${h}:${m}:${s}`)
    return `${h}:${m}:${s}`;
  }

  render() {
    const { history, studentTestResult, studentSubjectId } = this.props;
    const chartData = [
      {
        title: "New",
        value:
          studentTestResult !== undefined
            ? Object.keys(studentTestResult).length
              ? studentTestResult.totalMarksObatined
              : 0
            : 0,
        // value: donutData.new,
        color: "#28DF99",
      },
      {
        title: "In Progress",
        value:
          studentTestResult !== undefined
            ? Object.keys(studentTestResult).length
              ? studentTestResult.totalMarks -
                studentTestResult.totalMarksObatined
              : 0
            : 0,
        // value: donutData.inProgress,
        color: "#ededed",
      },
    ];
    const BlankTest = () => (
      <div
        className="m-10 text-center r-c-c-c"
        style={{ height: "calc(100% - 78px)" }}
      >
        <img src={emptyTest} alt="" className="" style={{ width: "auto" }} />
        <div className="NutinFont bold-700 text-sm m-t-10">
          No Result available!
        </div>
        <div className="NutinFont text-xmd simgrey">
          There are no Result available at the moment. Please check back later.
        </div>
      </div>
    );

    return (
      <div className="p-b-20" style={{ height: "100%" }}>
        <TestTopBar
          backPress={() => history.goBack()}
          title={"Result"}
          listMode={false}
          precentshow={false}
        />
        {studentTestResult !== undefined ? (
          Object.keys(studentTestResult).length ? (
            <div className="p-b-10">
              <div className="m-10">
                <div className="NutinFont bold-700 text-md m-t-10">
                  {studentTestResult.subjectNames}
                </div>
                <div className="NutinFont bold-600 text-xmd simgrey">
                  {studentTestResult.chapterNames.replace(/,/g, ", ")}
                </div>
              </div>
              <div className="p-20 mobileTestCardWrap m-10">
                <div className="r-c-c">
                  <PieChart
                    labelPosition={0}
                    rounded
                    startAngle={-90}
                    totalValue={studentTestResult.totalMarks}
                    lineWidth={15}
                    data={chartData}
                    style={{ width: "50%" }}
                  />
                  <div className="r-ac NutinFont absolute text-center">
                    <div className="text-lg color-black bold-800">
                      {studentTestResult.totalMarksObatined}
                    </div>
                    <div className="text-sm lowgrey bold-400 m-l-2">
                      /{studentTestResult.totalMarks}
                    </div>
                  </div>
                </div>
                <div className="text-sm color-black bold-700 m-t-20 m-b-20">
                  Summary
                </div>
                <div className="r-c-sb">
                  <div className="color-black text-xmd">All Questions</div>
                  <div className="color-black text-xmd bold-700">
                    {studentTestResult.totalQuestions}
                  </div>
                </div>
                <div
                  className="m-10"
                  style={{ height: "1px", background: "#EDEDED" }}
                ></div>
                <div className="r-c-sb">
                  <div className="r-ac">
                    <Badge color={Color.seaGreen} />
                    <div className="color-black text-xmd">Correct</div>
                  </div>
                  <div className="color-black text-xmd bold-700">
                    {studentTestResult.correctCount}
                  </div>
                </div>
                <div
                  className="m-10"
                  style={{ height: "1px", background: "#EDEDED" }}
                ></div>
                <div className="r-c-sb">
                  <div className="r-ac">
                    <Badge color={Color.red} />
                    <div className="color-black text-xmd">Incorrect</div>
                  </div>
                  <div className="color-black text-xmd bold-700">
                    {studentTestResult.inCorrectCount}
                  </div>
                </div>
                <div
                  className="m-10"
                  style={{ height: "1px", background: "#EDEDED" }}
                ></div>
                <div className="r-c-sb">
                  <div className="r-ac">
                    <Badge color={Color.aeGrey} />
                    <div className="color-black text-xmd">Unanswered</div>
                  </div>
                  <div className="color-black text-xmd bold-700">
                    {studentTestResult.skippedCount}
                  </div>
                </div>
              </div>
              <div className="p-20 mobileTestCardWrap m-10">
                <div className="text-sm color-black bold-700 m-b-20">Time</div>
                <div className="r-c-sb">
                  <div className="color-black text-xmd">Total time spent</div>
                  <div className="color-black text-xmd bold-700">
                    {this.secondsToHms(studentTestResult.duration)}
                  </div>
                </div>
                {/*<div className='m-10' style={{height:'1px', background:'#EDEDED'}}></div>
                <div className='r-c-sb'>
                  <div className='color-black text-xmd'>Average time per answer</div>
                  <div className='color-black text-xmd bold-700'>10:12</div>
                </div>*/}
              </div>
              <div className="p-20 mobileTestCardWrap m-10 p-b-30">
                <div className="text-sm color-black bold-700 m-b-20">
                  Topics
                </div>
                <Collapse
                  accordion
                  bordered={false}
                  defaultActiveKey={["0"]}
                  style={{ margin: "-10px", background: "transparent" }}
                  className="m-b-10"
                >
                  {studentTestResult.topics.map((data, qindex) => (
                    <Panel header={data.name} key={qindex}>
                      <div className="flex-wrap display-flex">
                        {data.questions
                          .sort((a, b) => a.index - b.index)
                          .map((item, i) => (
                            <Avatar
                              key={i}
                              onClick={() => {
                                this.props.studentTestAnswerSheet(
                                  data.questions,
                                  i,
                                  item.index + 1
                                );
                                history.push(
                                  `/web-test/${studentSubjectId}/instructions/start/answer-review`
                                );
                              }}
                              className="text-sm m-r-10 m-b-10 cursor-pointer r-c-c"
                              style={{
                                color: "#fff",
                                height: 34,
                                width: 34,
                                background: item.correct
                                  ? Color.seaGreen
                                  : item.inCorrect
                                  ? Color.red
                                  : // (item.inCorrect && (parseInt(item.marksObtained) > 0)) ? Color.seaGreen :
                                  item.skipped
                                  ? Color.aeGrey
                                  : null,
                              }}
                            >
                              {item.index + 1}
                            </Avatar>
                          ))}
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </div>
          ) : (
            <BlankTest />
          )
        ) : (
          <BlankTest />
        )}

        <Modal
          visible={false}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img
              src={require("Assets/dots-gif.gif").default}
              style={{ maxWidth: "100%" }}
              width={150}
              alt=""
            />
            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} /> */}
            {/* <div className="text-xmd m-t-10">Loading Assignments</div> */}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { studentTestResult, studentSubjectId } = state.mobileTest;
  return { studentTestResult, studentSubjectId };
};

const mapDispatchToProps = (dispatch) => ({
  studentTestAnswerSheet: (arr, index, qNo) =>
    dispatch(studentTestAnswerSheet(arr, index, qNo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileTestResults);
