import React, { useEffect, useState } from 'react'
import "./loginPage.css"
import { Font13, Font15, Font21, Heading } from 'components/responsiveTypography'
import AnimatedTextField from 'components/AnimatedTextField'
import { Button } from 'components/Button';
import { Separator } from './loginSignInForm';
import ReCaptchaV3 from 'pages/loginNew/reCaptchaV3';
import { connect } from 'react-redux';
import { postEmailLogIn, sendForgotLink } from 'actions/loginNew';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const validateEmail = (emailId) => {
    const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailId?.match(email)) {
        return false
    } else {
        return true
    }
}

function LoginEmailForm({ setEmailIdSignIn, setShowVerifyOtpForm, postEmailLogIn, sendForgotLink, isLoginModal }) {
    const [userEmailRegister, setUserEmailRegister] = useState({
        email: "",
        password: "",
        isWebLogin: true
    });
    const [emailLoader, setEmailLoader] = useState(false);
    const [forGotPressed, setForgotPressed] = useState(false);
    const history = useHistory();

    const sitekey = '6LcAkU4pAAAAANllAlCwLHYgaGPsqk4-KF9PKnT5';
    const { executeReCaptcha } = ReCaptchaV3({ sitekey });

    const handleEmailSubmit = async () => {
        if (!userEmailRegister?.email || !userEmailRegister?.password) {
        } else {
            setEmailLoader(true);
            try {
                const recaptchaToken = await executeReCaptcha('emailLogin');
                postEmailLogIn(
                    recaptchaToken,
                    userEmailRegister,
                    history,
                    () => { setEmailLoader(false) })
            } catch (error) {
                console.error('Error executing ReCaptcha:', error);
            }
        }
    };

    const handleForgotPassword = () => {
        if (userEmailRegister?.email == '') { }
        else {
            setEmailLoader(true);
            sendForgotLink(
                userEmailRegister?.email,
                history,
                () => { setEmailLoader(false); }
            )
        }
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleKeyPress = (e) => {
        if (
            e.key === 'Enter' &&
            userEmailRegister.email &&
            (forGotPressed || userEmailRegister.password) &&
            !validateEmail(userEmailRegister.email)
        ) {
        if(forGotPressed){
            handleForgotPassword()
        }else{
            handleEmailSubmit();
        }       
        }
    };
    return (
        <div className={isLoginModal ? 'login-modal-form-width' : 'form-width'}>
            <div className='m-b-40'><Font21 bold color='#191919'>Sign in using Email ID</Font21></div>
            <div className={forGotPressed ? "forgot-email-field-margin" : "email-field-margin"}>
                <AnimatedTextField
                    label="Email ID"
                    value={userEmailRegister?.email}
                    handleInputChange={(e) => { setUserEmailRegister({ ...userEmailRegister, email: e.target.value }) }}
                    isMandatory={false}
                    inputId="Email ID"
                    onKeyDown={handleKeyPress}
                />
                    {userEmailRegister?.email && validateEmail(userEmailRegister?.email) ? <div style={{ color: "red", fontSize: 12, fontWeight: 600, display: "flex", justifyContent: "flex-end", marginTop:5 }}>Invalid email</div> : <></>}
            </div>
            {!forGotPressed &&
                <>
                    <AnimatedTextField
                        label="Password"
                        value={userEmailRegister?.password}
                        handleInputChange={(e) => { setUserEmailRegister({ ...userEmailRegister, password: e.target.value }) }}
                        isMandatory={false}
                        inputId="password"
                        type="password"
                        onKeyDown={handleKeyPress}
                    />
                    <div className="m-t-5 m-b-5" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => { setForgotPressed(true) }} type="link">
                            Forgot Password?
                        </Button>
                    </div>
                </>}
            <div className='m-b-10'><Font13 color='#636363'>By continuing, you agree to the <a href="https://aulas.in/terms-conditions" target="_blank" rel="noreferrer"> Terms of the service</a> and <a href="https://aulas.in/privacy-policy" target="_blank" rel="noreferrer">Privacy policy</a> </Font13></div>
            <Button
                loading={emailLoader}
                type="primary"
                onClick={forGotPressed ? handleForgotPassword : handleEmailSubmit}
                style={{ width: "100%", marginBottom: 20 }}
                disabled={forGotPressed ? !userEmailRegister?.email : !userEmailRegister?.email || !userEmailRegister?.password || validateEmail(userEmailRegister?.email)}
            >
                {forGotPressed ? "SEND" : "SIGN IN"}
            </Button>
            {forGotPressed &&
                <div style={{ display: "flex", justifyContent: "flex-end", color: "#594099", fontWeight: 700, marginBottom: 30, cursor: "pointer", marginTop: -10 }}
                    onClick={() => { setForgotPressed(false); }}>
                    GO BACK
                </div>
            }
            <Separator />
            {isMobile ?
                <Button
                    type="primary"
                    style={{ width: "100%", backgroundColor: "#FFFFFF", color: "#594099" }}
                    onClick={() => { setEmailIdSignIn(false); setShowVerifyOtpForm(false); }}
                >CONTINUE WITH MOBILE NUMBER</Button> :
                <Font15>Sign in or register using <span style={{ color: "#1089FF", cursor: "pointer", fontWeight: 700 }} onClick={() => { setEmailIdSignIn(false); setShowVerifyOtpForm(false); }}>Mobile number</span></Font15>}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { resendOtpTimer, timer, phoneNo, isUserRegistered } = state.loginNew;
    return { resendOtpTimer, timer, phoneNo, isUserRegistered };
};

const mapDispatchToProps = (dispatch) => ({
    postEmailLogIn: (captchaToken, userEmailRegister, history, callBack) => dispatch(postEmailLogIn(captchaToken, userEmailRegister, history, callBack)),
    sendForgotLink: (userEmailRegister, history, callBack) => dispatch(sendForgotLink(userEmailRegister, history, callBack)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginEmailForm);