import { Button } from 'components/Button'
import React from 'react';
import moment from 'moment';
import TitleAndDescription from './titleAndDescription'
import { BellOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';

const dashboardStyle = {
    width: "90%", height: "fit-content", boxShadow: "1px 1px 8px #c7cece", margin: "5px 20px 20px 20px", borderRadius: 6, padding: 15
}
const insideViewAllStyle = {
    width: "380px", height: "fit-content", boxShadow: "1px 1px 8px #c7cece", margin: "5px 20px 20px 0px", borderRadius: 6, padding: 15
}
function PaymentRequestsCard({ item, storePaymentReqModalData, setIsEmailIdModalVisible }) {
    const location = useLocation();
    return (
        <div style={location?.state?.key === "fromViewAll" ? insideViewAllStyle : dashboardStyle}>
            <TitleAndDescription
                title={`Request for ${item?.note}`}
                description={"₹" + " " + item?.amountToShow ? item?.amountToShow : "0"}
                font16={true}
                typography={true}
            />
            <div className='r-c-sb m-t-10 m-b-10'>
                <TitleAndDescription
                    title={"Issue date"}
                    description={item?.date ? moment(item?.date, "DD-MM-YYYY, hh:mm A").format("DD/MM/YYYY, hh:mm A") : "-"}
                />
                <TitleAndDescription
                    title={"Due date"}
                    description={item?.dueDate ? moment(item?.dueDate, "DD-MM-YYYY").format("DD/MM/YYYY") : "-"}
                />
            </div>
            <div className='font'>
                <div style={{ color: "#636363", fontSize: 12 }}>Status</div>
                <div style={{ color: item?.status === 'Overdue' ? "red" : item?.status === 'Overdue' ? "red" : "#FFA931", fontSize: 14 }}>{item?.status.toUpperCase()} {item?.reminderCount > 0 ? <><span className='m-l-10 m-r-5'><BellOutlined style={{ color: "#FFA931" }} /></span><span style={{ color: "#FFA931" }}>{item?.reminderCount}</span></> : <></>}</div>
            </div>
            <Button type="primary" style={{ width: "100%", marginTop: 10, height: 35, }} onClick={() => { storePaymentReqModalData(item); setIsEmailIdModalVisible(true) }}>PAY NOW</Button>
        </div>
    )
}

export default PaymentRequestsCard