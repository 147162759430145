import {
  SET_FEE_DETAILS,
  SET_All_ACTIVITIES,
  SET_OVERVIEW_FILTER,
  SET_SELECTED_STD,
  SET_INVOICE_FILE,
  SET_STUDENT_NAME,
  SET_WHICH_VIEWALL_CLICKED,
  STORE_STUDENTS_BY_COURSE,
  STORE_VIEWALL_DATA,
  STORE_COURSEDETAILS_FEE,
  STORE_RESOURCE_DATA,
  STORE_STANDARD_DATA,
  RECENT_ACTIVITES_VA_FROM_WHERE,
  STORE_ALL_STUDENTS_BY_COURSE_DETAILS,
  STORE_GRAPH_DATA,
  STORE_FILTER_DETAILS
} from "../actions/feeManagement";
const initialState = {
  feeMangementData: {},
  allActivitiesFilter: {},
  overviewFilter: {},
  selectedStd: {},
  invoiceFile: null,
  studentName: null,
  viewAllIs: '',
  studentByCourseData: {},
  ViewAllDATA: {},
  courseDetailsOfResources: {},
  resourceData: {},
  standardData: {},
  recentActivitiesVAfromWhere: '',
  allStudentsByCoursesReducer: { search: null, startDate: null, endDate: null, sort: null, mode: [], type: null, status: [], deleted: false, durationFilter: null, feeType: null, courseStatus:'All' },
  insideGraphData: {},
  filterDetails: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FEE_DETAILS: {
      let newStudentFeeDetails = { ...state.feeMangementData };

      newStudentFeeDetails[action.key] = action.res;

      return {
        ...state,

        feeMangementData: newStudentFeeDetails,
        feeMangementData: newStudentFeeDetails,
      };
    }
    case SET_All_ACTIVITIES: {
      let filter = { ...state.allActivitiesFilter };
      filter[action.key] = action.res;
      return {
        ...state,
        allActivitiesFilter: filter,
      };
    }
    case SET_OVERVIEW_FILTER: {
      let filter = { ...state.overviewFilter };
      filter[action.key] = action.res;
      return {
        ...state,
        overviewFilter: filter,
      };
    }
    case SET_SELECTED_STD: {
      return {
        ...state,
        selectedStd: action.data,
      };
    }
    case SET_STUDENT_NAME: {
      return {
        ...state,
        studentName: action.val
      }
    }
    case SET_INVOICE_FILE:
      return {
        ...state,
        invoiceFile: action.file
      }
    case SET_STUDENT_NAME:
      return {
        ...state,
        studentName: action.val
      }

    case SET_WHICH_VIEWALL_CLICKED:
      return {
        ...state,
        viewAllIs: action.val
      }

    case STORE_STUDENTS_BY_COURSE:
      return {
        ...state,
        studentByCourseData: action.res
      }

    case STORE_VIEWALL_DATA:
      let tempViewAll = { ...state.ViewAllDATA };
      if (action.key == 'Live Courses') {
        tempViewAll.liveCourses = action.val
      } else if (action.key === 'Video Resources') {
        tempViewAll.videoResources = action.val;
      } else if (action.key === 'Study Materials') {
        tempViewAll.studyMaterials = action.val;
      } else if (action.key === 'Online Test Series') {
        tempViewAll.onlineTestSeries = action.val;
      }

      console.log({ tempViewAll })
      return {
        ...state,
        viewAllDATA: tempViewAll
      }

    case STORE_COURSEDETAILS_FEE:
      return {
        ...state,
        courseDetailsOfResources: action.res
      }

    case STORE_RESOURCE_DATA:
      return {
        ...state,
        resourceData: action.res
      }

    case STORE_STANDARD_DATA:
      return {
        ...state,
        standardData: action.res
      }

    case RECENT_ACTIVITES_VA_FROM_WHERE:
      return {
        ...state,
        recentActivitiesVAfromWhere: action.val
      }

    case STORE_ALL_STUDENTS_BY_COURSE_DETAILS: {
      let tempVariable = { ...state.allStudentsByCoursesReducer };
      tempVariable[action.key] = action.val;
      return {
        ...state,
        allStudentsByCoursesReducer: tempVariable,
      }
    }

    case STORE_FILTER_DETAILS:
      let tempVariable = { ...state.filterDetails };
      tempVariable[action.key] = action.val;
      return {
        ...state,
        filterDetails: tempVariable,
      }

    case STORE_GRAPH_DATA:
      return {
        ...state,
        insideGraphData: action.res
      }

    default:
      return state;
  }
};

export default reducer;
