import { Checkbox, DatePicker, Menu, Typography } from 'antd';
import { Button } from 'components/Button';
import moment from 'moment';
import SearchFilterComp from 'pages/adminMarketPlaceCourse/components/searchFilterComp';
import React, { useEffect, useState } from 'react';
import { AvailableOfferFilterTypes, OfferAvailableTypes, OfferFilterTypes, OfferSortTypes, UnavailableOfferFilterTypes } from '../constants/offers';
const gridListLayoutIcon = require('Assets/layout/grid-layout-icon.svg').default;
const tableListLayoutIcon = require('Assets/layout/table-layout-icon.svg').default;
const OfferListHeader = ({ offerFilterParams, setOfferFilterParams, setFilterVisible, isFilterVisible, selectAllHandler, selectedItems,
    offerData, setSelectedItems, setMainSectionType, setPreviousMainSectionType, type }) => {
    const [filterStateParams, setFilterStateParams] = useState({ ...(offerFilterParams || {}) });
    useEffect(() => {
        setFilterStateParams({ ...(offerFilterParams || {}) });
    }, [JSON.stringify(offerFilterParams)]);
    const OfferSort = () => {
        return (
            <div className=''>
                <span className='m-b-5' style={{ display: 'inline-block', color: "#636363" }}>Sort by</span>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {OfferSortTypes?.map((item) => (
                        <div
                            key={item.value}
                            className='offer-filter-option'
                            style={{
                                ...((!item.value && !filterStateParams?.sort) || (item.value && filterStateParams?.sort == item.value) ?
                                    {
                                        backgroundColor: '#D6C8F5',
                                        color: '#191919',
                                        border: 'none',
                                    } :
                                    {
                                        backgroundColor: '#FFFFFF',
                                        color: '#636363',
                                        border: '1px solid #E6E6E6',
                                    }
                                )
                            }}
                            onClick={(e) => { e.stopPropagation(); setFilterStateParams((params) => ({ ...params, sort: !item.value ? undefined : item.value })) }}
                        >
                            {item?.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    };
    const disabledEndDate = (current) => {
        const startDate = moment(filterStateParams?.startDate, 'YYYY-MM-DD', true).isValid() ? moment(filterStateParams?.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;
        const currentDate = current.format('YYYY-MM-DD');
        return current && startDate && currentDate < startDate;
    };
    const OfferDateFilter = () => {
        return (
            <div className='m-b-5'>
                <div className='r-jsb m-b-10' style={{ width: "100%" }}>
                    <div className='flex-column m-r-20' style={{ gap: '5px', width: "50%" }}>
                        <span style={{ color: "#636363" }}>Start date</span>
                        <DatePicker placeholder='Start date' label='Start date' format={"DD/MM/YYYY"} style={{ width: '100%', borderRadius: '1rem' }} onChange={(date, dateString) => { setFilterStateParams((params) => ({ ...params, startDate: dateString ? moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : null, endDate: null })) }} value={filterStateParams?.startDate ? moment(filterStateParams?.startDate) : null} />
                    </div>
                    <div className='flex-column' style={{ gap: '5px', width: "50%" }}>
                        <span style={{ color: "#636363" }}>End date</span>
                        <DatePicker placeholder='End date' disabledDate={disabledEndDate} format={"DD/MM/YYYY"} style={{ width: '100%', borderRadius: '1rem' }} onChange={(date, dateString) => { setFilterStateParams((params) => ({ ...params, endDate: dateString ? moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : null })) }} value={filterStateParams?.endDate ? moment(filterStateParams?.endDate) : null} />
                    </div>
                </div>
            </div>
        )
    };

    function handleFilterApply() {
        setFilterVisible(false);
        setOfferFilterParams((offerFilterParams) => ({
            search: offerFilterParams?.search,
            all: offerFilterParams?.all,
            available: offerFilterParams?.available,
            ...filterStateParams,
            filterTypes: filterStateParams?.filterTypes?.length ? [...filterStateParams.filterTypes] : undefined
        }));
        // getOffersOverviewData({
        //     search: filterParams.search || undefined,
        //     ...filterParams,
        // }, () => { setPageLoading(false) });
    }
    function handleFilterReset() {
        setFilterStateParams((filterParams) => {
            return {};
        });
    }
    const OfferAvailableFilter = () => {
        const handleTypeToggle = (val) => {
            if (val === 'All') {
                setFilterStateParams((filterParams) => ({ ...filterParams, filterTypes: undefined }));
            } else {
                let filterTypes = filterStateParams?.filterTypes?.length ? [...(filterStateParams.filterTypes)] : [];
                const index = filterTypes.indexOf(val);
                if (index == -1) {
                    filterTypes.push(val);
                    if (['course', 'singleCourse', 'category', 'subcategory'].every((item) => filterTypes.includes(item))) {
                        filterTypes = undefined;
                    }
                } else {
                    filterTypes.splice(index, 1);
                }
                setFilterStateParams((filterParams) => ({ ...filterParams, filterTypes }));
            }
        };
        return (
            <div>
                <span className='m-b-5' style={{ display: 'inline-block', color: "#636363" }}>Available for</span>
                <div style={{ display: "flex", flexWrap: "wrap", }}>
                    {OfferAvailableTypes?.map((item, index) => (
                        <div
                            key={item.value}
                            className={`offer-filter-option${index == OfferAvailableTypes.length - 1 ? ' m-r-0' : ''}`}
                            style={{
                                ...((item.value === 'All' && !filterStateParams?.filterTypes?.length) || (item.value !== 'All' && filterStateParams?.filterTypes?.includes(item?.value)) ?
                                    {
                                        backgroundColor: '#D6C8F5',
                                        color: '#191919',
                                        border: 'none',
                                    } :
                                    {
                                        backgroundColor: '#FFFFFF',
                                        color: '#636363',
                                        border: '1px solid #E6E6E6',
                                    }
                                )
                            }}
                            onClick={() => handleTypeToggle(item?.value)}
                        >
                            {item?.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const OfferTypesFilter = () => {
        const handleTypeToggle = (val) => {
            setFilterStateParams((filterParams) => {
                if (val === 'All') {
                    const { published, unpublished, banner, privateOffer, expired, ...others } = filterParams
                    return others;
                } else if (val === 'published') {
                    filterParams.published = filterParams.published ? null : true;
                } else if (val === 'unpublished') {
                    filterParams.unpublished = filterParams.unpublished ? null : true;
                } else if (val === 'banner') {
                    filterParams.banner = filterParams.banner ? null : true;
                } else if (val === 'expired') {
                    filterParams.expired = filterParams.expired ? null : true;
                } else if (val === 'privateOffer') {
                    filterParams.privateOffer = filterParams.privateOffer ? null : true;
                }
                if ((offerFilterParams.available && filterParams.published && filterParams.banner && filterParams.privateOffer) ||
                    (offerFilterParams.available === false && filterParams.unpublished && filterParams.expired) ||
                    (offerFilterParams.available == null && filterParams.unpublished && filterParams.expired && filterParams.published && filterParams.banner && filterParams.privateOffer)
                ) {
                    const { published, unpublished, banner, privateOffer, expired, ...others } = filterParams;
                    return others;
                }
                return { ...filterStateParams };
            });
        };
        const TypesArray = offerFilterParams?.available == null ? OfferFilterTypes : (offerFilterParams.available ? AvailableOfferFilterTypes : UnavailableOfferFilterTypes);
        return (
            <div>
                <span className='m-b-5' style={{ display: 'inline-block', color: "#636363" }}>Type</span>
                <div style={{ display: "flex", flexWrap: "wrap", }}>
                    {TypesArray?.map((item, index) => (
                        <div
                            key={item.value}
                            className={`offer-filter-option${index == TypesArray.length - 1 ? ' m-r-0' : ''}`}
                            style={{
                                ...((item.value === 'All' && (filterStateParams?.published == null && filterStateParams?.unpublished == null && filterStateParams?.banner == null
                                    && filterStateParams?.expired == null && filterStateParams?.banner == null && filterStateParams?.privateOffer == null))
                                    || (item.value !== 'All' && filterStateParams[item.value]) ?
                                    {
                                        backgroundColor: '#D6C8F5',
                                        color: '#191919',
                                        border: 'none',
                                    } :
                                    {
                                        backgroundColor: '#FFFFFF',
                                        color: '#636363',
                                        border: '1px solid #E6E6E6',
                                    }
                                )
                            }}
                            onClick={() => handleTypeToggle(item?.value)}
                        >
                            {item?.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const offerFilterDropDown = () => {
        return (
            <div style={{ width: "100%", height: "fit-content" }} onClick={(e) => e.stopPropagation()}>
                <Menu style={{ padding: "1rem", borderRadius: "0.5rem", border: "1px solid #E6E6E6" }}>
                    <OfferSort />
                    <OfferAvailableFilter />
                    <OfferTypesFilter />
                    <OfferDateFilter />
                    <div style={{ borderBottom: '1px solid #AEAEAE', marginTop: "20px" }}></div>
                    <div className='r-c-sb m-t-20'>
                        <Button type="link" style={{ color: "#594099" }}
                            onClick={handleFilterReset}
                        >RESET</Button>
                        <Button type='primary' style={{ borderRadius: 5 }} onClick={handleFilterApply}>APPLY</Button>
                    </div>
                </Menu >
            </div>
        );
    }
    const availableForText = OfferAvailableTypes.filter((item) => offerFilterParams?.filterTypes?.some((fItem) => fItem == item.value)).reduce((acc, cur, index) => {
        if (index == 0) {
            return cur.label;
        } else if (index == offerFilterParams?.filterTypes?.length - 1) {
            return acc + ' and ' + cur.label;
        } else {
            return acc + ', ' + cur.label;
        }
    }, '') || 'All';
    const filterTypesText = OfferFilterTypes.filter((item) => offerFilterParams?.[item.value]).reduce((acc, cur, index) => {
        if (index == 0) {
            return cur.label;
        } else if (index == offerFilterParams?.filterTypes?.length - 1) {
            return acc + ' and ' + cur.label;
        } else {
            return acc + ', ' + cur.label;
        }
    }, '') || 'All'
    return (
        <div className='offer-list-header m-l-25'>
            <div className="offer-header-search-filter">
                <div style={{ width: '35%' }}>
                    <SearchFilterComp
                        style={{ width: '100%' }}
                        placeholder='Search'
                        value={filterStateParams?.search || ''}
                        handleSearch={(e) => { setOfferFilterParams((params) => ({ ...params, search: e })); }}
                        menu={offerFilterDropDown}
                        open={isFilterVisible}
                        handleFilterIconClick={(e) => { e.stopPropagation(); setFilterStateParams({ ...(offerFilterParams || {}) }),setFilterVisible((isFilterVisible) => !isFilterVisible) }}
                        overlayStyle={{ width: '32%' }}
                        placement="bottomRight"
                    />
                </div>
                <div className='r-c-fs offer-header-filter-text' style={{ width: '65%', padding: "0.1rem" }}>
                    <span className="grey-text">Sort:&nbsp;</span>
                    <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: filterTypesText === 'All' ? '45%' : '30%' }}>
                        <span className="dark-text">{OfferSortTypes.find((item => item.value == offerFilterParams?.sort))?.label || 'Newest'}</span>
                    </Typography.Text>
                    <span className="grey-text" style={{ minWidth: "fit-content" }}>&nbsp;| Available for:&nbsp;</span>
                    <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: filterTypesText === 'All' ? '45%' : '30%' }}>
                        <span id='offer-available-for-text' className="dark-text">{availableForText}</span>
                    </Typography.Text>
                    <span className="grey-text" style={{ minWidth: "fit-content" }}>&nbsp;| Type:&nbsp;</span>
                    <Typography.Text id='offer-filter-types-text' ellipsis={{ tooltip: true }} style={{ maxWidth: filterTypesText === 'All' ? '45%' : '30%' }}>
                        <span className="dark-text">{filterTypesText}</span>
                    </Typography.Text>
                    {offerFilterParams?.startDate
                        ? <>
                            <span className="grey-text" style={{ minWidth: "fit-content" }}>&nbsp;| Start date:&nbsp;</span>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '40%' }}>{moment(offerFilterParams.startDate).format('DD/MM/YY')}</Typography.Text>
                        </>
                        : null
                    }
                    {offerFilterParams?.endDate
                        ? <>
                            <span className="grey-text" style={{ minWidth: "fit-content" }}>&nbsp;| End date:&nbsp;</span>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '40%' }}>{moment(offerFilterParams.endDate).format('DD/MM/YY')}</Typography.Text>
                        </>
                        : null
                    }
                </div>
            </div>
            <div className='r-c-fe' style={{ width: selectedItems?.length ? '24%' : '15%', gap: '1rem' }}>
                {type == 'gridList' && offerData?.items?.length ?
                    (<div style={{ minWidth: 'fit-content' }}>
                        <Checkbox className='offer-header-checkbox' onClick={selectAllHandler} checked={selectedItems?.length && selectedItems?.length === offerData?.items?.length} indeterminate={selectedItems?.length && selectedItems.length != offerData?.items?.length} />
                        <span style={{ marginLeft: '0.3rem', display: 'inline-block', minWidth: 'fit-content' }}>{selectedItems?.length && selectedItems?.length === offerData?.items?.length ? 'Unselect all' : 'Select all'}</span>
                        {selectedItems?.length ? <span style={{ marginLeft: '0.2rem', minWidth: 'fit-content' }}>{`(${selectedItems?.length} selected)`}</span> : null}
                    </div>)
                    : null
                }
                {type == 'tableList' && offerData?.items?.length ?
                    (<div style={{ minWidth: 'fit-content' }}>
                        {selectedItems?.length ? <span style={{ marginLeft: '0.2rem', minWidth: 'fit-content' }}>{`(${selectedItems?.length} selected)`}</span> : null}
                    </div>)
                    : null
                }
                {!selectedItems?.length ?
                    <img src={type == 'gridList' ? tableListLayoutIcon : gridListLayoutIcon} style={{ width: '1.2rem', cursor: "pointer" }} onClick={(e) => { setPreviousMainSectionType(type == 'tableList' ? 'tableList' : 'gridList'); setMainSectionType(type == 'tableList' ? 'gridList' : 'tableList'); setSelectedItems([]) }} />
                    : null
                }
            </div>
        </div >
    )
};

export default OfferListHeader;

