import React from 'react';
import {connect} from 'react-redux';
import Divider from 'antd/lib/divider';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import 'antd/lib/divider/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/button/style/index';
import { Color } from '../../../services';
import {rejectRequest, toggleScheduleModal, approveRequest} from '../../../actions/notification';
import '../styles.css';
import moment from 'moment';


class ScheduleModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: 0,
    }
  }

  pageRejectRequest(data){
    this.props.dispatch(rejectRequest(data.id))
  }

  pageApproveRequest(data){
    this.props.dispatch(approveRequest(data.id))
  }



  render() {
    const {scheduleModalVisible, approveLoader, rejectLoader, scheduleModalData} = this.props;
    return (
      <div>

        <Modal
          visible={scheduleModalVisible}
          centered
          width={800}
          closable={true}
          onCancel={() => this.props.dispatch(toggleScheduleModal(false, {}))}
          footer={[
              <div key="actions" className='r-c-c'>
                {scheduleModalData.status === 'Pending'
                ?
                  <div className='r-c-c'>
                    <Button loading={approveLoader ? true : false} className='mod-notifyApprove-btn' style={{background:Color.green}} key="back" onClick={() => this.pageApproveRequest(scheduleModalData)}>
                      Approve
                    </Button>
                    <Button loading={rejectLoader ? true : false} className='mod-notifyApprove-btn' style={{background:Color.red}} key="submit" onClick={() => this.pageRejectRequest(scheduleModalData)}>
                      Reject
                    </Button>
                  </div>
                :
                  <Button className='mod-notifyApprove-btn' style={{background:Color.darkGrey}} key="submit" loading={false} onClick={() => this.props.dispatch(toggleScheduleModal(false, {}))}>
                    Close
                  </Button>
                }

              </div>,
          ]}
        >
            <div>
              <div className='r-c-sb'>
                <div className='flex-1'>
                  <div className='' style={{color:Color.darkGrey}}>Subject</div>
                  <div className='bold-600' style={{color:Color.black}}>{scheduleModalData.name}</div>
                </div>
                <div className='flex-1'>
                <div className='' style={{color:Color.darkGrey}}>Teacher</div>
                <div className='bold-600' style={{color:Color.black}}>{scheduleModalData.teacherName}</div>
                </div>
              </div>
              <Divider style={{margin:'10px 0px'}}/>
              <div className='r-c-sb'>
                <div className='flex-1'>
                  <div className='' style={{color:Color.darkGrey}}>Start Time</div>
                  <div className='bold-600' style={{color:Color.black}}>{moment(scheduleModalData.startTime, "HH:mm:ss").format('hh:mm A')}</div>
                </div>
                <div className='flex-1'>
                <div className='' style={{color:Color.darkGrey}}>End Time</div>
                <div className='bold-600' style={{color:Color.black}}>{moment(scheduleModalData.endTime, "HH:mm:ss").format('hh:mm A')}</div>
                </div>
              </div>
              <Divider style={{margin:'10px 0px'}}/>
              <div className='r-c-sb'>
                <div className='flex-1'>
                  <div className='' style={{color:Color.darkGrey}}>Day(s)</div>
                  <div className='bold-600' style={{color:Color.black}}>{scheduleModalData.weekdays && scheduleModalData.weekdays.map((day, index, array) => (<span key={index}>{day.toUpperCase()}{index === array.length - 1 ? '' : ', '} </span>))}</div>
                </div>
              </div>

              <Divider style={{margin:'10px 0px'}}/>
              <div className='r-c-sb'>
                <div className='flex-1'>
                  <div className='' style={{color:Color.darkGrey}}>Class(es)</div>
                  <div className='bold-600' style={{color:Color.black}}>{scheduleModalData.standards && scheduleModalData.standards.map((std, index, array) => (<span key={index}>{std}{index === array.length - 1 ? '' : ', '}</span>))}</div>
                </div>
              </div>
            </div>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    scheduleModalVisible, detailModalData, approveLoader, rejectLoader, scheduleModalData
  } = state.notification;
  return {
    scheduleModalVisible, detailModalData, approveLoader, rejectLoader, scheduleModalData
  };
};

export default connect(mapStateToProps)(ScheduleModal);
