import { Button, Modal } from 'antd';
import React from 'react'
import { useHistory } from 'react-router-dom';
import {connect} from 'react-redux';
import {showKycPendingModal} from 'actions/kyc';
import './styles.css';

function KycPending({showKycPendingModal, kycPendingModal}) {
    const history = useHistory();
    return (
        <Modal
            onCancel={() => showKycPendingModal(false)}
            footer={false}
            visible={kycPendingModal}
            className="kycPendingModal"
        >
            <div className="r-c-c-c" style={{height: "100%"}}>
                <div>
                    <img src={require('Assets/kyc.png').default} height={250} alt="" />
                </div>
                <div className="m-t-20 text-md color-purple bold-700">
                    Welcome to Fee Management
                </div>
                <div className="m-t-20" style={{width: '70%', textAlign: 'center'}}>
                    You are just one step away from collecting fee from your students. All you need is to complete the KYC process to start accepting payments.
                </div>
                <div className="m-t-20 bold-600">
                    <Button type="primary" shape="round" style={{width: 160, fontWeight: 600}}
                        onClick={() => {history.push('/kyc-form'); showKycPendingModal(false) }}
                    >
                        ACTIVATE
                    </Button>
                </div>
            </div>
        </Modal>
    )
}


const mapStateToProps = state => {
    const {
        kycPendingModal
    } = state.kyc;
    return {
        kycPendingModal
    };
  };

  const mapDispatchToProps = dispatch => ({
    showKycPendingModal: bool => dispatch(showKycPendingModal(bool))
  });


export default connect(mapStateToProps, mapDispatchToProps)(KycPending);
