
import React, { useEffect, useState } from 'react'
import OverallStatusCard from './overallStatusCard'
import UserProfileCards from './userProfileCards'
import AdminSummary from './adminSummary'
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { IMG_URL } from '../../env.json';
import { getOverallStatusFunction, setDate, setSpecificStudentUseId, setAulasCareChat } from 'actions/adminCare';
import PageDataLoader from 'components/pageDataLoader';
import { Col, Row } from 'antd';
import image1 from '../../Assets/care/ic-chat.svg'
import image2 from '../../Assets/care/ic-closed-conversations.svg'
import image3 from '../../Assets/care/ic-open-conversations.svg'
import moment from 'moment';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { SubHeading } from 'components/Typography';
import { Button } from 'components/Button';
import PageHeader from 'components/pageHeader'



function SupportAdmin({
    summaryData,
    setDate,
    getOverallStatusFunction,
    setSpecificStudentUseId,
    setAulasCareChat
}) {


    const [pageLoader, togglePageLoader] = useState(false)
    useEffect(() => {
        setAulasCareChat(false)
        togglePageLoader(true);
        getOverallStatusFunction(() => togglePageLoader(false));
    }, [])

    const handleViewChatlist = (userId, createdAt) => {
        setSpecificStudentUseId(userId)
        setDate('')
        history.push('/care/admin/conversations/user')
    }
    const history = useHistory();
    const handleViewConversationClick = () => {
        setDate('')
        history.push('/care/admin/conversations')
    }
    const handleBackPress = () => {
        history.goBack()
    }

    return (
        <div>
            {/* <PageHeader
                title={<div className="text-md" style={{ fontFamily: "roboto", fontWeight: "bold" }}> Support</div>}
                onBack={() => goBack()}
                backIcon={<ArrowLeftOutlined />}
            /> */}

            <PageHeader title='Support' onBackPress={()=>handleBackPress()}/>

            {/* <h1 style={{ color: "#191919", fontFamily: "roboto", fontWeight: "bold", fontSize: "28px" }}>Support</h1> */}

            <Row style={{marginTop: 50}}>
                <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9 }}>
                    <div className="careAdminSummaryCard" >
                        <h3 className='overallStatusCard-heading'>Overview</h3>
                        <div className="adminStatusCard-rows" style={{ width: "100%" }}>
                            <div>
                                <div><img style={{ width: "50px", height: "50px", marginRight: "20px" }} src={image1} alt="" /></div>
                            </div>
                            <div style={{ width: "100%" }}>
                                {summaryData?.length != 0
                                    ?
                                    <OverallStatusCard numberOfConversation={summaryData ? summaryData?.totalCount : 0} typeOfConversation='Total conversations' lastWeekConversation={summaryData?.lastweekTotalCount} />
                                    :
                                    <OverallStatusCard numberOfConversation='0' typeOfConversation='Total conversations' lastWeekConversation='' />
                                }
                            </div>
                        </div>
                        <div className="adminStatusCard-rows" style={{ width: "100%" }}>
                            <div>
                                <div><img style={{ width: "50px", height: "50px", marginRight: "20px" }} src={image2} alt="" /></div>
                            </div>
                            <div style={{ width: "100%" }}>
                                {summaryData?.length != 0
                                    ?
                                    <OverallStatusCard numberOfConversation={summaryData ? summaryData?.closeCount : 0} typeOfConversation='Closed conversations' lastWeekConversation={summaryData?.lastWeekCloseCount} />
                                    :
                                    <OverallStatusCard numberOfConversation='0' typeOfConversation='Closed conversations' lastWeekConversation='' />
                                }
                            </div>
                        </div>
                        <div className="adminStatusCard-rows" style={{ width: "100%" }}>
                            <div>
                                <div><img style={{ width: "50px", height: "50px", marginRight: "20px" }} src={image3} alt="" /></div>
                            </div>
                            <div style={{ width: "100%" }}>
                                {summaryData?.length != 0
                                    ?
                                    <OverallStatusCard numberOfConversation={summaryData ? summaryData?.openCount : 0} typeOfConversation='Open conversations' lastWeekConversation={summaryData?.lastWeekOpenCount} />
                                    :
                                    <OverallStatusCard numberOfConversation='0' typeOfConversation='Open conversations' lastWeekConversation='' />
                                }
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 15 }}>
                    <div style={{ width: "100%" }}>
                        <AdminSummary togglePageLoader={(bool) => togglePageLoader(bool)} />
                    </div>
                </Col>
            </Row>

            <div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: '98%' }} className="m-t-20 m-l-10">
                    {summaryData?.details?.length > 0
                        ?
                        <SubHeading className="careUserCard-heading" style={{ fontSize: "24px", }}>Latest conversations</SubHeading>
                        :
                        null
                    }
                    {summaryData?.details?.length > 0
                        ?
                        <Button type="link" className="userCards_viewAll" onClick={() => handleViewConversationClick()} style={{ color: "#1089FF", marginRight: 20, fontSize: 12 }}>VIEW ALL</Button>
                        :
                        null
                    }

                </div>
                <div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>

                        {summaryData?.details?.map((item) =>
                            <div key={item.id} onClick={() => handleViewChatlist(item?.chat_userId, item?.chat_createdAt)}>
                                <UserProfileCards imgUrl={`${IMG_URL}/${item.user_image}`} userName={item.user_name} role={item.user_role} message={item.message} createdAt={item.chat_createdAt} type={item.chat_type} chat={false} unreadCount={item.unreadMessage} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

// export default SupportAdmin

const mapStateToProps = state => {
    const {
        recentUserList,
        summaryData,
        totalProblemsWithCount,
        startDate,
        endDate,
        isMonthOrWeek,


    } = state.adminCare;
    return {
        recentUserList,
        summaryData,
        totalProblemsWithCount,
        startDate,
        endDate,
        isMonthOrWeek,
    };
};

const mapDispatchToProps = dispatch => ({

    getOverallStatusFunction: (callback) => dispatch(getOverallStatusFunction(callback)),
    setSpecificStudentUseId: (m) => dispatch(setSpecificStudentUseId(m)),
    setDate: (m) => dispatch(setDate(m)),
    setAulasCareChat: (m) => dispatch(setAulasCareChat(m))

});


export default connect(mapStateToProps, mapDispatchToProps)(SupportAdmin);
