import { Api, Notification } from "services";

export const getCategoryList = (callback = null) => {
    return (dispatch, getState) => {
        return Api.get(`/course/category-name-list`).params().send((response) => {
            if (callback && typeof callback === 'function') {
                callback(response);
            }
        })
    }
}
export const getResourceList = (params, callback) => {
    return (dispatch, getState) => {
        return Api.get(`/course/resources-list`).params(params).send((response) => {
            if (callback && typeof callback === 'function') {
                callback(response);
            }
        })
    }
}