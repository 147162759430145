import React from 'react';
import {connect} from 'react-redux';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import {   AiOutlineArrowRight} from "react-icons/ai";
import { MdKeyboardBackspace, MdMoreVert } from "react-icons/md";
import { BsFilterLeft } from "react-icons/bs";
import { Column } from '@ant-design/charts';
import {Menu, Dropdown, DatePicker, Tooltip} from 'antd';
import BarGraph from "./localComponent/barGraph";
import moment from 'moment';
import './styles.css';
import { useHistory } from 'react-router-dom';
import { fetchClassSubjects,
         setSelectedSubject,
         createTestModal,
         unsetCreateTestModalBool,
         updateSelectedTestId,
         updateSelectedTest,
         updateListViewType,
         showGenerateQuesListView,
         updateSubmittedTestsId,
         fetchTestSubmitDetails,
         updateSelectedCardType,
         universalLoader,
         getTestClassList,
         changeTestStatusModal,
         setCModalType,
         setIsCModalVisible,
         storeCreateTestModalData,
         setOpenTab,
         updateFilter,
         setLoader,
         fetchBarChartData,
         setSubjectSectionAction,
         setSelectedClassroom,
         updateRole
         } from 'actions/testAdmin';
import { useEffect, useState } from 'react';
import PageDataLoader from 'components/pageDataLoader';
import { NodataCard } from './index';
import CreateTestModal from './modals/createTestModal';
import { SubIcon } from 'components/subIcon';
import TestPublishModal from './localComponent/testPublishModal';
import ConfirmationModal from './modals/confirmationModal';
import CustomMenu from './localComponent/customMenu';
import { HistoryOutlined, PlusCircleOutlined } from '@ant-design/icons';
import IconAndVal from './localComponent/iconAndVal';
import Loader from './localComponent/loader';
import TestCard from './localComponent/testCards';
import { Heading } from 'components/Typography';
import PageHeader from 'components/pageHeader';
import {Button} from 'components/Button'
import CreateTestType from './modals/createTestType';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setFromClassroom } from 'actions/classRoom';

const { RangePicker } = DatePicker;

const chapterTestIcon = require('Assets/test/ic-chapter-test.svg').default;
const fullTestIcon = require('Assets/test/ic-full-test.svg').default;
const partTestIcon = require('Assets/test/ic-part-test.svg').default;
const bookRed = require("../../Assets/test/bookRed.svg").default;


const SyllabusCard = ({ testType, objectiveVal,subjectiveVal, onClick,setSubjectSectionAction}) => {
  const history = useHistory();
    let iconUrl = testType==='Chapter Test'?chapterTestIcon:testType==='Part Test'?partTestIcon:testType==='Full Test'?fullTestIcon:'';
    let testDescp = testType==='Chapter Test'?'To provide a formative assessment of chapter-wise expertise'
                      :testType==='Part Test'?'To provide a formative assessment of a group of chapters':testType==='Full Test'?'To simulate an actual exam with full syllabus':'';
    useEffect(()=>{
      setSubjectSectionAction([])
   },[]) 
     return (
    <>
      <div
        className="cursor-pointer shadow-box hover-shadow customCardShadow p-20 m-l-20 m-b-20" style={{height: '200px', width: "250px", borderRadius: "5px"}}
        onClick={onClick(testType)}
      >
        <div>
          <div className="r-c-c-c m-t-10">
            <img
              style={{
                width: "60px",
                height: "60px",
              }}
              src={iconUrl}
              alt=""
            />
          </div>
          <h1 className="bold-700" style={{color: "#191919",textAlign: "center",fontSize: "14px",paddingTop: "5px",marginBottom: "0px"}}>
            {testType}
          </h1>
          <div className="bold-600 p-l-5 p-r-5"
            style={{ color: "#8C8C8C", textAlign: "center", fontSize: "10px" }}>
            {testDescp ? `${testDescp}` : " "}
          </div>
        </div>
        <div
          style={{display: "flex", justifyContent: "space-around", padding: "10px 10px 10px 10px"}}
        > 
            <IconAndVal val={subjectiveVal} refer={0}/> 
            <IconAndVal val={objectiveVal} refer={1}/>
        </div>
      </div>
    </>
  );
};


  const CardOfAll = ({cardCheck, test, handleResultCardClick, duration, updateSubmittedTestsId, handleButtonClick, 
                    isCModalVisible, editDetailsLoader, cloneLoader, userType, createTestModalBool}) => {
    return(
        <div>
          {
            cardCheck==='draft'||cardCheck==='upcoming'||cardCheck==='adminlive'||cardCheck==='feedback'
            ?
            <div className="absolute" style={{width:"10%", right: 0, top: 0}} onClick={()=>{}}>
            <Dropdown  
                       overlay={<CustomMenu type={cardCheck} test={test} handleButtonClick={handleButtonClick}
                       isCModalVisible={isCModalVisible} createTestModalBool={createTestModalBool}
                       userType={userType} editDetailsLoader={editDetailsLoader} 
                       cloneLoader={cloneLoader}/>}
                       placement="bottomRight" arrow onClick={(e)=>{e.stopPropagation();}}>
                <MdMoreVert className='text-lg a2grey cursor-pointer absolute' style={{ top: "14px", height:25}}/>
            </Dropdown>
            </div>
            :
            null
          }

          <div className='r-c-sb m-b-5'>
            <div className='text-xmd bold-600 color-black text-overflow flex-1'>{test?.title}</div>
          </div>
          <div className='display-flex flex-wrap text-xs color-black'>
                {test?.subjectNames?.split(',').join(' • ')}
          </div>
          <div className='r-c-sb'>
            <div className='r-ac'>
              <div className='text-xs a2grey'>{test?.totalQuestions!=null?test?.totalQuestions:test?.testQuestion?.length} Qs</div>
              <div className='p-2 radius-100 m-l-10 m-r-10' style={{background: '#a2a2a2'}}></div>
              <div className='text-xs a2grey'>{ cardCheck=='draft'?duration:Math.round(test?.duration/60)} mins</div>
              <div className='p-2 radius-100 m-l-10 m-r-10' style={{background: '#a2a2a2'}}></div>
              <div className='text-xs a2grey'>{test?.totalPoints?test.totalPoints:test?.totalMarks} marks</div>
            </div>
            {cardCheck === 'adminlive'
            ?
              <img src={require('Assets/live-dot.gif').default} width={40} height={20} alt="" />
            :
              cardCheck === 'result'
              ?
                <div className='r-ac cursor-pointer'>
                  <div className='text-xs bold-800 blue' onClick={()=>{
                    updateSubmittedTestsId(test.id)
                    handleResultCardClick(test, "view-result")
                    }}>VIEW RESULT</div>
                  <AiOutlineArrowRight className='text-xs blue m-l-5'/>
                </div>
              :
                cardCheck === 'cancel'
                ?
                  <div className='text-xs bold-800 a2grey'>CANCELLED</div>
                :
                  cardCheck === 'publishResult'
                  ?
                    <Button type='primary' className='purpleBtnWrap radius-100 text-xs purpleButtonHoverEffect'>PUBLISH RESULT</Button>
                  :
                  cardCheck=='draft'
                  ?
                  <div className='text-xs bold-800 a2grey'>DRAFT</div>
                  :
                  null
            }
          </div>
        </div>
    )
  }


  const SubmitCard = ({test,
                       togglePageLoader,
                       history,
                       updateSubmittedTestsId,
                       fetchTestSubmitDetails,
                       totalStudents}) => {
    return(
      <div className='shadow-box p-10 r-c-sb cursor-pointer'
            onClick={() => {
              togglePageLoader(true)
              updateSubmittedTestsId(test.id)
              fetchTestSubmitDetails((bool)=>{togglePageLoader(bool)
              history.push('test-submit-detail-screen')});
            }}>
        <div className='flex-1' style={{width:'calc(100% - 74px)'}}>
          <CardOfAll cardCheck={'submit'} test={test} updateSubmittedTestsId={updateSubmittedTestsId}/>
        </div>
        <div className='r-c-c-c'>
          <div className='text-xs bold-800 color-black'>{test?.submitted}/{totalStudents}</div>
          <div className='text-xs color-black'>Submissions</div>
        </div>
      </div>
    )
  }

  const ResultCard = ({test, handleResultCardClick, updateSubmittedTestsId}) => {
    return(
      <div className='shadow-box p-10'>
        <CardOfAll cardCheck={'result'} test={test} handleResultCardClick={handleResultCardClick} updateSubmittedTestsId={updateSubmittedTestsId}/>
      </div>
    )
  }

  const PublishResultCard = ({test}) => {
    return(
      <div className='shadow-box p-10'>
        <CardOfAll cardCheck={'publishResult'} test={test}/>
      </div>
    )
  }

  const PublishedCard = ({test, handlePublishedCardClick, handleButtonClick, createTestModalBool, editDetailsLoader, cloneLoader, userType, isCModalVisible}) => {
    let [testDate, month, startTime] = [null, null, null]
    if (test.testDate!=null){
       [testDate, month] = test.testDate?.split(',')
    }
    if (test.startTime!=null){
       startTime = test.startTime
    }
    return(
      <div className='shadow-box r-ac p-10 cursor-pointer relative' onClick={()=>handlePublishedCardClick(test)} style={{borderRadius:5}}>
        <div className='p-10 r-c-c-c radius-5 m-r-10' style={{background:'#F6F4FE', minWidth:65, height:60}}>
          <div className='text-xs bold-800 color-purple'>{month} {testDate}</div>
          <div className='text-xs bold-800 color-purple'>{startTime}</div>
        </div>
        <div className='flex-1' style={{width:'calc(100% - 74px)'}}>
          <CardOfAll cardCheck={'upcoming'} test={test} handleButtonClick={handleButtonClick}
          createTestModalBool={createTestModalBool} editDetailsLoader={editDetailsLoader} 
          cloneLoader={cloneLoader} userType={userType} isCModalVisible={isCModalVisible}/>
        </div>
      </div>
    )
  }

  const DraftCard = ({test, handlePublishedCardClick, handleButtonClick, createTestModalBool, 
                      editDetailsLoader, cloneLoader, userType, isCModalVisible}) => {
    let testDate = null;
    // let startTime = null;
    if (test.testDate!=null){
        testDate  = moment.utc(test.testDate).local().format('MMM DD').toString();
    }
    // if (test.startTime!=null){
    //     startTime = moment(test.startTime, 'hh:mm:ss').format('hh:mm A')
    // }
    return(
      <div className='shadow-box r-ac p-10 cursor-pointer relative' onClick={()=>handlePublishedCardClick(test)}>
        <div className='p-10 r-c-c-c radius-5 m-r-10' style={{background:'#F6F4FE', minWidth:65, height:60}}>
          <div className='text-xs bold-800 color-purple'>{testDate}</div>
          <div className='text-xs bold-800 color-purple'>{test.startTime}</div>
        </div>
        <div className='flex-1' style={{width:'calc(100% - 74px)'}}>
          <CardOfAll cardCheck={'draft'} test={test} duration={Math.round(test.duration/60)} handleButtonClick={handleButtonClick}
          createTestModalBool={createTestModalBool} editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader} userType={userType} 
          isCModalVisible={isCModalVisible}/>
        </div>
      </div>
    )
  }

  const CancelCard = ({test, handlePublishedCardClick}) => {
    let [testDate, month] = test.testDate?.split(',')
    // let startTime = moment(test.startTime, 'hh:mm:ss').format('hh:mm A')
    return(
      <div className='shadow-box r-ac p-10 cursor-pointer' onClick={()=>{handlePublishedCardClick(test)}}>
        <div className='p-10 r-c-c-c radius-5 m-r-10' style={{background:'#F6F4FE', minWidth:65, height:60}}>
          <div className='text-xs bold-800 color-purple'>{month} {testDate}</div>
          <div className='text-xs bold-800 color-purple'>{test.startTime}</div>
        </div>
        <div className='flex-1' style={{width:'calc(100% - 74px)'}}>
          <CardOfAll cardCheck={'cancel'} test={test}/>
        </div>
      </div>
    )
  }




function StdToSubjectsScreen ({selectedClassroom,
                              selectedClassroomId,
                              fetchClassSubjects,
                              subjectsByClass,
                              setSelectedSubject,
                              standardWiseBarChartData,
                              todayActivities,
                              isBarChartEmpty,
                              createTestModal, updateSelectedTest,
                              updateListViewType, showGenerateQuesListView,
                              updateSubmittedTestsId,
                              fetchTestSubmitDetails, userType, user, 
                              updateSelectedCardType, isCModalVisible, 
                              createTestModalBool, editDetailsLoader, cloneLoader,
                              generateQuesListViewBool, universalLoader, getTestClassList,
                              cModalType, changeTestStatusModal, setCModalType, setIsCModalVisible,
                              storeCreateTestModalData, updateSelectedTestId, setOpenTab, syllabi,
                              updateFilter, loaders,
                              setLoader, fetchBarChartData, filter, reloadToggler,setSubjectSectionAction, selectedSection, setFromClassroom,
                              setSelectedClassroom,
                              updateRole
                            }) {
    const history = useHistory();
    const [selectedFilter, setSelectedFilter] = useState('Week');
    const [isDropdownVisible, setDropdownVisibility] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);
    const [testModalOpen, setTestModalOpen] = useState(false);
    const location = useLocation();

    const showModal = () => {
      setTestModalOpen(true);
    };
  
    const closeModal = () => {
      setTestModalOpen(false);
    }
    const handleButtonClick = (id, type, test, e)=>{
      // setSelectedId(id);
      e.stopPropagation();
      updateSelectedTestId(id);
      if (type=="Publish"){
          setCModalType("Publish");
          setIsCModalVisible(true);
      }
      else if (type=="PublishResult"){
          setCModalType("PublishResult");
          setIsCModalVisible(true);
      }
      else if (type=="Review"){
          updateSelectedTest(test);
          changeTestStatusModal("SendReview");
      }
      else if (type=="Delete"){
          setCModalType("Delete");
          setIsCModalVisible(true);
      }
      else if (type=="Cancel"){
          setCModalType("Cancel");
          setIsCModalVisible(true);
      }
      else if (type=="Unpublish"){
          setCModalType("Unpublish");
          setIsCModalVisible(true);
      }
      else if (type=="MarkReady"){
          changeTestStatusModal("MarkReady");
          // setCModalType("")
          // setIsCModalVisible(true)
      }
      else if (type=="Edit"){
          if (generateQuesListViewBool){
              return
          }
          universalLoader('EDIT_DETAILS_LOADER', 'editDetailsLoader', true);
          updateListViewType('edit');
          updateSelectedTest(test);
          showGenerateQuesListView(()=>{
              universalLoader('EDIT_DETAILS_LOADER', 'editDetailsLoader', false);
              createTestModal("test-edit", user, 'dropdown');
          }, "tabview");
      }
      else if (type=="Clone"){
          if (generateQuesListViewBool){
              return
          }
          universalLoader('CLONE_LOADER', 'cloneLoader', true);
          updateListViewType('clone');
          updateSelectedTest(test);
          showGenerateQuesListView((classIds)=>{
              getTestClassList(classIds);
              universalLoader('CLONE_LOADER', 'cloneLoader', false);
              createTestModal("test-clone", user, 'dropdown');
              if(location?.state?.key){
                history.push({pathname: "/edit-test", state:{key:"fromClassroom"}});
              }else{
                history.push("/edit-test");
              }
              storeCreateTestModalData('tIndex', '');
          }, "tabview");
      }
  }
    const handleLiveClick = (test)=>{
      updateListViewType('no-edit');
      updateSelectedCardType('adminlive');
      updateSelectedTest(test);
      togglePageLoader(true);
      showGenerateQuesListView(()=>{
          history.push('admin-test-view');
          togglePageLoader(false);
      },'tabview');
    }
    const handlePublishedCardClick = (test)=>{
      updateListViewType('edit');
      updateSelectedCardType('');
      updateSelectedTest(test);
      togglePageLoader(true);
      showGenerateQuesListView(()=>{
        if ( (user?.role?.includes("Data Operator") || user?.role?.includes("Teacher"))) {
          const currentTime = moment();
            const startTime = moment(test?.startTime, 'hh:mm a');
            const timeDifference = startTime.diff(currentTime, 'minutes')
            if (timeDifference <= 29) {
              history.push('/admin-test-view');
              togglePageLoader(false);

            } else {
              history.push('/admin-test');
              togglePageLoader(false);

            }
          }
      },'tabview');
    }
    const handleCreateClick = () =>{
      showModal(); 
      updateListViewType('create');
      createTestModal('test-create', user, 'class');
    }
    const handleResultCardClick = (test, type) => {
      updateSelectedTest(test);
      if (type=="dash_scheduled"){
        history.push('admin-test')
      }
      else{
      history.push('test-publish-result-screen')}
    }
    const getData = async () => {
     fetchBarChartData('classroom', ()=>{});

      await fetchClassSubjects(location?.state?.key == "fromClassroom" ? selectedSection?.id : selectedClassroom?.id, (bool) => togglePageLoader(bool));
    };

    useEffect(()=>{
      setFromClassroom(false)
      if(location?.state?.key){
        setFromClassroom(true)
      }
      else if(location?.state?.syllabus)
      {
        let data = location?.state?.syllabus
        setSelectedClassroom({
          classname: data?.className,
          section: data?.section,
          id: data?.std_id
        })
      }
      updateRole()
    },[])

    useEffect(() => {
      setLoader('syllabusPageLoader', true);
      updateFilter('selectedGraphFilter', 'week');
      updateFilter('graphStartDate', moment().startOf("week").format("YYYY-MM-DD"));
      updateFilter('graphEndDate', moment().startOf("week").add(6, "days").format("YYYY-MM-DD")),
      updateFilter('graphYear', moment().format('YYYY'));
      getData();
    }, [reloadToggler]);

    useEffect(()=>{
      fetchBarChartData('classroom', (bool)=>{bool});
  }, [filter?.graphStartDate, filter?.graphEndDate, filter?.graphYear])

    const onDateBackward = () => {
      if (selectedFilter==="Week"){
        updateFilter('graphStartDate', moment(filter?.graphStartDate, "YYYY-MM-DD").subtract(7, 'days').format('YYYY-MM-DD'));
        updateFilter('graphEndDate', moment(filter?.graphEndDate, "YYYY-MM-DD").subtract(7, 'days').format('YYYY-MM-DD'))
      }else if(selectedFilter==='Months'){
        updateFilter('graphYear', ""+(parseInt(filter?.graphYear)-1))
      }
    }
  
    const onDateForward = () => {
      if (selectedFilter==="Week"){
        updateFilter('graphStartDate', moment(filter?.graphStartDate, "YYYY-MM-DD").add(7, 'days').format('YYYY-MM-DD'));
        updateFilter('graphEndDate', moment(filter?.graphEndDate, "YYYY-MM-DD").add(7, 'days').format('YYYY-MM-DD'))
      }else if(selectedFilter==='Months'){
        updateFilter('graphYear', ""+(parseInt(filter?.graphYear)+1))
      }
    }

    const handleFilterSelect = (key, filterText)=>{
      updateFilter('selectedGraphFilter', key);
      setSelectedFilter(filterText);
      fetchBarChartData('classroom', ()=>{});
    };
  const goToSubjects = (syllabusType) => {
    return () => {
      updateFilter('subjectSearch', null);
      updateFilter('selectedSyllabus', syllabusType)
      if (location?.state?.key) {
        history.push({ pathname: 'syllabus-subjects', state: { key: "fromClassroom" } });
      }else{
        history.push('syllabus-subjects');
      }
    }
  }

    const actions= [
      <Button type='primary' onClick={()=>{handleCreateClick()}} icon={<PlusCircleOutlined/>}>CREATE TEST</Button>
    ]

    const handleBackPress = () => {
      if(location?.state?.key == "fromClassroom"){
        history.push("/classroom/classroom-details")
      }else{
        history.goBack();
      }
    }

    var config = {
      data: standardWiseBarChartData,
      height:250,
      autoFit:false,
      columnWidthRatio:.3,
      isGroup: true,
      xField: 'date',
      yField: 'totalCount',
      seriesField: 'name',
      columnStyle: {
         radius: [5, 5, 0, 0],
       },
      color: ['#9464FA', '#AEAEAE', '#589EFF'],
      legend: false
    };
    let cardscount = 0;
    return (
      <div style={{height:'99vh', margin:'-20px', backgroundColor:"#FAFAFA", overflowY:"hidden"}}
        onClick={() => {
          setDropdownVisibility(false);
      }}>
        <div style={{marginLeft: 20}}>
          <PageHeader title={location?.state?.key == "fromClassroom" ? `${selectedSection?.std}-${selectedSection?.section}`: `${selectedClassroom?.classname}-${selectedClassroom?.section}`} actions={actions} onBackPress={()=>handleBackPress()}/>
        </div>

        <div className='display-flex full-height' style={{marginTop: 50}}>
          <div className='flex-1 p-20' style={{overflowY:"auto"}}>
            {
              userType==='admin'||userType==='teacher'?
              <div style={{height:375}}>
                <BarGraph barchartData={standardWiseBarChartData} userType={userType} isDropdownVisible={isDropdownVisible} setDropdownVisibility={setDropdownVisibility}
                    selectedFilter={selectedFilter} handleFilterSelect={handleFilterSelect} isBarChartEmpty={isBarChartEmpty} 
                    loading={loaders?.graphLoader} starDate={filter?.graphStartDate} endDate={filter?.graphEndDate} selectedYear={filter?.graphYear}
                    onDateBackward={onDateBackward} onDateForward={onDateForward}/>
              </div>
            :null
            }
              <Heading className='m-t-30'>Syllabus</Heading>
              {loaders?.syllabusPageLoader&&false?
              <Loader style={{fontSize:30}}/>
              :
              <>
              {syllabi && syllabi?.length>0?
              <>
                <div className='display-flex flex-wrap' style={{paddingBottom:60}}>
                  {syllabi?.map((item, index) => (
                    <SyllabusCard key={index} testType={item.syllabus} objectiveVal={item.objectiveCount} subjectiveVal={item.subjectiveCount} onClick={goToSubjects}
                    setSubjectSectionAction={setSubjectSectionAction}
                    />
                    )
                  )}
                </div>
                </>
              :null
              }
              </>
              }
          </div>
          {
            ((userType=="admin")||(userType=="teacher"))
            ?
            <>
               <div className='vertical-divider'></div>
          <div className='position-relative'>
            <div className='text-sm m-l-20 m-t-20 color-black bold-600' >Today's activities</div>
            <div style={{width:400, height:'calc(100% - 26px)', overflowY:"auto", overflowX:"hidden"}}>
              <div className='p-20' style={{paddingBottom:60}}>
                {todayActivities&&Object.keys(todayActivities)?.map((key)=>
                (
                  key=="onGoingLiveTest"
                  ?
                  (todayActivities[key] &&todayActivities[key]?.length>0)
                  ?
                  <>
                  {todayActivities[key]?.map((test, index)=>
                    <>
                    <span style={{display:"none"}}> {cardscount+=1} </span>
                    <TestCard
                      type={"adminlive"}
                      test={test}
                      key={index}
                      index={index}
                      fromTodayActivities={true}
                    />
                    <div className='m-b-20'></div>
                    </>
                  )}
                  </>
                    :null
                  :
                    key=="publishedResults"
                    ?
                    (todayActivities[key] &&todayActivities[key]?.length>0)
                    ?
                    <>
                    {todayActivities[key]?.map((test, index)=>
                    <>
                      <span style={{display:"none"}}> {cardscount+=1} </span>
                      <TestCard type='published' test={test} index={index} ispublished={true} handleResultCardClick={handleResultCardClick}/>
                      <div className='m-b-20'></div>
                      </>
                    )}
                    </>
                      :null
                    :
                      key=="cancelled"
                      ?
                      (todayActivities[key] &&todayActivities[key]?.length>0)
                      ?
                      <>
                      {todayActivities[key]?.map((test)=>
                      <>
                        <span style={{display:"none"}}> {cardscount+=1} </span>
                        <CancelCard test={test} handlePublishedCardClick={handlePublishedCardClick}/>
                        <div className='m-b-20'></div>
                        </>
                      )}
                      </>
                        :null
                      :
                      key=="submitted"
                      ?
                      (todayActivities[key] &&todayActivities[key]?.length>0)
                      ?
                      <>
                      {todayActivities[key]?.map((test, index)=>
                      <>
                          <span style={{display:"none"}}> {cardscount+=1} </span>
                          <TestCard type={"submitted_today"} test={test} key={index} index={index} fromTodayActivities={true}/>

                          <div className='m-b-20'></div>
                        </>
                      )}
                      </>
                        :null
                      :
                        key=="results"
                        ?
                        (todayActivities[key] &&todayActivities[key]?.length>0)
                        ?
                        <>
                        {todayActivities[key]?.map((test)=>
                        <>
                            <span style={{display:"none"}}> {cardscount+=1} </span>
                            <ResultCard test={test} handleResultCardClick={handleResultCardClick} updateSubmittedTestsId={updateSubmittedTestsId}/>
                            <div className='m-b-20'></div>
                          </>
                        )}
                        </>
                          :null
                        :
                          key=="resultsToPublish"
                          ?
                            (todayActivities[key] &&todayActivities[key]?.length>0)
                            ?
                            <>
                            {todayActivities[key]?.map((test, index)=>
                            <>
                              <span style={{display:"none"}}> {cardscount+=1} </span>
                              <TestCard type='published' test={test} index={index} ispublished={false} handleResultCardClick={handleResultCardClick}/>
                              <div className='m-b-20'></div>
                            </>
                            )}
                            </>
                            :null
                          :
                            key=="upcomingLiveTest"
                            ?
                              (todayActivities[key] &&todayActivities[key]?.length>0)
                              ?
                              // todayActivities[key]['id']&&
                              <>
                              {todayActivities[key]?.map((test)=>
                              <>
                                <span style={{display:"none"}}> {cardscount+=1} </span>
                                <PublishedCard test={test} handlePublishedCardClick={handlePublishedCardClick} handleButtonClick={handleButtonClick} 
                                isCModalVisible={isCModalVisible} editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader} createTestModalBool={createTestModalBool}
                                userType={userType}/>
                                <div className='m-b-20'></div>
                              </>
                              )
                              }
                              </>
                              :null
                            :
                              key=="draftTests"
                              ?
                              <>
                                {(todayActivities[key] &&todayActivities[key]?.length>0)
                                  ?
                                  <>
                                    
                                    {todayActivities[key]?.map((test)=>
                                        <>
                                          <span style={{display:"none"}}> {cardscount+=1} </span>
                                          <DraftCard test={test} handlePublishedCardClick={handlePublishedCardClick} handleButtonClick={handleButtonClick} 
                                                      isCModalVisible={isCModalVisible} editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader} createTestModalBool={createTestModalBool}
                                                      userType={userType}/>
                                          <div className='m-b-20'></div>
                                        </>
                                    )
                                    }
                                  </>
                              :
                                null
                                  }
                            </>
                            :
                            null
                ))}
                {
                  cardscount==0
                  ?
                  <NodataCard imgheight="50%" imgwidth="50%"/>
                  :null
                }
              </div>
            </div>
          </div>
            </>
            :
            null
          }
        {/* {createTestModalBool && <CreateTestModal history={history} />} */}
      {testModalOpen && <CreateTestType isModalOpen={testModalOpen} closeModal={closeModal} fromClassroom = {location?.state?.key}/>}
        <TestPublishModal history={history}/>
        <ConfirmationModal isCModalVisible={isCModalVisible}  cModalType={cModalType}/>
        <PageDataLoader visible={loaders?.syllabusPageLoader}/>
      </div>
    </div>
  );
}


const mapStateToProps = state => {
  const {selectedClassroom, selectedClassroomId, subjectsByClass,
    standardWiseBarChartData, todayActivities, isBarChartEmpty, userType, isCModalVisible, 
    createTestModalBool, editDetailsLoader, cloneLoader, generateQuesListViewBool, cModalType, syllabi, loaders, filter, reloadToggler} = state.testAdmin;
    const {user} = state.session;
    const {selectedSection} = state.classRoom
  return {selectedClassroom, selectedClassroomId, subjectsByClass,
    standardWiseBarChartData, todayActivities, isBarChartEmpty, userType, user, isCModalVisible, 
    createTestModalBool, editDetailsLoader, cloneLoader, generateQuesListViewBool, cModalType, syllabi, loaders, filter,reloadToggler, selectedSection};
};

const mapDispatchToProps = dispatch => ({
  fetchClassSubjects: (selectedClassId, loaderCallback) => dispatch(fetchClassSubjects(selectedClassId, loaderCallback)),
  setSelectedSubject: (selectedSubject, callback) => dispatch(setSelectedSubject(selectedSubject, callback)),
  createTestModal: (name, user, source) => dispatch(createTestModal(name, user, source)),
  unsetCreateTestModalBool: ()=>dispatch(unsetCreateTestModalBool()),
  updateSelectedTest: (test)=>dispatch(updateSelectedTest(test)),
  updateListViewType: (data)=>dispatch(updateListViewType(data)),
  showGenerateQuesListView: (callback, type)=> dispatch(showGenerateQuesListView(callback, type)),
  fetchTestSubmitDetails: (callback)=>dispatch(fetchTestSubmitDetails(callback)),
  updateSubmittedTestsId: value => dispatch(updateSubmittedTestsId(value)),
  updateSelectedCardType: (type)=>dispatch(updateSelectedCardType(type)),
  universalLoader: (name, varname, bool)=>dispatch(universalLoader(name, varname, bool)),
  getTestClassList: (classIds) => dispatch(getTestClassList(classIds)),
  changeTestStatusModal: (status)=>dispatch(changeTestStatusModal(status)),
  setCModalType: (type)=>dispatch(setCModalType(type)),
  setIsCModalVisible: (bool)=>dispatch(setIsCModalVisible(bool)),
  storeCreateTestModalData: (key, value) => dispatch(storeCreateTestModalData(key, value)),
  updateSelectedTestId: (id) => dispatch(updateSelectedTestId(id)),
  setOpenTab: (name) => dispatch(setOpenTab(name)),
  updateFilter: (key, val) => dispatch(updateFilter(key, val)),
  setLoader: (key, val) => dispatch(setLoader(key, val)),
  fetchBarChartData: (type, callback) => dispatch(fetchBarChartData(type, callback)),
  setSubjectSectionAction:(val) => dispatch(setSubjectSectionAction(val)),
  setFromClassroom:(val) => dispatch(setFromClassroom(val)),
  setSelectedClassroom: (selectedClass) => dispatch(setSelectedClassroom(selectedClass)),
  updateRole: () => dispatch(updateRole()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StdToSubjectsScreen);
