import { DeleteFilled, EditFilled, EditOutlined, ExclamationCircleOutlined, EyeFilled, LoadingOutlined, PlusOutlined, SettingFilled, UploadOutlined } from '@ant-design/icons';
import { Col, Divider, Input, Modal, Typography, Pagination, Row, Select, Skeleton, Tabs, Tag, Tooltip, Empty } from 'antd';
import moment from 'moment';
import React, {useEffect} from 'react'
import { Color } from 'services';
import './styles.css';
import ReactPlayer from 'react-player';
import {connect} from 'react-redux';
import {changeLibraryTab, updateLibFilterParams, togglePdfForm, toggleVideoForm, toggleVideoPlayer, clearLibFilterParams,
    fetchContent, setCurrentContentParams, updateContentStatus} from 'actions/digitalLibrary_old';
import { fetchClasslist } from 'actions/calendar';
import PdfForm from './modals/pdfForm';
import VideoForm from './modals/videoForm';
import VideoPlayer from './modals/videoPlayer';
import { useHistory } from 'react-router';
import { Button } from 'components/Button';
import ReactPlayerHoc from 'components/ReactPlayerHoc';


const {TabPane} = Tabs;
const {Option} = Select;
const {confirm} = Modal;
const { Title } = Typography;


const headerActions = (onUpdateSearch, libFilterParams,access, openForm, fetchContentLoader, standardList, subjectList, handleFilterSelect, fetchContent, role, chapterList) => (
    <Row style={{marginLeft: 30}}>
        <Col span={6}>
            <div >
                <Select
                    placeholder="Select Standard"
                    style={{width: 200, justifySelf: "flex-end"}}
                    value={libFilterParams?.standardId}
                    disabled={fetchContentLoader}
                    onSelect={val => handleFilterSelect(val, 'standard')}
                    allowClear
                    onClear={() => {handleFilterSelect(null, 'standard'); handleFilterSelect(null, 'chapterName')}}
                >
                    {standardList &&
                    standardList.map((standard, index) =>
                    (<Option key={index.toString()} value={standard.id}>{standard.std} - {standard.section} </Option>)
                    )}
                </Select>
            </div>
        </Col>
        <Col span={6}>
            <div >
                <Select
                    placeholder="Select Subject"
                    disabled={fetchContentLoader}
                    style={{width: 200}}
                    value={libFilterParams?.subjectId}
                    onSelect={val => handleFilterSelect(val, 'subject') }
                    onClear={() => {handleFilterSelect(null, 'subject'); handleFilterSelect(null, 'chapterName')}}
                    allowClear
                >
                     {subjectList &&
                        subjectList.map((subject, index) =>
                        (<Option key={index.toString()} value={subject.id}>{subject.name}</Option>)
                    )}
                </Select>
            </div>
        </Col>
        <Col span={6}>
            <div >
                <Select
                    placeholder="Select Chapter"
                    disabled={!libFilterParams?.subjectId || !libFilterParams?.standardId || fetchContentLoader}
                    style={{width: 200}}
                    value={libFilterParams?.chapterName}
                    onSelect={val => handleFilterSelect(val, 'chapterName') }
                    onClear={() => handleFilterSelect(null, 'chapterName')}
                    allowClear
                >
                     {chapterList &&
                        chapterList.map((chapter, index) =>
                        (<Option key={index.toString()} value={chapter.name}>{chapter.name}</Option>)
                    )}
                </Select>
                {/* <Input.Search placeholder="Search by chapter..."
                    onChange={e => onUpdateSearch(e.target.value)}
                    value={libFilterParams.search}
                    disabled={fetchContentLoader}
                    style={{width: 200}}
                    onSearch={() => fetchContent()}
                    allowClear
                /> */}
            </div>
        </Col>
        {access?.includes("digital-library-teacher") && role.length === 1 ? null:
         <Col span={6}>
            <div className="r-c-fe">
                <Button type="primary"
                    onClick={() => openForm()}
                    style={{width: 100}}
                    disabled={fetchContentLoader}
                    icon={<PlusOutlined/>}
                > ADD</Button>
            </div>
        </Col>}
    </Row>
)
//

const singlePdf = (pdf, index, handleSingleContentClick, updateStatus, role, updateContentStatus) => (
    <div key={index.toString()} style={{width: "100%"}}>
        <div  style={{width: "100%"}}>
            <Row style={{alignItems: 'center'}}>
                <Col span={12}>
                    <div className="display-flex" style={{alignItems: 'center'}}>
                        <div className="r-c-fe">
                            <div className="contentIcon" style={{backgroundColor: 'green'}}>{pdf.title.charAt(0).toUpperCase()}</div>
                        </div>
                        <div>
                            <div className="subTitle">{pdf?.subject?.name}
                                <Tag color={pdf.status === "Published" ? 'green' : 'red'} style={{marginLeft: 10, fontSize: 10, fontWeight:400}}>
                                    {pdf.status}
                                </Tag>
                            </div>
                            <div className="chapName">{pdf?.chapterName}</div>
                            <div className="contentTitle">{pdf?.title}</div>
                        </div>
                    </div>
                </Col>
                <Col span={4}>
                {pdf?.standard?.map((item, index, array) =>
                        <div className="chapName" style={{width: "75%", overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: 'black'}}>
                             {item.std} - {item.section}
                        </div>
                )}
                </Col>
                <Col span={4} style={{fontSize: 12}}>
                    {moment(pdf.updatedAt).format("DD/MM/YY")} • {moment(pdf.updatedAt).format("hh:mm A")}
                </Col>
                <Col span={2} offset={2}>
                    <div className="display-flex" style={{fontSize: 20, color: Color.darkGrey}}>
                        <div>
                            <Tooltip title="Publish Resource">
                                <UploadOutlined 
                                style={{cursor: pdf.status !== "Draft" ? "not-allowed" : 'pointer', 
                                    pointerEvents: pdf.status !== "Draft" ? "none" : 'all',
                                    opacity: pdf.status !== "Draft" ? 0.5 : 1
                                 }} className="m-r-10" onClick={() => updateContentStatus("Published", pdf.id)}/>
                            </Tooltip>
                        </div>
                        <div>
                            {pdf.status === "Published" ?
                            <EyeFilled className="m-r-10 cursor-pointer"  onClick={() => handleSingleContentClick(index, 'all')}/>
                            :
                            access?.includes("digital-library-teacher") && role.length === 1 ? null:
                            <EditFilled className="m-r-10 cursor-pointer"  onClick={() => handleSingleContentClick(index, 'all')}/>}
                        </div>
                        <div>
                        {access?.includes("digital-library-teacher") && role.length === 1 ? null:
                            <DeleteFilled className="cursor-pointer" onClick={() => showConfirm(updateStatus, pdf.id)}/>}
                        </div>

                    </div>
                </Col>
            </Row>
        </div>
        <Divider />
    </div>
)


const singleVideo = (video, index, toggleVideoPlayer, handleSingleContentClick, updateStatus, role, updateContentStatus) => (
    <div key={index.toString()} style={{width: "100%"}}>
        <div  style={{width: "100%"}} >
            <Row style={{alignItems: 'center'}}>
                <Col span={12}>
                    <div className="display-flex" style={{alignItems: 'center'}}>
                        <div >
                            <div style={{padding: 10, paddingTop: 0, }} onClick={(e) => {e.stopPropagation(); toggleVideoPlayer(true, video.link)}}>
                            <ReactPlayerHoc
                                url={video.link}
                                light = {true}
                                playing={false}
                                height={"60%"}
                                width={120}
                                keyboardControls={true}
                                controls
                                style={{pointerEvents: "none"}}
                            />
                            </div>
                        </div>
                        <div>
                            <div className="subTitle">{video?.subject?.name}
                                <Tag color={video.status === "Published" ? 'green' : 'red'} style={{marginLeft: 10, fontSize: 10, fontWeight:400}}>
                                    {video.status}
                                </Tag>
                            </div>
                            <div className="chapName">{video?.chapterName}</div>
                            <div className="contentTitle">{video.title}</div>
                        </div>
                    </div>
                </Col>
                <Col span={4}>
                {video?.standard?.map((item, index, array) =>
                        <div className="chapName" style={{width: "75%", overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: 'black'}}>
                             {item.std} - {item.section}
                        </div>
                )}
                </Col>
                <Col span={4} style={{fontSize: 12}}>
                    {moment(video.updatedAt).format("DD/MM/YY")} • {moment(video.updatedAt).format("hh:mm A")}
                </Col>
                <Col span={2} offset={2} style={{color: Color.aeGrey, fontSize: 12}}>
                    <div className="display-flex" style={{fontSize: 20, color: Color.darkGrey}}>
                        <div>
                            <Tooltip title="Publish Resource">
                                <UploadOutlined 
                                style={{cursor: video.status !== "Draft" ? "not-allowed" : 'pointer', 
                                    pointerEvents: video.status !== "Draft" ? "none" : 'all',
                                    opacity: video.status !== "Draft" ? 0.5 : 1
                                 }} className="m-r-10" onClick={() => updateContentStatus("Published", video.id)}/>
                            </Tooltip>
                        </div>
                        <div>
                            {video.status === "Published" ?
                                <EyeFilled className="m-r-10 cursor-pointer"  onClick={() => handleSingleContentClick(index, 'all')}/>
                                :
                                access?.includes("digital-library-teacher") && role.length === 1 ? null:
                                <EditFilled className="m-r-10 cursor-pointer"  onClick={() => handleSingleContentClick(index, 'all')}/>
                            }
                        </div>
                        <div>
                        {access?.includes("digital-library-teacher") && role.length === 1 ? null:
                            <DeleteFilled className="cursor-pointer" onClick={() => showConfirm(updateStatus, video.id)}/>}
                        </div>

                    </div>
                </Col>
            </Row>
        </div>
        <Divider />
    </div>
)

const showConfirm = (updateStatus, id) => {
    return confirm({
      title: `Do you want to delete the content?`, /*${item.name}*/
      icon: <ExclamationCircleOutlined />,
      content: 'Please click OK to confirm',
      onOk() {updateStatus("Deleted", id)},
      onCancel() {},
    })
  }




function DigitalLibrary({
    changeLibraryTab,
    updateLibFilterParams, libFilterParams,
    togglePdfForm, showPdfForm,
    toggleVideoForm, showVideoForm,
    toggleVideoPlayer, showVideoPlayer,
    clearLibFilterParams, fetchContentLoader,
    fetchContent, standardList, subjectList,
    contentList, cotentPageMeta, recentContent,
    setCurrentContentParams, fetchClasslist,
    updateContentStatus, role, chapterList
}) {

    const history = useHistory();

    useEffect(() => {
        fetchContent()
        return () => {
            clearLibFilterParams()
        }
    }, [])

    const handleTabChange = key => {
        changeLibraryTab(key)
        fetchContent()
    }

    const openForm = () => {
        if(libFilterParams.type === "video"){
            toggleVideoForm(true)
        }else{
            togglePdfForm(true)
        }
    }


    const handleFilterSelect = (val, type) => {
        if(type === "subject"){
            updateLibFilterParams(val, "subjectId")
        }else if(type === "standard"){
            updateLibFilterParams(val, "standardId")
        }else if(type === "chapterName"){
            updateLibFilterParams(val, "chapterName")
        }
        fetchContent();
    }

    const handlePageChange = page => {
        updateLibFilterParams(page, "page");
        fetchContent();
    }

    const handleSingleContentClick = (index, type) => {
        setCurrentContentParams(index, type);
        const subId = type === "all" ? contentList[index]?.subject.id : recentContent[index]?.subject.id
        fetchClasslist(subId);
        if(libFilterParams.type === "video"){
            toggleVideoForm(true)
        }else{
            togglePdfForm(true)
        }
    }

    const onUpdateSearch = (val) => {
        if(val === ""){
            updateLibFilterParams(null, "chapterName");
            fetchContent();
        }else{
            updateLibFilterParams(val, "chapterName")
        }
    }


    return (
        <div style={{height: "calc(100% - 60px)"}}>
            <Row style={{backgroundColor:'#F9F9F9', padding: 10, borderRadius: 10}} className="m-b-20">
              <Title style={{color: Color.darkGrey, margin:0}} level={3}>Digital Library</Title>
            </Row>
            <div style={{height: "100%",  borderRight: `1px solid ${Color.midGrey}`, paddingRight: 10}}>
                <Tabs activeKey={libFilterParams?.type}
                    onChange={key => handleTabChange(key)}
                    style={{padding: 20, height:'100%'}}
                    tabBarExtraContent={
                        access?.includes("digital-library-teacher") && role.length === 1 ? null:
                        <Tooltip title="Manage Access">
                        <SettingFilled style={{fontSize: 24, color: Color.primary}} className="cursor-pointer"
                            onClick={() => history.push('digital-library/access-management')}
                        />
                        </Tooltip>
                    }
                >
                    <TabPane tab="Notes" key="lecture-notes" style={{marginTop: 5, }}>
                        {headerActions(onUpdateSearch, libFilterParams, openForm, fetchContentLoader,
                            standardList, subjectList, handleFilterSelect, fetchContent, role, chapterList)}
                        {fetchContentLoader ?
                            <>
                                <div className="r-c-c" style={{height: "70vh"}}>
                                    <LoadingOutlined style={{color: Color.primary, opacity: 0.5, fontSize: '5em'}}/>
                                </div>
                            </>
                        :
                        <div style={{height: 'calc(100vh - 360px)', overflowY: "auto", marginTop: 40, paddingLeft: 20}}>
                                <Row className="tableHeader m-b-20">
                                    <Col span={12}>
                                        Items
                                    </Col>
                                    <Col span={4}>
                                        Published To
                                    </Col>
                                    <Col span={4}>
                                        Time & Date
                                    </Col>
                                    <Col span={2} offset={2}>
                                        Actions
                                    </Col>
                                </Row>
                            {contentList  ? contentList.length ? contentList.map((pdf, index) => singlePdf(pdf, index, handleSingleContentClick, updateContentStatus, role, updateContentStatus))
                                :
                                <Empty
                                    description="No notes added"
                                />
                                : null

                            }
                        </div>}
                        <div className="r-c-fe">
                            <Pagination
                                total={cotentPageMeta?.totalItems || 0}
                                onChange={(page) => handlePageChange(page)}
                                size="small"
                                current={cotentPageMeta?.currentPage || 1}
                                pageSize={cotentPageMeta?.itemsPerPage || 25}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                showSizeChanger={false}
                            />
                        </div>

                    </TabPane>

                    <TabPane tab="Videos" key="video" style={{margin: 5}}>
                        {headerActions(onUpdateSearch, libFilterParams, openForm, fetchContentLoader,
                            standardList, subjectList, handleFilterSelect, fetchContent, role, chapterList)}
                        {fetchContentLoader ?
                            <div className="r-c-c" style={{height: "70vh"}}>
                                <LoadingOutlined style={{color: Color.primary, opacity: 0.5, fontSize: '5em'}}/>
                            </div>
                        :
                        <div style={{height: 'calc(100vh - 360px)', overflowY: "auto", marginTop: 40, paddingLeft: 20}}>
                                <Row className="tableHeader m-b-20">
                                    <Col span={12}>
                                        Items
                                    </Col>
                                    <Col span={4}>
                                        Published To
                                    </Col>
                                    <Col span={4}>
                                        Time & Date
                                    </Col>
                                    <Col span={2} offset={2}>
                                        Actions
                                    </Col>
                                </Row>
                            {contentList  ? contentList.length ?   contentList.map((video, index) => singleVideo(video, index, toggleVideoPlayer, handleSingleContentClick, updateContentStatus, role, updateContentStatus))
                            :
                            <Empty
                                description="No videos added"
                            />
                            : null

                            }
                        </div>}
                        <div className="r-c-fe">
                            <Pagination
                                total={cotentPageMeta?.totalItems || 0}
                                onChange={(page) => handlePageChange(page)}
                                size="small"
                                current={cotentPageMeta?.currentPage || 1}
                                pageSize={cotentPageMeta?.itemsPerPage || 25}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                showSizeChanger={false}
                            />
                        </div>
                    </TabPane>

                    <TabPane tab="Question Bank" key="question-bank" style={{marginTop: 5}}>
                        {headerActions(onUpdateSearch, libFilterParams, openForm, fetchContentLoader,
                            standardList, subjectList, handleFilterSelect, fetchContent, role, chapterList)}
                        {fetchContentLoader ?
                            <div className="r-c-c" style={{height: "70vh"}}>
                                <LoadingOutlined style={{color: Color.primary, opacity: 0.5, fontSize: '5em'}}/>
                            </div>
                        :
                        <div style={{height: 'calc(100vh - 360px)', overflowY: "auto", marginTop: 40, paddingLeft: 20}}>
                                <Row className="tableHeader m-b-20">
                                    <Col span={12}>
                                        Items
                                    </Col>
                                    <Col span={4}>
                                        Published To
                                    </Col>
                                    <Col span={4}>
                                        Time & Date
                                    </Col>
                                    <Col span={2} offset={2}>
                                        Actions
                                    </Col>
                                </Row>
                            {contentList ? contentList.length ? contentList.map((pdf, index) => singlePdf(pdf, index, handleSingleContentClick, updateContentStatus, role, updateContentStatus))
                            :
                                <Empty
                                    description="No documents added"
                                />
                                : null
                            }
                        </div>}
                        <div className="r-c-fe">
                            <Pagination
                                total={cotentPageMeta?.totalItems || 0}
                                onChange={(page) => handlePageChange(page)}
                                size="small"
                                current={cotentPageMeta?.currentPage || 1}
                                pageSize={cotentPageMeta?.itemsPerPage || 25}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                showSizeChanger={false}
                            />
                        </div>

                    </TabPane>


                </Tabs>
            </div>

                {/* <Col md={{span: 8}} lg={{span: 8}} xl={{span: 8}} xxl={{span: 6}} >
                    <div style={{width: "100%", height: "90%"}}>
                        <div style={{fontWeight: 600,  fontSize: 20,  padding: 10}}>Recently Added</div>
                        <div style={{height: "60vh", width: "90%", overflow: "auto", marginTop: 20, paddingLeft: 50}}>
                            {fetchContentLoader ?
                                <div className="r-c-c" style={{height: "70vh"}}>
                                  Loading...
                                </div>
                             :
                            libFilterParams.type === "video" ?
                            recentContent.map((pdf, index) => recentVideo(pdf, index, toggleVideoPlayer))
                                :
                            recentContent.map((pdf, index) => recentPdf(pdf, index, handleSingleContentClick))}
                        </div>
                    </div>
                </Col> */}
            {showPdfForm && <PdfForm />}
            {showVideoForm && <VideoForm />}
            {showVideoPlayer && <VideoPlayer />}
        </div>
    )
}


const mapStateToProps = state => {
    const {
        libFilterParams, showPdfForm, showVideoForm, showVideoPlayer, fetchContentLoader,
        standardList, subjectList, contentList, cotentPageMeta, recentContent, chapterList
    } = state.digitalLibrary;
    const { role ,access} = state.session.user
    return {
        libFilterParams, showPdfForm, showVideoForm, showVideoPlayer, fetchContentLoader,
        standardList, subjectList, contentList, cotentPageMeta, recentContent, role, chapterList
    };
  };

  const mapDispatchToProps = dispatch => ({
    changeLibraryTab: key => dispatch(changeLibraryTab(key)),
    updateLibFilterParams: (val, key) => dispatch(updateLibFilterParams(val, key)),
    togglePdfForm: bool => dispatch(togglePdfForm(bool)),
    toggleVideoForm: bool => dispatch(toggleVideoForm(bool)),
    toggleVideoPlayer: (bool, link) => dispatch(toggleVideoPlayer(bool, link)),
    clearLibFilterParams: () => dispatch(clearLibFilterParams()),
    fetchContent: () => dispatch(fetchContent()),
    setCurrentContentParams: (index, type) => dispatch(setCurrentContentParams(index, type)),
    fetchClasslist: (subId) => dispatch(fetchClasslist(subId)),
    updateContentStatus: (status, id) => dispatch(updateContentStatus(status, id))
  });


export default connect(mapStateToProps, mapDispatchToProps)(DigitalLibrary);
