import React from "react";
import { Pie } from "@ant-design/plots";
import moment from "moment";
import { SmallInfo } from "./Typography";
import { Color } from "services";

function arePropsEqual(oldProps, newProps) {
    return JSON.stringify(oldProps) === JSON.stringify(newProps);
}

const PieCharts = React.memo(({ color, data }) => {
    const config = {
        appendPadding: 8,
        data,
        angleField: "value",
        colorField: "type",
        color,
        legend: false,
        label: {
            type: 'inner',
            offset: '-50%',
            style: {
                textAlign: 'center',
                fontSize: '10',
                fill: '#191919',
                fontWeight: 'bold',
            },
            autoRotate: false,
            formatter: (text, item) => {
                return item._origin.value + '%';
            },
        },
        tooltip: {
            customContent: (val, item) => {
                return (
                    <div>
                        {item[0]?.data?.tooltip === 'CRM' ?
                            <div style={{ height: "40px", width: "170px", borderRadius: "17px" }}>
                                <div style={{ marginTop: "10px" }}>
                                    <div style={{ display: "flex", gap: 20 }}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{
                                                marginTop: "5px",
                                                height: "5px",
                                                width: "5px",
                                                borderRadius: "100%",
                                                backgroundColor: `${item[0]?.data?.type === 'Enquiry' ? '#594099' : item[0]?.data?.type === 'Cart' ? '#C3B1EC' :
                                                    item[0]?.data?.type === 'Registration' ? '#DED5F5' : '#967DD5'}`
                                            }}></div>
                                            <SmallInfo color={Color.darkGrey} style={{ marginLeft: "5px" }}>{item[0]?.data?.type} :</SmallInfo>
                                        </div>
                                        <SmallInfo color={Color.darkGrey}>{item[0]?.data?.value}% </SmallInfo>
                                    </div>
                                </div>
                            </div>
                            :
                            <div style={{ height: "40px", width: "170px", borderRadius: "17px" }}>
                                <div style={{ fontSize: "12px", color: "#636363", fontWeight: 500, marginTop: "10px" }}>{moment().format('YYYY')}</div>
                                {item[0]?.data?.tooltip === 'studentDec' ?
                                    <div style={{ marginTop: "10px" }}>
                                        <div style={{ display: "flex", gap: 20 }}>
                                            <div style={{ display: "flex" }}>
                                                <div style={{
                                                    marginTop: "5px",
                                                    height: "5px",
                                                    width: "5px",
                                                    borderRadius: "100%",
                                                    backgroundColor: `${item[0]?.data?.type === 'liveCourse' ? '#594099' : item[0]?.data?.type === 'testSeries' ? '#C3B1EC' :
                                                        item[0]?.data?.type === 'studyMaterial' ? '#DED5F5' : '#967DD5'}`
                                                }}></div>
                                                <SmallInfo color={Color.darkGrey} style={{   marginLeft: "5px" }}>{item[0]?.data?.subtitle} :</SmallInfo>
                                            </div>
                                            <SmallInfo color={Color.darkGrey}>{item[0]?.data?.value}% ({item[0]?.data?.count}) </SmallInfo>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ marginTop: "10px" }}>
                                        <div style={{ display: "flex", gap: 20 }}>
                                            <div style={{ display: "flex" }}>
                                                <div style={{
                                                    marginTop: "5px",
                                                    height: "5px",
                                                    width: "5px",
                                                    borderRadius: "100%",
                                                    backgroundColor: `${item[0]?.data?.type === 'liveCourse' ? '#B59009' : item[0]?.data?.type === 'testSeries' ? '#E9CC64' :
                                                        item[0]?.data?.type === 'studyMaterial' ? '#F9E7A3' : '#DAAB00'}`
                                                }}></div>
                                                <SmallInfo color={Color.darkGrey} style={{ marginLeft: "5px" }}>{item[0]?.data?.subtitle} :</SmallInfo>
                                            </div>
                                            <SmallInfo color={Color.darkGrey} >{item[0]?.data?.value}%({item[0]?.data?.count})</SmallInfo>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                );
            },
        },
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: 8,
                }
            },
        },
        pieStyle: (item) => {
            return {
                stroke: item.color,
            };
        },
    };

    return <Pie {...config} />;
}, arePropsEqual);

export default PieCharts;