import { Avatar, Checkbox } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import image from "../../Assets/feeManagement/Mask Group 2.png";
import { Api } from "services";
import { connect } from "react-redux";
import {
  getFeeOverView,
  setAllActivitiesFilter,
  setFeeDetails,
  setOverviewFilter,
} from "actions/feeManagement";
import { setEditRole, setSpecificUserId } from "actions/userManagement";
import { setOthersSelectedTab } from "actions/profileNew";
import { setUserDetails } from "actions/userManagementV2";

function StudentCard({
  name,
  roleNo,
  setEditRole,
  transactions,
  setShowBtns,
  image,
  setFeeDetails,
  index,
  length,
  feeMangementData,
  userId,
  setOverviewFilter,
  getFeeOverView,
  setAllActivitiesFilter,
  setSpecificUserId,
  setOthersSelectedTab,
  setUserDetails,
  item
}) {
  const history = useHistory();

  //append role: ['Student'] to the item... 
  const newItem = { ...item };
  newItem.role = ['Student'];

  useEffect(() => {
    setShowBtns(feeMangementData?.setStudentsList?.length > 0 ? true : false);
  }, [feeMangementData?.setStudentsList]);

  return (
    <div
      className="box-shadow"
      style={{
        fontFamily: "roboto",
        width: "300px",
        padding: "20px",
        margin: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "-15px",
          marginTop: "-5px",
        }}
      >
        <Checkbox
          onChange={(e) => {
            let temp = feeMangementData?.setStudentsList;
            if (e.target.checked) {
              let obj = {
                userId: userId,
                name: name,
              };
              temp.push(obj);
            }
            if (e.target.checked == false) {
              temp = temp.filter((item) => item.userId != userId);
            }
            setShowBtns(temp?.length > 0 ? true : false);
            setFeeDetails("setStudentsList", temp);
            let temp2 = [];
            for (let i = 0; i < temp.length; i++) {
              temp2.push(temp[i].userId);
            }
            setFeeDetails("selectedStudentId", temp2);
          }}
          checked={
            feeMangementData?.setStudentsList?.filter(
              (item) => item.userId == userId
            ).length > 0
              ? true
              : false
          }
        ></Checkbox>
      </div>
      <div
        onClick={() => {
          setSpecificUserId(userId);
          setAllActivitiesFilter("search", null);
          setAllActivitiesFilter("standardId", null);
          setOverviewFilter("userId", userId);
          history.push({
            pathname: "/user-management/profile",
            state: {
              key: true
            }
          });
          setUserDetails(newItem)
          setEditRole("Student");
          setOthersSelectedTab('Basic Profile');
        }}
        className="r-ac"
        style={{ fontFamily: "roboto", cursor: "pointer" }}
      >
        <div>
          <div>
            <Avatar
              style={{ width: "45px", height: "45px" }}
              src={Api._s3_url + image}
            />
          </div>
        </div>
        <div style={{ marginLeft: "10px", marginLeft: "20px" }}>
          <div
            style={{
              fontWeight: "550",
              marginBottom: "0",
              width: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {name}
          </div>
          <div
            style={{
              color: "#636363",
              fontWeight: "550",
              fontSize: "12px",
              marginBottom: "0",
            }}
          >
            Roll No. {roleNo}
          </div>
          <div style={{ color: "#AEAEAE", fontSize: "12px" }}>
            {transactions} Transactions
          </div>
        </div>
      </div>
    </div>
  );
}

// export default StudentCard;
const mapStateToProps = (state) => {
  const { selectedStd, feeMangementData } = state.feeManagement;
  return {
    selectedStd,
    feeMangementData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // setSelectedStd: (data) => dispatch(setSelectedStd(data)),
  setOverviewFilter: (key, res) => dispatch(setOverviewFilter(key, res)),
  setFeeDetails: (key, res) => dispatch(setFeeDetails(key, res)),
  setEditRole: (m) => dispatch(setEditRole(m)),
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
  setAllActivitiesFilter: (key, val) =>
    dispatch(setAllActivitiesFilter(key, val)),
  setSpecificUserId: (val) => dispatch(setSpecificUserId(val)),
  setOthersSelectedTab: tab => dispatch(setOthersSelectedTab(tab)),
  setSpecificUserId: id => dispatch(setSpecificUserId(id)),
  setUserDetails: res => dispatch(setUserDetails(res)),

  // getFeeOverView: (callback) => dispatch(getFeeOverView(callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentCard);
