import React, { useState } from "react";
import OverViewGraph from "../feeManagement/overViewGraph";
import InstallmentCard from "../feeManagement/instalmentCard";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import ViewTransactionDetailsModal from "pages/feeManagement/viewTransactionDetailsModal";
import imageSet from "../../Assets/care/no-subjects.svg";
import { fetchZipDownloadReport } from "actions/feeManagement";
import { Button } from "antd";

function StudentFeeDetails({
  feeMangementData,
  getFeeOverView,
  fetchZipDownloadReport,
}) {

  function numberWithCommas(x) {
    return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
  }
  const [buttonLdr, setButtonLdr] = useState(false);
  return (
    <div>
      <div style={{ marginLeft: "10px", height: "90vh", overflowY: "auto" }}>
        <div
          className=" div_style box-shadow"
          style={{
            // height: "auto",
            padding: "20px",
            // border: "1px solid #E6E6E6",
            borderRadius: "15px",
            overflowY: "hidden",
          }}
        >
          <div className="r-jc">
            <div style={{ width: "25%" }}>
              <div>
                <div className="font_roboto bold-600">Overview</div>
                {/* <div
                      className="font_roboto"
                      style={{ fontSize: "12px", color: "#636363" }}
                    >
                      11/09/2021 - 17/09/2021
                    </div> */}
              </div>
              <div style={{ marginTop: "60px" }}>
                <div className="r-ac">
                  <div className="dot" style={{ marginRight: "3px" }}></div>
                  <div style={{ color: "#594099" }}>
                    <span className="bold-700">
                      {" "}
                      {
                        feeMangementData?.dashBoardDetails
                          ?.totalTransactionRequested?.count
                      }
                    </span>{" "}
                    {feeMangementData?.dashBoardDetails
                      ?.totalTransactionRequested?.count == 1
                      ? "payment"
                      : "payments"}{" "}
                    requested
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "1rem" }}>
                  ₹{" "}
                  {
                    feeMangementData?.dashBoardDetails
                      ?.totalTransactionRequested?.amount &&
                    numberWithCommas(feeMangementData?.dashBoardDetails
                      ?.totalTransactionRequested?.amount)
                  }
                </div>
                {/* <div style={{ color: "#AEAEAE", fontSize: "12px" }}>
                  {
                    feeMangementData?.dashBoardDetails
                      ?.totalTransactionRequested?.todayCount
                  }{" "}
                  {feeMangementData?.dashBoardDetails?.totalTransactionRequested
                    ?.todayCount == 1
                    ? "payment"
                    : "payments"}{" "}
                  requested today
                </div> */}
              </div>
            </div>
            <div
              style={{
                width: "80%",
                marginRight: "-15px",
                marginTop: "-10px",
              }}
            >
              <OverViewGraph />
            </div>
          </div>
          <div
            className="r-c-sb"
            style={{
              borderTop: "1px solid #E6E6E6",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                borderRight: "1px solid #E6E6E6",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <div className="r-ac">
                <div
                  className="dot"
                  style={{ marginRight: "3px", backgroundColor: "#FFA931" }}
                ></div>
                <div
                  style={{
                    color: "#FFA931",
                    fontSize: "10px",
                    fontWeight: "550",
                  }}
                >
                  <span className="bold-700" style={{ fontSize: "12.5px" }}>
                    {" "}
                    {
                      feeMangementData?.dashBoardDetails?.totalPaymentPending
                        ?.count
                    }
                  </span>{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentPending
                    ?.count == 1
                    ? "payment"
                    : "payments"}{" "}
                  pending
                </div>
              </div>
              <div className="bold-700" style={{ fontSize: "15px" }}>
                ₹{" "}
                {
                  feeMangementData?.dashBoardDetails?.totalPaymentPending
                    ?.amount && numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentPending
                      ?.amount)
                }
              </div>
              {/* <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                {
                  feeMangementData?.dashBoardDetails?.totalPaymentPending
                    ?.todayCount
                }{" "}
                {feeMangementData?.dashBoardDetails?.totalPaymentPending
                  ?.todayCount == 1
                  ? "payment"
                  : "payments"}{" "}
                requested today
              </div> */}
            </div>
            <div
              style={{
                borderRight: "1px solid #E6E6E6",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <div className="r-ac">
                <div
                  className="dot"
                  style={{ marginRight: "3px", backgroundColor: "#28DF99" }}
                ></div>
                <div
                  style={{
                    color: "#28DF99",
                    fontSize: "10px",
                    fontWeight: "550",
                  }}
                >
                  <span className="bold-700" style={{ fontSize: "12.5px" }}>
                    {" "}
                    {
                      feeMangementData?.dashBoardDetails?.totalPaymentPaid
                        ?.count
                    }
                  </span>{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentPaid
                    ?.count == 1
                    ? "payment"
                    : "payments"}{" "}
                  paid
                </div>
              </div>
              <div className="bold-700" style={{ fontSize: "15px" }}>
                ₹ {
                  feeMangementData?.dashBoardDetails?.totalPaymentPaid?.amount &&
                  numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentPaid?.amount)
                }
              </div>
              {/* <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                {
                  feeMangementData?.dashBoardDetails?.totalPaymentPaid
                    ?.todayCount
                }{" "}
                {feeMangementData?.dashBoardDetails?.totalPaymentPaid
                  ?.todayCount == 1
                  ? "payment"
                  : "payments"}{" "}
                requested today
              </div> */}
            </div>
            <div
              style={{
                borderRight: "1px solid #E6E6E6",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <div className="r-ac">
                <div
                  className="dot"
                  style={{ marginRight: "3px", backgroundColor: "#FF414D" }}
                ></div>
                <div
                  style={{
                    color: "#FF414D",
                    fontSize: "10px",
                    fontWeight: "550",
                  }}
                >
                  <span className="bold-700" style={{ fontSize: "12.5px" }}>
                    {" "}
                    {
                      feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                        ?.count
                    }
                  </span>{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                    ?.count == 1
                    ? "payment"
                    : "payments"}{" "}
                  overdue
                </div>
              </div>
              <div className="bold-700" style={{ fontSize: "15px" }}>
                ₹{" "}
                {
                  feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                    ?.amount && numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                      ?.amount)
                }
              </div>
              {/* <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                {
                  feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                    ?.todayCount
                }{" "}
                {feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                  ?.todayCount == 1
                  ? "payment"
                  : "payments"}{" "}
                requested today
              </div> */}
            </div>
            <div
              style={{
                borderRight: "1px solid #E6E6E6",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <div className="r-ac">
                <div
                  className="dot"
                  style={{ marginRight: "3px", backgroundColor: "#636363" }}
                ></div>
                <div
                  style={{
                    color: "#636363",
                    fontSize: "10px",
                    fontWeight: "550",
                    overflow: "hidden",
                    width: "100%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span
                    className="bold-700"
                    style={{
                      fontSize: "12.5px",
                    }}
                  >
                    {" "}
                    {
                      feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                        ?.count
                    }
                  </span>{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                    ?.count == 1
                    ? "payment"
                    : "payments"}{" "}
                  cancelled
                </div>
              </div>
              <div className="bold-700" style={{ fontSize: "15px" }}>
                ₹{" "}
                {
                  feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                    ?.amount && numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                      ?.amount)
                }
              </div>
              {/* <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                {
                  feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                    ?.todayCount
                }{" "}
                {feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                  ?.todayCount == 1
                  ? "payment"
                  : "payments"}{" "}
                requested today
              </div> */}
            </div>
            <div
              style={{
                // borderRight: "1px solid #E6E6E6",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <div className="r-ac">
                <div
                  className="dot"
                  style={{ marginRight: "3px", backgroundColor: "#1089FF" }}
                ></div>
                <div
                  style={{
                    color: "#1089FF",
                    fontSize: "10px",
                    fontWeight: "550",
                    overflow: "hidden",
                    width: "100%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span className="bold-700" style={{ fontSize: "12px" }}>
                    {" "}
                    {
                      feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                        ?.count
                    }
                  </span>{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                    ?.count == 1
                    ? "payment"
                    : "payments"}{" "}
                  refunded
                </div>
              </div>
              <div className="bold-700" style={{ fontSize: "15px" }}>
                ₹{" "}
                {
                  feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                    ?.amount && numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                      ?.amount)
                }
              </div>
              {/* <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                {
                  feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                    ?.todayCount
                }{" "}
                {feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                  ?.todayCount == 1
                  ? "payment"
                  : "payments"}{" "}
                requested today
              </div> */}
            </div>
          </div>
        </div>
        <div
          className="box-shadow"
          style={{ padding: "20px", marginTop: "25px" }}
        >
          <div style={{ fontWeight: "bold", fontSize: "18px" }}>
            Upcoming transactions (
            {feeMangementData?.dashBoardDetails?.upcomingTransactions?.length
              ? feeMangementData?.dashBoardDetails?.upcomingTransactions?.length
              : 0}
            )
          </div>
          {feeMangementData?.dashBoardDetails?.kyc != "Inactive" &&
            feeMangementData?.dashBoardDetails?.upcomingTransactions &&
            feeMangementData?.dashBoardDetails?.upcomingTransactions.length !=
            0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {feeMangementData?.dashBoardDetails?.upcomingTransactions?.map(
                (item, index) => (
                  <InstallmentCard
                    // item={item}
                    // instaCard={true}
                    // amount={parseFloat(item?.amount)}
                    // dueDate={item.dueDate}
                    // issueDate={item.date}
                    // status={item.status}
                    // index={index}
                    // payment_type={item.payment_type}
                    // payment_method={item.payment_method}
                    item={item}
                    instaCard={true}
                    amount={parseFloat(item?.amount)}
                    dueDate={item?.dueDate}
                    issueDate={item?.date}
                    status={item?.status}
                    index={index}
                    payment_type={item?.payment_type}
                    payment_method={item?.payment_method}
                    status="pending"
                  />
                )
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <img
                style={{
                  width: "30%",
                  height: "30%",
                  // marginBottom: "10px",
                }}
                src={imageSet}
                alt=""
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <h1 style={{ fontWeight: "500" }}>No data!</h1>
                <p
                  style={{
                    color: "#636363",
                    // width: "70%",
                    textAlign: "center",
                  }}
                >
                  There are no transactions/requests to show yet.
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className="box-shadow"
          style={{ padding: "20px", marginTop: "25px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: "18px" }}>
              All transactions (
              {feeMangementData?.dashBoardDetails?.paidTransactions?.length
                ? feeMangementData?.dashBoardDetails?.paidTransactions?.length
                : 0}
              )
            </div>
            <div>
              <Button
                onClick={() => {
                  setButtonLdr(true);
                  fetchZipDownloadReport(() => {
                    setButtonLdr(false);
                  });
                }}
                disabled={
                  feeMangementData?.dashBoardDetails?.paidTransactions
                    ?.length == 0
                    ? true
                    : false
                }
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  fontFamily: "roboto",
                  backgroundColor: "#594099",
                  borderRadius: "20px",
                  width: "190px",
                  padding: "2px",
                }}
                loading={buttonLdr}
              >
                Download all invoices
              </Button>
            </div>
          </div>
          {feeMangementData?.dashBoardDetails?.kyc != "Inactive" &&
            feeMangementData?.dashBoardDetails?.paidTransactions &&
            feeMangementData?.dashBoardDetails?.paidTransactions.length != 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {feeMangementData?.dashBoardDetails?.paidTransactions?.map(
                (item, index) => (
                  <InstallmentCard
                    item={item}
                    instaCard={true}
                    amount={parseFloat(item?.amount)}
                    dueDate={item.dueDate}
                    issueDate={item.date}
                    status={item.status}
                    index={index}
                    payment_type={item.payment_type}
                    payment_method={item.payment_method}
                  />
                )
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <img
                style={{
                  width: "30%",
                  height: "30%",
                  // marginBottom: "10px",
                }}
                src={imageSet}
                alt=""
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <h1 style={{ fontWeight: "500" }}>No data!</h1>
                <p
                  style={{
                    color: "#636363",
                    // width: "70%",
                    textAlign: "center",
                  }}
                >
                  There are no paid transactions to show yet.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// export default StudentFeeDetails;
const mapStateToProps = (state) => {
  const { feeMangementData } = state.feeManagement;
  return {
    feeMangementData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
  setAllActivitiesFilter: (key, val) =>
    dispatch(setAllActivitiesFilter(key, val)),
  fetchZipDownloadReport: (callback) =>
    dispatch(fetchZipDownloadReport(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentFeeDetails);
