import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import StudentGraph from "./localComponents/studentGraph";
import StudentBarGraph from "./localComponents/studentBarGraph";
import BarGraph from "./localComponents/barGraph";
import { DatePicker, Select, Input, Tooltip, Row, Col, Tag, } from "antd";
import SearchBox from "./localComponents/searchBox";
import { useHistory } from "react-router";
import {
  fetchClassCardId,
  fetchStudentDashboard,
  updateFilter,
  setStartDate,
  setEndDate,
  setMonthOrWeek,
  storeSelectedSubject,
  setBackToPrivilegedView,
  setFromUsersGeofence,
} from "actions/attendanceNew";
import { connect } from "react-redux";
import PageDataLoader from "components/pageDataLoader";
import moment from "moment";
import { Api } from "services";
import PageHeader from "components/pageHeader";
import BackToTop from "components/backToTop";
import { Heading } from "components/Typography";
import {Button} from 'components/Button'
import { InputSearch } from "components/customInputs";

// import RangePicker from "pages/digitalLibrary/components/rangePicker";

const { RangePicker } = DatePicker;
const geoImage = require("../../Assets/noun-location-point-4457421.svg").default;

const bodyStyle = {
  background: "#FAFAFA",
  width: "calc(100% + 30px)",
  height: "calc(100% + 40px)",
  margin: -20,
  padding: 20,
  overflow: "auto",
};

const PageHeaderTabs = ({ setBackToPrivilegedView, setFromUsersGeofence }) => {

  const history = useHistory();

  const handleOnGeoFenceClick = () => {
    history.push("/attendance-geofence");
    setFromUsersGeofence(true);
  }

  const handleBackPress = () => {
    history.goBack();
    setBackToPrivilegedView(true);
  }

  const actions = [
    <Button type="primary" onClick={()=>{handleOnGeoFenceClick()}} style={{}} icon={<EnvironmentOutlined/>}>GEO FENCE</Button>
  ]

  return (
    <div>
      <div style={{ marginTop: -10, height: 50}}>
        <PageHeader title='Students' actions={actions} onBackPress={()=>handleBackPress()}/>
      </div>
    </div>
  );
};

const DonutGraphCard = ({ studentDashboardData }) => {
  return (
    <div
      className=" boxShadow118 border-16 flex-wrap"
      style={{
        width: "96%",
        height: "340px",
        // borderRadius: 2,
        backgroundColor: "#FFFFFF",
        borderColor: "#E6E6E6",
        border: "solid 1px #E6E6E6",
      }}
    >
      <div style={{ padding: 20 }}>
        <div
          style={{
            fontSize: "15px",
            color: "#191919",
            fontWeight: "bold",
            fontFamily: "Roboto",
          }}
        >
          Today’s attendance
        </div>
        <div style={{ fontSize: "9px", color: "#636363" }}>
          {studentDashboardData?.todaysAttendance?.date} {"•"}{" "}
          {studentDashboardData?.todaysAttendance?.day}
        </div>
      </div>
      <div style={{ marginTop: -90 }}>
        {studentDashboardData?.todaysAttendance?.present || studentDashboardData?.todaysAttendance?.absent ?
          <StudentGraph
            overall={studentDashboardData?.todaysAttendance?.present}
            presentVal={studentDashboardData?.todaysAttendance?.present}
            absentVal={studentDashboardData?.todaysAttendance?.absent}
          />
          :
          <center style={{ marginTop: 150, width: '80%', marginLeft: 30 }}><NodataCard /></center>}
      </div>
    </div>
  );
};

const AttendanceByClassCard = ({
  studentPageLoader,
  studentDashboardData,
  isWeekOrMonth,
  endDate,
  startDate,
  setStartDate,
  setEndDate,
  setMonthOrWeek,
  fetchStudentDashboard,
}) => {
  let [sum, setSum] = useState(0);
  useEffect(() => {
     setSum(0);
    let temp = 0;
    if (studentDashboardData?.attendanceByClass?.length != 0) {
      studentDashboardData?.attendanceByClass?.forEach(
        (item) =>
          (temp = temp + parseFloat(item?.percentage ? item?.percentage : 0))
      );
    }
    setSum(temp);
  }, [studentDashboardData?.attendanceByClass]);

  const data = studentDashboardData?.attendanceByClass?.map((item) => ({
    "type": item.standard,
    "value": item.percentage,
    "studentCount": item.studentCount
  }))
  return (
    <div
      className="flex-wrap boxShadow118 border-16"
      style={{
        width: "100%",
        height: "370px",
        // borderRadius: 2,
        backgroundColor: "#FFFFFF",
        borderColor: "#E6E6E6",
        border: "solid 1px #E6E6E6",
      }}
    >
      <div
        style={{
          color: "#191919",
          fontWeight: "bold",
          fontSize: "15px",
          padding: 10,
          marginLeft: 15,
          fontFamily: "Roboto",
        }}
      >
        Attendance by class
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "-10px",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", marginLeft: 20 }}>
          {/* <div style={{ marginLeft: "5px", fontSize: "9px", color: "#636363" }}>
            <CaretLeftOutlined
              onClick={() => {
                setStartDate(
                  moment(startDate, "YYYY-MM-DD")
                    .startOf(isWeekOrMonth)
                    .subtract(1, isWeekOrMonth)
                    .format("YYYY-MM-DD")
                );
                setEndDate(
                  moment(endDate, "YYYY-MM-DD")
                    .endOf(isWeekOrMonth)
                    .subtract(1, isWeekOrMonth)
                    .format("YYYY-MM-DD")
                );
                fetchStudentDashboard(() => {});
              }}
            />
          </div> */}
          <div
            style={{
              marginLeft: "5px",
              fontSize: "9px",
              color: "#636363",
              fontFamily: "Roboto",
            }}
          >
            
          
            {startDate != '' && endDate != '' ?(
              <>
               {moment(startDate).format('DD-MM-YYYY') ? moment(startDate).format('DD-MM-YYYY')  : null} {""} {"-"} {""}{" "}
            {moment(endDate).format('DD-MM-YYYY') ? moment(endDate).format('DD-MM-YYYY') : null}
              </>
            )
           :null}
          </div>
          {/* {!(
            startDate ===
              moment().startOf(isWeekOrMonth).format("YYYY-MM-DD") &&
            endDate === moment().endOf(isWeekOrMonth).format("YYYY-MM-DD")
          ) ? (
            <div
              style={{ marginLeft: "5px", fontSize: "9px", color: "#636363" }}
            >
              <CaretRightOutlined
                onClick={() => {
                  setStartDate(
                    moment(startDate, "YYYY-MM-DD")
                      .startOf(isWeekOrMonth)
                      .add(1, isWeekOrMonth)
                      .format("YYYY-MM-DD")
                  );
                  setEndDate(
                    moment(endDate, "YYYY-MM-DD")
                      .endOf(isWeekOrMonth)
                      .add(1, isWeekOrMonth)
                      .format("YYYY-MM-DD")
                  );
                  fetchStudentDashboard(() => {});
                }}
              />
            </div>
          ) : null} */}
        </div>
        <div id='abc' style={{ display: "flex", marginLeft: 20, marginTop: -10 }}>
          <div style={{ width: "100%", marginLeft: "-50px"}}>
            <RangePicker
              // format={'DD-MM-YYYY'}
              getPopupContainer={(trigger) => trigger.parentElement}
              value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
              onChange={(value1, value) => {
                setStartDate(value[0])
                setEndDate(value[1])
                fetchStudentDashboard(() => { });
              }}
            />
          </div>
          {/* <div style={{ fontFamily: "Roboto", marginRight: 50 }}>
          <Select
            style={{
              fontSize: "12px",
              fontFamily: "Roboto",
              fontWeight: "bold",
              width: "100px",
            }}
            defaultValue={"Week"}
            value={isWeekOrMonth}
            onChange={(value) => {
              setMonthOrWeek(value);
              setStartDate(moment().startOf(value).format("YYYY-MM-DD"));
              setEndDate(moment().endOf(value).format("YYYY-MM-DD"));
              fetchStudentDashboard(() => {});
            }}
          >
            <Option
              style={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: "bold",
              }}
              value="week"
            >
              Week
            </Option>
            <Option
              style={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: "bold",
              }}
              value="month"
            >
              Month
            </Option>
          </Select>
        </div> */}
        </div>
      </div>
      {console.log("sum", sum)}
      {sum === 0 ? (
        <div style={{ marginTop: "10px" }}>
          <NodataCard />
        </div>
      ) : (
        (// data && data ?. length !=0 ?
          <div
            style={{ width: "95%", height: "80%", marginTop: 50, marginLeft: 20 }}
          >
            <BarGraph data={data?.length != 0 ? data : []} />
          </div> /* :null */ /*:null */)
      )}
      {/* {data && data ?. length == 0 ?   : null} */}
    </div>
  );
};

const StudentBarCard = ({
  studentDashboardData,
  storeSubject,
  storeSelectedSubject,
  fetchStudentDashboard,
}) => {
  const [graph, setGraph] = useState([]);
  // const [ week , isWeek] = useState(false)
  useEffect(() => {
    let temp = [];
    for (let i = 0; i < studentDashboardData?.todaysAttendanceByClass?.length; i++) {
      let obj = {}
      obj['subjectName'] = studentDashboardData?.todaysAttendanceByClass[i].subjectName,
        obj['key'] = studentDashboardData?.todaysAttendanceByClass[i].key
      obj['range'] = studentDashboardData?.todaysAttendanceByClass[i].presentPercentage,
        obj['presentCount'] = studentDashboardData?.todaysAttendanceByClass[i].presentCount,
        obj['icon'] = studentDashboardData?.todaysAttendanceByClass[i].icon,
        obj['teacherName'] = studentDashboardData?.todaysAttendanceByClass[i].teacherName,
        obj['name'] = 'Present count %'
      temp.push(obj);

      let obj1 = {}
      obj1['subjectName'] = studentDashboardData?.todaysAttendanceByClass[i].subjectName,
        obj1['key'] = studentDashboardData?.todaysAttendanceByClass[i].key

      obj1['range'] = studentDashboardData?.todaysAttendanceByClass[i].absentPercentage,
        obj1['absentCount'] = studentDashboardData?.todaysAttendanceByClass[i].absentCount,
        obj1['icon'] = studentDashboardData?.todaysAttendanceByClass[i].icon
      obj1['teacherName'] = studentDashboardData?.todaysAttendanceByClass[i].teacherName,
        obj1['name'] = 'Absent count %'
      temp.push(obj1);
    }
    setGraph([...temp])
    console.log("tem", studentDashboardData?.todaysAttendanceByClass)

  }, [studentDashboardData?.todaysAttendanceByClass])



  const [sectionArray, setSectionArray] = useState([])

  // const [sectionArray, setSectionArray] = useState([]);

  useEffect(() => {
    let temp = [];
    const studentSubject = Object.entries(
      studentDashboardData?.standards ? studentDashboardData?.standards : {}
    );
    for (let i = 0; i < studentSubject?.length; i++) {
      temp = temp.concat(studentSubject[i][1]);
    }

    setSectionArray([...temp]);
  }, [studentDashboardData?.standards]);

  useEffect(() => {
    if (storeSubject && storeSubject != "") {
    } else {
      storeSelectedSubject(sectionArray?.[0]?.id);
      fetchStudentDashboard(() => { });
      console.log("abc", sectionArray?.[0]?.id);
    }
  }, [sectionArray]);

  return (
    <div
      className="flex-wrap boxShadow118 border-16"
      style={{
        width: "100%",
        height: "340px",
        // borderRadius: 2,
        backgroundColor: "#FFFFFF",
        borderColor: "#E6E6E6",
        border: "solid 1px #E6E6E6",
      }}
    >
      <Row span={24}>
        <Col span={10}>
          <div
            style={{
              color: "#191919",
              fontWeight: "bold",
              fontSize: "15px",
              padding: 10,
              marginLeft: 10,
              fontFamily: "Roboto",
              marginTop: "8px",
            }}
          >
            Today’s attendance by class
          </div>
        </Col>
        {/* <div style={{ display: "flex" ,marginLeft:"20px"}}>
          <div style={{  fontSize: "9px", color: "#636363" }}>
            <CaretLeftOutlined />
          </div>
          <div
            style={{
              marginLeft: "5px",
              fontSize: "9px",
              color: "#636363",
              
            }}
          >
           {`${storeSubject ? storeSubject:''}`}
          </div>
          <div style={{  fontSize: "9px", color: "#636363" }}>
            <CaretRightOutlined />
          </div>
        </div> */}

        <Col span={9} style={{ display: "flex", marginTop: "25px" }}>
          <div
            style={{
              width: "8px",
              height: "8px",
              backgroundColor: "#28DF99",
              marginRight: "10px",
              marginTop: "2px",
            }}
          ></div>
          <div
            style={{
              fontSize: "9px",
              color: "#636363",
              marginRight: "20px",
              fontFamily: "Roboto",
            }}
          >
            Present
          </div>
          <div
            style={{
              width: "8px",
              height: "8px",
              backgroundColor: "#FF414D",
              marginRight: "10px",
              marginTop: "2px",
              marginLeft: "10px",
            }}
          ></div>
          <div
            style={{
              fontSize: "9px",
              color: "#636363",
              marginRight: "10px",
              fontFamily: "Roboto",
            }}
          >
            Absent
          </div>
        </Col>
        <Col span={5} style={{ marginTop: "25px" }}>
          <div
            style={{ marginLeft: 15, marginTop: "-8px", fontFamily: "Roboto" }} id="testHomeDropdown"
          >
            <Select
              style={{ width: "100px" }}
              value={storeSubject}
              onChange={(item) => {
                console.log("itemss", item);
                storeSelectedSubject(item);
                fetchStudentDashboard(() => { });
              }}
              getPopupContainer={() =>document.getElementById("testHomeDropdown")}
            >
              {sectionArray?.map((item) => (
                <Option value={item?.id}>
                  {" "}
                  {item?.std}-{item?.section}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>

      {graph && graph?.length != 0 ? (
        <div
          style={{ width: "95%", height: "80%", marginTop: 50, marginLeft: 20 }}
        >
          {graph && graph.length === 0 ? <NodataCard /> : <StudentBarGraph data={graph?.length != 0 ? graph : []} />}
        </div>
      ) : <NodataCard />}
    </div>
  );
};

const ClassCard = ({ section, fetchClassCardId }) => {
  const history = useHistory();
  return (
    <div
      className="cursor-pointer boxShadow118 border-16"
      style={{
        width: "250px",
        alignItems: "center",
        border: "#E6E6E6 solid 1px",
        // borderRadius: "2px",
        padding: "10px",
        paddingTop: "10px",
        height: "140px",
        marginRight: 12,
        borderColor: "#E6E6E6",
        backgroundColor: "#FFFFFF",
        marginTop: "15px",
        cursor: "pointer",
        marginLeft: "5px",
      }}
      onClick={() => {
        fetchClassCardId(section?.id);
        history.push("/Class-page");
      }}
    >
      <div
        style={{
          fontSize: "28px",
          color: "#594099",
          fontWeight: "bold",
          marginTop: "10px",
          textAlign: "center",
          fontFamily: "PT Serif",
        }}
      >
        {section?.section}
      </div>
      <div style={{ color: "#AEAEAE", fontSize: "12px", textAlign: "center" }}>
        {section?.courseName || "NA"}
      </div>

      <div style={{ display: "flex", marginTop: "15px", marginLeft: "85px" }}>
        <div style={{ color: "#28DF99", fontSize: "12px" }}>
          {section?.total_students}
        </div>
        <div style={{ marginLeft: "5px", color: "#8C8C8C", fontSize: "12px" }}>
          Students
        </div>
      </div>
    </div>
  );
};

export function NodataCard({
  containerheight = "80%",
  imgwidth = "30%",
  imgheight = "30%",
}) {
  return (
    <div className="r-c-c-c" style={{ height: containerheight }}>
      <img
        style={{ width: imgwidth, height: imgheight, marginBottom: "20px" }}
        src={require("../../Assets/test/no-subjects.svg").default}
        alt=""
      />
      <div className="r-c-c-c">
        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
        <p style={{ color: "#636363", fontFamily: "roboto" }}>
          There is no data to show yet. Please check back later.
        </p>
      </div>
    </div>
  );
}

const StudentLeaveRequest =({studentDashboardData,items})=>{
  console.log("AA-studentDashboardData",items?.isNew 

  );
  return (
    <>
      <div
        style={{
          marginRight: 10,
          borderRadius: "4px",
          // alignItems: "center",
          width: "95%",
          backgroundColor: "#FFFFFF",
          cursor: "default",
          marginBottom: "10px",
          padding: "0px 10px 10px 0px",
          height: "85px",
          marginLeft:"10px",
          marginTop:"15px"
        }}
      >
        

        <div className="display-flex" style={{ marginLeft: "10px",  }}>
          <div>
            <img style={{ width: "50px", height: "50px", borderRadius: "50%" }} src={`${Api._s3_url}${items?.user?.image}`} />
          </div>
          <div className="m-l-10" >

            <div style={{ display: "flex" }}>
              <div>
              <div
                style={{
                  fontSize: "13px",
                  color: "#191919",
                  fontWeight: 550,
                  fontFamily: "Roboto",
                }}
              >
                {items?.user?.name}
              </div>
              <div className="m-b-5">
            <Tag style={{
              color: `${items?.requestType === "Leave" ? " #1089FF" : "#594099"}`,
              backgroundColor: `${items?.requestType === "Leave" ? "#E7F3FF" : "#F6F4FE"}`,
              borderColor: `${items?.requestType === "Leave" ? "#E7F3FF" : "#F6F4FE"}`,
              fontSize: "10px",
              fontWeight: "bold",
            }}>
              {items?.requestType
}
            </Tag>
          </div>
                
              </div>
              <div 
              style={{ display: items?.isNew ? "view" : "none", marginTop: "-12px", marginLeft: "8px" }}
              >
                <Tag color="#FFA931" style={{ fontWeight: "bold", border: "none", fontSize: "8px", borderRadius: "100px", marginRight: "10px", marginTop: "10px" }}>New</Tag>
              </div>
            </div>

            <div style={{ display: "felx",}}>
              {/* <div style={{ fontSize: "11px", color: "#636363", fontFamily: "Roboto", }}>
                {items?.user?.role}
              </div> */}
              <div
                style={{
                  fontSize: "11px",
                  color: "#636363",
                  marginTop: "-25px",
                 marginLeft:"100px"
                // textAlign:"left"
                }}
              >
                { moment.utc(items?.createdAt).local().format("DD/MM/YYYY • hh:mm A")}
              </div>
              

            </div>
            




            {/* <div style={{ display: "flex" }}>
              <div style={{ fontSize: "12px", color: "#AEAEAE" }}>
                {props.status}
              </div> */}

            {/* </div> */}
          </div>


        </div>

      </div>
    </>
  );
}

let timer = false;
function StudentAttendanceGraph({
  sectionArray,
  storeSubject,
  storeSelectedSubject,
  setMonthOrWeek,
  isWeekOrMonth,
  endDate,
  startDate,
  setStartDate,
  setEndDate,
  fetchStudentDashboard,
  studentDashboardData,
  filter,
  updateFilter,
  fetchClassCardId,
  studentPageLoader,
  setBackToPrivilegedView,
  setFromUsersGeofence
}) {
  const history = useHistory();

  useEffect(() => {
    updateFilter("indexSearch", null);
    // storeSelectedSubject()
    setMonthOrWeek("week");
    setStartDate(moment().startOf("week").format("YYYY-MM-DD"));

    setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
    fetchStudentDashboard(() => { });
  }, []);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fetchStudentDashboard(() => { });
    }, 1000);
  }, [filter?.indexSearch]);

  const onSearch = (e) => {
    updateFilter("indexSearch", e);
  };

  return (
    <div style={bodyStyle}>
      <div>
        <PageHeaderTabs setBackToPrivilegedView={setBackToPrivilegedView} setFromUsersGeofence={setFromUsersGeofence}/>
      </div>
      <Row style={{ marginTop: "10px" }}>
        <Col
          md={{ span: 24 }}
          xl={{ span: 8 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
        >
          <DonutGraphCard studentDashboardData={studentDashboardData} />
        </Col>
        <Col
          md={{ span: 24 }}
          xl={{ span: 16 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
        >
          <StudentBarCard
            studentDashboardData={studentDashboardData}
            storeSelectedSubject={storeSelectedSubject}
            storeSubject={storeSubject}
            fetchStudentDashboard={fetchStudentDashboard}
          />
        </Col>
      </Row>

      {/* <div className="m-t-30">
        <AttendanceByClassCard
          studentDashboardData={studentDashboardData}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          startDate={startDate}
          endDate={endDate}
          setMonthOrWeek={setMonthOrWeek}
          isWeekOrMonth={isWeekOrMonth}
          studentPageLoader={studentPageLoader}
          fetchStudentDashboard={fetchStudentDashboard}
        />
      </div> */}
      <Row style={{ marginTop: "10px" }}>
        <Col
          md={{ span: 24 }}
          xl={{ span: 16 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
        >
           <AttendanceByClassCard
          studentDashboardData={studentDashboardData}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          startDate={startDate}
          endDate={endDate}
          setMonthOrWeek={setMonthOrWeek}
          isWeekOrMonth={isWeekOrMonth}
          studentPageLoader={studentPageLoader}
          fetchStudentDashboard={fetchStudentDashboard}
        />
        </Col>
        <Col
          md={{ span: 24 }}
          xl={{ span: 8 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
          
        >
          <div span={11} className='scroll container boxShadow118 border-16 '
                  style={{
                    width: "98%",
                    height: "370px",
                    // borderRadius: 2,
                    backgroundColor: "#FFFFFF",
                    borderColor: "#E6E6E6",
                    marginLeft:"10px"

                  }}>
                    <div style={{ display: "flex", justifyContent: "space-between", padding:"20px" }}>
                    <div style={{ display: "flex", }}>
                      <div
                        style={{ color: "#191919", fontSize: "15px", fontWeight: "bold", fontFamily: "Roboto", }}>
                        Request(s)
                      </div>
                      <div style={{ color: "#AEAEAE", fontSize: "15px", fontFamily: "Roboto", marginLeft: "5px" }}>({studentDashboardData?.count})</div>
                    </div>

                    <div style={{ color: "#1089FF", fontSize: "13px", fontWeight: "bold", cursor: "pointer" }} 
                    onClick={() => {
                      history.push("/request-viewall")
                      // storeUsersName('All');
                    }}
                    >
                      VIEW ALL
                    </div>
                  </div>
                    {studentDashboardData?.request?.map((item,index)=>(
                      <>
                      {
                        index < 3 && (
                          <StudentLeaveRequest
                      studentDashboardData={studentDashboardData}
                      items={item} />
                        )
                      }
                      </>
                    ))}
                  </div>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Heading>
          All Classes
        </Heading>
        <div style={{ marginRight: "22px" }}>
          <InputSearch
            allowClear
            placeholder={"Search by class"}
            onEnter={(e)=>onSearch(e)}
            // value={filter?.indexSearch}
          />
        </div>
      </div>
    {
      Object.keys(
        studentDashboardData?.standards ? studentDashboardData?.standards : {}
      ).length>0?
      <>
      {
      Object.keys(
        studentDashboardData?.standards ? studentDashboardData?.standards : {}
      )?.map((item) => (
        <div>
          <div
            style={{
              fontSize: "16px",
              color: "#191919",
              fontWeight: 700,
              marginLeft: "10px",
              marginTop: 20,
              fontFamily: "roboto",
            }}
          >
            {item}
          </div>
          <div
            className="flex-wrap "
            style={{ display: "flex", marginTop: "-10px" }}
          >
            {Object.keys(
              studentDashboardData?.standards
                ? studentDashboardData?.standards
                : {}
            ).length != 0 &&
              studentDashboardData?.standards?.[`${item}`]?.map((section) => (
                <ClassCard
                  // studentDashboardData={studentDashboardData}
                  fetchClassCardId={fetchClassCardId}
                  section={section}
                  studentPageLoader={studentPageLoader}
                />
              ))}
          </div>
        <PageDataLoader visible={studentPageLoader} />
        </div>
      ))} 
      </>
      :
      <>
    <NodataCard/>
      </>
    }
    <BackToTop/>
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    studentDashboardData,
    filter,
    studentPageLoader,
    attenadanceByClassDate,
    startDate,
    endDate,
    isWeekOrMonth,
    storeSubject,
  } = state.attendanceNew;
  return {
    studentDashboardData,
    filter,
    studentPageLoader,
    attenadanceByClassDate,
    startDate,
    endDate,
    isWeekOrMonth,
    storeSubject,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchStudentDashboard: (callback) =>
    dispatch(fetchStudentDashboard(callback)),
  updateFilter: (key, val) => dispatch(updateFilter(key, val)),
  fetchClassCardId: (id) => dispatch(fetchClassCardId(id)),
  setStartDate: (val) => dispatch(setStartDate(val)),
  setEndDate: (val) => dispatch(setEndDate(val)),
  setMonthOrWeek: (val) => dispatch(setMonthOrWeek(val)),
  storeSelectedSubject: (val) => dispatch(storeSelectedSubject(val)),
  setBackToPrivilegedView: (val) => dispatch(setBackToPrivilegedView(val)),
  setFromUsersGeofence:(bool)=>dispatch(setFromUsersGeofence(bool))

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentAttendanceGraph);