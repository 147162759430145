import React from "react";
import Button from 'antd/lib/button';
import 'antd/lib/button/style/index';
import Styles from "./style";
import { AudioIcon, AudioOffIcon, VideoIcon, VideoOffIcon } from "../../icons";
import { Color } from "../../services";
// import Peer  from '../../services/Peer.js';

class LiveClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localStream: false,
      multiStreams: [],
    };
  }

  componentDidMount() {}

  async getMedia(constraints) {
    // Peer.conf({
    //   signal: 'wss://webrtc.vectorseducation.com/websocket'
    // });

    navigator.mediaDevices.getUserMedia(constraints).then(function(stream) {
      const localVideo = document.getElementById("local-video");
      if (localVideo) {
        localVideo.srcObject = stream;
        localVideo.play();

        // Peer.join(1, 'auth-token');
        // const message = {
        //   event: 'open',
        //   data: {
        //     userIds: [1,2,3,4]
        //   }
        // }
        // Peer.send(message);
        // // Peer.connect(Math.floor(Math.random() * 10),true);
        // Peer.on('open', (e)=>{
        //   //when i join
        //   console.log('open', e);
        //   Peer.setStream(localVideo);
        // });
      }
    });
  }

  render() {
    const { visible } = this.props;
    if (visible) {
      this.getMedia({ video: false, audio: true });
    }

    return (
      <div
        style={{
          ...Styles.popUpContainer,
          display: visible ? "block" : "none"
        }}
      >
        <video
          autoPlay
          id="local-video"
          style={{
            width: 300,
            height: 300,
            backgroundColor: "#222",
            borderRadius: 25,
            transform: "rotateY(180deg)"
          }}
        ></video>
        <div style={Styles.popUpControl}>
          {false ? (
            <Button
              type="primary"
              shape="circle"
              style={{ border: 0 }}
              icon={<VideoIcon />}
              size="large"
            />
          ) : (
            <Button
              type="primary"
              shape="circle"
              style={{ backgroundColor: "white", border: 0 }}
              icon={<VideoOffIcon style={{ color: Color.primary }} />}
              size="large"
            />
          )}
          <div style={{ width: 30 }}></div>
          {false ? (
            <Button
              type="primary"
              shape="circle"
              icon={<AudioIcon />}
              size="large"
            />
          ) : (
            <Button
              type="primary"
              shape="circle"
              style={{ backgroundColor: "white", border: 0 }}
              icon={<AudioOffIcon style={{ color: Color.primary }} />}
              size="large"
            />
          )}
        </div>
      </div>
    );
  }
}

export default LiveClass;
