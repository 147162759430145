import React from 'react';
import "./loginPage.css"
import Carousel from 'react-multi-carousel';
import lottie1 from "Assets/loginNew/Live_Class_Final.json";
import lottie2 from "Assets/loginNew/Digital_Library_Final.json";
import lottie3 from "Assets/loginNew/Test_Final.json";
import lottie4 from "Assets/loginNew/Fee_Management_Final.json";
import lottie5 from "Assets/loginNew/Attendance_Final.json";
import 'react-multi-carousel/lib/styles.css';
import { Font15, Font17, Font19 } from 'components/responsiveTypography';
import Lottie from 'react-lottie';

const carouselData = [
    {
        lottie: lottie1,
        title: "Interactive live classes",
        description: "Join live sessions, engage with teachers, and learn collaboratively.",
    },
    {
        lottie: lottie2,
        title: "Digital Library",
        description: "Explore digital resources anytime and enhance your learning journey effortlessly.",
    },
    {
        lottie: lottie3,
        title: "Tests and Evaluations",
        description: "Measure growth, evaluate your learning, and improve consistently.",
    },
    {
        lottie: lottie4,
        title: "Easy Fee Payments",
        description: "Easily pay fees and purchase courses seamlessly through the app.",
    },
    {
        lottie: lottie5,
        title: "Attendance Tracking",
        description: "Effortlessly monitor your attendance and stay engaged in every session.",
    },
];

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 767 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1
    }
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
        carouselState: { currentSlide }
    } = rest;
    const slideNumbers = [2, 3, 4, 5, 6];

    return (
        <div className="">
            <div className="r-c-c m-b-30">
                {slideNumbers.map((slideNumber) => (
                    <div
                        key={slideNumber}
                        className="new-login-carousel-indicator"
                        onClick={() => goToSlide(slideNumber)}
                        style={{ background: currentSlide === slideNumber ? "#594099" : "#CDC6E0", marginRight: 7 }}
                    ></div>
                ))}
            </div>
        </div>
    );
};


function NewCarouselComponent() {
    return (
        <div className='new-carousel-parent'>
            <Carousel
                infinite
                pauseOnHover={false}
                responsive={responsive}
                arrows={false}
                renderButtonGroupOutside={true}
                autoPlay={true}
                autoPlaySpeed={6000}
                className="newLoginCarousel"
                customButtonGroup={<ButtonGroup />}
            >
                {carouselData.map((data, index) => {
                    const options = {
                        loop: true,
                        autoplay: true,
                        animationData: data.lottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                        },
                    };
                    return (
                        <div key={index} className="carousel-items-parent">
                            <div className='carousel-image'>
                                <Lottie options={options} isClickToPauseDisabled={true} />
                            </div>
                            <Font17 fontWeight={600} color='#191919'>{data?.title}</Font17>
                            <center style={{ width: "80%", marginTop: 10 }}>
                                <Font15 color='#191919'>{data?.description}</Font15>
                            </center>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    )
}

export default NewCarouselComponent