import { deleteKycDocument, UploadDocument } from 'actions/aulasWallet';
import { Button, Input, message, Modal, Select, Typography, Upload } from 'antd'
import { LabelDiv } from 'pages/profile/detailFields'
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Api, Notification } from 'services';
import ic_file_eadd from "../../Assets/images/ic_file_eadd.svg"
import pdfImage from '../../Assets/aulasWallet/ic-pdf.svg'
import Image from '../../Assets/aulasWallet/ic-file-image.svg'
import ImgViewModal from 'pages/kyc/modals/imgView';
import { MailOutlined, MessageOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Dragger } = Upload;

const DocumentPending = ({ userKycDetails }) => {
    return (
        <div>
            <div style={{ border: "2px dotted #E6E6E6", backgroundColor: "#FFFFFF", cursor: "pointer", height: 80 }}>
                <div className='r-c-c m-t-10' style={{ padding: 10 }}>
                    <div className="ant-upload-drag-icon">
                        <img src={file?.type === "image/png" ? Image : pdfImage} style={{ height: 30 }} />
                    </div>
                    <div className='r-c-sb m-l-10' style={{ width: "100%" }}>
                        <div>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", fontSize: "13px", fontWeight: "bold", width: "150px" }}>{file?.name}</Typography.Text>
                            <div style={{ color: "#AEAEAE", fontSize: "13px", fontWeight: "normal" }}>{displaySize(file?.size)}</div>
                        </div>
                        <div className='display-flex'>
                            <div style={{ marginRight: 20, color: "#AEAEAE", fontSize: "12px", fontWeight: "bold" }} onClick={() => { setfile({}); deleteKycDocument(userKycDetails[docType]); handleInputChange(null, docType) }}>REMOVE</div>
                            <div style={{ color: "#1089FF", fontSize: "12px", fontWeight: "bold" }} onClick={() => { handleDocClick() }}>VIEW</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const UploadComponent = ({ UploadDocument, handleInputChange, docType, userKycDetails, deleteKycDocument, documentSize, extension, status, statusType }) => {
    const [file, setfile] = useState([]);
    const [fileName, updateFileName] = useState('');
    const [showImageModal, updateShowImgModal] = useState(false);
    function handleRemove(info) {
        let temp = []
        for (let i = 0; i < file.length; i++) {
            if (file[i].uid !== info) {
                temp.push(file[i])
            }
        }
        setfile([...temp]);
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const handlePreview = async (file) => {
        if (file.type.includes('image')) {
            const src = file.url || await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
            const image = <img src={src} alt={file.name} style={{ width: 300, height: 300 }} />;
            Modal.info({
                title: file.name,
                content: image,
            });
        } else if (file.type === 'application/pdf') {
            const pdf = file.url || URL.createObjectURL(file.originFileObj);
            Modal.info({
                title: file.name,
                content: (
                    <iframe
                        src={pdf}
                        style={{ width: '100%', height: '60vh' }}
                        frameborder="0"
                    />
                ),
            });
        }
    };
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
        const isLt4M = file.size / 1024 / 1024 < 4;
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJpgOrPng) {
            Notification.error('error', "You can only upload JPG/PNG/PDF file!");
        } else if (!isLt4M && (file.type === "image/jpeg" || file.type === "image/png")) {
            Notification.error('error', "Image must be smaller than 4MB!");
        }else if(!isLt2M && file.type === "application/pdf"){
            Notification.error('error', "PDF must be smaller than 2MB!");
        } 
        return isJpgOrPng && isLt4M;
    }
    const handleUploadChange = (info, key) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            //   console.log(info.file, info.fileList);
        }
        if (status === 'done' || status === 'error') {
            uploadingProgress()
            handleUploadDocument(info.file.originFileObj)
            // Notification.success('success', `${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            Notification.error('error', `${info.file.name} file upload failed.`);
        }
    }
    const uploadingProgress = () => {
        const hide = message.loading('Upload in progress..', 0);
        // Dismiss manually and asynchronously
        setTimeout(hide, 1500);
    };
    const handleUploadDocument = (file) => {
        // updateUploadDocType(key);
        UploadDocument(
            file,
            (key) => {
                setfile(file)
                handleInputChange(key, docType)
                handleInputChange(key, docType)
            }
            // setUserKycDetails({
            // ...userKycDetails,
            // [key]: file, [key + '_type']: type,
            // })
        );

    }
    const displaySize = (size) => {
        const MB = (size / (1024 * 1024)).toFixed(2)
        return MB < 1.00 ? `${Math.round(size / 1024)} KB` : `${MB} MB`
    }
    const handleDocClick = (docType) => {
        updateFileName(userKycDetails[docType]);
        updateShowImgModal(true);
    }
    const props = {
        // name: 'file',
        // multiple: false,
        // action: "https://test.aulas.one/api/mock-api/mock-upload",
        // onChange(info) {
        //     const { status } = info.file;
        //     if (info.file.status === 'uploading') {
        //         return
        //     }
        //     if (info.file.status === "done") {
        //         getBase64(info.file.originFileObj, (imageUrl) => {
        //             message.success(`${info.file.name} file uploaded successfully.`);
        //             setfile([...file, info.file.originFileObj])
        //         })
        //     }
        // },
        // onDrop(e) {
        // },
        // onRemove(info, index) {
        //     handleRemove(info.uid);
        // }

    };
    return (
        <>
            {(userKycDetails[docType] && file?.length == 0) ?
                <>
                    <div style={{ border: status == "Rejected" ? "1px dotted #FF414D" : "2px dotted #E6E6E6", backgroundColor: status == "Rejected" ? "#FFE4D9" : "#FFFFFF", cursor: "pointer", height: 80 }}>
                        <div className='r-c-c m-t-10' style={{ padding: 10 }}>
                            <div className="ant-upload-drag-icon">
                                <img src={extension === "jpg" || extension === "image/png" ? Image : pdfImage} style={{ height: 30 }} />
                            </div>
                            <div className='r-c-sb m-l-10' style={{ width: "100%" }}>
                                <div>
                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", fontSize: "13px", fontWeight: "bold", width: "150px" }}>Document.{extension}</Typography.Text>
                                    <div style={{ color: "#AEAEAE", fontSize: "13px", fontWeight: "normal" }}>{documentSize} KB</div>
                                </div>
                                <div className='display-flex'>
                                    {status === "Approved" ? <></> : <div style={{ marginRight: 20, color: status == "Rejected" ? "#1089FF" : "#AEAEAE", fontSize: "12px", fontWeight: "bold" }} onClick={() => { setfile({}); deleteKycDocument(userKycDetails[docType]); handleInputChange(null, docType); handleInputChange(null, statusType) }}>{status == "Rejected" ? "REPLACE" : "REMOVE"}</div>}
                                    {extension === "png" || extension === "jpg" ?
                                        <div style={{ color: "#1089FF", fontSize: "12px", fontWeight: "bold" }} onClick={() => { handleDocClick() }}>VIEW</div>
                                        :
                                        <a href={`${Api._s3_url}${userKycDetails[docType]}`} target="blank">
                                            <div style={{ color: "#1089FF", fontSize: "12px", fontWeight: "bold" }}>VIEW</div>
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                (file?.type) ?
                    <div style={{ border: "2px dotted #E6E6E6", backgroundColor: "#FFFFFF", cursor: "pointer", height: 80 }}>
                        <div className='r-c-c m-t-10' style={{ padding: 10 }}>
                            <div className="ant-upload-drag-icon">
                                <img src={file?.type === "image/png" || file?.type === "image/jpeg" ? Image : pdfImage} style={{ height: 30 }} />
                            </div>
                            <div className='r-c-sb m-l-10' style={{ width: "100%" }}>
                                <div>
                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", fontSize: "13px", fontWeight: "bold", width: "150px" }}>{file?.name}</Typography.Text>
                                    <div style={{ color: "#AEAEAE", fontSize: "13px", fontWeight: "normal" }}>{displaySize(file?.size)}</div>
                                </div>
                                <div className='display-flex'>
                                    <div style={{ marginRight: 20, color: "#AEAEAE", fontSize: "12px", fontWeight: "bold" }} onClick={() => { setfile({}); deleteKycDocument(userKycDetails[docType]); handleInputChange(null, docType) }}>REMOVE</div>
                                    {file?.type === "image/png" || file?.type === "image/jpeg" ?
                                        <div style={{ color: "#1089FF", fontSize: "12px", fontWeight: "bold" }} onClick={() => { handleDocClick() }}>VIEW</div>
                                        :
                                        <a href={`${Api._s3_url}${userKycDetails[docType]}`} target="blank">
                                            <div style={{ color: "#1089FF", fontSize: "12px", fontWeight: "bold" }}>VIEW</div>
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Dragger
                        showUploadList={false}
                        className="radius-5 kyc-input-fields"
                        // {...props}
                        // maxCount={1}
                        onChange={(e) => handleUploadChange(e)}
                        beforeUpload={(e) => beforeUpload(e)}
                        onPreview={handlePreview}
                        style={{ borderColor: "#1089FF", backgroundColor: "#F3F9FF", cursor: "pointer" }}
                    >
                        <div style={{ padding: 10, display: "flex" }}>
                            <div className="ant-upload-drag-icon">
                                <img src={ic_file_eadd} style={{ height: 30 }} />
                            </div>
                            <div className='r-c-c m-l-10'>
                                <div className="ant-upload-text" style={{ color: "#AEAEAE", fontSize: "13px", fontWeight: "bold" }}>Drop file here or</div>
                                <div className="ant-upload-hint" style={{ color: "#1089FF", fontSize: "13px", fontWeight: "bold", marginLeft: "5px" }}>
                                    Click to Upload
                                </div>
                            </div>
                        </div>
                    </Dragger>
            }
            {showImageModal && <ImgViewModal visible={showImageModal} closeModal={() => updateShowImgModal(false)} fileName={userKycDetails[docType]} />}
        </>
    )
}

const PanComponent = ({ UploadDocument, handleInputChange, userKycDetails, deleteKycDocument, userKycDataContainer }) => {
    return (
        <div style={{ width: "100%", marginTop: 20 }}>
            <div style={{ width: "49%" }}>
                <LabelDiv>
                    <div style={{ color: "636363" }}>PAN Number<span style={{ color: "red" }}> *</span></div>
                </LabelDiv>
                <Input
                    value={userKycDetails?.panNo}
                    style={{
                        width: "100%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                        height: "40px"
                    }}
                    placeholder="Enter PAN number"
                    onChange={(e) => { handleInputChange(e.target.value, "panNo") }}>
                </Input>
            </div>
            <div className='r-c-sb'>
                <div style={{ width: "49%" }}>
                    <div className='r-c-sb'>
                        <LabelDiv>
                            <div style={{ color: "636363" }}>PAN Front<span style={{ color: "red" }}> *</span></div>
                        </LabelDiv>
                        {userKycDetails?.panFrontStatus == "Rejected" ?
                            <div>
                                <img src={require("../../Assets/aulasWallet/Group 17146.svg").default} /><span style={{ color: "red", marginLeft: 3, fontSize: 14, fontWeight: "bold" }}>Rejected</span>
                            </div>
                            :
                            userKycDetails?.panFrontStatus == "Approved" ?
                                <div>
                                    <img src={require("../../Assets/aulasWallet/noun-check-3496109.svg").default} /><span style={{ color: "#28DF99", marginLeft: 3, fontSize: 14, fontWeight: "bold" }}>Approved</span>
                                </div>
                                :
                                <></>
                        }
                    </div>
                    <UploadComponent
                        UploadDocument={UploadDocument}
                        handleInputChange={handleInputChange}
                        docType={"panFront"}
                        userKycDetails={userKycDetails}
                        deleteKycDocument={deleteKycDocument}
                        documentSize={userKycDetails?.panFrontSize}
                        extension={userKycDetails?.panFrontExtension}
                        status={userKycDetails?.panFrontStatus}
                        statusType={"panFrontStatus"} />
                    {userKycDataContainer?.panFrontStatus == "Rejected" ?
                        <div style={{ border: "1px solid #E4E4E4", backgroundColor: "#FFFFFF", cursor: "pointer", height: "fit-content", padding: 5, marginTop: 10 }}>
                            <div style={{ marginLeft: 5, fontSize: 12, color: "#594099", fontWeight: "bold" }}><MailOutlined /><span className='m-l-5'>APPROVER’S COMMENT</span></div>
                            <div style={{ marginTop: 5, fontSize: 14, color: "black" }}>{userKycDataContainer?.panFrontComment}</div>
                        </div>
                        :
                        <></>
                    }
                </div>
                <div style={{ width: "49%" }}>
                    <div className='r-c-sb'>
                        <LabelDiv>
                            <div style={{ color: "636363" }}>PAN Back<span style={{ color: "red" }}> *</span></div>
                        </LabelDiv>
                        {userKycDetails?.panBackStatus == "Rejected" ?
                            <div>
                                <img src={require("../../Assets/aulasWallet/Group 17146.svg").default} /><span style={{ color: "red", marginLeft: 3, fontSize: 14, fontWeight: "bold" }}>Rejected</span>
                            </div>
                            :
                            userKycDetails?.panBackStatus == "Approved" ?
                                <div>
                                    <img src={require("../../Assets/aulasWallet/noun-check-3496109.svg").default} /><span style={{ color: "#28DF99", marginLeft: 3, fontSize: 14, fontWeight: "bold" }}>Approved</span>
                                </div>
                                :

                                <></>
                        }
                    </div>
                    <UploadComponent
                        UploadDocument={UploadDocument}
                        handleInputChange={handleInputChange}
                        docType={"panBack"}
                        userKycDetails={userKycDetails}
                        deleteKycDocument={deleteKycDocument}
                        documentSize={userKycDetails?.panBackSize}
                        extension={userKycDetails?.panBackExtension}
                        status={userKycDetails?.panBackStatus}
                        statusType={"panBackStatus"} />
                    {userKycDataContainer?.panBackStatus == "Rejected" ?
                        <div style={{ border: "1px solid #E4E4E4", backgroundColor: "#FFFFFF", cursor: "pointer", height: "fit-content", padding: 5, marginTop: 10 }}>
                            <div style={{ marginLeft: 5, fontSize: 12, color: "#594099", fontWeight: "bold" }}><MailOutlined /><span className='m-l-5'>APPROVER’S COMMENT</span></div>
                            <div style={{ marginTop: 5, fontSize: 14, color: "black" }}>{userKycDataContainer?.panBackComment}</div>
                        </div>
                        :
                        <></>
                    }
                </div>
            </div>
            <div className='m-t-10' style={{ fontSize: 13, color: "#AEAEAE" }}>PAN details should be of the mentioned name only.</div>
        </div>
    )
}

function UploadDocuments({ UploadDocument, handleInputChange, userKycDetails, deleteKycDocument, userKycDataContainer }) {
    const [addressType, setAddressType] = useState('')
    const handleChange = (value) => {
        handleInputChange(value, "addressType")
        setAddressType(value)
    };
    return (
        <div style={{ width: "100%", marginTop: 20 }}>
            <div className='r-c-sb'>
                <div style={{ width: "49%" }}>
                    <LabelDiv>
                        <div style={{ color: "636363" }}>Address Proof<span style={{ color: "red" }}> *</span></div>
                    </LabelDiv>
                    <Select
                        value={userKycDetails?.addressType}
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placeholder={'Select an address proof'}
                        className="profileInputSelector"
                        defaultValue={null}
                        style={{ width: "100%", height: "40px", marginBottom: 20 }}
                        onChange={handleChange}>
                        <Option value='Aadhaar'>Aadhaar</Option>
                        <Option value='Passport'>Passport</Option>
                        <Option value='Voter Id'>Voter ID</Option>
                        <Option value='Driving Licence'>Driving license</Option>
                    </Select>
                </div>
                {!userKycDetails?.addressType ?
                    <></>
                    :
                    <div style={{ width: "49%" }}>
                        <LabelDiv>
                            <div style={{ color: "636363" }}>{userKycDetails?.addressType} Number<span style={{ color: "red" }}> *</span></div>
                        </LabelDiv>
                        <Input
                            style={{
                                width: "100%",
                                marginBottom: "20px",
                                border: "#E6E6E6 solid 1px",
                                borderRadius: "5px",
                                height: "40px"
                            }}
                            value={userKycDetails?.documentNo}
                            placeholder={"Enter" + " " + userKycDetails?.addressType + " " + "Number"}
                            onChange={(e) => { handleInputChange(e.target.value, "documentNo") }}>
                        </Input>
                    </div>
                }
            </div>
            {!userKycDetails?.addressType ?
                <></>
                :
                <>
                    <div className='r-c-sb'>
                        <div style={{ width: "49%" }}>
                            <div className='r-c-sb'>
                                <LabelDiv>
                                    <div style={{ color: "636363" }}>{userKycDetails?.addressType} Front<span style={{ color: "red" }}> *</span></div>
                                </LabelDiv>
                                {userKycDetails?.documentFrontStatus == "Rejected" ?
                                    <div>
                                        <img src={require("../../Assets/aulasWallet/Group 17146.svg").default} /><span style={{ color: "red", marginLeft: 3, fontSize: 14, fontWeight: "bold" }}>Rejected</span>
                                    </div>
                                    :
                                    userKycDetails?.documentFrontStatus == "Approved" ?
                                        <div>
                                            <img src={require("../../Assets/aulasWallet/noun-check-3496109.svg").default} /><span style={{ color: "#28DF99", marginLeft: 3, fontSize: 14, fontWeight: "bold" }}>Approved</span>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                            <UploadComponent
                                UploadDocument={UploadDocument}
                                handleInputChange={handleInputChange}
                                docType={"documentFront"}
                                userKycDetails={userKycDetails}
                                deleteKycDocument={deleteKycDocument}
                                documentSize={userKycDetails?.documentFrontSize}
                                extension={userKycDetails?.documentFrontExtension}
                                status={userKycDetails?.documentFrontStatus}
                                statusType={"documentFrontStatus"} />
                            {userKycDataContainer?.documentFrontStatus == "Rejected" ?
                                <div style={{ border: "1px solid #E4E4E4", backgroundColor: "#FFFFFF", cursor: "pointer", height: "fit-content", padding: 5, marginTop: 10 }}>
                                    <div style={{ marginLeft: 5, fontSize: 12, color: "#594099", fontWeight: "bold" }}><MailOutlined /><span className='m-l-5'>APPROVER’S COMMENT</span></div>
                                    <div style={{ marginTop: 5, fontSize: 14, color: "black" }}>{userKycDataContainer?.documentFrontComment}</div>
                                </div>
                                :
                                <></>
                            }
                        </div>
                        <div style={{ width: "49%" }}>
                            <div className='r-c-sb'>
                                <LabelDiv>
                                    <div style={{ color: "636363" }}>{userKycDetails?.addressType} Back<span style={{ color: "red" }}> *</span></div>
                                </LabelDiv>
                                {userKycDetails?.documentBackStatus == "Rejected" ?
                                    <div>
                                        <img src={require("../../Assets/aulasWallet/Group 17146.svg").default} /><span style={{ color: "red", marginLeft: 3, fontSize: 14, fontWeight: "bold" }}>Rejected</span>
                                    </div>
                                    :
                                    userKycDetails?.documentBackStatus == "Approved" ?
                                        <div>
                                            <img src={require("../../Assets/aulasWallet/noun-check-3496109.svg").default} /><span style={{ color: "#28DF99", marginLeft: 3, fontSize: 14, fontWeight: "bold" }}>Approved</span>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                            <UploadComponent
                                UploadDocument={UploadDocument}
                                handleInputChange={handleInputChange}
                                docType={"documentBack"}
                                userKycDetails={userKycDetails}
                                deleteKycDocument={deleteKycDocument}
                                documentSize={userKycDetails?.documentBackSize}
                                extension={userKycDetails?.documentBackExtension}
                                status={userKycDetails?.documentBackStatus}
                                statusType={"documentBackStatus"}
                            />
                            {userKycDataContainer?.documentBackStatus == "Rejected" ?
                                <div style={{ border: "1px solid #E4E4E4", backgroundColor: "#FFFFFF", cursor: "pointer", height: "fit-content", padding: 5, marginTop: 10 }}>
                                    <div style={{ marginLeft: 5, fontSize: 12, color: "#594099", fontWeight: "bold" }}><MailOutlined /><span className='m-l-5'>APPROVER’S COMMENT</span></div>
                                    <div style={{ marginTop: 5, fontSize: 14, color: "black" }}>{userKycDataContainer?.documentBackComment}</div>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </div>
                    <div className='m-t-10' style={{ fontSize: 13, color: "#AEAEAE" }}>{userKycDetails?.addressType ? userKycDetails?.addressType : addressType} details should be of the mentioned name only.</div>
                </>
            }
            <PanComponent
                UploadDocument={UploadDocument}
                handleInputChange={handleInputChange}
                userKycDetails={userKycDetails}
                deleteKycDocument={deleteKycDocument}
                userKycDataContainer={userKycDataContainer} />
        </div>
    )
}

const mapStateToProps = state => {
    const { userKycDataContainer } = state.aulasWallet;
    return { userKycDataContainer };
};

const mapDispatchToProps = dispatch => ({
    UploadDocument: (file, successCallback) => dispatch(UploadDocument(file, successCallback)),
    deleteKycDocument: (key) => dispatch(deleteKycDocument(key))
});


export default connect(mapStateToProps, mapDispatchToProps)(UploadDocuments);

// export default UploadDocuments