import { Modal, Radio } from 'antd';
import { Heading } from 'components/Typography';
import React, { useEffect } from 'react';
import {Button} from 'components/Button';
import { connect } from 'react-redux';
import { useState } from 'react';
import { updatePermanentlLocation, updateHolidays, getHolidays } from 'actions/profileNew';
import { useLocation } from "react-router-dom";


const UpdateGeofenceModal = ({visible, onClose, holidays, updatePermanentlLocation, user, specificUserId, pagecallback, selectedYear, updateHolidays, getHolidays}) => {
    const [currentLocation, setCurrentLocation] = useState({})
    const [buttonLoader, setButtonLoader] = useState(false)

    const location = useLocation();

    useEffect(() => {
        setCurrentLocation(({...holidays?.currentLocation}))
    }, [holidays]);

    const handleLocationSelect = (index) =>  {
        setCurrentLocation(({...holidays?.locations[index]}))
    }

    const handleUpdate = () => {
        setButtonLoader(true)
        const params = {
            geoFenceId: currentLocation.id, 
            userId: location?.pathname == "/user-management/profile" ? specificUserId : user.id,
            edit: true,
            year: selectedYear
        }
        updatePermanentlLocation(params, () => setButtonLoader(false), onClose, pagecallback)
    }

    return (
        <Modal
            visible={visible}
            footer={false}
            className="modal-round-corner"
            onCancel={()=>{
                updateHolidays([]);
                pagecallback(true)
                
                const params = {
                    geoFenceId: currentLocation.id, 
                    userId: location?.pathname == "/user-management/profile" ? specificUserId : user.id,
                    edit: true,
                    year: selectedYear
                }

                getHolidays(params, () => {pagecallback(false)})
                onClose()
            }}
        >
            <div className='r-c-c m-t-20'>
                <Heading>Holiday locations</Heading>
            </div>
            <div style={{height: "60vh", overflowY: 'auto'}}>
                {holidays?.locations?.map((location, index) => 
                <div className="m-t-20 p-5 m-l-20 r-ac cursor-pointer" style={{alignItems: 'flex-start'}} key={index} onClick={() => handleLocationSelect(index)}>
                    <Radio  checked={currentLocation?.id === location?.id} />
                    <div>
                        <div style={{fontSize: 16}}>{location?.title}</div>
                        <div style={{color: "#636363"}}>{location?.address}</div>
                    </div>
                </div>)}
            </div>
            <div className='r-c-c m-t-40'>
                <Button type="primary" loading={buttonLoader} onClick={handleUpdate} disabled={currentLocation?.id === holidays?.currentLocation?.id}>UPDATE</Button>
            </div>

        </Modal>
    );
}


const mapStateToProps = (state) => { 
    const {user} = state.session;
    const { holidays } = state.profileNew;
    const {specificUserId} = state.userManagementV2;

    return {
        user, holidays, specificUserId
    }
}

const mapDispatchToProps = (dispatch) => ({
    updatePermanentlLocation: (params, callback, successCallback, pagecallback) => dispatch(updatePermanentlLocation(params, callback, successCallback, pagecallback)),
    updateHolidays:(data) => dispatch(updateHolidays(data)),
    getHolidays: (params, callback) => dispatch(getHolidays(params, callback)),

})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateGeofenceModal);
