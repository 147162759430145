import { Bar } from '@ant-design/charts';
import { CloudDownloadOutlined, ConsoleSqlOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { setleavePageLoader } from 'actions/attendanceNew';
import { deleteZipFile, downloadInvoices, getAulasSubscriptionDetails, getBroadcastSubscriptionDetails, getStorageSubscriptionDetails, setGsSelectedTab, setOthersSelectedTab, setSubscriptionSelectedTab } from 'actions/profileNew';
import { Checkbox, Progress, Table, Typography,Tooltip } from 'antd';
import { Button } from 'components/Button';
import PageDataLoader from 'components/pageDataLoader';
import PageHeader from 'components/pageHeader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { easySwitch, TabsComponent } from './subComponents';
import { IMG_URL } from "../../env.json";


function numberWithCommas(x) {
    return x.toString()?.split('.')[0]?.length > 3 ? x?.toString()?.substring(0, x.toString()?.split('.')[0]?.length - 3)?.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString()?.substring(x.toString()?.split('.')[0]?.length - 3) : x.toString();
}

const columns =  [
    {
      title: 'Invoice',
      dataIndex: 'particulars',
      key: 'particulars',
      render: (text, row, idx) => {
        return (
            <>
                <div style={{fontSize: 16, fontWeight: 600}}>
                    {text}
                </div>
                <div style={{color: "#636363"}}>
                    {row.invoiceNo}
                </div>
            </>
        )
      }
    },
    {
      title: 'Amount',
      dataIndex: 'paid',
      key: 'paid',
        render: (text) => <div style={{fontSize: 16}}>₹ {text}</div>
    },
    {
      title: 'Payment date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: (text, record) => <div style={{fontSize: 16}}>{moment(record?.date).format("DD-MM-YYYY, hh:mm A")}</div>
    },
    {
        title: '',
        dataIndex: 'fileKey',
        key: 'fileKey',
        render: (text, row) => 
        <Button type="transparent" style={{color: 'blue'}}>
            <a href={IMG_URL + '/' + text} download target="_blank" ><CloudDownloadOutlined /></a>
        </Button>
      },
  ];

const BillingHistory = ({rowSelection, data, meta, filter, setFilter, historyTitle, handleDownload, downloadLoader, selectedRows}) => {


    return (
    <div>
         <div className='r-jsb m-t-50'>
            <div style={{fontSize: 18, fontWeight: 600}}>{historyTitle}</div>
            {selectedRows?.length > 1 ?<Button type="primary" icon={<CloudDownloadOutlined />} onClick={handleDownload} loading={downloadLoader}>DOWNLOAD</Button> :  null}
        </div>
        <div className='m-t-20'>
            <Table 
                dataSource={data || []} 
                columns={columns} 
                rowSelection={rowSelection} 
                onChange={page => setFilter({...filter, page: page.current})}
                pagination={{
                    total: meta?.totalItems,
                    pageSize: filter?.limit,
                    current: filter?.page,
                    size: 'small',
                    showTotal: (total, range) => <div>Showning {range[0]} - {range[1]} of {total}</div>
                }}
            />
        </div>
    </div>
)}

const formatFileSize = (sizeInBytes) => {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    const sizeInGB = sizeInMB / 1024;

    if (sizeInGB >= 1) {
        return parseFloat(sizeInGB.toFixed(2)) + ' GB';
    } else if (sizeInMB >= 1) {
        return parseFloat(sizeInMB.toFixed(2)) + ' MB';
    } else {
        const sizeInKB = sizeInBytes / 1024;
        return parseFloat(sizeInKB.toFixed(2)) + ' KB';
    }
};


const PlanDetails = ({plan, price, rowSelection,nextBillingDate,key, 
    billingHistory, expired, meta, redirectToRenewal, subscriptionDescription,
    filter, setFilter, buttonName, warningButtonName, warningText, warningSubtext,
    historyTitle, handleDownload, downloadLoader, selectedRows,sizeOfContents,currentStatus,totalSize, numberWithCommas, user}) => {
   
        const usedData = sizeOfContents?.sizeOfTotal || 0
        const storageLeft = sizeOfContents?.spaceAvailable || 0;
    return (
    <div>
        <div>
           {expired? 
             <div className='r-jsb'>
                <div>
                    <div style={{fontSize: 18, fontWeight: 500, color: "#FF414D"}}><ExclamationCircleOutlined />&nbsp;{warningText}</div>
                    <div style={{fontSize: 16, color: "#636363"}}>
                        {warningSubtext}
                    </div>
                </div>
                <Button type="primary" style={{ minWidth: 220}} onClick={redirectToRenewal}>{warningButtonName}</Button>
             </div>
           : 
            <>
                <div style={{fontSize: 18, fontWeight: 600}}>Your plan</div>
                <div style={{fontSize: 16, color: "#636363"}}>
                    {subscriptionDescription}
                </div>
            </>
            }
        </div>
       {plan ?  <div className='r-jsb m-t-20'>
            <div>
                <div style={{fontSize: 16, fontWeight: 500, color: "#636363"}}>Plan</div>
                <div style={{fontSize: 18, fontWeight: 600}}>
                    {plan}
                </div>
                {console.log("price",price)}
                {price == '₹ undefined per year' ? 
                <></>:
                <div style={{fontSize: 16, color: "#636363"}}>{price}</div> }
            </div>
            {expired? null: <Button type="link" style={{fontSize: 16}} onClick={redirectToRenewal}>{!user?.privilage.includes('Admin') ? buttonName : null}</Button>}
        </div> : null}
       {nextBillingDate ?  <div className='r-jsb m-t-20'>
            <div>
                <div style={{fontSize: 16, fontWeight: 500, color: "#636363"}}>Next billing date</div>
                <div style={{fontSize: 18, fontWeight: 600,}}>
                    {moment(nextBillingDate).format("DD MMM YYYY")}
                </div>
            </div>
        </div> : null}
        {subscriptionDescription === "Storage subscription for Digital library content." ? 
        (<>
        <div style={{ marginTop: '18px', marginBottom: '18px' }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <Tooltip
                    title={<div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Notes</Typography.Text>
                            <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>
                            {formatFileSize(sizeOfContents?.sizeOfLn)}
                                {/* {
                            parseFloat((parseFloat(sizeOfContents?.sizeOfLn) / 1024 / 1024).toFixed(2))} MB */}
                            </Typography.Text>
                        </div>
                    </div>}
                >
                    <div style={{ width: `${(parseFloat((parseFloat(sizeOfContents?.sizeOfLn) / 1024 / 1024) / parseFloat(totalSize * 1024)) * 100).toFixed(2)}%`, height: '40px', backgroundColor: '#9876E6', }}></div>
                </Tooltip>
                <Tooltip
                    title={<div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Videos</Typography.Text>
                            <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>
                            {formatFileSize(sizeOfContents?.sizeOfMp4Video)}

                                {/* {parseFloat((parseFloat(sizeOfContents?.sizeOfMp4Video) / 1024 / 1024).toFixed(2))} MB */}
                                </Typography.Text>
                        </div>
                    </div>}
                >
                    <div style={{ width: `${(parseFloat((parseFloat(sizeOfContents?.sizeOfMp4Video) / 1024 / 1024) / parseFloat(totalSize * 1024)) * 100).toFixed(2)}%`, height: '40px', backgroundColor: '#579EFF' }}></div>
                </Tooltip>
                <Tooltip
                    title={<div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Question Bank</Typography.Text>
                            <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>
                            {formatFileSize(sizeOfContents?.sizeOfQb)}

                                {/* {parseFloat((parseFloat(sizeOfContents?.sizeOfQb) / 1024 / 1024).toFixed(2))} MB */}
                                </Typography.Text>
                        </div>
                    </div>}
                >
                    <div style={{ width: `${(parseFloat((parseFloat(sizeOfContents?.sizeOfQb) / 1024 / 1024) / parseFloat(totalSize * 1024)) * 100).toFixed(2)}%`, height: '40px', backgroundColor: '#FF83D0' }}></div>
                </Tooltip>
                <Tooltip
                    title={<div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>

                            <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Storage Left</Typography.Text>
                            <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>
                                {/* {(parseFloat((parseFloat(totalSize * 1024 * 1024 * 1024 - (sizeOfContents?.sizeOfLn + sizeOfContents?.sizeOfQb + sizeOfContents?.sizeOfMp4Video))).toFixed(2) /1024/1024)).toFixed(2)} MB*/}
                                {formatFileSize(storageLeft)}
                                </Typography.Text> 
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                        
                        <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Used data</Typography.Text>
                            <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>
                                {/* {parseFloat(parseFloat(sizeOfContents?.sizeOfTotal/1024/1024).toFixed(2))} MB */}
                                {formatFileSize(usedData)}
                                </Typography.Text>
                        </div>

                    </div>}
                >
                    <div style={{ width: 
                    `${(parseFloat((parseFloat(sizeOfContents?.spaceAvailable) / 1024 / 1024) / parseFloat(totalSize * 1024)) * 100).toFixed(2)}%`,
                        // `${(parseFloat((parseFloat(totalSize * 1024 * 1024 * 1024 - (sizeOfContents?.sizeOfLn + sizeOfContents?.sizeOfQb + sizeOfContents?.sizeOfMp4Video))).toFixed(2) /1024/1024)).toFixed(2)}%`, 
                        height: '40px', backgroundColor: '#E6E6E6' }}></div>
                </Tooltip>
            </div>
        </div>
        <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
            <div style={{color:"#636363",fontSize:"13px",fontWeight:"530",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                <div style={{display:"flex",alignItems:"center"}}><div style={{width:"13px",height:"13px",backgroundColor:"#9876E6",marginRight:"5px"}}></div> <div>Notes</div></div>
                <div style={{display:"flex",alignItems:"center",marginLeft:"15px"}}><div style={{width:"13px",height:"13px",backgroundColor:"#579EFF",marginRight:"5px"}}></div> <div>Videos</div></div>
                 <div style={{display:"flex",alignItems:"center",marginLeft:"15px"}}><div style={{width:"13px",height:"13px",backgroundColor:"#FF83D0",marginRight:"5px"}}></div> <div>Question Bank</div></div>
            </div>
        </div>
        </>):(<><div className='m-t-10'>
        <Progress
            percent={currentStatus?.userHoursUsed / currentStatus?.totalUserHours * 100}
            status="active"
            size={"default"}
            showInfo={false}
            strokeColor={{
            "0%": "#9876E6",
            "100%": "#9876E6",
             }}
             strokeWidth={30}
             strokeLinecap={'square'}
        />
        </div>
        <div  className='m-t-15' style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={{ backgroundColor: "#9876E6", width: 15, height: 15, borderRadius: 5 }}>
            </div>
            <div className="m-l-10" style={{ fontSize: 14 }}>
                User Hours used
            </div>
            </div>
            <div className="text-xs dark-grey">
            {currentStatus?.userHoursUsed.toFixed(2)} used of {numberWithCommas(currentStatus?.totalUserHours ? currentStatus?.totalUserHours : "")} hours
            </div>
        </div></>)}
        <BillingHistory rowSelection={rowSelection} data={billingHistory} 
        meta={meta}  filter={filter} setFilter={setFilter} historyTitle={historyTitle} 
        handleDownload={handleDownload} downloadLoader={downloadLoader} selectedRows={selectedRows}/>
    </div>

)}

const tabs = (rowSelection, storageSubscriptionDetails,
     boradcastSubscriptionDetails, billingHistoryData, filter, setFilter, aulasSubscriptionDetails, handleDownload, 
     downloadLoader, selectedRows,sizeOfContents, numberWithCommas, user ) => {
    const history = useHistory()
    const tabsList = {}
    if(user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")){
        Object.assign(tabsList, {"aulas Plan": {
            body: (
                <div>
                    <div className='r-jsb'>
                        <div>
                            <div style={{fontSize: 18, fontWeight: 600}}>Your Plan</div>
                            <div style={{fontSize: 16, color: "#636363"}}>
                                aulas plan subscribed for your institute
                            </div>
                        </div>
                        <div className='text-right'>
                            <div style={{fontSize: 18, fontWeight: 600}}>₹ {aulasSubscriptionDetails?.latestSubscription?.amount}/year</div>
                            <div style={{fontSize: 16, color: "#636363"}}>
                                aulas {aulasSubscriptionDetails?.latestSubscription?.planType}
                            </div>
                        </div>
                    </div>
                    {aulasSubscriptionDetails?.latestSubscription?.nextBillingDate ?  <div className='r-jsb m-t-20'>
                        <div>
                            <div style={{fontSize: 16, fontWeight: 500, color: "#636363"}}>Next billing date</div>
                            <div style={{fontSize: 18, fontWeight: 600,}}>
                                {moment(aulasSubscriptionDetails?.latestSubscription?.nextBillingDate).format("DD MMM YYYY")}
                            </div>
                        </div>
                    </div> : null}
                    <BillingHistory rowSelection={rowSelection} data={billingHistoryData} 
                         meta={aulasSubscriptionDetails?.meta}  filter={filter} setFilter={setFilter} historyTitle={"Billing History"} 
                         handleDownload={handleDownload} downloadLoader={downloadLoader} selectedRows={selectedRows}/>
                </div>
            )
        }})
    }
    if(user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin") || user?.role?.includes("Data Operator")){
        Object.assign(tabsList, {"Storage": { 
            body: (
                !storageSubscriptionDetails?.latestSubscription  ? 
                <div>
                    <div className='r-jsb'>
                        <div>
                            <div style={{fontSize: 18, fontWeight: 600}}>No subscriptions</div>
                            <div style={{fontSize: 16, color: "#636363"}}>
                                Looks like you have not yet selected any plan.
                            </div>
                        </div>
                            <Button type="primary" style={{ minWidth: 220}} onClick={() => history.push("/digital-library/buy-storage")}>BUY STORAGE</Button>
                    </div>
                    <div>
                  </div>
                </div>
                :
                
                <PlanDetails 
                    subscriptionDescription={"Storage subscription for Digital library content."}
                    plan={`${storageSubscriptionDetails?.totalSize} GB`} 
                    nextBillingDate={storageSubscriptionDetails.latestSubscription?.nextBillingDate} 
                    price={`₹ ${storageSubscriptionDetails.latestSubscription?.paid } per year`} 
                    rowSelection={rowSelection}
                    billingHistory={billingHistoryData}
                    expired={storageSubscriptionDetails.latestSubscription?.expired}
                    paid={storageSubscriptionDetails.latestSubscription?.paid}
                    meta={storageSubscriptionDetails.meta}
                    redirectToRenewal={() => history.push("/digital-library/buy-storage")}
                    filter={filter} setFilter={setFilter}
                    buttonName="BUY STORAGE"
                    warningText="Your subscription has expired!"
                    warningSubtext="Please renew your subscription to continue uploading content on Digital library."
                    historyTitle="Billing History"
                    warningButtonName="RENEW SUBSCRIPTION"
                    downloadLoader={downloadLoader}
                    handleDownload={handleDownload}
                    selectedRows={selectedRows}
                    sizeOfContents={sizeOfContents}
                    totalSize={storageSubscriptionDetails?.totalSize}
                    user={user}
                /> 
            )
            
        }})
    }
    if(user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin") || user?.privilage?.includes("Planner")){

        Object.assign(tabsList, {"Broadcast": {
            body: (
                boradcastSubscriptionDetails?.currentStatus?.freeHours ? 
                <div>
                    <div className='r-jsb' style={{alignItems: 'center'}}>
                        <div>
                            <div style={{fontSize: 18, fontWeight: 600}}>No subscriptions</div>
                            <div style={{fontSize: 16, color: "#636363"}}>
                                Looks like you have not yet selected any plan.
                            </div>
                        </div>
                        <Button type="primary" style={{ minWidth: 220}} onClick={() => history.push("/broadcast-packages")}>Buy user hours</Button>
                    </div>
                    <div>
                        {/* <Bar {...barConfig} />; */}
                    </div>

                </div>
                :
                <PlanDetails 
                    subscriptionDescription={"Current plan for broadcast"}
                    plan={`${boradcastSubscriptionDetails?.latestSubscription?.userHours} user hours`} 
                    price={boradcastSubscriptionDetails?.currentStatus?.totalUserHours <= boradcastSubscriptionDetails?.currentStatus?.userHoursUsed ? null : `${numberWithCommas(Math.round(boradcastSubscriptionDetails?.currentStatus?.totalUserHours - boradcastSubscriptionDetails?.currentStatus?.userHoursUsed))} User hours remaining`} 
                    rowSelection={rowSelection}
                    billingHistory={billingHistoryData}
                    meta={boradcastSubscriptionDetails?.meta}
                    filter={filter} setFilter={setFilter}
                    buttonName="RECHARGE"
                    expired={boradcastSubscriptionDetails?.currentStatus?.totalUserHours <= boradcastSubscriptionDetails?.currentStatus?.userHoursUsed}
                    warningText="No user hours available!"
                    warningSubtext="You have used up all your user hours. Kindly recharge to continue using Broadcast."
                    warningButtonName="RECHARGE"
                    historyTitle="Purchase History"
                    redirectToRenewal={() => history.push("/broadcast-packages")}
                    downloadLoader={downloadLoader}
                    handleDownload={handleDownload}
                    selectedRows={selectedRows}
                    currentStatus={boradcastSubscriptionDetails?.currentStatus}
                    numberWithCommas={numberWithCommas}
                    user={user}
                /> 
                
            )
            
        }})
    }
    return tabsList
}

const Subscriptions = ({subscriptionsSelectedTab, setSubscriptionSelectedTab,
    getStorageSubscriptionDetails, storageSubscriptionDetails, 
    boradcastSubscriptionDetails, getBroadcastSubscriptionDetails, 
    aulasSubscriptionDetails, getAulasSubscriptionDetails, downloadInvoices,deleteZipFile,sizeOfContents, 
    user, setGsSelectedTab, setOthersSelectedTab}) => {
    
    const [pageLoader, setPageLoader] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [billingHistoryData, setBillingHistoryData] = useState([]);
    const [filter, setFilter] = useState({page: 1, limit: 10})
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        return () => { setSubscriptionSelectedTab('Storage')}
    }, [])

    useEffect(() => setSelectedRowKeys([]), [filter])
    
    useEffect(() => {
        setPageLoader(true)
        switch(subscriptionsSelectedTab){
            case "Storage":
                getStorageSubscriptionDetails(() => setPageLoader(false), filter)
                break;
            case "Broadcast":
                getBroadcastSubscriptionDetails(() => setPageLoader(false), filter)
                break
            case "aulas Plan":
                getAulasSubscriptionDetails(() => setPageLoader(false), filter)
                break;
            default: 
                setPageLoader(false)
        }
        setSelectedRowKeys([])
        setSelectedRows([])

    }, [subscriptionsSelectedTab, filter]);

    useEffect(() => setFilter({page: 1, limit: 10}), [subscriptionsSelectedTab])
  

    useEffect(() => {
        let historyData = [];
        
        switch(subscriptionsSelectedTab){
            case "Storage":
                historyData = storageSubscriptionDetails?.billingHistory?.map(
                    (item, index) => {
                        return {
                            key: index,
                            particulars: item.storage + " GB",
                            paid: item.paid,
                            invoiceNo: item.invoiceNo,
                            date: item.paymentDate,
                            fileKey: item.fileKey
                        }
                    }
                    ) || []
                break;
            case "Broadcast": 
                historyData = boradcastSubscriptionDetails?.billingHistory?.map(
                    (item, index) => {
                        return {
                            key: index,
                            particulars: item.userHours + " user hours",
                            paid: item.invoice?.finalCost,
                            invoiceNo: item.invoice?.invoiceNo,
                            date: item.invoice?.createdAt,
                            fileKey: item.invoice?.fileKey
                        }
                    }
                    ) || []
                break
            case "aulas Plan":
                historyData = aulasSubscriptionDetails?.invoices?.map(
                    (item, index) => {
                        return {
                            key: index,
                            particulars: "aulas " + item.planType,
                            paid: item.amount,
                            invoiceNo: item?.invoiceNo,
                            date: item?.date,
                            fileKey: item?.fileKey
                        }
                    }
                    ) || []
                break
            default: 
               historyData = []
            break
        }
        setBillingHistoryData([...historyData])

    }, [subscriptionsSelectedTab, boradcastSubscriptionDetails, storageSubscriptionDetails, aulasSubscriptionDetails])

    const history = useHistory();


    const rowSelection = {
        type: "checkbox",
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selections) => {
            setSelectedRows([...selections.map(item =>  item.fileKey)]);
            setSelectedRowKeys([...selectedRowKeys])
        },
    };

      const handleDownload = () => {
        const params = {
            type: subscriptionsSelectedTab === "Storage" ? "Storage Purchase" : subscriptionsSelectedTab === "aulas Plan" ? "Aulas Plan" : "UserHours Purchase",
            invoiceKeys: selectedRows
        }
        setDownloadLoader(true),
        downloadInvoices(params, () =>{setDownloadLoader(false)} ,(val)=>{deleteZipFile(val)}  )
      }

      const handleBackPress = () => {
        if(["/general-settings","/view-kyc", "/subscriptions", "/support-settings", "/user-profile/others"].includes(history.location?.state?.from)){
            history.push('/account-settings')
        }else{
            history.goBack()
        }
      }

    return (
        <div div={{height: "100%"}}>
            <PageHeader 
                title="Subscriptions/Plans"
                onBackPress={() => handleBackPress()}
                actions={
                    []
                }
                easySwitch={easySwitch(history, user, setGsSelectedTab, setSubscriptionSelectedTab, setOthersSelectedTab)}

            />
            <div className='r-c-c'>
                <div style={{width: "80%"}} className="m-t-50">
                    <TabsComponent dedfaultActiveKey ={'aulas Plan'}handleTabSelect={setSubscriptionSelectedTab} 
                        selectedTab={subscriptionsSelectedTab} 
                        tabs={tabs(rowSelection, storageSubscriptionDetails, 
                            boradcastSubscriptionDetails, billingHistoryData,
                            filter, setFilter, aulasSubscriptionDetails, handleDownload, 
                            downloadLoader, selectedRows,sizeOfContents, numberWithCommas, user)} 
                        rowSelection={rowSelection} 
                    />
                </div>
            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    );
}


const mapStateToProps = (state) => { 
    const {subscriptionsSelectedTab, storageSubscriptionDetails, boradcastSubscriptionDetails, aulasSubscriptionDetails} = state.profileNew;
    const {sizeOfContents} = state.digitalLibrary;
    const {user} = state.session;
    return {
        subscriptionsSelectedTab, storageSubscriptionDetails, boradcastSubscriptionDetails, aulasSubscriptionDetails,sizeOfContents, user
    }
}

const mapDispatchToProps = (dispatch) => ({
    setSubscriptionSelectedTab: (tab) => dispatch(setSubscriptionSelectedTab(tab)),
    getStorageSubscriptionDetails: (callback, filter) => dispatch(getStorageSubscriptionDetails(callback, filter)),
    getBroadcastSubscriptionDetails: (callback, filter) => dispatch(getBroadcastSubscriptionDetails(callback, filter)),
    getAulasSubscriptionDetails: (callback, filter) => dispatch(getAulasSubscriptionDetails(callback, filter)),
    downloadInvoices: (params, callback ,successCallback) => dispatch(downloadInvoices(params, callback,successCallback)),
    deleteZipFile:(params,callback) => dispatch(deleteZipFile(params,callback)),
    setGsSelectedTab: (tab) => dispatch(setGsSelectedTab(tab)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);

