import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Select, Input, Tooltip, Button } from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { createTestModal, fetchSyllabusSubjects, setOpenTab, setSelectedSubject, setSubjectSectionAction, updateFilter, updateListViewType } from "actions/testAdmin";
import { SubIcon } from 'components/subIcon';
import SearchBox from "./localComponent/searchBox";
import IconAndVal from "./localComponent/iconAndVal";
import PageDataLoader from "components/pageDataLoader";
import Loader from "./localComponent/loader";
import { NodataCard } from ".";
import CreateTestModal from './modals/createTestModal';
import { InputSearch } from "components/customInputs";
import CreateTestType from "./modals/createTestType";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { setFromClassroom } from "actions/classRoom";
const bookGreen = require("../../Assets/test/bookGreen.svg").default;
const bookRed = require("../../Assets/test/bookRed.svg").default;
const bookBlue = require("../../Assets/test/bookBlue.svg").default;
const bookPurple = require("../../Assets/test/bookPurple.svg").default;
const chapterTest = require("../../Assets/test/chapterTest.svg").default;
const fullTest = require("../../Assets/test/fullTest.svg").default;
const MathTest = require("../../Assets/test/sbj-mathematics.svg").default;
const GeoTest = require("../../Assets/test/sbj-geography.svg").default;
const ChemTest = require("../../Assets/test/sbj-chemistry.svg").default;
const MockTest = require("../../Assets/test/question-bank.svg").default;
const ICChapterTest = require("../../Assets/test/ic-chapter-test.svg").default;
let timer = false;


const PageHeader = ({ classname, syllabus,goBack, onSearch, searchVal, onCreateClick }) => {
  return (
    <div className="resultPageHeader full-width">
      <div className="r-c-fs m-r-30">
        <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} />
        <div className="text-md bold-700 m-l-20"> 
          <div>
            {syllabus}
          </div>
          <div style={{color:"#AEAEAE", fontSize:12}}>
            {classname}
          </div>
        </div>
      </div>
      <div className="testfiltersgroup">
        <InputSearch placeholder={'Search by subject name'} loading={false} onInputChange={onSearch} allowClear onClear={()=>{onSearch('')}}
                    className="m-r-10" style={{width:230}}/>
        <Button onClick={(e)=>{onCreateClick(e)}} className="purpleBtnWrap radius-100 purpleButtonHoverEffect p-r-10 p-l-10">
          + CREATE TEST
        </Button>
      </div>
    </div>
  );
};

const SyllabusSubjects = ({fetchSyllabusSubjects, selectedClassroom, filter, subjectsByClass, 
      updateFilter, setSelectedSubject, setOpenTab, loaders, createTestModal, updateListViewType, 
      user, createTestModalBool,setSubjectSectionAction, setFromClassroom, selectedSection}) => {
  const [testModalOpen, setTestModalOpen] = useState(false);
  const location = useLocation()
  const history = useHistory();
  const navigateToChapter = () => {
    history.push("insideChapter-list");
  };
  useEffect(()=>{
    setFromClassroom(false)
    if(location?.state?.key){
      setFromClassroom(true)
    }
  },[])
  useEffect(()=>{
    fetchSyllabusSubjects();
  }, []);
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
        fetchSyllabusSubjects();
    },800);
  }, [filter?.subjectSearch]);

  const goBack = () => {
    history.goBack();
  }

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);

    if (filter?.selectedSyllabus==='Chapter Test'){
      updateFilter('chapterSearch', null);
      history.push('insideChapter');
    }else{
      updateFilter('tabSearch', null);
      updateFilter('isTAdatefiltered', false);
      updateFilter('taDate', null);
      updateFilter('isVA', false);
      updateFilter('isTabPage', true);
      updateFilter('testTypeSelector', null);
      setOpenTab('draft');
      setSubjectSectionAction([{name:subject?.subjectName ,id:subject?.subjectId }])
      history.push('test-subject');
    }
  }
  const onSearch = (e)=>{
    updateFilter('subjectSearch', e)
  }
  const showModal = () => {
    setTestModalOpen(true);
  };
  const closeModal = () => {
    setTestModalOpen(false);
  }
  const onCreateClick = (value,key) => {
    showModal(); 
    updateListViewType('create');
    createTestModal('test-create', user, 'syllabus');

  }

  return (
    <div style={{backgroundColor:"#FAFAFA", margin:-20, padding:20, flex:1, height:"98vh", overflowY:"auto"}}>
      <PageHeader classname={location?.state?.key == "fromClassroom" ? `${selectedSection?.std}-${selectedSection?.section}` : `${selectedClassroom.classname} - ${selectedClassroom.section}`} syllabus={filter?.selectedSyllabus} goBack={goBack} 
                  onSearch={onSearch} searchVal={filter?.subjectSearch} onCreateClick={(e)=>{onCreateClick(e)}}/>
      {loaders?.subjectPageLoader?
      <Loader style={{fontSize:40}}/>
      :
      <>
      {subjectsByClass&&subjectsByClass?.length>0
        ?
        <>
          <div className='display-flex flex-wrap' style={{paddingBottom:60, marginLeft:-20, marginTop:20}}>
            {subjectsByClass?.map((item, index) => (
              <div className='shadow-box p-20 m-l-20 m-b-20 r-c-c-c hover-shadow cursor-pointer'
                style={{minWidth:200, height:180}} key={index}
                onClick={() => handleSubjectClick(item)}>
                  <SubIcon icon={item.icon} name={item.subjectName?item.subjectName:" "} size={70}/>
                <div className='text-xmd bold-600 color-black m-t-10'>{item.subjectName!=null?item.subjectName:"NA"}</div>
                <div className="r-c-sa full-width m-t-10">
                  <IconAndVal  val={item.subjectiveCount} refer={0}/>
                  <IconAndVal  val={item.objectiveCount} refer={1} />
                </div> 
              </div>)
            )}
          </div>
        </>
        :
        <div className="full-width full-height r-c-c">
        <NodataCard imgheight="50%" imgwidth="50%"/>
        </div>          
        }
        </>
      }
      {/* {createTestModalBool && <CreateTestModal history={history} />} */}
      {testModalOpen && <CreateTestType isModalOpen={testModalOpen} closeModal={closeModal} fromClassroom = {location?.state?.key}/>}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    selectedClassroom,
    filter,
    subjectsByClass,
    loaders,
    createTestModalBool,
    storeSubjectSection
  } = state.testAdmin;
  const {user} = state.session;
  const {selectedSection} = state.classRoom
  return {  
    selectedClassroom, 
    filter,
    subjectsByClass,
    loaders,
    user,
    createTestModalBool,
    storeSubjectSection,
    selectedSection
  };
}

const mapDispatchToProps = (dispatch) =>  ({
  fetchSyllabusSubjects: () => dispatch(fetchSyllabusSubjects()),
  updateFilter: (key, val) => dispatch(updateFilter(key, val)),
  setSelectedSubject: (val) => dispatch(setSelectedSubject(val)),
  setOpenTab: (val) => dispatch(setOpenTab(val)),
  updateListViewType: (type) => dispatch(updateListViewType(type)),
  createTestModal: (name, user, source) => dispatch(createTestModal(name, user, source)),
  setSubjectSectionAction:(val) => dispatch(setSubjectSectionAction(val)),
  setFromClassroom:(val) => dispatch(setFromClassroom(val))
})

export default connect(mapStateToProps, mapDispatchToProps)(SyllabusSubjects);