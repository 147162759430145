import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import moment from "moment";

const WeeklyAttendanceGraph = React.memo(({color,data,usersData}) => {
    const [graphData,setGraphData]=useState([])
    console.log("dataaa",data,graphData);


    useEffect(() => {
    let temp=[]
    for(let i=0;i<temp?.length;i++){
        temp[i]['showWorkingHours']=parseFloat(temp[i]?.workingHours)
        temp[i]['showHr']=temp[i]?.workingHours?.split(':')[0]
        temp[i]['showMin']=temp[i]?.workingHours?.split(':')[1]
    }
    setGraphData([...temp])
    
    }, [data])
    
    const config = {
        data,
        // minsToHrs, 
        xField: "unit",
        yField: "workingHrs",
        tooltip: {
            customContent: (val, item)=>{
             
                return(
                    <div style={{height: 70, minWidth: 100, backgroundColor: '#FFFFFF'}}>
                        <div style={{marginTop:10}}>Working hours :{" "}{" "} {item[0]?.data?.workingHrs}</div>
                        <div className="m-t-10">
                           {item[0]?.data?.percentageThanPrevDay < 0 ? '' : '+' }{item[0]?.data?.percentageThanPrevDay}% than previous day
                        </div>
                    </div>
                )
            } 
        },
        color:`${color}`,
        xAxis: {
        label: {
            autoHide: true,
            autoRotate: false,
        },
        },
        meta: {
        type: {
            alias: "Attendance",
        },
        // sales: {
        //   alias: "Attendance",
        // },
        },
        minColumnWidth: 35,
        maxColumnWidth: 35,
    };
    
    return <Column {...config} />
}
)
export default WeeklyAttendanceGraph;
