import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useCanvas } from './useCanvas'
import { drawNewShape, getShapeObject, DraggableImage, ClearCanvas, DraggableShape, drawPath } from './utils';
import './style.css'
import { applyObjectDiff, diffRecordTwoside } from './diff';
import { MdDelete } from "react-icons/md";
import deepClone from 'lodash.clonedeep'
import HistoryManager from './HistoryManager';
import ImageManager from './ImageManager';
let currentShapeDrawX = 0;
let currentShapeDrawY = 0;
let currentPathContainer = [];
let anchorPoint = -1;
let allowedShapeTypes = (ele) =>ele.shapeType==='image'||ele.shapeType==='rect'||ele.shapeType==='circle'||ele.shapeType==='line'
let offsetSkeleton = {zoomLevel:1, scaleOffset: {x:0, y:0}, panOffset: {x:0, y:0} };
let prevState = [];
let isShapeTranslating = false;
let isShapeTransforming = false;
let elementMetaPopoverHeight = 31;
let isShapeCreated = false;
let shapeInFocus = false;
let isMouseDown = false;
let ratio = window.devicePixelRatio || 2;

const generateFrame = (length, val=[]) => {
  const frame = {};
  for (let i=1; i<=length; i++){
    frame[i] = typeof val === 'object' ? Array.isArray(val) ? [...val]: {...val}: val
  }
  return frame
}

const SketchCanvasPro = forwardRef((props, ref) => {
    const [selectedElement, setSelectedElement] = useState(false);
    const [localToggler, setLocalToggler] = useState(false);
    const Platformref = useRef('android');
    const [isPanning, setIsPanning] = useState(false);
    const isPanningRef = useRef(null);
    const isFirstRender = useRef(true);
    const imageCanvasRef = useRef(null);
    const PDFCanvasRef = useRef(null);
    const downloadcanvasRef = useRef(null);
    const downloadcanvasoverlayRef = useRef(null);
    const imageGetterCanvasRef = useRef(null);
    const boardData = useRef({screenImage:null, mode:'pen', strokeColor:'black', currentShapeIndex:-1, canvasData:generateFrame(props.totalPage), currentPage:"1", totalPage:props.totalPage, offsets: generateFrame(props.totalPage, offsetSkeleton)});
    const { canvasRef, onMouseDown, clear, mouseDownPoint, overlayRef, clearOverlay } = useCanvas(drawLine, onPointerDown, onPointerUp,  boardData.current.offsets[boardData.current.currentPage], onMousePan, touchotherhandler, Platformref.current, onMousePinch, boardData.current.mode);
    function unSelectAllShape(){
      boardData.current.canvasData[boardData.current.currentPage]?.forEach((shape)=>{
        shape.isSelected = false;
      })
      setSelectedElement(false)
    }
    function clearAllCanvas(){
      ClearCanvas(imageCanvasRef)
      ClearCanvas(canvasRef)
      ClearCanvas(overlayRef)
      ClearCanvas(downloadcanvasRef)
      ClearCanvas(PDFCanvasRef)
    }
    function touchotherhandler(e){
      // console.log("51", e.changedTouches.length)
    }
    function _drawDC(pageNumber){
        ClearCanvas(downloadcanvasRef)
        ClearCanvas(downloadcanvasoverlayRef)
        let ctx = downloadcanvasRef.current.getContext('2d');
        let ctxo = downloadcanvasoverlayRef.current.getContext('2d');
        ctx.save();
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, downloadcanvasRef.current.width, downloadcanvasRef.current.height);
        ctx.restore();
        let currentPageData = boardData.current.canvasData[pageNumber];
        let images = currentPageData?.filter(item=>item.shapeType==='image')
        images?.forEach((_currentPathContainer)=>{
          ctx.globalCompositeOperation="source-over";
          _currentPathContainer.drawShape({context:ctx, drawBorders:false});
        })
        let nonImageData = currentPageData?.filter((item)=>item.shapeType!='image')
        renderNonImageData(ctxo, nonImageData, false, props.reactiveCanvas, true)
        ctx.drawImage(downloadcanvasoverlayRef.current, 0, 0, downloadcanvasoverlayRef.current.width/ratio, downloadcanvasoverlayRef.current.height/ratio)
    }

    function _drawImageGetter(pageNumber){
      ClearCanvas(imageGetterCanvasRef)
      let ctx = imageGetterCanvasRef.current.getContext('2d')
      let currentPageData = boardData.current.canvasData[pageNumber];
      let images = currentPageData?.filter(item=>(item.shapeType==='image' && item.isPDFImage))
      images?.forEach((_currentPathContainer)=>{
        ctx.globalCompositeOperation="source-over";
        _currentPathContainer.drawShape({context:ctx, drawBorders:false});
      })
    }
    function renderNonImageData(ctx, nonImageData, forceRender, reactiveCanvas, renderForDownload=false){
      nonImageData?.forEach((_currentPathContainer, index)=>{
          if (_currentPathContainer?.mode==='eraser'||_currentPathContainer?.mode==='pen'){
            drawPath({context:ctx, currentPathContainer:_currentPathContainer})
          }else if(_currentPathContainer?.mode==='shape'){
            if (!renderForDownload && !forceRender && !reactiveCanvas && _currentPathContainer?.isSelected){
              return
            }
            ctx.lineWidth = 5
            ctx.globalCompositeOperation="source-over";
            let shape = new DraggableShape(_currentPathContainer)
            shape.drawShape({context:ctx, drawBorders:reactiveCanvas?false:true})
          }
      })
    }
    function _drawTheGivenImage(){
      clearAllCanvas();
      resizeAllWithZoom();
      let ctxd = downloadcanvasRef.current.getContext('2d');
      if (boardData.current.screenImage){
        ctxd.drawImage(boardData.current.screenImage, 0, 0, downloadcanvasRef.current.width/ratio, downloadcanvasRef.current.height/ratio);
      }
      restoreAllCanvas();
    }
    function drawBoardData(canvasData, meta, forceRender=false, restore=true){
      if (props.reactiveCanvas){
        if (meta && meta.lastUpdateIsImage){
          clearAllCanvas();
        }else{
          ClearCanvas(canvasRef)
          ClearCanvas(overlayRef)
        }
      }else{
        clearAllCanvas();
      }
      if (restore){
        resizeAllWithZoom();
      }
      const ctx = canvasRef.current.getContext('2d')
      const ctximg = imageCanvasRef.current.getContext('2d')
      const ctxp = PDFCanvasRef.current.getContext('2d')
      let currentPageData = canvasData[boardData.current.currentPage];
      let nonImageData = currentPageData?.filter((item)=>item.shapeType!='image')
      if (props.reactiveCanvas){
        renderNonImageData(ctx, nonImageData, forceRender, props.reactiveCanvas);
      }
      let images = currentPageData?.filter(item=>item.shapeType==='image')
      if (props.reactiveCanvas && meta && meta.lastUpdateIsImage && images && images.length){
          ImageManager.loadAllImages(images).then(()=>{
            images.forEach((options)=>{
              let shape = new DraggableImage({...options, image: ImageManager.getImage(props.reactiveCanvas?options.url:options.localurl)})
              shape.drawShape({context:shape.isPDFImage?ctxp:ctximg, drawBorders:props.reactiveCanvas?false:true});
            })
            ctximg.restore();
          })
      }
      if (!props.reactiveCanvas){
        images?.forEach((_currentPathContainer)=>{
            if (_currentPathContainer.isSelected){
              return
            }
            ctx.globalCompositeOperation="source-over";
            if (_currentPathContainer instanceof DraggableImage){
              _currentPathContainer.drawShape({context:_currentPathContainer.isPDFImage?ctxp:ctx, drawBorders:props.reactiveCanvas?false:true});
            }
        })
      }
      renderNonImageData(ctx, nonImageData, forceRender, props.reactiveCanvas);
      if (restore){
        restoreAllCanvas();
      }
    }
    function restoreAllCanvas(){
      const ctx = canvasRef.current.getContext('2d')
      const ctximg = imageCanvasRef.current.getContext('2d')
      const ctxp = PDFCanvasRef.current.getContext('2d')
      const ctxo = overlayRef.current.getContext('2d')
      const ctxd = downloadcanvasRef.current.getContext('2d')
      ctx.restore()
      ctxo.restore()
      ctximg.restore() 
      ctxd.restore()
      ctxp.restore();
    }
    function resizeAllWithZoom(){
      resizeWithZoom(overlayRef)
      resizeWithZoom(canvasRef)
      resizeWithZoom(downloadcanvasRef)
      resizeWithZoom(imageCanvasRef)
      resizeWithZoom(PDFCanvasRef)
    }
    function resizeWithZoom(ref){
      const currentPage = boardData.current.offsets[boardData.current.currentPage]
      if (!currentPage) return
      const canvas = ref.current;
      const context = canvas.getContext('2d')
      let cw = canvas.width/ratio
      let ch = canvas.height/ratio
      const scaledWidth = cw *  currentPage.zoomLevel;
      const scaledHeight = ch *  currentPage.zoomLevel;;
      const scaleOffsetX = (scaledWidth - cw)/2;
      const scaleOffsetY = (scaledHeight - ch)/2;
      currentPage.scaleOffset.x = scaleOffsetX
      currentPage.scaleOffset.y = scaleOffsetY
      context.save();
      context.translate(
        currentPage.panOffset.x*currentPage.zoomLevel - scaleOffsetX,
        currentPage.panOffset.y*currentPage.zoomLevel - scaleOffsetY
      );
      context.scale(currentPage.zoomLevel, currentPage.zoomLevel)
  }
  function onMousePinch({currentPoint}){
    if (boardData.current?.mode!='move') return
    const delta = currentPoint.delta?currentPoint.delta:0 ;
    const currentPage = boardData.current.offsets[boardData.current.currentPage]
    const newZoom = Math.min(Math.max(1, currentPage.zoomLevel-delta * 0.01), 2);
    // newZoom *= 0.95 ** delta;
    const canvasWidth = canvasRef.current.width/ratio;
    const canvasHeight = canvasRef.current.height/ratio;
    const maxOffsetX = (canvasWidth * (newZoom - 1))/(2*ratio);
    const maxOffsetY = (canvasHeight * (newZoom - 1))/(2*ratio);
    const panOffset = currentPage.panOffset;
    panOffset.x = Math.min(maxOffsetX, Math.max(-maxOffsetX, panOffset.x));
    panOffset.y = Math.min(maxOffsetY, Math.max(-maxOffsetY, panOffset.y));
    currentPage.zoomLevel = newZoom;
    currentPage.panOffset = panOffset;
    _drawTheGivenImage()
  }
  function onMousePan({ prevPoint, currentPoint, ctx , ctxo}){
    if (boardData.current?.mode!='move' || boardData.current.offsets[boardData.current.currentPage].zoomLevel<=1) return
    const currentPage = boardData.current.offsets[boardData.current.currentPage]
    const canvasWidth = canvasRef.current.width/ratio;
    const canvasHeight = canvasRef.current.height/ratio;
    const panOffset = {
      x: currentPage.panOffset.x - currentPoint.deltaX/currentPage.zoomLevel,
      y: currentPage.panOffset.y - currentPoint.deltaY/currentPage.zoomLevel
    };
    const maxOffsetX = (canvasWidth * (currentPage.zoomLevel - 1))/(2*ratio);
    const maxOffsetY = (canvasHeight * (currentPage.zoomLevel - 1))/(2*ratio);
    panOffset.x = Math.min(maxOffsetX, Math.max(-maxOffsetX, panOffset.x));
    panOffset.y = Math.min(maxOffsetY, Math.max(-maxOffsetY, panOffset.y));
    currentPage.panOffset = panOffset
    _drawTheGivenImage()
  }

    function selectImage(selectedImage){
      unSelectAllShape();
      for (let image of boardData.current.canvasData[boardData.current.currentPage]){
        if (image.index===selectedImage.index){
          image.isSelected = true;
          setSelectedElement(image)
          drawBoardData(boardData.current.canvasData, false, false, false)
          ClearCanvas(overlayRef)
          ClearCanvas(imageCanvasRef) //obsvtn: commenting this was helping in keeping the bg image when something is selected
          let ctxo = overlayRef.current.getContext('2d')
          image.drawShape({context:ctxo})
          break;
        }
      }
    }
    function getImageObj(img, myOptions){
      const aspectRatio = img.width / img.height;
      const canvaswidth = Math.round(canvasRef.current.width/ratio);
      const canvasHeight = Math.round(canvasRef.current.height/ratio);
      const displayheight = myOptions.isPDFImage ? canvasHeight : myOptions.height;
      const displaywidth = myOptions.isPDFImage ? Math.round(aspectRatio*canvasHeight): myOptions.width;
      const displayX = myOptions.isPDFImage ? Math.round((canvaswidth-displaywidth)/2) : myOptions.x;
      const displayY = myOptions.isPDFImage ? 0 : myOptions.y; 
      const isSelected = myOptions.isPDFImage ? false : myOptions.isSelected
      return new DraggableImage({timestamp:myOptions.timestamp, image:img, url:myOptions.url, localurl:myOptions.localurl, x:displayX, y:displayY, width:displaywidth, height:displayheight, isSelected:isSelected, index:myOptions.index, isPDFImage:myOptions.isPDFImage});
    }
    function _drawPdf(myOptions, pageNo){
        const getContext = () => PDFCanvasRef.current.getContext('2d');
        const ctx = getContext();
        ctx.globalCompositeOperation="source-over";
        const image = getImageObj(ImageManager.getImage(props.reactiveCanvas?myOptions.url:myOptions.localurl), myOptions);
        if (pageNo==boardData.current.currentPage){
          image.drawShape({context:ctx});
          ctx.restore();
        }
        boardData.current.canvasData[pageNo].push(image);
        if (!myOptions.isPDFImage){
          sendAddImageDiff();
        }
    }
    function sendAddImageDiff() {
      const difftwoside = [{}, {}]
      difftwoside[0][boardData.current.canvasData[boardData.current.currentPage]?.length-1] = ["put", boardData.current.canvasData[boardData.current.currentPage].at(-1)]
      difftwoside[1][boardData.current.canvasData[boardData.current.currentPage]?.length-1] = ["delete"]
      HistoryManager.recordEvent(difftwoside)
      props.onPath({zoomLevel:1, scaleOffset: {x:0, y:0}, panOffset: {x:0, y:0}}, difftwoside[0], true);
    }
    function _drawImage(options){
      _drawPdf(options, boardData.current.currentPage);
      setTimeout(()=>{
        selectImage(options)
      }, 100);
    }

    function resizeCanvas(ref, factor) {
        const canvas = ref.current;
        if (!canvas) return
        const { width, height } = canvas.getBoundingClientRect()
        if (!factor){
          factor = {width: width, height:height}
        }
        
          // ratio = window.devicePixelRatio
          // if (!ratio){
          //   ratio = 2
          // }
          ratio = 2
          const context = canvas.getContext('2d')
          canvas.width = width*ratio*factor.width/width
          canvas.height = height*ratio*factor.height/height
          context.scale(ratio, ratio)
          return true
    
      }

    function drawLine({ prevPoint, currentPoint, ctx , ctxo}) {
      if (boardData.current.mode==='move') return
      resizeAllWithZoom()
      if (!isMouseDown) return
      if (isShapeTranslating && shapeInFocus){
        ClearCanvas(overlayRef)
        shapeInFocus.moveShapeCentre({context:ctxo, ...currentPoint})
        setSelectedElement({tx:shapeInFocus.tx, ty:shapeInFocus.ty, timestamp:shapeInFocus.timestamp})
        restoreAllCanvas();
        return
      }
      if (isShapeTransforming && shapeInFocus && anchorPoint>=0){
        ClearCanvas(overlayRef)
        shapeInFocus.extendShape({context:ctxo, anchorPoint:anchorPoint, ...currentPoint})
        setSelectedElement({tx:shapeInFocus.tx, ty:shapeInFocus.ty, timestamp:shapeInFocus.timestamp})
        restoreAllCanvas();
        return
      }
      if (boardData.current?.mode==='eraser'||boardData.current?.mode==='pen'){
        boardData.current.canvasData[boardData.current.currentPage].at(-1)?.path?.push({x:currentPoint.x, y:currentPoint.y})
        ClearCanvas(overlayRef)
        drawPath({context: boardData.current?.mode==='eraser'? ctx: ctxo, currentPathContainer: boardData.current.canvasData[boardData.current.currentPage].at(-1)})
      }else if(boardData.current?.mode==='drawShape'){
        isShapeCreated = true
        let shapeObject = getShapeObject(currentShapeDrawX, currentShapeDrawY, currentPoint.x, currentPoint.y, boardData.current.shapeType)
        clearOverlay();
        drawNewShape(ctxo, {...shapeObject, strokeColor: '#b2ccfe'})
      }else if(boardData.current?.mode==='select'){
        for (let shape of boardData.current.canvasData[boardData.current.currentPage].filter(allowedShapeTypes)){
          if (shape.isSelected){
            if (!isShapeTranslating && shape.isWithinExtendedBoundaries(currentPoint.x, currentPoint.y)){
              anchorPoint = shape.isTouchingAnchorPoints(currentPoint.x, currentPoint.y)
              if (anchorPoint>=0){
                isShapeTransforming = true
                ClearCanvas(overlayRef)
                ClearCanvas(imageCanvasRef)
                shape.extendShape({context:ctxo, anchorPoint:anchorPoint, ...currentPoint})
                setSelectedElement({tx:shape.tx, ty:shape.ty, timestamp:shape.timestamp})
                shapeInFocus = shape
                break
              }
            }
           if (!isShapeTransforming&& shape.isWithinBoundaries(currentPoint.x, currentPoint.y)){
              isShapeTranslating = true
              ClearCanvas(overlayRef)
              ClearCanvas(imageCanvasRef)
              shape.moveShapeCentre({context:ctxo, ...currentPoint})
              setSelectedElement({tx:shape.tx, ty:shape.ty, timestamp:shape.timestamp})
              shapeInFocus = shape
              break
            }
          }
        }
      }
      restoreAllCanvas();
    }
    function onPointerDown({ctx, currentPoint}){
      if (boardData.current.mode==='move') return
      resizeAllWithZoom();
      const ctxo = overlayRef.current.getContext('2d');
      isMouseDown = true
      isShapeCreated = false
      shapeInFocus = false
      if (boardData.current?.mode!='eraser'&&boardData.current?.mode!='pen'){
        prevState = deepClone(boardData.current.canvasData[boardData.current.currentPage])
      }
      const width = boardData.current?.mode==='eraser'?boardData.current.eraserWidth:boardData.current.penWidth
      if (boardData.current?.mode==='eraser'||boardData.current?.mode==='pen'){
        currentPathContainer = {index:boardData.current.canvasData[boardData.current.currentPage]?.length, mode: boardData.current.mode, width:width, color:boardData.current.strokeColor, path:[{x: currentPoint.x, y: currentPoint.y,}]};
        boardData.current.canvasData[boardData.current.currentPage].push(currentPathContainer)
        ClearCanvas(overlayRef)
        drawPath({context: boardData.current?.mode==='eraser'? ctx: ctxo, currentPathContainer: boardData.current.canvasData[boardData.current.currentPage].at(-1)})
      }else if(boardData.current?.mode==='drawShape'){
        ctx.lineWidth = 5
        ctx.globalCompositeOperation="source-over";
        currentShapeDrawX = currentPoint.x
        currentShapeDrawY = currentPoint.y
      }else if(boardData.current?.mode==='select'){
        let selected = false
        let selectedShape = false
        for (let shape of boardData.current.canvasData[boardData.current.currentPage].filter(allowedShapeTypes)){
          // store the selectedshape for delayed processing
          if (shape.isSelected){
            selectedShape = shape
            continue
          }
          // check if the pointerdown is intended at a fresh selection
          if (!shape.isPDFImage && (shape.shapeType==='image' && shape.isWithinBoundaries(currentPoint.x, currentPoint.y) || 
                 shape.shapeType!='image' && shape.isTouchingBorder(currentPoint.x, currentPoint.y))){
            if (!shape.isPDFImage){
              unSelectAllShape()
              selectImage(shape)
              selected = true
              break
            }
          }
        }
        if (!selected){
          // if nothing new is selected ensure the currentpoint is inside the last selectedshape (provided there is a last selectedshape)
          if (selectedShape){
            if (selectedShape.isWithinExtendedBoundaries(currentPoint.x, currentPoint.y)){
              unSelectAllShape()
              selectImage(selectedShape)
              selected = true
            }
          }
        }
        // still if the pointerdown is not selecting any shape, then unselect all
        if (!selected){
          unSelectAllShape();
          drawBoardData(boardData.current.canvasData, false, false, false)
        }
        selectedShape = false
      }
      restoreAllCanvas();
    }
    function onPointerUp({ctx, currentPoint, unintended}) {
      if (!isMouseDown || boardData.current.mode==='move') return
      isMouseDown = false
      if (boardData.current.mode==='drawShape'){
        currentPathContainer = new DraggableShape({
                            ...getShapeObject(currentShapeDrawX, currentShapeDrawY, currentPoint.x, currentPoint.y, boardData.current.shapeType),
                            index: boardData.current.canvasData[boardData.current.currentPage]?.length,
                            timestamp:Date.now(),
                            borderWidth: 5,
                            strokeColor: boardData.current.strokeColor
                        })
        boardData.current.canvasData[boardData.current.currentPage].push(currentPathContainer)
        clearOverlay();
        drawBoardData(boardData.current.canvasData)
      }else if(boardData.current.mode==='pen'||boardData.current.mode==='eraser'){
        resizeAllWithZoom();
        if (unintended){
          if (boardData.current.canvasData[boardData.current.currentPage].length){
            boardData.current.canvasData[boardData.current.currentPage].pop();
          }
          ClearCanvas(overlayRef);
          return
        }
        if (boardData.current.canvasData[boardData.current.currentPage].length){
          boardData.current.canvasData[boardData.current.currentPage].at(-1).timestamp = Date.now()
        }
        drawPath({context: ctx, currentPathContainer: boardData.current.canvasData[boardData.current.currentPage].at(-1)})
        restoreAllCanvas();
      }else if(boardData.current?.mode==='select'){
        for (let image of boardData.current.canvasData[boardData.current.currentPage].filter(ele=>ele.shapeType==='image')){
          if (image.isSelected){
            if (image.isDeleteTouched(currentPoint.x, currentPoint.y)){
            }
          }
        }
      } else{
        clearOverlay();
      }
      if (boardData.current.mode==='pen'||boardData.current.mode==='eraser'||(boardData.current.mode==='drawShape' && isShapeCreated)||(boardData.current?.mode==='select' && (isShapeTransforming || isShapeTranslating))){
        let difftwoside = false
        if (boardData.current.mode==='pen'||boardData.current.mode==='eraser'){
          difftwoside = [{}, {}]
          difftwoside[0][boardData.current.canvasData[boardData.current.currentPage]?.length-1] = ["put", boardData.current.canvasData[boardData.current.currentPage].at(-1)]
          difftwoside[1][boardData.current.canvasData[boardData.current.currentPage]?.length-1] = ["delete"]
          HistoryManager.recordEvent(difftwoside)
        }else{
          difftwoside = diffRecordTwoside(prevState, boardData.current.canvasData[boardData.current.currentPage])
          HistoryManager.recordEvent(deepClone(difftwoside))
        }
        props.onPath({zoomLevel:1, scaleOffset: {x:0, y:0}, panOffset: {x:0, y:0}}, difftwoside[0]);
      }
      isShapeTranslating = false
      isShapeTransforming = false
      isShapeCreated = false
      shapeInFocus = false
    }

    useEffect(()=>{
      boardData.current.mode = props.mode;
      boardData.current.penWidth = props.penWidth;
      boardData.current.eraserWidth = props.eraserWidth;    
      boardData.current.strokeColor = props.strokeColor;  
      boardData.current.shapeType = props.shapeType;
      boardData.current.currentPage = props.currentPage;
      boardData.current.totalPage = props.totalPage;
    }, [props.mode, props.penWidth, props.eraserWidth, props.strokeColor, props.shapeType, props.currentPage, props.totalPage]);
    const _resizeAll = (factor) => {
      resizeCanvas(PDFCanvasRef, factor);
      resizeCanvas(canvasRef, factor);
      resizeCanvas(overlayRef, factor);
      resizeCanvas(imageCanvasRef, factor);
      resizeCanvas(downloadcanvasRef, factor);
      resizeCanvas(downloadcanvasoverlayRef, factor);
    }
    const _setPlatform = (platform) => {
      Platformref.current = platform
      setLocalToggler((prev)=>!prev)
    }
    useEffect(()=>{
      _resizeAll(null);
    }, []);
    const getScreenImage = async () => {
      _drawDC(boardData.current.currentPage);
      const imgURL = await ImageManager.generateImageFromCanvas(downloadcanvasRef.current);
      boardData.current.screenImage = await ImageManager.loadImage(imgURL);
      _drawTheGivenImage();
      setLocalToggler((prev)=>!prev)
    }
    useEffect(()=>{
      if (!isFirstRender.current){
        unSelectAllShape();
        drawBoardData(boardData.current.canvasData)
        if (props.mode=='move'){
          getScreenImage();
        }else{
          ClearCanvas(downloadcanvasRef)
        }
      }
      if (props.mode!=='move'){
        setLocalToggler((prev)=>!prev)
      }
    }, [props.mode])
    

    function _getBoardData() {
      return boardData.current.canvasData
    }

    function _getOffsets(){
      return boardData.current.offsets
    }

    function _setBoardData(data){
      boardData.current.canvasData = data
      drawBoardData(boardData.current.canvasData)
    }

    function deleteElement(){
      let prev = boardData.current.canvasData[boardData.current.currentPage];
      let next = boardData.current.canvasData[boardData.current.currentPage].filter((item)=>item.timestamp!=selectedElement.timestamp)
      let difftwoside = diffRecordTwoside(prev, next)
      HistoryManager.recordEvent(difftwoside)
      boardData.current.canvasData[boardData.current.currentPage] = next
      drawBoardData(boardData.current.canvasData)
      setSelectedElement(false)
      props.onPath({zoomLevel:1, scaleOffset: {x:0, y:0}, panOffset: {x:0, y:0}}, difftwoside[0]);
    }

    function _undoCanvas(){
      if (boardData.current.canvasData[boardData.current.currentPage]?.length){
        let diff = HistoryManager.undo()
        if (!diff) return;
        let newState = applyObjectDiff(boardData.current.canvasData[boardData.current.currentPage], diff).filter((item)=>item!=undefined)
        boardData.current.canvasData[boardData.current.currentPage] = newState
        unSelectAllShape();
        drawBoardData(boardData.current.canvasData, false, true)
        props.onPath({zoomLevel:1, scaleOffset: {x:0, y:0}, panOffset: {x:0, y:0}}, diff);
      }
    }

    function _redoCanvas(){
      let diff = HistoryManager.redo()
      if (!diff) return;
      let newState = applyObjectDiff(boardData.current.canvasData[boardData.current.currentPage], diff).filter((item)=>item!=undefined)
      boardData.current.canvasData[boardData.current.currentPage] = newState
      unSelectAllShape();
      drawBoardData(boardData.current.canvasData, false, true)
      props.onPath({zoomLevel:1, scaleOffset: {x:0, y:0}, panOffset: {x:0, y:0}}, diff);
    }

    function _clearCanvas(pageNo=false){
      const pageToClear = pageNo?pageNo:boardData.current.currentPage
      HistoryManager.clear();
      boardData.current.offsets = generateFrame(props.totalPage, offsetSkeleton)
      boardData.current.screenImage = null
      boardData.current.canvasData[pageToClear] = [];
      clearAllCanvas()
    }

    function _clearOverlay(){
      const pageToClear = boardData.current.currentPage
      const pdfData = boardData.current.canvasData[pageToClear].filter((item)=>(item.shapeType==='image'&&item.isPDFImage));
      HistoryManager.clear();
      boardData.current.offsets = generateFrame(props.totalPage, offsetSkeleton)
      if (pdfData.length){
        boardData.current.canvasData[pageToClear] = pdfData;
        // sendAddImageDiff();
        clearAllCanvas()
        drawBoardData(boardData.current.canvasData)
      }
    }

    function _deletePage(startPage, endPage){
      for (let i=startPage; i<=endPage; i++){
        delete boardData.current.canvasData[i]
        delete boardData.current.offsets[i]
      }
      Object.keys(boardData.current.canvasData).forEach((key, index)=>{
        if (index+1<key){
          boardData.current.canvasData[index+1] = boardData.current.canvasData[key]
          delete boardData.current.canvasData[key]
          boardData.current.offsets[index+1] = boardData.current.offsets[key]
          delete boardData.current.offsets[key]
        }
      })
      HistoryManager.slice(startPage, endPage);
    }

    function _loadPaths(data){
      if (data.offsets){
        boardData.current.offsets[boardData.current.currentPage].panOffset = data.offsets.panOffset
        boardData.current.offsets[boardData.current.currentPage].scaleOffset = data.offsets.scaleOffset
        boardData.current.offsets[boardData.current.currentPage].zoomLevel  = data.offsets.zoomLevel
      }
      boardData.current.canvasData = data
      drawBoardData(data, data.meta)
    }
    function _loadPathUpdate(data){
      let newState = applyObjectDiff(boardData.current.canvasData["1"], data.data).filter((item)=>item!=undefined)
      boardData.current.canvasData["1"] = newState
      drawBoardData(boardData.current.canvasData, data.meta)
    }
    useEffect(()=>{
      if (!isFirstRender.current){
        unSelectAllShape();
        drawBoardData(boardData.current.canvasData);
        setLocalToggler((prev)=>!prev)
      }
      HistoryManager.setPage(props.currentPage)
    }, [props.currentPage])

    const _createPage = (pageNo, data=[]) => {
      boardData.current.canvasData[pageNo] = data
      boardData.current.offsets[pageNo] = {...offsetSkeleton}
      HistoryManager.generatePage(pageNo);
    }

    useEffect(()=>{
      isFirstRender.current = false
    }, []);


    useImperativeHandle(ref, ()=>({
      undoCanvas() {
        _undoCanvas()
      },
      redoCanvas(){
        _redoCanvas()
      },
      clearCanvas(){
        _clearCanvas()
      },
      getOffsets(){
        return _getOffsets()
      },
      drawImage(newImageMeta, callback){
        _drawImage(newImageMeta, true, callback)
      },
      drawPDF(options, pageNo){
        _drawPdf(options, pageNo)
      },
      loadPaths(paths){
        _loadPaths(paths)
      },
      loadPathUpdate(data){
        _loadPathUpdate(data)
      },
      getBoardData(){
        return _getBoardData()
      },
      drawDC(pagenumber){
        _drawDC(pagenumber)
      },
      drawImageGetter(pageNumber){
        _drawImageGetter(pageNumber)
      },
      setBoardData(data){
        _setBoardData(data)
      },
      resizeAll(meta){
        _resizeAll(meta)
      },
      setPlatform(platform){
        _setPlatform(platform)
      },
      createPage(pageNo, data){
        _createPage(pageNo, data)
      },
      clearOverlay(){
        _clearOverlay()
      },
      deletePage(startpageNo,endPageNo){
        _deletePage(startpageNo,endPageNo)
      },
      forceRender(){
        drawBoardData(boardData.current.canvasData)
      }
    }))
  
  return   (
    <div style={{position:"absolute", width:"100%", height:"100%"}}>
      <canvas id={"broadcast-canvas-overlay"} ref={overlayRef} onMouseDown={onMouseDown} style={{...props.style, zIndex:6 }} />
      <canvas id={"broadcast-canvas"} ref={canvasRef} style={{...props.style,  zIndex:3}}/>
      <canvas id={"broadcast-canvas-image"} ref={imageCanvasRef} style={{...props.style, zIndex:2}} />
      <canvas id={"broadcast-canvas-pdf"} ref={PDFCanvasRef} style={{...props.style, zIndex:1}} />
      <canvas id={"broadcast-canvas-download"} ref={downloadcanvasRef} style={{...props.style, zIndex:boardData.current.mode=='move'?5:0, visibility:boardData.current.mode=='move'?'visible':'hidden'}} />
      <canvas id={"broadcast-canvas-download-overlay"} ref={downloadcanvasoverlayRef} style={{...props.style, zIndex:0, visibility:'hidden'}} />
      <canvas id={"broadcast-canvas-get-image"} ref={imageGetterCanvasRef} style={{...props.style, zIndex:0, visibility:'hidden'}} />
      {
        !props.reactiveCanvas && selectedElement?
      <div className="elementMetaPopover" onClick={()=>{deleteElement()}} style={{cursor:"pointer", boxShadow: '1px 3px 10px #d4d3d3', position:"absolute", backgroundColor:"white", zIndex:25, left:selectedElement.tx, top:selectedElement.ty-elementMetaPopoverHeight-15, height:elementMetaPopoverHeight, display:"flex", flexDirection:"row", alignItems:"center", padding:10, borderRadius:5}}>
        <MdDelete style={{color:"red"}}/>
      </div>
      :null
      }
    </div>
  )
})

export default SketchCanvasPro