import React, {useEffect, useState} from "react";
import { Table ,Tag, Typography ,} from "antd";
import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import AttendanceReport from '../Modals/attendanceReport';
import "../style.css"
import { getStudentOverview,storeStudentSubjects } from 'actions/attendanceNew';
import { connect } from "react-redux";
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import { MdCastConnected, MdMoreVert } from 'react-icons/md'
import { RiWirelessChargingLine } from 'react-icons/ri'
import moment from "moment";


const TableCard=({Subjectname,time,color,attendanceStatus,showModal, studentOverviewDetail,item2,storeStudentSubjects})=>{
    console.log("item2",item2)
    return(
      <div style={{
        marginLeft: "10px",
        borderRadius: "6px",
        borderColor:  `${item2.attendanceStatus==="Present"?"#28DF99":"#FF414D"}`,
        alignItems: "center",
        border: `${item2.attendanceStatus==="Present"?"#28DF99":"#FF414D"} solid 1px`,
        minWidth: 170,
        height: 90,
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
        // borderLeft: "5px solid" ,
      }}
      onClick={()=>{
        showModal()
        storeStudentSubjects(item2)
      }}
      >
        <Tag style={{
          color:`${item2.mode==='Conference'?' #594099':
          item2.mode==='Zoom 40'?' #1089FF':item2.mode==='Broadcast'?'#FFA931':item2.mode==='Offline'?' #28DF99':null }`,
          backgroundColor: `${item2.mode==='Conference'?' #F6F4FE':
          item2.mode==='Zoom 40'?' #E7F3FF':item2.mode==='Broadcast'?'#FFF4D5':item2.mode==='Offline'?' #E9FCF5':null}`,
          borderColor:  `${item2.mode==='Conference'?' #F6F4FE':
          item2.mode==='Zoom 40'?' #E7F3FF':item2.mode==='Broadcast'?'#FFF4D5':item2.mode==='Offline'?' #E9FCF5':null}`,
          fontSize: "8px",
          fontWeight: "bold",marginLeft:"10px",
        }}>
          <div style={{display:"flex"}}>
          <div>{item2.mode==='Conference'? <RiWirelessChargingLine style={{color:"#594099"}} />:
            item2.mode==='Zoom 40'?<IoMdVideocam />:item2.mode==='Broadcast'? <MdCastConnected style={{color:"#FFA931"}} />:item2.mode==='Offline'?<IoMdChatbubbles style={{color:"#28DF99"}} />:null}
          </div>
          <div style={{marginLeft:"3px",marginTop:"-1px"}}>{item2.mode==='Conference'?'CONFERENCE':
          item2.mode==='Zoom 40'?'ZOOM 40':item2.mode==='Broadcast'?'BROADCAST':item2.mode==='Offline'?'OFFLINE':null}</div>
        </div>
          </Tag>  
        {/* <Tag  style={{
                color: "#1089FF",
                backgroundColor: "#E7F3FF",
                borderColor: "#E7F3FF",
                fontSize: "8px",
                fontWeight: "bold",marginLeft:"10px"
              }}>{item2.mode}</Tag> */}


              <div style={{display:"flex",justifyContent:"space-between"}}>
              <div className='flex-column'>
            <Typography.Text  style={{ fontWeight:"bold", color:"#191919", fontSize:"15px", width:"90px", padding:5, marginLeft:"5px" }} ellipsis={{tooltip:true}}>
                {item2.subject}
            </Typography.Text>
                <div style={{fontSize:"9px",color:"#636363",marginLeft:"10px"}}> {(moment(item2?.startTime,'hh:mm:ss').format('h:mm a'))} {}-{(moment(item2.endTime,'hh:mm:ss').format('h:mm a'))} </div>
              </div>
              <div className='flex-column m-t-5'>
        <div style={{color:`${item2.attendanceStatus==="Present"?"#28DF99":"#FF414D"}`, marginLeft: 9}}>{item2.attendanceStatus === "Present" ? <CheckCircleFilled/> : <CloseCircleFilled/> }</div>
        <div style={{fontSize:"9px",color:`${item2.attendanceStatus==="Present"?"#28DF99":"#FF414D"}`,fontWeight:"bold",marginRight:30}}>{item2.attendanceStatus}</div> 
        </div>
       </div>
      </div>
    )
  }

  const DateAndClassesCard = ({setIsModalVisible,showModal,isModalVisible,index, studentOverviewDetail,day,date,item1,storeStudentSubjects}) => {
   
    return(
      <div style={{display:"flex",flexDirection:"row"}}>
        <div style={{height:"120px",background:`${index%2===0?"#FFFFFF":"#FAFAFA"}`,width:"100%"}}>
          <div style={{display:"flex"}}>
        <div style={{marginTop:"30px",textAlign:"center",marginLeft:"30px",width:"30px"}}>
        <div style={{fontWeight:"bold",fontFamily:"roboto",fontSize:"15px",}}> {date}</div>
        <div style={{fontSize:"13px",color:"#636363",fontFamily:"roboto",}}>{day}</div>
        </div>
        <div className='p-5 m-l-40 scroll-bar-universal' style={{ display: "flex", overflowX: "scroll",height:"120px", borderLeft:"1px solid #e0e0e0"}}>
        {/* <div className='p-5 m-l-40 container-scrollbar' style={{ display: "flex",borderLeft:"1px solid #e0e0e0",height:"120px"}}> */}
        {item1?.classes?.map((item) => (
            <div style={{marginTop:"10px"}}>
            <TableCard 
            item2={item}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            showModal={showModal}
            studentOverviewDetail={studentOverviewDetail} 
            storeStudentSubjects={storeStudentSubjects}
               
               /></div>
           ))}
        </div>
        </div>
        </div>
      </div>
    )
  }
  function TableComponent({studentOverviewDetail, getStudentOverview,storeStudentSubjects}) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal=()=>{
      setIsModalVisible(true)
    }
    useEffect(() => {
        getStudentOverview()
    }, [])
    
      return(
        <div style={{marginTop:"20px",width:"100%",border: "1px solid #E6E6E6",borderRadius:"4px",background:"#FFFFFF",height:"60%"}}>
          <div style={{height:"50px",background: "#F6F4FE",borderRadius:" 5px 5px 0px 0px"}}>
            <div style={{display:"flex",fontSize:"14px",color:"#636363",fontFamily:"roboto",fontWeight:"bold",marginLeft:"30px"}}>
          <div style={{marginTop:"14px",width:"10%"}}>DATE</div>
          <div style={{marginTop:"14px",width:"80%",marginLeft:"20px"}}>CLASS(ES)</div>
          </div>
          </div>
          <div>
            {studentOverviewDetail?.attendanceTab?.attendanceDetails?.map((item,index)=>(
           
           <DateAndClassesCard
               item1={item}
               date={item?.date?.date}
               day={item?.date?.day}
               isModalVisible={isModalVisible}
               setIsModalVisible={setIsModalVisible}
               showModal={showModal}
               studentOverviewDetail={studentOverviewDetail}
               storeStudentSubjects={storeStudentSubjects}
               index={index}
               />
           
           ))}
           
          </div>
          <div>
          {isModalVisible && <AttendanceReport
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            />}
          </div>
           
        </div>
      )
}

const mapStateToProps = (state) => {
    const {studentOverviewDetail,storeSubjectDetails} = state.attendanceNew;
    return {studentOverviewDetail,storeSubjectDetails};
  };
  
  const mapDispatchToProps = (dispatch) => ({
    getStudentOverview:()=>dispatch(getStudentOverview()),
    storeStudentSubjects:(val)=>dispatch(storeStudentSubjects(val))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);
