import {
  ArrowLeftOutlined,
  CalendarOutlined,
  CaretDownOutlined,
  CheckOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  PageHeader,
  Pagination,
  Popover,
  Select,
  Input,
  Avatar,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import image from "../../Assets/feeManagement/Mask Group 2.png";
const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;
import SelectRangeModal from "./selectRangeModal";
import OfflinePayReq from "./addOfflinePayReqModal";
import AddDetailsModal from "./addDetailsModal";
import PayRequestDetailsModal from "./payReqDetails";
import { connect } from "react-redux";
import CalenderPopup from "./calenderPop";
import ConfirmModal from "./confirmationModal";

import {
  fetchTransaction,
  setAllActivitiesFilter,
  setFeeDetails,
} from "actions/feeManagement";
import PageDataLoader from "components/pageDataLoader";
import { Api } from "services";

function AllActivitiesForClass({
  fetchTransaction,
  setAllActivitiesFilter,
  feeMangementData,
  selectedStd,
  allActivitiesFilter,
  setFeeDetails,
}) {
  const [showSelectRangeModal, setShowSelectRangeModal] = useState(false);
  const [showOfflinePayReqModal, setShowOfflinePayReqModal] = useState(false);
  const [showAddDetailsModal, setShowAddDetailsModal] = useState(false);
  const [isAddOrGenModal, setIsAddOrGenModal] = useState(false);
  const [showPayRequestModal, setShowPayRequestModal] = useState(false);
  const [pageLoader, tooglePageLoader] = useState(false);
  const [payRequestData, setPayRequestData] = useState({});
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [showCustomCal, setShowCustomCal] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  let [checkBoxFilter, setCheckBoxFilter] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [values, setValues] = useState([])
  const { Search } = Input;

  const history = useHistory();

  const goBack = (history) => {
    history.goBack();
  };

  console.log("AAA-selectedStd?.id", selectedStd?.id);
  useEffect(() => {
    setAllActivitiesFilter("page", 1);
    setAllActivitiesFilter("status", null);
    setAllActivitiesFilter("mode", null);
    setAllActivitiesFilter("startDate", null);
    setAllActivitiesFilter("endDate", null);
    setAllActivitiesFilter("standardId", selectedStd?.id);
    setAllActivitiesFilter("section", [selectedStd?.id]);
    setAllActivitiesFilter("filter", null);
    setAllActivitiesFilter("search", null);
    tooglePageLoader(true);
    fetchTransaction(() => tooglePageLoader(false));
  }, []);

  const handlePageination = (value) => {
    setPage(value);
    setAllActivitiesFilter("page", value);
    tooglePageLoader(true);
    fetchTransaction(() => tooglePageLoader(false));
  };
  const handleChange = (e, index, value) => {
    checkBoxFilter[index] = e.target.checked;
    setCheckBoxFilter([...checkBoxFilter]);

    if (e.target.checked) {
      const temp = [...values]
      temp.push(value)
      setValues(temp)
      setStatus(value);
      setAllActivitiesFilter("status", temp);
      tooglePageLoader(true);
      setAllActivitiesFilter("page", 1);
      fetchTransaction(() => tooglePageLoader(false));
    } else {
      setStatus(null);
      const temp = values.filter(el => el !== value);
      setValues(temp)
      setAllActivitiesFilter("status", temp);
      tooglePageLoader(true);
      setAllActivitiesFilter("page", 1);
      fetchTransaction(() => tooglePageLoader(false));
    }
  };


  const onSearchFilter = (value) => {
    setAllActivitiesFilter("search", value);
    tooglePageLoader(true);
    setAllActivitiesFilter("page", 1);
    fetchTransaction(() => tooglePageLoader(false));
  };

  const handleFilterOption = (value) => {
    setAllActivitiesFilter("filter", value);
    tooglePageLoader(true);
    setAllActivitiesFilter("page", 1);
    fetchTransaction(() => tooglePageLoader(false));
  };

  const content = (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
      }}
    >
      <div className="dropmenuBp">
        <Checkbox
          checked={checkBoxFilter[0]}
          onChange={(e) => handleChange(e, 0, "Pending")}
        >
          Pending
        </Checkbox>
      </div>
      <div className="dropmenuBp">
        <Checkbox
          checked={checkBoxFilter[1]}
          onChange={(e) => handleChange(e, 1, "Paid")}
        >
          Paid
        </Checkbox>
      </div>
      <div className="dropmenuBp">
        <Checkbox
          checked={checkBoxFilter[2]}
          onChange={(e) => handleChange(e, 2, "Overdue")}
        >
          Overdue
        </Checkbox>
      </div>
      <div className="dropmenuBp">
        <Checkbox
          checked={checkBoxFilter[3]}
          onChange={(e) => handleChange(e, 3, "Refunded")}
        >
          Refunded
        </Checkbox>
      </div>
      <div className="dropmenuBp">
        <Checkbox
          checked={checkBoxFilter[4]}
          onChange={(e) => handleChange(e, 4, "Cancelled")}
        >
          Cancelled
        </Checkbox>
      </div>
    </div>
  );

  const content1 = (
    <div>
      {showCustomCal ? (
        <div
          style={{
            fontFamily: "roboto",
            width: "200px",
            height: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-around",
          }}
        >
          <div className="dropmenuBph" style={{ color: "#AEAEAE" }}>
            FILTER OPTIONS{" "}
            <span
              onClick={() => {
                handleFilterOption(null);
                setAllActivitiesFilter("startDate", null);
                setAllActivitiesFilter("endDate", null);
              }}
              style={{
                color: "#FF414D",
                fontWeight: "600",
                fontSize: "12px",
                marginLeft: "11px",
                cursor: "pointer",
              }}
            >
              CLEAR ALL
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => handleFilterOption("today")}
            >
              Today
            </div>
            {allActivitiesFilter?.filter == "today" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => handleFilterOption("yesterday")}
            >
              Yesterday
            </div>
            {allActivitiesFilter?.filter == "yesterday" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => handleFilterOption("month")}
            >
              This month
            </div>
            {allActivitiesFilter?.filter == "month" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => handleFilterOption("lastmonth")}
            >
              Past month
            </div>
            {allActivitiesFilter?.filter == "lastmonth" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => handleFilterOption("threemonths")}
            >
              Past 3 months
            </div>
            {allActivitiesFilter?.filter == "threemonths" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => {
                setAllActivitiesFilter("filter", "Customise range");
                setShowCustomCal(false);
              }}
            >
              Customise range
            </div>
            {allActivitiesFilter?.filter == "Customise range" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div>
          <CalenderPopup
            tooglePageLoader={tooglePageLoader}
            showCustomCal={setShowCustomCal}
          />
        </div>
      )}
    </div>
  );

  return (
    <div style={{ fontFamily: "roboto", marginTop: "-15px" }}>
      <div style={{ marginLeft: "-20px" }}>
        <PageHeader
          title={
            <div
              className="text-md"
              style={{ fontWeight: "bold", height: "45px", marginTop: "10px" }}
            >
              All activities
            </div>
          }
          onBack={() => goBack(history)}
          backIcon={<ArrowLeftOutlined />}
        />
      </div>
      <div
        style={{
          position: "absolute",
          right: "30px",
          top: "30px",
          marginTop: "-15px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "25px",
            marginBottom: "25px",
          }}
        >
          {/* <div style={{ width: "50%", marginLeft: "10px" }}> */}
          {/* <Select
                placeholder="Filter by: Classes (12)"
                allowClear={true}
                defaultValue={null}
                style={{ width: 200 }}
                // value={classId}
                // onChange={handleClassSelect}
                size="large"
                // mode="multiple"
              >
                <Option>Integrated Batch - B</Option>
                <Option>Integrated Batch - a</Option>
              </Select> */}
          {/* <Popover placement="bottomRight" content={content2} trigger="click">
              <Input
                style={{ width: "230px" }}
                placeholder={`Filter by: Classes (12)             ▼`}
                value={null}
                readOnly
              ></Input>
            </Popover> */}
          {/* </div> */}
          <div style={{ marginLeft: "20px" }}>
            <Search
              placeholder="Search"
              //   onSearch={onSearch}
              onSearch={onSearchFilter}
              style={{ width: 285 }}
              size="middle"
              allowClear={true}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "76vh",
          overflowY: "scroll",
          paddingBottom: "30px",
        }}
      >
        <table style={{ width: "100%" }}>
          <thead>
            <tr
              style={{
                backgroundColor: "#F6F4FE",
                padding: "20px",
                height: "50px",
              }}
            >
              <th style={{ color: "#636363" }}>STUDENT’S DETAILS</th>
              <th style={{ color: "#636363" }}>DESCRIPTIONS</th>
              <th style={{ color: "#636363" }}>
                ISSUE DATE
                <Popover
                  placement="leftTop"
                  content={content1}
                  trigger="click"
                >
                  <CalendarOutlined
                    onClick={() => {
                      setShowCustomCal(true);
                    }}
                    style={{
                      fontSize: "18px",
                      color: "#1089FF",
                      marginLeft: "20px",
                    }}
                  />
                </Popover>
              </th>
              <th style={{ color: "#636363" }}>
                STATUS
                <Popover
                  placement="rightTop"
                  content={content}
                  trigger="click"
                >
                  <CaretDownOutlined />
                </Popover>
              </th>
            </tr>
          </thead>
          {feeMangementData?.allActiviies?.items?.length != 0
            ? feeMangementData?.allActiviies?.items?.map((item, index) => (
              <tr
                onClick={() => {
                  setShowPayRequestModal(true);
                  setPayRequestData(item);
                  setFeeDetails("selectedTransaction", item);
                }}
                style={
                  index % 2
                    ? {
                      backgroundColor: "#FAFAFA",
                      height: "100px",
                      cursor: "pointer",
                    }
                    : {
                      height: "100px",
                      cursor: "pointer",
                    }
                }
              >
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100px",
                    marginRight: "-10px",
                  }}
                >
                  <div
                    className="r-ac"
                    style={{
                      fontFamily: "roboto",
                      marginLeft: "-20px",
                      width: "160px",
                    }}
                  >
                    <div>
                      <Avatar
                        style={{ width: "40px", height: "40px" }}
                        src={Api._s3_url + item?.user?.image}
                      />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <Typography.Text
                        ellipsis={{ tooltip: true }}
                        style={{
                          width: "130px",
                          fontWeight: "550",
                        }}
                      >
                        {item?.user?.name}
                      </Typography.Text>
                      <div style={{ color: "#636363" }}>
                        {item?.user?.student?.standard?.std} -{" "}
                        {item?.user?.student?.standard?.section}
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ height: "100px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "10px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ fontWeight: "550" }}>
                      ₹ {item?.amountPayable}
                    </div>
                    <Typography.Text
                      ellipsis={{ tooltip: true }}
                      style={{ color: "#636363", width: "200px" }}
                    >
                      {item?.note}
                    </Typography.Text>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      color: "#636363",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "10px",
                      justifyContent: "center",
                    }}
                  >
                    <div>{item?.date}</div>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        border: `1px solid ${item?.status == "Overdue"
                          ? "#FF414D"
                          : item?.status == "Paid"
                            ? "#28DF99"
                            : item?.status == "Refunded"
                              ? "#1089FF"
                              : item?.status == "Cancelled"
                                ? "#1089FF"
                                : "#FFA931"
                          }`,
                        borderRadius: "7px",
                        textAlign: "center",
                        fontSize: "10px",
                        padding: "5px",
                        fontWeight: "700",
                        width: "100px",
                        color: `${item?.status == "Overdue"
                          ? "#FF414D"
                          : item?.status == "Paid"
                            ? "#28DF99"
                            : item?.status == "Refunded"
                              ? "#1089FF"
                              : item?.status == "Cancelled"
                                ? "#1089FF"
                                : "#FFA931"
                          }`,
                        backgroundColor: `${item?.status == "Overdue"
                          ? "#FFECED"
                          : item?.status == "Paid"
                            ? "#EAFCF5"
                            : item?.status == "Refunded"
                              ? "#E7F3FF"
                              : item?.status == "Cancelled"
                                ? "#E7F3FF"
                                : "#FFF6EA"
                          }`,
                      }}
                    >
                      {item?.status}
                    </div>
                  </div>
                </td>
              </tr>
            ))
            : null}
        </table>
        {feeMangementData?.allActiviies?.items?.length == 0 ? (
          <div className="r-c-c-c" style={{ width: "100%", height: "60vh" }}>
            <div>
              <img src={illNoData} />
            </div>
            <div>
              <h4>
                <b>No data!</b>
              </h4>
            </div>
            <div>
              <h5>There are no data to show yet. Please check back later</h5>
            </div>
          </div>
        ) : null}
      </div>
      <PageDataLoader visible={pageLoader} />

      <div style={{ position: "absolute", right: "20px", bottom: "30px" }}>
        <Pagination
          current={feeMangementData?.allActiviies?.meta?.currentPage}
          defaultCurrent={1}
          total={feeMangementData?.allActiviies?.meta?.totalPages * 10}
          onChange={handlePageination}
          showSizeChanger={false}
        />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "30px",
          color: "#636363",
        }}
      >
        Showing{" "}
        {feeMangementData?.allActiviies?.meta?.itemsPerPage *
          (feeMangementData?.allActiviies?.meta?.currentPage - 1) +
          1}{" "}
        -{" "}
        {feeMangementData?.allActiviies?.meta?.itemsPerPage *
          feeMangementData?.allActiviies?.meta?.currentPage <
          feeMangementData?.allActiviies?.meta?.totalItems
          ? feeMangementData?.allActiviies?.meta?.itemsPerPage *
          feeMangementData?.allActiviies?.meta?.currentPage
          : feeMangementData?.allActiviies?.meta?.totalItems}{" "}
        of {feeMangementData?.allActiviies?.meta?.totalItems}
      </div>
      <SelectRangeModal
        isModalVisible={showSelectRangeModal}
        setShowSelectRangeModal={setShowSelectRangeModal}
      />
      {showOfflinePayReqModal && (
        <OfflinePayReq
          isModalVisible={showOfflinePayReqModal}
          setIsModalVisible={setShowOfflinePayReqModal}
          setShowAddDetailsModal={setShowAddDetailsModal}
          setIsAddOrGenModal={setIsAddOrGenModal}
        />
      )}
      {showAddDetailsModal && (
        <AddDetailsModal
          isModalVisible={showAddDetailsModal}
          setIsAddOrGenModal={setIsAddOrGenModal}
          isAddOrGenModal={isAddOrGenModal}
          handleCancel={() => {
            setShowAddDetailsModal(false);
            if (isAddOrGenModal == true) {
              setShowOfflinePayReqModal(true);
              setIsAddOrGenModal(false);
            } else {
              setIsAddOrGenModal(false);
            }
          }}
          setShowGeneratePayReq={() => { }}
        />
      )}
      {showPayRequestModal && (
        <PayRequestDetailsModal
          isModalVisible={showPayRequestModal}
          setShowPayRequestModal={setShowPayRequestModal}
          setShowConfirmationModal={setShowConfirmationModal}
          payRequestData={payRequestData}
        />
      )}
      <ConfirmModal
        setShowPayRequestModal={setShowPayRequestModal}
        setShowConfirmationModal={setShowConfirmationModal}
        isModalVisible={showConfirmationModal}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  const { feeMangementData, selectedStd, allActivitiesFilter } = state.feeManagement;
  return { feeMangementData, selectedStd, allActivitiesFilter };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTransaction: (callback) => dispatch(fetchTransaction(callback)),
  setAllActivitiesFilter: (key, res) => dispatch(setAllActivitiesFilter(key, res)),
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllActivitiesForClass);