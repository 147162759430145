import {
  TOGGLE_SIDER,
  SHOW_MENU,
  SAVE_PAGE_COORDINATES,
  SET_NOTIFICATIONS_MODAL,
  SET_MENU_WIDTH,
  SET_SCROLL_POS
} from 'actions/layout';

const initialState = {
  collapsed: false,
  showSideMenu: true,
  xCoordinate: 0,
  yCoordinate: 0,
  notificationTitle: "",
  notificationMessage: "",
  notificationType: "",
  notificationVisible: false,
  widthOfMenu: null,
  scrollPosition: 0
};

const layout = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCROLL_POS:
      return {
        ...state,
        scrollPosition: action.val,
      };
    case SET_MENU_WIDTH:
      return {
        ...state,
        widthOfMenu: action.value,
      };
    case TOGGLE_SIDER:
      return {
        ...state,
        collapsed: action.value,
      };
    case SET_NOTIFICATIONS_MODAL:
      return {
        ...state,
        notificationType: action.responseType,
        notificationTitle: action.title,
        notificationMessage: action.message,
        notificationVisible: action.visible
      }
    case SHOW_MENU:
      return {
        ...state,
        showSideMenu: action.bool,
      };
    case SAVE_PAGE_COORDINATES:
      return {
        ...state,
        xCoordinate: action.x,
        yCoordinate: action.y
      }
    default:
      return state;
  }
};

export default layout;
