import { CopyOutlined, DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { getOffers } from 'actions/offers';
import { Dropdown, Menu, Typography } from 'antd';
import newPublishIcon from "Assets/courseManagementV4/new-publish-icon.svg";
import newUnpublishIcon from "Assets/courseManagementV4/new-unpublish-icon.svg";
import { Button } from 'components/Button';
import CustomTable from 'components/customTable';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { MainSectionTypes, OfferTagText, OfferTagTextColor, OfferTypeGraphColor } from '../constants/offers';
import OffersConfirmationModal from '../modals/offersConfirmationModal';
import OfferListHeader from './offerListHeader';
const offerIconPublished = require('Assets/offers/offer-icon-published.svg').default;
const offerIconUnpublished = require('Assets/offers/offer-icon-unpublished.svg').default;
const offerIconPrivate = require('Assets/offers/offer-icon-private.svg').default;
const offerIconExpired = require('Assets/offers/offer-icon-expired.svg').default;
const offerIconBanner = require('Assets/offers/offer-icon-banner.svg').default;


function getOfferIcon(offer) {
    let offerIcon = offerIconPublished;
    if (offer.expired) {
        offerIcon = offerIconExpired;
    } else if (offer.privateOffer) {
        offerIcon = offerIconPrivate;
    } else if (!offer.published) {
        offerIcon = offerIconUnpublished;
    } else if (offer.banner) {
        offerIcon = offerIconBanner;
    }
    return offerIcon;
}

function getOfferColor(offer) {
    let offerHighlightColor = OfferTypeGraphColor.published;
    if (offer.expired) {
        offerHighlightColor = OfferTypeGraphColor.expired;
    } else if (offer.privateOffer) {
        offerHighlightColor = OfferTypeGraphColor.privateOffer;
    } else if (!offer.published) {
        offerHighlightColor = OfferTypeGraphColor.unpublished;
    } else if (offer.banner) {
        offerHighlightColor = OfferTypeGraphColor.banner;
    }
    return offerHighlightColor
}

const getOfferApplicableString = (offer) => {
    let applicableString = '';
    if (offer.type == 'course') {
        if (offer.applicableLive) {
            applicableString += 'LIVE COURSES'
        }
        if (offer.applicableVideo) {
            applicableString += `${applicableString.length ? ', ' : ''}VIDEO COURSES`;
        }
        if (offer.applicableStudy) {
            applicableString += `${applicableString.length ? ', ' : ''}STUDY MATERIALS`;
        }
        if (offer.applicableTest) {
            applicableString += `${applicableString.length ? ', ' : ''}ONLINE TEST SERIES`
        }
    } else if (offer.type == 'category') {
        applicableString += offer.categories?.reduce((acc, curr, index) => {
            if (index == 0) {
                return curr.name;
            } else {
                return acc + `, ${curr.name}`;
            }
        }, '');
    } else if (offer.type == 'subcategory') {
        applicableString += offer.subcategories?.reduce((acc, curr, index) => {
            if (index == 0) {
                return curr.name;
            } else {
                return acc + `, ${curr.name}`;
            }
        }, '');
    } else if (
        offer.type == 'liveCourse'
        || offer.type == 'videoResource'
        || offer.type == 'studyMaterial'
        || offer.type == 'testSeries'
    ) {
        applicableString += offer.course?.[0]?.name || ''
    }
    return applicableString;
}

export function OffersTableList({ offerData, setSelectedItems, selectedItems, offerFilterParams, getOffers, setMainSectionType, setIsOfferModalVisible, setSelectedOffer,
    setModalActionType, setPageLoading, pageLoading, setPreviousMainSectionType, previousMainSectionType, isModalVisible, actionType, selectedOffer, setOfferFilterParams, isFilterVisible, setFilterVisible, offersOverview, offersTypeIndex }) {
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [allSelected, setAllSelected] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [copiedId, setCopiedId] = useState(null);
    const [expandedDetailsItemId, setExpandedDetailsItemId] = useState(null);

    const rowSelection = {
        type: "checkbox",
    };

    const toggleExpansion = (itemId) => {
        setExpandedDetailsItemId(prevId => (prevId === itemId ? null : itemId));
    };

    const onRow = (record, rowIndex) => {
        return {
            style: { cursor: 'pointer' },
            onClick: (e) => {
                e.stopPropagation();
                toggleExpansion(record?.id);
            }
        }
    }
    const selectAllHandler = (e) => {
        e.stopPropagation();
        const offerItems = offerData?.items || [];
        setSelectedItems((selectedItems) => {
            if (selectedItems?.length && selectedItems?.length === offerItems.length) {
                return [];
            } else {
                setSelectedOffer({});
                return offerItems.map((offer) => ({
                    id: offer.id,
                    published: offer.published,
                    type: offer.type,
                    privateOffer: offer.privateOffer,
                    expired: offer.expired,
                    banner: offer.banner,
                }));
            }
        })
    };
    const handleRowCheckboxSelect = (row) => {
        setSelectedItems((selectedItems) => {
            const isSelected = selectedItems.find((item) => item.id == row.id);
            if (!isSelected) {
                const newItem = {
                    id: row.id,
                    published: row.published,
                    type: row.type,
                    privateOffer: row.privateOffer,
                    expired: row.expired,
                };
                return [...selectedItems, newItem];
            } else {
                return (selectedItems || []).filter((item) => item.id !== row.id);
            }
        });
    }
    useEffect(() => {
        if (!pageLoading && previousMainSectionType != 'createEdit') {
            setDataLoading(true);
        }
        setPage(1);
        if(previousMainSectionType == 'createEdit'){ // dataloading when search
            setPreviousMainSectionType('tableList');
        }
        setSelectedItems([]);
        getOffers({ ...offerFilterParams, page: 1 }, () => setDataLoading(false));
    }, [offerFilterParams.search,
    offerFilterParams.sort,
    offerFilterParams.startDate,
    offerFilterParams.endDate,
    offerFilterParams.filterTypes,
    offerFilterParams.published,
    offerFilterParams.unpublished,
    offerFilterParams.expired,
    offerFilterParams.banner,
    offerFilterParams.privateOffer,
    offerFilterParams.available,
    ])
    useEffect(() => {
        if (!pageLoading) {
            setIsLoading(true);
        }
        getOffers({ ...offerFilterParams, page }, () => { setIsLoading(false); setDataLoading(false) });
    }, [page])

    const handleCopyCode = (record) => {
        return () => {
            // eslint-disable-next-line
            navigator.clipboard.writeText(record.code || '');
            setCopiedId(record.id);
        }
    };

    const ExpandedRow = ({ record }) => {
        return (
            <div className='m-t-10' style={{ marginLeft: 40 }}>
                <article style={{ color: "#636363" }}>
                    {record.startDate || record.endDate
                        ?
                        <span>Offer</span>
                        : null
                    }
                    {record.startDate
                        ? <><span>&nbsp;starting from&nbsp;</span><span style={{ fontWeight: 700 }}>{moment(record.startDate).format('DD/MM/YYYY')}</span></>
                        : null
                    }
                    {record.endDate
                        ? <><span>&nbsp;ends on&nbsp;</span><span style={{ fontWeight: 700 }} >{moment(record.endDate).format('DD/MM/YYYY')}</span></>
                        : null
                    }
                </article>
                <article className='m-t-10' style={{ color: "#636363" }}>
                    {!record.privateOffer
                        ? <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'flex-start', justifyContent: 'flex-start', width: '100%' }}>
                            <div>Applicable for:</div>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ fontWeight: 700, color: "#636363", width: "80%", marginLeft: 5 }}>
                                {getOfferApplicableString(record) || ''}
                            </Typography.Text>
                        </div>
                        : null
                    }
                </article>
            </div>
        )
    }

    function handleSetOffer(offer) {
        setSelectedOffer({
            id: offer.id,
            published: offer.published,
            banner: offer.banner,
            privateOffer: offer.privateOffer,
            expired: offer.expired,
            type: offer.type,
        })
    }

    const menu = (offer) => {
        return (
            <Menu style={{ width: "fit-content", background: "#FFFFFF", boxShadow: "-4px 4px 16px #00000029", borderRadius: "8px", padding: "10px", marginTop: 15 }}>
                <div className='r-ac m-t-5 m-b-5 cursor-pointer three-dots-dropdown' style={{ color: "#636363", padding: "5px 10px" }} onClick={(e) => { e.stopPropagation(); setFilterVisible(false); handleSetOffer(offer); setPreviousMainSectionType('tableList'); setMainSectionType('createEdit'); }}>
                    <EditOutlined style={{ marginRight: 8, fontSize: 16 }} />EDIT
                </div>
                {!offer.privateOffer && !offer.expired ? (
                    <div className='r-ac m-t-5 m-b-5 cursor-pointer three-dots-dropdown' style={{ color: "#636363", padding: "5px 10px" }} onClick={(e) => { e.stopPropagation(); setFilterVisible(false); handleSetOffer(offer); setIsOfferModalVisible(true); setModalActionType('publish') }}>
                        <img style={{ marginRight: 8, width: 15 }} src={!offer.published ? newPublishIcon : newUnpublishIcon} alt="publish-icon" />
                        <span>{!offer.published ? 'PUBLISH' : 'UNPUBLISH'}</span>
                    </div>
                ) : null}
                <div className='r-ac m-t-5 m-b-5 cursor-pointer three-dots-dropdown' style={{ color: "#636363", padding: "5px 10px" }} onClick={(e) => { e.stopPropagation(); setFilterVisible(false); handleSetOffer(offer); setIsOfferModalVisible(true); setModalActionType('delete') }}>
                    <DeleteOutlined style={{ marginRight: 8, fontSize: 16 }} />DELETE
                </div>
                {!offer.privateOffer && !offer.expired ? (
                    <div className='r-ac m-t-5 m-b-5 cursor-pointer three-dots-dropdown' style={{ color: "#636363", padding: "5px 10px" }} onClick={(e) => { e.stopPropagation(); setFilterVisible(false); handleSetOffer(offer); setIsOfferModalVisible(true); setModalActionType('banner') }}>
                        {offer.banner ? (
                            <MinusCircleOutlined style={{ marginRight: 8, fontSize: 16 }} />
                        ) : (
                            <PlusCircleOutlined style={{ marginRight: 8, fontSize: 16 }} />
                        )}
                        <span>{!offer.banner ? 'ADD TO DISPLAY' : 'REMOVE FROM DISPLAY'}</span>
                    </div>
                ) : null}
            </Menu>
        )
    };

    function getOfferTagStyle(offer) {
        let offerTagText = OfferTagText.published;
        let offerTagTextColor = OfferTagTextColor.published;
        let offerTagBackground = OfferTypeGraphColor.published;
        if (offer.expired) {
            offerTagText = OfferTagText.expired;
            offerTagTextColor = OfferTagTextColor.expired;
            offerTagBackground = OfferTypeGraphColor.expired;
        } else if (offer.privateOffer) {
            offerTagText = OfferTagText.privateOffer;
            offerTagTextColor = OfferTagTextColor.privateOffer;
            offerTagBackground = OfferTypeGraphColor.privateOffer;
        } else if (!offer.published) {
            offerTagText = OfferTagText.unpublished;
            offerTagTextColor = OfferTagTextColor.unpublished;
            offerTagBackground = OfferTypeGraphColor.unpublished;
        } else if (offer.banner) {
            offerTagText = OfferTagText.banner;
            offerTagTextColor = OfferTagTextColor.banner;
            offerTagBackground = OfferTypeGraphColor.banner;
        }
        return {
            color: offerTagTextColor,
            backgroundColor: offerTagBackground,
            content: offerTagText,
        }
    }
    const baseColumns = [
        {
            title: 'OFFER NAME',
            dataIndex: 'name',
            key: 'title',
            width: "50%",
            render: (value, record) => {
                return (
                    <div className='r-c-fs' style={{ width: '100%', gap: '2%', overflow: "hidden" }}>
                        <img src={getOfferIcon(record)} style={{ width: '2.5rem', height: '2.5rem' }}></img>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ marginBottom: 0, width: "85%" }}>{record.title}</Typography.Text>
                    </div>
                );
            },
            renderMore: (record) => {
                return <ExpandedRow record={record} />
            }
        },
        {
            title: 'PROMO CODE',
            dataIndex: 'code',
            key: 'code',
            width: "23%",
            render: (value, record) => <div className='r-c-fs' style={{ gap: '0.5rem', marginBottom: 'auto', height: '100%', width: "90%" }}>
                <Typography.Text style={{ marginBottom: 'auto', color: "#191919", width: "80%", ...(!record.code ? { textAlign: 'center' } : null) }} ellipsis={{ rows: 1, tooltip: true }}><span>{record.code || '-'}</span></Typography.Text>
                <div className='offer-table-copy' style={{ ...(record.code && record.id == copiedId ? { display: 'block' } : null) }}>
                    {record.code
                        ? record.id == copiedId
                            ? <span style={{ fontSize: 13, color: "#191919" }} className={`copy-code${copiedId ? ' display-block' : ''}`}> Copied!</span>
                            : <div onClick={(e) => e.stopPropagation()}><CopyOutlined onClick={handleCopyCode(record)} style={{ color: "#636363" }} /></div>
                        : null
                    }
                </div>
            </div>,
        },
        {
            title: 'DISCOUNT',
            dataIndex: 'discount',
            key: 'discount',
            width: "27%",
            render: (value, record) => {
                const tagStyle = getOfferTagStyle(record);
                return (
                    <div className='r-c-fs' style={{ marginBottom: 'auto', height: '100%', width: "100%", position: 'relative' }}>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", marginRight: "0.1rem", width: "34%" }}>{`${record.discountType == 'amount' ? '₹' : ''}${record.discount}${record.discountType == 'amount' ? '' : '%'}`}</Typography.Text>
                        <section className='offer-table-type-tag' style={{ background: tagStyle.backgroundColor, width: "fit-content", color: tagStyle.color }}>
                            {tagStyle.content}
                        </section>
                        <div className='offer-table-action'>
                            <Dropdown overlay={menu(record)} placement="right" disabled={selectedItems?.length} destroyPopupOnHide={true}>
                                <img onClick={(e) => e.stopPropagation()} src={require("Assets/courseManagementV4/more-popover-img.png").default} style={{ height: 15, width: 15, ...(selectedItems?.length ? { cursor: 'not-allowed' } : null) }} />
                            </Dropdown>
                        </div>
                    </div>);
            }
        },
    ];
    const totalCount = parseInt(offersOverview?.offerCounts?.total) || 0;
    const availableCount = parseInt(offersOverview?.offerCounts?.available) || 0;
    const unavailableCount = parseInt(offersOverview?.offerCounts?.unavailable) || 0;
    const hideHeader = totalCount === 0 || (offersTypeIndex == 1 && availableCount === 0) || (offersTypeIndex === 2 && unavailableCount === 0);
    return (
        <div className="offer-table" style={{ height: "100%" }}>
            {!hideHeader ? <header className='table-list-header' style={{ height: '5%' }}>
                <OfferListHeader
                    offerFilterParams={offerFilterParams}
                    setOfferFilterParams={setOfferFilterParams}
                    setFilterVisible={setFilterVisible}
                    isFilterVisible={isFilterVisible}
                    selectAllHandler={selectAllHandler}
                    allSelected={allSelected}
                    selectedItems={selectedItems}
                    offerData={offerData}
                    setSelectedItems={setSelectedItems}
                    setMainSectionType={setMainSectionType}
                    setPreviousMainSectionType={setPreviousMainSectionType}
                    type={'tableList'}
                ></OfferListHeader>
            </header> : null}
            {offerData?.items?.length
                ? (dataLoading ?
                    <div className='r-c-c' style={{ width: "100%", height: "100%" }}>
                        <img src={require('Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                    </div>
                    :
                    <div className='m-t-15 m-l-15' style={{height:"calc(95% - 15px)"}}>
                        <CustomTable
                            style={{ width: "100%" }}
                            rowKey={record => record?.id}
                            onRow={onRow}
                            rowSelection={rowSelection}
                            dataSource={offerData?.items?.map((item) => { item.highlightColor = getOfferColor(item); return item }) || []}
                            columns={baseColumns}
                            selectedRowKeys={selectedItems?.map((item) => item.id) || []}
                            pageMeta={offerData?.meta}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            setPage={setPage}
                            page={page}
                            handleMasterCheckboxSelect={selectAllHandler}
                            handleRowCheckboxSelect={handleRowCheckboxSelect}
                            expandedDetailsItemId={expandedDetailsItemId}
                            source={"offers"}
                            rowClassName='offer-table-row'
                            expandedRowClassName='offer-expanded-row'
                            headerClassName='offer-table-header'
                        />
                    </div>
                )
                : (dataLoading ?
                    <div className='r-c-c' style={{ width: "100%", height: "100%" }}>
                        <img src={require('Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                    </div>
                    :
                    <div className={`r-c-c-c no-offers-card${hideHeader ? ' no-offers-card-full' : ''}`}>
                        <img src={require("Assets/offers/no-offers-img.png").default} style={{ width: 230 }} />
                        <div className='m-t-15' style={{ color: "#191919", fontSize: 16 }}>No offers available!</div>
                        <div className='m-t-5 m-b-30' style={{ color: "#636363" }}>There are no offers added yet.</div>
                        <Button type="primary" onClick={() => { setSelectedOffer({}), setPreviousMainSectionType('gridList'); setMainSectionType(MainSectionTypes.createEdit) }} icon={<PlusCircleOutlined />}> Add A Promotional Offer</Button>
                    </div>
                )
            }
            {isModalVisible &&
                <OffersConfirmationModal
                    isModalVisible={isModalVisible}
                    setModalVisible={setIsOfferModalVisible}
                    togglePageLoader={setPageLoading}
                    actionType={actionType}
                    selectedOfferId={selectedOffer.id}
                    selectedOfferIds={selectedItems.map((item) => item.id)}
                    isPublished={selectedItems?.length ? selectedItems.every((item) => item.published) : selectedOffer.published}
                    isBanner={selectedItems?.length ? selectedItems.every((item) => item.banner) : selectedOffer.banner}
                    offerFilterParams={offerFilterParams}
                    setSelectedItems={setSelectedItems}
                    setPage={setPage}
                    setMainSectionType={setMainSectionType}
                    previousMainSectionType={previousMainSectionType}
                />
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    const { offerData, offersOverview, offersTypeIndex } = state.offers;
    return { offerData, offersOverview, offersTypeIndex };
}

const mapDispatchToProps = (dispatch) => ({
    getOffers: (params, callback) => dispatch(getOffers(params, callback)),
})
export default connect(mapStateToProps, mapDispatchToProps)(OffersTableList);