import React from "react";
import DemoColumn from "./antGraph";

function DashBoardGraph() {
  return (
    <div>
      <DemoColumn />
    </div>
  );
}

export default DashBoardGraph;
