import React, { useState } from "react";
import { DatePicker, Space } from "antd";
import { connect } from "react-redux";
import {
  getStats,
  getUserDashBoardData,
  setEndDate,
  setStartDate,
} from "actions/userManagement";
import PageDataLoader from "components/pageDataLoader";
import moment from "moment";
const { RangePicker } = DatePicker;

const CalenderPopup = ({
  setStartDate,
  getDashBoardData,
  setEndDate,
  barGraphPlotFunction,
  togglePageLoader,
  getStats,
  dashBoardRole,
  getUserDashBoardData,
}) => {
  const [pageLoader, setPageLoader] = useState(false);

  function onChange(dates, dateStrings) {
    // console.log(dateStrings)
    setStartDate(moment(dateStrings[0],'DD-MM-YYYY').format('YYYY-MM-DD'));
    setEndDate(moment(dateStrings[1],'DD-MM-YYYY').format('YYYY-MM-DD'));
    if (dashBoardRole) {
      setPageLoader(true);
      getUserDashBoardData(() => setPageLoader(false));
    } else {
      setPageLoader(true);
      getStats(() => setPageLoader(false));
    }
    // togglePageLoader(true)
    // getDashBoardData(() => togglePageLoader(false))
  }

  return (
    <div className="rangePicker">
      <Space
        direction="vertical"
        mode={"month"}
        size={12}
        style={{ width: "240px", marginLeft: "-5px" }}
      >
        <RangePicker
          dropdownClassName={"rangePickerB"}
          placeholder={["Start Date", "End Date"]}
          onChange={onChange}
          renderExtraFooter={() => "extra footer"}
          showTime
          format={"DD-MM-YYYY"}
          getPopupContainer={(trigger) => trigger.parentElement}
        />
      </Space>
      <PageDataLoader visible={pageLoader} />
    </div>
  );
};
const mapStateToProps = (state) => {
  const { tab, dashBoardRole, isMonthOrWeek } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    isMonthOrWeek,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setStartDate: (m) => dispatch(setStartDate(m)),
  setEndDate: (m) => dispatch(setEndDate(m)),
  getStats: (callback) => dispatch(getStats(callback)),
  getUserDashBoardData: (callback) => dispatch(getUserDashBoardData(callback)),
  //   getDashBoardData: (callback) => dispatch(getDashBoardData(callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(CalenderPopup);
