import React, { useState, useEffect } from 'react'
import { Table, Typography } from 'antd';
import PageHeader from 'components/pageHeader';
import { useHistory } from 'react-router';
import { InputSearch } from 'components/customInputs';
import { getUsers, updateUserRegistrations } from 'actions/userManagementV2';
import { connect } from 'react-redux';
import { IMG_URL } from "../../env.json";
import PageDataLoader from 'components/pageDataLoader'
import { Button } from 'components/Button';
import UserDetails from './modals/userDetails';

const RegistrationRequestsVA = ({ usersList, userObj, getUsers, updateUserRegistrations, user }) => {

        const history = useHistory();

        const [search, setSearch] = useState('');
        const [selectedId, setSelectedId] = useState(null);
        const [selectedRows, setSelectedRows] = useState([]);
        const [pageLoader, togglePageLoader] = useState(false);
        const [rejectLoader, setRejectLoader] = useState(false);
        const [approveLoader, setApproveLoader] = useState(false);
        const [userDetailsData, setUserDetailsData] = useState({});
        const [selectedRowKeys, setSelectedRowKeys] = useState([]);
        const [filter, setFilter] = useState({ page: 1, limit: 25 });
        const [rejectRowLoader, setRejectRowLoader] = useState(false);
        const [approveRowLoader, setApproveRowLoader] = useState(false);
        const [userDetailsModal, setUserDetailsModal] = useState(false);

        let params = {
                userType: userObj?.userRole == 'student' ? 'Student' : 'Staff',
                status: ['Pending'],
                limit: 25,
                search,
                page: filter?.page
        };

        useEffect(() => {
                togglePageLoader(true);
                getUsers(params, () => togglePageLoader(false));
        }, [search, filter]);

        const columns = (userType, handleRowClick, user) => {
                const renderName = (value, record) => (
                        <div className='display-flex' style={{ justifyContent: 'flex-start' }}>
                                <img src={`${IMG_URL}/${record?.image ? record?.image : 'default.png'}`} alt="" style={{ width: '20px', marginRight: '10px' }} />
                                {value}
                        </div>
                );

                const baseColumns = [
                        {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                                width: '20%',
                                render: renderName,
                        },
                ];

                if (userType !== 'student') {
                        baseColumns.push({
                                title: 'Role',
                                dataIndex: 'role',
                                key: 'role',
                                render: (value, record) => <div>{record?.role || 'NA'}</div>
                        },
                                {
                                        title: 'Mobile No',
                                        dataIndex: 'mobileNo',
                                        key: 'mobileNo',
                                        render: (value, record) => <div>{record?.phone || 'NA'}</div>
                                },
                        );

                } else {
                        baseColumns.push(
                                {
                                        title: 'Mobile No',
                                        dataIndex: 'mobileNo',
                                        key: 'mobileNo',
                                        render: (value, record) => <div>{record?.phone || 'NA'}</div>
                                },
                                {
                                        title: 'Courses',
                                        dataIndex: 'courses',
                                        key: 'courses',
                                        render: (value, record) => <Typography.Text ellipsis={{ tooltip: true }}><div style={{ width: 250 }}>{record?.preferredCourse || 'NA'}</div></Typography.Text>
                                }
                        );
                }

                baseColumns.push({
                        title: 'Action',
                        dataIndex: 'status',
                        key: 'status',
                        render: (value, record, index) => {
                                return <div className='r-jsb m-l-30' style={{ width: '40%' }}>
                                        {user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin') ?
                                                <>
                                                        <Button onClick={(e) => { e.stopPropagation(); handleRowClick(record?.id, 'Rejected') }} loading={rejectRowLoader && record?.id == selectedId} type='transparent' style={{ width: '20%', padding: 0, color: '#FF414D', border: 'none' }}>REJECT</Button>
                                                        <Button onClick={(e) => { e.stopPropagation(); handleRowClick(record?.id, 'Approved') }} loading={approveRowLoader && record?.id == selectedId} type='transparent' style={{ width: '20%', border: 'none', color: '#28DF99' }}>APPROVE</Button>
                                                </>
                                                : null
                                        }
                                </div>
                        }
                });

                return baseColumns;
        };

        const actions = [
                <div className='r-jsb' style={{ width: '100%' }}>
                        {(user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes('Admin')) ?
                                <>
                                        <Button disabled={selectedRowKeys.length < 1} loading={rejectLoader} onClick={() => handleHeaderBtns(selectedRowKeys, 'Rejected')} style={{ width: '40%', padding: 0, color: 'white', marginRight: 10, backgroundColor: '#FF414D', border: 'none' }}>REJECT</Button>
                                        <Button disabled={selectedRowKeys.length < 1} loading={approveLoader} onClick={() => handleHeaderBtns(selectedRowKeys, 'Approved')} style={{ width: '20%', backgroundColor: '#28DF99', border: 'none', color: 'white', marginRight: 20 }}>APPROVE</Button>
                                </>
                                : null
                        }
                        <InputSearch style={{ width: '100%' }} allowClear placeholder='Search by name/mobile' onEnter={(e) => { setSearch(e); setSelectedRowKeys([]) }} onClear={() => setSearch('')} />
                </div>
        ];

        const rowSelection = {
                type: "checkbox",
                selectedRowKeys: selectedRowKeys,
                onChange: (selectedRowKeys, selections) => {
                        setSelectedRows([...selections?.map(item => item?.fileKey)]);
                        setSelectedRowKeys([...selectedRowKeys]);
                },
        };

        const handleHeaderBtns = (id, status) => {
                let buttonParams = {
                        requestStatuses: []
                };

                id?.forEach(id => {
                        buttonParams?.requestStatuses.push({ userId: id, status });
                });

                const setLoadingState = (loaderState) => {
                        if (status === 'Approved') {
                                setApproveLoader(loaderState);
                        } else {
                                setRejectLoader(loaderState);
                        }
                };

                const successCallBack = () => {
                        togglePageLoader(true);
                        getUsers(params, () => togglePageLoader(false));
                }

                setLoadingState(true)
                updateUserRegistrations(buttonParams, () => setLoadingState(false), () => successCallBack());

                setTimeout(() => {
                        setSelectedRowKeys([]);
                }, 2000);
        };

        const handleRowClick = (id, status) => {
                setSelectedId(id);

                let buttonParams = {
                        requestStatuses: [{
                                userId: id,
                                status
                        }]
                };

                const setLoadingState = (loaderState) => {
                        if (status === 'Approved') {
                                setApproveRowLoader(loaderState);
                        } else {
                                setRejectRowLoader(loaderState);
                        }
                };

                const successCallBack = () => {
                        togglePageLoader(true);
                        getUsers(params, () => togglePageLoader(false));
                };

                setLoadingState(true)
                updateUserRegistrations(buttonParams, () => setLoadingState(false), () => successCallBack());

                setTimeout(() => {
                        setSelectedRowKeys([]);

                }, 2000);
        };

        const onRow = (record, rowIndex) => {
                return {
                        onClick: () => {
                                setUserDetailsModal(true);
                                setUserDetailsData(record)
                        }
                }
        }

        return (
                <div>
                        <PageHeader title={userObj?.userRole == 'student' ? 'Student Registration Request' : 'Staff Registration Request'} onBackPress={() => history.goBack()} actions={actions} />
                        {pageLoader ? <></> :
                                <div className='m-t-60'>
                                        <Table
                                                style={{ width: "100vw" }}
                                                rowSelection={rowSelection}
                                                columns={columns(userObj?.userRole, handleRowClick, user)}
                                                dataSource={usersList?.items || []}
                                                rowKey={(record) => record.id}
                                                onRow={onRow}
                                                size='small'
                                                pagination={{
                                                        total: usersList?.meta?.totalItems,
                                                        pageSize: filter?.limit,
                                                        current: filter?.page,
                                                        size: 'small',
                                                        showTotal: (total, range) => <div>Showing {range[0]} - {range[1]} of {total}</div>,
                                                        onChange: (page) => setFilter({ ...filter, page }),
                                                        showSizeChanger: false
                                                }}
                                        />
                                </div>
                        }
                        <PageDataLoader visible={pageLoader} />
                        {userDetailsModal &&
                                <UserDetails open={userDetailsModal} onClose={() => setUserDetailsModal(false)} data={userDetailsData} updateUserRegistrations={updateUserRegistrations} getUsers={getUsers} params={params} togglePageLoader={togglePageLoader} />
                        }
                </div>
        )
};

const mapStateToProps = (state) => {
        const { user } = state.session
        const { usersList, userObj } = state.userManagementV2;
        return { usersList, userObj, user }
};

const mapDispatchToProps = (dispatch) => ({
        getUsers: (params, callback) => dispatch(getUsers(params, callback)),
        updateUserRegistrations: (params, callback, successCallback) => dispatch(updateUserRegistrations(params, callback, successCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationRequestsVA)
