import { Tabs } from 'antd';
import PageHeader from 'components/pageHeader'
import { DetailSection } from 'pages/profile/subComponents';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { studentBasicKycDetails, studentContactKycDetails, studentKycDetails, validateEmail } from './studentKycData';
import { getAddressFromPin } from '../profile/postalApi';
import { Button } from 'components/Button';
import ConfirmationModal from './modal/confirmationModal';
import { connect } from 'react-redux';
import UploadDocuments from './uploadDocuments';
import { getUserKycDetails, postUpdateUserKyc, setNumberVerified, setSubmitClicked } from 'actions/aulasWallet';
import moment from 'moment';
import PageDataLoader from 'components/pageDataLoader';

const bodyStyle = {
  background: "#FAFAFA",
  margin: -20,
  padding: 20,
  overflow: "hidden",
  marginTop: 20,
};

function CompleteStudentKyc({ isNumberVerified, setNumberVerified, postUpdateUserKyc, getUserKycDetails, userKycDataContainer, walletDashboardData, user, setSubmitClicked }) {
  const [userKycDetails, setUserKycDetails] = useState({});
  const [tabKey, setTabKey] = useState('1');
  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [submitKycLoader, setSubmitKycLoader] = useState(false);
  const [pageLoader, togglePageLoader] = useState(false);
  const [confirmModalSaveLoader, setConfirmModalSaveLoader] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const history = useHistory();
  const onChange = (key) => {
    if (tabKey == '3') {
      setTabKey(key)
    } if (tabKey == "2" && key == '1') {
      setTabKey(key)
    }
  };
  useEffect(() => {
    setSubmitClicked(false)
    setNumberVerified(false);
  }, [])
  useEffect(() => {
    if (walletDashboardData?.kycstatus == "Pending" || walletDashboardData?.kycstatus == "Rejected") {
      if (walletDashboardData?.kycstatus == "Rejected") {
        setTabKey("3")
      } else {
        setTabKey("1")
      }
      togglePageLoader(true)
      getUserKycDetails(() => togglePageLoader(false));
      const addressData = userKycDataContainer?.address;
      const parsedData = addressData != undefined || addressData != null ? JSON.parse(addressData) : ""
      setUserKycDetails({
        name: userKycDataContainer?.name,
        phone: userKycDataContainer?.phone,
        email: userKycDataContainer?.email,
        gender: userKycDataContainer?.gender,
        dob: (!userKycDataContainer?.dob) ? null : userKycDataContainer?.dob,
        relation: userKycDataContainer?.relation,
        relationType: userKycDataContainer?.relationType,
        h_no: parsedData?.locality,
        pincode: parsedData?.pincode,
        state: parsedData?.state,
        country: parsedData?.country,
        city: parsedData?.city,
        addressType: userKycDataContainer?.addressType,
        documentNo: userKycDataContainer?.documentNo,
        documentFront: userKycDataContainer?.documentFront,
        documentBack: userKycDataContainer?.documentBack,
        panNo: userKycDataContainer?.panNo,
        panFront: userKycDataContainer?.panFront,
        panBack: userKycDataContainer?.panBack,
        documentBackStatus: userKycDataContainer?.documentBackStatus,
        documentFrontStatus: userKycDataContainer?.documentFrontStatus,
        documentFrontComment: userKycDataContainer?.documentFrontComment,
        documentBackComment: userKycDataContainer?.documentBackComment,
        documentFrontSize: userKycDataContainer?.fileDetails?.documentFront?.sizeInKB,
        documentBackSize: userKycDataContainer?.fileDetails?.documentBack?.sizeInKB,
        panFrontStatus: userKycDataContainer?.panFrontStatus,
        panBackStatus: userKycDataContainer?.panBackStatus,
        panFrontComment: userKycDataContainer?.panFrontComment,
        panBackComment: userKycDataContainer?.panBackComment,
        panFrontSize: userKycDataContainer?.fileDetails?.panFront?.sizeInKB,
        panBackSize: userKycDataContainer?.fileDetails?.panBack?.sizeInKB,
        documentFrontExtension: userKycDataContainer?.documentFrontExtension,
        documentBackExtension: userKycDataContainer?.documentBackExtension,
        panFrontExtension: userKycDataContainer?.panFrontExtension,
        panBackExtension: userKycDataContainer?.panBackExtension
      })
      if (userKycDataContainer?.phoneVerification == "Success") {
        setNumberVerified(true)
      }
    }
  }, [userKycDataContainer?.name, userKycDataContainer?.phone, userKycDataContainer?.email,
  userKycDataContainer?.gender, userKycDataContainer?.dob, userKycDataContainer?.relation, userKycDataContainer?.relationType,
  userKycDataContainer?.address, userKycDataContainer?.addressType, userKycDataContainer?.documentNo,
  userKycDataContainer?.documentFront, userKycDataContainer?.documentBack, userKycDataContainer?.panNo,
  userKycDataContainer?.panFront, userKycDataContainer?.panBack])

  const hendlePinChange = (pincode) => {
    getAddressFromPin(pincode, (district, state, country) => {
      handleInputChange(district, 'city')
      handleInputChange(state, 'state')
      handleInputChange(country, 'country')
    })
  }
  const handleInputChange = (val, key) => {
    userKycDetails[key] = val;
    setUserKycDetails({ ...userKycDetails })
  }

  const handleFocusChange = (val, key) => {
    if (key) {
      if (val !== "" && val?.length < 6) {
        userKycDetails.pincodeValid = true;
        handleInputChange(null, 'city')
        handleInputChange(null, 'state')
        handleInputChange(null, 'country')
      } else {
        userKycDetails.pincodeValid = undefined;
      }
      if (key === "pincode" && val.length === 6) {
        hendlePinChange(val)
      }
    } else {
      userKycDetails[key] = val;
    }
    setUserKycDetails({ ...userKycDetails })
  }

  let disableProcced1;
  if ((userKycDetails?.name == '' || userKycDetails?.name == undefined) || (userKycDetails?.phone == '' || userKycDetails?.phone == undefined) || (userKycDetails?.email == '' || userKycDetails?.email == undefined || validateEmail(userKycDetails?.email)) || (userKycDetails?.gender == '' || userKycDetails?.gender == undefined) || (userKycDetails?.dob == '' || userKycDetails?.dob == undefined) || (userKycDetails?.relation == '' || userKycDetails?.relation == undefined) || (userKycDetails?.relation == "Others" && (userKycDetails?.relationType == '' || userKycDetails?.relationType == undefined)) || !isNumberVerified) {
    disableProcced1 = true
  } else {
    disableProcced1 = false
  }

  let disableProcced2;
  if ((userKycDetails?.h_no == '' || userKycDetails?.h_no == undefined) || (userKycDetails?.pincode == '' || userKycDetails?.pincode == undefined) || (userKycDetails?.state == '' || userKycDetails?.state == undefined) || (userKycDetails?.city == '' || userKycDetails?.city == undefined) || (userKycDetails?.country == '' || userKycDetails?.country == undefined) || userKycDetails.pincodeValid) {
    disableProcced2 = true
  } else {
    disableProcced2 = false
  }

  let disableSubmit;
  if ((userKycDetails?.addressType == '' || userKycDetails?.addressType == undefined) || (userKycDetails?.documentNo == '' || userKycDetails?.documentNo == undefined) || (userKycDetails?.documentFront == '' || userKycDetails?.documentFront == undefined) || (userKycDetails?.documentBack == '' || userKycDetails?.documentBack == undefined) || (userKycDetails?.panNo == '' || userKycDetails?.panNo == undefined) || (userKycDetails?.panFront == '' || userKycDetails?.panFront == undefined) || (userKycDetails?.panBack == '' || userKycDetails?.panBack == undefined) || userKycDetails?.documentFrontStatus == "Rejected" || userKycDetails?.documentBackStatus == "Rejected" || userKycDetails?.panFrontStatus == "Rejected" || userKycDetails?.panBackStatus == "Rejected") {
    disableSubmit = true
  } else {
    disableSubmit = false
  }
  const updatedUserKycDetails = {
    address: (userKycDetails?.city || userKycDetails?.state || userKycDetails?.pincode || userKycDetails?.h_no || userKycDetails?.country) ? JSON.stringify({
      city: userKycDetails?.city,
      state: userKycDetails?.state,
      pincode: userKycDetails?.pincode,
      locality: userKycDetails?.h_no,
      country: userKycDetails?.country
    }) : null,
    dob: moment(userKycDetails?.dob, "DD-MM-YYYY").format("YYYY-MM-DD"),
    ...userKycDetails,
  }

  const handlePhoneNumber = () => {
    if ((userKycDataContainer?.phone !== userKycDetails?.phone) || !userKycDetails?.phone) {
      setNumberVerified(false)
    } else {
      setNumberVerified(true)
    }
  }

  const getBasicInformationContent = () => {
    return (
      <>
        <div style={{ marginTop: 10, height: 580 }}>
          {
            studentBasicKycDetails(userKycDetails, handleInputChange, handlePhoneNumber, user)?.map((section, index) => (
              <DetailSection section={section} index={index} userKycDetails={userKycDetails} fromAulasWallet={true} phonInputWithSendOtp={true} />
            ))
          }
        </div>
        <div className='r-c-c' style={{}}>
          <Button
            type='primary'
            style={{ height: 35 }}
            onClick={() => { setTabKey('2') }}
            disabled={disableProcced1}
          >{(walletDashboardData?.kycstatus === "Pending" || walletDashboardData?.kycstatus === " Rejected") ? "NEXT" : "PROCEED"}</Button>
        </div>
      </>
    );
  };
  const getContactInformationContent = () => {
    return (
      <>
        <div style={{ marginTop: 10, height: 580 }}>
          {
            studentContactKycDetails(userKycDetails, handleInputChange, handleFocusChange)?.map((section, index) => (
              <DetailSection section={section} index={index} userKycDetails={userKycDetails} fromAulasWallet={true} />
            ))
          }
        </div>
        <div className='r-c-c' style={{}}>
          <Button
            type='primary'
            style={{ height: 35 }}
            onClick={() => { setTabKey('3') }}
            disabled={disableProcced2}
          >{(walletDashboardData?.kycstatus === "Pending" || walletDashboardData?.kycstatus === " Rejected") ? "NEXT" : "PROCEED"}</Button>
        </div>
      </>
    );
  };
  const getDocumentsContent = () => {
    return (
      <>
        <div style={{ overflowY: "scroll", height: "100%" }}>
          <div style={{ height: 580, }}>
            <div className=' m-b-10' style={{ fontSize: 14, color: "#636363" }}>You can upload JPG/PNG of max. size 4MB or PDF of max. size 2 MB.</div>
            <UploadDocuments
              userKycDetails={userKycDetails}
              handleInputChange={handleInputChange} />
          </div>
        </div>
        <div className='r-c-c m-t-20' style={{}}>
          <Button
            loading={submitKycLoader}
            type='primary'
            style={{ height: 35 }}
            onClick={() => {
              setSubmitClicked(true)
              setSubmitKycLoader(true);
              postUpdateUserKyc(
                updatedUserKycDetails,
                () => { setSubmitKycLoader(false) },
                () => { history.push('/aulas-wallet') })
            }}
            disabled={disableSubmit}
          >{walletDashboardData?.kycstatus === "Rejected" ? "UPDATE" : "SUBMIT"}</Button>
        </div>
      </>
    )
  }
  const items = [
    {
      key: '1',
      label: `BASIC INFORMATION `,
      children: getBasicInformationContent(),
    },
    {
      key: '2',
      label: `CONTACT DETAILS`,
      children: getContactInformationContent(),
    },
    {
      key: '3',
      label: `DOCUMENTS`,
      children: getDocumentsContent(),
    },
  ];

  const ExitOnClick = () => {
    history.push('/aulas-wallet')
  }
  const SaveOnClick = () => {
    setConfirmModalSaveLoader(true);
    postUpdateUserKyc(
      updatedUserKycDetails,
      () => { setConfirmModalSaveLoader(false) },
      () => { history.push('/aulas-wallet') })
  }
  let disableSave = false;
  if (tabKey == '1') {
    if ((userKycDetails?.name == '' || userKycDetails?.name == undefined) || (userKycDetails?.phone == '' || userKycDetails?.phone == undefined) || (userKycDetails?.email == '' || userKycDetails?.email == undefined || validateEmail(userKycDetails?.email)) || (userKycDetails?.gender == '' || userKycDetails?.gender == undefined) || (userKycDetails?.dob == '' || userKycDetails?.dob == undefined) || (userKycDetails?.relation == '' || userKycDetails?.relation == undefined) || (userKycDetails?.relation == "Others" && (userKycDetails?.relationType == '' || userKycDetails?.relationType == undefined)) || !isNumberVerified) {
      disableSave = true
    } else {
      disableSave = false
    }
  } else if (tabKey == '3') {
    if ((userKycDetails?.addressType == '' || userKycDetails?.addressType == undefined) || (userKycDetails?.documentNo == '' || userKycDetails?.documentNo == undefined)) {
      disableSave = true
    } else {
      disableSave = false
    }
  }
  const actions = [
    <div style={{ color: "#1089FF", fontSize: 16, fontWeight: "bold", marginRight: 20, fontFamily: "roboto", cursor: disableSave ? "not-allowed" : "pointer", opacity: disableSave ? 0.5 : 1 }}
      onClick={() => {
        setIsSaveClicked(true);
        postUpdateUserKyc(updatedUserKycDetails, null, null);
      }}
    >SAVE</div>]
  return (
    <div>
      <div style={{ marginTop: -10, height: 50 }}>
        <PageHeader
          title='Complete your KYC'
          subtitle='Kindly fill in the informations below'
          actions={actions}
          onBackPress={() => {
            if (tabKey == '1') {
              if ((userKycDetails?.name == '' || userKycDetails?.name == undefined) || (userKycDetails?.phone == '' || userKycDetails?.phone == undefined) || (userKycDetails?.email == '' || userKycDetails?.email == undefined || validateEmail(userKycDetails?.email)) || (userKycDetails?.gender == '' || userKycDetails?.gender == undefined) || (userKycDetails?.dob == '' || userKycDetails?.dob == undefined) || (userKycDetails?.relation == '' || userKycDetails?.relation == undefined) || (userKycDetails?.relation == "Others" && (userKycDetails?.relationType == '' || userKycDetails?.relationType == undefined)) || !isNumberVerified) {
                history.goBack()
              } else if (isSaveClicked) {
                history.goBack()
              } else {
                setConfirmationModalVisible(true)
              }
            } else if (isSaveClicked) {
              history.goBack()
            } else {
              setConfirmationModalVisible(true)
            }
          }} />
      </div>
      <div style={bodyStyle}>
        <Tabs activeKey={tabKey} defaultActiveKey="3" items={items} onChange={onChange} />
      </div>
      {(isConfirmationModalVisible && !isSaveClicked) &&
        <ConfirmationModal
          isConfirmationModalVisible={isConfirmationModalVisible}
          setConfirmationModalVisible={setConfirmationModalVisible}
          title={" Exit KYC Verification ? "}
          description={"Are you sure you want to exit KYC Verification?"}
          cancelText={"YES, EXIT"}
          okText={"SAVE & EXIT"}
          exitOnClick={ExitOnClick}
          saveOnClick={SaveOnClick}
          loadingProp={confirmModalSaveLoader}
        />
      }
      <PageDataLoader visible={pageLoader} />
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state?.session
  const { isNumberVerified, userKycDataContainer, walletDashboardData } = state.aulasWallet;
  return { isNumberVerified, userKycDataContainer, walletDashboardData, user };
};

const mapDispatchToProps = dispatch => ({
  setNumberVerified: (bool) => dispatch(setNumberVerified(bool)),
  postUpdateUserKyc: (userKycDetails, callBack, successCallBack) => dispatch(postUpdateUserKyc(userKycDetails, callBack, successCallBack)),
  getUserKycDetails: (callBack) => dispatch(getUserKycDetails(callBack)),
  setSubmitClicked: (val) => dispatch(setSubmitClicked(val))
});


export default connect(mapStateToProps, mapDispatchToProps)(CompleteStudentKyc);

// export default CompleteStudentKyc