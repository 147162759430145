import React from 'react'
import { connect } from 'react-redux'
import { useState } from 'react'
import Uploadfiles from '../modals/uploadfiles'
import Videouploadmodel from '../modals/Videouploadmodel'
import Succesfulmodel from '../modals/Succesfulmodel'
import VideoTypeModal from '../modals/videoTypeModal'
import { subjectFilter } from 'actions/digitalLibrary'
import { Button } from 'components/Button'
import { PlusOutlined } from '@ant-design/icons'


function LibraryCard({ title, text, logo, classess, uploadsuccessful, togglePageLoader, videoPremium, subjectFilter }) {
    const [ismodelvisible, setmodevisible] = useState(false)
    const [model, setmodel] = useState(false)
    const [isVideoTypeModal, setIsVideoTypeModal] = useState(false)
    const [videoType, setVideoType] = useState('')

    var type

    if (title == 'Lecture Notes') {
        type = 'lecture-notes'
    }
    else if (title == 'Videos') {
        type = 'video'
    }
    else {
        type = 'question-bank'
    }

    function setinitial(callback) {
        callback()
    }

    const handleClick = () => {
        if (title != 'Videos') {
            setmodevisible(true)
        }
        else {
            if (videoPremium) {
                setIsVideoTypeModal(true)
            }
            else {
                setVideoType('videoLink')
                setIsVideoTypeModal(false)
                setmodevisible(true)
            }

        }
    }

    return (
        <div className='libraryCard' style={{ height: 'auto' }} >
            <div className='tocentre'>
                <img src={logo} />
                <p className='libtitle'>{title}</p><br></br>
            </div>
            <p className='libtext'>{text}</p>
            <div style={{ height: '20px' }}>
                <Button id='myBtn' type="primary"
                    onClick={() =>{
                        handleClick()
                        subjectFilter()
                    }} style={{}} icon={<PlusOutlined/>}> Upload</Button>
            </div>
            {/* {
                title==='Videos'?
                        (ismodelvisible && <Videouploadmodel type={type} classess={classess} ismodelvisible={ismodelvisible} setmodevisible={setmodevisible} setmodel={setmodel}/>)
                        :
                (ismodelvisible && <Uploadfiles setinitial={setinitial} type={type} classess={classess} ismodelvisible={ismodelvisible} setmodevisible={setmodevisible} setmodel={setmodel} contentPage={false} />)
            } */}
            {(isVideoTypeModal && <VideoTypeModal visible={isVideoTypeModal} setVisible={setIsVideoTypeModal} setVideoType={setVideoType} toggleUploadFileModel={setmodevisible} />)}
            {videoType == 'videoLink' 
            ? 
            (ismodelvisible && <Videouploadmodel type={type} classess={classess} ismodelvisible={ismodelvisible} setmodevisible={setmodevisible} setmodel={setmodel} contentPage={false} />) 
            : 
            (ismodelvisible && <Uploadfiles type={type} classess={classess} ismodelvisible={ismodelvisible} setmodevisible={setmodevisible} setmodel={setmodel} contentPage={false} location={"home"} subjectFilter={subjectFilter}/>)}
            <Succesfulmodel togglePageLoader={togglePageLoader} fetchApi={false} videoType={videoType}/>

        </div>
    )
}
const mapStateToProps = (state) => {
    const {
        uploadsuccessful,
        videoPremium
    } = state.digitalLibrary;
    return {
        uploadsuccessful,
        videoPremium
    };
};

const mapDispatchToProps = (dispatch) => ({
    subjectFilter: (data, callback) => dispatch(subjectFilter(data, callback)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(LibraryCard);
