import { DeleteFilled, EllipsisOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { AutoComplete, Col, Divider, Dropdown, Input, Menu, Row, Select, Upload, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Api, Color, Notification } from 'services';
import {togglePdfForm, clearPostContentParams, updatePostContentParams, searchChapter, 
    updateContent, updateContentStatus, clearChaptersList} from 'actions/digitalLibrary_old';
import { fetchClasslist } from 'actions/calendar';
import {searchSubject} from 'actions/classRoom';
import { Button } from 'components/Button';

const { Option } = Select;

function PdfForm({
  showPdfForm,
  libFilterParams,
  togglePdfForm,
  clearPostContentParams,
  updatePostContentParams,
  fetchClasslist,
  subjectsFound,
  postContentParams,
  searchSubject,
  searchedChaptersList,
  searchChapter,
  subClasses,
  updateContent,
  updateContentLoader,
  updateContentStatus,
  clearChaptersList,
}) {
  const [fileLoader, toggleFileLoader] = useState(false);

  useEffect(() => {
    updatePostContentParams(libFilterParams.type, "type");
    return () => {
      clearPostContentParams();
      clearChaptersList();
    };
  }, []);

  // const menuOptions = () => (
  //     <Menu>
  //         {postContentParams.status === "Draft" ?
  //         <Menu.Item key="0" onClick={() => updateContentStatus("Published")}>
  //             <UploadOutlined style={{color: Color.green, marginRight: 5}}/> Publish
  //         </Menu.Item> : null}
  //         <Menu.Item key="1" onClick={() => updateContentStatus("Deleted")}>
  //             <DeleteFilled style={{color: Color.red, marginRight: 5}}/> Delete
  //         </Menu.Item>
  //     </Menu>
  //   );

  const handleSubjectSelect = (subject, obj) => {
    if (!obj || obj.id === -1) {
      return console.log("return");
    }
    updatePostContentParams(subject, "subjectName");
    updatePostContentParams(obj.id, "subjectId");
    updatePostContentParams([], "standardIds");
    clearChaptersList();
    fetchClasslist(obj.id);
  };

  const handleSubjectSearch = async (query) => {
    const response = await searchSubject(query);
    updatePostContentParams(null, "subjectId");
    return response;
  };

  const handleChapterSelect = (chapter, obj) => {
    if (!obj || obj.id === -1) {
      return console.log("return");
    }
    updatePostContentParams(obj.id, "chapterId");
    updatePostContentParams(chapter, "chapterName");
  };

  const beforeUpload = (file) => {
    const isPdf = file.type === "application/pdf"; // application/pdf
    if (!isPdf) {
      Notification.error("error", "You can only upload pdf file!");
      return Upload.LIST_IGNORE;
    }
    const isLt5M = file.size / 1024 / 1024 <= 70;
    if (!isLt5M) {
      Notification.error("error", "File must not be larger than 70MB!");
      return Upload.LIST_IGNORE;
    }
    console.log("Returning true");
    return true;
  };

  const uploadFile = (info) => {
    info.fileList.map((file, index) => {
      if (file.type !== "application/pdf") {
        console.log("Not pdf");
        info.fileList.splice(index, 1);
        console.log(info.fileList);
        updatePostContentParams(null, "file");

        return;
      }
    });
    if (info.file.status === "uploading") {
      toggleFileLoader(true);
      return;
    }
    if (info.fileList?.length) {
      if (info.file) {
        updatePostContentParams(info.file.originFileObj, "file");
      }
    } else {
      updatePostContentParams(null, "file");
    }
  };

  const onAddClick = () => {
    updateContent();
  };

  const handleSubjectChange = (val) => {
    if (val === "") {
      updatePostContentParams(val, "subjectName");
      updatePostContentParams(null, "subjectId");
      updatePostContentParams(val, "chapterName");
      updatePostContentParams(null, "chapterId");
      updatePostContentParams([], "standardIds");
      clearChaptersList();
    } else {
      updatePostContentParams(val, "subjectName");
    }
  };

  const handleChaptertChange = (val) => {
    if (val === "") {
      updatePostContentParams(val, "chapterName");
      updatePostContentParams(null, "chapterId");
    } else {
      updatePostContentParams(val, "chapterName");
    }
  };

  const handleChangeStandards = (val) => {
    if (!val.length) {
      updatePostContentParams(val, "standardIds");
      updatePostContentParams("", "chapterName");
      updatePostContentParams(null, "chapterId");
    } else {
      updatePostContentParams(val, "standardIds");
    }
    clearChaptersList();
  };


    return (
        <Modal
            className='rounded-border'
            visible={showPdfForm}
            title={<div className="r-jsb">
                        <div>{postContentParams.id? "Update Details":`Add ${libFilterParams.type === "lecture-notes" ? "Notes" : "Questions"}`} </div> 
                        {/* <div>
                        {postContentParams.id && postContentParams.status === "Draft" ?  
                        <Tooltip title="Publish Resource">
                            <UploadOutlined style={{color: Color.green, marginRight: 5}}  onClick={() => updateContentStatus("Published")}/>
                        </Tooltip>
                        : null }
                        </div> */}
        </div>
      }
      closable={false}
      footer={
        <div>
          <Button
            style={{ width: 150 }}
            onClick={() => togglePdfForm(false)}
          >
            Close
          </Button>
          {postContentParams.status !== "Published" ? (
            <Button
              type="primary"
              style={{ width: 150}}
              onClick={() => onAddClick()}
              loading={updateContentLoader}
              disabled={
                !postContentParams ||
                !postContentParams.subjectId ||
                !postContentParams.chapterId ||
                !postContentParams.standardIds.length ||
                !postContentParams.file ||
                !postContentParams.title ||
                postContentParams.title === ""
              }
            >
              {postContentParams.id ? "Update" : "Add"}
            </Button>
          ) : null}
        </div>
      }
    >
      <div>
        <Row>
          <Col span={24}>
            <div style={{ fontWeight: 500 }}>
              Subject<span style={{ color: Color.red }}>*</span>
            </div>
            <div className="m-t-10">
              <AutoComplete
                value={postContentParams.subjectName}
                onChange={(value) => handleSubjectChange(value)}
                onSelect={(value, obj) => handleSubjectSelect(value, obj)}
                onSearch={(query) => handleSubjectSearch(query)}
                options={subjectsFound}
                disabled={postContentParams.status === "Published"}
                style={{
                  color: Color.primary,
                  fontSize: 16,
                  fontWeight: 600,
                  backgroundColor: "#EEEEEE",
                  width: "100%",
                }}
              >
                <Input.Search
                  style={{ borderRadius: 5, height: 32 }}
                  placeholder="Search subject..."
                  loading={false}
                  allowClear
                />
              </AutoComplete>
            </div>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col span={24}>
            <div style={{ fontWeight: 500 }}>
              Class<span style={{ color: Color.red }}>*</span>
            </div>
            <div className="m-t-10">
              <Select
                className="bold-600"
                placeholder="Select class(es)..."
                disabled={
                  !postContentParams?.subjectId ||
                  !subClasses ||
                  !subClasses.length ||
                  postContentParams.status === "Published"
                }
                value={postContentParams.standardIds[0]}
                style={{ width: "100%" }}
                onChange={(value) => handleChangeStandards([value])}
                loading={false}
              >
                {subClasses &&
                  subClasses.map((item) => (
                    <Option value={item.id}>
                      {item.std}-{item.section}
                    </Option>
                  ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col span={24}>
            <div style={{ fontWeight: 500 }}>
              Chapter<span style={{ color: Color.red }}>*</span>
            </div>
            <div className="m-t-10">
              <AutoComplete
                options={searchedChaptersList}
                style={{
                  width: "100%",
                  color: Color.primary,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                disabled={
                  !postContentParams?.subjectId ||
                  !postContentParams.standardIds.length ||
                  postContentParams.status === "Published"
                }
                onSelect={(value, obj) => handleChapterSelect(value, obj)}
                onSearch={(query) => searchChapter(query)}
                value={postContentParams.chapterName}
                onChange={(value) => handleChaptertChange(value)}
              >
                <Input.Search
                  style={{ borderRadius: 5, height: 32 }}
                  placeholder="Search chapter..."
                  allowClear
                />
              </AutoComplete>
            </div>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col span={24}>
            <div style={{ fontWeight: 500 }}>
              Topic<span style={{ color: Color.red }}>*</span>
            </div>
            <div className="m-t-10">
              <Input
                type="text"
                placeholder="Enter Topic..."
                style={{ width: "100%" }}
                value={postContentParams.title}
                disabled={postContentParams.status === "Published"}
                onChange={(e) =>
                  updatePostContentParams(e.target.value, "title")
                }
              />
            </div>
          </Col>
        </Row>
        <Divider />
        <Row className="m-t-20">
          <Col span={12}>
            <div style={{ fontWeight: 500 }}>
              Attachment<span style={{ color: Color.red }}>*</span>
            </div>
            <div className="m-t-10">
              {postContentParams.id ? (
                <a
                  href={`${Api._s3_url}${postContentParams.file}`}
                  target="_blank" rel="noreferrer"
                >
                  View PDF
                </a>
              ) : (
                <Upload
                  name="avatar"
                  listType="text"
                  className="cursor-pointer"
                  showUploadList={true}
                  style={{ backgroundColor: "transparent" }}
                  action={`${Api._base_url}/api/mock-api/mock-upload`}
                  beforeUpload={(file) => beforeUpload(file)}
                  onChange={(info) => uploadFile(info)}
                >
                  <Button
                    icon={<PaperClipOutlined rotate={-135} />}
                    disabled={postContentParams.file}
                  >
                    Add PDF
                  </Button>
                </Upload>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const { subClasses } = state.calendar;
  const {
    libFilterParams,
    showPdfForm,
    postContentParams,
    searchedChaptersList,
    updateContentLoader,
  } = state.digitalLibrary;
  const { subjectsFound } = state.classRoom;
  return {
    libFilterParams,
    showPdfForm,
    subjectsFound,
    postContentParams,
    searchedChaptersList,
    subClasses,
    updateContentLoader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  togglePdfForm: (bool) => dispatch(togglePdfForm(bool)),
  clearPostContentParams: () => dispatch(clearPostContentParams()),
  updatePostContentParams: (val, key) =>
    dispatch(updatePostContentParams(val, key)),
  fetchClasslist: (subId) => dispatch(fetchClasslist(subId)),
  searchSubject: (query) => dispatch(searchSubject(query)),
  searchChapter: (query) => dispatch(searchChapter(query)),
  updateContent: () => dispatch(updateContent()),
  updateContentStatus: (status) => dispatch(updateContentStatus(status)),
  clearChaptersList: () => dispatch(clearChaptersList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PdfForm);
