import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { fetchClassLibraryData, updateCurrentSub, updateCurrentIcon, updateSetType, currentTypes } from 'actions/digitalLibrary'
import { SquareSubIcon } from 'components/subIcon'
import { Image, Typography } from 'antd'
import lectureNotes from 'Assets/digitalLibrary/lectureNotes.svg'
import videos from 'Assets/digitalLibrary/videos.svg'
import questionBank from 'Assets/digitalLibrary/question-bank.svg'

const SubjectCard = ({
    title, filesNO, images, index, fetchClassLibraryData, classSubjectList, updateCurrentSub, updateCurrentIcon, updateSetType,
    user, currentTypes, item, getStudentHome, totalsize
}) => {
    useEffect(() => {
        if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes('digital-library-dataoperator')) {
            // if(user.access.includes("Admin")||user.role.includes("SuperAdmin")||user.role.includes("Data Operator")){
            fetchClassLibraryData()
        }
    }, [])

    const history = useHistory();

    const viewSubjectDetails = (images, item, data) => {
        if (user.role?.includes('Student')) {
            updateCurrentSub(data)
        } else {
            updateCurrentSub(item)
            console.log({ item })
        }
        updateCurrentIcon(images)
        updateSetType("lecture-notes")
        currentTypes("lecture-notes")
        if (user.access.includes("digital-library-student")) {
            history.push('/digital-library/subject-details')
        }
        else {
            history.push('/digital-library/subject-details')
        }
    }

    const totalSize = parseInt(totalsize) || 0;
    const totalSizeInMB = (totalSize / 1048576).toFixed(2);
    const totalSizeInGB = (totalSize / 1073741824).toFixed(2);
    const displaySize = totalSize === 0
        ? '0 MB'
        : (totalSizeInGB > 1 ? `${totalSizeInGB} GB` : `${totalSizeInMB} MB`);

    return (
        <div style={{ marginBottom: "10px", width: "100%" }}>
            <div className="SubjectCard cursor-pointer" key={index} onClick={() => viewSubjectDetails(images, item, item)} style={{ position: "relative" }}>
                <div style={{ padding: "20px", display: "flex", gap: 20, marginBottom: (user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) ? 20 : 0 }}>
                    <SquareSubIcon icon={images ? images : ''} name={title ? title : ''} size={40} />
                    <div>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "130px" }} className="text-heading-library">{title}</Typography.Text>
                        <div className="s-nofiles">{filesNO} Chapter(s)</div>
                    </div>
                </div>
                {(user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) ?
                    <div style={{ position: "absolute", display: "flex", justifyContent: 'flex-start', height: 30, width: '100%', background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 5%, rgba(0, 0, 0, 1) 110%)", padding: "0px 10px", alignItems: "center", borderRadius: "0px 0px 8px 8px", bottom: 0, right: 0 }}>
                        <div style={{ color: "#FFFFFF", fontWeight: "bold" }}> {displaySize} data</div>
                    </div> : <></>}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "3px", width: "255px"}}>
                <div ><span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {item?.notesCount ? item?.notesCount : 0}  </span><span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Notes</span> <span style={{ fontSize: "13px", color: "#AEAEAE", marginLeft: "6px" }}> | </span></div>
                <div> <span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {item?.videoCount ? item?.videoCount : 0}  </span><span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Videos</span> <span style={{ fontSize: "13px", color: "#AEAEAE", marginLeft: "6px" }}>| </span></div>
                <div> <span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {item?.quesBankCount ? item?.quesBankCount : 0}  </span> <span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}>Test papers</span></div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {
        classLibraryList,
        classSubjectList,
        selectedSubject,
        addedTeacher,
        getStudentHome
    } = state.digitalLibrary;
    const { user } = state.session
    return {
        classLibraryList,
        classSubjectList,
        selectedSubject,
        addedTeacher,
        user,
        getStudentHome
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchClassLibraryData: () => dispatch(fetchClassLibraryData()),
    updateCurrentSub: (data) => dispatch(updateCurrentSub(data)),
    updateCurrentIcon: (images) => dispatch(updateCurrentIcon(images)),
    updateSetType: (type) => dispatch(updateSetType(type)),
    currentTypes: (type) => dispatch(currentTypes(type))

});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(SubjectCard);