import React from 'react';
import { connect } from "react-redux";
import {HandBox} from '../../components';
import '../../design/layout.css';

class HandRaiseList extends React.Component {

  render() {
    const {handRaiseQArray} = this.props;
    return (
      <div>
        <div>
          {handRaiseQArray ?
            handRaiseQArray.map((data) => (
              <HandBox
                name={data.name}
                rollno={data.student ? data.student.rollNo : 'N/A'}
                isAccepted={data.active}
              />
            ))
            : <div>No Doubt!</div>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {
    handRaiseQArray,
  } = state.liveClass;
  // const { user } = state.session;
  // const { activeClass } = state.today;

  return {
    handRaiseQArray,
  };
};

export default connect(mapStateToProps)(HandRaiseList);
