import { DeleteFilled, EditFilled, ExclamationCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Input, PageHeader, Row, Modal } from 'antd';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { useHistory } from 'react-router';
import { Color } from 'services';
import AccessForm from './modals/accessForm';
import {toggleAccessForm, fetchTeacherList, updateViewManagementFilterParams, clearViewManagementFilterParams, loadCurrentData, deleteItem} from 'actions/digitalLibrary_old'
import { Button } from 'components/Button';

const {confirm} = Modal;


const showConfirm = (id, name, deleteItem) => {
    return confirm({
      title: `Please click on OK to remove ${name} from digital library view!`, /*${item.name}*/
      icon: <ExclamationCircleOutlined />,
    //   content: 'Please click OK to confirm',
      onOk() {deleteItem(id)},
      onCancel() {},
    })
  }


const singleTeacher = (teacher, index, handleEditClick, deleteItem) => (
    <div key={index.toString()} style={{width: "100%"}}>
        <div  style={{width: "100%"}}>
            <Row style={{alignItems: 'center'}} >
                <Col span={8} className="bold-600" style={{paddingLeft: 20}}>
                    {teacher.teacherName}
                </Col>
                <Col span={6} style={{fontSize: 12}}>
                    {teacher.standards.split(',').map(item => <div>{item}</div> )}
                </Col>
                <Col span={6} style={{fontSize: 12}}>
                    {teacher.subjectNames?.split(',').map(sub => <div>{sub}</div> )}
                </Col>
                <Col span={2} offset={2}>
                    <div className="display-flex" style={{fontSize: 20, color: Color.darkGrey}}>
                        <div>
                            <EditFilled className="m-r-30 cursor-pointer"  onClick={() => handleEditClick(index)}/>
                        </div>
                        <div>
                            <DeleteFilled className="cursor-pointer" onClick={() => showConfirm(teacher.id, teacher.name, deleteItem)}/>
                        </div>

                    </div>
                </Col>
            </Row>
        </div>
        <Divider />
    </div>
)

function AccessManagement({
    toggleAccessForm, accessFormVisible,
    fetchTeacherList, fetchTeacherLoader, 
    teacherList, updateViewManagementFilterParams,
    viewMngmtFilterParams, clearViewManagementFilterParams,
    loadCurrentData, deleteItem
}) {
    const history = useHistory();

    useEffect(() => {
        fetchTeacherList()

        return () => {
            clearViewManagementFilterParams()
        }
    }, [])

    const handleEditClick = (index) => {
        toggleAccessForm(true);
        loadCurrentData(index)
    }

    const updateSearch = val => {
        if(val === "") {
            updateViewManagementFilterParams(null, 'teacherName');
            fetchTeacherList()
        }
        else  updateViewManagementFilterParams(val, 'teacherName');

    }

    return (
        <div style={{height: "100%"}}>
            <Row style={{backgroundColor:'#F9F9F9', padding: 0, borderRadius: 10}} className="m-b-20">
                <PageHeader
                    style={{color: Color.darkGrey, margin:0, padding: 5, borderRadius: 10}} 
                    title={<div style={{color: Color.darkGrey, fontSize: 22}}>Manage View</div>}
                    onBack={() => history.goBack()}
                />
            </Row>
            <Row className="r-jsb" style={{paddingLeft:20}}>
                <Input.Search
                    style={{width: 200}}
                    placeholder="Search By Teacher..."
                    onChange={e => updateSearch(e.target.value)}
                    value={viewMngmtFilterParams?.teacherName}
                    onSearch={() => fetchTeacherList()}
                    allowClear
                /><div className="r-c-fe">
                <Button type="primary"
                    onClick={() => toggleAccessForm(true)}
                    style={{width: 100}}
                    // disabled={fetchContentLoader}
                    icon={<PlusOutlined/>}
                > ADD</Button>
            </div>

            </Row>
            {fetchTeacherLoader ? 
                <>
                    <div className="r-c-c" style={{height: "90%"}}>
                        <LoadingOutlined style={{color: Color.primary, opacity: 0.5, fontSize: '5em'}}/>
                    </div> 
                </>
            :
            <div style={{height: "90%", overflowY: "auto", marginTop: 40}}>
                    <Row className="tableHeader">
                        <Col span={8}>
                            Teacher's Name
                        </Col>
                        <Col span={6}>
                            Classes
                        </Col>
                        <Col span={6}>
                            Subjects
                        </Col>
                        <Col span={2} offset={2}>
                            Actions
                        </Col>
                    </Row>
                    <Divider style={{marginTop: 5}}/>
                {teacherList && teacherList.map((teacher, index) => singleTeacher(teacher, index, handleEditClick, deleteItem))}
            </div>}

            {accessFormVisible && <AccessForm />}
            
        </div>
    )
}


const mapStateToProps = state => {
    const {
        accessFormVisible, fetchTeacherLoader, teacherList, viewMngmtFilterParams
    } = state.digitalLibrary;    
    return {
        accessFormVisible, fetchTeacherLoader, teacherList, viewMngmtFilterParams
    };
  };
  
  const mapDispatchToProps = dispatch => ({
    toggleAccessForm: bool => dispatch(toggleAccessForm(bool)),
    fetchTeacherList: () => dispatch(fetchTeacherList()),
    updateViewManagementFilterParams: (val, key) => dispatch(updateViewManagementFilterParams(val, key)),
    clearViewManagementFilterParams: () => dispatch(clearViewManagementFilterParams()),
    loadCurrentData: index => dispatch(loadCurrentData(index)),
    deleteItem: id => dispatch(deleteItem(id))
  });


export default connect(mapStateToProps, mapDispatchToProps)(AccessManagement);