import React, { useState, useEffect } from 'react'
import TopBar from './topBar'
import { FilterOutlined } from '@ant-design/icons'
import courseImage from '../../Assets/aulasMarket/teamgrp.png';
import { Button, Dropdown, Menu, Rate, Spin } from 'antd';
import Footer from './footer';
import { Link } from "react-router-dom";
import { AllCoursesData, CourseListApi, CoursePageApi, ExploreCourseData, StudyMaterialExplore, StudyMaterialPage, TestSeriesApi, VideoResourcePageApi, setAllCourseData, setCoursePage, setExploreCourseData, setItemType, setLoader, setPopularRender, setTestSeriesData } from 'actions/aulasMarket';
import { connect } from 'react-redux';
import dropDownIcon from "../../Assets/aulasMarket/ic-arrow-head-down.svg"
import dropUpIcon from "../../Assets/aulasMarket/ic-arrow-head-up.svg"
import "./styles.css";
import "./testSeries.css";
import { useHistory } from 'react-router-dom';
import PageDataLoader from 'components/pageDataLoader';
// import LazyLoad from 'react-lazyload';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Api } from 'services';
import noImage from "../../Assets/aulasMarket/no-image.svg"


const testData = [
    {
        title: "125 tests",
        description: "JEE Mains - 2023 test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: true,
    },
    {
        title: "125 tests",
        description: "National Eligibility cum Entrance Test",
        rating: 4,
        totalRatings: 215,
        tokenFree: true,
    },
    {
        title: "125 tests",
        description: "NEET Test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: false,
    },
    {
        title: "125 tests",
        description: "NEET Test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: true,
    },
    {
        title: "125 tests",
        description: "National Eligibility cum Entrance Test (NEET) Test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: false,
    },
    {
        title: "125 tests",
        description: "National Eligibility cum Entrance Test (NEET) Test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: true,
    },
    {
        title: "125 tests",
        description: "JEE Mains - 2023 test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: false,
    },
    {
        title: "125 tests",
        description: "JEE Mains - 2023 test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: true,
    },
]

const additionalContent = [
    {
        title: "Syllabus"
    },
    {
        title: "Important dates"
    },
    {
        title: "Exam pattern"
    }
]


export const AllLiveCourses = ({ token, parentLink, setExploreCourseData, exploreCourseData, setPopularRender, setCoursePage, setTestSeriesData, allCoursesData, setAllCourseData, history, loader, setLoader }) => {
    const [activeButton, setActiveButton] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selected, setSelected] = useState("");
    // console.log("livvvvvv", exploreCourseData)
    const [initialDataFetched, setInitialDataFetched] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    // console.log("funnn", setCoursePage);

    const LiveCourse = ({ liveCourses, index }) => {
        console.log("naaaa", liveCourses)

        const handleCourseClick = (id) => {
            setLoader(true);
            CoursePageApi(token, id, (res) => {
                if (res) {
                    // If the response is true, navigate to the course page
                    setCoursePage(res)
                    history.push('/course-page');
                } else {
                    // If the response is not true, you can handle it as needed (stay on the same page or show an error message)
                    // For example, you can display an error message here.
                    console.error('Course not available');
                }
            }, setLoader);
        };
        let discountAmt = 0;
        const percent = 50;

        if (typeof liveCourses?.amount === 'number') {
            discountAmt = (liveCourses.amount * percent) / 100;
        }
        return (
            <div className='live-course-parent m-t-20'>
                <div style={{ cursor: "pointer" }} onClick={() => handleCourseClick(liveCourses?.id)}>
                    {liveCourses?.amount === null || liveCourses?.amount === 0 ?
                        <div className='freeBanner'><img src={require("../../Assets/aulasMarket/freePng (2).png").default} /></div>
                        : <div className=''></div>}
                    <div className={`font-bold r-c-c m-t-10 ${liveCourses?.mode === 'hybrid' ? 'hybrid' : (liveCourses?.mode === 'online' ? 'online' : 'offline')}`} style={{}}>{liveCourses?.mode}</div>
                    <div>
                        <img className='popular-Img' src={`${Api.dlS3Url}${liveCourses?.iamge}`} style={{}} />
                    </div>
                    {liveCourses?.mode === "online" ? <div className=''></div> :
                        <div className='cost1 r-c-c font-bold' style={{}}>
                            ₹{liveCourses?.amount}
                            {/* <span style={{ textDecoration: 'line-through', color: '#FF414D', fontSize: "12px", marginLeft: 5 }}>₹{discountAmt}</span> */}
                        </div>}
                </div>
                <div className='popular-subTitle' style={{}}>
                    <div className='livecourse-title' style={{}}>{liveCourses?.courseName}</div>
                    <div style={{ color: "#636363", fontSize: 13, }}>{liveCourses?.courseInfo}</div>
                    {liveCourses?.rating ?
                        <div className='display-flex'>
                            <div className='font-bold' style={{ color: "#FFA931", fontSize: 18 }}>{liveCourses?.rating}</div>
                            <div style={{ margin: "-8px 5px 0px 5px" }}><Rate disabled defaultValue={liveCourses?.rating} style={{ color: "#FFA931" }} /></div>
                            <div style={{ color: "#636363", fontSize: 13 }}>({liveCourses?.ratingCount})</div>
                        </div>
                        : null}
                </div>
            </div>
        )
    }
    useEffect(() => {
        // Fetch initial data when the component mounts
        if (initialDataFetched) {
            fetchData('live');
            setInitialDataFetched(false); // Set the flag to true to prevent multiple calls
        }
    }, []);


    const handleClassClick = (buttonName) => {
        setSelected(buttonName);
        console.log("btnName", buttonName);
        if (buttonName) {
            setActiveButton(buttonName);

        } else {
            setActiveButton("All");
        }
    };


    const handleDropdownVisibleChange = (visible) => {
        setDropdownVisible(visible);
    };
    // Create the dropdown menu with sections and buttons
    const menu = (
        <Menu visible={dropdownVisible} style={{ width: "350px" }}>
            <Menu.ItemGroup title={<span style={{ color: '#594099', fontSize: 16 }}>Classes</span>}>
                <Menu.Item className='r-c-c' key="class1">
                    <Button
                        type="text"
                        value={selected}
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === '' ? '#594099' : 'transparent',
                            color: activeButton === '' ? 'white' : '',
                            border: activeButton === '' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => {
                            handleClassClick('');
                            setSelected("All");
                            setLoader(true);

                            ExploreCourseData(token, "", (res) => setExploreCourseData(res), setLoader, page, exploreCourseData?.meta?.itemsPerPage);
                        }}
                    >
                        All
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        value={selected}
                        type="text"

                        style={{
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'ongoing' ? '#594099' : 'transparent',
                            color: activeButton === 'ongoing' ? 'white' : '',
                            border: activeButton === 'ongoing' ? 'none' : '1px solid #594099',
                            width: "fit-content",
                        }}
                        onClick={() => { handleClassClick('ongoing'); setSelected("Ongoing"); ExploreCourseData(token, "ongoing", (res) => setExploreCourseData(res), setLoader, page, exploreCourseData?.meta?.itemsPerPage); }}
                    >
                        Ongoing
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        type="text"
                        value={selected}
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'upcoming' ? '#594099' : 'transparent',
                            color: activeButton === 'upcoming' ? 'white' : '',
                            border: activeButton === 'upcoming' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => { handleClassClick('upcoming'); setSelected("Upcoming"); ExploreCourseData(token, "upcoming", (res) => setExploreCourseData(res), setLoader, page, exploreCourseData?.meta?.itemsPerPage); }}
                    >
                        Upcoming
                    </Button>
                </Menu.Item>

            </Menu.ItemGroup>
            <Menu.ItemGroup title={<span style={{ color: '#594099', fontSize: 16 }}>Mode</span>}>
                <Menu.Item key="mode1">
                    <Button
                        type="text"
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === '' ? '#594099' : 'transparent',
                            color: activeButton === '' ? 'white' : '',
                            border: activeButton === '' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => {
                            handleClassClick('');
                            ExploreCourseData(token, " ", (res) => setExploreCourseData(res), setLoader, page, exploreCourseData?.meta?.itemsPerPage);
                        }}
                    >
                        All
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        type="text"
                        style={{
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'online' ? '#594099' : 'transparent',
                            color: activeButton === 'online' ? 'white' : '',
                            border: activeButton === 'online' ? 'none' : '1px solid #594099',
                            width: "fit-content",
                        }}
                        onClick={() => { handleClassClick('online'); ExploreCourseData(token, "online", (res) => setExploreCourseData(res), setLoader, page, exploreCourseData?.meta?.itemsPerPage); }}
                    >
                        Online
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        type="text"
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'offline' ? '#594099' : 'transparent',
                            color: activeButton === 'offline' ? 'white' : '',
                            border: activeButton === 'offline' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => { handleClassClick('offline'); ExploreCourseData(token, "offline", (res) => setExploreCourseData(res), setLoader, page, exploreCourseData?.meta?.itemsPerPage); }}
                    >
                        Offline
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        type="text"
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'hybrid' ? '#594099' : 'transparent',
                            color: activeButton === 'hybrid' ? 'white' : '',
                            border: activeButton === 'hybrid' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => { handleClassClick('hybrid'); ExploreCourseData(token, "hybrid", (res) => setExploreCourseData(res), setLoader, page, exploreCourseData?.meta?.itemsPerPage); }}
                    >
                        Hybrid
                    </Button>
                </Menu.Item>

                {/* Add more items as needed */}
            </Menu.ItemGroup>
            <Menu.ItemGroup title={<span style={{ color: '#594099', fontSize: 16 }}>Type</span>}>
                <Menu.Item key="type1">
                    <Button
                        type="text"
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === '' ? '#594099' : 'transparent',
                            color: activeButton === '' ? 'white' : '',
                            border: activeButton === '' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => { handleClassClick(''); ExploreCourseData(token, " ", (res) => setExploreCourseData(res), setLoader, page, exploreCourseData?.meta?.itemsPerPage); }}
                    >
                        All
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        type="text"
                        style={{
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'free' ? '#594099' : 'transparent',
                            color: activeButton === 'free' ? 'white' : '',
                            border: activeButton === 'free' ? 'none' : '1px solid #594099',
                            width: "fit-content",
                        }}
                        onClick={() => { handleClassClick('free'); ExploreCourseData(token, "free", (res) => setExploreCourseData(res), setLoader, page, exploreCourseData?.meta?.itemsPerPage); }}
                    >
                        Free
                    </Button>
                </Menu.Item>

                {/* Add more items as needed */}
            </Menu.ItemGroup>
        </Menu>
    );


    const fetchData = (val) => {
        setInitialDataFetched(true);
        if (exploreCourseData?.items?.length >= exploreCourseData?.meta?.totalItems) {
            setHasMore(false);
            return;
        }

        console.log("insidefetch")
        ExploreCourseData(token, itemTy, (res) => {
            if (res) {
                const updatedData = [...exploreCourseData.items, ...res.items];
                setExploreCourseData(updatedData);
                setPopularRender(1)
                setPage(page + 1);
            }
            else {
                console.error('Course not available');
            }
        }, setLoader, page, exploreCourseData?.meta?.itemsPerPage);
    }

    return (

        <div style={{ backgroundColor: "#FAFAFA" }}>
            <div className='main-container-parent'>
                <div className="main-container" style={{}}>
                    {parentLink ?
                        <div className='popular-breadcrumbs' style={{ color: "#AEAEAE" }} >
                            <Link to="/" style={{ color: "#AEAEAE" }}>Home</Link> / <span style={{ cursor: "pointer" }} onClick={() => { setPopularRender(0) }}>{parentLink?.parentName} / {parentLink?.childName}</span>/ <span className='font-bold' style={{ color: "#191919" }}>All Live Courses</span>
                        </div>
                        :
                        <div className='popular-breadcrumbs' style={{ color: "#AEAEAE" }} >
                            <Link to="/" style={{ color: "#AEAEAE" }}>Home</Link>/<span style={{ cursor: "pointer" }} onClick={() => { setPopularRender(0) }}>Popular Courses</span>/ <span className='font-bold' style={{ color: "#191919" }}>All Live Courses</span>
                        </div>
                    }
                    <div className='pop-header-parent'>
                        <div className='popular-header' style={{ color: "#191919" }}>All Live Courses (244)<img style={{ marginTop: "-2px", marginLeft: "10px" }} src={require("../../Assets/aulasMarket/infoIcon.svg").default}
                        /></div>

                        <Dropdown
                            overlay={menu}
                            placement="bottomLeft"
                            visible={dropdownVisible}
                            onVisibleChange={handleDropdownVisibleChange}
                        >
                            <div className='filter-Btn' style={{}}>
                                <img src={require("../../Assets/aulasMarket/filterIC.svg").default} /><div className='mobile-hidden'> Filter</div> <img className='mobile-hidden' src={dropdownVisible ? dropUpIcon : dropDownIcon} /> </div>
                        </Dropdown>

                    </div>

                    <div style={{}}>
                        <InfiniteScroll
                            dataLength={exploreCourseData?.items?.length || 0}
                            next={fetchData}
                            hasMore={hasMore}
                            loader={
                                <div className='r-c-c' style={{ textAlign: "center" }}>
                                </div>
                            }
                            height={550}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                            style={{}}

                        >
                            <div className="courses-container m-t-20" style={{ color: "#191919" }}>
                                {exploreCourseData?.items?.map((item, index) => (
                                    <LiveCourse
                                        key={index}
                                        index={index}
                                        liveCourses={item}
                                    />
                                ))}
                            </div>
                        </InfiniteScroll>
                    </div>


                </div>
            </div>
            <div className='m-t-100' style={{}}>
                <Footer />
            </div>
        </div>

    )
}

export const AllVideoResources = ({ token, parentLink, popularRenderPage, setCoursePage, setPopularRender, exploreCourseData, setExploreCourseData, loader, setLoader, history }) => {
    const [activeButton, setActiveButton] = useState("all");

    const [page, setPage] = useState(1);

    const VideoResources = ({ course, index }) => {
        console.log("video", course)
        const handleCourseClick = (id) => {
            setLoader(true);
            VideoResourcePageApi(token, id, (res) => {
                if (res) {
                    // If the response is true, navigate to the course page
                    setCoursePage(res)
                    history.push('/course-page');
                } else {
                    // If the response is not true, you can handle it as needed (stay on the same page or show an error message)
                    // For example, you can display an error message here.
                    console.error('Course not available');
                }
            }, setLoader);
        };
        let discountAmt = 0;
        const percent = 50;

        if (typeof data?.amount === 'number') {
            discountAmt = (data.amount * percent) / 100;
        }
        return (
            <div className='live-course-parent m-t-20' style={{}}>
                <div style={{ cursor: "pointer" }} onClick={() => handleCourseClick(course?.id)}>
                    {course?.amount === null || course?.amount === 0 ?
                        <div className='freeBanner'><img src={require("../../Assets/aulasMarket/freePng (2).png").default} /></div>
                        : <div className=''></div>}
                    {/* <div className={`font-bold r-c-c m-t-10 ${course?.mode === 'hybrid' ? 'hybrid' : (course?.mode === 'online' ? 'online' : 'offline')}`} style={{}}>{course?.mode}</div> */}
                    <div>
                        {course?.image ?
                            <img className='popular-Img' src={`${Api.dlS3Url}${course?.image}`} style={{}} />
                            :
                            <img className='popular-Img' src={courseImage} style={{}} />
                        }
                    </div>
                    {course?.mode === "online" ? <div className=''></div> :
                        <div className='cost1 r-c-c font-bold' style={{}}>
                            ₹{course?.amount}
                            {/* <span style={{ textDecoration: 'line-through', color: '#FF414D', fontSize: "12px", marginLeft: 5 }}>₹{discountAmt}</span> */}
                        </div>}
                </div>
                <div className='popular-subTitle' style={{}}>
                    <div className='livecourse-title' style={{}}>{course?.courseName}</div>
                    <div style={{ color: "#636363", fontSize: 13, }}>{course?.courseInfo}</div>
                    <div className='display-flex'>
                        <div className='font-bold' style={{ color: "#FFA931", fontSize: 18 }}>{course?.rating}</div>
                        <div style={{ margin: "-8px 5px 0px 5px" }}><Rate disabled defaultValue={course?.rating} style={{ color: "#FFA931" }} /></div>
                        <div style={{ color: "#636363", fontSize: 13 }}>({course?.ratingCount})</div>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
    }, [popularRenderPage])



    const handleClassClick = (buttonName) => {
        console.log("btnName", buttonName);

        if (buttonName) {
            setActiveButton(buttonName);

        } else {
            setActiveButton('');
        }
    };
    return (
        <div style={{ backgroundColor: "#FAFAFA" }}>
            <div className='main-container-parent'>
                <div className="main-container" style={{}}>
                    {parentLink ?
                        <div className='popular-breadcrumbs' style={{ color: "#AEAEAE" }} >
                            <Link to="/" style={{ color: "#AEAEAE" }}>Home</Link> / <span style={{ cursor: "pointer" }} onClick={() => { setPopularRender(0) }}>{parentLink?.parentName} / {parentLink?.childName}</span>/ <span className='font-bold' style={{ color: "#191919" }}>All Video Resources</span>
                        </div>
                        :
                        <div className='popular-breadcrumbs' style={{ color: "#AEAEAE" }} >
                            <Link to="/" style={{ color: "#AEAEAE" }}>Home</Link>/<span style={{ cursor: "pointer" }} onClick={() => { setPopularRender(0) }}>Popular Courses</span>/ <span className='font-bold' style={{ color: "#191919" }}>All Video Resources</span>
                        </div>
                    }
                    <div className='pop-header-parent'>
                        <div className='popular-header' style={{ color: "#191919" }}>All Video Courses (244)<img style={{ marginTop: "-2px", marginLeft: "10px" }} src={require("../../Assets/aulasMarket/infoIcon.svg").default} /> </div>
                        <div className='pop-btns'>
                            <Button
                                type="text"

                                style={{
                                    width: "fit-content",
                                    borderRadius: "100px",
                                    backgroundColor: activeButton === "all" ? '#594099' : 'transparent',
                                    color: activeButton === "all" ? 'white' : '',
                                    border: activeButton === "all" ? 'none' : '1px solid #594099'
                                }}
                                onClick={() => { handleClassClick('all'); ExploreCourseData(token, " ", (res) => setExploreCourseData(res), setLoader, page, exploreCourseData?.meta?.itemsPerPage); }}
                            >
                                All
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                type="text"

                                style={{
                                    borderRadius: "100px",
                                    backgroundColor: activeButton === 'free' ? '#594099' : 'transparent',
                                    color: activeButton === 'free' ? 'white' : '',
                                    border: activeButton === 'free' ? 'none' : '1px solid #594099',
                                    width: "fit-content",
                                }}
                                onClick={() => { handleClassClick('free'); ExploreCourseData(token, "free", (res) => setExploreCourseData(res), setLoader, page, exploreCourseData?.meta?.itemsPerPage); }}
                            >
                                Free
                            </Button>
                        </div>
                    </div>
                    <div className="courses-container" style={{ color: "#191919" }}>
                        {exploreCourseData?.items?.map((course, index) => (
                            <VideoResources
                                key={index}
                                index={index}
                                course={course}
                            />
                        ))}
                    </div>
                </div>

            </div>
            <div className='m-t-100' style={{}}>
                <Footer />
            </div>
        </div>
    )

}

export const AllOnlineTestSeries = ({ token, parentLink, popularRenderPage, setPopularRender, exploreCourseData }) => {
    const [activeButton, setActiveButton] = useState('All');
    const StudySeries = ({ testData, id, flag }) => {
        console.log("itttt", testData)
        return (
            <div className='card-design-parent' style={{}}>
                <div className='cd-p1' style={{}} onClick={() => setTestSeriesData(testData)}>
                    <Link to="/test-series">
                        <div className='card-design' style={{}}>
                            {testData?.tokenFree ? (
                                <div className='free-card font-bold r-c-c ' style={{}}>FREE</div>
                            ) : <div className=''></div>}
                            <div className='card-content-free' style={{}}>

                                <div className='card-img' style={{}}>
                                    <img style={{}} src={require("../../Assets/aulasMarket/onlineTestIcon.svg").default} />
                                </div>

                                <div className='m-l-10'>
                                    {testData?.title}
                                </div>

                            </div>
                            {!testData?.tokenFree ? (
                                <div className='cost-card' style={{}}>₹ 10,000</div>
                            ) : <div className=''></div>
                            }

                        </div>
                    </Link>
                </div>

                <div className='popular-subTitle'>
                    <div className='popular-description' style={{}}>{testData?.description}</div>

                    <div className='r-c-fs' style={{ width: "100%" }}>
                        <div className=' ratings font-bold' style={{}}>{testData?.rating}</div>
                        <div style={{ margin: "-8px 5px 0px 5px" }}><Rate disabled defaultValue={4} style={{ color: "#FFA931" }} /></div>
                        <div className='totalRatings' style={{}}>({testData?.totalRatings})</div>
                    </div>
                </div>

            </div>
        )
    }
    let discountAmt = 0;
    const percent = 50;

    if (typeof data?.amount === 'number') {
        discountAmt = (data.amount * percent) / 100;
    }
    return (
        <div style={{ backgroundColor: "#FAFAFA" }}>
            <div className='main-container-parent'>
                <div className="main-container" >
                    {parentLink ?
                        <div className='popular-breadcrumbs' style={{ color: "#AEAEAE" }} >
                            <Link to="/" style={{ color: "#AEAEAE" }}>Home</Link> / <span style={{ cursor: "pointer" }} onClick={() => { setPopularRender(0) }}>{parentLink?.parentName} / {parentLink?.childName}</span>/ <span className='font-bold' style={{ color: "#191919" }}>All Online Test Series</span>
                        </div>
                        :
                        <div className='popular-breadcrumbs' style={{ color: "#AEAEAE" }} >
                            <Link to="/" style={{ color: "#AEAEAE" }}>Home</Link>/<span style={{ cursor: "pointer" }} onClick={() => { setPopularRender(0) }}>Popular Courses</span>/ <span className='font-bold' style={{ color: "#191919" }}>All Online Test Series</span>
                        </div>
                    }
                    <div className='pop-header-parent'>
                        <div className='popular-header ' style={{ color: "#191919" }}>All Online Test Series (542)<img style={{ marginTop: "-2px", marginLeft: "10px" }} src={require("../../Assets/aulasMarket/infoIcon.svg").default} /> </div>
                        <div className='pop-btns'>
                            <Button
                                onClick={() => setActiveButton(!activeButton)}
                                style={{
                                    borderRadius: "100px",
                                    backgroundColor: activeButton ? '#594099' : 'transparent',
                                    color: activeButton ? 'white' : '',
                                    border: activeButton ? 'none' : '1px solid #594099',
                                    width: "fit-content",
                                }}
                            >
                                All
                            </Button>
                            &nbsp;
                            <Button
                                onClick={() => setActiveButton(!activeButton)}
                                style={{
                                    borderRadius: "100px",
                                    backgroundColor: !activeButton ? '#594099' : 'transparent',
                                    color: !activeButton ? 'white' : '',
                                    border: !activeButton ? 'none' : '1px solid #594099',
                                    width: "fit-content",
                                }}
                            >
                                Free
                            </Button>
                        </div>
                    </div>
                    <div className="courses-container" style={{ color: "#191919" }}>
                        {testData?.map((item, id) => (
                            <StudySeries testData={item} key={id} />
                        )
                        )}
                    </div>
                </div>
            </div>
            <div className='m-t-100' style={{}}>
                <Footer />
            </div>
        </div>
    )
}

export const AllStudyMaterials = ({ token, parentLink, popularRenderPage, setPopularRender, exploreCourseData, setCoursePage, loader, setLoader, history }) => {
    const [activeButton, setActiveButton] = useState('All');
    const [courseCount, setCourseCount] = useState(0);

    const StudyMaterial = ({ testData, id, flag }) => {
        setCourseCount(testData?.m_count)

        const handlePageData = (id) => {
            setLoader(true)
            StudyMaterialPage(token, id, (res) => {
                if (res) {
                    setTestSeriesData(res)
                    history.push("/study-materials")
                }
            }, setLoader)
        }
        let discountAmt = 0;
        const percent = 50;

        if (typeof testData?.amount === 'number') {
            discountAmt = (testData?.amount * percent) / 100;
        }
        return (
            <div className='card-design-parent' style={{}}>
                <div className='cd-p1' style={{}} onClick={() => handlePageData(testData?.id)}>

                    <div className='card-design cursor-pointer' style={{}}>
                        {testData?.feeType === "free" ? (
                            <div className='free-card font-bold r-c-c ' style={{}}>FREE</div>
                        ) : <div className=''></div>}
                        <div className='card-content-free' style={{}}>

                            <div className='card-img' style={{}}>
                                <img style={{}} src={require("../../Assets/aulasMarket/studyMaterialIcon.svg").default} />
                            </div>

                            <div className='m-l-10'>
                                {testData?.name}
                            </div>

                        </div>
                        {testData?.feeType !== "free" ? (
                            <div className='cost1 r-c-c font-bold' style={{}}>
                                ₹{testData?.amount}
                                {/* <span style={{ textDecoration: 'line-through', color: '#FF414D', fontSize: "12px", marginLeft: 5 }}>₹{discountAmt}</span> */}
                            </div>) : <div className=''></div>
                        }

                    </div>

                </div>

                <div className='popular-subTitle'>
                    <div className='popular-description' style={{}}>{testData?.brief}</div>

                    <div className='r-c-fs' style={{ width: "100%" }}>
                        <div className=' ratings font-bold' style={{}}>{testData?.rating}</div>
                        <div style={{ margin: "-8px 5px 0px 5px" }}><Rate disabled defaultValue={testData?.rating} style={{ color: "#FFA931" }} /></div>
                        <div className='totalRatings' style={{}}>({testData?.ratingCount})</div>
                    </div>
                </div>

            </div>
        )
    }
    return (
        <div style={{ backgroundColor: "#FAFAFA" }}>
            <div className='main-container-parent'>
                <div className="main-container" style={{ backgroundColor: "#FAFAFA", }}>
                    {parentLink ?
                        <div className='popular-breadcrumbs' style={{ color: "#AEAEAE" }} >
                            <Link to="/" style={{ color: "#AEAEAE" }}>Home</Link> / <span style={{ cursor: "pointer" }} onClick={() => { setPopularRender(0) }}>{parentLink?.parentName} / {parentLink?.childName}</span>/ <span className='font-bold' style={{ color: "#191919" }}>All Study Materials</span>
                        </div>
                        :
                        <div className='popular-breadcrumbs' style={{ color: "#AEAEAE" }} >
                            <Link to="/" style={{ color: "#AEAEAE" }}>Home</Link>/<span style={{ cursor: "pointer" }} onClick={() => { setPopularRender(0) }}>Popular Courses</span>/ <span className='font-bold' style={{ color: "#191919" }}>All Study Materials</span>
                        </div>
                    }
                    <div className='pop-header-parent'>
                        <div className='popular-header ' style={{ color: "#191919" }}>All Study Materials ({courseCount})<img style={{ marginTop: "-2px", marginLeft: "10px" }} src={require("../../Assets/aulasMarket/infoIcon.svg").default} /> </div>
                        <div className='pop-btns'>
                            <Button
                                onClick={() => setActiveButton(!activeButton)}
                                style={{
                                    borderRadius: "100px",
                                    backgroundColor: activeButton ? '#594099' : 'transparent',
                                    color: activeButton ? 'white' : '',
                                    border: activeButton ? 'none' : '1px solid #594099',
                                    width: "fit-content",
                                }}
                            >
                                All
                            </Button>
                            &nbsp;
                            <Button
                                onClick={() => setActiveButton(!activeButton)}
                                style={{
                                    borderRadius: "100px",
                                    backgroundColor: !activeButton ? '#594099' : 'transparent',
                                    color: !activeButton ? 'white' : '',
                                    border: !activeButton ? 'none' : '1px solid #594099',
                                    width: "fit-content",
                                }}
                            >
                                Free
                            </Button>
                        </div>
                    </div>
                    <div className="courses-container" style={{ color: "#191919" }}>
                        {exploreCourseData?.items?.map((item, id) => (
                            <StudyMaterial testData={item} key={id} flag={true} />
                        )
                        )}
                    </div>
                </div>
            </div>
            <div className='m-t-100' style={{}}>
                <Footer />
            </div>
        </div>
    )
}


function PopularCourses({ loginDetails, itemType, setItemType, popularRenderPage, setExploreCourseData, exploreCourseData, setPopularRender, setCoursePage, setTestSeriesData, allCoursesData, setAllCourseData, parentLink }) {
    const history = useHistory();
    const token = loginDetails?.accessToken;
    const [page, setPage] = useState(1);
    const [courseCount, setCourseCount] = useState(0);
    const [loader, setLoader] = useState(false);
    const [itemTy, setItemTy] = useState("");

    const PopularList = () => {
        setLoader(true);
        AllCoursesData(token, "", (res) => {
            if (res) {
                // If the response is true, navigate to the course page
                setAllCourseData(res)
                setPopularRender(0)
            } else {
                // If the response is not true, you can handle it as needed (stay on the same page or show an error message)
                // For example, you can display an error message here.
                console.error('Course not available');

            }
        }, setLoader);
    }
    useEffect(() => {
        {
            parentLink ? null :
                PopularList();
        }
    }, [])
    // console.log("ppppp", exploreCourseData?.meta);

    const LiveCourse = ({ liveCourses, index, itemkey }) => {
        // console.log("naaaa", liveCourses)
        setItemType(itemkey)
        const handleCourseClick = (id) => {
            setLoader(true);
            CoursePageApi(token, id, (res) => {
                if (res) {
                    // If the response is true, navigate to the course page
                    setCoursePage(res)
                    history.push('/course-page');
                } else {
                    // If the response is not true, you can handle it as needed (stay on the same page or show an error message)
                    // For example, you can display an error message here.
                    console.error('Course not available');
                }
            }, setLoader);
        };
        let discountAmt = 0;
        const percent = 50;

        if (typeof liveCourses?.amount === 'number') {
            discountAmt = (liveCourses?.amount * percent) / 100;
        }
        return (
            <div className='live-course-parent m-t-20'>
                <div style={{ cursor: "pointer" }} onClick={() => handleCourseClick(liveCourses?.id)}>
                    {liveCourses?.amount === null || liveCourses?.amount === 0 ?
                        <div className='freeBanner'><img src={require("../../Assets/aulasMarket/freePng (2).png").default} /></div>
                        : <div className=''></div>}
                    <div className={`font-bold r-c-c m-t-10 ${liveCourses?.mode === 'hybrid' ? 'hybrid' : (liveCourses?.mode === 'online' ? 'online' : 'offline')}`} style={{}}>{liveCourses?.mode?.toUpperCase()}</div>
                    <div>
                        <img className='popular-Img' src={liveCourses?.image ? `${Api.dlS3Url}${liveCourses?.image}` : noImage} style={{}} />
                    </div>
                    {liveCourses?.mode === "online" ? <div className=''></div> :
                        <div className='cost1 r-c-c font-bold' style={{}}>
                            ₹{liveCourses?.amount}
                            {/* <span style={{ textDecoration: 'line-through', color: '#FF414D', fontSize: "12px", marginLeft: 5 }}>₹{discountAmt}</span> */}
                        </div>}
                </div>
                <div className='popular-subTitle' style={{}}>
                    <div className='livecourse-title' style={{}}>{liveCourses?.courseName}</div>
                    <div style={{ color: "#636363", fontSize: 13, }}>{liveCourses?.courseInfo}</div>
                    {liveCourses?.rating ?
                        <div className='display-flex'>
                            <div className='font-bold' style={{ color: "#FFA931", fontSize: 18 }}>{liveCourses?.rating}</div>
                            <div style={{ margin: "-8px 5px 0px 5px" }}><Rate disabled defaultValue={liveCourses?.rating} style={{ color: "#FFA931" }} /></div>
                            <div style={{ color: "#636363", fontSize: 13 }}>({liveCourses?.ratingCount})</div>
                        </div>
                        : null}
                </div>

            </div>
        )
    }

    const VideoResources = ({ course, index, itemkey }) => {
        setItemType(itemkey)
        const handleCourseClick = () => {
            setLoader(true);
            VideoResourcePageApi(token, course?.id, (res) => {
                if (res) {
                    // If the response is true, navigate to the course page
                    setCoursePage(res)
                    history.push('/course-page');
                } else {
                    // If the response is not true, you can handle it as needed (stay on the same page or show an error message)
                    // For example, you can display an error message here.
                    console.error('Course not available');
                }
            }, setLoader);
        };
        let discountAmt = 0;
        const percent = 50;

        if (typeof course?.amount === 'number') {
            discountAmt = (course?.amount * percent) / 100;
        }
        return (
            <div className='live-course-parent m-t-20' style={{}}>
                <div style={{ cursor: "pointer" }} onClick={handleCourseClick}>
                    {course?.amount === null || course?.amount === 0 ?
                        <div className='freeBanner'><img src={require("../../Assets/aulasMarket/freePng (2).png").default} /></div>
                        : <div className=''></div>}
                    {/* <div className={`font-bold r-c-c m-t-10 ${course?.mode === 'hybrid' ? 'hybrid' : (course?.mode === 'online' ? 'online' : 'offline')}`} style={{}}>{course?.mode}</div> */}
                    <div>
                        <img className='popular-Img' src={course?.image ? `${Api.dlS3Url}${course?.image}` : noImage} style={{}} />
                    </div>
                    {course?.mode === "online" ? <div className=''></div> :
                        <div className='cost1 r-c-c font-bold' style={{}}>
                            ₹{course?.amount}
                            {/* <span style={{ textDecoration: 'line-through', color: '#FF414D', fontSize: "12px", marginLeft: 5 }}>₹{discountAmt}</span> */}
                        </div>}
                </div>
                <div className='popular-subTitle' style={{}}>
                    <div className='livecourse-title' style={{}}>{course?.name}</div>
                    <div style={{ color: "#636363", fontSize: 13, }}>{course?.info}</div>
                    <div className='display-flex'>
                        <div className='font-bold' style={{ color: "#FFA931", fontSize: 18 }}>{course?.rating}</div>
                        <div style={{ margin: "-8px 5px 0px 5px" }}><Rate disabled defaultValue={course?.rating} style={{ color: "#FFA931" }} /></div>
                        <div style={{ color: "#636363", fontSize: 13 }}>({course?.ratingCount})</div>
                    </div>
                    <div className='r-c-fs' style={{}}>
                        <div><img style={{ width: 20 }} src={require("../../Assets/aulasMarket/ratingIcon.png").default} /></div>
                        <div className='text xs m-l-10' style={{ color: "#636363" }}>Ranbir Swamy</div>
                    </div>
                </div>
            </div>
        )
    }

    const StudySeries = ({ testData, id, flag }) => {
        console.log("itttt", testData)
        const handleTestSeries = (id) => {
            TestSeriesApi(token, id, (res) => {
                if (res) {
                    setTestSeriesData(res);
                    history.push("/course-page")
                }
            }, setLoader)
        }
        let discountAmt = 0;
        const percent = 50;

        if (typeof testData?.amount === 'number') {
            discountAmt = (testData.amount * percent) / 100;
        }
        return (
            <div className='card-design-parent' style={{}}>
                <div className='cd-p1' style={{}} >
                    <div className='card-design' style={{}}>
                        {testData?.amount === "0" ? (
                            <div className='free-card font-bold r-c-c ' style={{}}>FREE</div>
                        ) : <div className=''></div>}
                        <div className='card-content-free' style={{}}>

                            <div className='card-img' style={{}}>
                                <img style={{}} src={require("../../Assets/aulasMarket/onlineTestIcon.svg").default} />
                            </div>

                            <div className='m-l-10 cursor-pointer' onClick={() => handleTestSeries(testData?.id)}>
                                {testData?.name}
                            </div>

                        </div>
                        {!testData?.tokenFree ? (
                            <div className='cost1 r-c-c font-bold' style={{}}>
                                ₹{testData?.amount}
                                {/* <span style={{ textDecoration: 'line-through', color: '#FF414D', fontSize: "12px", marginLeft: 5 }}>₹{discountAmt}</span> */}
                            </div>) : <div className=''></div>
                        }

                    </div>

                </div>

                <div className='popular-subTitle'>
                    <div className='popular-description' style={{}}>{testData?.description}</div>

                    <div className='r-c-fs' style={{ width: "100%" }}>
                        <div className=' ratings font-bold' style={{}}>{testData?.averageRating}</div>
                        <div style={{ margin: "-8px 5px 0px 5px" }}><Rate disabled defaultValue={4} style={{ color: "#FFA931" }} /></div>
                        <div className='totalRatings' style={{}}>({testData?.totalReviews})</div>
                    </div>
                </div>

            </div>
        )
    }

    const StudyMaterial = ({ testData, id, flag }) => {
        console.log("itttt", testData)
        setCourseCount(testData?.m_count)
        const handlePageData = (id) => {
            setLoader(true)
            StudyMaterialPage(token, id, (res) => {
                if (res) {
                    setTestSeriesData(res)
                    history.push("/study-materials")
                }
            }, setLoader)
        }
        let discountAmt = 0;
        const percent = 50;

        if (typeof testData?.amount === 'number') {
            discountAmt = (testData.amount * percent) / 100;
        }
        return (
            <div className='card-design-parent' style={{}}>
                <div className='cd-p1 cursor-pointer' style={{}} onClick={() => handlePageData(testData?.id)}>

                    <div className='card-design' style={{}}>
                        {testData?.amount === null || testData?.amount === 0 ? (
                            <div className='free-card font-bold r-c-c ' style={{}}>FREE</div>
                        ) : <div className=''></div>}
                        <div className='card-content-free' style={{}}>
                            <div className='card-img' style={{}}>
                                <img style={{}} src={require("../../Assets/aulasMarket/studyMaterialIcon.svg").default} />
                            </div>
                            <div className='m-l-10'>
                                {testData?.name}
                            </div>
                        </div>
                        {testData?.feeType !== "free" ? (
                            <div className='cost-card ' style={{}}>
                                ₹{testData?.amount}
                                {/* <span style={{ textDecoration: 'line-through', color: '#FF414D', fontSize: "12px", marginLeft: 5 }}>₹{discountAmt}</span> */}
                            </div>) : <div className=''></div>
                        }
                    </div>
                </div>
                <div className='popular-subTitle'>
                    <div className='popular-description' style={{}}>{testData?.brief}</div>

                    <div className='r-c-fs' style={{ width: "100%" }}>
                        <div className=' ratings font-bold' style={{}}>{testData?.rating}</div>
                        <div style={{ margin: "-8px 5px 0px 5px" }}><Rate disabled defaultValue={testData?.rating} style={{ color: "#FFA931" }} /></div>
                        <div className='totalRatings' style={{}}>({testData?.ratingCount})</div>
                    </div>
                </div>

            </div>
        )
    }

    const AdditionalContent = ({ additionalContent, id }) => {
        return (
            <div className='testcards-container' style={{}}>
                <div className='r-c-c testCards' style={{}}>

                    <div className='popular-additional' style={{ color: "#191919" }}>
                        {additionalContent?.title}
                    </div>



                    <div className='popular-additional' style={{}}>{testData?.description}</div>


                </div>
            </div>
        )
    }



    const handleExploreMore = (val) => {
        setLoader(true);
        CourseListApi(token, val, (res) => {
            if (res) {
                setExploreCourseData(res);
                setPopularRender(1)
            }
            else {
                console.error('Course not available');
            }
        }, setLoader, page, exploreCourseData?.meta?.itemsPerPage)
    }

    const handleExploreStudyMaterial = (id) => {
        setLoader(true);

        StudyMaterialExplore(token, id, (res) => {
            if (res) {
                setPopularRender(4);
                setExploreCourseData(res);
            }

        }, setLoader);
    }


    const handleExploreMoreVideo = (val) => {

        setLoader(true);

        ExploreCourseData(token, val, (res) => {
            if (res) {
                setExploreCourseData(res);
                setPopularRender(2)
            }
            else {
                console.error('Course not available');
            }
        }, setLoader, page, exploreCourseData?.meta?.itemsPerPage)
    }
    // console.log("pagee", popularRenderPage)

    return (
        <>
            <div className='top-bar'><TopBar /></div>
            {popularRenderPage === 0 ? (
                <div style={{ backgroundColor: "#FAFAFA" }}>
                    <div className="main-container-parent" style={{}}>
                        <div className='main-container' style={{}}>
                            {parentLink ?
                                <div className='popular-breadcrumbs' style={{}} >
                                    <Link to="/" style={{ color: "#AEAEAE" }}>Home</Link>/ <span className='font-bold' style={{ color: "#191919" }}>{parentLink?.parentName}</span> / <span className='font-bold' style={{ color: "#191919" }}>{parentLink?.childName}</span>
                                </div>
                                :
                                <div className='popular-breadcrumbs' style={{}} >
                                    <Link to="/" style={{ color: "#AEAEAE" }}>Home</Link>/ <span className='font-bold' style={{ color: "#191919" }}>Popular Courses</span>
                                </div>
                            }

                            {allCoursesData?.liveCourses ?
                                <div className='popular-header' style={{ color: "#191919" }}>Live Courses<img style={{ marginTop: "-2px", marginLeft: "10px" }} src={require("../../Assets/aulasMarket/infoIcon.svg").default} /></div>
                                : null}
                            <div className="courses-container " style={{ color: "#191919" }}>
                                {allCoursesData?.liveCourses?.map((item, index) => (
                                    <LiveCourse
                                        key={index}
                                        index={index}
                                        liveCourses={item}
                                    />
                                ))}
                            </div>
                            {allCoursesData?.liveCourses ?
                                <div className='r-c-c m-t-20'>
                                    <Button className='font-bold' onClick={() => { handleExploreMore("live") }} style={{ color: "#594099", borderRadius: "100px" }}>EXPLORE MORE</Button>
                                </div>
                                : null}

                            {allCoursesData?.videoResources ?
                                <div className='popular-header' style={{ color: "#191919" }}>Video Resources&nbsp;<img style={{ marginTop: "-2px", marginLeft: "20px" }} src={require("../../Assets/aulasMarket/infoIcon.svg").default} /></div>
                                : null}
                            <div className="courses-container " style={{ color: "#191919" }}>
                                {allCoursesData?.videoResources?.map((course, index) => (
                                    <VideoResources
                                        key={index}
                                        index={index}
                                        course={course}
                                    />
                                ))}
                            </div>
                            {allCoursesData?.videoResources ?
                                <div className='r-c-c m-t-20' style={{}}>
                                    <Button className='font-bold' onClick={() => { handleExploreMoreVideo("recorded") }} style={{ color: "#594099", borderRadius: "100px" }}>EXPLORE MORE</Button>
                                </div>
                                : null}

                            {allCoursesData?.tests ?
                                <div className='popular-header ' style={{ color: "#191919" }}>Online Test Series&nbsp;<img style={{ marginTop: "-2px", marginLeft: "20px" }} src={require("../../Assets/aulasMarket/infoIcon.svg").default} /></div>
                                : null}

                            <div className="courses-container m-t-40 " style={{ color: "#191919" }}>
                                {allCoursesData?.tests?.map((item, id) => (
                                    <StudySeries testData={item} key={id} />
                                )
                                )}
                            </div>
                            {allCoursesData?.tests ?
                                <div className='r-c-c m-t-20' style={{}}>
                                    <Button className='font-bold' onClick={() => { setPopularRender(3) }} style={{ color: "#594099", borderRadius: "100px" }}>EXPLORE MORE</Button>
                                </div>
                                : null}


                            {allCoursesData?.studyMaterials ?
                                <div className='popular-header' style={{ color: "#191919" }}>Study Materials ({courseCount})&nbsp;<img style={{ marginTop: "-2px", marginLeft: "20px" }} src={require("../../Assets/aulasMarket/infoIcon.svg").default} /></div>
                                : null}
                            <div className="courses-container " style={{ color: "#191919" }}>
                                {allCoursesData?.studyMaterials && allCoursesData?.studyMaterials?.map((item, id) => (
                                    <StudyMaterial testData={item} key={id} flag={true} />
                                )
                                )}

                            </div>

                            {allCoursesData?.studyMaterials ?
                                <div className='r-c-c m-t-20' style={{}}>
                                    <Button className='font-bold' onClick={handleExploreStudyMaterial} style={{ color: "#594099", borderRadius: "100px" }}>EXPLORE MORE</Button>
                                </div>
                                : null}


                            {/* <div className='popular-header ' style={{ color: "#191919" }}>Additional Content (122)&nbsp;<img style={{ marginTop: "-2px", marginLeft: "20px" }} src={require("../../Assets/aulasMarket/infoIcon.svg").default} /></div>
                            <div className="additional-container m-t-20" style={{ color: "#191919" }}>
                                {additionalContent.map((item, id) => (
                                    <AdditionalContent additionalContent={item} key={id} />
                                )
                                )}
                            </div> */}
                        </div>


                    </div>
                    <div className='m-t-100' style={{}}>
                        <Footer />
                    </div>
                </div>

            ) : (
                popularRenderPage === 1 ? (<AllLiveCourses parentLink={parentLink} popularRenderPage={popularRenderPage} setPopularRender={setPopularRender} exploreCourseData={exploreCourseData} history={history} setCoursePage={setCoursePage} loader={loader} setLoader={setLoader} token={token} />) : (
                    popularRenderPage === 2 ? (<AllVideoResources parentLink={parentLink} setExploreCourseData={setExploreCourseData} popularRenderPage={popularRenderPage} setPopularRender={setPopularRender} exploreCourseData={exploreCourseData} history={history} setCoursePage={setCoursePage} loader={loader} setLoader={setLoader} token={token} />) : (
                        popularRenderPage === 3 ? (<AllOnlineTestSeries parentLink={parentLink} popularRenderPage={popularRenderPage} setPopularRender={setPopularRender} history={history} exploreCourseData={exploreCourseData} setCoursePage={setCoursePage} loader={loader} setLoader={setLoader} token={token} />) : (
                            popularRenderPage === 4 ? (<AllStudyMaterials parentLink={parentLink} popularRenderPage={popularRenderPage} setPopularRender={setPopularRender} history={history} exploreCourseData={exploreCourseData} setCoursePage={setCoursePage} loader={loader} setLoader={setLoader} token={token} />) : null
                        )
                    )
                )

            )}
            <PageDataLoader visible={loader} />
        </>
    );

}


const mapStateToProps = (state) => {
    const { popularRenderPage, coursePageData, allCoursesData, exploreCourseData, loader, itemType, loginDetails, parentLink } = state.aulasMarket
    // console.log("pppp", popularRenderPage)
    return {
        popularRenderPage, coursePageData, allCoursesData, exploreCourseData, loader, itemType, loginDetails, parentLink
    }
};




const mapDispatchToProps = (dispatch) => ({
    setPopularRender: (val) => dispatch(setPopularRender(val)),
    setCoursePage: (val) => dispatch(setCoursePage(val)),
    setTestSeriesData: (val) => dispatch(setTestSeriesData(val)),
    setAllCourseData: (val) => dispatch(setAllCourseData(val)),
    setExploreCourseData: (val) => dispatch(setExploreCourseData(val)),
    setLoader: (val) => dispatch(setLoader(val)),
    setItemType: (val) => dispatch(setItemType(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopularCourses);
