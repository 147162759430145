import { InfoCircleFilled, PlayCircleTwoTone } from '@ant-design/icons';
import { getVideoResourcesData, postUpdateVideoProgress, setVideoCompletionStatus } from 'actions/studentMarketPlace';
import PageDataLoader from 'components/pageDataLoader';
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { Api } from 'services';
import playIcon from "../../../Assets/aulasMarket/playCircleIc.svg"
import YTPlayer from 'components/ytPlayer';
import ReactPlayerHoc from 'components/ReactPlayerHoc';

function VideoPlayer({ videoUrl, videoData, postUpdateVideoProgress, videoDuration, getVideoResourcesData, setVideoCompletionStatus, itemIdVideoResource, topicId,videoTypeLink }) {
    console.log("videorr", videoDuration);
    const [pageLoader, togglePageLoader] = useState(false);
    const [renderPagePlayer, setRenderPagePlayer] = useState(0);
    const [progressParam, setProgressParam] = useState({
        id: null,
        videoResourceId: itemIdVideoResource,
        progress: null,
    });
    const [videoProgress, setVideoProgress] = useState(0);

    const playerRef = useRef(null);
    const [localProgress,setLocalProgress] = useState(0);

    console.log("revvv", videoProgress)
    useEffect(() => {
        if (topicId) {
            if (progressParam?.id && progressParam?.progress) {
                postUpdateVideoProgress(progressParam, (res) => {
                    if (res) {
                        getVideoResourcesData(itemIdVideoResource, () => { togglePageLoader(false); })
                    }
                });
            }
        }
    }, [renderPagePlayer])


    const handleProgress = (state) => {
        const played = state?.played;
        const progressPercentage = Math.round(played * 100);
        const finalProgress = played >= 0.98 ? 100 : progressPercentage;
        setProgressParam((prev) => ({
            ...prev,
            id: topicId,
            progress: finalProgress,
        }));
        setVideoProgress(played);
       

    };

    const handleVideoEnd = () => {
        console.log('Video has ended');
        setRenderPagePlayer(renderPagePlayer + 1);
        // setVideoCompletionStatus((prevStatus) => ({
        //     ...prevStatus,
        //     [topicId]: true,
        // }));
        setVideoProgress(0);
    };

    const handleVideoPause = () => {
        setRenderPagePlayer(renderPagePlayer + 1);
        const player = playerRef.current;
        if (player) {
            setVideoProgress(player.getCurrentTime() / player.getDuration());
        }
    };

    const durationString = videoDuration?.duration ?? "00:00:00";
    const totalSeconds = parseDuration(durationString);

    function parseDuration(durationString) {
        const [hours, minutes, seconds] = durationString.split(":").map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    }

  

    const onReady = ()=> {
        let progress = progressParam?.progress || videoDuration?.progress
        let youtubeDuration = 0;
        if(progress === 100){
            progress = 1;
        }
        // if(videoDuration?.videoType === "link"){
        //     youtubeDuration = await getVideoDuration(videoUrl)
        // }
        console.log("youtube",youtubeDuration)

        const timeToStart = (progress / 100) * totalSeconds;
        playerRef.current.seekTo(timeToStart, 'seconds');


    };
    


    console.log("duration", (videoDuration?.progress / 100) * totalSeconds, videoDuration?.duration, videoDuration);

    return (
        <>
            <div className='video-container' style={{ position: 'relative'}}>
    {videoUrl ? (
        <>
            {videoTypeLink == 'link' ? (
                <YTPlayer keyboardControls={true} videoUrl={`${Api.dlS3Url}${videoUrl}`} />
            ) : (
                <>
                    <ReactPlayerHoc
                        ref={playerRef}
                        style={{ marginTop: 30 }}
                        height={"90%"}
                        width={"100%"}
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        onContextMenu={e => e.preventDefault()}
                        url={`${Api.dlS3Url}${videoUrl}`}
                        controls={true}
                        light={true}
                        keyboardControls={true}
                        onProgress={handleProgress}
                        onEnded={handleVideoEnd}
                        onPause={handleVideoPause}
                        onPlay={videoDuration?.videoType == "link" ? undefined : onReady}
                        onReady={videoDuration?.videoType == "video" ? undefined : onReady}
                        playIcon={<PlayCircleTwoTone style={{ fontSize: 50 }} />}
                    />
                    <div style={{
                        position: 'absolute',
                        top: '3px',
                        left: '50%',
                        width:'100%',
                        transform: 'translateX(-50%)',
                        color: '#fff',
                        padding: '2px 20px',
                        borderRadius: '8px',
                        zIndex: 1
                    }}>
                       <InfoCircleFilled />  Multi resolution feature will be coming shortly !
                    </div>
                </>
            )}
        </>
    ) : (
        <div className='r-c-c' style={{ color: '#FFFFFF', width: "100%", height: "100%" }}>
            Click on video file
        </div>
    )}
</div>

            <PageDataLoader visible={pageLoader} />
        </>
    )
}

const mapStateToProps = (state) => {
    const { } = state?.studentMarketPlace
    return {

    }
};

const mapDispatchToProps = (dispatch) => ({
    setVideoCompletionStatus: (val) => dispatch(setVideoCompletionStatus(val)),
    postUpdateVideoProgress: (param, callback) => dispatch(postUpdateVideoProgress(param, callback)),
    getVideoResourcesData: (id, callback) => dispatch(getVideoResourcesData(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer)
