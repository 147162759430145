import { Col, DatePicker, Modal, Row, TimePicker } from 'antd';
import { Input } from 'components/customInputs';
import { Heading } from 'components/Typography';
import React, { useEffect, useState } from 'react';
import {Button} from 'components/Button';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment/moment';
import { editRequest, requestLeaveOrAttendance } from 'actions/profileNew';
import { connect } from 'react-redux';


const MarkAttendanceModal = ({
    visible, onClose, attendanceFormDetails, setAttendanceFormDetails, requestLeaveOrAttendance, 
    setPageLoader, filter, setFilter, user, fromWhere, userRowDetails, isEdit
}) => {

    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(() => {
        var dif = moment.duration(attendanceFormDetails.clockOut?.diff(attendanceFormDetails.clockIn));
       
        attendanceFormDetails.totalHours =   ("0" + dif.hours()).slice(-2) + ":" + ("0" + dif.minutes()).slice(-2);
        setAttendanceFormDetails({...attendanceFormDetails})
    }, [attendanceFormDetails.clockIn, attendanceFormDetails.clockOut]);

    // const disabledDate = (current) => {
    //     // Can not select days before today and today
    //     return current && current >= moment();
    //   };

    const handleSaveClick = () => {
        const params = {
            userId: fromWhere == 'userManagementProfile' ? userRowDetails?.id : user?.id, 
            commonId: attendanceFormDetails.commonId,
            requestType: "Attendance",
            startDate: moment(attendanceFormDetails.date).format("YYYY-MM-DD"),
            clockIn: moment(attendanceFormDetails.clockIn).format("HH:mm:ss"),
            clockOut: moment(attendanceFormDetails.clockOut).format("HH:mm:ss"),
            reason: attendanceFormDetails.reason,
            totalhours: attendanceFormDetails.totalHours,
            page: 1,
            limit: filter.limit
        }
        console.log({params});
        setButtonLoader(true)
        requestLeaveOrAttendance(params,() => setButtonLoader(false), onClose, pagecallback)
    }

    const pagecallback = (state) => {
        setPageLoader(state);
        setFilter({...filter, page:1})
    }

    function onKeyDown(event) {
        event.preventDefault();
    };

    function disabledDate(current) {
        return current && (current < moment().add(-4, "days") || current > moment().add(-1, "days"))
    }

    return (
        <Modal
            open={visible}
            footer={false}
            className="modal-round-corner"
            onCancel={onClose}
        >
            <div className='r-c-c m-t-20'>
                <Heading>{user?.privilage?.includes('SuperAdmin')  ? isEdit == 'edit'? "EDIT ATTENDANCE" :"MARK ATTENDANCE" : isEdit == 'edit'? "EDIT ATTENDANCE" :"REQUEST ATTENDANCE"}</Heading>
            </div>
            <div className='p-20'>
                <div>Date</div>
                <DatePicker  
                disabled  = {isEdit == 'edit' ? true : false}
                format='DD-MM-YYYY'
                    style={{width: "100%"}} 
                    value={attendanceFormDetails.date} 
                    // value={moment(attendanceFormDetails.date, 'YYYY-MM-DD').format('DD-MM-YYYY')} 
                    onChange={(value) => setAttendanceFormDetails({...attendanceFormDetails, date: value})}
                    disabledDate={disabledDate}
                    onKeyDown={onKeyDown}
                />
            </div>
            <Row className='p-20' style={{paddingTop: 0}}>
                <Col span={12}>
                    <div>Clock in</div>
                    <TimePicker 
                        style={{width: "100%"}}  
                        format="HH:mm" 
                        value={attendanceFormDetails.clockIn} 
                        onChange={(value) => setAttendanceFormDetails({...attendanceFormDetails, clockIn: value})}
                        onKeyDown={onKeyDown}
                    />
                </Col>
                <Col span={11} offset={1}>
                    <div>Clock out</div>
                    <TimePicker 
                        style={{width: "100%"}}  
                        format="HH:mm" 
                        value={attendanceFormDetails.clockOut}
                        onChange={(value) => setAttendanceFormDetails({...attendanceFormDetails, clockOut: value})}
                        disabled={!attendanceFormDetails.clockIn}
                        onKeyDown={onKeyDown}
                    />
                </Col>
            </Row>
            <div className='p-20' style={{paddingTop: 0}}>
                <div>Total hours</div>
                
                <Input disabled style={{width: "100%"}} placeholder="Total Hours" value={attendanceFormDetails.clockIn > attendanceFormDetails.clockOut || attendanceFormDetails.totalHours ==  'aN:aN' ? "00:00" : attendanceFormDetails.totalHours} />
            </div>
            <div className='p-20' style={{paddingTop: 0}}>
                <div>Reason</div>
                <TextArea 
                    placeholder='Enter reason'
                    style={{resize: "none", height: 100}}
                    value={attendanceFormDetails.reason}
                    onChange={(e) => setAttendanceFormDetails({...attendanceFormDetails, reason: e.target.value})}
                />
            </div>

            <div className='r-c-c m-t-40'>
                <Button type="primary"
                    onClick={handleSaveClick}
                    loading={buttonLoader}
                    disabled={
                        attendanceFormDetails.clockIn > attendanceFormDetails.clockOut ||
                        !attendanceFormDetails.date || !attendanceFormDetails.clockIn || 
                        !attendanceFormDetails.clockOut || !attendanceFormDetails.reason || attendanceFormDetails.reason?.trim ===""
                    }
                >{(user?.privilage?.includes('SuperAdmin')) ? isEdit == 'edit' ? "UPDATE":"MARK" : isEdit == 'edit' ? "UPDATE":"REQUEST"}</Button>
            </div>

        </Modal>
    );
}


const mapStateToProps = (state) => { 
    const {user} = state.session;
    const { leaveDetails } = state.profileNew;
    const {userRowDetails} = state.userManagementV2;
    return {
        leaveDetails, user, userRowDetails
    }
}

const mapDispatchToProps = (dispatch) => ({
    requestLeaveOrAttendance: (params, callback, successCallback, pagecallback) => dispatch(requestLeaveOrAttendance(params, callback, successCallback, pagecallback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarkAttendanceModal);

