import styled, { css } from "styled-components";

const Card = styled.div`
  height: ${(props) => props.height || "auto"};
  margin: 10px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 4px 4px 18px #302a5f14;
  min-width: 200px;
  background-color: #fff;
`;

export default Card;
