const Styles = {
  cardContainer: {
    marginTop: 25,
    padding:20,
    marginBottom: 25,
    border: 0,
    borderRadius: 20,
  },
  avatar: {
    fontWeight: 'bold',
    marginRight: 10,
  },
  timeWrapper: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'space-between',
  },
  clockIcon: {
    fontWeight: 'bold',
    fontSize: 15,
    marginRight: 10,
  },
  startBtn: {
    marginTop: -7,
    border: 0,
    borderRadius: 11,
    color: 'white',
  },
};

export default Styles;
