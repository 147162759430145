import React from 'react'

function BarsComponent({ impressionCountStudent,openproblemsNo,dateOrMonth, impressionCountTeacher, short_date, teacherView, colors ,maxHeight}) {
    var impressionCountStudentsNu = `calc(${impressionCountStudent}/${maxHeight} * 210px)`
    var impressionCountTeacherNu = `calc(${impressionCountTeacher}/${maxHeight} * 210px)`
    var openproblemsNo=`calc(${openproblemsNo}/${maxHeight} * 210px)`

    if (teacherView == true) {
        return (
            <div style={{ display: "flex", flexDirection: "column", width: "80%", height: "100%", marginLeft: "10px" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "90%", height: "100%" }}>
                    <div style={{ width: "60%", height: openproblemsNo, marginTop: `calc(210px - ${openproblemsNo})`, backgroundColor: colors, marginLeft: "5.5%", borderRadius: "5px 5px 0px 0px" }}>

                    </div>

                </div>
                <p style={{ color: "#636363", fontFamily: "roboto", marginLeft: "2%", fontSize: "12px", marginTop: "5px" }}> {dateOrMonth}</p>
            </div>
        )
    }
    return (

        <div style={{ display: "flex", flexDirection: "column", width: "80%", height: "100%", marginLeft: "2%" }}>
            <div style={{ display: "flex", flexDirection: "row", width: "90%", height: "100%" }}>
                <div style={{ width: "50%", height: impressionCountStudentsNu, marginTop: `calc(210px - ${impressionCountStudentsNu})`, backgroundColor: "#FF83D0", marginLeft: "5.5%", borderRadius: "5px 5px 0px 0px" }}>

                </div>
                <div style={{ width: "50%", height: impressionCountTeacherNu, marginTop: `calc(210px - ${impressionCountTeacherNu})`, backgroundColor: "#579EFF", marginLeft: "-1px", borderRadius: "5px 5px 0px 0px" }}>

                </div>

            </div>
            <p style={{ marginLeft: "25%", color: "#636363", fontFamily: "roboto", fontSize: "12px", marginTop: "5px" }}>{dateOrMonth}</p>
        </div>

    )
}

export default BarsComponent
