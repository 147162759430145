import React, {useEffect, useRef, useState} from "react";
import { Modal, Button, Radio, Select, Input, Checkbox, Row, Col, Table, Tooltip, Typography } from "antd";
import AddUserCard from "./addUserCard";
import image from "Assets/user-management/ic-students.svg";
import image1 from "Assets/user-management/ic-teachers.svg";
import planner from "Assets/user-management/ic-planner.svg";
import data_operator from "Assets/user-management/ic-data-operator.svg";
import operation from "Assets/user-management/ic-operation.svg";
import { clearAddUserFields, deleteExcelOnComplete, downloadTemplate, fetchExcelData, getStats, getStudentsclass, setAddRole, setDashBoardRole, setPrivilage, storeAdduserFields, storeAddUsers, storeExcelUploadDetails, uploadBulkUsers } from "actions/userManagement";
import { connect } from "react-redux";
import createYourOwn from '../../Assets/user-management/createYourOwn.svg'
import bulkImport from '../../Assets/user-management/illustration-upload.svg'
import PhoneInput from "react-phone-number-input";
import { ArrowLeftOutlined, CheckCircleOutlined, CheckOutlined, ClockCircleOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { Notification } from "services";
import { InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';


const { Dragger } = Upload;

function AddUsers({
  isModalVisible,
  setAddRole,
  setDashBoardRole,
  handleOk,
  handleCancel,
  showRoleModal,
  storeAddUsers,
  getStudentsclass,
  classList,
  storeAdduserFields,
  storeAddUserDetails,
  privilages,
  user,
  setPrivilage,
  addUserByRole,
  buttonLoader,
  storeExcelUploadDetails,
  uploadBulkUsers,
  fetchExcelData,
  excelData,
  ChooseUploadType,
  downloadTemplate,
  excelFile,
  pendingUsers,
  deleteExcelOnComplete,
  clearAddUserFields,
  handleDone,
  getStats
}) {
console.log("AA-abc",excelFile?.name);
  const { Option } = Select;

  const userType = [{id: 0, type: 'Student'}, {id: 1, type: 'Teacher'}, {id: 2, type: 'Data Operator'}, {id: 3, type: 'Operation'}]
  const privileges = [{id: 0, type: 'Admin'}, {id: 1, type: 'Planner'}, {id: 2, type: 'Fee Manager'}, {id: 3, type: 'Institute support'}, {id: 4, type: 'Attendance Marker'}]

  // USE_STATES
  const [currentView, setCurrentView] = useState(0);
  const [selected, setSelected] = useState(0); 
  const [isAdminSelected, setIsAdminSelected] = useState(false);
  const [documentUploadLoader, updateDocUpdateLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);

  // Kindly fill all the details 
  const fillTheDetails = storeAddUserDetails?.role == '' || storeAddUserDetails?.role == undefined || storeAddUserDetails?.name?.trim() == '' || storeAddUserDetails?.phoneNo == '' || storeAddUserDetails?.phoneNo == undefined || storeAddUserDetails?.phoneNo == null;


  //USE_EFFECTS
  useEffect(() => {
    getStudentsclass(()=>{})
  }, [])
  
  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

// clearing InputFileds when modal is opened. 
useEffect(() => {
  clearAddUserFields('role', '');
  clearAddUserFields('name', '');
  clearAddUserFields('email', '');
  clearAddUserFields('privilage', []);
  clearAddUserFields('phoneNo', null);
  clearAddUserFields('course', '');
}, [])


const callFetchExcelData = async () => {
  if(currentView == 3 && pendingUsers?.some(el => el.status === 'Pending')){
    await fetchExcelData(()=>{})
  }
}

useInterval(() => {
  callFetchExcelData()
}, 4000)


  // if Admin is selected from the privilege list then, Planner and Institute support checkboxes get ticked and disables. 
  useEffect(() => {
    if (storeAddUserDetails?.privilage?.includes("Admin")) {
      setIsAdminSelected(true);
      if (storeAddUserDetails?.privilage?.indexOf("Planner") > -1) {
        storeAddUserDetails?.privilage?.splice(storeAddUserDetails?.privilage?.indexOf("Planner"), 1);
      }
      if (storeAddUserDetails?.privilage?.indexOf("InstituteSupport") > -1) {
        storeAddUserDetails?.privilage?.splice(storeAddUserDetails?.privilage?.indexOf("InstituteSupport"), 1);
      }
    } else {
      setIsAdminSelected(false);
    }
  }, [storeAddUserDetails?.privilage]);


  const handleNext = () => {
    if(selected == 1){
      setCurrentView(1);
    }else{
      setCurrentView(2);
    };

    if(currentView == 1){
      if(fillTheDetails){
        Notification.error('Error', 'Please fill all the mandatory details')
      }else{
        addUserByRole()
      }
    }else if(currentView == 2){
      updateDocUpdateLoader(true);
      if(excelFile){
        uploadBulkUsers(()=>{
          updateDocUpdateLoader(false);
          setCurrentView(3);
        }, ()=> {
           fetchExcelData(()=>{});  
        });
      }else{
        updateDocUpdateLoader(false);
      }
      
    }else if(currentView == 3){
      deleteExcelOnComplete();
      storeExcelUploadDetails(null);
      handleDone();
      getStats(()=>{})
    }else if(currentView == 0){
      null
    }
  }

  const handleBack = () => {
    if(currentView == 1 || currentView == 2){
      setCurrentView(0)
      storeAdduserFields('privilage', [])
    }
  }
  

  const handleDownloadTemplate = (e) => {
    e.stopPropagation();
    setDownloadLoader(true); 
    downloadTemplate(()=>setDownloadLoader(false))
  }

  const handleView1Back = () => {
    setCurrentView(0)
    storeAdduserFields('role', '')
    storeAdduserFields('name', '')
    storeAdduserFields('email', '')
    storeAdduserFields('phoneNo', null)
    storeAdduserFields('course', '')
    storeAdduserFields('privilage', [])
  }

  function onChange(checkedValues) {
    storeAdduserFields('privilage',checkedValues);
  }

  const handleUploadChange = (info) => {
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done' || status === 'error') {
      uploadingProgress()
      handleUploadDocument(info.file.originFileObj)
      setTimeout(()=>{
        message.success(`${info.file.name} file uploaded successfully.`);
      }, 2000)
    } else if (status === 'error') {
      Notification.error('error', `${info.file.name} file upload failed.`);
    }
}

  const uploadingProgress = () => {
    const hide = message.loading('Upload in progress..', 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 2500);
  };

  const handleUploadDocument = (file) => {
    storeExcelUploadDetails(file)
  }


  const props = {
    name: 'file',
    showUploadList: false,
    multiple: true,
    onChange : (info) => handleUploadChange(info),
    onDrop(e) {
    },
    beforeUpload: (file) => {
      const isExcel = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isExcel) {
        Notification.error('error', "You can only upload xls and xlsx file!");
      } else if (!isLt5M) {
        Notification.error('error', "Image must be smaller than 5MB!");
      }
      return isExcel && isLt5M;
    }
  };


  const columns = [
  {
    title: '#', dataIndex: 'slno', key: 'slno', render: (text, record, idx) =>{
    return (
      <div className="roboto bold-600">{idx + 1}</div>
  )}},
  {
    title: 'Name', dataIndex: 'name', key: 'name', render: (text, record) => {
    return (
    <>
      <Typography.Text ellipsis={{tooltip: true}} style={{width: 80}} className="color-black bold-600 text-xmd roboto">{text}</Typography.Text>
      <div className="simgrey bold text-xs roboto">{record?.role?.map((item)=>item)}</div>
    </>
  )}},
  {
    title: 'PHONE NUMBER', dataIndex: 'phoneNo', key: 'phoneNo', render: (text, record) =>{
    return (
      <div className="simgrey bold-600 text-xmd roboto">{record?.phone}</div>
  )}},
  {
    title: 'EMAIL ID', dataIndex: 'emailId', key: 'emailId', render: (text, record) =>{
    return (
      <Typography.Text ellipsis={{tooltip: true}} style={{width: 100}} className="simgrey bold-600 text-xmd roboto">{record?.email}</Typography.Text>
  )}},
  {
    title: 'COURSE', dataIndex: 'course', key: 'course', render: (text, record) =>{
    return (
      <Typography.Text ellipsis={{tooltip: true}} style={{width: 60}} className="simgrey bold-600 text-xs roboto">{record?.course}</Typography.Text>
  )}},
  {
    title: 'PRIVILEGES', dataIndex: 'privileges', key: 'privileges', render: (text, record) =>{
    return (
        <Typography.Text ellipsis={{tooltip: true}} style={{width: 150}} className="simgrey bold-600 text-xs roboto">{record?.priviliege?.join(', ', " ")}</Typography.Text>
  )}},
  {
    title: 'STATUS', dataIndex: 'status', key: 'status', render: (text, record) =>{
    return (
      <center className="simgrey bold-600 text-xmd roboto" style={{ fontFamily: 'roboto'}}>
        {record?.status == "Fail" ? 
        <div style={{color: '#FF414D', cursor: 'pointer', fontFamily: 'roboto'}}>
          <Tooltip placement="right" getPopupContainer={(trigger) => trigger.parentElement} title={record?.failMessage}>
            <WarningOutlined /> {record?.status?.toUpperCase()}
          </Tooltip>
        </div>
        : record?.status == "Pending" ? 
        <div style={{color: '#FFA931', fontFamily: 'roboto'}}>
          <ClockCircleOutlined /> {record?.status?.toUpperCase()}
        </div>
        : record?.status == 'Success' ?
        <div style={{color: '#28DF99', fontFamily: 'roboto'}}>
          <CheckCircleOutlined /> {record?.status?.toUpperCase()}
        </div>
        : null}
      </center>
  )}},
  ];

  return (
    <Modal
      className="addUserModal"
      footer={null}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={currentView == 3 ? false : true}
      width={currentView == 3 ? 900 : 700}
      style={{marginTop: -90, height: 500}}
      centered
    >
    <>
    {currentView == 0  ?
    <>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ marginBottom: "0", fontWeight: "bold" }}>Choose</h2>
        <p style={{ color: "#636363", marginBottom: "25px" }}>
          Add user(s) by importing in groups or by filling a form manually.          
        </p>
      </div>
      
      <div className="r-jsb">
        <div className='shadow-box p-20 position-relative cursor-pointer shadow-box hover-shadow customCardShadow' style={{ height: 320, width: '45%', border: '1px solid #E6E6E6', borderRadius: 8, minWidth: 250}} onClick={()=>{setSelected(1); storeAddUsers('type', 'single')}}>
          <div style={{float: 'right'}}>
            {selected == 1 ? <MdRadioButtonChecked className="color-purple"/> : <MdRadioButtonUnchecked/>}
          </div>
        <center style={{ height: 100}}>
          <img src={createYourOwn} className='m-t-10'/>
              <center className="m-t-20 color-black bold-700 text-md" style={{ height: 'fit-content'}}>{'Create your own'}</center>
              <center className="m-t-20 text-xmd simgrey" style={{ height: 'fit-content'}}>{'Add a user by filling a form manually.'}</center>
        </center> 
        </div>

        <div className='shadow-box p-20 position-relative cursor-pointer shadow-box hover-shadow customCardShadow' style={{ height: 320, width: '45%', border: '1px solid #E6E6E6', borderRadius: 8, minWidth: 250}} onClick={()=>{setSelected(2); storeAddUsers('type', 'bulk')}}>
        <div style={{float: 'right'}}>
            {selected == 2 ? <MdRadioButtonChecked className="color-purple"/> : <MdRadioButtonUnchecked/>}
          </div>
        <center style={{ height: 150}}>
          <img src={bulkImport} className='m-t-10'/>
              <center className="m-t-50 color-black bold-700 text-md" style={{ height: 'fit-content'}}>{'Import user(s)'}</center>
              <center className="m-t-20 text-xmd simgrey" style={{ height: 'fit-content'}}>{'Add user(s) by importing in groups'}</center>
        </center> 
        </div>
      </div>
    </>
    : 
    currentView == 1 ? 
    <div className="p-30">
      <div style={{ textAlign: "center" }}>
        <h2 style={{ marginBottom: "0", fontWeight: "bold" }}>Add a user</h2>
        <p style={{ color: "#636363", marginBottom: "25px" }}>
          Kindly provide the following details          
        </p>
      </div>

      <div className="scroll-bar-universal">
      <div className="m-b-5 color-black bold-600 text-xmd display-flex" style={{width: 'fit-content'}}>User Type<span style={{color: 'red'}}>*</span></div>
      <Select onChange={(value)=>{storeAdduserFields('role', value)}} getPopupContainer={(trigger)=>trigger.parentElement} className="full-width" placeholder={'Select user type'}>
        {userType?.map((item)=>{
          return <Option value={item?.type}>{item?.type}</Option>
        })}
      </Select>

      <div className="m-b-5 m-t-20 color-black bold-600 text-xmd" style={{width: 'fit-content'}}>Name<span style={{color: 'red'}}>*</span></div>
      <Input onChange={(e)=>{storeAdduserFields('name', e.target.value)}} className="full-width" placeholder={"Enter name"}/>

      <div className="m-b-5 m-t-20 color-black bold-600 text-xmd" style={{width: 'fit-content'}}>Phone number<span style={{color: 'red'}}>*</span></div>
      <PhoneInput addInternationalOption={true} value={null} onChange={(e)=>{storeAdduserFields('phoneNo', e)}} className="full-width" placeholder={"Enter number"} defaultCountry="IN"/>

      <div className="m-b-5 m-t-20 color-black bold-600 text-xmd" style={{width: 45}}>Email</div>
      <Input onChange={(e)=>{storeAdduserFields('email', e.target.value)}} className="full-width" placeholder={"Enter email ID"}/>
     {console.log("AA-studentCourse",storeAddUserDetails?.role)}
    {storeAddUserDetails?.role == 'Student' ? 
      <>
     
      {classList?.response?.items?.length == 0 ? null :
       ( <><div className="m-t-20 color-black bold-600 text-xmd" style={{width: 70}}>Course</div>
        <Select onChange={(value)=>{storeAdduserFields('course', value)}} placeholder={'Select a course'} style={{width: 300}}>
          {classList?.response?.items?.map((item)=>{
          return <Option key={item?.id} value={item?.id}>{item?.courseName}</Option>
          })}
        </Select></>)
        }
      </>
    :
    <>
    <div className="m-t-20 color-black bold-600 text-xmd m-b-10">Privileges</div>
    <Checkbox.Group
      style={{ width: "100%", fontFamily: "Roboto" }}
      onChange={onChange}
      value={storeAddUserDetails?.privilage}
    >
      <Row>
        <Col style={{ marginBottom: "20px" }} span={8}>
          <Checkbox disabled={user?.privilage?.includes("Admin") && !user?.privilage?.includes("SuperAdmin") ? true : false}
            value="Admin"
          >
            Admin
          </Checkbox>
        </Col>
        <Col span={8}>
        {isAdminSelected?
              <div style={{marginTop:3}}>
                <div style={{height:17,width:17,border:"1px solid #E6E6E6",display:"flex",alignItems:"center",cursor:"not-allowed",backgroundColor:"#E6E6E6"}}>
                    <CheckOutlined style={{color:"white"}}/>
                  <div style={{padding:5,color:"#E6E6E6",fontSize:14,marginLeft:5}}>Planner</div>
                </div>
              </div>
              :
              <Checkbox disabled={isAdminSelected} value="Planner">
                Planner
              </Checkbox>

              }
        </Col>
        <Col span={8}>
          <Checkbox
            disabled={
              user?.privilage?.includes("Admin") &&
              !user?.privilage?.includes("SuperAdmin")
                ? true
                : false
            }
            value="FeeManager"
          >
            Fee manager
          </Checkbox>
        </Col>
        <Col span={8}>
          {isAdminSelected?
            <div style={{marginTop:3}}>
              <div style={{height:17,width:17,border:"1px solid #E6E6E6",display:"flex",alignItems:"center",cursor:"not-allowed",backgroundColor:"#E6E6E6"}}>
                  <CheckOutlined style={{color:"white"}}/>
                <div style={{padding:5,color:"#E6E6E6",fontSize:14,marginLeft:5}}>Institute support</div>
              </div>
            </div>:
            <Checkbox
              disabled={isAdminSelected}
              value="InstituteSupport"
            >
              Institute support
            </Checkbox>
          }
        </Col>
        <Col
          style={{display: storeAddUserDetails?.role === "Teacher" ? "none" : "block"}}
          span={8}
        >
          <Checkbox
            value="AttendanceMarker"
          >
            Attendance marker
          </Checkbox>
        </Col>
        <Col span={8}>
          <Checkbox
          disabled={user?.privilage?.includes('Admin')}
            value="LeaveManager"
          >
            Leave Manager 
          </Checkbox>
        </Col>
      </Row>
    </Checkbox.Group>
    </>
    }
    </div>
    </div>
    : 
    currentView == 2 ? <>
    <div>
      <center style={{color: '#191919', fontSize: 24, fontFamily: 'roboto', fontWeight: 600}}>Add user(s)</center>
      <center style={{color: '#636363', fontSize: 14, fontFamily: 'roboto'}}>Add user(s) by importing in groups</center>
    </div>
    <div style={{ width: '30%', float: 'right', marginTop: 40, display: 'flex', marginRight: -40}}>
    </div>
    <center style={{width: 600, height: 80, backgroundColor: '#FAFAFA', marginTop: 20, borderRadius: 6, marginLeft: '4%'}}>
      <div className="p-20" style={{width: 500, color: '#636363', fontFamily: 'roboto', fontWeight: 500, marginLeft: -130, marginTop: 30}}>If you have updated classrooms or courses, we recommend you to download the new template before uploading</div>
      <div style={{ marginLeft: '400px', width: 100, height: 40, marginTop: -65}}>
        <Button  
          style={{ fontSize: 18, marginLeft: -10}} 
          icon={downloadLoader ?  <LoadingOutlined style={{marginTop: -6, marginRight: -5}}/> : <DownloadOutlined style={{marginTop: -6, marginRight: -5 }}/>} 
          type='link' 
          className="radius-100" 
          onClick={(e)=>{handleDownloadTemplate(e)}}
        >
          TEMPLATE
        </Button>
      </div>
    </center>
   
      <div className="m-t-5 p-10">
        <Dragger {...props} style={{ borderColor: "#0086FF", backgroundColor: '#E7F3FF' }}>
          <p className="ant-upload-drag-icon">
            <img src={bulkImport}/>
          </p>
          <p className="ant-upload-text">{excelFile?.name}</p>
            <p className="ant-upload-text">Drag and drop your files here</p>
          <p className="ant-upload-hint">
            Support files include xls and xlsx.
          </p>
          <div className="bold-700 text-xmd m-t-20">
            OR 
          </div>
          <center>
            <Button className="purpleBtnWrap radius-100 purpleButtonHoverEffect m-t-20">BROWSE FILES</Button>
          </center>
        </Dragger>
      </div>
      </>
      : currentView == 3 ?
      <div className="m-t-20">
        <Table pagination={false} columns={columns} dataSource={pendingUsers}></Table>
      </div>
    : null}

    <center className="r-jsb m-t-20" style={{ width: 250, marginLeft: '20.5%', marginLeft: currentView == 1 ? 200 : 135}}>
      <Button 
        className="blankGreyBtnWrap radius-100 boxShadow118" 
        style={{width: 100, marginLeft: currentView == 3 ? '-40px' : 0, marginLeft: currentView == 1 ? 100 : 0, marginLeft: currentView == 2 ? '25%' : 0, marginRight: currentView == 2 ? 20 : 0}} 
        onClick={handleBack}>
          {currentView == 3 || currentView == 0 ? null : "BACK"}
      </Button>

      <Button 
        className="purpleBtnWrap radius-100 purpleButtonHoverEffect boxShadow118" 
        style={{width: 100, marginRight: currentView == 3 ? "25px" : 0, marginLeft: currentView == 1 ? 200 : 0, marginLeft: currentView == 2 ? 70 : 0, marginLeft: currentView == 3 ? 120 : 0}} 
        loading={currentView == 2 ? documentUploadLoader : buttonLoader} 
        onClick={()=>handleNext()}
        disabled={selected !== 1 && selected !== 2 || ChooseUploadType === '' ? true : false || currentView == 2 && excelFile == null || currentView == 3 && pendingUsers?.some(el => el?.status === 'Pending') || (currentView == 1 && fillTheDetails) ? true : false } 
      >
        {currentView == 3 ? "DONE" : "NEXT" && currentView == 1 ? 'ADD' : 'NEXT' && currentView == 2 ? 'ADD' : 'NEXT'}
      </Button>
    </center>
    </>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const { tab, classList, storeAddUserDetails, privilages, excelData, ChooseUploadType, excelFile, pendingUsers } = state.userManagement;
  const { user } = state.session;
  return { tab, classList, storeAddUserDetails, privilages, user, excelData, ChooseUploadType, excelFile, pendingUsers };
};

const mapDispatchToProps = (dispatch) => ({
  // setTab: (m) => dispatch(setTab(m))
  setStatus: (m) => dispatch(setStatus(m)),
  setAddRole: (m) => dispatch(setAddRole(m)),
  setDashBoardRole: (m) => dispatch(setDashBoardRole(m)),
  storeAddUsers: (key,val) => dispatch(storeAddUsers(key,val)),
  getStudentsclass: (callback) => dispatch(getStudentsclass(callback)),
  storeAdduserFields: (key,val) => dispatch(storeAdduserFields(key,val)),
  setPrivilage: (privilageData) => dispatch(setPrivilage(privilageData)),
  storeExcelUploadDetails:(res) => dispatch(storeExcelUploadDetails(res)),
  uploadBulkUsers:(callback, successCallBack) => dispatch(uploadBulkUsers(callback, successCallBack)),
  fetchExcelData: (callback) => dispatch(fetchExcelData(callback)),
  downloadTemplate: (callback) => dispatch(downloadTemplate(callback)),
  deleteExcelOnComplete: () => dispatch(deleteExcelOnComplete()),
  clearAddUserFields:(key,val) => dispatch(clearAddUserFields(key,val)),
  getStats: (m) => dispatch(getStats(m)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUsers);