import { Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DemoColumn from "./antGraphs";
import image1 from "../../Assets/care/no-subjects.svg";
import UserCalender from "./userCalender";
import { GraphHeading, SubHeading } from "components/Typography";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
// import { setEndDate, setStartDate } from "actions/adminCare";
// import { setSartDate } from "actions/schedule";

function GrowthSummary({
  isMonthOrWeek,
  growthSummary,
  setPageLoader
  // setStartDate,
  // setEndDate,
  // dashboardData,
}) {
  const [sum, setSum] = useState("");
  useEffect(() => {
    let sum = 0;
    setSum(0);
    for (let i = 0; i < growthSummary?.length; i++) {
      sum = sum + growthSummary[i]?.added;

      sum = sum + growthSummary[i]?.deleted;
      // console.log(sum);
    }
    setSum(sum);
    // console.log("sum", sum, growthSummary);
  }, [growthSummary]);

  return (
    <div>
      <div
        className="careAdminSummaryCard"
        style={{
          boxShadow: "1px 1px 8px #c7cece",
          minWidth:'97%',
          minHeight:'100%'
          // boxShadow: "1px 5px 8px 5px #302a5f14",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Col span={8}>
            <div>
              <GraphHeading>
                Growth Summary
              </GraphHeading>
              {growthSummary ? (
                <div>
                  {growthSummary && isMonthOrWeek === "week" ? (
                    <div className="adminSummary_p" style={{ fontSize: "12px" }}>
                      {/* <CaretLeftOutlined className="cursor-pointer" onClick={() => handleSwitchWeak('back')} /> */}
                      {moment(growthSummary?.[0]?.date).format("DD/MM/YYYY")} -{" "}
                      {moment(
                        growthSummary[growthSummary?.length - 1]?.date
                      ).format("DD/MM/YYYY")}{" "} 
                      {/* <CaretRightOutlined className="cursor-pointer" onClick={() => handleSwitchWeak('forward')}/> */}
                      {/* <span>| This week</span> */}
                    </div>
                  ) : (
                    <p className="adminSummary_p" style={{ fontSize: "12px" }}>
                      Jan - Dec | {moment().format("YYYY")}
                    </p>
                  )}
                </div>
              ) : null}
            </div>
          </Col>
          <Col span={16}>
            <Row style={{ width: "100%" }}>
              <Col lg={{ span: 24 }} xl={{ span: 12 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "start",
                    marginBottom: 5,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "2%",
                    }}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#9876E6",
                        marginRight: "10px",
                      }}
                    ></div>
                    <span>New</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "10px",
                      marginTop: "2%",
                    }}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#FEA5DD",
                        marginRight: "10px",
                      }}
                    ></div>
                    <span style={{ marginRight: "10px" }}>Deleted</span>
                  </div>
                </div>
              </Col>
              <Col lg={{ span: 24 }} xl={{ span: 12 }}>
                <div style={{ width: "100%", maxWidth: "200px" }}>
                  <UserCalender
                  setPageLoader={setPageLoader}
                  // togglePageLoader={togglePageLoader}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ width: "100%", height: "97%" }}>
          {sum === 0 ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "30%", height: "30%", marginBottom: "20px" }}
                src={image1}
                alt=""
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 style={{ fontWeight: "500" }}>No data!</h1>
                <p
                  style={{
                    color: "#636363",
                    width: "60%",
                    textAlign: "center",
                  }}
                >
                  There are no data to show yet. Please try some other date
                  range.
                </p>
              </div>
            </div>
          ) : (
            <DemoColumn />
          )}
        </div>
      </div>
    </div>
  );
}

// export default GrowthSummary

const mapStateToProps = (state) => {
  const { tab, isMonthOrWeek, dashboardData, growthSummary } =
    state.userManagement;
  return {
    tab,
    isMonthOrWeek,
    dashboardData,
    growthSummary,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // setStartDate: (date) => dispatch(setStartDate(date)),
  // setEndDate: (date) => dispatch(setEndDate(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GrowthSummary);
