import React from 'react'
import { Tooltip } from 'antd';
import { Column } from "@ant-design/plots";




// function BarsComponents({ openproblemsNo, closeproblemsNo, dateOrMonth, maxHeight }) {
//     var openproblemsNu = `calc(${openproblemsNo}/${maxHeight} * 210px)`
//     var closeproblemsNu = `calc(${closeproblemsNo}/${maxHeight} * 210px)`
//     return (

//         <div  style={{ display: "flex", flexDirection: "column", width: "80%", height: "100%" }}>
//             <Tooltip placement="top" title={"sa"} style={{bottom:"-80px"}}>
//             <div  style={{ display: "flex", flexDirection: "row", width: "100%", height: "100%" }}>
//                 <div style={{ width: "50%", height: openproblemsNu, marginTop: `calc(210px - ${openproblemsNu})`, backgroundColor: "#99DED9", marginLeft: "5.5%", borderRadius: "5px 5px 0px 0px" }}>
//                 </div>
//                 <div style={{ width: "50%", height: closeproblemsNu, marginTop: `calc(210px - ${closeproblemsNu})`, backgroundColor: "#B2DFFF", marginLeft: "-1px", borderRadius: "5px 5px 0px 0px" }}>
//                 </div>
//             </div>
//             </Tooltip>
//             <p style={{ color: "#636363", fontFamily: "roboto", fontSize: "12px", marginTop: "5px" }}>{dateOrMonth}</p>
//         </div>
//     )
// }

// import React, { useState, useEffect } from "react";
// import { Column } from "@ant-design/plots";
// import { Api } from 'services';

const BarsComponents = ({data}) => {
//   const data = [
//     {
//       name: "Present",
//       date: "11/12",
//       range: 58.9,
//     },
//     {
//       name: "Present",
//       date: "12/12",
//       range: 100,
//     },
//     {
//       name: "Present",
//       date: "13/12",
//       range: 62,
//     },
//     {
//       name: "Present",
//       date: "14/12",
//       range: 62,
//     },
//     {
//       name: "Present",
//       date: "15/12",
//       range: 62,
//     },
//     {
//       name: "Absent",
//       date: "11/12",
//       range: 10,
//     },
//     {
//       name: "Absent",
//       date: "13/12",
//       range: 9,
//     },
//     {
//       name: "Absent",
//       date: "14/12",
//       range: 9,
//     },
//     {
//       name: "Absent",
//       date: "15/12",
//       range: 9,
//     },
//   ];
console.log("data",data)

  const config = {
    data,
    isGroup: true,
    xField: "date",
    yField: "range",
    seriesField: "name",
    // tooltip:{customContent:(val,item)=>{
    //   // console.log("hhh",item)
    //   return(
    //     <div>
    //       <div style={{marginLeft:"-20px",marginRight:"-20px",alignItems:"center" ,height:"100px"}}>
    //       <div 
    //       style={{
    //         minWidth:"200px"
    //       }}></div>
    //       <div style={{display:"flex",marginTop:"8px",width:"190px",marginLeft:"20px"}}>
    //         <div><img style={{width:"45px",height:"45px"}}src={`${Api._s3_url}${item[0]?.data?.icon}`}/></div>
    //           <div>
    //           <div  style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto",marginTop:"10px",width:"150px",marginLeft:"8px",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{/* {item[0]?.data?.subjectName} */}{item[0]?.data?.subjectName.substring(0,item[0]?.data?.subjectName.indexOf('~'))}</div>
    //           <div  style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto",marginTop:"5px",width:"150px",marginLeft:"8px",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{item[0]?.data?.teacherName}</div> 
    //           </div>
    //         </div>
    //           <div style={{display:"flex",justifyContent:"space-between",marginTop:"10px",width:"150px",marginLeft:"20px"}}>
    //           <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto"}}>Present  </div>
    //           <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto",fontWeight:"bold"}}>{parseInt(item[0]?.data?.presentCount)} </div>
    //           </div>
    //           <div style={{display:"flex",justifyContent:"space-between",marginTop:"5px",width:"150px",marginLeft:"20px"}}>
    //           <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto"}}>Absent  </div>
    //           <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto",fontWeight:"bold" }}>{parseInt(item[1]?.data?.absentCount)}</div>
    //           </div>
  
    //       </div>
    //     </div>

    //   )
        
    // }},
    columnStyle: {
      radius: [3, 3, 0, 0],
    },

    color: ["#99DED9", "#B2DFFF"],
    legend: false,
    minColumnWidth: 15,
    maxColumnWidth: 15,
  };
  return <Column {...config} />
};
// export default NeetBarGraph;


export default BarsComponents;


