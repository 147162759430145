import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, DownCircleOutlined, DragOutlined, EditOutlined, FileOutlined, FilePdfOutlined, InfoCircleOutlined, LockOutlined, MinusCircleOutlined, PlusCircleOutlined, UpCircleOutlined, YoutubeOutlined } from '@ant-design/icons';
import { Checkbox, Input, Progress, Tooltip, Typography, message } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useEffect, useState } from 'react'
import { MdKeyboardBackspace } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PDFDocument } from 'pdf-lib';
import { connect } from 'react-redux';
import { MergeChunk, deleteCourseDocument, deleteQuiz, fileKeyUpdate, getEditQuizData, getSpecificVideoCourseData, postCreateVideoResourceContent, storeQuizData, updatepdf, uploadBlock2 } from 'actions/courseManagement';
import quiz from '../../../Assets/aulasMarket/ic-practice-quiz.svg'
import { v4 as uuidv4 } from "uuid";
import video from '../../../Assets/course/Video.png'
import { Button } from 'components/Button';
import { Api } from 'services';



const blockSize = 1048576 * 20;
const newResourcesCreate = {
    backgroundColor: "#F6F4FE",
    padding: "10px",
    boxShadow: "4px 4px 16px #E6E6E6",
    borderRadius: "7px",
    width: "100%"
}
const expandedCard = {
    backgroundColor: "#FFFF",
    padding: "10px",
    boxShadow: "4px 4px 16px #E6E6E6",
    borderRight: '1px solid #E6E6E6',
    borderLeft: '1px solid #E6E6E6',
    borderBottom: '1px solid #E6E6E6',
    height: "620px",
    // borderRadius: "7px",
    width: "100%"
}

const newAddtopicCard = {
    backgroundColor: "#F6F4FE",
    padding: "15px",
    boxShadow: "4px 4px 16px #E6E6E6",
    borderRadius: "7px",
    width: "100%"
}

const ItemType = 'SECTION';

const DraggableSection = ({ section, index, moveSection, setSections, sections, addLesson, localVideo, setLocalVideo, youtubelinks, setYouTubeLinks, youtubeData, setYoutubeData, MergeChunk, fileKeyUpdate, updatepdf, uploadBlock2, isType, setIsType
    , setIncrease, Pdftoupload, increase, getEditQuizData, deleteCourseDocument, videoFile, setVideoFile, history, storeQuizData, location, deleteQuiz, setSaveBtnEnable,saveBtnEnable}) => {
    const ref = React.useRef(null);
    const dragRef = React.useRef(null);

    const handleRemoveSection = (sectionIndex) => {
        const updatedSections = sections.filter((_, index) => index !== sectionIndex);
        setSections(updatedSections);
    };

    function formatDuration(durationInSeconds) {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showProgress,setShowProgress] = useState(false)

    const handleResourceUpload = (info, index, topicIndex) => {
        // setIsStatus(false)
        setUploadProgress(0)
        updatepdf(info.file.originFileObj);
        const { status } = info.file;
        if (status === 'done' || status === 'error') {
            const fileType = info.file.type;

            // Set the appropriate message based on file type
            let uploadingMessage;
            if (fileType === "application/pdf") {
                uploadingMessage = message.loading('Pdf upload in progress..', 0);
            } else {
                uploadingMessage = message.loading('Video upload in progress..', 0);
            }
            setShowProgress(true)
            console.log("uploadingMessage1", message);
            uploadFunction(uploadingMessage, index, topicIndex, () => { }, () => { });
            setIncrease(increase + 1);

            const updateResourceFile = [...sections];
            const videoFile = info.file.originFileObj;
            info.file.originFileObj?.arrayBuffer().then((data) => {
                PDFDocument.load(data).then((doc) => {
                    const pageCount = doc.getPageCount();
                    const updateResourceFile = [...sections];

                    updateResourceFile[index].topics[topicIndex].pageCount = pageCount
                    setSections(updateResourceFile);

                }).catch((err) => {
                });
            })
            updateResourceFile[index].topics[topicIndex].file = videoFile;
            updateResourceFile[index].topics[topicIndex].fileName = videoFile?.name;
            updateResourceFile[index].topics[topicIndex].fileSize = videoFile?.size;
            updateResourceFile[index].topics[topicIndex].fileIndex = increase + 1;

            if (videoFile.type === "application/pdf") {

                updateResourceFile[index].topics[topicIndex].type = 'notes';
                updateResourceFile[index].topics[topicIndex].videoType = 'video';
            } else {
                updateResourceFile[index].topics[topicIndex].videoType = 'video';
                updateResourceFile[index].topics[topicIndex].type = 'video';
                if (videoFile.type.startsWith('video/') && videoFile.type !== 'video/quicktime') {
                    const videoElement = document.createElement('video');
                    videoElement.src = URL.createObjectURL(videoFile);
                    videoElement.onloadedmetadata = function () {
                        const durationInSeconds = Math.floor(videoElement.duration);
                        const formattedDuration = formatDuration(durationInSeconds);
                        updateResourceFile[index].topics[topicIndex].duration = formattedDuration;
                        URL.revokeObjectURL(videoElement.src);
                        setSections(updateResourceFile);
                    };
                }
            }
            setSections(updateResourceFile);
        } else if (status === 'error') {
            Notification.error('error', `${info.file.name} file upload failed.`);
        }
    };


    let uploadingMessage;

    // merge chunk and upload
    let blockStart = null;
    let blockEnd = null;
    let currentBlockNumber = null;
    let fileId = null;
    let totalBlocksCount = null;
    const uploadFunction = async (uploadingMessage, index, topicIndex, callback, successCallback) => {
        console.log("pdffff", Pdftoupload);
        totalBlocksCount = Pdftoupload?.size % blockSize === 0 ? Pdftoupload?.size / blockSize : Math.floor(Pdftoupload?.size / blockSize) + 1;
        fileId = uuidv4() + "." + Pdftoupload.name.split('.').pop();
        blockStart = 0;
        blockEnd = blockSize;
        console.log("pdffff1", totalBlocksCount, fileId);
        for (currentBlockNumber = 1; currentBlockNumber <= totalBlocksCount; currentBlockNumber += 1) {

            var block = Pdftoupload.slice(blockStart, blockEnd);
            var blockWithType = new Blob([block], { type: Pdftoupload?.type });

            await uploadBlock(uploadingMessage, blockWithType, index, topicIndex, callback, successCallback);
        }
    };

    const uploadBlock = async (uploadingMessage, currentBlock, index, topicIndex, callback, successCallback) => {

        try {
            // currentBlock.type='application/pdf'
            var params = {
                file: currentBlock,

                orderId: currentBlockNumber,
                fileName: fileId,

            }
            console.log('pdfff', params);
            setSaveBtnEnable(true)

            await uploadBlock2(params, async (response) => {
                if (response != undefined) {
                    if (response?.data?.progress?.message === "uploaded") {
                        blockStart = blockEnd;
                        blockEnd += blockSize;
                        if (currentBlockNumber === totalBlocksCount) {
                            var percent = Math.round((currentBlockNumber / totalBlocksCount) * 100);
                            setUploadProgress(percent);
                            MergeChunk(fileId, (key) => {
                                if (uploadingMessage) {
                                    uploadingMessage()
                                }
                                const updateResourceFile = [...sections]
                                updateResourceFile[index].topics[topicIndex].fileUrl = key;


                                setSections(updateResourceFile)
                                setSaveBtnEnable(false)
                                setShowProgress(false)
                            })
                        } else {
                            var percent = Math.round((currentBlockNumber / totalBlocksCount) * 100);
                        
                            setUploadProgress(percent);
                        }
                    }
                }
            });
        } catch (error) {
            console.log("error in block upload", error);
        }
    }

    const [, drop] = useDrop({
        accept: ItemType,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveSection(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: ItemType,
        item: { type: ItemType, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    preview(drop(ref));

    const opacity = isDragging ? 0 : 1;

    function formatFileSize(sizeInBytes) {
        const kilobyte = 1024;
        const megabyte = kilobyte * 1024;
        const gigabyte = megabyte * 1024;
        if (sizeInBytes >= gigabyte) {
            return (sizeInBytes / gigabyte).toFixed(2) + ' GB';
        } else if (sizeInBytes >= megabyte) {
            return (sizeInBytes / megabyte).toFixed(2) + ' MB';
        } else if (sizeInBytes >= kilobyte) {
            return (sizeInBytes / kilobyte).toFixed(2) + ' KB';
        } else {
            return sizeInBytes + ' bytes';
        }
    }


    useEffect(() => {
        console.log("location?.state?.fromAddQuizPage", location?.state?.fromAddQuizPage, location?.state?.retrieveQuizUpdatedTopics);
        if (location?.state?.fromAddQuizPage) {

            if (location?.state?.retrieveQuizUpdatedTopics?.length > 0) {
                console.log("abcdef", location?.state?.retrieveQuizUpdatedTopics);
                const lastItem = location.state.retrieveQuizUpdatedTopics.slice(-1)[0];
                if (lastItem.topics && lastItem.topics.length > 0 && lastItem.topics.slice(-1)[0].quizId === null) {
                    lastItem.topics.pop();
                }
            }
            setIncrease(location?.state?.increase)
            setSections(location?.state?.retrieveQuizUpdatedTopics)
        }
    }, [location?.state?.fromAddQuizPage])

    const handleRemoveVRTopicField = (index, topicIndex, fromQuiz) => {

        if (fromQuiz) {
            const updatedVideoResourcesTopics = [...sections];
            updatedVideoResourcesTopics[index].topics[topicIndex].name = null
            deleteQuiz(updatedVideoResourcesTopics[index].topics[topicIndex]?.quizId)
            if (updatedVideoResourcesTopics[index].topics.length === 1) {
                updatedVideoResourcesTopics[index].topics[topicIndex].name = null
                updatedVideoResourcesTopics[index].topics[topicIndex].file = null
                updatedVideoResourcesTopics[index].topics[topicIndex].fileUrl = null
                updatedVideoResourcesTopics[index].topics[topicIndex].fileName = null
                updatedVideoResourcesTopics[index].topics[topicIndex].type = null
                updatedVideoResourcesTopics[index].topics[topicIndex].duration = null
            } else {
                delete updatedVideoResourcesTopics[index].topics[topicIndex];
                updatedVideoResourcesTopics[index].topics = updatedVideoResourcesTopics[index].topics.filter(topic => topic !== null && topic !== undefined);
            }
            setSections(updatedVideoResourcesTopics);
        } else {
            setUploadProgress(0)
            const updateVideoResourcesTopics = [...sections];
            // deleteCourseDocument(updateVideoResourcesTopics[index].topics[topicIndex].fileUrl, isType)
            if (updateVideoResourcesTopics[index].topics.length == 1) {
                updateVideoResourcesTopics[index].topics[topicIndex].name = null
                updateVideoResourcesTopics[index].topics[topicIndex].file = null
                updateVideoResourcesTopics[index].topics[topicIndex].fileUrl = null
                updateVideoResourcesTopics[index].topics[topicIndex].fileName = null
                updateVideoResourcesTopics[index].topics[topicIndex].type = null
                updateVideoResourcesTopics[index].topics[topicIndex].duration = null
                setSections(updateVideoResourcesTopics)
                setYoutubeData('')

            } else {
                const updateVideoResourcesTopics = [...sections];
                // deleteCourseDocument(updateVideoResourcesTopics[index].topics[topicIndex].fileUrl,);
                if (updateVideoResourcesTopics[index].topics.length === 1) {
                    updateVideoResourcesTopics[index].topics = [];
                } else {
                    delete updateVideoResourcesTopics[index].topics[topicIndex];
                    updateVideoResourcesTopics[index].topics = updateVideoResourcesTopics[index].topics.filter(topic => topic !== null && topic !== undefined);
                }
                setSections(updateVideoResourcesTopics);
                setYoutubeData('')

            }
            setSections(updateVideoResourcesTopics)
            setYoutubeData('')

        }
    }

    const updateSectionTopics = (index, topicIndex, field, value) => {
        const updatedSections = [...sections];
        updatedSections[index].topics[topicIndex][field] = value;
        setSections(updatedSections);
    };

    const updateSection = (index, field, value) => {
        const updatedSections = [...sections];
        updatedSections[index][field] = value;
        setSections(updatedSections);
    };
    useEffect(() => {
        if (location?.state?.fromBrodcastVR?.fromAddBroadcastPage) {
            setSections(location?.state?.fromBrodcastVR?.sections)
        }
    }, [location?.state?.fromBrodcastVR?.fromAddBroadcastPage])
    console.log("secccccc", sections);


    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

    const handleUpload = (options) => {
        const { onSuccess } = options;
        setTimeout(() => {
          onSuccess("ok");
        }, 1000);
      };
    return (
        <div ref={ref} style={{ ...newAddtopicCard, marginTop: 20, opacity }}>

            {!section?.expand ? (<>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <div className='m-b-5' style={{ fontSize: "14px", color: "#191919", fontWeight: 'bold' }}>{section?.name}</div>
                        <div className='m-b-5' style={{ fontSize: "12px", color: "#8C8C8C", fontWeight: 500 }}> {section?.name == '' ? 0 : section?.topics?.filter(topic =>  topic.name !== '').length}  lectures</div>
                    </div>
                    <div style={{ width: "80px", display: "flex", justifyContent: "space-between" }}>
                        <Tooltip placement="top" overlay={'Drag & Drop'}>
                            <div ref={dragRef}><DragOutlined style={{ fontSize: "18px", color: "#FFA931", marginTop: "3px", cursor: "pointer" }} ref={(el) => drag(el)} /></div>
                        </Tooltip>
                        <div onClick={() => { updateSection(index, 'expand', true) }}><DownCircleOutlined style={{ fontSize: "18px", color: "#594099", marginTop: "3px", cursor: "pointer" }} /> </div>
                        <Tooltip placement="top" overlay={'Delete section'}>
                            {!saveBtnEnable &&<div onClick={() => { handleRemoveSection(index) }}><CloseCircleOutlined style={{ fontSize: "18px", color: "#FF414D", marginTop: "3px", cursor: "pointer" }} /></div>}
                        </Tooltip>

                    </div>
                </div></>) :
                (<>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div className='m-b-5' style={{ fontSize: "12px", color: "#8C8C8C", fontWeight: 500 }}>Section name</div>
                        <div style={{ width: "50px", display: "flex", justifyContent: "space-between" }}>

                            <div onClick={() => { updateSection(index, 'expand', false) }}> <UpCircleOutlined style={{ fontSize: "18px", color: "#594099", marginTop: "3px" }} /> </div>
                            <Tooltip placement="top" overlay={'Delete section'}>
                                {!saveBtnEnable && <div onClick={() => { handleRemoveSection(index) }}><CloseCircleOutlined style={{ fontSize: "18px", color: "#FF414D", marginTop: "3px", cursor: "pointer" }} /></div>}
                            </Tooltip>

                        </div>
                    </div>

                    <Input
                        placeholder='Enter section name'
                        bordered={null}
                        value={section?.name}
                        onChange={(e) => {
                            const updateSections = [...sections];
                            updateSections[index].name = e.target.value;
                            setSections(updateSections);
                        }}
                    />
                    <div style={{ borderBottom: "1px solid #636363", marginTop: 10 }}></div>
                    {section?.topics?.map((item, topicIndex) => (
                        <div key={topicIndex}>
                            {item?.type != 'quiz' ?
                                (<>
                                    <div style={{display:"flex",justifyContent:"space-between",width:'100%'}}>
                                        <div className='m-b-5' style={{ fontSize: "12px", color: "#8C8C8C", fontWeight: 500, marginTop: 10 }}>Lecture name</div>
                                        {!item.localVideo && !item.youtubeLinks ? (<></>) : !saveBtnEnable && (<>
                                            <div style={{marginTop:5}}
                                            onClick={()=>{updateSectionTopics(index, topicIndex, 'localVideo', false)
                                            updateSectionTopics(index, topicIndex, 'youtubeLinks', false) }}
                                            > <CloseCircleOutlined style={{ fontSize: "18px", color: "#FF414D", marginTop: "3px", cursor: "pointer" }}/></div></>)}
                                    </div>

                                    <Input
                                        placeholder='Enter lecture name'
                                        bordered={null}
                                        value={item?.name}
                                        onChange={(e) => {
                                            const updateTopics = [...sections];
                                            updateTopics[index].topics[topicIndex].name = e.target.value;
                                            setSections(updateTopics);
                                        }}
                                    />
                                    <div style={{ borderBottom: "1px solid #636363", marginTop: 10 }}></div></>)
                                : (<></>)}
                            {item?.file || item?.fileUrl || item?.type == 'quiz' ?
                                <>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ height: "35px", width: "100%", marginTop: "13px", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex" }}>
                                                    {
                                                        item?.type == 'quiz' ?
                                                            (<>
                                                                <img src={quiz} style={{ width: "25px", height: "25px", marginLeft: "10px", marginTop: "5px" }} />
                                                            </>) : (<>
                                                                {item?.type == 'notes' ?
                                                                    (<>
                                                                        <FilePdfOutlined style={{ color: "#FF414D", marginLeft: 10, fontSize: 20, marginTop: "5px" }} />
                                                                    </>) :
                                                                    (<>
                                                                        <img src={video} style={{ width: "25px", height: "25px", marginLeft: "5px", marginTop: 3 }} />
                                                                    </>)}</>)}



                                                    <div style={{ marginTop: "5px" }}>
                                                        <Typography.Text
                                                            ellipsis={{ tooltip: true }}
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "#AEAEAE",
                                                                fontWeight: 700,
                                                                maxWidth: 600,
                                                                width: '200px',
                                                                marginLeft: "20px"
                                                            }}
                                                        >
                                                            {console.log("item?.name", item)}
                                                            {item?.type == 'quiz' ?
                                                                (<>{item?.name}</>) :
                                                                (<>
                                                                    {item?.fileName ? (<>File : {item?.fileName}</>) : item?.videoType === 'recorded' ? (<>Broadcast : {item?.fileName}</>) : item?.type == 'notes' ? (<>Pdf : {item?.fileName}</>) : (<>Youtube</>)}
                                                                </>)}
                                                        </Typography.Text>
                                                    </div>
                                                </div>
                                                <div style={{ color: '#AEAEAE', fontSize: "12px", marginTop: "6px", marginRight: "20px" }}>
                                                    {item?.file?.size ? (<>{formatFileSize(item?.file?.size)}</>) : (<></>)}

                                                </div>
                                            </div>

                                        </div>
                                        {item?.type == 'quiz' ?
                                            (<>
                                                <div style={{ display: "flex" }} >
                                                    <EditOutlined
                                                        onClick={() => {
                                                            console.log("data12332", item?.quizId);
                                                            storeQuizData([])
                                                            getEditQuizData(item?.quizId, () => { })
                                                            history.push({
                                                                pathname: "/course/add-quiz",
                                                                state: {
                                                                    fromVideoResourceSection:
                                                                    {
                                                                        updatedSections: sections,
                                                                        editTrue: true,
                                                                        increase: increase,
                                                                        isEdit: location?.state?.key?.isEdit,
                                                                        topicIndex: topicIndex,
                                                                        quizId: item?.quizId
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        style={{ color: '#1089FF', cursor: 'pointer', fontSize: 18, marginLeft: 20, marginTop: "20px" }}
                                                    />
                                                    < MinusCircleOutlined
                                                        className='m-t-5'
                                                        style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20, marginTop: "20px" }}
                                                        onClick={() => handleRemoveVRTopicField(index, topicIndex, true)}
                                                    />
                                                </div>
                                            </>)
                                            :
                                            
                                            !saveBtnEnable &&  <MinusCircleOutlined
                                                style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20, marginTop: "20px" }}
                                                onClick={() => handleRemoveVRTopicField(index, topicIndex, false)}
                                            />
                                            }

                                    </div>
                                    <div style={{ borderBottom: "1px solid #636363", marginTop: 10 }}></div>
                                    {topicIndex == section.topics.length - 1 && showProgress ? <Progress type='line' percent={uploadProgress} />:null}
                                </> : (<>
                                    {!item.localVideo && !item.youtubeLinks ?
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <div style={{ width: "30%", borderRadius: "50px", border: "1px solid #8C8C8C", backgroundColor: "#ffff", height: "40px", marginTop: "15px", cursor: "pointer" }}>
                                                <div style={{ display: "flex", justifyContent: "center", marginTop: 5 }} onClick={() => { updateSectionTopics(index, topicIndex, 'localVideo', true) }}><FileOutlined style={{ color: "#1089FF", fontSize: "18px", marginTop: "5px" }} /><span style={{ fontSize: "14px", color: "#636363", fontWeight: 500, marginLeft: 5, marginTop: 3 }}>From files</span></div>
                                            </div>
                                            <div style={{ width: "30%", borderRadius: "50px", border: "1px solid #8C8C8C", backgroundColor: "#ffff", height: "40px", marginTop: "15px", opacity:0.5,cursor:"not-allowed"}}>
                                                <div style={{ display: "flex", justifyContent: "center", marginTop: 5, }}
                                                    // onClick={(e) => {
                                                    //     const updateTopics = [...sections];
                                                    //     updateTopics[index].topics[topicIndex].type = 'video';
                                                    //     updateTopics[index].topics[topicIndex].videoType = 'recorded';
                                                    //     setSections(updateTopics);
                                                    //     history.push({
                                                    //         pathname: "/digital-library/division-card",
                                                    //         state: {
                                                    //             fromBrodcastVR:
                                                    //             {
                                                    //                 redirectingPageDL: true,
                                                    //                 sections: sections,
                                                    //             }
                                                    //         }
                                                    //     })
                                                    // }}
                                                ><LockOutlined style={{ color: "#1089FF", fontSize: "18px", marginTop: "5px" }} /><span style={{ fontSize: "14px", color: "#636363", fontWeight: 500, marginLeft: 5, marginTop: 3 }}>Broadcast Recordings</span></div>
                                            </div>
                                            <div style={{ width: "30%", borderRadius: "50px", border: "1px solid #8C8C8C", backgroundColor: "#ffff", height: "40px", marginTop: "15px", cursor: "pointer" }}>
                                                <div style={{ display: "flex", justifyContent: "center", marginTop: 5 }} onClick={() => { updateSectionTopics(index, topicIndex, 'youtubeLinks', true) }}><YoutubeOutlined style={{ color: "#FF414D", fontSize: "18px", marginTop: "5px" }} /><span style={{ fontSize: "14px", color: "#636363", fontWeight: 500, marginLeft: 10, marginTop: 3 }}>YouTube Links</span></div>
                                            </div>
                                        </div> :
                                        (<>
                                            {item.youtubeLinks ?
                                                (<div style={{ marginTop: 5 }}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                        <div className='m-b-5' style={{ fontSize: "12px", color: "#8C8C8C", fontWeight: 500, marginTop: 10 }}>Enter YouTube link</div>
                                                        <div style={{ marginTop: 5, marginRight: 10 }}>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    const updateTopics = [...sections];
                                                                    updateTopics[index].topics[topicIndex].preview = e.target.checked;
                                                                    setSections(updateTopics);
                                                                }}
                                                                checked={item?.preview == 1 || item?.preview == true}
                                                            />
                                                            <span style={{color:"#8C8C8C", fontWeight:700, marginRight:8}}> FREE PREVIEW</span>

                                                        </div>
                                                    </div>
                                                    <Input
                                                        placeholder='Enter YouTube link Example: https://www.youtube.com/watch?v=RlUOoCMnd'
                                                        bordered={null}
                                                        // value={item?.name}
                                                        suffix={<CheckCircleOutlined
                                                            style={{ color: "" }}
                                                            onClick={() => {
                                                                const updateTopics = [...sections];
                                                                updateTopics[index].topics[topicIndex].fileUrl = youtubeData;
                                                                updateTopics[index].topics[topicIndex].fileIndex = -1;
                                                                updateTopics[index].topics[topicIndex].type = 'video';
                                                                updateTopics[index].topics[topicIndex].videoType = 'link';
                                                                updateTopics[index].topics[topicIndex].youtubeLinks = false;


                                                                setSections(updateTopics)

                                                            }} />}
                                                        onChange={(e) => { setYoutubeData(e.target.value) }}
                                                    />
                                                    <div>
                                                        {youtubeData === null || youtubeData === "" || youtubeData?.match(regExp) ? null : (<h style={{ color: "#FF414D", fontSize: 9 }}>Invalid YouTube Link</h>)}
                                                    </div>
                                                    <div style={{ borderBottom: "1px solid #636363", marginTop: 10 }}></div>

                                                </div>) :
                                                <div>

                                                    <Dragger
                                                        // multiple={true}
                                                      customRequest={handleUpload}
                                                        showUploadList={false}
                                                        accept={".mp4,.avi,.mkv,.pdf,.mov"}
                                                        onChange={(e) => { 
                                                            handleResourceUpload(e, index, topicIndex), setIsType('file')

                                                         }}

                                                        style={{ borderColor: "#1089FF", cursor: "pointer", width: "100%", margin: "10px 0px" }}
                                                    >
                                                        <div style={{ display: "flex", justifyContent: "flex-start", paddingLeft: "10px", marginTop: "-10px" }}> <FileOutlined style={{ color: "#1089FF", fontSize: "18px", marginTop: "3px" }} /> <span style={{ color: "#636363", fontSize: 14, fontWeight: 600, marginLeft: "10px", marginTop: 3 }}> Drag and drop or </span><span style={{ color: "#1089FF", margin: "0px 5px", marginTop: 3 }}>Choose file</span> <span style={{ marginTop: 3 }}>to Upload</span></div>
                                                        <div style={{ marginRight: 30, display: "flex", justifyContent: "flex-end", marginTop: -23 }}>Supported file: Pdf/Mp4</div>
                                                    </Dragger>
                                                </div>
                                            }</>)}</>)}

                            {topicIndex === section.topics.length - 1 && (
                                <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                                    <div style={{ fontSize: 15, color: "#1089FF", fontWeight: "bold", cursor: "pointer", marginRight: 10 }} onClick={() => { addLesson(index), setLocalVideo(false), setYouTubeLinks(false) }}><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD LECTURES</span></div>
                                    <div style={{ fontSize: 15, color: "#594099", fontWeight: "bold", cursor: "pointer", marginLeft: 10 }}
                                        onClick={() => {
                                            storeQuizData([])
                                            let targetSection = sections[index]
                                            let newTopic = {
                                                name: 'quiz',
                                                type: "quiz",
                                                quizId: null
                                            }
                                            let updatedTopics = [...targetSection.topics, newTopic];
                                            let updatedSection = {
                                                ...targetSection,
                                                topics: updatedTopics
                                            };
                                            console.log("updatedSection", updatedSection);
                                            history.push({
                                                pathname: "/course/add-quiz",
                                                state: {
                                                    key: {
                                                        fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                        searchedValue: location?.state?.key?.searchedValue,
                                                        fromSubcategory: location?.state?.key?.fromSubcategory,
                                                        fromCourseCard: location?.state?.key?.fromCourseCard,
                                                        fromDashboardSearch: location?.state?.key?.fromDashboard,
                                                        fromCourseDetails: location?.state?.key?.fromCourseDetails,
                                                        isEdit: location?.state?.key?.isEdit,
                                                    },
                                                    fromVideoResourceSection:
                                                    {

                                                        videoFile: [...videoFile],
                                                        increase: increase,
                                                        updatedSections: [
                                                            ...sections.slice(0, index),
                                                            updatedSection,
                                                            ...sections.slice(index + 1)
                                                        ],
                                                    }
                                                }
                                            })
                                        }}><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD QUIZ</span></div>
                                </div>
                            )}
                        </div>
                    ))}
                </>)}

        </div>
    );
};

const VideoResourcesContent = ({ Pdftoupload, fileKeyUpdate, updatepdf, uploadBlock2, MergeChunk, getEditQuizData, deleteCourseDocument, storeQuizData, deleteQuiz, postCreateVideoResourceContent, isVideoResource, setIsVideoResource, getSpecificVideoCourseData, specificCourseDataContainer, isEdit }) => {
    const history = useHistory()
    const location = useLocation()

    const [isExapand, setIsExpand] = useState(false)
    const [increase, setIncrease] = useState(-1);
    const [sections, setSections] = useState(
        [{
            name: '',
            topics: [{
                name: '',
                preview: false,
                fileIndex: 0,
                videoType: null,
                type: null,
                quizId: null,
                fileUrl: null,
                duration: null

            }]
        }]
    )
    const [localVideo, setLocalVideo] = useState(false)
    const [youtubelinks, setYouTubeLinks] = useState(false)
    const [youtubeData, setYoutubeData] = useState('')
    const [isType, setIsType] = useState('')
    const [videoFile, setVideoFile] = useState([])
    const [saveBtnEnable,setSaveBtnEnable]=useState(false)


    useEffect(() => {
        if (isEdit) {

            const sectionTemp = specificCourseDataContainer?.sections?.map((section) => {
                const newTopic = {
                    name: section?.name,
                    id: section?.id,
                    topics: section?.topics?.length > 0 ? section?.topics?.map((topic, index) => ({
                        id: topic?.id || null,
                        name: topic?.name,
                        preview: topic?.preview,
                        fileIndex: !topic?.quizId ? index : null,
                        videoType: topic?.videoType,
                        fileUrl: topic?.fileUrl,
                        duration: topic?.duration,
                        type: topic?.type,
                        quizId: topic?.quizId,
                        fileName: topic.fileName


                    })) :
                        [{
                            name: '',
                            preview: false,
                            fileIndex: 0,
                            videoType: null,
                            type: null,
                            quizId: null,
                            fileUrl: null,
                            duration: null,
                            type: null
                        }]

                };
                return newTopic;
            });
            setSections(specificCourseDataContainer?.sections?.length > 0 ? sectionTemp : [
                {
                    name: '',
                    topics: [{
                        name: '',
                        preview: false,
                        fileIndex: 0,
                        videoType: null,
                        type: null,
                        quizId: null,
                        fileUrl: null,
                        duration: null,
                        type: null
                    }]
                }
            ]);
        }

    }, [specificCourseDataContainer?.course?.id])
    const moveSection = (fromIndex, toIndex) => {
        const updatedSections = [...sections];
        const [movedSection] = updatedSections.splice(fromIndex, 1);
        updatedSections.splice(toIndex, 0, movedSection);
        setSections(updatedSections);
    };

    const addLesson = (index) => {
        console.log("ss");

        const updateTopic = [...sections]
        updateTopic[index]?.topics.push({ name: '', preview: false, fileIndex: increase, videoType: null, type: null, quizId: null, fileUrl: null, duration: null })
        setSections(updateTopic)
    }
    const addSection = () => {
        // if (sections[sections?.length - 1]?.name != '') {
        setSections([...sections, { name: "", topics: [{ name: '', localVideo: false, expand: true, youtubeLinks: false, preview: false, fileIndex: increase, videoType: null, type: null, quizId: null, fileUrl: null, duration: null }] }])
        // }
    }

    let disableVideoSections = sections?.some((section) => {
        const isSectionNameEmptyOrNull = section?.name === '' || section?.name === null || saveBtnEnable;
    
        const isAnyTopicInvalid = section?.topics?.some((topic) => {
            const isTopicNameEmptyOrNull = topic?.name === '' || topic?.name === null;
            const isTopicTypeEmptyOrNull = topic?.type === '' || topic?.type === null;
    
            // Return true if one is filled and the other is empty/null
            return (isTopicNameEmptyOrNull && !isTopicTypeEmptyOrNull) ||
                   (!isTopicNameEmptyOrNull && isTopicTypeEmptyOrNull);
        });
    
        // Return true if section name is empty/null or any invalid topic is found
        return isSectionNameEmptyOrNull || isAnyTopicInvalid;
    });
    // let disableVideoSections = sections?.some((section) => {
    //     const isSectionNameEmptyOrNull = section?.name === '' || section?.name === null || saveBtnEnable;
    //     return isSectionNameEmptyOrNull
    // });

    return (
        <div>
            <div style={newResourcesCreate}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", gap: 10, paddingLeft: 10 }}>

                        <MdKeyboardBackspace
                            className="text-mdl cursor-pointer"
                            label="Back"
                            onClick={() => (setIsVideoResource(false))}
                        />
                        <div style={{ fontSize: "18px", color: "#191919", marginLeft: "10px", fontWeight: "bold" }}>Course content</div>
                    </div>
                    <div style={{ display: "flex", gap: 20 }}>
                        <Button type='link' disabled={disableVideoSections} style={{ color: '#1089FF', fontSize: "15px", fontWeight: 500, cursor:disableVideoSections ? 'not-allowed' :  "pointer" }}
                            onClick={() => {
                                postCreateVideoResourceContent(
                                    sections,
                                    () => { },
                                    () => { history.push("/course-details"), setIsVideoResource(false) }

                                )
                            }}>SAVE</Button>
                        <div onClick={() => { setIsExpand(!isExapand) }}> {!isExapand ? <DownCircleOutlined style={{ fontSize: "18px", color: "#594099", marginTop: "3px", cursor: "pointer" }} /> : <UpCircleOutlined style={{ fontSize: "18px", color: "#594099", marginTop: "3px", cursor: "pointer" }} />}</div>
                    </div>
                </div>

            </div>
            {!isExapand ?

                <div className='scroll-bar-universal' style={expandedCard}>


                    <DndProvider backend={HTML5Backend}>
                        {sections.map((section, index) => (
                            <DraggableSection
                                key={index}
                                index={index}
                                section={section}
                                moveSection={moveSection}
                                setSections={setSections}
                                sections={sections}
                                addLesson={addLesson}
                                localVideo={localVideo}
                                setLocalVideo={setLocalVideo}
                                youtubelinks={youtubelinks}
                                setYouTubeLinks={setYouTubeLinks}
                                youtubeData={youtubeData}
                                setYoutubeData={setYoutubeData}
                                Pdftoupload={Pdftoupload}
                                fileKeyUpdate={fileKeyUpdate}
                                updatepdf={updatepdf}
                                uploadBlock2={uploadBlock2}
                                MergeChunk={MergeChunk}
                                isType={isType}
                                setIsType={setIsType}
                                setIncrease={setIncrease}
                                increase={increase}
                                deleteCourseDocument={deleteCourseDocument}
                                getEditQuizData={getEditQuizData}
                                videoFile={videoFile}
                                setVideoFile={setVideoFile}
                                history={history}
                                storeQuizData={storeQuizData}
                                location={location}
                                deleteQuiz={deleteQuiz}
                                postCreateVideoResourceContent={postCreateVideoResourceContent}
                                setSaveBtnEnable={setSaveBtnEnable}
                                saveBtnEnable={saveBtnEnable}

                            />
                        ))}
                    </DndProvider>
                    <div className='r-c-c m-t-20 m-b-20' style={{ fontSize: 15, color: "#1089FF", fontWeight: "bold", cursor: "pointer" }} onClick={() => { addSection() }}><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD SECTION</span></div>



                </div> : (<></>)
            }
        </div >

    )

}


const mapStateToProps = (state) => {
    const { courseTypeName, specificCourseDataContainer, Pdftoupload } = state.courseManagement;
    return { courseTypeName, specificCourseDataContainer, Pdftoupload };
};
const mapDispatchToProps = (dispatch) => ({
    storeQuizData: (val) => dispatch(storeQuizData(val)),
    deleteQuiz: (id) => dispatch(deleteQuiz(id)),
    uploadBlock2: (params, callback) => dispatch(uploadBlock2(params, callback)),
    MergeChunk: (fileId, callback) => dispatch(MergeChunk(fileId, callback)),
    fileKeyUpdate: (key) => dispatch(fileKeyUpdate(key)),
    updatepdf: (data) => dispatch(updatepdf(data)),
    getEditQuizData: (id, callback) => dispatch(getEditQuizData(id, callback)),
    deleteCourseDocument: (key, isType) => dispatch(deleteCourseDocument(key, isType)),
    postCreateVideoResourceContent: (sections, callBack, successCallBack) => dispatch(postCreateVideoResourceContent(sections, callBack, successCallBack)),
    getSpecificVideoCourseData: (callback) => dispatch(getSpecificVideoCourseData(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoResourcesContent)

