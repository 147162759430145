import {
  Input,
  Tabs,
  Select,
  Typography,
  PageHeader,
} from "antd";
import { SettingFilled, ArrowLeftOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import LectureNotes from "../components/LectureNotes";
import SubjectPageEmpty from "./subjectPageEmpty";
import Videocomponent from "../components/Videocomponent";
import {
  fetchTeacherData,
  fetchAdminLibraryData,
  updateSetSort,
  updateSetTopic,
  updateChapterFilter,
  updateCurrentSub,
  updateCurrentIcon,
  updateSetType,
  fetchTeacherLibraryData,
  updatepdf,
  fetchAdminChapterFilter2,
  fetchStudentLibraryData,
  updateAdminAddTeacher,
} from "actions/digitalLibrary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import UploadModal from "../modals/uploadModel";
import Uploadfiles from "../modals/uploadfiles";
import Videouploadmodel from "../modals/Videouploadmodel";
import Succesfulmodel from "../modals/Succesfulmodel";
import AddTeacherFormView from "../modals/addTeacherFormView";
import PageDataLoader from "components/pageDataLoader";
import EmptyCard from "./emptyCard";
import lecturenotes from "../../../Assets/digitalLibrary/ic-dl-lecture-notes.svg";
import questBankImg from "../../../Assets/digitalLibrary/ic-dl-lecture-question-banks.svg";
import { Button } from "components/Button";

const { Search } = Input;
const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;

const SubjectDetails = ({
  fetchTeacherData,
  fetchAdminLibraryData,
  adminLibraryList,
  selectedSubject,
  updateSetSort,
  updateSetTopic,
  selectedStd,
  updateChapterFilter,
  addedTeacher,
  arrStoreData,
  updateCurrentSub,
  updateCurrentIcon,
  updateSetType,
  fetchTeacherLibraryData,
  getTeacherLibraryList,
  updatepdf,
  setTabType,
  user,
  fetchAdminChapterFilter2,
  getChaptersListForFilter,
  fetchStudentLibraryData,
  getStudentStd,
  updateAdminAddTeacher,
  currentType,
}) => {
  const [modalUpload, setModalUpload] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [pageLoader, togglePageLoader] = useState(false);
  const [modal, setmodel] = useState(false);
  const [uploadFileModal, toggleUploadFileModel] = useState(false);
  const [type, setType] = useState("");
  const [tabKey, setTabKey] = useState("1");
  useEffect(() => {
    togglePageLoader(true);
    updateSetTopic("");
    updateChapterFilter("");
    if (currentType == "lecture-notes") {
      setTabKey("1");
      updateSetType(currentType)
    } else if (currentType == "video") {
      setTabKey("2");
      updateSetType(currentType)
    } else if (currentType == "question-bank") {
      setTabKey("3");
      updateSetType(currentType)
    }
    if (
      user.access.includes("digital-library-admin") ||
      user.access.includes("digital-library-superadmin") ||
      user.access.includes("digital-library-dataoperator")
    ) {
      fetchAdminLibraryData(() => togglePageLoader(false));
      fetchAdminChapterFilter2();
      fetchTeacherData();
    } else if (user.access.includes("digital-library-teacher")) {
      fetchTeacherLibraryData(() => togglePageLoader(false));
    } else {
      fetchStudentLibraryData(() => togglePageLoader(false));
    }
    updatepdf(null);
  }, []);

  const history = useHistory();
  const onSearchTopic = (value) => {
    updateSetTopic(value);
    togglePageLoader(true);
    if (
      user.access.includes("digital-library-admin") ||
      user.access.includes("digital-library-superadmin") ||
      user.access.includes("digital-library-dataoperator")
    ) {
      fetchAdminLibraryData(() => togglePageLoader(false));
      fetchAdminChapterFilter2();
      fetchTeacherData();
    } else if (user.access.includes("digital-library-teacher")) {
      fetchTeacherLibraryData(() => togglePageLoader(false));
    } else {
      fetchStudentLibraryData(() => togglePageLoader(false));
    }
  };

  const onChange = (value) => {
    updateChapterFilter(value);
    togglePageLoader(true);
    updateSetType(setTabType);
    if (
      user.access.includes("digital-library-admin") ||
      user.access.includes("digital-library-superadmin") ||
      user.access.includes("digital-library-dataoperator")
    ) {
      fetchAdminLibraryData(() => togglePageLoader(false));
    } else if (user.access.includes("digital-library-teacher")) {
      fetchTeacherLibraryData(() => togglePageLoader(false));
    } else {
      fetchStudentLibraryData(() => togglePageLoader(false));
    }
  };
  const onChangeSort = (value) => {
    updateSetSort(value);
    togglePageLoader(true);
    updateSetType(setTabType);
    if (
      user.access.includes("digital-library-admin") ||
      user.access.includes("digital-library-superadmin") ||
      user.access.includes("digital-library-dataoperator")
    ) {
      fetchAdminLibraryData(() => togglePageLoader(false));
    } else if (user.access.includes("digital-library-teacher")) {
      fetchTeacherLibraryData(() => togglePageLoader(false));
    } else {
      fetchStudentLibraryData(() => togglePageLoader(false));
    }
  };

  const showTeacher = () => {
    setVisibleForm(true);
  };

  const handleHistory = () => {
    updateAdminAddTeacher([]);
    updateCurrentSub({});
    updateCurrentIcon("");
    updateChapterFilter("");
    history.goBack();
  };

  const onDeselect = () => {
    togglePageLoader(true);
    if (
      user.access.includes("digital-library-admin") ||
      user.access.includes("digital-library-superadmin") ||
      user.access.includes("digital-library-dataoperator")
    ) {
      fetchAdminLibraryData(() => togglePageLoader(false));
    } else if (user.access.includes("digital-library-teacher")) {
      fetchTeacherLibraryData(() => togglePageLoader(false));
    } else {
      fetchStudentLibraryData(() => togglePageLoader(false));
    }
  };

  const handleTabChange = (key) => {
    setTabKey(key);
    if (key == "1") {
      updateSetType("lecture-notes");
      togglePageLoader(true);
      if (
        user.access.includes("digital-library-admin") ||
        user.access.includes("digital-library-superadmin") ||
        user.access.includes("digital-library-dataoperator")
      ) {
        fetchAdminLibraryData(() => togglePageLoader(false));
      } else if (user.access.includes("digital-library-teacher")) {
        fetchTeacherLibraryData(() => togglePageLoader(false));
      } else {
        fetchStudentLibraryData(() => togglePageLoader(false));
      }
    } else if (key == "2") {
      updateSetType("video");
      togglePageLoader(true);
      if (
        user.access.includes("digital-library-admin") ||
        user.access.includes("digital-library-superadmin") ||
        user.access.includes("digital-library-dataoperator")
      ) {
        fetchAdminLibraryData(() => togglePageLoader(false));
      } else if (user.access.includes("digital-library-teacher")) {
        fetchTeacherLibraryData(() => togglePageLoader(false));
      } else {
        fetchStudentLibraryData(() => togglePageLoader(false));
      }
    } else if (key == "3") {
      updateSetType("question-bank");
      togglePageLoader(true);
      if (
        user.access.includes("digital-library-admin") ||
        user.access.includes("digital-library-superadmin") ||
        user.access.includes("digital-library-dataoperator")
      ) {
        fetchAdminLibraryData(() => togglePageLoader(false));
      } else if (user.access.includes("digital-library-teacher")) {
        fetchTeacherLibraryData(() => togglePageLoader(false));
      } else {
        fetchStudentLibraryData(() => togglePageLoader(false));
      }
    }
  };
  if (
    user.access.includes("digital-library-admin") ||
    user.access.includes("digital-library-superadmin") ||
    user.access.includes("digital-library-dataoperator")
  ) {
    if (addedTeacher && addedTeacher?.length !== 0) {
      return (
        <div>
          <div>
            <div className="r-c-sb" style={{ height: "50px" }}>
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PageHeader
                  title={
                    <div
                      className="text-md"
                      style={{ fontSize: "8px !important" }}
                    >
                      {selectedSubject.subjects_name}
                    </div>
                  }
                  onBack={() => handleHistory()}
                  backIcon={<ArrowLeftOutlined />}
                />
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  className="m-r-10 cursor-pointer"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#302A5F14",
                  }}
                  onClick={showTeacher}
                >
                  <SettingFilled
                    style={{ fontSize: "20px", color: "#594099" }}
                  />
                </div>
                {visibleForm && (
                  <AddTeacherFormView
                    visibleForm={visibleForm}
                    setVisibleForm={setVisibleForm}
                  />
                )}

                <Button
                  className="m-r-10"
                  style={{
                    width: "120px",
                    backgroundColor: "#594099",
                    color: "white",
                  }}
                  onClick={() => setModalUpload(true)}
                  icon={"+"}
                >
                   Upload
                </Button>
                <UploadModal
                  visible={modalUpload}
                  setVisible={setModalUpload}
                  toggleUploadFileModel={toggleUploadFileModel}
                  setType={setType}
                />
                {type === "video" ? (
                  <Videouploadmodel
                    type={type}
                    classess={arrStoreData[0]}
                    ismodelvisible={uploadFileModal}
                    setmodevisible={toggleUploadFileModel}
                    setmodel={setmodel}
                  />
                ) : (
                  uploadFileModal && (
                    <Uploadfiles
                      classess={arrStoreData[0]}
                      type={type}
                      ismodelvisible={uploadFileModal}
                      setmodevisible={toggleUploadFileModel}
                      setmodel={setmodel}
                    />
                  )
                )}

                <div className="input-search-textk">
                  <Search
                    placeholder="Search By Topic"
                    bordered="false"
                    allowClear="true"
                    onSearch={onSearchTopic}
                  />
                </div>
              </div>
            </div>

            <Text
              style={{
                marginLeft: "55px",
                color: "#AEAEAE",
                font: "normal normal normal 14px/19px Roboto",
              }}
            >
              {selectedStd?.standard}: {selectedStd?.std_section}
            </Text>
          </div>

          <div>
            <Tabs activeKey={tabKey} onTabClick={handleTabChange}>
              <TabPane tab="Notes" key="1">
                <div className="sel-chap-div">
                  <Select
                    showSearch
                    allowClear={true}
                    style={{ width: 300, borderRadius: "8px" }}
                    placeholder="Select Chapter"
                    optionFilterProp="children"
                    onChange={onChange}
                    onDeselect={onDeselect}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {getChaptersListForFilter?.length != 0
                      ? getChaptersListForFilter.map((item) => (
                        <Option value={item.name}>{item.name}</Option>
                      ))
                      : null}
                  </Select>
                </div>
                <div className="chapter-flex">
                  {adminLibraryList?.length != 0 ? (
                    adminLibraryList?.map((item, index) => (
                      <div>
                        <LectureNotes
                          chapterName={item.chapterName}
                          topic={item.title}
                          file={item.file}
                          id={item.id}
                          filesSize={item.size}
                          types={"lecture-notes"}
                          img={lecturenotes}
                          chapterId={item.chapterId}
                          togglePageLoader={togglePageLoader}
                          index={index}
                        />
                      </div>
                    ))
                  ) : (
                    <EmptyCard uploadButton={true} />
                  )}
                </div>
              </TabPane>
              <TabPane tab="Videos" key="2">
                <div className="sel-chap-div">
                  <Select
                    showSearch
                    allowClear={true}
                    style={{ width: 300, borderRadius: "8px" }}
                    placeholder="Select Chapter"
                    optionFilterProp="children"
                    onChange={onChange}
                    onDeselect={onDeselect}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {getChaptersListForFilter?.length != 0
                      ? getChaptersListForFilter.map((item) => (
                        <Option value={item.name}>{item.name}</Option>
                      ))
                      : null}
                  </Select>
                </div>
                <div className="chapter-flex">
                  {adminLibraryList?.length != 0 ? (
                    adminLibraryList?.map((item) => (
                      <div>
                        <Videocomponent
                          url={item.link}
                          chapterName={item.chapterName}
                          topic={item.title}
                          id={item.id}
                          filesSize={item.size}
                          types={"video"}
                          chapterId={item.chapterId}
                          togglePageLoader={togglePageLoader}
                        />
                      </div>
                    ))
                  ) : (
                    <EmptyCard uploadButton={true} />
                  )}
                </div>
              </TabPane>
              <TabPane tab="Question Bank" key="3">
                <div className="sel-chap-div">
                  <Select
                    showSearch
                    allowClear={true}
                    style={{ width: 300, borderRadius: "8px" }}
                    placeholder="Select Chapter"
                    optionFilterProp="children"
                    onChange={onChange}
                    onDeselect={onDeselect}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {getChaptersListForFilter?.length != 0
                      ? getChaptersListForFilter.map((item) => (
                        <Option value={item.name}>{item.name}</Option>
                      ))
                      : null}
                  </Select>
                </div>
                <div className="chapter-flex">
                  {adminLibraryList?.length != 0 ? (
                    adminLibraryList?.map((item, index) => (
                      <div>
                        <LectureNotes
                          chapterName={item.chapterName}
                          topic={item.title}
                          file={item.file}
                          id={item.id}
                          filesSize={item.size}
                          types={"question-bank"}
                          img={questBankImg}
                          chapterId={item.chapterId}
                          togglePageLoader={togglePageLoader}
                          index={index}
                        />
                      </div>
                    ))
                  ) : (
                    <EmptyCard uploadButton={true} />
                  )}
                </div>
              </TabPane>
            </Tabs>
          </div>
          <Succesfulmodel togglePageLoader={togglePageLoader} fetchApi={true} />
          <PageDataLoader visible={pageLoader} />
        </div>
      );
    } else {
      return (
        <SubjectPageEmpty
          pageLoader={pageLoader}
          togglePageLoader={togglePageLoader}
        />
      );
    }
  } else if (
    user.access.includes("digital-library-teacher") ||
    user.access.includes("digital-library-student")
  ) {
    return (
      <div>
        <div>
          <div className="r-c-sb" style={{ height: "50px" }}>
            <div
              style={{
                width: "10%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PageHeader
                title={
                  <div
                    className="text-md"
                    style={{ fontSize: "8px !important" }}
                  >
                    {selectedSubject.subjects_name}
                  </div>
                }
                onBack={() => handleHistory()}
                backIcon={<ArrowLeftOutlined />}
              />
            </div>
            <div className="input-search-textk">
              <Search
                placeholder="Search By Topic"
                bordered="false"
                allowClear="true"
                onSearch={onSearchTopic}
              />
            </div>
          </div>
          {user.access.includes("digital-library-teacher") ? (
            <Text
              style={{
                marginLeft: "55px",
                color: "#AEAEAE",
                font: "normal normal normal 14px/19px Roboto",
              }}
            >
              {selectedStd?.standard}: {selectedStd?.std_section}
            </Text>
          ) : (
            <Text
              style={{
                marginLeft: "55px",
                color: "#AEAEAE",
                font: "normal normal normal 14px/19px Roboto",
              }}
            >
              {getStudentStd?.std}: {getStudentStd?.section}
            </Text>
          )}
        </div>
        <div>
          <Tabs defaultActiveKey="1" onChange={handleTabChange}>
            <TabPane tab="Notes" key="1">
              <div style={{ display: "flex", flexDirection: "column" }}>
                {getTeacherLibraryList?.length != 0 ? (
                  getTeacherLibraryList?.map((item, index) => (
                    <div>
                      {
                        <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                          {item.name}
                        </div>
                      }
                      <div className="chapter-flex">
                        {item?.digitalLibrary?.map((lib, index) => (
                          <div>
                            <LectureNotes
                              topic={lib.title}
                              file={lib.file}
                              id={lib.id}
                              filesSize={item.size}
                              types={"lecture-notes"}
                              img={lecturenotes}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <EmptyCard />
                )}
              </div>
            </TabPane>
            <TabPane tab="Videos" key="2">
              <div style={{ display: "flex", flexDirection: "column" }}>
                {getTeacherLibraryList?.length != 0 ? (
                  getTeacherLibraryList?.map((item, index) => (
                    <div>
                      {
                        <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                          {item.name}
                        </div>
                      }
                      <div className="chapter-flex">
                        {item?.digitalLibrary?.map((lib, index) => (
                          <div>
                            <Videocomponent
                              url={lib.link}
                              chapterName={lib.chapterName}
                              topic={lib.title}
                              id={lib.id}
                              filesSize={lib.size}
                              types={"video"}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <EmptyCard />
                )}
              </div>
            </TabPane>
            <TabPane tab="Question Bank" key="3">
              <div style={{ display: "flex", flexDirection: "column" }}>
                {getTeacherLibraryList?.length != 0 ? (
                  getTeacherLibraryList?.map((item, index) => (
                    <div>
                      {
                        <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                          {item.name}
                        </div>
                      }
                      <div className="chapter-flex">
                        {item?.digitalLibrary.map((lib, index) => (
                          <div>
                            <LectureNotes
                              topic={lib.title}
                              file={lib.file}
                              id={lib.id}
                              filesSize={item.size}
                              types={"question-bank"}
                              img={questBankImg}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <EmptyCard />
                )}
              </div>
            </TabPane>
          </Tabs>
        </div>
        <PageDataLoader visible={pageLoader} />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  const {
    classLibraryList,
    classSubjectList,
    adminLibraryList,
    selectedSubject,
    selectedStd,
    updateChapterFilter,
    addedTeacher,
    arrStoreData,
    getTeacherLibraryList,
    setTabType,
    getChaptersListForFilter,
    getStudentStd,
    key,
    currentType,
  } = state.digitalLibrary;
  const { user } = state.session;
  return {
    classLibraryList,
    classSubjectList,
    adminLibraryList,
    selectedSubject,
    selectedStd,
    updateChapterFilter,
    addedTeacher,
    arrStoreData,
    getTeacherLibraryList,
    setTabType,
    user,
    getChaptersListForFilter,
    getStudentStd,
    key,
    currentType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTeacherData: () => dispatch(fetchTeacherData()),
  updateSetSort: (value) => dispatch(updateSetSort(value)),
  updateSetTopic: (value) => dispatch(updateSetTopic(value)),
  fetchAdminLibraryData: (callback) =>
    dispatch(fetchAdminLibraryData(callback)),
  updateChapterFilter: (value) => dispatch(updateChapterFilter(value)),
  fetchAdminChapterFilter2: () => dispatch(fetchAdminChapterFilter2()),
  updateCurrentSub: (data) => dispatch(updateCurrentSub(data)),
  updateCurrentIcon: (data) => dispatch(updateCurrentIcon(data)),
  updateSetType: (e) => dispatch(updateSetType(e)),
  updatepdf: (data) => dispatch(updatepdf(data)),
  fetchTeacherLibraryData: (callback) =>
    dispatch(fetchTeacherLibraryData(callback)),
  fetchStudentLibraryData: (callback) =>
    dispatch(fetchStudentLibraryData(callback)),
  updateAdminAddTeacher: (data) => dispatch(updateAdminAddTeacher(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubjectDetails);