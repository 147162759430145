import React from "react";
import Carousel from "antd/lib/carousel";
import Upload from "antd/lib/upload";
import message from "antd/lib/message";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import "antd/lib/carousel/style/index";
import "antd/lib/upload/style/index";
import "antd/lib/message/style/index";
import "antd/lib/modal/style/index";
import "antd/lib/row/style/index";
import "antd/lib/col/style/index";
import "antd/lib/button/style/index";
import {
  SyncOutlined,
  LoadingOutlined,
  CloseOutlined,
  EllipsisOutlined
} from "@ant-design/icons";
import { BsFillPauseFill, BsPlayFill } from "react-icons/bs";
import { FiImage } from "react-icons/fi";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Color } from "services";
import HyperModal from "react-hyper-modal";
import Lottie from "react-lottie";
import animationData from "Assets/lottie/doc.json";
import animationCheck from "Assets/lottie/check.json";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import TeX from "@matejmazur/react-katex";
import { IMG_URL } from "env.json";

import {
  setAnswer,
  updateQtime,
  updateReviewTime,
  setStudentQuestionIndex,
  submitModalToggle,
  postAssignmentQuestions,
  setLocalImage,
  deleteAnswerImage,
  setStudentAssignmentStatus,
  submitAssignment,
  bookmarkQuestion,
  replaceAnswerImage,
  setFinishMode,
  setStudentAssignnent,
  getStudentAssignment,
  resetDeletedAssignmentImages
} from "actions/assignment";
import { connect } from "react-redux";
import { AssignmentTopBar, MobileButton } from "components";
import "./styles.css";
import "design/layout.css";
import "design/assignment.css";
import Compressor from 'compressorjs';


// window.katex = katex;

const QBox = ({
  smallBox,
  delFCount,
  statement,
  options,
  orgId,
  setQAnswer,
  showImageModal,
  hideImageModal,
  type,
  currentIndex,
  selectedKey,
  positiveMarks,
  files,
  totalQuestions,
  qNo,
  gotoQues,
  beforeUpload,
  showChangeImage,
  imageModal,
  bookmarkQuestion,
  bookmark,
  showOptionImageModal,
  modalImgType
}) => {
  let fileCheck = 0;
  if (type === "Image") {
    if (files) {
      fileCheck += files.length;
    }
    if (selectedKey) {
      fileCheck += selectedKey.length;
    }
    if (delFCount) {
      fileCheck -= delFCount;
    }
  }
  const myComponent = (node, type) => {
    let clickHoldTimer = null;
    const handleMouseDown = () => {
      clickHoldTimer = setTimeout(() => {
        showOptionImageModal(node.attribs.src);
      }, 1000); //Change 1000 to number of milliseconds required for mouse hold
    };
    const handleMouseUp = () => {
      clearTimeout(clickHoldTimer);
    };
    const handleOpenClick = () => {
      if (type && type === "statement") showOptionImageModal(node.attribs.src);
    };
    return (
      <div
        onClick={() => handleOpenClick()}
        onMouseDown={handleMouseDown}
        onMouseUp={() => handleMouseUp()}
      >
        <img
          alt=""
          src={node.attribs.src}
          style={{
            width: "100%",
            maxWidth: 100,
            height: 100,
            borderRadius: 10
          }}
        />
      </div>
    );
  };
  return (
    <div
      suppressContentEditableWarning="true"
      style={{
        height: smallBox ? "auto" : "calc(100vh - 153px)",
        margin: smallBox ? 10 : 0
      }}
      className="testSnippet NutinFont hideScroll"
    >
      <Row
        style={{
          zIndex: !smallBox ? 1 : 0
        }}
        className="snippetTopBar orange-yellow p-10"
        justify="space-between"
      >
        <Col xs={8}>
          {positiveMarks == null ? (
            <div></div>
          ) : (
            <div>
              <span className="lowgrey">Marks: </span>
              {positiveMarks}
            </div>
          )}
        </Col>
        <Col xs={8} className="r-c-c">
          {qNo}/<span className="lowgrey">{totalQuestions}</span>
        </Col>
        <Col xs={8} className="r-c-fe" onClick={() => bookmarkQuestion()}>
          {bookmark ? (
            <>
              <FaBookmark className="color-purple" />{" "}
              <span className="color-purple">Bookmarked </span>
            </>
          ) : (
            <>
              <FaRegBookmark /> <span className="lowgrey">Bookmark </span>{" "}
            </>
          )}
        </Col>
      </Row>
      <div onClick={smallBox ? gotoQues : null} className="mainQBox hideScroll">
        <div
          suppressContentEditableWarning="true"
          className="flex-wrap p-l-10 p-r-10 p-t-10 color-black text-md bold-600"
        >
          {ReactHtmlParser(statement, {
            transform: node => {
              if (node.type === "tag" && node.name === "img") {
                return myComponent(node, "statement");
              }
              if (
                node.type === "tag" &&
                node.name === "span" &&
                node.attribs["data-value"]
              ) {
                return <TeX math={`${node.attribs["data-value"]}`} />;
              }
              // if (node.type === 'tag' && node.name === 'math') {
              //   return <MathJax math={String.raw`${statement}`} />;
              // }
            }
          })}
        </div>
        <div className="p-l-15 p-r-10 p-t-10 p-b-15 color-black text-sm">
          <Row>
            {options && options.length
              ? options.map((data, index) => {
                  if (!smallBox) {
                    return (
                      <Col xs={12}>
                        <div
                          suppressContentEditableWarning="true"
                          style={{
                            marginLeft: index % 2 !== 0 ? 5 : 0,
                            marginRight: index % 2 === 0 ? 5 : 0,
                            borderColor:
                              index.toString() === selectedKey
                                ? Color.dodgeBlue
                                : Color.aeGrey,
                            borderWidth:
                              index.toString() === selectedKey ? 2 : 1
                          }}
                          onClick={() => {
                            if (index.toString() === selectedKey) {
                              setQAnswer("");
                            } else {
                              setQAnswer(index.toString());
                            }
                          }}
                          className="m-t-10 p-5 color-black text-xs optionsStyle"
                        >
                          <div className="r-c-sb">
                            ({String.fromCharCode(65 + index)})
                            {index.toString() === selectedKey ? (
                              <i
                                className="icon-ic-submitted text-sm bold-bolder m-l-10 m-r-5"
                                style={{
                                  color: Color.dodgeBlue,
                                  borderRadius: 10,
                                  backgroundColor: "rgb(16, 137, 255, 0.3)"
                                }}
                              />
                            ) : null}
                          </div>
                          {ReactHtmlParser(data.value, {
                            transform: node => {
                              node.suppressContentEditableWarning = "true";
                              if (node.type === "tag" && node.name === "img") {
                                return myComponent(node, "option");
                              }
                              if (
                                node.type === "tag" &&
                                node.name === "span" &&
                                node.attribs["data-value"]
                              ) {
                                return (
                                  <TeX math={`${node.attribs["data-value"]}`} />
                                );
                              }
                              // if (node.type === 'tag' && node.name === 'math') {
                              //   return <MathJax math={String.raw`${data.value}`} suppressContentEditableWarning="true" />;
                              // }
                            }
                          })}
                        </div>
                      </Col>
                    );
                  } else if (smallBox && index.toString() === selectedKey) {
                    return (
                      <div
                        suppressContentEditableWarning="true"
                        style={{
                          minWidth: "40vw",
                          borderColor:
                            index.toString() === selectedKey
                              ? Color.dodgeBlue
                              : Color.aeGrey,
                          borderWidth: index.toString() === selectedKey ? 2 : 1
                        }}
                        className="m-t-10 p-5 p-l-10 color-black text-xs optionsStyle"
                      >
                        ({String.fromCharCode(65 + index)}){" "}
                        {ReactHtmlParser(data.value, {
                          transform: node => {
                            if (node.type === "tag" && node.name === "img") {
                              return myComponent(node, "option");
                            }
                            if (
                              node.type === "tag" &&
                              node.name === "span" &&
                              node.attribs["data-value"]
                            ) {
                              return (
                                <TeX math={`${node.attribs["data-value"]}`} />
                              );
                            }
                            // if (node.type === 'tag' && node.name === 'math') {
                            //   return <MathJax math={String.raw`${data.value}`} />;
                            // }
                          }
                        })}
                        <i
                          className="icon-ic-submitted text-sm bold-bolder m-l-10 m-r-5"
                          style={{
                            color: Color.dodgeBlue,
                            borderRadius: 10,
                            backgroundColor: "rgb(16, 137, 255, 0.3)"
                          }}
                        />
                      </div>
                    );
                  } else return null;
                })
              : null}
          </Row>
          {type === "Image" && !smallBox && fileCheck < 5 ? (
            <Upload
              name="statementImage"
              listType="picture"
              className="cursor-pointer"
              showUploadList={false}
              beforeUpload={file => beforeUpload(file)}
            >
              <div className="lowgrey text-xs">Your answers:</div>
              <div className="a2grey studentAssignmentUp">
                <FiImage className="text-md" />
                <AiOutlinePlusCircle className="text-xs smallPlus" />
              </div>
            </Upload>
          ) : type === "Subjective" ? (
            <div className="lowgrey text-xs">
              Your answer:
              <input
                type="text"
                value={selectedKey}
                className="inputStudentNum radius-10 color-black"
                onChange={e => {
                  const { value } = e.target;
                  const reg = /(^-\d*\.?\d*[0-9]+\d*$)|(^\d*\.?\d*[0-9]+\d*$)|(^[0-9]+\d*\.\d*$)|(^-[0-9]+\d*\.\d*$)/;
                  if (
                    (!Number.isNaN(value) && reg.test(value)) ||
                    value === "" ||
                    value === "-"
                  ) {
                    setQAnswer(e.target.value);
                  }
                }}
              />
            </div>
          ) : null}
          <div>
            {files && files.length
              ? files.map((data, index) => {
                  if (data.deleted) return null;
                  return (
                    <div className="relative inline-flex p-r-10 m-t-10">
                      <div onClick={() => showImageModal(index + 1, "new")}>
                        <img
                          src={
                            data.remote
                              ? `${IMG_URL}/${data.remote.value}`
                              : data.localShowImg
                          }
                          alt="avatar"
                          className="studentUploadImg"
                        />
                      </div>
                      <div
                        onClick={() => showChangeImage(index, "new")}
                        className="text-md white absolute right8"
                      >
                        <EllipsisOutlined style={{ fontSize: "16px" }} />
                      </div>
                    </div>
                  );
                })
              : null}
            {type === "Image" && selectedKey && selectedKey.length
              ? selectedKey.map((data, index) => {
                  if (data.deleted) return null;
                  return (
                    <div className="relative inline-flex p-r-10 m-t-10">
                      <div onClick={() => showImageModal(index + 1, "old")}>
                        <img
                          src={`${IMG_URL}/${data.value}`}
                          alt="avatar"
                          className="studentUploadImg"
                        />
                      </div>
                      <div
                        onClick={() => showChangeImage(index, "old")}
                        className="text-md white absolute right8"
                      >
                        <EllipsisOutlined style={{ fontSize: "16px" }} />
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      {type === "Image" && qNo - 1 === currentIndex ? (
        <Modal
          className="imageModal NutinFont"
          centered
          visible={imageModal}
          bodyStyle={{
            padding: 0,
            borderRadius: 15,
            textAlign: "center",
            backgroundColor: "transparent"
          }}
          closable={false}
          style={{ maxWidth: "calc(100vw - 16px)" }}
          footer={null}
        >
          <CloseOutlined
            onClick={() => hideImageModal()}
            className="absolute top0 right0 text-xmd white"
          />
          {files && files.length && imageModal && modalImgType === "new" ? (
            <img
              src={
                files[imageModal - 1].remote
                  ? `${IMG_URL}/${files[imageModal - 1].remote.value}`
                  : files[imageModal - 1].localShowImg
              }
              alt="avatar"
              className="modalImage p-10"
            />
          ) : null}
          {selectedKey &&
          selectedKey.length &&
          imageModal &&
          modalImgType === "old" ? (
            <img
              src={
                selectedKey[imageModal - 1].value
                  ? `${IMG_URL}/${selectedKey[imageModal - 1].value}`
                  : null
              }
              alt="avatar"
              className="modalImage p-10"
            />
          ) : null}
        </Modal>
      ) : null}
    </div>
  );
};

class WebQuestions extends React.Component {
  constructor() {
    super();
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.incrementSeconds = this.incrementSeconds.bind(this);
    this.clearTimer = this.clearTimer.bind(this);
  }
  state = {
    listMode: false,
    autoSave: false,
    imageModal: false,
    optionImageModal: false,
    optionImage: "",
    imageChange: false,
    imageIndex: 0,
    currentIndex: 0,
    submittedModal: false,
    modalImgType: "",
    seconds: 0,
    play: true,
    time: {},
    saveAssignmentLoader: false
  };
  componentDidMount() {
    this.setState({
      autoSave: true
    });
    this.carouselRef.goTo(this.props.studentQIndex, false);
  }

  componentWillUnmount() {
    const { resetDeletedAssignmentImages } = this.props;
    resetDeletedAssignmentImages();
  }

  setNowAnswer(answer, index) {
    this.props.setAnswer(answer, index);
  }

  async showSubmit() {
    const { submitAssignment, answeringLoader } = this.props;
    if (answeringLoader) return;
    await submitAssignment();
  }

  async lastQuestionPress() {
    const {
      studentCurrentAssignmentTasks,
      studentQIndex,
      answeringLoader
    } = this.props;
    if (answeringLoader) return;
    if (
      (studentCurrentAssignmentTasks[studentQIndex].selectedKey !== "" &&
        studentCurrentAssignmentTasks[studentQIndex].selectedKey !== null) ||
      (studentCurrentAssignmentTasks[studentQIndex].files &&
        studentCurrentAssignmentTasks[studentQIndex].files.length)
    ) {
      await this.SaveAndNext();
    } else {
      await this.skipAndNext();
    }
    await this.showSubmit();
  }

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  beforeUpload(file, index) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
      return false;
    } else {
      const url = URL.createObjectURL(file);
      new Compressor(file, {
        convertSize: 1000,
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          const blobFile = new File([compressedResult], compressedResult.name, {lastModified: new Date().getTime()});
          this.uploadAndSetImages(blobFile, url, index)
        },
        error: (err) => {
          message.error(err.message);
        },
      });
      return false;
    }
  }

  uploadAndSetImages(file, url, index) {
    this.props.setLocalImage(file, url, index);
  }

  defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  defaultCheckOptions = {
    loop: false,
    autoplay: true,
    animationData: animationCheck,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  startReviewTimer() {
    //clear if any existing timer is ongoing
    this.clearTimer();
    const { studentCurrentAssignment } = this.props;
    this.setState({
      time: {
        h: 0,
        m: 0,
        s: 0
      },
      seconds: studentCurrentAssignment.reviewDuration || 0
    });
    this.timer = setInterval(this.incrementSeconds, 1000);
  }

  startTimer(index) {
    const { studentCurrentAssignmentTasks, studentQIndex } = this.props;
    index =
      index >= studentCurrentAssignmentTasks.length
        ? studentCurrentAssignmentTasks.length - 1
        : index;
    //to check if index is greater than length of tasks and set its value to last
    index = index < 0 ? studentQIndex : index;
    if (studentCurrentAssignmentTasks[index].duration) {
      this.setState({
        time: {
          h: 0,
          m: 0,
          s: 0
        },
        seconds: studentCurrentAssignmentTasks[index].duration
      });
    }
    this.timer = setInterval(this.incrementSeconds, 1000);
  }

  incrementSeconds() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds + 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });
    // Check if we're at zero.
  }

  pauseTimer() {
    const { studentQIndex, updateQtime } = this.props;
    const { seconds } = this.state;
    this.setState({ play: false });
    updateQtime(studentQIndex, seconds);
    clearInterval(this.timer);
  }

  clearTimer() {
    this.setState({
      time: {
        h: 0,
        m: 0,
        s: 0
      },
      seconds: 0
    });
    clearInterval(this.timer);
  }

  async SaveAndNext() {
    const {
      setStudentAssignmentStatus,
      studentCurrentAssignment,
      postAssignmentQuestions,
      studentCurrentAssignmentTasks,
      studentQIndex
    } = this.props;
    this.clearTimer();
    let status = "Attempted";
    if (studentCurrentAssignmentTasks[studentQIndex].markedForReview) {
      status = "AttemptedAndBookmarked";
    }
    await postAssignmentQuestions(
      studentQIndex,
      this.carouselRef,
      this.state.seconds,
      status
    );
    if (studentCurrentAssignment.assignmentStatus === "New") {
      setStudentAssignmentStatus("Draft");
    }
    // if last question, don't start timer again
    if (studentQIndex == studentCurrentAssignment.totalQuestions - 1) return;
    this.startTimer(studentQIndex + 1);
  }

  async skipAndNext() {
    const {
      postAssignmentQuestions,
      studentQIndex,
      studentCurrentAssignment,
      studentCurrentAssignmentTasks
    } = this.props;
    this.clearTimer();
    let status = "Unanswered";
    if (studentCurrentAssignmentTasks[studentQIndex].markedForReview) {
      status = "Bookmarked";
    }
    await postAssignmentQuestions(
      studentQIndex,
      this.carouselRef,
      this.state.seconds,
      status
    );
    // if last question, don't start timer again
    if (studentQIndex == studentCurrentAssignment.totalQuestions - 1) return;
    this.startTimer(studentQIndex + 1);
  }

  async recheckForProgress() {
    const {
      setStudentAssignnent,
      studentCurrentAssignment,
      studentAssignmentList,
      studentNewAssignmentList,
      history,
      getStudentAssignment,
      studentQIndex,
      updateReviewTime,
      postAssignmentQuestions,
      studentCurrentAssignmentTasks,
      updateQtime
    } = this.props;
    if (
      studentCurrentAssignment.assignmentStatus == "Graded" ||
      studentCurrentAssignment.assignmentStatus == "Submitted"
    ) {
      return history.push("/web-assignment");
    }
    this.setState({ saveAssignmentLoader: true });
    const { listMode, seconds } = this.state;
    if (listMode) {
      updateReviewTime(seconds);
    } else {
      updateQtime(studentQIndex, seconds);
    }
    let status = "Attempted";
    if (
      (studentCurrentAssignmentTasks[studentQIndex].selectedKey !== "" &&
        studentCurrentAssignmentTasks[studentQIndex].selectedKey !== null) ||
      (studentCurrentAssignmentTasks[studentQIndex].files &&
        studentCurrentAssignmentTasks[studentQIndex].files.length)
    ) {
      if (studentCurrentAssignmentTasks[studentQIndex].markedForReview) {
        status = "AttemptedAndBookmarked";
      } else {
        status = "Attempted";
      }
      await postAssignmentQuestions(
        studentQIndex,
        null,
        studentCurrentAssignmentTasks[studentQIndex].duration,
        status
      );
    } else {
      if (studentCurrentAssignmentTasks[studentQIndex].markedForReview) {
        status = "Bookmarked";
      } else {
        status = "Unanswered";
      }
      await postAssignmentQuestions(
        studentQIndex,
        null,
        studentCurrentAssignmentTasks[studentQIndex].duration,
        status
      );
    }

    this.clearTimer();
    history.push("/web-assignment");
    // await getStudentAssignment(() => {});
    // const { id } = studentCurrentAssignment;
    // const length = studentAssignmentList.length;
    // for (let i = 0; i < length; i++) {
    //   if (id === studentAssignmentList[i].id) {
    //     setStudentAssignnent(studentAssignmentList[i]);
    //     this.setState({ saveAssignmentLoader: false });
    //     history.push(`/web-assignment/${id}`);
    //     break;
    //   }
    // }
    // const newLength = studentNewAssignmentList.length;
    // for (let i = 0; i < newLength; i++) {
    //   if (id === studentNewAssignmentList[i].id) {
    //     setStudentAssignnent(studentNewAssignmentList[i]);
    //     this.setState({ saveAssignmentLoader: false });
    //     history.push(`/web-assignment/${id}`);
    //     break;
    //   }
    // }
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  }

  async toggleListMode(value, index = undefined) {
    await this.setState({ listMode: value });
    // run below code once listMode is updated; else carousel ref is not found
    const {
      updateReviewTime,
      updateQtime,
      studentQIndex,
      studentCurrentAssignmentTasks
    } = this.props;
    const { seconds } = this.state;
    if (value) {
      // update duration of current task
      // TODO: fix this (you dont know for which index seconds is holding time)
      updateQtime(
        studentQIndex,
        seconds
          ? seconds
          : studentCurrentAssignmentTasks[studentQIndex].duration
      );
      this.startReviewTimer();
    } else {
      updateReviewTime(seconds);
      this.clearTimer();
      // go to the current task
      const indexCondtion = typeof index != "undefined";
      this.carouselRef.goTo(indexCondtion ? index : studentQIndex, false);
      // start the timer of the current task
      this.startTimer(indexCondtion ? index : studentQIndex);
    }
  }

  handleSubmitFromListMode() {
    const { updateReviewTime } = this.props;
    const { seconds } = this.state;
    updateReviewTime(seconds);
    this.clearTimer();
    this.showSubmit();
  }

  computeBookmarkCount() {
    const { studentCurrentAssignmentTasks } = this.props;
    let bookMarkCount = 0;
    studentCurrentAssignmentTasks.forEach(item => {
      if (item.markedForReview) {
        bookMarkCount += 1;
      }
    });
    return bookMarkCount || 0;
  }

  handleReviewModalClose() {
    const { listMode } = this.state;
    const { submitModalToggle, studentQIndex } = this.props;
    if (listMode) {
      this.startReviewTimer();
    } else {
      this.clearTimer();
      this.startTimer(studentQIndex);
    }
    submitModalToggle(false);
  }

  renderTaskType() {
    const { studentCurrentAssignmentTasks, studentQIndex } = this.props;
    if (studentCurrentAssignmentTasks[studentQIndex].type === "MCQ") {
      return "Multiple Choice Question";
    } else if (studentCurrentAssignmentTasks[studentQIndex].type === "Image") {
      return "Subjective";
    } else if (
      studentCurrentAssignmentTasks[studentQIndex].type === "Subjective"
    ) {
      return "Numeric";
    }
    return studentCurrentAssignmentTasks[studentQIndex].type;
  }

  render() {
    const {
      history,
      user,
      studentCurrentAssignment,
      setStudentQuestionIndex,
      studentCurrentAssignmentTasks,
      setStudentAssignmentStatus,
      deleteAnswerImage,
      bookmarkQuestion,
      submitModal,
      submitModalToggle,
      studentCurrentSubmitData,
      setFinishMode,
      answeringLoader,
      updateQtime,
      assignmentLoader,
      studentQIndex
    } = this.props;
    const totalQuestions = studentCurrentAssignmentTasks.length;
    const {
      time,
      seconds,
      play,
      listMode,
      autoSave,
      imageModal,
      optionImageModal,
      optionImage,
      imageChange,
      imageIndex,
      modalImgType,
      submittedModal,
      saveAssignmentLoader
    } = this.state;
    return (
      <div className="greyBackTest" style={{ minHeight: "100%" }}>
        <AssignmentTopBar
          backPress={() => {
            this.recheckForProgress();
          }}
          title={studentCurrentAssignment.sub}
          isStarted
          changeMode={async () => await this.toggleListMode(!listMode)}
          listMode={listMode}
        />
        <div className="m-l-10 m-r-10 m-t-10 r-c-sb">
          {listMode ? (
            <div>All Questions</div>
          ) : (
            <div>{this.renderTaskType()}</div>
          )}
          <div className="higrey r-c-c">
            {!play ? (
              <BsPlayFill
                className="text-sm"
                onClick={() => {
                  this.startTimer();
                  this.setState({ play: true });
                }}
              />
            ) : (
              <BsFillPauseFill
                className="text-sm"
                onClick={() => this.pauseTimer()}
              />
            )}
            {time.m < 10 ? "0" + time.m : time.m} :{" "}
            {time.s < 10 ? "0" + time.s : time.s}
          </div>
        </div>
        {listMode && studentCurrentAssignmentTasks.length ? (
          studentCurrentAssignmentTasks.map((data, index) => (
            <QBox
              files={data.files}
              showImageModal={(index, type) =>
                this.setState({ imageModal: index, modalImgType: type })
              }
              showOptionImageModal={url =>
                this.setState({
                  optionImageModal: true,
                  optionImage: url
                })
              }
              modalImgType={modalImgType}
              smallBox={true}
              totalQuestions={totalQuestions}
              gotoQues={async () => {
                await this.toggleListMode(false, index);
                // this.carouselRef.goTo(index, false);
                updateQtime(this.props.studentQIndex, seconds);
                // this.clearTimer();
                setStudentQuestionIndex(index);
                // this.startTimer(index);
              }}
              beforeUpload={file => this.beforeUpload(file)}
              statement={data.statement}
              options={data.type === "MCQ" ? data.options : null}
              type={data.type}
              selectedKey={data.selectedKey}
              positiveMarks={data.positiveMarks}
              qNo={index + 1}
              bookmark={data.markedForReview}
              bookmarkQuestion={() => bookmarkQuestion(index)}
              orgId={user.orgId}
            />
          ))
        ) : (
          <Carousel
            afterChange={e => setStudentQuestionIndex(e)}
            ref={ref => (this.carouselRef = ref)}
            className="greyBackTest p-10"
            dots={false}
            swipe={false}
          >
            {studentCurrentAssignmentTasks.length
              ? studentCurrentAssignmentTasks.map((data, index) => (
                  <QBox
                    delFCount={data.delFCount}
                    showImageModal={(index, type) =>
                      this.setState({ imageModal: index, modalImgType: type })
                    }
                    modalImgType={modalImgType}
                    hideImageModal={() => this.setState({ imageModal: false })}
                    showChangeImage={(index, name) =>
                      this.setState({
                        imageChange: name,
                        imageIndex: index
                      })
                    }
                    showOptionImageModal={url =>
                      this.setState({
                        optionImageModal: true,
                        optionImage: url
                      })
                    }
                    files={data.files}
                    statement={data.statement}
                    options={data.type === "MCQ" ? data.options : []}
                    type={data.type}
                    selectedKey={data.selectedKey}
                    beforeUpload={file => this.beforeUpload(file, index)}
                    setQAnswer={answer => this.setNowAnswer(answer, index)}
                    positiveMarks={data.positiveMarks}
                    imageModal={imageModal}
                    qNo={index + 1}
                    orgId={user.orgId}
                    currentIndex={studentQIndex}
                    totalQuestions={totalQuestions}
                    bookmark={data.markedForReview}
                    bookmarkQuestion={() => bookmarkQuestion(index)}
                  />
                ))
              : null}
          </Carousel>
        )}
        {listMode ? (
          <div className="greyBackTest endBox">
            <MobileButton
              onPress={() => this.handleSubmitFromListMode()}
              text="SUBMIT"
              icon={
                assignmentLoader ? (
                  <LoadingOutlined style={{ color: "white" }} />
                ) : (
                  <i className="icon-ic-arrow-forward white" />
                )
              }
            />
          </div>
        ) : (
          <div
            style={{
              position: imageChange ? "relative" : "sticky"
            }}
            className="greyBackTest r-c-sb nextBox"
          >
            {studentQIndex > 0 ? (
              <MobileButton
                before
                white
                fillBox
                onPress={() => {
                  this.clearTimer();
                  updateQtime(studentQIndex, seconds);
                  this.startTimer(this.props.studentQIndex - 1);
                  this.carouselRef.prev();
                }}
                text="PREVIOUS"
                icon={<i className="icon-ic-chevron-left color-purple" />}
              />
            ) : null}
            <MobileButton
              fillBox
              white={
                !(
                  (studentCurrentAssignmentTasks[studentQIndex].selectedKey !==
                    "" &&
                    studentCurrentAssignmentTasks[studentQIndex].selectedKey !==
                      null) ||
                  (studentCurrentAssignmentTasks[studentQIndex].files &&
                    studentCurrentAssignmentTasks[studentQIndex].files.length)
                )
              }
              onPress={() => {
                if (answeringLoader) return;
                if (studentQIndex == studentCurrentAssignmentTasks.length - 1) {
                  return this.lastQuestionPress();
                }
                if (
                  (studentCurrentAssignmentTasks[studentQIndex].selectedKey !==
                    "" &&
                    studentCurrentAssignmentTasks[studentQIndex].selectedKey !==
                      null) ||
                  (studentCurrentAssignmentTasks[studentQIndex].files &&
                    studentCurrentAssignmentTasks[studentQIndex].files.length)
                ) {
                  this.SaveAndNext();
                } else {
                  this.skipAndNext();
                }
              }}
              text={
                (studentCurrentAssignmentTasks[studentQIndex].selectedKey !==
                  "" &&
                  studentCurrentAssignmentTasks[studentQIndex].selectedKey !==
                    null) ||
                (studentCurrentAssignmentTasks[studentQIndex].files &&
                  studentCurrentAssignmentTasks[studentQIndex].files.length)
                  ? "NEXT"
                  : "SKIP"
              }
              icon={
                answeringLoader ? (
                  <LoadingOutlined
                    style={{
                      color:
                        studentCurrentAssignmentTasks[studentQIndex]
                          .selectedKey == "" ||
                        studentCurrentAssignmentTasks[studentQIndex]
                          .selectedKey == null ||
                        (studentCurrentAssignmentTasks[studentQIndex].type ==
                          "Image" &&
                          !studentCurrentAssignmentTasks[studentQIndex].files)
                          ? "#5448B7"
                          : "white"
                    }}
                  />
                ) : !(
                    (studentCurrentAssignmentTasks[studentQIndex]
                      .selectedKey !== "" &&
                      studentCurrentAssignmentTasks[studentQIndex]
                        .selectedKey !== null) ||
                    (studentCurrentAssignmentTasks[studentQIndex].files &&
                      studentCurrentAssignmentTasks[studentQIndex].files.length)
                  ) ? (
                  <i className="icon-ic-arrow-forward color-purple" />
                ) : (
                  <i className="icon-ic-arrow-forward white" />
                )
              }
            />
          </div>
        )}
        <Modal
          className="autoSaveModal NutinFont"
          centered
          visible={autoSave}
          bodyStyle={{
            padding: 0,
            borderRadius: 15,
            textAlign: "center"
          }}
          closable={false}
          width={1000}
          style={{ maxWidth: "calc(100vw - 16px)" }}
          footer={null}
        >
          <div className="color-black text-sm p-b-10 bold-600">Auto save</div>
          <div className="higrey text-xmd">
            Your assignment will be automatically saved! You can easily pick up
            where you left off with ease.
          </div>
          <div
            onClick={() => {
              let timeLeftVar = this.secondsToTime(this.state.seconds);
              this.setState({ time: timeLeftVar });
              this.startTimer(this.props.studentQIndex);
              this.setState({
                autoSave: false
              });
            }}
            style={{
              padding: "8px 5px"
            }}
            className="NutinFont cursor-pointer m-t-10 full-width p-5 backPurp white text-md radius-100"
          >
            GOT IT!
          </div>
        </Modal>
        <HyperModal
          isOpen={imageChange}
          unmountOnClose
          requestClose={() =>
            this.setState({
              imageChange: false
            })
          }
          position={{
            alignItems: "flex-end",
            justifyContent: "center"
          }}
          renderCloseIcon={() => null}
          closeIconPosition={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <div className="full-width r-c-c p-t-10">
            <div className="topNotch inline-flex" />
          </div>
          <div
            className="hypermodalbtn"
            onClick={() => {
              deleteAnswerImage(studentQIndex, imageIndex, imageChange);

              this.setState({ imageChange: false });
            }}
          >
            Delete
          </div>
        </HyperModal>
        <Modal
          className="autoSaveModal NutinFont"
          centered
          visible={submitModal}
          bodyStyle={{
            padding: 0,
            borderRadius: 15,
            textAlign: "center"
          }}
          closable={false}
          width={1000}
          style={{ maxWidth: "calc(100vw - 16px)" }}
          footer={null}
        >
          <div className="r-c-fe" onClick={() => this.handleReviewModalClose()}>
            <i className="icon-ic-close text-lg" />
          </div>
          <div className="whiteBack p-l-10 p-r-10 p-b-10 radius-10 NutinFont">
            <Lottie options={this.defaultOptions} height={100} width={100} />
            <div className="p-5 color-black bold-700 text-md">
              Review submission
            </div>
            <div className="p-5 m-b-5 color-black bold-600 simgrey text-sm">
              Would you like to review before submission?
            </div>
            <div className="radius-10 greyContainbordered text-left text-xmd">
              <div className="p-5 m-l-40 color-black bold-600">Summary</div>
              <Row className="p-5 higrey bold-600">
                <Col xs={2} />
                <Col xs={14}>
                  Total number of questions <br />
                </Col>
                <Col xs={1}>
                  : <br />
                </Col>
                <Col xs={7}>
                  {studentCurrentSubmitData.totalQuestions} <br />
                </Col>
              </Row>
              <Row className="p-5 higrey bold-600">
                <Col xs={2} />
                <Col xs={14}>
                  Answered questions <br />
                </Col>
                <Col xs={1}>
                  : <br />
                </Col>
                <Col xs={7}>
                  {studentCurrentSubmitData.attempted} <br />
                </Col>
              </Row>
              <Row className="p-5 higrey bold-600">
                <Col xs={2} />
                <Col xs={14}>
                  Unanswered questions <br />
                </Col>
                <Col xs={1}>
                  : <br />
                </Col>
                <Col xs={7}>
                  {studentCurrentSubmitData.totalQuestions -
                    studentCurrentSubmitData.attempted}{" "}
                  <br />
                </Col>
              </Row>
              <Row className="p-5 m-b-10 higrey bold-600">
                <Col xs={2} />
                <Col xs={14}>
                  Bookmarked <br />
                </Col>
                <Col xs={1}>
                  : <br />
                </Col>
                <Col xs={7}>
                  {this.computeBookmarkCount()}
                  <br />
                </Col>
              </Row>
            </div>
            <Row className="m-t-10" justify="space-between">
              <Col xs={12}>
                <MobileButton
                  white
                  grey
                  onPress={() => {
                    setStudentAssignmentStatus("Submitted");
                    submitModalToggle(false);
                    this.setState({
                      submittedModal: true
                    });
                    setTimeout(function() {
                      history.push("/web-assignment");
                      setFinishMode(true);
                    }, 5000);
                  }}
                  text="SUBMIT"
                />
              </Col>
              <Col xs={12}>
                <MobileButton
                  onPress={async () => {
                    // if already in listMode just start review timer
                    if (listMode) {
                      this.startReviewTimer();
                    } else {
                      await this.toggleListMode(true);
                    }
                    submitModalToggle(false);
                  }}
                  text="REVIEW"
                  icon={<i className="icon-ic-arrow-forward white" />}
                />
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          className="autoSaveModal NutinFont"
          centered
          visible={submittedModal}
          bodyStyle={{
            padding: 0,
            borderRadius: 15,
            textAlign: "center"
          }}
          closable={false}
          width={1000}
          style={{ maxWidth: "calc(100vw - 16px)" }}
          footer={null}
        >
          <div
            className="r-c-fe"
            onClick={() => this.setState({ submittedModal: false })}
          >
            <i className="icon-ic-close text-lg" />
          </div>
          <div className="whiteBack p-b-10 p-l-10 p-r-10 radius-10 NutinFont">
            <Lottie
              options={this.defaultCheckOptions}
              height={100}
              width={100}
            />
            <div className="p-5 color-black bold-700 text-md">
              Successfully submitted
            </div>
            <div className="p-5 m-b-5 color-black bold-600 simgrey text-sm">
              Your assignment has been successfully submitted.
            </div>
            <div className="radius-10 greyContainbordered text-left text-xmd p-10">
              <div className="color-black bold-700 text-sm">
                Your grade will be published on
              </div>
              <Row className="m-t-10" align="space-between">
                {/*<Col>
                  <i className='icon-ic-time' /> 9:00 A.M. - 10:00 A.M.
                </Col>*/}
                <Col>
                  <i className="icon-ic-date" />{" "}
                  {moment(studentCurrentSubmitData.resultDate).format(
                    "DD MMM YYYY"
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
        <Modal
          className="autoSaveModal NutinFont"
          centered
          visible={optionImageModal}
          bodyStyle={{
            padding: 0,
            borderRadius: 15,
            textAlign: "center"
          }}
          closable={false}
          footer={null}
        >
          <div
            className="r-c-fe"
            onClick={() => this.setState({ optionImageModal: false })}
          >
            <i className="icon-ic-close text-lg" />
          </div>
          <img src={optionImage} style={{ maxWidth: "100%" }} alt="" />
        </Modal>
        <Modal
          className="autoSaveModal NutinFont"
          centered
          visible={!play}
          bodyStyle={{
            padding: 0,
            borderRadius: 15,
            textAlign: "center"
          }}
          closable={false}
          width={1000}
          style={{ maxWidth: "calc(100vw - 16px)" }}
          footer={null}
        >
          <div className="color-black text-sm p-b-10 bold-600">Auto save</div>
          <div className="higrey text-xmd">Your assignment is paused.</div>
          <Button
            size="large"
            shape="round"
            onClick={() => {
              this.startTimer(this.props.studentQIndex);
              this.setState({ play: true });
            }}
            style={{
              border: "none",
              background:
                "transparent linear-gradient(266deg, #69E9B8 0%, #28DF99 100%) 0% 0% no-repeat padding-box"
            }}
            className="NutinFont m-t-10 full-width p-5"
          >
            RESUME
          </Button>
          <Button
            size="large"
            shape="round"
            onClick={() => {
              this.recheckForProgress();
            }}
            style={{
              border: "none",
              backgroundColor: Color.dodgeBlue
            }}
            className="NutinFont m-t-10 full-width p-5"
          >
            GO BACK
          </Button>
        </Modal>
        <Modal
          visible={saveAssignmentLoader}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img src={require('Assets/dots-gif.gif').default} style={{maxWidth: "100%"}} width={150} alt=""/>

            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} />
            <div className="text-xmd m-t-10">Saving assignment ...</div> */}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    assignmentLoader,
    answeringLoader,
    studentQIndex,
    studentAssignmentList,
    studentNewAssignmentList,
    studentCurrentAssignment,
    studentCurrentAssignmentTasks,
    submitModal,
    studentCurrentSubmitData
  } = state.assignment;
  const { user } = state.session;
  return {
    user,
    assignmentLoader,
    answeringLoader,
    studentCurrentAssignment,
    studentNewAssignmentList,
    studentAssignmentList,
    submitModal,
    studentQIndex,
    studentCurrentAssignmentTasks,
    studentCurrentSubmitData
  };
};

const mapDispatchToProps = dispatch => ({
  updateReviewTime: seconds => dispatch(updateReviewTime(seconds)),
  resetDeletedAssignmentImages: () => dispatch(resetDeletedAssignmentImages()),
  getStudentAssignment: (callback) => dispatch(getStudentAssignment(callback)),
  setAnswer: (answer, index) => dispatch(setAnswer(answer, index)),
  setStudentAssignnent: value => dispatch(setStudentAssignnent(value)),
  updateQtime: (index, seconds) => dispatch(updateQtime(index, seconds)),
  setStudentAssignmentStatus: status =>
    dispatch(setStudentAssignmentStatus(status)),
  submitAssignment: () => dispatch(submitAssignment()),
  setStudentQuestionIndex: value => dispatch(setStudentQuestionIndex(value)),
  submitModalToggle: value => dispatch(submitModalToggle(value)),
  setFinishMode: value => dispatch(setFinishMode(value)),
  bookmarkQuestion: index => dispatch(bookmarkQuestion(index)),
  setLocalImage: (file, url, index) =>
    dispatch(setLocalImage(file, url, index)),
  postAssignmentQuestions: (index, carouselRef, seconds, status) =>
    dispatch(postAssignmentQuestions(index, carouselRef, seconds, status)),
  deleteAnswerImage: (index, imageIndex, kind) =>
    dispatch(deleteAnswerImage(index, imageIndex, kind)),
  replaceAnswerImage: (index, imageIndex) =>
    dispatch(replaceAnswerImage(index, imageIndex))
});

export default connect(mapStateToProps, mapDispatchToProps)(WebQuestions);
