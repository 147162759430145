
import Modal from 'antd/lib/modal'
import React, { useState, useRef, useEffect } from 'react'
import ReactQuill from "react-quill";
import Button from 'antd/lib/button';
import { Api } from '../../services';
import "react-quill/dist/quill.snow.css";

const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "formula",
    "code-block",
    "small",
];

const modules = {
    toolbar: {
        container: [
            [{ 'size': ['small', false, 'large'] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'color': [] }, { 'background': [] }],
            ['image', 'formula', 'code-block']
        ],
    },
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true
    }
};


//todo add logic to delete files from aws
function TextEditorModal({
    visible, value, onCancel, onSave, currentQType, fromTest
}) {

    const [editorData, setData] = useState()

    useEffect(() => {

        //when opening modal
        if(visible == true)
            setData(value)
    }, [ visible ])

    const quillReff = useRef(null)

    //https://github.com/zenoamaro/react-quill/issues/743#issuecomment-1445623002
    useEffect(() => {
        quillReff.current
            ?.getEditor()
            .getModule('toolbar')
            .addHandler('image', () => {
                const input = document.createElement('input')
                input.setAttribute('type', 'file')
                input.setAttribute('accept', 'image/*')
                input.click()
                input.onchange = async () => {
                    const file = input.files[0];

                    const formData = new FormData()
                    if (file !== null) {
                        formData.append('file', file)
                    }
                    console.log("Api req", '/test/upload-image', { upload: file });

                    await Api.post('/test/upload-image').upload({ upload: file }, (percent, response) => {
                        console.log("Api res", response);
                        if (response) {
                            let cursorPosition = quillReff.current.getEditor().getSelection(true);
                            let imagePath = response.data.url;
                            // uploadedImageName = response.data.fileName;
                            quillReff.current.getEditor().insertEmbed(cursorPosition.index, 'image', imagePath)
                            quillReff.current.getEditor().setSelection(cursorPosition.index + 1, 0)
                        }
                    })
                }
            })
    }, [quillReff])


    return (
        <Modal
            centered={true}
            open={visible}
            closable={false}
            width={700}
            className='modal-round-corner'
            footer={false}
        >
            <div className="test-texteditor-wrapper">
                <div className='text-sm bold-600 color-black m-b-20'>Text editor</div>

                <div className="text-editor">
                    <ReactQuill
                        ref={quillReff}
                        theme="snow"
                        value={editorData}
                        onChange={(val) => { setData(val) }}
                        placeholder={"Type here..."}
                        modules={modules}
                        formats={formats}
                        style={{ height: '30vh' }}
                        className='admin-test-quill'
                    />

                    <div className="m-t-20 r-c-c" style={{ marginTop: '11%' }}>
                        <Button style={{ borderRadius: 10, marginRight: 10 }}
                            onClick={() => onCancel()}>Cancel</Button>
                        <Button style={{ borderRadius: 10 }} type="primary"
                        disabled = {fromTest && !currentQType}
                            onClick={() => onSave(editorData)}>Save & Close
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default TextEditorModal