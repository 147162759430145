import PageHeader from 'components/pageHeader'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { AutoComplete, Checkbox, DatePicker, Dropdown, Pagination, Popover, Select, Space, Spin, Tabs, Typography, Menu, Tooltip } from 'antd';
import { CalendarFilled, CalendarOutlined, CheckOutlined, CloudDownloadOutlined, DownOutlined, EditOutlined, LoadingOutlined, PlusCircleOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { deleteCrmtable, getCRMAutocompleteSearch, getCoursesInCartData, getEnquiryFormsData, getWhistledCoursesData, postCrmDownloadReport, setCRMPageParamsObj, setCourseTypeName, setTab, storeAutocompleteSearchData, storeCRMTableData, storeCrmCourseListData, storeSingleCourseId } from 'actions/courseManagement';
import PageDataLoader from 'components/pageDataLoader';
import { connect } from 'react-redux';
import moment from 'moment';
import './styles.css'
import { storeEndDate, storeSelectedRange, storeStartDate } from 'actions/attendanceNew';
import { showMenu } from 'actions/layout';
import { setSpecificUserId, setUserDetails } from 'actions/userManagementV2';
import { setOthersSelectedTab } from 'actions/profileNew';
import UserAvatar from 'components/userAvatar';
import { getAllClasses } from 'actions/classRoom'
import { storeStudentRollNo } from 'actions/userManagementV2'
import CustomTable from 'components/customTable';
import liveCourseIcon from "../../Assets/courseManagementV4/liveCourseIcon.svg";
import videoResourceIcon from "../../Assets/courseManagementV4/videoResourceIcon.svg";
import studyMaterialIcon from "../../Assets/courseManagementV4/studyMaterialIcon.svg";
import testSeriesIcon from "../../Assets/courseManagementV4/testSeriesIcon.svg";
import { Button } from 'components/Button';
import AddCourseClassModal from 'pages/MarketplaceCRM/addCourseClassModal';
import AllCoursesListModal from 'pages/adminMarketPlaceCourse/allCoursesListModal';
import SearchFilterComp from 'pages/adminMarketPlaceCourse/components/searchFilterComp';
import CrmDeleteModal from 'pages/MarketplaceCRM/crmDeleteModal';
import { bodyStyle } from 'pages/adminMarketPlaceCourse';
import MarketPlaceCrmDownloadReport from './marketPlaceCrmDownloadModal';
import MarketPlaceCrmFilterModal from './marketPlaceCrmFilterModal';
import StatusChangeModal from './statusChangeModal';

const { Option } = Select;
const { RangePicker } = DatePicker;

function MarketPlaceCRM({ getEnquiryFormsData, crmTableData, setCRMPageParamsObj, crmParamsObj, getCoursesInCartData, getWhistledCoursesData, storeCRMTableData, crmCourseListData, storeSelectedRange, storeStartDate, storeEndDate, startDateContainer, selectedRangeContainer, endDateContainer, postCrmDownloadReport, storeSingleCourseId, setCourseTypeName, getCRMAutocompleteSearch, autocompleteSearchData, storeAutocompleteSearchData, showMenu, setUserDetails, setSpecificUserId, setOthersSelectedTab, setTab, tab, storeCrmCourseListData, user }) {
    const [showCustomCal, setShowCustomCal] = useState(true);
    const [isCalenderClicked, setCalenderClicked] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState(null);
    // const [tab, setTab] = useState(1);
    const [isModelVisible, setisModelVisible] = useState(false);
    const [isFilterModal, setIsFilterModal] = useState(false);
    const [isAllCoursesListModalVisible, setAllCoursesListModalVisible] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);
    
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [expandedItemId, setExpandedItemId] = useState(null);
    const [statusModalVisible, setStatusIsModalVisible] = useState(false)
    const [studentId, setStudentId] = useState(null)
    const [assignserDetails, setAssignUserDetails] = useState({ userId: null, phone: null, name: null, email: null })
    const [isAssignBatchModalVisible, setIsAssignBatchModalVisible] = useState(false);
    const [parmanentDeleteModal, setPermanentDeletModal] = useState(false);
    const [type, setType] = useState('Enquiry');
    const [crmStatus, setCrmStatus] = useState('');
    const [search, setSearch] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isApply, setIsApply] = useState(false);
    const [filterParam, setFilterParam] = useState({
        status: 'All',
        sort: 'newest',
        type: ['All']
    });
    const [storeFilter,setStoreFilter] =useState([])
    const [tempFilterParam, setTempFilterParam] = useState({ ...filterParam });
    const [filterParamConversion, setFilterParamConversion] = useState({
        type: ['All']
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRowKeysForAssignCourse, setSelectedRowKeysForAssignCourse] = useState([]);
    const [selectedDeleteButton, setSelectedDeleteButton] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [applyButton, setApplyButton] = useState(false);
    const [isValue,setIsValue] = useState('')

    const hasMarketingPrivilege = user?.privilage?.includes('Marketing') && !user?.privilage?.includes('Admin');


    const history = useHistory()
    const location = useLocation()

    const onPermanentDeleteModalCancel = () => {
        setPermanentDeletModal(false)
    };

    const clearValues = () => {
        setCourseTypeName(null);
        setSelectedFilter(null);
        setCRMPageParamsObj("type", null);
        setCRMPageParamsObj("search", '');
        setCRMPageParamsObj("page", 1);
        setCRMPageParamsObj("limit", 10);
        setCRMPageParamsObj("filter", null);
        setCRMPageParamsObj("startDate", null);
        setCRMPageParamsObj("endDate", null);
        setCRMPageParamsObj("newFirst", true);
        setCRMPageParamsObj("filterByCourseType", ['All']);
        setCRMPageParamsObj("status", 'All');
        setSearch(null)
        // setAutoSearch(null);
        storeCRMTableData([]);
        storeAutocompleteSearchData([])
        getCRMAutocompleteSearch('', tab, () => { });
        setExpandedItemId(null);
        setPage(1)
        setCRMPageParamsObj('filterByCourseName' , undefined)
    }
    useEffect(() => {
        clearValues();
    }, [tab])


    useEffect(() => {
        showMenu(true)
        storeAutocompleteSearchData([])
        getCRMAutocompleteSearch('', tab, () => { })
    }, [])

    

    useEffect(() => {
        setPage(1)
        storeCRMTableData([])
        togglePageLoader(true)

        switch (tab) {
            
            case '1':
                getEnquiryFormsData('Enquiry', 1, page === 1, () => {
                    setIsLoading(false);
                    togglePageLoader(false);
                    setIsApply(false);
                });
                break;
            case '2':
                getEnquiryFormsData('Registration', 1, page === 1, () => {
                    setIsLoading(false);
                    togglePageLoader(false);
                    setIsApply(false);
                });
                break;
            case '3':
                getCoursesInCartData(1, page === 1, () => {
                    setIsLoading(false);
                    togglePageLoader(false);
                    setIsApply(false);
                });
                break;
            default:
                getWhistledCoursesData(1, page === 1, () => {
                    setIsLoading(false);
                    togglePageLoader(false);
                    setIsApply(false);
                });
                break;
        }
        
    }, [ crmParamsObj?.search, crmParamsObj?.limit, isApply])


    useEffect(() => {
        setSelectedRowKeys([]);
        setSelectedRowKeysForAssignCourse([]);
        setIsLoading(true);
        if (tab == 1) {
            getEnquiryFormsData('Enquiry', page, (page == 1 ? true : false), () => { setIsLoading(false); togglePageLoader(false), setIsApply(false) })
        } else if (tab == 2) {
            getEnquiryFormsData('Registration', page, (page == 1 ? true : false), () => { setIsLoading(false); togglePageLoader(false), setIsApply(false) })
        } else if (tab == 3) {
            getCoursesInCartData(page, (page == 1 ? true : false), () => { setIsLoading(false); togglePageLoader(false), setIsApply(false) })
        } else {
            getWhistledCoursesData(page, (page == 1 ? true : false), () => { setIsLoading(false); togglePageLoader(false), setIsApply(false) })
        }
    }, [page])

    useEffect(()=>{
        if(!dropdownVisible){
            setTempFilterParam({ ...filterParam });
            setDropdownVisible(false);
        }
        
    },[dropdownVisible])

    const handleSearch = (value) => {
        setSearch(value)
        setCRMPageParamsObj("search", value);
        setPage(1)
    }

    const handleChange = (date, dateString) => {
        setPage(1);
        setStartDate(date ? date : null);
        setCRMPageParamsObj("startDate", date ? date.format('DD/MM/YYYY') : 'None');
        setCRMPageParamsObj("endDate", date ? date.format('DD/MM/YYYY') : 'None');
        togglePageLoader(true);
        if (tab == 1) {
            getEnquiryFormsData('Enquiry', page, (page == 1 ? true : false), () => { setIsLoading(false); togglePageLoader(false), setIsApply(false) })
        } else if (tab == 2) {
            getEnquiryFormsData('Registration', page, (page == 1 ? true : false), () => { setIsLoading(false); togglePageLoader(false), setIsApply(false) })
        } else if (tab == 3) {
            getCoursesInCartData(1, true, () => { togglePageLoader(false) })
        } else if (tab == 4) {
            getWhistledCoursesData(1, true, () => { togglePageLoader(false) })
        }

    };

    

    

    const handleDropdownVisibleChange = (visible) => {
        setDropdownVisible(true);
    };

    const Types1 = tab == '3' ? 
    [{ label: "All", value: 'All' }, { label: "Video course", value: "videoResource" }, { label: "Test series", value: "testSeries" }, { label: "Study materials", value: "studyMaterial" }]
    :  
    [{ label: "All", value: 'All' }, { label: "Live course", value: "course" }, { label: "Video course", value: "videoResource" }, { label: "Test series", value: "testSeries" }, { label: "Study materials", value: "studyMaterial" }]

    const Types = [{ label: "All", value: 'All' }, { label: "New", value: "New" }, { label: "Interested", value: "Interested" }, { label: "Not Interested", value: "Not Interested" }, { label: "Contact Initiated", value: "Contact Initiated" }, { label: "Follow Up Requested", value: "Follow Up Requested" }]
    const sortBy = [{ label: "Newest", value: 'newest' }, { label: "Oldest", value: "oldest" }]

    const SortByFilter = () => {
        const handleClassToggle = (val) => {

            const newSortValue = tempFilterParam.sort === val ? 'newest' : val;
        
        setTempFilterParam({
            ...tempFilterParam,
            sort: newSortValue
        });

        setCRMPageParamsObj("newFirst", newSortValue === "newest");
        };

        return (
            <div className='r-c-c-fs'>
                <FilterTitleName filterName="Sort by" />
                <div className='r-c-fs' style={{}}>
                    {sortBy?.map((item) => (
                        <div
                            key={item.value}
                            className='sortByFilter'
                            style={{
                                backgroundColor: tempFilterParam?.sort?.includes(item?.value) ? "#D6C8F5" : '#FFFFFF',
                                color: tempFilterParam?.sort?.includes(item?.value) ? '#191919' : "#636363",
                                border: tempFilterParam?.sort?.includes(item?.value) ? 'none' : '1px solid #E6E6E6',
                                fontWeight: tempFilterParam?.sort?.includes(item?.value) ? "650" : "normal",
                                width: 100,
                                height: 32

                            }}
                            onClick={(e) => {handleClassToggle(item?.value); e.stopPropagation()}}
                        >
                            <div style={{ marginTop: -5, display: "flex", justifyContent: "center" }}> {item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const TypeFilter = () => {
        const handleTypeToggle = (val) => {
            const updatedFilterParam = { ...tempFilterParam };
            if (val === 'All') {
                updatedFilterParam.status = 'All';
            } else {
                if (updatedFilterParam.status === 'All') {
                    updatedFilterParam.status = val;
                } else if (updatedFilterParam.status === val) {
                    updatedFilterParam.status = 'All';
                } else {
                    updatedFilterParam.status = val;
                }
            }
            setTempFilterParam(updatedFilterParam);
        };
        return (
            <div className=''>
                <FilterTitleName filterName="Status" />
                <div style={{ display: "flex", flexWrap: "wrap", }}>
                    {Types?.map((item) => (
                        <div
                            key={item.value}
                            className='sortByFilter'
                            style={{
                                backgroundColor: tempFilterParam?.status === item?.value ? "#D6C8F5" : '#FFFFFF',
                                color: tempFilterParam?.status === item?.value ? '#191919' : "#636363",
                                border: tempFilterParam?.status === item?.value ? 'none' : '1px solid #E6E6E6',
                                fontWeight: tempFilterParam?.status === item?.value ? "650" : "normal",
                                maxWidth: 160,
                                minWidth: 100,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',

                            }}
                            onClick={(e) => {handleTypeToggle(item?.value), e.stopPropagation()}}
                        >
                            {item?.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const FilterTitleName = ({ filterName }) => {
        return (
            <div style={{ color: '', fontSize: 14, fontWeight: "bold", marginBottom: "5px" }}>
                {filterName}
            </div>
        )
    }

    function formatFilterParam(paramName, paramValue) {
        if (Array.isArray(paramValue)) {
            if (paramValue.length > 1) {
                const firstValue = paramValue[0];
                const additionalCount = paramValue.length - 1;
                const additionalText = `+${additionalCount}`;
                return ` ${firstValue} ${additionalText}`;
            } else {
                return ` ${paramValue[0]}`;
            }
        } else {
            return ` ${paramValue}`;
        }
    };


    const courseTypeMapping = {
        'course': 'Live course',
        'videoResource': 'Video course',
        'studyMaterial': 'Study materials',
        'testSeries': 'Test series'
    };
    const allTypes = ['course', 'videoResource', 'studyMaterial', 'testSeries'];
    const transformCourseTypeValue = (value) => {
        const typesArray = value?.split(', ')?.map(item => courseTypeMapping[item] || item);
        const includesAllTypes = allTypes?.every(type => value?.includes(type));

        if (includesAllTypes) {
            return 'All';
        } else {
            return typesArray?.join(', ');
        }
    };

    const sortDisplay = filterParam?.sort ? `Sort: ` : '';
    const typeDisplay = filterParam?.status ? `Status: ` : '';
    const courseTypeDisplay = filterParam?.type ? `Type: ` : '';
    const sortValue = filterParam?.sort == 'newest' ? 'Newest' : 'Oldest' || '';
    const typeValue = filterParam?.status ? formatFilterParam('Status', filterParam.status) : '';
    const result = Array.isArray(filterParam.type)
        ? filterParam.type.join(', ')
        : filterParam.type;
    const courseTypeValue = transformCourseTypeValue(result);

    const typeLabels = {
        studyMaterial: 'Study materials',
        course: 'Live course',
        videoResource: 'Video course',
        testSeries: 'Test series',
        All: 'All'
    };

   const mappedTypes = storeFilter?.type?.map(item => typeLabels[item] || item).join(', ') || 'All';



    const TypeFilterConversions = () => {
        const handleTypeToggle = (val) => {
            const updatedFilterParam = { ...filterParamConversion };

            updatedFilterParam.type = updatedFilterParam.type || [];

            if (val === 'All') {
                updatedFilterParam.type = ['All'];
            } else {
                const index = updatedFilterParam.type.indexOf('All');
                if (index !== -1) {
                    updatedFilterParam?.type?.splice(index, 1);
                }

                const indexOfVal = updatedFilterParam.type.indexOf(val);
                if (indexOfVal !== -1) {
                    updatedFilterParam.type.splice(indexOfVal, 1);
                } else {
                    updatedFilterParam.type.push(val);
                }

                if (updatedFilterParam.type.length === 0) {
                    updatedFilterParam.type.push('All');
                }
            }

            setFilterParamConversion(updatedFilterParam);
        };


        return (
            <div className=''>
                <div style={{ marginTop: -7 }}>
                    <FilterTitleName filterName="Sort by" />
                </div>

                <div style={{ display: "flex", flexWrap: "wrap", }}>
                    {Types1?.map((item) => (
                        <div
                            key={item.value}
                            className='sortByFilter'
                            style={{
                                backgroundColor: filterParamConversion?.type?.includes(item?.value) ? "#D6C8F5" : '#FFFFFF',
                                color: filterParamConversion?.type?.includes(item?.value) ? '#191919' : "#636363",
                                border: filterParamConversion?.type?.includes(item?.value) ? 'none' : '1px solid #E6E6E6',
                                fontWeight: filterParamConversion?.type?.includes(item?.value) ? "650" : "normal",
                                maxWidth: 130,
                                minWidth: 100,
                            }}
                            onClick={(e) => {handleTypeToggle(item?.value), e.stopPropagation()}}
                        >
                            {item?.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

        const filterDropDownMenu = (                
            <Menu visible={dropdownVisible} className='filterParent-1' style={{ width: 440, height: tab == 1 || tab == 2 ? 280 : 375, marginLeft: -345 }}>
                {tab == 1 || tab == 2 ?
                    <SortByFilter />
                    :
                    <TypeFilterConversions />
                }
                <TypeFilter />
                <div style={{ borderBottom: '1px solid #E6E6E6', marginTop: "5px" }}></div>
                <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "10px" }}>
                    <Button
                        type='link'
                        style={{ color: "#594099", cursor: "pointer" }}
                        onClick={(e) => {
                            // clearValues();
                            e.stopPropagation()
                            setCRMPageParamsObj("status", 'All')
                            setCRMPageParamsObj("sort", 'newest')
                            setFilterParam({ status: 'All', sort: 'newest', type: ['All'] })
                            setTempFilterParam({ status: 'All', sort: 'newest', type: ['All'] });
                            setIsApply(true)
                            setDropdownVisible(false)
                            setCRMPageParamsObj("newFirst", true);
                        }}
                    >RESET</Button>
                    <Button type='primary'
                    style={{ borderRadius: 5 }}                
                    onClick={(e) => {setPage(1); setFilterParam(tempFilterParam), setIsApply(true), setCRMPageParamsObj("status", tempFilterParam.status), setDropdownVisible(false) , e.stopPropagation()}}>APPLY</Button>
                </div>
            </Menu >
        );

    const actions = [
        selectedRowKeys?.length ? (<Button style={{ marginRight: 20, backgroundColor: "#1089FF", color: "#FFFFFF", borderRadius: 8, border: "1px solid #1089FF" }} onClick={() => { getAllClasses(); storeStudentRollNo(null); setIsAssignBatchModalVisible(true), setAssignUserDetails({ userId: null, phone: null, name: null, email: null }) }}>ADD BATCH/COURSE</Button>) : null,
        selectedRowKeys?.length && (tab == 1 || tab == 2) ? (<Button style={{ marginRight: 20, backgroundColor: "#FF414D", color: "#FFFFFF", borderRadius: 8, border: "1px solid #FF414D" }} onClick={() => { setPermanentDeletModal(true) }}>DELETE</Button>) : null,
        (!selectedRowKeys?.length > 0 ?
            <div style={{ marginLeft: -250, marginRight: 20, }}><Button icon={<CloudDownloadOutlined />} style={{ fontSize: 13 }} type="link" onClick={(e) => { e.stopPropagation(); setisModelVisible(true); setCalenderClicked(false); setShowCustomCal(false) }}>DOWNLOAD REPORT</Button></div>
            :
            <></>
        ),
        (
            !selectedRowKeys?.length > 0 ?
                <div style={{ marginRight: 20 }}>
                    {/* <DatePicker value={moment(crmParamsObj?.startDate, "DD/MM/YYYY") }   onChange={headerDatePckerOnchange} renderExtraFooter={() => 'extra footer'} showTime format={'DD/MM/YYYY'} style={{ width: 250 }} /> */}
                    <DatePicker
                        placeholder='Select date'
                        inputReadOnly={true}
                        format="DD/MM/YYYY"
                        onChange={handleChange}
                        allowClear={true}
                        style={{ borderRadius: '10px', width: 125 }}
                        value={startDate}
                    />
                </div>
                :
                <></>
        ),

        !selectedRowKeys?.length &&
        <div>
            {/* <SearchAndFilters onSearch={(e) => { handleSearch(e) }} filterDropDownMenu={filterDropDownMenu} handleDropdownVisibleChange={handleDropdownVisibleChange} dropdownVisible={dropdownVisible} fromMarketPlaceTable={true} tab={tab} setIsFilterModal={setIsFilterModal} search={search} searchPlaceholder={<div style={{ marginBottom: 18, }} >Search by name / phone number</div>} /> */}
            <SearchFilterComp
                style={{ width: 340 ,borderRadius:"8px"}}
                placeholder="Search by name / mobile number"
                handleSearch={(e) => { handleSearch(e) }}
                value={crmParamsObj?.search}
                menu={filterDropDownMenu}
                open={dropdownVisible}
                handleFilterIconClick={(e) => {
                    e.stopPropagation()
                    if((tab == 3 || tab == 4)){
                        setIsFilterModal(true)
                    }else{
                        setDropdownVisible(true);
                    }
                }} />
            <div className='display-flex' style={{ justifyContent: 'flex-end', width: '94%' }} >
                {tab == 1 || tab == 2 ? <> {sortDisplay && (
                    <span>
                        <span style={{ color: '#AEAEAE',  fontSize : 12  }}>
                            {sortDisplay}
                        </span>
                        <span style={{fontSize:11}}>
                            {sortValue}
                        </span>
                    </span>
                )}
                    <span style={{ color: '#AEAEAE', marginLeft: 8 }}>|</span>
                    {typeDisplay && (
                        <span style={{ marginLeft: sortDisplay ? '10px' : '0' }}>
                            <span style={{ color: '#AEAEAE', fontSize : 12  }}>
                                {typeDisplay}
                            </span>
                            <span style={{fontSize:11}}>
                                {typeValue}
                            </span>
                        </span>
                    )}
                </>
                    :
                    <>
                        {/* {courseTypeDisplay && ( */}
                        {console.log("mappedTypes",mappedTypes)}
                            <span>
                                <span style={{ color: '#AEAEAE', fontSize : 12 }}>Type:</span>
                                <span style={{ fontSize: 11 }}>{mappedTypes}</span>
                                {/* <span style={{ color: '#AEAEAE', fontSize : 12  }}>
                                    {console.log("courseTypeDisplay",courseTypeDisplay,courseTypeValue)}
                                    {courseTypeDisplay}
                                </span>
                                <span style={{ fontSize: 11 }}>
                                    {courseTypeValue}sss
                                </span> */}
                            </span>
                        {/* )} */}
                        <span style={{ color: '#AEAEAE', marginLeft: 8 }}>|</span>

                        {typeDisplay && (
                            <span style={{ marginLeft: sortDisplay ? '10px' : '0' }}>
                                <span style={{ color: '#AEAEAE', fontSize : 12 }}>
                                    {typeDisplay}
                                </span>
                                <span style={{ fontSize: 11 }}>
                                    {typeValue}
                                </span>
                            </span>
                        )}
                    </>
                }
            </div>
        </div>
    ]

    const onRow = (record, rowIndex) => {
        return {
            style: { cursor: hasMarketingPrivilege && !record?.questions?.length > 0 ? '' : 'pointer' },
            onClick: (e) => {
                e.stopPropagation();
                if (record?.questions?.length > 0) {
                    toggleExpansion(record?.id, "DETAILS");
                }
            }
        }
    }

    const rowSelection = {
        type: "checkbox",
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selections) => {
            setSelectedRowKeys([...selectedRowKeys]);
            setSelectedRowKeysForAssignCourse([...selectedRowKeysForAssignCourse])

        },
    };

    const [expandedDetailsItemId, setExpandedDetailsItemId] = useState(null);
    const [expandedMoreItemId, setExpandedMoreItemId] = useState(null);
    


    const toggleExpansion = (itemId, source) => {
        if (source === "DETAILS") {
            setExpandedDetailsItemId(prevId => (prevId === itemId ? null : itemId));
            setExpandedMoreItemId(null);
        } else if (source === "MORE") {
            setExpandedMoreItemId(prevId => (prevId === itemId ? null : itemId));
            setExpandedDetailsItemId(null);
        }
    };

    const CoursesInCartComp = ({ record }) => {
        const itemId = record.id;
        const isMoreExpanded = expandedMoreItemId === itemId;
        const imageSources = {
            course: liveCourseIcon,
            videoResource: videoResourceIcon,
            studyMaterial: studyMaterialIcon,
            testSeries: testSeriesIcon
        }
        return (
            <div style={{ width: "100%" }}>
                {record?.details?.slice(0, isMoreExpanded ? undefined : 1)?.map((course, courseIndex) => (
                    <div className='r-c-sb' style={{ width: "100%" }}>
                        <div className='r-ac cursor-pointer' style={{ width: "100%" }}
                            onClick={() => {
                                storeSingleCourseId(course?.id);
                                if (course?.type == "course") {
                                    setCourseTypeName("liveCourses")
                                } else if (course?.type == "videoResource") {
                                    setCourseTypeName("videoResources")
                                } else if (course?.type == "testSeries") {
                                    setCourseTypeName("onlineTestSeries")
                                } else if (course?.type == "studyMaterial") {
                                    setCourseTypeName("studyMaterials")
                                }
                                history.push(
                                    {
                                        pathname: "/course-details",
                                        state: {
                                            fromCRMPage: true,
                                            courseType: course?.type,
                                        }
                                    })
                            }}>
                            <img src={imageSources[course?.type]} style={{ width: 30 }} />
                            <div className='m-l-10' style={{ width: "100%" }}>
                                <div className='r-ac' style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <Tooltip title={course?.courseName || "NA"}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: 'nowrap', color: "#191919", marginRight: 10, maxWidth: !isMoreExpanded && record?.details?.length > 1 ? 'calc(100% - 100px)' : "80%", fontSize: 12 }}>
                                            {course?.courseName}
                                        </div>
                                    </Tooltip>
                                    {!isMoreExpanded && record?.details?.length > 1 && (
                                        <div className='cursor-pointer' style={{ color: "#594099", fontWeight: 600, whiteSpace: 'nowrap', fontSize: 12 }} onClick={(e) => { e.stopPropagation(); toggleExpansion(record?.id, "MORE") }}>
                                            + {record?.details?.length - 1} more
                                        </div>
                                    )}
                                </div>
                                <div style={{ color: "#AEAEAE", fontFamily: "roboto", fontSize: 12 }}>{moment(course?.createdAt).format("DD/MM/YYYY")}</div>
                                {courseIndex !== record?.details.length - 1 && <div className='m-t-5 m-b-5'></div>}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const ExpandedRow = ({ record }) => {
        return (
            <div>
                {record?.questions?.map((qa, index) => (
                    <div key={index} >
                        <div className='m-t-10' style={{ color: "#AEAEAE" }}>{qa.statement}</div>
                        <div style={{ color: "#191919" }}>{qa.answer}</div>
                    </div>
                ))}
            </div>
        )
    }

    const columns = () => {
        const renderName = (value, record) => (
            <div className='r-ac' style={{ cursor: hasMarketingPrivilege ? "" : record?.type == "registration" ? "pointer" : "default" }} onClick={(e) => {
                e.stopPropagation();
                if(!hasMarketingPrivilege){
                if (record?.type == "registration") {
                    const newItem = {
                        ...record,
                        id: record?.userId
                    };
                    delete newItem.userId;
                    setUserDetails(newItem)
                    setSpecificUserId(newItem?.id);
                    setOthersSelectedTab('Basic Profile');
                    history.push({
                        pathname: '/user-management/profile',
                        state: {
                            key: true
                        }
                    })
                }
            }
            }}>
                <UserAvatar size="4vmin" shape="circle" name={record?.name} />
                <div className='m-l-10' style={{ textAlign: "left", width: "100%" }}>
                    <Tooltip title={value || "NA"}>
                        <div style={{ width: '100%', overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical", color: "#191919", fontSize: 12 }}>{value}</div>
                    </Tooltip>
                    {(tab == 1 || tab == 2) && (
                        <div style={{ color: "#AEAEAE", fontFamily: "roboto", fontSize: 12 }}>{record?.role?.[0]}</div>
                    )}
                </div>
            </div>
        );

        const baseColumns = [
            {
                title: 'NAME',
                dataIndex: 'name',
                key: 'name',
                width: tab == 3 || tab == 4 ? "15%" : '20%',
                render: renderName,
                renderMore: (record) => {
                    return <ExpandedRow record={record} />
                }
            },
            {
                title: 'CONTACT',
                dataIndex: 'contact',
                key: 'contact',
                width: tab == 1 ? '20%' : '15%',
                render: (value, record) => (
                    <div style={{ width: "100%", textAlign: "left", color: "#191919", fontFamily: "roboto" }}>
                        <Tooltip title={record?.phone || "NA"}>
                            <div style={{ width: '100%', overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical", color: "#191919", fontSize: 12 }}>
                                {!record?.phone || record?.phone === "-" || record?.phone === "+91" ? "Guest user" : record?.phone}
                            </div>
                        </Tooltip>
                        {record?.email && record?.email != "-" &&
                            <Tooltip title={record?.email || "NA"}>
                                <div style={{ width: '100%', overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical", color: "#AEAEAE", fontSize: 12 }}>
                                    {!record?.email || record?.email === "-" ? "NA" : record?.email}
                                </div>
                            </Tooltip>}
                    </div>
                ),
                renderMore: (record) => {
                }
            },
            {
                title: tab == 1 ? 'ENQUIRED AT' : tab == 2 ? "REGISTERED AT" : "REGISTERED ON",
                dataIndex: 'date',
                key: 'date',
                width: "14%",
                render: (value, record) => (
                    <div style={{ color: "#191919", fontFamily: "roboto", fontSize: 12 }}>
                        {tab == 1 || tab == 2 ? moment(record?.createdAt).format("hh:mm A") : moment(record?.createdAt).format("DD/MM/YY")}
                    </div>
                ),
                renderMore: (record) => {
                    // return <ExpandedRow record={record} />
                }
            }
        ];

        const coursesInCartOrDeviceColumn = {
            title: tab == 2 ? "DEVICE" : tab == 3 ? "COURSES IN CART" : "BOOKMARKED COURSES",
            dataIndex: 'column4',
            key: 'column4',
            width: tab == 2 ? '10%' : "28%",
            render: (value, record) => (
                tab == 2 ?
                    <div style={{ color: "#191919", fontFamily: "roboto", fontSize: 12 }}>Web</div>
                    : (tab == 3 || tab == 4) ? <CoursesInCartComp record={record} />
                        : null
            )
        };

        const statusColumn = {
            title: "STATUS",
            dataIndex: 'status',
            key: 'status',
            width: "15%",
            render: (value, record) => (
                <div className='card-container' style={{ display: 'flex', color: "#191919", fontFamily: "roboto" }}>
                    <div style={{ fontSize: 12, color: record?.crmStatus == 'New' ? '#594099' : record?.crmStatus == 'Interested' ? '#14B789' : record?.crmStatus == 'Not Interested' ? '#FFA931' : record?.crmStatus == 'Contact Initiated' ? '#0AB1E6' : '#9876E6' }}>{record?.crmStatus?.toUpperCase()}</div>
                    {record?.crmStatus === 'New' ? (
                        <div className='edit-text-fee' style={{ marginLeft: 5, color: '#1089FF', cursor: 'pointer', marginTop: -2 }} onClick={(e) => { e.stopPropagation(); setStatusIsModalVisible(true); setCrmStatus(record) }}>
                            <PlusCircleOutlined />
                        </div>
                    ) : (
                        <div className='edit-text-fee' style={{ marginLeft: 5, color: '#1089FF', cursor: 'pointer', marginTop: -2 }} onClick={(e) => { e.stopPropagation(); setStatusIsModalVisible(true); setCrmStatus(record) }}>
                            <EditOutlined />
                        </div>
                    )}
                </div>
            )
        };

        if (tab != 1) {
            if (tab == 3 || tab == 4) {
                baseColumns.push(coursesInCartOrDeviceColumn);
                baseColumns.push(statusColumn);
            } else {
                baseColumns.push(statusColumn);
                baseColumns.push(coursesInCartOrDeviceColumn);
            }
        } else {
            baseColumns.push(statusColumn);
        }

        baseColumns.push({
            title: "ACTIONS",
            dataIndex: 'actions',
            key: 'actions',
            width: tab == 1 || tab == 2 ? "20%" : "12%",
            render: (value, record) => (
                <div className='r-jsb' style={{ width: "100%" }}>
                    {record ? (
                        <>
                            <Button disabled={selectedRowKeys?.length > 0} type="link" style={{ padding: "0px 0px", fontSize: 13 }} onClick={(e) => { e.stopPropagation(); setIsAssignBatchModalVisible(true); setStudentId(record?.userId); setAssignUserDetails({ userId: record?.userId, phone: record?.phone, name: record?.name, email: record?.email }) }}>ADD BATCH/COURSE</Button>
                            {tab == 1 || tab == 2 ? <Button style={{ backgroundColor: "transparent", fontSize: 13, marginLeft: -20 }} disabled={selectedRowKeys?.length > 0} type="delete" onClick={(e) => { e.stopPropagation(); setPermanentDeletModal(true); setSelectedDeleteButton(record?.id) }}>DELETE</Button> : <></>}
                        </>
                    ) : (tab == 3 || tab == 4) ? (
                        <Button disabled={selectedRowKeys?.length > 0} type="link" style={{ padding: "0px 0px", fontSize: 13 }} onClick={(e) => { e.stopPropagation(); setIsAssignBatchModalVisible(true); setStudentId(record?.id); setAssignUserDetails({ userId: record?.id, phone: record?.phone }) }}>ADD BATCH/COURSE</Button>
                    ) : null}
                </div>
            )
        });
        return baseColumns;
    };

    const onChange = (key) => {
        clearValues();
        togglePageLoader(true)  
        switch (key) {
            case '1':
                getEnquiryFormsData('Enquiry', 1, page === 1, () => {
                    setIsLoading(false);
                    togglePageLoader(false);
                    setIsApply(false);
                });
                break;
            case '2':
                getEnquiryFormsData('Registration', 1, page === 1, () => {
                    setIsLoading(false);
                    togglePageLoader(false);
                    setIsApply(false);
                });
                break;
            case '3':
                getCoursesInCartData(1, page === 1, () => {
                    setIsLoading(false);
                    togglePageLoader(false);
                    setIsApply(false);
                });
                break;
            default:
                getWhistledCoursesData(1, page === 1, () => {
                    setIsLoading(false);
                    togglePageLoader(false);
                    setIsApply(false);
                });
                break;
        }

        
        setTab(key);
        setType(key === '2' ? 'Registration' : 'Enquiry');
        setDropdownVisible(false);
        setCalenderClicked(false);
        setFilterParam({ status: 'All', sort: 'newest', type: ['All'] });
        setTempFilterParam({ status: 'All', sort: 'newest', type: ['All'] });
        setCRMPageParamsObj('newFirst', true)
        setCRMPageParamsObj("endDate", 'None');
        setCRMPageParamsObj("startDate", 'None');
        setSelectedRowKeys([]);
        setSelectedRowKeysForAssignCourse([]);
        setExpandedDetailsItemId(null);
        setExpandedMoreItemId(null);
        setStartDate(null);
        setStoreFilter([])
        setIsValue(null)
    };
    

    return (
        <div style={bodyStyle} onClick={e=>{e.stopPropagation(); setDropdownVisible(false)}}>
            <div style={{ marginBottom: "60px" }}>
                <PageHeader
                    onBackPress={() => {
                        history.push({
                            pathname: "/overview-crm",
                            state: {
                                key: true,
                            }
                        })
                    }}
                    title='CRM' actions={actions} />
            </div>

            <Tabs defaultActiveKey={tab.toString()} activeKey={tab.toString()} onChange={onChange}>
                <Tabs.TabPane tab="ENQUIRY" key={'1'} />
                <Tabs.TabPane tab="REGISTRATION" key={'2'} />
                <Tabs.TabPane tab="COURSES IN CART" key={'3'} />
                <Tabs.TabPane tab="BOOKMARKED COURSES" key={'4'} />
            </Tabs>
            <CustomTable
                style={{ width: "100vw", cursor:hasMarketingPrivilege ? '' :'pointer' }}
                rowKey={record => record?.id}
                onRow={onRow}
                rowSelection={rowSelection}
                dataSource={crmTableData?.items || []}
                columns={columns()}
                setSelectedRowKeys={setSelectedRowKeys}
                setSelectedRowKeysForAssignCourse = {setSelectedRowKeysForAssignCourse}
                selectedRowKeys={selectedRowKeys}
                expandedMoreItemId={expandedMoreItemId}
                expandedDetailsItemId={expandedDetailsItemId}
                pageMeta={crmTableData?.meta}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setPage={setPage}
                page={page}
                source={"crmTable"}
                showCursor={tab == 1 || tab == 2 ? true : false}
                hasMarketingPrivilege={hasMarketingPrivilege}
            />
            {isAllCoursesListModalVisible &&
                <AllCoursesListModal
                    isAllCoursesListModalVisible={isAllCoursesListModalVisible}
                    setAllCoursesListModalVisible={setAllCoursesListModalVisible}
                    crmCourseListData={crmCourseListData}
                    storeSingleCourseId={storeSingleCourseId}
                    setCourseTypeName={setCourseTypeName}
                    tab={tab}
                    selectedUserId={selectedUserId}
                />}

            {
                isFilterModal && <MarketPlaceCrmFilterModal
                    isFilterModal={isFilterModal}
                    setIsFilterModal={setIsFilterModal}
                    tab={tab}
                    onSearch={(e) => { handleSearch(e) }}
                    setCRMPageParamsObj={setCRMPageParamsObj}
                    storeAutocompleteSearchData={storeAutocompleteSearchData}
                    setPage={setPage}
                    fromMarketPlaceTable={true}
                    searchPlaceholder={<div style={{ marginBottom: 18, }} >Search by name / phone number</div>}
                    filterParamConversion={filterParamConversion}
                    setFilterParamConversion={setFilterParamConversion}
                    Types1={Types1}
                    Types={Types}
                    filterParam={filterParam}
                    setFilterParam={setFilterParam}
                    // setAutoSearch={setAutoSearch}
                    togglePageLoader={togglePageLoader}
                    getCoursesInCartData={getCoursesInCartData}
                    getWhistledCoursesData={getWhistledCoursesData}
                    autocompleteSearchData={autocompleteSearchData}
                    // setAutoSearchLoader={setAutoSearchLoader}
                    // autoSearchLoader={autoSearchLoader}
                    getCRMAutocompleteSearch={getCRMAutocompleteSearch}
                    crmParamsObj={crmParamsObj}
                    // autoSearch={autoSearch}
                    setIsApply={setIsApply}
                    tempFilterParam={tempFilterParam}
                    setTempFilterParam={setTempFilterParam}
                    applyButton = {applyButton}
                    setApplyButton = {setApplyButton}
                    setStoreFilter={setStoreFilter}
                    isValue = {isValue}
                    setIsValue = {setIsValue}
                    storeCRMTableData = {storeCRMTableData}

                />
            }
            {isModelVisible &&
                <MarketPlaceCrmDownloadReport
                    isModelVisible={isModelVisible}
                    setisModelVisible={setisModelVisible}
                    storeSelectedRange={storeSelectedRange}
                    storeStartDate={storeStartDate}
                    storeEndDate={storeEndDate}
                    startDateContainer={startDateContainer}
                    endDateContainer={endDateContainer}
                    selectedRangeContainer={selectedRangeContainer}
                    postCrmDownloadReport={postCrmDownloadReport}
                    fromMarketPlaceCRM={true}
                    tab={tab}
                />}
            {statusModalVisible && (
                <StatusChangeModal
                    isModalVisible={statusModalVisible}
                    setIsModalVisible={setStatusIsModalVisible}
                    setCrmStatus={setCrmStatus}
                    crmStatus={crmStatus}
                    tab={tab}
                    getEnquiryFormsData={getEnquiryFormsData}
                    getCoursesInCartData={getCoursesInCartData}
                    getWhistledCoursesData={getWhistledCoursesData}
                    togglePageLoader={togglePageLoader}
                    setPage={setPage}
                />
            )

            }
            {isAssignBatchModalVisible &&
                <AddCourseClassModal
                    tab={tab}
                    assignserDetails={assignserDetails}
                    getEnquiryFormsData={getEnquiryFormsData}
                    getCoursesInCartData={getCoursesInCartData}
                    getWhistledCoursesData={getWhistledCoursesData}
                    isAssignBatchModalVisible={isAssignBatchModalVisible}
                    setIsAssignBatchModalVisible={setIsAssignBatchModalVisible}
                    studentId={studentId}
                    selectedStudents={selectedRowKeysForAssignCourse}
                    togglePageLoader={togglePageLoader}
                    setPage={setPage}
                />
            }
            {parmanentDeleteModal &&
                <CrmDeleteModal
                    // isFromCourse={true}
                    getEnquiryFormsData={getEnquiryFormsData}
                    getCoursesInCartData={getCoursesInCartData}
                    getWhistledCoursesData={getWhistledCoursesData}
                    tab={tab}
                    togglePageLoader={togglePageLoader}
                    setSelectedRowKeys={setSelectedRowKeys}
                    selectedRowKeys={selectedRowKeys}
                    open={parmanentDeleteModal}
                    setPage={setPage}
                    setPermanentDeletModal={setPermanentDeletModal}
                    onCancel={() => onPermanentDeleteModalCancel()}
                    selectedDeleteButton = {selectedDeleteButton}
                     />}

            <PageDataLoader visible={pageLoader} />
        </div>
    )
}
const mapStateToProps = (state) => {
    const { crmTableData, crmParamsObj, crmCourseListData, autocompleteSearchData, tab } = state.courseManagement;
    const { startDateContainer, selectedRangeContainer, endDateContainer, } = state.attendanceNew;
    const { user } = state.session;
    return {
        crmTableData, crmParamsObj, crmCourseListData, startDateContainer, selectedRangeContainer, endDateContainer, autocompleteSearchData, tab, user
    };
};

const mapDispatchToProps = (dispatch) => ({
    getEnquiryFormsData: (type, page, firstTimeLoading, callBack) => dispatch(getEnquiryFormsData(type, page, firstTimeLoading, callBack)),
    setCRMPageParamsObj: (key, val) => dispatch(setCRMPageParamsObj(key, val)),
    getCoursesInCartData: (page, firstTimeLoading, callBack) => dispatch(getCoursesInCartData(page, firstTimeLoading, callBack)),
    getWhistledCoursesData: (page, firstTimeLoading, callBack) => dispatch(getWhistledCoursesData(page, firstTimeLoading, callBack)),
    storeCRMTableData: (val) => dispatch(storeCRMTableData(val)),
    storeSelectedRange: (val) => dispatch(storeSelectedRange(val)),
    storeStartDate: (val) => dispatch(storeStartDate(val)),
    storeEndDate: (val) => dispatch(storeEndDate(val)),
    postCrmDownloadReport: (tab, callback) => dispatch(postCrmDownloadReport(tab, callback)),
    storeSingleCourseId: (val) => dispatch(storeSingleCourseId(val)),
    setCourseTypeName: (val) => dispatch(setCourseTypeName(val)),
    getCRMAutocompleteSearch: (search, tab, callBack) => dispatch(getCRMAutocompleteSearch(search, tab, callBack)),
    storeAutocompleteSearchData: (val) => dispatch(storeAutocompleteSearchData(val)),
    showMenu: (bool) => dispatch(showMenu(bool)),
    setUserDetails: (res) => dispatch(setUserDetails(res)),
    setSpecificUserId: id => dispatch(setSpecificUserId(id)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
    setTab: (res) => dispatch(setTab(res)),
    storeCrmCourseListData: (val) => dispatch(storeCrmCourseListData(val))

});
export default connect(mapStateToProps, mapDispatchToProps)(MarketPlaceCRM);