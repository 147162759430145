// import { AddRating } from 'actions/aulasMarket';
import { Button, Modal, Rate } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react'
import "../studentMarketPlace/myCourses/allCourses.css"
import { getCourseDashBoardData, postAddRating } from 'actions/studentMarketPlace';
import { connect } from 'react-redux';
import PageDataLoader from 'components/pageDataLoader';

function AddRatingModal({ isRatingModalVisible, getCourseDashBoardData, setIsRatingModalVisible, userRating, postAddRating }) {
    const [pageLoader, togglePageLoader] = useState(false);
    const [ratingExplanation, setRatingExplanation] = useState("");
    const [ratingValue, setRatingValue] = useState(0);
    const [addLoader, setAddLoader] = useState(false);
   
    useEffect(() => {
        if (userRating) {
            // Set the initial state values based on the userRating prop
            setRatingValue(userRating.ratingValue);
            setRatingExplanation(userRating.ratingExplanation);
        }
    }, [userRating]);
    const StarRatingNew = () => {
        const handleStarClick = (clickedRating) => {
            setRatingValue(clickedRating);
        };

        return (
            <div className='star-rating-new-parent' style={{}}>
                <div className="star-rating-new" style={{}}>
                    <Rate onChange={handleStarClick}
                        className='r-c-c'
                        value={ratingValue} allowHalf style={{ maxWidth: "100%", fontSize: "35px", color: "#FFA931" }} />
                </div>
            </div>
        );
    };
    const handleCancel = () => {
        setIsRatingModalVisible(false)
    }

    const handleComment = (e) => {
        console.log("valll", e.target.value)
        setRatingExplanation(e.target.value)
    }
    const handleSubmit = () => {
        setAddLoader(true);
        postAddRating(userRating?.id, userRating?.type, ratingValue, ratingExplanation, () => {
            setAddLoader(false);
        }, () => { setIsRatingModalVisible(false); togglePageLoader(true); getCourseDashBoardData('', () => { togglePageLoader(false) }) })
    }

    return (
        <div>
            <Modal open={isRatingModalVisible} bodyStyle={{ borderRadius: "8px", height: "50%" }}
                centered
                className="modal-round-corner"
                onOk={handleCancel}
                onCancel={handleCancel}
                header={null}
                footer={null}

            >
                <div className='r-c-c' style={{ fontSize: "18px", fontWeight: 500 }}>Add a rating</div>
                <div><StarRatingNew /></div>
                {console.log("ratingValue",ratingValue)}
                <div className='m-t-10'><TextArea rows={4} value={ratingExplanation} onChange={handleComment} style={{ resize: 'none', borderRadius: "8px" }} placeholder='Add review' /></div>
                <div className='r-c-c m-t-20'><Button loading={addLoader} disabled={ratingValue >= 0.5 ? false : true} onClick={handleSubmit} className='submitBtn'>SUBMIT</Button></div>
            </Modal>

            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = (state) => {

};

const mapDispatchToProps = (dispatch) => ({
    postAddRating: (id, type, ratingValue, ratingExplanation, callback, succesCallback) => dispatch(postAddRating(id, type, ratingValue, ratingExplanation, callback, succesCallback)),
    getCourseDashBoardData: (filterParam, callback) => dispatch(getCourseDashBoardData(filterParam, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRatingModal)
