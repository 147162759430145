import {
    APPEND_OFFERS_LIST,
    SET_CREATE_OFFERS_OBJ,
    SET_CREATE_OFFERS_OBJ_KEY,
    SET_OFFERS_OVERVIEW,
    SET_OFFERS_TYPE_INDEX,
    STORE_OFFERS_LIST,
    STORE_SINGLE_OFFER,
} from 'actions/offers';

const initialState = {
    offersObj: {},
    offerData: [],
    singleOfferData: [],
    offersOverview: {},
    offersTypeIndex: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CREATE_OFFERS_OBJ_KEY: {
            let newOffersObj = { ...state.offersObj };
            newOffersObj[action.key] = action.val;
            return {
                ...state,
                offersObj: newOffersObj,
            }
        }
        case SET_CREATE_OFFERS_OBJ: {
            return {
                ...state,
                offersObj: action.val,
            }
        }
        case STORE_OFFERS_LIST: {
            return {
                ...state,
                offerData: action.val
            }
        }
        case APPEND_OFFERS_LIST: {
            const newData = { ...state.offerData };
            newData.meta = action.val.meta;
            newData.items = newData.items.concat(action.val.items)
            return {
                ...state,
                offerData: newData,
            }
        }
        case STORE_SINGLE_OFFER: {
            return {
                ...state,
                singleOfferData: action.val
            }
        }
        case SET_OFFERS_OVERVIEW: {
            return {
                ...state,
                offersOverview: action.val,
            }
        }
        case SET_OFFERS_TYPE_INDEX: {
            return {
                ...state,
                offersTypeIndex: action.val,
            }
        }
        default: {
            return {
                ...state
            };
        }
    }
}

export default reducer;