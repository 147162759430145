import React, {useState, useEffect} from 'react'
import { PageHeader, Row, Col, Select, Progress, Button } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { SubHeading, Heading, Title } from 'components/Typography';
import { Color } from 'services';
import { useHistory } from 'react-router';
import PageDataLoader from 'components/pageDataLoader';
import {
    fetchResult,

} from 'actions/studentTest'
import {connect} from 'react-redux';
import { AiTwotoneCheckCircle } from 'react-icons/ai';


const questionsSummary = (icon, count, label) => (
    <div className="display-flex" style={{alignItems: 'center'}}>
        <div>
            <img src={icon} width={40} alt="" />
        </div>
        <div className="m-l-10">
            <div className="text-md bold-700">{count}</div>
            <div className="text-xs dark-grey">{label}</div>
        </div>
    </div>
)

const performanceSummary = (icon, count, text, label) => (
    <div className="r-jsb" style={{alignItems: 'center'}}>
        <div className="display-flex" style={{alignItems: 'center'}}>
            <img src={icon} width={40} alt="" />
            <div className="text-xs bold-700 m-l-10">{label}</div>
        </div>
        <div className="m-l-10">
            <div className="text-sm bold-700">{count} <span className="text-xsm dark-grey">{text}</span> </div>
        </div>
    </div>
)

const singleSection = (sub, details, index) => {
    return (
    <div className="m-t-20">
        <div className="bold-600">{sub}</div>
        <div className="r-jsb">
            <div className="display-flex" style={{borderBottom: `1px solid ${Color.midGrey}`,  width: "100%"}}>
                <div style={{padding: 20}}>
                    <CheckCircleOutlined style={{color: Color.seaGreen}} /> {details.totalCorrect}
                </div>
                <div style={{padding: 20}}>
                    <CloseCircleOutlined style={{color: Color.red}} /> {details.totalIncorrect}
                </div>
                <div style={{padding: 20}}>
                    <MinusCircleOutlined style={{color: Color.darkGrey}} /> {details.totalUnattempted}
                </div>
                <div style={{padding:20}}>
                    <AiTwotoneCheckCircle style={{color:"white", border:"1px solid black", borderRadius:10}}/> {details.totalUnseen}
                </div>
            </div>
            <div className="text-md bold-600" style={{padding: 20}}>
                {details.marksObtained || 0}/{details.totalMarks}
            </div>
        </div>
    </div>
)}

function TestResult({
    fetchResult, testResult, selectedTest
}) {
    const history = useHistory();

    const [pageLoader, togglePageLoader] = useState(false);

    useEffect(() => {
        togglePageLoader(true)
        fetchResult(() => togglePageLoader(false))
        return () => {

        };
    }, []);
    console.log({testResult: testResult});

    return (

    <div style={{ height: '100%'}}>
        <PageHeader
            title={<div className="text-md"> Result </div>}
            onBack={() => history.goBack()}
            backIcon={<ArrowLeftOutlined  style={{fontSize: "1.2em"}} />	}
        />
        <div className="m-l-20">
            <Heading bold>{selectedTest.title}</Heading>
            <div className="text-xs dark-grey">{selectedTest.subjectNames?.split(',')?.join(' • ')} </div>
        </div>
        <Row style={{height: '100%'}}>
        <Col md={{span:12}} lg={{span: 15}} xl={{span: 15}} xxl={{span: 17}}>
        {/*Section 1 */}
            <div style={{padding: "0 20px 20px 20px"}}>

                <div className="graphBox" style={{ padding: 25, height: 350}}>
                    <div className="bold-600">Score</div>
                    <Row style={{height: "95%"}}>
                        <Col span={12} className="r-c-c-c">
                            <Progress
                                type="circle"
                                percent={Math.round(testResult.total?.marksObtained/testResult.total?.totalMarks * 100)}
                                strokeWidth={10}
                                width={220}
                                strokeLinecap="square"
                                strokeColor="#594099"
                                format={(percent) => (
                                    <>
                                        <div className="bold-600 m-b-10 "
                                            style={{fontSize: "0.6em"}}>{testResult.total?.marksObtained}</div>
                                        <div className="bold-600 " style={{fontSize: "0.6em"}}>
                                        <span style={{borderTop: '1px solid black'}}>{testResult.total?.totalMarks}</span></div>
                                    </>

                                    )}

                            />
                        </Col>
                        <Col span={12}>
                            <div className="m-l-40 r-c-jsb" style={{alignItems: 'flex-start', height: "100%"}}>
                                {questionsSummary(require("Assets/correct-icon.svg").default,testResult.total?.totalCorrect, "Correct")}
                                {questionsSummary(require("Assets/correct-icon.svg").default,testResult.total?.totalPartialCorrect, "Partially correct")}
                                {questionsSummary(require("Assets/wrong-icon.svg").default,testResult.total?.totalIncorrect, "Incorrect")}
                                {questionsSummary(require("Assets/unattempt-icon.svg").default,testResult.total?.totalUnattempted, "Unattempted")}
                                {questionsSummary(require("Assets/images/ic-unseen.svg").default,testResult.total?.totalUnseen, "Unseen")}

                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            {
                testResult.subjects && Object.entries(testResult.subjects)?.length>1?
                <div style={{padding: "20px 20px"}}>
                    <div className="graphBox" style={{ padding: 25, minHeight: 400}}>
                            <div className="bold-600">Sections</div>
                            {testResult.subjects ? Object.entries(testResult.subjects)?.map(([sub, details], index) => singleSection(sub, details, index)) : null}
                            <div className="r-c-c m-t-30">
                                <Button type="primary" shape="round" onClick={() => history.push("/student-test/solutions")}>
                                        VIEW SOLUTIONS
                                </Button>
                            </div>
                    </div>
                </div>
                :testResult.subjects &&Object.entries(testResult.subjects)?.length==1
                ?
                <div className="r-c-c m-t-30">
                    <Button type="primary" shape="round" onClick={() => history.push("/student-test/solutions")}>
                            VIEW SOLUTIONS
                    </Button>
                </div>
                :null
            }

        </Col>
        <Col md={{span:12}} lg={{span: 9}} xl={{span: 9}} xxl={{span: 7}}>
            <div style={{padding: "0 20px 20px 20px"}}>
                <div className="graphBox" style={{width: "90%", padding: 25, height: 350}}>
                    <div className="bold-600">Performance analysis</div>
                    <div className="display-flex m-t-30" style={{flexDirection: 'column',
                        justifyContent: 'space-between', height: "80%"}}>
                        {performanceSummary(require("Assets/rank-icon.svg").default,testResult.rank+'/'+testResult?.totalStudents, '', "Rank")}
                        {performanceSummary(require("Assets/accuracy-icon.svg").default,testResult.accuracy, "%","Accuracy")}
                        {performanceSummary(require("Assets/speed-icon.svg").default,testResult.speed, "Mins/Qs", "Speed")}
                        {performanceSummary(require("Assets/percentile-icon.svg").default,testResult.percentile, <sup>%</sup>, "Percentile")}
                    </div>
                </div>
            </div>
        </Col>
        </Row>
        <PageDataLoader visible={pageLoader}/>
    </div>


    )
}

const mapStateToProps = state => {
    const {
        testResult, selectedTest
    } = state.studentTest;
    return {
        testResult, selectedTest
    };
  };

  const mapDispatchToProps = dispatch => ({
    fetchResult: (callback) => dispatch(fetchResult(callback)),
  });


export default connect(mapStateToProps, mapDispatchToProps)(TestResult);
