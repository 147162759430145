import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'antd';
import ReactPlayer from 'react-player';
import { Api } from 'services';
import YTPlayer from 'components/ytPlayer';
import ReactPlayerHoc from 'components/ReactPlayerHoc';

function PreviewCourse({ isVideoPreview, setVideoPreview, previewLink,courseName,videoType, isPreviewClicked, courseContentPreviewName,videoName }) {
    console.log("abcd123",`${Api.dlS3Url}${previewLink}`);
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    console.log("fileUrl: ", previewLink);
    const handleCancel = () => {
        setVideoPreview(false)
    }
    return (
        <Modal
            open={isVideoPreview}
            onCancel={handleCancel}
            footer={null}
            width={1200}
            bodyStyle={{ height: "750px", backgroundColor: "#000000", marginTop: "-80px" }}
        >
            <div className='r-c-fs'>
                <div onClick={handleCancel} style={{ cursor: "pointer" }}>
                    <img src={require("../../../Assets/aulasMarket/ic-back-arrow.svg").default} />
                    <span style={{color:"White",fontSize:"21px",marginLeft:"50px"}}>{videoName}</span>
                </div>
                <div style={{color:"White",fontSize:"21px",marginLeft:"-30px"}}>{isPreviewClicked ? courseContentPreviewName : courseName?.resourceType == "course" ? courseName?.courseName : courseName?.name}</div>
            </div>
            <div className="" style={{ width: "100%", height: "100%" }}>
                {videoType == 'link' ?
                    <YTPlayer keyboardControls={true}  videoUrl={`${Api.dlS3Url}${previewLink}`}  /> :
                    <ReactPlayerHoc
                        ref={videoRef}
                        keyboardControls={true}
                        url={`${Api.dlS3Url}${previewLink}`}
                        playing={isPlaying}
                        controls
                        width="100%"
                        height="100%"
                    />}
            </div>
        </Modal>
    );
}

export default PreviewCourse
