import React, { useEffect, useState } from 'react'
import { Result } from 'antd'
import { Button } from 'components/Button'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { connect } from 'react-redux';
import { postPaymentDetails } from 'actions/profileNew';
import { showMenu } from 'actions/layout';
import { postCartPayment } from 'actions/aulasMarket';

const PaymentSuccess = ({ postPaymentDetails, paymentDetails, postCartPayment, paymentStatus }) => {

        const urlParams = new URLSearchParams(window.location.search);
        const orderIdFromUrl = urlParams.get('orderId');
        const transactionIdFromUrl = urlParams.get('id');
        const userId = urlParams.get('userId');
        const fromMarket = urlParams.get('fromMarket');

        const [gotData, setData] = useState(null);

        console.log({ paymentDetails });
        console.log({ userId });

        useEffect(() => {
                let params
                if (!fromMarket) {
                        params = {
                                orderId: orderIdFromUrl,
                                checkout: true,
                                type: '',
                                id: null,
                                // promo: 
                        }
                } else {
                        params = {
                                orderId: orderIdFromUrl
                        }
                }

                showMenu(false);
                if (fromMarket) {
                        postCartPayment(params, () => { })
                        setData(paymentStatus)
                } else {
                        postPaymentDetails(orderIdFromUrl, transactionIdFromUrl, userId);
                        setData(paymentDetails);
                }
        }, []);

        return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        {gotData !== undefined ?
                                paymentDetails?.txStatus == 'SUCCESS' ?
                                        <>
                                                {paymentDetails?.referenceId && <Result status='success' title="Successfully paid the fee" subTitle={`Reference ID: ${paymentDetails?.referenceId}`}></Result>}

                                                <div>
                                                        <Button onClick={() => { window.close() }} type='primary' style={{ padding: 0 }} icon={<CheckCircleOutlined />}>DONE</Button>
                                                </div>
                                        </>
                                        :
                                        <>
                                                {paymentDetails?.referenceId && <Result status='error' title="Transaction failed" subTitle={`Reference ID: ${paymentDetails?.referenceId}`}></Result>}
                                                <div>
                                                        <Button onClick={() => { window.close() }} type='primary' style={{ padding: 0 }} icon={<CloseCircleOutlined />}>TRY AGAIN</Button>
                                                </div>
                                        </>

                                : <div style={{ color: '#191919', fontSize: 16, fontFamily: 'roboto' }}>Loading...</div>
                        }
                </div>
        )
};

const mapStateToProps = (state) => {
        const { paymentDetails } = state.profileNew;
        const { paymentStatus } = state.aulasMarket
        return { paymentDetails, paymentStatus };
};

const mapDispatchToProps = (dispatch) => ({
        postPaymentDetails: (orderId, id) => dispatch(postPaymentDetails(orderId, id)),
        postCartPayment: (params, callback) => dispatch(postCartPayment(params, callback)),
        showMenu: bool => dispatch(showMenu(bool))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);