import moment from 'moment';
import {
  SHOW_MODAl, UPDATE_TIME_TABLE, TOGGLE_POST_LOADER,UPDATE_SCHEDULE_INPUT, HIDE_MODAl,UPDATE_TEACHER_LIST,
  UPDATE_SUB_CLASS, UPDATE_SUB_CLASS_LOADER, TOGGLE_FETCH_SCHEDULE_LOADER, UPDATE_FILTER_PARAMS, RESET_FILTER_PARAMS, TOGGLE_SEARCH_LOADER,
  RESRE_SCHEDULE_PARAMS
} from '../actions/calendar';

const initialState = {
  schedules: [],
  standards: [],
  classModal: false,
  subjects: [],
  togglePostLoader: false,
  singleSchedule: {
      selectedClass: '-1',
      selectedDate: moment().format('YYYY-MM-DD'),
      startTime: null,
      endTime: null,
      selectedClassNameSec: 'All Classes',
      selectedClasses: [],
      selectedDays: [],
      selectedTeacher: "",
      selectedTeacherId: -1,
      standardId: "-1",
      mode: null
    } ,
  selectedClass:'-1',
  subClasses: [],
  subClassesLoader: false,
  selectedClasses: [],
  fetchScheduleLoader: false,
  filterParams: {
    date: moment().format('YYYY-MM-DD'),
    teacherName: '',
    teacherId: null,
    standardId: "-1",
  },
  userSearchLoader:  false,
  modes: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESRE_SCHEDULE_PARAMS:
      return {
        ...state,
        singleSchedule: {...initialState.singleSchedule}
      };
    case RESET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: {...initialState.filterParams}
      };
    case UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: {...state.filterParams, ...action.filterParams}
      };
    case SHOW_MODAl:
      return {
        ...state,
        classModal: true,
        singleSchedule: typeof(action.data) != "undefined" ? action.data : {...state.singleSchedule}
      };
    case TOGGLE_FETCH_SCHEDULE_LOADER:
      return {
        ...state,
        fetchScheduleLoader: action.bool
      }
    case TOGGLE_SEARCH_LOADER:
      return {
        ...state,
        userSearchLoader: action.value,
      };
    case HIDE_MODAl:
      return {
        ...state,
        classModal: false,
      };
    case TOGGLE_POST_LOADER:
      return {
        ...state,
        togglePostLoader: action.value
      };
    case UPDATE_TIME_TABLE:
      return {
        ...state,
        schedules: action.schedules,
        standards: action.standards,
        subjects: action.subjects,
        teachers: action.teachers,
        modes: action.modes
      };
    case UPDATE_SCHEDULE_INPUT:
        return {
          ...state,
          singleSchedule: {...state.singleSchedule, ...action.singleSchedule}
        };
    case UPDATE_TEACHER_LIST:
        return {
          ...state,
          teachers: action.teachers
        }
    case UPDATE_SUB_CLASS:
        return {
          ...state,
          subClasses: action.classes
        };
    case UPDATE_SUB_CLASS_LOADER:
        return {
          ...state,
          subClassesLoader: action.status
        };
    default:
      return state;
  }
};

export default reducer;
