import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import { changeTestStatusModal, clearGenerateQuesFields, createTestModal, saveEntireEvaluatedTest, setFromImportListView, showGenerateQuesListView, updateListViewType } from 'actions/testAdmin';
import { FaRegEdit, FaRegClone } from "react-icons/fa";
import { MdPublish, MdKeyboardBackspace } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import '../styles.css';
import { Color, Notification } from 'services';
import { Col, Row , Typography} from 'antd';
import user from 'pages/user/user';
import { setFromClassroom } from 'actions/classRoom';

const { TabPane } = Tabs;
const { confirm } = Modal;
const { Title } = Typography;

// const goBackHandler =()=>{
//   history.goBack()
//   clearGenerateQuesFields()
// }



function TopNavigationColorHeader ({submittedTestsDetails,
                                    submittedTestsUsers,
                                    submittedTestsStudentsId, 
                                    history, type, generateQuesObj, 
                                    changeTestStatusModal, createTestModal,
                                    listView=false, listViewType, userType, user,
                                    saveEntireEvaluatedTest, selectedCardType, 
                                    totalQuestions, fromCreateTest,
                                    location, updateListViewType, submittedTestsByStudentId,
                                     showGenerateQuesListView,clearGenerateQuesFields, setFromImportListView, isFromImportListView, setFromClassroom}) {
    useEffect(()=>{
      setFromClassroom(false)
    },[])
    return (
      <div className='r-c-sb p-20' style={{height:60, background:'#F6F4FE'}}>
        <div className='r-ac'>
          <MdKeyboardBackspace onClick={() => {
            if(fromCreateTest == "fromClassroom" || location?.state?.key == "fromClassroom"){
              history.push({pathname:"/test-std-to-subjects-screen", state:{key:"fromClassroom"}});
            }else if(fromCreateTest || location?.state?.key == '2' || isFromImportListView || location?.state?.key == "fromEditTest"){
              history.push("/test");
              setFromImportListView(false)
            }else{
              history.goBack();
            }
            clearGenerateQuesFields()}} className='text-md color-black m-r-10 cursor-pointer' />

          {type === 'studentAnswer'
          ?
            <div>
              <div className='text-sm m-l-10 bold-600 color-black'>
                {
                  submittedTestsUsers?.map((item, index) => (
                    item.userId == submittedTestsStudentsId ? item.username : ""
                  ))
                }
              </div>
              <div className='text-xs m-l-10 color-black'>{` ${submittedTestsDetails.title} `}</div>
            </div>
          :
          location?.state?.key == '2' || location?.state?.key == "fromClassroom" ?
          <div className='text-sm bold-600 color-black'>{generateQuesObj?.test?.title}</div>
          :
            <div className='text-sm bold-600 color-black'>{generateQuesObj.title}</div>
          }
          {((type === 'studentAnswer')||type==='no-edit'||(generateQuesObj?.test?.status==='Published'&&userType!='admin'))
          ?
            null
          : location?.state?.key == '2' || location?.state?.key == "fromClassroom"
            ?
            <AiTwotoneEdit onClick={() => {              
              // universalLoader('EDIT_DETAILS_LOADER', 'editDetailsLoader', true);
              updateListViewType('edit');
              showGenerateQuesListView(()=>{                
                  // universalLoader('EDIT_DETAILS_LOADER', 'editDetailsLoader', false);
                  createTestModal("test-edit", user, 'navigation');
                  history.push({
                    pathname:"/edit-test",
                    state:{
                      key:location?.state?.key == "fromClassroom" ? "fromClassroom" : "fromImportListViewEdit"
                    }});
                  setFromImportListView(true)
              }, "editImportListView");
            }} className='text-md a2grey m-l-20 cursor-pointer' />
            :
              <AiTwotoneEdit onClick={() => {
               
                // universalLoader('EDIT_DETAILS_LOADER', 'editDetailsLoader', true);
                updateListViewType('edit');
                showGenerateQuesListView(()=>{
                  
                    // universalLoader('EDIT_DETAILS_LOADER', 'editDetailsLoader', false);
                    createTestModal("test-edit", user, 'navigation');
                    history.push("/edit-test")
                }, "navigation");
              }} className='text-md a2grey m-l-20 cursor-pointer' />
          }
        </div>

        {type === 'studentAnswer'
          ? 
            submittedTestsDetails.testType!="Objective" && (submittedTestsByStudentId.resultsPublished===false|| userType==='admin')
            ?
            <Button onClick={() => saveEntireEvaluatedTest(() => {
              history.goBack()
            })} className='blueBtnWrap radius-100 m-l-20 text-xs'>SAVE TEST SCORE</Button>
            :
            null
          :
            <div className='r-ac'>
              {((type=='no-edit')||(listViewType=='no-edit'))
              ?
                selectedCardType=='adminlive'
                  ?
                  <img src={require('Assets/live-dot.gif').default} width={50} height={22} alt=""/>
                  :
                  null
              :
                <>
                  {location?.state?.key == '2' || location?.state?.key == "fromClassroom" || (generateQuesObj.status==="Draft"||generateQuesObj.status==="Feedback") && (userType=="dataOperator"||(userType==='admin'&&generateQuesObj.authorId===user.id&&generateQuesObj.authorId!==generateQuesObj.teacherId))
                  ?
                  <Button disabled={totalQuestions === 0 ? true : false} onClick={() => {
                    if (totalQuestions>0){
                    changeTestStatusModal('Review')
                    }
                    else{
                      Notification.error("error", "Cannot send for review without any questions created")}
                  }} className='orangeBtnWrap radius-100 text-xs'>SEND FOR REVIEW</Button>
                  :null}
                  {(generateQuesObj.status==='Review' && (userType=="teacher"||(userType=='admin'&&user.id===generateQuesObj.teacherId&&user.id!==generateQuesObj.authorId))) 
                  ?
                  <Button onClick={() => changeTestStatusModal('Feedback')} className='orangeBtnWrap radius-100 text-xs'>SEND FEEDBACK</Button>
                  :null
                  }
                  {(userType==='teacher'&&(generateQuesObj.status==='Draft'||generateQuesObj.status==='Feedback'||generateQuesObj.status==='Review'))
                  ?
                    <Button onClick={() => {
                      if (totalQuestions>0){
                        changeTestStatusModal('Ready')
                      }
                      else{
                        Notification.error("error", "Cannot Mark as ready without any questions created")
                      }
                      }} className='blueBtnWrap radius-100 m-l-20 text-xs'> Mark as Ready </Button>
                  :
                  null
                  }
                  {(userType=="admin"&&generateQuesObj.status!=='Published')
                  ?
                  <Button disabled={totalQuestions === 0 ? true : false} 
                  onClick={() => {
                    changeTestStatusModal('Published');
                    if(location?.state?.key == "fromClassroom"){
                      setFromClassroom(true);
                    }
                  }} className='blueBtnWrap radius-100 m-l-20 text-xs'>PUBLISH NOW</Button>
                  :
                  null
                  }
                </>
              }
            </div>
        }

      </div>
  );
}


const mapStateToProps = state => {
  const {generateQuesObj, listViewType, submittedTestsDetails, submittedTestsUsers, submittedTestsStudentsId, userType,
        selectedCardType, totalQuestions, submittedTestsByStudentId, isFromImportListView } = state.testAdmin;
  const {user} = state.session;
  return {generateQuesObj, listViewType, submittedTestsDetails, submittedTestsUsers, submittedTestsStudentsId, userType, user,
        selectedCardType, totalQuestions, submittedTestsByStudentId, isFromImportListView};
};

const mapDispatchToProps = dispatch => ({
  changeTestStatusModal: (name) => dispatch(changeTestStatusModal(name)),
  createTestModal: (name, user, source) => dispatch(createTestModal(name, user, source)),
  saveEntireEvaluatedTest: (callback) => dispatch(saveEntireEvaluatedTest(callback)),
  updateListViewType: (type)=> dispatch(updateListViewType(type)),
  showGenerateQuesListView: (callback, type) => dispatch(showGenerateQuesListView(callback, type)),
  clearGenerateQuesFields: (val) => dispatch(clearGenerateQuesFields(val)),
  setFromImportListView : (val) => dispatch(setFromImportListView(val)),
  setFromClassroom:(val) => dispatch(setFromClassroom(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigationColorHeader);