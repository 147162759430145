import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/plots";


const TeachersPieChart = ({ usersDataContainer, usersNameContainer }) => {
  const data = [
    {
      type: "Present",
      value: usersDataContainer?.todaysAttendance?.present,
    },
    {
      type: "Absent",
      value: usersDataContainer?.todaysAttendance?.absent,
    },
    {
      type: "Leave",
      value: usersDataContainer?.todaysAttendance?.leaveCount,
    },
  ];
  const config = {
    appendPadding: 8,
    data,
    angleField: "value",
    colorField: "type",
    color: ["#28DF99", "#FF414D", "#1089FF"],
    radius: 0.7,
    innerRadius: 0.7,
    label: {
      type: "inner",
      offset: "-50%",
      content: "",
      autoFit: true,
      legend: false,
      style: {
        fill: '#fff',
        fontSize: 14,
        textAlign: 'center',
      },
    },
    // interactions: [
    //   {
    //     type: "element-selected",
    //   },
    //   {
    //     type: "element-active",
    //   },
    // ],

    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: 8,
        },
        customHtml: function customHtml() {
          return (
            <div>
              <div style={{ fontSize: "20px", fontWeight: "700" }}> {`${usersDataContainer?.todaysAttendance?.total ? usersDataContainer?.todaysAttendance?.total : 0}`}</div>
              <div style={{ marginTop: "5px", fontSize: "15px", fontWeight: "500", marginLeft: "10px" }}>
                Total active {usersNameContainer === "Teacher" ? "teachers" :
                  usersNameContainer === "Operation" ? "operations" :
                    usersNameContainer === "Data Operator" ? "data operators" : null}  </div>
            </div>)
        }

        // content: `${usersDataContainer?.todaysAttendance?.total}`+" "+"Total"+" "+`${usersNameContainer==="Teacher"?"teachers":
        //                                                                             usersNameContainer==="Operation"?"operations":
        //                                                                             usersNameContainer==="Data Operator"?"data operators":null}`
      },
    },
  };

  return <Pie {...config} />;
};

export default TeachersPieChart;
