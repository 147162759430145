import { ArrowLeftOutlined } from "@ant-design/icons";
import { PageHeader, Pagination, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import { useHistory } from "react-router";
import RequestUserCard from "./requestUserCard";
import image2 from "Assets/user-management/add-students.svg";
import { connect } from "react-redux";
import {
  getUserRequests,
  setName,
  setRole,
  setTab,
  setUserDetails,
  setUserName,
} from "actions/userManagement";
import { IMG_URL } from "../../env.json";
import PageDataLoader from "components/pageDataLoader";
import { setFromNotification } from "actions/notification";

const goBack = (history) => {
  history.goBack();
};

const { TabPane } = Tabs;

function AllUsersRequest({
  setTab,
  dashBoardRole,
  getUserRequests,
  usersByStats,
  setRole,
  setUserDetails,
  setUserName,
  setName,
  tab,
  role,
  setFromNotification
}) {
  const [pageLoader, setPageLoader] = useState(false);
  const handleSearch = (search) => {
    setName(search);
    setPageLoader(true);
    getUserRequests(() => setPageLoader(false));
  };
  const handleApprove = (setTab, history, item) => {
    setUserDetails(item);
    setTab("Pending");
    // history.push("/user-management/all-requests/approval");
  };
  const handleReject = (setTab, history, item) => {
    setUserDetails(item);
    setTab("Rejected");
    // history.push("/user-management/all-requests/approval");
  };

  function handleChangeRole(value) {
    setRole(value);
    setPageLoader(true);
    getUserRequests(() => setPageLoader(false));
  }
  function callback(key) {
    if (key === "1") {
      setTab("Pending");
      setPageLoader(true);
      getUserRequests(() => setPageLoader(false));
    }
    if (key === "2") {
      setTab("Rejected");
      setPageLoader(true);
      getUserRequests(() => setPageLoader(false));
    }
  }
  useEffect(() => {
    setTab("Pending");
    setName(null);
    setRole(null);
    setUserName(null);
    setPageLoader(true);
    getUserRequests(() => setPageLoader(false));
  }, []);
  const history = useHistory();
  const showMoreUsers = (e) => {
    setPageLoader(true);
    getUserRequests(() => setPageLoader(false), e);
  };
  const { Option } = Select;
  // console.log("dashBoardRole", dashBoardRole);

  return (
    <div style={{ marginTop: "-25px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <div style={{ marginLeft: "-25px" }}>
          <PageHeader
            title={
              <div style={{ fontWeight: "bold",fontSize:"25px"}}>
                Registration request(s){" "}
                {tab == "pending" &&
                usersByStats?.meta?.totalItems !== undefined
                  ? `(${usersByStats?.meta?.totalItems})`
                  : null}
              </div>
            }
            onBack={() => goBack(history)}
            backIcon={<ArrowLeftOutlined />}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "70%",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              height: "40px",
              display: "flex",
              flexDirection: "row",
              marginRight: "10%",
              justifyContent: "space-between",
              alignItems: "center",
              border: "#E6E6E6 solid 1px",
              borderRadius: "5px",
            }}
          >
            <Select
              className="inputSelecter"
              style={{ width: 200, border: "none" }}
              placeholder="All"
              onChange={handleChangeRole}
              bordered={false}
              removeIcon={true}
              dropdownMatchSelectWidth={true}
              allowClear={true}
              value={role}
            >
              <Option value={null}>
                <h3>All</h3>
              </Option>
              <Option value={"Student"}>
                <h3>Students</h3>
              </Option>
              <Option value={"Teacher"}>
                <h3>Teacher</h3>
              </Option>
              <Option value={"DataOperator"}>
                <h3>Data operator</h3>
              </Option>
              <Option value="Operation">
                <h3>Operation</h3>
              </Option>
            </Select>
          </div>
          {/* <div className="searchByNameinpt" style={{ border: "1px solid rgb(230, 230, 230)", borderRadius: "5px", width: "400px" }} > */}
          <Input.Search
            size={"large"}
            type="text"
            placeholder="Search By Name / Phone No."
            style={{ border: "0px", fontSize: "1em", width: "300px" }}
            onSearch={(e) => handleSearch(e)}
            onChange={() => {}}
            // value={name}
            allowClear={true}
          />
          {/* </div> */}
        </div>
      </div>
      {/* <p
        style={{
          color: "#636363",
          fontSize: "15px",
          marginLeft: "29px",
          marginTop: "-30px",
        }}
      >
        Manual approval
        <span
          style={{
            color: "#1089FF",
            fontSize: "13px",
            fontWeight: "500",
            marginLeft: "20px",
            cursor: "pointer",
          }}
        >
          CHANGE SETTINGS
        </span>
      </p> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "85vh",
          marginTop:"10px",
          justifyContent: "space-between",
        }}
      >
        <Tabs defaultActiveKey="1" onChange={callback} className="tabsClass">
          <TabPane tab="PENDING" key="1">
            <div
              style={{
                overflowY: "auto",
                height: "70vh",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {usersByStats?.items?.map((item) => (
                  <div
                    onClick={() => {handleApprove(setTab, history, item),setFromNotification(false)}}
                    style={{
                      width: "340px",
                      margin: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <RequestUserCard
                      createdAt={item?.createdAt}
                      id={item?.id}
                      name={item?.name}
                      role={item?.role[0]}
                      // phoneNo={item?.phone}
                      avatar={`${IMG_URL}/${
                        item?.details?.image
                          ? item?.details?.image
                          : "default.png"
                      }`}
                      border={true}
                      item={item}
                    />
                  </div>
                ))}
                {usersByStats?.items?.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "60vh",
                      // display:
                      //   usersByStats?.items?.length !== 0 ? "none" : "view",
                    }}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "-40px",
                      }}
                    >
                      <img
                        style={{ width: "50%", height: "50%" }}
                        src={image2}
                      ></img>
                      <h3 style={{ marginTop: "20px" }}>
                        No Registration Request Found!
                      </h3>
                      <h3>Try again later</h3>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </TabPane>
          <TabPane tab="REJECTED" key="2">
            <div
              style={{
                overflowY: "auto",
                height: "70vh",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {usersByStats?.items?.map((item) => (
                  <div
                    onClick={() => {handleReject(setTab, history, item),setFromNotification(false)}}
                    style={{
                      width: "340px",
                      margin: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <RequestUserCard
                      createdAt={item?.createdAt}
                      name={item?.name}
                      // phoneNo={item?.phone}
                      id={item?.id}
                      role={item?.role[0]}
                      avatar={`${IMG_URL}/${
                        item?.details?.image
                          ? item?.details?.image
                          : "default.png"
                      }`}
                      border={true}
                      rejected={true}
                      item={item}
                    />
                  </div>
                ))}
                {usersByStats?.items?.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "60vh",
                      // display:
                      //   usersByStats?.items?.length !== 0 ? "none" : "view",
                    }}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "-40px",
                      }}
                    >
                      <img
                        style={{ width: "50%", height: "50%" }}
                        src={image2}
                      ></img>
                      <h3 style={{ marginTop: "20px" }}>
                        No Registration Request Found!
                      </h3>
                      <h3>Try again later</h3>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </TabPane>
        </Tabs>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            // style={{ right: "100", position: "relative", marginLeft: "70%" }}
            current={usersByStats?.meta?.currentPage}
            defaultCurrent={usersByStats?.meta?.currentPage}
            total={usersByStats?.meta?.totalPages * 10}
            onChange={showMoreUsers}
          />
        </div>
      </div>
      <PageDataLoader visible={pageLoader} />
    </div>
  );
}

// export default AllUsersRequest

const mapStateToProps = (state) => {
  const { tab, dashBoardRole, usersByStats, role } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    usersByStats,
    role,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTab: (m) => dispatch(setTab(m)),
  getUserRequests: (callback, page) =>
    dispatch(getUserRequests(callback, page)),
  setRole: (role) => dispatch(setRole(role)),
  setUserDetails: (details) => dispatch(setUserDetails(details)),
  setUserName: (search) => dispatch(setUserName(search)),
  setName: (search) => dispatch(setName(search)),
  setFromNotification:(val) => dispatch(setFromNotification(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllUsersRequest);
