import PageHeader from 'components/pageHeader';
import React, { useState, useEffect } from 'react';
import { Button } from 'components/Button';
import { useHistory } from 'react-router';
import PageDataLoader from 'components/pageDataLoader';
import { Col, Row, AutoComplete, Checkbox, Input, Typography } from 'antd';
import { AiTwotoneFolder } from 'react-icons/ai';
import { FaChromecast } from 'react-icons/fa';
import { FiRadio } from 'react-icons/fi';
import { SquareSubIcon } from 'components/subIcon';
import moment from 'moment/moment';
import PermanentlyDeleteConfirm from './modals/permanentlyDeleteConfirm';
import { SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import {
        getMoreContent, getUserAllData, searchUsers, setTranseferDataValue, transferData,
        permanentlyDelete
} from 'actions/userManagementV2';
import TransferModal from './modals/transferModal';
import { IoMdChatbubbles } from 'react-icons/io';
const { Text } = Typography

const PartTestIcon = require("Assets/test/ic-part-test.svg").default;
const ChapterTestIcon = require('Assets/test/ic-chapter-test.svg').default;
const FullTestIcon = require('Assets/test/ic-full-test.svg').default;
const LectureNotes = require('Assets/ic-dl-lecture-notes.svg').default
const QuestionBank = require('Assets/ic-dl-lecture-question-banks.svg').default
const Videos = require('Assets/ic-dl-lecture-videos.svg').default

const dataTypes = (userAllData) => {
        return [
                userAllData.scheduleCount ? {
                        label: "Schedule"
                } : undefined,
                userAllData.liveTestCount ? {
                        label: "Live test"
                } : undefined,
                userAllData.practiceTestCount ? {
                        label: "Practice test"
                } : undefined,
                userAllData.digitalResourcesCount ? {
                        label: "Digital library"
                } : undefined
        ]
}

const detailDataTypes = (userAllData) => [
        {
                label: "Schedule",
                iconColor: "#9876E6",
                iconBackGround: "#F6F4FE",
                count: `${userAllData?.schedules?.meta?.totalItems || 0} schedule(s)`,
                itemsKey: "schedules",
                noOfItems: userAllData?.schedules?.meta?.totalItems || 0
        },
        {
                label: "Live test",
                iconColor: "#1089FF",
                iconBackGround: "#E7F3FF",
                itemsKey: "liveTests",
                count: `${userAllData?.liveTests?.meta?.totalItems || 0} live test(s)`,
                noOfItems: userAllData?.liveTests?.meta?.totalItems || 0
        },
        {
                label: "Practice test",
                iconColor: "#FFA931",
                iconBackGround: "#FFF4D5",
                itemsKey: "practiceTests",
                count: `${userAllData?.practiceTests?.meta?.totalItems || 0} practice test(s)`,
                noOfItems: userAllData?.practiceTests?.meta?.totalItems || 0
        },
        {
                label: "Lecture notes",
                iconColor: "#FF414D",
                iconBackGround: "#FFE4D9",
                itemsKey: "lectureNotesResources",
                count: `${userAllData?.lectureNotesResources?.meta?.totalItems || 0} lecture notes`,
                noOfItems: userAllData?.lectureNotesResources?.meta?.totalItems || 0
        },
        {
                label: "Videos",
                iconColor: "#28DF99",
                iconBackGround: "#E9FCF5",
                itemsKey: "videoResources",
                count: `${userAllData?.videoResources?.meta?.totalItems || 0} video(s)`,
                noOfItems: userAllData?.videoResources?.meta?.totalItems || 0
        },
        {
                label: "Question banks",
                iconColor: "#9876E6",
                iconBackGround: "#F6F4FE",
                itemsKey: "questionBankResources",
                count: `${userAllData?.questionBankResources?.meta?.totalItems || 0} question bank(s)`,
                noOfItems: userAllData?.questionBankResources?.meta?.totalItems || 0
        },
]

const IndividualSchedules = ({ data, index, handleCheck, temp }) => (
        <div className='m-t-10' key={index}>
                <div className='r-jsb'>
                        <div className='r-ac' style={{ alignItems: 'center', width: "100%" }}>
                                <div>
                                        <Checkbox checked={temp?.includes(data?.id)} onChange={(e) => handleCheck(data?.id)} />
                                </div>
                                <div className='m-l-5'>
                                        <SquareSubIcon icon={data.subject.iconPath} />
                                </div>
                                <div className='m-l-10' style={{ width: "100%" }}>
                                        <div className='r-jsb' style={{ width: "90%" }}>
                                                <div style={{ fontSize: 16, fontWeight: 500, width: "50%", whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>{data.subject.name}</div>
                                                <div className='m-t-5' style={{ fontSize: 12, fontWeight: 600 }}>
                                                        {
                                                                data.mode === "Broadcast" ?
                                                                        <div className="r-c-c" style={{ background: "#FFF4D5", color: "#FFA931", padding: "0px 2px", bordeRadius: 2 }}>
                                                                                <FaChromecast /> &nbsp;BROADCAST
                                                                        </div>
                                                                        : data.mode === "Conference" ?
                                                                                <div className="r-c-c" style={{ background: "#F6F4FE", color: "#594099", padding: "0px 2px", bordeRadius: 2 }}>
                                                                                        <FiRadio /> &nbsp;CONFERENCE
                                                                                </div>
                                                                                : data?.mode === 'Offline' ?
                                                                                        <div style={{ width: 80, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '4px', padding: '2px' }}>
                                                                                                <IoMdChatbubbles style={{ color: '#28DF99', textAlign: 'center' }} />
                                                                                                <Text style={{ fontSize: '12px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                                                                                        </div>
                                                                                        : null}
                                                </div>
                                        </div>

                                        <div style={{ color: "#636363", width: "80%", whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                                                {data.standards.map((std, index) => <span>{index !== 0 ? "|" : ""} {std.std} • {std.section} </span>)}
                                        </div>
                                        <div style={{ fontSize: 12, color: "#AEAEAE", width: "80%", whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                                                {moment(data.startTime, "HH:mm:ss").format("HH:mm")} - {moment(data.endTime, "HH:mm:ss").format("HH:mm")} • &nbsp;
                                                {data.weekdays.map((day, i) => <>{i > 0 ? ',' : ''}{day[0].toUpperCase()}{day[1]}{day[2]}</>)}
                                        </div>

                                        <div style={{ fontSize: 12, color: "#AEAEAE", width: "80%", whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                                                {moment(data.startDate)?.format("DD-MM-YYYY")} - {moment(data?.endDate).format("DD-MM-YYYY")} 
                                                
                                        </div>
                                </div>
                        </div>

                </div>
                <div style={{ width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 15, marginTop: 20 }} />
        </div>
)

const IndividualTests = ({ data, index, handleCheck, temp }) => (
        <div className='m-t-10' key={index}>
                <div className='r-jsb'>
                        <div className='r-ac' style={{ alignItems: 'center', width: "100%" }}>
                                <div>
                                        <Checkbox checked={temp?.includes(data?.id)} onChange={(e) => handleCheck(data?.id)} />
                                </div>
                                <div className='m-l-5'>
                                        <img width={60} src={data.syllabus === "Part Test" ? PartTestIcon : data.syllabus === "Chapter Test" ? ChapterTestIcon : FullTestIcon} alt="" />
                                </div>
                                <div className='m-l-10' style={{ width: "100%" }}>
                                        <div className='r-jsb' style={{ width: "100%" }}>
                                                <div style={{ fontSize: 16, fontWeight: 500, width: "50%", whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>{data.title}</div>
                                        </div>

                                        <div style={{ color: "#636363", width: "80%", whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                                                {data?.syllabus} • {data.subject.map((sub, i) => <>{i > 0 ? ',' : ''}{sub.name}</>)}
                                        </div>
                                        <div style={{ fontSize: 12, color: "#AEAEAE", width: "80%", whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                                                {data.standard.std} - {data.standard.section}
                                        </div>
                                </div>
                        </div>

                </div>
                <div style={{ width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 15, marginTop: 20 }} />
        </div>
)

const IndividualResource = ({ data, index, handleCheck, temp }) => (
        <div className='m-t-10' key={index}>
                <div className='r-jsb'>
                        <div className='r-ac' style={{ alignItems: 'center', width: "100%" }}>
                                <div>
                                        <Checkbox checked={temp?.includes(data?.id)} onChange={(e) => handleCheck(data?.id)} />
                                </div>
                                <div className='m-l-5'>
                                        <img width={60} src={data.contentType === "lecture-notes" ? LectureNotes : data.contentType === "video" ? Videos : QuestionBank} alt="" />
                                </div>
                                <div className='m-l-10' style={{ width: "100%" }}>
                                        <div className='r-jsb' style={{ width: "100%" }}>
                                                <div style={{ fontSize: 16, fontWeight: 500, width: "50%", whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>{data.fileName}</div>
                                        </div>

                                        <div style={{ color: "#636363", width: "80%", whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                                                {data.topicName} •  {data.chapterName}
                                        </div>
                                        <div style={{ fontSize: 12, color: "#AEAEAE", width: "80%", whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                                                {data.subjectName} {data.class} - {data.section}
                                        </div>
                                </div>
                        </div>

                </div>
                <div style={{ width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 15, marginTop: 20 }} />
        </div>
)

const ListOfContents = ({ selectedItem, handleLoadMore, buttonLoader, filter, userAllData, handleCheck, temp, handleSelectAll }) => {

        const checkAll = temp?.length === userAllData[selectedItem?.itemsKey]?.items?.length;

        return (
                <div className='p-20' style={{ height: "100%", overflowY: 'auto', overflowX: 'hidden' }}>
                        <div style={{ fontSize: 18, fontWeight: 500 }}>{selectedItem.label}<span style={{ fontWeight: 400, color: "#AEAEAE" }}>({selectedItem.noOfItems})</span></div>
                        {userAllData[selectedItem?.itemsKey]?.items?.length !== 0 ?
                                <div>
                                        <Checkbox checked={checkAll} onChange={(e) => handleSelectAll()} /><span className='m-l-5' style={{ color: '#636363', fontSize: 12, fontWeight: 500 }}>SELECT ALL</span>
                                </div>
                                : null}
                        {userAllData[selectedItem.itemsKey]?.items.map((item, index) => {
                                if (selectedItem.label === "Schedule") {
                                        return (
                                                <IndividualSchedules data={item} index={index} handleCheck={handleCheck} temp={temp} />
                                        )
                                } else if (selectedItem.label === "Live test" || selectedItem.label === "Practice test") {
                                        return <IndividualTests data={item} index={index} handleCheck={handleCheck} temp={temp} />
                                } else {
                                        return <IndividualResource data={item} index={index} handleCheck={handleCheck} temp={temp} />
                                }
                        })}
                        {selectedItem.noOfItems > 3 && userAllData[selectedItem.itemsKey].items.length < selectedItem.noOfItems ?
                                <div className='r-c-c'>
                                        <Button type="link" onClick={() => handleLoadMore(selectedItem.label)} loading={buttonLoader}>LOAD MORE</Button>
                                </div> : null
                        }
                </div>
        )
}

const TransferData = ({ userToTransferData, getUserAllData, userAllData, searchUsers, searchedTeachers, getMoreContent, transferData, storeTransferValue, setTranseferDataValue, permanentlyDelete, getDeletingUserData }) => {

        const history = useHistory();

        const [temp, setTemp] = useState([]);
        const [dataType, setDataType] = useState(null);
        const [sameForAll, setSameForAll] = useState(false);
        const [pageLoader, togglePageLoader] = useState(false);
        const [selectedItem, setSelectedItem] = useState(null);
        const [buttonLoader, setButtonLoader] = useState(false);
        const [agreeToDelete, setAgreeToDelete] = useState(false);
        const [selectedTeachers, setSelectedTeachers] = useState([]);
        const [searchedTeacherNames, setSearchedTeachers] = useState([]);
        const [selectedItemIndex, setSelectedItemIndex] = useState(null);
        const [transferDataLoader, setTransferDataLoader] = useState(false);
        const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
        const [searchLoader, setSearchLoader] = useState([false, false, false, false]);
        const [filter, setFilter] = useState({
                schedule: { page: 1, type: "schedule" },
                liveTest: { page: 1, type: "liveTest" },
                practiceTest: { page: 1, type: "practiceTest" },
                video: { page: 1, type: "" },
                "lecture-notes": { page: 1, type: "" },
                "question-bank": { page: 1, type: "" },
        });
        const [transferModal, setTransferModal] = useState(false);

        useEffect(() => {
                setSelectedItem(null)
        }, []);

        const successCallBack = () => {
                setTemp([]);
                togglePageLoader(true);
                getUserAllData({ id: userToTransferData?.id }, () => togglePageLoader(false))
        }

        useEffect(() => {
                successCallBack();
        }, [])

        const handleCheck = (id) => {
                if (temp.includes(id)) {
                        setTemp(temp.filter((item) => item != id));
                } else {
                        setTemp([...temp, id]);
                };
        };

        const handleSelectAll = () => {
                if (temp?.length === userAllData[selectedItem.itemsKey]?.items?.length) {
                        setTemp([]);
                } else {
                        let id = [];
                        userAllData[selectedItem.itemsKey]?.items?.forEach((item) => {
                                id.push(item?.id)
                        });
                        setTemp(id);
                };
        };


        const handleTypeClick = (item, index) => {

                const type = item?.label
                const dataType = type === "Schedule" ? 'schedule' : type === "Live test" ? 'liveTest' : type === "Practice test" ? 'practiceTest' : type === "Videos" ? 'video' : type === "Lecture notes" ? 'lecture-notes' : type === "Question banks" ? 'question-bank' : '';

                setDataType(dataType)

                setTemp([]);
                setSelectedItem({ ...item });
                setSelectedItemIndex(index);
        };

        const onSearchChange = (e, index) => {
                searchedTeacherNames[index] = e;
                setSearchedTeachers([...searchedTeacherNames])
        };

        const handleSearch = (val, index) => {
                searchLoader[index] = true;
                setSearchLoader([...searchLoader])
                searchUsers(val, () => handleSearchCallback(index))
        };

        const handleSearchCallback = (index) => {
                setSearchLoader([false, false, false, false]);
                // options[index] = searchedTeachers;
                // setOptions([...options]);
        };

        const handleTeacherSelect = (value, object, index) => {
                if (sameForAll) {
                        selectedTeachers[0] = object?.id;
                        selectedTeachers[1] = object?.id;
                        selectedTeachers[2] = object?.id;
                        selectedTeachers[3] = object?.id;
                        searchedTeacherNames[0] = value;
                        searchedTeacherNames[1] = value;
                        searchedTeacherNames[2] = value;
                        searchedTeacherNames[3] = value;
                        setSearchedTeachers([...searchedTeacherNames])
                } else {
                        selectedTeachers[index] = object?.id;
                }
                setSelectedTeachers([...selectedTeachers])
        };

        const handleLoadMore = () => {
                setFilter({ ...filter, dataType: { page: filter[dataType].page + 1 } })
                setButtonLoader(true);
                getMoreContent({ limit: 10, page: filter[dataType].page + 1, type: dataType, userId: userToTransferData.id }, () => setButtonLoader(false))
        };

        const deleteConfirm = (callback) => {
                let type = dataType
                if (type == 'video' || type == 'lecture-notes' || type == 'question-bank')
                        type = 'digitalResource'
                permanentlyDelete({
                        sourceUserId: userToTransferData.id,
                        type,
                        scheduleIds: temp,
                        digitalResourceIds: temp,
                        practiceTestIds: temp,
                        liveTestIds: temp
                }, callback, successCallBack)
        };



        const actions = [
                <>
                        <Button disabled={!temp?.length} type="delete" onClick={() => { setDeleteConfirmVisible(true) }}>DELETE DATA</Button>
                        <Button disabled={!temp?.length} style={{ marginLeft: 10 }} type="primary" onClick={() => {setTransferModal(true)}} loading={transferDataLoader}>TRANSFER DATA</Button>
                </>
        ];


        return (
                <div>
                        <PageHeader title='Manage Data' onBackPress={() => history.goBack()} actions={actions} />
                        {pageLoader ? <></> :
                                <Row style={{ height: "100%", marginTop: 80,  }}>
                                        <Col span={14} >
                                                <div style={{ color: "#636363", fontWeight: 500 }} className="m-t-20">
                                                        Data
                                                </div>
                                                <Row className='r-jsb'>
                                                        {detailDataTypes(userAllData).map((item, index) => (
                                                                <Col span={8} key={index}>
                                                                        <div style={{ border: "1px solid #E6E6E6", width: "90%", boxShadow: selectedItemIndex === index ? "4px 4px 18px #00000014" : "none", borderRadius: 8 }}
                                                                                className="m-t-10 p-20 cursor-pointer"
                                                                                onClick={() => handleTypeClick(item, index)}
                                                                        >
                                                                                <div style={{ borderRadius: 16, background: item.iconBackGround, width: 'fit-content', padding: "10px 15px" }}>
                                                                                        <AiTwotoneFolder style={{ fontSize: 30, color: item.iconColor }} />
                                                                                </div>
                                                                                <div style={{ fontSize: 16, fontWeight: 500 }} className="m-t-10">{item.label}</div>
                                                                                <div style={{ color: "#636363" }}>{item.count}</div>
                                                                        </div>
                                                                </Col>
                                                        ))}
                                                </Row>
                                        </Col>
                                        <Col span={9} offset={0.8}>
                                                <div style={{ width: "100%", height: "85vh", border: "1px solid #E6E6E6", borderRadius: 8 }} className={!selectedItem ? "r-c-c" : ""}>
                                                        {selectedItem ?
                                                                <ListOfContents selectedItem={selectedItem} userAllData={userAllData} filter={filter} handleLoadMore={handleLoadMore} buttonLoader={buttonLoader} handleCheck={handleCheck} temp={temp} handleSelectAll={handleSelectAll} />
                                                                : <div style={{ width: "80%" }} className="text-center">
                                                                        <img src={require("../../Assets/classroom/noSubjects.svg").default} width={"100%"} alt="" />
                                                                        <div style={{ fontSize: 18, fontWeight: 500 }}>
                                                                                No item selected yet!
                                                                        </div>
                                                                        <div style={{ fontSize: 16, color: "#636363" }}>
                                                                                Please select any folder to preview the files inside.
                                                                        </div>
                                                                </div>
                                                        }
                                                </div>
                                        </Col>
                                </Row>
                        }
                        <PageDataLoader visible={pageLoader} />
                        <PermanentlyDeleteConfirm
                                visible={deleteConfirmVisible}
                                onCancel={() => setDeleteConfirmVisible(false)}
                                onConfirm={(callback) => { deleteConfirm(callback); setDeleteConfirmVisible(false); }}
                                setAgreeToDelete={setAgreeToDelete}
                                agreeToDelete={agreeToDelete}
                                setTranseferDataValue={setTranseferDataValue}
                        />

                        {transferModal &&
                                <TransferModal
                                        transferModal={transferModal}
                                        setTransferModal={setTransferModal}
                                        searchedTeachers={searchedTeachers}
                                        searchUsers={searchUsers}
                                        transferData={transferData}
                                        userToTransferData={userToTransferData}
                                        setTemp={setTemp}
                                        temp={temp}
                                        dataType={dataType}
                                />
                        }
                </div>
        )
}

const mapStateToProps = (state) => {
        const { user } = state.session;
        const { userToTransferData, userAllData, searchedTeachers, storeTransferValue } = state.userManagementV2;
        return { userToTransferData, userAllData, searchedTeachers, storeTransferValue }
}

const mapDispatchToProps = (dispatch) => ({
        getUserAllData: (params, callback) => dispatch(getUserAllData(params, callback)),
        searchUsers: (params, callback) => dispatch(searchUsers(params, callback)),
        getMoreContent: (params, callback) => dispatch(getMoreContent(params, callback)),
        transferData: (params, callback, pageCallBack) => dispatch(transferData(params, callback, pageCallBack)),
        setTranseferDataValue: (res) => dispatch(setTranseferDataValue(res)),
        permanentlyDelete: (params, callback, successCallback) => dispatch(permanentlyDelete(params, callback, successCallback)),
        //         getDeletingUserData: (params, callback) => dispatch(getDeletingUserData(params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferData);