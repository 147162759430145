import { deleteLogo, saveInstituteDetails } from 'actions/profileNew';
import { Button } from 'components/Button';
import PageHeader from 'components/pageHeader';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import SaveConfirmation from './saveConfirmation';
import { DetailSection, EditImage, ImageComponent } from './subComponents';
import { designations } from './userProfile';
import { validateEmail } from './userProfileData';


export const instituteProfileDetails = (instituteProfile, handleInputChange, user) => {

    return [
        {
            heading: "Institute details",
            subHeading: "Edit/update institute details and more",
            fields: [
                {
                    label: "Name", type: "text", value: instituteProfile?.instituteName, 
                    required:true, disabled:true, 
                    placeholder: "Enter institute name..."
                },
                {
                    label: "Business type", type: "select", value: instituteProfile?.instituteType, 
                    required:true, disabled:true, 
                    placeholder: "Select business type...",
                    options:[]
                },
              
                {
                    label: "Website", type: "text", value: instituteProfile?.website, 
                    required:false, disabled:false, 
                    onChange:e => handleInputChange(e.target.value, 'website'),
                    placeholder: "Enter institute website..."
                },
                {
                    label: "Number of students", type: "select", value:instituteProfile?.noOfStudents,
                    required:true, 
                    onChange:(value) => handleInputChange(value, "noOfStudents"), 
                    placeholder: "Select students...",
                    options:['1-100','101-500']
                },
                {
                    label: "Building/Locality/Area", type: "text", value: instituteProfile?.locality, 
                    required:true, disabled:true, 
                    onChange:{},
                    placeholder: "Enter Building/Locality/Area..."
                },
                {
                    label: "Pincode", type: "text", value: instituteProfile?.pincode, 
                    required:true, disabled:true, 
                    onChange:{},
                    placeholder: "Enter Pincode..."
                },
                {
                    label: "State", type: "text", value: instituteProfile?.state, 
                    required:true, disabled:true, 
                    onChange:{},
                    placeholder: "Enter State..."
                },
                {
                    label: "District", type: "text", value: instituteProfile?.city, 
                    required:true, disabled:true, 
                    onChange:{},
                    placeholder: "Enter District..."
                },
                {
                    label: "Country", type: "text", value: instituteProfile?.country,  
                    required:true, disabled:true, 
                    onChange:{},
                    placeholder: "Enter Country..."
                },
                {
                    label: "Unique ID", type: "text", value: instituteProfile?.instituteId, 
                    required:true, disabled:true, 
                    onChange:{},
                },
                {
                    label: "Domain URL", type: "text", value: instituteProfile?.url, 
                    required:true, disabled:true, 
                    onChange:{},
                    placeholder: "Enter Country..."
                },
            ]
        },
        {
            heading: "Secondary contact details",
            subHeading: "This person will be the point of contact between the institute and aulas team incase primary contact person (Super Administrator) is unreachable.",
            fields: [
                {
                    label: "Name", type: "text", value: 
                    instituteProfile?.contactName, required:true, disabled:false, 
                    onChange:e => handleInputChange(e.target.value, 'contactName'),
                    placeholder: "Enter name..."
                },
                {
                    label: "Email ID", type: "text", value: instituteProfile?.contactEmail || '', required:true, disabled:false, 
                    onChange:e => handleInputChange(e.target.value, 'contactEmail'),
                    placeholder: "Enter email id...",
                    error: validateEmail(instituteProfile?.contactEmail) ?  "Enter valid email id": null ?  "Enter valid email id": null
                },
                {
                    label: "Mobile Number", type: "phone", value: instituteProfile?.contactPhone, required:true, 
                    placeholder: "Enter mobile no...",
                    disabled:false, 
                    onChange:e => handleInputChange(e, 'contactPhone'),
                },
                {
                    label: "Designation", type: "select", 
                    value: instituteProfile?.contactDesignation, required:true, disabled:false, 
                    onChange:e => handleInputChange(e, 'contactDesignation'), options: designations,
                    placeholder: "Select Designation..."
                },
                
            ]
        },
        {
            heading: "Billing details",
            subHeading: "Information given will be included in future invoices generated",
            fields: [
                {
                    label: "Building No./Locality/Area", type: "text", value: instituteProfile?.localityBilling, 
                    required:true, disabled:true, onChange:e => handleInputChange(e.target.value, 'localityBilling'),
                    placeholder: "Enter Building No./Locality/Area..."
                },
                {
                    label: "PIN/ZIP Code", type: "number", value: instituteProfile?.pincodeBilling, required:true, disabled:true, 
                    onChange:e => handleInputChange(e.target.value, 'pincodeBilling'),
                    placeholder: "Enter Pincode..."
                },
                {
                    label: "State", type: "text", value: instituteProfile?.stateBilling, required:false, disabled:true,
                    onChange:e => handleInputChange(e.target.value, 'stateBilling'),
                    placeholder: "Enter State..."
                },
                {
                    label: "District", type: "text", value: instituteProfile?.cityBilling, required:true, disabled:true, 
                    onChange:e => handleInputChange(e.target.value, 'cityBilling'),
                    placeholder: "Enter District..."
                },
                {
                    label: "Country", type: "text", value: instituteProfile?.countryBilling, required:true, disabled:true, 
                    onChange:e => handleInputChange(e.target.value, 'countryBilling'),
                    placeholder: "Enter Country..."
                },
            
            ]
        }
    ]
}

const InstituteProfile = ({
    user,
    instituteDetails,
    saveInstituteDetails,
    deleteLogo
}) => {

    const history = useHistory();
    useEffect(() => {
        setProfileDetails({...instituteDetails})
    }, [instituteDetails]);

    const instituteLogoRef = useRef(null);
    const [instituteProfile, setProfileDetails]= useState({});
    const [isSaveHidden, setIsSaveHidden] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [editLogoModal, setEditLogoModal] = useState(false);
    const [deleteConfirmation, setdeleteConfirmation] = useState(false);
    const [studentStrength , setStudentStrength] = useState('')

    

    const handleInputChange = (val, key) => {
        instituteProfile[key] = val;
        setProfileDetails({...instituteProfile})
        setStudentStrength({...instituteProfile})
    }
    console.log({instituteProfile});

    useEffect(() => {
        setIsSaveHidden(
            JSON.stringify(instituteDetails) === JSON.stringify(instituteProfile) 
        )
    }, [instituteProfile])

    const handleLogoModalOk = () => {
        setEditLogoModal(false);
        instituteLogoRef?.current?.click();
      };
    const handleSaveClick = (callback, buttonCallback) => {
        const params = {...instituteProfile};
        saveInstituteDetails(params, () => onSaveSuccess(callback), buttonCallback)
    }

    const onSaveSuccess = (callback) => {
        callback();
        setIsSaveHidden(true);
    }

    const onDeleteConfirm = (callback, successCallback) => {
        deleteLogo(callback, () => {
            successCallback();
            handleInputChange('defaultLogo.png', 'logo')
            handleInputChange(undefined, 'file')
            instituteLogoRef.current.value=null
        })
    }

    return (
        <div style={{height: "100%"}}>
            <PageHeader
                title="Edit Institute"
                onBackPress={() => history.goBack()}
                actions={
                    isSaveHidden ? null : [<Button disabled={instituteProfile?.contactEmail === ''||instituteProfile?.contactEmail === undefined || instituteProfile?.contactName === ''||instituteProfile?.contactName === undefined || instituteProfile?.contactPhone === '' || instituteProfile?.contactPhone === undefined || validateEmail(instituteProfile?.contactEmail)} type="link" style={{fontSize: 14}} onClick={() => setShowConfirmModal(true)}>SAVE</Button>]
                }
            />
            <ImageComponent 
                src={instituteProfile?.logo} 
                onChange={() => {}} 
                handleEditModal={setEditLogoModal} 
                ref={instituteLogoRef}
                setfileInputValue={(file) => { handleInputChange(file, 'file');}}
                localImg={instituteProfile.file}
            />
            <div style={{padding: 20}}>
                {instituteProfileDetails(instituteProfile, handleInputChange, user).map((section, index) => (
                    <DetailSection section={section} index={index} />
                )
                )}
            </div>
            <SaveConfirmation 
                saveConfirmationModal={showConfirmModal}
                handleOk={(callback, buttonCallback) => handleSaveClick(callback, buttonCallback)}
                onCancel={() => setShowConfirmModal(false)}
            />
             <EditImage 
                imageUrl={instituteProfile?.logo}
                onEditCancel={() => setEditLogoModal(false)}
                editModalVisible={editLogoModal}
                handleEditOk={handleLogoModalOk}
                deleteConfirm={() => {
                    setdeleteConfirmation(true), setEditLogoModal(false);
                    }}
                deleteModalVisible={deleteConfirmation}
                onDeleteCancel={() => setdeleteConfirmation(false)}
                onDeleteConfirm={(callback, successCallback) => onDeleteConfirm(callback, successCallback)}
            />
             <Prompt
                when={!isSaveHidden}
                message='You have unsaved changes, are you sure you want to leave?'
            />
        </div>
    );
}


const mapStateToProps = (state) => { 
    const {user} = state.session;
    const {instituteDetails} = state.profileNew;
    return {
        user,
        instituteDetails
    }
}

const mapDispatchToProps = (dispatch) => ({
    deleteLogo: (callback, successCallback) => dispatch(deleteLogo(callback, successCallback)),
    saveInstituteDetails: (params, callback, buttonCallback) => dispatch(saveInstituteDetails(params, callback, buttonCallback))
})

export default connect(mapStateToProps, mapDispatchToProps)(InstituteProfile);

