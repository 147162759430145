import { StarFilled } from '@ant-design/icons';
import { Button, Typography } from 'antd'
import React, { useEffect, useState } from 'react';
import './userHomeStyles.css'
import { Api } from 'services';
import saveOutlined from "../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import saveFilled from "../../Assets/aulasMarket/saveFilledIcon.svg"
// import { Button } from 'components/Button';
import RatingStars from './ratingStars';
import { connect } from 'react-redux';
import { getCartCount, getCheckOutItem, postAddCartItem, postAddSaveItem, postCourseEnrollNow, removeSaveItem, storeCourseIdAndType, storeSingleBuyNow } from 'actions/studentMarketPlace';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';
import './allCourseDetailsStyles.css';
import HtmlParser from 'react-html-parser';
const defaultCourseImage = require('Assets/default_course-img.svg').default;



function CourseCard({ item, postAddSaveItem, getCheckOutItem, lessData, postAddCartItem, removeSaveItem, user, postCourseEnrollNow, getCartCount, length, storeCourseIdAndType }) {
  const history = useHistory()
  const [shareUrl, setShareUrl] = useState(null);
  const [saved, setSaved] = useState({});
  const [cart, setCart] = useState({});
  const [enroll, setEnroll] = useState({})
  const [isShareModalVisible, setShareModalVisible] = useState(false);
  const [isSavedClicked, setIsSavedClicked] = useState(false);
  const [enrollLoader, setEnrollLoader] = useState(false);
  const [addCartLoader, setAddCartLoader] = useState(false)
  const [addCartClicked, setAddToCartClicked] = useState(false)
  useEffect(() => {
    setSaved((prevSavedItems) => ({
      ...prevSavedItems,
      [item?.id]: item?.inSaved == 1 ? true : item?.inSaved == 0 ? false : null,
    }));
    setEnroll((prevEnrolledItems) => ({
      ...prevEnrolledItems,
      [item?.id]: item?.owned == 1 ? true : item?.owned == 0 ? false : null,
    }));
  }, [item?.owned, item?.inSaved])

  const handleSaveForLater = (id, itemType) => {
    setIsSavedClicked(!isSavedClicked)
    if (user?.accessToken) {
      if (saved[id]) {
        removeSaveItem(id, itemType, (res) => {
          if (res) {
            setSaved((prevSavedItems) => ({
              ...prevSavedItems,
              [id]: false,
            }));
          }
        });
      } else {
        postAddSaveItem(id, itemType, (res) => {
          if (res) {
            setSaved((prevSavedItems) => ({
              ...prevSavedItems,
              [id]: true,
            }));
          }
        });
      }
    } else {
      history.push('/login');
    }
  };
  const handleAddToCart = (id, itemType) => {
    if (user?.accessToken) {

      postAddCartItem(id, itemType, (res) => {
        if (res) {
          setAddCartLoader(false)
          setCart((prevSavedItems) => ({
            ...prevSavedItems,
            [id]: true,
          }));
          setAddToCartClicked(true)
        }
      });
    } else {
      history.push('/login');
    }
  };
  const handleEnroll = (id, itemType) => {
    if (user?.accessToken) {
      postCourseEnrollNow(id, itemType, (res) => {
        if (res) {
          setEnrollLoader(false)
          setEnroll((prevSavedItems) => ({
            ...prevSavedItems,
            [id]: true,
          }));
          history.push('/courses-list')
        }
      });
    } else {
      history.push('/login');
    }
  };

  function formatDuration(duration) {
    if (!duration) {
      return '';
    }
    const [hours, minutes, seconds] = duration?.split(':');
    const formattedDuration = new Date(0, 0, 0, hours, minutes, seconds);
    const formattedHours = formattedDuration.getHours();
    const formattedMinutes = formattedDuration.getMinutes();
    const formattedSeconds = formattedDuration.getSeconds();
    let result = '';

    if (formattedHours > 0) {
      result += `${formattedHours} ${formattedHours === 1 ? 'hour' : 'hours'}`;
    }

    if (formattedMinutes > 0 || formattedSeconds > 0) {
      if (result.length > 0) {
        result += ', ';
      }
      result += `${formattedMinutes} ${formattedMinutes === 1 ? 'minute' : 'minutes'}`;
    }

    if (formattedSeconds > 0) {
      if (result.length > 0) {
        result += ', ';
      }
      result += `${formattedSeconds} ${formattedSeconds === 1 ? 'second' : 'seconds'}`;
    }

    return result || 'Less than a minute';
  }

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  function isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType === 1) return true;
    }
    return false;
  }

  console.log("itemLiveeeee", item?.resourceType)
  return (
    <div className='home-course-card-parent zoom12' style={{marginRight:length <= 3 ? 20 : ""}} onClick={() => {
      storeCourseIdAndType("id",item?.id);
      storeCourseIdAndType("type",item?.resourceType)
      history.push({
        pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
        state: {
          id: item?.id,
          resourceType: item?.resourceType
        }
      })
    }}>
      <div className={`${lessData ? 'home-not-hovered-course-card-with-less-data' : 'home-not-hovered-course-card'}`} >
        {item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ?
          <div style={{ width: "100%", height: "140px", cursor: "pointer" }}>
            <div className='study-material-cards'>
              {item?.feeType == 'free' ?
                <div className='study-materials-free' >
                  <div style={{ textAlign: "center", fontSize: "10px", color: '#FFFFFF', fontWeight: "700px", marginTop: 2 }}>FREE</div></div> : <div style={{ width: '60px', height: 20 }} ></div>}
              <div style={{ display: "flex", marginLeft: "20px", position: "absolute", top: 48, left: 0 }}>
                <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50 }} />

                {item?.resourceType == "testSeries"
                  ?
                  <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px" }}>
                    {item?.tCount} {item?.tCount <= 1 ? "Test" : "Tests"}

                  </div>
                  :
                  <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px" }}>
                    {item?.m_count} {item?.m_count <= 1 ? "Material" : "Materials"}
                    {item?.q_count > 0 ? `, ${item?.q_count} ${item?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}
                  </div>
                }

              </div>
              <div style={{ marginRight: "10px", marginTop: "-10px" }}>
                {item?.amount > 0 ? <div style={{ fontSize: 12, color: "#594099", backgroundColor: "#F6F4FE", padding: "3px 6px", position: "absolute", fontWeight: "bold", bottom: 10, right: 10, borderRadius: 4, border: " 1px solid #594099" }}>₹ {(parseFloat(item?.amount || 0) - parseFloat(item?.discountAmount)).toFixed(2)}{" "}{item?.discountAmount > 0 ? <span style={{ color: "#FF414D", textDecoration: 'line-through' }}>₹ {parseFloat(item?.amount || 0).toFixed(2)}</span> : <></>}</div> : <></>}
              </div>
            </div>
          </div>
          :
          <div style={{ width: "100%", height: "140px", position: "relative" }}>
            <img src={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} style={{ borderRadius: 8, width: "100%", height: "140px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} />
            {item?.feeType == "free" ? <img src={require("../../Assets/userMarketPlace/freeTag.svg").default} style={{ position: "absolute", left: 0, top: -5, height: 45, width: 45 }} /> : <></>}
            {item?.mode ? <div style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", position: "absolute", fontWeight: "bold", top: 15, right: 0 }}>{item?.mode?.charAt(0)?.toUpperCase() + item?.mode?.slice(1)}</div> : <></>}
            {item?.amount > 0 ? <div style={{ fontSize: 12, color: "#594099", backgroundColor: "#F6F4FE", padding: "3px 6px", position: "absolute", fontWeight: "bold", bottom: 10, right: 10, borderRadius: 4, border: " 1px solid #594099" }}>₹ {(parseFloat(item?.amount || 0) - parseFloat(item?.discountAmount)).toFixed(2)}{" "}{item?.discountAmount > 0 ? <span style={{ color: "#FF414D", textDecoration: 'line-through' }}>₹ {parseFloat(item?.amount || 0).toFixed(2)}</span> : <></>}</div> : <></>}
          </div>
        }
        <Typography.Text className='course-name-div' ellipsis={{ tooltip: true }} style={{ color: "#191919", fontWeight: "bold", fontSize: 16, width: "100%" }}>{item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName}</Typography.Text>
        {item?.resourceType === "videoResource" ? (
          <div className='' style={{ color: "#636363" }}>
            {formatDuration(item?.duration)} video {item?.quizCount > 0 && <>| {item?.quizCount} quizzes</>} {item?.notesCount > 0 && <>| {item?.notesCount} notes</>}
          </div>
        ) : (
          <></>
        )}

        {item?.resourceType == "testSeries"
          ?
          <div style={{ display: "flex" }}>
            {(item?.ratingValue && item?.ratingValue > 0) ? <RatingStars star={item?.ratingValue} /> : <></>}
            <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
          </div>
          :
          <div style={{ display: "flex" }}>
            {(item?.rating && item?.rating > 0) ? <RatingStars star={item?.rating} /> : <></>}
            <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
          </div>
        }

      </div>
      <div className={`${lessData ? 'home-hovered-course-card-with-less-data' : 'home-hovered-course-card'}`}>
        <div style={{ color: "#191919", fontWeight: "bold", fontSize: 14, width: "100%", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 3 }}>{item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName}</div>
        {item?.resourceType == "testSeries"
          ?
          <div style={{ display: "flex" }}>
            {(item?.ratingValue && item?.ratingValue > 0) ? <RatingStars star={item?.ratingValue} /> : <></>}
            <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
          </div>
          :
          <div style={{ display: "flex" }}>
            {(item?.rating && item?.rating > 0) ? <RatingStars star={item?.rating} /> : <></>}
            <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
          </div>
        }

        <div className={`m-t-5 ${item?.bestseller == 1 ? 'r-c-sb' : ""}`}>
          {item?.bestseller == 1 ? <div style={{ color: "#191919", backgroundColor: "#FFA931", border: "1px solid #707070", padding: "2px 8px", height: "fit-content", fontWeight: "bold", borderRadius: 4, fontSize: 12 }}>Bestseller</div> : <></>}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {user?.role?.includes("Student") || user?.accessToken == undefined ? <>
              {item?.owned == 1 ? <></> : <div onClick={(e) => {
                e.stopPropagation();
                if (user.accessToken) {
                  handleSaveForLater(item?.id, item?.resourceType)
                } else {
                  history.push("/login")
                }
              }}><img src={addCartClicked ? saveOutlined : saved[item?.id] || (isSavedClicked && item?.isSaved == 0) ? saveFilled : saveOutlined} style={{ height: 20, width: 20 }} /></div>}
            </> : <></>}
            <img src={require("../../Assets/aulasMarket/shareIcon.svg").default} style={{ marginLeft: 20, height: 20, width: 20 }}
              onClick={(e) => {
                e.stopPropagation();
                setShareModalVisible(true);
                setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`)
              }} />
          </div>
        </div>
        <div className='ellipsis-text m-t-10' style={{ color: "#191919", fontSize: 12 }}>
          {IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}</div>
        {item?.mode ? <div className='m-t-10' style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", fontWeight: "bold", width: "fit-content" }}>{item?.mode?.charAt(0)?.toUpperCase() + item?.mode?.slice(1)}</div> : <></>}
        {item?.mode ? <div className='m-t-5' style={{ fontSize: 11, color: "#AEAEAE" }}>{item?.mode == "hybrid" ? "Classes to be held in physical classroom and also over the internet." : item?.mode == "online" ? "Classes to be conducted over the internet." : "Classes to be held in physical classroom."}</div> : <></>}
        {user?.role?.includes("Student") || user?.accessToken == undefined ? <>
          <center className='m-t-5'>
            {item?.owned == 1 || enroll[item?.id] ?
              <Button style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "fit-content", marginTop: 5, padding: "5px 10px", borderRadius: 18, fontWeight: 600, fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)` }}>VIEW COURSE</Button>
              :
              <>
                {item?.amount == 0 || item?.feeType == "free" ?
                  <Button loading={enrollLoader} className='m-t-5' type="primary" style={{ width: "47%", padding: "5px 10px", borderRadius: 18, fontWeight: 600, fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)` }}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (user?.accessToken) {
                        if (enroll[item?.id]) { } else if (item?.feeType == "free") {
                          setEnrollLoader(true)
                          handleEnroll(item?.id, item?.resourceType)
                        }
                      } else {
                        history.push("/login")
                      }
                    }}
                  >{enroll[item?.id] ? "ENROLLED" : "ENROLL NOW"}</Button>
                  :
                  <div className='r-c-sb m-t-8' style={{ width: "100%" }}>
                    {item?.resourceType == "course" ? null:
                      <Button loading={addCartLoader} style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "47%", padding: "5px 10px", borderRadius: 18, fontWeight: 600, fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)` }} onClick={(e) => {
                        e.stopPropagation()
                        if (!user?.accessToken) {
                          history.push("/login")
                        } else if ((item?.inCart == 1 || cart[item?.id]) && user?.accessToken) {
                          history.push("/cart")
                        } else {
                          setAddCartLoader(true)
                          handleAddToCart(item?.id, item?.resourceType)
                          getCartCount()
                        }
                      }}>{item?.inCart == 1 || cart[item?.id] ? "GO TO CART" : "ADD TO CART"}</Button>
                    }
                    <Button type="primary" style={{ width:item?.resourceType =="course"?"100%":"47%", padding: "5px 10px", borderRadius: 18, fontWeight: 600, fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)` }}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (user?.accessToken) {
                          if (item?.feeType == "free") {
                            handleEnroll(item?.id, item?.resourceType)
                          } else {
                            history.push({
                              pathname: '/user-checkout',
                              state: { itemId: item?.id, itemType: item?.resourceType }
                            });
                            
                            
                          }
                        } else {
                          history.push("/login")
                        }
                      }}
                    >{enroll[item?.id] ? "ENROLLED" : item?.feeType == "free" || item?.resourceType =="course" ? "ENROLL NOW" : "BUY NOW"}</Button>
                  </div>
                }
              </>
            }
          </center>
        </> : <></>}
      </div>
      {isShareModalVisible &&
        <ShareCourseModal
          isShareModalVisible={isShareModalVisible}
          setShareModalVisible={setShareModalVisible}
          shareUrl={shareUrl} />}
    </div>
  )
}
const mapStateToProps = (state) => {
  const { userMarketPlaceHomeData } = state.studentMarketPlace;
  const { user } = state.session
  return { userMarketPlaceHomeData, user };
};

const mapDispatchToProps = (dispatch) => ({
  postAddSaveItem: (id, itemType, callback) => dispatch(postAddSaveItem(id, itemType, callback)),
  postAddCartItem: (id, itemType, callback) => dispatch(postAddCartItem(id, itemType, callback)),
  removeSaveItem: (id, itemType, callback) => dispatch(removeSaveItem(id, itemType, callback)),
  storeSingleBuyNow: (res) => dispatch(storeSingleBuyNow(res)),
  postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
  getCheckOutItem: (id, itemType, callback) => dispatch(getCheckOutItem(id, itemType, callback)),
  getCartCount: () => dispatch(getCartCount()),
  storeCourseIdAndType:(key,val) => dispatch(storeCourseIdAndType(key,val))
});
export default connect(mapStateToProps, mapDispatchToProps)(CourseCard);