import moment from "moment";
import { Api } from "../services";
import { SET_FILTER_PARAMS } from "./user";

export const UPDATE_DASHBOARD_DATA = "UPDATE_DASHBOARD_DATA";
export const UPDATE_STUDENT_TEST_FILTER = "UPDATE_STUDENT_TEST_FILTER";
export const SET_SELECTED_SUB = "SET_SELECTED_SUB";
export const UPDATE_STUDENT_TEST_COUNT = "UPDATE_STUDENT_TEST_COUNT";
export const SET_SELECTED_TEST = "SET_SELECTED_TEST";
export const UPDATE_TEST_RESULT = "UPDATE_TEST_RESULT";
export const SET_TEST_DURATION = "SET_TEST_DURATION";
export const SET_CURRENT_STUDENT_QUESTION = "SET_CURRENT_STUDENT_QUESTION";
export const UPDATE_PALATE_DATA = "UPDATE_PALATE_DATA";
export const UPDATE_COUNT_DATA = "UPDATE_COUNT_DATA";
export const SET_CURRENT_INDEX = "SET_CURRENT_INDEX";
export const UPDATE_Q_PARAM = "UPDATE_Q_PARAM";
export const UPDATE_TEST_SUBS_LIST = "UPDATE_TEST_SUBS_LIST";
export const SET_SELECTED_SUB_INDEX = "SET_SELECTED_SUB_INDEX";
export const UPDATE_SOLUTION_COUNT_DATA = "UPDATE_SOLUTION_COUNT_DATA";
export const UPDATE_ANSWER_IMAGES = "UPDATE_ANSWER_IMAGES";
export const REMOVE_ANSWER_IMAGE = "REMOVE_ANSWER_IMAGE";
export const CLEAR_IMG_LIST = "CLEAR_IMG_LIST";
export const UPDATE_CURRENT_DURATION = "UPDATE_CURRENT_DURATION";
export const UPDATE_SOLUTION_SUBS_LIST = "UPDATE_SOLUTION_SUBS_LIST";
export const TOGGLE_OUT_OF_TIME_MODAL = "TOGGLE_OUT_OF_TIME_MODAL";
export const UPDATE_QUESTIONS_CONTAINER = 'UPDATE_QUESTIONS_CONTAINER'
export const UPDATE_TEST_TYPE = 'UPDATE_TEST_TYPE'
export const UPDATE_STUDENT_TEST_DATA="UPDATE_STUDENT_TEST_DATA"
export const UPDATE_HOME_PAGE_DATA  = "UPDATE_HOME_PAGE_DATA"
export const UPDATE_SUBJECT_IN_SYLLABUS = 'UPDATE_SUBJECT_IN_SYLLABUS'
export const UPDATE_CHAPTER_IN_SYLLABUS = 'UPDATE_CHAPTER_IN_SYLLABUS'
export const SET_FILTER = "SET_FILTER"
export const SET_CARDS = 'SET_CARDS'
export const UPDATE_CHAPTER_CARDS='UPDATE_CHAPTER_CARDS'
export const UPDATE_QUESTION_BY_TEST='UPDATE_QUESTION_BY_TEST'
export const UPDATE_GET_RESULTS ='UPDATE_GET_RESULTS'
export const UPDATE_TEST_PERFORMANCE='UPDATE_TEST_PERFORMANCE'
export const UPDATE_TEST_SOLUTION='UPDATE_TEST_SOLUTION'
export const SET_LOADER = "SET_LOADER"
export const RELOAD_TOGGLER = "RELOAD_TOGGLER"
export const SET_SOLUTION_SUBJECTS = "SET_SOLUTION_SUBJECTS"
export const REFER_DURATION = 'REFER_DURATION'
export const CLEAR_QUESTION = 'CLEAR_QUESTION'

export const selectQIndex = (
  index,
  pageLoaderCallback,
  finalSubmit=false
) => {
  return (dispatch, getState) => {
    const { currentQ, currentTest, currentIndex, currentDuration,referDuration } =
      getState().studentPracticeTest;

      console.log('infocurrent',typeof(currentDuration))
      console.log('inforefer',typeof(referDuration))
    const params = {
      answer: currentQ?.currentAns ?? null,
      testQuestionId: currentQ?.id,
      files: currentQ?.files,
      duration: referDuration?(currentDuration - referDuration) : currentDuration,
      submitStatus: currentQ?.status
        ? currentQ?.status
        : currentQ?.attempt?.submitStatus
        ? currentQ?.attempt?.submitStatus
        : "Unanswered"
    };
    return Api.post(`/practice-test/student/submit-ans`)
      .params(params)
      .send((response, error) => {
        if (response !== undefined) {
          if (finalSubmit===true){
            pageLoaderCallback(true);
          }else{
            dispatch(setCurrentIndex(index));
            pageLoaderCallback(true);
            dispatch(fetchTestQuestions(pageLoaderCallback));
            dispatch(clearImageList());
          }
          // Notification.success("success", "Answer Saved!")
        }
      });
  };
};

export const updateCurrentDuration = (seconds) => ({
  type: UPDATE_CURRENT_DURATION,
  seconds,
});

export const toggleOutOfTimeModal = (value) => ({
  type: TOGGLE_OUT_OF_TIME_MODAL,
  value,
});

export const finalSubmit = (buttonCallback, successCallback) => {
  return (dispatch, getState) => {
    const { currentQ, currentTest, currentIndex, currentDuration, selectedTest } =
      getState().studentPracticeTest;
    const params = {
      testId: selectedTest.id,
      testStatus: "Submitted"
    };
    return Api.post(`/practice-test/student/update-attempt`)
      .params(params)
      .send((response, error) => {
        buttonCallback();
        if (response !== undefined) {
          successCallback();
          // dispatch(clearQuestion());

        }
      });
  };
};

const clearImageList = () => ({
  type: CLEAR_IMG_LIST,
});

export const removeAnswerImage = (index) => ({
  type: REMOVE_ANSWER_IMAGE,
  index,
});

export const updateImage = (base64Img, originalObject) => ({
  type: UPDATE_ANSWER_IMAGES,
  base64Img,
  originalObject,
});

export const setSelectedSubIndex = (index) => ({
  type: SET_SELECTED_SUB_INDEX,
  index,
});

export const handleSelectSubject = (index, pageLoaderCallback) => {
  return (dispatch, getState) => {
    const { testSubjects } = getState().studentPracticeTest;
    dispatch(setSelectedSubIndex(index));
    let qLength = 0;
    for (let i = 0; i <= index - 1; i++) {
      qLength += parseInt(testSubjects[i].totalQuestions);
    }
    dispatch(setCurrentIndex(qLength+1));
    pageLoaderCallback(true);
    dispatch(fetchTestQuestions(pageLoaderCallback));
  };
};

export const handleSubjectSelection = (index, loaderCallback) => {
  return (dispatch, getState) => {
    const { solutionSubjectsList, questionsContainer } = getState().studentPracticeTest;
    let newIndex = 0;
    for (let i=0; i<questionsContainer.length; i++){
      if (solutionSubjectsList[index].id===questionsContainer[i].subjectId){
        newIndex = i;
        break; 
      }
    }
    dispatch(setCurrentIndex(newIndex));
  };
};

export const navigateQuestion = (
  dir,
  buttonLoaderCallBack,
  pageLoaderCallback
) => {
  return (dispatch, getState) => {
    const { currentQ, currentTest, currentIndex, currentDuration,referDuration } =
      getState().studentPracticeTest;
    const params = {
      answer: currentQ.currentAns || null,
      testQuestionId: currentQ.id,
      duration: referDuration?(currentDuration - referDuration) : currentDuration,
      submitStatus: currentQ?.status
        ? currentQ?.status
        : currentQ?.attempt?.submitStatus
        ? currentQ?.attempt?.submitStatus
        : "Unanswered",
    };
    return Api.post(`/practice-test/student/submit-ans`)
      .params(params)
      .send((response, error) => {
        buttonLoaderCallBack(false);
        if (response !== undefined) {
          const index = dir==='1'?1: dir === "next" ? currentIndex + 1 : currentIndex - 1;
          dispatch(setCurrentIndex(index));
          pageLoaderCallback(true);
          dispatch(fetchTestQuestions(pageLoaderCallback));
          dispatch(clearImageList());
          // Notification.success("success", "Answer Saved!")
        }
      });
  };
};

export const removeAnswer = (
  status = null,
  history = null,
  buttonLoaderCallBack = null,
  pageLoaderCallback = null
) => {
  return (dispatch, getState) => {
    const { currentQ, currentTest, currentDuration,referDuration } = getState().studentPracticeTest;
    const params = {
      answer: currentQ.currentAns,
      testQuestionId: currentQ.id,
      duration: referDuration?(currentDuration - referDuration) : currentDuration,
      submitStatus: status,
    };
    return Api.post(`/practice-test/student/submit-ans`)
      .params(params)
      .send((response, error) => {
        buttonLoaderCallBack(false);
        pageLoaderCallback(false);
        dispatch(clearImageList());
        if (response !== undefined) {
          dispatch(clearImageList());
        }
      });
  };
};


export const updateQParam = (val, key) => ({
  type: UPDATE_Q_PARAM,
  qParam: { [key]: val },
});

export const setCurrentIndex = (index) => ({
  type: SET_CURRENT_INDEX,
  index,
});

const updateCountData = (value, key) => ({
  type: UPDATE_COUNT_DATA,
  countData: { [key]: value },
});

const updateSolutionCountData = (value, key) => ({
  type: UPDATE_SOLUTION_COUNT_DATA,
  solutionCountData: { [key]: value },
});

const updatePalateData = (data) => ({
  type: UPDATE_PALATE_DATA,
  data,
});

export const setCurrentStudentQ = (data) => ({
  type: SET_CURRENT_STUDENT_QUESTION,
  data
});

const updateTestSubList = (data) => ({
  type: UPDATE_TEST_SUBS_LIST,
  data,
});

const setSolutionSubs = (data) => ({
  type: SET_SOLUTION_SUBJECTS,
  data,
});

const updateSolutionSubList = (data) => ({
  type: UPDATE_SOLUTION_SUBS_LIST,
  data,
});

export const fetchTestQuestions = (loaderCallback) => {
  return (dispatch, getState) => {
    loaderCallback(true);
    const { selectedTest, currentIndex, } = getState().studentPracticeTest;
    const params = {};
    return new Promise((resolve, reject) => {
      Api.get(
        `/practice-test/student/questions-by-test/${selectedTest.id}?index=${
          currentIndex
        }`
      )
        .params(params)
        .send((response, error) => {
          if (response !== undefined) {
            dispatch(setCurrentStudentQ(response.question));
            dispatch(updatePalateData(response.matrix));
            dispatch(updateCountData(response.attempted, "attempted"));
            dispatch(
              updateCountData(
                response.attemptedAndBookmarked,
                "attemptedAndBookmarked"
              )
            );
            dispatch(updateCountData(response.bookmarked, "bookmarked"));
            dispatch(updateCountData(response.unanswered, "unanswered"));
            dispatch(updateCountData(response.unseen, "unseen"));
            dispatch(updateTestSubList(response.subjects));
            dispatch(referDuration(response?.question?.attempt?.duration));
          }
          loaderCallback(false);
        });
    });
  };
};

const getPalateData = (solutions)=>{
  let palette = [];
  if (solutions!=null){
  solutions.map((item, index)=>{
    palette.push({status:(item.submitStatus==="Attempted"||item.submitStatus==='AttemptedAndBookmarked')?item.correct:item.submitStatus!=null?item.submitStatus:"Unanswered"})
  })}
  return palette;
}
export const fetchTestSolutions = (
  index = 1,
  loaderCallback,
  buttonLoaderCallBack
) => {
  return (dispatch, getState) => {
    // loaderCallback(true);
    // buttonLoaderCallBack(true);
    const { selectedTest,filter } = getState().studentPracticeTest;
    const params = {};
    // const index = 1;
    return Api.get(`/practice-test/student/get-solution/${selectedTest?.testId}`)
      .params(params)
      .send((response, error) => {
        if (response !== undefined) {
          console.log("response", response);
          const resData = response.items[0];
          dispatch(setCurrentStudentQ(resData));
          let PalateData = getPalateData(response.items);
          dispatch(updatePalateData(PalateData));
          console.log("updated palate data", PalateData)
          dispatch(
            updateSolutionCountData(response.unattemptedCount, "unattempted")
          );
          dispatch(updateSolutionCountData(response.correctCount, "correct"));
          dispatch(updateSolutionCountData(response.inCorrectCount, "incorrect"));
          dispatch(
            updateSolutionCountData(response.partialCorrectCount, "partialCorrect")
          );
          dispatch(updateSolutionCountData(response.unSeenCount, "unseen"));
          dispatch(updateQuestionsContainer(response.items));
          dispatch(setSolutionSubs(response.subjects))
          // dispatch(updateTestSubList(response.subjects))
        }
        buttonLoaderCallBack(false);
        loaderCallback(false);
      });
  };
};
const updateQuestionsContainer = (questions)=> ({
  type: UPDATE_QUESTIONS_CONTAINER,
  questions
})
export const fetchTestSummary = (loaderCallback) => {
  return (dispatch, getState) => {
    loaderCallback(true);
    const { selectedTest } = getState().studentPracticeTest;
    const params = {};
    return Api.get(`/testv2/student-solution-summary/?testId=19`).send(
      (response, error) => {
        if (response !== undefined) {
          const resData = response.summary[0];
          dispatch(updatePalateData(response.matrix));
          dispatch(
            updateSolutionCountData(resData.totalUnattempted, "unanswered")
          );
          dispatch(updateSolutionCountData(resData.correctSum, "correct"));
          dispatch(updateSolutionCountData(resData.wrongSum, "incorrect"));
          dispatch(
            updateSolutionCountData(resData.partialCorrectSum, "partialCorrect")
          );
          dispatch(updateSolutionSubList(response.subjects));
        }
        loaderCallback(false);
      }
    );
  };
};

export const setDuration = (duration) => ({
  type: SET_TEST_DURATION,
  duration,
});



export const setSelectedTest = (data) => ({
  type: SET_SELECTED_TEST,
  data,
});

// export const fetchResult = (callback) => {
//   return (dispatch, getState) => {
//     const { selectedTest } = getState().studentPracticeTest;
//     const params = {
//       testId: selectedTest?.id,
//     };
//     return Api.get(`/testv2/get/student-result`)
//       .params(params)
//       .send((response, error) => {
//         callback();
//         if (response !== undefined) {
//           dispatch(updateTestResult(response));
//         }
//       });
//   };
// };

const updateSubTestCount = (count) => ({
  type: UPDATE_STUDENT_TEST_COUNT,
  count,
});

export const setSelectedSub = (data) => ({
  type: SET_SELECTED_SUB,
  data,
});

export const fetchSubPageData = (callback) => {
  return (dispatch, getState) => {
    const { studentTestFilterParams, selectedSub } = getState().studentPracticeTest;
    const params = {
      status: studentTestFilterParams.testStatus,
      subjectId: selectedSub.subjectId,
    };
    return Api.get(`/testv2/get/subwise-tests-for-student`)
      .params(params)
      .send((response, error) => {
        callback();
        if (response !== undefined) {
          dispatch(updateDashboardData(response.data));
          dispatch(updateSubTestCount(response.total));
        }
      });
  };
};

export const updateStudentTestFilter = (val, key) => ({
  type: UPDATE_STUDENT_TEST_FILTER,
  filterParams: { [key]: val },
});

const updateDashboardData = (data) => ({
  type: UPDATE_DASHBOARD_DATA,
  data,
});

export const fetchDashBoardData = (callback=null, startDate=null, endDate=null) => {
  return (dispatch, getState) => {
    const { studentTestFilterParams } = getState().studentPracticeTest;
    const params = {

    };
    if (studentTestFilterParams.rangeType=="custom"){
      params.startDate = startDate?moment(startDate, "DD/MM/YYYY").format("YYYY/MM/DD"):null
      params.endDate = endDate?moment(endDate, "DD/MM/YYYY").format("YYYY/MM/DD"):null;
    }
    else{
      params.filter = studentTestFilterParams.rangeType
    }
    return Api.get(`/testv2/get/all-tests-for-student`)
      .params(params)
      .send((response, error) => {
        if(callback){
          callback()
        }
        if (response !== undefined) {
          dispatch(updateDashboardData(response));
        }
      });
  };
};


export const updateTestType = (testType)=>({
  type: UPDATE_TEST_TYPE,
  testType
})

export const UpdateStudentTestData=(key,val)=>({
  type: UPDATE_STUDENT_TEST_DATA,
  key,
  val
})

export const fetchChapterWiseTest = (callback=null) => {
  return (dispatch, getState) => {
    const { studentTestFilterParam ,studentTestData} = getState().studentPracticeTest;
    const params = {
      syllabus:studentTestData?.selectedTestCard?.testType,
      search:studentTestData?.search
    };
    return Api.get(`/testv2/student/syllabus-tests`)
      .params(params)
      .send((response, error) => {
        if(callback){
          callback()
        }
        if (response !== undefined) {
          // dispatch(updateDashboardData(response));
          dispatch(UpdateStudentTestData('testData',response))
          console.log(response)
          // callback();
        }
      });
  };
};

export const getChapterWiseTest = (callback=null) => {
  return (dispatch, getState) => {
    const { studentTestFilterParam ,studentTestData} = getState().studentPracticeTest;
    const params = {
      subjectId:studentTestData?.selectedChapterTestCard?.subjectId,
      search:studentTestData?.search
    };
    return Api.get(`/testv2/student/chapter-test-chapters`)
      .params(params)
      .send((response, error) => {
        if(callback){
          callback();
        }
        if (response !== undefined) {
          // dispatch(updateDashboardData(response));
          dispatch(UpdateStudentTestData('chapterWiseTestData',response))
          console.log(response)
        }
      });
  };
};


export const fetchStatusWiseTest = (callback=null) => {
  return (dispatch, getState) => {
    const { studentTestFilterParam ,studentTestData,studentTestFilterParams} = getState().studentPracticeTest;
    const params = {
      status:studentTestFilterParams.testStatus,
      syllabus:studentTestData?.selectedTestCard?.testType,
      subjectId:studentTestData?.selectedTestCard?.testType!="Chapter Test"?studentTestData?.selectedChapterTestCard?.subjectId:null,
      chapterId:studentTestData?.selectedTestCard?.testType=="Chapter Test"?studentTestData?.selectedChapter?.chapterId:null,
      combined:studentTestData?.selectedChapterTestCard?.combinedTest?studentTestData?.selectedChapterTestCard?.combinedTest:null,
      testType:studentTestData?.type,
      page:-1,
      // page:studentTestData?.page,
      search:studentTestData?.search
    };
    return Api.get(`/testv2/get/subwise-tests-for-student`)
      .params(params)
      .send((response, error) => {
        if(callback){
          callback();
        }
        if (response !== undefined) {
          // dispatch(updateDashboardData(response));
          dispatch(UpdateStudentTestData('statusWiseTest',response))
          // dispatch(updateDashboardData(response));

          console.log(response)
        }
      });
  };
};

export const fetchHomePageData = (callback=null) => {
  return (dispatch, getState) => {
    const params = {};
    dispatch(setLoader('homePageLoader',true))
    return Api.get(`/practice-test/student`)
      .params(params)
      .send((response, error) => {
        if(callback){
          callback();
        }
        if (response !== undefined) {
          dispatch(updateHomePageData("set", response));
          dispatch(setLoader('homePageLoader',false))
        }
      });
  };
};


export const updateHomePageData = (key, value) => ({
  type: UPDATE_HOME_PAGE_DATA,
  key,
  value
})

const handleSubjects = (response, syllabus) => {
  let temp = [];
  for (const sub of response.subjects){
    temp.push({...sub, isCombined:false});
  }
  if (syllabus!=='Chapter Test'){
    temp.push({
      subjectName:"Combined Test(s)",
      testCount:response.combinedCount,
      icon: response.combinedIcon,
      isCombined: true
    })
  }
  return temp;
}


export const fetchSubjectInSyllabusData = (callback=null) => {
  return (dispatch, getState) => {
    const {studentTestData,filter} = getState().studentPracticeTest;
    const params = {
      syllabus: studentTestData?.selectedTestCard?.type,
      search: filter?.subjectSearch
    };
    dispatch(setLoader('subjectPageLoader',true))
    return Api.get(`/practice-test/student/subjects-in-syllabus`)
      .params(params)
      .send((response, error) => {
        if(callback){
          callback();
        }
        if (response !== undefined) {
          // dispatch(updateDashboardData(response));
          const subjects = handleSubjects(response, filter.selectedSyllabus);
          dispatch(updateSubjectInSyllabusData(subjects));
          dispatch(setLoader('subjectPageLoader',false))
          console.log(response)
        }
      });
  };
};


export const updateSubjectInSyllabusData = (value) => ({
  type: UPDATE_SUBJECT_IN_SYLLABUS,
  value
})



export const fetchChapterInSyllabusData = (callback=null) => {
  return (dispatch, getState) => {
    dispatch(setLoader('chapterPageLoader',true))
    const {studentTestData, filter} = getState().studentPracticeTest;
    const params = {
    subjectId:filter?.selectedSubject?.subjectId,
    search: filter?.chapterSearch
    };
    return Api.get(`/practice-test/student/chapter-test-chapters`)
      .params(params)
      .send((response, error) => {
        if(callback){
          callback();
        }
        if (response !== undefined) {          
          dispatch(updateChapterInSyllabusData(response));
          console.log(response)
          dispatch(setLoader('chapterPageLoader',false))
        }
      });
  };
};

export const updateChapterInSyllabusData = (value) => ({
  type: UPDATE_CHAPTER_IN_SYLLABUS,
  value
})

export const fetchTestByStageData = (callback=null) => {
  return (dispatch, getState) => {
    dispatch(setLoader('tabPageLoader',true))
    const {studentTestData, filter,cards} = getState().studentPracticeTest;
    const params = {
      syllabus: filter?.selectedSyllabus,
      stage: filter?.selectedTab,
      subjectId:filter?.selectedSyllabus!=='Chapter Test'?filter?.selectedSubject?.subjectId:null,
      chapterId:filter?.selectedSyllabus==='Chapter Test'?filter?.selectedChapter?.chapterId:null,
      combined:filter?.selectedSyllabus!=='Chapter Test'&& filter?.selectedSubject?.isCombined===true?true:null,
      search: filter?.tabSearch
    };
    return Api.get(`/practice-test/student/tests-by-stage`)
      .params(params)
      .send((response, error) => {
        if(callback){
          callback();
        }
        if (response !== undefined) {
          dispatch(setCards(filter?.selectedTab, response));
          dispatch(setLoader('tabPageLoader',false))
        }
      });
  };
};


export const postUpdateAttempt = (finalSubmit, callback=null) => {
  return (dispatch, getState) => {
    const {studentTestData, filter,cards, selectedTest} = getState().studentPracticeTest;
    const params = {
      testId:selectedTest?.testId ,
      testStatus: finalSubmit === true ? "Submitted" : "Draft",
    };
    return Api.post(`/practice-test/student/update-attempt`)
      .params(params)
      .send((response, error) => {
        if (response !== undefined) {
          // dispatch(updateDashboardData(response));
          if(callback){
            callback(response);
          }
          
          // dispatch(UpdateStudentTestData('chapterWiseTestData',response))
          console.log(response)
        }
      });
  };
};

export const saveNext = (
  status = null,
  history = null,
  finalSubmit = false,
  buttonLoaderCallBack = null,
  pageLoaderCallback = null
) => {
  return (dispatch, getState) => {
    const { currentQ, currentTest, currentIndex, currentDuration, palateData,referDuration } =
      getState().studentPracticeTest;
    let finalStatus = "";
    if (
      status === "Bookmarked" &&
      (currentQ.studentAnswer)
    ) {
      finalStatus = "AttemptedAndBookmarked";
    } else if (
      (status === "Attempted" || status === "AttemptedAndBookmarked") 
      &&
      !(
        currentQ.currentAns ||
        currentQ.currentAns === "" 
      )
    ) {
      finalStatus = "Unanswered";
    } else {
      finalStatus = status;
    }
    console.log("761 logged", finalStatus)
    const params = {
      answer: currentQ.currentAns,
      testQuestionId: currentQ.id,
      duration: referDuration?(currentDuration - referDuration) : currentDuration,
      submitStatus: finalStatus,
      // testStatus: finalSubmit === true ? "Submitted" : "Draft",
    };
    // if (currentQ.type !== "Image") {
      
      return Api.post(`/practice-test/student/submit-ans`).params(params).send((response)=> { 
          buttonLoaderCallBack(false);
          if (response !== undefined) {
            // Notification.success("success", "Answer Saved!")
            if (!finalSubmit) {
              const index = currentIndex < palateData.length ? currentIndex + 1 : currentIndex;
              dispatch(setCurrentIndex(index));
              pageLoaderCallback(true);
              dispatch(fetchTestQuestions(pageLoaderCallback));
            } else {
              history.push("/student-test-summary");
            }
            dispatch(clearImageList());
          }
        });
  };
};

export const fetchResult = (callback) => {
  return (dispatch, getState) => {
    const { selectedTest,filter,cards } = getState().studentPracticeTest;
    const params = {
      
    };
    return Api.get(`/practice-test/student/get-result/${selectedTest?.testId}`)
      .params(params)
      .send((response, error) => {
        callback();
        if (response !== undefined) {
          dispatch(updateTestResult(response));
          dispatch(setFilter('selectedResult',response));
        }
      });
  };
};

export const fetchPerformance = (callback) => {
  return (dispatch, getState) => {
    const { selectedTest,filter,cards } = getState().studentPracticeTest;
    const params = {
    };
    return Api.get(`/practice-test/student/get-performance/${selectedTest?.testId}`)
      .params(params)
      .send((response, error) => {
        if (response !== undefined) {
          dispatch(updateTestPerformance(response));
          dispatch(setFilter('selectedPerformance',response))
          callback();
        }
      });
  };
};

export const updateTestResult = (data) => ({
  type: UPDATE_TEST_RESULT,
  data,
});


export const updateTestPerformance = (data) => ({
  type: UPDATE_TEST_PERFORMANCE,
  data,
});

// export const fetchSolution = (callback=null) => {
//   return (dispatch, getState) => {
//     const { selectedTest,filter,cards } = getState().studentPracticeTest;
//     const params = {
//     };
//     return Api.get(`/practice-test/student/get-solution/${selectedTest?.testId}`)
//       .params(params)
//       .send((response, error) => {
//         if(callback){
//           callback();

//         }
//         if (response !== undefined) {
//           dispatch(updateTestSolution(response));
//           dispatch(setFilter('selectedSolution',response))
//         }
//       });
//   };
// };

// export const updateTestSolution = (data) => ({
//   type: UPDATE_TEST_SOLUTION,
//   data,
// });

export const updateQuestionByTestData = (key,value) => ({
  type: UPDATE_QUESTION_BY_TEST,
  key,
  value
})


export const setFilter = (key, val) =>({
  type: SET_FILTER,
  key,
  val
}) 

export const setCards = (key, val) =>({
  type: SET_CARDS,
  key,
  val
}) 

export const submitAnswer = (
  dir,
  buttonLoaderCallBack,
  pageLoaderCallback
) => {
  return (dispatch, getState) => {
    const { currentQ, currentDuration,referDuration } =
      getState().studentPracticeTest;
    const params = {
      answer: currentQ?.currentAns || null,
      testQuestionId: currentQ?.id,
      duration: referDuration?(currentDuration - referDuration) : currentDuration,
      submitStatus: currentQ?.status
        ? currentQ?.status
        : currentQ?.attempt?.submitStatus
        ? currentQ?.attempt?.submitStatus
        : "Unanswered",
    };
    return Api.post(`/practice-test/student/submit-ans`)
      .params(params)
      .send((response, error) => {
        buttonLoaderCallBack(false);
        if (response !== undefined) {
          // const index = dir==='1'?1: dir === "next" ? currentIndex + 1 : currentIndex - 1;
          // dispatch(setCurrentIndex(index));
          pageLoaderCallback(true);
          // dispatch(fetchTestQuestions(pageLoaderCallback));
          dispatch(clearImageList());
          // Notification.success("success", "Answer Saved!")
        }
      });
  };
};

export const setLoader = (key, val) => ({
  type: SET_LOADER,
  key,
  val
})

export const clearQuestion = () => ({
  type:CLEAR_QUESTION,

})

export const toggleReloader = () => ({
  type: RELOAD_TOGGLER
})

export const referDuration = (seconds)=> ({
  type:REFER_DURATION,
  seconds:seconds??0
})