import { BellOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import React from 'react'

function TitleAndDescription({ title, description, font16, color, typography }) {
    return (
        <div className='font'>
            {typography ?
                <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#636363", fontSize: 12, width: "100%" }}>{title}</Typography.Text>
                :
                <div style={{ color: "#636363", fontSize: 12 }}>{title}</div>
            }
            <div style={{ color: color ? color : "#191919", fontSize: font16 ? 16 : 14 }}>{description}</div>
            {/* <div style={{ color: "red", fontSize: font16 ? 16 : 14 }}>{description} <span className='m-l-10 m-r-5'><BellOutlined style={{ color: "#FFA931" }} /></span><span style={{ color: "#FFA931" }}>{count}</span></div> */}
        </div>
    )
}

export default TitleAndDescription