import React from 'react'
import {connect} from 'react-redux';



function OverallStatusCard({operatorStats}) {
    return (
        <div className="full-width full-height">
            <h3 className='overallStatusCard-heading m-b-20'>Overall stats</h3>
            <div className="full-height r-jsb-fc">
                <div className="adminStatusCard-rows">
                    <div><img style={{width:"50px" ,height:"50px",marginRight:"15px"}}  src={require('Assets/test/ic-test-created.svg').default} alt="" />
                    </div>
                    <div className="r-c-sb" style={{width:"300px"}}>
                        <div className="ca-r-c-c-fs">
                            <h2 className='overallStatusCard-h1 m-b-0' style={{fontSize:"16px"}}>{operatorStats.totalTestsCreated?operatorStats.totalTestsCreated:0}</h2>
                            <p style={{color:"#636363", fontSize:12}}>Total test(s) created</p>
                        </div>
                        <div className="adminlastWeekConversation-p" style={operatorStats.createFromLastWeek&&operatorStats.createFromLastWeek>=0?{}:{color:"red"}}>
                            {operatorStats.createFromLastWeek && operatorStats.createFromLastWeek+' from last week'}
                        </div>
                    </div>
                </div>

                <div className="adminStatusCard-rows">
                    <div><img style={{width:"50px" ,height:"50px",marginRight:"15px"}}  src={require('Assets/test/ic-test-published.svg').default} alt="" />
                    </div>
                    <div className="r-c-sb" style={{width:"300px"}}>
                        <div className="ca-r-c-c-fs">
                            <h2 className='overallStatusCard-h1 m-b-0' style={{fontSize:"16px"}}>{operatorStats.totalTestsPublished?operatorStats.totalTestsPublished:0}</h2>
                            <p style={{color:"#636363", fontSize:12}}>Total test(s) created & published</p>
                        </div>
                        <div className="adminlastWeekConversation-p" style={operatorStats.publishedFromLastWeek&&operatorStats.publishedFromLastWeek>0?{}:{color:"red"}}>
                            {operatorStats.publishedFromLastWeek+' from last week'}
                        </div>
                    </div>
                </div>

                <div className="adminStatusCard-rows">
                    <div><img style={{width:"50px" ,height:"50px",marginRight:"15px"}}  src={require('Assets/test/ic-test-avg-time.svg').default} alt="" />
                    </div>
                    <div className="r-c-sb" style={{width:"300px"}}>
                        <div className="ca-r-c-c-fs">
                            <h2 className='overallStatusCard-h1 m-b-0' style={{fontSize:"16px"}}>{operatorStats.averageTimeSpentPerTest?operatorStats.averageTimeSpentPerTest:0}</h2>
                            <p style={{color:"#636363", fontSize:12}}>Average time per test</p>
                        </div>
                        <div className="adminlastWeekConversation-p" style={operatorStats.avgTimeFromLastWeek&&operatorStats.avgTimeFromLastWeek>0?{}:{color:"red"}}>
                            {operatorStats.avgTimeFromLastWeek+'% from last week'}
                        </div>
                    </div>
                </div>
            </div>
            </div>
    )
}
const mapStateToProps = state => {
    const {operatorStats} = state.testAdmin;
    return {operatorStats}
};
  
  const mapDispatchToProps = dispatch => ({
  });

export default connect(mapStateToProps, mapDispatchToProps)(OverallStatusCard)
