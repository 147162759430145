import { setAccess } from 'actions/schedule';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import ScheduleAdmin from "./scheduleAdmin"
import ScheduleStudent from './ScheduleStudent';
import ScheduleTeacher from './ScheduleTeacher';
const Schedule = ({ user,setAccess }) => {
    
    if (user.access.includes("schedule-admin")||user.access.includes("schedule-superadmin")||user.access.includes("schedule-planner")) {
        setAccess(true)
        return (
            <div>
                <ScheduleAdmin />
            </div>
        )
    }
    else if (user.access.includes("schedule-teacher")) {
        return (
            <div>
                <ScheduleTeacher />
            </div>
        )
    }
    else if (user.access.includes("schedule-student")) {
        
        return (
            <div>
                <ScheduleStudent />
            </div>
        )
    }

    else{
         setAccess(false)
        return(
            <div>
                <ScheduleAdmin/>
            </div>
        )
    }

}


const mapStateToProps = (state) => {
    const {
    } = state.schedule;
    const { user } = state.session;
    return {
        user
    };
};

const mapDispatchToProps = (dispatch) => ({
    setAccess : (bool) => dispatch(setAccess(bool))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(Schedule);

