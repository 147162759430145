import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateCurrentStd } from "actions/digitalLibrary"
import { Image, Typography } from 'antd';
import lectureNotes from 'Assets/digitalLibrary/lectureNotes.svg'
import videos from 'Assets/digitalLibrary/videos.svg'
import questionBank from 'Assets/digitalLibrary/question-bank.svg'
import { savePageCoordinates } from 'actions/layout';

function Divisioncomponent({
    std_section, notesCount, videoCount, quesBankCount, index, stds, section_arr, updateCurrentStd, std_stream,
    savePageCoordinates, location, item, user, totalsize
}) {


    const history = useHistory();
    const viewStdDetails = (data, stds) => {
        data.standard = stds;
        updateCurrentStd(data);
        console.log("AA_location", location);
        history.push({
            pathname: "/digital-library/dashbord-subject",
            state: {
                fromBrodcastVR:
                {
                    sections: location?.state?.fromBrodcastVR?.sections,
                }
            }
        })

        savePageCoordinates(document.getElementById('mainBody').scrollLeft, document.getElementById('mainBody').scrollTop)
    };
    console.log("item098", item);

    const totalSize = parseInt(totalsize) || 0;
    const totalSizeInMB = (totalSize / 1048576).toFixed(2);
    const totalSizeInGB = (totalSize / 1073741824).toFixed(2);
    const displaySize = totalSize === 0 
        ? '0 MB' 
        : (totalSizeInGB > 1 ? `${totalSizeInGB} GB` : `${totalSizeInMB} MB`);

    return (
        <div style={{marginLeft:10}}>
            {/* <div className='lecturenotesmain cursor-pointer'
            style={{ width: '', marginRight: '1%', marginBottom: '1%', height: '90px', padding: '2%',borderRadius:"17px",marginRight:"3px"}}
            key={index}
            onClick={() => viewStdDetails(section_arr[stds[0]][index], stds[0])}
        >
            <div style={{padding:`${std_stream === ''  || std_stream === 'NA'  ? '15px' : ''}`,paddingLeft:`${std_stream === ''  || std_stream === 'NA'  ? '20px' : '20px'}`}}>
                <div style={{ fontSize: '30px', fontFamily: 'Roboto',fontWeight:"bold", }}>{std_section}</div>
                <div style={{fontSize:'12px'}}>{ std_stream === 'NA'?  null: std_stream}</div>
            </div>
        </div> */}
            <div className='lecturenotesmain cursor-pointer'
                style={{ height:(user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) ? 115 : '90px', padding: std_stream == 'NA' ? 20 : 10, paddingLeft: std_stream == 'NA' ? 20 : 20, position: 'relative' }}
                key={index}
                onClick={() => viewStdDetails(section_arr[stds[0]][index], stds[0])}>
                <div style={{ fontSize: '30px', fontFamily: 'Roboto', fontWeight: "bold", }}>{std_section}</div>
                <div style={{ marginTop: '-5px' }}>
                    <Typography.Text
                        ellipsis={{ tooltip: true }}
                        style={{
                            fontSize: "12px",
                            width: 200
                        }}
                    >
                        {std_stream === 'NA' ? null : std_stream}
                    </Typography.Text>
                </div>
                {(user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) ?
                    <div style={{ position: "absolute", display: "flex", justifyContent: 'flex-start', height: 30, width: '100%', background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 5%, rgba(0, 0, 0, 1) 110%)", padding: "0px 10px", alignItems: "center", borderRadius: "0px 0px 8px 8px", bottom: 0, right: 0 }}>
                        <div style={{ color: "#FFFFFF", fontWeight: "bold" }}> {displaySize} data</div>
                    </div> : <></>}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "3px", width: "255px" }}>
                <div ><span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {notesCount ? notesCount : 0}  </span><span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Notes</span> <span style={{ fontSize: "13px", color: "#AEAEAE" ,marginLeft:"6px"}}>|</span></div>
                <div> <span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {videoCount ? videoCount : 0}  </span><span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Videos</span> <span style={{ fontSize: "13px", color: "#AEAEAE",marginLeft:"6px" }}>|</span></div>
                <div> <span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {quesBankCount ? quesBankCount : 0}  </span> <span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}>Test papers</span></div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {
        classLibraryList,
        classSubjectList,
        selectedSubject,
        selectedStd
    } = state.digitalLibrary;
    const { user } = state.session
    return {
        classLibraryList,
        classSubjectList,
        selectedSubject,
        selectedStd,
        user
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateCurrentStd: (data) => dispatch(updateCurrentStd(data)),
    savePageCoordinates: (x, y) => dispatch(savePageCoordinates(x, y))
});

export default connect(mapStateToProps, mapDispatchToProps)(Divisioncomponent);
