import { ArrowLeftOutlined, DownloadOutlined, MinusOutlined } from "@ant-design/icons";
import { getAllSettlements, setFeeDetails } from "actions/feeManagement";
import { Pagination } from "antd";
import axios from "axios";
import PageDataLoader from "components/pageDataLoader";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import SettlementDetailsModal from "./settlementDetailsModal";
import PageHeader from "components/pageHeader";

function AllSettlements({
  getAllSettlements,
  feeMangementData,
  setFeeDetails,
}) {
  const [showSettlementModal, setShowSettlementModal] = useState(false);
  const [data, setData] = useState(null);
  const [pageLoader, setPageLoader] = useState(false);

  const history = useHistory();

  const handleBackPress = () => {
    history.goBack();
  }
  
  const onPageChange = (e) => {
    console.log(e);
    setPageLoader(true);

    getAllSettlements(e, () => {
      setPageLoader(false);
    });
  };
  useEffect(() => {
    setPageLoader(true);
    getAllSettlements(1, () => {
      setPageLoader(false);
    });
  }, []);
  useEffect(() => {
    setData(feeMangementData?.allSettlements);
  }, [feeMangementData?.allSettlements]);

 

  return (
    <div>
        <PageHeader title={`Settlements (${feeMangementData?.allSettlements?.items?.length})`} onBackPress={()=>handleBackPress()}/>
      <div>
        <table style={{ marginTop: "60px", width: "100%" }}>
          <thead className="tablehead">
            <tr
              style={{
                backgroundColor: "#F6F4FE",
                padding: "20px",
                height: "50px",
                color: "#636363",
              }}
            >
              <th style={{ width: "20%", fontSize: "13px" }}>
                TRANSACTION DETAILS
              </th>
              <th style={{ width: "20%", fontSize: "13px" }}>DATE RANGE</th>
              <th style={{ width: "20%", fontSize: "13px" }}>
                SETTLEMENT DATE
              </th>
              {/* <th style={{ width: "20%", fontSize: "13px" }}>TRANSACTION ID</th> */}
              <th style={{ width: "20%", fontSize: "13px" }}>STATUS</th>
              <th style={{ width: "20%", fontSize: "13px" }}>ACTION</th>
            </tr>
          </thead>
          {feeMangementData?.allSettlements?.items?.map((item, index) => (
            <tr
              onClick={() => {
                if(item?.status=="Settled"){
                  setFeeDetails("selectedSettlement", item);
                  setShowSettlementModal(true);
                }
              }}
              style={{
                padding: "20px",
                height: "60px",
                width: "100%",
                backgroundColor: index % 2 ? "#FAFAFA" : "white",
                cursor:item?.status=="Settled"? "pointer":null,
              }}
            >
              <td
                style={{
                  width: "20%",
                  // justifyContent: "center",
                  // backgroundColor: "red",
                  paddingLeft: "30px",
                }}
                //   className="flex_row_center"
              >
                <div style={{ paddingLeft: "10px" }}>
                  <div style={{ fontWeight: "650" }}>₹ {item?.total}</div>
                  <div style={{ fontSize: "11px" }}>
                    {item?.noOftransactions} Transactions
                  </div>
                </div>
              </td>
              <td
                style={{ width: "20%", textAlign: "center", fontSize: "13px" }}
              >
                {moment(item?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(item?.endDate).format("DD/MM/YYYY")}
              </td>
              <td
                style={{ width: "20%", textAlign: "center", fontSize: "13px" }}
              >
                {item?.closedDate?moment(item?.closedDate).format("DD/MM/YYYY, hh:mm a"):<MinusOutlined />}
              </td>
              <td
                style={{ width: "20%", textAlign: "center", fontSize: "13px",textTransform: "uppercase"}}
              >
                {item?.status}
                {/* {item?.transactionId} */}
              </td>
              <td style={{ width: "20%" }}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if(item?.status=='Settled'){
                      axios
                        .get(item?.invoice, {
                          responseType: "blob",
                        })
                        .then((res) => {
                          fileDownload(res.data, "Settlemt.pdf");
                        });
                    }
                  }}
                  style={{
                    backgroundColor: index % 2 ? "white" : "#E7F3FF",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    textAlign: "center",
                    margin: "auto",
                    cursor:item?.status=='Settled'?"pointer":"not-allowed"
                  }}
                >
                  <DownloadOutlined
                    style={{
                      color: "#1089FF",
                      fontSize: "15px",
                      marginTop: "5px",
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </table>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div>
            Showing{" "}
            {data?.meta?.currentPage == 1
              ? 1
              : (data?.meta?.currentPage - 1) * data?.meta?.itemsPerPage}{" "}
            -{" "}
            {data?.meta?.currentPage * data?.meta?.itemsPerPage >
            data?.meta?.totalItems
              ? data?.meta?.totalItems
              : data?.meta?.currentPage * data?.meta?.itemsPerPage}{" "}
            of {data?.meta?.totalItems}
          </div>
          <Pagination
            current={data?.meta?.currentPage}
            onChange={(e) => {
              onPageChange(e);
            }}
            defaultCurrent={1}
            total={data?.meta?.totalPages * 10}
            showSizeChanger={false}
          />
        </div>
      </div>
      {showSettlementModal && (
        <SettlementDetailsModal
          isModalVisible={showSettlementModal}
          setShowSettlementModal={setShowSettlementModal}
        />
      )}
      <PageDataLoader visible={pageLoader} />
    </div>
  );
}

// export default AllSettlements;
const mapStateToProps = (state) => {
  const { feeMangementData, allActivitiesFilter } = state.feeManagement;
  return {
    feeMangementData,
    allActivitiesFilter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // fetchTransaction: (callback) => dispatch(fetchTransaction(callback)),
  // setAllActivitiesFilter: (key, res) =>
  //   dispatch(setAllActivitiesFilter(key, res)),
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
  getAllSettlements: (page, callback) =>
    dispatch(getAllSettlements(page, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllSettlements);
