import {
  ArrowLeftOutlined,
  CameraFilled,
  CheckOutlined,
  LoadingOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  PageHeader,
  Progress,
  Row,
  Select,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import image from "Assets/user-management/Group 19.png";
import PhoneInput from "react-phone-number-input";
import Date_Picker from "./datePicker";
import DeleteModal from "./deleteModal";
import { connect } from "react-redux";
import { IMG_URL } from "../../env.json";
import PageDataLoader from "components/pageDataLoader";
import userAvatar from "Assets/user-management/Icon awesome-user-alt.svg";
import { getLeavesTabData, storeTabData,setBackToPrivilegedView, setClickedOnUserCard, getWebClockIn } from "actions/attendanceNew";

// import notification from "pages/notification/notification";

import {
  deleteUserById,
  editUserInfo,
  getStudentsclass,
  getStudentsDetailsById,
  getStudentsRollNo,
  setClass,
  setClassId,
  setEditBloodGroup,
  setEditCurrentAddress,
  setEditCurrentCountry,
  setEditCurrentDistrict,
  setEditCurrentPinCode,
  setEditCurrentState,
  setEditDateOfBirth,
  setEditEmail,
  setEditEmergencyName,
  setEditEmergencyPhone,
  setEditEmergencyRelation,
  setEditGender,
  setEditName,
  setEditPermanentAddress,
  setEditPermanentCountry,
  setEditPermanentDistrict,
  setEditPermanentPinCode,
  setEditPermanentState,
  setEditPhone,
  setEditPrivilages,
  setEditRole,
  setEmail,
  setNextRollNo,
  setPhone,
  setRollNumber,
  setUserName,
  fetchPinCodeDetails,
  sendRemaider,
  deleteProfilePic,
  setUserDetailsById,
  assignCourseToStudent,
  getCourseDetalis,
  setCourseDetails,
  setSpecificUserId,
} from "actions/userManagement";
import { CountryDropdown } from "react-country-region-selector";
import moment from "moment";
import EditProfilePic from "./editProfilePic";
import { Notification } from "services";
import CourseDetails from "./courseDetails";
import StudentFeeDetails from "./studentFeeDetails";
import { getFeeOverView, setOverviewFilter } from "actions/feeManagement";
import FeeDetails from "./feeDetails";
import LeavesDashBoard from "pages/attendanceNew/leavesDashBoard";
import AttendanceView from "pages/attendanceNew/attendanceView";
import AttendanceTabDetails from "pages/attendanceNew/attendanceTabDetails";
// import AttendanceDetails from "pages/attendanceNew/attendanceTab";
import StudentProfileDetail from "pages/attendanceNew/studentProfileDetail";
import SettingModal from "pages/attendanceNew/Modals/settingModal";
// import dashboardByRole from "./dashboardByRole";
// import { useWindowScroll } from "react-use";
// import { Link } from "react-scroll";
const { Option } = Select;

var key = 0;
const setHeading = (setKeys, str) => {
  const element = document.getElementById(str);

  const { top } = element ? element.getBoundingClientRect() : 200;

  const screenHeight = window.innerHeight;

  if (top > screenHeight) {
  } else {
    if (str == "basicInfo" && 150 < top && top < 500) {
      key = 1;
      if (setKeys) {
        setKeys(1);
      }
    }
    if (str == "Privilages" && 10 < top && top < 500) {
      key = 2;
      if (setKeys) {
        setKeys(2);
      }
    }
    if (str == "PermanentAddress" && 150 < top && top < 500) {
      key = 3;
      if (setKeys) {
        setKeys(3);
      }
    }

    if (str == "currentAddress" && 150 < top && top < 500) {
      key = 3;
      if (setKeys) {
        setKeys(3);
      }
    }
    if (str == "emergencyInfo" && 150 < top && top < 700) {
      key = 4;
      if (setKeys) {
        setKeys(4);
      }
    }
  }
};

const goBack = (history) => {
  history.goBack();
};

function UserDetailsForm({
  sendRemaider,
  tab,
  setEditName,
  editName,
  editRole,
  setEditRole,
  editPhone,
  setEditPhone,
  editEmail,
  setEditEmail,
  editGender,
  setEditGender,
  editBloodGroup,
  setEditBloodGroup,
  editDateOfBirth,
  setEditDateOfBirth,
  setEditPrivilages,
  editPrivilage,
  editCurrentAddress,
  editCurrentPinCode,
  editCurrentState,
  editCurrentDistrict,
  editCurrentCountry,
  editPermanentAddress,
  editPermanentPinCode,
  editPermanentState,
  editPermanentDistrict,
  editPermanentCountry,
  editEmergencyName,
  editEmergencyPhone,
  editEmergencyRelation,
  setEditEmergencyName,
  setEditEmergencyPhone,
  setEditEmergencyRelation,
  setEditCurrentAddress,
  setEditCurrentPinCode,
  setEditCurrentState,
  setEditCurrentDistrict,
  setEditCurrentCountry,
  setEditPermanentAddress,
  setEditPermanentPinCode,
  setEditPermanentState,
  setEditPermanentDistrict,
  setEditPermanentCountry,
  getStudentsDetailsById,
  userDetailsById,
  editUserInfo,
  dashBoardRole,
  deleteUserById,
  getStudentsclass,
  classList,
  setClassId,
  setNextRollNo,
  getStudentsRollNo,
  nextRollNo,
  classId,
  fetchPinCodeDetails,
  user,
  deleteProfilePic,
  setUserDetailsById,
  studentCourseDetails,
  assignCourseToStudent,
  getCourseDetalis,
  getFeeOverView,
  setOverviewFilter,
  setCourseDetails,
  storeTabData,
  getLeavesTabData,
  setBackToPrivilegedView,
  setClickedOnUserCard,
  storeIsClickedOnUserCard,
  setSpecificUserId,
  getWebClockIn
}) {
  const [keys, setKeys] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [saveButtonLoader, setSaveButtonLoader] = useState(false);
  const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [scroll, setScroll] = useState(0);
  const inputProfilePic = useRef(null);
  const [fileInputValue, setfileInputValue] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [currentAddLocker, setCurrentAddLocker] = useState(true);
  const [permanentAddLocker, setPermanentAddLocker] = useState(true);
  const [showPinMsg, setShowPinMsg] = useState(false);
  const [showPermanentPinMsg, setShowPermanentPinMsg] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [feedBackBtnLoader, setFeedBackBtnLoader] = useState(false);
  const [checkPermanent, setCheckPermanent] = useState(false);
  const [emailValidCheck, setEmailValidCheck] = useState(true);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [isSameCurrentParmanentAdd, setIsSameCurrentParmanentAdd] =
    useState(false);
  const [isAdminSelected, setIsAdminSelected] = useState(false);
  const [deletePicLoader, setDeletePicLoader] = useState(false);
  const [userDetailsSaved, setUserDetailsSaved] = useState(
    userDetailsById?.image != "default.png" ? 1 : 0
  );
  const [courseAndFeeDetails, setCourseAndFeeDetails] = useState(false);
  const [feeDetails, setFeeDetails] = useState(false);
  const [studentAttendanceDetails, setStudentAttendanceDetails]=useState(false)
  const [transactionDetails, setTransactionDetails] = useState(false);
  const [attendanceDetails,setAttendanceDetails] = useState(false);
  const [attendanceDetail, setAttendanceDetail] = useState(false);
  const [basicInfoDetails, setBasicInfoDetails] = useState(true);
  const [fieldDisabledForFee, setFieldDisabledForFee] = useState(false);
  const [disableSuperAdmin, setDisableSuperAdmin] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(true);
  const [leavesTab,setLeavesTab] = useState(false);
  const [reload, setReload] = useState(true)
  const [isSettingModalOpen,setIsSettingModalOpen]=useState(false)

  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(location?.state?.tab ? location?.state?.tab : 1);

  useEffect(() => {
    if (selectedTab == 'fee') {
      setFeeDetails(true);
      setCourseAndFeeDetails(false);
      setTransactionDetails(false);
      setBasicInfoDetails(false);
      setShowSaveBtn(false);
      setLeavesTab(false)
      setStudentAttendanceDetails(false);
      setAttendanceDetail(false);
    }
    if (selectedTab == 'leave') {
      setStudentAttendanceDetails(false);
      setCourseAndFeeDetails(false);
      setTransactionDetails(false);
      setFeeDetails(false);
      setBasicInfoDetails(false);
      setShowSaveBtn(false);
      setAttendanceDetail(false);
      storeTabData("Leaves")
      setLeavesTab(true)
    }
    if (selectedTab == 'attendance') {
      setTransactionDetails(false);
      setCourseAndFeeDetails(false);
      setFeeDetails(false);
      setBasicInfoDetails(false);
      setShowSaveBtn(false);
      if (editRole != 'Student') {
        setAttendanceDetail(true);
      }else if (editRole == 'Student') {
        setStudentAttendanceDetails(true);
      }
      storeTabData("Attendance")
      setLeavesTab(false);
      
    }
  }, [editRole])

  useEffect(() => {
    let temp = false;
    temp =
      user?.privilage.includes("SuperAdmin") ||
      user?.privilage.includes("Admin");
    setFieldDisabledForFee(!temp);
  }, []);
  useEffect(() => {
    if (
      (editPrivilage?.includes("SuperAdmin") &&
        !user?.privilage?.includes("SuperAdmin")) /* ||
      (editPrivilage?.includes("Admin") && user?.privilage?.includes("Admin")&&(userDetailsById?.id!=user?.id)) */
    ) {
      setDisableSuperAdmin(true);
    } else {
      setDisableSuperAdmin(false);
    }
  }, [editPrivilage]);
  // const { y: pageYOfset } = useWindowScroll();

  const basicInfoRef = useRef(null);
  const previlageRef = useRef(null);
  const addressRef = useRef(null);
  const emergencyRef = useRef(null);
  const formCntr = useRef(null);

  // useEffect(() => {
  //   setUserDetailsById({});
  // }, []);
  // const disabledFunction = () => {
  //   if (
  //     user?.privilage?.includes("Admin") ||
  //     user?.privilage?.includes("SuperAdmin")
  //   ) {
  //     return false;
  //   } else {
  //     true;
  //   }
  // };
  const [basicInfoDetailsDisable, setBasicInfoDetailsDisable] = useState(true);
  useEffect(() => {
    setOverviewFilter("type", "week");
  }, []);

  useEffect(() => {
    setBasicInfoDetailsDisable(
      user?.privilage?.includes("Admin") ||
        user?.privilage?.includes("SuperAdmin")
        ? false
        : true
    );
  }, [editRole]);
  useEffect(() => {
    if (editPrivilage?.includes("Admin")) {
      setIsAdminSelected(true);
      // const index = array.indexOf(5);
      if (editPrivilage.indexOf("Planner") > -1) {
        editPrivilage.splice(editPrivilage.indexOf("Planner"), 1);
      }
      if (editPrivilage.indexOf("InstituteSupport") > -1) {
        editPrivilage.splice(editPrivilage.indexOf("InstituteSupport"), 1);
      }
      // if (editPrivilage.indexOf("AttendanceMarker") > -1) {
      //   editPrivilage.splice(editPrivilage.indexOf("AttendanceMarker"), 1);
      // }
    } else {
      setIsAdminSelected(false);
    }
  }, [editPrivilage]);

  const handlePinUpdate = (e) => {
    if (e.target.value.length <= 6 && Number(e.target.value)) {
      setEditCurrentPinCode(e.target.value.trim());
      fetchPinCodeDetails(e.target.value.trim(), pinUpdateCallback);
      if (isSameCurrentParmanentAdd) {
        setEditPermanentPinCode(e.target.value !== " " ? e.target.value : null);
      }
    }

    if (e.target.value == "") {
      setEditCurrentDistrict(null);
      setEditCurrentState(null);
      setEditCurrentPinCode(null);
      if (isSameCurrentParmanentAdd) {
        setEditPermanentPinCode(null);
        setEditPermanentState(null);
        setEditPermanentDistrict(null);
      }
    }
  };
  const handlePermanentPinUpdate = (e) => {
    if (e.target.value.length <= 6 && Number(e.target.value)) {
      setEditPermanentPinCode(e.target.value.trim());
      fetchPinCodeDetails(e.target.value.trim(), PermanentpinUpdateCallback);
    }
    if (e.target.value == "") {
      setEditPermanentDistrict(null);
      setEditPermanentState(null);
      setEditPermanentPinCode(null);
      setShowPinMsg(false);
    }
  };

  const pinUpdateCallback = (response) => {
    if (response?.Status === "Success") {
      const details = response.PostOffice[0];
      // setEditCurrentAddress(response.PostOffice[0].Name);
      setEditCurrentDistrict(response.PostOffice[0].District);
      setEditCurrentState(response.PostOffice[0].State);
      setEditCurrentCountry(response.PostOffice[0].Country);

      setShowPinMsg(false);
      if (isSameCurrentParmanentAdd) {
        setEditPermanentDistrict(response.PostOffice[0].District);
        setEditPermanentState(response.PostOffice[0].State);
        setEditPermanentCountry(response.PostOffice[0].Country);
        setShowPermanentPinMsg(false);
      }
    } else {
      setShowPinMsg(true);
      setEditCurrentDistrict(null);
      setEditCurrentState(null);
      // setEditCurrentCountry(null);
      if (isSameCurrentParmanentAdd) {
        setEditPermanentDistrict(null);
        setEditPermanentState(null);
        // setEditPermanentCountry(null);
        // setShowPermanentPinMsg(false);
      }
    }
  };

  const PermanentpinUpdateCallback = (response) => {
    if (response?.Status === "Success") {
      const details = response.PostOffice[0];
      // setEditPermanentAddress(response.PostOffice[0].Name);
      setEditPermanentDistrict(response.PostOffice[0].District);
      setEditPermanentState(response.PostOffice[0].State);
      setEditPermanentCountry(response.PostOffice[0].Country);
      setShowPermanentPinMsg(false);
    } else {
      setShowPermanentPinMsg(true);
    }
  };

  function onChange(checkedValues) {
    // console.log(`checked = ${checkedValues[0]}`);
    // let str = checkedValues;

    setEditPrivilages(checkedValues);
  }
  const onChangeCurrentPinCode = (e) => {
    setCurrentAddLocker(!currentAddLocker);
    if (currentAddLocker) {
      setEditCurrentPinCode("");
      setShowPinMsg(false);
    }

    if (isSameCurrentParmanentAdd) {
      setCheckPermanent(!checkPermanent);
      setEditPermanentPinCode("");
    }
  };
  const onChangePermanentPinCode = (e) => {
    setCheckPermanent(!checkPermanent);

    setPermanentAddLocker(!permanentAddLocker);
    if (permanentAddLocker) {
      setEditPermanentPinCode("");
      setShowPermanentPinMsg(false);
      // setPermanentAddLocker(permanentAddLocker);
    }
  };

  function handleClassSelect(value) {
    // console.log(`selected ${value}`);
    setClassId(value);
    setNextRollNo(null);
    // setPrivilage(["dummy"]);
    setSubmitDisable(true);
    getStudentsRollNo(() => setSubmitDisable(false), true);
  }
  function parseData(data) {
    // console.log("parsedata", data);
    if (!data) return {};
    if (typeof data === "object") return data;
    if (data[0] === "{") {
      if (typeof data === "string") return JSON.parse(data);
    }
  }
  useEffect(() => {
    setIsSameCurrentParmanentAdd(
      parseData(userDetailsById?.basicprofile?.permanentAddress)?.sameAsCa
    );

    if (
      parseData(userDetailsById?.basicprofile?.currentAddress)?.PinAvailable !==
      undefined
    ) {
      setCurrentAddLocker(
        !parseData(userDetailsById?.basicprofile?.currentAddress)?.PinAvailable
      );
    }

    setCheckPermanent(
      parseData(userDetailsById?.basicprofile?.permanentAddress)?.PinAvailable
    );

    setUserDetailsSaved(userDetailsById?.image != "default.png" ? 1 : 0);
  }, [userDetailsById]);
  const handleCurrentPermanentAdd = (e) => {
    if (e.target.checked) {
      setIsSameCurrentParmanentAdd(true);
      setShowPermanentPinMsg(false);
      setEditPermanentAddress(editCurrentAddress);
      setEditPermanentPinCode(editCurrentPinCode);
      setEditPermanentState(editCurrentState);
      setEditPermanentDistrict(editCurrentDistrict);
      setEditPermanentCountry(editCurrentCountry);
      setCheckPermanent(!currentAddLocker);
      // setPermanentAddLocker(currentAddLocker)
    } else {
      setIsSameCurrentParmanentAdd(false);

      setEditPermanentAddress(
        parseData(userDetailsById?.basicprofile?.permanentAddress)?.h_no
      );
      setEditPermanentPinCode(
        parseData(userDetailsById?.basicprofile?.permanentAddress)?.pincode
      );
      setEditPermanentState(
        parseData(userDetailsById?.basicprofile?.permanentAddress)?.state
      );
      setEditPermanentDistrict(
        parseData(userDetailsById?.basicprofile?.permanentAddress)?.city
      );
      setEditPermanentCountry(
        parseData(userDetailsById?.basicprofile?.permanentAddress)?.country
      );
      setCheckPermanent(false);
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCallback = () => {
    setSaveButtonLoader(false), updateProgress();
  };
  const handleSuccessCallback = () => {
    setPageLoader(true);
    getStudentsDetailsById(() => setPageLoader(false));
    setImageUrl("");
  };
  const handleSave = () => {
    // console.log(imageUrl);
    var email =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const val = /.*[a-zA-Z]+.*/.test(editCurrentDistrict);
    // console.log("check district", !val, editCurrentDistrict);
/*     if (showPinMsg || showPermanentPinMsg) {
      Notification.error("Error", "Enter a valid pincode");
    } else */ if (
      (
        !(editCurrentDistrict === null ||editCurrentDistrict === '') &&
        !/.*[a-zA-Z]+.*/.test(editCurrentDistrict)
      ) || 
      (
        !(editPermanentDistrict === null ||editPermanentDistrict === '') &&
        !/.*[a-zA-Z]+.*/.test(editPermanentDistrict)
      )
    ) {
      Notification.error("Error", "Enter a valid district");
    } else if (
      editEmail != null &&
      editEmail != "" &&
      !editEmail?.match(email)
    ) {
      Notification.error("Error", "Enter a valid email id");
    } else {
      if (imageUrl) {
        setUserDetailsSaved(1);
      }
      setSaveButtonLoader(true);
      // console.log(!currentAddLocker, isSameCurrentParmanentAdd, checkPermanent);
      // if (studentCourseDetails?.ApiC) {
      // console.log("Apic CALL");
      //   assignCourseToStudent(
      //     () => setSaveButtonLoader(false),
      //     () => getCourseDetalis(),
      //     true
      //   );
      // } else {
      editUserInfo(
        () => handleSuccessCallback(),
        () => handleCallback(),
        fileInputValue,
        !currentAddLocker,
        checkPermanent,
        isSameCurrentParmanentAdd,
        true
      );
      // }
    }
  };
  const handleSettingSave=()=>{
   
    getWebClockIn(userDetailsById?.id)
    setIsSettingModalOpen(true)
  }
  const userCourseUpdate = () => {
    editUserInfo(
      () => handleSuccessCallback(),
      () => handleCallback(),
      fileInputValue,
      !currentAddLocker,
      checkPermanent,
      isSameCurrentParmanentAdd,
      false
    );
  };
  const deleteSuccess = () => {
    setDeleteButtonLoader(false);
    history.goBack();
  };
  const handleOk = () => {
    setIsModalVisible(false);
    setDeleteButtonLoader(true);
    deleteUserById(() => deleteSuccess());
  };
  const handleProfileModalOk = () => {
    setEditProfileModal(false);
    inputProfilePic.current.click();
  };
  const profileDeleted = () => {
    setEditProfileModal(false);
    setDeletePicLoader(false);
    handleSuccessCallback();
  };
  const handleProfileModalCancel = () => {
    if (userDetailsSaved) {
      setDeletePicLoader(true);
      deleteProfilePic(() => profileDeleted());
      setUserDetailsSaved(0);
    }
    if (userDetailsSaved == 0) {
      setImageUrl("");
      setEditProfileModal(false);
    }
    // setEditProfileModal(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditProfileModal(false);
  };
  const history = useHistory();

  const element = document.getElementById("basicInfo");
  const handleScroll = () => {
    setScroll(scroll + 1);
  };

  const successCallBack = () => {
    // setEditName(userDetailsById?.name);
    setPageLoader(false);
  };
  useEffect(() => {
    // setEditPermanentCountry("India");
    setEditRole(dashBoardRole);
    setPageLoader(true);
    getStudentsDetailsById(() => successCallBack());

    updateProgress();
    return () => {
      setUserDetailsById("");
    };
  }, []);

  useEffect(() => {
    if (dashBoardRole == "Student" || editRole == "Student") {
      getStudentsclass();
      getFeeOverView();
    } else {
    }
  }, [editRole]);
  useEffect(() => {
    setHeading(setKeys, "PermanentAddress");
    setHeading(setKeys, "currentAddress");
    setHeading(setKeys, "basicInfo");
    setHeading(setKeys, "emergencyInfo");
    setHeading(setKeys, "Privilages");

    // console.log('useeffect')
  }, [scroll]);

  useEffect(() => {
    if(basicInfoDetails == true || leavesTab == true){
      setStudentAttendanceDetails(false);
    }else{
      setStudentAttendanceDetails(true);
    }
  }, [reload])
  
  useEffect(() => {
    if (progress < 101) {
      updateProgress();
    }
  }, [
    editName,
    editRole,
    editPhone,
    editEmail,
    editGender,
    editBloodGroup,
    editPrivilage,
    editEmergencyName,
    editEmergencyPhone,
    editEmergencyRelation,
    editCurrentAddress,
    editCurrentPinCode,
    editCurrentState,
    editCurrentDistrict,
    editCurrentCountry,
    editPermanentAddress,
    editPermanentPinCode,
    editPermanentState,
    editPermanentDistrict,
    editPermanentCountry,
    imageUrl,
    userDetailsById?.image,
    classId,
    editDateOfBirth,
    nextRollNo,
    currentAddLocker,
    permanentAddLocker,
  ]);
  const updateProgress = () => {
    let progress = 0;

    if (editDateOfBirth && editDateOfBirth !== "") {
      if (editRole === "Student") {
        progress = progress + 3;
      } else {
        progress = progress + 8;
      }
    }
    if (editName && editName !== "") {
      progress = progress + 2;
    }
    if (classId && classId !== "") {
      progress = progress + 3;
    }
    if (nextRollNo && nextRollNo != "") {
      progress = progress + 2;
    }
    if (editRole && editRole !== "") {
      progress = progress + 5;
    }
    if (editPhone && editPhone != "") {
      progress = progress + 5;
    }
    if (editEmail && editEmail != "") {
      progress = progress + 5;
    }
    if (editGender && editGender != "") {
      progress = progress + 5;
    }
    if (editBloodGroup && editBloodGroup != "") {
      progress = progress + 5;
    }
    // if (editPrivilage && editPrivilage != "") {
    //   progress = progress + 5;
    // }
    if (editEmergencyName && editEmergencyName != "") {
      progress = progress + 5;
    }
    if (
      editEmergencyPhone &&
      editEmergencyPhone != "" &&
      editEmergencyPhone != "na"
    ) {
      progress = progress + 5;
    }
    if (editEmergencyRelation && editEmergencyRelation != "") {
      progress = progress + 5;
    }
    if (editCurrentAddress && editCurrentAddress != "") {
      progress = progress + 5;
    }
    if (editCurrentPinCode && editCurrentPinCode != "") {
      progress = progress + 5;
    } else if (!currentAddLocker) {
      progress = progress + 5;
    }

    if (editCurrentState && editCurrentState != "") {
      progress = progress + 5;
    }
    if (editCurrentDistrict && editCurrentDistrict != "") {
      progress = progress + 5;
    }
    if (editCurrentCountry && editCurrentCountry != "") {
      progress = progress + 5;
    }
    if (editPermanentAddress && editPermanentAddress != "") {
      progress = progress + 5;
    }
    if (editPermanentPinCode && editPermanentPinCode != "") {
      progress = progress + 5;
    } else if (checkPermanent) {
      progress = progress + 5;
    }

    if (editPermanentState && editPermanentState != "") {
      progress = progress + 5;
    }
    if (editPermanentDistrict && editPermanentDistrict != "") {
      progress = progress + 5;
    }
    if (editPermanentCountry && editPermanentCountry != "") {
      progress = progress + 5;
    }
    // if (imageUrl && imageUrl != "") {
    //   progress = progress + 5;
    // }else
    // if(userDetailsById?.image!=='default.png'){
    //   progress = progress + 5;

    // }
    setProgress(progress);
    // return progress;
  };

  const onChangeFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    // var blob=new Blob([file])

    if (file != undefined) {
      const imgUrl = URL.createObjectURL(file);
      setfileInputValue(file);
      setImageUrl(imgUrl);

      // showModal();
    }
  };
  const handleSendRemainder = () => {
    setFeedBackBtnLoader(true);
    sendRemaider(() => setFeedBackBtnLoader(false));
  };

  const addProfileImg = () => {
    if (
      fieldDisabledForFee ||
      disableSuperAdmin ||
      editPrivilage?.includes("SuperAdmin")
    ) {
    } else {
      setEditProfileModal(true);
    }
    // inputProfilePic.current.click();
  };
  useEffect(() => {
    if (editEmail === "") {
      setEmailValidCheck(true);
    }
    var email =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (editEmail?.match(email)) {
      // console.log("correct email");
      setEmailValidCheck(true);
    } else {
      // console.log("incorrect email");
      if (editEmail) {
        setEmailValidCheck(false);
      }
    }
  }, [editEmail]);

  return (
    <div style={{ width: "100%", height: "100%", marginTop: "-20px" }}>
      <div style={{ height: "10px", marginLeft: "-25px" }}>
        <PageHeader
          title={<div>{userDetailsById?.name}</div>}
          onBack={() => {
            history.goBack();
            if(location?.state?.key === 1){
              history.push({
                pathname: '/profile',
                state: {
                    key: 9,
                }
              });
              setSpecificUserId(user?.id)
            }

            if(storeIsClickedOnUserCard===true){
            setBackToPrivilegedView(true);
            setClickedOnUserCard(false)
            }else{
              setBackToPrivilegedView(false)
            }
          }}
          backIcon={<ArrowLeftOutlined style={{ fontSize: "20px" }} />}
        />
        {/* <p>hi tyhtrdyguhii cfyuy gygiu</p> */}
      </div>
      <h1 style={{ backgroundColor: "red" }}> {}</h1>

      <div
        style={{
          width: "100%",
          marginTop: "50px",
          height: "95%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "30%",
            height: "100%",
            boxShadow: " 1px 1px 16px #E6E6E6",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginTop: "30px", marginLeft: "30px" }}>
            {editRole == "Student" ? null : (
              <h3
                style={{
                  cursor: "pointer",
                  borderRadius: "3px",
                  padding: "10px",
                  marginRight: "20px",
                  color: basicInfoDetails ? "#594099" : "#AEAEAE",
                  backgroundColor: basicInfoDetails ? "#F6F4FE" : "white",
                }}
                onClick={() => {
                  // basicInfoRef?.current?.scrollIntoView({ behavior: "smooth" });
                  setCourseAndFeeDetails(false);
                  setTransactionDetails(false);
                  setShowSaveBtn(true);
                  setLeavesTab(false);
                  setFeeDetails(false);
                  setAttendanceDetail(false);
                  setBasicInfoDetails(true);
                  setStudentAttendanceDetails(false);

                }}
                // onClick={() => formCntr.current.scrollTo({ top: 0, behavior: "smooth" })}
                // scrollIntoView
              >
                Basic information
              </h3>
            )}
            {editRole == "Student" ? (
              <h3
                style={{
                  cursor: "pointer",
                  borderRadius: "3px",
                  padding: "10px",
                  marginRight: "20px",
                  color: basicInfoDetails  ? "#594099" : "#AEAEAE",
                  backgroundColor: basicInfoDetails  ? "#F6F4FE" : "white",
                }}
                onClick={() => {
                  // basicInfoRef?.current?.scrollIntoView({ behavior: "smooth" });
                  setCourseAndFeeDetails(false);
                  setTransactionDetails(false);
                  setFeeDetails(false);
                  setBasicInfoDetails(true);
                  setShowSaveBtn(true);
                  setLeavesTab(false);
                  setAttendanceDetail(false);
                  setStudentAttendanceDetails(false);

                }}
                // onClick={() => formCntr.current.scrollTo({ top: 0, behavior: "smooth" })}
                // scrollIntoView
              >
                Basic information
              </h3>
            ) : null}
           {/*  <h3
              style={
                dashBoardRole === "Student" ||
                editRole == "Student" ||
                editPrivilage?.includes("SuperAdmin")
                  ? { display: "none" }
                  : {
                      cursor: "pointer",
                      borderRadius: "3px",
                      height: "50px",
                      marginRight: "20px",
                      color: keys === 2 ? "#594099" : "#AEAEAE",
                      backgroundColor: keys === 2 ? "#F6F4FE" : "white",
                      padding: "10px",
                      // display:
                      //   dashBoardRole === "Student" ||
                      //   editRole === "Student" ||
                      //   editPrivilage?.includes("SuperAdmin")
                      //     ? "none"
                      //     : "view",
                    }
              }
              onClick={() =>
                previlageRef?.current?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Privileges
            </h3> */}
           {/*  {editRole == "Student" ? null : (
              <h3
                style={{
                  cursor: "pointer",
                  borderRadius: "3px",
                  padding: "10px",
                  height: "50px",
                  marginRight: "20px",
                  color: keys === 3 ? "#594099" : "#AEAEAE",
                  backgroundColor: keys === 3 ? "#F6F4FE" : "white",
                }}
                onClick={() => {
                  addressRef?.current?.scrollIntoView({ behavior: "smooth" });
                  setCourseAndFeeDetails(false);
                }}
              >
                Address
              </h3>
            )}

            {editRole == "Student" ? null : (
              <h3
                style={{
                  cursor: "pointer",
                  borderRadius: "3px",
                  padding: "10px",
                  marginRight: "20px",
                  color: keys === 4 ? "#594099" : "#AEAEAE",
                  backgroundColor: keys === 4 ? "#F6F4FE" : "white",
                }}
                onClick={() => {
                  emergencyRef?.current?.scrollIntoView({ behavior: "smooth" });
                  setCourseAndFeeDetails(false);
                }}
              >
                Emergency contact
              </h3>
            )} */}
            {editRole == "Student" ? (
              <h3
                style={{
                  cursor: "pointer",
                  borderRadius: "3px",
                  padding: "10px",
                  marginRight: "20px",
                  color: courseAndFeeDetails ? "#594099" : "#AEAEAE",
                  backgroundColor: courseAndFeeDetails ? "#F6F4FE" : "white",
                }}
                onClick={() => {
                  // emergencyRef.current.scrollIntoView({ behavior: "smooth" })
                  setCourseAndFeeDetails(true);
                  setTransactionDetails(false);
                  setBasicInfoDetails(false);
                  setFeeDetails(false);
                  setShowSaveBtn(false);
                  setLeavesTab(false)
                  setStudentAttendanceDetails(false);
                  setAttendanceDetail(false);

                }}
              >
                Classroom & Course(s)
              </h3>
            ) : null}
            {editRole == "Student" && (user?.privilage?.includes("SuperAdmin")||user?.privilage?.includes("FeeManager")) ? (
              <h3
                style={{
                  cursor: "pointer",
                  borderRadius: "3px",
                  padding: "10px",
                  marginRight: "20px",
                  color: feeDetails ? "#594099" : "#AEAEAE",
                  backgroundColor: feeDetails ? "#F6F4FE" : "white",
                }}
                onClick={() => {
                  // emergencyRef.current.scrollIntoView({ behavior: "smooth" })
                  setFeeDetails(true);
                  setCourseAndFeeDetails(false);
                  setTransactionDetails(false);
                  setBasicInfoDetails(false);
                  setShowSaveBtn(false);
                  setLeavesTab(false)
                  setStudentAttendanceDetails(false);
                  setAttendanceDetail(false);

                }}
              >
                Fee Details
              </h3>
            ) : null}
             

            {editRole == "Student" && (user?.privilage?.includes("SuperAdmin")||user?.privilage?.includes("FeeManager")) ? (
              <h3
                style={{
                  cursor: "pointer",
                  borderRadius: "3px",
                  padding: "10px",
                  marginRight: "20px",
                  color: transactionDetails ? "#594099" : "#AEAEAE",
                  backgroundColor: transactionDetails ? "#F6F4FE" : "white",
                }}
                onClick={() => {
               // emergencyRef.current.scrollIntoView({ behavior: "smooth" })
                  getFeeOverView();
                  setCourseAndFeeDetails(false);
                  setTransactionDetails(true);
                  setFeeDetails(false);
                  setBasicInfoDetails(false);
                  setShowSaveBtn(false);
                  setLeavesTab(false)
                  setStudentAttendanceDetails(false);
                  setAttendanceDetail(false);

                }}
              >
                Transactions
              </h3>
            ) : null}
             
             
             <h3
                style={{
                  cursor: "pointer",
                  borderRadius: "3px",
                  padding: "10px",
                  height: "50px",
                  marginRight: "20px",
                  color: attendanceDetail || studentAttendanceDetails ? "#594099" : "#AEAEAE",
                  backgroundColor: attendanceDetail || studentAttendanceDetails? "#F6F4FE" : "white",
                }}
                onClick={() => {
                  setTransactionDetails(false);
                  setCourseAndFeeDetails(false);
                  setFeeDetails(false);
                  setBasicInfoDetails(false);
                  setShowSaveBtn(false);
                  if(editRole!='Student'){
                    setAttendanceDetail(true);
                  }
                  storeTabData("Attendance")
                  setLeavesTab(false);
                  setKeys(0);
                  getLeavesTabData()
                  if(editRole=='Student'){
                    setStudentAttendanceDetails(true);
                    setReload(!reload)
                  }

                }}
              >
              Attendance 
              </h3>
            {editRole!='Student'}
              <h3
                style={
                  editRole=='Student'?
                  {
                    display:"none"
                  }:
                  {
                  cursor: "pointer",
                  borderRadius: "3px",
                  height: "50px",
                  padding: "10px",
                  marginRight: "20px",
                  color: studentAttendanceDetails || leavesTab? "#594099" : "#AEAEAE",
                  backgroundColor: studentAttendanceDetails || leavesTab? "#F6F4FE" : "white",
                }}
                onClick={() => {
                  // emergencyRef.current.scrollIntoView({ behavior: "smooth" })
                  getFeeOverView();
                  setStudentAttendanceDetails(false);
                  setCourseAndFeeDetails(false);
                  setTransactionDetails(false);
                  setCourseAndFeeDetails(false);
                  setFeeDetails(false);
                  setBasicInfoDetails(false);
                  setShowSaveBtn(false);
                  setAttendanceDetail(false);
                  storeTabData("Leaves")
                  setKeys(0);
                  setLeavesTab(true)
                  getLeavesTabData()
                }}
              >
              Leaves
              </h3>


          </div>
          <div style={{ marginBottom: "10px" }}>
            <div style={{ width: "80%", margin: "auto" }}>
              <Progress
                strokeColor="#594099"
                percent={progress}
                showInfo={false}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
                margin: "auto",
              }}
            >
              <p style={{ color: "#AEAEAE" }}>
                {progress < "100" ? "In-progress" : "Completed"}
              </p>
              <p style={{ color: "#AEAEAE" }}>{progress}%</p>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
                margin: "auto",
              }}
            >
              <Button
                onClick={showModal}
                loading={deleteButtonLoader}
                disabled={
                  fieldDisabledForFee ||
                  disableSuperAdmin ||
                  editPrivilage?.includes("SuperAdmin") ||
                  (editPrivilage?.includes("Admin") &&
                    user?.privilage?.includes("Admin"))
                }
                style={{
                  border: "#AEAEAE solid 1px",
                  borderRadius: "20px",
                  width: "100px",
                  marginBottom: "5px",
                  height: "35px",
                  fontFamily: "Roboto",
                  textAlign: "center",
                  color: "#AEAEAE",
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingTop: "6px",
                }}
              >
                DELETE
              </Button>
              {progress !== 100 && false ? (
                <Button
                  loading={feedBackBtnLoader}
                  onClick={handleSendRemainder}
                  style={{
                    border: "#FFA931 solid 1px",
                    borderRadius: "20px",
                    width: "120px",
                    width: "fit-content",
                    height: "35px",
                    fontFamily: "Roboto",
                    textAlign: "center",
                    fontSize: "12px",
                    color: "#FFA931",
                    marginLeft: "10px",
                    fontWeight: "600",
                    paddingTop: "6px",
                  }}
                >
                  SEND REMINDER
                </Button>
              ) : null}
            </div>
          </div>
        </div>
       
        {showSaveBtn ? (
          <Button
            style={{
              borderRadius: "30px",
              width: "130px",
              height: "40px",
              color: "white",
              backgroundColor: "#594099",
              position: "absolute",
              right: "35px",
              marginTop: "-40px",
            }}
            onClick={() => {
              setCourseDetails("addedNotSaved", false);
              if (fieldDisabledForFee || disableSuperAdmin) {
              } else {
                handleSave();
              }
            }}
            loading={saveButtonLoader}
            disabled={
              submitDisable || fieldDisabledForFee || disableSuperAdmin
              // || !emailValidCheck
            }
          >
            SAVE
          </Button>
        ) : (
        <>
          {editRole != "Student" && !user?.privilage?.includes('FeeManager') ? 
        <Button
          style={{
            borderRadius: "30px",
            width: "130px",
            height: "40px",
            color: "#636363",
            backgroundColor: "white",
            position: "absolute",
            right: "35px",
            marginTop: "-50px",
            fontWeight:500
          }}
          onClick={(e) => {handleSettingSave()}}
          loading={saveButtonLoader}
          // disabled={submitDisable || fieldDisabledForFee || disableSuperAdmin}
        >
         <SettingOutlined/> SETTING
        </Button>:null}
        </>)}
        {
          basicInfoDetails ? (
            <div
              onScroll={() => handleScroll()}
              ref={formCntr}
              style={{
                width: "65%",
                overflowY: "scroll",
                overflowX: "hidden",
                paddingRight: "20px",
                padding: "8px",
                // pointerEvents:""
                // backgroundColor:"red"
              }}
            >
              <div
                ref={basicInfoRef}
                style={{
                  boxShadow: " 1px 1px 16px #E6E6E6",
                  borderRadius: "10px",
                  paddingTop: "50px",
                  paddingBottom: "50px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "50px",
                  }}
                >
                  {!imageUrl &&
                  userDetailsById?.image !== "default.png" &&
                  userDetailsById?.image !== undefined ? (
                    <img
                      style={{ borderRadius: "50%" }}
                      width="130px"
                      height="130px"
                      src={`${IMG_URL}/${userDetailsById?.image}`}
                      alt=""
                    ></img>
                  ) : (
                    <img
                      style={{
                        borderRadius: "50%",
                        padding: imageUrl ? 0 : "15px",
                        backgroundColor: imageUrl ? 0 : "#F6F4FE",
                      }}
                      width="130px"
                      height="130px"
                      src={imageUrl ? imageUrl : userAvatar}
                      alt=""
                    ></img>
                  )}
                  <input
                    type="file"
                    id="file"
                    ref={inputProfilePic}
                    onChange={(e) => {
                      onChangeFile(e);
                    }}
                    style={{ display: "none" }}
                    accept="image/*"
                    value=""
                  />

                  <div
                    style={{
                      backgroundColor: "grey",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "inherit",
                      marginLeft: "88px",
                      marginTop: "-30px",
                      backgroundColor: "white",
                    }}
                  >
                    <CameraFilled
                      style={{
                        color: "#1089FF",
                        fontSize: "20px",

                        cursor:
                          fieldDisabledForFee ||
                          disableSuperAdmin ||
                          editPrivilage?.includes("SuperAdmin")
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={addProfileImg}
                    />
                  </div>
                  <p style={{ color: "#636363", fontFamily: "roboto" }}>
                    Since{" "}
                    {moment(userDetailsById?.createdAt).format("MMM Do, YYYY")}
                  </p>
                </div>
                <h2
                  style={{
                    fontWeight: "bold",
                    paddingLeft: "30px",
                    fontFamily: "Roboto",
                  }}
                >
                  Basic information
                </h2>

                <div
                  id="basicInfo"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <div
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "10px",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#636363",
                          marginBottom: "7px",
                          fontWeight: "bold",
                          fontFamily: "Roboto",
                        }}
                      >
                        Name
                      </p>
                      <Input
                        style={{
                          width: "100%",
                          marginBottom: "20px",
                          border: "#E6E6E6 solid 1px",
                          borderRadius: "5px",
                          color: "black",
                        }}
                        placeholder="Enter name"
                        value={editName}
                        onChange={(e) => {
                          setEditName(
                            e.target.value == " " ? null : e.target.value
                          );
                        }}
                        type="text"
                        disabled={fieldDisabledForFee || disableSuperAdmin}
                      />
                      <div
                        className="supportPhone"
                        style={{ marginBottom: "20px" }}
                      >
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#636363",
                            marginBottom: "7px",
                            fontWeight: "bold",
                            fontFamily: "Roboto",
                          }}
                        >
                          Phone Number
                        </p>

                        <PhoneInput
                          style={{
                            width: "100%",
                            border: "1px solid #d9d9d9",
                            paddingLeft: 5,
                            backgroundColor: "transparent",
                          }}
                          addInternationalOption={true}
                          defaultCountry="IN"
                          placeholder="Enter phone number"
                          value={editPhone}
                          height={"40px"}
                          onCountryChange={(e) => console.log("code", e)}
                          onChange={(e) => setEditPhone(e)}
                          disabled={
                            fieldDisabledForFee ||
                            disableSuperAdmin ||
                            editPrivilage?.includes("SuperAdmin")
                          }
                          maxLength={(editPhone?.startsWith('+91') || editPhone?.startsWith('91')) && editPhone?.length == 13 ? 11 : ""}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        {editRole == "Student" ? null : (
                          <div style={{ width: "47%" }}>
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#636363",
                                marginBottom: "7px",
                                fontWeight: "bold",
                                fontFamily: "Roboto",
                              }}
                            >
                              Gender
                            </p>
                            <Select
                              className="genderStyleBp"
                              placeholder="Select  Gender"
                              defaultValue={null}
                              style={{ width: "100%" }}
                              value={editGender}
                              onChange={(e) => setEditGender(e)}
                              disabled={
                                fieldDisabledForFee || disableSuperAdmin
                              }
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                            }}
                              // disabled={true}
                            >
                              <Option value="Male">Male</Option>
                              <Option value="Female">Female</Option>
                              <Option value="Others">Others</Option>
                            </Select>
                          </div>
                        )}
                        <div
                          style={
                            editRole == "Student"
                              ? { width: "100%" }
                              : { width: "47%" }
                          }
                        >
                          <p
                            style={{
                              marginBottom: "0px",
                              color: "#636363",
                              marginBottom: "7px",
                              fontWeight: "bold",
                              fontFamily: "Roboto",
                            }}
                          >
                            Blood Group
                          </p>
                          <Select
                            className="genderStyleBp"
                            placeholder="Select  Blood Group"
                            defaultValue={null}
                            style={{ width: "100%" }}
                            value={(editBloodGroup === "NA" || editBloodGroup === "" || editBloodGroup === undefined )? null : editBloodGroup}

                            // value={(editBloodGroup && editBloodGroup !='') ? editBloodGroup : null}
                            disabled={fieldDisabledForFee || disableSuperAdmin}
                            onChange={(e) => setEditBloodGroup(e)}
                            getPopupContainer={(triggerNode) => {
                              return triggerNode.parentNode;
                          }}
                          >
                            <Option value="A+">A+</Option>
                            <Option value="A-">A-</Option>
                            <Option value="B+">B+</Option>
                            <Option value="B-">B-</Option>
                            <Option value="AB+">AB+</Option>
                            <Option value="AB-">AB-</Option>
                            <Option value="O+">O+</Option>
                            <Option value="O-">O-</Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "50%" }}>
                    <div
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "10px",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* <div style={{ width: "47%" }}>
                                            <p style={{ marginBottom: "0px", color: "#636363", marginBottom: "7px" }}> Class</p>
                                            <Select placeholder="Select  Privilage..." defaultValue={null} style={{ width: "100%" }} onChange={() => { }}>

                                                <Option value="Admin">Admin</Option>
                                                <Option value="Fee Manager">Fee Manager</Option>
                                                <Option value="Aulas Support">Aulas Support</Option>
                                                <Option value="Data operator">Data operator</Option>


                                            </Select>
                                        </div> */}
                        {dashBoardRole === "Student" ||
                        editRole == "Student" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            {editRole == "Student" ? (
                              <div
                                style={{ width: "100%", marginBottom: "20px" }}
                              >
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    color: "#636363",
                                    marginBottom: "7px",
                                    fontWeight: "bold",
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  Gender
                                </p>
                                <Select
                                  className="genderStyleBp"
                                  placeholder="Select  Gender"
                                  defaultValue={null}
                                  style={{ width: "100%" }}
                                  value={editGender}
                                  onChange={(e) => setEditGender(e)}
                                  disabled={
                                    fieldDisabledForFee || disableSuperAdmin
                                  }
                                  getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                }}
                                  // disabled={true}
                                >
                                  <Option value="Male">Male</Option>
                                  <Option value="Female">Female</Option>
                                  <Option value="Others">Others</Option>
                                </Select>
                              </div>
                            ) : null}
                            {/* <div style={{ width: "47%" }}>
                              <p
                                style={{
                                  marginBottom: "0px",
                                  color: "#636363",
                                  marginBottom: "7px",
                                  fontFamily: "Roboto",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                {userDetailsById?.student?.standardId == -1
                                  ? "Preferred"
                                  : null}{" "}
                                Class
                              </p>
                              <Select
                                placeholder="Select  Class..."
                                defaultValue={null}
                                style={{ width: "100%" }}
                                value={classId}
                                onChange={handleClassSelect}
                              >
                                {classList?.length &&
                                  classList.map(
                                    (item, index) => (
                                      // <div key={index} >

                                      <Option key={index} value={item.id}>
                                        {" "}
                                        {item?.std}-{item?.section}:
                                        {item?.courseName}
                                      </Option>
                                    )
                                    // </div>
                                  )}
                              </Select>
                            </div> */}
                            {/* <div style={{ width: "47%" }}>
                              <p
                                style={{
                                  marginBottom: "0px",
                                  color: "#636363",
                                  marginBottom: "7px",
                                  fontFamily: "Roboto",
                                  fontWeight: "bold",
                                }}
                              >
                                Roll number
                              </p>
                              <Input
                                style={{
                                  width: "100%",
                                  marginBottom: "20px",
                                  border: "#E6E6E6 solid 1px",
                                  borderRadius: "5px",
                                  color: "#000000",
                                }}
                                // placeholder="Enter Roll number"
                                type="text"
                                value={nextRollNo}
                                disabled={true}
                                onChange={() => {}}
                              />
                              {submitDisable ? (
                                <LoadingOutlined
                                  spin={true}
                                  style={{
                                    position: "relative",
                                    marginLeft: "-25px",
                                    fontSize: "20px",
                                    marginTop: "5px",
                                    color: "grey",
                                  }}
                                />
                              ) : null}
                            </div> */}
                          </div>
                        ) : (
                          <div style={{ width: "100%" }}>
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#636363",
                                marginBottom: "7px",
                                fontWeight: "bold",
                                fontFamily: "Roboto",
                              }}
                            >
                              User type
                            </p>
                            <Select
                              // placeholder="Select  ..."
                              className="genderStyleBp"
                              defaultValue={null}
                              style={{ width: "100%", marginBottom: "20px" }}
                              value={editRole}
                              onChange={(e) => setEditRole(e)}
                              disabled={true}
                            >
                              <Option value="Teacher">Teacher</Option>
                              <Option value="Operation">Operation</Option>
                              <Option value="DataOperator">
                                Data Operator
                              </Option>
                            </Select>
                            {/* <Input
                          style={{
                            width: "100%",
                            marginBottom: "20px",
                            border: "#E6E6E6 solid 1px",
                            borderRadius: "5px",
                          }}
                          placeholder="Enter Role"
                          type="text"
                          value={editRole}
                          onChange={(e) => setEditRole(e.target.value)}
                        /> */}
                          </div>
                        )}
                      </div>
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#636363",
                          marginBottom: "7px",
                          fontWeight: "bold",
                          fontFamily: "Roboto",
                        }}
                      >
                        Email ID
                      </p>
                      {!emailValidCheck ? (
                        <p
                          style={{
                            color: "red",
                            marginLeft: "30%",
                            marginTop: "-27px",
                            marginBottom: "5px",
                          }}
                        >
                          Enter A Valid Email ID{" "}
                        </p>
                      ) : null}
                      <Input
                        style={{
                          width: "100%",
                          marginBottom: "20px",
                          border: "#E6E6E6 solid 1px",
                          borderRadius: "5px",
                          color: "black",
                        }}
                        placeholder="Enter Email ID"
                        type="text"
                        disabled={
                          fieldDisabledForFee ||
                          disableSuperAdmin ||
                          editPrivilage?.includes("SuperAdmin")
                        }
                        value={editEmail}
                        onChange={(e) =>
                          setEditEmail(
                            e.target.value !== " " ? e.target.value : null
                          )
                        }
                      />
                      <div style={{ width: "200%" }}>
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#636363",
                            marginBottom: "7px",
                            fontWeight: "bold",
                            fontFamily: "Roboto",
                          }}
                        >
                          Date of birth
                        </p>

                        <Date_Picker 
                          disabled={fieldDisabledForFee || disableSuperAdmin}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* {console.log("privilege issue",
                dashBoardRole === "Student" ||
                editRole == "Student" ||
                editPrivilage?.includes("SuperAdmin")
)} */}
              <div
                id="Privilages"
                style={
                  dashBoardRole === "Student" ||
                  editRole == "Student" ||
                  editPrivilage?.includes("SuperAdmin")
                    ? { display: "none" }
                    : {
                        width: "100%",
                        marginTop: "30px",
                        boxShadow: " 1px 1px 16px #E6E6E6",
                        borderRadius: "10px",
                        paddingTop: "30px",
                        paddingBottom: "30px",
                        // display:
                        //   dashBoardRole === "Student" ||
                        //   editRole == "Student" ||
                        //   editPrivilage?.includes("SuperAdmin")
                        //     ? "none"
                        //     : "view",
                      }
                }
                ref={previlageRef}
              >
                <h2
                  style={{
                    marginLeft: "4%",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                  }}
                >
                  Privileges
                </h2>

                <div
                  style={{
                    marginLeft: "4%",
                    display: "flex",
                    marginTop: "30px",
                    width: "90%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Checkbox.Group
                    style={{ width: "100%", fontFamily: "Roboto" }}
                    onChange={onChange}
                    value={editPrivilage}
                    disabled={fieldDisabledForFee || disableSuperAdmin}
                  >
                    <Row>
                      <Col style={{ marginBottom: "20px" }} span={8}>
                        <Checkbox
                          disabled={
                            user?.privilage?.includes("Admin") &&
                            !user?.privilage?.includes("SuperAdmin")
                              ? true
                              : false
                          }
                          value="Admin"
                        >
                          Admin
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        {isAdminSelected?
                        <div style={{marginTop:3}}>
                        <div style={{height:17,width:17,border:"1px solid #E6E6E6",display:"flex",alignItems:"center",cursor:"not-allowed",backgroundColor:"#E6E6E6"}}>
                         <CheckOutlined style={{color:"white"}}/>
                         <div style={{padding:5,color:"#E6E6E6",fontSize:14,marginLeft:5}}>Planner</div>
                         
                        </div>
                        </div>:
                          <Checkbox disabled={isAdminSelected} value="Planner">
                          Planner
                        </Checkbox>

                        }
                       
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          disabled={
                            user?.privilage?.includes("Admin") &&
                            !user?.privilage?.includes("SuperAdmin")
                              ? true
                              : false
                          }
                          value="FeeManager"
                        >
                          Fee manager
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                      {isAdminSelected?
                        <div style={{marginTop:3}}>
                        <div style={{height:17,width:17,border:"1px solid #E6E6E6",display:"flex",alignItems:"center",cursor:"not-allowed",backgroundColor:"#E6E6E6"}}>
                         <CheckOutlined style={{color:"white"}}/>
                         <div style={{padding:5,color:"#E6E6E6",fontSize:14,marginLeft:5}}>Institute support</div>
                         
                        </div>
                        </div>:
                          <Checkbox
                          disabled={
                            // (user?.privilage?.includes("Admin") &&
                            // !user?.privilage?.includes("SuperAdmin")
                            //   ? true
                            //   : false) ||
                            isAdminSelected
                          }
                          value="InstituteSupport"
                        >
                          Institute support
                        </Checkbox>

                        }
                        
                      </Col>
                      {(dashBoardRole == "Teacher" || editRole == "Teacher")?null:
                      
                      <Col
                        span={8}
                      >
                        <Checkbox
                          // disabled={isAdminSelected}
                          value="AttendanceMarker"
                        >
                          Attendance marker
                        </Checkbox>
                      </Col>
                      }
                      <Col span={8}>
                        <Checkbox
                          disabled={
                            (user?.privilage?.includes("Admin") &&
                            !user?.privilage?.includes("SuperAdmin")
                              ? true
                              : false) 
                          }
                          value="LeaveManager"
                        >
                          Leave Manager 
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                  <br />

                  {/* <Checkbox value={editPrivilages} onChange={onChange}>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "600",
                    fontFamily: "Roboto",
                  }}
                >
                  Admin
                </p>
              </Checkbox>
              <Checkbox onChange={onChange}>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "600",
                    fontFamily: "Roboto",
                  }}
                >
                  Fee Manager
                </p>
              </Checkbox>
              <Checkbox onChange={onChange}>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "600",
                    fontFamily: "Roboto",
                  }}
                >
                  aulas care
                </p>
              </Checkbox> */}
                </div>
              </div>

              <div
                ref={addressRef}
                id="currentAddress"
                style={{
                  marginTop: "30px",
                  boxShadow: " 1px 1px 16px #E6E6E6",
                  borderRadius: "10px",
                  paddingTop: "30px",
                }}
              >
                <h2
                  style={{
                    marginLeft: "3%",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                  }}
                >
                  Current address
                </h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: "20px",
                  }}
                >
                  <div style={{ width: "47%", marginRight: "5px" }}>
                    <p
                      style={{
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      House No./Locality/Area
                    </p>
                    <Input
                      style={{
                        width: "97%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                        color: "black",
                      }}
                      placeholder="Enter House No./Locality/Area"
                      type="text"
                      disabled={fieldDisabledForFee || disableSuperAdmin}
                      value={editCurrentAddress}
                      onChange={(e) => {
                        setEditCurrentAddress(
                          e.target.value == " " ? null : e.target.value
                        );
                        if (isSameCurrentParmanentAdd) {
                          setEditPermanentAddress(
                            e.target.value !== " " ? e.target.value : null
                          );
                        }
                      }}
                    />
                    <p
                      style={{
                        color: "#636363",
                        marginBottom: "7px",
                        marginTop: "16px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      State
                    </p>
                    <Input
                      style={{
                        width: "97%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                        color: "black",
                      }}
                      placeholder="Enter State"
                      disabled={fieldDisabledForFee || disableSuperAdmin}
                      type="text"
                      value={
                        editCurrentState != undefined ? editCurrentState : null
                      }
                      // disabled={currentAddLocker}
                      onChange={(e) => {
                        if (
                          e.target.value.match(/^[a-z ]+$/i) ||
                          e.target.value == ""
                        ) {
                          setEditCurrentState(
                            e.target.value !== " " ? e.target.value : null
                          );
                          if (isSameCurrentParmanentAdd) {
                            setEditPermanentState(
                              e.target.value !== " " ? e.target.value : null
                            );
                          }
                        }
                      }}
                    />
                    <p
                      style={{
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      Country
                    </p>
                    {/* <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter Country"
                  disabled={currentAddLocker}
                  type="text"
                  value={editCurrentCountry}
                  onChange={(e) => setEditCurrentCountry(e.target.value)}
                /> */}
                    <div
                      className="details-input-fields"
                      style={{
                        background: "grey",
                        padding: "0px 10px",
                        marginBottom: "20px",
                        height: "10px !important",
                      }}
                    >
                      <CountryDropdown
                        style={{
                          border: "none",
                          background: "grey",
                          width: "100%",
                        }}
                        className="details-input-fields"
                        placeholder="Select Country*"
                        value={editCurrentCountry}
                        // defaultCountry="India"
                        disabled={fieldDisabledForFee || disableSuperAdmin}
                        onChange={(value) => {
                          setEditCurrentCountry(value);
                          if (isSameCurrentParmanentAdd) {
                            setEditPermanentCountry(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ width: "47%", marginLeft: "25px" }}>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      Pincode
                    </p>
                    {editCurrentPinCode != null && editCurrentPinCode?.length<=5 && editCurrentPinCode!=""? (
                      <p
                        style={{
                          color: "red",
                          marginLeft: "30%",
                          marginTop: "-27px",
                          marginBottom: "5px",
                        }}
                      >
                        Enter A Valid Pincode{" "}
                      </p>
                    ) : null}
                    <Input
                      style={{
                        width: "97%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                        color: "black",
                      }}
                      placeholder="Enter Pincode"
                      disabled={
                        !currentAddLocker ||
                        fieldDisabledForFee ||
                        disableSuperAdmin
                      }
                      value={
                        editCurrentPinCode !== undefined
                          ? editCurrentPinCode
                          : null
                      }
                      onChange={(e) => handlePinUpdate(e)}
                      // type="number"
                      // stringMode
                    />
                    <div style={{ marginLeft: "38%", marginTop: "-16px" }}>
                      <Checkbox
                        checked={!currentAddLocker}
                        onChange={onChangeCurrentPinCode}
                        disabled={fieldDisabledForFee || disableSuperAdmin}
                      >
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#636363",
                            marginBottom: "7px",
                          }}
                        >
                          I do not have a Pincode
                        </p>
                      </Checkbox>
                    </div>

                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      District
                    </p>
                    <Input
                      style={{
                        width: "97%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                        color: "black",
                      }}
                      placeholder="Enter District"
                      type="text"
                      disabled={fieldDisabledForFee || disableSuperAdmin}
                      value={editCurrentDistrict}
                      onChange={(e) => {
                        setEditCurrentDistrict(
                          e.target.value !== " " ? e.target.value : null
                        );
                        if (isSameCurrentParmanentAdd) {
                          setEditPermanentDistrict(
                            e.target.value !== " " ? e.target.value : null
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                id="PermanentAddress"
                className="PermanentAddress"
                style={{
                  marginTop: "30px",
                  boxShadow: " 1px 1px 16px #E6E6E6",
                  borderRadius: "10px",
                  paddingTop: "30px",
                }}
              >
                <h2
                  style={{
                    marginLeft: "3%",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                  }}
                >
                  Permanent address
                </h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: "20px",
                  }}
                >
                  <div style={{ width: "47%", marginRight: "5px" }}>
                    <p
                      style={{
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      House No./Locality/Area
                    </p>
                    <Input
                      style={{
                        width: "97%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                        color: "black",
                      }}
                      placeholder="Enter House No./Locality/Area"
                      // disabled={fieldDisabledForFee}
                      type="text"
                      value={editPermanentAddress}
                      disabled={
                        isSameCurrentParmanentAdd ||
                        fieldDisabledForFee ||
                        disableSuperAdmin
                      }
                      onChange={(e) => {
                        setEditPermanentAddress(
                          e.target.value == " " ? null : e.target.value
                        );
                      }}
                    />
                    <p
                      style={{
                        color: "#636363",
                        marginBottom: "7px",
                        marginTop: "16px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      State
                    </p>
                    <Input
                      style={{
                        width: "97%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                        color: "black",
                      }}
                      placeholder="Enter State"
                      type="text"
                      disabled={
                        //   (checkPermanent
                        //     ? !checkPermanent
                        //     : permanentAddLocker) ||
                        isSameCurrentParmanentAdd ||
                        fieldDisabledForFee ||
                        disableSuperAdmin
                      }
                      value={editPermanentState ? editPermanentState : null}
                      onChange={(e) => {
                        if (
                          e.target.value.match(/^[a-z ]+$/i) ||
                          e.target.value == ""
                        )
                          setEditPermanentState(
                            e.target.value !== " " ? e.target.value : null
                          );
                      }}
                    />
                    <p
                      style={{
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      Country
                    </p>
                    {/* <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter Country"
                  type="text"
                  disabled={permanentAddLocker}
                  value={editPermanentCountry}
                  onChange={(e) => setEditPermanentCountry(e.target.value)}
                  
                  // filterOption={(inputValue, option) =>
                  //   option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  // }
                /> */}
                    <div
                      className="details-input-fields"
                      style={{
                        background: "grey",
                        padding: "0px 10px",
                        marginBottom: "20px",
                        height: "10px !important",
                      }}
                    >
                      <CountryDropdown
                        style={{
                          border: "none",
                          background: "grey",
                          width: "100%",
                        }}
                        className="details-input-fields"
                        placeholder="Select Country*"
                        value={editPermanentCountry}
                        onChange={(value) => setEditPermanentCountry(value)}
                        disabled={
                          isSameCurrentParmanentAdd ||
                          fieldDisabledForFee ||
                          disableSuperAdmin
                        }
                      />
                    </div>
                  </div>
                  <div style={{ width: "47%", marginLeft: "25px" }}>
                    <div
                      style={{
                        marginLeft: "38%",
                        marginTop: "-56px",
                        position: "inherit",
                      }}
                    >
                      <Checkbox
                        checked={isSameCurrentParmanentAdd}
                        onChange={(e) => handleCurrentPermanentAdd(e)}
                        disabled={fieldDisabledForFee || disableSuperAdmin}
                      >
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#636363",
                            marginBottom: "30px",
                          }}
                        >
                          Same as current address
                        </p>
                      </Checkbox>
                    </div>

                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      Pincode
                    </p>
                    {
                    isSameCurrentParmanentAdd !== true &&
                    editPermanentPinCode != null &&
                    editPermanentPinCode?.length<=5 && editPermanentPinCode!=""? (
                      <p
                        style={{
                          color: "red",
                          marginLeft: "30%",
                          marginTop: "-27px",
                          marginBottom: "6px",
                        }}
                      >
                        Enter A Valid Pincode{" "}
                      </p>
                    ) : null}
                    <Input
                      style={{
                        width: "97%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                        color: "black",
                      }}
                      placeholder="Enter Pincode"
                      disabled={
                        !permanentAddLocker ||
                        checkPermanent ||
                        isSameCurrentParmanentAdd ||
                        fieldDisabledForFee ||
                        disableSuperAdmin
                      }
                      type="text"
                      value={
                        editPermanentPinCode !== undefined
                          ? editPermanentPinCode
                          : null
                      }
                      onChange={(e) => handlePermanentPinUpdate(e)}
                    />
                    <div style={{ marginLeft: "38%", marginTop: "-16px" }}>
                      <Checkbox
                        onChange={onChangePermanentPinCode}
                        checked={checkPermanent}
                        disabled={
                          isSameCurrentParmanentAdd ||
                          fieldDisabledForFee ||
                          disableSuperAdmin
                        }
                      >
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#636363",
                            marginBottom: "7px",
                          }}
                        >
                          I do not have a Pincode
                        </p>
                      </Checkbox>
                    </div>

                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      District
                    </p>
                    <Input
                      style={{
                        width: "97%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                        color: "black",
                      }}
                      disabled={
                        //   (checkPermanent
                        //     ? !checkPermanent
                        //     : permanentAddLocker) ||
                        isSameCurrentParmanentAdd ||
                        fieldDisabledForFee ||
                        disableSuperAdmin
                      }
                      placeholder="Enter District"
                      type="text"
                      value={editPermanentDistrict}
                      onChange={(e) =>
                        setEditPermanentDistrict(
                          e.target.value !== " " ? e.target.value : null
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                ref={emergencyRef}
                id="emergencyInfo"
                style={{
                  marginTop: "30px",
                  boxShadow: " 1px 1px 16px #E6E6E6",
                  borderRadius: "10px",
                  paddingTop: "30px",
                }}
              >
                <h2
                  style={{
                    marginLeft: "3%",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                  }}
                >
                  Emergency Contact
                </h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: "20px",
                  }}
                >
                  <div style={{ width: "47%", marginRight: "5px" }}>
                    <p
                      style={{
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      Name
                    </p>
                    <Input
                      style={{
                        width: "97%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                      }}
                      placeholder="Enter name"
                      type="text"
                      value ={editEmergencyName === "null" || editEmergencyName === null || editEmergencyName === undefined || editEmergencyName === "undefined" || editEmergencyName === "" ? null : editEmergencyName }
                      disabled={fieldDisabledForFee || disableSuperAdmin}
                      onChange={(e) => {
                        setEditEmergencyName(
                          e.target.value == " " ? null : e.target.value
                        );
                      }}
                    />
                    <p
                      style={{
                        color: "#636363",
                        marginBottom: "7px",
                        marginTop: "16px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      Relationship
                    </p>
                    {/* <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter Relationship"
                  type="text"
                  value={editEmergencyRelation}
                  onChange={(e) => setEditEmergencyRelation(e.target.value)}
                /> */}
                    <Select
                      placeholder="Select Relationship"
                      defaultValue={null}
                      style={{
                        width: "97%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                      }}
                      value={(editEmergencyRelation === null || editEmergencyRelation === 'null' || editEmergencyRelation === undefined || editEmergencyRelation === "undefined")  ? null: editEmergencyRelation }
                      onChange={(e) => setEditEmergencyRelation(e)}
                      disabled={fieldDisabledForFee || disableSuperAdmin}
                      className="genderStyleBp"
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                    }}
                      // placeholder="Select a relation.."
                    >
                      <Option value="Father">Father</Option>
                      <Option value="Mother">Mother</Option>
                      <Option value="Uncle">Uncle</Option>
                      <Option value="Aunt">Aunt</Option>
                      <Option value="Brother">Brother</Option>
                      <Option value="Sister">Sister</Option>
                      <Option value="Others">Others</Option>
                    </Select>
                  </div>
                  <div
                    style={{
                      width: "47%",
                      marginLeft: "25px",
                      marginRight: "5px",
                    }}
                  >
                    <div
                      className="supportPhone"
                      style={{ marginBottom: "20px" }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#636363",
                          marginBottom: "7px",
                          fontWeight: "bold",
                          fontFamily: "Roboto",
                        }}
                      >
                        Phone number{" "}
                      </p>

                      <PhoneInput
                        style={{
                          width: "100%",
                          border: "1px solid #d9d9d9",
                          paddingLeft: 5,
                          backgroundColor: "transparent",
                        }}
                        addInternationalOption={true}
                        defaultCountry="IN"
                        placeholder="Enter phone number"
                        height={"40px"}
                        value={editEmergencyPhone}
                        onCountryChange={(e) => console.log("code", e)}
                        onChange={(e) => setEditEmergencyPhone(e)}
                        disabled={fieldDisabledForFee || disableSuperAdmin}
                        maxLength={(editEmergencyPhone?.startsWith('+91') || editEmergencyPhone?.startsWith('91')) && editEmergencyPhone?.length == 13 ? 11 : ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : // <div style={{ height: "100vh", width: "60%" }}>
          // <CourseDetails userCourseUpdate={userCourseUpdate} />
          // </div>
          null
          // null
        }
        {courseAndFeeDetails ? (
          <CourseDetails
            userCourseUpdate={userCourseUpdate}
            feeDetails={true}
          />
        ) : null}
        {transactionDetails ? (
          <div style={{ width: "66%" }}>
            <StudentFeeDetails />
          </div>
        ) : null}
         {leavesTab && editRole!=="Student" ? (
          <div style={{ width: "66%" }}>
            <LeavesDashBoard />
            </div>):null}
        {attendanceDetail && editRole!=="Student" ? (
          <div style={{ width: "66%" }}>
            <AttendanceTabDetails/>
          </div>
        ) : null}
        {/* {attendanceDetail && editRole==="Student" ? (
          <div style={{ width: "66%" }}>
            <StudentProfileDetail/>
          </div>
        ) : null} */}
        {feeDetails ? (
          <CourseDetails userCourseUpdate={userCourseUpdate} />
        ) : null}
        {studentAttendanceDetails && editRole=='Student' ? (
          <div style={{width:"70%"}}>
          <StudentProfileDetail/>
          </div>
          ) : null}
        <PageDataLoader visible={pageLoader} />
      </div>
      <DeleteModal
        isModalVisible={isModalVisible}
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        name={editName}
        role={editRole}
      />
      <EditProfilePic
        imageUrl={imageUrl}
        userDetailsSaved={userDetailsSaved}
        deletePicLoader={deletePicLoader}
        isModalVisible={editProfileModal}
        handleCancel={handleCancel}
        userDetailsById={userDetailsById}
        handleOk={handleProfileModalOk}
        handleProfileModalCancel={handleProfileModalCancel}
        fileInputValue={fileInputValue}
        setfileInputValue={setfileInputValue}
        
      />
      <SettingModal
      setIsSettingModalOpen={setIsSettingModalOpen}
      isSettingModalOpen={isSettingModalOpen}
      storeUserId={userDetailsById?.id}
      />
    </div>
  );
}

// export default UserDetailsForm

const mapStateToProps = (state) => {
  const {
    tab,
    editName,
    editRole,
    editBloodGroup,
    editGender,
    editEmail,
    editPhone,
    // editPrivilage,
    editPrivilage,
    editEmergencyName,
    editEmergencyPhone,
    editEmergencyRelation,
    editCurrentAddress,
    editCurrentPinCode,
    editCurrentState,
    editCurrentDistrict,
    editCurrentCountry,
    editPermanentAddress,
    editPermanentPinCode,
    editPermanentState,
    editPermanentDistrict,
    editPermanentCountry,
    userDetailsById,
    dashBoardRole,
    classList,
    nextRollNo,
    classId,
    editDateOfBirth,
    studentCourseDetails,
  } = state.userManagement;
  const { user } = state.session;
  const {specificUserId} = state.userManagement;
  const {storeIsClickedOnUserCard} = state.attendanceNew
  return {
    user,
    tab,
    editName,
    editRole,
    editPhone,
    editEmail,
    editGender,
    editBloodGroup,
    editPrivilage,
    editEmergencyName,
    editEmergencyPhone,
    editEmergencyRelation,
    editCurrentAddress,
    editCurrentPinCode,
    editCurrentState,
    editCurrentDistrict,
    editCurrentCountry,
    editPermanentAddress,
    editPermanentPinCode,
    editPermanentState,
    editPermanentDistrict,
    editPermanentCountry,
    userDetailsById,
    dashBoardRole,
    classList,
    nextRollNo,
    classId,
    editDateOfBirth,
    studentCourseDetails,
    storeIsClickedOnUserCard,
    specificUserId
  };
};

const mapDispatchToProps = (dispatch) => ({
  // setTab: (m) => dispatch(setTab(m))
  deleteProfilePic: (callback,) => dispatch(deleteProfilePic(callback,)),
  setEditName: (name) => dispatch(setEditName(name)),
  setEditRole: (roll) => dispatch(setEditRole(roll)),
  setEditPhone: (phone) => dispatch(setEditPhone(phone)),
  setEditEmail: (email) => dispatch(setEditEmail(email)),
  setEditGender: (gender) => dispatch(setEditGender(gender)),
  setEditBloodGroup: (group) => dispatch(setEditBloodGroup(group)),
  setEditDateOfBirth: (date) => dispatch(setEditDateOfBirth(date)),
  setEditPrivilages: (privilage) => dispatch(setEditPrivilages(privilage)),
  setEditEmergencyName: (name) => dispatch(setEditEmergencyName(name)),
  setEditEmergencyPhone: (phone) => dispatch(setEditEmergencyPhone(phone)),
  setEditEmergencyRelation: (rel) => dispatch(setEditEmergencyRelation(rel)),
  setEditCurrentAddress: (value) => dispatch(setEditCurrentAddress(value)),
  setEditCurrentPinCode: (value) => dispatch(setEditCurrentPinCode(value)),
  setEditCurrentState: (value) => dispatch(setEditCurrentState(value)),
  setEditCurrentDistrict: (value) => dispatch(setEditCurrentDistrict(value)),
  setEditCurrentCountry: (value) => dispatch(setEditCurrentCountry(value)),
  setEditPermanentAddress: (value) => dispatch(setEditPermanentAddress(value)),
  setEditPermanentPinCode: (value) => dispatch(setEditPermanentPinCode(value)),
  setEditPermanentState: (value) => dispatch(setEditPermanentState(value)),
  setEditPermanentDistrict: (value) =>
    dispatch(setEditPermanentDistrict(value)),
  setEditPermanentCountry: (value) => dispatch(setEditPermanentCountry(value)),
  getStudentsDetailsById: (callback) =>
    dispatch(getStudentsDetailsById(callback)),
  editUserInfo: (
    handleSuccessCallback,
    callback,
    fileInputValue,
    currentAddPin,
    perminentAddpin,
    sameAsCA,
    notification
  ) =>
    dispatch(
      editUserInfo(
        handleSuccessCallback,
        callback,
        fileInputValue,
        currentAddPin,
        perminentAddpin,
        sameAsCA,
        notification
      )
    ),
  deleteUserById: (callback) => dispatch(deleteUserById(callback)),
  getStudentsclass: (callback) => dispatch(getStudentsclass(callback)),
  setClassId: (id) => dispatch(setClassId(id)),
  setNextRollNo: (rollNo) => dispatch(setNextRollNo(rollNo)),
  getStudentsRollNo: (callback, isStudentSelected) =>
    dispatch(getStudentsRollNo(callback, isStudentSelected)),
  fetchPinCodeDetails: (pin, callback) =>
    dispatch(fetchPinCodeDetails(pin, callback)),
  sendRemaider: (callback) => dispatch(sendRemaider(callback)),
  setUserDetailsById: (details) => dispatch(setUserDetailsById(details)),
  assignCourseToStudent: (callback, successCallBack, edit) =>
    dispatch(assignCourseToStudent(callback, successCallBack, edit)),
  getCourseDetalis: () => dispatch(getCourseDetalis()),
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
  setOverviewFilter: (key, val) => dispatch(setOverviewFilter(key, val)),
  setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
  storeTabData:(val)=>dispatch(storeTabData(val)),
  getLeavesTabData:(callback)=>(dispatch(getLeavesTabData(callback))),
  setBackToPrivilegedView:(val)=>(dispatch(setBackToPrivilegedView(val))),
  setClickedOnUserCard:(val)=>dispatch(setClickedOnUserCard(val)),
  setSpecificUserId: (res) => dispatch(setSpecificUserId(res)),
  getWebClockIn:(storeUserId) => dispatch(getWebClockIn(storeUserId)),

});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsForm);