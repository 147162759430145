import React, { useState } from 'react'
import attendancePresent from '../../../Assets/dashBoard/more-digital-library.svg'
import course from '../../../Assets/dashBoard/course.svg'
import moment from 'moment'
import CourseDetailModal from '../modal/courseDetailModal'
import Attendancemodal from '../modal/attendanceModal'
import ScheduleModal from '../modal/scheduleDetailModal'
import scheduleimg from '../../../Assets/dashBoard/sbj-physics.png'
import testImg from '../../../Assets/dashBoard/ic-chapter-test.svg'
import announceImg from '../../../Assets/dashBoard/ic-announcements-text.svg'
import { Typography } from 'antd'
import ViewAnnouncementModal from '../modal/viewAnnounceModal'
import { useHistory } from "react-router";
import ic_file_add from "../../../Assets/images/ic_file_add.svg"
import { FilePdfOutlined } from '@ant-design/icons'
import { Button } from 'components/Button'






const InformationBoardCard = ({ allData, title, time, clockin, clockOut, date, type, CourseStartDate, CourseDurationType, CourseDuration, courseName, createdAt
    , scheduleTitle, scheduleDesc, scheduleStartDate, scheduleStartTime, AnnouncementTitle, AnnouncementText, publishedTests, setSelectedTest
}) => {

    function removeHTMLTags(sentence) {
        const regex = /<[^>]*>/g;
        return sentence.replace(regex, '');
    }

    const filenames = allData?.meta?.filenames || [];
    const fileKey = filenames[0]?.key;

    let message = 'Unknown file format';
    if (fileKey) {
        if (fileKey.endsWith('.jpg') || fileKey.endsWith('.png')) {
            message = 'Photo';

        } else if (fileKey.endsWith('.pdf')) {
            message = 'Pdf';
        }
    }



    const history = useHistory();
    const [isCourseModal, setIsCourseModal] = useState(false)
    const [isAttendanceModal, setAttendanceModal] = useState(false)
    const [isScheduleModal, setIsScheduleModal] = useState(false)
    const [isViewModal, setIsViewModal] = useState(false)
    return (
        <div>
            {type == 'Attendance' ?
                <>
                    <div style={{ padding: "2px", height: "85px", marginTop: "15px", cursor: "pointer" }} onClick={() => { setAttendanceModal(true) }} >
                        <div style={{ display: "flex", width: "100%" }}>
                            <div><img src={attendancePresent} /></div>
                            <div style={{ marginLeft: "10px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ fontSize: "12px", fontWeight: "bold" }}>{title}</div>
                                    <div style={{ fontSize: "9px", color: "#AEAEAE", marginTop: "3px", marginLeft: "25px" }}>{moment(createdAt).format('h:mm a')}</div>
                                </div>

                                <Typography.Text ellipsis={{ tooltip: true }}>
                                    <div style={{ width: "285px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Request for ({date}){" "},{moment(clockin, 'hh:mm').format('hh:mm a')}{" "}-{" "}{moment.utc(clockOut, 'hh:mm:ss').format('h:mm a')}</div>
                                </Typography.Text>
                            </div>
                        </div>

                    </div>
                    <div style={{ borderBottom: "1px solid #E6E6E6", marginLeft: "20px", marginRight: "20px", marginTop: "-15px" }}></div>
                </>


                : null}

            {type == 'Course' ?
                <>
                    <div style={{ padding: "2px", height: "85px", marginTop: "15px", cursor: 'pointer' }} onClick={() => setIsCourseModal(true)}>
                        <div style={{ display: "flex", width: "100%" }}>
                            <div><img src={course} /></div>
                            <div style={{ marginLeft: "10px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div>
                                        <div style={{ fontSize: "12px", fontWeight: "bold" }}>{title}</div>
                                        <Typography.Text ellipsis={{ tooltip: true }}>
                                            <div style={{ width: "250px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{courseName} will be starting from {moment(CourseStartDate).format('DD-MM-YYYY')}{" "}({CourseDuration} {CourseDurationType})</div>
                                        </Typography.Text>

                                    </div>

                                    <div style={{ fontSize: "9px", color: "#AEAEAE", marginTop: "3px", width: "20%" }}>{moment(createdAt).format('h:mm a')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ borderBottom: "1px solid #E6E6E6", marginLeft: "20px", marginRight: "20px", marginTop: "-15px" }}></div>

                </>
                : null}
            {type == "Schedule" ?
                <>
                    <div style={{ padding: "2px", height: "85px", marginTop: "15px", cursor: 'pointer' }} onClick={() => setIsScheduleModal(true)}>
                        <div style={{ display: "flex", width: "100%" }}>
                            <div><img width={60} src={scheduleimg} /></div>
                            <div style={{ marginLeft: "10px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div>
                                        <div style={{ fontSize: "12px", fontWeight: "bold" }}>{scheduleTitle}</div>
                                        <div style={{ color: "#636363", fontSize: "11px", fontWeight: "bold" }}>{courseName} will be starting from {moment(scheduleStartDate).format('DD-MM-YYYY')}{" "}({CourseDuration} {CourseDurationType})</div>
                                    </div>

                                    <div style={{ fontSize: "9px", color: "#AEAEAE", marginTop: "3px", marginLeft: "70px" }}>{moment(scheduleStartTime, 'h:mm a').format('h:mm a')}</div>
                                </div>
                                <div style={{ color: '#1089FF', fontSize: "11px", textAlign: "right", fontWeight: "bold" }}> View Detail</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ borderBottom: "1px solid #E6E6E6", marginLeft: "20px", marginRight: "20px", marginTop: "-15px" }}></div>
                </>
                : null}
            {type == "Announcement" ?
                <>
                    <div style={{ padding: "2px", width: "100%", height: "85px", marginTop: "5px", cursor: 'pointer' }} onClick={() => setIsViewModal(true)}>
                        <div style={{ display: "flex" }}>
                            <div><img width={50} src={announceImg} /></div>
                            <div style={{ marginLeft: "10px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", }}>
                                    <div style={{ width: "50%" }}>
                                        <Typography.Text ellipsis={{ tooltip: true }}>
                                            <div style={{ width: "250px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', }}>{AnnouncementTitle}</div>
                                        </Typography.Text>

                                    </div>

                                    <div style={{ fontSize: "9px", color: "#AEAEAE", marginTop: "3px", }}>{moment(allData?.meta?.announcedOn).format('DD-MM-YYYY')}</div>
                                </div>
                                <div>{allData?.meta?.typeAnnouncement != 'file' ?
                                    <Typography.Text ellipsis={{ tooltip: true }}>
                                        <div style={{ width: "170px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', }}>{removeHTMLTags(AnnouncementText)}</div>
                                    </Typography.Text>
                                    :
                                    (<>
                                        <div >
                                            {message == 'Pdf' ? <FilePdfOutlined style={{ fontSize: "15px", color: "red", marginRight: "5px" }} /> : <img src={ic_file_add} style={{ width: "20px", height: "20px", marginRight: "5px" }} />}<h style={{ fontSize: "13px" }}>{message}</h>
                                        </div>
                                    </>)
                                }</div>
                                <div style={{ color: '#1089FF', fontSize: "11px", textAlign: "right", fontWeight: "bold" }}> CONTINUE READING</div>

                            </div>


                        </div>
                        {/* <div style={{display:"flex",width:"100%"}}>
                <div><img width={50} src={announceImg}/></div>
                <div style={{marginLeft:"10px",}}>
                    <div style={{display:"flex",justifyContent:"space-between",}}>
                        <div>
                        <Typography.Text ellipsis={{ tooltip: true }}>
                            <div style={{ width: "170px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold',}}>{AnnouncementTitle}</div>
                        </Typography.Text>
                        {allData?.meta?.typeAnnouncement != 'file' ? 
                            <Typography.Text ellipsis={{ tooltip: true }}>
                            <div style={{ width: "170px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold',  }}>{removeHTMLTags(AnnouncementText)}</div>
                        </Typography.Text>
                        :
                        (<>
                        <div >
                            {message == 'Pdf' ? <FilePdfOutlined style={{ fontSize: "15px" ,color:"red",marginRight:"5px"}} />:<img src={ic_file_add} style={{width:"20px",height:"20px",marginRight:"5px"}}/>}<h style={{fontSize:"13px"}}>{message }</h>
                            </div>
                        </>)
                        }
                        </div>
                        <div style={{fontSize:"9px",color:"#AEAEAE",marginTop:"3px",marginLeft:"80px"}}>{moment(allData?.meta?.announcedOn).format('DD-MM-YYYY')}</div>

                    </div>
                </div>  
            </div> */}
                    </div>
                    <div style={{ borderBottom: "1px solid #E6E6E6", marginLeft: "20px", marginRight: "20px", marginTop: "-15px" }}></div>

                </>
                : null}
            {type == "TestResult" || type == "Test" ?
                <>
                    {/* <div style={{ padding: "2px", height: "fit-content", marginTop: "15px",cursor:'pointer', display: 'flex'}}>
            <img width={60} src={testImg}/>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <Typography.Text style={{ width: "170px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} ellipsis={{ tooltip: true }}>{scheduleTitle}</Typography.Text>
                <div style={{fontSize:"9px",color:"#AEAEAE",marginTop:"3px",marginLeft:"0px"}}>{moment(createdAt).format('DD-MM-YYYY')}</div>
            </div>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <Typography.Text ellipsis={{ tooltip: true }}>
                    <div style={{ width: "170px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{scheduleDesc}</div>
                </Typography.Text>
                {type == "TestResult" || type == "Test" ?
                    <Button type='transparent' style={{color:'#1089FF',fontSize:"11px",textAlign:"right",fontWeight:"bold"}} onClick={()=>{history.push('/student-test/result'),setSelectedTest(allData?.meta?.details)}}> VIEW RESULT</Button> 
            :null}
            </div>
        </div> */}

                    <div style={{ padding: 5, height: 85, cursor: 'pointer', display: 'flex' }}>
                        <img src={testImg} style={{ width: 60 }} />
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div>
                                <div style={{display: 'flex', width: '150%', justifyContent: 'space-between', marginTop: 10}}>
                                    <Typography.Text style={{ width: "170px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} ellipsis={{ tooltip: true }}>{scheduleTitle}</Typography.Text>
                                    <div style={{ fontSize: 10, color: "#AEAEAE", }}>{moment(createdAt).format('DD-MM-YYYY')}</div>
                                </div>
                                <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "170px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginTop: 10 }}>{scheduleDesc}</Typography.Text>
                            </div>
                            {/* <div style={{ border: '1px solid red', display: 'flex', flexDirection: 'column', marginTop: 10,  }}> */}
                                {type == "TestResult" || type == "Test" ?
                                    <Button type='transparent' style={{ color: '#1089FF', fontSize: "10px", fontWeight: "bold", marginTop: 30, marginLeft: 10 }} onClick={() => { history.push('/student-test/result'), setSelectedTest(allData?.meta?.details) }}> VIEW RESULT</Button>
                                    : null
                                }
                            {/* </div> */}
                        </div>
                    </div>


                    {/* <div style={{ padding: "2px", height: "85px", marginTop: "15px",cursor:'pointer'}}>
            <div style={{display:"flex",width:"100%"}}>
                <div><img width={60} src={testImg}/></div>
                <div style={{marginLeft:"10px"}}>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>
                        <Typography.Text ellipsis={{ tooltip: true }}>
                            <div style={{ width: "170px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{scheduleTitle}</div>
                        </Typography.Text>
                        <Typography.Text ellipsis={{ tooltip: true }}>
                            <div style={{ width: "170px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{scheduleDesc}</div>
                        </Typography.Text>
                        </div>
                        
                        <div style={{fontSize:"9px",color:"#AEAEAE",marginTop:"3px",marginLeft:"0px"}}>{moment(createdAt).format('DD-MM-YYYY')}</div>
                    </div>
                    
                </div>  
            </div>
        </div> */}
                    <div style={{ borderBottom: "1px solid #E6E6E6", marginLeft: "20px", marginRight: "20px", marginTop: "-15px" }}></div>

                </>
                : null}
            <CourseDetailModal
                setIsCourseModal={setIsCourseModal}
                isCourseModal={isCourseModal}
                allData={allData}
            />
            <Attendancemodal
                setisAttendance={setAttendanceModal}
                isAttendanceModal={isAttendanceModal}
                allData={allData}
            />
            <ScheduleModal
                setIsScheduleModal={setIsScheduleModal}
                isScheduleModal={isScheduleModal}
                allData={allData}
            />
            <ViewAnnouncementModal
                isViewModal={isViewModal}
                setIsViewModal={setIsViewModal}
                allData={allData}
            />
        </div>
    )

}
export default InformationBoardCard