import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import { Api } from 'services';

const NeetBarGraph = ({data}) => {
  // const data = [
  //   {
  //     name: "Present",
  //     date: "11/12",
  //     range: 58.9,
  //   },
  //   {
  //     name: "Present",
  //     date: "12/12",
  //     range: 100,
  //   },
  //   {
  //     name: "Present",
  //     date: "13/12",
  //     range: 62,
  //   },
  //   {
  //     name: "Present",
  //     date: "14/12",
  //     range: 62,
  //   },
  //   {
  //     name: "Present",
  //     date: "15/12",
  //     range: 62,
  //   },
  //   {
  //     name: "Absent",
  //     date: "11/12",
  //     range: 10,
  //   },
  //   {
  //     name: "Absent",
  //     date: "13/12",
  //     range: 9,
  //   },
  //   {
  //     name: "Absent",
  //     date: "14/12",
  //     range: 9,
  //   },
  //   {
  //     name: "Absent",
  //     date: "15/12",
  //     range: 9,
  //   },
  // ];

  var config = {
    data,
    isGroup: true,
    xField: "key",
    yField: "range",
    seriesField: "name",
    tooltip:{customContent:(val,item)=>{
      // console.log("hhh",item)
      return(
        <div>
          <div style={{marginLeft:"-20px",marginRight:"-20px",alignItems:"center" ,height:"100px"}}>
          <div 
          style={{
            minWidth:"200px"
          }}></div>
          <div style={{display:"flex",marginTop:"8px",width:"190px",marginLeft:"20px"}}>
              {item[0]?.data?.icon != null ?
                <img style={{width: 40, height: 40}} src={`${Api._s3_url}${item[0]?.data?.icon}`}/> :
                <div style={{marginTop: 20, marginLeft: 15 ,height:40,width:40,fontSize:30}}>
                 {item[0]?.data?.subjectName?.charAt(0)}
                </div>}
              <div>
              <div  style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto",marginTop:"10px",width:"150px",marginLeft:"8px",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{item[0]?.data?.subjectName}</div>
              <div  style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto",marginTop:"5px",width:"150px",marginLeft:"8px",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{item[0]?.data?.teacherName}</div> 
              </div>
            </div>
              <div style={{display:"flex",justifyContent:"space-between",marginTop:"10px",width:"150px",marginLeft:"20px"}}>
              <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto"}}>Present  </div>
              <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto",fontWeight:"bold"}}>{parseInt(item[0]?.data?.presentCount)} </div>
              </div>
              <div style={{display:"flex",justifyContent:"space-between",marginTop:"5px",width:"150px",marginLeft:"20px"}}>
              <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto"}}>Absent  </div>
              <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto",fontWeight:"bold" }}>{parseInt(item[1]?.data?.absentCount)}</div>
              </div>
  
          </div>
        </div>

      )
        
    }},
    columnStyle: {
      radius: [3, 3, 0, 0],
    },

    color: ["#28DF99", "#FF414D"],
    legend: false,
    minColumnWidth: 40,
    maxColumnWidth: 40,
  };
  return <Column {...config} />;
};
export default NeetBarGraph;
