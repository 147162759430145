import { CaretDownOutlined } from '@ant-design/icons';
import { Checkbox, Input, Popover, Tooltip } from 'antd';
import { TMPX } from 'ifsc/src/node/bank';
import React, { useEffect, useState } from 'react'




function CustomCheckBox({
    DATA,
    placeholder,
    style,
    className,
    onSelection,
    onDropdownClose,
    setFilters,
}) {

    const [trigger, setTrigger] = useState(false);
    const CheckboxContent = () => {
        useEffect(() => { }, [trigger,]);
        return (
            <div
                id='checkbox'
                className="flex-column organistationcardfilter"
                style={{
                    maxWidth: "200px",
                    maxHeight: "30vw",
                    overflowY: "auto",
                    overflowX: "hidden",
                    marginLeft: -16,
                    marginRight: -16,
                    marginTop: -12,
                    width: "fit-content",
                }}
            >
                <Checkbox.Group
                    className="flex-column"
                    onChange={(e) => {
                        setFilters(e);
                    }}
                >
                    {DATA?.length > 0
                        ? DATA?.map((item, index) => (
                            <Checkbox
                                getPopupContainer={() => document.getElementById('checkbox')}
                                className="black p-10 m-l-0 full-width"
                                // style={{
                                //     backgroundColor: checkRoleArray.includes(index + 1) ? "#F6F4FE" : null,
                                //     fontWeight: checkRoleArray.includes(index + 1) ? "700" : "400",
                                // }}
                                value={item?.val}
                            >
                                <Tooltip
                                    title={item.label}
                                    placement="left"
                                    mouseEnterDelay={0.2}
                                    overlayInnerStyle={{
                                        borderRadius: 5,
                                        marginLeft: 7,
                                        marginRight: 7,
                                    }}
                                >
                                    <div
                                        className="m-l-10 text-overflow-ellipsis full-width"
                                        style={{ fontSize: 14 }}
                                    >
                                        {item.label}
                                    </div>
                                </Tooltip>
                            </Checkbox>
                        ))
                        : null}
                </Checkbox.Group>
            </div>
        );
    };
    return (
        <Popover
            placement="bottomRight"
            content={CheckboxContent}
            trigger="click"
            overlayInnerStyle={{ borderRadius: 7 }}
            onVisibleChange={(bool) => {
                if (!bool && onDropdownClose != undefined) {
                    onDropdownClose();
                }
            }}
        >
            <div
                className="r-c-c"
                style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "5px",
                    border: "1px solid #E6E6E6",
                }}
            >
                <Input
                    style={{ ...style, width: "150px", height: "30px" }}
                    className={className}
                    placeholder={placeholder}
                    size="small"
                    value={null}
                    bordered={false}
                    readOnly
                />
                <CaretDownOutlined style={{ color: "#AEAEAE" }} className="m-r-5" />
            </div>
        </Popover>
    )
}

export default CustomCheckBox;