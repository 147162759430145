import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, DownOutlined, DragOutlined, EditOutlined, MoreOutlined, PlusCircleOutlined, RightOutlined, SearchOutlined, ShareAltOutlined, StarFilled, UserOutlined } from '@ant-design/icons';
import { Button } from 'components/Button';
import { InputSearch } from 'components/customInputs';
import PageHeader from 'components/pageHeader'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { AutoComplete, Checkbox, DatePicker, Dropdown, Input, Menu, Popover, Progress, Radio, Space, Tabs, Tooltip, Typography } from 'antd';
import "./styles.css"
import { connect } from 'react-redux';
import NoCourseImage from "../../Assets/course/ill-courses.png";
import { getCoursesCategories, getCoursesSubCategories, getStudyMaterialsList, getTotalCoursesList, setAllCoursesPageParamsObj, storeSingleCourseId, getTotalsVideoResource, setCourseTypeName, getAutocompleteSearch, getSearchResultsData, setSearchResultPageParamsObj, storeAutocompleteSearchData, getCourseLoadMore, getVideoResourceLoadMore, getStudyMaterialLoadMore, getCourseStats, getOnlineTestSeries, getOnlineTestSeriesCourseDetails, getTestsLoadMore } from 'actions/courseManagement';
import PageDataLoader from 'components/pageDataLoader';
import CreateOrEditCategoryModal from './createOrEditCategoryModal';
import { Api } from 'services';
import moment from 'moment';
import MoveModal from './moveModal';
import DeleteModal from './deleteModal';
import ConfirmPublishOrUnpublishModal from './confirmPublishOrUnpublishModal';
import NoDataCard from 'pages/digitalLibrary/components/noDataCard';
import ShareCourseModal from './shareCourseModal';
import { IMG_URL } from "../../env.json";
import { MdMoreVert } from 'react-icons/md';
import ListViewCoursecards from './ListViewCoursecards';
const defaultCourseImage = require('Assets/default_course-img.svg').default;

export const bodyStyle = {
    backgroundColor: "#FAFAFA",
    margin: "-20px",
    padding: "20px",
    overflow: "auto",
    minHeight: "92vh"
}

function AdminMarketPlaceWithData({ courseTypeName, getCoursesCategories, courseCategoryData, getCoursesSubCategories, courseSubCategoriesData, getTotalCoursesList, setAllCoursesPageParamsObj, totalCoursesDataContainer, storeSingleCourseId, courseStatsData, courseCreateObj, getStudyMaterialsList, getTotalsVideoResource, setCourseTypeName, getAutocompleteSearch, autocompleteSearchData, getSearchResultsData, searchResultData, setSearchResultPageParamsObj, searchPageParamsObj, storeAutocompleteSearchData, getCourseLoadMore, getVideoResourceLoadMore, getStudyMaterialLoadMore, getCourseStats, getOnlineTestSeries, storeCategoryData, getTestsLoadMore, user }) {
    const [isSubCategoryVisible, setSubCategoryVisible] = useState(false);
    const [isGridView, setIsGridView] = useState(false);
    const [isCoursesCardsVisible, setCoursesCardsVisible] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSeletedSubCategory] = useState({});
    const [isCreateOrEditCategoryVisible, setIsCreateOrEditCategoryVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isSubCategory, setIsSubCategory] = useState(false);
    const [selectedUnpublishCards, setSelectedUnpublishCards] = useState([]);
    const [selectedPublishCards, setSelectedPublishCards] = useState([]);
    const [selectedCategoryCards, setSelectedCategoryCards] = useState([]);
    const [selectedSubCategoryCards, setSelectedSubCategoryCards] = useState([]);
    const [isMoveModalVisible, setIsMoveModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isConfirmPublishModalVisible, setIsConfirmPublishModalVisible] = useState(false);
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [shareUrl, setShareUrl] = useState(null);
    const [showCourseTypeDropdown, setCourseTypeDropdown] = useState(false);
    const [showCategoriesDropdown, setCategoriesDropdown] = useState(false);
    const [showSubcategoriesDropdown, setSubcategoriesDropdown] = useState(false);
    const [isFilterCategoryClicked, setFilterCategoryClicked] = useState(false);
    const [isFilterSubCategoryClicked, setFilterSubCategoryClicked] = useState(false);
    const [searchLoader, setSearchLoader] = useState(false);
    const [isSearchedSuggestionClicked, setSearchedSuggestionClicked] = useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    const [searchSelectedCourseType, setSearchSelectedCourseType] = useState(null);
    const [autocompleteSearchedValue, setAutocompleteSearchedValue] = useState(null);
    const [isSearchFilterClicked, setSearchFilterClicked] = useState(false);
    const [previousCourseType, setPreviousCourseType] = useState(null);
    const [loadMorePage, setLoadMorePage] = useState({
        courseLoadMore: 1,
        videoLoadMore: 1,
        studyLoadMore: 1,
        testsLoadMore: 1
    })
    const [isMultipleFilterClicked, setMultipleFilterClicked] = useState(false);
    const [isPublished, setIsPublished] = useState(true);
    const [hoveredCardId, setHoveredCardId] = useState(null);


    const togglePublished = () => {
        setIsPublished(!isPublished);
    };


    const history = useHistory();
    const location = useLocation();
    const hasMarketingPrivilege = user?.privilage?.includes('Marketing') && !user?.privilage?.includes('Admin');
    const publishedTests = storeCategoryData?.published?.items
    const unpublishedTests = storeCategoryData?.unpublished?.items


        useEffect(()=>{
            if(location?.state?.key?.isPublished == true){
                setIsPublished(true)
            }else if(location?.state?.key?.isPublished == false){
                setIsPublished(false)
            }

            if(location?.state?.key?.isGridView == true){
                setIsGridView(true)
            }else if(location?.state?.key?.isGridView == false){
                setIsGridView(false)
            }


            
        },[location])
    const defaultCategoryImage = "defaultCategory.svg";
    useEffect(() => {
        getCourseStats()
        setPreviousCourseType(courseTypeName)
        if (location?.state?.key?.fromDashboardSearch) {
            setSearchedSuggestionClicked(true);
            setSelectedSuggestion(location?.state?.key?.dashboardSearchedValue)
            setSearchResultPageParamsObj("search", location?.state?.key?.dashboardSearchedValue);
            togglePageLoader(true);
            getSearchResultsData(() => { togglePageLoader(false) })
        } else {
            storeAutocompleteSearchData([])
        }
        if (location?.state?.key?.fromSearchResult) {
            setSearchedSuggestionClicked(true);
            setSelectedSuggestion(location?.state?.key?.searchedValue)
        } else {
            if (location?.state?.key == "fromMaybeLaterBtn") {
                setCoursesCardsVisible(true);
                setAllCoursesPageParamsObj("subcategoryId", courseCreateObj?.subcategoryId?.id);
                setSelectedCategory({ id: courseCreateObj?.categoryId?.id, name: courseCreateObj?.categoryId?.name });
                setSeletedSubCategory({ id: courseCreateObj?.subcategoryId?.id, name: courseCreateObj?.subcategoryId?.name })
            }
            if (location?.state?.key?.fromSubcategory?.isSubCategoryVisible) {
                setSubCategoryVisible(true);
                setSelectedCategory({ id: location?.state?.key?.fromSubcategory?.selectedCategory?.id, name: location?.state?.key?.fromSubcategory?.selectedCategory?.name });
            }
            if (location?.state?.key?.fromCourseCard?.isCoursesCardsVisible) {
                setCoursesCardsVisible(true);
                setSelectedCategory({ id: location?.state?.key?.fromCourseCard?.selectedCategory?.id, name: location?.state?.key?.fromCourseCard?.selectedCategory?.name });
                setSeletedSubCategory({ id: location?.state?.key?.fromCourseCard?.selectedSubCategory?.id, name: location?.state?.key?.fromCourseCard?.selectedSubCategory?.name });
            }
        }
    }, [])

    useEffect(() => {
        setMultipleFilterClicked(false);
        resetValues();
        togglePageLoader(true);
        if (!isSubCategoryVisible && !isCoursesCardsVisible) {
            getCoursesCategories(() => { togglePageLoader(false) })
        }
        if (isSubCategoryVisible && selectedCategory?.id && !isCoursesCardsVisible) {
            getCoursesSubCategories(selectedCategory?.id, () => { togglePageLoader(false) })
        }
        if (isCoursesCardsVisible) {
            if (courseTypeName == "liveCourses") {
                getTotalCoursesList(() => togglePageLoader(false))
            } else if (courseTypeName == "studyMaterials") {
                getStudyMaterialsList(() => togglePageLoader(false))
            } else if (courseTypeName == "videoResources") {
                getTotalsVideoResource(() => togglePageLoader(false))
            }
            else if (courseTypeName == "onlineTestSeries") {
                getOnlineTestSeries(() => togglePageLoader(false))

            }
        }
        setSelectedPublishCards([]);
        setSelectedUnpublishCards([])
    }, [isSubCategoryVisible, isCoursesCardsVisible])

    useEffect(() => {
        if (location?.state?.key?.selectedCategory?.id) {

            setSubCategoryVisible(true);
            setSelectedCategory({ id: location?.state?.key?.selectedCategory?.id, name: location?.state?.key?.selectedCategory?.name })
            togglePageLoader(true);
            getCoursesSubCategories(location?.state?.key?.selectedCategory?.id, () => { togglePageLoader(false) })
        }
    }, [location?.state?.key?.selectedCategory?.id])

    const categoryContent = (id, name) => (
        <div style={{ borderRadius: 12 }} >
            <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setIsCreateOrEditCategoryVisible(true); setIsEdit(true); setIsSubCategory(false); setSelectedCategory({ id: id, name: name }) }}><EditOutlined style={{ marginRight: 8 }} />Edit</div>
            <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 10 }} onClick={(e) => { e.stopPropagation(); setIsDeleteModalVisible(true); setSelectedCategoryCards([id]) }}><DeleteOutlined style={{ marginRight: 8 }} />Delete </div>
        </div>
    );
    const subcategoryContent = (id, name) => (
        <div style={{ borderRadius: 12 }}>
            <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setIsCreateOrEditCategoryVisible(true); setIsEdit(true); setIsSubCategory(true); setSeletedSubCategory({ id: id, name: name }) }}><EditOutlined style={{ marginRight: 8 }} />Edit</div>
            <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 10 }} onClick={(e) => { e.stopPropagation(); setIsDeleteModalVisible(true); setSelectedSubCategoryCards([id]) }}><DeleteOutlined style={{ marginRight: 8 }} />Delete </div>
            <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 10 }} onClick={(e) => { e.stopPropagation(); setIsMoveModalVisible(true); setSeletedSubCategory({ id: id, name: name }); setSelectedSubCategoryCards([id]) }}><DragOutlined style={{ marginRight: 8 }} />Move </div>
        </div>
    );
    const CourseCardContent = (id, fromSearchCourse, fromSearchVideo, fromSearchStudy, fromSearchTest) => (
        <div style={{ borderRadius: 12 }}>
            {isGridView ? <></> :
                <>
                    <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer" }}

                        //  onClick={(e) => {
                        //     e.stopPropagation()
                        //     storeSingleCourseId(id);
                        //     if (isSearchedSuggestionClicked) {
                        //         setSearchSelectedCourseType(fromSearchCourse ? "searchCourse" : fromSearchVideo ? "searchVideo" : fromSearchStudy ? "searchStudyMaterial" : fromSearchTest ? "searchTests" : null);
                        //         setCourseTypeName(fromSearchCourse ? "liveCourses" : fromSearchVideo ? "videoResources" : fromSearchStudy ? "studyMaterials" : fromSearchTest ? "onlineTestSeries" : null);
                        //         history?.push(
                        //             fromSearchCourse ?
                        //                 {
                        //                     pathname: "/add-live-courses",
                        //                     state: {
                        //                         key: {
                        //                             isEdit: "isFromEdit",
                        //                             isSearchedSuggestionClicked: isSearchedSuggestionClicked,
                        //                             searchedValue: selectedSuggestion,
                        //                             fromDashboard: location?.state?.key?.fromDashboardSearch
                        //                         }
                        //                     }
                        //                 } :
                        //                 fromSearchVideo ?
                        //                     {
                        //                         pathname: "/add-video-resources",
                        //                         state: {
                        //                             key: {
                        //                                 isEdit: "isFromEdit",
                        //                                 isSearchedSuggestionClicked: isSearchedSuggestionClicked,
                        //                                 searchedValue: selectedSuggestion,
                        //                                 fromDashboard: location?.state?.key?.fromDashboardSearch
                        //                             }
                        //                         }
                        //                     } :
                        //                     fromSearchStudy ?
                        //                         {
                        //                             pathname: "/add-study-materials",
                        //                             state: {
                        //                                 key: {
                        //                                     isEdit: "isFromEdit",
                        //                                     isSearchedSuggestionClicked: isSearchedSuggestionClicked,
                        //                                     searchedValue: selectedSuggestion,
                        //                                     fromDashboard: location?.state?.key?.fromDashboardSearch
                        //                                 }
                        //                             }
                        //                         } :
                        //                         fromSearchTest ?
                        //                             {
                        //                                 pathname: "/add-online-test-series",
                        //                                 state: {
                        //                                     key: {
                        //                                         isEdit: "isFromEdit",
                        //                                         isSearchedSuggestionClicked: isSearchedSuggestionClicked,
                        //                                         searchedValue: selectedSuggestion,
                        //                                         fromDashboard: location?.state?.key?.fromDashboardSearch
                        //                                     }
                        //                                 }
                        //                             }
                        //                             :
                        //                             "/add-live-courses")
                        //     } else {
                        //         history?.push(courseTypeName == "liveCourses" ?
                        //             {
                        //                 pathname: "/add-live-courses", state: {
                        //                     key: {
                        //                         isEdit: "isFromEdit",
                        //                         fromCourseCard: {
                        //                             isCoursesCardsVisible: isCoursesCardsVisible,
                        //                             selectedCategory: selectedCategory,
                        //                             selectedSubCategory: selectedSubCategory
                        //                         }
                        //                     }
                        //                 }
                        //             } :
                        //             courseTypeName == "videoResources" ?
                        //                 {
                        //                     pathname: "/add-video-resources", state: {
                        //                         key: {
                        //                             isEdit: "isFromEdit",
                        //                             fromCourseCard: {
                        //                                 isCoursesCardsVisible: isCoursesCardsVisible,
                        //                                 selectedCategory: selectedCategory,
                        //                                 selectedSubCategory: selectedSubCategory
                        //                             }
                        //                         }
                        //                     }
                        //                 } :
                        //                 courseTypeName == "onlineTestSeries" ?
                        //                     {
                        //                         pathname: "/add-online-test-series", state: {
                        //                             key: {
                        //                                 isEdit: "isFromEdit",
                        //                                 fromCourseCard: {
                        //                                     isCoursesCardsVisible: isCoursesCardsVisible,
                        //                                     selectedCategory: selectedCategory,
                        //                                     selectedSubCategory: selectedSubCategory
                        //                                 }
                        //                             }
                        //                         }
                        //                     } :
                        //                     courseTypeName == "studyMaterials" ?
                        //                         {
                        //                             pathname: "/add-study-materials", state: {
                        //                                 key: {
                        //                                     isEdit: "isFromEdit",
                        //                                     fromCourseCard: {
                        //                                         isCoursesCardsVisible: isCoursesCardsVisible,
                        //                                         selectedCategory: selectedCategory,
                        //                                         selectedSubCategory: selectedSubCategory
                        //                                     }
                        //                                 }
                        //                             }
                        //                         } : "/add-live-courses")
                        //     }
                        // }}

                        onClick={(e) => {
                            e.stopPropagation();
                            storeSingleCourseId(id);
                            if (isSearchedSuggestionClicked) {
                                setCourseTypeName(fromSearchCourse ? "liveCourses" : fromSearchVideo ? "videoResources" : null);
                            }
                            history.push({
                                pathname: "/course-details",
                                state: {
                                    key: {
                                        fromCourseCard: {
                                            isCoursesCardsVisible: isCoursesCardsVisible,
                                            selectedCategory: selectedCategory,
                                            selectedSubCategory: selectedSubCategory,
                                        },
                                        isSearchedSuggestionClicked: isSearchedSuggestionClicked,
                                        searchedValue: selectedSuggestion,
                                        fromDashboard: location?.state?.key?.fromDashboardSearch,
                                        fromDashboardSearch: location?.state?.key?.fromDashboardSearch,
                                        isPublished:isPublished,
                                        isGridView:isGridView
                                    }
                                }
                            })
                        }}

                    >
                        <EditOutlined style={{ marginRight: 8 }} />Edit</div>
                    <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 10 }} onClick={(e) => { e.stopPropagation(); setIsDeleteModalVisible(true); setSelectedPublishCards([id]); setSelectedUnpublishCards([id]); if (isSearchedSuggestionClicked) { setSearchSelectedCourseType(fromSearchCourse ? "searchCourse" : fromSearchVideo ? "searchVideo" : fromSearchStudy ? "searchStudyMaterial" : fromSearchTest ? "searchTests" : null) } }}>
                        <DeleteOutlined style={{ marginRight: 8 }} />Delete </div>
                    {(fromSearchCourse || fromSearchVideo || fromSearchStudy || fromSearchTest) ? <></> : <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 10 }} onClick={(e) => { e.stopPropagation(); setIsMoveModalVisible(true); setSelectedPublishCards([id]); setSelectedUnpublishCards([id]) }}>
                        <DragOutlined style={{ marginRight: 8 }} />Move </div>}
                </>
            }



            <div style={{ display: 'flex', marginTop: 3 }} onClick={(e) => { e.stopPropagation(); !isPublished ? toggleUnpublishedCheckbox(id, true) : togglePublishedCheckbox(id, true); setIsConfirmPublishModalVisible(true); }}>
                <div><img src={isPublished ? require("../../Assets/courseManagementV4/unpublished.svg").default : require("../../Assets/courseManagementV4/publish.svg").default} style={{ width: "14px", height: "14px", color: "#636363", marginLeft: 2, marginTop: isGridView ? 9 : courseTypeName !== 'liveCourses' ? 10 : 6 }} onClick={(e) => { e.stopPropagation() }} /></div>
                <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 5, marginLeft: 8 }}>{isPublished ? 'Unpublish' : 'Publish'}</div>
            </div>

            {/* {courseTypeName == "liveCourses" ? <div style={{display:'flex', justifyContent:'space-between', marginTop:3}}>
                        <div><img src={require("../../Assets/courseManagementV4/markas.svg").default} style={{ width: "14px", height: "14px", color: "#636363", marginLeft:2 , marginTop: 10}} onClick={(e) => { e.stopPropagation() }} /></div>
                        <div style={{color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 5, marginLeft:8}}>Mark as course completed</div>
                    </div> :
                    <></> 
                    }     */}


        </div>
    );
    function extractInitials(name) {
        const words = name.split(/\s+/);
        let initials = '';

        if (words.length > 0) {
            initials += words[0].charAt(0).toUpperCase();

            if (words.length > 1) {
                initials += words[words.length - 1].charAt(0).toUpperCase();
            }
        }

        return initials;
    }
    const CategoryCard = ({ item, index }) => {


        return (
            <div className='category-subcategory' style={{ display: "flex", flexDirection: "column" }}>
                {!item?.iconPath ?
                    <div className='cat-subCat-hovered' style={{ border: "1px solid #EDEDED", backgroundColor: item?.iconColor ? item?.iconColor : "#E4F1FB", borderRadius: 8, padding: 10, height: 160, width: 200, cursor: "pointer", margin: "10px 20px 5px 0px" }} onClick={(e) => { e.stopPropagation(); setSubCategoryVisible(true); setSelectedCategory({ id: item?.id, name: item?.name }); setSelectedCategoryCards([]); setSelectedSubCategoryCards([]) }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {hasMarketingPrivilege ? <div style={{ height: 22, width: 22 }}></div> :
                                <div onClick={(e) => { e.stopPropagation() }}><Checkbox checked={selectedCategoryCards?.includes(courseCategoryData?.[index]?.id)} onChange={(e) => { e.stopPropagation(); toggleCategoryCheckbox(item?.id) }} /></div>
                            }
                            {hasMarketingPrivilege ? <></> :
                                <div className={`${selectedCategoryCards?.length > 0 ? 'three-dots-div' : 'hovered-text-visible'}`}>
                                    <Popover content={selectedCategoryCards?.length > 0 ? null : categoryContent(item?.id, item?.name)} trigger="hover" placement='right' showArrow={false}>
                                        <img src={require("../../Assets/courseManagementV4/new-more-icon.svg").default} style={{ width: "24px", height: "22px", color: "grey" }} onClick={(e) => { e.stopPropagation() }} />
                                    </Popover>
                                </div>
                            }

                        </div>
                        <div style={{ position: "relative" }}>
                            <div className='r-c-c'><img src={`${Api.dlS3Url}${defaultCategoryImage}`} style={{ width: 140, height: 140, marginTop: -21 }} /></div>
                            <div className='r-c-c' style={{ position: "absolute", top: 34, left: 83, fontSize: 17, color: "#594099", fontWeight: "bold", width: 10 }}>{item?.name?.charAt(0).toUpperCase()}</div>
                        </div>
                        {/* <div style={{ display: "flex", justifyContent: 'flex-end', height: 30, width: 199, background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 5%, rgba(0, 0, 0, 1) 110%)", padding: "0px 10px", alignItems: "center", borderRadius: "0px 0px 8px 8px", marginLeft: -10, marginTop: -10 }}>
                            <div style={{ color: "#FFFFFF", fontWeight: "bold" }}>{parseInt(item?.courseCount) || 0} {item?.courseCount > 1 ? "courses" : "course"}</div>
                        </div> */}
                    </div> :
                    <div className='cat-subCat-hovered' style={{ width: 200, cursor: "pointer", margin: "10px 20px 5px 0px", position: "relative" }} onClick={(e) => { e.stopPropagation(); setSubCategoryVisible(true); setSelectedCategory({ id: item?.id, name: item?.name }); setSelectedCategoryCards([]); setSelectedSubCategoryCards([]) }}>
                        <div style={{ display: "flex", justifyContent: "space-between", position: "absolute" }}>
                            {hasMarketingPrivilege ? <div style={{ height: 22, width: 22 }}></div> :
                                <div onClick={(e) => { e.stopPropagation() }} style={{ position: "absolute", top: 8, left: 8 }}><Checkbox checked={selectedCategoryCards?.includes(courseCategoryData?.[index]?.id)} onChange={(e) => { e.stopPropagation(); toggleCategoryCheckbox(item?.id) }} /></div>
                            }

                            {hasMarketingPrivilege ? <></> :
                                <div className={`${selectedCategoryCards?.length > 0 ? 'three-dots-div' : 'hovered-text-visible'}`}>
                                    <Popover content={selectedCategoryCards?.length > 0 ? null : categoryContent(item?.id, item?.name)} trigger="hover" placement='right' showArrow={false}>
                                        <img src={require("../../Assets/courseManagementV4/new-more-icon.svg").default} style={{ width: "24px", height: "22px", color: "grey", position: "absolute", top: 8, left: 170 }} onClick={(e) => { e.stopPropagation() }} />
                                    </Popover>
                                </div>
                            }

                        </div>
                        <img src={`${Api._s3_url.slice(0, -1)}${item?.iconPath}`} style={{ width: "100%", borderRadius: 8 }} />
                        {/* <div style={{ position: "absolute", display: "flex", justifyContent: 'flex-end', height: 30, width: 199, background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 5%, rgba(0, 0, 0, 1) 110%)", padding: "0px 10px", alignItems: "center", borderRadius: "0px 0px 8px 8px", bottom: 0 }}>
                            <div style={{ color: "#FFFFFF", fontWeight: "bold" }}>{parseInt(item?.courseCount) || 0} {item?.courseCount > 1 ? "courses" : "course"}</div>
                        </div> */}
                    </div>}
                <Tooltip placement="top" title={item?.name}>
                    <Typography.Text className='text-ellipsis-div' style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 190 }}>{item?.name}</Typography.Text>
                </Tooltip>
                <div className='m-b-20'>
                    <div style={{ color: "#636363" }}><span style={{ fontWeight: 600 }}>{parseInt(item?.courseCount) || 0}</span> {parseInt(item?.courseCount) > 1 ? "courses" : "course"}  • <span style={{ fontWeight: 600 }}>{parseInt(item?.studentCount) || 0}</span> {courseTypeName == 'liveCourses' ? 'enrolled' : 'purchased'}</div>
                </div>
            </div>
        )
    }
    const SubCategoryCard = ({ item, index }) => {
        return (
            <div className='category-subcategory' style={{ display: "flex", flexDirection: "column" }}>
                <div className='cat-subCat-hovered' style={{ border: "1px solid #EDEDED", backgroundColor: selectedSubCategoryCards?.includes(courseSubCategoriesData?.[index]?.id) ? '#DACFF2' : index % 2 === 0 ? "#F6F4FE" : "#FFFFFF", borderRadius: 8, padding: 10, height: 75, width: 200, cursor: "pointer", margin: "10px 20px 5px 0px", border: "1px solid #E6E6E6" }} onClick={(e) => { e.stopPropagation(); setCoursesCardsVisible(true); setSeletedSubCategory({ id: item?.id, name: item?.name }); setAllCoursesPageParamsObj("subcategoryId", item?.id); setSelectedSubCategoryCards([]); setSelectedPublishCards([]); setSelectedUnpublishCards([]); setSubCategoryVisible(false) }}>
                    {hasMarketingPrivilege ? <div style={{ height: 22, width: 22 }}></div> :
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div onClick={(e) => { e.stopPropagation() }}><Checkbox checked={selectedSubCategoryCards?.includes(courseSubCategoriesData?.[index]?.id)} onChange={(e) => { e.stopPropagation(); toggleSubCategoryCheckbox(item?.id) }} /></div>
                            <div className={`${selectedSubCategoryCards?.length > 0 ? 'three-dots-div' : 'hovered-text-visible'}`}>
                                <Popover content={selectedSubCategoryCards?.length > 0 ? null : subcategoryContent(item?.id, item?.name)} trigger="hover" placement='right' showArrow={false}>
                                    <img src={require("../../Assets/courseManagementV4/new-more-icon.svg").default} style={{ width: "24px", height: "22px", color: "grey" }} onClick={(e) => { e.stopPropagation() }} />
                                </Popover>
                            </div>
                        </div>
                    }

                    <center style={{ color: "#594099", fontSize: 28, fontWeight: "bold", marginTop: -18 }}>{extractInitials(item?.name)}</center>
                    {/* <div style={{ display: "flex", justifyContent: 'flex-end', height: 30, width: 199, background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 5%, rgba(0, 0, 0, 1) 110%)", padding: "0px 10px", alignItems: "center", borderRadius: "0px 0px 8px 8px", marginLeft: -10, marginTop: 13 }}>
                        <div style={{ color: "#FFFFFF", fontWeight: "bold" }}>{parseInt(item?.courseCount) || 0} {item?.courseCount > 1 ? "courses" : "course"}</div>
                    </div> */}
                </div>
                <Tooltip placement="top" title={item?.name}>
                    <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 190 }}>{item?.name}</Typography.Text>
                </Tooltip>
                <div className='m-b-20'>
                    <div style={{ color: "#636363" }}><span style={{ fontWeight: 600 }}>{parseInt(item?.courseCount) || 0}</span> {parseInt(item?.courseCount) > 1 ? "courses" : "course"}  • <span style={{ fontWeight: 600 }}>{parseInt(item?.studentCount) || 0}</span> {courseTypeName == 'liveCourses' ? 'enrolled' : 'purchased'}</div>
                </div>
            </div>
        )
    }
    const toggleCategoryCheckbox = (id) => {
        if (selectedCategoryCards.includes(id)) {
            setSelectedCategoryCards(selectedCategoryCards?.filter(cardId => cardId !== id));
        } else {
            setSelectedCategoryCards([...selectedCategoryCards, id]);
        }
    };
    function onSelectAllCategory(e) {
        let temp = [];
        if (e.target.checked) {
            for (let i = 0; i < courseCategoryData?.length; i++) {
                temp.push(courseCategoryData?.[i]?.id)
            }
        }
        setSelectedCategoryCards([...temp])
    }
    const toggleSubCategoryCheckbox = (id) => {
        if (selectedSubCategoryCards.includes(id)) {
            setSelectedSubCategoryCards(selectedSubCategoryCards?.filter(cardId => cardId !== id));
        } else {
            setSelectedSubCategoryCards([...selectedSubCategoryCards, id]);
        }
    };
    function onSelectAllSubCategory(e) {
        let temp = [];
        if (e.target.checked) {
            for (let i = 0; i < courseSubCategoriesData?.length; i++) {
                temp.push(courseSubCategoriesData?.[i]?.id)
            }
        }
        setSelectedSubCategoryCards([...temp])
    }
    const toggleUnpublishedCheckbox = (id, isListPublishClicked) => {


        if (isListPublishClicked) {
            setSelectedUnpublishCards([id]);
        }
        else {
            if (selectedUnpublishCards.includes(id)) {
                setSelectedUnpublishCards(selectedUnpublishCards?.filter(cardId => cardId !== id));
            } else {
                setSelectedUnpublishCards([...selectedUnpublishCards, id]);
            }
        }

    };



    function onSelectAllUnpublish(e) {
        let temp = [];
        if (e.target.checked) {
            if (courseTypeName == "onlineTestSeries") {
                for (let i = 0; i < unpublishedTests?.length; i++) {
                    temp.push(unpublishedTests?.[i]?.id)
                }
            }
            else
                for (let i = 0; i < totalCoursesDataContainer?.unpublished?.items?.length; i++) {
                    temp.push(totalCoursesDataContainer?.unpublished?.items?.[i]?.id)
                }
        }
        setSelectedUnpublishCards([...temp])
    }
    const togglePublishedCheckbox = (id, isListPublishClicked) => {

        if (isListPublishClicked) {
            setSelectedPublishCards([id]);
        } else {
            if (selectedPublishCards.includes(id)) {
                setSelectedPublishCards(selectedPublishCards?.filter(cardId => cardId !== id));
            } else {
                setSelectedPublishCards([...selectedPublishCards, id]);
            }
        }

    };
    function onSelectAllPublish(e) {
        let temp = [];
        if (e.target.checked) {
            if (courseTypeName == "onlineTestSeries") {
                for (let i = 0; i < publishedTests?.length; i++) {
                    temp.push(publishedTests?.[i]?.id)
                }
            }
            else
                for (let i = 0; i < totalCoursesDataContainer?.published?.items?.length; i++) {
                    temp.push(totalCoursesDataContainer?.published?.items?.[i]?.id)
                }
        }
        setSelectedPublishCards([...temp])
    }
    const LiveCourseCard = ({ item, index, reference, fromSearchCourse, fromSearchVideo, fromSearchStudy, fromSearchTest }) => {
        const getAdjustedHour = (duration) => {

            const [hours, minutes] = duration?.split(':').map(Number);

            if (minutes > 30) {
                return hours + 1;
            } else {
                return hours;
            }


        };
        const isSelectedCard = courseTypeName === 'onlineTestSeries'
            ? (isPublished
                ? selectedPublishCards?.includes(publishedTests[index]?.id)
                : selectedUnpublishCards?.includes(unpublishedTests[index]?.id)
            )
            : (
                (fromSearchCourse && reference === 'unpublished')
                    ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                    : (fromSearchCourse && reference === 'published')
                        ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                        : (fromSearchVideo && reference === 'unpublished')
                            ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                            : (fromSearchVideo && reference === 'published')
                                ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                : reference === 'unpublished'
                                    ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                    : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
            );

        return (
            // <div className={`${(fromSearchCourse || fromSearchVideo) ? 'search-live-course-card' : 'zoom live-course-card'}`}
            <div className='new-course-card' style={{
                backgroundColor:
                    courseTypeName === 'onlineTestSeries'
                        ? (
                            isSelectedCard ? "#EDE9F6" : "#ffffff"
                        )
                        : (
                            (fromSearchCourse && reference === "unpublished")
                                ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                : (fromSearchCourse && reference === "published")
                                    ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                    : (fromSearchVideo && reference === "unpublished")
                                        ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                        : (fromSearchVideo && reference === "published")
                                            ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                            : reference === "unpublished"
                                                ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
                        )
                            ? "#EDE9F6"
                            : "#ffffff"




                , padding: 15, cursor: 'pointer', borderRadius: 10, width: '31.9%', marginBottom: '2.1%', boxShadow: '4px 4px 16px #0000000F'
            }}
                onClick={(e) => {
                    e.stopPropagation();
                    storeSingleCourseId(item?.id);
                    if (isSearchedSuggestionClicked) {
                        setCourseTypeName(fromSearchCourse ? "liveCourses" : fromSearchVideo ? "videoResources" : null);
                    }
                    history.push({
                        pathname: "/course-details",
                        state: {
                            key: {
                                fromCourseCard: {
                                    isCoursesCardsVisible: isCoursesCardsVisible,
                                    selectedCategory: selectedCategory,
                                    selectedSubCategory: selectedSubCategory,
                                },
                                isSearchedSuggestionClicked: isSearchedSuggestionClicked,
                                searchedValue: selectedSuggestion,
                                fromDashboard: location?.state?.key?.fromDashboardSearch,
                                fromDashboardSearch: location?.state?.key?.fromDashboardSearch,
                                isPublished:isPublished,
                                isGridView:isGridView
                            }
                        }
                    })
                }}
            >
                <div style={{ position: "relative", }}>
                    <img src={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} style={{ width: "100%", height: 180, borderRadius: 8 }} loading='lazy' />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {hasMarketingPrivilege ? <></> :
                            <div style={{ position: "absolute", top: 7, left: 10 }} onClick={(e) => { e.stopPropagation() }}>


                                <Checkbox
                                    checked={courseTypeName == 'onlineTestSeries' ?

                                        ((fromSearchStudy && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                            : (fromSearchStudy && reference == "published") ? selectedPublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                                : (fromSearchTest && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.onlineTestSeries?.items?.[index]?.id)
                                                    : (fromSearchTest && reference == "published") ? selectedPublishCards?.includes(searchResultData?.onlineTestSeries?.items?.[index]?.id)
                                                        : reference == "unpublished"
                                                            ? (courseTypeName == "onlineTestSeries")
                                                                ? selectedUnpublishCards?.includes(unpublishedTests?.[index]?.id)
                                                                : selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                            : (courseTypeName == "onlineTestSeries")
                                                                ? selectedPublishCards?.includes(publishedTests?.[index]?.id)
                                                                : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
                                        )

                                        :

                                        (fromSearchCourse && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                            : (fromSearchCourse && reference == "published") ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                                : (fromSearchVideo && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                                    : (fromSearchVideo && reference == "published") ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                                        : reference == "unpublished" ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                            : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)}


                                    onChange={() => {
                                        if (fromSearchCourse || fromSearchVideo) {
                                            setSearchSelectedCourseType(fromSearchCourse ? "searchCourse" : "searchVideo")
                                        }
                                        if (fromSearchStudy) {
                                            setSearchSelectedCourseType("searchStudyMaterial")
                                        }
                                        if (fromSearchTest) {
                                            setSearchSelectedCourseType("searchTests")
                                        }

                                        reference == "unpublished" ? toggleUnpublishedCheckbox(item?.id, false) : togglePublishedCheckbox(item?.id, false)
                                    }} />


                            </div>
                        }


                        {hasMarketingPrivilege ? <></> :
                            <div className={`${(isSelectedCard) ? 'three-dots-div' : 'hovered-text-visible'}`} style={{ cursor: isSelectedCard ? 'not-allowed' : 'pointer' }}>
                                <Popover content={(isSelectedCard) ? null : CourseCardContent(item?.id, fromSearchCourse, fromSearchVideo, false, false)} trigger="hover" placement='right' showArrow={false}>
                                    <img src={require("../../Assets/courseManagementV4/new-more-icon.svg").default} style={{ width: "24px", height: "22px", color: "grey", position: "absolute", top: 5, right: 5 }} onClick={(e) => { e.stopPropagation() }} />
                                </Popover>
                            </div>
                        }

                    </div>
                    {hasMarketingPrivilege ? <></> :
                        <div style={{ position: "absolute", top: 7, left: 10 }} onClick={(e) => { e.stopPropagation() }}>
                            <Checkbox
                                checked={courseTypeName == 'onlineTestSeries' ?

                                    ((fromSearchStudy && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                        : (fromSearchStudy && reference == "published") ? selectedPublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                            : (fromSearchTest && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.onlineTestSeries?.items?.[index]?.id)
                                                : (fromSearchTest && reference == "published") ? selectedPublishCards?.includes(searchResultData?.onlineTestSeries?.items?.[index]?.id)
                                                    : reference == "unpublished"
                                                        ? (courseTypeName == "onlineTestSeries")
                                                            ? selectedUnpublishCards?.includes(unpublishedTests?.[index]?.id)
                                                            : selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                        : (courseTypeName == "onlineTestSeries")
                                                            ? selectedPublishCards?.includes(publishedTests?.[index]?.id)
                                                            : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
                                    )

                                    :

                                    (fromSearchCourse && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                        : (fromSearchCourse && reference == "published") ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                            : (fromSearchVideo && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                                : (fromSearchVideo && reference == "published") ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                                    : reference == "unpublished" ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                        : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)}


                                onChange={() => {
                                    if (fromSearchCourse || fromSearchVideo) {
                                        setSearchSelectedCourseType(fromSearchCourse ? "searchCourse" : "searchVideo")
                                    }
                                    if (fromSearchStudy) {
                                        setSearchSelectedCourseType("searchStudyMaterial")
                                    }
                                    if (fromSearchTest) {
                                        setSearchSelectedCourseType("searchTests")
                                    }

                                    reference == "unpublished" ? toggleUnpublishedCheckbox(item?.id, false) : togglePublishedCheckbox(item?.id, false)
                                }} />
                        </div>
                    }

                    {(courseTypeName == "videoResources" || fromSearchVideo) ? <></> : <div style={{ borderTopLeftRadius: 3, borderBottomLeftRadius: 3, fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "5px 10px", position: "absolute", fontWeight: "bold", top: 28, right: 0 }}>{item?.mode == "online" ? "Online" : item?.mode == "offline" ? "Offline" : "Hybrid"}</div>}
                    {/* {item?.amount ?
                        <div className='amount-text' style={{ fontSize: 10, color: "#594099", backgroundColor: "#F6F4FE", padding: "5px 10px", position: "absolute", fontWeight: "bold", bottom: 10, right: 10, borderRadius: 4, border: " 1px solid #594099" }}>₹ {parseFloat(item?.amount || 0) - parseFloat(item?.totalDiscount || 0)}</div>
                        :
                        <div className='amount-text' style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: "#FF414D", padding: "5px 20px", position: "absolute", fontWeight: "bold", bottom: 10, right: 10, borderRadius: 4, border: " 1px solid #FF414D" }}>FREE</div>
                    } */}
                    <div className={`${(fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : 'hovered-text-visible'}`} style={{ justifyContent: 'space-between', height: 30, width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "0px 10px", alignItems: "center", position: "absolute", bottom: 0, borderRadius: "0px 0px 8px 8px" }}>
                        {item?.ratingValue ? <div><StarFilled style={{ color: "#FFA931", marginRight: 5 }} /><span style={{ color: "#FFFFFF", fontWeight: "bold" }}>{item?.ratingValue || 0}</span></div> : <div></div>}
                        <div><UserOutlined style={{ color: "#FFFFFF", marginRight: 5 }} /><span style={{ color: "#FFFFFF", fontWeight: "bold" }}>{item?.studentCount} {courseTypeName == 'liveCourses' ? 'enrolled' : 'purchased'}</span></div>
                    </div>
                </div>
                <div style={{ padding: "0px 0px 0px 0px", }}>
                    {/* {courseTypeName == 'videoResources' ?
                        <div className={`${(fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : 'hovered-text-visible'}`} style={{ fontSize: 12, fontWeight: 600, color: "#636363", marginTop: 10 }}>{item?.sectionCount}sections •{item?.topicCount} •lecture {item?.duration}Total length • </div> :
                        <div className={`${(fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : 'hovered-text-visible'}`} style={{ fontSize: 12, fontWeight: 600, color: "#636363", marginTop: 10 }}>{item?.duration} {item?.durationType} • {item?.subjectCount} Subjects • {item?.chapterCount} Chapters</div>

                    } */}
                    {(fromSearchCourse || fromSearchVideo) ?
                        <div className='r-jsb m-t-5'>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 200 }}>{item?.name}</Typography.Text>
                            <div>{item?.published ?
                                <img src={require("../../Assets/course/check.svg").default} style={{ height: 16, width: 16 }} />
                                :
                                <img src={require("../../Assets/course/block.svg").default} style={{ height: 16, width: 16 }} />
                            }</div>
                        </div>
                        :
                        <Typography.Text
                            ellipsis={false}
                            style={{
                                fontSize: 16,
                                color: "#191919",
                                fontWeight: "400",
                                marginTop: 7,
                                width: '100%',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                lineHeight: '23px'
                            }}
                        >
                            {item?.name}
                        </Typography.Text>
                    }

                    {courseTypeName == 'liveCourses' ?
                        <div style={{ color: '#636363', marginTop: 2 }}>
                            <span>{parseInt(item?.duration) > 0 ? item?.duration : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.duration) > 0 ? item?.durationType?.toLowerCase() : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.subjectCount) > 0 ? '|' : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.subjectCount) > 0 ? item?.subjectCount : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.subjectCount) > 0 ? parseInt(item?.subjectCount) == 1 ? 'subject' : 'subjects' : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.chapterCount) > 0 ? '|' : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.chapterCount) > 0 ? item?.chapterCount : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.chapterCount) > 0 ? parseInt(item?.chapterCount) == 1 ? 'chapter' : 'chapters' : <></>}</span>
                        </div>
                        : courseTypeName == 'videoResources' ?

                            <div style={{ color: '#636363', marginTop: 2 }}>
                                <span>{item?.duration ? getAdjustedHour(item?.duration) > 0 ? getAdjustedHour(item?.duration) : <></> : <></>}</span>
                                <span style={{ marginLeft: item?.duration ? getAdjustedHour(item?.duration) > 0 ? 5 : 0 : 0 }}>{item?.duration ? getAdjustedHour(item?.duration) > 0 ? 'hours' : <></> : <></>}</span>
                                <span style={{ marginLeft: item?.duration ? getAdjustedHour(item?.duration) > 0 ? 5 : 0 : 0 }}>{parseInt(item?.quizCount) > 0 && item?.duration ? getAdjustedHour(item?.duration) > 0 ? '|' : <></> : <></>}</span>
                                <span style={{ marginLeft: item?.duration ? getAdjustedHour(item?.duration) > 0 ? 5 : 0 : 0 }}>{parseInt(item?.quizCount) > 0 ? item?.quizCount : <></>}</span>
                                <span style={{ marginLeft: parseInt(item?.quizCount) > 0 ? 5 : 0 }}>{parseInt(item?.quizCount) > 0 ? parseInt(item?.quizCount) == 1 ? 'quiz' : 'quizzes' : <></>}</span>
                                <span style={{ marginLeft: parseInt(item?.notesCount) > 0 && parseInt(item?.quizCount) > 0 ? 5 : 0 }}>{parseInt(item?.notesCount) > 0 && parseInt(item?.quizCount) > 0 ? '|' : <></>}</span>
                                <span style={{ marginLeft: parseInt(item?.notesCount) > 0 && parseInt(item?.quizCount) > 0 ? 5 : 0 }}>{parseInt(item?.notesCount) > 0 ? item?.notesCount : <></>}</span>
                                <span style={{ marginLeft: 5 }}>{parseInt(item?.notesCount) > 0 ? parseInt(item?.notesCount) == 1 ? 'notes' : 'notes' : <></>}</span>
                            </div>
                            :
                            courseTypeName == 'onlineTestSeries' ?

                                <div style={{ color: '#636363', marginTop: 2 }}>
                                    <span>{parseInt(item?.tCount) > 0 ? item?.tCount : <></>}</span>
                                    <span style={{ marginLeft: 5 }}>{parseInt(item?.tCount) > 0 ? parseInt(item?.tCount) == 1 ? 'test' : 'tests' : <></>}</span>

                                </div>
                                :
                                <div style={{ color: '#636363', marginTop: 2 }}>
                                    <span>{parseInt(item?.m_count) > 0 ? item?.m_count : <></>}</span>
                                    <span style={{ marginLeft: 5 }}>{parseInt(item?.m_count) > 0 ? parseInt(item?.m_count) == 1 ? 'material' : 'materials' : <></>}</span>

                                </div>
                    }
                    {/* <div className='hovered-text-visible'>
                        {courseTypeName == 'videoResources' ?
                            (<div style={{ display: "flex" }}>
                                <img
                                    src={`${IMG_URL}/${item?.teacherImage || "default.png"}`}
                                    className="radius-100 batch-detial__student-img"
                                    style={{ height: 30, width: 30, borderRadius: '50%' }}
                                />
                                <div style={{ marginLeft: "5px", color: "#636363", fontSize: "12px", fontWeight: "bold", marginTop: "5px" }}>{item?.teacherName}</div>
                            </div>) : null}
                    </div> */}

                    <div style={{ display: "flex", justifyContent: 'space-between', marginTop: 2 }}>
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            <RatingStars star={item?.ratingValue || 0} />
                            <div style={{ color: "#AEAEAE", margin: "5px 0px 0px 10px", fontFamily: "roboto", fontWeight: 400, marginTop: -1 }}>({item?.ratingCount || 0})</div>
                        </div>
                        <div>
                            {parseInt(item?.studentCount) > 0 ?
                                <span ><img src={require("../../Assets/courseManagementV4/Team.svg").default} style={{ marginTop: -4, height: 15, width: 15 }} /></span> : <></>}
                            <span style={{ color: '#636363', marginLeft: 10, }}>{parseInt(item?.studentCount) > 0 ? item?.studentCount : <></>}</span> <span style={{ marginLeft: 5, color: '#636363', marginTop: -3 }}><span style={{ color: '#636363' }}>{parseInt(item?.studentCount) > 0 ? 'enrolled' : <></>}</span></span>
                        </div>


                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <div>
                            {/* Calculate final price after discount */}
                            {item?.discountType ? item?.discountType === "percent"
                                ? Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100 === 0
                                    ? <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", backgroundColor: "#FF414D", width: "50px", height: 25, marginTop: 8, }}>
                                        <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                                    </div>
                                    : (
                                        <>

                                            <span style={{ fontSize: 18, fontWeight: "500", color: "#594099" }}>
                                                ₹ {Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100}
                                            </span>

                                            {item?.discount > 0 && (
                                                <span
                                                    style={{ fontSize: 14, color: "#636363", marginLeft: 10, textDecoration: 'line-through' }}>
                                                    ₹ {item?.amount}
                                                </span>
                                            )}
                                        </>
                                    )
                                : item?.discountType === "amount"
                                    ? (item?.amount - item?.discount) === 0
                                        ? <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", backgroundColor: "#FF414D", width: "50px", height: 25, marginTop: 8 }}>
                                            <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                                        </div>
                                        : <span style={{ fontSize: 18, fontWeight: "500", color: "#594099" }}>
                                            ₹ {item?.amount - item?.discount}
                                        </span>
                                    : <span style={{ fontSize: 18, fontWeight: "500", color: "#594099" }}>
                                        ₹ {item?.amount}
                                    </span>
                                :
                                (
                                    item?.amount - item?.discount === 0 ?
                                        <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", backgroundColor: "#FF414D", width: "50px", height: 25, marginTop: 8 }}>
                                            <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                                        </div>
                                        :
                                        <div>
                                            : <span style={{ fontSize: 18, fontWeight: "500", color: "#594099" }}>
                                                ₹ {item?.amount - item?.discount}
                                            </span>
                                        </div>)
                            }

                        </div>
                        <div style={{
                            display: 'flex',
                            marginTop: (item?.discountType === "percent" && Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100 === 0)
                                || (item?.discountType === "amount" && (item?.amount - item?.discount) === 0)
                                ? '8px'
                                : '0px'
                        }}>

                            <span>
                                <img
                                    src={require("../../Assets/courseManagementV4/share-2.svg").default}
                                    style={{ height: 20, width: 20, cursor: (isSelectedCard) ? 'not-allowed' : 'pointer' }}

                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (isSelectedCard) {

                                        }
                                        else {
                                            setShareModalVisible(true);
                                            setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${courseTypeName}`);
                                        }
                                    }}


                                />
                            </span>

                            <span style={{ cursor: isSelectedCard ? 'not-allowed' : 'pointer' }}>
                                <Popover content={(isSelectedCard) ? null : CourseCardContent(item?.id, fromSearchCourse, fromSearchVideo, false, false)} trigger="hover" placement='right' showArrow={false}>
                                    <img
                                        src={require("../../Assets/courseManagementV4/new-more-icon.svg").default}
                                        style={{ width: 20, height: 20, color: "grey", marginLeft: 5 }}
                                        onClick={(e) => { e.stopPropagation(); }}
                                    />
                                </Popover>

                            </span>
                        </div>
                    </div>


                    {/* {courseTypeName == 'videoResources' ? (<></>) : (<>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 5 }}>
                            <div style={{ color: "#636363", fontSize: "12px", fontWeight: "bold" }}>Completed</div>
                            <div style={{ color: "#636363", fontSize: "12px", fontWeight: "bold" }}>{Math.round(item?.progress === undefined ? null : item?.progress)} %</div>
                        </div>
                        <div>
                            <Progress
                                className='student-test-progressbar'
                                style={{ width: "100%", left: '0px', right: '50px' }}
                                trailColor=''
                                strokeColor={item?.progress == 100 ? "#28DF99" : '#594099'}
                                percent={item?.progress}
                                size={'default'}
                                status="active"
                                showInfo={false} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ color: "#AEAEAE", fontSize: "12px", fontWeight: "bold" }}>{item?.startDate === null ? null : (moment(item?.startDate).format('DD-MM-YYYY'))}</div>
                            <div style={{ color: "#AEAEAE", fontSize: "12px", fontWeight: "bold" }}>{item?.endDate === null ? null : moment(item?.endDate).format('DD-MM-YYYY')} </div>
                        </div></>)} */}
                    {/* <div className={`m-t-5 m-b-5 ${(fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : 'hovered-text-visible'}`} style={{ borderBottom: "1px solid #E6E6E6", width: "100%" }}></div> */}
                    {/* <div className={`r-jsb ${(fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : 'hovered-text-visible'}`}>
                        {item?.amount ?
                            <div style={{ display: "flex" }}>
                                <div style={{ fontSize: 20, fontWeight: "bold", color: "#191919", }}>₹ {item?.discountType == "percent" ? Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100 : item?.discountType == "amount" ? (item?.amount - item?.discount) : item?.amount}</div>
                                {item?.discount == 0 ? <></> : <div className='r-c-c' style={{ fontSize: 12, fontWeight: "bold", color: "#636363", marginLeft: 10, textDecoration: 'line-through' }}>₹ {item?.amount}</div>}
                            </div>
                            :
                            <div></div>
                        }
                        <div style={{ color: "#FFFFFF", backgroundColor: "#594099", padding: "3px 5px", fontWeight: "bold", borderRadius: 4, fontSize: 12, height: "fit-content", marginTop: 5 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShareModalVisible(true);
                                setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${courseTypeName}`);
                            }}>
                            <ShareAltOutlined style={{ marginRight: 5 }} />Share
                        </div>
                    </div> */}
                </div>
            </div>
        )
    };

    const RatingStars = ({ star }) => {
        const fullStars = Math.floor(star);
        const halfStar = star % 1 === 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
        const stars = [];
        for (let i = 0; i < fullStars; i++) {
            stars.push(<StarFilled key={i} className="fas fa-star" style={{ color: '#FFA931', marginLeft: 2, fontSize: 12 }} />);
        }
        if (halfStar) {
            stars.push(<StarFilled key="half" className="fas fa-star-half-alt" style={{ color: '#FFA931', marginLeft: 2, fontSize: 12 }} />);
        }
        for (let i = 0; i < emptyStars; i++) {
            stars.push(<StarFilled key={`empty-${i}`} className="fas fa-star" style={{ color: '#AEAEAE', marginLeft: 2, fontSize: 12 }} />);
        }
        return (
            <div style={{ display: "flex", }}>
                <div style={{ color: "#FFA931", fontWeight: "bold", marginRight: 5 }}>{star}</div>
                <div >{stars.map((starElement) => starElement)}</div>
            </div>
        );
    };
    const StudyMaterialCard = ({ item, index, reference, fromSearchStudy, fromSearchTest }) => {
        return (
            <div style={{ margin: "10px 20px 20px 0px" }}>
                <div className='study-material-card'
                    onClick={(e) => {
                        e.stopPropagation();
                        storeSingleCourseId(item?.id);
                        if (isSearchedSuggestionClicked) {
                            if (fromSearchStudy) {
                                setCourseTypeName(fromSearchStudy ? "studyMaterials" : null);
                            } else {
                                setCourseTypeName(fromSearchTest ? "onlineTestSeries" : null);
                            }
                        }
                        history.push({
                            pathname: "/course-details",
                            state: {
                                key: {
                                    fromCourseCard: {
                                        isCoursesCardsVisible: isCoursesCardsVisible,
                                        selectedCategory: selectedCategory,
                                        selectedSubCategory: selectedSubCategory,
                                    },
                                    isSearchedSuggestionClicked: isSearchedSuggestionClicked,
                                    searchedValue: selectedSuggestion
                                }
                            }
                        })
                    }}>
                    <div style={{ display: "flex", justifyContent: hasMarketingPrivilege ? "flex-end" : "space-between" }}>
                        {!hasMarketingPrivilege && (
                            <div onClick={(e) => { e.stopPropagation() }}>
                                <Checkbox style={{ paddingLeft: 5, paddingTop: 10 }}
                                    checked={(fromSearchStudy && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                        : (fromSearchStudy && reference == "published") ? selectedPublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                            : (fromSearchTest && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.onlineTestSeries?.items?.[index]?.id)
                                                : (fromSearchTest && reference == "published") ? selectedPublishCards?.includes(searchResultData?.onlineTestSeries?.items?.[index]?.id)
                                                    : reference == "unpublished"
                                                        ? (courseTypeName == "onlineTestSeries")
                                                            ? selectedUnpublishCards?.includes(unpublishedTests?.[index]?.id)
                                                            : selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                        : (courseTypeName == "onlineTestSeries")
                                                            ? selectedPublishCards?.includes(publishedTests?.[index]?.id)
                                                            : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
                                    }
                                    onChange={() => {
                                        if (fromSearchStudy) {
                                            setSearchSelectedCourseType("searchStudyMaterial")
                                        }
                                        if (fromSearchTest) {
                                            setSearchSelectedCourseType("searchTests")
                                        }
                                        reference == "unpublished"
                                            ? toggleUnpublishedCheckbox(item?.id, false)
                                            : togglePublishedCheckbox(item?.id, false)
                                    }} />
                            </div>
                        )}
                        <div style={{ display: "flex", paddingRight: '15px', paddingTop: '15px' }}>
                            <div style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                                {item?.amount ?
                                    <div style={{ fontSize: 10, color: "#594099", backgroundColor: "#F6F4FE", padding: "5px 10px", fontWeight: "500", borderRadius: 4, border: " 1px solid #594099" }}>₹ {parseFloat(item?.amount || 0) - parseFloat(item?.totalDiscount || 0)}</div>
                                    :
                                    <div style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: "#FF414D", padding: "5px 20px", fontWeight: "500", borderRadius: 4, border: " 1px solid #FF414D" }}>FREE</div>
                                }
                                {!hasMarketingPrivilege && (
                                    <div className={`${(selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0) ? 'three-dots-div' : 'hovered-text-visible'}`}>
                                        <Popover content={(selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0) ? null : CourseCardContent(item?.id, false, false, fromSearchStudy, fromSearchTest)} trigger="hover" placement='right' showArrow={false}>
                                            <img src={require("../../Assets/courseManagementV4/new-more-icon.svg").default} style={{ width: "24px", height: "22px", color: "grey", marginRight: -10, marginTop: -5 }} onClick={(e) => { e.stopPropagation() }} />
                                        </Popover>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: "-20px 0px 0px 10px", marginLeft: "25px" }}>
                        <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50 }} />

                        {(courseTypeName == "onlineTestSeries" || fromSearchTest) ?
                            <div style={{ color: "#636363", fontSize: 16, marginLeft: 20 }}>{item?.tCount} {item?.tCount > 1 ? "tests" : "test"}</div>
                            :
                            <div style={{ color: "#636363", fontSize: 16, marginLeft: 20 }}>{item?.m_count} {item?.m_count > 1 ? "materials" : "materials"}</div>
                        }
                    </div>
                </div>
                {(fromSearchStudy) ?
                    <div className='r-jsb m-t-5'>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 200 }}>{item?.name}</Typography.Text>
                        <div>{item?.published ?
                            <img src={require("../../Assets/course/check.svg").default} style={{ height: 16, width: 16 }} />
                            :
                            <img src={require("../../Assets/course/block.svg").default} style={{ height: 16, width: 16 }} />
                        }</div>
                    </div>
                    :
                    <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", marginTop: 5, width: 200 }}>{item?.name}</Typography.Text>
                }
                <div style={{ display: "flex" }}>
                    <RatingStars star={item?.ratingValue || 0} />
                    <div style={{ color: "#191919", fontSize: 12, margin: "3px 0px 0px 10px", fontWeight: 600 }}>({item?.ratingCount || 0} ratings)</div>
                </div>
            </div>
        )
    }
    const AddCategoryCard = () => {
        return (
            <div className='r-c-c' style={{ border: "1px dashed #1089FF", backgroundColor: "#FFFFFF", borderRadius: 8, padding: 20, height: 210, width: 210, cursor: "pointer", margin: "10px 20px 20px 0px", flexDirection: "column" }} onClick={() => { setIsCreateOrEditCategoryVisible(true); setIsEdit(false); setIsSubCategory(false) }}>
                <PlusCircleOutlined style={{ color: "#1089FF", fontSize: 28 }} />
                <center style={{ color: "#191919", fontSize: 14, fontWeight: "bold", marginTop: 10 }}>Add category</center>
                <center style={{ color: "#636363", fontSize: 14, marginTop: 5 }}>Create a new category</center>
            </div>
        )
    }
    const AddSubCategoryCard = () => {
        return (
            <div className='r-c-c' style={{ flexDirection: "column", border: "1px dashed #1089FF", backgroundColor: "#FFFFFF", borderRadius: 8, padding: 20, height: 125, width: 200, cursor: "pointer", margin: "10px 20px 20px 0px" }} onClick={() => { setIsCreateOrEditCategoryVisible(true); setIsSubCategory(true); setIsEdit(false) }}>
                <PlusCircleOutlined style={{ color: "#1089FF", fontSize: 15 }} />
                <center style={{ color: "#191919", fontSize: 14, fontWeight: "bold", marginTop: 5 }}>Add subcategory</center>
                <center style={{ color: "#636363", fontSize: 12, marginTop: 5 }}>Create a new subcategory</center>
            </div>
        )
    }
    const filterSubCategoryMenu = (
        <Menu style={{ maxHeight: courseCategoryData?.length > 3 ? 150 : 'fit-content', overflowY: "scroll", padding: 10, backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014" }}>
            <Radio.Group onChange={(e) => {
                e.stopPropagation();
                setFilterCategoryClicked(true)
                const selectedSubcategory = e.target.value;
                if (isSearchedSuggestionClicked) {
                    setSearchResultPageParamsObj("subcategoryId", selectedSubcategory?.id);
                    togglePageLoader(true);
                    getSearchResultsData(() => { togglePageLoader(false) })
                } else {
                    setCoursesCardsVisible(true);
                    setSeletedSubCategory({ id: selectedSubcategory?.id, name: selectedSubcategory?.name });
                    setAllCoursesPageParamsObj("subcategoryId", selectedSubcategory?.id);
                    togglePageLoader(true);
                    if (courseTypeName == "liveCourses") {
                        getTotalCoursesList(() => togglePageLoader(false))
                    } else if (courseTypeName == "studyMaterials") {
                        getStudyMaterialsList(() => togglePageLoader(false))
                    } else if (courseTypeName == "videoResources") {
                        getTotalsVideoResource(() => togglePageLoader(false))
                    }
                    else if (courseTypeName == "onlineTestSeries") {
                        getOnlineTestSeries(() => togglePageLoader(false))
                    }
                    setFilterCategoryClicked(false);
                    setFilterSubCategoryClicked(false);
                    setSelectedSubCategoryCards([]); setSelectedPublishCards([]); setSelectedUnpublishCards([]); setSubCategoryVisible(false)
                }
            }}>
                {courseSubCategoriesData?.map((subcategory) => (
                    <Radio key={subcategory?.name} value={subcategory} style={{ display: "flex", justifyContent: "space-between", padding: 5 }}>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#636363", width: 160 }}>{subcategory?.name}</Typography.Text>
                    </Radio>
                ))}
            </Radio.Group>
        </Menu>
    )
    const handleCategoryDropdownVisibleChange = (visible) => {
        setFilterCategoryClicked(visible);
        setFilterSubCategoryClicked(false);
    };

    const handleSubCategoryDropdownVisibleChange = (visible) => {
        setFilterSubCategoryClicked(visible);
    };
    const filterCategoryMenu = (
        <Dropdown
            visible={isFilterCategoryClicked}
            onVisibleChange={handleSubCategoryDropdownVisibleChange}
            overlay={isFilterSubCategoryClicked ? filterSubCategoryMenu : <></>} placement='right' open={isFilterSubCategoryClicked}>
            <Menu className='scroll-bar-universal' style={{ maxHeight: courseCategoryData?.length > 6 ? 300 : 'fit-content', padding: 10, backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014" }}>
                <Radio.Group onChange={(e) => {
                    const selectedCat = e?.target?.value;
                    getCoursesSubCategories(selectedCat?.id, null); setFilterSubCategoryClicked(true); setSelectedCategory({ id: selectedCat?.id, name: selectedCat?.name });
                }}>
                    {courseCategoryData && courseCategoryData?.map((category) => (
                        <Radio key={category?.id} value={category} style={{ display: "flex", justifyContent: "space-between", padding: 5 }}>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#636363", width: 160 }}>{category?.name}</Typography.Text>
                        </Radio>
                    ))}
                </Radio.Group>
            </Menu>
        </Dropdown>
    );
    const handleAutocompleteSearch = (value) => {
        setAutocompleteSearchedValue(value);
        setSearchLoader(true);
        getAutocompleteSearch(value, () => { setSearchLoader(false) })
    }
    const renderTitle = (title) => (
        <div style={{ color: "#636363", fontSize: 16, marginLeft: 10 }}>{title}</div>
    );

    const searchOptions = [
        {
            label: autocompleteSearchData?.suggestions?.length != 0 ? renderTitle("Suggestions") : null,
            options: autocompleteSearchData?.suggestions?.length ? autocompleteSearchData?.suggestions?.map((item) => ({
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "3px 0px" }} onClick={() => {
                     
                        setSelectedSuggestion(item);
                        setSearchedSuggestionClicked(true);
                        setLoadMorePage({
                            courseLoadMore: 1,
                            videoLoadMore: 1,
                            studyLoadMore: 1,
                            testsLoadMore: 1
                        });
                        setSearchResultPageParamsObj("search", item);
                        resetValues();

                        history.push({
                            pathname: "/search-results",
                            state: {
                                key: {
                                    fromCourse: isCoursesCardsVisible ? 'fromCourseCard' : isSubCategoryVisible ? 'fromSubCategory' : 'fromCategory',
                                    selectedCategory: selectedCategory,
                                    selectedSubCategory:selectedSubCategory,
                                    courseTypeName:courseTypeName,
                                    searchData:selectedSuggestion,
                                    isCoursesCardsVisible:isCoursesCardsVisible

                                }
                            }
                        });
                       
                        // togglePageLoader(true);
                        // getSearchResultsData(() => togglePageLoader(false));
                    }}>
                        <SearchOutlined /><Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, marginLeft: 10, width: 230 }}>{item}</Typography.Text>
                    </div>
                ),
            }))
                :
                []
        },
        {
            label: autocompleteSearchData?.resources?.length != 0 ? renderTitle("Courses") : null,
            options: autocompleteSearchData?.resources?.length ? autocompleteSearchData?.resources?.map((item) => ({
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "5px 0px" }} onClick={() => { setCourseTypeName(item?.type == "course" ? "liveCourses" : item?.type == "videoResource" ? "videoResources" : item?.type == "studyMaterial" ? "studyMaterials" : item?.type == "testSeries" ? "onlineTestSeries" : null); storeSingleCourseId(item?.id); history.push("/course-details") }}>
                        {<img src={`${Api.dlS3Url}${item?.image}`} style={{ width: 70, height: 40 }} />}
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, marginLeft: 10, width: 230 }}>{item?.name}</Typography.Text>
                    </div>
                ),
            }))
                :
                []
        },
    ];
    const resetValues = () => {
        setSearchResultPageParamsObj("startDate", null);
        setSearchResultPageParamsObj("endDate", null);
        setSearchResultPageParamsObj("sort", null);
        setSearchResultPageParamsObj("mode", null);
        setSearchResultPageParamsObj("type", null);
        setSearchResultPageParamsObj("status", null);
        setSearchResultPageParamsObj("durationFilter", null);
        setSearchResultPageParamsObj("page", 1);
        setSearchResultPageParamsObj("feeType", null);
        setSelectedSubCategoryCards([])
    }
    useEffect(() => {
        setSearchResultPageParamsObj("subcategoryId", null);
        resetValues();
    }, [])

    useEffect(() => {
        if(location?.state?.key?.fromSearchToCoursePage){
            setCourseTypeName(location.state.key.courseTypeName)
            if(location?.state?.key?.selectedCategory && location?.state?.key?.selectedSubCategory){
                setSelectedCategory(location.state.key.selectedCategory )
                setSeletedSubCategory(location.state.key.selectedSubCategory)
                setCoursesCardsVisible(true)
                setSubCategoryVisible(false)
            }
        }
 
    }, [location])


    const sortArraySearch = [{ label: "Popularity", value: "popularity" }, { label: "Relevance", value: "relevance" }, { label: "Upload date", value: "uploadDate" }, { label: "Rating", value: "rating" }];
    const sortArrayCourse = [{ label: "Popularity", value: "popularity" }, { label: "Upload date", value: "uploadDate" }, { label: "Rating", value: "rating" }];
    const sortArrayCatSubcat = [{ label: "Popularity", value: "popularity" }, { label: "Rating", value: "rating" }];
    const modeArray = [{ label: "All", value: null }, { label: "Online", value: "online" }, { label: "Hybrid", value: "hybrid" }, { label: "Offline", value: "offline" }];
    const typeArray = [{ label: "All", value: null }, { label: "Live Courses", value: "course" }, { label: "Video Course", value: "videoResource" }, { label: "Study Materials", value: "studyMaterial" }, { label: "Online Test Series", value: "onlineTestSeries" }];
    const statusArray = [{ label: "All", value: null }, { label: "Unpublished", value: "unpublished" }, { label: "Published", value: "published" }];
    const durationArray = [{ label: "1 week", value: "oneWeek" }, { label: "7 to 30 days", value: "oneMonth" }, { label: "1 to 6 months", value: "sixMonths" }, { label: "6 to 12 month", value: "oneYear" }, { label: "1 to 2 year", value: "twoYears" }, { label: "More than 2 year", value: "greaterTwoYear" }];
    const studyTypeArray = [{ label: "All", value: null }, { label: "Free", value: "free" }, { label: "Paid", value: "paid" }]

    const toggleSort = (sort) => {
        setSearchResultPageParamsObj("sort", sort);
    };
    const toggleMode = (mode) => {
        setSearchResultPageParamsObj("mode", mode);
    };
    const toggleType = (type) => {
        setSearchResultPageParamsObj("type", type);
    };
    const toggleFeeType = (type) => {
        setSearchResultPageParamsObj("feeType", type);
    };
    const toggleStatus = (status) => {
        setSearchResultPageParamsObj("status", status);
    };
    const toggleDuration = (duration) => {
        setSearchResultPageParamsObj("durationFilter", duration);
    };

    const FilterTitle = ({ titleName }) => {
        return (
            <div className='m-b-5' style={{ color: "#636363", fontWeight: 500 }}>{titleName}</div>
        )
    }
    const disabledEndDate = (current) => {
        const startDate = moment(searchPageParamsObj?.startDate, 'DD/MM/YYYY');
        return current && current < startDate;
    };

    const DateFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Filter by date"} />
                <div className='r-jsb m-b-10' style={{ width: "100%" }}>
                    <DatePicker placeholder='Start date' format={"DD/MM/YYYY"} style={{ width: "48%" }} onChange={(date, dateString) => { setSearchResultPageParamsObj("startDate", dateString) }} value={searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY") : null} />
                    <DatePicker placeholder='End date' disabledDate={disabledEndDate} format={"DD/MM/YYYY"} style={{ width: "48%" }} onChange={(date, dateString) => { setSearchResultPageParamsObj("endDate", dateString) }} value={searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY") : null} />
                </div>
            </div>
        )
    }
    const SortFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Sort by"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {isSearchFilterClicked ?
                        <>
                            {sortArraySearch?.map((item, index) => (
                                <div className='m-r-20 m-b-10' key={index}>
                                    <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.sort === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.sort === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.sort === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleSort(item?.value)}>{item?.label}</div>
                                </div>
                            ))}
                        </>
                        :
                        isCoursesCardsVisible && !isSearchedSuggestionClicked ?
                            <>
                                {sortArrayCourse?.map((item, index) => (
                                    <div className='m-r-20 m-b-10' key={index}>
                                        <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.sort === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.sort === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.sort === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleSort(item?.value)}>{item?.label}</div>
                                    </div>
                                ))}
                            </>
                            :
                            <>
                                {sortArrayCatSubcat?.map((item, index) => (
                                    <div className='m-r-20 m-b-10' key={index}>
                                        <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.sort === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.sort === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.sort === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleSort(item?.value)}>{item?.label}</div>
                                    </div>
                                ))}
                            </>
                    }
                </div>
            </div>
        );
    }
    const ModeFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Mode of class"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {modeArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.mode === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.mode === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.mode === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleMode(item?.value)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
    const TypeFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Course type"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {typeArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.type === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.type === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.type === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleType(item?.value)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
    const StatusFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Course status"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {statusArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.status === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.status === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.status === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleStatus(item?.value)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
    const DurationFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Course duration"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {durationArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.durationFilter === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.durationFilter === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.durationFilter === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleDuration(item?.value)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
    const StudyTypeFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Type"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {studyTypeArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.feeType === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.feeType === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.feeType === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleFeeType(item?.value)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
    const searchFilterMenu = (
        <div style={{ height: "fit-content", padding: 20, backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014", width: 450 }}>
            {/* <DateFilter /> */}
            <SortFilter />
            <ModeFilter />
            <TypeFilter />
            <StatusFilter />
            <DurationFilter />
            <div className='r-c-c-c' style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
            <div className='r-jsb m-t-10'>
                <div style={{ color: "#1089FF", fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => {
                        resetValues();
                        setSearchFilterClicked(false);
                        togglePageLoader(true);
                        getSearchResultsData(() => { togglePageLoader(false) })
                    }}>Reset</div>
                <div className='r-c-c-c' style={{ color: "#FFFFFF", backgroundColor: "#1089FF", borderRadius: 4, padding: "5px 10px", fontWeight: 600, fontSize: 13, cursor: "pointer" }}
                    onClick={() => {
                        setSearchFilterClicked(false)
                        togglePageLoader(true);
                        getSearchResultsData(() => { togglePageLoader(false) })
                    }}
                >Apply</div>
            </div>
        </div>
    )

    const multipleFilterMenu = (
        <div style={{ height: "fit-content", padding: 20, backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014", width: 450, borderRadius: 17 }}>
            {/* {(courseTypeName == "liveCourses" || previousCourseType == "liveCourses") ? <DateFilter /> : <></>} */}
            <SortFilter />
            {(courseTypeName != "liveCourses" || previousCourseType != "liveCourses") ? <StudyTypeFilter /> : <></>}
            {(courseTypeName == "liveCourses" || previousCourseType == "liveCourses") ? <ModeFilter /> : <></>}
            {(courseTypeName == "liveCourses" || previousCourseType == "liveCourses") ? <DurationFilter /> : <></>}
            <div className='r-c-c-c' style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
            <div className='r-jsb m-t-10'>
                <div style={{ color: "#594099", fontWeight: "bold", cursor: "pointer", marginTop: 10 }} onClick={() => {
                    resetValues();
                    setMultipleFilterClicked(false);
                    togglePageLoader(true);
                    if (isSubCategoryVisible) {
                        getCoursesSubCategories(selectedCategory?.id, () => { togglePageLoader(false) })
                    } else if (isCoursesCardsVisible) {
                        if (courseTypeName == "liveCourses") {
                            getTotalCoursesList(() => togglePageLoader(false))
                        } else if (courseTypeName == "studyMaterials") {
                            getStudyMaterialsList(() => togglePageLoader(false))
                        } else if (courseTypeName == "videoResources") {
                            getTotalsVideoResource(() => togglePageLoader(false))
                        }
                        else if (courseTypeName == "onlineTestSeries") {
                            getOnlineTestSeries(() => togglePageLoader(false))
                        }
                    } else {
                        getCoursesCategories(() => togglePageLoader(false))
                    }
                }}>RESET</div>
                <div className='r-c-c-c' style={{ color: "#FFFFFF", backgroundColor: "#594099", borderRadius: 4, padding: "5px 10px", fontWeight: 600, fontSize: 13, cursor: "pointer", marginTop: 10 }}
                    onClick={() => {
                        setMultipleFilterClicked(false)
                        togglePageLoader(true);
                        if (isSubCategoryVisible) {
                            getCoursesSubCategories(selectedCategory?.id, () => { togglePageLoader(false) })
                        } else if (isCoursesCardsVisible) {
                            if (courseTypeName == "liveCourses") {
                                getTotalCoursesList(() => togglePageLoader(false))
                            } else if (courseTypeName == "studyMaterials") {
                                getStudyMaterialsList(() => togglePageLoader(false))
                            } else if (courseTypeName == "videoResources") {
                                getTotalsVideoResource(() => togglePageLoader(false))
                            }
                            else if (courseTypeName == "onlineTestSeries") {
                                getOnlineTestSeries(() => togglePageLoader(false))
                            }
                        } else {
                            getCoursesCategories(() => togglePageLoader(false))
                        }
                    }}
                >APPLY</div>
            </div>
        </div>
    )


    const actions = [
        ((courseTypeName == "liveCourses" && courseStatsData?.courseCount == 0) || (courseTypeName == "videoResources" && courseStatsData?.videoResourceCount == 0) || (courseTypeName == "onlineTestSeries" && courseStatsData?.testCount == 0) || (courseTypeName == "studyMaterials" && courseStatsData?.studyMaterialCount == 0)) ?
            [] :
            <></>,
        ((courseTypeName == "liveCourses" && courseStatsData?.courseCount == 0) || (courseTypeName == "videoResources" && courseStatsData?.videoResourceCount == 0) || (courseTypeName == "onlineTestSeries" && courseStatsData?.testCount == 0) || (courseTypeName == "studyMaterials" && courseStatsData?.studyMaterialCount == 0)) ?
            []
            :
            <></>,
        (isSearchedSuggestionClicked ?
            <Dropdown overlay={isSearchFilterClicked ? searchFilterMenu : <></>} trigger={['click']} open={isSearchFilterClicked}>
                <div style={{ border: "1px solid #E6E6E6", backgroundColor: "#FFFFFF", width: 150, padding: "5px 10px", marginRight: 20, color: "#AEAEAE", display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}  onClick={() => { setSearchFilterClicked(!isSearchFilterClicked) }}>
                    <div style={{ display: "flex", cursor: "pointer" }}>
                        <img src={require("../../Assets/aulasMarket/filterIC.svg").default} style={{ height: 18, width: 18, marginRight: 10 }} />
                        <div>Filter</div>
                    </div>
                    {!isSearchFilterClicked ? <CaretDownOutlined onClick={() => { setSearchFilterClicked(!isSearchFilterClicked) }} /> : <CaretUpOutlined onClick={() => { setSearchFilterClicked(!isSearchFilterClicked) }} />}
                </div>
            </Dropdown>
            :
            (((courseTypeName == "liveCourses" && courseStatsData?.courseCount == 0) || (courseTypeName == "videoResources" && courseStatsData?.videoResourceCount == 0) || (courseTypeName == "onlineTestSeries" && courseStatsData?.testCount == 0) || (courseTypeName == "studyMaterials" && courseStatsData?.studyMaterialCount == 0)) ?
                [] :


                (
                    <>
                        {/* Toggle Component: This will always be displayed */}
                        {isCoursesCardsVisible ? <div className={`toggle-container ${isPublished ? 'published' : 'unpublished'}`} onClick={togglePublished}>
                            <div className="toggle-option" style={{ fontWeight: 500 }}>PUBLISHED</div>
                            <div className="toggle-option" style={{ fontWeight: 500 }}>UNPUBLISHED</div>
                        </div> : <></>}

                        {/* Button: This will only be displayed if hasMarketingPrivilege is false */}
                        {!hasMarketingPrivilege && (
                            <Button type="primary"
                                onClick={() => {
                                    history?.push(courseTypeName == "liveCourses" ?
                                        {
                                            pathname: "/add-live-courses",
                                            state: {
                                                key: {
                                                    fromSubcategory: {
                                                        isSubCategoryVisible: isSubCategoryVisible,
                                                        selectedCategory: selectedCategory
                                                    },
                                                    fromCourseCard: {
                                                        isCoursesCardsVisible: isCoursesCardsVisible,
                                                        selectedCategory: selectedCategory,
                                                        selectedSubCategory: selectedSubCategory
                                                    }
                                                }
                                            }
                                        } : courseTypeName == "videoResources" ?
                                            {
                                                pathname: "/add-video-resources",
                                                state: {
                                                    key: {
                                                        fromSubcategory: {
                                                            isSubCategoryVisible: isSubCategoryVisible,
                                                            selectedCategory: selectedCategory
                                                        },
                                                        fromCourseCard: {
                                                            isCoursesCardsVisible: isCoursesCardsVisible,
                                                            selectedCategory: selectedCategory,
                                                            selectedSubCategory: selectedSubCategory
                                                        }
                                                    }
                                                }
                                            } : courseTypeName == "onlineTestSeries" ?
                                                {
                                                    pathname: "/add-online-test-series",
                                                    state: {
                                                        key: {
                                                            fromSubcategory: {
                                                                isSubCategoryVisible: isSubCategoryVisible,
                                                                selectedCategory: selectedCategory
                                                            },
                                                            fromCourseCard: {
                                                                isCoursesCardsVisible: isCoursesCardsVisible,
                                                                selectedCategory: selectedCategory,
                                                                selectedSubCategory: selectedSubCategory
                                                            }
                                                        }
                                                    }
                                                } : courseTypeName == "studyMaterials" ?
                                                    {
                                                        pathname: "/add-study-materials",
                                                        state: {
                                                            key: {
                                                                fromSubcategory: {
                                                                    isSubCategoryVisible: isSubCategoryVisible,
                                                                    selectedCategory: selectedCategory
                                                                },
                                                                fromCourseCard: {
                                                                    isCoursesCardsVisible: isCoursesCardsVisible,
                                                                    selectedCategory: selectedCategory,
                                                                    selectedSubCategory: selectedSubCategory
                                                                }
                                                            }
                                                        }
                                                    } : "/add-live-courses")
                                }}
                                icon={<PlusCircleOutlined />}>
                                ADD {courseTypeName == "liveCourses" ? "LIVE COURSES" :
                                    courseTypeName == "videoResources" ? "VIDEO COURSE" :
                                        courseTypeName == "onlineTestSeries" ? "ONLINE TEST SERIES" :
                                            courseTypeName == "studyMaterials" ? "STUDY MATERIALS" : "LIVE COURSES"}
                            </Button>
                        )}
                    </>
                )


            ))
    ]
    const categoryActions = hasMarketingPrivilege ? [] : [
        <Button type="cancel" style={{ marginRight: 10 }} onClick={() => { setSelectedCategoryCards([]); setSelectedSubCategoryCards([]); setSelectedPublishCards([]); setSelectedUnpublishCards([]) }}>CANCEL</Button>,
        <Button type="course-delete" style={{ marginRight: 10 }} onClick={() => { setIsDeleteModalVisible(true) }}>DELETE</Button>
    ]
    const subCategoryActions = [
        <Button type="cancel" style={{ marginRight: 10 }} onClick={() => { setSelectedCategoryCards([]); setSelectedSubCategoryCards([]); setSelectedPublishCards([]); setSelectedUnpublishCards([]) }}>CANCEL</Button>,
        <Button type="course-delete" style={{ marginRight: 10 }} onClick={() => { setIsDeleteModalVisible(true) }}>DELETE</Button>,
        <Button type="primary" onClick={() => { setIsMoveModalVisible(true) }}>MOVE</Button>,
    ]
    const courseActions = [
        <Button type="cancel" style={{ marginRight: 10 }} onClick={() => { setSelectedCategoryCards([]); setSelectedSubCategoryCards([]); setSelectedPublishCards([]); setSelectedUnpublishCards([]) }}>CANCEL</Button>,
        <Button type="course-delete" style={{ marginRight: 10 }} onClick={() => { setIsDeleteModalVisible(true) }}>DELETE</Button>,
        <Button type="primary" disabled={selectedPublishCards?.length > 0 && selectedUnpublishCards?.length > 0} onClick={() => { setIsConfirmPublishModalVisible(true) }}>{selectedPublishCards?.length != 0 ? "UNPUBLISH" : "PUBLISH"}</Button>
    ]
    const CourseTypeDropdownItems = [
        { label: "Live Courses", typeName: "liveCourses" },
        { label: "Video Resources", typeName: "videoResources" },
        { label: "Online Test Series", typeName: "onlineTestSeries" },
        { label: "Study Materials", typeName: "studyMaterials" }
    ]


    return (
        <div onClick={(e) => { e.stopPropagation(); /* setMultipleFilterClicked(false) */ }}>
            <div>
                <div style={{ marginBottom: "60px" }}>
                    <PageHeader title={isSearchedSuggestionClicked ? "Search result" :
                        isCoursesCardsVisible ? <Typography.Text ellipsis={{ tooltip: true }} style={{ width: 300 }}>{selectedSubCategory?.name}</Typography.Text> :
                            isSubCategoryVisible ? <Typography.Text ellipsis={{ tooltip: true }} style={{ width: 300 }}>{selectedCategory?.name}</Typography.Text> :
                                courseTypeName == "liveCourses"
                                    ? "Live Courses"
                                    : courseTypeName == "videoResources"
                                        ? "Video course"
                                        : courseTypeName == "onlineTestSeries"
                                            ? "Online Test Series"
                                            : courseTypeName == "studyMaterials"
                                                ? "Study Materials"
                                                : "Live Courses"}
                        onBackPress={isSearchedSuggestionClicked ? () => {
                            resetValues();
                            if (location?.state?.key?.fromDashboardSearch) {
                                history.push("/courses")
                            }
                            else if (isSearchedSuggestionClicked) {
                                setCourseTypeName(previousCourseType);
                                resetValues();
                                history.push(previousCourseType == "liveCourses" ? "/live-courses-data" : previousCourseType == "videoResources" ? "/video-resources-data" : previousCourseType == "onlineTestSeries" ? "/online-test-series-details" : previousCourseType == "studyMaterials" ? "/study-materials-data" : "/live-courses-data")
                                setAutocompleteSearchedValue(null);
                                setSelectedSuggestion(null);
                                setSearchedSuggestionClicked(false);
                                if (selectedCategory?.id && selectedSubCategory?.id) {
                                    setCoursesCardsVisible(true);
                                    togglePageLoader(true)
                                    if (previousCourseType == "liveCourses") {
                                        getTotalCoursesList(() => togglePageLoader(false))
                                    } else if (previousCourseType == "studyMaterials") {
                                        getStudyMaterialsList(() => togglePageLoader(false))
                                    } else if (previousCourseType == "videoResources") {
                                        getTotalsVideoResource(() => togglePageLoader(false))
                                    } else if (previousCourseType == "onlineTestSeries") {
                                        getOnlineTestSeries(() => togglePageLoader(false))
                                    }
                                } else if (selectedCategory?.id) {
                                    setSubCategoryVisible(true)
                                }
                            } else if (isCoursesCardsVisible) {
                                setCoursesCardsVisible(false);
                                setSubCategoryVisible(true)
                            } else if (isSubCategoryVisible) {
                                setSubCategoryVisible(false);
                                setCoursesCardsVisible(false)
                            } else {
                                history.push("/courses")
                            }
                        } : ((courseTypeName == "liveCourses" && courseStatsData?.courseCount == 0) || (courseTypeName == "videoResources" && courseStatsData?.videoResourceCount == 0) || (courseTypeName == "onlineTestSeries" && courseStatsData?.testCount == 0) || (courseTypeName == "studyMaterials" && courseStatsData?.studyMaterialCount == 0)) ? () => { history.push("/courses") } : null}
                        actions={selectedCategoryCards?.length > 0 ? categoryActions : selectedSubCategoryCards?.length > 0 ? subCategoryActions : (selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0) ? courseActions : courseCategoryData?.length != 0 ? actions : []} maxWidth={400} />
                </div>
                <div style={bodyStyle}>
                    {isSearchedSuggestionClicked ?
                        <div>
                            <div style={{ color: "#191919", fontSize: 20, fontWeight: 650 }}>{searchResultData?.totalCount ? searchResultData?.totalCount : "0"} results for “{selectedSuggestion}”</div>
                            {searchResultData?.courses?.items?.length > 0 ?
                                <div className={`${(searchSelectedCourseType == "searchStudyMaterial" || searchSelectedCourseType == "searchVideo" || searchSelectedCourseType == "searchTests") && (selectedUnpublishCards?.length != 0 || selectedPublishCards?.length != 0) ? "disabled-div" : ""}`}>
                                    <div style={{ color: "#191919", fontSize: 18, marginTop: "20px", fontWeight: 650 }}>Live Courses</div>
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: '2.1%' }}>
                                        {searchResultData?.courses?.items?.map((item, index) => (
                                            <LiveCourseCard item={item} index={index} reference={item?.published == 1 ? "published" : "unpublished"} fromSearchCourse={true} />
                                        ))}
                                        {((searchResultData?.courses?.meta?.currentPage != searchResultData?.courses?.meta?.totalPages) && searchResultData?.courses?.meta?.totalItems != 0) ?
                                            <div className='r-c-c' style={{ width: "100%" }}>
                                                <Button
                                                    onClick={() => {
                                                        setLoadMorePage({ ...loadMorePage, courseLoadMore: loadMorePage?.courseLoadMore + 1 })
                                                        togglePageLoader(true);
                                                        getCourseLoadMore(loadMorePage?.courseLoadMore + 1, () => { togglePageLoader(false) })
                                                    }}
                                                >LOAD MORE</Button>
                                            </div> : <></>}

                                    </div>
                                </div>
                                :
                                <></>
                            }
                            {searchResultData?.videoResources?.items?.length > 0 ?
                                <div className={`${(searchSelectedCourseType == "searchCourse" || searchSelectedCourseType == "searchStudyMaterial" || searchSelectedCourseType == "searchTests") && (selectedUnpublishCards?.length != 0 || selectedPublishCards?.length != 0) ? "disabled-div" : ""}`}>
                                    <div style={{ color: "#191919", fontSize: 18, marginTop: "20px", fontWeight: 650 }}>Video Course</div>
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: '2.1%' }}>
                                        {searchResultData?.videoResources?.items?.map((item, index) => (
                                            <LiveCourseCard item={item} index={index} reference={item?.published == 1 ? "published" : "unpublished"} fromSearchVideo={true} />
                                        ))}
                                        {((searchResultData?.videoResources?.meta?.currentPage !== searchResultData?.videoResources?.meta?.totalPages) && searchResultData?.videoResources?.meta?.totalItems !== 0) ?
                                            <div className='r-c-c' style={{ width: "100%" }}>
                                                <Button
                                                    onClick={() => {
                                                        setLoadMorePage({ ...loadMorePage, videoLoadMore: loadMorePage?.videoLoadMore + 1 })
                                                        togglePageLoader(true);
                                                        getVideoResourceLoadMore(loadMorePage?.videoLoadMore + 1, () => { togglePageLoader(false) })
                                                    }}
                                                >LOAD MORE</Button></div> : <></>}
                                    </div>
                                </div>
                                :
                                <></>
                            }
                            {searchResultData?.studyMaterials?.items?.length > 0 ?
                                <div className={`${(searchSelectedCourseType == "searchVideo" || searchSelectedCourseType == "searchCourse" || searchSelectedCourseType == "searchTests") && (selectedUnpublishCards?.length != 0 || selectedPublishCards?.length != 0) ? "disabled-div" : ""}`}>
                                    <div style={{ color: "#191919", fontSize: 18, margin: "20px 0px", fontWeight: 650 }}>Study Materials</div>
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {searchResultData?.studyMaterials?.items?.map((item, index) => (
                                            <StudyMaterialCard item={item} index={index} reference={item?.published == 1 ? "published" : "unpublished"} fromSearchStudy={true} />
                                        ))}
                                        {((searchResultData?.studyMaterials?.meta?.currentPage !== searchResultData?.studyMaterials?.meta?.totalPages) && searchResultData?.studyMaterials?.meta?.totalItems !== 0) ?
                                            <div className='r-c-c' style={{ width: "100%" }}>
                                                <Button
                                                    onClick={() => {
                                                        setLoadMorePage({ ...loadMorePage, studyLoadMore: loadMorePage?.studyLoadMore + 1 })
                                                        togglePageLoader(true);
                                                        getStudyMaterialLoadMore(loadMorePage?.studyLoadMore + 1, () => { togglePageLoader(false) })
                                                    }}
                                                >LOAD MORE</Button></div> : <></>}
                                    </div>
                                </div>
                                :
                                <></>
                            }
                            {searchResultData?.onlineTestSeries?.items?.length > 0 ?
                                <div className={`${(searchSelectedCourseType == "searchVideo" || searchSelectedCourseType == "searchCourse" || searchSelectedCourseType == "searchStudyMaterial") && (selectedUnpublishCards?.length != 0 || selectedPublishCards?.length != 0) ? "disabled-div" : ""}`}>
                                    <div style={{ color: "#191919", fontSize: 18, margin: "20px 0px", fontWeight: 650 }}>Test Series</div>
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {searchResultData?.onlineTestSeries?.items?.map((item, index) => (
                                            <StudyMaterialCard item={item} index={index} reference={item?.published == 1 ? "published" : "unpublished"} fromSearchTest={true} />
                                        ))}
                                        {((searchResultData?.onlineTestSeries?.meta?.currentPage !== searchResultData?.onlineTestSeries?.meta?.totalPages) && searchResultData?.onlineTestSeries?.meta?.totalItems !== 0) ?
                                            <div className='r-c-c' style={{ width: "100%" }}>
                                                <Button
                                                    onClick={() => {
                                                        setLoadMorePage({ ...loadMorePage, studyLoadMore: loadMorePage?.testsLoadMore + 1 })
                                                        togglePageLoader(true);
                                                        getTestsLoadMore(loadMorePage?.testsLoadMore + 1, () => { togglePageLoader(false) })
                                                    }}
                                                >LOAD MORE</Button></div> : <></>}
                                    </div>
                                </div>
                                :
                                <></>
                            }
                        </div>
                        :
                        <>
                            {((courseTypeName == "liveCourses" && courseStatsData?.courseCount == 0) || (courseTypeName == "videoResources" && courseStatsData?.videoResourceCount == 0) || (courseTypeName == "onlineTestSeries" && courseStatsData?.testCount == 0) || (courseTypeName == "studyMaterials" && courseStatsData?.studyMaterialCount == 0)) ?
                                <div className='r-c-c' style={{ borderRadius: 8, border: "1px solid #EDEDED", height: "85vh", padding: 20, width: "100%", backgroundColor: "#FFFFFF" }}>
                                    <center>
                                        <img src={NoCourseImage} style={{ width: 180, height: 180 }} />
                                        <div style={{ fontSize: 18, color: "#191919", fontWeight: "bold" }}>No {courseTypeName == "liveCourses" ? "live course" : courseTypeName == "videoResources" ? "video course" : courseTypeName == "onlineTestSeries" ? "online test series" : courseTypeName == "studyMaterials" ? "online study materials" : "live course"} available!</div>
                                        <div style={{ fontSize: 16, color: "#636363", marginBottom: 20 }}>There are no {courseTypeName == "liveCourses" ? "live course" : courseTypeName == "videoResources" ? "video course" : courseTypeName == "onlineTestSeries" ? "online test series" : courseTypeName == "studyMaterials" ? "study materials" : "live courses"} added yet.</div>
                                        {hasMarketingPrivilege ? <></> :
                                            <Button type="primary" onClick={() => { history?.push(courseTypeName == "liveCourses" ? "/add-live-courses" : courseTypeName == "videoResources" ? "/add-video-resources" : courseTypeName == "onlineTestSeries" ? "/add-online-test-series" : courseTypeName == "studyMaterials" ? "/add-study-materials" : "/add-live-courses") }} icon={<PlusCircleOutlined />}>ADD {courseTypeName == "liveCourses" ? "LIVE COURSES" : courseTypeName == "videoResources" ? "VIDEO COURSE" : courseTypeName == "onlineTestSeries" ? "ONLINE TEST SERIES" : courseTypeName == "studyMaterials" ? "STUDY MATERIALS" : "LIVE COURSES"}</Button>
                                        }
                                    </center>
                                </div>
                                :
                                <>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ border: "1px solid #EDEDED", height: 40, width: isCoursesCardsVisible ? selectedUnpublishCards?.length > 0 || selectedPublishCards?.length > 0 ? "83%" : "66%" : '68%', backgroundColor: "#FFFFFF", borderRadius: 8, display: "flex", alignItems: "center" }}>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ color: "#191919", fontSize: 16, fontWeight: 600, margin: "0px 10px", cursor: "pointer" }} onClick={() => { history.push("/courses") }}>Courses <RightOutlined style={{ color: "#AEAEAE", fontSize: 12 }} /></div>
                                                <div style={{ color: "#191919", fontSize: 16, fontWeight: 600, cursor: isSubCategoryVisible || isCoursesCardsVisible ? "pointer" : "auto" }}
                                                    onClick={() => { resetValues(); setSubCategoryVisible(false); setCoursesCardsVisible(false) }}
                                                >{courseTypeName == "liveCourses" ? "Live Courses" : courseTypeName == "videoResources" ? "Video Course" : courseTypeName == "onlineTestSeries" ? "Online Test Series" : courseTypeName == "studyMaterials" ? "Study Materials" : "Live Courses"}
                                                </div>
                                                <div>
                                                    {showCourseTypeDropdown ?
                                                        <Dropdown
                                                            trigger={['click']}
                                                            visible={showCourseTypeDropdown}
                                                            onVisibleChange={(visible) => setCourseTypeDropdown(visible)}
                                                            overlay={
                                                                <Menu style={{ width: "150px", padding: 5, marginTop: 10 }}>
                                                                    {CourseTypeDropdownItems.map((item, index) => (
                                                                        <Menu.Item key={index} style={{ color: "#636363" }} onClick={() => { setCourseTypeName(item?.typeName); history.push(index == 0 ? "/live-courses-data" : index == 1 ? "/video-resources-data" : index == 2 ? "/online-test-series-details" : index == 3 ? "/study-materials-data" : "/live-courses-data"); window.location.reload(); }}>{item?.label}</Menu.Item>
                                                                    ))}
                                                                </Menu>
                                                            }
                                                        >
                                                            <DownOutlined style={{ color: "#AEAEAE", fontSize: 12, margin: "8px 0px 0px 5px", cursor: "pointer" }} onClick={() => { setCourseTypeDropdown(false) }} />
                                                        </Dropdown>
                                                        :
                                                        <RightOutlined style={{ color: "#AEAEAE", fontSize: 12, margin: "8px 0px 0px 5px", cursor: "pointer" }} onClick={() => {
                                                            if (((courseTypeName == "liveCourses" && (courseStatsData?.videoResourceCount > 0 || courseStatsData?.testCount > 0 || courseStatsData?.studyMaterialCount > 0)) || (courseTypeName == "videoResources" && (courseStatsData?.courseCount > 0 || courseStatsData?.testCount > 0 || courseStatsData?.studyMaterialCount > 0)) || (courseTypeName == "onlineTestSeries" && (courseStatsData?.courseCount > 0 || courseStatsData?.videoResourceCount > 0 || courseStatsData?.studyMaterialCount > 0)) || (courseTypeName == "studyMaterials" && (courseStatsData?.courseCount > 0 || courseStatsData?.videoResourceCount > 0 || courseStatsData?.testCount > 0)))) {
                                                                setCourseTypeDropdown(true)
                                                            }
                                                        }} />
                                                    }
                                                </div>
                                            </div>
                                            {isSubCategoryVisible ?
                                                <>
                                                    <div style={{ color: "#191919", fontSize: 16, fontWeight: 600, margin: "0px 10px", }}>{selectedCategory?.name}</div>
                                                    <div>
                                                        {showCategoriesDropdown ?
                                                            <Dropdown
                                                                trigger={['click']}
                                                                visible={showCategoriesDropdown}
                                                                onVisibleChange={(visible) => setCategoriesDropdown(visible)}
                                                                overlay={
                                                                    <Menu style={{ width: "200px", padding: 5, marginTop: 10, height: 200, overflowY: "scroll" }}>
                                                                        {courseCategoryData?.map((item, index) => (
                                                                            <Menu.Item key={index} style={{ color: "#636363" }}
                                                                                onClick={() => {
                                                                                    setCategoriesDropdown(false);
                                                                                    setSubCategoryVisible(true);
                                                                                    setSelectedCategory({ id: item?.id, name: item?.name });
                                                                                    togglePageLoader(true);
                                                                                    getCoursesSubCategories(item?.id, () => { togglePageLoader(false) }); setSelectedCategoryCards([]);
                                                                                    setSelectedSubCategoryCards([])
                                                                                }}>
                                                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ width: 150, color: "#636363" }}>{item?.name}</Typography.Text>
                                                                            </Menu.Item>
                                                                        ))}
                                                                    </Menu>
                                                                }
                                                            >
                                                                <DownOutlined style={{ color: "#AEAEAE", fontSize: 12, marginTop: "7px", cursor: "pointer" }} onClick={() => { setCategoriesDropdown(false) }} />
                                                            </Dropdown>
                                                            :
                                                            <RightOutlined style={{ color: "#AEAEAE", fontSize: 12, marginTop: "7px", cursor: "pointer" }} onClick={() => { setCategoriesDropdown(true) }} />
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }
                                            {isCoursesCardsVisible ?
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ color: "#191919", fontSize: 16, fontWeight: 600, margin: "0px 10px", cursor: "pointer" }}
                                                        onClick={() => { resetValues(); setCoursesCardsVisible(false); setSubCategoryVisible(true) }}
                                                    >{selectedCategory?.name}</div>
                                                    <div>
                                                        {showCategoriesDropdown ?
                                                            <Dropdown
                                                                trigger={['click']}
                                                                visible={showCategoriesDropdown}
                                                                onVisibleChange={(visible) => setCategoriesDropdown(visible)}
                                                                overlay={
                                                                    <Menu style={{ width: "160px", padding: 5, marginTop: 10, height: 200, overflowY: "scroll" }}>
                                                                        {courseCategoryData?.map((item, index) => (
                                                                            <Menu.Item key={index} style={{ color: "#636363" }} onClick={() => {
                                                                                setCategoriesDropdown(false);
                                                                                setCoursesCardsVisible(false);
                                                                                setSubCategoryVisible(true);
                                                                                setSelectedCategory({ id: item?.id, name: item?.name });
                                                                                togglePageLoader(true);
                                                                                getCoursesSubCategories(item?.id, () => { togglePageLoader(false) }); setSelectedCategoryCards([]);
                                                                                setSelectedSubCategoryCards([])
                                                                            }}>
                                                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ width: 150, color: "#636363" }}>{item?.name}</Typography.Text>
                                                                            </Menu.Item>
                                                                        ))}
                                                                    </Menu>
                                                                }
                                                            >
                                                                <DownOutlined style={{ color: "#AEAEAE", fontSize: 12, marginTop: "8px", marginLeft: -2 }} onClick={() => { setCategoriesDropdown(false) }} />
                                                            </Dropdown>
                                                            :
                                                            <RightOutlined style={{ color: "#AEAEAE", fontSize: 12, marginTop: "8px", marginLeft: -2 }} onClick={() => { setCategoriesDropdown(true) }} />
                                                        }
                                                    </div>
                                                    <div style={{ color: "#191919", fontSize: 16, fontWeight: 600, margin: "0px 10px" }}
                                                    >{selectedSubCategory?.name}
                                                    </div>
                                                    <div>
                                                        {showSubcategoriesDropdown ?
                                                            <Dropdown
                                                                trigger={['click']}
                                                                visible={showSubcategoriesDropdown}
                                                                onVisibleChange={(visible) => setSubcategoriesDropdown(visible)}
                                                                overlay={
                                                                    <Menu style={{ width: "160px", padding: 5, marginTop: 10, height: courseSubCategoriesData?.length < 4 ? "fit-content" : 200, overflowY: courseSubCategoriesData?.length < 4 ? "" : "scroll" }}>
                                                                        {courseSubCategoriesData?.map((item, index) => (
                                                                            <Menu.Item key={index} onClick={() => {
                                                                                setCoursesCardsVisible(true);
                                                                                setSeletedSubCategory({ id: item?.id, name: item?.name });
                                                                                setAllCoursesPageParamsObj("subcategoryId", item?.id);
                                                                                setSelectedSubCategoryCards([]);
                                                                                setSelectedPublishCards([]);
                                                                                setSelectedUnpublishCards([]);
                                                                                togglePageLoader(true);
                                                                                if (courseTypeName == "liveCourses") {
                                                                                    getTotalCoursesList(() => togglePageLoader(false))
                                                                                } else if (courseTypeName == "studyMaterials") {
                                                                                    getStudyMaterialsList(() => togglePageLoader(false))
                                                                                } else if (courseTypeName == "videoResources") {
                                                                                    getTotalsVideoResource(() => togglePageLoader(false))
                                                                                } else if (courseTypeName == "onlineTestSeries") {
                                                                                    getOnlineTestSeries(() => togglePageLoader(false))
                                                                                }
                                                                            }}>
                                                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ width: 150, color: "#636363" }}>{item?.name}</Typography.Text>
                                                                            </Menu.Item>
                                                                        ))}
                                                                    </Menu>
                                                                }
                                                            >
                                                                <DownOutlined style={{ color: "#AEAEAE", fontSize: 12, marginTop: "8px", marginLeft: -2, cursor: "pointer" }} onClick={() => { setSubcategoriesDropdown(false) }} />
                                                            </Dropdown>
                                                            :
                                                            <RightOutlined style={{ color: "#AEAEAE", fontSize: 12, marginTop: "8px", marginLeft: -2, cursor: "pointer" }} onClick={() => { setSubcategoriesDropdown(true) }} />
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                        {(selectedCategoryCards?.length != 0 || selectedSubCategoryCards?.length != 0) ?
                                            <div style={{ color: "#AEAEAE", fontSize: 16, marginTop: 10 }}><Checkbox style={{ marginRight: 10 }} checked={isSubCategoryVisible ? (selectedSubCategoryCards?.length === courseSubCategoriesData?.length && selectedSubCategoryCards?.length !== 0) : (selectedCategoryCards?.length === courseCategoryData?.length && selectedCategoryCards?.length !== 0)} onChange={isSubCategoryVisible ? onSelectAllSubCategory : onSelectAllCategory} /><span>{isSubCategoryVisible ? selectedSubCategoryCards?.length == courseSubCategoriesData?.length ? 'Clear all' : 'Select all' : selectedCategoryCards?.length == courseCategoryData?.length ? 'Clear all' : 'Select all'}</span> <span>( {isSubCategoryVisible ? selectedSubCategoryCards?.length : selectedCategoryCards?.length} selected )</span>
                                            </div>
                                            :
                                            (courseTypeName == 'onlineTestSeries' ? isCoursesCardsVisible && (isPublished
                                                ? selectedPublishCards?.length > 0
                                                : selectedUnpublishCards?.length > 0) && !hasMarketingPrivilege ?

                                                <div style={{ color: "#AEAEAE", fontSize: 16 }}>
                                                    <Checkbox
                                                        style={{ marginRight: 10 }}
                                                        checked={
                                                            isPublished
                                                                ? selectedPublishCards?.length === publishedTests?.length && selectedPublishCards?.length !== 0
                                                                : selectedUnpublishCards?.length === unpublishedTests?.length && selectedUnpublishCards?.length !== 0
                                                        }
                                                        onChange={isPublished ? onSelectAllPublish : onSelectAllUnpublish}
                                                    />
                                                    <span>SELECT ALL</span>
                                                </div>

                                                :

                                                <div style={{ position: 'relative', display: 'inline-block', width: '28.3%' }}>
                                                    <AutoComplete

                                                        // allowClear
                                                        popupClassName="certain-category-search-dropdown"
                                                        popupMatchSelectWidth={500}
                                                        style={{ marginRight: 20, width: '100%' }}
                                                        options={(autocompleteSearchData?.suggestions?.length > 0 || autocompleteSearchData?.resources?.length) ? searchOptions : []}
                                                        onSearch={handleAutocompleteSearch}
                                                        onClear={() => {
                                                            setSelectedSuggestion(null);
                                                            setAutocompleteSearchedValue(null);
                                                            setSearchedSuggestionClicked(false);
                                                            resetValues();
                                                            setCourseTypeName(previousCourseType);
                                                            history.push(previousCourseType === "liveCourses" ? "/live-courses-data" : previousCourseType === "videoResources" ? "/video-resources-data" : previousCourseType === "onlineTestSeries" ? "/online-test-series-details" : previousCourseType === "studyMaterials" ? "/study-materials-data" : "/live-courses-data");
                                                            if (location?.state?.key?.fromDashboardSearch) {
                                                                history.push("/courses");
                                                            } else if (selectedCategory?.id) {
                                                                setSubCategoryVisible(true);
                                                            } else if (selectedSubCategory?.id) {
                                                                setCoursesCardsVisible(false);
                                                            }
                                                        }}
                                                        value={selectedSuggestion ? selectedSuggestion : autocompleteSearchedValue ? autocompleteSearchedValue : null}
                                                        placeholder={
                                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: 30 }}>
                                                                <span><SearchOutlined /> Search courses</span>
                                                            </span>
                                                        }
                                                        loading={searchLoader}
                                                        className='round-bordered-search-course-custom'
                                                    />

                                                    {/* Dropdown for filter icon */}
                                                    <Dropdown
                                                        overlay={isMultipleFilterClicked ? multipleFilterMenu : <></>}
                                                        trigger={['click']}
                                                        open={isMultipleFilterClicked}
                                                    >
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                right: 10, // Adjust positioning as needed
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setMultipleFilterClicked(!isMultipleFilterClicked);
                                                            }}
                                                        >
                                                            <img
                                                                src={require("../../Assets/aulasMarket/filterIC.svg").default}
                                                                style={{
                                                                    height: 15,
                                                                    width: 15,
                                                                    marginRight: 10,
                                                                    marginTop: -3

                                                                }}
                                                                alt="Filter Icon"
                                                            />
                                                        </div>
                                                    </Dropdown>
                                                </div>


                                                : isCoursesCardsVisible && (isPublished
                                                    ? selectedPublishCards?.length > 0
                                                    : selectedUnpublishCards?.length > 0) && !hasMarketingPrivilege ?
                                                    <div style={{ color: "#AEAEAE", fontSize: 16, marginTop: 5, }}>
                                                        <Checkbox
                                                            style={{ marginRight: 10 }}
                                                            checked={
                                                                (isPublished
                                                                    ? selectedPublishCards?.length === totalCoursesDataContainer?.published?.items?.length && selectedPublishCards?.length !== 0
                                                                    : selectedUnpublishCards?.length === totalCoursesDataContainer?.unpublished?.items?.length && selectedUnpublishCards?.length !== 0)
                                                            }
                                                            onChange={isPublished ? onSelectAllPublish : onSelectAllUnpublish}
                                                        />
                                                        <span>SELECT ALL</span>
                                                    </div>
                                                    :
                                                    <div style={{ position: 'relative', display: 'inline-block', width: '28.3%' }}>
                                                        <AutoComplete

                                                            // allowClear
                                                            popupClassName="certain-category-search-dropdown"
                                                            popupMatchSelectWidth={500}
                                                            style={{ marginRight: 20, width: '100%' }}
                                                            options={(autocompleteSearchData?.suggestions?.length > 0 || autocompleteSearchData?.resources?.length) ? searchOptions : []}
                                                            onSearch={handleAutocompleteSearch}
                                                            onClear={() => {
                                                                setSelectedSuggestion(null);
                                                                setAutocompleteSearchedValue(null);
                                                                setSearchedSuggestionClicked(false);
                                                                resetValues();
                                                                setCourseTypeName(previousCourseType);
                                                                history.push(previousCourseType === "liveCourses" ? "/live-courses-data" : previousCourseType === "videoResources" ? "/video-resources-data" : previousCourseType === "onlineTestSeries" ? "/online-test-series-details" : previousCourseType === "studyMaterials" ? "/study-materials-data" : "/live-courses-data");
                                                                if (location?.state?.key?.fromDashboardSearch) {
                                                                    history.push("/courses");
                                                                } else if (selectedCategory?.id) {
                                                                    setSubCategoryVisible(true);
                                                                } else if (selectedSubCategory?.id) {
                                                                    setCoursesCardsVisible(false);
                                                                }
                                                            }}
                                                            value={selectedSuggestion ? selectedSuggestion : autocompleteSearchedValue ? autocompleteSearchedValue : null}
                                                            placeholder={
                                                                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: 30 }}>
                                                                    <span><SearchOutlined /> Search courses</span>
                                                                </span>
                                                            }
                                                            loading={searchLoader}
                                                            className='round-bordered-search-course-custom'
                                                        />

                                                        {/* Dropdown for filter icon */}
                                                        <Dropdown
                                                            overlay={isMultipleFilterClicked ? multipleFilterMenu : <></>}
                                                            trigger={['click']}
                                                            open={isMultipleFilterClicked}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: 10, // Adjust positioning as needed
                                                                    top: '50%',
                                                                    transform: 'translateY(-50%)',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setMultipleFilterClicked(!isMultipleFilterClicked);
                                                                }}
                                                            >
                                                                <img
                                                                    src={require("../../Assets/aulasMarket/filterIC.svg").default}
                                                                    style={{
                                                                        height: 15,
                                                                        width: 15,
                                                                        marginRight: 10,
                                                                        marginTop: -3

                                                                    }}
                                                                    alt="Filter Icon"
                                                                />
                                                            </div>
                                                        </Dropdown>
                                                    </div>)

                                        }

                                        <div style={{ paddingTop: 5 }}>
                                            {isCoursesCardsVisible && (isPublished
                                                ? !selectedPublishCards?.length > 0
                                                : !selectedUnpublishCards?.length > 0) ?
                                                !isGridView ?
                                                    <img src={require("../../Assets/courseManagementV4/list.svg").default} style={{ width: "24px", height: "22px", cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); setIsGridView(true) }} />
                                                    :
                                                    <img src={require("../../Assets/courseManagementV4/grid.svg").default} style={{ width: "24px", height: "22px", cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); setIsGridView(false) }} />
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ marginTop: '2.1%' }}>
                                        {isCoursesCardsVisible ? (
                                            <>
                                                {courseTypeName == 'onlineTestSeries' ?
                                                    (
                                                        <>
                                                            {(isPublished ? publishedTests?.length === 0 : unpublishedTests?.length === 0) ? (
                                                                <div style={{ marginTop: 100, height: "65vh" }}>
                                                                    <NoDataCard />
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {(isPublished ? publishedTests?.length !== 0 : unpublishedTests?.length !== 0) ? (
                                                                        <>
                                                                            {isGridView ? (
                                                                                <div style={{ width: '100%' }}>
                                                                                    {(isPublished ? publishedTests : unpublishedTests)?.map((item, index) => (
                                                                                        <ListViewCoursecards
                                                                                            key={index}
                                                                                            index={index}
                                                                                            item={item}
                                                                                            reference={isPublished ? "published" : "unpublished"}
                                                                                            hasMarketingPrivilege={hasMarketingPrivilege}
                                                                                            selectedPublishCards={selectedPublishCards}
                                                                                            selectedUnpublishCards={selectedUnpublishCards}
                                                                                            totalCoursesDataContainer={totalCoursesDataContainer}
                                                                                            setSearchSelectedCourseType={setSearchSelectedCourseType}
                                                                                            togglePublishedCheckbox={togglePublishedCheckbox}
                                                                                            toggleUnpublishedCheckbox={toggleUnpublishedCheckbox}
                                                                                            CourseCardContent={CourseCardContent}
                                                                                            storeSingleCourseId={storeSingleCourseId}
                                                                                            isCoursesCardsVisible={isCoursesCardsVisible}
                                                                                            selectedCategory={selectedCategory}
                                                                                            selectedSubCategory={selectedSubCategory}
                                                                                            setIsDeleteModalVisible={setIsDeleteModalVisible}
                                                                                            setSelectedPublishCards={setSelectedPublishCards}
                                                                                            setSelectedUnpublishCards={setSelectedUnpublishCards}
                                                                                            setIsMoveModalVisible={setIsMoveModalVisible}
                                                                                            setShareModalVisible={setShareModalVisible}
                                                                                            setShareUrl={setShareUrl}
                                                                                            isPublished={isPublished}
                                                                                            setIsConfirmPublishModalVisible={setIsConfirmPublishModalVisible}
                                                                                            publishedTests={publishedTests}
                                                                                            unpublishedTests={unpublishedTests}
                                                                                            courseTypeName={courseTypeName}
                                                                                            isSearchedSuggestionClicked={isSearchedSuggestionClicked}
                                                                                            setCourseTypeName={setCourseTypeName}
                                                                                            selectedSuggestion={selectedSuggestion}
                                                                                            setHoveredCardId={setHoveredCardId}
                                                                                            hoveredCardId={hoveredCardId}
                                                                                            isGridView={isGridView}
                                                                                        />
                                                                                    ))}
                                                                                    {((storeCategoryData?.[isPublished ? "published" : "unpublished"]?.meta?.currentPage !==
                                                                                        storeCategoryData?.[isPublished ? "published" : "unpublished"]?.meta?.totalPages) &&
                                                                                        storeCategoryData?.[isPublished ? "published" : "unpublished"]?.meta?.totalItems !== 0) && (
                                                                                            <div className='r-c-c' style={{ width: "100%" }}>
                                                                                                <Button
                                                                                                    onClick={() => {
                                                                                                        setLoadMorePage({
                                                                                                            ...loadMorePage,
                                                                                                            testsLoadMore: loadMorePage?.testsLoadMore + 1
                                                                                                        });
                                                                                                        togglePageLoader(true);
                                                                                                        getTestsLoadMore(
                                                                                                            loadMorePage?.testsLoadMore + 1,
                                                                                                            () => { togglePageLoader(false) },
                                                                                                            isPublished
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    LOAD MORE
                                                                                                </Button>
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            ) : (
                                                                                <div className={`${isPublished ? selectedUnpublishCards?.length !== 0 : selectedPublishCards?.length !== 0 ? "disabled-div" : ""}`}
                                                                                    style={{ display: "flex", flexWrap: "wrap", gap: '2.1%' }}>
                                                                                    {(isPublished ? publishedTests : unpublishedTests)?.map((item, index) => (
                                                                                        <LiveCourseCard key={index} index={index} item={item} reference={isPublished ? "published" : "unpublished"} />
                                                                                    ))}
                                                                                    {((storeCategoryData?.[isPublished ? "published" : "unpublished"]?.meta?.currentPage !==
                                                                                        storeCategoryData?.[isPublished ? "published" : "unpublished"]?.meta?.totalPages) &&
                                                                                        storeCategoryData?.[isPublished ? "published" : "unpublished"]?.meta?.totalItems !== 0) && (
                                                                                            <div className='r-c-c' style={{ width: "100%" }}>
                                                                                                <Button
                                                                                                    onClick={() => {
                                                                                                        setLoadMorePage({
                                                                                                            ...loadMorePage,
                                                                                                            testsLoadMore: loadMorePage?.testsLoadMore + 1
                                                                                                        });
                                                                                                        togglePageLoader(true);
                                                                                                        getTestsLoadMore(
                                                                                                            loadMorePage?.testsLoadMore + 1,
                                                                                                            () => { togglePageLoader(false) },
                                                                                                            isPublished
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    LOAD MORE
                                                                                                </Button>
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )
                                                    :
                                                    (<>
                                                        {(isPublished
                                                            ? totalCoursesDataContainer?.published?.items?.length == 0
                                                            : totalCoursesDataContainer?.unpublished?.items?.length == 0) ? (
                                                            <div style={{ marginTop: 100, height: "65vh" }}>
                                                                <NoDataCard />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {isPublished ? (
                                                                    totalCoursesDataContainer?.published?.items?.length !== 0 ? (
                                                                        <>
                                                                            {/* Render Published Courses */}
                                                                            {isGridView ? (
                                                                                <div style={{ width: '100%' }}>
                                                                                    {totalCoursesDataContainer?.published?.items?.map((item, index) => (
                                                                                        <ListViewCoursecards
                                                                                            key={index}
                                                                                            index={index}
                                                                                            item={item}
                                                                                            reference="published"
                                                                                            hasMarketingPrivilege={hasMarketingPrivilege}
                                                                                            fromSearchCourse={false}
                                                                                            selectedUnpublishCards={selectedUnpublishCards}
                                                                                            searchResultData={searchResultData}
                                                                                            fromSearchVideo={false}
                                                                                            selectedPublishCards={selectedPublishCards}
                                                                                            courseTypeName={courseTypeName}
                                                                                            totalCoursesDataContainer={totalCoursesDataContainer}
                                                                                            setSearchSelectedCourseType={setSearchSelectedCourseType}
                                                                                            togglePublishedCheckbox={togglePublishedCheckbox}
                                                                                            toggleUnpublishedCheckbox={toggleUnpublishedCheckbox}
                                                                                            CourseCardContent={CourseCardContent}
                                                                                            storeSingleCourseId={storeSingleCourseId}
                                                                                            isCoursesCardsVisible={isCoursesCardsVisible}
                                                                                            selectedCategory={selectedCategory}
                                                                                            selectedSubCategory={selectedSubCategory}
                                                                                            setIsDeleteModalVisible={setIsDeleteModalVisible}
                                                                                            setSelectedPublishCards={setSelectedPublishCards}
                                                                                            setSelectedUnpublishCards={setSelectedUnpublishCards}
                                                                                            setIsMoveModalVisible={setIsMoveModalVisible}
                                                                                            setShareModalVisible={setShareModalVisible}
                                                                                            setShareUrl={setShareUrl}
                                                                                            isPublished={isPublished}
                                                                                            setIsConfirmPublishModalVisible={setIsConfirmPublishModalVisible}
                                                                                            publishedTests={publishedTests}
                                                                                            unpublishedTests={unpublishedTests}
                                                                                            isSearchedSuggestionClicked={isSearchedSuggestionClicked}
                                                                                            setCourseTypeName={setCourseTypeName}
                                                                                            selectedSuggestion={selectedSuggestion}
                                                                                            setHoveredCardId={setHoveredCardId}
                                                                                            hoveredCardId={hoveredCardId}
                                                                                            isGridView={isGridView}


                                                                                        />
                                                                                    ))}
                                                                                    {/* Load More Button for Published Courses */}
                                                                                    {(totalCoursesDataContainer?.published?.meta?.currentPage !== totalCoursesDataContainer?.published?.meta?.totalPages && totalCoursesDataContainer?.published?.meta?.totalItems !== 0) && (
                                                                                        <div className='r-c-c' style={{ width: "100%" }}>
                                                                                            <Button onClick={() => loadMore('published')}>
                                                                                                LOAD MORE
                                                                                            </Button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <div className={`${selectedUnpublishCards?.length != 0 ? "disabled-div" : ""}`} style={{ display: "flex", flexWrap: "wrap", gap: '2.1%' }}>
                                                                                    {totalCoursesDataContainer?.published?.items?.map((item, index) => (
                                                                                        <LiveCourseCard key={index} index={index} item={item} reference="published" />
                                                                                    ))}
                                                                                    {/* Load More Button for Published Courses */}
                                                                                    {(totalCoursesDataContainer?.published?.meta?.currentPage !== totalCoursesDataContainer?.published?.meta?.totalPages && totalCoursesDataContainer?.published?.meta?.totalItems !== 0) && (
                                                                                        <div className='r-c-c' style={{ width: "100%" }}>
                                                                                            <Button onClick={() => loadMore('published')}>
                                                                                                LOAD MORE
                                                                                            </Button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    ) : null
                                                                ) : (
                                                                    totalCoursesDataContainer?.unpublished?.items?.length !== 0 ? (
                                                                        <>
                                                                            {isGridView ? (
                                                                                <div style={{ width: '100%' }}>
                                                                                    {totalCoursesDataContainer?.unpublished?.items?.map((item, index) => (
                                                                                        <ListViewCoursecards
                                                                                            key={index}
                                                                                            index={index}
                                                                                            item={item}
                                                                                            reference="unpublished"
                                                                                            hasMarketingPrivilege={hasMarketingPrivilege}
                                                                                            fromSearchCourse={false}
                                                                                            selectedUnpublishCards={selectedUnpublishCards}
                                                                                            searchResultData={searchResultData}
                                                                                            fromSearchVideo={false}
                                                                                            selectedPublishCards={selectedPublishCards}
                                                                                            courseTypeName={courseTypeName}
                                                                                            totalCoursesDataContainer={totalCoursesDataContainer}
                                                                                            setSearchSelectedCourseType={setSearchSelectedCourseType}
                                                                                            togglePublishedCheckbox={togglePublishedCheckbox}
                                                                                            toggleUnpublishedCheckbox={toggleUnpublishedCheckbox}
                                                                                            CourseCardContent={CourseCardContent}
                                                                                            storeSingleCourseId={storeSingleCourseId}
                                                                                            isCoursesCardsVisible={isCoursesCardsVisible}
                                                                                            selectedCategory={selectedCategory}
                                                                                            selectedSubCategory={selectedSubCategory}
                                                                                            setIsDeleteModalVisible={setIsDeleteModalVisible}
                                                                                            setSelectedPublishCards={setSelectedPublishCards}
                                                                                            setSelectedUnpublishCards={setSelectedUnpublishCards}
                                                                                            setIsMoveModalVisible={setIsMoveModalVisible}
                                                                                            setShareModalVisible={setShareModalVisible}
                                                                                            setShareUrl={setShareUrl}
                                                                                            isPublished={isPublished}
                                                                                            setIsConfirmPublishModalVisible={setIsConfirmPublishModalVisible}
                                                                                            publishedTests={publishedTests}
                                                                                            unpublishedTests={unpublishedTests}
                                                                                            isSearchedSuggestionClicked={isSearchedSuggestionClicked}
                                                                                            setCourseTypeName={setCourseTypeName}
                                                                                            selectedSuggestion={selectedSuggestion}
                                                                                            setHoveredCardId={setHoveredCardId}
                                                                                            hoveredCardId={hoveredCardId}
                                                                                            isGridView={isGridView}

                                                                                        />
                                                                                    ))}
                                                                                    {/* Load More Button for Published Courses */}
                                                                                    {(totalCoursesDataContainer?.unpublished?.meta?.currentPage !== totalCoursesDataContainer?.unpublished?.meta?.totalPages && totalCoursesDataContainer?.unpublished?.meta?.totalItems !== 0) && (
                                                                                        <div className='r-c-c' style={{ width: "100%" }}>
                                                                                            <Button onClick={() => loadMore('unpublished')}>
                                                                                                LOAD MORE
                                                                                            </Button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <div className={`${selectedPublishCards?.length != 0 ? "disabled-div" : ""}`} style={{ display: "flex", flexWrap: "wrap", gap: '2.1%' }}>
                                                                                    {totalCoursesDataContainer?.unpublished?.items?.map((item, index) => (
                                                                                        <LiveCourseCard key={index} index={index} item={item} reference="unpublished" />
                                                                                    ))}
                                                                                    {/* Load More Button for Unpublished Courses */}
                                                                                    {(totalCoursesDataContainer?.unpublished?.meta?.currentPage !== totalCoursesDataContainer?.unpublished?.meta?.totalPages && totalCoursesDataContainer?.unpublished?.meta?.totalItems !== 0) && (
                                                                                        <div className='r-c-c' style={{ width: "100%" }}>
                                                                                            <Button onClick={() => loadMore('unpublished')}>
                                                                                                LOAD MORE
                                                                                            </Button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}






                                                                        </>
                                                                    ) : null
                                                                )}
                                                            </>
                                                        )}
                                                    </>)


                                                }


                                            </>
                                        )
                                            : isSubCategoryVisible ? (
                                                <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
                                                    {hasMarketingPrivilege ? <></> : <AddSubCategoryCard />}

                                                    {courseSubCategoriesData?.map((item, index) => <SubCategoryCard key={index} index={index} item={item} />)}
                                                </div>
                                            ) : (

                                                <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
                                                    {hasMarketingPrivilege ? <></> : <AddCategoryCard />}
                                                    {courseCategoryData?.map((item, index) => <CategoryCard key={index} item={item} index={index} />)}
                                                </div>
                                            )}
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            {isCreateOrEditCategoryVisible &&
                <CreateOrEditCategoryModal
                    isCreateOrEditCategoryVisible={isCreateOrEditCategoryVisible}
                    setIsCreateOrEditCategoryVisible={setIsCreateOrEditCategoryVisible}
                    isEdit={isEdit}
                    isSubCategory={isSubCategory}
                    selectedCategory={selectedCategory}
                    selectedSubCategory={selectedSubCategory}
                    togglePageLoader={togglePageLoader}
                    getCoursesCategories={getCoursesCategories}
                    getCoursesSubCategories={getCoursesSubCategories}
                />}
            {isMoveModalVisible &&
                <MoveModal
                    isMoveModalVisible={isMoveModalVisible}
                    setIsMoveModalVisible={setIsMoveModalVisible}
                    selectedSubCategoryCards={selectedSubCategoryCards}
                    setSelectedSubCategoryCards={setSelectedSubCategoryCards}
                    courseSubCategoriesData={courseSubCategoriesData}
                    isSubCategoryVisible={isSubCategoryVisible}
                    courseCategoryData={courseCategoryData}
                    togglePageLoader={togglePageLoader}
                    getCoursesSubCategories={getCoursesSubCategories}
                    selectedCategory={selectedCategory}
                    selectedPublishCards={selectedPublishCards}
                    setSelectedPublishCards={setSelectedPublishCards}
                    selectedUnpublishCards={selectedUnpublishCards}
                    setSelectedUnpublishCards={setSelectedUnpublishCards}
                    isCoursesCardsVisible={isCoursesCardsVisible}
                    UnpublishCourseData={(isSearchedSuggestionClicked && searchSelectedCourseType == "searchCourse") ? searchResultData?.courses?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchVideo") ? searchResultData?.videoResources?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchStudyMaterial") ? searchResultData?.studyMaterials?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchTests") ? searchResultData?.onlineTestSeries?.items : totalCoursesDataContainer?.unpublished?.items}
                    publishCourseData={(isSearchedSuggestionClicked && searchSelectedCourseType == "searchCourse") ? searchResultData?.courses?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchVideo") ? searchResultData?.videoResources?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchStudyMaterial") ? searchResultData?.studyMaterials?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchTests") ? searchResultData?.onlineTestSeries?.items : totalCoursesDataContainer?.published?.items}
                    getTotalCoursesList={getTotalCoursesList}
                    getCoursesCategories={getCoursesCategories}
                    courseTypeName={courseTypeName}
                    getStudyMaterialsList={getStudyMaterialsList}
                    getTotalsVideoResource={getTotalsVideoResource}
                    getOnlineTestSeries={getOnlineTestSeries}
                />}
            {isDeleteModalVisible &&
                <DeleteModal
                    isDeleteModalVisible={isDeleteModalVisible}
                    setIsDeleteModalVisible={setIsDeleteModalVisible}
                    selectedCategoryCards={selectedCategoryCards}
                    setSelectedCategoryCards={setSelectedCategoryCards}
                    courseCategoryData={courseCategoryData}
                    selectedSubCategoryCards={selectedSubCategoryCards}
                    setSelectedSubCategoryCards={setSelectedSubCategoryCards}
                    courseSubCategoriesData={courseSubCategoriesData}
                    isSubCategoryVisible={isSubCategoryVisible}
                    selectedPublishCards={selectedPublishCards}
                    setSelectedPublishCards={setSelectedPublishCards}
                    selectedUnpublishCards={selectedUnpublishCards}
                    setSelectedUnpublishCards={setSelectedUnpublishCards}
                    isCoursesCardsVisible={isCoursesCardsVisible}
                    UnpublishCourseData={(isSearchedSuggestionClicked && searchSelectedCourseType == "searchCourse") ? searchResultData?.courses?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchVideo") ? searchResultData?.videoResources?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchStudyMaterial") ? searchResultData?.studyMaterials?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchTests") ? searchResultData?.onlineTestSeries?.items : totalCoursesDataContainer?.unpublished?.items}
                    publishCourseData={(isSearchedSuggestionClicked && searchSelectedCourseType == "searchCourse") ? searchResultData?.courses?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchVideo") ? searchResultData?.videoResources?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchStudyMaterial") ? searchResultData?.studyMaterials?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchTests") ? searchResultData?.onlineTestSeries?.items : totalCoursesDataContainer?.published?.items}
                    togglePageLoader={togglePageLoader}
                    getTotalCoursesList={getTotalCoursesList}
                    getCoursesSubCategories={getCoursesSubCategories}
                    selectedCategory={selectedCategory}
                    getCoursesCategories={getCoursesCategories}
                    courseTypeName={courseTypeName}
                    getStudyMaterialsList={getStudyMaterialsList}
                    getTotalsVideoResource={getTotalsVideoResource}
                    isSearchedSuggestionClicked={isSearchedSuggestionClicked}
                    searchSelectedCourseType={searchSelectedCourseType}
                    getSearchResultsData={getSearchResultsData}
                    getOnlineTestSeries={getOnlineTestSeries}

                />
            }
            {isConfirmPublishModalVisible &&
                <ConfirmPublishOrUnpublishModal
                    isConfirmPublishModalVisible={isConfirmPublishModalVisible}
                    setIsConfirmPublishModalVisible={setIsConfirmPublishModalVisible}
                    selectedPublishCards={selectedPublishCards}
                    setSelectedPublishCards={setSelectedPublishCards}
                    selectedUnpublishCards={selectedUnpublishCards}
                    setSelectedUnpublishCards={setSelectedUnpublishCards}
                    togglePageLoader={togglePageLoader}
                    getTotalCoursesList={getTotalCoursesList}
                    courseTypeName={courseTypeName}
                    getStudyMaterialsList={getStudyMaterialsList}
                    getTotalsVideoResource={getTotalsVideoResource}
                    isSearchedSuggestionClicked={isSearchedSuggestionClicked}
                    searchSelectedCourseType={searchSelectedCourseType}
                    getSearchResultsData={getSearchResultsData}
                    getOnlineTestSeries={getOnlineTestSeries}
                    setHoveredCardId={setHoveredCardId}
                />}
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl} />}
            <PageDataLoader visible={pageLoader} />
        </div>

    )
}
const mapStateToProps = (state) => {
    const { courseTypeName, courseCategoryData, courseSubCategoriesData, totalCoursesDataContainer, courseStatsData, courseCreateObj, autocompleteSearchData, searchResultData, searchPageParamsObj, storeCategoryData } = state.courseManagement;
    const { user } = state.session;
    return {
        courseTypeName, courseCategoryData, courseSubCategoriesData, totalCoursesDataContainer, courseStatsData, courseCreateObj, autocompleteSearchData, searchResultData, searchPageParamsObj, storeCategoryData, user
    };
};

const mapDispatchToProps = (dispatch) => ({
    getCoursesCategories: (callBack) => dispatch(getCoursesCategories(callBack)),
    getCoursesSubCategories: (id, callBack) => dispatch(getCoursesSubCategories(id, callBack)),
    getOnlineTestSeries: (callBack) => dispatch(getOnlineTestSeries(callBack)),
    getTotalCoursesList: (callBack) => dispatch(getTotalCoursesList(callBack)),
    setAllCoursesPageParamsObj: (key, val) => dispatch(setAllCoursesPageParamsObj(key, val)),
    storeSingleCourseId: (val) => dispatch(storeSingleCourseId(val)),
    getStudyMaterialsList: (callBack) => dispatch(getStudyMaterialsList(callBack)),
    getTotalsVideoResource: (callBack) => dispatch(getTotalsVideoResource(callBack)),
    setCourseTypeName: (val) => dispatch(setCourseTypeName(val)),
    getAutocompleteSearch: (search, callBack) => dispatch(getAutocompleteSearch(search, callBack)),
    getSearchResultsData: (callBack) => dispatch(getSearchResultsData(callBack)),
    setSearchResultPageParamsObj: (key, val) => dispatch(setSearchResultPageParamsObj(key, val)),
    storeAutocompleteSearchData: (val) => dispatch(storeAutocompleteSearchData(val)),
    getCourseLoadMore: (page, callBack, published) => dispatch(getCourseLoadMore(page, callBack, published)),
    getVideoResourceLoadMore: (page, callBack, published) => dispatch(getVideoResourceLoadMore(page, callBack, published)),
    getTestsLoadMore: (page, callBack, published) => dispatch(getTestsLoadMore(page, callBack, published)),
    getStudyMaterialLoadMore: (page, callBack, published) => dispatch(getStudyMaterialLoadMore(page, callBack, published)),
    getCourseStats: () => dispatch(getCourseStats()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminMarketPlaceWithData);