import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import PageDataLoader from "components/pageDataLoader";
import { getResult } from "actions/marketTest";
import { Color } from "services";
import { PageHeader, Row, Col, Select, Progress, Button } from "antd";
import { Heading } from 'components/Typography';
import { CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { AiTwotoneCheckCircle } from 'react-icons/ai';


const questionsSummary = (icon, count, label) => (
    <div className="display-flex" style={{ alignItems: 'center' }}>
        <div>
            <img src={icon} width={40} alt="" />
        </div>
        <div className="m-l-10">
            <div className="text-md bold-700">{count}</div>
            <div className="text-xs dark-grey">{label}</div>
        </div>
    </div>
)

const performanceSummary = (icon, count, text, label) => (
    <div className="r-jsb" style={{ alignItems: 'center' }}>
        <div className="display-flex" style={{ alignItems: 'center' }}>
            <img src={icon} width={40} alt="" />
            <div className="text-xs bold-700 m-l-10">{label}</div>
        </div>
        <div className="m-l-10">
            <div className="text-sm bold-700">{count} <span className="text-xsm dark-grey">{text}</span> </div>
        </div>
    </div>
)

const singleSection = (details) => {
    return (
        <div className="m-t-20">
            <div className="bold-600">{details?.subject?.name}</div>
            <div className="r-jsb">
                <div className="display-flex" style={{ borderBottom: `1px solid ${Color.midGrey}`, width: "100%" }}>
                    <div style={{ padding: 20 }}>
                        <CheckCircleOutlined style={{ color: Color.seaGreen }} /> {details.correct + details.partial}
                    </div>
                    <div style={{ padding: 20 }}>
                        <CloseCircleOutlined style={{ color: Color.red }} /> {details.incorrect}
                    </div>
                    <div style={{ padding: 20 }}>
                        <MinusCircleOutlined style={{ color: Color.darkGrey }} /> {details.unattempted}
                    </div>
                    <div style={{ padding: 20 }}>
                        <AiTwotoneCheckCircle style={{ color: "white", border: "1px solid black", borderRadius: 10 }} /> {details.unseen}
                    </div>
                </div>
                <div className="text-md bold-600" style={{ padding: 20 }}>
                    {details.marksObtained || 0}/{details.totalMarks}
                </div>
            </div>
        </div>
    )
}



function TestSeriesResult({ getResult, testResult ,selectedTest}) {
    const [pageLoader, togglePageLoader] = useState(false);

    const history = useHistory();

    useEffect(() => {
        togglePageLoader(true);
        getResult(() => togglePageLoader(false));
    }, []);

    return (
        <div style={{ height: "100%" }}>
            <PageHeader
                title={<div className="text-md"> Result </div>}
                onBack={() => history.goBack()}
                backIcon={<ArrowLeftOutlined style={{ fontSize: "1.2em" }} />}
            />
            <div className="m-l-20">
                <Heading bold>{selectedTest?.title}</Heading>
                <div className="text-xs dark-grey">
                    {testResult?.myTest?.subjectSectionDetails?.map((subject, index) => {
                        if (index == 0) return `${subject?.subject?.name}`
                        else return ` • ${subject?.subject?.name}`
                    })}
                </div>
            </div>
            <Row style={{ height: "100%" }}>
                <Col
                    md={{ span: 12 }}
                    lg={{ span: 15 }}
                    xl={{ span: 15 }}
                    xxl={{ span: 17 }}
                >
                    <div style={{ padding: "0 20px 20px 20px" }}>
                        <div className="graphBox" style={{ padding: 25, height: 350 }}>
                            <div className="bold-600">Score</div>
                            <Row style={{ height: "95%" }}>
                                <Col span={12} className="r-c-c-c">
                                    <Progress
                                        type="circle"
                                        percent={Math.round(
                                            (testResult?.myTest?.gradeObtained /
                                                testResult?.myTest?.totalPoints) *
                                            100
                                        )}
                                        strokeWidth={10}
                                        width={220}
                                        strokeLinecap="square"
                                        strokeColor="#594099"
                                        format={(percent) => (
                                            <>
                                                <div
                                                    className="bold-600 m-b-10 "
                                                    style={{ fontSize: "0.6em" }}
                                                >
                                                    
                                                    {testResult?.myTest?.gradeObtained == null ? 0 : testResult?.myTest?.gradeObtained}
                                                </div>
                                                <div
                                                    className="bold-600 "
                                                    style={{ fontSize: "0.6em" }}
                                                >
                                                    <span style={{ borderTop: "1px solid black" }}>
                                                        {testResult?.myTest?.totalPoints}
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    />
                                </Col>
                                <Col span={12}>
                                    <div
                                        className="m-l-40 r-c-jsb"
                                        style={{ alignItems: "flex-start", height: "100%" }}
                                    >
                                        {questionsSummary(
                                            require("Assets/correct-icon.svg").default,
                                            testResult?.myTest?.correct,
                                            "Correct"
                                        )}
                                        {/* {questionsSummary(
                                            require("Assets/correct-icon.svg").default,
                                            testResult?.myTest?.partial,
                                            "Partially correct"
                                        )} */}
                                        {questionsSummary(
                                            require("Assets/wrong-icon.svg").default,
                                            testResult?.myTest?.incorrect,
                                            "Incorrect"
                                        )}
                                        {questionsSummary(
                                            require("Assets/unattempt-icon.svg").default,
                                            testResult?.myTest?.unattempted,
                                            "Unattempted"
                                        )}
                                        {questionsSummary(
                                            require("Assets/images/ic-unseen.svg").default,
                                            testResult?.myTest?.unseen,
                                            "Unseen"
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {testResult?.myTest?.subjectSectionDetails?.length > 1 ? (
                        <div style={{ padding: "20px 20px" }}>
                            <div className="graphBox" style={{ padding: 25, minHeight: 400 }}>
                                <div className="bold-600">Subjects</div>
                                {testResult?.myTest?.subjectSectionDetails?.map((subject, index) => singleSection(subject))}
                                <div className="r-c-c m-t-30">
                                    <Button
                                        type="primary"
                                        shape="round"
                                        onClick={() => history.push("/testSeries/solutions")}
                                    >
                                        VIEW SOLUTIONS
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ) :
                        <div className="r-c-c m-t-30">
                            <Button
                                type="primary"
                                shape="round"
                                onClick={() => history.push("/testSeries/solutions")}
                            >
                                VIEW SOLUTIONS
                            </Button>
                        </div>
                    }
                </Col>
                <Col
                    md={{ span: 12 }}
                    lg={{ span: 9 }}
                    xl={{ span: 9 }}
                    xxl={{ span: 7 }}
                >
                    <div style={{ padding: "0 20px 20px 20px" }}>
                        <div
                            className="graphBox"
                            style={{ width: "90%", padding: 25, height: 350 }}
                        >
                            <div className="bold-600">Performance analysis</div>
                            <div
                                className="display-flex m-t-30"
                                style={{
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    height: "80%",
                                }}
                            >
                                {performanceSummary(
                                    require("Assets/rank-icon.svg").default,
                                    testResult?.myTest?.rank + "/" + testResult?.totalStudents,
                                    "",
                                    "Rank"
                                )}
                                {performanceSummary(
                                    require("Assets/accuracy-icon.svg").default,
                                    testResult?.accuracy,
                                    "%",
                                    "Accuracy"
                                )}
                                {performanceSummary(
                                    require("Assets/speed-icon.svg").default,
                                    testResult?.speed,
                                    "Mins/Qs",
                                    "Speed"
                                )}
                                {performanceSummary(
                                    require("Assets/percentile-icon.svg").default,
                                    testResult?.myTest?.percentile,
                                    <sup>%</sup>,
                                    "Percentile"
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <PageDataLoader visible={pageLoader} />
        </div>
    );
}

const mapStateToProps = (state) => {
    const { testResult } = state.marketTest;
    const {selectedTest} = state.studentTest;

    return { testResult,selectedTest };
};

const mapDispatchToProps = (dispatch) => ({
    getResult: (loaderCallback) => dispatch(getResult(loaderCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestSeriesResult);
