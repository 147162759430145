import React from 'react'
import image from '../../Assets/care/illustration-chat.svg';
import {
    getConversationOpenedClosedUsersFunction,
    setCurrentPageNo,
    setSpecificStudentUseId,
    setRole,
    setBatch,
    setName,
    setDate,
    setMonthOrWeek,
    setAulasCareChat
  } from 'actions/adminCare';
import { connect } from 'react-redux';

function EmptyChatScreen({ adimin,openCloseConversationListWithChat }) {
    return (

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "50px" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "400px", borderRadius: "10px", padding: "20px" }}>
                <img style={{ width: "50%", height: "50%", marginBottom: "10px" }} src={image} alt="" />
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    {adimin ? 
                    !openCloseConversationListWithChat?.onlyOpen?.length > 0 && !openCloseConversationListWithChat?.onlyClose?.length>0?
                        <>
                            <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}> No conversation found!</h1>
                            <p style={{ color: "#636363", fontFamily: "roboto", textAlign: "center" }}>Try again later.</p>
                        </>
                        :
                        <>
                             <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}> No Chat Selected, kindly select a chat </h1>
                             <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>to view conversation</h1>
                             {/* <p style={{ color: "#636363", fontFamily: "roboto", textAlign: "center" }}>Try ater.</p> */}

                        </>
                        :
                        <>
                            <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}> Start a conversation now!</h1>
                            <p style={{ color: "#636363", fontFamily: "roboto", textAlign: "center" }}>Need support with something? Tell us about it and we will sort it out for you asap.</p>
                        </>
                    }
                </div>
            </div>
        </div>


    )
}

// export default EmptyChatScreen
const mapStateToProps = state => {
    const {
      chats,
      currentPage,
      batch,
      role,
      date,
      name,
      specificStudentId,
      openCloseConversationListWithChat,
    } = state.adminCare;
    const {
      user,
    } = state.session;
  
    return {
      chats,
      user,
      batch,
      role,
      date,
      name,
      specificStudentId,
      currentPage,
      openCloseConversationListWithChat,
    };
  };
  
  const mapDispatchToProps = dispatch => ({
   
    setSpecificStudentUseId: (userId) => dispatch(setSpecificStudentUseId(userId)),
   
  
  
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(EmptyChatScreen);
