import { Modal } from 'antd'
import { Button } from 'components/Button'
import { Heading } from 'components/Typography'
import React from 'react'

const GoLiveModal = ({ open, onCancel, handleGoliveClick, goLiveLoader, active, handleGoOffline,toggleGoLiveModal }) => {

    console.log({ active })

    return (
        <div>
            <Modal
                centered
                closable
                open={open}
                footer={null}
                className='modal-round-corner'
                onCancel={onCancel}
            >
                <center>
                    <Heading>Confirm</Heading>
                    {!active ?
                        <div style={{ width: '80%', fontWeight: 500, fontSize: 14 }}>Are you sure you want to make Marketplace live? All the saved data will be made accessible to the students.</div>
                        :
                        <div style={{ width: '80%', fontWeight: 500, fontSize: 14 }}>Are you sure you want to make Marketplace offline? All the saved data will be made non accessible to the students.</div>
                    }
                    <div className='r-jsb m-t-50' style={{ width: '60%' }}>
                        <Button type='primary' onClick={()=>toggleGoLiveModal(false)} style={{ backgroundColor: 'white', color: 'black' }}>NO</Button>
                        <Button loading={goLiveLoader} onClick={!active ? handleGoliveClick : handleGoOffline} type='primary'>YES</Button>
                    </div>
                </center>
            </Modal>
        </div>
    )
}

export default GoLiveModal