import React from "react";
import {  InputToolbar,  Bubble, Time } from 'react-web-gifted-chat'
export function renderTime(props) {
    return (
      <Time
        {...props}
        textStyle={{
          right: {
            color: "#F5F5F5",
            opacity: 0.5,
            fontSize: 10
          },
          left: {
            color: "#F5F5F5",
            opacity: 0.5,
            fontSize:10
          }
        }}
      />
    );
  }

 export function renderBubble(props) {
    return (
      <Bubble
        {...props}
        selectable={true}
        usernameStyle={{
          fontSize: 10,
          marginRight:-10
        }}
        wrapperStyle={{
          right: {
            backgroundColor: '#5d5d5d',
            padding: 6,
            borderRadius: 13,
            elevation: 2,
            marginBottom: 10,
            overflow:"hidden",
            width:"90%"
          },
          left: {
            backgroundColor: '#616161',
            padding: 6,
            borderRadius: 13,
            elevation: 2,
            marginBottom: 10,
            overflow:"hidden",
            width:"90%"
          },
        }}
        textStyle={{
          right: {
            color: 'white',
            fontSize: 11,
            lineHeight: 17,
            fontFamily: 'Roboto',
            opacity:0.7,
            wordWrap:'break-word'
          },
          left: {
            color: 'white',
            fontSize: 11,
            lineHeight: 17,
            fontFamily: 'Roboto',
            opacity:0.7,
            wordWrap: 'break-word'
          },
        }}
      />
    );
  }

  export function renderInputToolbar(props) {
    return (
      <InputToolbar {...props} containerStyle={{
        minHeight: '200',
        maxHeight: '500',
        height: 'fit-content',
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor:'#3e3e3e',
        boxShadow: '4px 4px 18px #302A5F14',
        border: '1px solid rgba(256, 256, 256, 0.25)',
        borderRadius: 8,
        position: 'relative'

      }}  >

      </InputToolbar>
    )
  }