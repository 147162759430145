import React, { useEffect, useState } from 'react';
// import { NoData, newCardStyle } from "./courseInformationPage";
import { ClockCircleOutlined, FileSearchOutlined, PlusCircleOutlined, ProfileOutlined, QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button } from 'components/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SquareSubIcon } from 'components/subIcon';
import { Divider, Skeleton, Tooltip, Typography } from 'antd';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { NodataCard } from 'pages/Test';
import moment from 'moment';

export const newCardStyle = {
    backgroundColor: "#FFFFFF",
    padding: "24px",
    boxShadow: "4px 4px 16px #0000000F",
    borderRadius: "17px",
    width: "100%"
}

function CourseInfoTest({ specificCourseDataContainer, isCoursePlanningEditClicked, setIsCoursePlanningEditClicked, setIsExamPatern, isSyllabas, setIsSyllabas, courseTypeName, isTestExpan, setIsTestExpand, setIndividualTestId, setIsViewDetailsClicked, setImportMeta, hasMarketingPrivilege }) {
    const history = useHistory();
    return (
        <div>
            <div style={{ ...newCardStyle, marginTop: 20, height: isTestExpan ? '320px' : 76, transition: "height 0.2s ease" }} className='course-planning-card '>
                {isTestExpan ?
                    <>
                        <div className='r-c-sb' >
                            <div  className='r-c-sb' style={{width:"35%" }}>
                                <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>Tests</div>
                                <div style={{ color: "#636363", fontWeight: "normal" }}>{specificCourseDataContainer?.testSeriesDetails?.totalTestCount} Tests </div>
                                <div style={{ color: "#636363", fontWeight: "normal" }}>{specificCourseDataContainer?.testSeriesDetails?.freeTestCount} Free attempt tests </div>
                            </div>
                            {/* <div style={{  marginLeft: '30%',border:"1px solid blue",width:"100%" }}> */}
                            <div className='r-c-sb' style={{ width: "30%", marginLeft: "30%", display: hasMarketingPrivilege ? 'flex' : '', justifyContent: hasMarketingPrivilege ? 'flex-end' : 'space-between' }}>

                                {hasMarketingPrivilege ? <></> :
                                    <div
                                        style={{ color: "#594099", backgroundColor: "#FFFFFF", marginRight: 20, height: 28, fontWeight: "bold", cursor: "pointer" }}
                                        onClick={() => {
                                            setImportMeta("selectedQBType", "sample question papers");
                                            setImportMeta('selectedTypeId', 1);
                                            history.push({
                                                pathname: '/test-import-classes',
                                                state: {
                                                    testSeriesData: true
                                                }
                                            })
                                        }}><SearchOutlined style={{ marginRight: 5 }} />BROWSE</div>
                                }
                                {hasMarketingPrivilege ? <></> :
                                    <div
                                        style={{ color: "#1089FF", height: 28, fontWeight: "bold", cursor: "pointer" }}
                                        onClick={() => { setIndividualTestId(null); history.push({ pathname: "/testSeries/createTest" }) }} >
                                        <PlusCircleOutlined style={{ color: "#1089FF", marginRight: 5 }} />CREATE</div>
                                }


                                <Button
                                    type="link"
                                    style={{ fontSize: 14, color: '#636363' }}
                                    onClick={() => {
                                        setIsCoursePlanningEditClicked(false);
                                        setIsTestExpand(false)
                                        setIsExamPatern(false)
                                        setIsSyllabas(false)
                                        setIsViewDetailsClicked(true)
                                    }}
                                ><FiChevronUp style={{ fontSize: 18, marginLeft: 5 }} /></Button>
                            </div>

                        </div>


                        {/* </div> */}
                        {/* <div style={{ color: "#636363", fontWeight: "normal" }}>Subjects </div>
                    <div style={{ color: "#636363", fontWeight: "normal" }}>Chapters </div> */}
                        <div className=' m-t-10'>
                            <div className='scroll-bar-universal' style={{ width: '100%', height: '230px', backgroundColor: '#fff' }}>
                                <div style={{ display: 'flex', width: '99%', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                    {console.log('specificCourseDataContainer', specificCourseDataContainer)}
                                    {specificCourseDataContainer?.testData?.length != 0 ?
                                        specificCourseDataContainer?.testData?.map((item) => {

                                            return (

                                                <div style={{ backgroundColor: "#fff", width: '48%', height: '130px', marginBottom: 30, border: '1px solid #E6E6E6', borderRadius: '17px' }}>
                                                    <div style={{ cursor: hasMarketingPrivilege ? '' : 'pointer' }} onClick={() => {
                                                        if (!hasMarketingPrivilege) {
                                                            setIndividualTestId(item.id); history.push({
                                                                pathname: "/testSeries/createTest",
                                                                state: {
                                                                    key: {
                                                                        isEdit: "isFromEdit",
                                                                        fromCourseInfoPage: true
                                                                    }
                                                                }
                                                            })
                                                        }

                                                    }}>
                                                        <Tooltip title={item?.title} placement="top">
                                                            <span
                                                                style={{
                                                                    display: 'block',
                                                                    width: '60%',
                                                                    fontWeight: 'bold',
                                                                    marginTop: 20,
                                                                    fontSize: 17,
                                                                    marginLeft: 15,
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                }}
                                                            >
                                                                {item?.title}
                                                            </span>
                                                        </Tooltip>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                                                                <div style={{ color: '#636363', marginTop: 10, fontSize: 13 }}> <QuestionCircleOutlined /> <h style={{ marginLeft: 5 }}>{item?.totalQuestions} Questions</h></div>
                                                                <div style={{ color: '#636363', fontSize: 13 }}> <ProfileOutlined /><h style={{ marginLeft: 9 }}>{item?.totalMarks} Marks</h></div>
                                                                <div style={{ color: '#636363', fontSize: 13 }}><ClockCircleOutlined /><h style={{ marginLeft: 9 }}>{item?.duration} Minutes</h></div>
                                                            </div>
                                                            <div style={{ width: '60%' }}>
                                                                {item?.publishDate && item?.totalQuestions > 0 ?
                                                                    <div style={{ fontSize: 10, color: '#594099', marginLeft: '20px', marginTop: '51px' }}>Publishing on {moment(item?.publishDate).format('DD-MM-YYYY')}, {moment(item?.startTime, 'HH:mm').format('hh:mm A')}</div>
                                                                    :
                                                                    item?.totalQuestions === 0 ?
                                                                        <div style={{ fontSize: 10, color: 'red', marginLeft: '20px', marginTop: '51px' }}>Test creation in progress</div>
                                                                        : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div style={{ width: '100%', height: 'fit-content', backgroundColor: '#fff', padding: 30 }}>
                                            <NodataCard />
                                            {hasMarketingPrivilege ? <></> :
                                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '32%' }}>
                                                    <Button onClick={() => { setIndividualTestId(null); history.push({ pathname: "/testSeries/createTest" }) }} style={{ backgroundColor: "#FFFFFF", marginRight: '20px' }}>ADD</Button>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => {
                                                            setImportMeta("selectedQBType", "sample question papers");
                                                            setImportMeta('selectedTypeId', 1);
                                                            history.push({
                                                                pathname: '/test-import-classes',
                                                                state: {
                                                                    testSeriesData: true
                                                                }
                                                            })
                                                        }}
                                                    ><FileSearchOutlined style={{ marginRight: 5 }} />BROWSE</Button>
                                                </div>
                                            }

                                        </div>
                                    }
                                </div>
                            </div>
                            {/* {coursePlanningArray?.map((item) => (
                            <div style={{ width: "28%" }}>
                                <label style={{ color: "#8C8C8C", fontSize: 12, fontWeight: 600 }}>{item?.label}{" "} </label>
                                <input
                                    type="number"
                                    className="custom-input"
                                    value={coursePlanningData[item.id]}
                                    onChange={(e) => handlePlanningInputChange(e, item.id)}
                                    style={{ color: "#636363", fontSize: 14, backgroundColor: "#FFFFFF" }}
                                    onKeyDown={(e) => handleKeyDown(e, item?.id)}
                                />
                            </div>
                        ))} */}

                        </div>
                    </> :
                    <div className='r-c-sb'>
                        <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>Tests</div>
                        <div style={{ color: "#636363", fontWeight: "normal" ,marginLeft:"44px"}}><span style={{ fontWeight: "bold", color: "#191919" }}>{specificCourseDataContainer?.testSeriesDetails?.totalTestCount}</span>  Tests </div>
                        <div style={{ color: "#636363", fontWeight: "normal" }}><span style={{ fontWeight: "bold", color: "#191919" }}>{specificCourseDataContainer?.testSeriesDetails?.freeTestCount}</span>  Free attempt tests </div>

                        <div className='cursor-pointer show-course-planning-edit' style={{ color: '#636363', fontSize: 14, fontWeight: "bold" }} onClick={() => {
                            setIsCoursePlanningEditClicked(false);
                            setIsTestExpand(true)
                            setIsExamPatern(false)
                            setIsSyllabas(false)
                            setIsViewDetailsClicked(false)

                        }}>   <FiChevronDown style={{ fontSize: 18, marginLeft: 5 }} />
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default CourseInfoTest