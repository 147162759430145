import React from 'react';
import '../styles.css';
import Modal from 'antd/lib/modal';
import 'antd/lib/modal/style/index';

const InstructionModal = ({modalVisible, closeModal, instructions}) => (
  <Modal
    visible={modalVisible}
    onCancel={() => closeModal()}
    onOk={null}
    footer={null}
  >
    <div style={{padding: 20, paddingBottom: 0, fontSize: 24, fontWeight: 600}}>Instructions</div>
    <div style={{padding: 20}} dangerouslySetInnerHTML={{__html: instructions}} />

  </Modal>
);

export default InstructionModal;
