import { CheckOutlined, CloseOutlined, LoadingOutlined, SwapOutlined  } from '@ant-design/icons';
import { Button, Col, Input, PageHeader, Row, Select, Table, Tag, Tooltip } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Color } from 'services';
import {getNewRegistrations, toggleDetailModal, updateUserStatus, togglePaymentModal, updatePendingUserFilterParams, 
    clearPendingUserFilterParams, setFilterParams} from 'actions/user';
import moment from 'moment';
import UserApprovalModal from './modal/userApprovalModal';
//
const {Option} = Select;
const columns = (showDetailModal, approveReq, selectedStatus, detailModalVisible, toggleStatusLoader, selectedIndex, filterPendingUserParams) => {
    let columns = [
        {
            title: <div style={{fontSize: 11, fontWeight: 600, color: Color.darkGrey}}>USER'S DETAILS</div> ,
            key: 'name',
            render: (text, row) => (
                <>
                    <div className="bold-600">
                    <Button type="link" onClick={() => showDetailModal(row)} style={{paddingLeft:0, width : 50, alignItems: 'left'}}>
                    <div style={{
                       textAlign: 'left', width: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                    }}>{row.name}</div></Button> 
                    </div>
                    <div style={{fontSize: 12, color: Color.darkGrey}}>
                        {row.phone}
                    </div>
                </>
            )
        },
        {
            title: <div style={{fontSize: 11, fontWeight: 600, color: Color.darkGrey}}>USER'S ROLE</div> ,
            key: 'name',
            render: (text, row) => (
                <>
                    <div className="bold-600">
                    <Button type="link" onClick={() => showDetailModal(row)} style={{paddingLeft:0}}>{row.role}</Button> 
                    </div>
                </>
            )
        },
        {
            title: <div style={{fontSize: 11, fontWeight: 600, color: Color.darkGrey}}>REQUEST SUBMITTED ON</div> ,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render:  text => <div style={{fontSize: 12}}>{moment(text).format("DD-MM-YY")}</div>
        },
    ]

    if(filterPendingUserParams.status === "Rejected"){
        columns.push( {
            title: <div style={{fontSize: 11, fontWeight: 600, color: Color.darkGrey}}>REQUEST REJECTED ON</div> ,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render:  text => <div style={{fontSize: 12}}>{moment(text).format("DD-MM-YY")}</div>
        })
    }
    // {
    //     title: <div style={{fontSize: 11, fontWeight: 600, color: Color.darkGrey}}>PAYMENT STATUS</div> ,
    //     dataIndex: 'paymentStatus',
    //     key: 'paymentStatus',
    //     render: text => (
    //         text ? <Tag color={text === "Link Sent" ? 'orange' : text === "Paid" ? 'green' : 'gray'}>{text === "Link Sent" ? "Pending" : text}</Tag> : "NA"
    //     )
    // },
    // {
    //     title: <div style={{fontSize: 11, fontWeight: 600, color: Color.darkGrey}}>PAYMENT DETAILS</div> ,
    //     dataIndex: 'paymentDetails',
    //     key: 'paymentDetails',
    // },
    columns.push({
        title: <div style={{fontSize: 11, fontWeight: 600, color: Color.darkGrey}}>ACTIONS</div> ,
        key: 'actions',
        render: (text, row, index) =>  
        (<div>
            {/* <Tooltip title="Send Payment Link">
                <Button className="m-r-5" style={{padding: "0 10px", color: Color.purple, backgroundColor: Color.lightpurple, border: 'none'}} 
                onClick={() => togglePaymentModal(true, row)}>
                    <SwapOutlined style={{fontSize: 10}}/>
                </Button>
            </Tooltip> */}
            <Tooltip title="Approve">
                <Button 
                    className="m-r-5" 
                    style={{padding: "0 10px", color: Color.green, backgroundColor: Color.backgroundGreen, border: 'none'}}
                    onClick={() => approveReq(row, "Approved", index)}
                    // onClick={() => console.log(index)}
                >
                  { toggleStatusLoader && !detailModalVisible && selectedStatus === "Approved" && selectedIndex === index ? <LoadingOutlined /> :  <CheckOutlined style={{fontSize: 10}}/>}
                </Button>
            </Tooltip>
            {filterPendingUserParams.status === "Pending" ?  <Tooltip title="Reject">
                <Button 
                    style={{padding: "0 10px", color: Color.red, backgroundColor: Color.lightorange, border: 'none'}}
                    onClick={() => approveReq(row, "Rejected", index)}
                >
                    { toggleStatusLoader && !detailModalVisible && selectedStatus === "Rejected" && selectedIndex === index ? <LoadingOutlined /> :  <CloseOutlined style={{fontSize: 10}}/>}
                </Button>
            </Tooltip> : null}
        </div> )
    })
    return columns;

}



class UserRequests extends Component {
    constructor(props){
        super(props);
        this.state = {
          selectedStatus: "",
          selectedIndex: null
        }
      }

    componentDidMount(){
        this.props.getNewRegistrations();
        window.onpopstate = ()=> {
            this.props.setFilterParams("Student", "role");
        }
    }

    componentWillUnmount(){
        this.props.clearPendingUserFilterParams();
    }

    showDetailModal = (data) => {
          this.props.toggleDetailModal(true, data);
    }

    pageApproveRequest = (data, status, index) => {
        // status/:userId
        this.props.updateUserStatus(data.id, status, index);
        this.setState({selectedStatus: status, selectedIndex: index});

    
      }

    // onSelectChange = selectedRowKeys => {
    //     console.log('selectedRowKeys changed: ', selectedRowKeys);
    //     this.setState({ selectedRowKeys });
    //   };

    handleSearchChange(val){
        if(val === ""){
            this.props.updatePendingUserFilterParams(null, 'search');
            this.props.getNewRegistrations();
        }else{
            this.props.updatePendingUserFilterParams(val, 'search');
        }
    }

    handlePageChange(page){
        this.props.updatePendingUserFilterParams(page, 'page');
        this.props.getNewRegistrations();
    }

    handleStatusChange(value){
        this.props.updatePendingUserFilterParams(value, 'status');
        this.props.updatePendingUserFilterParams(1, 'page');
        this.props.getNewRegistrations();
    }

    handleBackPress(){
        this.props.setFilterParams("Student", "role");
        this.props.history.goBack()
    }

    render () {
        const {history, pendingUsersLoader, pendingUsersList, pendingUsersPageMeta, detailModalVisible, toggleStatusLoader, togglePaymentModal, filterPendingUserParams} = this.props;
        // const { selectedRowKeys } = this.state;
        // const rowSelection = {
        //     selectedRowKeys,
        //     onChange: this.onSelectChange,
        //     // selections: [
        //     //   Table.SELECTION_ALL
        //     // ],
        //   };

        return (
           <div style={{height: "100%"}}>
               <PageHeader
                    title="User Registration Requests"    
                    onBack={()=> this.handleBackPress()}           
               />
               <Row className="r-jsb m-b-10">
                   <Col span={18}>
                       <Input.Search 
                            style={{width: "200px"}}
                            className="m-r-10"
                            placeholder="Search Name/Phone No."
                            onChange={(e) => this.handleSearchChange(e.target.value)}
                            value={filterPendingUserParams?.search}
                            onSearch={() => this.props.getNewRegistrations()}
                            allowClear
                       />
                       <Select
                            style={{width: "200px"}}
                            placeholder="Select Status..."
                            value={filterPendingUserParams.status}
                            onChange={(value) => this.handleStatusChange(value)}
                       > 
                        <Option value="Pending">Pending</Option>
                        <Option value="Rejected">Rejected</Option>
                       </Select>
                   </Col>
                   {/* <Col span={6}>
                        <div className="r-c-fe">
                            <Button type="primary" shape="round">Send Links</Button>
                        </div>
                   </Col> */}

               </Row>
               <div style={{height: "95%"}}>
                    <Table
                        columns={columns(this.showDetailModal, this.pageApproveRequest, this.state.selectedStatus, detailModalVisible,
                            toggleStatusLoader, this.state.selectedIndex, filterPendingUserParams)}
                        dataSource={pendingUsersList ? pendingUsersList : []}
                        loading={pendingUsersLoader}
                        pagination={{
                            pageSize: 25,
                            size: "small",
                            current: pendingUsersPageMeta ? pendingUsersPageMeta.currentPage : 1,
                            total: pendingUsersPageMeta ? pendingUsersPageMeta.totalItems : 0,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            showSizeChanger: false,
                            onChange: (page) => this.handlePageChange(page)
                        }}
                        scroll={{y: "70vh"}}
                        scrollToFirstRowOnChange={true}
                        // rowSelection={rowSelection}
                    />
               </div>
               <UserApprovalModal />
           </div>
        )
    }
}

const mapStateToProps = state => {
    const {
        pendingUsersLoader, pendingUsersList, pendingUsersPageMeta, detailModalVisible, toggleStatusLoader, filterPendingUserParams
    } = state.user;
    return {
        pendingUsersLoader, pendingUsersList, pendingUsersPageMeta, detailModalVisible, toggleStatusLoader, filterPendingUserParams
    };
  };

  const mapDispatchToProps = dispatch => ({
   
    getNewRegistrations: () => dispatch(getNewRegistrations()),
    toggleDetailModal: (bool, data) => dispatch(toggleDetailModal(bool, data)),
    updateUserStatus: (id, status) => dispatch(updateUserStatus(id, status)),
    togglePaymentModal: (bool, data) => dispatch(togglePaymentModal(bool, data)),
    updatePendingUserFilterParams: (val, key) => dispatch(updatePendingUserFilterParams(val, key)),
    clearPendingUserFilterParams: () => dispatch(clearPendingUserFilterParams()),
    setFilterParams: (value, key) => dispatch(setFilterParams(value, key)),

  });

export default connect(mapStateToProps,mapDispatchToProps)(UserRequests);