import moment from 'moment';
import {
  ORIENTATION_CHANGE,
  DETECT_DEVICE_TYPE,
  TOGGLE_LOCAL_STREAM,
  UPDATE_LOCAL_STREAM,
  UPDATE_DEVICE_SIZE,
  UPDATE_NETWORK_STATE,
  SHOW_SNACK_BAR,
  HIDE_SNACK_BAR,
  TOGGLE_VIDEO,
  TOGGLE_AUDIO,
  TOGGLE_SPEAKER,
  UPDATE_RECONNECT_MODAL,
  UPDATE_APP_STATE,
  UPDATE_BLUETOOTH_HEADSET,
  UPDATE_AUDIOJACK_STATUS,
  SHOW_INFO_MODAL,
  HIDE_INFO_MODAL
} from 'actions/common';

const initialState = {
  appState: false,
  showLocalStream: false,
  localStream: false,
  orientation: 'portrait',
  deviceType: 'phone',
  width: false,
  height: false,
  networkState: false,
  snackMessage: {
    type: 'success',
    text: '',
  },
  showSnackbar: false,
  isVideo: true,
  isAudio: true,
  isSpeaker: true,
  showReconnectModal: false,
  audioJack: true,
  bluetoothHeadset: true,
  offset: new Date().getTimezoneOffset(),
  today: moment(new Date()).format('YYYY-MM-DD'),
  weekday: new Date().getDay(),
  showInfoModal: false,
  infoData: {
    type: 'info',
    text: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BLUETOOTH_HEADSET:
      return {
        ...state,
        bluetoothHeadset: action.value,
      };
    case UPDATE_AUDIOJACK_STATUS:
      return {
        ...state,
        audioJack: action.data.audioJack,
        bluetoothHeadset: action.data.bluetooth,
      };
    case UPDATE_APP_STATE:
      return {
        ...state,
        appState: action.value,
      };
    case UPDATE_RECONNECT_MODAL:
      return {
        ...state,
        showReconnectModal: action.value,
      };
    case TOGGLE_SPEAKER:
      return {
        ...state,
        isSpeaker: action.value,
      };
    case TOGGLE_AUDIO:
      return {
        ...state,
        isAudio: action.value,
      };
    case TOGGLE_VIDEO:
      return {
        ...state,
        isVideo: action.value,
      };
    case HIDE_SNACK_BAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case HIDE_INFO_MODAL:
      return{
        ...state,
        showInfoModal: false
      }
    case SHOW_INFO_MODAL:
      return {
        ...state,
        showInfoModal: true,
        infoData: action.data
      }
    case SHOW_SNACK_BAR:
      return {
        ...state,
        showSnackbar: true,
        snackMessage: action.message,
      };
    case UPDATE_NETWORK_STATE:
      return {
        ...state,
        networkState: action.state,
      };
    case UPDATE_DEVICE_SIZE:
      return {
        ...state,
        width: action.width,
        height: action.height,
      };
    case UPDATE_LOCAL_STREAM:
      return {
        ...state,
        localStream: action.stream,
        showLocalStream: true,
      };
    case TOGGLE_LOCAL_STREAM:
      return {
        ...state,
        showLocalStream: action.value,
      };
    case ORIENTATION_CHANGE:
      return {
        ...state,
        orientation: action.orientation,
      };
    case DETECT_DEVICE_TYPE:
      return {
        ...state,
        deviceType: action.deviceType,
      };
    default:
      return state;
  }
};

export default reducer;
