import React, { useEffect } from "react";
import { Api } from '../../services';
import Button from 'antd/lib/button';
import 'antd/lib/button/style/index';
import ReactQuill from "react-quill";
import { removeImage } from './imageHandler';
import QuillToolbar, { formats, modules, uploadedImageName } from "./toolbar";
import "react-quill/dist/quill.snow.css";
// import katex from 'katex';
// import 'katex/dist/katex.min.css';
// window.katex = katex;
// import "./styles.css";
import './styles.css'
// import { postComment} from "actions/testAdmin";
import { connect } from "react-redux";
import Notification from "services/Notification";
import { isEmpty } from "lodash";





class FormulaEditor extends React.Component {
  static quillRef = null;
  static uploadUrl = "";
  constructor(props) {
    super(props);
    this.state = {
      data: "",
    }
  }

  componentDidMount() {
    FormulaEditor.quillRef ? FormulaEditor.quillRef.getEditor().focus() : null;
    this.setState((state, props) => {
      return { data: this.props.data }
    })
  }

  static getRef = () => {
    return FormulaEditor.quillRef;
  }
  onChange(value, v1, v2, v3) {
    const editorData = FormulaEditor.quillRef ? FormulaEditor.quillRef.getEditorContents() : '';
    const { imagesList, deletedImages, imgPath } = this.props;
    const editorImgs = Array.from(new DOMParser().parseFromString(editorData, 'text/html')
      .querySelectorAll('img'))
      .map(img => img.getAttribute('src'));
    if (editorImgs.length) {
      if (!editorImgs.includes(null)) {
        if (editorImgs.length > imagesList.length) {
          imagesList.push(uploadedImageName)
        } else if (editorImgs.length < imagesList.length) {
          imagesList.forEach((item, index) => {
            const deleteIndex = editorImgs.indexOf(`${Api._base_url}/${this.props.user.orgId}${imgPath}${item}`);
            if (deleteIndex === -1) {
              const deletedImage = imagesList[index];
              removeImage(deletedImage, deletedImages, imagesList, index);
              return
            }
          })
        }
      }
    } else {
      const count = imagesList.length;
      if (count) {
        imagesList.forEach((img, index) =>
          removeImage(img, deletedImages, imagesList, index)
        )
      }
    }
  }
  isEmptyHtml = (content) => {
    let pattern = /^(?:\s|<br>|<p>|<\/p>)*$/;
    return pattern.test(content);
  }
  onSave(type = null, clear) {
    const editorData = FormulaEditor.quillRef.getEditorContents();
    if (type == "comment") {
      let params = {
        id: this.props.postParams.id,
        remark: clear === 'clear' ? '' : this.isEmptyHtml(editorData) ? "" : editorData,
        markedForReview: clear === 'clear' ? false : this.isEmptyHtml(editorData) ? false : true,
        // deleteImages: []
      }
      if (this.isEmptyHtml(this.props.generateCommentText) && (this.isEmptyHtml(editorData))) {
        Notification.error('Error', 'Comment shall not be empty');
        return
      } else {
        this.props.postComment(params, (response) => { });
      }
    }
    this.props.onChange(clear === 'clear' ? '' : editorData)
  }

  onCancel() {
    const editorData = FormulaEditor.quillRef.getEditorContents();
    const { imagesList, deletedImages } = this.props;
    const editorImgs = Array.from(new DOMParser().parseFromString(editorData, 'text/html')
      .querySelectorAll('img'))
      .map(img => img.getAttribute('src').split('/').pop().split('#').shift().split('?').shift());
    const count = editorImgs.length;
    if (count) {
      editorImgs.forEach((img, index) =>
        removeImage(img, deletedImages, imagesList, index)
      )
    }
  }

  render() {
    const { data, id, resetEditor, addOption, disableOptAdd, userType, textEditorNameKey,
      postParams, generateCommentText, selectedCardType, selectedTest, user } = this.props;
    return (
      <div className="text-editor">
        {/* <QuillToolbar/> */}
        <ReactQuill
          selection={{ start: 0, end: 0 }}
          ref={(el) => FormulaEditor.quillRef = el}
          theme="snow"
          value={data}
          onChange={(value, v1, v2, v3) => this.onChange(value, v1, v2, v3)}
          placeholder={"Type here..."}
          modules={modules}
          formats={formats}
          style={{ height: '30vh' }}
          className='admin-test-quill'
          readOnly={textEditorNameKey === "comment" ? (userType === "teacher" || (selectedTest.status === 'Review' && userType === 'admin' && user.id === selectedTest.teacherId && user.id !== selectedTest.authorId)) ? false : true : false}
        />
        {/* <Button style={{ borderRadius: 10, marginRight: 10 }} onClick={() => {
            resetEditor()
            // this.onCancel()
          }}>Cancel</Button> */}
        {console.log('textEditorName', textEditorNameKey)}
        {textEditorNameKey == "comment"
          ?
          // (userType == "teacher" && selectedTest.status != 'Ready') || (selectedTest.status === 'Review' && userType === 'admin' && user.id === selectedTest.teacherId && user.id !== selectedTest.authorId)
          ((userType == "teacher" || userType == 'dataOperator') && selectedTest.status != 'Ready') || ((selectedTest.status === 'Review' || selectedTest.status === 'Feedback') && userType === 'admin') || (user.id === selectedTest.teacherId && user.id !== selectedTest.authorId)
            ?
            <div className="m-t-20 r-c-c" style={{ marginTop: '11%', display: 'flex', justifyContent: 'space-between' }}>
              <Button style={{ borderRadius: 10, marginRight: 10 }} onClick={() => {
                this.onSave("comment", 'clear')
                resetEditor()
              }}>Clear</Button>
              <div>
                <Button style={{ borderRadius: 10, marginRight: 10 }} onClick={() => {
                  resetEditor()
                  // this.onCancel()
                }}>Cancel</Button>
                <Button style={{ borderRadius: 10 }} type="primary" onClick={() => {
                  this.onSave("comment")
                  resetEditor()
                }}>Save & Close</Button>
              </div>
            </div>
            : null
          :
          <div className="m-t-20 r-c-c" style={{ marginTop: '11%' }}>
            <Button style={{ borderRadius: 10, marginRight: 10 }} onClick={() => {
              resetEditor()
              // this.onCancel()
            }}>Cancel</Button>
            <Button style={{ borderRadius: 10 }} type="primary" onClick={() => {
              this.onSave()
              resetEditor()
            }}>Save & Close
            </Button>
          </div>
        }
      </div>
    );
  }

};

const mapStateToProps = state => {
  const { user } = state.session;
  return { user };
};

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormulaEditor);
