import { ArrowLeftOutlined, CaretDownOutlined } from '@ant-design/icons'
import { Col, Dropdown, Input, Menu, Row } from 'antd'
import { Heading, SmallHeading, SubHeading } from 'components/Typography'
import { EditImage, ImageComponent } from 'pages/profile/subComponents'
import React, { useEffect, useRef, useState } from 'react'
import Carousel from 'react-multi-carousel'
import carouselData from './carouselData'
import CarouselData from './carouselData'
import SignInForm from './signInForm'
import "./styles.css"
import { IMG_URL } from "../../env.json";
import { connect } from 'react-redux'
import { deleteLogo } from 'actions/profileNew'
import { getOrgDetails, getOrgsStandardList, postRegisterDetails, setShowDownloadAppModal, setShowRegisterForm } from 'actions/loginNew'
import RegisterForm from './registerForm'
import DownloadAppModal from 'pages/studentMarketPlace/downloadAppModal'

const appStore = require("Assets/applestore.png").default;
const playStore = require("Assets/playstore.png").default;
const defaultImg = require("../../Assets/Icon awesome-user-alt.svg").default

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1
  }
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide, deviceType }
  } = rest;
  return (
    <div className="">
      <div className="r-c-c m-t-10">
        <div
          className="carousel-indicator m-r-10"
          onClick={() => goToSlide(2)}
          style={{ background: currentSlide === 2 ? "#594099" : "#E6E6E6" }}
        ></div>
        <div
          className="carousel-indicator m-r-10"
          onClick={() => goToSlide(3)}
          style={{ background: currentSlide === 3 ? "#594099" : "#E6E6E6" }}
        ></div>
        <div
          className="carousel-indicator m-r-10"
          onClick={() => goToSlide(4)}
          style={{ background: currentSlide === 4 ? "#594099" : "#E6E6E6" }}
        ></div>
        <div
          className="carousel-indicator m-r-10"
          onClick={() => goToSlide(5)}
          style={{ background: currentSlide === 5 ? "#594099" : "#E6E6E6" }}
        ></div>
      </div>
    </div>
  );
};

function LoginNew({ showRegisterForm, orgData, getOrgDetails, setShowRegisterForm, showDownloadAppModal, setShowDownloadAppModal, userMarketPlaceHomeData }) {
  const [userRegister, setUserRegister] = useState({
    role: "",
    name: "",
    email: null,
    preferredClass: "",
    preferredCourseId: "",
    image: defaultImg
  });
  const [showVerifyOtpForm, setShowVerifyOtpForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isEditClicked, setIsEditClicked] = useState(false)

  useEffect(() => {
    setShowRegisterForm(false);
    getOrgDetails();
  }, [])

  useEffect(() => {
    document.body.classList.add('login-page');
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);
  return (
    <div className='aulas-login__body-wrap login-page'>
      <div style={{ height: "100%", width: "100%", display: "flex" }}>
        <div className='login-carousel-parent' >
          <Carousel
            infinite
            pauseOnHover={false}
            responsive={responsive}
            arrows={false}
            renderButtonGroupOutside={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            customButtonGroup={<ButtonGroup />}
          >
            {carouselData.map((data, index) => (
              <div className="text-center" key={index}>
                <div className="r-c-c-c">
                  <Col xs={12}>
                    <img src={data.image} alt={data.title} style={{ maxWidth: "100%", marginTop: 150, height: 350, width: 350 }} />
                  </Col>
                </div>
                <div style={{}}>
                  <Col flex="auto">
                    <Heading bold>{data.title}</Heading>
                    <SubHeading color="#636363">{data.description}</SubHeading>
                  </Col>
                </div>
              </div>
            ))}
          </Carousel>
          <div style={{ display: "flex", justifyContent: "space-between", padding: "160px 20px 20px 20px" }}>
            <a href='https://www.aulas.in/' target='_blank()' /* className='m-l-20 p-t-10 p-b-10 blue' */>www.aulas.in</a>
            <div className='display-flex'>
              <div>Login using aulas app</div>
              <a href='https://apps.apple.com/in/app/aulas/id1564724183' target='_blank()' className='cursor-pointer m-l-20 p-b-10'>
                <img src={appStore} alt="aulas App Store" style={{ maxWidth: "80px" }} />
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.classroom.aulas' target='_blank()' className='cursor-pointer m-l-20 p-b-10'>
                <img src={playStore} alt="aulas Play Store" style={{ maxWidth: "80px" }} />
              </a>
            </div>
          </div>
        </div>
        <div className='login-child2-parent' >
          {showRegisterForm ?
            <div>
              <RegisterForm
                userRegister={userRegister}
                setUserRegister={setUserRegister}
                setShowVerifyOtpForm={setShowVerifyOtpForm}
                isEditClicked={isEditClicked}
              />
            </div>
            :
            <>
              <Row justify="center">
                <Col className='login-logo-parent' flex="auto" align="center">
                  <img className="institute-logo login-inst-logo" style={{ borderRadius: 10 }} src={orgData ? `${IMG_URL}/${orgData.logo}` : require("Assets/noinstitute.png").default} />
                  {orgData ? <Heading bold style={{ margin: 0, marginTop: 5 }}>{orgData.title}</Heading> : null}
                  {orgData ? <SubHeading color="#636363" className='login-subHeading-div'>Institute ID : {orgData.instituteId}</SubHeading> : null}
                </Col>
              </Row>
              <SignInForm
                setShowVerifyOtpForm={setShowVerifyOtpForm}
                showVerifyOtpForm={showVerifyOtpForm}
                userRegister={userRegister}
                setShowRegisterForm={setShowRegisterForm}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                setUserRegister={setUserRegister}
                setIsEditClicked={setIsEditClicked}
              />
            </>
          }
          <div className={`login-footer-parent ${userRegister?.role == 'Student' ? "login-footer-padding1" : (showRegisterForm && userRegister?.role != 'Student') ? "login-footer-padding2" : "login-footer-padding3"}`} style={{ display: "flex", justifyContent: "space-between", /* paddingTop: userRegister?.role == 'Student' ? 18 : (showRegisterForm && userRegister?.role != 'Student') ? 100 : 60  */ }}>
            <a href={`https://${orgData ? orgData.website : ''}`} target='_blank()'>{orgData ? orgData.website : ''}</a>
            {orgData && (orgData.playstoreLink === '' || orgData.playstoreLink === null)
              ?
              null
              :
              <div className='r-ac'>
                <div className='m-l-20'>Download {orgData ? orgData.title : ''} app</div>
                <a href={orgData ? orgData.playstoreLink : '/'} target='_blank()' className='cursor-pointer m-l-20'>
                  <img src={playStore} alt="aulas Play Store" style={{ maxWidth: "80px" }} />
                </a>
              </div>
            }
          </div>
        </div>
      </div>
      {showDownloadAppModal &&
        <DownloadAppModal
          showDownloadAppModal={showDownloadAppModal}
          setShowDownloadAppModal={setShowDownloadAppModal}
          userMarketPlaceHomeData={userMarketPlaceHomeData}
          fromLoginPage={true}
        />}
    </div>
  )
}
const mapStateToProps = (state) => {
  const { showRegisterForm, orgData, showDownloadAppModal } = state.loginNew;
  const { userMarketPlaceHomeData } = state.studentMarketPlace;
  return {
    showRegisterForm, orgData, showDownloadAppModal, userMarketPlaceHomeData
  }
}

const mapDispatchToProps = (dispatch) => ({
  getOrgDetails: () => dispatch(getOrgDetails()),
  setShowRegisterForm: (bool) => dispatch(setShowRegisterForm(bool)),
  setShowDownloadAppModal: (val) => dispatch(setShowDownloadAppModal(val))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginNew);
// export default LoginNew