import { Button, Modal } from 'antd';
import { Heading, SubHeading } from 'components/Typography';
import React, { useState } from 'react'
import '../styles.css'

function VerifyOtpModal({ verfiyOtpModal, setVerfiyOtpModal, phoneNumber, VerifyKycOtp, GenerateKycOtp, resendOtpTimer, timer }) {
  const [otp, setOtp] = useState();
  const [verifyLoader, setVerifyLoader] = useState(false);
  const [resendLoader, setResendLoader] = useState(false);
  const handleKeyDown = (event) => {
    if (event.key.toLowerCase() === 'e') {
      event.preventDefault();
      return;
    }
  };
  return (
    <div>
      <Modal
        centered
        closable={true}
        footer={null}
        maskClosable={false}
        visible={verfiyOtpModal}
        className="verifyOtpModal"
        onCancel={() => {
          setOtp('');
          setVerfiyOtpModal(false);
        }}
      >
        <Heading bold className='r-c-c m-t-10'>Verify Your Number</Heading>
        <SubHeading color="#636363" className='r-c-c m-t-10'>Please enter the verification code we’ve sent you on</SubHeading>
        <div className='r-c-c m-t-5'>
          <SubHeading bold color="#636363">{phoneNumber}</SubHeading>
          <Button onClick={() => {
            setOtp('');
            setVerfiyOtpModal(false);
          }} type="text" className="bold-bold"
            style={{ color: '#1089FF' }} >
            Edit
          </Button>
        </div>
        <div style={{ position: "relative", marginTop: 60 }} className="r-c-c">
          <div className="r-c-c" style={{ fontWeight: 700 }}>
            <div className="otpInputNew">{otp?.[0] ? "*" : null}</div>
            <div className="otpInputNew">{otp?.[1] ? "*" : null}</div>
            <div className="otpInputNew">{otp?.[2] ? "*" : null}</div>
            <div className="otpInputNew">{otp?.[3] ? "*" : null}</div>
            <div className="otpInputNew">{otp?.[4] ? "*" : null}</div>
            <div className="otpInputNew">{otp?.[5] ? "*" : null}</div>
          </div>
          <input
            type="number"
            className='aulas-login__input-verify-otp'
            value={otp}
            onChange={(em) => { setOtp(em.target.value.substring(0, 6)) }}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="m-b-30 r-c-c" style={{ marginTop: 50 }}>
          <Button
            disabled={!otp || otp?.length < 6}
            type="primary"
            shape="round"
            className="r-c-c bold-bold"
            style={{ padding: 20, width: "40%" }}
            onClick={() => { setVerifyLoader(true); VerifyKycOtp(phoneNumber, otp, () => { setVerifyLoader(false) }, () => { setVerfiyOtpModal(false) }) }}
            loading={verifyLoader}
          >
            VERIFY
          </Button>
        </div>

        <div className='r-c-c' style={{ marginLeft: 40 }}>
          <SubHeading color="#636363">Didn’t receive a code?</SubHeading>
          <Button type="text"
            className="bold-bold"
            style={{ color: resendOtpTimer ? '#1089FF' : '#28DF99', width: 200, marginLeft: -30 }}
            onClick={() => {
              setResendLoader(true);
              GenerateKycOtp(phoneNumber, () => setResendLoader(false))
            }}
            loading={resendLoader}
            disabled={verifyLoader || resendOtpTimer}
          >
            Resend OTP {timer == '' ? '' : `in ${timer}`}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default VerifyOtpModal