import React, { useEffect, useState } from 'react'

import "./saveForLater.css"

import { Button } from 'components/Button'
import arrowUpIcon from "../../Assets/aulasMarket/ic-chevron-up.svg"
import arrowDownIcon from "../../Assets/aulasMarket/ic-chevron-down.svg"

import "./studentCart.css"

import { Input } from 'antd'
import { applyPromo, getCheckPayment, payNowCart, postCartPayment, setCouponInCart } from 'actions/studentMarketPlace'
import { connect } from 'react-redux'
import moment from 'moment';
import { generatePaymentRequest } from 'pages/profile/paymentGateway'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation } from 'react-router-dom';




function MobilePriceDetails({ cartList, getCheckPayment, couponList, postCartPayment, checkLiveCourse, selectedCouponCode, applyPromo, payNowCart, setCouponInCart, user, couponInCart, storePromoPrice }) {
    const [isDetailsVisible, setDetailsVisible] = useState(false);
    const [disableHeader, setHeaderdisable] = useState(false);
    const [applyLoader, toggleApplyLoader] = useState(false);
    const [paynowLoader, togglePaynowLoader] = useState(false);
    const location = useLocation();
    const [params, setParams] = useState({
        amount: cartList?.cart?.totalAmount || 0,
        code: location?.state?.code || '',
    });

    useEffect(() => {
        setParams((prev) => ({
            ...prev,
            amount: cartList?.cart?.totalAmount,
            code: location?.state?.code
        }))
    }, [location?.state])

    const history = useHistory();
    const numberWithCommas = (x) => {
        return x?.toString().split('.')[0].length > 3 ? x?.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x?.toString().substring(x.toString().split('.')[0].length - 3) : x?.toString();
    }
    const generateOrderId = user?.orgId + user?.id + Math.floor(Math.random() * 100) + moment().format('SS');
    const fromMarket = true;
    const totalAmount = storePromoPrice?.cart?.amount - storePromoPrice?.cart?.discount;
    const totalAmountBeforeDiscount = cartList?.cart?.amount - cartList?.cart?.discount;


    console.log("insideEffectcouponcodesss", params)

    const toggleDetails = () => {
        setDetailsVisible((prev) => !prev);
        setHeaderdisable(!disableHeader);
    };
    const handleApplyClick = () => {
        let paramsOffer = {
            code: params?.code,
            type: 'offer',

        };
        toggleApplyLoader(true);
        applyPromo(paramsOffer, (res) => {
            setParams((prev) => ({
                ...prev,
                amount: (res?.cart?.amount - res?.cart?.discount).toFixed(2),
            }))
            toggleApplyLoader(false);
        }, (res) => {
            setCouponInCart(true);
            toggleApplyLoader(false)
        });
    }

    const checkDiffTotalAmt = couponInCart ? (storePromoPrice?.cart?.amount - storePromoPrice?.cart?.discount)?.toFixed(2) : (cartList?.cart?.amount - cartList?.cart?.discount)?.toFixed(2) || null

    console.log("checkDiffTotalAmt11mobile", checkDiffTotalAmt)
    const handlePayNow = () => {

        const isLiveParam = {
            userId: cartList?.cart?.userId,
            userAccessToken: user?.accessToken
        }
        togglePaynowLoader(true)
        let paramsPay = {
            orderAmount: cartList?.cart?.totalAmount || null,
            orderCurrency: 'INR',
            orderId: generateOrderId.toString() || null,
            customerDetails: {
                customer_id: user?.id?.toString() || null,
                customer_email: user?.email || null,
                customer_phone: user?.phone?.toString() || null
                // customer_phone: '+916360068158'
            }
        };

        if (checkDiffTotalAmt == 0) {
            console.log("cartoutttt", isLiveParam)
            let paramProcess = {
                code: params?.code,
                free: true
            }

            postCartPayment(paramProcess, (res) => {
                if (res) {
                    if (cartList?.cart?.hasLiveCourse) {
                        console.log("livecourseeethere")
                        checkLiveCourse(isLiveParam, () => {
                            history.push("/home")
                            setRenderPage(!renderPageLoading)
                        });
                    }
                }
            })
        } else {
            getCheckPayment(params, (res) => {
                if (res?.valid) {
                    payNowCart(paramsPay, (response) => {
                        togglePaynowLoader(false)
                        generatePaymentRequest(response, null, user, fromMarket)
                        togglePaynowLoader(false)
                    })
                }
            })
        }


    }
    const handleInputPromo = (e) => {
        const filteredValue = e.target.value.replace(/[^A-Za-z0-9]/g, ''); // Allow only alphanumeric characters
        setParams((prev) => ({
            ...prev,
            code: filteredValue
        }))
    }
    const [isCourseInCart, setIsCourseInCart] = useState(false);
    let foundCourse = false;
    useEffect(() => {
        if (Array.isArray(cartList?.cart?.items)) {

            for (const item of cartList?.cart?.items) {
                if (item?.itemType === "course") {
                    foundCourse = true;
                    break; // Exit the loop once a course is found
                }
            }

            setIsCourseInCart(foundCourse);
        }
    }, [cartList]);
    return (
        <div className='mobile-price-parent'>
            <div className='mobile-price-header' >PRICE DETAILS</div>
            <div className='mobile-price-details-container'>
                <div className='r-c-sb' style={{ fontSize: 12, fontWeight: 'bold' }}>
                    <span style={{ width: "100%", padding: 10 }} className={disableHeader ? "mobile-disable" : ""}>Total payable amount</span>
                    <span style={{ width: "100%", padding: 10 }} onClick={toggleDetails} className='r-c-fe'>
                        <span className={disableHeader ? "mobile-disable" : ""}> ₹{cartList?.cart?.totalAmount}</span>
                        <img className='m-l-10' src={isDetailsVisible ? arrowUpIcon : arrowDownIcon} alt="Arrow Icon" />
                    </span>
                </div>
                {isDetailsVisible && (
                    <div className='price-details'>
                        <div className='cart-price' style={{ padding: 10, fontSize: 12 }}>Price
                            <span>₹{cartList?.cart?.exAmount}</span>
                        </div>
                        <div className='cart-discount' style={{ padding: 10, marginTop: "-15px", fontSize: 12 }} >Discount
                            <span style={{ color: "#28DF99" }}>₹{couponInCart ? numberWithCommas(storePromoPrice?.cart?.discount?.toFixed(2)) : numberWithCommas(cartList?.cart?.discount?.toFixed(2))}</span>
                        </div>
                        {cartList?.cart?.isGSTEnabled ? <>
                            <div className='r-c-sb cart-price m-b-10' style={{ padding: 10, marginTop: "0px", fontSize: 12 }} >
                                <div>GST</div>
                                <div>₹{cartList?.cart?.gst}</div>
                            </div>
                        </> : <></>}
                        <div className='price-border'></div>
                        <div className='cart-total-amount' style={{ padding: 10, marginTop: "0px", fontSize: 12 }}>Total amount
                            <span>₹{cartList?.cart?.totalAmount}</span>
                        </div>
                        <div className='price-border'></div>
                        {cartList?.cart?.discount == 0 || storePromoPrice?.cart?.discount == 0 ? <></> : (<>
                            <div className='price-border'></div>
                            <div className='cart-diffrence-amount'>
                                You will save ₹
                                <span>{couponInCart ? numberWithCommas(storePromoPrice?.cart?.discount?.toFixed(2)) : numberWithCommas(cartList?.cart?.discount?.toFixed(2))}</span>
                                on this purchase
                            </div>
                        </>)}
                        <div className='price-border'></div>
                        {/* <div className='price-coupon-field' style={{ padding: "10px" }}>
                            {couponInCart ?
                                <div className='r-c-sb' style={{ alignItems: "flex-start" }}>
                                    <div className='r-c-c'><img style={{ marginTop: -20 }} src={require("../../Assets/aulasMarket/ic-check-filled.svg").default} />
                                        <div className='r-sfa m-l-10'>
                                            <div style={{ color: "#191919", fontSize: 13, fontWeight: "600" }}>You saved ₹{numberWithCommas(storePromoPrice?.cart?.discount?.toFixed(2))} with this code</div>
                                            <div style={{ color: "#AEAEAE", fontSize: 12 }}>Coupon Applied</div>
                                        </div>
                                    </div>
                                    <div style={{ color: '#FF414D', cursor: 'pointer', marginLeft: 5, fontWeight: "500" }} onClick={() => { setCouponInCart(false); setParams((prev) => ({ ...prev, code: '' })) }}>REMOVE</div>
                                   

                                </div>
                                :
                                <div className=' r-c-c' style={{ display: 'flex' }}>
                                    <Input
                                        className='price-coupon-filed'
                                        type="text"
                                        placeholder='Enter coupon code'
                                        onChange={(e) => handleInputPromo(e)}
                                        value={params?.code}

                                    ></Input>
                                    <Button
                                        loading={applyLoader}
                                        className='r-c-c text-xm bold-600'
                                        type="button"
                                        onClick={handleApplyClick}
                                        style={{
                                            cursor: 'pointer',
                                            border: '1px solid #594099',
                                            borderRadius: " 0px 4px 4px 0px",
                                            backgroundColor: 'white',
                                            padding: '5px 10px',
                                            color: "#594099",
                                            height: "6vh",
                                            opacity: 0.4
                                        }}
                                    >
                                        APPLY
                                    </Button>
                                </div>
                            }
                            {couponList?.items?.length > 0 ?
                                <div onClick={() => { history.push("/apply-coupon") }} style={{ fontSize: "12px", fontWeight: "bold", fontWeight: "bold", color: "#1089FF", cursor: "pointer", marginTop: 10 }}>VIEW ALL COUPONS</div>
                                : <></>}
                            {isCourseInCart ?
                                <div style={{ fontSize: 12, marginTop: 10 }}>
                                    For the live courses in the cart only the registration fee will be charged, the total fees will be collected as per the fee plan
                                </div>
                                : <></>}       
                            </div> */}
                    </div>
                )}
                <div style={{ marginTop: 10 }}>
                    <Button
                        style={{ width: "100%" }}
                        type="primary"
                        loading={paynowLoader}
                        onClick={() => handlePayNow()}
                    >
                        {!couponInCart ? (totalAmountBeforeDiscount === 0 ? "ENROLL NOW" : "PAY NOW") : (totalAmount === 0 ? "ENROLL NOW" : "PAY NOW")}
                    </Button>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const { renderPageLoading, couponInCart, couponList, storePromoPrice, selectedCouponCode } = state.studentMarketPlace
    const { user } = state.session
    return { user, couponInCart, storePromoPrice, couponList, renderPageLoading, selectedCouponCode }
};

const mapDispatchToProps = (dispatch) => ({
    checkLiveCourse: (param) => dispatch(checkLiveCourse(param)),
    setCouponInCart: res => dispatch(setCouponInCart(res)),
    applyPromo: (params, callback, succesCallback) => dispatch(applyPromo(params, callback, succesCallback)),
    payNowCart: (params, callback) => dispatch(payNowCart(params, callback)),
    getCheckPayment: (param, callback) => dispatch(getCheckPayment(param, callback)),
    postCartPayment: (param, callback) => dispatch(postCartPayment(param, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobilePriceDetails)
