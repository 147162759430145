import React, { useEffect, useState } from 'react'
import { Row, Col, Image, Typography } from 'antd'
import lectureNotesImg from "../../../Assets/digitalLibrary/ic-dl-lecture-notes.svg"
import videoImg from "../../../Assets/digitalLibrary/ic-dl-lecture-videos.svg"
import quesBankImg from "../../../Assets/digitalLibrary/ic-dl-lecture-question-banks.svg"
import { connect } from 'react-redux'
import SubjectCard from '../components/subjectCard'
import LibraryCardDetails from '../components/LibraryCards_details'
import { fetchClassLibraryData, updateCurrentSub, updateCurrentStd, updateCurrentIcon, updatepdf, fetchTeacherHomeDataSummary, fetchStudentHome } from 'actions/digitalLibrary'
import { useHistory } from 'react-router-dom'
import PageDataLoader from 'components/pageDataLoader'
import TeachAndStudNotes from '../components/teacherAndStudentNotes'
import TeachAndStudVideo from '../components/teachAndStudVideo'
import emptyimage from "../../../Assets/digitalLibrary/ill-no-classes-assigned.svg"
import { Heading } from 'components/Typography'
import PageHeader from 'components/pageHeader'
import { InputSearch } from 'components/customInputs'
import { IMG_URL } from "env.json";
import image1 from '../../../Assets/care/no-subjects.svg'

import BroadcastCard from '../components/broadcastCard'
import RecordingsModal from 'pages/broadcast/localComponent/recordingsModal'


const lectureNotesIcon = require('Assets/digitalLibrary/lectureNotesIcon.svg').default;
const videosIcon = require('Assets/digitalLibrary/videosIcon.svg').default;
const quesBankIcon = require('Assets/digitalLibrary/quesBankIcon.svg').default;

const ClassCard = ({ item, updateCurrentStd, history, fetchStudentHome,setStdId}) => {
    console.log(item,'hello' )
    return (
        // <div onClick={() => { fetchStudentHome({ standardId: item?.standardId }, () => { }); history.push('/digital-library/division-card'); updateCurrentStd(item?.standardId) }} className='boxShadow118 cursor-pointer m-b-20' style={{ height: 160, width: 240, borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <div onClick={() => { fetchStudentHome({ standardId: item?.standardId }, () => { }); history.push('/digital-library/division-card'); updateCurrentStd(item?.standardId); setStdId(item?.standardId) }} className='boxShadow118 cursor-pointer m-b-20' style={{ height: 160, width: 240, borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

            <div style={{ color: '#594099', fontSize: 40 }}>{item?.sectionName || 'NA'}</div>
            <div style={{ color: '#8C8C8C', fontSize: 12 }}>{item?.courseName || 'NA'}</div>
            <div className='m-t-20' style={{ width: '80%', display: 'flex', justifyContent: 'space-between' }}>
                <div className='display-flex'>
                    <img src={lectureNotesIcon} style={{ width: 20, height: 20 }} alt="Lecture notes"></img>
                    <div>{item?.notesCount}</div>
                </div>
                <div className='display-flex'>
                    <img src={videosIcon} style={{ width: 20, height: 20 }} alt="Videos"></img>
                    <div className='m-l-5'>{item?.videoCount}</div>
                </div>
                <div className='display-flex'>
                    <img src={quesBankIcon} style={{ width: 20, height: 20 }} alt="Question Bank"></img>
                    <div>{item?.quesBankCount}</div>
                </div>
            </div>
        </div>
    );
};

const StdudentHome = ({ classLibraryList, classSubjectList, showLibrary, getStudentHome, subjectAssigned, fetchStudentHome, updateCurrentStd }) => {

    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [isRecordingsModalVisible, SetIsRecordingsModalVisible] = useState(false)
    const [isStdId, setStdId] = useState(null)



    useEffect(() => {
        setLoader(true)
        fetchStudentHome(null, () => { setLoader(false) });
    }, [])


    const OverviewData = (item) => [
        {
            image: lectureNotesImg,
            count: item?.totalCount?.notesCount ?? 0,
            title: 'Notes'
        },
        {
            image: videoImg,
            count: item?.totalCount?.videoCount ?? 0,
            title: 'Videos'
        },
        {
            image: quesBankImg,
            count: item?.totalCount?.quesBankCount ?? 0,
            title: 'Test papers'
        },
    ]

    const OverViewCard = (item) => {
        return (
            <div className='boxShadow118 cursor-pointer p-20' style={{ width: 350, height: 100, borderRadius: 8, backgroundColor: 'white' }}>
                <Row>
                    <Col>
                        <img src={item?.item?.image} style={{ width: 60, height: 60 }} />
                    </Col>
                    <Col style={{ marginLeft: 10 }}>
                        <div className='simGrey bold-600 text-md'>{item?.item?.count}</div>
                        <div className='simGrey'>{item?.item?.title}</div>
                    </Col>
                </Row>
            </div>
        )
    }


    const handleBroadcast = () => {
        history.push('/digital-library/broadcast-recordings')
        if (getStudentHome?.multiStandard == true) {
            updateCurrentStd(isStdId)
        } else {
            updateCurrentStd(getStudentHome?.standardId)
        }
    }

    // const SubjectCard = (item) => {

    //     return (
    //         <div onClick={() => { history.push('/digital-library/subject-details') }} className='boxShadow118 cursor-pointer m-b-20' style={{ height: 180, width: 240, borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
    //             <img src={`${IMG_URL}/${item?.item?.path}`} style={{ width: 50, height: 50 }} />
    //             <div style={{ color: '#594099', fontSize: 15 }}>{item?.item?.subjectName || 'NA'}</div>
    //             <div style={{ color: '#8C8C8C', fontSize: 12 }}>{item?.item?.chapterCount ?? 0} chapters</div>
    //             <div className='m-t-20' style={{ width: '80%', display: 'flex', justifyContent: 'space-between' }}>
    //                 <div className='display-flex'>
    //                     <img src={lectureNotesIcon} style={{ width: 20, height: 20 }} alt="Lecture Notes"></img>
    //                     <div>{item?.item?.notesCount}</div>
    //                 </div>
    //                 <div className='display-flex'>
    //                     <img src={videosIcon} style={{ width: 20, height: 20 }} alt="Videos"></img>
    //                     <div className='m-l-5'>{item?.item?.videoCount}</div>
    //                 </div>
    //                 <div className='display-flex'>
    //                     <img src={quesBankIcon} style={{ width: 20, height: 20 }} alt="Question Bank"></img>
    //                     <div>{item?.item?.quesBankCount}</div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    const bodyStyle = {
        background: "#FFFFFF",
        width: "calc(100% + 30px)",
        height: "calc(100% + 40px)",
        margin: -20,
        padding: 20,
        overflow: "auto",
    };

   
    return (
        <div style={bodyStyle}>
            {getStudentHome?.multiStandard == true ? 
            <div><PageHeader title={'Library'} /></div>:<div><PageHeader title={'Library'} onBackPress={() => {history.goBack(),
        fetchStudentHome(null, () => { setLoader(false) });
    }}/></div>}
            <div className='m-t-50'>
                <Heading>Overall stats</Heading>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {OverviewData(getStudentHome)?.map((item => {
                        return <div style={{}}><OverViewCard item={item} /></div>
                    }))}
                </div>
            </div>
            {getStudentHome?.multiStandard == true ? (
                <>
                    <Row className='m-t-50' style={{ justifyContent: 'space-between' }}>
                        <Heading>All classes</Heading>
                        {/* <InputSearch /> */}
                    </Row>
                    {getStudentHome?.standards && getStudentHome?.standards?.length > 0 ? (
                        getStudentHome?.standards?.map((item) => (
                            <div key={item.standardName}>
                                <h2 className="userCard-heading m-t-10" style={{ fontSize: "14px" }}>{item.standardName}</h2>
                                <div className="r-c-fs flex-wrap">
                                    {item ? <ClassCard item={item} updateCurrentStd={updateCurrentStd} history={history} fetchStudentHome={fetchStudentHome} setStdId={setStdId}/> : null}
                                </div>
                            </div>
                        ))
                    ) : null}
                </>
            ) : <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {console.log("classInfo?.allBroadcastsCount12",getStudentHome ,getStudentHome?.allBroadcastsCount)}

                    <Heading className='m-t-20 m-l-10'>Live class recordings{" "}{"("}{getStudentHome?.allBroadcastsCount}{")"}</Heading>
                    {getStudentHome?.allBroadcasts != 0 ? <Heading onClick={() => { handleBroadcast() }} style={{ color: "#1089FF", fontSize: "14px", fontWeight: "bold", marginTop: "30px", cursor: 'pointer' }}>VIEW ALL</Heading>: null }

                </div>
                {getStudentHome?.allBroadcasts?.length != 0 ? 
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between' }}>
                    {getStudentHome?.allBroadcasts?.map((item) => (
                        <div  >
                            <BroadcastCard
                                subjectName={item?.subject?.name}
                                teacherName={item?.teacher?.name}
                                date={item?.date}
                                startTime={item?.startTime}
                                endTime={item?.endTime}
                                image={item?.subject?.icon}
                                streamId={item?.roomName}
                                isRecordingsModalVisible={isRecordingsModalVisible}
                                SetIsRecordingsModalVisible={SetIsRecordingsModalVisible}
                                item={item}
                            />
                        </div>
                    ))}
                </div>:
                
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <img style={{ width: "10%", height: "10%", marginBottom: "20px" }} src={image1} alt="" />
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
                    <p style={{ color: "#636363", fontFamily: "roboto", textAlign: "center" }}>There are no data to show yet. Please try later.</p>
                </div>
            </div>}
                <Heading className='m-t-50'>Subject(s)</Heading>
                {/* <div style={{display: "flex", flexWrap: "wrap" }}>
                    {getStudentHome ? getStudentHome?.subjects?.map((item, index) => {
                        return <SubjectCard title={item?.subjectName} filesNO={item?.chapterCount} images={item?.path} index={index} classSubjectList={classSubjectList} item={item} />
                    }) : null}
                </div> */}
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {getStudentHome?.subjects?.map((item, index) => (
                        <div style={{ margin: "7px" }}>
                           <SubjectCard title={item?.subjectName} filesNO={item?.chapterCount} images={item?.path} index={index} classSubjectList={classSubjectList} item={item} />
                        </div>
                    ))}
                </div>
            </div>}
            <RecordingsModal
                isRecordingsModalVisible={isRecordingsModalVisible}
                setRecordingsModalVisibility={SetIsRecordingsModalVisible}

            />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { getStudentHome, classSubjectList } = state.digitalLibrary;
    const { user } = state.session;
    return { user, getStudentHome, classSubjectList };
};

const mapDispatchToProps = (dispatch) => ({
    fetchClassLibraryData: (callback) => dispatch(fetchClassLibraryData(callback)),
    updateCurrentStd: (data) => dispatch(updateCurrentStd(data)),
    updateCurrentIcon: (icon) => dispatch(updateCurrentIcon(icon)),
    updatepdf: (data) => dispatch(updatepdf(data)),
    updateCurrentSub: (data) => dispatch(updateCurrentSub(data)),
    fetchTeacherHomeDataSummary: (id, callback) => dispatch(fetchTeacherHomeDataSummary(id, callback)),
    fetchStudentHome: (params, callback) => dispatch(fetchStudentHome(params, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(StdudentHome);

{/* <div> */ }
        //     {showLibrary ?
        //         <div>
        //             <div>
        //                 <div className='r-c-sb'>
        //                     <div style={{ width: "10%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        //                         <PageHeader
        //                             style={{ padding: '0px' }}
        //                             title={<div className="text-md" style={{ fontWeight: 'bold', fontSize: "10px !important", height: '50px' }}>Digital Library</div>}
        //                         />
        //                     </div>
        //                 </div>

        //                 {classLibraryList?.length != 0 ? classLibraryList?.map((item, index) => (
        //                     <div>
        //                         <Row >
        //                             <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
        //                                 <div className="m-r-10 m-t-10">
        //                                     <LibraryCardDetails title={"Lecture Notes"} filesNO={item?.notesCount ? item?.notesCount : 0} images={lecturenotes} lastWeek={getStudentHome[0]?.lastWeekLectureNotesCount} />
        //                                 </div>
        //                             </Col>
        //                             <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
        //                                 <div className="m-r-10 m-t-10">
        //                                     <LibraryCardDetails title={"Videos"} filesNO={item?.videoCount ? item?.videoCount : 0} images={videoImg} lastWeek={getStudentHome[0]?.lastWeekVideoCount} />
        //                                 </div>
        //                             </Col>
        //                             <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
        //                                 <div className="m-r-10 m-t-10">
        //                                     <LibraryCardDetails title={"Question Banks"} filesNO={item?.quesBankCount ? item?.quesBankCount : 0} images={questBankImg} lastWeek={getStudentHome[0]?.lastWeekQuestionBankCount} />
        //                                 </div>
        //                             </Col>
        //                         </Row>

        //                         {getStudentHome[0]?.lectureNotes?.length != 0 ?
        //                             <div className="m-t-10" style={{ fontWeight: "bold", fontSize: "25px" }}>Recently Added Lecture Notes</div> : null}
        //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start" }}>
        //                             {getStudentHome[0]?.lectureNotes?.map((item) =>
        //                                 <TeachAndStudNotes chapterName={item?.chapterName} topic={item?.title} id={item.id} file={item?.file} types={"lecture-notes"} std={item.standard?.std} section={item.standard?.section} img={lecturenotes} subject={item?.subject?.name} />
        //                             )}
        //                         </div>

        //                         {getStudentHome[0]?.video?.length != 0 ?
        //                             <div className="m-t-10" style={{ fontWeight: "bold", fontSize: "25px" }}>Recently Added Videos</div> : null}

        //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start" }}>
        //                             {getStudentHome[0]?.video?.map((item) =>
        //                                 <TeachAndStudVideo url={item?.link} file={item?.file} chapterName={item.chapterName} topic={item.title} types={"video"} std={item.standard?.std} section={item.standard?.section} subject={item?.subject?.name} id={item?.id} />
        //                             )}
        //                         </div>
        //                         {getStudentHome[0]?.questionBank?.length != 0 ?
        //                             <div className="m-t-10" style={{ fontWeight: "bold", fontSize: "25px" }}>Recently Added Question Banks</div> : null}
        //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start" }}>
        //                             {getStudentHome[0]?.questionBank?.map((item) =>
        //                                 <TeachAndStudNotes chapterName={item.chapterName} topic={item.title} id={item.id} file={item?.file} types={"question-bank"} std={item.standard?.std} section={item.standard?.section} img={questBankImg} subject={item?.subject?.name} />
        //                             )}
        //                         </div>
        //                     </div>
        //                 )) :
        //                     <Row >
        //                         <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
        //                             <div className="m-r-10 m-t-10">
        //                                 <LibraryCardDetails title={"Lecture Notes"} filesNO={0} images={lecturenotes} />
        //                             </div>
        //                         </Col>
        //                         <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
        //                             <div className="m-r-10 m-t-10">
        //                                 <LibraryCardDetails title={"Videos"} filesNO={0} images={videoImg} />
        //                             </div>
        //                         </Col>
        //                         <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
        //                             <div className="m-r-10 m-t-10">
        //                                 <LibraryCardDetails title={"Question Bank"} filesNO={0} images={questBankImg} />
        //                             </div>
        //                         </Col>
        //                     </Row>
        //                 }
        //             </div>

        //             <Heading className='m-t-20'>Subject(s)</Heading>
        //             <div style={{ display: "flex", flexWrap: "wrap" }}>
        //                 {classSubjectList ? classSubjectList.map((item, index) => (
        //                     <div className="s-i-card" style={{ margin: "7px" }} >
        //                         <SubjectCard title={item?.subjects_name} filesNO={item?.chapterCount} images={item?.icon} index={index} classSubjectList={classSubjectList} item={item} />
        //                     </div>
        //                 )) : null}
        //             </div>
        //         </div>
        //         :
        //         <div style={{ width: '100%', height: '500px' }}>
        //             <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        //                 <Image width={350} height={350} src={emptyimage} preview={false}></Image>
        //                 <Text style={{ fontFamily: 'roboto', fontWeight: 'bold', fontSize: '20px' }}>{subjectAssigned?.class == false ? 'You are not added to a class yet!' : 'There is no subject in your standard!'}</Text>
        //             </div>
        //         </div>
        //     }
        // </div>