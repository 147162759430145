import React, { useState } from "react";
import { DatePicker, Space } from "antd";
import { connect } from "react-redux";
import {
  getStats,
  getUserDashBoardData,
  setEndDate,
  setStartDate,
} from "actions/userManagement";
import PageDataLoader from "components/pageDataLoader";
import {
  fetchTransaction,
  setAllActivitiesFilter,
} from "actions/feeManagement";
import moment from "moment";
const { RangePicker } = DatePicker;

const CalenderPopup = ({
  setStartDate,
  getDashBoardData,
  setEndDate,
  barGraphPlotFunction,
  togglePageLoader,
  getStats,
  dashBoardRole,
  getUserDashBoardData,
  showCustomCal,
  fetchTransaction,
  setAllActivitiesFilter,
  tooglePageLoader,
  allActivitiesFilter,
  
}) => {
  const [pageLoader, setPageLoader] = useState(false);
  function onChange(dates, dateStrings) {


    if (dateStrings && dateStrings[0] && dateStrings[1]) {
      setAllActivitiesFilter("startDate", moment(dateStrings[0],'DD-MM-YYYY').format('YYYY-MM-DD'));
      setAllActivitiesFilter("endDate", moment(dateStrings[1],'DD-MM-YYYY').format('YYYY-MM-DD'));
      
      tooglePageLoader(true);
      fetchTransaction(() => tooglePageLoader(false));
      showCustomCal(false);
     
    } else {
      // Handle case when dates are cleared or not selected
      setAllActivitiesFilter("startDate", undefined);
      setAllActivitiesFilter("endDate", undefined);
    }
  }

  return (
    <div className="rangePicker">
      <Space
        direction="vertical"
        mode={"month"}
        size={12}
        style={{ width: "240px", marginLeft: "-5px" }}
      >
        <RangePicker
          dropdownClassName={"rangePickerB"}
          placeholder={["Start Date", "End Date"]}
          onChange={onChange}
          renderExtraFooter={() => "extra footer"}
          showTime
          value={[allActivitiesFilter?.startDate && moment(allActivitiesFilter.startDate), allActivitiesFilter?.endDate && moment(allActivitiesFilter.endDate)]}
          format={"DD-MM-YYYY"}
        />
      </Space>
      <PageDataLoader visible={pageLoader} />
    </div>
  );
};
const mapStateToProps = (state) => {
  const { tab, dashBoardRole, isMonthOrWeek } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    isMonthOrWeek,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setStartDate: (m) => dispatch(setStartDate(m)),
  setEndDate: (m) => dispatch(setEndDate(m)),
  getStats: (callback) => dispatch(getStats(callback)),
  getUserDashBoardData: (callback) => dispatch(getUserDashBoardData(callback)),
  //   getDashBoardData: (callback) => dispatch(getDashBoardData(callback))
  fetchTransaction: (callback) => dispatch(fetchTransaction(callback)),
  setAllActivitiesFilter: (key, val) =>
    dispatch(setAllActivitiesFilter(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalenderPopup);
