import { AutoComplete, Modal } from 'antd'
import UserAvatar from 'components/userAvatar'
import React, { useEffect, useState } from 'react'
import { Api, Color } from 'services'
import { renderCardContent } from './recentAddedCourseModal'
import { connect } from 'react-redux'
import { getTeachersGeofenceLocations, saveEditedGeoFence } from 'actions/attendanceNew'
import { Button } from 'components/Button'
import { Information, ModalHeading, SmallInfo } from 'components/Typography'

function RecentAddedStudentsModal({ isStudentModalVisible, setIsStudentModalVisible, image, name, role, number, email, icon, createdAt, courseName, showAssignClass, assignClassOnclick, showAssignGeofence, getTeachersGeofenceLocations, teachersGeofenceLocDataContainer, saveEditedGeoFence, togglePageLoader, getCourseActivityData, userId, type, hasMarketingPrivilege }) {
    const handleCancel = () => {
        setIsStudentModalVisible(false)
    }
    useEffect(() => {
        if (showAssignGeofence) {
            getTeachersGeofenceLocations(null, () => { })
        }
    }, [])
    const [searchName, setSearchName] = useState(null);
    const [loader, setLoader] = useState(false);
    const [selectedGeofence, setSelectedGeofence] = useState(null);
    const [submitBtnLoader, setSubmitBtnLoader] = useState(false);
    const handleGeofenceAutoCompleteSearch = (value) => {
        setLoader(true);
        getTeachersGeofenceLocations(value, () => { setLoader(false) })
    }

    const handleGeofenceSelect = (obj) => {
        setSelectedGeofence(obj?.data)
    }
    const handleSubmit = () => {
        setSubmitBtnLoader(true)
        const params = {
            "userId": userId.toString(),
            "geoFences": [
                {
                    "geoFenceId": selectedGeofence?.id.toString(),
                    "type": "Permanent",
                    "startDate": null,
                    "endDate": null,
                    "isNew": true,
                    "title": selectedGeofence?.title
                }
            ]
        }
        const activityParams = {
            type: type,
            limit: 10,
            page: 1
        }
        saveEditedGeoFence(
            params,
            () => { setSubmitBtnLoader(false) },
            () => {
                setIsStudentModalVisible(false);
                togglePageLoader(true);
                getCourseActivityData(activityParams, true, () => { togglePageLoader(false) })
            }
        )

    }
    useEffect(() => {
        if (!searchName) {
            setSelectedGeofence(null)
        }
    }, [searchName])
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isStudentModalVisible}
            onCancel={handleCancel}
            footer={null}
        >
            <div className='r-ac'>
                <UserAvatar size={50} shape="circle" name={name} img={image == "default.png" ? "" : `${Api._s3_url}${image}`} />
                <ModalHeading className='m-l-10'>{name}</ModalHeading>
            </div>
            <div className="r-jsb m-t-20" style={{ width: "100%" }}>
                {renderCardContent("Role", role, "49%")}
                {renderCardContent("Mobile number", number, "49%")}
            </div>
            {email ?
                <div className="m-t-10">
                    {renderCardContent("Email", email, "100%")}
                </div>
                :
                <></>}
            <div className='m-t-20'><SmallInfo color={Color.lightBlack}>Courses purchased</SmallInfo></div>
            <div className='m-t-10 r-ac'>
                <img src={icon} />
                <Information bold color={Color.black} style={{ marginLeft: 10 }}>{courseName}</Information>
            </div>
            <div className='r-jsb m-t-5'>
                <div style={{ color: "#AEAEAE", marginLeft: 50 }}>Registered on {createdAt}</div>
                {showAssignClass && !hasMarketingPrivilege ? <Button type="link" onClick={assignClassOnclick}>ASSIGN A CLASS</Button> : <></>}
            </div>
            {hasMarketingPrivilege ? <></> :
                (showAssignGeofence ?
                    <>
                        <div className='m-t-20'><SmallInfo color={Color.lightBlack}>Assign permanent geofence</SmallInfo></div>
                        <AutoComplete
                            allowClear={true}
                            notFoundContent={'No geofence found'}
                            dropdownMatchSelectWidth={252}
                            className="round-bordered-search-course"
                            onSelect={(value, obj) => handleGeofenceSelect(obj)}
                            onSearch={handleGeofenceAutoCompleteSearch}
                            loading={loader}
                            style={{ marginTop: 5, width: "100%" }}
                            value={searchName}
                            onChange={e => setSearchName(e)}
                            placeholder={`Search geofence`}
                            options={teachersGeofenceLocDataContainer?.length ? teachersGeofenceLocDataContainer?.map((item) => ({ value: item?.title, data: item, key: item?.id })) : []}
                        />
                        <div className='r-c-c-c'>
                            <Button
                                type="link"
                                onClick={() => {
                                    handleSubmit();
                                }} loading={submitBtnLoader}
                                disabled={!selectedGeofence}
                                style={{ opacity: !selectedGeofence ? 0.5 : 1, marginTop: 10 }}>
                                SUBMIT
                            </Button>
                        </div>
                    </>
                    :
                    <></>
                )
            }

        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { teachersGeofenceLocDataContainer } = state.attendanceNew;
    return { teachersGeofenceLocDataContainer };
};

const mapDispatchToProps = (dispatch) => ({
    getTeachersGeofenceLocations: (search, callBack) => (dispatch(getTeachersGeofenceLocations(search, callBack))),
    saveEditedGeoFence: (params, callBack, successCallBack) => dispatch(saveEditedGeoFence(params, callBack, successCallBack)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RecentAddedStudentsModal);
// export default RecentAddedStudentsModal