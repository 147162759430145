import { Modal } from "antd";
import React, { useState } from "react";

function DeletePicConfirmationModal({
  isModalVisible,
  handleOk,
  handleCancel,
  onDeleteConfirm,
  setEditProfileModal,
  setdeleteConfirmation,
  addUserFieldsData,
  fromUserProfile,
  setProfileDetails,
  userProfileDetails
}) {
  
  // console.log("modal", isModalVisible);
  const [deletePicLoader, setDeletePicLoader] = useState(false)
  const onOk = () => {
    setDeletePicLoader(true);
    onDeleteConfirm(() => setDeletePicLoader(false), () => {
      setEditProfileModal(false); setdeleteConfirmation(false),

        !fromUserProfile ? addUserFieldsData('file', '') : setProfileDetails((prevDetails) => ({
          ...prevDetails,
          file: 'default.png'
        })),
        setProfileDetails((prevDetails) => ({
          ...prevDetails,
          image: 'default.png'
        }))
    })
  }
  return (
    <div>
      <Modal
        className="deleteUserModal"
        okText={"Yes"}
        cancelText={"No"}
        title="Basic Modal"
        visible={isModalVisible}
        onOk={() => onOk()}
        onCancel={handleCancel}
        okButtonProps={{ loading: deletePicLoader }}
      // okButtonProps={{ loading: true }}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Confirm
        </h2>
        <p
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#636363",
          }}
        >
          Are you sure you want to remove the display picture?
        </p>
      </Modal>
    </div>
  );
}

export default DeletePicConfirmationModal;
