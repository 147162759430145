import { ArrowLeftOutlined } from '@ant-design/icons';
import React, {useState} from 'react'
import { useHistory } from 'react-router-dom';
import {Row, Col} from 'antd'

const PageHeader = ({ goBack, selectedQBtype, }) => {
    return (
      <div className="resultPageHeader full-width">
        <div className="r-c-fs m-r-30">
          <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer"/>
          <div className="text-md bold-700 m-l-20"> 
            <div>
              {selectedQBtype}
            </div>
           </div>
        </div>
      </div>
    )
}

const ClassCard = ({  standardData })=>{
  const [currentView, setCurrentView] = useState(1)
    return (
        <div className="adminTestCard customCardShadow m-t-10 cursor-pointer shadow-box hover-shadow m-r-20"
                style={{height: 130, width:250, minWidth:120, padding: 0, borderRadius:10, padding:20}}
                onClick={() => {
                    // setSelectedClassData(
                    //     {
                    //         curriculumId: curriculumId,
                    //         standardId: standardData.id,
                    //         standardName: `${standardData.name} ${standardData.stream}`
                    //     }
                    // )
                    // history.push('question-bank-type')
                    // history.push('question-bank-subjects')
                }}>
            <Row  style={{height:"56%"}}>
                <Col span={24}>
                    <div className="bold r-c-c-c" style={{color: "#594099", fontFamily:"serif"}}>
                        <div style={{width:"100%"}} className='text-xlg text-center text-overflow p-r-5 p-l-5'>
                            {standardData.name}
                        </div>
                                <div style={{ width:'100%', color:"#8C8C8C"}} className="text-center text-overflow p-r-5 p-l-5">
                                                {standardData.stream==""?"NA":standardData.stream}
                                </div>
                    </div>
                </Col>
            </Row>
            {/* <Divider style={{ width:"20px", marginTop:5, marginBottom: 5}} /> */}
            {/* <Row>
                <Col className="r-c-c-c" style={{borderRight:"1px solid rgba(0, 0, 0, 0.06)", }} span={12}>
                    <div className="orange-yellow bold-700 text-center">
                        <span>{standardData.noOfSubjects}</span>
                    </div>
                    <div style={{fontSize:"9px"}} className="text-center">
                        Subjects
                    </div>
                </Col>
                <Col className="r-c-c-c" span={12}>
                    <div className="seaGreen bold-700 text-center">
                        {standardData.noOfQuestions}
                    </div>
                    <div style={{fontSize:"9px"}} className="text-center">
                        Questions
                    </div>
                </Col>
            </Row> */}
        </div>
      )
}

const SubjectwisePapers = () => {
  
  const history = useHistory()
const goBack = () => {
   history.goBack();}
return ( 
<div style={{background: "#FAFAFA",
width: "105%",
height: "94vh",
margin:-20,
padding:20,
overflow:"hidden" }}>
{[{name:"NEET", standards: Array(10).fill({name:"Ferarri", stream:"LaFararri"})}]?.map((curriculumData, index) => (
   <>
     <div className="text-xmd bold-600 color-black m-b-15">
       {" "}
       {curriculumData.name}{" "}
     </div>
     <div className="full-width r-afs flex-wrap m-b-20">
       {curriculumData.standards?.map((standardData, index) => (
       //   <Card
       //     type="classcard"
       //     standardData={standardData}
       //     curriculumId={curriculumData.id}
       //   />
         <ClassCard standardData={standardData}/>
       ))}
     </div>
   </>
 ))
}
</div>
)
}


const SampleQuesPapers = (classcardData) => {
      
  
  return ( 
      <div style={{background: "#FAFAFA",
      width: "105%",
      height: "94vh",
      margin:-20,
      padding:20,
      overflow:"hidden" }}>
    {[{name:"NEET", standards: Array(10).fill({name:"AMG", stream:"GT-R"})}]?.map((curriculumData, index) => (
            <>
              <div className="text-xmd bold-600 color-black m-b-15">
                {" "}
                {curriculumData.name}{" "}
              </div>
              <div className="full-width r-afs flex-wrap m-b-20">
                {curriculumData.standards?.map((standardData, index) => (
                //   <Card
                //     type="classcard"
                //     standardData={standardData}
                //     curriculumId={curriculumData.id}
                //   />
                <>
                  <ClassCard standardData={standardData}/>
                  </>
                ))}
              </div>
            </>
          ))
        }
        <SubjectwisePapers />
        </div>)
}




export default SampleQuesPapers ;