import { ArrowLeftOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  getAllCourses,
  setIsFromDetailsPage,
  setSearchCourse,
  setSpecificCourseDetails,
  storePageNumber,
  storeSelectedCourseId,
} from "actions/course";
import { Col, Pagination, Row } from "antd";
import PageDataLoader from "components/pageDataLoader";
import PageHeader from "components/pageHeader";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import image from "../../Assets/course/ill-courses.png";
import AddCourseModal from "./addCourseModal";
import CourceCard from "./course";
import "./style.css";
import { Input, InputSearch } from 'components/customInputs';
import { Button } from "components/Button";
import SuccessModal from "./successModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


let timer = false

function CourseMain({
  setSearchCourse,
  getAllCourses,
  setSpecificCourseDetails,
  courses,
  search,
  specificCourseDetails,
  isFromDetailsPage,
  pageNumber,
  storePageNumber,
  setIsFromDetailsPage,
  storeSelectedCourseId
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const [pageLoader, togglePageLoader] = useState(false);
  const handleSearch = (e) => {
    // console.log(e);
    setSearchCourse(e);
    togglePageLoader(true);
    getAllCourses(() => togglePageLoader(false));
  };

  useEffect(() => {
    setSearchCourse(null);
    togglePageLoader(true);
    if (isFromDetailsPage) {
      getAllCourses(() => togglePageLoader(false), pageNumber);
      setIsFromDetailsPage(false)
    } else {
      getAllCourses(() => togglePageLoader(false), null);
    }

  }, []);

  const history = useHistory();

  const actions = [
    (courses?.response?.items?.length == 0 ? <></> : <Button type="primary" onClick={() => { setIsModalVisible(true) }} style={{ marginRight: 30 }} icon={<PlusCircleOutlined />}>ADD A COURSE</Button>),
    <InputSearch allowClear onClear={(e) => { handleSearch('') }} placeholder='Search courses...' style={{ width: 280, marginRight: 20 }} onInputChange={(e) => { handleSearch(e); }} />
  ]
  const location = useLocation()

  return (
    <div

    >
                  {location?.state?.key == "fromClassroom" ?
                <div style={{ display: "flex", justifyContent: "space-between", height: 50, borderBottom: "1px solid #E6E6E6"}}>
                    <div style={{ fontSize: "2em", fontWeight: 700, display: "flex", flexDirection: "row" }}>
                        <ArrowLeftOutlined
                            style={{ marginTop: "15px", fontSize: "20px" }}
                            onClick={() => {
                                history.push("/classroom/classroom-details");
                            }} />
                        <div style={{ marginLeft: "15px" }}>{"Courses"}</div>
                    </div>
                    <div style={{display:"flex"}}>
                    <Button type="primary" onClick={() => { setIsModalVisible(true) }} style={{ marginRight: 30 }} icon={<PlusCircleOutlined />}>ADD A COURSE</Button>
                    <InputSearch allowClear onClear={(e) => { handleSearch('') }} placeholder='Search courses...' style={{ width: 280, marginRight: 20 }} onInputChange={(e) => { handleSearch(e); }} />
                    </div>
                </div>
                :
                <div style={{ marginTop: -10, height: 50 }}>
                <PageHeader title='Courses' actions={actions} />
              </div>
            }
      <div style={{ width: "100%" }}>
        {courses?.response?.items?.length != 0 ? (
          <div
            style={{
              marginTop: "20px",
              padding: "5px",
              height: "85vh",
              marginBottom: "50px"
            }}
          >
            <Row
              style={{
                marginTop: "0px",
                marginBottom: "50px",
              }}
            >
              {courses?.response?.items?.map((item) => (
                <Col
                  onClick={() => {
                    setSpecificCourseDetails(item);
                    storeSelectedCourseId(item?.id)
                    history.push("/course-details");
                  }}
                >
                  <CourceCard
                    course={item.courseName}
                    duration={`${item.duration} ${item.durationType} `}
                    plan={item.billingPlan}
                    startDate={item?.startDate}
                    endDate={item?.endDate}
                    progress={item?.progress}
                  />
                </Col>
              ))}
            </Row>
            <div
              style={{
                position: "absolute",
                bottom: "20px",
                right: "50px",
                backgroundColor: "white"
              }}
            >
              <Pagination
                size="small"
                showSizeChanger={false}
                onChange={(e) => {
                  storePageNumber(e)
                  togglePageLoader(true);
                  getAllCourses(() => togglePageLoader(false), e);
                }}
                defaultCurrent={1}
                total={courses?.response?.meta?.totalPages * 10}
                current={courses?.response?.meta?.currentPage}
              />
            </div>
          </div>
        ) : (
          <div
            className="flex_column_center "
            style={{
              justifyContent: "center",
              height: "100vh",
              textAlign: "center",
              fontSize: "24px",
            }}
          >
            <div className="flex_row_center">
              <img width="85%" style={{ margin: "auto" }} src={image} alt="" />
            </div>
            <div>
              <h3
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  fontFamily: "roboto",
                  marginBottom: "2px",
                  marginTop: "15px",
                }}
              >
                No course available!
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  color: "#636363",
                  fontFamily: "roboto",
                }}
              >
                There are no course added yet.
              </p>
            </div>
            <Button
              className="purpleBtnWrap radius-100 purpleButtonHoverEffect"
              style={{
                borderRadius: "25px",
                backgroundColor: "#594099",
                color: "white",
                height: "40px",
                fontWeight: "500",
                fontSize: "13px",
                marginTop: "10px",
              }}
              type="primary" onClick={() => { setIsModalVisible(true) }} icon={<PlusCircleOutlined />}>ADD A COURSE</Button>
          </div>
        )}
      </div>
      {isModalVisible && (
        <AddCourseModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          isEditModalVisible={isEditModalVisible}
          setIsEditModalVisible={setIsEditModalVisible}
          togglePageLoader={togglePageLoader}
          setSuccessModalVisible={setSuccessModalVisible}
        />
      )}
      {
        isSuccessModalVisible &&
        <SuccessModal
          isSuccessModalVisible={isSuccessModalVisible}
          setSuccessModalVisible={setSuccessModalVisible}
          togglePageLoader={togglePageLoader}
          getAllCourses={getAllCourses} />
      }
      <PageDataLoader visible={pageLoader} />
    </div>
  );
}

// export default CourseMain;

const mapStateToProps = (state) => {
  const { search, courses, specificCourseDetails, isFromDetailsPage, pageNumber } = state.course;
  return {
    search,
    courses,
    specificCourseDetails,
    isFromDetailsPage,
    pageNumber
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSearchCourse: (val) => dispatch(setSearchCourse(val)),
  getAllCourses: (val, page) => dispatch(getAllCourses(val, page)),
  setSpecificCourseDetails: (val) => dispatch(setSpecificCourseDetails(val)),
  storePageNumber: (val) => dispatch(storePageNumber(val)),
  setIsFromDetailsPage: (val) => dispatch(setIsFromDetailsPage(val)),
  storeSelectedCourseId: (val) => dispatch(storeSelectedCourseId(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseMain);