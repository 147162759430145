import React, {useRef} from "react";

import {
  EditOutlined,
  RollbackOutlined
} from "@ant-design/icons";
import {IoShapesOutline} from 'react-icons/io5'
import {HiOutlineMinus} from 'react-icons/hi'
import {GiLasso} from 'react-icons/gi'
import Tooltip from 'antd/lib/tooltip';
import Popover from 'antd/lib/popover';
import 'antd/lib/tooltip/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/popover/style/index';
import Styles from "./style";
import Slider from 'antd/lib/slider';
import 'antd/lib/slider/style/index';
import html2canvas from "html2canvas";
import domtoimage from 'dom-to-image';
import { Modal } from "antd";
import { Notification, Api } from "services";
import { postCanvasImage, postChatImage, postRoomData, setRoomBoardData, storePaths } from "actions/broadcast";
import { connect } from "react-redux";
import { FaEraser, FaRegCircle } from "react-icons/fa";
import { AiFillDelete, AiFillEdit, AiOutlineSelect } from "react-icons/ai";
import { BsFillImageFill, BsFillShareFill } from 'react-icons/bs';
import { BiRectangle, BiSolidFilePdf, BiSolidImage } from 'react-icons/bi';
import jsPDF from "jspdf";
import { SketchCanvasPro } from "components";
import { CanvasData } from "../fakedata";
import { TbZoomReset, TbFocusCentered } from "react-icons/tb";


const TooltipTemplate = ({title}) => (
  <div style={{color:"white"}}>{title}</div>
)
const screenWidth = isNaN(window.innerWidth) ? window.clientWidth : window.innerWidth;
const screenHeight = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight;
const ImageArray = React.memo(({Data, reactiveCanvas}) => {
  return (
    <>
      {Data?.map((item)=>(
        <img crossOrigin="anonymous" key={item.localurl} src={item.url} style={{height:item.height, width:item.width, left:100, top:100, visibility:'hidden'}}/>
      ))
      }
    </>
  )
})
class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.inputFile = React.createRef();
    this.canvasPro = React.createRef();
    this.handlePenClick = this.handlePenClick.bind(this);
    this.openWidthPicker = this.openWidthPicker.bind(this);
    this.handleUndoClick = this.handleUndoClick.bind(this);
    this.openColorPicker = this.openColorPicker.bind(this);
    this.addNewImage = this.addNewImage.bind(this);
    this.toggleColorPicker = this.toggleColorPicker.bind(this);
    this.openEraserWidthPicker = this.openEraserWidthPicker.bind(this);
    // this.handleRedoClick = this.handleRedoClick.bind(this);
    // this.handleClearClick = this.handleClearClick.bind(this);
    this.handleEraserClick = this.handleEraserClick.bind(this);
    this.openShapesPicker = this.openShapesPicker.bind(this);
    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.streamId = this.props.streamId;
    this.state = {
      canvasnum: 1,
      mode: 'pen',
      color: "black",
      showColorPicker: false,
      path: false,
      width: 10,
      toggler: true,
      canvasImageData: {
        "1": [],
        "2": [],
        "3": []
      },
      eraserWidth:10,
      screenshoturl: '',
      isModalVisible: false,
      screenshotfile: null,
      showWidthPicker:false,
      showShapesPicker:false,
      showEraserWidthPicker: false,
      imageArray: [],
      shapeType: 'rect',
      pages: [
        // props.streamId in props.paths
        // ?
        // this.props.paths[this.props.streamId]
        // :  
        [{
          paths: [{ x: null, y: null }],
          strokeWidth: 0,
          strokeColor: 0,
          drawMode: 0,
          startTimestamp: 0,
          endTimestamp: 0
        }],
        [{
          paths: [{ x: null, y: null }],
          strokeWidth: 0,
          strokeColor: 0,
          drawMode: 0,
          startTimestamp: 0,
          endTimestamp: 0
        }],
        [{
          paths: [{ x: null, y: null }],
          strokeWidth: 0,
          strokeColor: 0,
          drawMode: 0,
          startTimestamp: 0,
          endTimestamp: 0
        }]
      ]
    };
  }
  componentWillUnmount(){
    if (this.props.isScheduledTeacher){
      this.props.postRoomData({roomId:this.props.streamId?.split('xxxx')[1], roomData: JSON.stringify(this.props.roomboardData)}, ()=>{})
    }
  }
  switchCanvas2(canvasno) {
    this.setState({
      canvasnum: canvasno
    })
  }
  selectCanvas(canvasno) {
    let currentcanvas = null
    if (canvasno == 1) {
      currentcanvas = this.canvas1
    }
    else if (canvasno == 2) {
      currentcanvas = this.canvas2
    }
    else if (canvasno == 3) {
      currentcanvas = this.canvas3
    }
    this.setState({
      canvasnum: canvasno,
      canvas: currentcanvas,
      showColorPicker: false
    });
  }

  content = (
    <div style={Styles.colorPickerWrapper}>
      <button
        className="cursor-pointer"
        style={{ ...Styles.colorPickerBtn, backgroundColor: "red" }}
        onClick={() => this.selectColor("red")}
      />
      <button
        className="cursor-pointer"
        style={{ ...Styles.colorPickerBtn, backgroundColor: "blue" }}
        onClick={() => this.selectColor("blue")}
      />
      <button
        className="cursor-pointer"
        style={{ ...Styles.colorPickerBtn, backgroundColor: "green" }}
        onClick={() => this.selectColor("green")}
      />
      <button
        className="cursor-pointer"
        style={{ ...Styles.colorPickerBtn, backgroundColor: "black" }}
        onClick={() => this.selectColor("black")}
      />
    </div>
  );

  shapesType = () => (
    <div style={Styles.colorPickerWrapper}>
    <button className="cursor-pointer" onClick={() => this.selectShape("rect")}
     style={{ backgroundColor: "white", border:"none", padding:5}}>
      <BiRectangle style={{fontSize:20}}/>
    </button>
    <button className="cursor-pointer" onClick={() => this.selectShape("circle")}
      style={{ backgroundColor: "white", border:"none", padding:5 }}>
      <FaRegCircle style={{fontSize:20}}/>
    </button>
    <button className="cursor-pointer" onClick={() => this.selectShape("line")}
    style={{ backgroundColor: "white", border:"none", padding:5 }}>
      <HiOutlineMinus style={{transform:'rotate(20deg)', fontSize:25}} />
    </button>
  </div>
  )

  selectColor(color) {
    this.setState({
      color,
      showColorPicker: false
    });
  }

  selectShape(shape){
    this.setState({
      shapeType: shape,
      mode: 'drawShape',
      showShapesPicker:false
    })
  }

  handleUndoClick() {
    this.canvasPro.current.undoCanvas();
  }


  handleClearClick() {
    this.canvasPro.current.clearCanvas();
    // this.props.postRoomData({roomId:this.props.streamId?.split('xxxx')[1], roomData: JSON.stringify(this.props.roomboardData)}, ()=>{})
  }
  changeWidth(value) {
    this.setState({
      width: value
    })
  }
  changeEraserWidth(value) {
    this.setState({
      eraserWidth: value
    })
  }
  handlePenClick() {
    this.setState((prevState) => {
      return {
        // ...prevState,
        mode: 'pen',
        showEraserWidthPicker:false,
        showColorPicker:false,
        showShapesPicker:false,
        width: prevState?.width ? prevState.width : 2
      }
    })
    this.openWidthPicker(!this.state.showWidthPicker);
  }

  openColorPicker() {
    this.toggleColorPicker(!this.state.showColorPicker)
    this.setState({ showWidthPicker:false, showEraserWidthPicker:false, showShapesPicker:false });
  }
  toggleColorPicker(bool){
    this.setState({showColorPicker: bool})
  }
  openWidthPicker(bool) {
    this.setState({ showWidthPicker: bool });
  }
  openShapesPicker(bool) {
    this.setState({showShapesPicker: bool})
  }
  openEraserWidthPicker(bool){
    this.setState({showEraserWidthPicker: bool })
  }
  handleEraserClick() {
    if (this.props.isScheduledTeacher){
      this.props.postRoomData({roomId:this.props.streamId?.split('xxxx')[1], roomData: JSON.stringify(this.props.roomboardData)}, ()=>{})
    }
    this.setState((prevState)=> {
      return { 
        mode: 'eraser', 
        showWidthPicker:false,
        showColorPicker:false,
        showShapesPicker:false,
        eraserWidth:prevState?.eraserWidth ? prevState.eraserWidth : 10
    } });
    this.openEraserWidthPicker(!this.state.showEraserWidthPicker);
  }




  handleSelectClick() {
    this.setState({
      mode:'select'
    })
  }
  handleDivRef(divRef) {
    if (divRef !== null) {
      this.sketchContainer = divRef;
    }
  }

  slider = () => {
    return (
      <div style={Styles.colorPickerWrapper}>
        <Slider
          style={{ width: '100px' }}
          min={1}
          step={0.01}
          max={20}
          onChange={(value) => {
            if (this.state.showWidthPicker){
              this.changeWidth(value)
            }else if(this.state.showEraserWidthPicker){
              this.changeEraserWidth(value*10)
            }
            }}
          value={this.state.showWidthPicker?this.state.width:this.state.showEraserWidthPicker?Math.round(this.state.eraserWidth/10):this.state.mode==='pen'?this.state.width:Math.round(this.state.eraserWidth/10)}
          onAfterChange={() => {
              if (this.state.showWidthPicker){
                this.openWidthPicker(!this.state.showWidthPicker)
              }else if(this.state.showEraserWidthPicker){
                this.openEraserWidthPicker(!this.state.showEraserWidthPicker);
              }
            }}
        />
      </div>
    )
  };
  addNewImage = (url, localurl) => {
    this.canvasPro.current.unselectAll();
    let newImageMeta = {index:this.canvasPro.current?this.canvasPro.current.getBoardData()[this.state.canvasnum]?.length:0, localurl:localurl, url:url, x:Math.ceil(Math.random()*300), y:Math.ceil(Math.random()*300), width:200, height:200, isSelected:true, timestamp:Date.now(), shapeType:"image"}

    this.canvasPro.current.drawImage(newImageMeta, ()=>{
    });
    this.inputFile.current.value = ""
    this.state.canvasImageData[this.state.canvasnum]?.push(newImageMeta)

    this.setState({
      mode:"select",
      toggler: !this.state.toggler
    })
    ;
  }
  handleFocusClick(){
    this.canvasPro.current.focusCentre();
  }
  handleZoomResetClick(){
    this.canvasPro.current.zoomReset();
  }
  render() {

    const user = {
      id: this.props.sessionuser.id,
      name: this.props.sessionuser.name,
      avatar: `${Api._s3_url}${this.props.sessionuser.image}`,
    }
    const handleImageUpload = (file) => {
      const params = {
        file,
        userId: user.id,
        roomName: this.props.streamId?.split("xxxx")[1],
        type: "image"
      };
      this.props.postChatImage(params, user,
          (newMessage) => {
              // chat.sendmsg(newMessage, 'Image');
              this.props.chat.sendGroupChat(user.id, this.props.streamId, 
                  {
                      type: 'Image',
                      data: newMessage,
                      userId: user.id,
                      roomId: this.props.streamId,
                  }
                  )
          }, "Group");
    }

    const imageBeforeUpload = file => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      const reader = new FileReader();
      if (!isJpgOrPng) {
        Notification.error("error", "You can only upload JPG/PNG file!");
      } else if (!isLt2M) {
        Notification.error("error", "Image must smaller than 2MB!");
      } else {
        reader.addEventListener("load", () => {
          handleImageUpload(file);
        });
        reader.readAsDataURL(file);
      }
      return false;
    };

    const imageBeforeUploadPrivate = file => {
      const isJpgOrPngPrivate = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2MPrivate = file.size / 1024 / 1024 < 2;
      const readerPrivate = new FileReader();
      if (!isJpgOrPngPrivate) {
          Notification.error("error", "You can only upload JPG/PNG file!");
      } else if (!isLt2MPrivate) {
          Notification.error("error", "Image must smaller than 2MB!");
      } else {
          readerPrivate.addEventListener("load", () => {
              handleImageUploadPrivate(file);
          });
          readerPrivate.readAsDataURL(file);
      }
      return false;
      // return isJpgOrPng && isLt2M;
  };

    const handleImageUploadPrivate = (file, imgUrl) => {
      // const { user } = this.props;
      const params = {
          file,
          roomId: this.props.streamId?.split('xxxx')[1],
          //   roomName: 'fpyrpub0pug',
          //   roomName: '2de4fc06-f1b0-4ca7-9d97-8bc72c7a40e6',
          type: "image"
      };
      this.props.postCanvasImage(params,
        (newMessage)=>{
                  this.addNewImage(newMessage.image, imgUrl);
                    });
    }

    const onChangeFile = (e) => {
      this.setState({
        mode:'image'
      })
      e.preventDefault();
      const file = e.target.files[0];
      if (file != undefined) {
          const imgUrl = URL.createObjectURL(file);
          imageBeforeUploadPrivate(file, imgUrl)

          // this.addNewImage(null, imgUrl);
      }
    }
    const getImageUrl = async () => {
      let thiz = this;
      return new Promise((resolve, reject)=>{
        setTimeout(()=>{
  
          domtoimage.toPng(document.querySelector("#canvas-top"))
              .then(function (dataUrl) {
                const canvas = document.getElementById('broadcast-canvas-download');
                const ctx = canvas.getContext('2d');
          
                // Create an image object
                const img = new Image();
                img.src = dataUrl;
                img.onload = () => {
                  // Set canvas dimensions to match image dimensions
                  canvas.width = screenWidth;
                  canvas.height = screenHeight;
          
                  // Draw white background
                  ctx.fillStyle = '#ffffff'; // white color
                  ctx.fillRect(0, 0, canvas.width, canvas.height);
          
                  // Draw the image on top of the white background
                  ctx.drawImage(img, 0, 0);
          
                  // Get the modified image data URL
                  const modifiedImageDataUrl = canvas.toDataURL();
                  resolve(modifiedImageDataUrl)
                  // Invoke the callback with the modified image data URL
                };
              })
              .catch(function (error) {
                reject(error)
                  console.error('oops, something went wrong!', error);
              });
  
        }, 500);
      })
    }
    return (
      <>
      <div className={`full-height full-width relative r-c-fs whiteBoard`}
        ref={divElement => {
          this.handleDivRef(divElement);
        }}
        style={{width:"100%"}}
      >
        <div data-html2canvas-ignore style={{...Styles.toolbarContainer, cursor:"default"}}>
          <div style={Styles.toolbarBtn}>
            <Popover placement="left" color={"#3e3e3e"} overlayClassName={'broadcastTooltip'} content={<TooltipTemplate title="Select"/>} title={null}>
              <div className="full-width full-height r-c-c" style={this.state.mode==='select'?{backgroundColor:"gray", borderRadius:'100%', padding:5}:{}}
                onClick={this.handleSelectClick}>
                <AiOutlineSelect />
              </div>
            </Popover>
          </div>
          <div style={Styles.toolbarBtn}>
            <Popover placement="left" color={"#3e3e3e"} overlayClassName={'broadcastTooltip'} content={<TooltipTemplate title="Pen"/>} title={null}>
              <Popover placement="left" content={this.slider} title={()=>(<div className='r-c-fs'><EditOutlined color={'grey'}/>{'  '} <span className="m-l-10">Width</span></div>)} trigger="click" open={this.state.showWidthPicker} onOpenChange={this.openWidthPicker}>
              <div className="full-width full-height r-c-c" style={this.state.mode==='pen'?{backgroundColor:"gray", borderRadius:'100%', padding:5}:{}}
                onClick={this.handlePenClick}>
                <EditOutlined />
              </div>
              </Popover>
            </Popover>
          </div>
          <div style={Styles.toolbarBtn}>
            <Popover placement="left" color={"#3e3e3e"} overlayClassName={'broadcastTooltip'} content={<TooltipTemplate title="Shapes"/>} title={null}>
              <Popover placement="left" content={this.shapesType} title={()=>(<div className='r-c-fs'>Draw <span className="m-l-5">Shapes</span></div>)} trigger="click" open={this.state.showShapesPicker} onOpenChange={this.openShapesPicker}>
              <div className="full-width full-height r-c-c" style={this.state.mode==='drawShape'?{backgroundColor:"gray", borderRadius:'100%', padding:5}:{}}
                onClick={()=>{
                  this.setState({
                    mode: 'drawShape',
                    showShapesPicker: !this.state.showShapesPicker
                  })
                }}>
                  {
                    this.state.shapeType==='rect'?
                    <BiRectangle style={{fontSize:20}}/>
                    :this.state.shapeType==='circle'?
                    <FaRegCircle style={{fontSize:20}}/>
                    :this.state.shapeType==='line'?
                    <HiOutlineMinus style={{transform:'rotate(20deg)', fontSize:25}} />
                    :null
                  }
              </div>
              </Popover>
            </Popover>
          </div>
          <div style={Styles.toolbarBtn}>
            <Popover placement="left" color={"#3e3e3e"} overlayClassName={'broadcastTooltip'} content={<TooltipTemplate title="Image"/>} title={null}>
              <div
                className="full-width full-height r-c-c"
                type="text"
                shape="circle"
                style={this.state.mode==='image'?{backgroundColor:"gray", borderRadius:'100%', padding:5}:{}}
              >
                <label htmlFor="file-input-image">
                    <input type='file' id='file' ref={(el)=>{this.inputFile.current=el}}
                        onChange={onChangeFile}
                        style={{ display: 'none' }} accept='image/*' />
                        <BsFillImageFill className="cursor-pointer" onClick={()=>{
                  this.inputFile.current.click();
                        }}/>
                </label>
             </div>
            </Popover>
          </div>
          <div style={Styles.toolbarBtn}>
            <Popover placement="left" color={"#3e3e3e"} overlayClassName={'broadcastTooltip'} content={<TooltipTemplate title="Color picker"/>} title={null}>
              <Popover placement="left" content={this.content} title="Choose a colour" trigger="click" open={this.state.showColorPicker} onOpenChange={this.toggleColorPicker}>
              <div className="full-width full-height r-c-c"
                onClick={this.openColorPicker}
              >
                <div style={{ width: 20, height: 20, minHeight: 12, minWidth: 12, borderRadius: 20, backgroundColor: this.state?.color }}> </div>
              </div>
              </Popover>
            </Popover>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Undo">
              <div className="full-width full-height r-c-c"
                onClick={this.handleUndoClick}
              >
                <RollbackOutlined />
              </div>
            </Tooltip>
          </div>
          {/* <div style={Styles.toolbarBtn}>
            <Popover placement="left" color={"#3e3e3e"} overlayClassName={'broadcastTooltip'} content={<TooltipTemplate title="Select to erase"/>} title={null}>
              <div className="full-width full-height r-c-c" style={this.state.mode==='shape'?{backgroundColor:"gray", borderRadius:'100%', padding:5}:{}}
                onClick={()=>{
                  this.setState({
                    mode: 'shape',
                    showShapesPicker: !this.state.showShapesPicker
                  })
                }}>
                <GiLasso/>
              </div>
              </Popover>
          </div> */}
          <div style={Styles.toolbarBtn}>
            
           <Popover placement="left" color={"#3e3e3e"} overlayClassName={'broadcastTooltip'} content={<TooltipTemplate title="Eraser"/>} title={null}>
              <Popover placement="left" content={this.slider} title={()=>(<div className='r-c-fs'><FaEraser/>{'  '} <span className="m-l-10">Width</span></div>)} trigger="click" open={this.state.showEraserWidthPicker} onOpenChange={this.openEraserWidthPicker}>
              <div className="full-width full-height r-c-c" style={!this.state.mode==='pen'?{backgroundColor:"gray", borderRadius:'100%', padding:5}:{}}
                onClick={this.handleEraserClick}
              >
                <FaEraser/>
              </div>
              </Popover>
            </Popover>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Canvas 1">
              <div className="full-width full-height toolbarNum r-c-c" style={this.state.canvasnum==1?{borderLeftWidth:2, borderLeftColor:"grey", borderLeftStyle:'solid'}:{}}
                type="text"
                shape="circle"
                onClick={() => { this.switchCanvas2(1) }}
              >
                1
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Canvas 2">
              <div className="full-width full-height toolbarNum r-c-c" style={this.state.canvasnum==2?{borderLeftWidth:2, borderLeftColor:"grey", borderLeftStyle:'solid'}:{}}
                onClick={() => { this.switchCanvas2(2) }}
              >
                2
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Canvas 3">
              <div
                className="full-width full-height toolbarNum r-c-c" style={this.state.canvasnum==3?{borderLeftWidth:2, borderLeftColor:"grey", borderLeftStyle:'solid'}:{}}
                type="text"
                shape="circle"
                onClick={() => { this.switchCanvas2(3) }}
              >
                3
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Clear">
              <div
                className="full-width full-height r-c-c"
                type="text"
                shape="circle"
                onClick={()=>{this.handleClearClick()}}
              >
                <AiFillDelete/>
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Share in chat">
              <div
                type="text"
                shape="circle"
                className='r-c-c'
                onClick={async () => {
                  try{
                    let screenshotUrl = await getImageUrl();
                    this.setState({
                      screenshoturl: screenshotUrl,
                      isModalVisible: true
                    })
                  }catch(e){
                    console.log("error in getting image url",e)
                  }

                }}
              >
                <BsFillShareFill/>
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Save as PDF">
              <div
                type="text"
                shape="circle"
                className='r-c-c'
                onClick={async () => {
                  let imgData = await getImageUrl()
                    const pdf = new jsPDF({
                      orientation: 'landscape',
                    });
                    const imgProps= pdf.getImageProperties(imgData);
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                    pdf.save('whiteboard.pdf');
                }}
              >
                <BiSolidFilePdf/>
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Reset zoom">
              <div
                type="text"
                shape="circle"
                className='r-c-c'
                onClick={() => {
                  this.handleZoomResetClick();
                }}
              >
                <TbZoomReset/>
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Recentre the canvas">
              <div
                type="text"
                shape="circle"
                className='r-c-c'
                onClick={() => {
                  this.handleFocusClick();
                }}
              >
                <TbFocusCentered/>
              </div>
            </Tooltip>
          </div>
        </div>
        <SketchCanvasPro 
          ref={this.canvasPro}
          style={{width:screenWidth, height:screenHeight}} 
          mode={this.state.mode}
          penWidth={this.state.width}
          eraserWidth={this.state.eraserWidth}
          strokeColor={this.state.color}
          reactiveCanvas={false}
          shapeType={this.state.shapeType}
          onPath={(currentPath, fullPath, lastUpdateIsImage)=>{
            this.props.setRoomBoardData(JSON.parse(JSON.stringify(fullPath)));
          }}
          currentPage={this.state.canvasnum}
          boardData={{
            "1": this.props.roomboardData["1"],
            "2": this.props.roomboardData["2"],
            "3": this.props.roomboardData["3"],
          }}
          toggler={this.state.toggler}
          />
          <ImageArray Data={this.state.canvasImageData[this.state.canvasnum]} reactiveCanvas={this.props.reactiveCanvas?this.props.reactiveCanvas:false}/>
          <img crossOrigin="anonymous" src={this.state.screenshoturl} style={{height:screenHeight, width:screenWidth, visibility:'hidden'}}/>
        <Modal wrapClassName="imagePreview" centered={true} okText={"Send"}
          visible={this.state.isModalVisible}
          onOk={async () => {
            let blob = await fetch(this.state.screenshoturl).then(r => r.blob());
            this.setState({
              screenshotfile: blob,
              screenshoturl: '',
              isModalVisible: false
            });
            imageBeforeUpload(this.state.screenshotfile);
          }}
          onCancel={() => {
            this.setState({
              screenshoturl: '',
              isModalVisible: false
            })
          }}>
          <div style={{height: "60%" }}>
            <img style={{ width: "100%", height: "100%" }} src={`${this.state.screenshoturl}`} alt="" />
          </div>
        </Modal>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state.session;
  const { streamId, paths, roomboardData, isScheduledTeacher } = state.broadcast;
  const sessionuser = user;
  return {
    sessionuser,
    streamId,
    paths,
    roomboardData,
    isScheduledTeacher
  }
}

const mapDispatchToProps = dispatch => ({
  postChatImage: (params, user, callback, type) => dispatch(postChatImage(params, user, callback, type)),
  storePaths: (roomName, paths) => dispatch(storePaths(roomName, paths)),
  setRoomBoardData: (data) => dispatch(setRoomBoardData(data)),
  postCanvasImage: (params, callback) => dispatch(postCanvasImage(params, callback)),
  postRoomData: (data, callback) => dispatch(postRoomData(data, callback))
})


export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
