import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import Notification from "services/Notification";
import PreviewTestModal from '../modals/previewTestModal';

import { Button, Checkbox, DatePicker, Form, Input, Select, TimePicker } from 'antd'
import { CheckCircleOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from 'antd/lib/input/TextArea';
import { getExaminer, getIndexOfTest, getTestChapterList, postTestCreate, storeCreateTestModalData, getSubjects, getTestClassList, removeSelectedSearchSubjs, removeSelectedChapter, setSubjectSectionAction, fetchMarkingSchemeData, clearTestCreateObj, createTestModal, fromCreateNewButton, setFromSectionMarkingScheme, storeMarkedSchemeCardId } from 'actions/testAdmin';
import { AiOutlineClose } from 'react-icons/ai';
import { useHistory } from 'react-router';
import CreateMarkingScheme from '../modals/createMarkingScheme';
import EditMarkingSchemeModal from '../modals/editMarkingSchemeModal';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const handleKeyDown = (e) => {
    if (e.key === '+' || e.key === '-') {
        e.preventDefault();
    }
};

let timer = false;
const { Option } = Select;
let selectedTestStartTime = null;
const SectionsNeeded = ({ storeSubjectSection, isSameMarkingScheme, setIsSameMarkingScheme, sectionMeta, storeMarkingSchemeRes, fetchMarkingSchemeData, onSectionChange, section, showFields, childIndex, mainIndex, isCreateMarkingSchemeVisible, setIsCreateMarkingSchemeVisible, setFromSectionMarkingScheme, setIsPreviewModalOpen, onFillSubjectiveSection, storeMarkedSchemeCardId, fromCreateNewButton }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const sectionState = section;
    const [isEditModal, setIsEditModal] = useState(false)
    useEffect(() => {
        fetchMarkingSchemeData()
    }, [])

    const onFillSection = (mainIndex, index, key, value) => {
        const newSectionState = {
            ...sectionState,
            [key]: value
        }
        onSectionChange(index, newSectionState);
    }

    return (
        <>
            <div className='display-flex m-t-20' style={{ width: '100%' }}>
                <div className='' style={{}}>
                    <div className='bold-600 text-xs m-t-20'>Enter section name</div>
                    <Input
                        className="m-t-5 test-custom-select createTestModalValue"
                        style={{ width: '230%', transition: `${"width 0.5s"}` }}
                        placeholder="Enter section name"
                        onChange={(e) => { onFillSection(mainIndex, childIndex, "name", e.target.value) }}
                    />
                </div>
                {showFields != true ?
                    <>
                        <div className='' style={{ marginLeft: 240 }}>
                            <div className='bold-600 text-xs m-t-20'>Total questions</div>
                            <Input
                                onKeyDown={handleKeyDown}
                                type="number"
                                style={{ width: '105%' }}
                                placeholder="Enter value"
                                className="m-t-5 test-custom-select createTestModalValue"
                                onChange={(e) => { onFillSection(mainIndex, childIndex, "totalQuestions", e.target.value) }}
                            />
                        </div>
                        <div className='' style={{ marginLeft: 40 }}>
                            <div className='bold-600 text-xs m-t-20'>Mandatory questions</div>
                            <Input
                                onKeyDown={handleKeyDown}
                                type="number"
                                placeholder="Enter value"
                                style={{ width: '105%' }}
                                className="m-t-5 test-custom-select createTestModalValue"
                                onChange={(e) => { onFillSection(mainIndex, childIndex, "mandatoryQuestions", e.target.value) }}
                            />
                        </div>
                    </>
                    : null}
            </div>
            {isSameMarkingScheme && childIndex > 0 ? (<>
                <div className='r-jsb m-t-20'>
                    <div className='bold-600 text-xs' style={{ color: '#636363' }}>Marking scheme</div>
                </div>
                <Input
                    disabled
                    value={storeMarkingSchemeRes.find(el => (sectionState?.markingSchemeId == el.id))?.title}
                />
            </>) : (
                <>
                    <div className='r-jsb m-t-20'>
                        <div className='bold-600 text-xs' style={{ color: '#636363' }}>Marking scheme</div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: "#1089FF", fontWeight: "bold", fontSize: "11px", cursor: "pointer", marginRight: 5 }} onClick={() => { setFromSectionMarkingScheme(true); fromCreateNewButton(false); setIsModalVisible(true) }}><PlusOutlined />{" "}CREATE NEW {" "}</div>
                            <div className='bold-600 text-xs cursor-pointer' style={{ display: "flex", fontSize: "11px", color: '#636363', fontWeight: "bold" }}>
                                <div>
                                    {"  "}|{" "}
                                </div>
                                <div
                                    style={{ marginLeft: 5, cursor: sectionState?.markingSchemeId === "" || sectionState?.markingSchemeId === null || sectionState?.markingSchemeId === undefined ? "not-allowed" : "pointer" }}
                                    onClick={() => {
                                        if (!(sectionState?.markingSchemeId === "" || sectionState?.markingSchemeId === null || sectionState?.markingSchemeId === undefined)) {
                                            storeMarkedSchemeCardId(sectionState?.markingSchemeId?.id ? sectionState?.markingSchemeId?.id : sectionState?.markingSchemeId);
                                            setIsEditModal(true);
                                            setFromSectionMarkingScheme(true);
                                        } else {

                                        }
                                    }}>PREVIEW</div>
                            </div>
                        </div>
                    </div>
                    <Select
                        allowClear
                        className="m-t-5 test-custom-select createTestModalValue"
                        style={{ width: '100%', minWidth: '40%' }}
                        placeholder="Select marking scheme"
                        value={sectionState?.markingSchemeId?.title}
                        onChange={(value) => { onFillSection(mainIndex, childIndex, "markingSchemeId", value) }}
                    >

                        {storeMarkingSchemeRes?.length && storeMarkingSchemeRes?.map((item) => (
                            <Option value={item?.id} key={item.id}>{item?.title}</Option>
                        ))}
                    </Select>
                </>)}

            {isModalVisible &&
                <CreateMarkingScheme
                    isCreateMarkingSchemeVisible={isModalVisible}
                    setIsCreateMarkingSchemeVisible={setIsModalVisible}
                    onFillSection={onFillSection}
                    mainIndex={mainIndex}
                    childIndex={childIndex}
                    onFillSubjectiveSection={onFillSubjectiveSection}
                />
            }
            {isEditModal &&
                <EditMarkingSchemeModal
                    isEditModalVisible={isEditModal}
                    setIsEditModalVisisble={setIsEditModal}
                    onFillSection={onFillSection}
                    mainIndex={mainIndex}
                    childIndex={childIndex}
                    onFillSubjectiveSection={onFillSubjectiveSection}
                />
            }
        </>
    )
}

const SectionCount = ({ setSectionVisible, sectionVisible, sectionCountChange, setSectionMeta, index, item, sectionMeta, setIsOther }) => {
    return (
        <div className='display-flex' style={{ width: 50 }}>
            <div onClick={() => { setSectionMeta(index + 1); sectionCountChange(index + 1); setSectionVisible(true), setIsOther(false) }}
                className={`text-xmd bold-600 cursor-pointer
             ${sectionMeta == item ? 'purpleBtnWrap' : 'blankGreyBtnWrap'}`}
                style={{ minWidth: 45, height: 45, borderRadius: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #E6E6E6', fontFamily: 'roboto' }}
            >

                {item}</div>

        </div>
    )
}

const SubjectSectionsCard = ({
    fetchMarkingSchemeData, storeMarkingSchemeRes, subjectSectionOnChange, id,
    item, testCreateObj, groupedChapters, setChapterSearchSubjectId, setSearchText,
    setSearchTextIndex, updateHandleRender, storeCreateTestModalData, searchedGroupedChapters,
    createTestModalName, removeSelectedChapter, section, storeSubjectSection,
    mainIndex, loaders, setIsPreviewModalOpen, fromCreateNewButton, setFromSectionMarkingScheme,
    storeMarkedSchemeCardId, }) => {

    const [sectionMeta, setSectionMeta] = useState(null);
    const [subjectSection, setSubjectSection] = useState(storeSubjectSection)
    const [sectionVisible, setSectionVisible] = useState(false);
    const [isCreateMarkingSchemeVisible, setIsCreateMarkingSchemeVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisisble] = useState(false)
    const [isSameMarkingScheme, setIsSameMarkingScheme] = useState(false)
    const [allQuesMandatory, setAllQuesMandatory] = useState(false)
    const [fillMandatory, setMandatory] = useState()
    const [isOther, setIsOther] = useState(false)
    const [storeSection, setStoreSection] = useState(0)
    const [error, setError] = useState(false);

    useEffect(() => {
        setSubjectSection(storeSubjectSection)
    }, [storeSubjectSection])

    useEffect(() => {
        if (!section) {
            setSectionMeta(null);
            setSectionVisible(false);
            setAllQuesMandatory(false)
        }
    }, [section])

    const onFillSubjectiveSection = (index, key, value) => {
        const newSubjectSection = {
            ...subjectSection,
            [key]: value
        }
        setSubjectSection(newSubjectSection);
        subjectSectionOnChange(index, newSubjectSection);
    }
    const onSectionChange = (sectionIndex, value) => {
        const newSectionsArr = [
            ...subjectSection?.sections?.slice(0, sectionIndex),
            value,
            ...subjectSection?.sections?.slice(sectionIndex + 1)
        ];
        if (isSameMarkingScheme) {
            newSectionsArr.forEach((section) => {
                section.markingSchemeId = newSectionsArr[0]?.markingSchemeId;
            })
        }
        onFillSubjectiveSection(mainIndex, "sections", newSectionsArr)

    }
    const sameForAll = () => {
        const newSectionsArr = subjectSection?.sections?.length ? [...subjectSection?.sections] : [];
        if (!isSameMarkingScheme) {
            newSectionsArr.forEach((section) => {
                section.markingSchemeId = newSectionsArr[0]?.markingSchemeId;
            })
        }
        onFillSubjectiveSection(mainIndex, "sections", newSectionsArr)
    }
    const sectionCountChange = (count) => {
        if (subjectSection?.sections?.length > count) {
            onFillSubjectiveSection(mainIndex, 'sections', subjectSection?.sections?.slice(0, count))
        } else if (!subjectSection?.sections || subjectSection?.sections?.length < count) {
            const newSections = Array(count - (subjectSection?.sections ? subjectSection?.sections?.length : 0))?.fill()?.map(el => ({}))
            onFillSubjectiveSection(mainIndex, 'sections', [...(subjectSection?.sections ? subjectSection?.sections : []), ...newSections])
        }
    }
    useEffect(() => {
        if (fillMandatory === undefined || fillMandatory === '') {
            onFillSubjectiveSection(mainIndex, "mandatoryQuestions", storeSubjectSection?.totalQuestions)
        }
        setMandatory(undefined)
    }, [, storeSubjectSection?.totalQuestions,])
    const validateInput = (value) => {
        if (value < 7) {
            setError(true);
            console.log("AA,hree");
        } else {
            setError(false);
            setSectionMeta(parseInt(value)); setSectionVisible(true)
            //   console.log("AA,hree1");
        }
    };

    return (
        <>
            <div style={{ backgroundColor: '#FFFFFF', border: '1px solid #E4E4E4', height: 'fit-content', width: '100%', borderRadius: 8, marginTop: '20px' }}>
                <div className='p-20'>
                    <div className='r-jsb' style={{ width: '100%' }}>
                        <div className='bold-600 text-xmd' style={{ color: '#636363' }}>{item}</div>
                        <div className='display-flex' style={{ marginRight: -5 }}>

                            {section === false ?
                                <></> :
                                <div className='display-flex m-r-20'>
                                    {
                                        sectionMeta && sectionMeta >= 2 ?
                                            <>
                                                <Checkbox style={{ fontSize: 5, marginTop: -3 }} onChange={(e) => { onFillSubjectiveSection(mainIndex, "sameMarkingScheme", e.target.checked), setIsSameMarkingScheme(!isSameMarkingScheme), sameForAll() }}></Checkbox>
                                                <div className='m-l-5 text-xs' style={{ color: '#191919', }}>Same marking scheme for all sections</div>
                                            </>
                                            : null
                                    }
                                </div>
                            }

                            <div className='display-flex' style={{ justifyContent: "flex-end" }}>
                                <Checkbox style={{ fontSize: 5, marginTop: -3 }} checked={allQuesMandatory} /* defaultChecked={(e)=>e.target.checked} */ onChange={(e) => {
                                    setAllQuesMandatory(e.target.checked); onFillSubjectiveSection(mainIndex, "allQuestionMandatory", e.target.checked),
                                        onFillSubjectiveSection(mainIndex, "totalQuestions", e.target.value)

                                }}></Checkbox>
                                <div className='m-l-5 text-xs' style={{ color: '#191919', }}>All questions are mandatory</div>
                            </div>
                        </div>
                    </div>
                    {
                        testCreateObj.syllabus != '' && testCreateObj.syllabus != undefined && testCreateObj.syllabus != 'Full' && testCreateObj?.subjectName?.length > 0 && groupedChapters != undefined && Object.keys(groupedChapters)?.length > 0 && testCreateObj.standardId?.length > 0 ?
                            <>
                                {
                                    Object.keys(groupedChapters)?.map((subjectId) => (
                                        <>
                                            {parseInt(subjectId) === parseInt(id) ?
                                                <div>

                                                    <div className="text-xs color-black bold-600 m-b-5 m-t-20">{testCreateObj.syllabus === "Chapter" ? `${groupedChapters[subjectId]?.subjectName} Chapter` : `${groupedChapters[subjectId]?.subjectName} Chapter(s)`}</div>
                                                    <Select
                                                        disabled={((createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || (createTestModalName === 'test-clone') || testCreateObj.class === '' || testCreateObj.class == undefined || (testCreateObj.syllabus === "Chapter" && testCreateObj.chapterList?.length >= 1)) ? true : false}
                                                        showSearch
                                                        value={testCreateObj.syllabus === 'Chapter' ? `Search ${groupedChapters[subjectId].subjectName} chapter` : `Search ${groupedChapters[subjectId].subjectName} Chapter(s)`}
                                                        className='test-custom-select createTestModalValue'
                                                        placeholder={testCreateObj.syllabus === 'Chapter' ? 'Search chapter' : 'Search Chapter(s)'}
                                                        style={{ width: '100%', color: "#AEAEAE" }}
                                                        defaultActiveFirstOption={false}
                                                        showArrow={true}
                                                        loading={loaders?.modalChapterLoader}
                                                        filterOption={false}
                                                        onSearch={(text) => {
                                                            setChapterSearchSubjectId(subjectId);
                                                            setSearchText(text);
                                                            setSearchTextIndex('chapters');
                                                            updateHandleRender(true)
                                                        }}
                                                        onSelect={(text, value) => {
                                                            storeCreateTestModalData('chapters', { id: value.value[0], name: value.children, subjectId: value.value[1] });
                                                        }
                                                        }
                                                        notFoundContent={null}
                                                    >
                                                        {searchedGroupedChapters[subjectId]?.chapters?.map((item, index) => {
                                                            if (testCreateObj.chapterList?.map((item) => item.id).includes(item.id)) {
                                                                return null
                                                            }
                                                            return <Option className="createTestModalDropdown" key={item.id} value={[item.id, item.subjectId]}>{item.name}</Option>
                                                        })}
                                                    </Select>
                                                </div>
                                                : null}
                                            {testCreateObj.subjectName?.length
                                                ?
                                                <div className='flex-wrap r-ac'>
                                                    {testCreateObj.chapterList?.map((item, index) => {
                                                        if (groupedChapters[subjectId].chapters?.map(chapterObj => chapterObj.id)?.includes(item.id)) {
                                                            return (
                                                                <div>
                                                                    {
                                                                        parseInt(subjectId) === parseInt(id) ?
                                                                            <div key={index} className='shadow-box white p-l-10 p-r-10 p-t-5 p-b-5 m-r-15 m-t-15 r-ac' style={{ background: '#594099' }}>

                                                                                <div className='text-xs'>{item.name}</div>
                                                                                {
                                                                                    (createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || createTestModalName === 'test-clone'
                                                                                        ? null
                                                                                        :
                                                                                        <AiOutlineClose onClick={((createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || (createTestModalName === 'test-clone'))
                                                                                            ? () => { }
                                                                                            : () => { removeSelectedChapter(item.id); }}
                                                                                            className='m-l-10 text-xs cursor-pointer' />
                                                                                }
                                                                            </div> :
                                                                            null}
                                                                </div>
                                                            )
                                                        }
                                                        return null
                                                    })}
                                                </div>
                                                : null}
                                        </>
                                    ))
                                }
                            </>
                            : null
                    }
                    {section == false ?
                        <>

                            <div className='r-jsb m-t-20'>
                                <div className='bold-600 text-xs' style={{ color: '#636363' }}>Marking scheme</div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: "#1089FF", fontWeight: "bold", fontSize: "11px", cursor: "pointer", marginRight: 5 }} onClick={() => { fromCreateNewButton(true); setFromSectionMarkingScheme(false); setIsCreateMarkingSchemeVisible(true) }}><PlusOutlined />{" "}CREATE NEW {" "}</div>
                                    <div className='bold-600 text-xs cursor-pointer' style={{ display: "flex", fontSize: "11px", color: '#636363', fontWeight: "bold" }}>
                                        <div>
                                            {"  "}|{" "}
                                        </div>
                                        <div
                                            style={{ marginLeft: 5, cursor: subjectSection?.markingSchemeId === "" || subjectSection?.markingSchemeId === null || subjectSection?.markingSchemeId === undefined ? "not-allowed" : "pointer" }}
                                            onClick={
                                                () => {
                                                    if (!(subjectSection?.markingSchemeId === "" || subjectSection?.markingSchemeId === null || subjectSection?.markingSchemeId === undefined)) {
                                                        storeMarkedSchemeCardId(subjectSection?.markingSchemeId?.id ? subjectSection?.markingSchemeId?.id : subjectSection?.markingSchemeId);
                                                        setIsEditModalVisisble(true);
                                                        fromCreateNewButton(true)
                                                    } else {

                                                    }
                                                }}>
                                            PREVIEW</div>
                                    </div>
                                </div>
                            </div>
                            <Select
                                allowClear
                                className="m-t-5 test-custom-select createTestModalValue"
                                style={{ width: '100%', minWidth: '40%' }}
                                placeholder="Select marking scheme"
                                value={subjectSection?.markingSchemeId?.title}
                                onChange={(value) => { onFillSubjectiveSection(mainIndex, "markingSchemeId", value) }}
                            >
                                {storeMarkingSchemeRes?.length && storeMarkingSchemeRes?.map((item) => (
                                    <Option value={item?.id} key={item.id}>{item?.title}</Option>
                                ))}
                            </Select>
                            {allQuesMandatory != true ?
                                <>
                                    <div className='r-jsb m-t-10' style={{ width: '100%' }}>
                                        <div className='flex-1 m-r-20'>
                                            <div className='bold-600 text-xs' style={{ color: '#636363', marginBottom: 5 }}>Total questions</div>
                                            <Input
                                                onKeyDown={handleKeyDown}
                                                type="number"
                                                placeholder="Enter value"
                                                className="m-b-15 test-custom-select createTestModalValue"
                                                style={{ width: '100%', minWidth: '40%' }}
                                                onChange={(e) => {
                                                    onFillSubjectiveSection(mainIndex, "totalQuestions", e.target.value)
                                                }}
                                            />
                                        </div>

                                        <div className='flex-1'>
                                            {console.log("AA-mandatory", fillMandatory, storeSubjectSection?.totalQuestions, storeSubjectSection?.mandatoryQuestions)}
                                            <div className='bold-600 text-xs' style={{ color: '#636363', marginBottom: 5 }}>Mandatory questions</div>
                                            <Input
                                                onKeyDown={handleKeyDown}
                                                type="number"
                                                value={fillMandatory == undefined ? storeSubjectSection?.totalQuestions : storeSubjectSection?.mandatoryQuestions}
                                                placeholder="Enter value"
                                                className="m-b-15 test-custom-select createTestModalValue"
                                                style={{ width: '100%', minWidth: '40%' }}
                                                onChange={(e) => {
                                                    if (e.target.value === undefined) {
                                                        setMandatory(undefined)

                                                    }
                                                    setMandatory(e.target.value)
                                                    onFillSubjectiveSection(mainIndex, "mandatoryQuestions", e.target.value)
                                                }}

                                            />
                                        </div>
                                    </div>
                                </>
                                : null}
                        </>
                        : null
                    }
                    {section == true && sectionMeta != 0 ?
                        <>
                            <div className='' style={{ width: '100%' }}>
                                <div className='bold-600 text-xs m-t-20' style={{ color: '#636363', marginBottom: 5 }}>No of sections needed</div>
                                <div className='display-flex'>
                                    {[1, 2, 3, 4, 5, 6,]?.map((item, index) => {
                                        return (
                                            <>
                                                <SectionCount
                                                    sectionMeta={sectionMeta}
                                                    setSectionMeta={setSectionMeta}
                                                    index={index}
                                                    item={item}
                                                    sectionCountChange={sectionCountChange}
                                                    setSectionVisible={setSectionVisible}
                                                    setIsOther={setIsOther}
                                                />
                                            </>
                                        )
                                    })}
                                    <div style={{ minWidth: 50, height: 45, borderRadius: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #E6E6E6', fontFamily: 'roboto' }} onClick={() => { setIsOther(true) }}>others</div>
                                    {isOther ?
                                        <div style={{ marginLeft: "40px", marginTop: "5px" }}>
                                            <Form>
                                                <Form.Item
                                                    label="Input"
                                                    validateStatus={error ? 'error' : ''}
                                                    help={error ? 'Value must be 7 or greater.' : ''}
                                                >
                                                    <Input
                                                        style={{ width: "300px" }} placeholder='Enter values'
                                                        type="number"
                                                        min={6}
                                                        onChange={(e) => { validateInput(e.target.value) }}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            {sectionMeta > 0 ?
                                <>
                                    {Array(sectionMeta)?.fill()?.map((item, index) => {
                                        return (
                                            <>
                                                {
                                                    sectionVisible ?
                                                        <SectionsNeeded
                                                            showFields={allQuesMandatory}
                                                            section={subjectSection?.sections?.[index]}
                                                            childIndex={index}
                                                            mainIndex={mainIndex}
                                                            onFillSubjectiveSection={onFillSubjectiveSection}
                                                            onSectionChange={onSectionChange}
                                                            storeMarkingSchemeRes={storeMarkingSchemeRes}
                                                            fetchMarkingSchemeData={fetchMarkingSchemeData}
                                                            setIsCreateMarkingSchemeVisible={setIsCreateMarkingSchemeVisible}
                                                            isCreateMarkingSchemeVisible={isCreateMarkingSchemeVisible}
                                                            setFromSectionMarkingScheme={setFromSectionMarkingScheme}
                                                            setIsPreviewModalOpen={setIsPreviewModalOpen}
                                                            storeMarkedSchemeCardId={storeMarkedSchemeCardId}
                                                            fromCreateNewButton={fromCreateNewButton}
                                                            isSameMarkingScheme={isSameMarkingScheme}
                                                            setIsSameMarkingScheme={setIsSameMarkingScheme}
                                                            sectionMeta={sectionMeta}
                                                            storeSubjectSection={storeSubjectSection}
                                                        /> : null
                                                }
                                            </>
                                        )
                                    })}
                                </>
                                :
                                <></>}

                        </>
                        : null}
                </div>
            </div>
            {isCreateMarkingSchemeVisible &&
                <CreateMarkingScheme
                    isCreateMarkingSchemeVisible={isCreateMarkingSchemeVisible}
                    setIsCreateMarkingSchemeVisible={setIsCreateMarkingSchemeVisible}
                    onFillSubjectiveSection={onFillSubjectiveSection}
                    mainIndex={mainIndex}
                />
            }
            {isEditModalVisible &&
                <EditMarkingSchemeModal
                    isEditModalVisible={isEditModalVisible}
                    setIsEditModalVisisble={setIsEditModalVisisble}
                    onFillSubjectiveSection={onFillSubjectiveSection}
                    mainIndex={mainIndex}
                />
            }
        </>
    )
}

const CreateTest = ({
    postTestCreate, fetchMarkingSchemeData, storeMarkingSchemeRes,
    storeSubjectSection, setSubjectSectionAction, removeSelectedChapter,
    searchedGroupedChapters, groupedChapters, stdsForCreatingTest,
    removeSelectedSearchSubjs, createTestModalSource,
    getTestClassList, createTestModalName, testCreateObj,
    storeCreateTestModalData, getExaminer, getExaminerArr,
    userType, getSubjects, getIndexOfTest, getSubjectsBool,
    getSubjectsArr, getTestChapterList, selectedClassroom, getExaminerBool,
    loaders, clearTestCreateObj, listViewType, createTestModal,
    fromCreateNewButton, setFromSectionMarkingScheme, storeMarkedSchemeCardId, user, isFromClassRoomDetails, selectedSection }) => {

    const bodyStyle = {
        background: "#FAFAFA",
        width: "calc(100% + 40px)",
        height: "calc(100% + 40px)",
        padding: 20,
        margin: -20,
        color: "FFFFFF",
    };
    const [createSaveLoader, setCreateSaveLoader] = useState(false)
    const [sectionValue, setSectionValue] = useState(false);
    const fromCreateTest = true;
    const PageHeader = () => {
        const history = useHistory();
        return (
            <div style={{ width: '100%' }}>
                <div className='r-jsb p-10' style={{ width: '100%' }}>
                    <div className='bold-700 text-md' style={{ fontFamily: 'roboto' }}> Create Test</div>
                    <div style={{ display: "flex" }}>
                        <Button
                            className='blankGreyBtnWrap radius-100 m-r-30'
                            style={{ width: 120, marginRight: "25px" }}
                            onClick={() => { setSubjectSectionAction([]); history.goBack(); clearTestCreateObj(); }}
                        >CANCEL</Button>
                        <Button
                            loading={createSaveLoader}
                            className='purpleBtnWrap radius-100 purpleButtonHoverEffect'
                            style={{ width: 120 }}
                            onClick={() => {
                                for (let i = 0; i < storeSubjectSection?.length; i++) {
                                    storeSubjectSection[i].markingSchemeId = storeSubjectSection[i].markingSchemeId?.id ? storeSubjectSection[i].markingSchemeId?.id : storeSubjectSection[i].markingSchemeId ? storeSubjectSection[i].markingSchemeId : storeSubjectSection[i]?.schemeId;
                                    storeSubjectSection[i].id = storeSubjectSection[i].subjId ? storeSubjectSection[i].subjId : storeSubjectSection[i].id;
                                    for (let j = 0; j < storeSubjectSection?.[i]?.sections?.length; j++) {
                                        storeSubjectSection[i].sections[j].markingSchemeId = storeSubjectSection[i].sections[j].markingSchemeId?.id ? storeSubjectSection[i].sections[j].markingSchemeId?.id : storeSubjectSection[i].sections[j].markingSchemeId ? storeSubjectSection[i].sections[j].markingSchemeId : storeSubjectSection[i].sections[j].schemeId
                                    }
                                }
                                setCreateSaveLoader(true)
                                postTestCreate((res) => {
                                    history.push({
                                        pathname: '/admin-test',
                                        state: location?.state?.key == "fromClassroom" ? "fromClassroom" : fromCreateTest
                                    });
                                    if (listViewType == 'edit') {
                                        if (createTestModalSource != 'navigation') {
                                            history.push({
                                                pathname: '/admin-test',
                                                state: fromCreateTest
                                            });
                                        }
                                    }
                                    else {
                                        history.push({
                                            pathname: '/admin-test',
                                            state: location?.state?.key == "fromClassroom" ? "fromClassroom" : fromCreateTest
                                        });
                                    }
                                    createTestModal(createTestModalName, userType, '');
                                },
                                    () => {
                                        setCreateSaveLoader(false);
                                    }
                                    , sectionValue
                                );
                                setFromSectionMarkingScheme(false);
                                fromCreateNewButton(false)
                            }} >{createSaveLoader == true ? <></> : <PlusCircleOutlined />}CREATE</Button>
                    </div>
                </div>
            </div>
        )
    }
    const [section, includeSection] = useState(false)
    const [sectionMeta, setSectionMeta] = useState(null)
    const [searchText, setSearchText] = useState('');
    const [searchTextIndex, setSearchTextIndex] = useState('');
    const [handleRender, updateHandleRender] = useState(false);
    const [chapterSearchSubjectId, setChapterSearchSubjectId] = useState(null);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const location = useLocation();
    const isClassScreen = window.location.pathname == '/test-std-to-subjects-screen' ? true : false;
    useEffect(() => {
        if (createTestModalName === 'test-create' && testCreateObj.syllabus != undefined && testCreateObj.syllabus != '' && testCreateObj.subjectName?.length && testCreateObj.class != '' && testCreateObj.class != undefined) {
            getIndexOfTest();
        }
        getExaminer('');
        getSubjects('', isFromClassRoomDetails ? selectedSection?.id : isClassScreen ? selectedClassroom?.id : '');
    }, []);

    useEffect(() => {
        fetchMarkingSchemeData()
    }, [testCreateObj?.testType])

    useEffect(() => {
        if (handleRender) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                if (searchTextIndex === 'examiner') {
                    getExaminer(searchText)
                    updateHandleRender(false);
                } else if (searchTextIndex === 'chapters') {
                    getTestChapterList(searchText, chapterSearchSubjectId);
                    updateHandleRender(false);
                }
                else {
                    getSubjects(searchText, isFromClassRoomDetails ? selectedSection?.id : isClassScreen ? selectedClassroom?.id : '')
                    updateHandleRender(false);
                }
            }, 800);
        }
    }, [searchText, searchTextIndex, handleRender])
    const syllabusTypes = [{ id: 0, type: "Chapter" }, { id: 1, type: "Part" }, { id: 2, type: "Full" }]
    const timerDisable = (dependency) => {
        for (let x of dependency) {
            let bool = testCreateObj[x] === '' || testCreateObj[x] === 'Invalid date' || testCreateObj[x] == undefined
            if (bool) return true
        }
        return false
    }
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day')
    }
    const disabledResultDate = (current) => {
        return current && current < moment(testCreateObj?.heldDate, "DD/MM/YYYY").subtract(1, 'day').endOf('day');
    }
    const disabledStartTimeHours = () => {
        const hours = [];
        if (moment().format('DD/MM/YYYY') === testCreateObj?.heldDate) {
            for (var i = 0; i < moment().hour(); i++) {
                hours.push(i)
            }

        }
        return hours;
    };
    const disabledStartTimeMinutes = (selectedHour) => {
        var minutes = []
        if (selectedHour === moment().hour()) {
            for (var i = 0; i <= moment().minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }
    const disabledEndTimeHours = () => {
        const hours = [];
        for (var i = 0; i < moment(testCreateObj?.heldStartTime, 'HH:mm:ss').hour(); i++) {
            hours.push(i);
        }
        return hours
    }
    const disabledEndTimeMinutes = (selectedHour) => {
        const minutes = [];
        if (selectedHour === moment(testCreateObj.heldStartTime, 'HH:mm:ss').hour()) {
            for (var i = 0; i <= moment(testCreateObj.heldStartTime, 'HH:mm:ss').minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }
    const disabledResultTimeHours = () => {
        const hours = [];
        if (testCreateObj.heldDate === testCreateObj.resultDate) {
            for (var i = 0; i < moment(testCreateObj.heldEndTime, 'HH:mm:ss').hour(); i++) {
                hours.push(i)
            }
        }
        return hours
    }
    const disabledResultTimeMinutes = (selectedHour) => {
        const minutes = [];
        if (testCreateObj.heldDate === testCreateObj.resultDate && selectedHour === moment(testCreateObj.heldEndTime, 'HH:mm:ss').hour()) {
            for (var i = 0; i <= moment(testCreateObj.heldEndTime, 'HH:mm:ss').minute(); i++) {
                minutes.push(i)
            }
        }
        return minutes
    }
    const subjectSectionOnChange = (mainIndex, value) => {
        setSubjectSectionAction([...storeSubjectSection?.slice(0, mainIndex), value, ...storeSubjectSection?.slice(mainIndex + 1)])
    }
    const selectSubject = (value) => {
        storeCreateTestModalData('subjects', { name: value.value, id: value.key });
        setSubjectSectionAction([...storeSubjectSection, { name: value.value, id: value.key }])
    }
    const removeSelectedSubject = (id) => {
        let temp = storeSubjectSection
        temp?.splice(id, 1);
        setSubjectSectionAction([...temp])
    }
    return (
        <div style={bodyStyle}>
            <PageHeader />
            <div className='m-t-30' style={{ overflowY: "scroll", height: "calc(100% + -80px)" }}>
                <div style={{ padding: "0px 100px 0px 100px" }}>
                    <div>
                        <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Question type</div>
                        <Select
                            placeholder="Choose test type"
                            className='test-custom-select createTestModalValue'
                            style={{ width: '100%', borderRadius: 5 }}
                            onChange={(value) => { storeCreateTestModalData('testType', value) }}
                            value={testCreateObj?.testType === "" ? null : testCreateObj?.testType}
                            bordered={true}>
                            <Option value="Objective">Objective type questions</Option>
                            <Option value="Subjective">Subjective type questions</Option>
                        </Select>
                    </div>
                    <div className='m-t-20'>
                        <div className='text-xmd bold-600 m-b-5 m-t-20' style={{ color: '#636363' }}>Title</div>
                        <Input value={testCreateObj?.title} onChange={(e) => storeCreateTestModalData('title', e.target.value)} style={{ borderRadius: 5, width: '100%' }} placeholder="Enter title" bordered={true} />
                    </div>
                    <div className='r-jsb m-t-20' style={{ width: '100%' }}>
                        <div className='flex-1 m-r-20'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Examiner</div>
                            <Select
                                allowClear={true}
                                disabled={user?.name === testCreateObj?.teacherName}
                                className="m-b-15 test-custom-select createTestModalValue"
                                style={{ width: '100%', minWidth: '40%' }}
                                placeholder={"Search examiner"}
                                showSearch
                                loading={getExaminerBool}
                                value={(testCreateObj?.teacherName == '' || testCreateObj?.teacherName == undefined) ? null : testCreateObj?.teacherName}
                                showArrow={userType == 'teacher' ? false : true}
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                notFoundContent={null}
                                onSearch={(text) => { setSearchText(text); setSearchTextIndex('examiner'); updateHandleRender(true) }}
                                onChange={(text, value) => { value ? storeCreateTestModalData('examiner', { name: value.value, id: value.key }) : getExaminer('') }}
                            >
                                {!getExaminerBool && getExaminerArr?.map((item) => {
                                    //   if (item?.id != testCreateObj?.teacherId) {
                                    return <Option key={item?.id} className="createTestModalDropdown" value={item.value}>{item.value}</Option>
                                    //   }
                                    return null
                                })}
                            </Select>
                        </div>
                        <div className='flex-1'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Syllabus</div>
                            <Select
                                className="m-b-15 test-custom-select createTestModalValue"
                                style={{ width: '100%', minWidth: '40%', color: testCreateObj?.syllabus === '' ? '#a2a2a2' : '' }}
                                placeholder={"Select syllabus"}
                                filterOption={false}
                                value={(testCreateObj?.syllabus === '' || testCreateObj?.syllabus == undefined) ? null : testCreateObj?.syllabus}
                                onChange={(value) => {
                                    storeCreateTestModalData('syllabus', value);
                                    if (!isClassScreen) {
                                        storeCreateTestModalData('class', '');
                                    }
                                    storeCreateTestModalData('clearsubjects', null);
                                    storeCreateTestModalData('clearchapters', null);
                                    storeCreateTestModalData('tIndex', 0);
                                    getSubjects('', isFromClassRoomDetails ? selectedSection?.id : isClassScreen ? selectedClassroom?.id : '');
                                    includeSection(false);
                                    setSubjectSectionAction([])
                                }}
                                notFoundContent={null}>
                                {syllabusTypes?.map((item, index) => (
                                    <Option key={item.id} value={item.type} className="createTestModalDropdown">{item.type}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div>
                        <div className='r-jsb' style={{ width: '100%' }}>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Subject(s)</div>
                            <div className='display-flex'>
                                {/* Show sections only if include sections is checked */}
                                <Checkbox checked={section} onChange={(e) => {
                                    includeSection(e.target.checked)
                                    setSectionValue(e.target.checked);
                                }}></Checkbox>
                                <div className='text-xs m-l-5 m-t-5' style={{ color: '#191919' }}>Include sections</div>
                            </div>
                        </div>
                        <Select
                            disabled={(testCreateObj?.syllabus == undefined || testCreateObj?.syllabus === "" || (createTestModalName === 'test-edit' && testCreateObj?.totalQuestions > 0) || (createTestModalName === 'test-clone') || (testCreateObj.syllabus === 'Chapter' && testCreateObj.subjectName?.length >= 1) || (testCreateObj.testType === 'Subjective' && testCreateObj.subjectName?.length >= 1)) ? true : false}
                            className='test-custom-select createTestModalValue'
                            style={{ width: '100%', color: "#AEAEAE" }}
                            placeholder={"Select subject(s)"}
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            showArrow={true}
                            loading={loaders?.modalSubjectLoader}
                            value={null}
                            onSearch={(text) => { setSearchText(text); setSearchTextIndex('subjects'); updateHandleRender(true) }}
                            onSelect={(text, value) => {
                                storeCreateTestModalData('clearchapters', null);
                                if (!testCreateObj?.subjectId?.includes(value.key)) {
                                    //   storeCreateTestModalData('subjects', { name: value.value, id: value.key });
                                    selectSubject(value)
                                    if (!isClassScreen) {
                                        storeCreateTestModalData('class', '');
                                    }
                                    else {
                                        getIndexOfTest();
                                    }
                                    getTestClassList(testCreateObj.subjectId ?? []);
                                    fetchMarkingSchemeData()
                                    if (isClassScreen && testCreateObj.syllabus !== 'Full') {
                                        getTestChapterList();
                                    }
                                }
                                console.log("testCreateObj", testCreateObj)

                            }}
                        >
                            {!getSubjectsBool && getSubjectsArr?.map((item, index) => {
                                if (!testCreateObj?.subjectId?.includes("" + item.id)) {
                                    return <Option className="createTestModalDropdown" key={item?.id} value={item?.value}>{item?.value}</Option>
                                } else {
                                    return null
                                }
                            })}
                        </Select>
                        {typeof testCreateObj?.subjectName !== 'string' ?
                            <>
                                {testCreateObj?.subjectName?.length
                                    ?
                                    <div className='flex-wrap r-ac'>
                                        {testCreateObj.subjectName?.map((item, index) => (
                                            <div key={index} className='shadow-box white p-l-10 p-r-10 p-t-5 p-b-5 m-r-15 m-t-15 r-ac' style={{ background: '#594099' }}>
                                                <div className='text-xs'>{item}</div>
                                                {
                                                    (createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || createTestModalName === 'test-clone'
                                                        ?
                                                        null
                                                        :
                                                        <AiOutlineClose onClick={((createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || (createTestModalName === 'test-clone'))
                                                            ?
                                                            () => { }
                                                            :
                                                            () => {
                                                                removeSelectedSubject(index)
                                                                storeCreateTestModalData('removesubjectchapters', index);
                                                                if (testCreateObj?.class !== 0 && testCreateObj?.subjectId?.length !== 1) {
                                                                    getIndexOfTest();
                                                                }
                                                                if (testCreateObj?.subjectId?.length > 1) {
                                                                    getTestClassList(testCreateObj?.subjectId ?? []);
                                                                    fetchMarkingSchemeData()
                                                                    getTestChapterList();
                                                                }
                                                                if (createTestModalSource !== 'class' && testCreateObj?.subjectId?.length === 1) {
                                                                    storeCreateTestModalData('class', '');
                                                                    storeCreateTestModalData('tIndex', 0);
                                                                }
                                                                removeSelectedSearchSubjs(index, 'removeOne');
                                                            }}
                                                            className='m-l-10 text-xs cursor-pointer' />
                                                }
                                            </div>
                                        ))}
                                    </div>
                                    : null
                                }
                            </>
                            :
                            null
                        }
                    </div>
                    <div className='r-jsb m-t-20' style={{ width: '100%' }}>
                        <div className='flex-1 m-r-20'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Class</div>
                            <Select
                                disabled={((createTestModalName !== 'test-clone') && (isClassScreen || (createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0)
                                    || testCreateObj?.subjectName?.length === 0)
                                ) ? true : false}
                                onChange={(text, value) => {
                                    if (createTestModalName !== 'test-clone') {
                                        storeCreateTestModalData('clearchapters', null);
                                    }
                                    storeCreateTestModalData('standardId', value.key);
                                    storeCreateTestModalData('class', value.value);
                                    getIndexOfTest();
                                    if (createTestModalName !== 'test-clone' && testCreateObj?.syllabus !== 'Full') {
                                        getTestChapterList();
                                    }
                                }}
                                loading={loaders?.modalClassLoader && !isClassScreen}
                                value={testCreateObj?.class === '' ? 'Select class' : isFromClassRoomDetails ? `${selectedSection.std + ' - ' + selectedSection.section}` : testCreateObj?.class}
                                className="m-b-15 test-custom-select createTestModalValue"
                                style={{ width: '100%', minWidth: '40%' }}
                                placeholder={"Search class"}
                                filterOption={false}
                                notFoundContent="No data found"
                            >
                                {stdsForCreatingTest?.map((item, index) => (
                                    <Option className="createTestModalDropdown" key={item.id} value={`${item?.std} - ${item?.section}`}>{`${item?.std} - ${item?.section}`}</Option>
                                ))}
                            </Select>
                        </div>
                        <div className='flex-1'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Index</div>
                            <Input
                                value={testCreateObj?.tIndex === '' ? 0 : testCreateObj?.tIndex}
                                className="m-b-15 test-custom-select createTestModalValue"
                                style={{ width: '100%', minWidth: '40%', borderRadius: 5 }}
                                readOnly
                                disabled={true} />
                        </div>
                    </div>
                    <div className='bold-700 text-xmd m-t-20'>Test to be held on</div>
                    <div className='r-jsb m-t-10' style={{ width: '100%' }}>
                        <div className='flex-1 m-r-20'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Date</div>
                            <DatePicker
                                format='DD/MM/YYYY'
                                className="createmodalbg"
                                style={{ width: '100%', minWidth: '40%' }}
                                dropdownClassName='legend-range-calendar-drpdwn'
                                placeholder={"Set date"}
                                defaultValue={testCreateObj?.heldDate === '' || testCreateObj?.heldDate === 'Invalid date' || testCreateObj?.heldDate === undefined ? '' : moment(testCreateObj?.heldDate, 'DD/MM/YYYY')}
                                showToday={false}
                                allowClear={false}
                                onChange={(e, date) => { storeCreateTestModalData('heldDate', date); selectedTestStartTime = date }}
                                disabledDate={disabledDate}
                            />
                        </div>
                        <div className='flex-1'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Start time</div>
                            <TimePicker
                                minuteStep={10}
                                showNow={false}
                                format={'HH:mm'}
                                inputReadOnly={true}
                                disabled={timerDisable(['heldDate'])}
                                style={{ width: '100%', minWidth: '40%' }}
                                disabledMinutes={disabledStartTimeMinutes}
                                disabledHours={disabledStartTimeHours}
                                className="m-b-5 test-custom-select createTestModalValue createmodalbg"
                                defaultValue={testCreateObj?.heldStartTime === '' ? '' : moment(testCreateObj?.heldStartTime, 'HH:mm:ss')}
                                onChange={(e, time) => {
                                    storeCreateTestModalData('heldStartTime', moment(time, 'HH:mm').format('HH:mm:ss'));
                                    if (time != testCreateObj?.heldStartTime) {
                                        storeCreateTestModalData('heldEndTime', '')
                                        storeCreateTestModalData('resultTime', '')
                                    }
                                }}

                            />

                        </div>
                        <div className='flex-1 m-l-20'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>End time</div>
                            <TimePicker
                                value={(testCreateObj?.heldEndTime === '' || testCreateObj?.heldEndTime === 'Invalid date' || testCreateObj?.heldEndTime === undefined) ? '' : moment(testCreateObj?.heldEndTime, 'HH:mm:ss')}
                                minuteStep={10}
                                format={'HH:mm'}
                                showNow={false}
                                inputReadOnly={true}
                                placeholder={"Set time"}
                                disabledHours={() => disabledEndTimeHours()}
                                disabledMinutes={disabledEndTimeMinutes}
                                style={{ width: '100%', minWidth: '40%' }}
                                disabled={timerDisable(['heldDate', 'heldStartTime'])}
                                className="m-b-5 test-custom-select createTestModalValue createmodalbg"
                                onChange={(e, time) => storeCreateTestModalData('heldEndTime', moment(time, 'HH:mm').format('HH:mm:ss'))}
                            />
                        </div>
                    </div>
                    {testCreateObj.testType == 'Subjective'
                        ?
                        null
                        :
                        <>
                            <div className='bold-700 text-xmd m-t-20'>Result to be published on </div>
                            <div className='r-jsb m-t-10' style={{ width: '100%' }}>
                                <div className='flex-1 m-r-20'>
                                    <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Date</div>
                                    <DatePicker
                                        format='DD/MM/YYYY'
                                        allowClear={false}
                                        showToday={false}
                                        placeholder={"Set date"}
                                        disabledDate={disabledResultDate}
                                        style={{ width: '100%', minWidth: '40%' }}
                                        dropdownClassName='legend-range-calendar-drpdwn'
                                        className="m-b-15 test-custom-select createTestModalValue"
                                        onChange={(e, date) => { storeCreateTestModalData('resultDate', date) }}
                                        disabled={timerDisable(['heldDate', 'heldStartTime', 'heldEndTime'])}
                                        defaultValue={testCreateObj?.resultDate === '' || testCreateObj?.heldDate === 'Invalid date' || testCreateObj?.heldDate === undefined ? '' : moment(testCreateObj?.resultDate, 'DD/MM/YYYY')}
                                    />
                                </div>
                                <div className='flex-1'>
                                    <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Time</div>
                                    <TimePicker
                                        value={testCreateObj?.resultTime === '' ? '' : moment(testCreateObj?.resultTime, 'HH:mm:ss')}
                                        allowClear={false}
                                        showNow={false}
                                        minuteStep={10}
                                        format={'HH:mm'}
                                        inputReadOnly={true}
                                        placeholder={"Set time"}
                                        style={{ width: '100%', minWidth: '40%' }}
                                        disabledMinutes={disabledResultTimeMinutes}
                                        disabledHours={() => disabledResultTimeHours()}
                                        className="m-b-15 test-custom-select createTestModalValue createmodalbg"
                                        disabled={timerDisable(['heldDate', 'heldStartTime', 'heldEndTime', 'resultDate'])}
                                        defaultValue={testCreateObj?.resultTime === '' ? '' : moment(moment(testCreateObj?.resultTime, "HH:mm A").format("HH:mm"), "HH:mm:ss")}
                                        onChange={(e, time) => storeCreateTestModalData('resultTime', moment(time, 'HH:mm').format('HH:mm:ss'))}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    <div>
                        <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Instructions</div>
                        <TextArea
                            style={{ width: '100%' }}
                            defaultValue={testCreateObj?.instruction}
                            placeholder="Add Instructions"
                            className='test-custom-select createTestModalValue'
                            onChange={(e) => storeCreateTestModalData('instruction', e.target.value)}
                        />
                    </div>
                    {typeof testCreateObj?.subjectName !== 'string' ?
                        <>
                            {testCreateObj?.subjectName?.map((item, mainIndex) => {
                                return <SubjectSectionsCard
                                    id={testCreateObj?.subjectId?.[mainIndex]}
                                    item={item}
                                    testCreateObj={testCreateObj}
                                    groupedChapters={groupedChapters}
                                    setChapterSearchSubjectId={setChapterSearchSubjectId}
                                    setSearchText={setSearchText}
                                    setSearchTextIndex={setSearchTextIndex}
                                    updateHandleRender={updateHandleRender}
                                    storeCreateTestModalData={storeCreateTestModalData}
                                    searchedGroupedChapters={searchedGroupedChapters}
                                    createTestModalName={createTestModalName}
                                    removeSelectedChapter={removeSelectedChapter}
                                    section={section}
                                    mainIndex={mainIndex}
                                    storeSubjectSection={storeSubjectSection[mainIndex]}
                                    subjectSectionOnChange={subjectSectionOnChange}
                                    storeMarkingSchemeRes={storeMarkingSchemeRes}
                                    fetchMarkingSchemeData={fetchMarkingSchemeData}
                                    loaders={loaders}
                                    setIsPreviewModalOpen={setIsPreviewModalOpen}
                                    fromCreateNewButton={fromCreateNewButton}
                                    setFromSectionMarkingScheme={setFromSectionMarkingScheme}
                                    storeMarkedSchemeCardId={storeMarkedSchemeCardId}

                                />
                            })
                            }
                        </>
                        :
                        null
                    }
                </div>
            </div>
            {isPreviewModalOpen &&
                <PreviewTestModal
                    isModalOpen={isPreviewModalOpen}
                    setIsModalOpen={setIsPreviewModalOpen}
                />
            }
        </div>
    )
}


const mapStateToProps = state => {
    const { testCreateObj, getExaminerArr, getExaminerBool, getSubjectsBool, getSubjectsArr,
        universalLoaderBool, getIndexOfTestBool, stdsForCreatingTest, createTestModalBool, createTestModalName,
        generateQuesObj, userType, selectedTest, listViewType, createTestModalSource, selectedClassroomId, chaptersForCreatingTest,
        selectedClassroom, loaders, groupedChapters, searchedGroupedChapters, storeSubjectSection, storeMarkingSchemeRes } = state.testAdmin;
    const { user } = state.session;
    const { isFromClassRoomDetails, selectedSection } = state.classRoom
    return {
        testCreateObj, getExaminerArr, getExaminerBool, getSubjectsBool, getSubjectsArr,
        universalLoaderBool, getIndexOfTestBool, stdsForCreatingTest, createTestModalBool, createTestModalName,
        generateQuesObj, userType, selectedTest, listViewType, createTestModalSource, selectedClassroomId, chaptersForCreatingTest,
        selectedClassroom, loaders, groupedChapters, searchedGroupedChapters, storeSubjectSection, storeMarkingSchemeRes, user, isFromClassRoomDetails, selectedSection
    };
};

const mapDispatchToProps = dispatch => ({
    storeCreateTestModalData: (key, value) => dispatch(storeCreateTestModalData(key, value)),
    getSubjects: (text, standardId) => dispatch(getSubjects(text, standardId)),
    getExaminer: (text) => dispatch(getExaminer(text)),
    removeSelectedSearchSubjs: (index, text) => dispatch(removeSelectedSearchSubjs(index, text)),
    postTestCreate: (callback, success, sectionValue) => dispatch(postTestCreate(callback, success, sectionValue)),
    getIndexOfTest: () => dispatch(getIndexOfTest()),
    createTestModal: (name, userType, source) => dispatch(createTestModal(name, userType, source)),
    showGenerateQuesListView: (callback, pagesource) => dispatch(showGenerateQuesListView(callback, pagesource)),
    updateSelectedCardType: (type) => dispatch(updateSelectedCardType(type)),
    getTestClassList: (classIds) => dispatch(getTestClassList(classIds)),
    getTestChapterList: (search, subId, iscreate, stdid) => dispatch(getTestChapterList(search, subId, iscreate, stdid)),
    removeSelectedChapter: (id) => dispatch(removeSelectedChapter(id)),
    setSubjectSectionAction: (val) => dispatch(setSubjectSectionAction(val)),
    fetchMarkingSchemeData: () => dispatch(fetchMarkingSchemeData()),
    clearTestCreateObj: () => dispatch(clearTestCreateObj()),
    fromCreateNewButton: (bool) => dispatch(fromCreateNewButton(bool)),
    setFromSectionMarkingScheme: (val) => dispatch(setFromSectionMarkingScheme(val)),
    storeMarkedSchemeCardId: (val) => dispatch(storeMarkedSchemeCardId(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTest); 
