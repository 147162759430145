import React from 'react';
import {MdAssignment} from "react-icons/md";
import {
  SyncOutlined,
} from '@ant-design/icons';

const NoDataFound = ({loading}) => (
  <div className='r-c-c-c m-t-20'>
    {loading
      ?
      <SyncOutlined spin className='color-lightpurple text-sm' />
      :
      <div className='med-grey text-sm text-bold text-center m-b-20'>
        No data found
      </div>
    }
    <MdAssignment className='color-lightpurple' style={{fontSize: 50}}/>
  </div>
);

export default NoDataFound;
