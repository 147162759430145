import {
  UPDATE_CLASS_RESOURCE, UPDATE_CLASS_DATA, UPDATE_CHATS, TOGGLE_CHAT_LOADER, GET_ROOM_ID_FOR_SIDEMENU, SET_PAGE
} from '../actions/classResources';

const initialState = {
  updateClassLoader: false,
  scheduleInfo: {},
  currentPage: 1,
  chatLoader: false,
  classRoomIdSideMenu: false,
  classRecords: {
    chats: [],
    resources: {}
  }

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CLASS_DATA:
      return {
        ...state,
        page: action.currentPage,
        scheduleInfo: action.extraData,
        classRecords: {
          chats: action.messages,
          resources: action.resources
        }
      };
    case SET_PAGE:
      return {
        ...state,
        currentPage: action.value
      };
    case UPDATE_CLASS_RESOURCE:
      return {
        ...state,
        updateClassLoader: action.status
      };
    case UPDATE_CHATS:
      const chats = state.classRecords.chats.concat(action.chats);
      return {
        ...state,
        classRecords: {...state.classRecords, chats},
        currentPage: action.currentPage,
      }
    case TOGGLE_CHAT_LOADER:
      return {
        ...state,
        chatLoader: action.value
      }
    case GET_ROOM_ID_FOR_SIDEMENU:
      return {
        ...state,
        classRoomIdSideMenu: action.roomId
      }
    default:
      return state;
  }
};

export default reducer;
