import { Api } from "services";
import axios from "axios";

export const UPDATE_ORG_DETAILS_KYC = "UPDATE_ORG_DETAILS_KYC";
export const SHOW_KYC_PENDING_MODAL = "SHOW_KYC_PENDING_MODAL";
export const SHOW_KYC_APPROVAL_PENDING_MODAL = "SHOW_KYC_APPROVAL_PENDING_MODAL";


export const fetchPinCodeDetails = (pin, callback) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const request = {
        method: 'get',
        url: `https://api.postalpincode.in/pincode/${pin}`,
      };
      axios(request)
      .then((response, error) => {
        console.log("APi res", response);
        callback(response.data[0])
      })
      .catch((error, response) => {
        console.log(error);
      });
    });
  };
}

export const showApprovalPendingModal = (bool) => ({
  type: 'SHOW_KYC_APPROVAL_PENDING_MODAL',
  bool
})

export const showKycPendingModal = (bool) => ({
  type: 'SHOW_KYC_PENDING_MODAL',
  bool
})

export const postKycDetails = (params, callback, successCallBack) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
          Api.post("/fee/updateKyc")
            .params(params)
            .send(async (response, error) => {
              callback()
              if(response && response.show && response.show.type === "success"){
                  // history.push('/kyc-form-submitted')
                  successCallBack()
              }
              
            });
        });
      };
}

export const uploadDocument = (file, key, callback, successCallback) => {
    return (dispatch) => {
            const params = {
                file, key, type: "Image"
            }
            console.log("Req", params);
            return Api.post(`/fee/files`).upload(params, (percent, response) => {
              if(response !== undefined){
                console.log(response);
                if(percent === 100){
                    callback();
                    let type = "image"
                    if(response?.data?.file.split('.')[response?.data?.file.split('.').length -1] === "pdf"){
                      type = "pdf"
                    }
                    successCallback(response?.data?.file, type);
                }
              }
              if(percent === -1){
                callback()
            }
              
            });
            
          }
}

const updateOrgDetails = data => ({
    type: UPDATE_ORG_DETAILS_KYC,
    data
})

export const getOrgDetails = (callback, history) => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        Api.get("/fee/kyc")
          .send(async (response, error) => {
            if(response && response.kyc === 'submitted'){
              return history.push('/kyc-form-submitted')
            }
            callback(response?.kyc)
            if (typeof response !== "undefined") {
             dispatch(updateOrgDetails(response))
            }
          });
      });
    };
  };
  