import React, { useCallback, useEffect, useRef, useState } from 'react';
import './style.css';
import { Button, Checkbox, Dropdown, Menu, Pagination, Select } from 'antd';
import { NoData } from 'pages/adminMarketPlaceCourse/courseInformationPage';
import { FilterFilled } from '@ant-design/icons';

const CustomTable = ({ dataSource, columns, rowSelection, rowKey, onRow, locale, setSelectedRowKeys, selectedRowKeys, expandedMoreItemId, expandedDetailsItemId, pageMeta, isLoading, setPage, page, source, handleRowCheckboxSelect, handleMasterCheckboxSelect, setSelectedRowKeysForAssignCourse, hasMarketingPrivilege, rowHighlight, rowClassName, expandedRowClassName, headerClassName }) => {
    const [masterCheckboxChecked, setMasterCheckboxChecked] = useState(false);
    const [selectedFilterOption, setSelectedFilterOption] = useState(null);

    useEffect(() => {
        if (selectedRowKeys?.length === 0) {
            setMasterCheckboxChecked(false);
        } else if (selectedRowKeys?.length === dataSource?.length) {
            setMasterCheckboxChecked(true);
        } else {
            setMasterCheckboxChecked('indeterminate');
        }
    }, [selectedRowKeys, dataSource]);

    const handleMasterCheckboxChange = (e) => {
        let temp = [];
        if (e.target.checked) {
            for (let i = 0; i < dataSource?.length; i++) {
                temp.push(dataSource?.[i]?.id)
            }
        }

        let temp1 = [];
        if (e.target.checked) {
            for (let i = 0; i < dataSource?.length; i++) {
                let obj = {}
                obj['userId'] = dataSource?.[i]?.id
                obj['phone'] = dataSource?.[i]?.phone
                obj['name'] = dataSource?.[i]?.name
                obj['email'] = dataSource?.[i]?.email
                temp1.push(obj)
            }
        }

        setSelectedRowKeys([...temp])
        setSelectedRowKeysForAssignCourse([...temp1])
    };

    const handleRowCheckboxChange = (value) => {
        setSelectedRowKeys((prevSelectedOptions) => {
            const isChecked = prevSelectedOptions.includes(value);
            if (isChecked) {
                return prevSelectedOptions.filter((option) => option !== value);
            } else {
                return [...prevSelectedOptions, value];
            }
        });
    };

    const handleRowCheckboxChange1 = (value) => {
        setSelectedRowKeysForAssignCourse((prevSelectedOptions) => {
            const isChecked = prevSelectedOptions.some(
                (option) => option.phone === value.phone
            );
            if (isChecked) {
                return prevSelectedOptions.filter(
                    (option) => option.phone !== value.phone
                );
            } else {
                return [...prevSelectedOptions, value];
            }
        });
    };

    const FilterFilledDropdown = ({ filterOptions, handleFilterOk }) => {
        const handleOk = () => {
            handleFilterOk(selectedFilterOption);
        };

        const handleReset = () => {
            handleFilterOk(null);
        };

        const handleFilterCheckBox = (e, value) => {
            if (e.target.checked) {
                setSelectedFilterOption(value);
            } else {
                setSelectedFilterOption(null);
            }
        };

        return (
            <Menu className='different-bgc-dropdown' style={{ borderRadius: "8px", padding: "10px 10px", width: "fit-content", boxShadow: "-4px 4px 16px #00000029" }}>
                {filterOptions?.map((filter) => (
                    <div key={filter.value} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <Checkbox onChange={(e) => { handleFilterCheckBox(e, filter.value) }} checked={selectedFilterOption == filter?.value}>
                            {filter.text}
                        </Checkbox>
                    </div>
                ))}
                <div className='r-c-c-c m-t-5 m-b-5' style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
                <div className='r-jsb' style={{ marginTop: '10px' }}>
                    <Button disabled={!selectedFilterOption} size="small" style={{ border: "none", backgroundColor: "#FFFFFF" }} onClick={handleReset}>RESET</Button>
                    <Button type="primary" size="small" onClick={handleOk}>OK</Button>
                </div>
            </Menu>
        );
    }

    const renderTableHeader = () => {
        return (
            <div className={`table-header${headerClassName ? ' '+headerClassName : ''}`} style={{ width: '98.5%', backgroundColor: '#FFFFFF', boxShadow: "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(255, 255, 255, 1)", borderRadius: 16, height: 50, padding: "15px 40px", position: 'relative', display: 'flex', alignItems: 'center', margin: "0px 10px" }}>
                {hasMarketingPrivilege ? <></> :
                    (rowSelection && rowSelection.type === 'checkbox' && (
                        <div className="table-header-cell" style={{ color: '#636363', width: '4%', minWidth: "4%" }}>
                            <Checkbox checked={masterCheckboxChecked === true} indeterminate={masterCheckboxChecked === 'indeterminate'} onChange={handleMasterCheckboxSelect ? handleMasterCheckboxSelect : handleMasterCheckboxChange} />
                        </div>
                    ))
                }

                <div className='r-jsb' style={{ width: rowSelection && rowSelection.type === 'checkbox' ? '96%' : '100%', gap: 20 }}>
                    {columns?.map((column, index) => (
                        <div key={column?.key} className="simgrey bold-500" style={{ color: '#636363', position: 'relative', width: column?.width, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            {column?.title}
                            {column?.handleHeaderClick &&
                                column?.handleHeaderClick?.map((item, idx) => (
                                    <div key={idx} className='m-l-10' onClick={item?.action}>{item?.icon}</div>
                                ))
                            }
                            {column?.filters && column?.filterOutlined && (
                                <Dropdown overlay={<FilterFilledDropdown filterOptions={column?.filters} handleFilterOk={() => column?.onFilter(selectedFilterOption)} />} placement='bottomRight'>
                                    <FilterFilled style={{ position: 'absolute', right: 20, top: 3, cursor: "pointer" }} />
                                </Dropdown>
                            )}
                            {index !== columns.length - 1 && <div style={{ position: 'absolute', height: '100%', backgroundColor: '#AEAEAE', right: 0, top: 0, borderRight: "1.5px solid #D7D7D7" }} />}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const scrollableDivRef = useRef(null);

    useEffect(() => {
        if (page == 1) { }
        else if (scrollableDivRef.current && page > 0) {
            scrollableDivRef.current.scrollTop = page * 200;
        }
    }, [page]);

    const observer = useRef()
    const lastElementRef = useCallback(node => {
        if (isLoading || !node) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && (source !== 'offers' || pageMeta?.currentPage == page ) && pageMeta?.currentPage < pageMeta?.totalPages) {
                setPage(prevPage => prevPage + 1);  
            }
        });
        observer.current.observe(node);
    }, [isLoading, dataSource, pageMeta]);

    const renderTableBody = () => {
        if (!dataSource || dataSource.length === 0) {
            return (
                <div className="r-c-c-c m-t-50">
                    {locale && locale.emptyText ? (
                        <>
                            <div>{locale.emptyText}</div>
                        </>
                    ) : (
                        <div className='r-c-c' style={{ flexDirection: "column" }}>
                            <img style={{ width: 150 }} src={NoData} alt='' />
                            <div className='m-t-20'>No data available!</div>
                        </div>
                    )}
                </div>
            );
        }

        return (
            <div ref={source == "crmTable" ? scrollableDivRef : null} className={`scroll-bar-universal m-t-5 ${source == "crmTable" ? "crm-table-row-parent" : source == "offers" ? "offers-table-row-parent" : "table-row-parent"}`}>
                {dataSource?.map((row, rowIndex) => {
                    const key = rowKey ? row[rowKey] : rowIndex;
                    const rowProps = onRow ? onRow(row) : {};
                    return (
                        <div key={rowIndex} ref={(rowIndex === dataSource?.length - 1) ? lastElementRef : null}>
                            <div key={key} {...rowProps} className={`${rowClassName ? rowClassName + ' ' : ''}${expandedDetailsItemId == row?.id && expandedRowClassName ? expandedRowClassName + ' ' : ''}${!hasMarketingPrivilege ? "cursor-pointer" : ""}`} style={{ width: '98.5%', backgroundColor: selectedRowKeys?.includes(row?.id) ? '#DACFF2' : '#FFFFFF', boxShadow: selectedRowKeys?.includes(row?.id) ? "" : "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(255, 255, 255, 1)", borderRadius: 16, padding: "10px 40px", position: 'relative', margin: rowIndex == 0 ? "10px 10px" : '15px 10px', fontFamily: "roboto", ...(rowHighlight ? { overflow: 'hidden' } : null) }}>
                                {rowHighlight && row.highlightColor ? <div className='row-highlight' style={{ backgroundColor: row.highlightColor }}></div> : null}
                                <div style={{ display: 'flex', alignItems: expandedMoreItemId == row?.id ? "flex-start" : "center", }}>
                                    {hasMarketingPrivilege && source != "crmTable" ? <></>
                                        :
                                        (rowSelection && rowSelection.type === 'checkbox' && (
                                            <div className="table-header-cell" style={{ color: '#636363', width: "4%", minWidth: "4%", marginTop: expandedMoreItemId == row?.id ? 5 : 0 }} onClick={(e) => e.stopPropagation()}>
                                                <Checkbox checked={selectedRowKeys?.includes(row?.id)} onChange={() => { handleRowCheckboxSelect ? handleRowCheckboxSelect(row) : handleRowCheckboxChange(row?.id); if (source == "crmTable") { handleRowCheckboxChange1({ userId: row?.userId, phone: row?.phone, name: row?.name, email: row?.email }) } }} />
                                            </div>
                                        ))
                                    }

                                    <div className='r-jsb' style={{ width: rowSelection && rowSelection.type === 'checkbox' ? '96%' : '100%', gap: 20, alignItems: expandedMoreItemId == row?.id ? "flex-start" : "center" }}>
                                        {columns.map((column, index) => (
                                            <div key={`${key}-${column?.key}`} className="table-cell" style={{ color: '#191919', position: 'relative', width: column?.width, display: 'flex', justifyContent: 'flex-start', overflowX: 'hidden' }}>
                                                {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    {expandedDetailsItemId == row?.id &&
                                        <>
                                            {columns.map((column, index) => (
                                                <div>
                                                    {column.renderMore ? column.renderMore(row) : null}
                                                </div>
                                            ))}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    );
                })}
                {isLoading &&
                    <div className='r-c-c-c' style={{ height: 50 }}>
                        <img src={require('../../Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                    </div>
                }
            </div>
        );
    };

    return (
        <div className={`custom-table${source == 'offers' ? ' offers-custom-table' : ''}`}>
            {renderTableHeader()}
            {renderTableBody()}
        </div>
    );
};

export default CustomTable;