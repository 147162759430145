import React, { useEffect, useState } from 'react'
import "./prerequest.css"
import { LinesCarousel } from '../lineCarousel';
import { Row } from 'antd';

function Prerequests({ singleCourseDetailsDataContainer, fromMyCourcesPage }) {
    console.log("singleCourseDetailsDataContainer", singleCourseDetailsDataContainer)
    const [currentIndex, setCurrentIndex] = useState(0);
    const chunkSize = 4;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % totalChunks);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const learnings = singleCourseDetailsDataContainer || [];
    const totalChunks = Math.ceil(learnings.length / chunkSize);
    const startIdx = currentIndex * chunkSize;
    const endIdx = Math.min(startIdx + chunkSize, learnings.length);
    
    const currentChunk = learnings?.slice(startIdx, endIdx);
    // const currentChunk = learnings?.learnings?.slice(startIdx, endIdx);

    return (
        <div style={{ marginTop: 50 }}>
            <div className={`${fromMyCourcesPage ? 'bodyStyle' : 'course-details-learnings-parent'}`}>
                <div style={{ border: "1px solid #E6E6E6", backgroundColor: "#FFFFFF", padding: 20 }}>
                    <div style={{ color: "#191919", fontWeight: "bold", fontSize: 20 }}>Prerequisites</div>
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                        {fromMyCourcesPage ?
                            singleCourseDetailsDataContainer?.prerequisites?.map((item, index) => (
                                <div className='r-c-fs' style={{ alignItems: "baseline", width: singleCourseDetailsDataContainer?.prerequisites?.length == 1 ? "100%" : "49%" }}>
                                    <span className='list-dot' style={{ height: 5, width: 5 }}></span>
                                    <div key={index} className='pre-list' style={{ flexBasis: "100%" }}>{item?.name}</div>
                                </div>
                            ))
                            :
                            singleCourseDetailsDataContainer?.map((item, index) => (
                                <div className='r-c-fs' style={{ alignItems: "baseline", width: singleCourseDetailsDataContainer?.length == 1 ? "100%" : "49%" }}>
                                    <span className='list-dot'></span>
                                    <div key={index} className='pre-list' style={{ flexBasis: "100%" }}>{item?.name}</div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            <div className='course-details-learnings-mobile'>
                <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18, marginBottom: 20 }}>Prerequisites</div>
                <LinesCarousel>
                    <Row style={{ height: "fit-content" }}>
                        <div style={{ border: "1px solid #E6E6E6", backgroundColor: "#FFFFFF", padding: 15 }}>

                            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                                {currentChunk?.map((item, index) => (
                                    <div className='r-c-fs' style={{ alignItems: "baseline" }}>
                                        <span className='list-dot'></span>
                                        <div key={index} className='pre-list' style={{ flexBasis: "100%" }}>{item?.name}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Row>
                    {totalChunks > 1 && (
                        <Row className='arrow-button'>
                            <div className='lines'>
                                {Array(totalChunks)?.fill()?.map((_, index) => (
                                    <div
                                        key={index}
                                        className={currentIndex === index ? 'blueline' : 'greyline'}
                                        onClick={() => setCurrentIndex(index)}
                                    ></div>
                                ))}
                            </div>
                        </Row>
                    )}
                </LinesCarousel>
            </div>
        </div>
    )
}


export default Prerequests