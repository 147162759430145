import { DatePicker, Modal, Select } from 'antd'
import { Button } from 'components/Button'
import React, { useEffect, useState } from 'react'
import PieCharts from './pieCharts';
import { CaretLeftOutlined, CaretRightOutlined, LeftCircleFilled, LeftCircleOutlined, RightCircleFilled, RightCircleOutlined } from '@ant-design/icons';
import SwitchComponent from './switchComponent';
import GroupedBarPlot from './groupedBarPlot';
import moment from 'moment';

function BarPlotModal({ pieChartModal, setPieChartModal, colors, courseData, stdColors, studentData, graphData, courseType, isChecked, setIsMonth, setIsChecked, setIsYear, isMonth, isYear, setCourseType, data, currentPie, setCurrentPie }) {
    const handleCancel = () => {
        setPieChartModal(false);
    };





    const handleNext = () => {
        setCurrentPie((prevPie) => (prevPie % 2) + 1);
    };

    const handlePrev = () => {
        setCurrentPie((prevPie) => (prevPie === 1 ? 2 : prevPie - 1));
    };
    return (
        <div>
            <Modal width={800} open={pieChartModal} onOk={handleCancel} onCancel={handleCancel} footer={null} bodyStyle={{ padding: 40 }} className="modal-round-corner">
                <center style={{ fontSize: 20, fontWeight: 650 }}> Course types</center>
                <div>
                    <div style={{ width: "100%", display: `${currentPie == 2 ? '' : "flex"}`, justifyContent: "space-around", alignItems: "center" }}>
                        {currentPie == 1 ? null :
                            <div><LeftCircleFilled style={{ fontSize: "25px", color: "#fff", marginTop: `${currentPie == 2 ? '200px' : "-40px"}`, position: "absolute", marginLeft: "-80px" }} onClick={() => { handlePrev() }} /></div>}
                        {currentPie === 1 && (<>
                            <div style={{ width: "50%" }}>
                                <div className='r-c-c-c'>
                                    <div style={{ width: "300px", height: "250px" }}>
                                        <PieCharts color={colors} data={courseData?.length != 0 ? courseData : []} />
                                    </div>
                                </div>
                                <div style={{ fontSize: "14px", fontWeight: 500, color: "#191919", paddingLeft: "20px" }}>Course distribution</div>
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: '90%', marginLeft: "20px" }}>
                                    {courseData?.map((item) => {
                                        return (
                                            <div style={{ display: "flex", gap: 5, marginRight: "10px", marginTop: "5px", width: "45%" }}>
                                                {item?.type == undefined ? null : (<>
                                                    <div style={{ height: "12px", width: "12px", backgroundColor: `${item?.type == 'liveCourse' ? "#B59009" : item?.type == 'videoResource' ? "#DAAB00" : item?.type == 'testSeries' ? "#E9CC64" : item?.type == 'studyMaterial' ? "#F9E7A3" : null}` }}></div>
                                                    <div style={{ color: "#AEAEAE", fontSize: "10px", marginTop: "-3px", fontWeight: 500 }}>{item?.type == 'liveCourse' ? (<><span>Live courses</span> ({item?.count})</>) : item?.type == 'videoResource' ? (<><span>Video courses</span> ({item?.count})</>) : item?.type == 'testSeries' ? (<><span>Test series</span> ({item?.count})</>) : item?.type == 'studyMaterial' ? (<><span >Study materials</span> ({item?.count})</>) : null}</div></>)}

                                            </div>

                                        )
                                    })}</div>
                            </div>

                            <div style={{ width: "50%" }}>
                                <div className='r-c-c-c'>
                                    <div style={{ width: "300px", height: "250px" }}>
                                        <PieCharts color={stdColors} data={studentData?.length != 0 ? studentData : []} />
                                    </div>
                                </div>
                                <div style={{ fontSize: "14px", fontWeight: 500, color: "#191919", paddingLeft: "20px" }}>Students distribution</div>
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: '90%', marginLeft: "20px" }}>
                                    {studentData?.map((item) => {
                                        return (
                                            <div style={{ display: "flex", gap: 5, marginRight: "10px", marginTop: "5px", width: "45%" }}>
                                                {item?.type == undefined ? null : (<>
                                                    <div style={{ height: "12px", width: "12px", backgroundColor: `${item?.type == 'liveCourse' ? "#594099" : item?.type == 'videoResource' ? "#967DD5" : item?.type == 'testSeries' ? "#C3B1EC" : item?.type == 'studyMaterial' ? "#DED5F5" : null}` }}></div>
                                                    <div style={{ color: "#AEAEAE", fontSize: "10px", marginTop: "-3px", fontWeight: 500 }}>{item?.type == 'liveCourse' ? (<><span>Live courses</span> ({item?.count})</>) : item?.type == 'videoResource' ? (<><span>Video courses</span> ({item?.count})</>) : item?.type == 'testSeries' ? (<><span>Test series</span> ({item?.count})</>) : item?.type == 'studyMaterial' ? (<><span >Study materials</span> ({item?.count})</>) : null}</div></>)}
                                            </div>
                                        )
                                    })}</div>
                            </div></>)}
                        {currentPie === 2 &&
                            (<>
                                <div style={{ marginTop: "20px" }}>
                                    <div >
                                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                            <div style={{ marginLeft: "-20px" }}>
                                                <Select
                                                    bordered={null}
                                                    placeholder="Select plan"
                                                    value={courseType}
                                                    onChange={(e) => {
                                                        setCourseType(e)
                                                    }}
                                                >
                                                    <Option value="liveCourse">Live course</Option>
                                                    <Option value="videoResource">Video course</Option>
                                                    <Option value="studyMaterial">Study material</Option>
                                                    <Option value="testSeries">Online test series</Option>
                                                </Select>
                                            </div>
                                            <div style={{ display: "flex", gap: 30 }}>
                                                {!isChecked ?
                                                    <DatePicker
                                                        allowClear={false}
                                                        picker="month"
                                                        format={'MMM'}
                                                        value={moment(isMonth, 'MMM')}
                                                        style={{ borderRadius: "50px", width: "80px", height: "25px" }}
                                                        onChange={(date, dateString) => {
                                                            setIsMonth(moment(date).format('MMM'))
                                                        }} /> :
                                                    <DatePicker
                                                        allowClear={false}

                                                        picker="year"
                                                        value={moment(isYear)}
                                                        style={{ borderRadius: "50px", width: "80px", height: "25px" }}
                                                        onChange={(date, dateString) => {
                                                            setIsYear(moment(date).format('YYYY'))

                                                        }}
                                                    />}
                                                <SwitchComponent
                                                    setIsChecked={setIsChecked}
                                                    isChecked={isChecked} />
                                                {/* <FullscreenOutlined style={{ display: "flex", justifyContent: "right", paddingRight: "10px", fontSize: "20px", marginTop: "3px" }} onClick={() => { setBarChartModal(true) }} /> */}

                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: "20px" }}> <div style={{ width: "90%" }}>
                                        <GroupedBarPlot data={graphData?.length != 0 ? graphData : []} value1={'modal'} />
                                    </div></div>
                                    <div style={{ position: "absolute", marginTop: "10px" }}>
                                        <div style={{ display: "flex", gap: 10 }}>
                                            <div style={{ height: "13px", width: "13px", backgroundColor: "#594099" }}></div>
                                            <div style={{ color: "#AEAEAE", fontSize: "11px", marginTop: "-3px", fontWeight: 500 }}> Revenue (₹)</div>
                                        </div>
                                        {/* <div style={{ display: "flex", gap: 10, marginTop: "3px" }}>
                                            <div style={{ height: "12px", width: "12px", backgroundColor: "#DFDEF5" }}></div>
                                            <div style={{ color: "#AEAEAE", fontSize: "10px", marginTop: "-3px", fontWeight: 500 }}> Popularity</div>
                                        </div> */}
                                    </div>

                                </div>
                            </>)}
                        {currentPie == 2 ? null :
                            <div><RightCircleFilled style={{ color: "#fff", fontSize: "25px", marginTop: "40px", position: "absolute", marginLeft: "60px" }} onClick={() => { handleNext() }} /></div>}
                    </div>
                </div>

                <div className='r-c-sa m-t-50'><Button onClick={handleCancel} type="secondary">CLOSE</Button></div>
            </Modal>
        </div>
    )
}

export default BarPlotModal