import { showMenu } from 'actions/layout'
import { applyPromoCheckout, checkLiveCourse, fetchPromoCodes, getCheckOutItem, getCheckPayment, getCouponList, payNowCart, postAddSaveForLater, postAddSaveItem, postCartPayment, removeSaveItem, setCouponInCart, setPromoCode, setSelectedCouponCode, setStorePayTerm, setStorePromoPrice, setValueForCheckout, storeCourseIdAndType } from 'actions/studentMarketPlace'
import { Input, Tooltip } from 'antd'
import { Button } from 'components/Button'
import PageDataLoader from 'components/pageDataLoader'
import moment from 'moment'
import { generatePaymentRequest } from 'pages/profile/paymentGateway'
import React, { useEffect, useState } from 'react'
import HtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Api } from 'services'
import saveFill from "../../Assets/aulasMarket/saveFilledIcon.svg"
import saveOutlined from "../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import CouponsModal from './couponsModal'
import { InvalidCouponMessage, InvalidCouponType } from './constants/studentMarketPlace';
import CourseInstallmentModal from './coursePageCompo/courseInstallment'
import Footer from './footer'
import MobileCheckOut from './mobileCheckOut'
import MobilePageHeader from './mobilePageHeader'
import RatingStars from './ratingStars'
import "./saveForLater.css"
import "./studentCart.css"
import TopBar from './topBar'
import NewFooter from './newFooter'
import DownloadAppModal from './downloadAppModal'
const defaultCourseImage = require('Assets/default_course-img.svg').default;

function StudentCheckOut({ singleBuyNowDetails, setStorePayTerm, storePayTerm, selectedTerm, setStorePromoPrice,
    termList, couponList, setSelectedCouponCode, selectedCouponCode, checkLiveCourse, getCouponList, removeSaveItem,
    postAddSaveItem, applyPromoCheckout, postCartPayment, getCheckPayment, couponInCart, postAddSaveForLater, fetchPromoCodes,
    promoCodeDetials, user, cartDetails, payNowCart, setLoader, loginDetails, setCouponInCart, storePromoPrice, offersCarouselData,
    showMenu, setValueForCheckout, getCheckOutItem, isDownloadBarVisible, showContactBar, storeCourseIdAndType, isOffersBarVisible, userMarketPlaceHomeData }) {
    const [applyLoader, toggleApplyLoader] = useState(false);
    const [paynowLoader, togglePaynowLoader] = useState(false);
    const history = useHistory();
    const [payOfferCode, setPayOfferCode] = useState(null);
    const [isCouponModal, setIsCouponModal] = useState(false);
    const [isInputTyped, setIsInputTyped] = useState(false);
    const [isInstallmentVisible, setInstallmentVisible] = useState(false);
    const location = useLocation();
    const [pageLoader, togglePageLoader] = useState(false);
    const { itemId, itemType, closeInstallmentModal, promo } = location.state || {};
    const [isPayTerm, setIsPayTerm] = useState(singleBuyNowDetails?.billingPlan);
    const [invalidCouponType, setInvalidCouponType] = useState(null);
    const [disablePay, setDisablePay] = useState(false);
    const [showDownloadAppModal, setShowDownloadAppModal] = useState(false)
    const [fromCheckoutPage, setFromCheckoutPage] = useState(false)

    let couponParm = {
        checkout: true,
        itemType: itemType,
        itemId: itemId,
        disablePagination: true,
        updatedCourseBilling: true
    };

    useEffect(() => {
        if (invalidCouponType) {
            setDisablePay(true);
        } else {
            setDisablePay(false);
        }
    }, [invalidCouponType])

    useEffect(() => {
        if (selectedCouponCode) {
            setPayOfferCode(selectedCouponCode);
        } else {
            setPayOfferCode(null);
        }
    }, [selectedCouponCode])
    useEffect(() => {
        if (selectedTerm) {
            setIsPayTerm(selectedTerm);
            setStorePayTerm(selectedTerm);
        } else {
            setIsPayTerm(singleBuyNowDetails?.billingPlan)
            setStorePayTerm(singleBuyNowDetails?.billingPlan)
        }
    }, [selectedTerm, singleBuyNowDetails?.billingPlan])

    useEffect(() => {
        closeInstallmentModal === true ? setInstallmentVisible(false) : setInstallmentVisible(false);
    }, [closeInstallmentModal]);

    useEffect(() => {
        // setSelectedCouponCode(null);
        togglePageLoader(true);
        const id = itemId || singleBuyNowDetails?.item?.[0]?.id;
        const type = itemType || singleBuyNowDetails?.item?.[0]?.resourceType;
        getCheckOutItem(
            id,
            type,
            () => {
                if (promo) {
                    let params = {
                        code: promo,
                        type: 'offer',
                        id,
                        itemType: type,
                    };
                    applyPromoCheckout(params, (res) => {
                        toggleApplyLoader(false);
                        togglePageLoader(false);
                        if (res) { setInvalidCouponType(null); setPayOfferCode(promo) } else { setPayOfferCode(null) }
                    }, () => {
                        setCouponInCart(true);
                    }, (err) => {
                        if (err.invalid) {
                            setInvalidCouponType(InvalidCouponType.invalid);
                        } else if (err.expired) {
                            setInvalidCouponType(InvalidCouponType.expired);
                        } else if (err.minPurchase) {
                            setInvalidCouponType(InvalidCouponType.minPurchase);
                        } else if (err.usageLimit) {
                            setInvalidCouponType(InvalidCouponType.usageLimit);
                        }
                    });
                } else {
                    togglePageLoader(false);
                }
            })
    }, [])

    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;

        for (var c = a.childNodes, i = c.length; i--;) {
            if (c[i].nodeType === 1) return true;
        }
        return false;
    }
    console.log("selectedTermNaa", selectedCouponCode, payOfferCode)
    useEffect(() => {
        fetchPromoCodes(() => { });
        setCouponInCart(false)
        setStorePromoPrice(null)
        setPayOfferCode(null);
        setSelectedCouponCode(null);
    }, []);



    useEffect(() => {
        if (itemId || itemType) {
            getCouponList(couponParm);
        }
    }, [itemId, itemType])

    const [isCourseInCart, setIsCourseInCart] = useState(false);

    let foundCourse = false;
    useEffect(() => {
        if (Array.isArray(singleBuyNowDetails?.items)) {

            for (const item of singleBuyNowDetails?.items) {
                if (item?.itemType === "course") {
                    foundCourse = true;
                    break; // Exit the loop once a course is found
                }
            }

            setIsCourseInCart(foundCourse);
        }
    }, [singleBuyNowDetails]);

    // useEffect(() => {
    //     if (singleBuyNowDetails?.item?.[0]?.id) {
    //         setStorePromoPrice(false);
    //     }
    // }, [])

    console.log("storePayTerm", storePayTerm, isPayTerm)
    const CartCard = ({ item, key }) => {
        const [saved, setSaved] = useState({});
        const handleSaveForLater = (id, itemType) => {
            if (saved[id]) {
                removeSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: false,
                        }));
                    }
                });
            } else {
                postAddSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: true,
                        }));
                    }
                });
            }
        };
        return (
            <div className='cart-parent-container' >
                <div className='cart-img-card-parent'
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        storeCourseIdAndType("id", item?.id);
                        storeCourseIdAndType("type", item?.resourceType)
                        history.push({
                            pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                            state: {
                                id: item?.id,
                                resourceType: item?.resourceType,
                            }
                        })

                    }}>
                    {item?.resourceType == 'studyMaterial' ?
                        (<>
                            <div style={{ height: "100px", paddingTop: '10px' }} >
                                <div style={{ color: "#594099", fontSize: 16, marginLeft: 2, marginTop: "9px", }}>
                                    <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50, marginRight: "20px" }} />
                                    {item?.m_count} {item?.m_count <= 1 ? "Material" : "Materials"}
                                    {item?.q_count > 0 ? `, ${item?.q_count} ${item?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}</div>
                            </div>
                        </>) :
                        <img className='cart-img-card' src={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} />

                    }
                    {item?.mode ?
                        <div style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", position: "absolute", fontWeight: "bold", top: 15, right: 0 }}>{item?.mode?.charAt(0).toUpperCase() + item?.mode?.slice(1)}</div>
                        : <></>}
                    {item?.amount > 0 ? <div className='card-amount-label'>₹ {(parseFloat(item?.amount) - parseFloat(item?.discountAmount)).toFixed(2)}{" "}{item?.discountAmount > 0 ? <span style={{ color: "#FF414D", textDecoration: 'line-through' }}>₹ {parseFloat(item?.amount || 0)}</span> : <></>}</div> : <></>}

                </div>
                <div className='cart-title-parent' >
                    <div className='cart-card-title'>{item?.resourceType == 'course' || item?.resourceType == "videoResource" ? item?.courseName : item?.name}
                        {item?.newTag === "0" ? null :
                            <span className='mobile-new-tag m-l-5'>NEW</span>
                        }
                    </div>
                    <div className='cart-card-description' >
                        <Tooltip title={IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}>
                            {IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}
                        </Tooltip>
                    </div>

                    <div className='r-c-sb m-t-10 mobile-disable'>
                        <div className='r-c-fs' style={{ width: "100%" }} >
                            {item?.bestseller ?
                                <div className='cart-best-seller m-r-10'>Bestseller</div>
                                : null}
                            {item?.rating > 0 ?
                                <div className='r-c-fs '><RatingStars star={item?.rating} />
                                    <span className='m-l-5'>({item?.ratingCount})</span>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className='mobile-enable'>
                        <div className='cart-seller-btn'  >
                            <div className='cart-best-seller'>Bestseller</div>
                            <div className='mobile-rating'><img className='mobile-star' src={require("../../Assets/aulasMarket/ic-star-fill.svg").default} /><span style={{ color: "#191919", fontWeight: "550" }}>{item?.rating}</span>
                                <span style={{ marginLeft: 3 }}>({item?.ratingCount})</span>
                            </div>
                        </div>
                        <div className="r-c-fe m-l-10">
                            {item?.owned == 1 ? <></> :
                                <span style={{ marginRight: 10, fontWeight: 600, color: "#594099", fontSize: 12, cursor: "pointer" }}>
                                    <img className='cart-delete-outline' onClick={(e) => { e.stopPropagation(); handleSaveForLater(item?.id, item?.resourceType) }} src={saved[item?.id] || item?.inSaved == "1" ? saveFill : saveOutlined} />
                                </span>
                            }
                            {/* <span style={{ fontWeight: 600, color: "#FF414D", fontSize: 12, cursor: "pointer" }}>REMOVE</span> */}
                        </div>
                    </div>
                    {item?.amount > 0 && (
                        <div className='mobile-enable m-t-10 font-bold' style={{ fontSize: 12 }}>
                            ₹ {(parseFloat(item?.amount) - parseFloat(item?.discountAmount)).toFixed(2)}
                            {item?.discountAmount > 0 && (
                                <>
                                    <span style={{ color: '#636363', marginLeft: 5, textDecoration: 'line-through', fontWeight: "normal", fontSize: 10 }}>
                                        ₹ {(parseFloat(item?.amount).toFixed(2))}
                                    </span>
                                    <span style={{ color: "#28DF99", marginLeft: 5, fontSize: 10, fontWeight: "normal", fontSize: 10 }}>{(parseFloat(item?.discountPercent).toFixed(0))}% off</span>
                                </>
                            )}
                        </div>
                    )}
                    <div className='m-t-15 mobile-disable'>
                        <span onClick={() => {
                            postAddSaveForLater(item?.id, item?.resourceType, () => { })
                        }} style={{ marginRight: 20, fontWeight: 'bold', color: "#191919", fontSize: 12, cursor: "pointer" }}>SAVE FOR LATER</span>
                    </div>
                </div>
            </div>
        )
    }


    const generateOrderId = user?.orgId + user?.id + Math.floor(Math.random() * 100) + moment().format('SS');
    let fromMarket = true;


    const numberWithCommas = (x) => {
        console.log('xx', x);
        return x?.toString().split('.')[0].length > 3 ? x?.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x?.toString().substring(x.toString().split('.')[0].length - 3) : x?.toString();
    }
    const totalAmount = storePromoPrice?.cart?.amount - storePromoPrice?.cart?.discount || storePromoPrice?.amount - storePromoPrice?.discount;
    const totalAmountBeforeDiscount = singleBuyNowDetails?.amount - singleBuyNowDetails?.discount;


    const isLiveParam = {
        userId: user?.id,
        userAccessToken: user?.accessToken
    }
    const checkDiffTotalAmt = couponInCart ? storePromoPrice?.amount : singleBuyNowDetails?.amount || null
    console.log("payOfferCode", isPayTerm, singleBuyNowDetails?.billingPlan)
    console.log("singleBuyNowDetails123", singleBuyNowDetails)

    const handlePayNow = () => {
        setValueForCheckout(true)
        togglePaynowLoader(true)
        if (singleBuyNowDetails?.item && singleBuyNowDetails.item.length > 0) {
            const firstItem = singleBuyNowDetails.item?.[0] || null;
            let paramsCheckPayment = {
                // amount: singleBuyNowDetails?.totalAmount || null,
                amount: storePromoPrice ? storePromoPrice?.totalAmount : singleBuyNowDetails?.totalAmount ? singleBuyNowDetails?.totalAmount : null,
                checkout: true,
                itemId: firstItem?.id,
                itemType: firstItem?.resourceType,
                code:  selectedCouponCode || undefined,
                billingPlan: singleBuyNowDetails?.item?.[0]?.resourceType === "course" ? isPayTerm ? isPayTerm : null : singleBuyNowDetails?.billingPlan,
                updatedCourseBilling: true,
                planName: isPayTerm
            }
            let paramsPay = {
                orderAmount: paramsCheckPayment.amount,
                orderCurrency: 'INR',
                orderId: generateOrderId.toString() || null,
                customerDetails: {
                    customer_id: user?.id?.toString() || null,
                    customer_email: user?.email || null,
                    // customer_phone: user?.phone?.toString() || null
                    customer_phone: '+916360068158'
                }
            };

            if (storePromoPrice?.totalAmount == 0 || singleBuyNowDetails?.totalAmount == 0) {
                let paramProcess = {
                    checkout: true,
                    type: paramsCheckPayment?.itemType,
                    id: paramsCheckPayment?.itemId,
                    promo: payOfferCode || selectedCouponCode || undefined,
                    free: true,
                    updatedCourseBilling: true,
                    planName: isPayTerm,
                    billingPlan: singleBuyNowDetails?.item?.[0]?.resourceType === "course" ? isPayTerm ? isPayTerm : null : singleBuyNowDetails?.billingPlan,

                }

                postCartPayment(paramProcess, () => {
                    togglePaynowLoader(false)
                    if (singleBuyNowDetails?.item?.[0]?.resourceType === "course") {
                        checkLiveCourse(isLiveParam);
                    }
                    // showMenu(true)
                    // history.push("/courses-list")
                    setFromCheckoutPage(true)
                    setShowDownloadAppModal(true)
                    togglePaynowLoader(false)
                })
                togglePaynowLoader(false)

            } else {
                setPromoCode(selectedCouponCode || '');
                getCheckPayment(paramsCheckPayment, (res) => {
                    togglePaynowLoader(false)
                    if (res?.valid) {
                        payNowCart(paramsPay, (response) => {
                            togglePaynowLoader(false)
                            generatePaymentRequest(response, null, user, fromMarket)
                            togglePaynowLoader(false)
                        }, selectedCouponCode || '')
                    }
                    togglePaynowLoader(false)
                })

            }
        }
    }
    const handleApplyClick = () => {

        if (singleBuyNowDetails?.item && singleBuyNowDetails.item.length > 0) {
            const firstItem = singleBuyNowDetails.item[0];
            let params = {
                code: payOfferCode,
                type: 'offer',
                id: firstItem?.id,
                itemType: firstItem?.resourceType,
            };
            toggleApplyLoader(true);
            applyPromoCheckout(params, (res) => {
                toggleApplyLoader(false);
                if (res) { setInvalidCouponType(null) }
            }, () => {
                setCouponInCart(true);
                toggleApplyLoader(false)
            }, (err) => {
                if (err.invalid) {
                    setInvalidCouponType(InvalidCouponType.invalid);
                } else if (err.expired) {
                    setInvalidCouponType(InvalidCouponType.expired);
                } else if (err.minPurchase) {
                    setInvalidCouponType(InvalidCouponType.minPurchase);
                } else if (err.usageLimit) {
                    setInvalidCouponType(InvalidCouponType.usageLimit);
                }
            });
        }
    }
    const handleInputCode = (e) => {
        const filteredValue = e.target.value.replace(/[^A-Za-z0-9]/g, ''); // Allow only alphanumeric characters
        setPayOfferCode(filteredValue);
        setIsInputTyped(filteredValue.trim() !== '');
        setInvalidCouponType(null);
    }
    const numberOfInstallments = termList?.length || singleBuyNowDetails?.installmentCount || 0;

    // Determine the term to use based on the number of installments
    const installmentTerm = numberOfInstallments === 1 ? 'installment' : 'installments';
    const PriceDetails = () => {
        return (
            <div className='price-parent-container'>
                <div className='cart-price-details-title'>PRICE DETAILS</div>
                <div className='price-border'></div>
                {singleBuyNowDetails?.item?.[0]?.resourceType === "course" ?
                    <>
                        <div className='' style={{ fontSize: "14px", fontWeight: "bold", padding: "10px 20px" }}>Pay after registration</div>
                        <div className='r-c-sb cart-price' style={{ padding: "2px 20px" }} >
                            <div>Balance after registration and discount</div>
                            <div>₹{storePromoPrice ? storePromoPrice?.balancePayable?.toLocaleString() : singleBuyNowDetails?.balanceRegistration?.toLocaleString()}</div>
                        </div>
                        {(storePromoPrice ? storePromoPrice?.offerDiscount : singleBuyNowDetails?.offerDiscount) ?
                            <div className='r-c-sb cart-price m-b-10' style={{ padding: "2px 20px" }}>
                                <div>Offer discount</div>
                                <div style={{ color: "#28DF99" }}>₹{storePromoPrice ? storePromoPrice?.offerDiscount?.toLocaleString() : singleBuyNowDetails?.offerDiscount?.toLocaleString()}</div>
                            </div> : null}
                        {/* <div className='cart-discount' style={{ padding: "2px 20px" }}>Promo discount
                            <span style={{ color: "#28DF99" }}>
                                ₹0
                            </span>
                        </div>
                        <div className='r-c-sb cart-price' style={{ padding: "2px 20px" }} >
                            <div>Payable after discount</div>
                            <div>₹9500</div>
                        </div> */}
                        {storePromoPrice ? <>
                            {isPayTerm ?
                                <>{storePromoPrice?.courseBilling?.length > 0 ?
                                    <div className='r-c-sb cart-price' style={{ padding: "2px 20px" }} >
                                        <div>Payment term</div>
                                        <div>{isPayTerm}</div>
                                    </div>
                                    : null}

                                    <div className='r-c-sb m-b-10'>
                                        <div className='' onClick={() => { setInstallmentVisible(true) }} style={{ padding: "2px 20px", color: "#594099", fontSize: "12px", fontWeight: "bold", cursor: "pointer" }}>EDIT PLAN</div>
                                        <div className='' style={{ padding: "2px 20px", color: "#636363", fontWeight: 600 }}>
                                            {storePromoPrice?.courseBilling?.length > 0 ?
                                                <>
                                                    ({termList?.length || singleBuyNowDetails?.installmentCount} {installmentTerm})
                                                </>
                                                : null}
                                            {/* ({termList?.length || singleBuyNowDetails?.installmentCount} {installmentTerm}) */}
                                        </div>
                                    </div>

                                </> : <></>
                            }
                        </> : <>
                            {isPayTerm ?
                                <>
                                    <div className='r-c-sb cart-price' style={{ padding: "2px 20px" }} >
                                        <div>Payment term</div>
                                        <div>{isPayTerm}</div>
                                    </div>
                                    {singleBuyNowDetails?.installmentCount ?
                                        <div className='r-c-sb m-b-10'>
                                            <div className='' onClick={() => { setInstallmentVisible(true) }} style={{ padding: "2px 20px", color: "#594099", fontSize: "12px", fontWeight: "bold", cursor: "pointer" }}>EDIT PLAN</div>
                                            <div className='' style={{ padding: "2px 20px", color: "#636363", fontWeight: 600 }}>
                                                ({termList?.length || singleBuyNowDetails?.installmentCount} {installmentTerm})
                                            </div>
                                        </div>
                                        : <></>}
                                </> : <></>
                            }
                        </>}
                        <div className='price-border'></div>
                        <div className='' style={{ fontSize: "14px", fontWeight: "bold", padding: "10px 20px" }}>Pay now to register</div>
                        <div className='r-c-sb cart-price' style={{ padding: "2px 20px" }} >
                            <div>Registration Fee</div>
                            <div>₹{singleBuyNowDetails?.exAmount?.toLocaleString()}</div>
                        </div>
                        {singleBuyNowDetails?.isGSTEnabled ?
                            <div className='r-c-sb cart-price m-b-10' style={{ padding: "2px 20px" }} >
                                <div>GST</div>
                                <div>+₹{storePromoPrice ? storePromoPrice?.gst?.toLocaleString() : singleBuyNowDetails?.gst?.toLocaleString()}</div>
                            </div>
                            : null}
                        {/*                 
                <div className='cart-price'>{singleBuyNowDetails?.item?.[0]?.resourceType === "course" ? "Registration fee" : "Price"}
                    <span>₹{couponInCart ? numberWithCommas(storePromoPrice?.cart?.amount?.toFixed(2) || storePromoPrice?.amount?.toFixed(2)) : numberWithCommas(singleBuyNowDetails?.amount?.toFixed(2))}</span>
                </div> */}
                        <div className='price-border'></div>
                        <div className='cart-total-amount'>Payable now <br></br> (Registration fee)
                            <span>
                                ₹{singleBuyNowDetails?.totalAmount?.toLocaleString()}
                            </span>
                        </div>
                    </>
                    :
                    <>
                        <div className='cart-price'>{"Price"}
                            <span>₹{storePromoPrice ? storePromoPrice?.exAmount?.toLocaleString() : singleBuyNowDetails?.exAmount?.toLocaleString()}</span>
                        </div>
                        <div className='cart-discount'>Discount
                            <span>
                                -&nbsp;₹{storePromoPrice ? storePromoPrice?.eDiscountEx?.toLocaleString() : singleBuyNowDetails?.eDiscountEx?.toLocaleString()}
                            </span>
                        </div>
                        {storePromoPrice?.offerDiscountEx || singleBuyNowDetails?.offerDiscountEx ?
                            <div className='cart-discount'>Offer Discount
                                <span style={{ color: "#28DF99" }}>
                                    -&nbsp;₹{(storePromoPrice?.offerDiscountEx || singleBuyNowDetails?.offerDiscountEx)?.toLocaleString() || '0'}
                                </span>
                            </div> : null
                        }
                        {singleBuyNowDetails?.isGSTEnabled ?
                            <div className='r-c-sb cart-price m-b-10'  >
                                <div>GST</div>
                                <div>+&nbsp;₹{storePromoPrice ? storePromoPrice?.gst?.toLocaleString() : singleBuyNowDetails?.gst?.toLocaleString()}</div>
                            </div> : null}
                        <div className='price-border'></div>
                        <div className='cart-total-amount'>Total amount
                            <span>
                                <span>
                                    ₹{storePromoPrice ? storePromoPrice?.totalAmount?.toLocaleString() : singleBuyNowDetails?.totalAmount?.toLocaleString()}
                                </span>
                            </span>
                        </div>
                    </>
                }

                <div className='price-border'></div>
            </div >
        )
    }
    const [showMobDiscount, setShowMobDiscount] = useState(true)
    const [isCategoryClicked, setIsCategoryClicked] = useState(false);
    const [isProfileClicked, setIsProfileClicked] = useState(false);
    const CalculateMarginTop = () => {
        const margins = {
            isDownloadBarVisible: 55,
            showContactBar: 55,
            isOfferBarVisible: 60,
            isMinOfferVisible: 55,
        }
        return 60 + (isDownloadBarVisible ? margins.isDownloadBarVisible : 0) +
            (showContactBar ? margins.showContactBar : 0) +
            (isOffersBarVisible ? margins.isOfferBarVisible : 0);
    }
    return (
        <div onClick={(e) => { e.stopPropagation(); setIsCategoryClicked(false); setIsProfileClicked(false) }} style={{ marginTop: window.innerWidth > 800 ? CalculateMarginTop() : 0 }}>
            <div className='mobile-topbar-parent'>
                <TopBar isOffersBarVisible={isOffersBarVisible} offersCarouselData={offersCarouselData} isCategoryClicked={isCategoryClicked} setIsCategoryClicked={setIsCategoryClicked} isProfileClicked={isProfileClicked} setIsProfileClicked={setIsProfileClicked} />
            </div>
            <div className='mobile-header-parent'>
                <MobilePageHeader title={"Check Out"} onBackPress={() => { history.goBack() }} sider={true} />
            </div>
            <div style={{ backgroundColor: "#FAFAFA", minHeight: "calc(100vh - 50px)" }}>
                {/* <OffersCarousel />
                {showMobDiscount && offersCarouselData?.length > 0 ?
                    <MobileDiscountOffersCarousel setShowMobDiscount={setShowMobDiscount} />
                    :
                    <></>
                } */}
                <div className='student-cart-parent'>
                    <div className='mobile-cart-header m-b-10'>Checkout</div>
                    {/* <div className='empty-cart-parent'>
                        <EmptyCart />
                    </div> */}

                    <div className='mobile-flex-price'>
                        <div className='student-cart-child'>
                            <div className='cart-course-container'>
                                <div className='save-card-border'>
                                    {singleBuyNowDetails?.item?.map((item) => {
                                        return (
                                            <CartCard item={item} />
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='cart-price-details'>
                                <PriceDetails />
                                <div className='price-coupon-field' style={{ fontFamily: "Roboto" }}>
                                    {storePromoPrice ?
                                        <div className='price-applied' style={{ alignItems: "flex-start" }}>
                                            <div className='r-c-c'><img style={{ marginTop: -20 }} src={require("../../Assets/aulasMarket/ic-check-filled.svg").default} />
                                                <div className='r-sfa m-l-10'>
                                                    <div style={{ color: "#191919", fontSize: 13, fontWeight: "600" }}>You saved ₹{storePromoPrice?.promoDiscount || singleBuyNowDetails?.promoDiscount} with this code</div>
                                                    <div style={{ color: "#AEAEAE", fontSize: 12 }}>Promotional offer Applied</div>
                                                </div>
                                            </div>
                                            <div style={{ color: '#FF414D', cursor: 'pointer', marginLeft: 5, fontWeight: "500" }} onClick={() => { setCouponInCart(false); setPayOfferCode(null); setSelectedCouponCode(false); setStorePromoPrice(false); setIsInputTyped(false) }}>REMOVE</div>


                                        </div>
                                        :
                                        <div className=' r-c-c' style={{ display: 'flex' }}>
                                            <Input
                                                className='price-coupon-filed'
                                                type="text"
                                                placeholder='Enter promotional offer'
                                                onChange={(val) => { handleInputCode(val) }}
                                                value={payOfferCode}
                                                style={{ height: "4.5vh", }}

                                            ></Input>
                                            <Button
                                                loading={applyLoader}
                                                className='r-c-c text-xm bold-600'
                                                type="button"
                                                onClick={handleApplyClick}
                                                style={{
                                                    cursor: isInputTyped ? 'pointer' : 'not-allowed',
                                                    border: ` 1px solid #594099 ${isInputTyped ? 1 : 0.4}`,
                                                    borderRadius: " 0px 4px 4px 0px",
                                                    backgroundColor: isInputTyped ? "#594099" : "white",
                                                    padding: '5px 10px',
                                                    color: isInputTyped ? "white" : "#594099",
                                                    height: "4.5vh",
                                                    opacity: isInputTyped ? 1 : 0.4
                                                }}
                                                disabled={!isInputTyped}
                                            >
                                                APPLY
                                            </Button>
                                        </div>

                                    }
                                    {invalidCouponType ?
                                        <div style={{ color: "red", fontWeight: "normal" }}>{InvalidCouponMessage[invalidCouponType] || ''}</div>
                                        : null}
                                    {storePromoPrice || ( singleBuyNowDetails?.item?.[0]?.resourceType == "course" ? singleBuyNowDetails?.balanceRegistration == 0  : singleBuyNowDetails?.totalAmount == 0) ? <></> : couponList?.items?.length > 0 && singleBuyNowDetails?.balanceRegistration !== 0 ? <div onClick={() => { setIsCouponModal(true) }} style={{ fontSize: "13px", fontWeight: "bold", fontWeight: "bold", color: "#1089FF", cursor: "pointer", marginTop: 10 }}>VIEW ALL PROMOTIONAL OFFERS</div> : <></>}
                                </div>
                                {
                                    !couponList?.minOffers?.length ? <></> : (<>
                                        <div className='cart-diffrence-amount'>
                                            Add items worth ₹{couponList?.minOffers?.[0]?.dif?.toLocaleString() || ''} to get {couponList?.minOffers?.[0]?.discountType == 'percent' ? `${couponList?.minOffers?.[0]?.discount}%` : `₹${couponList?.minOffers?.[0]?.discount}`} off above ₹{couponList?.minOffers?.[0]?.minPurchase?.toLocaleString()}
                                        </div>
                                    </>)
                                }
                                {
                                    singleBuyNowDetails?.item?.[0]?.resourceType !== "course" ? <></> : (<>
                                        <div className='checkout-disclaimer'>
                                            This is the registration fee for the course, the total fees will be collected as per the fee plan
                                        </div>
                                    </>)
                                }
                                {
                                    (storePromoPrice ? !storePromoPrice?.discount : !singleBuyNowDetails?.discount) ? <></> : (<>
                                        <div className='cart-diffrence-amount'>
                                            Your total savings on this purchase is ₹ {storePromoPrice ? storePromoPrice?.discount?.toLocaleString() : singleBuyNowDetails?.discount?.toLocaleString()}
                                        </div>
                                    </>)
                                }
                                <div className='r-c-c m-t-20'>
                                    <Button
                                        type="primary"
                                        disabled={disablePay}
                                        loading={paynowLoader}
                                        onClick={() => handlePayNow()}
                                    >
                                        {!couponInCart ? (totalAmountBeforeDiscount === 0 ? "ENROLL NOW" : singleBuyNowDetails?.item?.[0]?.resourceType === "course" ? "PAY NOW" : "PAY NOW") : (totalAmount === 0 ? "ENROLL NOW" : "PAY NOW")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='mobile-price-enable'>
                            <MobileCheckOut isPayTerm={isPayTerm} termList={termList} />
                        </div>
                    </div>
                    <CouponsModal
                        isCouponModal={isCouponModal}
                        setIsCouponModal={setIsCouponModal}
                        from={"checkout"}
                        setPayOfferCode={setPayOfferCode}
                        singleBuyNowDetails={singleBuyNowDetails}
                        setInvalidCouponType={setInvalidCouponType}
                    />
                    {isInstallmentVisible &&
                        <CourseInstallmentModal
                            isInstallmentVisible={isInstallmentVisible}
                            setInstallmentVisible={setInstallmentVisible}
                            singleCourseDetailsDataContainer={singleBuyNowDetails?.item?.[0]}
                            from="checkout"

                        />
                    }
                    {showDownloadAppModal &&
                        <DownloadAppModal
                            showDownloadAppModal={showDownloadAppModal}
                            setShowDownloadAppModal={setShowDownloadAppModal}
                            userMarketPlaceHomeData={userMarketPlaceHomeData}
                            fromCheckoutPage={fromCheckoutPage}
                        />}
                    <PageDataLoader visible={pageLoader} />
                </div>
            </div>
            <NewFooter/>

        </div>
    )
}



const mapStateToProps = (state) => {
    const { promoCodeDetials, storePayTerm, cartDetails, couponList, selectedCouponCode, couponInCart, storePromoPrice, singleBuyNowDetails, offersCarouselData, isDownloadBarVisible, showContactBar, selectedTerm, termList, isOffersBarVisible, userMarketPlaceHomeData } = state.studentMarketPlace
    const { user } = state.session
    return { promoCodeDetials, storePayTerm, cartDetails, couponList, selectedCouponCode, user, couponInCart, storePromoPrice, singleBuyNowDetails, offersCarouselData, isDownloadBarVisible, showContactBar, selectedTerm, termList, isOffersBarVisible, userMarketPlaceHomeData }
};

const mapDispatchToProps = (dispatch) => ({
    checkLiveCourse: (param) => dispatch(checkLiveCourse(param)),
    fetchPromoCodes: (callback) => dispatch(fetchPromoCodes(callback)),
    getCouponList: (param) => dispatch(getCouponList(param)),
    applyPromoCheckout: (params, callback, succesCallback,errorCallback) => dispatch(applyPromoCheckout(params, callback, succesCallback,errorCallback)),
    payNowCart: (params, callback, promo) => dispatch(payNowCart(params, callback, null, promo)),
    setCouponInCart: res => dispatch(setCouponInCart(res)),
    postAddSaveForLater: (id, itemType, callback) => dispatch(postAddSaveForLater(id, itemType, callback)),
    getCheckPayment: (param, callback) => dispatch(getCheckPayment(param, callback)),
    postCartPayment: (param, callback) => dispatch(postCartPayment(param, callback)),
    postAddSaveItem: (id, itemType, callback) => dispatch(postAddSaveItem(id, itemType, callback)),
    removeSaveItem: (id, itemType, callback) => dispatch(removeSaveItem(id, itemType, callback)),
    showMenu: (bool) => dispatch(showMenu(bool)),
    setValueForCheckout: (val) => dispatch(setValueForCheckout(val)),
    setSelectedCouponCode: (val) => dispatch(setSelectedCouponCode(val)),
    getCheckOutItem: (id, itemType, callback) => dispatch(getCheckOutItem(id, itemType, callback)),
    storeCourseIdAndType: (key, val) => dispatch(storeCourseIdAndType(key, val)),
    setStorePromoPrice: (val) => dispatch(setStorePromoPrice(val)),
    setStorePayTerm: (val) => dispatch(setStorePayTerm(val)),
    setPromoCode: (val) => dispatch(setPromoCode(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentCheckOut)