import {
    SET_CRM_DASHBOARD_DATA,
    SET_CRM_LEADS_DATA,
    SET_CRM_CONVERSIONS_DATA,
    SET_CRM_IMPRESSION_GRAPH_DATA,
    SET_CRM_LEADS_CONVERSIONS_GRAPH_DATA,
    APPEND_CRM_CONVERSIONS_DATA,
    APPEND_CRM_LEADS_DATA,
    
} from "actions/marketPlaceCRM"


const initialState = {
    crmDashboardData: [],
    crmLeadsData: [],
    crmConversionsData: [],
    crmImpressionGraphDataData: [],
    crmLeadsConversiopnsGraph: [],
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CRM_DASHBOARD_DATA:
            return {
                ...state,
                crmDashboardData: action.val,
            }
        case SET_CRM_LEADS_DATA:
            return {
                ...state,
                crmLeadsData: action.val,
            }
        case SET_CRM_CONVERSIONS_DATA:
            return {
                ...state,
                crmConversionsData: action.val,
            }
        case SET_CRM_IMPRESSION_GRAPH_DATA:
            return {
                ...state,
                crmImpressionGraphDataData: action.val,
            }
        case SET_CRM_LEADS_CONVERSIONS_GRAPH_DATA:
            return {
                ...state,
                crmLeadsConversiopnsGraph: action.val,
            }
        case APPEND_CRM_CONVERSIONS_DATA:
            let conversionsTemp = state.crmConversionsData
            conversionsTemp.meta = action?.res?.meta
            conversionsTemp?.data.push(...action.res?.data)
            return {
                ...state,
                crmConversionsData: conversionsTemp
            }
        case APPEND_CRM_LEADS_DATA:
            let leadsTemp = state.crmLeadsData
            leadsTemp.meta = action?.res?.meta
            leadsTemp?.data.push(...action.res?.data)
            return {
                ...state,
                crmLeadsData: leadsTemp
            }
        default:
            return {
                ...state
            };
    }
}

export default reducer;