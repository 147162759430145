
const MAX_LENGTH = 50;
class HistoryManager{
    constructor(){
        this.undostack = {'1': [], '2': [], '3': []}
        this.redostack = {'1': [], '2': [], '3': []}
        this.totalPages = 3
        this.usl = {'1': 0, '2': 0, '3': 0}
        this.rsl = {'1': 0, '2': 0, '3': 0}
        this.maxundostacklength = MAX_LENGTH;
        this.maxredostacklength = MAX_LENGTH;
        this.currentPage = 1
    }
    setPage(page){
        this.currentPage = page
    }
    generatePage(page){
        if (this.totalPages<page){
            this.totalPages += 1;
            this.undostack[this.totalPages] = []
            this.redostack[this.totalPages] = []
            this.usl[this.totalPages] = 0
            this.rsl[this.totalPages] = 0
        }
    }
    slice(startPage, endPage){
        for (let i=startPage; i<=endPage; i++){
            delete this.undostack[i]
            delete this.redostack[i]
            delete this.usl[i]
            delete this.rsl[i]
          }
          Object.keys(this.undostack).forEach((key, index)=>{
            if (index+1<key){
                this.undostack[index+1] = this.undostack[key]
                delete this.undostack[key]
                this.redostack[index+1] = this.redostack[key]
                delete this.redostack[key]
                this.usl[index+1] = this.usl[key]
                delete this.usl[key]
                this.rsl[index+1] = this.rsl[key]
                delete this.rsl[key]
            }
          })
          this.currentPage = 1
          this.totalPages = Object.keys(this.undostack).length
    }
    recordEvent(event){
        this.undostack[this.currentPage]?.push(event)
        this.usl[this.currentPage]++;
        this.redostack[this.currentPage] = [];
        this.rsl[this.currentPage] = 0;
    }
    undo(){
        if (this.usl[this.currentPage]){
            let event = this.undostack[this.currentPage].pop();
            this.redostack[this.currentPage].push(event);
            this.usl[this.currentPage]--;
            this.rsl[this.currentPage]++;
            return event[1]
        }
        return false
    }
    redo(){
        if (this.rsl[this.currentPage]){
            let event = this.redostack[this.currentPage].pop();
            this.undostack[this.currentPage].push(event);
            this.usl[this.currentPage]++;
            this.rsl[this.currentPage]--;
            return event[0]
        }
        return false
    }
    clear(){
        this.undostack[this.currentPage] = [];
        this.redostack[this.currentPage] = [];
        this.usl[this.currentPage] = 0;
        this.rsl[this.currentPage] = 0;
    }
}

export default new HistoryManager();


// whenever a change occurs push the diff to the diff(next, prev) to the undo stack, and clear the redo stack. 
// whenever an undo occurs take pop the undostack and push the diff, then push the diff(prev, next) to the redo stack 