import React, { useState, useEffect } from 'react'
import { Button, DatePicker, Popover, Radio, Select } from 'antd';
import more from '../../Assets/ic-sort.svg'
import { BellOutlined, CalendarOutlined, CaretDownOutlined, CheckCircleOutlined, DeleteFilled, FilterOutlined, MoreOutlined } from '@ant-design/icons';
import { NodataCard } from 'pages/classRoom';
import TransactionGraph from './transactionGraph';
import reminderIcon from '../../Assets/ic-file-pdf.png'
import { Checkbox } from 'antd';
import { getTransactionData } from 'actions/profileNew';
import { connect } from 'react-redux';
import moment from 'moment';
import OfflinePayReq from "../feeManagement/markAsPaidModal";
import ConfirmModal from "../feeManagement/confirmationModal";

import TransactionHistory from './transactionHistory';
import SendReminderModal from 'pages/feeManagement/sendReminderModal';
import AddDetailsModal from 'pages/feeManagement/addDetailsModal';
import EditOfflinePayment from 'pages/userManagement/editOfflinePayment';
import EidtScholerShipAddModal from 'pages/userManagement/eidtScholerShipAddModal';
import { setFeeDetails } from 'actions/feeManagement';
import PageDataLoader from 'components/pageDataLoader';
import NoDataCard from 'pages/digitalLibrary/components/noDataCard';


const { RangePicker } = DatePicker;








const Transactions = ({ getTransactionData, storeTransactionData, userRowDetails, setFeeDetails, feeMangementData, allActivitiesFilter , week, setWeek,startDate, setStartDate, endDate, setEndDate}) => {
   
    const [filterValue, setFilterValue] = useState('All')
    // const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    // const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [isYear, setIsYear] = useState(moment().format('YYYY'))
    const [selectedDate, setSelectedDate] = useState(null);
    const [showHistory, setShowHistory] = useState(false);
    const [showSendReminder, setShowSendReminder] = useState(false);
    const [storeId, setStoreId] = useState('')
    const [showAddDetailsModal, setShowAddDetailsModal] = useState(false);
    const [isAddOrGenModal, setIsAddOrGenModal] = useState(false);
    const [showOfflinePayReqModal, setShowOfflinePayReqModal] = useState(false);
    const [isAdditionModal, setIsAdditionModal] = useState(false);
    const [showEditOffline, setShowEditOffline] = useState(false);
    const [showEditSA, setShowEditSA] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [pageDataLoader, setPageDataLoader] = useState(false)
    const [transactionPage, setTransactionPage] = useState(false)
    const [issueDate, setIssueDate] = useState('')
    const [dueDate, setDueDate] = useState('')
    const [loader, setLoader] = useState(false)



   
    let cardArray = [
        { count: storeTransactionData?.totalPaymentPending?.count, name: `payment pending`, amount: storeTransactionData?.totalPaymentPending?.amount, pending: ` payment pending today`, secondAmount: storeTransactionData?.totalPaymentPending?.todayCount, color: '#FFA931' },
        { count: storeTransactionData?.totalPaymentPaid?.count, name: ` payment paid`, amount: storeTransactionData?.totalPaymentPaid?.amount, pending: ` payment paid today`, secondAmount: storeTransactionData?.totalPaymentPaid?.todayCount, color: '#28DF99' },
        { count: storeTransactionData?.totalPaymentOverDue?.count, name: ` payment overdue`, amount: storeTransactionData?.totalPaymentOverDue?.amount, pending: ` payment overdue today`, secondAmount: storeTransactionData?.totalPaymentOverDue?.todayCount, color: '#FF414D' },
        { count: storeTransactionData?.totalPaymentCancelled?.count, name: ` payment cancelled`, amount: storeTransactionData?.totalPaymentCancelled?.amount, pending: ` payment cancelled today`, secondAmount: storeTransactionData?.totalPaymentCancelled?.todayCount, color: '#636363' },
        { count: storeTransactionData?.totalPaymentRefunded?.count, name: ` payment refunded`, amount: storeTransactionData?.totalPaymentRefunded?.amount, pending: ` payment refunded today`, secondAmount: storeTransactionData?.totalPaymentRefunded?.todayCount, color: '#1089FF' },
    ]



    useEffect(() => {
        setPageDataLoader(true)
        setLoader(true)
        getTransactionData(startDate, endDate, week, isYear, () => { setLoader(false) }, () => { setPageDataLoader(false); })
    }, [])

    useEffect(() => {
        setPageDataLoader(true)
        setLoader(true)
        getTransactionData(startDate, endDate, week, isYear, () => { setLoader(false) }, () => { setPageDataLoader(false); })
    }, [startDate, endDate, week, isYear])

    const content = (
        <div >
            <DatePicker
                onChange={(date) => {
                    const formattedDate = date ? moment(date).format('DD-MM-YYYY') : '';
                    setIssueDate(formattedDate);
                    // getTransactionData(startDate, endDate, week, isYear, () => { }, () => { /* setPageDataLoader(false) */ })
                }}
            />
        </div>
    );

    const content1 = (
        <div >
            <DatePicker
                onChange={(date) => {
                    const formattedDate = date ? moment(date).format('DD-MM-YYYY') : '';
                    setDueDate(formattedDate);
                    // getTransactionData(startDate, endDate, week, isYear, () => { }, () => { /* setPageDataLoader(false) */ })
                }}
            />
        </div>
    );


    const filterData = (
        <div style={{ width: "120px" }}>
            <Radio.Group value={filterValue} onChange={(e) => setFilterValue(e.target.value)}>
                <div style={{ fontWeight: 600, fontSize: '14px' }}>
                    <Radio value="All">All</Radio>
                </div>
                <div style={{ fontWeight: 600, fontSize: '14px' }}>
                    <Radio value="overdue">Overdue</Radio>
                </div>
                <div style={{ fontWeight: 600, fontSize: '14px' }}>
                    <Radio value="pending">Pending</Radio>
                </div>
            </Radio.Group>
        </div>
    )

  
    const statusFilter = storeTransactionData?.upcomingTransactions?.filter(item => {
    
        const statusMatches =
            filterValue === 'All' ||
            (filterValue === 'overdue' && item?.status === 'Overdue') ||
            (filterValue === 'pending' && item?.status === 'Pending');
        const dueDateMatches =
            !dueDate || item?.dueDate === dueDate;
    
        const issueDateMatches = !issueDate || item?.date?.split(',')[0] === issueDate;

            return statusMatches && dueDateMatches && issueDateMatches;
    });











    const paymentLength = storeTransactionData?.userTransactions?.filter((item) => item?.status !== 'Paid')?.length
    // const transactionLenth = storeTransactionData?.paidTransactions?.length ? storeTransactionData?.paidTransactions?.length : feeMangementData?.allActiviies?.items?.length
    // console.log('paymentLength',transactionLenth);


    const actionButtons = (
        <div style={{ width: "150px", height: '180px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', cursor: 'pointer' }}>
            <div style={{ color: '#636363', fontSize: '14px' }} onClick={() => { setShowSendReminder(true) }}> <BellOutlined style={{ marginRight: '5px' }} />Send Reminder</div>
            <div style={{ color: '#636363', fontSize: '14px' }} onClick={() => {
                setShowSendReminder(true);
                setShowHistory(true);

            }}> <img src={reminderIcon} style={{ width: '17px', marginRight: '5px' }} />Reminder History</div>
            <div style={{ color: '#636363', fontSize: '14px' }} onClick={() => {
                setShowOfflinePayReqModal(true)
            }}> <CheckCircleOutlined style={{ marginRight: '5px' }} />Mark as paid offline</div>
            <div style={{ color: '#636363', fontSize: '14px' }} onClick={() => {
                setShowConfirmationModal(true);
                setTransactionPage(true)

            }}> <DeleteFilled style={{ marginRight: '5px' }} />Delete</div>
        </div>
    )

    const handleChangeDateType = (date, dateString) => {
        setStartDate(moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD'))
        setEndDate(moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD'))
        // getTransactionData(startDate,endDate,week,isYear,()=>{},()=>{})
    }
    const handleDateChange = (date) => {
        setIsYear(moment(date).format('YYYY'))
        getTransactionData(startDate, endDate, week, isYear, () => { }, () => { })


    }
    const handleWeekChange = (value) => {
        setWeek(value)
        getTransactionData(startDate, endDate, week, isYear, () => { }, () => { })

    }

    return (
        <div className='m-t-80' style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>
                    <div><b>Overview</b></div>
                    {week == 'week' ?
                        <div>{moment(startDate).format('DD-MM-YYYY')}{" "}-{" "}{moment(endDate).format('DD-MM-YYYY')}</div> : <div>{isYear}</div>}


                </div>

                <div style={{ display: 'flex' }}>
                    {week == 'week' ?
                        <RangePicker
                            format={'DD-MM-YYYY'}
                            value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
                            allowClear={false}
                            onChange={(date, dateString) => { handleChangeDateType(date, dateString) }} /> :
                        <DatePicker value={moment(isYear)} picker='year' allowClear={false} onChange={(date) => { handleDateChange(date) }} />}
                    <div style={{ display: "flex", flexDirection: "row", width: "150px", backgroundColor: "#fff", justifyContent: "space-between", alignItems: "center", border: "#E6E6E6 solid 1px", marginLeft: '20px' }}>
                        <div><img style={{ width: "50px", height: "15px" }} src={more} alt="" /></div>
                        <Select
                            style={{ width: "80%", border: "none", fontWeight: "bold" }}
                            bordered={false}
                            removeIcon={true}
                            dropdownMatchSelectWidth={true}
                            onChange={(value) => { handleWeekChange(value) }}
                            defaultValue={week}
                            value={week == 'week' ? 'Week' : 'Months' }
                        >
                            <Option value="week" style={{ fontWeight: "bold" }}>Week</Option>
                            <Option value="month" style={{ fontWeight: "bold" }}>Months</Option>
                        </Select>
                    </div>


                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ marginTop: "180px" }}>
                    <div className="r-ac">
                        <div className="dot" style={{ marginRight: "3px" }}></div>
                        <div style={{ color: "#594099" }}>
                            <span className="bold-700"> {storeTransactionData?.totalTransactionRequested?.count}</span> payment requested
                        </div>
                    </div>
                    <div className="bold-700" style={{ fontSize: "20px" }}>
                        ₹ {storeTransactionData?.totalTransactionRequested?.amount}
                    </div>
                    <div style={{ color: "#AEAEAE", fontSize: "12px" }}>
                        {storeTransactionData?.totalTransactionRequested?.todayCount} payment requested today
                    </div>
                </div>
                <div style={{ justifyContent: 'center', width: '700px', height: '700px', marginTop: '70px', marginLeft: '50px' }}>
                    {/* <NodataCard /> */}

                    <TransactionGraph />

                </div>

            </div>
            <div style={{ border: '1px solid #e6e6e6', marginTop: '-350px' }}></div>

            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>

                {cardArray?.map(((item, index) => {
                    return (
                        <>
                            <div >
                                <div>
                                    <div className="r-ac">
                                        <div style={{ marginRight: "3px", color: `${item?.color}`, fontSize: '30px' }}>{"•"} </div>
                                        <div style={{ color: `${item?.color}` }}>
                                            <span className="bold-700"> {item?.firstAmount}</span>{item?.count} {item?.name}
                                        </div>
                                    </div>
                                    <div className="bold-700" style={{ fontSize: "20px" }}>
                                        ₹ {item?.amount}
                                    </div>
                                    <div style={{ color: "#AEAEAE", fontSize: "12px" }}>
                                        {item?.secondAmount} {item?.pending}
                                    </div>
                                </div>

                            </div>
                            {index < 4 ? <div style={{ borderRight: '1px solid #e6e6e6' }}>  </div> : null}

                        </>
                    )
                }))}
            </div>

            <div style={{ fontWeight: 'bold', fontSize: '21px', marginTop: '50px' }}> Payment Request <h style={{ color: '#AEAEAE' }}>({paymentLength})</h></div>

            <div className="scroll-bar-container" style={{ width: '100%', height: '450px', overflowX: 'auto', overflowY: 'auto' }}>
                <table style={{ marginTop: "18px", width: "100%" }}>
                    <thead className="tablehead">
                        <tr
                            style={{
                                backgroundColor: "#F6F4FE",
                                padding: "20px",
                                height: "50px",
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <th style={{ color: "#AEAEAE", width: "170px", textAlign: 'left' }}>REQUEST DETAILS</th>
                            <th style={{ color: "#AEAEAE", width: "140px", textAlign: 'center' }}>AMOUNT</th>
                            <th style={{ color: "#AEAEAE", width: "200px", textAlign: 'center' }}>ISSUE DATE
                                <Popover placement="bottomLeft" dropdownClassName={"dropDownB"} content={content} trigger="hover">
                                    <CalendarOutlined className="antCalenderIcon" style={{ fontSize: 18, marginRight: "2px", color: "#AEAEAE", marginLeft: "10px", cursor:'pointer' }} />
                                </Popover></th>
                            <th style={{ color: "#AEAEAE", width: "170px", textAlign: 'center' }}>DUE DATE
                                <Popover placement="bottomLeft" dropdownClassName={"dropDownB"} content={content1} trigger="hover">
                                    <CalendarOutlined className="antCalenderIcon" style={{ fontSize: 18, marginRight: "2px", color: "#AEAEAE", marginLeft: "10px", cursor:'pointer' }} />
                                </Popover></th>
                            <th style={{ color: "#AEAEAE", width: "140px", textAlign: 'left', marginLeft: 40 }}>STATUS
                                <Popover placement="bottomLeft" dropdownClassName={"dropDownB"} content={filterData} trigger="hover">
                                    <FilterOutlined className="antCalenderIcon" style={{ fontSize: 18, marginRight: "2px", color: "#AEAEAE", marginLeft: "10px" , cursor:'pointer'}} />
                                </Popover></th>
                            <th style={{ color: "#AEAEAE", width: "100px", textAlign: 'center' }}>ACTIONS</th>

                        </tr>
                    </thead>

                    {

                        statusFilter?.filter((item) => item.status !== 'Paid').length !== 0 ?
                            statusFilter?.map((item) => {
                                return (
                                    < >
                                        <tr style={{
                                            // padding: "20px",
                                            // height: "50px",
                                            backgroundColor: '#fff'
                                        }}>
                                            {item?.status == 'Paid' ? null :
                                                <div style={{ padding: 20 }}>


                                                    <td style={{ color: "#AEAEAE", fontSize: "14px", fontWeight: 600, width: "170px", textAlign: "left" }}>{item?.note ? item?.note : '-'}</td>
                                                    <td style={{ color: "#AEAEAE", fontSize: "14px", fontWeight: 600, width: "140px", textAlign: "center" }}>{item?.amountPayable}</td>
                                                    <td style={{ color: "#AEAEAE", fontSize: "14px", fontWeight: 600, width: "200px", textAlign: "center" }}>{item?.date}</td>
                                                    <td style={{ color: "#AEAEAE", fontSize: "14px", fontWeight: 600, width: "170px", textAlign: "center" }}>{item?.dueDate}</td>
                                                    <td style={{ color: `${item?.status == 'Pending' ? '#FFA931' : item?.status == 'Overdue' ? '#FF414D' : '#28DF99'}`, fontSize: "14px", fontWeight: 600, width: "140px", textAlign: "left", paddingLeft: 40 }}>
                                                        {item?.status}
                                                        {item?.feeReminders?.length == 0 ? null : (<><BellOutlined style={{ marginLeft: '5px', color: '#FFA931', fontWeight: 'bold' }} onClick={() => { setShowSendReminder(true), setStoreId(item) }} /> <span style={{ color: '#FFA931' }}>{`${item.feeReminders.length}`}</span></>)}

                                                    </td>

                                                    <td style={{ color: "#AEAEAE", fontSize: "14px", fontWeight: 600, width: "fit-content" }} onClick={() => { setStoreId(item) }}>
                                                        <Popover placement="right" dropdownClassName={"dropDownL"} content={actionButtons} trigger={'hover'} >
                                                            {console.log("AAA-storeis", item, storeId)}
                                                            <div style={{ marginLeft: 20 }}>{<MoreOutlined style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '60px' }} />}</div>
                                                        </Popover>
                                                    </td>


                                                </div>}
                                            {item?.status == 'Paid' ? null :
                                                <div style={{ border: '1px solid #E6E6E6' }}></div>}
                                        </tr>
                                        {/* <div style={{ border: '1px solid #E6E6E6' }}></div> */}

                                    </>
                                )
                            })
                            : <div style={{ width: '350', height: '450px' }}><NoDataCard /></div>

                    }

                </table>
            </div>

            <div style={{ fontWeight: 'bold', fontSize: '21px', marginTop: '50px' }}> Transaction History<h style={{ color: '#AEAEAE' }}>({
                allActivitiesFilter?.mode == 'online' || allActivitiesFilter?.mode == 'offline' ||
                    allActivitiesFilter?.filter == "today" || allActivitiesFilter?.filter == "yesterday" ||
                    allActivitiesFilter?.filter == "month" || allActivitiesFilter?.filter == 'lastmonth' ||
                    allActivitiesFilter?.filter == "threemonths" || allActivitiesFilter?.filter == 'Customise range' ?
                    feeMangementData?.allActiviies?.items?.length : storeTransactionData?.paidTransactions?.length
            })</h></div>

            <div className='scroll-bar-universal' style={{ width: '100%' }}>

                <TransactionHistory storeTransactionData={storeTransactionData} getTransactionData={getTransactionData} />

            </div>
            {console.log("AA-statusFilter", storeId)}
            <div>

                <SendReminderModal
                    setShowSendReminder={setShowSendReminder}
                    getTransactionData={getTransactionData}
                    isModalVisible={showSendReminder}
                    setShowHistory={setShowHistory}
                    showHistory={showHistory}
                    item={storeId}
                    startDate={startDate}
                    endDate={endDate}
                    week={week}
                    isYear={isYear}


                //  item = {statusFilter?.map(transaction => transaction) || []}


                />
                <AddDetailsModal
                    isModalVisible={showAddDetailsModal}
                    setIsAddOrGenModal={setIsAddOrGenModal}
                    isAddOrGenModal={isAddOrGenModal}
                    isAdditionModal={isAdditionModal}
                    handleOk={() => {
                        setShowAddDetailsModal(false);
                        // if (isAddOrGenModal == true) {
                        setShowOfflinePayReqModal(true);
                        setIsAddOrGenModal(false);
                        // } else {
                        //   setShowGeneratePayReq(true);
                        //   setIsAddOrGenModal(false);
                        // }
                    }}
                    handleCancel={() => {
                        setShowAddDetailsModal(false);
                        // if (isAddOrGenModal == true) {
                        setShowOfflinePayReqModal(true);
                        setIsAddOrGenModal(false);
                        // } else {
                        //   setShowGeneratePayReq(true);
                        //   setIsAddOrGenModal(false);
                        // }
                    }}
                    setShowGeneratePayReq={statusFilter}

                />

                {showOfflinePayReqModal && (
                    <OfflinePayReq
                        setPageLoader={setPageDataLoader}
                        item={storeId}
                        isModalVisible={showOfflinePayReqModal}
                        setIsModalVisible={setShowOfflinePayReqModal}
                        setShowAddDetailsModal={setShowAddDetailsModal}
                        setIsAddOrGenModal={setIsAddOrGenModal}
                        setIsAdditionModal={setIsAdditionModal}
                        getTransactionData={getTransactionData}
                        startDate={startDate}
                        endDate={endDate}
                        week={week}
                        isYear={isYear}
                    />
                )}

                {showEditOffline && (
                    <EditOfflinePayment
                        showEditOffline={showEditOffline}
                        setShowEditOffline={setShowEditOffline}
                        setShowEditSA={setShowEditSA}
                        setIsAdditionModal={setIsAdditionModal}
                    />
                )}

                {showEditSA && (
                    <EidtScholerShipAddModal
                        setShowEditSA={setShowEditSA}
                        ShowEditSA={showEditSA}
                        setShowEditOffline={setShowEditOffline}
                        isAdditionModal={isAdditionModal}
                        setIsAdditionModal={setIsAdditionModal}
                    />
                )}
                <ConfirmModal
                    // setPageLoader={setPageLoader}
                    getTransactionData={getTransactionData}
                    transactionPage={transactionPage}
                    setShowConfirmationModal={setShowConfirmationModal}
                    isModalVisible={showConfirmationModal}
                    deleteApiCall={true}
                    id={storeId}
                    startDate={startDate}
                    endDate={endDate}
                    week={week}
                    isYear={isYear}
                />


            </div>
            {/* <PageDataLoader visible={pageDataLoader} loader={loader} /> */}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { storeTransactionData } = state.profileNew
    const { userRowDetails } = state.userManagementV2;
    const { feeMangementData, allActivitiesFilter } = state.feeManagement;

    return { storeTransactionData, userRowDetails, feeMangementData, allActivitiesFilter }
}

const mapDispatchToProps = (dispatch) => ({
    getTransactionData: (startDate, endDate, week, isYear, callBack, successCallBack) => dispatch(getTransactionData(startDate, endDate, week, isYear, callBack, successCallBack)),
    setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),

})
export default connect(mapStateToProps, mapDispatchToProps)(Transactions)