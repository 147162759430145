import React from "react";

function OverallStatusCard(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <h2
          className="overallStatusCard-h1"
          style={{ marginBottom: "0px", fontSize: "20px" }}
        >
          {props.numberOfConversation < 0
            ? props.numberOfConversation
            : props.numberOfConversation}
        </h2>
        <p className="overallStatusCard-p">{props.typeOfConversation}</p>
      </div>
      {/* <div className="lastWeekConversation-p" >
                <p>{props.lastWeekConversation< 0?'': '+'}{props.lastWeekConversation}</p>

            </div> */}
      <div className="lastWeekConversation-p">
        {props.lastWeekConversation != 0 ? (
          <div>
            {props.lastWeekConversation > 0 ? (
              <p>+{props.lastWeekConversation} from last week</p>
            ) : (
              <p style={{ color: "red" }}>
                {props.lastWeekConversation} from last week
              </p>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default OverallStatusCard;
