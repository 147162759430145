import React from 'react';
import Avatar from 'antd/lib/avatar/avatar';
import { Api, Color } from 'services';


export const SubIcon = ({icon, name, size}) =>  {
    if(icon){
       return <Avatar size={size} src={`${Api._s3_url}${icon}`}/>
    }else{
        return <Avatar size={size} style={{backgroundColor: Color.lightGrey, fontSize: 20}} 
        className="color-purple font-pt-serif bold-600">{name?.charAt(0)}</Avatar>
    }
}

export const SquareSubIcon = ({icon, name, size}) =>  {
    if(icon){
       return <div className='text-xlg r-c-c color-purple bold-600 font-pt-serif text-square-bg__grey'>
           <img width={"100%"} src={`${Api._s3_url}${icon}`} alt="" />
       </div>

    }else{
        return  <div className='text-xlg r-c-c color-purple bold-600 font-pt-serif text-square-bg__grey'>
            {name?.charAt(0) == ' ' ? name?.charAt(1) : name?.charAt(0)}</div>

    }
}

