import React, { useEffect, useState } from "react";
import { DatePicker, Modal, Radio } from "antd";
import moment from "moment";
import { Button } from "components/Button";

const MarketPlaceCrmDownloadReport = ({
  isModelVisible,
  setisModelVisible,
  usersDownloadReport,
  storeSelectedRange,
  storeStartDate,
  storeEndDate,
  startDateContainer,
  endDateContainer,
  selectedRangeContainer,
  fromMarketPlaceCRM,
  tab,
  postCrmDownloadReport
}) => {
  const [value, setValue] = useState(0);
  const [isDownloadBtnLoading, setIsDownloadBtnLoading] = useState(false);

  const handleCancel = (e) => {
    e.stopPropagation();
    setisModelVisible(false);
  };

  const onRadioChange = (e) => {
    setValue(e.target.value);
  };

  const RangeStyle = {
    font: "normal normal normal 15px/21px Roboto",
    color: "#191919",
    margin: "5px",
  };

  const disabledStartDate = (current) => {
    if (!endDateContainer) {
      return current >= moment().endOf("day");
    }
    return current >= moment().endOf("day") || current >= moment(endDateContainer).endOf("day");
  };
  
  const disabledDate = (current) => {
    if (!startDateContainer) {
      return current >= moment().endOf("day");
    }
    return current >= moment().endOf("day") || current <= moment(startDateContainer).startOf("day");
  };
  

  useEffect(() => {
    storeStartDate(null);
    storeEndDate(null);
    storeSelectedRange(null);
  }, []);

  return (
    <div>
      <Modal
        centered
        className="modal-round-corner"
        visible={isModelVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#191919",
            fontSize: "22px",
            fontWeight: 600,
          }}
        >
          Select range
        </div>
        <div
          style={{
            color: "#191919",
            fontSize: "14px",
            fontWeight: 600,
            padding: "10px",
          }}
        >
          <Radio.Group
            onChange={onRadioChange}
            value={value}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Radio
              onClick={() => storeSelectedRange("today")}
              value={3}
              style={{ marginTop: "20px" }}
            >
              <div style={RangeStyle}>Today</div>
            </Radio>
            <Radio
              onClick={() => storeSelectedRange("yesterday")}
              value={4}
              style={{ marginTop: "5px" }}
            >
              <div style={RangeStyle}>Yesterday</div>
            </Radio>
            <Radio
              onClick={() => storeSelectedRange("lastweek")}
              value={1}
              style={{ marginTop: "5px" }}
            >
              <div style={RangeStyle}>Last week</div>
            </Radio>
            <Radio
              onClick={() => storeSelectedRange("lastmonth")}
              value={2}
              style={{ marginTop: "5px" }}
            >
              <div style={RangeStyle}>Last month</div>
            </Radio>
            <Radio
              onClick={() => {
                setValue(6);
                storeSelectedRange("specificDateRange");
              }}
              value={6}
              style={{ marginTop: "5px" }}
            >
              <div style={RangeStyle}>Specific date range</div>
            </Radio>
          </Radio.Group>
        </div>

        {value === 6 && (
          <div
            className="flex-row"
            style={{ justifyContent: "space-around", marginTop: "10px" }}
          >
            <div>
              <div style={{ color: "#636363", fontSize: "13px" }}>
                Start date
              </div>
              <DatePicker
                allowClear={false}
                format={"DD/MM/YYYY"}
                style={{ width: "200px" }}
                onChange={(e) => storeStartDate(e.format("YYYY-MM-DD"))}
                disabledDate={disabledStartDate}
                inputReadOnly={true}
              />
            </div>
            <div>
              <div style={{ color: "#636363", fontSize: "13px" }}>End date</div>
              <DatePicker
                format={"DD/MM/YYYY"}
                allowClear={false}
                disabled={!startDateContainer}
                style={{ width: "200px" }}
                onChange={(e) => storeEndDate(e.format("YYYY-MM-DD"))}
                disabledDate={disabledDate}
                inputReadOnly={true}
              />
            </div>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            disabled={
              value === 6
                ? !startDateContainer || !endDateContainer
                : value === 0
            }
            loading={isDownloadBtnLoading}
            className="m-l-30"
            style={{
              opacity:
                value === 6
                  ? !startDateContainer || !endDateContainer
                    ? 0.5
                    : 1
                  : value === 0
                  ? 0.5
                  : 1,
            }}
            type="primary"
            onClick={() => {
              setIsDownloadBtnLoading(true);
              if (fromMarketPlaceCRM) {
                postCrmDownloadReport(tab, () => {
                  setIsDownloadBtnLoading(false);
                  setisModelVisible(false);
                });
              } else {
                usersDownloadReport(() => {
                  setIsDownloadBtnLoading(false);
                  setisModelVisible(false);
                });
              }
            }}
          >
            DOWNLOAD
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default MarketPlaceCrmDownloadReport;