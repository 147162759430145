import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Popover, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getTeachersGeofenceData, deallocateTeachersGeofence} from 'actions/attendanceNew';
import EditGeoFence from './Modals/editGeoFence';
import moment from 'moment';

const timerImage = require("../../Assets/ic-timer.svg").default;

const ConfirmDeleteModal = ({
    isDeAllocateModalVisible,
    setIsDeAllocateModalVisible,
    deAllocateLocation,
    item,
    index,
    deallocateTeachersGeofence,
    getTeachersGeofenceData,
    allocatedLoc,
    togglePageLoader}) => {
        const [isDeleteLoading, setIsDeleteLoading]=useState(false) 
     const handleOk = () => {
      setIsDeAllocateModalVisible(false);
    };
    const handleCancel = () => {
      setIsDeAllocateModalVisible(false);
  
    };
    
    
    return (
      <>
        <Modal
          className="modal-round-corner"
          centered
          visible={isDeAllocateModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          header={null}
          footer={null}
        >
          <div 
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "25px",
            }}
          >
            <div
              style={{ fontSize: "24px", color: "#191919", fontWeight: "bold" }}
            >
              Confirm
            </div>
            <div
              style={{
                fontSize: "16px",
                color: "#636363",
                fontWeight: "bold",
                marginTop: "10px",
                textAlign:'center'
              }}
            >
              Are you sure you want to de-allocate {item.title}?
              
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "30px",
            }}
          >
            <div>
              <Button
                style={{
                  borderRadius: "19px",
                  width: "120px",
                  height: "35px",
                  border: "1px solid  #636363",
                  backgroundColor: "#FFFFFF",
                  color: "#594099",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    color: "#636363",
                    fontWeight: "bold",
                  }}
                  onClick={handleOk}
                >
                  NO
                </div>
              </Button>
            </div>
            <div>
              <Button
              loading={isDeleteLoading}
                style={{
                  borderRadius: "19px",
                  width: "120px",
                  height: "35px",
                  border: "1px solid #594099 ",
                  backgroundColor: "#594099",
                  fontSize: "16px",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                }}
                onClick={()=>{
                    setIsDeleteLoading(true);
                    deallocateTeachersGeofence(allocatedLoc,()=>{
                    setIsDeleteLoading(false)
                  },
                  ()=>{
                    setIsDeAllocateModalVisible(false);
                    togglePageLoader(true)
                    getTeachersGeofenceData(()=>{
                    togglePageLoader(false)
                  });
                  })
                  
                  }}
              >
                  YES
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  };

const GeoFenceCard = ({ teacherLocationTag, getTeachersGeofenceData, deallocateTeachersGeofence, togglePageLoader, item, index }) => {
  const [allocatedLoc, setAllocatedLoc] = useState([]);
  const [isDeAllocateModalVisible, setIsDeAllocateModalVisible] = useState(false);

  const deAllocateLocation = (item, index) => {
    let temp1 = allocatedLoc;
    if (temp1.includes(teacherLocationTag[index].allocationId)) {
      temp1.splice(temp1.indexOf(teacherLocationTag[index].allocationId), 1)
    } else {
      console.log("fogjh", teacherLocationTag[index].allocationId)
      temp1.push(teacherLocationTag[index].allocationId)
    }
    setAllocatedLoc([item?.allocationId])
    console.log("checkedArray", allocatedLoc, item, index)
  };

  const Card = () => {
    return (
      <div style={{ width: "fit-content", height: "40px", border: '1px solid #bfbfbf', borderRadius: '5px', marginTop: "18px", marginLeft: "20px", display: "flex", marginBottom: "10px" }}>
        {item.type === "Temporary" ?
          <div><img style={{ padding: "10px 0px 0px 7px" }} src={timerImage} /></div>
          :
          null
        }
        <Typography.Text style={{ marginTop: "9px", marginLeft: "5px", fontSize: "15px", color: "#636363", fontFamily: "roboto", fontWeight: "normal",width:"100px" }} ellipsis={{tooltip:true}}>{item.title}</Typography.Text>
        <CloseOutlined style={{ marginLeft: "10px", marginTop: "11px", color: "#bfbfbf", marginRight: '10px' }}
          onClick={(e) => {
            console.log("ABCD", item, index)
            e.stopPropagation();
            setIsDeAllocateModalVisible(true)
            deAllocateLocation(item, index)
          }}
        />
      </div>
    )
  }

  const content = (
    <div style={{ width: '200px', display: 'flex', justifyContent: 'space-around' }}>
      <div>
        <div style={{ color: " #000000", fontSize: "13px" }}>START DATE</div>
        <div style={{ color: " #000000", fontSize: "14px", fontWeight: "bold" }}>{item?.geoFenceAllocationStartDate === null ? "" : moment(item?.geoFenceAllocationStartDate).format("YYYY-MM-DD")}</div>
      </div>
      <div>
        <div style={{ color: " #000000", fontSize: "13px" }}>END DATE</div>
        <div style={{ color: " #000000", fontSize: "14px", fontWeight: "bold" }}>{item?.geoFenceAllocationEndDate === null ? "" : moment(item?.geoFenceAllocationEndDate).format("YYYY-MM-DD")}</div>
      </div>
    </div>
  );

  return (
    <>
      {item?.type == "Temporary" ?
        <Popover content={content}>
          <div style={{ width: "fit-content", height: "40px", border: '1px solid #bfbfbf', borderRadius: '5px', marginTop: "18px", marginLeft: "20px", display: "flex", marginBottom: "10px" }}>
            {item.type === "Temporary" ?
              <div><img style={{ padding: "10px 0px 0px 7px" }} src={timerImage} /></div>
              :
              null
            }
            <Typography.Text className='text-overflow-ellipsis' style={{ marginTop: "9px", marginLeft: "5px", fontSize: "15px", color: "#636363", fontFamily: "roboto", fontWeight: "normal",width:"100px" }} ellipsis={{tooltip:true}}>{item.title}</Typography.Text>
            <CloseOutlined style={{ marginLeft: "10px", marginTop: "11px", color: "#bfbfbf", marginRight: '10px' }}
              onClick={(e) => {
                console.log("ABCD", item, index)
                e.stopPropagation();
                setIsDeAllocateModalVisible(true)
                deAllocateLocation(item, index)
              }}
            />
          </div>
        </Popover>
        :
        <Card />
      }
      {isDeAllocateModalVisible &&
        <ConfirmDeleteModal
          isDeAllocateModalVisible={isDeAllocateModalVisible}
          setIsDeAllocateModalVisible={setIsDeAllocateModalVisible}
          deAllocateLocation={deAllocateLocation}
          item={item}
          index={index}
          deallocateTeachersGeofence={deallocateTeachersGeofence}
          getTeachersGeofenceData={getTeachersGeofenceData}
          allocatedLoc={allocatedLoc}
          togglePageLoader={togglePageLoader} />
      }
    </>

  )
}

const mapStateToProps = (state) => {
    const {teachersGeofenceDataContainer,usersNameContainer} =
      state.attendanceNew;
    return {
      teachersGeofenceDataContainer,usersNameContainer
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    getTeachersGeofenceData:(callback)=>(dispatch(getTeachersGeofenceData(callback))),
    searchFilter:(value)=>dispatch(searchFilter(value)),
    deallocateTeachersGeofence:(allocatedLoc,callback,successCallBack)=>dispatch(deallocateTeachersGeofence(allocatedLoc,callback,successCallBack)),
    // allocateTeachersGeofence:(checkedArray)=>(dispatch(allocateTeachersGeofence(checkedArray))),
    });
  
  export default connect(mapStateToProps, mapDispatchToProps)(GeoFenceCard);
// export default GeoFenceCard;