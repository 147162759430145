import { currentTypes, deleteNotes, fetchClassLibraryData, fetchDashBoardData, fetchLibraryData, fetchTopicWiseList, postCreateTopic, updateSetType } from 'actions/digitalLibrary'

import { Modal, Typography } from 'antd'
import { Button } from 'components/Button'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

const { Text } = Typography



const DelConfirmModal = ({
    isModalVisible, setModalVisible, id, postCreateTopic, fetchTopicWiseList, notes, deleteNotes, fetchLibraryData, history,
    contentPage, fetchClassLibraryData, fetchDashBoardData, pageNumber, tooglePageLoader, setTopicsData, selectedChapter, updateSetType, currentTypes
}) => {

    const [delLoader, setLoader] = useState(false)
    const handleCancle = (e) => {
        e.stopPropagation();
        setModalVisible(false)
    }
    const handleDelete = (e) => {
        e.stopPropagation();
        if (!notes) {
            let object = {
                topicId: id,
                deleted: 1,
                chapterId: selectedChapter?.chapterId ? selectedChapter?.chapterId : null
            }
            setLoader(true)
            postCreateTopic(object, () => setLoader(false), successCallback)
        }
        else if (notes) {
            setLoader(true)
            deleteNotes(id, () => setLoader(false), (contentCount) => {successCallback(contentCount)})
        }
        // setModalVisible(false)
    }

    const successCallback = (contentCount) => {
        setModalVisible(false)
        if (!notes) {
            tooglePageLoader(true)
            fetchTopicWiseList('', () => tooglePageLoader(false), (response) => { setTopicsData(response)})
        }
        else if (notes) {
            if (history) {
                history.goBack()
            }
            if (pageNumber == 3) {
                updateSetType(parseInt(contentCount?.notesCount) > 0 ? 'lecture-notes' : parseInt(contentCount?.videoCount) > 0 ? "video" : "question-bank")
                currentTypes(parseInt(contentCount?.notesCount) > 0 ? 'lecture-notes' : parseInt(contentCount?.videoCount) > 0 ? "video" : "question-bank")
                fetchLibraryData('');
            }
            else if (pageNumber == 2) {
                fetchClassLibraryData()
            }
            else if (pageNumber == 1) {
                fetchDashBoardData()
            }
        }
        setModalVisible(false)
    }

    return (
        <Modal
            className="deleteModal"
            visible={isModalVisible}
            closable={false}
            footer={false}
            onCancel={(e) => handleCancle(e)}
            maskClosable={false}
        >
            <div className="deleteModal-content" onClick={(e) => e.stopPropagation()}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ textAlign: 'center', color: '#594099', fontWeight: 'bold', fontFamily: 'roboto', fontSize: '24px' }}>{`Confirm to delete`}</Text><br></br>
                    <Text style={{ textAlign: 'center', color: '#636363', fontFamily: 'roboto', fontSize: '12px' }}>{` Are you sure you want to delete this ${notes == true ? 'content' : 'topic'}?`}</Text>
                </div>
                <div style={{ width: '60%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button type='default' style={{ width: '100px' }} onClick={(e) => handleCancle(e)}>NO</Button>
                    <Button loading={delLoader} type='primary' style={{ width: '100px' }} onClick={(e) => handleDelete(e)}>YES</Button>
                </div>
            </div>

        </Modal>
    )
}

const mapStateToProps = (state) => {
    const {
        pageNumber, selectedChapter
    } = state.digitalLibrary;
    return {
        pageNumber, selectedChapter
    };
};

const mapDispatchToProps = (dispatch) => ({
    postCreateTopic: (object, callback, successCallback) => dispatch(postCreateTopic(object, callback, successCallback)),
    fetchTopicWiseList: (search, callback, successCallBack) => dispatch(fetchTopicWiseList(search, callback, successCallBack)),
    deleteNotes: (id, callback, successCallback) => dispatch(deleteNotes(id, callback, successCallback)),
    fetchLibraryData: (search, callback) => dispatch(fetchLibraryData(search, callback)),
    fetchClassLibraryData: (callback) => dispatch(fetchClassLibraryData(callback)),
    fetchDashBoardData: (callback) => dispatch(fetchDashBoardData(callback)),
    updateSetType: (data) => dispatch(updateSetType(data)),
    currentTypes: (type) => dispatch(currentTypes(type))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(DelConfirmModal);

// export default DelConfirmModal