import React from "react";
import Row from "antd/lib/row";
import Modal from "antd/lib/modal";
import Col from "antd/lib/col";
import Progress from "antd/lib/progress";
import { SyncOutlined } from "@ant-design/icons";
import "antd/lib/row/style/index";
import "antd/lib/col/style/index";
import "antd/lib/progress/style/index";
import { connect } from "react-redux";
import { Color } from "services";
import { DetailCard, AssignmentTopBar } from "components";
import {
  setFinishMode,
  setStudentAssignmentStatus,
  answeringLoaderToggle
} from "actions/assignment";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import "./styles.css";
import "design/layout.css";
import "design/assignment.css";

class StartAssignment extends React.Component {
  state = {
    loading: false
  };
  definition = (title, details) => (
    <div className="NutinFont text-xmd m-t-10">
      {title ? <div className="lowgrey">{title}</div> : null}
      {details ? <div className="color-black">{details}</div> : null}
    </div>
  );

  beginAssignment(id) {
    this.props.history.push(`${id}/start`);
  }
  beginReview = id => this.props.history.push(`${id}/result`);

  componentDidMount() {
    this.props.answeringLoaderToggle(false);
  }

  render() {
    const {
      assignmentLoader,
      studentCurrentAssignment,
      history,
      studentCurrentSubmitData
    } = this.props;
    return (
      <div>
        <AssignmentTopBar
          backPress={() => history.push(`/web-assignment`)}
          title={studentCurrentAssignment.sub}
          listMode={false}
        />
        {studentCurrentAssignment.resultsPublished ? (
          <div className="yellowGrad p-l-10 p-r-10 NutinFont">
            <div className="text-md bold-600 text-center">Your Score</div>
            <Row align="center" className="p-t-20">
              <Progress
                type="circle"
                strokeColor={{
                  "0%": "#69e9b8",
                  "100%": "#28df99"
                }}
                percent={Math.round(studentCurrentSubmitData.percentage)}
              />
            </Row>
            <Row align="center" className="p-t-20">
              Questions: {studentCurrentSubmitData.totalQuestions}
              <br />
              Marks: {studentCurrentSubmitData.totalMarksObtained} /{" "}
              {studentCurrentSubmitData.totalMarks}
            </Row>
            <Row className="p-t-20 p-b-20">
              <Col span={1} />
              <Col span={7}>
                Attempted <br />
                Unattempted <br />
              </Col>
              <Col span={1}>
                : <br />
                : <br />
              </Col>
              <Col span={4}>
                {studentCurrentAssignment.assignmentStatus != "Submitted"
                  ? studentCurrentSubmitData.attempted
                  : ""}{" "}
                <br />
                {studentCurrentAssignment.assignmentStatus != "Submitted"
                  ? studentCurrentSubmitData.unansweredCount
                  : ""}{" "}
                <br />
              </Col>
              <Col span={7}>
                Correct <br />
                Incorrect <br />
              </Col>
              <Col span={1}>
                : <br />
                : <br />
              </Col>
              <Col span={2}>
                {studentCurrentAssignment.assignmentStatus != "Submitted"
                  ? studentCurrentSubmitData.correctCount
                  : ""}{" "}
                <br />
                {studentCurrentAssignment.assignmentStatus != "Submitted"
                  ? studentCurrentSubmitData.inCorrectCount
                  : ""}
              </Col>
              <Col span={1} />
            </Row>
          </div>
        ) : null}
        <div className="p-10 m-b-50">
          {this.definition("Title", studentCurrentAssignment.title)}
          {this.definition(
            "Description",
            ReactHtmlParser(studentCurrentAssignment.description)
          )}
          {this.definition(
            "Instructions",
            ReactHtmlParser(studentCurrentAssignment.instructions)
          )}
          <div className="lowgrey text-xmd m-t-10">Progress</div>
          {studentCurrentAssignment.chapters &&
          studentCurrentAssignment.chapters.length
            ? studentCurrentAssignment.chapters.map((data, index) => (
                <div className="color-black">{data}</div>
              ))
            : null}
          <Progress
            percent={Math.round(studentCurrentAssignment.progress)}
            className="m-t-10 m-b-10"
          />
          <DetailCard
            dueDate={
              moment(studentCurrentAssignment.deadlineDate).format(
                "DD MMM YYYY"
              ) || ""
            }
            result={
              moment(studentCurrentAssignment.resultDueDate).format(
                "DD MMM YYYY"
              ) || ""
            }
            questions={studentCurrentAssignment.totalQuestions}
            faculty={
              studentCurrentAssignment.author
                ? studentCurrentAssignment.author.name
                : ""
            }
            time={studentCurrentAssignment.duration || "00:00"}
            attempted={studentCurrentAssignment.attemptedQuestions || 0}
            unattempted={
              studentCurrentAssignment.totalQuestions -
                studentCurrentAssignment.attemptedQuestions || 0
            }
            assignmentStatus={studentCurrentAssignment.assignmentStatus}
          />

          <>
            {studentCurrentAssignment.assignmentStatus === "Graded" ||
            studentCurrentAssignment.assignmentStatus === "Submitted" ? (
              <div
                style={{
                  padding: "10px 5px",
                  position: "sticky",
                  bottom: 20
                }}
                onClick={() => this.beginReview(studentCurrentAssignment.id)}
                className="backPurp white text-md radius-100 r-c-c"
              >
                REVIEW <i className="icon-ic-arrow-forward white" />
              </div>
            ) : (
              <div
                style={{
                  padding: "10px 5px",
                  position: "sticky",
                  bottom: 20
                }}
                onClick={() =>
                  this.beginAssignment(studentCurrentAssignment.id)
                }
                className="backPurp white text-md radius-100 r-c-c"
              >
                {studentCurrentAssignment.assignmentStatus == "New"
                  ? "START"
                  : "RESUME"}{" "}
                <i className="icon-ic-arrow-forward white" />
              </div>
            )}
          </>
        </div>
        <Modal
          visible={assignmentLoader}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img src={require('Assets/dots-gif.gif').default} style={{maxWidth: "100%"}} width={150} alt=""/>

            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} />
            <div className="text-xmd m-t-10">Loading Assignment Details</div> */}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    assignmentLoader,
    finishMode,
    answeringLoader,
    studentCurrentSubmitData,
    studentCurrentAssignment
  } = state.assignment;
  return {
    assignmentLoader,
    finishMode,
    answeringLoader,
    studentCurrentSubmitData,
    studentCurrentAssignment
  };
};

const mapDispatchToProps = dispatch => ({
  setStudentAssignmentStatus: status =>
    dispatch(setStudentAssignmentStatus(status)),
  setFinishMode: value => dispatch(setFinishMode(value)),
  answeringLoaderToggle: value => dispatch(answeringLoaderToggle(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(StartAssignment);
