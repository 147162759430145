import { fetchTopicWiseList, postCreateTopic } from 'actions/digitalLibrary'
import { Input, Modal, Typography } from 'antd'
import { Button } from 'components/Button'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

const CreateTopic = ({ isModalVisible, setIsModalVisible, selectedChapter, postCreateTopic, edit, selectedTopic, fetchTopicWiseList, setIsSearchClear, onSearchTopic, setSearch, tooglePageLoader, setTopicsData }) => {

    let object;

    const [topic, setTopic] = useState('');
    const [createLoader, setCreateLoader] = useState(false);

    const handleCancle = (e) => {
        () => setIsSearchClear('')
        e.stopPropagation();
        setIsModalVisible(false)
    };

    const handleChange = (e) => {
        e.stopPropagation();
        setTopic(e.target.value)
    };

    const successCallback = () => {
        console.log("AA-abced");
        // () => onSearchTopic('')
        tooglePageLoader(true)
        fetchTopicWiseList('', () => tooglePageLoader(false), (response) => { setTopicsData(response)})
        setIsModalVisible(false);
        setCreateLoader(false);
    };

    const callback = () => {
        setCreateLoader(false);
        // () => setSearch('');
        // () => setIsSearchClear('');
        // onSearchTopic('');
    }

    const handleCreate = (e) => {
        // () => setIsSearchClear('')
        e.stopPropagation();
        if (!edit) {
            object = {
                chapterId: selectedChapter?.chapterId ? selectedChapter?.chapterId : '',
                topicName: topic
            }
        }
        else {
            object = {
                topicId: selectedTopic?.topicId,
                topicName: topic
            }
        }

        setCreateLoader(true)
        postCreateTopic(object, () => callback(), () => { successCallback() })
    };

    useEffect(() => {
        setTopic('')
        if (edit == true) {
            if (Object.keys(selectedTopic)?.length != 0) {
                setTopic(selectedTopic?.topicName)
            }
        }
    }, [])

    return (
        <div>
            <Modal
                className='createTopicLibrary'
                open={isModalVisible}
                footer={null}
                centered={true}
                closable={false}
                bodyStyle={{ height: "280px" }}
                maskClosable={false}
                onCancel={(e) => handleCancle(e)}
            >
                <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }} onClick={(e) => e.stopPropagation()}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
                        <Typography.Text className='text-heading-library' style={{ fontSize: '24px', marginTop: '10px' }}>{edit == true ? 'Edit a topic' : 'Create a topic'}</Typography.Text>
                    </div>
                    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ width: '100%' }}>
                            <div className='text-heading-library' style={{ marginBottom: '10px' }}>Topic</div>
                            <Input placeholder='Enter a topic' onChange={(e) => handleChange(e)} value={topic}></Input>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <Button type='default' style={{ width: '120px', marginRight: '5px' }} onClick={(e) => handleCancle(e)}>Cancel</Button>
                            <Button loading={createLoader} type='primary' style={{ width: '120px', marginLeft: '5px' }} onClick={(e) => handleCreate(e)} disabled={(topic && topic.trim() != '') ? false : true}>{!edit ? 'Create' : 'Save'}</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { selectedChapter, selectedTopic } = state.digitalLibrary;
    const { user } = state.session
    return { user, selectedChapter, selectedTopic };
};

const mapDispatchToProps = (dispatch) => ({
    postCreateTopic: (object, callback, successCallback) => dispatch(postCreateTopic(object, callback, successCallback)),
    fetchTopicWiseList: (search, callback, successCallBack) => dispatch(fetchTopicWiseList(search, callback, successCallBack))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTopic);
