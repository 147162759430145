import React, { useState } from 'react';
import "../styles.css";
import "../../../components/overViewCard/style.css"

function CrmOverviewCard({ count, subTitle, imageSources, item,hoverNone}) {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div className={hoverNone ? 'courseOverviewParent' : 'courseOverviewParent zoom'} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className={hoverNone ? 'crmOverviewCard-none1' :"courseOverviewCard"}>
                <div className='overviewCourseinfo'>
                    <div className="card-titles">{count}<span className='card-sub-titles text-ellipsis--2'> {subTitle}</span></div>
                    <div className="card-img-parent"> <img className='card-img' src={imageSources} alt={"images"} /></div>
                </div>
                {/* {isHovered && <div className='courseOverviewHoveredText'
                    onClick={() => {
                        setCourseTypeName(item?.overviewType == "visitors" ? "total visitors" : item?.overviewType == "download" ? "app downloads" : item?.overviewType == "enrollPurchase" ? "enrolled/purchased" : item?.overviewType == "leadsNotConverted" ? "leads not converted" : null);
                        // history.push(item?.overviewType == "liveCourse" ? "/live-courses-data" : item?.overviewType == "videoResource" ? "/video-resources-data" : item?.overviewType == "testSeries" ? "/online-test-series-details" : item?.overviewType == "studyMaterial" ? "/study-materials-data" : "/live-courses-data")
                    }}
                >VIEW</div>} */}
            </div>
        </div>
    );
}

export default CrmOverviewCard;