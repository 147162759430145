import isEqual from 'lodash.isequal'

export const ValueOpType = {
	Put: 'put',
	Delete: 'delete',
	Append: 'append',
	Patch: 'patch',
}

export function diffRecord(prev, next){
	return diffObject(prev, next, new Set(['props']))
}

export function diffRecordTwoside(prev, next){
    return [diffObject(prev, next, new Set(['props'])), diffObject(next, prev, new Set(['props']))]
}

function diffObject(prev, next, nestedKeys) {
    if (prev === next) {
        return null
    }
    let result = null;
    for (const key of Object.keys(prev)) {
        if (key === 'isSelected') continue; // Ignore the 'isSelected' key
        // if key is not in next then it was deleted
        if (!(key in next)) {
            if (!result) result = {};
            result[key] = [ValueOpType.Delete];
            continue;
        }
        // if key is in both places, then compare values
        const prevVal = prev[key];
        const nextVal = next[key];
        if (!isEqual(prevVal, nextVal)) {
            if (nestedKeys?.has(key) && prevVal && nextVal) {
                const diff = diffObject(prevVal, nextVal);
                if (diff) {
                    if (!result) result = {};
                    result[key] = [ValueOpType.Patch, diff];
                }
            } else if (Array.isArray(nextVal) && Array.isArray(prevVal)) {
                const op = diffArray(prevVal, nextVal);
                if (op) {
                    if (!result) result = {};
                    result[key] = op;
                }
            } else {
                if (!result) result = {};
                result[key] = [ValueOpType.Put, nextVal];
            }
        }
    }
    for (const key of Object.keys(next)) {
        if (key === 'isSelected') continue; // Ignore the 'isSelected' key
        // if key is in next but not in prev then it was added
        if (!(key in prev)) {
            if (!result) result = {};
            result[key] = [ValueOpType.Put, next[key]];
        }
    }
    return result;
}

function diffValue(valueA, valueB) {
    if (Object.is(valueA, valueB)) return null;
    if (Array.isArray(valueA) && Array.isArray(valueB)) {
        return diffArray(valueA, valueB);
    } else if (!valueA || !valueB || typeof valueA !== 'object' || typeof valueB !== 'object') {
        return isEqual(valueA, valueB) ? null : [ValueOpType.Put, valueB];
    } else {
        const diff = diffObject(valueA, valueB);
        return diff ? [ValueOpType.Patch, diff] : null;
    }
}

function diffArray(prevArray, nextArray) {
    if (Object.is(prevArray, nextArray)) return null;
    // if lengths are equal, check for patch operation
    if (prevArray.length === nextArray.length) {
        // bail out if more than len/5 items need patching
        const maxPatchIndexes = Math.max(prevArray.length / 5, 1);
        const toPatchIndexes = [];
        for (let i = 0; i < prevArray.length; i++) {
            if (!isEqual(prevArray[i], nextArray[i])) {
                toPatchIndexes.push(i);
                if (toPatchIndexes.length > maxPatchIndexes) {
                    return [ValueOpType.Put, nextArray];
                }
            }
        }
        if (toPatchIndexes.length === 0) {
            // same length and no items changed, so no diff
            return null;
        }
        const diff = {};
        for (const i of toPatchIndexes) {
            const prevItem = prevArray[i];
            const nextItem = nextArray[i];
            if (!prevItem || !nextItem) {
                diff[i] = [ValueOpType.Put, nextItem];
            } else if (typeof prevItem === 'object' && typeof nextItem === 'object') {
                const op = diffValue(prevItem, nextItem);
                if (op) {
                    diff[i] = op;
                }
            } else {
                diff[i] = [ValueOpType.Put, nextItem];
            }
        }
        return [ValueOpType.Patch, diff];
    }

    // if lengths are not equal, check for append operation, and bail out
    // to replace whole array if any shared elems changed
    for (let i = 0; i < prevArray.length; i++) {
        if (!isEqual(prevArray[i], nextArray[i])) {
            return [ValueOpType.Put, nextArray];
        }
    }

    return [ValueOpType.Append, nextArray.slice(prevArray.length), prevArray.length];
}

/** @public */
export function applyObjectDiff(object, objectDiff){
	// don't patch nulls
	if (!object || typeof object !== 'object') return object
	const isArray = Array.isArray(object)
	let newObject = undefined
	const set = (k, v) => {
		if (!newObject) {
			if (isArray) {
				newObject = [...object]
			} else {
				newObject = { ...object }
			}
		}
		if (isArray) {
			newObject[Number(k)] = v
		} else {
			newObject[k] = v
		}
	}
	for (const [key, op] of Object.entries(objectDiff)) {
		switch (op[0]) {
			case ValueOpType.Put: {
				const value = op[1]
				if (!isEqual(object[key], value)) {
					set(key, value)
				}
				break
			}
			case ValueOpType.Append: {
				const values = op[1]
				const offset = op[2]
				const arr = object[key]
				if (Array.isArray(arr) && arr.length === offset) {
					set(key, [...arr, ...values])
				}
				break
			}
			case ValueOpType.Patch: {
				if (object[key] && typeof object[key] === 'object') {
					const diff = op[1]
					const patched = applyObjectDiff(object[key], diff)
					if (patched !== object[key]) {
						set(key, patched)
					}
				}
				break
			}
			case ValueOpType.Delete: {
				if (key in object) {
					if (!newObject) {
						if (isArray) {
							console.error("Can't delete array item yet (this should never happen)")
							newObject = [...object]
						} else {
							newObject = { ...object }
						}
					}
					delete newObject[key]
				}
			}
		}
	}

	return newObject ?? object
}