import React from "react";
import "./mobilePageHeader.css";
import { MenuOutlined } from "@ant-design/icons";
import { MdKeyboardBackspace } from "react-icons/md";
import { Typography } from "antd";
import { setSideBarStudentHome } from "actions/studentMarketPlace";
import { connect } from "react-redux";

// const bodyStyle = {
//     paddingRight: 20,
//     position: "fixed",
//     top: 0,
//     backgroundColor: "#ffffff",
//     // borderBottom: '1px solid #E6E6E6',
//     zIndex: 10,
//     padding: 10,
// };

const MobilePageHeader = ({
    title,
    onBackPress,
    actions,
    subtitle,
    givenStyle,
    sider,
    setSideBarStudentHome,
    subChapter,
}) => {
    return (
        <div style={{ padding: "10px 20px" }} className="mobile-top-bar-parent">
            <div className="r-c-fs" style={{ width: "100%" }}>
                {onBackPress ? (
                    <MdKeyboardBackspace
                        style={{ fontSize: 20, }}
                        className="text-mdl m-t-5 cursor-pointer"
                        label="Back"
                        onClick={() => onBackPress()}
                    />
                ) : null}
                <div
                    className=""
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                        <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Typography.Text
                                ellipsis={{ tooltip: true }}
                                style={{
                                    fontSize: "1.5em",
                                    fontWeight: 700,
                                    marginLeft: onBackPress ? 10 : 30,
                                    maxWidth: 600,
                                    width: 'fit-content',
                                }}
                            >
                                {" "}
                                {title}{" "}
                            </Typography.Text>
                            {subChapter ?
                                <span style={{
                                    fontSize: "1em",
                                    fontWeight: 500,
                                    marginLeft: onBackPress ? 10 : 30,
                                    maxWidth: 600,
                                    width: 'fit-content',
                                }}> {" "}
                                {subChapter}{" "} Chapter</span>
                                : <></>}
                        </div>
                        {subtitle ? (
                            <Typography.Text
                                ellipsis={{ tooltip: true }}
                                style={{
                                    fontSize: "1rem",
                                    fontWeight: 600,
                                    marginLeft: onBackPress ? 10 : 30,
                                    color: "#636363",
                                    width: 400
                                }}
                            >
                                {" "}
                                {subtitle}{" "}
                            </Typography.Text>
                        ) : null}
                        {sider ? (
                            <MenuOutlined style={{ color: "#AEAEAE", fontSize: 23 }} onClick={() => { setSideBarStudentHome(true) }} />
                        ) : (<></>)}

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {

};

const mapDispatchToProps = (dispatch) => ({
    setSideBarStudentHome: (val) => dispatch(setSideBarStudentHome(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MobilePageHeader)
