import React, { useEffect, useState } from 'react';
import { Tooltip, Typography } from 'antd'
import { Api } from 'services';
import '../userHomeStyles.css';
import RatingStars from '../ratingStars';
import { Button } from 'components/Button';
import { getCartCount, impressionEngagementIncrement, postAddCartItem, postAddSaveItem, postCourseEnrollNow, removeSaveItem, storeSingleBuyNow } from 'actions/studentMarketPlace';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import saveOutlined from "../../../Assets/aulasMarket/notSavedBookmark.svg"
import saveFilled from "../../../Assets/aulasMarket/savedBookmark.svg"
import studyMaterialImg from '../../../Assets/aulasMarket/studyMaterialImg.svg'
import testDefaultImg from '../../../Assets/aulasMarket/testImg.svg'
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';

import { motion } from 'framer-motion';
import FreeCourseConfirmationModal from './freeConfirmationModal';
import { setShowBottomSheet, setShowLoginModal } from 'actions/loginNew';

function MobileViewListCards({ item, postAddSaveItem, postAddCartItem, removeSaveItem, user, postCourseEnrollNow, getCartCount, impressionEngagementIncrement, setShowDownloadAppModal, setShowLoginModal, setShowBottomSheet }) {
    const history = useHistory()
    const [shareUrl, setShareUrl] = useState(null);
    const [saved, setSaved] = useState({});
    const [cart, setCart] = useState({});
    const [enroll, setEnroll] = useState({})
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [isSavedClicked, setIsSavedClicked] = useState(false);
    const [enrollLoader, setEnrollLoader] = useState(false);
    const [addCartLoader, setAddCartLoader] = useState(false)
    const [addCartClicked, setAddToCartClicked] = useState(false)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    useEffect(() => {
        setSaved((prevSavedItems) => ({
            ...prevSavedItems,
            [item?.id]: item?.inSaved == 1 ? true : item?.inSaved == 0 ? false : null,
        }));
        setEnroll((prevEnrolledItems) => ({
            ...prevEnrolledItems,
            [item?.id]: item?.owned == 1 ? true : item?.owned == 0 ? false : null,
        }));
    }, [item?.owned, item?.inSaved])

    const handleSaveForLater = (id, itemType) => {

        let params = {
            id: id,
            type: 'engagement',
            courseType: itemType
        }
        impressionEngagementIncrement(params, () => { setEnrollLoader(false) })
        setIsSavedClicked(!isSavedClicked)
        if (user?.accessToken) {
            if (saved[id]) {
                removeSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: false,
                        }));
                    }
                });
            } else {
                postAddSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: true,
                        }));
                    }
                });
            }
        } else {
            history.push('/login');
        }
    };
    const handleAddToCart = (id, itemType) => {

        let params = {
            id: id,
            type: 'engagement',
            courseType: itemType
        }
        impressionEngagementIncrement(params, () => { setEnrollLoader(false) })
        if (user?.accessToken) {

            postAddCartItem(id, itemType, (res) => {
                if (res) {
                    setAddCartLoader(false)
                    setCart((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                    setAddToCartClicked(true)
                }
            });
        } else {
            history.push('/login');
        }
    };
    const handleEnroll = (id, itemType) => {
        let params = {
            id: id,
            type: 'engagement',
            courseType: itemType
        }
        impressionEngagementIncrement(params, () => { setEnrollLoader(false) })

        if (user?.accessToken) {
            postCourseEnrollNow(id, itemType, (res) => {
                if (res) {
                    setEnrollLoader(false)
                    setEnroll((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                    // history.push('/courses-list')
                    setFreeConfirmModal(false)
                }
                setShowDownloadAppModal(true)
            })
        } else {
            history.push('/login');
        }
    };
    console.log("item-06", item);

    const handleCardClick = () => {
        let params = {
            id: item?.id,
            type: 'impression',
            courseType: item?.resourceType
        }
        impressionEngagementIncrement(params, () => { setEnrollLoader(false) })
        history.push({
            pathname: "/resource-details",
            state: {
                id: item?.id,
                resourceType: item?.resourceType
            }
        })

    }
    function formatDuration(duration) {
        console.log("item?.plannedHours1", duration);
        
        if (!duration) {
            return '0 hour'; // Return "0 hour" when no duration is provided
        }
    
        // Split the duration into hours, minutes, and seconds
        const [hours = '0', minutes = '0', seconds = '0'] = duration.split(':');
        
        // Convert minutes and seconds into minutes
        const totalMinutes = parseInt(minutes, 10);
        const totalSeconds = parseInt(seconds, 10);
        let totalHours = parseInt(hours, 10);
        let result = '';
    
        // Check the conditions for minutes and seconds
        if (totalMinutes === 30 && totalSeconds === 0) {
            // Exact 2:30:00 case
            totalHours += 0.5;
            result = `${totalHours} ${totalHours > 0 ? 'hours' : 'hour'}`;  // Use "hour" for 0, "hours" for >0
        } else if (totalMinutes === 30 && totalSeconds > 0) {
            // 2:30:01 and above case (e.g., 2:30:47)
            totalHours += 0.5;
            result = `${totalHours}+ ${totalHours > 0 ? 'hours' : 'hour'}`;  // Use "hours" for non-zero values
        } else if (totalMinutes >= 45) {
            // 2:45:00 and above
            totalHours += 0.5;
            result = `${totalHours}+ ${totalHours > 0 ? 'hours' : 'hour'}`;
        } else if (totalMinutes > 0 && totalMinutes < 30) {
            // 2:15:00 to 2:29:59
            totalHours += 1;
            result = `${totalHours}+ ${totalHours > 0 ? 'hours' : 'hour'}`;
        } else {
            // Handle exact hours like 2:00:00
            result = `${totalHours} ${totalHours > 0 ? 'hours' : 'hour'}`;  // Use "hours" if >0, "hour" if 0
        }
    
        return result;
    }
    const [freeCourseName,setFreeCourseName]=useState('')
    const [freeCourseType,setFreeCourseType]=useState('')
    const [freeConfirmModal,setFreeConfirmModal]= useState(false)

    const handleFree=()=>{
        handleEnroll(item?.id, item?.resourceType)

    }
    return (
        <div className='course-mobile-card' onClick={()=>{handleCardClick()}}>
            <div style={{ position: "relative", width: "100%", }}>
                <div style={{ overflow: "hidden", borderRadius: "10px", width: "100%" }}>
                    <motion.img
                        src={item?.image ? `${Api.dlS3Url}${item?.image}` : item?.resourceType == "testSeries" ? testDefaultImg : studyMaterialImg}
                        loading="lazy"
                        alt="blogs-image"
                        style={{ width: "100%",height:140 }}
                        initial={{ scale: 0.8 }}
                        animate={{ scale: 1 }}
                        whileHover={{ scale: 1.08 }}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                    />
                </div>
                {item?.mode ? <div className={`${item?.mode == 'online' ? 'online-course-mode' : item?.mode == 'hybrid' ? 'hybrid-course-mode' : 'offline-course-mode'}`}>{item?.mode?.charAt(0)?.toUpperCase() + item?.mode?.slice(1)}</div> : <></>}
            </div>
            <div style={{ height: '40px', marginTop: 10, width: "100%", }}>
                <Tooltip title={item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName}>
                    <div style={{ width: '100%', overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 2, fontWeight: 400, WebkitBoxOrient: "vertical", color: "#191919", fontSize: 12 }}>{item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName}</div>
                </Tooltip>
            </div>
            <div style={{ display: "flex", width: "100%",height:"20px" }}>
                <div style={{width:"100%"}}>
                {item?.resourceType === "videoResource" ? (
                                <div className='' style={{ color: "#636363",fontSize:10 }}>
                                    {formatDuration(item?.duration)}{<>| {item?.quizCount == 1 || item?.quizCount == 0 ? `${item?.quizCount} quiz` : `${item?.quizCount}quizzes`}</>} {<>| {item?.notesCount} notes</>}
                                </div>
                            ) :  item?.resourceType == 'course' ? (<>
                                <div className='' style={{ color: "#636363",fontSize:10}}>
                                        {<>{item?.plannedClasses} classes  |</>}  { <>{item?.plannedTests } tests </>} { <>| {formatDuration(item?.plannedHours)}</>}
                                    </div>
                                </>):
                                item?.resourceType == "studyMaterial" ? (
                                    <>
                                     <div className='' style={{ color: "#636363",fontSize:10}}>
                                            {<>{item?.m_count == 1 ? `${item?.m_count} material` : `${item?.m_count} materials`} |</>}  {<>{item?.q_count == 1 ? `${item?.q_count} quiz` : `${item?.q_count} quizzes` }  </>}
                                        </div>
                                    </>):
                                     item?.resourceType == "testSeries"? (
                                        <>
                                         <div className='' style={{ color: "#636363",fontSize:10}}>
                                                {<>{item?.tCount == 1 ? `${item?.tCount} test` : `${item?.tCount} tests`} </>}
                                            </div>
                                        </>): (
                                <div className='' style={{ color: "#636363", marginTop: 10 }}></div>
                            )}
                </div>
                <div style={{justifyContent:"flex-end",width:"70%"}}>
                {item?.resourceType == "testSeries"
                    ?
                    <div style={{ display: "flex" }}>
                        <div style={{ color: "#FFA931", fontSize: 12,fontWeight: 700, marginRight: 5, }}>{item?.ratingValue ? item?.ratingValue : 0}</div>
                        <RatingStars star={item?.ratingValue} size={11}/>
                        <div style={{ color: "#636363", fontSize: 12,  fontWeight: 600 ,marginLeft:5}}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : `(0)`}</div>
                    </div>
                    :
                    <div style={{ display: "flex" }}>
                        <div style={{ color: "#FFA931", fontSize: 12,  fontWeight: 700, marginRight: 5, }}>{item?.rating ? item?.rating : 0}</div>
                        <RatingStars star={item?.rating} size={11}/>
                        <div style={{ color: "#636363", fontSize: 12,  fontWeight: 600,marginLeft:5 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : `(0)`}</div>
                    </div>
                }
                </div>
            </div>
            <div className='m-t-7' style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div >
                        {item?.amount > 0 ? <div style={{ fontSize: 11, color: "#594099",fontWeight: 'bold', bottom: 10, right: 10, }}>₹{(parseFloat(item?.amount || 0) - parseFloat(item?.discountAmount)).toFixed(2)}{" "}{item?.discountAmount > 0 ? <span style={{ color: "#636363", fontWeight: 400, textDecoration: 'line-through', fontSize: 11 }}>₹{parseFloat(item?.amount || 0).toFixed(2)}</span> : <></>}</div> : <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D",  fontWeight: "bold", bottom: 10, right: 10, backgroundColor: "#FF414D", width: "50px", height: 20 }}> <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div></div>}
                    </div>
                        <div style={{ display: "flex", justifyContent: "space-between",width:35,gap:5 ,}}>

                            <img src={require("../../../Assets/aulasMarket/shareIcon-copy.svg").default} style={{ marginLeft: item?.owned == 1 || item?.disableAdmission ? 20 : 0, height: 15, width: 15,marginTop:2 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShareModalVisible(true);
                                    setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`)
                                }} />
                            {user?.role?.includes("Student") || user?.accessToken == undefined ?
                                <>
                                    {item?.owned == 1 || item?.disableAdmission ? <></> :
                                        <div style={{ marginTop: -3 }} onClick={(e) => {
                                            e.stopPropagation();
                                            if (user.accessToken) {
                                                handleSaveForLater(item?.id, item?.resourceType)
                                            } else {
                                                if (isMobile) {
                                                    setShowBottomSheet(true)
                                                } else {
                                                    setShowLoginModal(true)
                                                }
                                            }
                                        }}><img src={addCartClicked ? saveOutlined : saved[item?.id] || (isSavedClicked && item?.isSaved == 0) ? saveFilled : saveOutlined} style={{ height: 15, width: 15 }} /></div>}
                                </> : <></>}
                        </div>

                </div>
                <div>
            {item?.disableAdmission ?
                    <center>
                        <div className='r-c-c' style={{ fontSize: "15px", color: "#FF414D", width: "100%",  borderRadius: "50px",  fontWeight: 600,marginTop:10 }}>Admission closed</div>
                    </center> : <>
                        {user?.role?.includes("Student") || user?.accessToken == undefined ? <>
                            <center className='m-t-10'>
                                {item?.owned == 1 || enroll[item?.id] ?
                                    <div  >
                                        <Button style={{ position: 'relative', overflow: 'hidden', border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "100%", padding: "5px 10px", borderRadius: 8, }}>
                                            <div className='glare'></div>

                                            VIEW COURSE</Button>
                                    </div>
                                    :
                                    <>
                                        {item?.amount == 0 || item?.feeType == "free" ?
                                            <Button loading={enrollLoader} className='m-t-5' type="primary" style={{ position: 'relative', overflow: 'hidden', width: "100%", padding: "5px 10px", borderRadius: 8, marginTop: 10 }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (user?.accessToken) {
                                                        if (enroll[item?.id]) { } else if (item?.feeType == "free") {
                                                            setFreeConfirmModal(true)
                                                            setFreeCourseType(item?.resourceType)
                                                            setFreeCourseName(item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName)
                                                        }
                                                    } else {
                                                        if (isMobile) {
                                                            setShowBottomSheet(true)
                                                        } else {
                                                            setShowLoginModal(true)
                                                        }
                                                    }
                                                }}
                                            >
                                                <div className='glare'></div>

                                                {enroll[item?.id] ? "ENROLLED" : "ENROLL NOW"}</Button>
                                            :
                                            <div className='r-c-sb m-t-8' style={{ width: "100%" }}>
                                                {item?.resourceType == "course" ? null :
                                                    <Button loading={addCartLoader} loaderArgColor="#594099" style={{ position: 'relative', overflow: 'hidden', border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "47%", padding: "5px 10px", borderRadius: 8, }} onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (!user?.accessToken) {
                                                            if (isMobile) {
                                                                setShowBottomSheet(true)
                                                            } else {
                                                                setShowLoginModal(true)
                                                            }
                                                        } else if ((item?.inCart == 1 || cart[item?.id]) && user?.accessToken) {
                                                            history.push("/cart")
                                                        } else {
                                                            setAddCartLoader(true)
                                                            handleAddToCart(item?.id, item?.resourceType)
                                                            getCartCount()
                                                        }
                                                    }}>
                                                        <div className='glare'></div>

                                                        {item?.inCart == 1 || cart[item?.id] ? "GO TO CART" : "ADD TO CART"}</Button>
                                                }
                                                <Button
                                                    type="primary"
                                                    style={{
                                                        position: 'relative',
                                                        width: item?.resourceType === "course" ? "100%" : "47%",
                                                        padding: "10px 10px",
                                                        borderRadius: 8,
                                                        marginTop: item?.resourceType === "course" ? 3 : 0,
                                                        overflow: "hidden"
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (user?.accessToken) {
                                                            if (item?.feeType === "free") {
                                                                handleEnroll(item?.id, item?.resourceType);
                                                            } else {
                                                                history.push({
                                                                    pathname: '/user-checkout',
                                                                    state: { itemId: item?.id, itemType: item?.resourceType }
                                                                });
                                                            }
                                                        } else {
                                                            if (isMobile) {
                                                                setShowBottomSheet(true)
                                                            } else {
                                                                setShowLoginModal(true)
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <div className='glare'></div>
                                                    {enroll[item?.id] ? "ENROLLED" : item?.feeType === "free" || item?.resourceType === "course" ? "ENROLL NOW" : "BUY NOW"}
                                                </Button>

                                            </div>
                                        }
                                    </>
                                }
                            </center>
                        </> : <></>}
                    </>}
            </div>
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl} />}
                    {freeConfirmModal && <FreeCourseConfirmationModal
            freeConfirmModal={freeConfirmModal}
            setFreeConfirmModal ={setFreeConfirmModal}
            freeCourseName={freeCourseName}
            freeCourseType={freeCourseType}
            handleFree={handleFree}/>}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { userMarketPlaceHomeData } = state.studentMarketPlace;
    const { user } = state.session
    return { userMarketPlaceHomeData, user };
};

const mapDispatchToProps = (dispatch) => ({
    postAddSaveItem: (id, itemType, callback) => dispatch(postAddSaveItem(id, itemType, callback)),
    postAddCartItem: (id, itemType, callback) => dispatch(postAddCartItem(id, itemType, callback)),
    removeSaveItem: (id, itemType, callback) => dispatch(removeSaveItem(id, itemType, callback)),
    storeSingleBuyNow: (res) => dispatch(storeSingleBuyNow(res)),
    postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
    getCartCount: () => dispatch(getCartCount()),
    impressionEngagementIncrement: (params, callback) => dispatch(impressionEngagementIncrement(params, callback)),
    setShowLoginModal: (val) => dispatch(setShowLoginModal(val)),
    setShowBottomSheet: (val) => dispatch(setShowBottomSheet(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(MobileViewListCards);
