import React, { useEffect, useState, useRef } from 'react';
import "../AnimatedTextField/textFieldStyles.css";
import PhoneInput, { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function AnimatedPhoneInput({ label, value, handleInputChange, isMandatory, inputId, isDisabled, disabled,onKeyDown }) {
    const [isFocused, setIsFocused] = useState(false);
    const [isPhoneInputVisible, setIsPhoneInputVisible] = useState(false);
    const [countryCode, setCountryCode] = useState('+91'); 
    const phoneInputRef = useRef(null);
    const containerRef = useRef(null);

    const handleInputFocus = () => {
        setIsPhoneInputVisible(true);
        document.getElementById(`customInputLabel_${inputId}`)?.classList?.add('shrink');
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        if (!value && !isPhoneInputVisible) {
            document.getElementById(`customInputLabel_${inputId}`)?.classList?.remove('shrink');
        }
        setIsFocused(false);
    };

    const handleCountrySelectClick = (country) => {
        if (country) {
            const callingCode = getCountryCallingCode(country);
            setCountryCode(`+${callingCode}`); 
        }
        document.getElementById(`customInputLabel_${inputId}`)?.classList?.add('shrink');
    };

    const handleDropdownToggle = (isOpen) => {
        if (isOpen) {
            document.getElementById(`customInputLabel_${inputId}`)?.classList?.add('shrink');
        }
    };

    useEffect(() => {
        if (isFocused && phoneInputRef.current) {
            phoneInputRef.current.focus();
        }
    }, [isFocused]);

    useEffect(() => {
        if (value) {
            document.getElementById(`customInputLabel_${inputId}`)?.classList?.add('shrink');
            setIsPhoneInputVisible(true);
            const phoneNumber = parsePhoneNumber(value);
            if (phoneNumber) {
                setCountryCode(`+${getCountryCallingCode(phoneNumber.country)}`);
            }
        } else if (!isPhoneInputVisible) {
            document.getElementById(`customInputLabel_${inputId}`)?.classList?.remove('shrink');
        }
    }, [value, isPhoneInputVisible]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target) && isPhoneInputVisible) {
                setIsPhoneInputVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPhoneInputVisible]);

    const phoneInputValue = value?.startsWith('+') ? value : `${countryCode}${value || ''}`;
  

    return (
        <div className="custom-input-container m-t-20" ref={containerRef}>
            <label
                htmlFor={`customInput_${inputId}`}
                id={`customInputLabel_${inputId}`}
                className={`custom-input-label ${value || isPhoneInputVisible ? 'shrink' : ''}`}
            >
                {label} {isMandatory ? <span style={{ color: 'red' }}>*</span> : null}
            </label>

            {!isPhoneInputVisible ? (
                <input
                    type="text"
                    id={`customInput_${inputId}`}
                    className="custom-input"
                    style={{ color: "#636363", fontSize: 14 }}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    value={value}
                />
            ) : (
                <PhoneInput
                    ref={phoneInputRef}
                    className="custom-phone-input-enquiry"
                    onKeyDown={
                        onKeyDown ? onKeyDown : (e) => {
                        if (e.key.toLowerCase() === 'e' || e.keyCode === 69) {
                            e.preventDefault();
                        }
                    }}
                    onChange={(val) => {
                        handleInputChange(val);
                    }}
                    defaultCountry="IN"
                    addInternationalOption={true}
                    disabled={disabled}
                    onBlur={handleInputBlur}
                    value={phoneInputValue}
                    onCountryChange={handleCountrySelectClick}
                    onDropdownToggle={handleDropdownToggle}
                    maxLength={phoneInputValue?.startsWith('91') || phoneInputValue?.startsWith('+91') ? 15 : ""}
                />
            )}
        </div>
    );
}

export default AnimatedPhoneInput;
