import { CloudDownloadOutlined, DownCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { Heading } from 'components/Typography';
import React from 'react';
import moment from 'moment';
import fileDownload from "js-file-download";
import { Api } from 'services';
import Axios from 'axios';
import TitleAndDescription from '../titleAndDescription';

function TransactionDeatilsModal({ isTransactionDetailsVisible, setTransactionDetailsVisible, transactionModalData }) {
    const handleCancel = () => {
        setTransactionDetailsVisible(false);
    };
    const downloadFile = (file) => {
        let fileName = file
        Axios.get(Api._s3_url + file, {
            responseType: "blob",
        }).then((res) => {
            fileDownload(res?.data, fileName);
        });
    }
    return (
        <Modal
            className="modal-round-corner"
            centered
            visible={isTransactionDetailsVisible}
            onOk={handleCancel}
            onCancel={handleCancel}
            header={null}
            footer={null}
            width={600}
        >
            <Heading bold className='r-c-c m-t-10' style={{ fontSize: 22 }}>Transaction Details</Heading>
            <div style={{ padding: "10px 20px 10px 20px" }}>
                <div className='font m-b-20'>
                    <div className='m-b-10' style={{ color: "#636363", fontSize: 12 }}>Paid By</div>
                    <div style={{ display: "flex" }}>
                        <img style={{ width: "50px", height: "50px", borderRadius: "50%" }} src={`${Api._s3_url}${transactionModalData?.user?.image}`} alt="iamge" />
                        <div className='m-l-20'>
                            <div style={{ fontSize: 16, color: "#191919" }}>{transactionModalData?.user?.name}</div>
                            <div style={{ fontSize: 14, color: "#636363" }}>{transactionModalData?.user?.student?.standard?.std} : {transactionModalData?.user?.student?.standard?.section}</div>
                        </div>
                    </div>
                </div>
                <div className='display-flex m-b-10'>
                    <div style={{ width: "19vw" }}>
                        <TitleAndDescription
                            title={"Amount"}
                            description={"₹" + " " + transactionModalData?.amountToShow ? transactionModalData?.amountToShow : "0"}
                        />
                    </div>
                    <TitleAndDescription
                        title={"Issue date"}
                        description={moment(transactionModalData?.date, "DD-MM-YYYY, hh:mm A").format("DD/MM/YYYY, hh:mm A")}
                    />
                </div>
                <div className='display-flex m-t-20'>
                    <div style={{ width: "19vw" }}>
                        <TitleAndDescription
                            title={"Due date"}
                            description={moment(transactionModalData?.dueDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
                        />
                    </div>
                    <TitleAndDescription
                        title={"Status"}
                        description={transactionModalData?.status.toUpperCase()}
                        color={transactionModalData?.status == "Paid" ? "#28DF99" : "#594099"}
                    />

                </div>
                <div className='display-flex m-t-20'>
                    <div style={{ width: "19vw" }}>
                        <TitleAndDescription
                            title={"Mode of payment"}
                            description={transactionModalData?.payment_type.toUpperCase()}
                            color={transactionModalData?.payment_type == "offline" ? "#636363" : "#28DF99"}
                        />
                    </div>
                    <TitleAndDescription
                        title={"Payment date"}
                        description={transactionModalData?.payment_type == "offline" ? moment(transactionModalData?.paymentDate, "DD-MM-YYYY").format("DD/MM/YYYY") : moment(transactionModalData?.paymentDate, "DD-MM-YYYY, hh:mm A").format("DD/MM/YYYY, hh:mm A")}
                    />
                </div>
                {transactionModalData?.payment_type == "offline" ?
                    <div className='display-flex m-t-20'>
                        <div style={{ width: "19vw" }}>
                            <TitleAndDescription
                                title={"Payment type"}
                                description={transactionModalData?.payment_method.toUpperCase()}
                            />
                        </div>
                        {transactionModalData?.payment_method === "Cash"
                            ?
                            <></>
                            :
                            <TitleAndDescription
                                title={"Transaction ID"}
                                description={transactionModalData?.cheque_no || '-'}
                            />
                        }
                    </div>
                    :
                    <></>
                }
                <div className='display-flex m-t-20 '>
                    <div style={{ width: "19vw" }}>
                        <TitleAndDescription
                            title={"Invoice number"}
                            description={transactionModalData?.paymentId}
                        />
                    </div>
                    <div>
                        <TitleAndDescription
                            title={"Invoice"}
                        />
                        <Button style={{ color: "#1089FF", border: "none", fontWeight: "bold", fontSize: 12, marginLeft: -15 }} onClick={() => downloadFile(transactionModalData?.invoice)}><CloudDownloadOutlined /><span className='m-l-5'>DOWNLOAD INVOICE</span></Button>
                    </div>
                </div>
                {transactionModalData?.status === "Refunded"
                    ?
                    <div className='m-t-10 '>
                        <TitleAndDescription
                            title={"Refund initiate date"}
                            description={moment(transactionModalData?.refundDate).format("DD/MM/YYYY, hh:mm A")}
                        />
                    </div>
                    :
                    <></>
                }
                <div className='m-t-10 '>
                    <TitleAndDescription
                        title={"Description"}
                        description={transactionModalData?.note}
                    />
                </div>
                <div className='m-t-20 '>
                    <TitleAndDescription
                        title={"Amount details"}
                    />
                </div>
                <div className='font m-t-10 r-c-sb' style={{ color: "#191919", fontSize: 14 }}>
                    <div>Fee amount</div>
                    <div>₹ {transactionModalData?.amount ? (Math.round((parseFloat(transactionModalData?.amount) + transactionModalData?.total_discount - transactionModalData?.total_addition) * 100) / 100 || "0") : "0"}</div>
                </div>
                {transactionModalData?.discount_details &&
                    JSON.parse(transactionModalData?.discount_details)?.length != 0 ?
                    <>
                        {JSON.parse(transactionModalData?.discount_details)?.map((item, index) => (
                            <div className='font r-c-sb m-t-5' style={{ color: "#1089FF", fontSize: 14 }}>
                                <div>{item?.name}</div>
                                <div>- ₹ {item?.amount}</div>
                            </div>
                        ))}
                    </>
                    :
                    <></>
                }
                {transactionModalData?.addition_details &&
                    JSON.parse(transactionModalData?.addition_details)?.length != 0 ?
                    <>
                        {JSON.parse(transactionModalData?.addition_details)?.map((item, index) => (
                            <div className='font r-c-sb m-t-5' style={{ color: "#FFA931", fontSize: 14 }}>
                                <div>{item?.name}</div>
                                <div>+ ₹ {item?.amount}</div>
                            </div>
                        ))}
                    </>
                    :
                    <></>
                }
                <div className='font m-t-10 r-c-sb' style={{ color: "grey", fontSize: 14 }}>
                    <div>GST</div>
                    <div>+ ₹ {transactionModalData?.gst}</div>
                </div>
                <div className='m-t-10' style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
                <div className='font r-c-sb m-t-5' style={{ color: "#191919", fontSize: 14 }}>
                    <div>Total payable</div>
                    <div>₹ {transactionModalData?.amountToShow}</div>
                </div>
                <div className='m-t-10' style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
                <div className='r-c-c m-t-20'>
                    <Button
                        style={{
                            borderRadius: "19px",
                            width: "120px",
                            height: "35px",
                            border: "1px solid  #636363",
                            backgroundColor: "#FFFFFF",
                            color: "#594099",
                        }}
                        onClick={() => {
                            setTransactionDetailsVisible(false);
                        }}
                    >
                        CLOSE</Button>
                </div>
            </div>
        </Modal>
    )
}

export default TransactionDeatilsModal