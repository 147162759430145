import React, { useState } from 'react'
import { Tooltip, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';




function ToolTipB({ content }) {
    const text = <span>{
        content == 'left' ? 'A representation of when students are most active on digital library.'
            :
            <div style={{ color: "#FFFFFF", borderRadius: "25px" }}>
                <h3 style={{ color: "#FFFFFF", marginBottom: "0px" }}>IMPRESSIONS</h3>
                <p style={{ color: "#FFFFFF" }}>The number of times your content were on screen.</p>
                <h3 style={{ color: "#FFFFFF", marginBottom: "0px" }}>ENGAGEMENTS</h3>
                <p style={{ color: "#FFFFFF" }}>The total number of engagements on your content, including clicks.</p>

            </div>
    }</span>;

    return (
        <div>
            <Tooltip overlayClassName="tooltipBpk" placement="right" title={text}>
                <ExclamationCircleOutlined style={{ color: '#1089FF' }} />
            </Tooltip>

        </div>
    )
}

export default ToolTipB
