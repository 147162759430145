import { Select, DatePicker, Typography, Checkbox, Popover } from 'antd';
import PageHeader from 'components/pageHeader';
import React, { useEffect, useState } from 'react'
import { fetchClassLibraryData, updateCurrentSub, updateCurrentIcon, updateSetType, currentTypes, fetchBroadcastSession, setStoreTeacher, setDateFilter, checkBoxValue } from 'actions/digitalLibrary'
import { useHistory } from 'react-router';
// import BroadCastRecordingsModal from '../modals/broadCastRecordingsModal';
// import BroadCastSubjectCard from './components/broadcastSubject'
import { Heading } from 'components/Typography';
import { connect } from 'react-redux';
import BroadcastCard from '../components/broadcastCard';
import RecordingsModal from 'pages/broadcast/localComponent/recordingsModal';
import moment from 'moment';
import { NodataCard } from 'pages/classRoom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'components/Button';
import { fetchBroadcastWithoutRecordingCOunt } from 'actions/broadcast';
import UploadLocalVideosModal from '../modals/uploadLocalVideosModal';
import { NoDataFound } from 'components';

const { Option } = Select;

const BroadCastRecordingsCards = ({ fetchClassLibraryData, user, storeBroadcastRecordings, storeDateFilter, setDateFilter, fetchBroadcastSession, checkBoxValue, recordingsData, storeBroadcastWithoutCount,fetchBroadcastWithoutRecordingCOunt }) => {
  const [isRecordingsModalVisible, SetIsRecordingsModalVisible] = useState(false)
  const [storeTeacher, setStoreTeacher] = useState([])
  const [storeStrem, setStoreStream] = useState(false)
  const [isUploadModal, setIsUploadModal] = useState(false)

  const location = useLocation()
  console.log("AAAA-storeTeacher", location);



  // const [isTeacher ,setIsTeacher] =useState(storeBroadcastRecordings?.teachers)
  useEffect(() => {
    if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes('digital-library-dataoperator')) {
      // if(user.access.includes("Admin")||user.role.includes("SuperAdmin")||user.role.includes("Data Operator")){
      fetchClassLibraryData()
    }
  }, [])
  useEffect(() => {
    setDateFilter('')
    fetchBroadcastSession(() => { }, null)
  }, [])
  const history = useHistory();

  const handleOnChange = (value) => {
    console.log('datvavave', value);
    setDateFilter(moment(value).format('YYYY-MM-DD'));
    fetchBroadcastSession(() => { }, storeTeacher)
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const handleBackPress = () => {
    history.goBack();
  }
  const handleTeacherSelect = (item) => {
    const newSelectedTeachers = [...storeTeacher];
    if (newSelectedTeachers.includes(item)) {
      // If already selected, remove it
      newSelectedTeachers.splice(newSelectedTeachers.indexOf(item), 1);
    } else {
      // If not selected, add it
      newSelectedTeachers.push(item);
    }
    // setSelectedTeachers(newSelectedTeachers);
    setStoreTeacher(newSelectedTeachers); // Set selected teachers to the state used by Select
    // Call fetchBroadcastSession with selected teachers
    console.log("storeTeacher", storeTeacher);
    fetchBroadcastSession(() => { }, storeTeacher);
  };

  // useEffect(() => {
  //   if (storeTeacher && storeTeacher != "") {
  //   } else {
  //     setStoreTeacher(isTeacher?.[0]?.id);
  //     fetchBroadcastSession(()=>{},storeTeacher)
  //   }
  // }, [isTeacher]);
  const content2 = (
    <div
      style={{
        fontFamily: "roboto",
        width: "200px",
        height: storeBroadcastRecordings?.teachers?.length <= 5 ? "fit-content" : 300,
        display: "flex",
        flexDirection: "column",
        // alignItems: "flex-start",
        // justifyContent: "space-around",
        overflowY: "auto",
      }}
    >
      {storeBroadcastRecordings?.teachers?.length == 0 ?
        (<><NoDataFound /></>) :
        <Checkbox.Group
          style={{ display: "flex", flexDirection: "column" }}
          onChange={(value) => {
            handleTeacherSelect(value)
            setStoreTeacher(value);
            fetchBroadcastSession(() => { }, value);
          }}
          defaultValue={storeTeacher}
        // defaultValue={location?.state?.classroom ? location?.state?.classroom.toString() : null}
        >
          {storeBroadcastRecordings?.teachers
            ?.length != 0
            ? storeBroadcastRecordings?.teachers?.map(
              (item) => (
                <Checkbox
                  className="dropmenuBph"
                  style={{ color: "black", marginLeft: "0" }}
                  value={item?.id}
                >
                  <Typography.Text
                    ellipsis={{ tooltip: true }}
                    style={{ width: "130px" }}
                  >
                    {item?.name}
                  </Typography.Text>
                </Checkbox>
              )
            )
            : null}
        </Checkbox.Group>}
    </div>
  );
  const actions = [
    <div style={{ display: "flex", justifyContent: "right", marginRight: "50px" }}>
      <div>
        <DatePicker
          onChange={(value) => { handleOnChange(value) }} format={'DD-MM-YYYY'} />
      </div>
      <div style={{ marginLeft: "15px" }}>
        <Popover
          placement="bottomRight"
          content={content2}
          trigger="click"
        >

          <div
            className="classFilter"
            style={{
              width: "200px",
              height: "30px",
              borderRadius: "2px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor:'#ffff'
            }}
          >
            <div
              style={{
                color: "#d9d9d9",
                marginBottom: "0",
                marginLeft: "5px",
              width: "200px",

              }}
            >
              Select teacher (
              {
                storeBroadcastRecordings?.teachers?.length
              }
              )<span >▼</span>
            </div>
          </div>
        </Popover>
      </div>
      <div style={{ marginLeft: 10 }}>
      
{location?.state?.fromBrodcastVR?.sections != undefined ?
        <Button type='primary' disabled={storeStrem ? false : true}  onClick={() => {
    
            checkBoxValue(false)
            history.push({
              // pathname: location?.state?.fromBrodcastVR?.fromAddBroadcastPage == true ?  "/add-video-resources" : '/add-video-resources-content',
              pathname:"/course-details",
              state: {
                fromBrodcastVR:
                {
                  fromAddBroadcastPage:true,
                  redirectingPageDL: true,
                  sections: location?.state?.fromBrodcastVR?.sections,
                }
              }
            })
            // 
        }}>UPLOAD</Button> :
        (<>
        {user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") ?
          <Button type='primary' onClick={() => {
            // fetchBroadcastWithoutRecordingCOunt(() => {
            setIsUploadModal(true)

            // })
          }}>UPLOAD</Button> : <></>}
        </>)}
        
      </div>
    </div>
    // <Button type='primary' disabled={storeStrem ? false : true} onClick={() => {

    //   checkBoxValue(false)
    //   history.push({
    //     // pathname: location?.state?.fromBrodcastVR?.fromAddBroadcastPage == true ?  "/add-video-resources" : '/add-video-resources-content',
    //     pathname:"/add-video-resources",
    //     state: {
    //       fromBrodcastVR:
    //       {
    //         fromAddBroadcastPage:true,
    //         sections: location?.state?.fromBrodcastVR?.sections,
    //       }
    //     }
    //   })
    //   // history.push('/add-video-resources')

    // }}>UPLOAD</Button>
  ]
  return (
    <div>
      <PageHeader title={'Live class recordings'} onBackPress={() => {
        handleBackPress();
        checkBoxValue(false)
      }}
        actions={actions}
      />
      <div style={{ marginTop: "60px" }}></div>
      {storeBroadcastRecordings?.occurancesByStatus?.completed != 0 ?
        <div style={{ display: "flex", flexWrap: "wrap", }}>
          {storeBroadcastRecordings?.occurancesByStatus?.completed?.map((item) => (
            <div style={{ marginTop: "20px", }}>

              <BroadcastCard
                subjectName={item?.subject?.name}
                teacherName={item?.teacher?.name}
                date={item?.date}
                startTime={item?.startTime}
                endTime={item?.endTime}
                item={item}
                image={item?.subject?.icon}
                streamId={item?.roomName}
                isRecordingsModalVisible={isRecordingsModalVisible}
                SetIsRecordingsModalVisible={SetIsRecordingsModalVisible}
                sections={location?.state?.fromBrodcastVR?.sections}
                setStoreStream={setStoreStream}
                recordingsData={recordingsData}
              />
            </div>
          ))}

        </div> : <div style={{ marginTop: "50px" }}><NodataCard /></div>}
      {/* {storeBroadcastRecordings?.} */}


      <RecordingsModal
        isRecordingsModalVisible={isRecordingsModalVisible}
        setRecordingsModalVisibility={SetIsRecordingsModalVisible}

      />
      {(isUploadModal &&
        <UploadLocalVideosModal
          isUploadModal={isUploadModal}
          setIsUploadModal={setIsUploadModal}
          storeBroadcastWithoutCount={storeBroadcastWithoutCount}
          fetchBroadcastSession={fetchBroadcastSession}
          fetchBroadcastWithoutRecordingCOunt={fetchBroadcastWithoutRecordingCOunt}
        />)}
    </div>
  )
}
const mapStateToProps = (state) => {
  const {
    classLibraryList,
    classSubjectList,
    selectedSubject,
    addedTeacher,
    storeBroadcastRecordings,
    storeTeacher,
    storeDateFilter, recordingsData


  } = state.digitalLibrary;
  const { storeBroadcastWithoutCount } = state.broadcast;

  const { user } = state.session
  return {
    classLibraryList,
    classSubjectList,
    selectedSubject,
    addedTeacher,
    user,
    storeBroadcastRecordings,
    storeTeacher,
    storeDateFilter,
    recordingsData,
    storeBroadcastWithoutCount

  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchClassLibraryData: () => dispatch(fetchClassLibraryData()),
  updateCurrentSub: (data) => dispatch(updateCurrentSub(data)),
  updateCurrentIcon: (images) => dispatch(updateCurrentIcon(images)),
  updateSetType: (type) => dispatch(updateSetType(type)),
  currentTypes: (type) => dispatch(currentTypes(type)),
  fetchBroadcastSession: (callBack, storeTeacher) => dispatch(fetchBroadcastSession(callBack, storeTeacher)),
  setStoreTeacher: (res) => dispatch(setStoreTeacher(res)),
  setDateFilter: (res) => dispatch(setDateFilter(res)),
  checkBoxValue: (value) => dispatch(checkBoxValue(value)),
  fetchBroadcastWithoutRecordingCOunt: (callBack) => dispatch(fetchBroadcastWithoutRecordingCOunt(callBack))


});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(BroadCastRecordingsCards);

