import axios from "axios";
import moment from "moment";
import { Api, Notification } from "services";
export const UPDATE_STADARDS_INFO = "UPDATE_STADARDS_INFO";
export const UPDATE_STADARDS_DROPDOWN_LIST = "UPDATE_STADARDS_DROPDOWN_LIST";
export const PDF_UPLOADED = "pdfuploaded";
export const SUBJECT_UPLOAD = "SUBJECT_UPLOAD";
export const UPDATE_CHAPTERS = "UPDATE_CHAPTERS";
export const UPLOAD_LECTURENOTES = "UPLOAD_LECTURENOTES";
export const UPLOAD_DONE = "UPLOAD_DONE";
export const SET_PERFOMANCE_GRAPH_DATA = "SET_PERFOMANCE_GRAPH_DATA"
export const SET_TRAFFIC_DATA = "SET_TRAFFIC_DATA"
export const SET_IS_MONTH_OR_WEEK = "SET_IS_MONTH_OR_WEEK"

export const GET_STORAGE_BY_CATOGIRY = "GET_STORAGE_BY_CATOGIRY";
export const UPDATE_CLASS_LIBRARY_INFO = "UPDATE_CLASS_LIBRARY_INFO";
export const UPDATE_CLASS_SUBJECT_INFO = "UPDATE_CLASS_SUBJECT_INFO";
export const UPDATE_TEACHER_MANAGEMENT_INFO = "UPDATE_TEACHER_MANAGEMENT_INFO";
export const UPDATE_ADMIN_LIBRARY_INFO = "UPDATE_ADMIN_LIBRARY_INFO";
export const UPDATE_CURRENT_SUBJECT = "UPDATE_CURRENT_SUBJECT";
export const UPDATE_CURRENT_STD = "UPDATE_CURRENT_STD";
export const UPDATE_CURRENT_SORT = "UPDATE_CURRENT_SORT";
export const UPDATE_ADMIN_CHAPTER_INFO = "UPDATE_ADMIN_CHAPTER_INFO";
export const UPDATE_SEARCHED_TOPIC = "UPDATE_SEARCHED_TOPIC";
export const UPDATE_SELECTED_TEACHER = "UPDATE_SELECTED_TEACHER";
export const CURRENT_FILE_TO_UPLOAD = "CURRENT_FILE_TO_UPLOAD";
export const UPDATE_CHAPTER_FILTER = "UPDATE_CHAPTER_FILTER";
export const TOOGLE_EDIT_MOODLE = "TOOGLE_EDIT_MOODLE";
export const UPDATE_ADMIN_ADD_TEACHER = "UPDATE_ADMIN_ADD_TEACHER";
export const STORE_ARRAY_DATA = "STORE_ARRAY_DATA";
export const DELETE_TEACHER = "DELETE_TEACHER";
export const UPDATE_TOTAL_COUNT = "UPDATE_TOTAL_COUNT";
export const UPDATE_CURRENT_ICON = "UPDATE_CURRENT_ICON";
export const UPDATE_CURRENT_ID = "UPDATE_CURRENT_ID";
export const UPDATE_CURRENT_TYPE = "UPDATE_CURRENT_TYPE";
export const UPDATE_TAB_TYPE = "UPDATE_TAB_TYPE";
export const UPDATE_TEACHER_LIBRARY_DATA = "UPDATE_TEACHER_LIBRARY_DATA";
export const GET_TEACHERS_HOME_DATA = "GET_TEACHERS_HOME_DATA";
export const UPDATE_CHECKED_LECTURE = "UPDATE_CHECKED_LECTURE";
export const UPDATE_CHECKED_VIDEO = "UPDATE_CHECKED_VIDEO";
export const UPDATE_CHECKED_QUESTION = "UPDATE_CHECKED_QUESTION";
export const UPDATE_CHAPTER_INFO_FILTER = "UPDATE_CHAPTER_INFO_FILTER";
export const UPLOAD_ENGAGEMENT_HISTORY = "UPLOAD_ENGAGEMENT_HISTORY";
export const UPDATE_CLASS_ANALTYICS_INFO = "UPDATE_CLASS_ANALTYICS_INFO";
export const GET_ADMIN_DASHBOARD_DATA = "GET_ADMIN_DASHBOARD_DATA";
export const SET_IMPRESSION_ENGAUGEMENT = "SET_IMPRESSION_ENGAUGEMENT";
export const SET_STARTDATE = "SET_STARTDATE";
export const SET_ENDDATE = "SET_ENDDATE";
export const SET_MONTH_OR_WEEK = "SET_MONTH_OR_WEEK";
export const UPDATE_STUDENT_HOME_INFO = "UPDATE_STUDENT_HOME_INFO";
export const UPDATE_STANDARDS_STUDENT = "UPDATE_STANDARDS_STUDENT";
export const UPDATE_STD_LIST = "UPDATE_STD_LIST";
export const UPDATE_DOCUMENT_INFO = "UPDATE_DOCUMENT_INFO";
export const SET_UPLOAD_CONTENT = "SET_UPLOAD_CONTENT";
export const UPDATE_CLASS_INFO = "UPDATE_CLASS_INFO";
export const GET_ACTIVE_TIME = "GET_ACTIVE_TIME";
export const SET_SHOW_LIBRARY = "SET_SHOW_LIBRARY";
export const SET_POST_ID = "SET_POST_ID";
export const SET_EDIT_OBJECT = "SET_EDIT_OBJECT";
export const SET_KEY = "SET_KEY";
export const UPDATE_SELECTED_CHAPTER = "UPDATE_SELECTED_CHAPTER";
export const GET_CHAPTER_WISE_DATA = "GET_CHAPTER_WISE_DATA";
export const GET_TOPIC_WISE_LIST = "GET_TOPIC_WISE_LIST";
export const UPDATE_SELECTED_TOPIC = "UPDATE_SELECTED_TOPIC";
export const UPDATE_FILTER_TOPIC = "UPDATE_FILTER_TOPIC";
export const UPDATE_LIBARY_DATA = "UPDATE_LIBARY_DATA";
export const SET_IS_VIDEO_PREMIUM = "SET_IS_VIDEO_PREMIUM";
export const UPDATE_BUY_STORAGE_DETAILS = "UPDATE_BUY_STORAGE_DETAILS";
export const UPDATE_SIZE_OF_CONTENT = "UPDATE_SIZE_OF_CONTENT";
export const UPDATE_TOTAL_STORAGE_SIZE = "UPDATE_TOTAL_STORAGE_SIZE";
export const UPDATE_TOTAL_UNUSED_STORAGE_SIZE =
  "UPDATE_TOTAL_UNUSED_STORAGE_SIZE";
export const IS_HSL = "IS_HSL";
export const UPLOAD_VIDEO_MP4 = "UPLOAD_VIDEO_MP4";
export const FILE_KEY_UPDATE = "FILE_KEY_UPDATE";
export const UPDATE_PAGE_NUMBER = "UPDATE_PAGE_NUMBER";
export const GET_CURRENT_SUBSCRIPTION = "GET_CURRENT_SUBSCRIPTION";
export const SET_IS_SUBJECT_ASSIGNED = "SET_IS_SUBJECT_ASSIGNED"
export const UPDATE_TITLE = "UPDATE_TITLE"
export const SET_SUBSCRIPTION_PLAN = "SET_SUBSCRIPTION_PLAN"
export const SET_RECORDINGS_DETAILS = "SET_RECORDINGS_DETAILS"
export const SET_STORE_TEACHER = -"SET_STORE_TEACHER"
export const SET_STORE_SUBJECT_ID = "SET_STORE_SUBJECT_ID"
export const SET_DATE_FILTER = "SET_DATE_FILTER"
export const CHECK_BOX_VALUE = 'CHECK_BOX_VALUE'
export const SET_STORE_STREAM_ID = 'SET_STORE_STREAM_ID'

import { updateBillingHistory } from "./profile";

export const fetchDashBoardData = (callback) => {
  return (dispatch, getState) => {
    dispatch(updatesizeOfContents({}));
    const url = `/admin-library/class`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          console.log({ response });
          dispatch(updateStandardsInfo(response.data));
          dispatch(updateStdDropdownList(response.standards));
          dispatch(updateTotalCount(response.totalCount));
          dispatch(getDashBoardData(response.analyticsData));
          dispatch(setIsVideoPremium(response.videoPremiumFeature));
          dispatch(updatesizeOfContents(response.sizeOfContents)); // need to update
          dispatch(updateTotalStorageSize(response.totalSize));
          // dispatch(updateTotalUnusedStorageSize(response.unusedSpace));
          dispatch(getCurrentSubscription(response.currentSubscription));
          // dispatch(getStorageData());

          dispatch(getStorageDetails())

        }
      });
    });
  };
};
export const getStorageData = (callback) => {
  return (dispatch, getState) => {
    const { getBillingHistory } = getState().profile;
    const url = `/admin-library/storage-overview`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          // console.log(response);

          // dispatch(updatesizeOfContents(response.sizeOfContents));
          // dispatch(updateTotalStorageSize(response.totalSize));
          // dispatch(updateTotalUnusedStorageSize(response.unusedSpace));
          // console.log("getBillingHistory",getBillingHistory)
          // let temp = {
          //   storageOverviewDetais:
          //     response?.getBillingHistory?.storageOverviewDetais,
          //   billingHistory: getBillingHistory?.billingHistory,
          //   latestSubscription: getBillingHistory?.latestSubscription,
          // };
          // dispatch(updateBillingHistory(temp));
          // console.log("getBillingHistory",temp)

          // dispatch(getCurrentSubscription(response.currentSubscription))
        }
      });
    });
  };
};

export const adminFilterOfStudent = (callback) => {
  return (dispatch, getState) => {
    const { startDate, endDate, isMonthOrWeek, selectedStd } =
      getState().digitalLibrary;
    const url = `/admin-library/library-student-filter?startDate=${startDate ? startDate : moment().startOf("week").format("YYYY-MM-DD")
      }&endDate=${endDate
        ? endDate
        : moment().startOf("week").add(6, "days").format("YYYY-MM-DD")
      }&type=${isMonthOrWeek || "week"}&id=${selectedStd ? selectedStd?.std_id : ""}`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          dispatch(updateClassAnaltyticsInfo(response));
        }
      });
    });
  };
};

export const removemodel = () => {
  return (dispatch, getState) => {
    dispatch(removemodelofsuccess());
  };
};

export const updateTitle = (val) => ({
  type: UPDATE_TITLE,
  val
})

// export const setSubscriptionPlan =(res)=>({
//   type:SET_SUBSCRIPTION_PLAN,
//   res
// })
// export const getSubscriptionDetails =(callback,subscriptionDetails)=>{
//   // console.log("AA-hello",subscriptionDetails?.space);
//   console.log("AA-hello",subscriptionDetails);
//   return (dispatch, getState) => {
//     const url = `/admin-library/new-subscription-details?chosenPlanStorage=${subscriptionDetails}`
//     return Api.get(url).send((response, error) => {
//       if (callback) {
//         callback();
//       }
//       if (response != undefined) {
//         dispatch(setSubscriptionPlan(response));
//       }
//     });
//   };

// }

export const subjectFilter = (subject, callback) => {
  return (dispatch, getState) => {
    subject = subject ? subject : "";
    const url = `/admin-library/filterSubject?name=${subject}`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          dispatch(updateStandardfilter(response));
        } else {
          console.log(error);
        }
      });
    });
  };
};

export const uploadlecturenotes = (
  object,
  callback,
  successCallback,
  setProgress
) => {
  return (dispatch, getState) => {
    let params = object;
    if (object.type == "video") {
      dispatch(currentFileToShow("video"));
    }
    if (object.type != "video") {
      dispatch(currentFileToShow("pdf"));
    }
    const url = `/admin-library/content`;
    return new Promise((resolve, reject) => {
      Api.post(url).upload(params, (percent, response) => {
        setProgress(percent);
        if (percent === -1) {
          callback();
        }
        if (response !== undefined) {
          if (percent === 100) {
            dispatch(uploadContent({ ...response.data, type: object.type }));
            dispatch(setPostId(response.data.postId));
            callback();
            successCallback();
          }
        }
      });
    });
  };
};

export const currentFileToShow = (data) => ({
  type: CURRENT_FILE_TO_UPLOAD,
  data,
});

export const setIsSubjectAssigned = (data) => ({
  type: SET_IS_SUBJECT_ASSIGNED,
  data,
});

export const uploadEdit = (object, callback, successCallback) => {
  return (dispatch, getState) => {
    let params = object;
    const url = `/admin-library/content`;
    return Api.post(url, params).send((response, error) => {
      if (response) {
        successCallback();
        // dispatch(fetchLibraryData())
      } else {
        callback();
      }
    });
  };
};

export const getchapters = (chapter, subjectid) => {
  return (dispatch, getState) => {
    chapter = chapter ? chapter : "";
    console.log("subjectid", subjectid);
    const { selectedStd } = getState().digitalLibrary;
    const url = `/admin-library/filterChapter?name=${chapter ? chapter : ""
      }&standardId=${selectedStd ? selectedStd?.std_id : ""}&subjectId=${subjectid ? subjectid : ""
      }`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (response) {
          dispatch(updateChapters(response));
        } else {
          console.log(error);
        }
      });
    });
  };
};

export const updatepdf = (data) => {
  return (dispatch, getState) => {
    dispatch(updatepdfaction(data));
  };
};
export const uploadContent = (data) => ({
  type: UPLOAD_LECTURENOTES,
  data,
});

const updateStandardfilter = (data) => ({
  type: SUBJECT_UPLOAD,
  data: data,
});
const updatepdfaction = (data) => ({
  type: PDF_UPLOADED,
  data,
});
const removemodelofsuccess = () => ({
  type: UPLOAD_DONE,
});

export const fetchClassLibraryData = (callback) => {
  return (dispatch, getState) => {
    const { selectedSubject, selectedStd } = getState().digitalLibrary;
    const url = `/admin-library/standardById?id=${selectedStd?.std_id}`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          let tmp = response.subjects;
          for (let i = 0; i < tmp.length; i++) {
            let ts = tmp[i];
            tmp[i]['id'] = ts?.subjects_id
            tmp[i]['name'] = ts?.subjects_name;
          }
          dispatch(updateClassLibraryInfo(response.data));
          dispatch(updateClassSubjectInfo(tmp));
          dispatch(updateClassAnaltyticsInfo(response.analyticsData));
          dispatch(updateClassInfo(response));
          // dispatch(getStorageData());
          // dispatch(getStorageDetails())
          let obj = {
            sizeOfLn: response.data?.[0]?.lectureNotesSize,
            sizeOfMp4Video: response.data?.[0]?.videoSize,
            sizeOfQb: response.data?.[0]?.questionBankSize,
          }
          dispatch(updatesizeOfContents(obj))
          dispatch(updateTotalStorageSize(response.data?.[0]?.totalSize))
          // dispatch(updatesizeOfContents(response.sizeOfContents))
          // dispatch(updateTotalUnusedStorageSize(response.unusedSpace))
        }
      });
    });
  };
};

export const fetchTeacherData = () => {
  return (dispatch, getState) => {
    const { selectedSubject, selectedStd } = getState().digitalLibrary;
    const url = `/admin-library/teacher-management?subjectId=${selectedSubject.subjects_id}&standardId=${selectedStd.std_id}`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (response) {
          dispatch(updateTeacherManagementInfo(response));
        }
      });
    });
  };
};

export const fetchAdminLibraryData = (callback) => {
  return (dispatch, getState) => {
    const {
      selectedSubject,
      selectedStd,
      setSort,
      setSearchedTopic,
      setTabType,
      seletedChapter,
    } = getState().digitalLibrary;
    const url = `/admin-library/contents?type=${setTabType}&search=${setSearchedTopic ? setSearchedTopic : ""
      }&subjectId=${selectedSubject ? selectedSubject?.subjects_id : ""}&sort=${setSort ? setSort : ""
      }&standardId=${selectedStd ? selectedStd?.std_id : ""}&chapterName=${seletedChapter ? seletedChapter : ""
      }`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          dispatch(updateAdminLibraryInfo(response?.library?.items));
          dispatch(updateAdminAddTeacher(response?.addedTeachers));
        }
      });
    });
  };
};

export const fetchAdminChapterFilter = (name, classid, subjectid, callback) => {
  return (dispatch, getState) => {
    const { selectedSubject, selectedStd, setSort, seletedChapter } =
      getState().digitalLibrary;
      console.log("selectedSubjectAA",selectedSubject);
      console.log("selectedSubjectAB",selectedSubject);
      console.log("selectedSubjectAC",selectedSubject);

    const url = `/admin-library/filterChapter?name=${name ? name : ""
      }&standardId=${classid ? classid : ""}&subjectId=${subjectid ? subjectid : ""
      }`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          dispatch(updateAdminChapterInfo(response));
        }
      });
    });
  };
};

export const fetchAdminChapterFilter2 = () => {
  return (dispatch, getState) => {
    const { selectedSubject, selectedStd, setSort, seletedChapter } =
      getState().digitalLibrary;
    const url = `/admin-library/filterChapter?standardId=${selectedStd ? selectedStd.std_id : ""
      }&subjectId=${selectedSubject ? selectedSubject.subjects_id : ""}`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (response) {
          dispatch(updateChapterFilterInfo(response));
        }
      });
    });
  };
};

export const postTeachersArray = (data, callback, succesCallback) => {
  return (dispatch) => {
    return Api.post("/admin-library/teacher-management", data).send(
      (response, error) => {
        if (response) {
          succesCallback();
          // dispatch(fetchAdminLibraryData())
          dispatch(fetchChapterWiseList());
        } else {
          callback();
        }
      }
    );
  };
};

export const deleteNotes = (id, callback, successCallback) => {
  return (dispatch) => {
    const url = `/admin-library/content/delete?id=${id}`;
    return Api.delete(url).send((response, error) => {
      if (callback) {
        callback();
      }
      if (response != undefined) {
        // if (fetch) {
        // }
        if (successCallback()) {
          successCallback();
        }
        if (successCallback) {
          successCallback(response?.contentCount);
        }
      } else {
        if (callback) {
          callback();
        }
      }
    });
  };
};

export const deleteTeachers = (deleteId) => {
  return (dispatch) => {
    return Api.delete("/admin-library/teacher-management/delete")
      .params(deleteId)
      .send((response, error) => {
        if (response != undefined) {
          // console.log("responseDelete", response);
        }
      });
  };
};

export const fetchTeacherLibraryData = (callback) => {
  return (dispatch, getState) => {
    const { setTabType, selectedSubject, setSearchedTopic, selectedStd } =
      getState().digitalLibrary;
    const url = `/admin-library/studentcontent?type=${setTabType}&search=${setSearchedTopic ? setSearchedTopic : ""
      }&subjectId=${selectedSubject ? selectedSubject.subjects_id : ""
      }&standardId=${selectedStd ? selectedStd.std_id : ""}`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response != undefined) {
          dispatch(updateTeacherLibraryInfo(response));
        } else {
          console.log("error", error);
        }
      });
    });
  };
};

export const fetchTeacherHomeData = (callback) => {
  return (dispatch, getState) => {
    const url = `/admin-library/class`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          dispatch(getTeacherHomeData(response));
          dispatch(updateStandardsInfo(response.data));
          // dispatch(getStorageData())
        }
      });
    });
  };
};

export const fetchTeacherHomeDataSummary = (id, callback) => {
  return (dispatch, getState) => {
    const url = `/admin-library/standardById?id=${id}&type=days`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          dispatch(updateClassLibraryInfo(response.data));
          dispatch(updateClassSubjectInfo(response.subjects));
          dispatch(updateClassAnaltyticsInfo(response.analyticsData));
          dispatch(updateClassInfo(response));
        }
      });
    });
  };
};

export const postEngagementHistory = (object) => {
  return (dispatch) => {
    let params = object;
    const url = `/admin-library/engagement-history`;
    return new Promise((resolve, reject) => {
      Api.post(url, params).send((response, error) => {
        if (response != undefined) {
          // console.log("response=", response);
        }
      });
    });
  };
};

export const fetchStudentHome = (params, callback) => {
  return (dispatch) => {
    const url = `/admin-library/standardById`;
    return new Promise((resolve, reject) => {
      Api.get(url).params(params).send(async (response, error) => {
        if (response != undefined) {
          dispatch(updateStudentHomeInfo(response));
          // if (response.showLibrary == true) {
          // dispatch(updateClassLibraryInfo(response.totalCount));
          // dispatch(updateClassSubjectInfo(response.subjects));
          //   dispatch(setShowLibrary(response.showLibrary));
          //   dispatch(setIsSubjectAssigned({
          //     subject:response?.isAnySubjectsInThisStandard,
          //     class:response?.isClassAssigned
          //   }))
          // } else {
          //   dispatch(setIsSubjectAssigned({
          //     subject:response?.isAnySubjectsInThisStandard,
          //     class:response?.isClassAssigned
          //   }))
          //   dispatch(setShowLibrary(response.showLibrary));
          // }
        }
        if (callback) callback();
      });
    });
  };
};

export const fetchStudentLibraryData = (callback) => {
  return (dispatch, getState) => {
    const { selectedSubject, setTabType, setSearchedTopic } =
      getState().digitalLibrary;
    const url = `/admin-library/studentcontent?subjectId=${selectedSubject ? selectedSubject.subjects_id : ""
      }&type=${setTabType}&search=${setSearchedTopic ? setSearchedTopic : ""}`;
    return new Promise((resolve, reject) => {
      Api.get(url).send((response, error) => {
        if (callback) {
          callback();
        }
        if (response != undefined) {
          dispatch(updateStandardStudent(response.stdDetail));
          dispatch(updateTeacherLibraryInfo(response.content));
        }
      });
    });
  };
};

export const fetchLibraryData = (search, callback) => {
  return (dispatch, getState) => {
    const { selectedTopic, setTabType, selectedStd, selectedChapter, setSort, selectedSubject } = getState().digitalLibrary;
    const { user } = getState().session;
console.log("selectedStd",selectedStd);
    let standardId = null;
    if (user.role == 'Student') {
      standardId = selectedSubject?.standardId
    } else {
      standardId = selectedStd?.std_id ? selectedStd?.std_id : selectedStd?.classid
    }

    const url = `/admin-library/library-contents?type=${setTabType ? setTabType : ""
      }&topicId=${selectedTopic?.topicId ? selectedTopic?.topicId : ""}&search=${search ? search : ""
      }&standardId=${standardId}&chapterId=${selectedChapter?.chapterid ? selectedChapter?.chapterid : selectedChapter?.chapterId ? selectedChapter?.chapterId :""
      }&sort=${setSort ? setSort : ""}`;
    return new Promise((resolve, reject) => {
      Api.get(url).send((response, error) => {
        if (callback) {
          callback();
        }
        if (response != undefined) {
          // dispatch(updateStandardStudent(response.stdDetail))
          dispatch(updateLibraryData(response));
          dispatch(updateTotalCount(response.totalCount));
          dispatch(updateTeacherLibraryInfo(response.contents));
          // dispatch(updateTotalUnusedStorageSize(response.unusedSpace))
          // dispatch(getStorageData());
          dispatch(getStorageDetails())
        }
      });
    });
  };
};

export const fetchClasslist = (subId, chapterName, callback) => {
  const url = `/admin-library/standardBySubject?subjectId=${subId}&chapterName=${chapterName ? chapterName : ""
    }`;
  return (dispatch) => {
    return Api.get(url).send((response, error) => {
      if (callback) {
        callback();
      }
      if (response != undefined) {
        dispatch(updateStdList(response));
      }
    });
  };
};

export const postClone = (
  classid,
  id,
  topicId,
  chapterId,
  callback,
  successCallback,
) => {
  return (dispatch, getState) => {
    const url = `/admin-library/clone-content`;
    return new Promise((resolve, reject) => {
      const { changedTitle } = getState().digitalLibrary;
      Api.post(url)
        .params({
          standardId: classid,
          libraryId: id,
          topicId: topicId,
          chapterId: chapterId,
          title: changedTitle,
        })
        .send((response, error) => {
          if (response != undefined) {
            successCallback();
            // console.log("response=", response);
          } else {
            callback();
          }
        });
    });
  };
};

export const fetchChapterWiseList = (search, callback, successCallBack = null) => {
  return (dispatch, getState) => {
    const { selectedSubject, selectedStd } = getState().digitalLibrary;
    const { user } = getState().session;
    const { selectedSection } = getState().classRoom

    let standardId = null
    let subjectId = null;

    // for standardId
    if (user.role == 'Student') {
      standardId = selectedSubject?.standardId
    } else {
      standardId = selectedStd?.std_id
    }

    // for subjectId
    if (user.role == 'Student') {
      subjectId = selectedSubject?.subjectId
    } else {
      subjectId = selectedSubject?.subjects_id
    }

    const url = `/admin-library/chapter-list?subjectId=${subjectId}&search=${search ? search : ""}&standardId=${standardId}`;
    return Api.get(url).send((response, error) => {
      if (callback) {
        callback();
      }
      if (response != undefined) {
        if(successCallBack){
          successCallBack(response?.chapters)
        }
        dispatch(getChapterWiseData(response?.chapters));
        if (
          user.access.includes("digital-library-admin") ||
          user.access.includes("digital-library-superadmin") ||
          user.access.includes("digital-library-dataoperator")
        ) {
          dispatch(updateAdminAddTeacher(response?.addedTeachers));
        }
      }
    });
  };
};

export const postReorderChapters = (chapters, callBack = null, successCallBack = null) =>
  (dispatch, getState) => {
    const { selectedSubject, selectedStd } = getState().digitalLibrary;
    const { user } = getState().session;

    let standardId = null
    let subjectId = null;

    // for standardId
    if (user.role == 'Student') {
      standardId = selectedSubject?.standardId
    } else {
      standardId = selectedStd?.std_id
    }

    // for subjectId
    if (user.role == 'Student') {
      subjectId = selectedSubject?.subjectId
    } else {
      subjectId = selectedSubject?.subjects_id
    }
    const transformedChapters = chapters.map(({ chapterId, chapterName }) => ({ chapterId, chapterName }))
    const params = {
      subjectName: selectedSubject?.name,
      subjectId,
      standardId,
      chapters: transformedChapters
    }
    return Api.post("/subjects/createSubjectV2")
      .params(params)
      .send((response) => {
        if (callBack) {
          callBack();
        }
        if (response !== undefined && response?.show?.type !== 'error') {
          if (successCallBack) {
            successCallBack();
          }
        }
      });
  };

export const postReorderTopics = (topics, callBack = null, successCallBack = null) =>
  (dispatch, getState) => {
    const { selectedChapter } = getState().digitalLibrary;
    const transformedTopics = topics.map(({ topicId }) => ({ id: topicId }))
    const params = {
      chapterId: selectedChapter?.chapterId,
      topics: transformedTopics
    }
    return Api.patch("/admin-library/update-topics-order")
      .params(params)
      .send((response) => {
        if (callBack) {
          callBack();
        }
        if (response !== undefined && response?.show?.type !== 'error') {
          if (successCallBack) {
            successCallBack();
          }
        }
      });
  };

export const fetchTopicWiseList = (search, callback, successCallBack = null) => {
  return (dispatch, getState) => {
    const { selectedSubject, selectedChapter, selectedStd } = getState().digitalLibrary;
    const { selectedSection } = getState().classRoom
    const { user } = getState().session;

    let subjectId = null;

    // for subjectId 
    if (user.role == 'Student') {
      subjectId = selectedSubject?.subjectId
    } else {
      subjectId = selectedSubject?.subjects_id
    }

    const url = `/admin-library/topic-list-by-chapterId?chapterId=${selectedChapter?.chapterId ? selectedChapter?.chapterId : ""}&topicName=${search ? search : ""}&subjectId=${subjectId}`;
    return Api.get(url).send((response, error) => {
      if (callback) {
        callback();
      }
      if (response != undefined) {
        if(successCallBack){
          successCallBack(response)
        }
        dispatch(getTopicWiseList(response));
      }
    });
  };
};

export const postCreateTopic = (object, callback, successCallback) => {
  return (dispatch) => {
    const url = `/admin-library/topic`;
    return new Promise((resolve, reject) => {
      Api.post(url, object).send((response, error) => {
        if (response != undefined) {
          if (callback) {
            callback();
          }
          if (successCallback) {
            successCallback();
          }
        } else {
          callback();
        }
      });
    });
  };
};

export const fetchFilterTopic = (subId, stdId, chapterId, callback) => {
  return (dispatch, getState) => {
    // const { selectedSubject, seletedChapter, selectedStd, } = getState().digitalLibrary
    const url = `/admin-library/filterTopic?subjectId=${subId}&standardId=${stdId}&chapterId=${chapterId}`;
    return Api.get(url).send((response, error) => {
      if (callback) {
        callback();
      }
      if (response != undefined) {
        // console.log('response',response)
        dispatch(updateFilterTopic(response));
      }
    });
  };
};

export const setPerfomanceGraphData = (res) => ({
  type: SET_PERFOMANCE_GRAPH_DATA,
  res
})

export const fetchPerfomanceGraph = (year, callback) => {
  return (dispatch, getState) => {
    const { storeMonthOrWeek, startDate, endDate } = getState().digitalLibrary
    const url = `/admin-library/library/filters?type=${storeMonthOrWeek || "week"}&startDate=${startDate ? moment(startDate).format('YYYY-MM-DD') : moment().startOf('week').format('YYYY-MM-DD')}&endDate=${endDate
      ? moment(endDate).format('YYYY-MM-DD')
      : moment().startOf('week').add(6, 'days').format('YYYY-MM-DD')}&year=${moment(year).format('YYYY') ? moment(year).format('YYYY') : moment().format('YYYY')}`;
    return Api.get(url).send((response, error) => {
      if (callback) {
        callback();
      }
      if (response != undefined) {

        console.log('AA-response', response)
        dispatch(setPerfomanceGraphData(response));
      }
    });
  };
};

export const setTrafficData = (res) => ({
  type: SET_TRAFFIC_DATA,
  res
})

export const fetchTrafficData = (standardId, isDayOrWeek, date, callback) => {
  return (dispatch, getState) => {
    const url = `/admin-library/most-active-time?&standardId=${standardId}&type=${isDayOrWeek ? isDayOrWeek : 'days'}&date=${date ? moment(date).format('YYYY-MM-DD') : moment(date).startOf("week").format("YYYY-MM-DD")}`;
    return Api.get(url).send((response, error) => {
      if (callback) {
        callback();
      }
      if (response != undefined) {
        dispatch(setTrafficData(response));
      }
    });
  };
};


export const setSubscriptionPlan = (res) => ({
  type: SET_SUBSCRIPTION_PLAN,
  res
})

export const getSubscriptionDetails = (callback, subscriptionDetails) => {
  // console.log("AA-hello",subscriptionDetails?.space);
  console.log("AA-hello", subscriptionDetails);
  return (dispatch, getState) => {
    const url = `/admin-library/new-subscription-details?chosenPlanStorage=${subscriptionDetails}`
    return Api.get(url).send((response, error) => {
      if (callback) {
        callback();
      }
      if (response != undefined) {
        dispatch(setSubscriptionPlan(response));
      }
    });
  };

}
export const fetchBuyStorage = (callback) => {
  return (dispatch, getState) => {
    // const { selectedSubject, seletedChapter, selectedStd, } = getState().digitalLibrary
    const url = `/admin-library/storage-plans`;
    return Api.get(url).send((response, error) => {
      if (callback) {
        callback();
      }
      if (response != undefined) {
        // console.log('response',response)
        // dispatch(updateFilterTopic(response))
        dispatch(updateBuyStorageDetails(response));
      }
    });
  };
};

export const postStorageUpgrade = (object, callback, successCallback) => {
  return (dispatch) => {
    const url = `/admin-library/onPaymentSuccess`;
    return new Promise((resolve, reject) => {
      Api.post(url, object).send((response, error) => {
        if (response != undefined) {
          if (callback) {
            callback();
          }
          if (successCallback) {
            successCallback();
          } else {
            if (callback) {
              callback();
            }
          }
          // console.log("response=", response);
        }
      });
    });
  };
};

export const uploadBlock2 = (params, callback) => {
  // const blockUploadUrl = `/admin-library/UploadChunk?fileName=${params.fileName}&orderId=${params.orderId}`;
  const blockUploadUrl = `/admin-library/UploadChunk`;

  // let file=params.file;
  // let data = {
  //   fileName: params.fileName,
  //   orderId:
  // }
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      Api.post(blockUploadUrl).upload(params, (percent, response) => {
        if (percent === -1) {
          // callback()
          // console.log("percent -1");
        }
        if (response !== undefined) {
          // console.log("percent", percent);
          if (percent === 100) {
            callback(response);
            resolve();
          }
        }
      });
    });
  };
};

export const MergeChunk =
  (fileId, callback, succesCallback) => (dispatch, getState) => {
    const params = {
      fileName: fileId,
    };
    const { fileKey } = getState().digitalLibrary;
    // console.log('object', object)
    return Api.post(`/admin-library/MergeChunks`)
      .params(params)
      .send((response) => {
        // console.log("up2 res", response);
        // if (callback) {
        //   callback()
        // }
        if (response != undefined) {
          // dispatch(uploadContent({ ...response, type: object.type }))
          // callback(response)
          if (response?.fileKey) {
            dispatch(fileKeyUpdate(response.fileKey));
            // if (succesCallback) {
            //   succesCallback()
            // }
          } else {
            if (callback) {
              callback();
            }
          }
        } else {
          if (callback) {
            callback();
          }
        }
      });
  };

export const uploadCompleted2 = (object, callback) => (dispatch, getState) => {
  // const params = {
  //   fileName: fileId,
  // }
  // console.log("object", object);
  return Api.post(`/admin-library/UploadComplete`, object).send((response) => {
    // console.log("up2 res", response);
    if (response != undefined) {
      dispatch(uploadContent({ ...response, type: object.type }));
      callback(response);
    }
  });
};

export const getStorageDetails = (callback) => {
 
  const url = `/admin-library/storage-size-details`;
  return (dispatch, getState) => {
      return Api.get(url).send((response, error) => {
        if(callback){
          callback()
        }
          if (response) {
            let obj = {
              sizeOfLn: response.lectureNotesSize,
              sizeOfMp4Video: response.videoSize,
              sizeOfQb: response.questionBankSize,
              sizeOfTotal:response.totalSize,
              spaceAvailable:response.spaceAvailable    
       }
            dispatch(updatesizeOfContents(obj))
          }
      }
      )
  }
}

// export const getStorageDetails = (object, callback) => (dispatch, getState) => {

//   // console.log("object", object);
//   const { sizeOfContents, totalStorageSize } = getState().digitalLibrary;
//   const { storageSubscriptionDetails } = getState().profileNew;

//   const { user } = getState().session;

//   let isProd = (window.location.hostname == "eks2.aulas.one" || window.location.hostname == "localhost" ? false : true);
//   let baseUrl = isProd ? "https://5ol6n0fje6.execute-api.ap-south-1.amazonaws.com/prod/s3-space-calculate-prod" : "https://5ol6n0fje6.execute-api.ap-south-1.amazonaws.com/dev/s3-space-calculate-prod";
//   let orgId = user?.org?.id;
//   let dlBucketName = isProd ? "prod-digital-library-0949" : "test-digital-library-brigosha-0949";
//   let transcodedBucketName = "hls-transcoded-5777";
//   console.log('orgUrl', window.location.hostname, isProd)

//   let url = `${baseUrl}?availableSpace=${storageSubscriptionDetails?.totalSize}&organisationName=${orgId}/admin-library/digital-library/&qbBucketName=${dlBucketName}&videoBucketName=${transcodedBucketName}&lnBucketName=${dlBucketName}`;


//   axios.get(url).then((response) => {

//     let obj = {
//       sizeOfLn: response.data.lectureNotesSize,
//       sizeOfMp4Video: response.data.videoSize,
//       sizeOfQb: response.data.questionBankSize
//     }
//     dispatch(updatesizeOfContents(obj))
//   }).catch((error) => {
//     console.error(error);
//   }).finally(() => {
//     // TODO
//   });
// };



const getTeacherHomeData = (res) => ({
  type: GET_TEACHERS_HOME_DATA,
  payload: res,
});

const updateStandardsInfo = (data) => ({
  type: UPDATE_STADARDS_INFO,
  data,
});

const updateStdDropdownList = (data) => ({
  type: UPDATE_STADARDS_DROPDOWN_LIST,
  data,
});
const updateChapters = (data) => ({
  type: UPDATE_CHAPTERS,
  data,
});

const updateClassLibraryInfo = (data) => ({
  type: UPDATE_CLASS_LIBRARY_INFO,
  data,
});

export const updateClassSubjectInfo = (classSubjectData) => ({
  type: UPDATE_CLASS_SUBJECT_INFO,
  classSubjectData,
});

const updateTeacherManagementInfo = (teacherData) => ({
  type: UPDATE_TEACHER_MANAGEMENT_INFO,
  teacherData,
});

const updateAdminLibraryInfo = (adminLibraryData) => ({
  type: UPDATE_ADMIN_LIBRARY_INFO,
  adminLibraryData,
});

export const updateCurrentSub = (currentSub) => ({
  type: UPDATE_CURRENT_SUBJECT,
  currentSub,
});

export const updateCurrentStd = (currentStd) => ({
  type: UPDATE_CURRENT_STD,
  currentStd,
});

export const updateSetSort = (currentSort) => ({
  type: UPDATE_CURRENT_SORT,
  currentSort,
});

export const updateAdminChapterInfo = (adminChapterData) => ({
  type: UPDATE_ADMIN_CHAPTER_INFO,
  adminChapterData,
});

export const updateSetTopic = (searchedTopic) => ({
  type: UPDATE_SEARCHED_TOPIC,
  searchedTopic,
});

export const updateSelectedTeacher = (teachers) => ({
  type: UPDATE_SELECTED_TEACHER,
  teachers,
});

export const updateChapterFilter = (chapFilter) => ({
  type: UPDATE_CHAPTER_FILTER,
  chapFilter,
});

export const updateAdminAddTeacher = (adminTeacherData) => ({
  type: UPDATE_ADMIN_ADD_TEACHER,
  adminTeacherData,
});

export const toogleEditModal = (bool) => ({
  type: TOOGLE_EDIT_MOODLE,
  bool,
});
export const storeArray = (arrData) => ({
  type: STORE_ARRAY_DATA,
  arrData,
});

export const deleteTeacher = (del_id) => ({
  type: DELETE_TEACHER,
  del_id,
});

const updateTotalCount = (totalCount) => ({
  type: UPDATE_TOTAL_COUNT,
  totalCount,
});

export const updateCurrentIcon = (icons) => ({
  type: UPDATE_CURRENT_ICON,
  icons,
});
export const currentDocument = (docId) => ({
  type: UPDATE_CURRENT_ID,
  docId,
});
export const currentTypes = (data) => ({
  type: UPDATE_CURRENT_TYPE,
  data,
});

export const updateSetType = (getTabType) => {
  return {
    type: UPDATE_TAB_TYPE,
    getTabType,
  };
};
export const updateTeacherLibraryInfo = (data) => ({
  type: UPDATE_TEACHER_LIBRARY_DATA,
  data,
});

export const setCheckedLecture = (bool) => ({
  type: UPDATE_CHECKED_LECTURE,
  bool,
});

export const setCheckedVideo = (bool) => ({
  type: UPDATE_CHECKED_VIDEO,
  bool,
});

export const setCheckedQuestion = (bool) => ({
  type: UPDATE_CHECKED_QUESTION,
  bool,
});

export const updateChapterFilterInfo = (data) => ({
  type: UPDATE_CHAPTER_INFO_FILTER,
  data,
});

export const updateClassAnaltyticsInfo = (analyticsData) => ({
  type: UPDATE_CLASS_ANALTYICS_INFO,
  analyticsData,
});

export const getDashBoardData = (res) => ({
  type: GET_ADMIN_DASHBOARD_DATA,
  payload: res,
});

export const setIsImpressionsOrEngagements = (res) => ({
  type: SET_IMPRESSION_ENGAUGEMENT,
  payload: res,
});

export const setStartDateLib = (res) => ({
  type: SET_STARTDATE,
  payload: res,
});

export const setEndDateLib = (res) => ({
  type: SET_ENDDATE,
  payload: res,
});

export const setMonthOrWeek = (res) => ({
  type: SET_MONTH_OR_WEEK,
  payload: res,
});
export const updateStudentHomeInfo = (res) => ({
  type: UPDATE_STUDENT_HOME_INFO,
  res,
});

export const updateStandardStudent = (standardDetails) => ({
  type: UPDATE_STANDARDS_STUDENT,
  standardDetails,
});

export const updateStdList = (standard) => ({
  type: UPDATE_STD_LIST,
  standard,
});

export const updateDocumentInfo = (data) => ({
  type: UPDATE_DOCUMENT_INFO,
  data,
});

export const setUploadContent = (object) => ({
  type: SET_UPLOAD_CONTENT,
  object,
});

export const updateClassInfo = (data) => ({
  type: UPDATE_CLASS_INFO,
  data,
});

export const getActimeTime = (data) => ({
  type: GET_ACTIVE_TIME,
  data,
});

export const setShowLibrary = (bool) => ({
  type: SET_SHOW_LIBRARY,
  bool,
});

export const setPostId = (id) => ({
  type: SET_POST_ID,
  id,
});

export const setEditObject = (object) => ({
  type: SET_EDIT_OBJECT,
  object,
});

export const setKey = (key) => ({
  type: SET_KEY,
  key,
});

export const getChapterWiseData = (data) => ({
  type: GET_CHAPTER_WISE_DATA,
  data,
});

export const updateSelectedChapter = (chapter) => ({
  type: UPDATE_SELECTED_CHAPTER,
  chapter,
});

export const getTopicWiseList = (topic) => ({
  type: GET_TOPIC_WISE_LIST,
  topic,
});

export const updateSelectedTopic = (topic) => ({
  type: UPDATE_SELECTED_TOPIC,
  topic,
});

export const updateFilterTopic = (topic) => ({
  type: UPDATE_FILTER_TOPIC,
  topic,
});

export const updateLibraryData = (data) => ({
  type: UPDATE_LIBARY_DATA,
  data,
});

export const setIsVideoPremium = (bool) => ({
  type: SET_IS_VIDEO_PREMIUM,
  bool,
});

export const updateBuyStorageDetails = (data) => ({
  type: UPDATE_BUY_STORAGE_DETAILS,
  data,
});

export const updatesizeOfContents = (data) => ({
  type: UPDATE_SIZE_OF_CONTENT,
  data,
});

export const updateTotalStorageSize = (data) => ({
  type: UPDATE_TOTAL_STORAGE_SIZE,
  data,
});

export const updateTotalUnusedStorageSize = (data) => ({
  type: UPDATE_TOTAL_UNUSED_STORAGE_SIZE,
  data,
});

export const IsHSL = (bool) => ({
  type: IS_HSL,
  bool,
});

export const uploadVideoMP4 = (data) => ({
  type: UPLOAD_VIDEO_MP4,
  data,
});

export const fileKeyUpdate = (data) => ({
  type: FILE_KEY_UPDATE,
  data,
});

export const updatePageNumber = (data) => ({
  type: UPDATE_PAGE_NUMBER,
  data,
});

export const getCurrentSubscription = (data) => ({
  type: GET_CURRENT_SUBSCRIPTION,
  data,
});

export const getStorageByCatogiry = (key, val) => ({
  type: GET_STORAGE_BY_CATOGIRY,
  key,
  val,
});

export const setIsMonthOrWeek = (res) => ({
  type: SET_IS_MONTH_OR_WEEK,
  res,
})

export const setRecordingDetails = (res) => ({
  type: SET_RECORDINGS_DETAILS,
  res
})

export const setStoreTeacher = (res) => ({
  type: SET_STORE_TEACHER,
  res
})

export const setStoreSubjectId = (res) => ({
  type: SET_STORE_SUBJECT_ID,
  res
})

export const setDateFilter = (res) => ({
  type: SET_DATE_FILTER,
  res
})

export const checkBoxValue = (value) => ({
  type: CHECK_BOX_VALUE,
  value,
});

export const setStoreStreamId = (value) => ({
  type: SET_STORE_STREAM_ID,
  value,
});


export const fetchBroadcastSession = (callBack, storeTeacher) => {
  return (dispatch, getState) => {
    const { storeSubjectId, selectedStd, storeDateFilter } = getState().digitalLibrary
    console.log("abcdf",selectedStd);

  const { user } = getState().session;

    const params = {
      subjectIds: [storeSubjectId],
      teacherIds: storeTeacher,
      classIds: [selectedStd?.std_id ? selectedStd?.std_id : selectedStd],
      date: storeDateFilter == 'Invalid date' ? '' : storeDateFilter,
      recording: true,
      ...(user?.role?.includes("Student") ? {published:true} : null)

    };
    const url = `/schedule-new/get-occurances`;
    return Api.get(url).params(params).send((response, error) => {
      if (callBack) {
        callBack();
      }
      if (response != undefined) {
        dispatch(setRecordingDetails(response));
      }
    });
  };
};
