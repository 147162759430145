import React, { useState } from 'react';
import { Modal, Button } from 'antd';
// import './modal.css';
import moment from 'moment';
import {connect} from 'react-redux';
import { fetchPerformance, fetchResult } from 'actions/studentPracticeTest';


const SubmittedSuccessTest = ({isModalVisible, handleOk, close, history, selectedTest, fetchResult, fetchPerformance,storeOwnedData}) => {

  const handleHomeClick = () => {
    close();
    if(storeOwnedData == 0 ){
      history.push('/')
    }else{
      history.push('/')

    }
    close();

  }

//   const handleResultClick = () => {
//     fetchResult(()=>{
//       fetchPerformance(()=>{
//         close();
//         history.push('/practice-result-screen')
//       })
//     })
//   }

  return (
    <div className="confirm">
      <Modal centered className="confirm"  visible={isModalVisible} onOk={handleOk} closable={true} width={'600px'} onCancel={close}>

        <div className="message-area" style={{display:"flex", flexDirection:"column",justifyContent:"space-between",alignItems:"center"}}>
            <div className="imgconfirm" style={{display:"flex",height:"80%",width:"90%",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <div><img style={{width:"100%" ,height:"100%"}} src={require('Assets/images/ic-confirmation.PNG').default} alt="" /></div>
            </div>
            <div className="test1 m-t-10">
                <h2 style={{fontWeight:"700px", fontSize:"23px"}}>Successfully submitted</h2>
                <p style={{color:"grey", textAlign:'center'}}>Your test is successfully submitted</p>
            </div>

            <div className='m-t-50' style={{ borderRadius:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                <Button type="primary" shape="round"  onClick={handleHomeClick} style={{backgroundColor:"white", width:"120px",height:"10%",color:"#636363",borderColor:'#636363',marginRight:'30px'}}>HOME</Button>
                {/* <Button type="primary" shape="round"   onClick={handleResultClick} style={{backgroundColor:"#594099", width:"120px",height:"10%",color:"white"}}>VIEW RESULTS</Button> */}

            </div>
        </div>




      </Modal>
    </div>
  );
};


const mapStateToProps = state => {
  const { storeOwnedData } = state.marketTest;
  const { user } = state.session
  return { storeOwnedData, user };
};

const mapDispatchToProps = dispatch => ({
//   fetchResult:(callback)=>dispatch(fetchResult(callback)),
//   fetchPerformance:(callback)=>dispatch(fetchPerformance(callback)),
});


export default connect(mapStateToProps, mapDispatchToProps)(SubmittedSuccessTest);
