import { leaveOrAttendanceRequestActions } from 'actions/profileNew';
import { Checkbox, Col, Modal, Row, Steps, Timeline, Tooltip } from 'antd';
import { Button } from 'components/Button';
import { Heading } from 'components/Typography';
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { CheckCircleFilled } from "@ant-design/icons";

const LeaveRequestsModal = ({ open, onClose, data, leaveOrAttendanceRequestActions, user }) => {
        const [approveBtnLoader, setApproveBtnLoader] = useState(false);
        const [rejectBtnLoader, setRejectBtnLoader] = useState(false);
        const [selectedDates, setSelectedDates] = useState([]);
        const [selectedId, setSelectedId] = useState([]);

        console.log({ data })
        const successCallback = () => {
                setSelectedDates([]);
                setSelectedId([])
                window.location.reload();
        }

        const handleApprove = (commonId) => {
                let params = {
                        action: 'Approved',
                        commonId: commonId,
                        requestIds: selectedId,
                        requestType: 'Leave'
                };

                const callback = () => {
                        onClose();
                        setApproveBtnLoader(false);
                };


                setApproveBtnLoader(true);
                leaveOrAttendanceRequestActions(params, () => callback(), () => { }, () => successCallback())
        };

        const handleReject = (commonId) => {
                let params = {
                        action: 'Rejected',
                        commonId: commonId,
                        requestIds: selectedId,
                        requestType: 'Leave'

                };

                const callback = () => {
                        onClose();
                        setRejectBtnLoader(false);
                }

                setRejectBtnLoader(true);
                leaveOrAttendanceRequestActions(params, () => callback(), () => { }, () => successCallback())
        };

        const checkOnChange = (e, item) => {
                setSelectedDates(e.target.checked);
                setSelectedId(prevSelectedId => prevSelectedId ? [...prevSelectedId, item?.id] : [item?.id]);
        };

        return (
                <Modal
                        centered
                        closable
                        open={open}
                        footer={null}
                        className='modal-round-corner'
                        onCancel={onClose}
                >
                        <center><Heading>Details</Heading></center>
                        <div className='p-20'>
                                <Row style={{ justifyContent: 'space-between', width: '80%' }}>
                                        <Col>
                                                <div className='simgrey bold-600'>Leave type</div>
                                                <div>{data?.leaveType}</div>
                                        </Col>
                                        <Col>
                                                <div className='simgrey bold-600'>Duration</div>
                                                <div>{data?.duration} days</div>
                                        </Col>
                                </Row>
                                <div className='m-t-20'>
                                        <div className='simgrey bold-600'>Reason(s)</div>
                                        <div>{data?.reason}</div>
                                </div>

                                <div className='m-t-50 simgrey bold-600'>Date(s)</div>
                                {data?.requests?.map((item) => {
                                        return <div className='m-l-10 m-t-20'>
                                                <div style={{ display: 'flex' }}>
                                                        <Checkbox value={selectedDates} disabled={moment(item?.date, 'YYYY-MM-DD') <= moment()} onChange={(e) => checkOnChange(e, item)} style={{ marginTop: 4 }}></Checkbox>
                                                        <div className='m-l-20 color-black text-sm bold-600'>{moment(item?.date).format('DD/MM/YYYY')}</div>
                                                </div>
                                                <div className='simgrey text-xs bold-600' style={{ marginLeft: 37 }}>{data?.duration == '0.5' ? 'Half day' : 'Full day'}</div>
                                        </div>
                                })}

                                {data?.timeline &&
                                        <div style={{ width: '100%' }}>
                                                <div className='simGrey bold-600 m-t-20 m-b-10'>Approval progress</div>
                                                <Steps direction="Horizontal" size='small' >
                                                        {data?.timeline?.map((item) => {
                                                                const itemStatus = item.status === 'Approved' ? 'finish' : 'wait';
                                                                const itemStyle = { color: item.status === 'Approved' ? '#28DF99' : '#FFA931' };

                                                                return (
                                                                        <Steps.Step
                                                                                responsive
                                                                                labelPlacement="vertical"
                                                                                status={itemStatus}
                                                                                key={item.id}
                                                                                title={
                                                                                        <div>
                                                                                                {item.actionBy && item.status === 'Approved' ? (
                                                                                                        <Tooltip title={`${item.actionBy.name}, ${item?.actionBy?.role[0]}`}>
                                                                                                                <span style={itemStyle}>
                                                                                                                        <CheckCircleFilled style={{ color: '#28DF99' }} />
                                                                                                                </span>
                                                                                                        </Tooltip>
                                                                                                ) : (
                                                                                                        null
                                                                                                )
                                                                                                }
                                                                                                <div>{item.status}</div>
                                                                                        </div>
                                                                                }
                                                                        >
                                                                        </Steps.Step>
                                                                );
                                                        })}
                                                </Steps>
                                        </div>
                                }

                                {user?.privilage?.includes('SuperAdmin') &&
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                                                <div className='r-jsb' style={{ width: '60%' }}>
                                                        <Button disabled={selectedDates?.length == 0} onClick={() => handleReject(data?.commonId)} type='primary' style={{ backgroundColor: '#FF414D', border: 'none' }} loading={rejectBtnLoader}>REJECT</Button>
                                                        <Button disabled={selectedDates?.length == 0} onClick={() => handleApprove(data?.commonId)} type='primary' style={{ backgroundColor: '#28DF99', border: 'none' }} loading={approveBtnLoader}>APPROVE</Button>
                                                </div>
                                        </div>
                                }
                        </div>
                </Modal>
        )
}

const mapStateToProps = (state) => {
        const { user } = state.session;
        const { } = state.profileNew;
        const { } = state.userManagementV2
        return { user }
}

const mapDispatchToProps = (dispatch) => ({
        leaveOrAttendanceRequestActions: (params, callback, pagecallback, successCallback) => dispatch(leaveOrAttendanceRequestActions(params, callback, pagecallback, successCallback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeaveRequestsModal)