import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React from "react";

function EditProfilePic({
  deletePicLoader,
  isModalVisible,
  handleOk,
  handleCancel,
  userDetailsById,
  handleProfileModalCancel,
  setImageUrl,
  userDetailsSaved,
  imageUrl,
  setfileInputValue,
  fileInputValue
}) {
  return (
    <div>
      <Modal
        maskClosable={false}
        className="editProfilePicModal"
        okText={"Yes"}
        cancelText={"No"}
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Change a photo
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <Button
            onClick={()=>{handleProfileModalCancel();setfileInputValue("default.png")}}
            style={{ borderRadius: "20px", width: "150px" }}
            loading={deletePicLoader}
            disabled={
              (userDetailsById?.image == "default.png" ||
                userDetailsById?.image == null) &&
              imageUrl === ""
                ? true
                : false
            }
          >
            <DeleteOutlined style={{ color: "#AEAEAE" }} />
            <span
              style={{ color: "#AEAEAE", fontSize: "12px", fontWeight: "600" }}
            >
              REMOVE
            </span>
          </Button>
          <Button
            onClick={handleOk}
            style={{
              borderRadius: "20px",
              backgroundColor: "#594099",
              width: "150px",
            }}
          >
            <SearchOutlined style={{ color: "white" }} />
            <span
              style={{ color: "white", fontSize: "12px", fontWeight: "600" }}
            >
              BROWSE PHOTO
            </span>
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default EditProfilePic;
