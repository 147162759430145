import {
        GET_DASHBOARD_DATA,
        LIST_OF_USERS,
        SET_ROLE,
        ADD_USERS_FIELDS_DATA,
        FETCH_CLASS_LIST,
        STORE_EXCEL_DETAILS,
        STORE_EXCEL_FILEID,
        STORE_PENDING_USERS,
        STORE_EXCEL_DATA,
        UPDATE_PROFILE,
        GET_MENU_SETTINGS_DATA,
        SET_QUICK_ACCESS_ITEM,
        UPDATE_USER_MENU_SETTINGS,
        SET_SPECIFIC_USER_ID,
        DELETED_USERS_LIST,
        UPDATE_USER_CONTENT,
        UPDATE_USER_ALL_DATA,
        UPDATE_SEARCHED_TEACHERS,
        SET_TRANSFERDATA_VALUE,
        UPDATE_USER_TO_TRANSFER_DATA,
        STORE_STANDARDS,
        SET_USERRECORD,
        SET_COURSES,
        STUDENT_ROLL_NO,
        STORE_ALL_COURSES,
        STORE_COURSE_STANDARDS,
        SET_SELECTED_COURSE,
        SET_SELECTED_STANDARD,
        STORE_STUDENTS_WRT_COURSETYPE,
        STORE_USER_RESOURCES, SET_STUDENT_WITHOUT_DATA,
        SET_USERIDS,
        STORE_AUTOCOMPLETE_COURSES_LIST,
        SET_NOTIFICATIONS_MENU
} from 'actions/userManagementV2';

const initialState = {
        dashboardMeta: {},
        quickAccessItem:{addUser:false,addFee:false,addSchedule:false,addAnnounce:false,markAttendance:false,applyLeave:false,createCourse:false,addResource:false,addTest:false},
        menuSettingsData:[],
        usersList: {},
        userObj: { userRole: '', userStatus: '' },
        addUserFields: { name: '', phone: null, email: '', privilage: [], course: null, role: '', file: '' },
        classList: [],
        excelFile: null,
        excelFileId: null,
        pendingUsers: {},
        specificUserProfile: {},
        userMenusettingsData:[],
        specificUserId: null,
        deletedUsersList: {},
        userAllData: {},
        searchedTeachers: [],
        storeTransferValue: false,
        userToTransferData: {},
        standards: null,
        userRowDetails: {},
        courses: {},
        studentRollNo: null,
        allCourseData: {},
        selectedCourse: null,
        selectedStandard: null,
        studentsDataWRTCourseType: {},
        userResources: {},
        storeWithoutData: null,
        userIds: [],
        autocompleteCoursesData: [],
        notificationMenu: {},
}

const reducer = (state = initialState, action) => {
        switch (action.type) {
                case SET_NOTIFICATIONS_MENU:
                        return {
                        ...state,
                        notificationMenu: action.res
                        }

                case GET_MENU_SETTINGS_DATA:
                        return {
                                ...state,
                                menuSettingsData: action.res
                        };
                case SET_QUICK_ACCESS_ITEM:
                        return {
                                ...state,
                                quickAccessItem: action.res
                        };
                case GET_DASHBOARD_DATA:
                        return {
                                ...state,
                                dashboardMeta: action.res
                        };
                case SET_STUDENT_WITHOUT_DATA:
                        return {
                                ...state,
                                storeWithoutData: action.res
                        };

                case LIST_OF_USERS:
                        return {
                                ...state,
                                usersList: action.res
                        };

                case SET_ROLE:
                        let role = { ...state.userObj }
                        if (action.key == 'userRole') {
                                role.userRole = action.val
                        } else if (action.key = 'userStatus') {
                                role.userStatus = action.val;
                        }
                        return {
                                ...state,
                                userObj: { ...role }
                        };

                case ADD_USERS_FIELDS_DATA:
                        let tempData = { ...state.addUserFields }

                        if (action.key === 'name') {
                                tempData.name = action.val
                        } else if (action.key === 'phone') {
                                tempData.phone = action.val
                        } else if (action.key === 'email') {
                                tempData.email = action.val
                        } else if (action.key === 'role') {
                                tempData.role = action.val
                        } else if (action.key === 'course') {
                                tempData.course = action.val
                        } else if (action.key === 'privilage') {
                                tempData.privilage = action.val
                        } else if (action.key === 'file') {
                                tempData.file = action.val
                        } else if (action.key === 'courseType') {
                                tempData.courseType = action.val
                        }
                        return {
                                ...state,
                                addUserFields: tempData
                        };

                case FETCH_CLASS_LIST:
                        return {
                                ...state,
                                classList: action.res
                        };

                case STORE_EXCEL_DETAILS:
                        return {
                                ...state,
                                excelFile: action.res
                        };

                case STORE_EXCEL_FILEID:
                        return {
                                ...state,
                                excelFileId: action.id
                        };

                case STORE_PENDING_USERS:

                        return {
                                ...state,
                                pendingUsers: action.res
                        };

                case STORE_EXCEL_DATA:
                        let newPendingUsers = [...state.pendingUsers];
                        let excelIdsMap = {};
                        action.res.data?.forEach((item, index) => {
                                excelIdsMap[item.id] = index
                        });
                        for (let i = 0; i < newPendingUsers.length; i++) {
                                if (newPendingUsers[i].id in excelIdsMap) {
                                        newPendingUsers[i].status = action.res.data[excelIdsMap[newPendingUsers[i].id]].status
                                        newPendingUsers[i].failMessage = action.res.data[excelIdsMap[newPendingUsers[i].id]].failMessage
                                };
                        };

                        return {
                                ...state,
                                excelData: { ...action.res, data: [...action.res.data] },
                                pendingUsers: newPendingUsers
                        };
                case UPDATE_USER_MENU_SETTINGS:
                        return {
                                ...state,
                                userMenusettingsData: action.res
                        };

                case UPDATE_PROFILE:
                        return {
                                ...state,
                                specificUserProfile: action.res
                        };

                case SET_SPECIFIC_USER_ID:
                        return {
                                ...state,
                                specificUserId: action.id,
                        };

                case DELETED_USERS_LIST:
                        return {
                                ...state,
                                deletedUsersList: action.res
                        };

                case UPDATE_USER_CONTENT:
                        if (action.dataType === "schedule") {
                                state.userAllData.schedules = {
                                        items: [...state.userAllData.schedules.items, ...action.data.items],
                                        meta: { ...action.data.meta }
                                }
                        } else if (action.dataType === "liveTest") {
                                state.userAllData.liveTests = {
                                        items: [...state.userAllData.liveTests.items, ...action.data.items],
                                        meta: { ...action.data.meta }
                                }

                        } else if (action.dataType === "practiceTest") {
                                state.userAllData.practiceTests = {
                                        items: [...state.userAllData.practiceTests.items, ...action.data.items],
                                        meta: { ...action.data.meta }
                                }

                        } else if (action.dataType === "question-bank") {
                                state.userAllData.questionBankResources = {
                                        items: [...state.userAllData.questionBankResources.items, ...action.data.items],
                                        meta: { ...action.data.meta }
                                }
                        } else if (action.dataType === "video") {
                                state.userAllData.videoResources = {
                                        items: [...state.userAllData.videoResources.items, ...action.data.items],
                                        meta: { ...action.data.meta }
                                }
                        } else if (action.dataType === "lecture-notes") {
                                state.userAllData.lectureNotesResources = {
                                        items: [...state.userAllData.lectureNotesResources.items, ...action.data.items],
                                        meta: { ...action.data.meta }
                                }
                        }
                        return {
                                ...state,
                                userAllData: { ...state.userAllData }

                        };

                case UPDATE_USER_ALL_DATA:
                        return {
                                ...state,
                                userAllData: { ...action.data }
                        };

                case UPDATE_SEARCHED_TEACHERS:
                        return {
                                ...state,
                                searchedTeachers: [...action.data]
                        };

                case SET_TRANSFERDATA_VALUE:
                        return {
                                ...state,
                                storeTransferValue: action.res
                        };

                case UPDATE_USER_TO_TRANSFER_DATA:
                        return {
                                ...state,
                                userToTransferData: { ...action.data }
                        }

                case STORE_STANDARDS:
                        return {
                                ...state,
                                standards: action.res
                        }

                case SET_USERRECORD:
                        return {
                                ...state,
                                userRowDetails: action.res
                        }

                case SET_COURSES:
                        return {
                                ...state,
                                courses: action.res
                        }

                case STUDENT_ROLL_NO:
                        return {
                                ...state,
                                studentRollNo: action.rollNo
                        }

                case STORE_ALL_COURSES:
                        return {
                                ...state,
                                allCoursesData: action.res
                        };

                case STORE_COURSE_STANDARDS:
                        return {
                                ...state,
                                courseStandards: action.res
                        };

                case SET_SELECTED_COURSE:
                        return {
                                ...state,
                                selectedCourse: action.res
                        };

                case SET_SELECTED_STANDARD:
                        return {
                                ...state,
                                selectedStandard: action.res
                        }

                case STORE_STUDENTS_WRT_COURSETYPE:
                        return {
                                ...state,
                                studentsDataWRTCourseType: action.res
                        }

                case STORE_USER_RESOURCES:
                        return {
                                ...state,
                                userResources: action.res
                        }

                case SET_USERIDS:
                        return {
                                ...state,
                                userIds: action.userIds
                        }
                case STORE_AUTOCOMPLETE_COURSES_LIST: {
                        return {
                            ...state,
                            autocompleteCoursesData: action.res,
                        }
                    }

                default: {
                        return {
                                ...state
                        };
                };
        };
};

export default reducer;

