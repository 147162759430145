import moment from 'moment'
import React, { useState } from 'react'
import questionBank from '../../../Assets/dashBoard/questionBank.svg'
import { Typography } from 'antd';
import { connect } from 'react-redux';
import { IsHSL, currentDocument, currentFileToShow, currentTypes, postEngagementHistory, updateDocumentInfo, uploadContent } from 'actions/digitalLibrary';
import { useHistory } from "react-router";
import { ConsoleSqlOutlined } from '@ant-design/icons';
import qstBank from '../../../Assets/dashBoard/ic-dl-question-bank.svg'
const first = require('../../../Assets/ic-dl-lecture-notes.svg').default;
const videodash = require('../../../Assets/ic-dl-lecture-videos.svg').default;
const thirddash = require('../../../Assets/ic-dl-lecture-question-banks.svg').default;


const { Text } = Typography


const RecentlyAddedCard = ({ title, subjectName, type, createdAt, postEngagementHistory, id, user, uploadContent, currentFileToShow, currentDocument, IsHSL, url, file, updateDocumentInfo, item }) => {
    const history = useHistory();

    console.log({item})

    const handleNotes = (id) => {
        updateDocumentInfo(item);
        // uploadContent({ filePath: file })

        if (type != "video") {
            currentFileToShow("pdf");
            uploadContent({ filePath: file });
        } else {
            IsHSL(true)
            currentFileToShow("video");
            uploadContent({ filePath: file ? file : link });
        }

        currentDocument(id);
        currentTypes(type);

        if (
            user.access.includes("digital-library-teacher") ||
            user.access.includes("digital-library-student")
        ) {
            let arr = [];
            arr[0] = id;
            postEngagementHistory({ libraryIds: arr, type: "engagement" });
        }
        history.push("/digital-library/view-content");
    };

    return (
        <div>
            {type == 'lecture-notes' ?
                <div style={{ display: "flex", padding: "1px", height: "fit-content", marginTop: "15px", cursor: "pointer" }} onClick={() => { handleNotes(id) }}>
                    <img src={first} style={{ width: 50 }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '120%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 5 }}>
                            <Typography.Text style={{ width: "100px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: 2 }} ellipsis={{ tooltip: true }}>{title}</Typography.Text>
                            <div style={{ fontSize: 10 }}>{moment(createdAt).format('DD-MM-YYYY')}</div>
                        </div>
                        <div className='p-5' style={{ marginLeft: "2px", marginTop: -10 }}>
                            <div style={{ fontSize: "11px", fontWeight: 500, color: "#636363" }}>{subjectName} • {`${item?.standard?.std} - ${item?.standard?.section}`} </div>
                            <div style={{ fontSize: "11px", fontWeight: 500, color: "#636363" }}>{type}</div>
                        </div>
                    </div>
                </div>
                : null
            }

            {type == 'question-bank' ?
                <div style={{ display: "flex", padding: "1px", height: "fit-content", marginTop: "15px", cursor: "pointer" }} onClick={() => { handleNotes(id) }}>
                    <img src={thirddash} style={{ width: 50 }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '120%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 5 }}>
                            <Typography.Text style={{ width: "100px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: 2 }} ellipsis={{ tooltip: true }}>{title}</Typography.Text>
                            <div style={{ fontSize: 10 }}>{moment(createdAt).format('DD-MM-YYYY')}</div>
                        </div>
                        <div className='p-5' style={{ marginLeft: "2px", marginTop: -10 }}>
                            <div style={{ fontSize: "11px", fontWeight: 500, color: "#636363" }}>{subjectName} • {`${item?.standard?.std} - ${item?.standard?.section}`}</div>
                            <div style={{ fontSize: "11px", fontWeight: 500, color: "#636363" }}>{type}</div>
                        </div>
                    </div>
                </div>
                : null}

            {type == 'video' ?
                <div style={{ display: "flex", padding: "1px", width: "100%", height: "85px", marginTop: "15px", cursor: "pointer" }} onClick={() => { handleNotes(id) }}>
                    <div><img src={videodash} style={{ width: "70%" }} /></div>
                    <div >
                        <div style={{ display: "flex", width: "100%" }}>
                            <div style={{ marginLeft: "-10px" }}>
                                <Typography.Text ellipsis={{ tooltip: true }}>
                                    <div style={{ width: "100px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</div>
                                </Typography.Text>
                            </div>
                            <div style={{ marginLeft: "-10px", fontSize: "9px", color: "#AEAEAE", marginTop: "3px" }}>{moment(createdAt).format('DD-MM-YYYY')}</div>
                        </div>
                        <div style={{ fontSize: "11px", fontWeight: 500, color: "#636363", marginLeft: "-10px" }}>{subjectName} • {`${item?.standard?.std} - ${item?.standard?.section}`}</div>
                        <div style={{ fontSize: "11px", fontWeight: 500, color: "#636363", marginLeft: "-10px" }}>{type}</div>
                    </div>
                </div> : null
            }

        </div>

    )

}
const mapStateToProps = (state) => {

    const { user } = state.session;
    return { user };
};

const mapDispatchToProps = (dispatch) => ({
    postEngagementHistory: (object) => dispatch(postEngagementHistory(object)),
    currentFileToShow: (data) => dispatch(currentFileToShow(data)),
    uploadContent: (object) => dispatch(uploadContent(object)),
    currentDocument: (id) => dispatch(currentDocument(id)),
    currentTypes: (type) => dispatch(currentTypes(type)),
    postEngagementHistory: (object) => dispatch(postEngagementHistory(object)),
    IsHSL: (bool) => dispatch(IsHSL(bool)),
    updateDocumentInfo: (data) => dispatch(updateDocumentInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyAddedCard);
