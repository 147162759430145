import React from 'react';
// import {Progress} from 'antd';
import '../design/layout.css';
import '../design/assignment.css';
import '../Assets/fonts/css/icon-assignment.css';
import {FiList} from 'react-icons/fi'

const carouselIc = require('../Assets/carouselIc.svg').default;

export const MobileButton = ({text, icon, onPress, white, before, fillBox, grey}) => (
  <div
    onClick={onPress ? onPress : null}
    style={{
      backgroundColor: white ? 'white' : '#5448B7',
      color: !white ? 'white' : grey ? '#AEAEAE' : '#5448B7',
      padding: '5px 20px',
      width: fillBox ? '100%' : 'auto',
      border: '1px solid transparent',
      borderColor: grey ? '#AEAEAE' : 'transparent',
    }}
    className='backPurp white text-md radius-100 NutinFont r-c-c m-l-5 m-r-5 cursor-pointer'>
    {text && before ? icon : text} {icon && before ? text : icon}
  </div>
);

export const AssignmentTopBar = ({
  title, isStarted, listMode, changeMode, lastItem, backPress
}) => {
  return (
  <div
    className='r-c-sb p-10 color-black sticky top0 whiteBack text-sm shadowTopBar'>
    <i onClick={backPress} className='icon-ic-arrow-back text-sm color-black' />
    <div>
      {title}
    </div>
    {!isStarted ?
      <div>
        {lastItem}
      </div>
      : listMode ?
      <FiList onClick={() => changeMode()} /> :
      <img
        onClick={() => changeMode()}
        src={carouselIc}
        style={{width: '1.2em'}}
        alt=""
      />
    }
  </div>
)}
