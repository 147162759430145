import React, { useEffect, useState } from 'react'
import { QuestionTypes } from './questionTypes';
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';

const Comprehensive = ({data, currQuesIndex, toggleImageModal, setAnswerObj, setCurrentImg, answerObj}) => {

  const [insideQuesType, setInsideQuesType] = useState();
  const [quesData, setQuesData] = useState(null);
  const [isImageViewerVisible, setImageViewerVisibility] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  // const [answerObj, setAnswerObj] = useState([])

  console.log('data', data)

  useEffect(() => {
    data?.questionContent?.subQuestions?.map((item)=>{
      setInsideQuesType(item?.type)
    });
  }, [])  

  return (
    <div>
      <div className='m-l-40'>
      {ReactHtmlParser(data?.questionContent?.paragraph, {
        transform: (node) => {
          if (
            node.type === "tag" &&
            node.name === "span" &&
            node.attribs["data-value"]
            ) {
            return <TeX math={`${node.attribs["data-value"]}`} />
            }
            if (node.type === "tag" && node.name === "img") {
            return (
            <img
              alt="question"
              style={{ maxHeight: "100px", maxWidth: "100px" }}
              src={node.attribs.src}
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setImgUrl(node.attribs.src);
                setImageViewerVisibility(true);
                toggleImageModal(true, -1);
                setCurrentImg(node.attribs.src);
              }}
            />
          );
        }}})}
      </div>
      {data?.questionContent?.subQuestions?.map((item, index)=>{
        return(
          <div>
            <div style={{fontWeight: 600, color: '#636363', margin: 20, marginBottom: -10}}>{`Question ${currQuesIndex}(${index + 1})`}</div> 
            <QuestionTypes data={data} type={item?.type} quesContent={item?.questionContent} quesIndex={index} currQuesIndex={currQuesIndex} setAnswerObj={setAnswerObj} answerObj={answerObj} comprehensive={true} toggleImageModal={toggleImageModal} setCurrentImg={setCurrentImg}/>
          </div>
        ) 
      })}
    </div>
  )
}

export default Comprehensive