import React, { useState } from 'react';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import {  } from 'antd';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import Loader from 'pages/Test/localComponent/loader';
import { Utils } from 'services';


function ConfirmationModal ({visible, onCancel, onConfirm, title, content, confirmTitle="YES", rejectTitle="CANCEL", onClose=null, hasInputField=false, loading=false, fullmodalloading=false, isNoButtonRed=false, maskClosable=true}) {
    const [input, setInput] = useState('');
    return (
      <Modal
        centered
        className='modal-round-corner'
        style={{padding:0}}
        visible={visible}
        onCancel={onClose?onClose:onCancel}
        onOk={onClose?onClose:onCancel}
        footer={null}
        closable={!isNoButtonRed}
        maskClosable={maskClosable}
      >
        {
          fullmodalloading?
          <div style={{paddingTop:50, paddingBottom:50}}>
            <Loader style={{fontSize:50, color:"#5448b7"}}/>
          </div>
          :
            <>
              <div className='text-sm text-center bold-600 color-purple m-t-20'>
                  {title}
              </div>
              <div style={{fontWeight:isNoButtonRed?'700':'300'}} className={`text-xs text-center ${isNoButtonRed?'red':'a2grey'} m-b-20`}>
                  {content}
              </div>
              {
                hasInputField?
                <div className="r-c-c">
                  <input value={input} onChange={(e)=>{
                    console.log("eee", e.target.value)
                    if (Utils.isValidString(e.target.value)||e.target.value?.trim()===''){
                      setInput(e.target.value)
                    }
                  }} onKeyDown={(e)=>{
                        if(e.key === 'Enter' && !(input==undefined||input?.trim()=='')) {
                          console.log("50", input)
                          onConfirm(input);
                         }
                  }} placeholder='Please enter a file name for the recording' style={{border:"none", borderBottomColor:"#5448b7", borderBottomWidth:1, borderBottomStyle:"solid", width:300}}></input>
                </div>
                :null
              }

              <div className='r-c-c m-t-50'>
                <Button className={`${isNoButtonRed?'redBtnWrap':'blankGreyBtnWrap'} radius-100`} onClick={onCancel}>{rejectTitle}</Button>
                <Button className={"radius-100 m-l-20 purpleBtnWrap purpleButtonHoverEffect"} onClick={()=>{onConfirm(input)}} loading={loading} disabled={hasInputField && (input==undefined||input?.trim()=='')}>{confirmTitle}</Button>
              </div>
            </>
        }
      </Modal>
  );
}



export default ConfirmationModal;
