import React, { useState } from 'react'
import { newCardStyle, descStyle, IsJsonString, isHTML } from "./courseInformationPage"
import { Collapse, Divider, Dropdown, Menu, Tag, Tooltip, Typography } from 'antd';
import { CaretRightOutlined, EditOutlined, FilePdfOutlined, PlusCircleOutlined } from '@ant-design/icons';
import video from '../../Assets/course/Video.png'
import quiz from '../../Assets/aulasMarket/ic-practice-quiz.svg'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'components/Button';
import StudyMaterialCourseComponent from './components/studyMateerialResourcesComp';
import VideoResourcesContent from './components/videoResourcesContent';
import PreviewCourse from 'pages/studentMarketPlace/coursePageCompo/previewCourse';


const { Panel } = Collapse;

const StudyMaterialTopicsContent = ({ item, index }) => {
    return (
        <div style={{ border: "1px solid #E6E6E6", height: "fit-content", width: "100%", padding: "8px 20px", display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "#F6F4FE", fontSize: 16 }}>
            <div style={{ color: "#191919", fontWeight: "bold" }}>{index + 1}<span style={{ margin: "0px 10px 0px 20px" }}>{item?.name}</span>{item?.preview == 1 ? <></> : <img src={require("../../Assets/course/lockIcon.svg").default} />}</div>
            <div style={{ display: "flex" }}>
                {/* <div style={{ color: "#636363", marginRight: 20 }}>24 Quiz</div> */}
                {console.log("item34", item)}
                {item?.type == 'quiz' ? <></> :
                    <div style={{ color: "#AEAEAE" }}>{item?.pageCount} Pages</div>}
            </div>
        </div>
    )
}
const CourseContentComp = ({ isBatchesExpandClicked, isViewDetailsClicked, courseTypeName, specificCourseDataContainer, isCoursePlanningEditClicked, setResources, resources, isEdit, setIsEdit, isVideoResource, setIsVideoResource, hasMarketingPrivilege, togglePageLoader }) => {
    const history = useHistory()
    const location = useLocation()
    const [isVideoPreview, setVideoPreview] = useState(false)
    const [previewLink, setPreviewLink] = useState('')
    const [videoTypeLink, setVideoTypeLink] = useState('')
    const [videoName, setVideoName] = useState('')

    console.log("specificCourseDataContainer34", location?.state?.key?.resources)
    return (
        <div>
            {isBatchesExpandClicked ? <></> :
                <>
                    {isViewDetailsClicked ?
                        <div style={{ ...newCardStyle, marginTop: 20, border: "1px solid red" }}>
                            <div className='r-c-sb' style={{ width: `${courseTypeName == 'studyMaterials' ? '68%' : "100%"}` }}>
                                <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}> {courseTypeName == 'studyMaterials' ? 'Resources' : 'Course content'}</div>
                                {courseTypeName == 'studyMaterials' ?
                                    (<>
                                        <div style={{ color: "#191919", fontWeight: "bold" }}>{specificCourseDataContainer?.m_count}<span style={{ color: "#636363", fontWeight: "normal" }}> {specificCourseDataContainer?.m_count > 1 ? "PDF resources" : "PDF resource"}</span></div>
                                        <div style={{ color: "#191919", fontWeight: "bold" }}>{specificCourseDataContainer?.q_count}<span style={{ color: "#636363", fontWeight: "normal" }}> {specificCourseDataContainer?.q_count > 1 ? "Quizzes" : "Quiz"}</span></div>

                                    </>)
                                    :
                                    (
                                        <>
                                            <div style={{ color: "#191919", fontWeight: "bold" }}>{specificCourseDataContainer?.sections?.length}<span style={{ color: "#636363", fontWeight: "normal" }}> {specificCourseDataContainer?.sections?.length > 1 ? "Sections" : "Section"}</span></div>
                                            <div style={{ color: "#191919", fontWeight: "bold" }}>{specificCourseDataContainer?.course?.totalChapterCount}<span style={{ color: "#636363", fontWeight: "normal" }}> {specificCourseDataContainer?.course?.totalChapterCount > 1 ? "Lectures" : "Lecture"}</span></div>
                                            <div style={{ color: "#191919", fontWeight: "bold" }}>{specificCourseDataContainer?.course?.totalChapterCount}<span style={{ color: "#636363", fontWeight: "normal" }}> {specificCourseDataContainer?.course?.totalChapterCount > 1 ? "Quizzes" : "Quiz"}</span></div>
                                            <div style={{ color: "#191919", fontWeight: "bold" }}>{specificCourseDataContainer?.duration}<span style={{ color: "#636363", fontWeight: "normal" }}> Total length</span></div>
                                        </>
                                    )
                                }</div>
                        </div>
                        :
                        <div style={{ ...newCardStyle, marginTop: resources || isVideoResource ? '' : 20, height: resources || isVideoResource ? '700px' : 515 }}>
                            <div className='r-c-sb' style={{ width: `${courseTypeName == 'studyMaterials' ? '96%' : "100%"}` }}>


                                {courseTypeName == 'studyMaterials' ?
                                    (<>
                                        {specificCourseDataContainer?.topics?.length != 0 && !resources ?
                                            (<>
                                                {!resources && !isVideoResource ?
                                                    <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>{courseTypeName == 'studyMaterials' ? 'Resources' : courseTypeName == 'videoResources' ? 'Course content' : ''}</div> : (<></>)}

                                                {/* <div style={{border:"1px solid red"}}> */}
                                                <div style={{ color: "#191919", fontWeight: "bold" }}>{specificCourseDataContainer?.m_count}<span style={{ color: "#636363", fontWeight: "normal" }}> {specificCourseDataContainer?.m_count > 1 ? "PDF resources" : "PDF resource"}</span></div>
                                                <div style={{ color: "#191919", fontWeight: "bold" }}>{specificCourseDataContainer?.q_count}<span style={{ color: "#636363", fontWeight: "normal" }}> {specificCourseDataContainer?.q_count > 1 ? "Quizzes" : "Quiz"}</span></div>
                                                {hasMarketingPrivilege ?
                                                    <></>
                                                    :
                                                    <div style={{ color: "#1089FF", fontSize: "13px", fontWeight: 700, cursor: "pointer" }} onClick={() => { setResources(true), setIsEdit(true) }}><EditOutlined /> EDIT</div>
                                                }
                                                {/* </div> */}
                                            </>)
                                            : (<></>)}
                                    </>) : (<>
                                        {specificCourseDataContainer?.sections?.length != 0 && !isVideoResource ?
                                            <>
                                                <div className='r-c-sb' style={{ width: "70%", }}>
                                                    {!resources && !isVideoResource ?
                                                        <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>{courseTypeName == 'studyMaterials' ? 'Resources' : courseTypeName == 'videoResources' ? 'Course content' : ''}</div> : (<></>)}

                                                    <div style={{ color: "#191919", fontWeight: "bold" }}>{specificCourseDataContainer?.sections?.length}<span style={{ color: "#636363", fontWeight: "normal" }}> {specificCourseDataContainer?.sections?.length > 1 ? "Sections" : "Section"}</span></div>
                                                    <div style={{ color: "#191919", fontWeight: "bold" }}>{specificCourseDataContainer?.lectureCount}<span style={{ color: "#636363", fontWeight: "normal" }}> {specificCourseDataContainer?.lectureCount > 1 ? "Lectures" : "Lecture"}</span></div>
                                                    <div style={{ color: "#191919", fontWeight: "bold" }}>{specificCourseDataContainer?.quizCount}<span style={{ color: "#636363", fontWeight: "normal" }}> {specificCourseDataContainer?.quizCount > 1 ? "Quizzes" : "Quiz"}</span></div>
                                                    <div style={{ color: "#191919", fontWeight: "bold" }}>{specificCourseDataContainer?.duration}<span style={{ color: "#636363", fontWeight: "normal" }}> Total length</span></div>
                                                </div>

                                                <div className='r-c-sb' style={{ width: "18%" }}>
                                                    <div style={{ color: "#594099", fontSize: "13px", fontWeight: 500, cursor: "pointer" }} /* onClick={() => { history.push('/question-and-answer') }} */></div>
                                                    {hasMarketingPrivilege ?
                                                        <></>
                                                        :
                                                    <div style={{ color: "#1089FF", fontSize: "14px", fontWeight: 700, cursor: "pointer" }} onClick={() => { setIsVideoResource(true), setIsEdit(true) }}><EditOutlined /> EDIT</div>
                                                    }
                                                </div>
                                            </> : (<></>)}
                                    </>)}

                            </div>
                            {resources ?
                                (<>
                                    <StudyMaterialCourseComponent
                                        resources={resources}
                                        setResources={setResources}
                                        setIsEdit={setIsEdit}
                                        isEdit={isEdit}
                                        togglePageLoader={togglePageLoader} />
                                </>) :
                                (<>
                                    {specificCourseDataContainer?.topics?.length != 0 ? (<></>) :
                                        (<>
                                            <div className="r-c-c-c" style={{ height: 'fit-content' }}>
                                                <img
                                                    style={{ width: '50%', marginTop: "50px" }}
                                                    src={require("../../Assets/test/no-subjects.svg").default}
                                                    alt=""
                                                />
                                                <div className="r-c-c-c">
                                                    <h1 style={{ fontFamily: "roboto", fontWeight: "800", marginTop: "20px" }}>No resources added</h1>
                                                    <p style={{ color: "#636363", fontFamily: "roboto" }}>
                                                        No resources added to the course yet
                                                    </p>
                                                </div>
                                            </div>
                                            {hasMarketingPrivilege ?
                                                <></>
                                                :
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <Button style={{ border: '1px solid #1089FF' }} onClick={() => { setResources(true) }}>
                                                        <PlusCircleOutlined style={{ color: "#1089FF" }} /><div style={{ color: "#1089FF", fontSize: "14px", fontWeight: '400px', marginLeft: "5px" }}>ADD</div>
                                                    </Button>
                                                </div>
                                            }
                                        </>)}
                                </>)}
                            {courseTypeName == 'studyMaterials' ?
                                (<>
                                    {console.log("resources", resources)}
                                    {specificCourseDataContainer?.topics?.length != 0 && !resources ?
                                        <div className='scroll-bar-universal' style={{ margin: "20px 20px 20px 0px", height: "50vh" }}>
                                            {specificCourseDataContainer?.topics?.map((item, index) => (
                                                <StudyMaterialTopicsContent
                                                    item={item} index={index} />
                                            ))}
                                        </div> :
                                        (<>

                                        </>)}
                                </>)
                                :
                                (<>
                                    {specificCourseDataContainer?.sections?.length != 0 && !isVideoResource ?
                                        <div style={{ marginTop: "20px" }}>
                                            <Collapse
                                                accordion
                                                style={{ height: "20px" }}

                                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ marginTop: "-10px" }} />}
                                            >
                                                {specificCourseDataContainer?.sections?.map((item) => (
                                                    <Panel
                                                        header={
                                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px", width: "100%" }}>
                                                                <div style={{ fontSize: "12px", fontFamily: "Roboto", fontWeight: "bold" }}><span style={{ color: "#AEAEAE" }}><CaretRightOutlined /></span><span style={{ marginLeft: "10px" }}>{item.name}</span></div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "30%" }}>
                                                                    <div style={{ color: "#AEAEAE", fontSize: "14px", fontFamily: "Roboto", }}>{item?.topics?.length}{" "}{" "}<h style={{ marginRight: "15px" }}>lectures</h></div>
                                                                    <div style={{ color: "#AEAEAE", fontSize: "14px", fontFamily: "Roboto", }}>{item?.totalDuration}• mins </div>
                                                                </div>
                                                            </div>}
                                                        key={item.key}
                                                    >
                                                        {item?.topics?.map((item) => (
                                                            <div key={item.key}>
                                                                {console.log("item34", item)}
                                                                <div style={{ display: 'flex', padding: 5, justifyContent: "space-between" }}>
                                                                    <div style={{ display: "flex", width:'60%' }}>
                                                                        <div style={{ marginLeft: "20px" }}>
                                                                            {item?.type == 'video' || item?.videoType == 'recorded' ?
                                                                                <img style={{ width: "25px", height: "25px", marginLeft: "10px", marginTop: "5px" }} src={video} alt="Video" />
                                                                                :
                                                                                item?.type == 'notes' ?
                                                                                    <FilePdfOutlined style={{ color: "#FF414D", marginLeft: 10, fontSize: 20, marginTop: "5px" }} />
                                                                                    :
                                                                                    (<>
                                                                                        <img src={quiz} style={{ width: "25px", height: "25px", marginLeft: "10px", marginTop: "5px" }} />

                                                                                    </>)

                                                                            }

                                                                        </div>
                                                                        <div style={{ fontSize: "14px", fontWeight: 700, marginLeft: "12px", marginTop: "3px", width:'100%' }}>
                                                                            {item?.name}
                                                                        </div>
                                                                    </div>
                                                            
                                                                    {item?.type == 'notes' ? <></> :item?.fileUrl || item?.type == 'video' || item?.type == 'link'  ? <div style={{ color: "#1089FF", cursor: "pointer", width: "10%", fontSize: "14px", fontWeight: 600, marginRight: "5px", marginTop: 2 }}
                                                                        onClick={() => { 
                                                                            console.log('hjkl',item);
                                                                            
                                                                         setVideoPreview(true), setPreviewLink(item?.fileUrl), setVideoTypeLink(item?.videoType), setVideoName(item?.name) }}>
                                                                        Preview
                                                                    </div> : null}
                                                                    <div style={{ color: "#AEAEAE", fontSize: "14px", width: "10%" }}>
                                                                        {item?.videoType == 'recorded' || item?.type == 'notes' || item?.type == 'quiz' || item?.videoType == 'link' ?
                                                                            null : (<> {item?.duration}</>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Panel>
                                                ))}
                                            </Collapse>

                                        </div> : (<></>)}

                                    {isVideoResource ?
                                        (<>
                                            <div>
                                                <VideoResourcesContent
                                                    isVideoResource={isVideoResource}
                                                    setIsVideoResource={setIsVideoResource}
                                                    setIsEdit={setIsEdit}
                                                    isEdit={isEdit} />
                                            </div>

                                        </>) :
                                        (<>
                                            {specificCourseDataContainer?.sections?.length != 0 ? (<></>) :
                                                (<>
                                                    <div className="r-c-c-c" style={{ height: 'fit-content' }}>
                                                        <img
                                                            style={{ width: '50%', marginTop: "50px" }}
                                                            src={require("../../Assets/test/no-subjects.svg").default}
                                                            alt=""
                                                        />
                                                        <div className="r-c-c-c">
                                                            <h1 style={{ fontFamily: "roboto", fontWeight: "800", marginTop: "20px" }}>No resources added</h1>
                                                            <p style={{ color: "#636363", fontFamily: "roboto" }}>
                                                                No resources added to the course yet
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {hasMarketingPrivilege ?
                                                        <></>
                                                        :
                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                            <Button style={{ border: '1px solid #1089FF' }} onClick={() => { setIsVideoResource(true) }}>
                                                                <PlusCircleOutlined style={{ color: "#1089FF" }} /><div style={{ color: "#1089FF", fontSize: "14px", fontWeight: '400px', marginLeft: "5px" }}>ADD</div>
                                                            </Button>
                                                        </div>
                                                    }
                                                </>)}
                                        </>)}

                                </>)}

                        </div>
                    }
                </>}
            {isVideoPreview &&
                <PreviewCourse
                    isVideoPreview={isVideoPreview}
                    setVideoPreview={setVideoPreview}
                    previewLink={previewLink}
                    videoType={videoTypeLink}
                    videoName={videoName}
                />}
        </div>

    )

}
export default CourseContentComp

