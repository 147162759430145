import React, { useEffect, useState } from 'react';
import './loginPage.css';
import { IMG_URL } from "../../env.json";
import { connect } from 'react-redux';
import { Heading } from 'components/Typography';
import { getOrgDetails, setRegisterSuccessModal, setShowBottomSheet, setShowDownloadAppModal, setShowRegisterForm } from 'actions/loginNew';
import { Font13 } from 'components/responsiveTypography';
import playStore from 'Assets/Footer/googleplay.svg'
import appStore from 'Assets/Footer/Appstore.svg'
import LoginCardComponent from './loginCardComponent';
import DownloadAppModal from 'pages/studentMarketPlace/downloadAppModal';
import FormComponent from './formComponent';
import wave from "Assets/loginNew/Wave.png"
import SuccessModal from './successModal';
import { motion } from 'framer-motion';
import BottomSheet from './BottomSheet';

const LoginPage = ({ getOrgDetails, orgData, setShowDownloadAppModal, showDownloadAppModal, userMarketPlaceHomeData, setShowRegisterForm, setRegisterSuccessModal, showRegisterSuccessModal, setShowBottomSheet }) => {
    useEffect(() => {
        getOrgDetails();
        setShowRegisterForm(false);
        setShowBottomSheet(false)
    }, [])
    useEffect(() => {
        document.body.classList.add('new-login-page');
        return () => {
            document.body.classList.remove('new-login-page');
        };
    }, []);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const loginContainerVariants = {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition: {
                delay: 0.5, duration: 0.5
            }
        },
        exit: {
            x: "-100vw",
            transition: { ease: "easeInOut" }
        }
    }
    return (
        <>
            {isMobile ?
                <>
                    <img src={wave} style={{ width: "100%" }} />
                    <div className='r-c-c-c'>
                        <img src={orgData ? `${IMG_URL}/${orgData.logo}` : require("Assets/noinstitute.png").default} style={{ width: 60 }} className='m-t-20' />
                        {orgData ? <Heading bold style={{ margin: 0, marginTop: 5 }}>{orgData.title}</Heading> : null}
                    </div>
                    <FormComponent />
                    <BottomSheet />
                </>
                :
                <motion.div
                    className='new-login-page'
                    variants={loginContainerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <div className='new-login-logo-parent'>
                        <img className='new-login-institute-logo' src={orgData ? `${IMG_URL}/${orgData.logo}` : require("Assets/noinstitute.png").default} />
                        {orgData ? <Heading bold style={{ margin: 0, marginTop: 5 }}>{orgData.title}</Heading> : null}
                    </div>
                    <div className='login-left-side'>
                    </div>
                    <div className='login-right-side'>
                    </div>
                    <LoginCardComponent />
                    <div className='new-login-download-section'>
                        <a href={`https://${orgData.website}`} target='_blank()'>{orgData.website}</a>
                        <div className='new-login-download-child1'>
                            <Font13 color='#636363'>Download {orgData.title} app</Font13>
                            <img className='new-login-store-img m-l-20' src={playStore} onClick={() => window.open(orgData?.playstoreLink, "_blank")} />
                            <img className='new-login-store-img' src={appStore} onClick={() => window.open(orgData?.appStoreLink, "_blank")} />
                        </div>
                    </div>
                </motion.div>
            }
            {showDownloadAppModal &&
                <DownloadAppModal
                    showDownloadAppModal={showDownloadAppModal}
                    setShowDownloadAppModal={setShowDownloadAppModal}
                    userMarketPlaceHomeData={userMarketPlaceHomeData}
                    fromLoginPage={true}
                />}
            {showRegisterSuccessModal &&
                <SuccessModal
                    showRegisterSuccessModal={showRegisterSuccessModal}
                    setRegisterSuccessModal={setRegisterSuccessModal}
                />}
        </>
    );
};
const mapStateToProps = (state) => {
    const { showRegisterForm, orgData, showDownloadAppModal, showRegisterSuccessModal } = state.loginNew;
    const { userMarketPlaceHomeData } = state.studentMarketPlace;
    return {
        showRegisterForm, orgData, showDownloadAppModal, userMarketPlaceHomeData, showRegisterSuccessModal
    }
}

const mapDispatchToProps = (dispatch) => ({
    getOrgDetails: () => dispatch(getOrgDetails()),
    setShowRegisterForm: (bool) => dispatch(setShowRegisterForm(bool)),
    setShowDownloadAppModal: (val) => dispatch(setShowDownloadAppModal(val)),
    setRegisterSuccessModal: (val) => dispatch(setRegisterSuccessModal(val)),
    setShowBottomSheet: (val) => dispatch(setShowBottomSheet(val))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

