import React from "react";
import Modal from "antd/lib/modal";
import "antd/lib/modal/style/index";
import moment from "moment";
import { SyncOutlined, BellOutlined } from "@ant-design/icons";
import PullToRefresh from "react-simple-pull-to-refresh";
import { MdNotifications } from "react-icons/md";
import { TiFilter } from "react-icons/ti";
import { BsBellFill } from "react-icons/bs";
import { Api, Color, Utils } from "services";
import Carousel from "react-multi-carousel";
import { TestCard } from "components";
import { updateUserAction } from "actions/login";
import {
  getStudentTestData,
  getStudentSubjectHeader,
  getStudentTestDetail,
  getStudentTestResult,
} from "actions/mobileTest";
import { connect } from "react-redux";
import chemistryIcon from "Assets/test-assignment/chemistryIcon.svg";
import mathsIcon from "Assets/test-assignment/mathsIcon.svg";
import physicsIcon from "Assets/test-assignment/physicsIcon.svg";
import botanyIcon from "Assets/test-assignment/botanyIcon.svg";
import computerIcon from "Assets/test-assignment/computerIcon.svg";
import englishIcon from "Assets/test-assignment/englishIcon.svg";
import zoologyIcon from "Assets/test-assignment/zoologyIcon.svg";
import sliderIndicator from "Assets/test-assignment/sliderIndicator.svg";
import sliderIndicatorLight from "Assets/test-assignment/sliderIndicatorLight.svg";
import emptyTest from "Assets/test-assignment/emptyTest.svg";
import emptySubjects from "Assets/test-assignment/emptySubjects.svg";
import "./styles.css";
import "../../design/layout.css";
import "../../design/assignment.css";

const activityResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class MobileTest extends React.Component {
  componentDidMount() {
    localStorage.clear();
    this.props.getStudentTestData("", () => {});
    Utils.stopDuration();

    var url = new URL(window.location);
    var user = url.searchParams.get("user");

    if (user) {
      const userObj = JSON.parse(user);
      this.props.updateUserAction(userObj);
      Api.header(userObj.accessToken);
      Api.orgHeader(userObj.org.url);
    }
  }

  pageNavtoSubjects = (history, subjectId, subjectName) => {
    this.props.getStudentTestData(subjectId, (response) => {
      if (typeof response != "undefined") {
        this.props.getStudentSubjectHeader(subjectName, subjectId);
        history.push(`${history.location.pathname}/${subjectId}`);
      }
    });
  };

  onRefresh = () => {
    return new Promise((resolve) => {
      // setTimeout(resolve, 1000);
      this.props.getStudentTestData("", resolve);
    });
  };

  render() {
    const {
      history,
      studentTestLoader,
      studentTestData,
      studentTestSubLoader,
      studentTestDetailLoader,
      studentTestResultLoader,
    } = this.props;

    const ActivityButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
      const {
        carouselState: { currentSlide, deviceType },
      } = rest;
      return (
        <div className="r-c-c m-t-30 flex-wrap">
          {studentTestData.liveEvents.length
            ? studentTestData.liveEvents.map((data, index) => (
                <img
                  src={
                    currentSlide === index
                      ? sliderIndicator
                      : sliderIndicatorLight
                  }
                  alt=""
                  key={index.toString()}
                  className="m-r-10 m-b-10"
                  onClick={() => goToSlide(index)}
                />
              ))
            : null}
        </div>
      );
    };

    const LoaderModal = ({ loadingkey, loadingText }) => (
      <Modal
        visible={loadingkey}
        footer={null}
        centered
        closable={false}
        className="loadingModal"
      >
        <div className="r-c-c-c">
          <img
            src={require("Assets/dots-gif.gif").default}
            style={{ maxWidth: "100%" }}
            width={150}
            alt=""
          />
          {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} />
          <div className="text-xmd m-t-10">{loadingText}</div> */}
        </div>
      </Modal>
    );
    const BlankTest = () => (
      <div
        className="m-10 text-center r-c-c-c"
        style={{ height: "calc(100% - 78px)" }}
      >
        <img src={emptyTest} alt="" className="" style={{ width: "auto" }} />
        <div className="NutinFont bold-700 text-sm m-t-10">
          No test available!
        </div>
        <div className="NutinFont text-xmd simgrey">
          There are no tests available at the moment. Please check back later.
        </div>
      </div>
    );

    return (
      <div style={{ height: "100%" }}>
        {/*<div className='r-c-sb p-10'>
          <div className='NutinFont bold-700 text-mdl'>Tests</div>
          <BsBellFill style={{color:Color.dodgeBlue}} className='text-md'/>
        </div>*/}

        <PullToRefresh
          onRefresh={() => this.onRefresh()}
          // isPullable={false}
          // fetchMoreThreshold={100}
          // pullDownThreshold={10}
          // maxPullDownDistance={25}
          // backgroundColor={'pink'}
        >
          {studentTestLoader ? (
            <BlankTest />
          ) : studentTestData !== undefined ? (
            Object.keys(studentTestData).length ? (
              <div>
                <div className="NutinFont bold-700 text-md p-l-10 p-t-10">
                  LIVE events
                </div>
                {studentTestData.liveEvents?.length ? (
                  <Carousel
                    responsive={activityResponsive}
                    className="studentTestCarousel"
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ActivityButtonGroup />}
                  >
                    {studentTestData.liveEvents.map((data, index) => {
                      return (
                        <TestCard
                          key={index}
                          subject={data.subjects.replace(/,/g, ", ")}
                          topic={data.title}
                          icon={
                            data.subjects.includes("Physics")
                              ? physicsIcon
                              : data.subjects.includes("Chemistry")
                              ? chemistryIcon
                              : data.subjects.includes("Zoology")
                              ? zoologyIcon
                              : data.subjects.includes("English")
                              ? englishIcon
                              : data.subjects.includes("Computer")
                              ? computerIcon
                              : data.subjects.includes("Botany")
                              ? botanyIcon
                              : data.subjects.includes("Mathematics")
                              ? mathsIcon
                              : computerIcon
                          }
                          testDate={moment(data.testDate).format("DD/MM/yyyy")}
                          startTime={data.startTime}
                          endTime={data.endTime}
                          testProgress={data.testProgress}
                          cardType={"activities"}
                          onPress={
                            data.testProgress === "GRADED"
                              ? () => {
                                  this.props.getStudentTestResult(
                                    data.id,
                                    (response) => {
                                      history.push(
                                        `${history.location.pathname}/${data.subjectId}/instructions/start/results`
                                      );
                                    }
                                  );
                                }
                              : () => {
                                  this.props.getStudentTestDetail(
                                    data.id,
                                    "live",
                                    (response) => {
                                      if (response !== undefined) {
                                        this.props.getStudentSubjectHeader(
                                          data.subjects,
                                          data.subjectId
                                        );
                                        history.push(
                                          `${history.location.pathname}/${data.subjectId}/instructions`
                                        );
                                      }
                                    }
                                  );
                                }
                          }
                        />
                      );
                    })}
                  </Carousel>
                ) : (
                  <div className="mobileTestCardWrap m-10 text-center r-c-c-c p-20">
                    <img
                      src={emptyTest}
                      alt=""
                      className=""
                      style={{ width: "55%" }}
                    />
                    <div className="NutinFont bold-700 text-sm m-t-30">
                      No live events!
                    </div>
                    <div className="NutinFont text-xmd simgrey">
                      There are no live events at the moment.
                    </div>
                  </div>
                )}
                <div className="NutinFont bold-700 text-md p-l-10 m-t-30">
                  Subjects
                </div>
                {studentTestData.subjects?.length ? (
                  studentTestData.subjects.map((data, index) => (
                    <div
                      key={index.toString()}
                      className="r-ac m-10 m-b-15"
                      onClick={() =>
                        this.pageNavtoSubjects(
                          history,
                          data.subjectId,
                          data.subjectName
                        )
                      }
                    >
                      <img
                        src={
                          data.subjectName.includes("Physics")
                            ? physicsIcon
                            : data.subjectName.includes("Chemistry")
                            ? chemistryIcon
                            : data.subjectName.includes("Zoology")
                            ? zoologyIcon
                            : data.subjectName.includes("English")
                            ? englishIcon
                            : data.subjectName.includes("Computer")
                            ? computerIcon
                            : data.subjectName.includes("Botany")
                            ? botanyIcon
                            : data.subjectName.includes("Math")
                            ? mathsIcon
                            : computerIcon
                        }
                        alt=""
                        className="test-subjects-img-wrap"
                        style={{
                          background:
                            data.subjectName === "Physics"
                              ? "#fddc5e"
                              : data.subjectName === "Chemistry"
                              ? "#9381ff"
                              : data.subjectName === "Mathematics"
                              ? "#fa7477"
                              : "#fddc5e",
                        }}
                      />
                      <div className="flex-1 test-subjects-text-wrap p-l-10">
                        <div className="text-sm bold-600 color-black">
                          {data.subjectName}
                        </div>
                        <div className="text-xmd simgrey">
                          {data.testCount}{" "}
                          {data.testCount == 1 || data.testCount == 0
                            ? "test"
                            : "tests"}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="m-10 text-center r-c-c-c p-20">
                    <img
                      src={emptySubjects}
                      alt=""
                      className=""
                      style={{ width: "65%" }}
                    />
                    <div className="NutinFont bold-700 text-sm m-t-30">
                      No topics!
                    </div>
                    <div className="NutinFont text-xmd simgrey">
                      There are no topics at the moment.
                    </div>
                  </div>
                )}
                <div className="p-b-5"></div>
              </div>
            ) : (
              <BlankTest />
            )
          ) : (
            <BlankTest />
          )}
        </PullToRefresh>

        <LoaderModal
          loadingkey={studentTestLoader}
          loadingText={"Loading Tests"}
        />
        <LoaderModal
          loadingkey={studentTestSubLoader}
          loadingText={"Loading Subjects"}
        />
        <LoaderModal
          loadingkey={studentTestDetailLoader}
          loadingText={"Loading Tests"}
        />
        <LoaderModal
          loadingkey={studentTestResultLoader}
          loadingText={"Loading Result"}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    studentTestLoader,
    studentTestData,
    studentTestSubLoader,
    studentTestDetailLoader,
    studentTestDurationInSec,
    studentTestResultLoader,
  } = state.mobileTest;
  return {
    studentTestLoader,
    studentTestData,
    studentTestSubLoader,
    studentTestDetailLoader,
    studentTestDurationInSec,
    studentTestResultLoader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentTestData: (subjectId, callback) =>
    dispatch(getStudentTestData(subjectId, callback)),
  getStudentSubjectHeader: (subjectName, subjId) =>
    dispatch(getStudentSubjectHeader(subjectName, subjId)),
  getStudentTestDetail: (id, tabname, callback) =>
    dispatch(getStudentTestDetail(id, tabname, callback)),
  updateUserAction: (user) => dispatch(updateUserAction(user)),
  getStudentTestResult: (id, callback) =>
    dispatch(getStudentTestResult(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileTest);
