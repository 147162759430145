import React from "react";
import '../styles.css';

function OfferTypeButtonList({ offerTypeList, setOfferFilterParams, setMainSectionType, mainSectionType, offersTypeIndex, previousMainSectionType, setPreviousMainSectionType, setOffersTypeIndex, setSelectedItems }) {
    const handleOnClick = (index) => {
        return function (e) {
            if (index != offersTypeIndex) {
                setSelectedItems([]);
            }
            setOffersTypeIndex(index);
            if (mainSectionType == 'createEdit') {
                setMainSectionType('gridList');
            }
            if (previousMainSectionType == 'createEdit') {
                setPreviousMainSectionType('gridList');
            }
            setOfferFilterParams((params) => {
                const { available, all, published, unpublished, expired, banner, privateOffer, ...otherParams } = params;
                if (index == 0) {
                    otherParams.all = true;
                }
                if (index == 1) {
                    otherParams.available = true;
                } else if (index == 2) {
                    otherParams.available = false;
                }
                return otherParams;
            })
        };
    }

    return (
        <div className="offer-overview-type-list">
            {offerTypeList.map((item, index) => {
                return (
                    <div className={`offer-overview-list-item ${index == offersTypeIndex ? ' list-item-selected' : ''}`} key={index} onClick={handleOnClick(index)}
                        style={{
                            color: index == offersTypeIndex ? "#594099" : "#636363",
                            backgroundColor: index == offersTypeIndex ? "#DACFF2" : "#FFFFFF",
                            fontWeight: index == offersTypeIndex ? "bold" : "500",
                            boxShadow: index == offersTypeIndex ? "" : "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(255, 255, 255, 1)"
                        }}>
                        <span>{`${item.label} (${item.count})`}</span>
                    </div>
                );
            })}
        </div>
    );
}

export default OfferTypeButtonList;