import React from 'react';
import {connect} from 'react-redux';
import PageHeader from 'antd/lib/page-header';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Avatar from 'antd/lib/avatar';
import DatePicker from 'antd/lib/date-picker';
import Progress from 'antd/lib/progress';
import Empty from 'antd/lib/empty';
import 'antd/lib/page-header/style/index';
import 'antd/lib/row/style/index';
import 'antd/lib/col/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/date-picker/style/index';
import 'antd/lib/progress/style/index';
import 'antd/lib/empty/style/index';
import { Api, Color } from '../../../services';
import '../styles.css';
import Styles from './styles'
import { ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { BarGraphIcon, UserPresentIcon, UserAbsentIcon, BookOpenIcon } from '../../../icons';
import { getSubjectSchedule, setDateRange, getTeacherAttendance, updateStdsList } from '../../../actions/attendance-old'
import TeacherSubjectView from './teacherSubjectView'
import moment from 'moment';

const { RangePicker } = DatePicker;


class SingleTeachersView extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      selectedSubId: -1,
      selectedSubIndex: -1,
      standards: [],
      selectedSubName: '',
      selectedStandard: '',
      selectedStdId: -1
    }
  }


  handleSubSelect(subId, index, name){
    this.setState({
      selectedSubId: subId,
      selectedSubIndex: index,
      standards: this.props.singleTeacher.subjects[index].standards,
      selectedSubName: name
    })
    this.props.updateStdsList(index);

  }

  componentDidMount(){
    const date = [this.props.startDate, this.props.endDate]
    this.props.getTeacherAttendance(this.props.user.id, this.props.history, date, this.state.selectedSubIndex)
  }

  handleScheduleSelect(std, sec){
    const subject = this.props.singleTeacher.subjects[this.state.selectedSubIndex]
    const date = [this.props.startDate, this.props.endDate];
    this.props.getSubjectSchedule(subject, sec.id, date, this.props.singleTeacher.id);
    this.setState({
      selectedStandard: `${std.name} - ${sec.section}`,
      selectedStdId: sec.id
    })

  }

  handleDateSelect(date, history){
    // const startDate = date[0];
    // const endDate = date[1];
    this.props.setDateRange(date);
    if(this.props.singleSubjSelected){
        this.props.getSubjectSchedule(this.props.selectedSubject, this.state.selectedStdId, date, this.props.singleTeacher.id);
    }else{
      this.props.getTeacherAttendance(this.props.user.id, this.props.history, date, this.state.selectedSubIndex)
    }


  }

  handleBackToTeacher(history){
    if(!this.props.singleSubjSelected){
      history.push('/attendance');
    }else{
      const date = [this.props.startDate, this.props.endDate];
      this.props.getTeacherAttendance(this.props.singleTeacher.id, history, date)
      // this.props.showChapterScreen(false, {});
    }// this.props.setSingleClassView(false);
  }


  render() {
    const { singleTeacher, history, startDate, endDate, teacherSubjDetailLoader, singleSubjSelected } = this.props;
    const { selectedSubName } = this.state;

    const standards = this.state.selectedSubIndex !==-1 && singleTeacher.subjects ? singleTeacher.subjects[this.state.selectedSubIndex].standards : []

    const operations = (
      <div style={{position:'absolute', right: 0, top: 0}}>
        <RangePicker
        style={{borderRadius: 10}}
        onChange={(dateMoment, date) => this.handleDateSelect(date, history)}
        renderExtraFooter={() => 'extra footer'}
        defaultValue={[moment(startDate), moment(endDate)]}
        />
      </div>
      );

    return (
        <div style={{height: '100%'}}>
        <PageHeader
          style={{padding: 0, paddingBottom: 20}}
          className="site-page-header"
          onBack={singleSubjSelected ? () => this.handleBackToTeacher(history) : null}
          title={`My Attendance ${singleSubjSelected ? this.state.selectedStandard : ''}`}
          extra={operations}
          />
        {!singleSubjSelected ?
          <Row style={{height: '100%', width: '100%'}}>
            <Col style={{height: '100%'}} span={24}>
                <Row style={{width: '100%'}}>
                  <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:24}}>
                  <div style={{ border: '1px solid lightgray', borderRadius: 10}}>
                    <Row style={{padding: 10}}>
                      <Col xs={{span:2}} sm={{span:2}} md={{span:2}} lg={{span:2}} style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                        <Avatar size={90} src={`${Api._s3_url}${singleTeacher.image}`} style={{marginLeft: 0 }}/>
                      </Col>
                      <Col xs={{span:22}} sm={{span:22}} md={{span:22}} lg={{span:22}}>
                        <Row>
                          <Col xs={{span:6}} sm={{span:6}} md={{span:6}} lg={{span:6}} style={{paddingTop: '4%'}}>
                            <Row style={{marginLeft:20, fontSize: 16}}>{singleTeacher.name}</Row>
                            <Row style={{marginLeft:20, fontSize: 12, color: 'gray'}}>{singleTeacher.email}</Row>
                          </Col>
                          <Col xs={{span:18}} sm={{span:18}} md={{span:18}} lg={{span:18}}>
                            <Row style={{position: 'relative', float: 'right'}}>
                              <div  style={Styles.attendanceStatCard}>
                                <BarGraphIcon style={{paddingTop:10, color:Color.primary, marginTop:5}}/>
                                <div style={{fontSize: 10}}>Average</div>
                                <div style={{fontSize: 14, color: Color.primary}}>{singleTeacher.average}<span style={{fontSize: 12}}>%</span></div>
                              </div>
                              <div  style={Styles.attendanceStatCard}>
                                <UserPresentIcon style={{paddingTop:10, color:"#61BB45", marginTop:5}}/>
                                <div style={{fontSize: 10}}>Present</div>
                                <div style={{fontSize: 14, color: Color.primary}}>{singleTeacher.present}</div>
                              </div>
                              <div  style={ Styles.attendanceStatCard}>
                                <UserAbsentIcon style={{paddingTop:10, color: Color.red, marginTop:5}}/>
                                <div style={{fontSize: 10}}>Absent</div>
                                <div style={{fontSize: 14, color: Color.primary}}>{singleTeacher.absent}</div>
                              </div>
                              <div  style={ Styles.attendanceStatCard}>
                                <BookOpenIcon style={{paddingTop:10, color:Color.primary, marginTop:5}}/>
                                <div style={{fontSize: 10}}>Subjects</div>
                                <div style={{fontSize: 14, color: Color.primary}}>{singleTeacher && singleTeacher.subjects ? singleTeacher.subjects.length : 0}</div>
                              </div>
                              <div  style={ Styles.attendanceStatCard}>
                                <ClockCircleOutlined style={{paddingTop:10, color:Color.darkGrey, marginTop:5}}/>
                                <div style={{fontSize: 10}}>Total Hours</div>
                                <div style={{fontSize: 14, color: Color.primary}}>{singleTeacher.totalHours}</div>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                        <Row><Progress style={{marginLeft:10}} percent={singleTeacher.average} showInfo={false} strokeColor="#61BB45" trailColor="#FF7D5C"/></Row>
                      </Col>
                    </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{height: '80%'}}>
                  <Col style={{height: '100%'}} span={4}>
                    <div style={{height: '100%', overflowY: 'auto'}}>
                      {singleTeacher.subjects && singleTeacher.subjects.length > 0 ?
                       singleTeacher.subjects.map((subject, index) => (
                         <div key={index} onClick={()=> this.handleSubSelect(subject.id, index, subject.name)} className='cursor-pointer' style={{ backgroundColor: subject.id === this.state.selectedSubId ? Color.lightGrey: 'transparent' ,borderRadius: 5, marginTop: 15, padding:5}}>
                           <div style={{fontSize: 16, color: Color.darkGrey}}>
                             {subject.name}
                           </div>
                         </div> )): null
                        }
                    </div>
                  </Col>
                  <Col style={{height: '100%'}} span={20}>
                  <div style={{height: '100%', overflowY: 'auto'}}>
                    {singleTeacher.subjects && singleTeacher.subjects.length ?
                     this.state.selectedSubIndex !== -1 ? standards.length > 0 ?
                    standards.map((std, index) => (
                      <div key={index} style={{marginLeft: 10, marginTop: 20}}>
                        <span style={Styles.secBox}>
                         {std.name}
                        </span>
                        { std.sections ? std.sections.map((sec, index) => (
                          <div key={index} onClick={() => this.handleScheduleSelect(std, sec)} style={{display: 'flex', width: '30%', marginTop: 20, justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{flex:1}}>
                              <Avatar size={18} style={{backgroundColor: Color.darkGrey, fontSize: 12}}>{sec.section}</Avatar>
                            </div>
                            <div style={Styles.singleScheculeBox} className='cursor-pointer r-c-c'>
                              <Row style={{width:'100%'}}>
                                <Col span={3} style={{display: 'flex', alignItems: 'center'}}>
                                  <Avatar size={24} style={{backgroundColor: Color.primary, fontSize: 12}}>{selectedSubName.charAt(0)}</Avatar>
                                </Col>
                                <Col span={13} style={{fontSize: 12}}>
                                  <Row style={{color: 'black', marginBottom: 3, marginLeft:3}}> {selectedSubName} </Row>
                                  <Row style={{color: Color.darkGrey, marginLeft:3}}> {sec.duration}mins </Row>
                                </Col>
                                <Col span={8}>
                                  <Row style={{color: 'black', marginBottom: 3, fontSize: 11}}>{sec.noOfClasses} Classes</Row>
                                </Col>
                              </Row>
                              {teacherSubjDetailLoader == sec.id ? <LoadingOutlined  style={{position:'absolute'}}/> : null}
                            </div>
                          </div>
                        )) : null}

                      </div>
                    )) : <Empty style={{marginTop: 30}}  description="No classes to display" />
                    : <Empty  style={{marginTop: 30}} description="Select a subject" /> :
                    <Empty style={{marginTop: 30}} description="No subjects to display. Try changing the date range" />}
                  </div>
                  </Col>
                </Row>

                </Col>
            </Row>
            : <TeacherSubjectView teacherName={singleTeacher.name}/> }


        </div>
      )
  }
}

const mapStateToProps = state => {
  const { singleTeacher, startDate, endDate, standards, singleClassDetail, selectedSubject,
    teacherSubjDetailLoader, singleSubjSelected } = state.attendance;
  const { user } = state.session;
  return { singleTeacher, startDate, endDate, standards , singleClassDetail, selectedSubject,
    teacherSubjDetailLoader, singleSubjSelected, user};
};

const mapDispatchToProps = dispatch => ({
  getSubjectSchedule: (subject, stdId, date, teacherId) => dispatch(getSubjectSchedule(subject, stdId, date, teacherId)),
  setDateRange: (date) => dispatch(setDateRange(date)),
  getTeacherAttendance: (id, history, date, index) => dispatch(getTeacherAttendance(id, history, date, index)),
  updateStdsList: index => dispatch(updateStdsList(index))
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleTeachersView);
