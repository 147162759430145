import { Dropdown, Typography, Menu, Avatar, Tooltip } from 'antd'
import React, { useState } from 'react'
import { MdCastConnected, MdMoreVert } from 'react-icons/md'
import { FaGraduationCap } from 'react-icons/fa'
import { ClockCircleFilled, UserOutlined } from '@ant-design/icons'
import moment from 'moment'
import { connect } from 'react-redux'
import { Api } from 'services'
import { SquareSubIcon } from 'components/subIcon'
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import { RiWirelessChargingLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import Conference from "Assets/schedule/confrence.svg"
import Broadcast from "Assets/schedule/Broadcast.svg"
import Offline from "Assets/schedule/ofline.svg"
import { EventRegister } from 'react-native-event-listeners'
import { EmbedModal, regexMeet } from './onGoingClassCard'
import { postEmbedLink } from 'actions/broadcast'
import OtherColor from "Assets/schedule/other_color.svg"
import { setLiveStreamUrl } from 'actions/schedule'

const { Text } = Typography
const ScheduleClassTandS = ({ user, scheduleItem, postEmbedLink, setLiveStreamUrl }) => {
    const history = useHistory()
    const handleContact = () => {
        history.push("/care/chat")
    }
    const [embedModalVisible, setEmbedModalVisible] = useState(false);
    const [embedding, setEmbedding] = useState(false);
    const menu = (
        <Menu style={{ width: "230px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
            <Menu.Item key="1" onClick={() => handleContact()}>

                Contact your admin
            </Menu.Item>
            {
                user.access.includes("schedule-teacher") && user.id === scheduleItem.teacherId && scheduleItem.mode==='Others' ?
                <>
                <Menu.Item key="2" onClick={()=>{handleJoin()}}>
                    {scheduleItem.sUrl!=undefined?'Replace link':'Add link'} 
                </Menu.Item>
                {scheduleItem?.sUrl != undefined ?
                <Menu.Item key="3" onClick={()=>{playStream()}}>
                    Play stream
                </Menu.Item>
                :null}
                </>
                :null
            }
        </Menu>

    );
    const playStream = () => {
        if (scheduleItem.sUrl.match(regexMeet)){
            window.open(scheduleItem.sUrl, '_blank');
        }else{
            setLiveStreamUrl(scheduleItem?.sUrl)
            history.push('/liveplayer')
        }
    }
  
    const handleJoin = () => {
      setEmbedModalVisible(true);
    }
  
    const onEmbed = async(link) => {
      setEmbedding(true);
      await postEmbedLink({id:scheduleItem.id, sUrl: link})
      setEmbedding(false);
      setEmbedModalVisible(false);
      EventRegister.emit('apicall:scheduleHome');
    }

    if (user.access.includes("schedule-teacher")) {

        return (
            <div className="ScheduleClassTandS">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="scheduleclass-img-text">
                        <div>
                            <SquareSubIcon icon={scheduleItem?.subject?.icon ? scheduleItem?.subject?.icon : ''} name={scheduleItem?.subject?.name ? scheduleItem?.subject?.name : ''} size={40} />

                        </div>
                        <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', width: '200px' }}>
                            {scheduleItem.mode == "Zoom 40" ?
                                <div style={{ width: '74px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E7F3FF', borderRadius: '8px', padding: '2px' }}>
                                    <IoMdVideocam style={{ color: '#1089FF' }} />
                                    <Text style={{ fontSize: '10px', color: '#1089FF', marginLeft: '5px' }}>Zoom 40</Text>
                                </div>
                                : scheduleItem.mode == "Broadcast" ?
                                    <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF4D5', borderRadius: '8px', padding: '2px' }}>
                                        <MdCastConnected style={{ color: '#FFA931', textAlign: 'center' }} />
                                        <Text style={{ fontSize: '10px', color: '#FFA931', marginLeft: '5px' }}>Broadcast</Text>
                                    </div>
                                    :
                                    scheduleItem.mode == "Conference" ?
                                        <div style={{ width: '77px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F4FE', borderRadius: '8px', padding: '2px' }}>
                                            <RiWirelessChargingLine style={{ color: '#594099' }} />
                                            <Text style={{ color: '#594099', marginLeft: '5px', fontSize: '10px' }}>Conference</Text>
                                        </div>
                                        :
                                        scheduleItem.mode == 'Others' ?
                                        <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFE5F4', borderRadius: '8px', padding: '3px 7px' }}>
                                            <img src={OtherColor} style={{ color: '#E50087',height:"16px",width:"16px" }} />
                                            <Text style={{ color: '#E50087', marginLeft: '5px', fontSize: '10px' }}>Other</Text>
                                        </div>
                                        :
                                        <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '8px', padding: '2px' }}>
                                            <IoMdChatbubbles style={{ color: '#28DF99', textAlign: 'center' }} />
                                            <Text style={{ fontSize: '10px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                                        </div>
                            }
                            <Tooltip title={scheduleItem?.subject?.name ? scheduleItem?.subject?.name : null}>
                                <Text style={{ fontWeight: 'bold', fontSize: '15px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{scheduleItem?.subject?.name}</Text>
                            </Tooltip>
                        </div>
                    </div>

                    {/* commented due to aulas care is not in PROD */}


                    {/* {!user.privilage.includes('Institute Support') ?
                        <div>
                            <Dropdown overlay={menu} disabled={false} placement="bottomRight" >
                                <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
                            </Dropdown>
                        </div> : null} */}
                        
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                    <div style={{ width: '50%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <Tooltip title={scheduleItem?.standards.length != 0 ? scheduleItem?.standards?.map((item, index, array) => (`${item?.std + "-" + item?.section}${index === array.length - 1 ? '' : ' | '}`)) : null}>
                            <FaGraduationCap style={{ color: 'purple', marginRight: '5px' }} />
                        </Tooltip>
                        {scheduleItem?.standards.length != 0 ? scheduleItem?.standards?.map((item, index, array) => (
                            <Text style={{ fontSize: '80%', fontFamily: 'roboto' }} key={index}>{item?.std + "-" + item?.section}{index === array.length - 1 ? '' : '|'}</Text>
                        )) : null}
                    </div>
                    <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <ClockCircleFilled style={{ fontSize: '11px', color: '#594099' }} />
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(scheduleItem?.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(scheduleItem?.endTime, 'HH:mm:ss').format("hh:mm A")}`}</Text>
                    </div>
                </div>
                {embedModalVisible ? <EmbedModal adding={scheduleItem.sUrl==undefined} embedding={embedding} visible={embedModalVisible} onClose={()=>{setEmbedModalVisible(false)}} onOk={onEmbed}/>:null}
            </div>
        )
    }
    else if (user.access.includes('schedule-student')) {
        return (
            <div className="ScheduleClassTandS">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="scheduleclass-img-text">
                        <div>
                            <SquareSubIcon icon={scheduleItem?.subject?.icon ? scheduleItem?.subject?.icon : ''} name={scheduleItem?.subject?.name ? scheduleItem?.subject?.name : ''} size={40} />
                        </div>
                        <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', width: '200px' }}>
                                {scheduleItem.mode == "Zoom 40" ?
                                <div style={{ width: '74px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E7F3FF', borderRadius: '8px' }}>
                                    <IoMdVideocam style={{ color: '#1089FF' }} />
                                    <Text style={{ fontSize: '10px', color: '#1089FF', marginLeft: '5px' }}>Zoom 40</Text>
                                </div>
                                : 
                                scheduleItem.mode == "Broadcast" ?
                                <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF4D5', borderRadius: '8px', padding: '2px' }}>
                                    <img src={Broadcast} style={{ color: '#FFA931', textAlign: 'center', height: "18px", width: "18px" }} />
                                    <Text style={{ fontSize: '10px', color: '#FFA931', marginLeft: '5px' }}>Broadcast</Text>
                                </div>
                                :scheduleItem.mode == "Conference" ?
                                <div style={{ width: '77px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F4FE', borderRadius: '8px', padding: '2px' }}>
                                    <img src={Conference} style={{ color: '#594099', height: "18px", width: "18px" }} />
                                    <Text style={{ color: '#594099', marginLeft: '5px', fontSize: '10px' }}>Conference</Text>
                                </div>
                                :scheduleItem.mode == "Others" ?
                                <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFE5F4', borderRadius: '8px', padding: '3px 7px' }}>
                                    <img src={OtherColor} style={{ color: '#E50087',height:"16px",width:"16px" }} />
                                    <Text style={{ color: '#E50087', marginLeft: '5px', fontSize: '10px' }}>Other</Text>
                                </div>
                                :
                                <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '8px', padding: '2px' }}>
                                    <img src={Offline} style={{ color: '#28DF99', textAlign: 'center', height: "18px", width: "18px" }} />
                                    <Text style={{ fontSize: '10px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                                </div>
                                }
                            <Tooltip title={scheduleItem?.subject?.name ? scheduleItem?.subject?.name : null}>
                                <Text style={{ fontWeight: 'bold', fontSize: '15px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{scheduleItem?.subject?.name}</Text>
                            </Tooltip>
                        </div>
                    </div>

                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', padding: '10px' }}>
                    <div style={{ width: '50%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <Avatar shape='circle' size='small' src={`${Api._s3_url}${scheduleItem?.teacher?.image}`} />
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{scheduleItem?.teacher?.name}</Text>
                    </div>
                    <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <ClockCircleFilled style={{ fontSize: '11px', color: '#594099' }} />
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(scheduleItem?.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(scheduleItem?.endTime, 'HH:mm:ss').format("hh:mm A")}`}</Text>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
    } = state.schedule;
    const { user } = state.session;
    return {
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
  postEmbedLink: (params, c, sc) => dispatch(postEmbedLink(params, c, sc)),
  setLiveStreamUrl: (url) => dispatch(setLiveStreamUrl(url))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(ScheduleClassTandS);