import React, {useState} from "react";
import Typography from 'antd/lib/typography';
import Tooltip from 'antd/lib/tooltip';
import 'antd/lib/typography/style/index';
import 'antd/lib/tooltip/style/index';
import { Color } from "services";
import {
  MdInsertComment, MdKeyboardArrowLeft, MdKeyboardArrowRight
} from "react-icons/md";
import { IoIosPeople, IoIosHand } from "react-icons/io";

import "./styles.css";
import "../../design/layout.css";

const { Text } = Typography;

const UtilityButtons = props => {
  return (
    <div onClick={props.onPress} className="utibtnBox relative">
      <Tooltip title={props.title}>
        {props.children}
      </Tooltip>
    </div>
  );
};

const UtilityBox = ({
  audioOn,
  videoOn,
  onlineCount,
  onPressChat,
  onPressHand,
  onPressPart,
  pageNumber,
  chatCount,
  handCount,
}) => {
  const [box, setBox] = useState(true);
  return (
    <div className = 'r-c-sb'>
      <div className='m-r-10 hiding' style={{
          marginTop: box ? 0 : 10,
        }} onClick={() => setBox(!box)}>
        {box ?
          <MdKeyboardArrowRight
            style={{
              margin: 2,
              color: Color.darkGrey,
              fontSize: 25,
            }}
          />
          :
          <MdKeyboardArrowLeft
            style={{
              margin: 2,
              color: Color.darkGrey,
              fontSize: 25,
            }}
          />
        }
      </div>
      <div className="utilityStyle" style={{
          display: box ? 'flex' : 'none',
          opacity: box ? 1 : 0,
          width: box ? 'auto' : 10,
        }}>
        <UtilityButtons onPress={onPressChat} title='Chats'>
          <MdInsertComment
            style={{
              color: Color.darkGrey,
              fontSize: 25
            }}
          />
          {chatCount ? <div className='radius-100 absolute badge'>{chatCount}</div> : null}
        </UtilityButtons>
        <UtilityButtons onPress={onPressPart} title='Online students'>
          <IoIosPeople
            style={{
              color: Color.darkGrey,
              fontSize: 25
            }}
          />
        {onlineCount ? <div className='radius-100 absolute badge'>{onlineCount}</div> : null}
        </UtilityButtons>
        <UtilityButtons onPress={onPressHand} title='Hand raise'>
          <IoIosHand
            style={{
              color: Color.darkGrey,
              fontSize: 25
            }}
          />
        {handCount ? <div className='radius-100 absolute badge'>{handCount}</div> : null}
        </UtilityButtons>
        <Text style={{ color: Color.darkGrey, fontSize: 20, padding: 10 }}>
          Page {pageNumber}
        </Text>
        {/*<UtilityButtons>
          <div className='profileImage' />
          <div className='r-c-sb'>
            {audioOn ?
              <FaMicrophone style={{color: Color.magenta, fontSize: 15, }} />
              :
              <FaMicrophoneSlash style={{color: Color.magenta, fontSize: 15, }} />
            }
            {videoOn ?
              <FaVideo style={{color: Color.magenta, fontSize: 15, }} />
              :
              <FaVideoSlash style={{color: Color.magenta, fontSize: 15, }} />
            }
          </div>
        </UtilityButtons>*/}
      </div>
    </div>
  );
};

export default UtilityBox;
