import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

import { Row, Col, Slider, Modal, Image, Progress, Divider, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { updateProgress } from "../../actions/curriculum";

import { IMG_URL } from "../../env.json";
import digitalLibraryIcon from "../../Assets/layout/ic-digital-library.svg";
import testIcon from "../../Assets/test/ic-test-1.svg";
import '../../design/layout.css';
import practicetestIcon from "../../Assets/test/ic-practice-test.svg";




function ChapterRow({ chapter, chapIdx, updateProgress, user, std, section, subjectName }) {

	const history = useHistory();
	const location = useLocation();


    const handleTestRedirect = () => {
        if(user?.role?.[0] == 'Student')
            history.push({ pathname: '/student-test', state: {
                    syllabus: true
                }
            })
        else
            history.push({ pathname: "/test-std-to-subjects-screen", state: { 
                    syllabus: {
                        std_id: chapter?.stdId,
                        className: std,
                        section
                    }
                } 
            })
    }

    const handleLibraryRedirect = () => {
        history.push({ pathname: "/digital-library/chapter-details", state: { 
                syllabus: {
                    chapterId: chapter?.id,
                    chapterName: chapter?.name,
                    id: chapter?.subjectId,
                    subjects_id: chapter?.subjectId,
                    subjects_name: subjectName,
                    std_id: chapter?.stdId,
                    standard: std,
                    std_section: section
                }
            } 
        })
    }

    const [progress, setProgress] = useState(chapter?.progress);

    useEffect(() => {
        setProgress(chapter.progress);
	}, [chapter?.progress])
    

    const handleOnChange = (newval) => {
        if (newval > progress) setProgress(newval);
    }

    const handleProgressChange = (val, chapter) => {
        if (val <= progress) return;
        const params = {
            progress: val,
            chapterId: chapter?.id
        }
        return Modal.confirm({
            title: "Update Progress",
            icon: <ExclamationCircleOutlined />,
            content: <p>Do you want to update progress of <b>{chapter?.name}</b> to <b>{val}%</b>?<br/>
            NOTE: You can not decrease chapter progress once increased.</p>,
            okText: "Confirm",
            onOk() {
                setProgress(val)
                updateProgress(params, location.pathname, () => setProgress(prev => chapter?.progress));
            },
            onCancel() { setProgress(chapter?.progress) },
        });
    }


    return <div key={chapIdx} style={{}}>
        <Divider  style={{marginBottom: 10, marginTop: 10}}/>
        <Row className="r-ac">
            <Col span={1}>{chapIdx + 1}</Col> 
            <Col offset={1} span={6}>{chapter?.name}</Col>
            <Col offset={1} span={(user?.role?.[0] == 'Teacher' &&
							    location.pathname == '/syllabus') ? 13: 9}>
                {!(
                    user?.privilage?.includes("SuperAdmin") || 
                    user?.privilage?.includes("Admin") ||
                    (user?.role?.[0] == 'Teacher' && location.pathname == '/syllabus')
                )
                    &&
                    <Progress
                        percent={chapter?.progress}
                        showInfo={true}
                        style={{paddingRight: 20}}
                    />
                }
                {(
                    user?.privilage?.includes("SuperAdmin") || 
                    user?.privilage?.includes("Admin") ||
                    (user?.role?.[0] == 'Teacher' && location.pathname == '/syllabus')
                ) &&
                    <Row style={{maxHeight: 20, marginTop: -5}}>
                        <Col span={20}>
                            <Slider
                                defaultValue={chapter.progress}
                                value={progress}
                                onChange={(newval) => handleOnChange(newval)}
                                onAfterChange={(val) =>
                                    setTimeout(() => handleProgressChange(val, chapter), 350)
                                }
                                disabled={false}
                                trackStyle={{
                                    background: (progress == 100) ? 'green' : 'purple'
                                }}
                                handleStyle={{
                                    color: 'black'
                                }}
                                preview={true}
                            />
                        </Col>
                        <Col span={3} offset={1}>{progress} %</Col>
                    </Row>
                }

                {chapter?.progress > 0 && `as on ${moment(chapter?.modifiedOn, 'YYYY-MM-DD').format('DD-MM-YYYY')}`}
            </Col>
            {!(user?.role?.[0] == 'Teacher' && location.pathname == '/syllabus') &&
                <Col span={4} className="r-c-fs">
                    &emsp;
                    {chapter?.actionBy &&
                        <div style={{marginRight: 10}}>
                            <img src={`${IMG_URL}/${chapter?.actionBy?.image || "default.png"}`}
                                style={{ height: 20, width: 20, borderRadius: "50%" }}
                            />
                        </div> 
                    }
                    <div style={{wordBreak: 'break-word', marginRight: 10}}>{chapter?.actionBy?.name}</div>
                </Col>
            }
            <Col span={1}>
                <Tooltip placement="bottom" title={'Digital library'} 
                    className= {chapter?.hasAccess ? 'cursor-pointer' : ''}
                    onClick={chapter?.hasAccess ? handleLibraryRedirect : () => {}}
                >
                    <Image
                        width={16}
                        height={16}
                        src={digitalLibraryIcon}
                        preview={false}
                        
                    />
                    &nbsp;{chapter?.hasAccess ? chapter?.digitalLibrary : 0}
                </Tooltip>
            </Col>
            {/* <Col span={1}>
                <Tooltip placement="bottom" title={'Practice Test'}>
                    <Image
                        width={16}
                        height={16}
                        src={practicetestIcon}
                        preview={false}
                    />
                    &nbsp;0
                </Tooltip>
            </Col> */}
            <Col span={1}>
                <Tooltip placement="bottom" title={'Live test'} 
                    className= {(user?.role?.[0] != 'Teacher' && !chapter?.hasAccess) ? '' : 'cursor-pointer'}
                    onClick={(user?.role?.[0] != 'Teacher' && !chapter?.hasAccess) ? () => {}
                        : handleTestRedirect}
                >
                    <Image
                        width={16}
                        height={16}
                        src={testIcon}
                        preview={false}
                    />
                    &nbsp;{chapter?.tests}
                </Tooltip>
            </Col>

        </Row>
    </div>

}


const mapStateToProps = (state) => {
    const { user } = state.session;

    return { user }
};


const mapDispatchToProps = (dispatch) => ({
    updateProgress: (params, location, failureCallback) => dispatch(updateProgress(params, location, failureCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChapterRow);
