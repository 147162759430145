import { Modal, Tabs } from 'antd'
import AnimatedCustomSelect from 'components/AnimatedSelectComp'
import AnimatedTextField from 'components/AnimatedTextField'
import { Button } from 'components/Button'
import React, { useEffect, useState } from 'react'

function NewUnassignStudentsModal({ isUnassignStudentsModalVisible, setIsUnassignStudentsModalVisible, standardsFromStudentIdsData, tab, setTab, setIsConfirmModalVisible, courseName, postUnassignStudents, setSelectedStudents, togglePageLoader, getCourseStudentsData, selectedStudents }) {
    const handleCancel = () => {
        setIsUnassignStudentsModalVisible(false)
    }
    const [unassignParamsObj, setUnassignParamsObj] = useState({
        selectedStandard: null,
        selectedSection: null,
    })
    const [unassignLoader, setUnassignLoader] = useState(false);

    useEffect(() => {
        setTab(1)
    }, [])

    const fromclassKeysArray = Object.keys(standardsFromStudentIdsData?.items ?? {})?.map((key) => ({ name: key }));
    const fromSections = unassignParamsObj?.selectedStandard && standardsFromStudentIdsData?.items[unassignParamsObj?.selectedStandard]?.map(cls => ({ id: cls.id, value: cls.section }));

    const RenderBatchComponent = () => {
        return (
            <div>
                <div>
                    <AnimatedCustomSelect
                        label={"Batch"}
                        required={true}
                        options={fromclassKeysArray}
                        handleSelectChange={(value) => { setUnassignParamsObj({ ...unassignParamsObj, selectedStandard: value, selectedSection: null }) }}
                        value={unassignParamsObj?.selectedStandard}
                        isSelected={unassignParamsObj?.selectedStandard ? true : false} />
                </div>
                <div className='m-t-20'>
                    <AnimatedCustomSelect
                        label={"Section"}
                        required={true}
                        options={fromSections}
                        handleSelectChange={(value) => setUnassignParamsObj({ ...unassignParamsObj, selectedSection: value })}
                        value={unassignParamsObj?.selectedSection}
                        isSelected={unassignParamsObj?.selectedSection ? true : false} />
                </div>
            </div>
        )
    }

    const RenderCourseComponent = () => {
        return (
            <div style={{ marginTop: 15 }}>
                <AnimatedTextField
                    label={"Course"}
                    isMandatory={true}
                    inputId={"-1000"}
                    isDisabled={true}
                    value={courseName}
                    handleInputChange={() => { }}
                />
            </div>
        )
    }

    const onChange = (key) => {
        setTab(key);
    };
    const items = [
        {
            key: '1',
            label: 'BATCH',
            children: <RenderBatchComponent />,
        },
        {
            key: '2',
            label: 'COURSE',
            children: <RenderCourseComponent />,
        },
    ];

    const handleUnassignClick = () => {
        const params = {
            studentsList: selectedStudents,
            standardId: unassignParamsObj?.selectedSection
        }
        setUnassignLoader(true);
        postUnassignStudents(
            params,
            () => { setUnassignLoader(false); setSelectedStudents([]) },
            () => {
                setIsUnassignStudentsModalVisible(false);
                setSelectedStudents([])
                togglePageLoader(true);
                getCourseStudentsData(() => togglePageLoader(false))
            }
        )
    }

    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isUnassignStudentsModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}>
            <center style={{ fontSize: "20px", color: "#191919", fontWeight: "bold" }}>Unassign student(s)</center>
            <div className='m-t-10'>
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
            <center style={{ marginTop: tab == 1 ? 100 : 172 }}>
                <div className='r-jsb m-t-30' style={{ width: "60%" }}>
                    <Button type="link" style={{ color: "#FF414D", fontWeight: "bold", fontSize: 16 }}
                        disabled={tab == 1 && (!unassignParamsObj?.selectedStandard || !unassignParamsObj?.selectedSection)}
                        loading={unassignLoader}
                        onClick={() => {
                            if (tab == 1) {
                                handleUnassignClick()
                            } else if (tab == 2) {
                                setIsUnassignStudentsModalVisible(false); setIsConfirmModalVisible(true)
                            }
                        }}
                    >UNASSIGN</Button>
                    <Button type="primary" onClick={() => { setIsUnassignStudentsModalVisible(false) }}>CANCEL</Button>
                </div>
            </center>
        </Modal>
    )
}

export default NewUnassignStudentsModal