import {
    UPDATE_ORG_DETAILS_KYC,
    SHOW_KYC_PENDING_MODAL,
    SHOW_KYC_APPROVAL_PENDING_MODAL
  } from "actions/kyc";
  
  const initialState = {
    organisationDetails: {},
    kycPendingModal: false,
    kycApprovalPendingModal: false,

  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_KYC_APPROVAL_PENDING_MODAL: 
            return {
              kycApprovalPendingModal: action.bool
            }
        case SHOW_KYC_PENDING_MODAL: 
            return {
              kycPendingModal: action.bool
            }
        case UPDATE_ORG_DETAILS_KYC: 
            return {
                organisationDetails: {...action.data.kyc}
            }
        default:
            return {
            ...state,
            };
    }
  };
  
  export default reducer;
  