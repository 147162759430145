import { Api } from "../services";
import moment from 'moment'
// import { RESET_FILTER_PARAMS } from "./user";

export const SHOW_MODAl = "SHOW_MODAl";
export const HIDE_MODAl = "HIDE_MODAl";
export const UPDATE_TIME_TABLE = "UPDATE_TIME_TABLE";
export const TOGGLE_POST_LOADER = "TOGGLE_POST_LOADER";
export const UPDATE_SCHEDULE_INPUT = "UPDATE_SCHEDULE_INPUT";
export const UPDATE_TEACHER_LIST = "UPDATE_TEACHER_LIST";
export const TOGGLE_SEARCH_LOADER = "TOGGLE_SEARCH_LOADER";
export const UPDATE_SUB_CLASS = "UPDATE_SUB_CLASS";
export const UPDATE_SUB_CLASS_LOADER = "UPDATE_SUB_CLASS_LOADER";
export const TOGGLE_FETCH_SCHEDULE_LOADER = "TOGGLE_FETCH_SCHEDULE_LOADER";
export const UPDATE_FILTER_PARAMS = "UPDATE_FILTER_PARAMS";
export const RESET_FILTER_PARAMS = "RESET_FILTER_PARAMS";
export const RESRE_SCHEDULE_PARAMS = "RESRE_SCHEDULE_PARAMS";

export const hideModal = data => ({
  type: HIDE_MODAl,
});

export const showModal = data => ({
  type: SHOW_MODAl,
  data
});

export const updateFilterParams = (val, key) => ({
  type: UPDATE_FILTER_PARAMS,
  filterParams: {[key]: val}
})

export const resetFilterParams = () => ({
  type: RESET_FILTER_PARAMS
})

export const clearSingleSchedule = () => ({
  type: RESRE_SCHEDULE_PARAMS
})

const weekdays = [  "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
// export const getSchedule = (standard, date, teacher) => {
//   return (dispatch, getState) => {
//     const { offset, today, weekday } = getState().login;
//     const params = {
//       standardId: standard,
//       date,
//       teacherId: teacher,
//       offset,
//       today,
//       weekday
//     };
//     // dispatch(togglePostLoader(true));
//     dispatch(toggleFetchScheduleLoader(true));
//     return Api.get("/schedule")
//       .params(params)
//       .send((response, error) => {
//         dispatch(toggleFetchScheduleLoader(false));
//         // dispatch(togglePostLoader(false));
//         if (typeof response != "undefined") {
//           dispatch(
//             updateSchedule(
//               response.schedules,
//               response.standards,
//               response.subjects,
//               response.teachers
//             )
//           );
//         }
//       });
//   };
// };

export const getSchedule = () => {
  return (dispatch, getState) => {
    const params = getState().calendar.filterParams;
    // dispatch(togglePostLoader(true));
    dispatch(toggleFetchScheduleLoader(true));
    return Api.get("/schedule")
      .params(params)
      .send((response, error) => {
        dispatch(toggleFetchScheduleLoader(false));
        // dispatch(togglePostLoader(false));
        if (typeof response != "undefined") {
          dispatch(
            updateSchedule(
              response.schedules,
              response.standards,
              response.subjects,
              response.teachers, 
              response.modes
            )
          );
        }
      });
  };
};

export const toggleFetchScheduleLoader = bool => ({
  type: TOGGLE_FETCH_SCHEDULE_LOADER,
  bool
})

export const postSchedule = (params, otherParams) => {
  return (dispatch, getState) => {
    const { offset, today, weekday } = getState().login;
    dispatch(togglePostLoader(true));
    return Api.post("/schedule")
      .params({ ...params, offset, today, weekday })
      .send((response, error) => {
        dispatch(togglePostLoader(false));
        if (
          typeof response != "undefined" &&
          response.show &&
          response.show.type !== "error"
        ) {
          if(params.standardIds.length > 1){
            dispatch(updateFilterParams('-1', 'standardId'));
          }else{
            dispatch(updateFilterParams(params.standardIds[0], 'standardId'));
          }
          const date = moment(nextDay(params.weekdays[0])).format('YYYY-MM-DD');
          dispatch(updateFilterParams(date, 'date'));
          dispatch(getSchedule());
          // const data = {
          //   selectedClass: otherParams.class,
          //   selectedDate: otherParams.date,
          //   selectedClassNameSec: otherParams.section
          // };
          dispatch(hideModal());
          dispatch(handleInput(null, "startTime"));
          dispatch(handleInput(null, "endTime"));
          dispatch(handleInput("", "selectedTeacher"));
          dispatch(handleInput("", "selectedSubject"));
          dispatch(handleInput([], "selectedDays"));
          dispatch(handleInput([], "selectedClasses"));
        }
      });
  };
};

const nextDay = (day) => {
  const dayIndex = weekdays.indexOf(day);
  var now = new Date();    
  now.setDate(now.getDate() + (dayIndex+(7-now.getDay())) % 7);
  return now;
}

export const deleteSchedule = (item, otherParams) => {
  return dispatch => {
    return Api.delete(`/schedule/${item.id}`).send((response, error) => {
      if (typeof response != "undefined") {
        dispatch(getSchedule(otherParams.class, otherParams.date));
      }
    });
  };
};

export const searchUser = query => {
  return dispatch => {
    const params = {
      name: query,
      role: "Teacher"
    };
    dispatch(toggleSearchLoader(true));
    return Api.get("/user/autocomplete")
      .params(params)
      .send((response, error) => {
        dispatch(toggleSearchLoader(false));
        if (typeof response != "undefined") {
          if (!response.length) {
            dispatch(
              updateTeacherList([{ id: -1, value: "No teachers found", disabled: true }])
            );
          } else {
            dispatch(updateTeacherList(response));
          }
        }
      });
  };
};

export const endCall = query => {
  return dispatch => {
    const params = {
      roomName: query
    };
    dispatch(toggleSearchLoader(true));
    return Api.delete("/conference")
      .params(params)
      .send((response, error) => {
        dispatch(toggleSearchLoader(false));
        if (typeof response != "undefined") {
          if (!response.length) {
          } else {
          }
        }
      });
  };
};

export const updateTeacherList = teachers => ({
  type: UPDATE_TEACHER_LIST,
  teachers
});

export const updateSchedule = (schedules, standards, subjects, teachers, modes) => ({
  type: UPDATE_TIME_TABLE,
  schedules,
  standards,
  subjects,
  teachers, 
  modes
});

export const togglePostLoader = value => ({
  type: TOGGLE_POST_LOADER,
  value
});

export const toggleSearchLoader = value => ({
  type: TOGGLE_SEARCH_LOADER,
  value
});

export const handleInput = (value, key) => ({
  type: UPDATE_SCHEDULE_INPUT,
  singleSchedule: { [key]: value }
});

export const updateSubClasses = classes => ({
  type: UPDATE_SUB_CLASS,
  classes
});

export const updateSubClassesLoader = status => ({
  type: UPDATE_SUB_CLASS_LOADER,
  status
});

export const fetchClasslist = subId => {
  const url = `/standards/standardBySubject/${subId}`;
  return dispatch => {
    dispatch(updateSubClassesLoader(true));
    dispatch(updateSubClasses([]));
    return Api.get(url).send((response, error) => {
      if (typeof response != "undefined") {
        dispatch(updateSubClasses(response));
        dispatch(updateSubClassesLoader(false));
      }
    });
  };
};
