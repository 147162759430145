import React, { useState, useEffect } from 'react';
import { Pie } from '@ant-design/charts';

const PieChartCard=({
  activitiesData
}) => {
  var data = [
    {
      type: 'Scheduled',
      value: activitiesData?.scheduled,
    },
    {
      type: 'On-going',
       value: activitiesData?.ongoing,
    },
    {
      type: 'Completed',
      value: activitiesData?.completed,
    },
    {
      type: 'Cancelled',
      value: activitiesData?.cancelled,
    },
  ];
 
  var config = {
    appendPadding: 8,
    data: data,
     angleField: 'value',
    colorField: 'type',
    color: ({ type }) => {
      if(type === 'Scheduled'){
        return '#9463F9';
      }
      else if(type === 'On-going'){
        return '#FF414D';
      }
      else if(type === 'Completed'){
        return '#579EFF'
      }
      else if(type === 'Cancelled'){
        return '#AEAEAE'
      }
    },
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '',
      autoFit: true,
      legend: false,
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    // interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize:10
        },
        content: `Total live classes ${activitiesData?.scheduled+activitiesData?.ongoing+activitiesData?.completed+activitiesData?.cancelled}`,
      },
    },
  };

  return <Pie {...config} />;
};

export default PieChartCard;