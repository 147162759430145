import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NoData, newCardStyle } from "./courseInformationPage";
import DashboardOffersCard from './dashboardOffersCard';

function FeeContentComp({ specificCourseDataContainer, isStudentsExpandClicked, setIsStudentExpandClicked, setIsBatchesExpandClicked, isFeeClosed, setIsFeeClosed, setWasStudentCardExpanded, hasMarketingPrivilege, setIsOfferDrawerVisible, setSelectedOfferId  }) {
    const history = useHistory();
    const LiveCourseFeeContent = () => {
        const liveCourseFeeContentArray = [
            {
                label: "Registration fee",
                value: specificCourseDataContainer?.course?.registrationFee
            },
            {
                label: "Total fee",
                value: specificCourseDataContainer?.course?.amount
            },
            {
                label: "Fee after discount",
                value: specificCourseDataContainer?.course?.discountedCourseAmount
            },
        ]
        const defaultPlanItem = specificCourseDataContainer?.course?.courseBilling?.find(item => item?.defaultPlan === true);
        const { billingPlan, installments } = defaultPlanItem ?? { billingPlan: null, installments: null };
        const [paymentPlanExpand, setPaymentPlanExpand] = useState(false)

        return (
            <div style={{ width: "98%" }}>
                {liveCourseFeeContentArray?.map((item, index) => (
                    <div>
                        <div className='r-c-sb m-b-10' style={{ marginTop: index == 0 ? 0 : 10 }}>
                            <div style={{ color: "#636363", fontWeight: 600 }}>{item?.label}</div>
                            <div style={{ color: "#636363", fontWeight: 600 }}><span style={{ color: "#191919" }}>₹ </span>{item?.value}{index == 2 ? <span style={{ color: "#2EA083" }}> ({specificCourseDataContainer?.course?.discountPercent}%)</span> : <></>}</div>
                        </div>
                        <div style={{ borderBottom: "1px solid #E6E6E6" }} />
                    </div>
                ))}
                {specificCourseDataContainer?.course?.courseBilling?.length ?
                    <>
                        <div className='m-t-20 m-b-10' style={{ color: "#636363", fontWeight: "bold", fontSize: 16 }}>Fee payment plan</div>
                        {defaultPlanItem ?
                            <>
                                <div className='r-c-sb'>
                                    <div className='display-flex'>
                                        <div style={{ color: "#191919", fontWeight: 600 }}> {billingPlan}</div>
                                        <div className='m-l-10 r-c-c-c' style={{ backgroundColor: "#E7F3FF", color: "#1089FF", fontSize: 10, fontWeight: "bold", borderRadius: 50, padding: "2px 10px", width: "fit-content" }}>DEFAULT PLAN</div>
                                    </div>
                                    {specificCourseDataContainer?.course?.offers?.length > 0 && specificCourseDataContainer?.course?.offers?.filter(item => item.published)?.length ?
                                        <>
                                            {paymentPlanExpand ?
                                                <FiChevronUp style={{ fontSize: 20, color: "#636363", cursor: "pointer" }} onClick={() => { setPaymentPlanExpand(!paymentPlanExpand) }} />
                                                :
                                                <FiChevronDown style={{ fontSize: 20, color: "#636363", cursor: "pointer" }} onClick={() => { setPaymentPlanExpand(!paymentPlanExpand) }} />
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                                {specificCourseDataContainer?.course?.offers?.length > 0 && specificCourseDataContainer?.course?.offers?.filter(item => item.published)?.length ?
                                    <>
                                        {paymentPlanExpand &&
                                            <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                                                {installments?.map((item, index) => (
                                                    <div className='m-t-10' style={{ width: "50%" }}>
                                                        <div style={{ fontSize: 12, color: "#8C8C8C", fontFamily: "roboto" }}>Installment {index + 1} </div>
                                                        <div style={{ fontSize: 14, color: "#191919", fontFamily: "roboto" }}>₹ {item?.amount}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                        {/* && paymentPlanExpand removed in below line as testers said */}
                                        {specificCourseDataContainer?.course?.courseBilling?.length - 1 > 0   || !defaultPlanItem ? <div className='m-t-10' style={{ color: "#594099", fontSize: 12, fontWeight: "bold", cursor:'pointer' }} onClick={() => { history.push('/fee-details') }}>{specificCourseDataContainer?.course?.courseBilling?.length - 1} more fee payment {specificCourseDataContainer?.course?.courseBilling?.length - 1 == 1 ? "plan" : "plans"} available</div> : <></>}
                                    </>
                                    : <>
                                        <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                                            {installments?.map((item, index) => (
                                                <div className='m-t-10' style={{ width: "50%" }}>
                                                    <div style={{ fontSize: 12, color: "#8C8C8C", fontFamily: "roboto" }}>Installment {index + 1} </div>
                                                    <div style={{ fontSize: 14, color: "#191919", fontFamily: "roboto" }}>₹ {item?.amount}</div>
                                                </div>
                                            ))}
                                        </div>
                                        {specificCourseDataContainer?.course?.courseBilling?.length - 1 > 0 ? <div className='m-t-10' style={{ color: "#594099", fontSize: 12, fontWeight: "bold", cursor:'pointer' }} onClick={() => { history.push('/fee-details') }}>{specificCourseDataContainer?.course?.courseBilling?.length - 1} more fee payment {specificCourseDataContainer?.course?.courseBilling?.length - 1 == 1 ? "plan" : "plans"} available</div> : <></>}
                                    </>}
                            </>
                            :
                            <div></div>
                        }
                    </>
                    :
                    <></>}
                {specificCourseDataContainer?.course?.offers?.length > 0 ? <>
                        <div className='m-t-20 m-b-10' style={{ color: "#636363", fontWeight: "bold", fontSize: 16 }}>Promotional offers</div>
                        <div className='scroll-bar-universal' style={{height:"30%"}}>
                        {specificCourseDataContainer?.course?.offers?.slice(0,3)?.map((item, index) => (
                            <div key={item.id}>
                                <DashboardOffersCard courseTypeName='liveCourses' item={item} setIsOfferDrawerVisible={setIsOfferDrawerVisible} hasMarketingPrivilege={hasMarketingPrivilege} setSelectedOfferId={setSelectedOfferId} />
                            </div>
                        ))}
                        </div>
                        </>
                    :
                    <></>}
            </div>
        )
    }

    return (
        <div className={isStudentsExpandClicked || isFeeClosed ? 76.5 : "fee-details-expand-height"} style={{ ...newCardStyle, /* height: isStudentsExpandClicked || isFeeClosed ? 76.5 : 518, */ transition: isStudentsExpandClicked ? "" : "height 0.2s ease" }}>
            <div className='r-c-sb'>
                <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>Fee details</div>
                {isStudentsExpandClicked || isFeeClosed ?
                    <FiChevronDown style={{ fontSize: 22, color: "#636363", cursor: "pointer" }} onClick={() => {
                        setIsStudentExpandClicked(false);
                        setIsBatchesExpandClicked(false);
                        setIsFeeClosed(false);
                        setWasStudentCardExpanded(false)
                    }} />
                    :
                    <>
                        {hasMarketingPrivilege ? <></> : specificCourseDataContainer?.course?.amount ?
                            <div className='cursor-pointer' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold" }} onClick={() => { history.push('/fee-details') }}><EditOutlined /> EDIT FEE</div>
                            :
                            <></>}
                    </>}
            </div>
            {!(isStudentsExpandClicked || isFeeClosed) ?
                <>
                    {specificCourseDataContainer?.course?.amount ?
                        <div className='scroll-bar-universal fee-details-expand-card-height' style={{ /* height: 431.5, */ marginTop: 10 }}>
                            <LiveCourseFeeContent />
                        </div>
                        :
                        <div className='r-c-c m-t-100' style={{ flexDirection: "column" }}>
                            <img className='no-data-img-width' src={NoData} alt='' />
                            <div className='m-t-10' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>No data available!</div>
                            <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>There is no data to show you right now</center>

                            {hasMarketingPrivilege ?
                                <></>
                                :
                                <div className='cursor-pointer m-t-20' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", marginBottom: 110 }} onClick={() => {
                                    history.push('/fee-details')
                                }
                                }><PlusCircleOutlined /> ADD FEE DETAILS</div>
                            }

                        </div>
                    }
                </>
                :
                <></>}
        </div>
    )
}

export default FeeContentComp