import React, { useEffect, useState } from 'react'
import TopBar from './topBar'
import Footer from './footer'
import "./testSeries.css";
import "./styles.css";
import { connect } from 'react-redux';
import { Button, Collapse, Input, Modal, Rate } from 'antd';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import saveOutlined from "../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import saveFilled from "../../Assets/aulasMarket/saveFilledIcon.svg"
import starOutline from "../../Assets/aulasMarket/ic-star-outLine.svg"
import startFilled from "../../Assets/aulasMarket/ic-star-fill.svg"
import { TestSeriesApi, setRenderTest, setTestSeriesData } from 'actions/aulasMarket';
import chemistry from "../../Assets/aulasMarket/sbj-chemistry.svg";
import physics from "../../Assets/aulasMarket/sbj-physics.svg";
import computer from "../../Assets/aulasMarket/sbj-computerscience.svg";
import CourseList from './courseList';
import TestModal from './testModal';
import { setLoader } from 'actions/testAdmin';
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';
import { Api } from 'services';

const { Panel } = Collapse;
const { TextArea } = Input;


export const MyCourseList = ({ }) => {
    return (
        <div className='parent-compo'>
            <div className='' style={{}}>
                <CourseList test={true} />
            </div>
        </div>
    )
}


function TestSeriesProfile({ testSeriesData, setTestSeriesData, test, setRenderTest, renderTest, loginDetails }) {
    const token = loginDetails?.accessToken;
    const [isShareModalOpen, setShareModalOpen] = useState(false);
    const [addRating, setAddRating] = useState(false);
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [shareUrl, setShareUrl] = useState(null);
    const [save, setSave] = useState(false);
    const [testModal, setTestModal] = useState(false);


    const fetchTestApi = () => {
        TestSeriesApi(token, 1, (res) => {
            setTestSeriesData(res);
        }, setLoader)
    }

    useEffect(() => {
        setRenderTest(0);
        fetchTestApi();
    }, [])

    console.log("testssss", testSeriesData)

    const handleRatingOk = () => {
        setAddRating(false);
    };
    const handleRatingCancel = () => {
        setAddRating(false);
    };

    const testData = [
        {
            title: "125 tests",
            description: "JEE Mains - 2023 test series",
            rating: 4,
            totalRatings: 215,
            tokenFree: true,
        },
        {
            title: "125 tests",
            description: "National Eligibility cum Entrance Test",
            rating: 4,
            totalRatings: 215,
            tokenFree: true,
        },
        {
            title: "125 tests",
            description: "NEET Test series",
            rating: 4,
            totalRatings: 215,
            tokenFree: false,
        },
        {
            title: "125 tests",
            description: "NEET Test series",
            rating: 4,
            totalRatings: 215,
            tokenFree: true,
        },
        {
            title: "125 tests",
            description: "National Eligibility cum Entrance Test (NEET) Test series",
            rating: 4,
            totalRatings: 215,
            tokenFree: false,
        },
        {
            title: "125 tests",
            description: "National Eligibility cum Entrance Test (NEET) Test series",
            rating: 4,
            totalRatings: 215,
            tokenFree: true,
        },
        {
            title: "125 tests",
            description: "JEE Mains - 2023 test series",
            rating: 4,
            totalRatings: 215,
            tokenFree: false,
        },
        {
            title: "125 tests",
            description: "JEE Mains - 2023 test series",
            rating: 4,
            totalRatings: 215,
            tokenFree: true,
        },
        {
            title: "125 tests",
            description: "National Eligibility cum Entrance Test (NEET) Test series",
            rating: 4,
            totalRatings: 215,
            tokenFree: true,
        },
        {
            title: "125 tests",
            description: "JEE Mains - 2023 test series",
            rating: 4,
            totalRatings: 215,
            tokenFree: false,
        },
        {
            title: "125 tests",
            description: "JEE Mains - 2023 test series",
            rating: 4,
            totalRatings: 215,
            tokenFree: true,
        },
    ]
    console.log("tteesstt", testSeriesData)
    const [showAll, setShowAll] = useState(false);
    const [viewAll, setViewAll] = useState(false);
    const [showRatings, setShowRatings] = useState(false);
    const itemsToShow = viewAll ? testSeriesData?.testData?.length : 6;
    const itemsToShowCard = showAll ? testData.length : 8;
    const itemsToShowCourse = showRatings ? testSeriesData?.latestReviews?.length : 6;

    const testProfile = testSeriesData?.testSeriesDetails;


    const StarRatingNew = () => {
        const [rating, setRating] = useState(0);

        const handleStarClick = (clickedRating) => {
            setRating(clickedRating);
        };

        return (
            <div className='star-rating-new-parent' style={{}}>
                <div className="star-rating-new" style={{}}>
                    <Rate allowHalf className='r-c-c' style={{ maxWidth: "100%", fontSize: "50px", color: "#FFA931" }} />
                </div>
            </div>
        );
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };
    const toggleViewAll = () => {
        setViewAll(!viewAll);
    };
    const toggleRatting = () => {
        setShowRatings(!showRatings);
    };
    const subjects = [
        {
            name: "chemistry",
            des: "24 ChapterList",
            courseChapter: [
                {
                    chapter: "the solid state",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
                {
                    chapter: "the solid state",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
            ]
        },
        {
            name: "physics",
            des: "24 ChapterList",
            courseChapter: [
                {
                    chapter: "the solid state",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
                {
                    chapter: "the solid state",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
            ]
        },
        {
            name: "maths",
            des: "24 ChapterList",
            courseChapter: [
                {
                    chapter: "the solid state",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
                {
                    chapter: "the solid state",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
            ]
        },
        {
            name: "computer",
            des: "24 ChapterList",
            courseChapter: [
                {
                    chapter: "the solid state",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
                {
                    chapter: "the solid state",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
            ]
        },
        {
            name: "English",
            des: "24 ChapterList",
            courseChapter: [
                {
                    chapter: "the solid state",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
                {
                    chapter: "the solid state",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
            ]
        },
        {
            name: "kannada",
            des: "24 ChapterList",
            courseChapter: [
                {
                    chapter: "the solid state",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
                {
                    chapter: "the solid state",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
                {
                    chapter: "solutions",
                },
                {
                    chapter: "ElectroChemistry",
                },
            ]
        },
    ]

    const MockCards = ({ data }) => {
        console.log("daaaa", data);
        return (
            <div className='test-card-container'>
                <div className='mock-card-title'>{data?.title}</div>
                <div className='mock-card-list1' >
                    <div>
                        <img src={require("../../Assets/aulasMarket/questionMark.png").default} />
                    </div>
                    <div className='list1-text'>
                        {data?.totalQuestions} Questions
                    </div>
                </div>
                <div className='mock-card-list2'>
                    <div>
                        <img src={require("../../Assets/aulasMarket/score.png").default} />
                    </div>
                    <div className='list2-text'>
                        {data?.totalMarks} Marks
                    </div>
                </div>
                <div className='mock-card-last-parent'>
                    <div className='mock-card-list3'>
                        <div className='timer'>
                            <img src={require("../../Assets/aulasMarket/ic-timer.svg").default} />
                        </div>
                        <div className='list3-text'>
                            {data?.duration} Minutes
                        </div>
                    </div>
                    <div className='mock-attempt'>
                        {data?.freePreview ?
                            <Button className='attemptBtn' onClick={() => { setTestModal(true) }} >ATTEMPT</Button>
                            : <div className='attemptStatus'>Available on 10th Oct 2023 at 10:00 AM</div>}
                    </div>
                </div>
            </div>
        )
    }

    const PanelContent = ({ content }) => {
        console.log("iiii", content)
        return (
            <div className='r-c-sb text-sm'>
                <div className='panel-column'>
                    <div>{content?.unit}</div>
                    <div>{content?.chapter1}</div>
                    <div>{content?.chapter2}</div>
                    <div>{content?.chapter3}</div>
                    <div>{content?.chapter4}</div>
                    <div>{content?.chapter5}</div>
                    <div>{content?.chapter6}</div>
                    <div>{content?.chapter7}</div>
                    <div className='m-t-20'>{content?.unit1}</div>
                    <div>{content?.chapter1}</div>
                    <div>{content?.chapter2}</div>
                    <div>{content?.chapter3}</div>
                    <div>{content?.chapter4}</div>
                    <div>{content?.chapter5}</div>
                    <div>{content?.chapter6}</div>
                    <div>{content?.chapter7}</div>
                </div>

            </div>
        )
    }

    const ExamTable = () => {
        return (
            <div className=''>
                <table className='beta table' style={{ border: "1px solid", backgroundColor: "#FFFFFF" }}>
                    <tbody>
                        <tr>
                            <td className='beta td1' style={{ fontWeight: "bold" }}>Exam Mode</td>
                            <td className=''>Online</td>
                        </tr>
                        <tr>
                            <td className='td1' style={{ fontWeight: "bold" }}>Subjects</td>
                            <td className='td2'>Description 2</td>
                        </tr>
                        <tr>
                            <td className='td1' style={{ fontWeight: "bold" }}>Type of Questions</td>
                            <td className='td2'>Description 3</td>
                        </tr>
                        <tr>
                            <td className='td1' style={{ fontWeight: "bold" }}>Duration</td>
                            <td className='td2'>Description 4</td>
                        </tr>
                        <tr>
                            <td className='td1' style={{ fontWeight: "bold" }}>Medium</td>
                            <td className='td2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                        </tr>
                        <tr>
                            <td className='td1' style={{ fontWeight: "bold" }}>Total Marks</td>
                            <td className='td2'>600</td>
                        </tr>
                        <tr>
                            <td className='td1' style={{ fontWeight: "bold" }}>Marking Scheme</td>
                            <td className='td2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    const StudySeries = ({ testData, id, flag }) => {
        console.log("itttt", testData)

        return (
            <div className='card-design-parent' style={{}}>
                <div className='cd-p1' style={{}} onClick={() => setTestSeriesData(testData)}>
                    <Link to="/test-series">
                        <div className='card-design' style={{}}>
                            {testData?.tokenFree ? (
                                <div className='free-card font-bold r-c-c ' style={{}}>FREE</div>
                            ) : <div className=''></div>}
                            <div className='card-content-free' style={{}}>

                                <div className='card-img' style={{}}>
                                    <img style={{}} src={require("../../Assets/aulasMarket/onlineTestIcon.svg").default} />
                                </div>

                                <div className='m-l-10'>
                                    {testData?.title}
                                </div>

                            </div>
                            {!testData?.tokenFree ? (
                                <div className='cost-card' style={{}}>₹ 10,000</div>
                            ) : <div className=''></div>
                            }

                        </div>
                    </Link>
                </div>

                <div className='popular-subTitle'>
                    <div className='popular-description' style={{}}>{testData?.description}</div>

                    <div className='r-c-fs'>
                        <div className=' ratings font-bold' style={{}}>{testData?.rating}</div>
                        <div style={{ margin: "-8px 5px 0px 5px" }}><Rate disabled allowHalf defaultValue={4} style={{ color: "#FFA931" }} /></div>
                        <div className='totalRatings' style={{}}>({testData?.totalRatings})</div>
                    </div>
                </div>

            </div>
        )
    }

    const DateDisplay = ({ date }) => {
        // Parse the backend date string into a JavaScript Date object
        const backendDate = new Date(date);

        // Calculate the time difference in milliseconds
        const timeDifference = Date.now() - backendDate.getTime();

        // Calculate the number of days
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

        // Calculate the number of hours
        const hoursDifference = Math.floor(timeDifference / (1000 * 3600));

        // Calculate the number of minutes
        const minutesDifference = Math.floor(timeDifference / (1000 * 60));

        // Display different formats based on elapsed time
        if (daysDifference > 0) {
            return <div>{daysDifference} {daysDifference === 1 ? 'day' : 'days'} ago</div>;
        } else if (hoursDifference > 0) {
            return <div>{hoursDifference} {hoursDifference === 1 ? 'hour' : 'hours'} ago</div>;
        } else if (minutesDifference > 0) {
            return <div>{minutesDifference} {minutesDifference === 1 ? 'minute' : 'minutes'} ago</div>;
        } else {
            return <div>Now</div>;
        }
    };
    const CourseRating = ({ course }) => {
        console.log("reeee", course)
        return (
            <div className='courseRating'>
                <div className='r-c-fs m-t-20'>
                    <div>
                        <img style={{ width: 50 }} src={require("../../Assets/aulasMarket/ratingIcon.png").default} />
                    </div>
                    <div className='m-l-20' >
                        <div className='text-sm font-bold'>{course?.user?.name}</div>
                        <div className='r-c-fs' >
                            <div className='r-c-sb'><Rate disabled allowHalf defaultValue={course?.rating} style={{ color: "#FFA931" }} />
                                <div className='m-l-10 text-xmd' style={{ color: "#AEAEAE" }}><DateDisplay date={course?.updatedAt} /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-xmd bold-600' style={{ width: "350px" }}>"{course?.review}"</div>
            </div>
        )
    }

    const ChapterList = ({ data }) => {
        console.log("fffff", data?.subject)
        return (
            <div className='' style={{}}>
                {data?.subject?.testSeriesChapter?.slice(0, 6).map((item, index) => (
                    <div className='r-c-fs' key={index} style={{ margin: "10px" }}>
                        <div className='font-bold text-xmd' style={{ color: "#636363" }}>
                            Chapter {index + 1}
                        </div>
                        <div className='font-bold text-xmd m-l-15'>
                            {item?.name}
                        </div>
                    </div>
                ))}
            </div>
        )
    }
    const ChapterContent = ({ data }) => {
        console.log("ddddddd", data?.subject)
        return (
            <>
                <div className={test ? "chapterContent-1" : 'chapterContent'}>
                    <div className='chapter-title r-c-fs' style={{}}>
                        <img src={data?.subject?.name === "physics" ? physics : data?.subject?.name === "Science" ? physics : computer} />
                        <div className='m-l-10 font-bold text-sm ' style={{ color: "" }}>
                            {data?.subject?.name}
                            <div className='text-xmd font-medium' style={{ color: "#636363" }}>
                                {data?.des}
                            </div>
                        </div>
                    </div>
                    <div className='border'></div>
                    <div style={{}}>

                        <ChapterList data={data} />


                    </div>

                    {data?.subject?.testSeriesChapter?.length <= 6 ? null : (
                        <div className='r-c-c font-bold' style={{ color: "#1089FF", cursor: "pointer" }}>
                            {test ? (
                                <span onClick={() => { setRenderTest(1); console.log("viewall") }}>
                                    VIEW ALL
                                </span>
                            ) : (
                                <Link to="/course-list">VIEW ALL</Link>
                            )}
                        </div>
                    )}




                </div>
            </>
        )
    }



    return (
        <>
            {renderTest === 0 ?
                <>
                    {test ? null :
                        <div>
                            <TopBar />
                        </div>
                    }
                    <div style={{ backgroundColor: "#FAFAFA" }}>
                        <div className='test-parent'>
                            <div className={test ? null : "test-series-container"} style={{}}>

                                {test ?
                                    <>
                                        <div className='r-c-sb'>
                                            <div style={{}}>
                                                <span className='test-title' >
                                                    {testProfile?.name}
                                                </span>
                                                <div style={{ fontSize: "18px", fontWeight: "bold" }}>{testProfile?.description}</div>
                                                <div className='r-c-fs'>
                                                    <div>rating</div>
                                                    <div style={{ fontSize: "14px", fontWeight: "bold", color: "#1089FF" }}>Rate this</div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='test-title'>
                                            {testProfile?.name}
                                        </div>
                                        <div className='test-des' >
                                            {testProfile?.description}
                                        </div>
                                    </>
                                }


                                {test ? null :
                                    <div>
                                        <div className='test-options'>
                                            <div className='opt-1'>
                                                <div className='sellerButton'>Bestseller</div>
                                                <div className='test-ratings'><div className='ratings' style={{ color: "#FFA931" }}>{testProfile?.averageRating}</div><Rate allowHalf disabled defaultValue={testProfile?.averageRating} style={{ color: "#FFA931", marginLeft: "10px" }} /> <div className='test-total-ratings'>{testProfile?.totalReviews}</div></div>
                                            </div>
                                            <div className='opt-2'>
                                                <div className='add-ratings' onClick={() => setAddRating(true)}>Add a rating</div>
                                                <div className='test-share' onClick={() => {
                                                    setShareModalVisible(true);
                                                    setShareUrl(`${Api?._base_url}/course-page?id=${testProfile?.id}&type=${"tests"}`)
                                                }}><img src={require("../../Assets/aulasMarket/shareIcon.svg").default} />Share</div>
                                                <div className='test-save' onClick={() => setSave(!save)} ><img src={save ? saveFilled : saveOutlined} />Save for later</div>
                                            </div>
                                        </div>
                                        <div className='test-cost'>₹{testProfile?.amount}</div>
                                        <div className='test-btns-parent' style={{}} >
                                            <Button className='content-add' style={{}}>ADD TO CART</Button>
                                            <Button className='content-reg ' style={{}}>BUY NOW</Button>
                                        </div>
                                        <div className='test-description' >Description</div>
                                        <div className='test-des-text'>{testProfile?.description}
                                        </div>
                                    </div>
                                }

                                <div className='mock-test'>
                                    <div style={{}}>Test</div>
                                    <div style={{ color: "#1089FF", fontWeight: 600 }} >Download test-wise syllabus</div>
                                </div>
                                <div className='mock-card-container'>
                                    {testSeriesData?.testData?.slice(0, itemsToShow).map((item, index) => (
                                        <div key={index}>
                                            <MockCards data={item} />
                                        </div>
                                    ))}
                                </div>
                                {testSeriesData?.testData?.length > 6 && (
                                    <div className='test-viewAll' onClick={toggleViewAll}>
                                        {viewAll ? 'View Less' : 'View More'}
                                    </div>
                                )}
                                <div className='syllabus-header'>Syllabus</div>

                                <div className='syllabus-Collapse'>
                                    <div className={test ? "chapter-parent-2" : 'chapter-parent'}>
                                        {testProfile?.subjectTestSeries?.map((item) => (
                                            <ChapterContent data={item} />
                                        ))}
                                    </div>
                                </div>
                                <div className='syllabus-header' >Exam Pattern</div>

                                <div className='m-t-20'>
                                    <ExamTable />
                                </div>

                                <div className='related-series' style={{ color: "#191919" }}>Related Test Series (542)<img style={{ marginTop: "-2px", marginLeft: "10px" }} src={require("../../Assets/aulasMarket/infoIcon.svg").default} /></div>

                                <div className="related-series-parent" style={{ color: "#191919" }}>

                                    {testData.slice(0, itemsToShowCard).map((item, index) => (
                                        <div key={index}>
                                            <StudySeries testData={item} key={index} />
                                        </div>
                                    ))}
                                </div>
                                {testData.length > 8 && (
                                    <div className='test-showAll' onClick={toggleShowAll}>
                                        {showAll ? 'Show Less' : 'Show More'}
                                    </div>
                                )}

                                {test ? null :
                                    <>
                                        <div className='course-rating-header' style={{}}><span><img src={require("../../Assets/aulasMarket/ic-star-fill.svg").default} />4.7 course rating</span>&nbsp;&nbsp;<li>221 ratings</li> </div>
                                        <div className='course-related-container'>
                                            {testSeriesData?.latestReviews?.slice(0, itemsToShowCourse).map((item, index) => (
                                                <div key={index}>
                                                    <CourseRating course={item} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className='test-showAll' style={{ color: "#636363", cursor: "pointer" }} >
                                            {testSeriesData?.latestReviews?.length > 6 && (
                                                <div onClick={toggleRatting}>
                                                    {showRatings ? 'Show Less' : 'Show More'}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                        {test ? null :
                            <div className='m-t-100' style={{}}>
                                <Footer />
                            </div>
                        }
                    </div>
                    {/* share Modal  */}
                    {isShareModalVisible &&
                        <ShareCourseModal
                            isShareModalVisible={isShareModalVisible}
                            setShareModalVisible={setShareModalVisible}
                            shareUrl={shareUrl} />}

                    {/* Add rating Modal */}
                    <Modal open={addRating} footer={null} className='modal-round-corner' onOk={handleRatingOk} onCancel={handleRatingCancel} bodyStyle={{ borderRadius: "8px", height: "50%" }}>
                        <div className='r-c-c text-md font-bold' style={{}}>Add a rating</div>
                        <div><StarRatingNew /></div>
                        <div className='m-t-10'><TextArea rows={4} style={{ resize: 'none', borderRadius: "8px" }} placeholder='Add review' /></div>
                        <div className='r-c-c m-t-20'><Button className='submitBtn'>SUBMIT</Button></div>
                    </Modal>
                </>
                :
                (renderTest === 1 ? <MyCourseList setRenderTest={setRenderTest} renderTest={renderTest} /> : null)
            }
            <TestModal
                visible={testModal}
                onConfirm={() => setTestModal(false)}
                onCancel={() => setTestModal(false)}

            />
        </>

    )
}


const mapStateToProps = (state) => {
    const { testSeriesData, renderTest, loginDetails } = state.aulasMarket;
    console.log("pppp", state.aulasMarket);
    return {
        testSeriesData, renderTest, loginDetails
    }
};

const mapDispatchToProps = (dispatch) => ({
    setTestSeriesData: (val) => dispatch(setTestSeriesData(val)),
    setRenderTest: (val) => dispatch(setRenderTest(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestSeriesProfile);
