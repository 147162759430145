import React from 'react'
import { Modal } from 'antd';
import logo from '../components/assets/successicon.svg'
import { connect } from 'react-redux'
import { currentFileToShow, fetchClassLibraryData, fetchDashBoardData, IsHSL, setUploadContent, updateDocumentInfo, updatepdf } from 'actions/digitalLibrary';
import { removemodel } from 'actions/digitalLibrary';
import { useHistory } from 'react-router-dom';
import { fetchLibraryData, setEditObject } from "../../../actions/digitalLibrary"
function Succesfulmodel({ Pdftoupload, updatepdf,
  removemodel, uploadsuccessful, fetchLibraryData, togglePageLoader, fetchApi, setEditObject, postId, uploadContent, setUploadContent,
  IsHSL, pageNumber, fetchClassLibraryData, fetchDashBoardData, videoType,currentFileToShow,updateDocumentInfo
}) {
  const history = useHistory();
 console.log("videoType",uploadContent);
  const publishevent = () => {
    updateDocumentInfo(uploadContent)
    updatepdf(null)
    setEditObject({
      id: postId,
      updateDetails: uploadContent
    })
    IsHSL(false)
    if(videoType=='videoLink'){
      currentFileToShow('link')
    }else if(videoType == ""){
      currentFileToShow("pdf");

    }
     else {
      currentFileToShow("video");
    }
    history.push("/digital-library/view-content")
    removemodel()
  }

  const handleCancel = () => {
    updatepdf(null)
    setUploadContent({})
    setEditObject({})
    if (fetchApi && pageNumber == 3) {
      togglePageLoader(true)
      fetchLibraryData('', () => togglePageLoader(false));
    }
    else if (pageNumber == 2) {
      togglePageLoader(true)
      fetchClassLibraryData(() => togglePageLoader(false))
    }
    else if (pageNumber == 1) {
      togglePageLoader(true)
      fetchDashBoardData(() => togglePageLoader(false))
    }
    removemodel()
  };

  return (
    <Modal className='rounded-border' visible={uploadsuccessful} footer={null} onCancel={handleCancel} maskClosable={false}>
      <div className='successmain'>
        <img id='successmodelimg' src={logo}></img>
        <p id='successmodeltitle' style={{ margin: '0px', marginTop: '2%' }}>Successfully published!</p>
        <p className='lecturnotesdescription' style={{ margin: '0px', color: '#636363' }}>Your uploaded content has been successfully published to the class</p>
        {videoType != 'videoFile' ?
          <button className='modelbutton' style={{cursor:"pointer"}} onClick={publishevent}>SEE IT NOW</button>
          : null}
      </div>
    </Modal>
  )
}
const mapStateToProps = (state) => {
  const {
    Pdftoupload,
    uploadsuccessful,
    postId, uploadContent,
    pageNumber

  } = state.digitalLibrary;
  return {
    Pdftoupload,
    uploadsuccessful,
    postId, uploadContent,
    pageNumber

  };
};

const mapDispatchToProps = (dispatch) => ({
  updatepdf: (data) => dispatch(updatepdf(data)),
  removemodel: () => dispatch(removemodel()),
  fetchLibraryData: (search, callback) => dispatch(fetchLibraryData(search, callback)),
  setEditObject: (object) => dispatch(setEditObject(object)),
  setUploadContent: (object) => dispatch(setUploadContent(object)),
  IsHSL: (bool) => dispatch(IsHSL(bool)),
  fetchClassLibraryData: (callback) => dispatch(fetchClassLibraryData(callback)),
  fetchDashBoardData: (callback) => dispatch(fetchDashBoardData(callback)),
  currentFileToShow: (data) => dispatch(currentFileToShow(data)),
  updateDocumentInfo: (data) => dispatch(updateDocumentInfo(data)),


});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(Succesfulmodel);
