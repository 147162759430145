import { EditOutlined, FilePdfOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Checkbox, Input, Typography, message } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { Button } from 'components/Button';
import PageHeader from 'components/pageHeader';
import React, { useEffect, useState } from 'react';
import video from '../../Assets/course/Video.png'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { inputHeadingStyle } from './addCoursePage';
import { PDFDocument } from 'pdf-lib';
import quiz from '../../Assets/aulasMarket/ic-practice-quiz.svg'
import { MergeChunk, deleteCourseDocument, deleteQuiz, fileKeyUpdate, getEditQuizData, postCreateSMCourseContent, postCreateVideoResourceContent, storeQuizData, updatepdf, uploadBlock2 } from 'actions/courseManagement';
import { v4 as uuidv4 } from "uuid";

const blockSize = 1048576 * 20;

function formatFileSize(sizeInBytes) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;
    if (sizeInBytes >= gigabyte) {
        return (sizeInBytes / gigabyte).toFixed(2) + ' GB';
    } else if (sizeInBytes >= megabyte) {
        return (sizeInBytes / megabyte).toFixed(2) + ' MB';
    } else if (sizeInBytes >= kilobyte) {
        return (sizeInBytes / kilobyte).toFixed(2) + ' KB';
    } else {
        return sizeInBytes + ' bytes';
    }
}

const AddCourseContent = ({ courseTypeName, postCreateSMCourseContent, deleteQuiz,
    uploadBlock2, MergeChunk, Pdftoupload, fileKey, fileKeyUpdate, updatepdf, postCreateVideoResourceContent, deleteCourseDocument, getEditQuizData, storeQuizData,newCourseId
}) => {
    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
console.log("newCourseId",newCourseId);
    const history = useHistory();
    const location = useLocation();
    console.log("location", location);
    const [updateBtnLoader, setUpdateBtnLoader] = useState(false)
    const [youtubeLink, setYouTubeLink] = useState('')
    const [videoFile, setVideoFile] = useState([])
    const [increase, setIncrease] = useState(0);
    const [progress, setProgress] = useState(0);
    const [sections, setSections] = useState(
        [{
            name: '',
            topics: [{
                name: '',
                preview: false,
                fileIndex: 0,
                videoType: null,//if(type == video){video || recorded || link } not mandatory
                type: null,//value == video || notes || quiz
                quizId: null,
                fileUrl: null,
                duration: null

            }]
        }]
    )

    const [studyMaterialsTopics, setStudyMaterialsTopics] = useState([
        {
            name: "",
            file: null,
            fileIndex: 0,
            preview: false,
            type: "notes",
            quizId: null
        }
    ]);
    const [SMfilesArray, setSMfilesArray] = useState([]);
    const [SMfileIndex, setSMfileIndex] = useState(0);
    console.log("AA-sections123", sections);
    let uploadingMessage;

    useEffect(() => {
        fileKeyUpdate('')
    }, [])

    useEffect(() => {
        if (location?.state?.fromBrodcastVR?.fromAddBroadcastPage) {

            setSections(location?.state?.fromBrodcastVR?.sections)
        }
    }, [location?.state?.fromBrodcastVR?.fromAddBroadcastPage])
    const handleRemoveSMTopicField = (topicIndex, fromQuiz) => {
        if (fromQuiz) {
            const updatedStudyMaterialsTopics = [...studyMaterialsTopics];
            updatedStudyMaterialsTopics[topicIndex].type = null
            deleteQuiz(updatedStudyMaterialsTopics[topicIndex]?.quizId)
            updatedStudyMaterialsTopics[topicIndex].quizId = null
            updatedStudyMaterialsTopics[topicIndex].name = null
            updatedStudyMaterialsTopics[topicIndex].preview = null
            updatedStudyMaterialsTopics[topicIndex].pageCount = null
            updatedStudyMaterialsTopics[topicIndex].id = null
            updatedStudyMaterialsTopics[topicIndex].file = null
            updatedStudyMaterialsTopics[topicIndex].fileIndex = null
            updatedStudyMaterialsTopics[topicIndex].fileUrl = null
            // delete updatedStudyMaterialsTopics[topicIndex]
            setStudyMaterialsTopics(updatedStudyMaterialsTopics);
        } else {
            const updatedStudyMaterialsTopics = [...studyMaterialsTopics];
            updatedStudyMaterialsTopics[topicIndex].file = null;
            delete updatedStudyMaterialsTopics[topicIndex].fileUrl;
            updatedStudyMaterialsTopics[topicIndex].pageCount = 0;
            setStudyMaterialsTopics(updatedStudyMaterialsTopics);
            const updatedSMfilesArray = [...SMfilesArray];
            updatedSMfilesArray[topicIndex] = null;
            setSMfilesArray(updatedSMfilesArray);
        }
    };
    const handleStudyMaterialUpload = (info, topicIndex) => {
        const isLt10MB = info.file.originFileObj.size / 1024 / 1024 < 10;
        if (!isLt10MB && info.file.originFileObj.type == "application/pdf") {
            Notification.error('error', "File must be smaller than 10MB!");
        } else if (info.file.originFileObj.type != "application/pdf") {
            Notification.error('error', "File extension not supported");
        } else {
            info.file.originFileObj?.arrayBuffer().then((data) => {
                PDFDocument.load(data).then((doc) => {
                    const pageCount = doc.getPageCount();
                    const updatedStudyMaterialFile = [...studyMaterialsTopics];
                    updatedStudyMaterialFile[topicIndex].pageCount = pageCount
                    setStudyMaterialsTopics(updatedStudyMaterialFile);
                }).catch((err) => {
                });
            })
            const updatedSMfilesArray = [...SMfilesArray];
            if (!updatedSMfilesArray[topicIndex]) {
                updatedSMfilesArray[topicIndex] = [];
            }
            updatedSMfilesArray[topicIndex].push(info.file.originFileObj);
            setSMfilesArray(updatedSMfilesArray);
            setSMfileIndex(SMfileIndex + 1)
            const updatedStudyMaterialFile = [...studyMaterialsTopics];
            updatedStudyMaterialFile[topicIndex].file = info.file.originFileObj;
            setStudyMaterialsTopics(updatedStudyMaterialFile);
        }
    }
    const addStudyMaterialsTopic = () => {
        if ((studyMaterialsTopics[studyMaterialsTopics.length - 1].name !== '' && studyMaterialsTopics[studyMaterialsTopics.length - 1].file != null) || (studyMaterialsTopics[studyMaterialsTopics.length - 1].name !== '' && studyMaterialsTopics[studyMaterialsTopics.length - 1].quizId != null)) {
            setStudyMaterialsTopics([...studyMaterialsTopics, { name: '', file: null, fileIndex: SMfileIndex, preview: false, type: "notes" }]);
        }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const handleResourceUpload = (info, index, topicIndex) => {
        updatepdf(info.file.originFileObj);

        const { status } = info.file;
        console.log("info", info.file);

        if (status === 'done' || status === 'error') {
            uploadingMessage = message.loading('Video upload in progress..', 0);
            uploadFunction(uploadingMessage, index, topicIndex, () => { }, () => { });
            setIncrease(increase + 1);

            const updateResourceFile = [...sections];
            const videoFile = info.file.originFileObj;

            updateResourceFile[index].topics[topicIndex].file = videoFile;
            updateResourceFile[index].topics[topicIndex].fileName = videoFile?.name;
            updateResourceFile[index].topics[topicIndex].fileSize = videoFile?.size;
            updateResourceFile[index].topics[topicIndex].fileIndex = increase + 1;

            if (videoFile.type === "application/pdf") {
                updateResourceFile[index].topics[topicIndex].type = 'notes';
                updateResourceFile[index].topics[topicIndex].videoType = 'video';
            } else {
                updateResourceFile[index].topics[topicIndex].videoType = 'video';
                updateResourceFile[index].topics[topicIndex].type = 'video';

                // Check if the file is a video and has a duration
                if (videoFile.type.startsWith('video/') && videoFile.type !== 'video/quicktime') {
                    const videoElement = document.createElement('video');
                    videoElement.src = URL.createObjectURL(videoFile);
                    videoElement.onloadedmetadata = function () {
                        // Store the duration in your data structure
                        const durationInSeconds = Math.floor(videoElement.duration);
                        updateResourceFile[index].topics[topicIndex].duration = durationInSeconds;

                        // Clean up the video element
                        URL.revokeObjectURL(videoElement.src);
                    };
                }
            }

            console.log("updateResourceFile", updateResourceFile, fileKey);
            setSections(updateResourceFile);
        } else if (status === 'error') {
            Notification.error('error', `${info.file.name} file upload failed.`);
        }
    };
    // merge chunk - upload chunk
    let blockStart = null;
    let blockEnd = null;
    let currentBlockNumber = null;
    let fileId = null;
    let totalBlocksCount = null;
    const uploadFunction = async (uploadingMessage, index, topicIndex, callback, successCallback) => {
        totalBlocksCount = Pdftoupload?.size % blockSize === 0 ? Pdftoupload?.size / blockSize : Math.floor(Pdftoupload?.size / blockSize) + 1;
        fileId = uuidv4() + "." + Pdftoupload.name.split('.').pop();
        blockStart = 0;
        blockEnd = blockSize;
        for (currentBlockNumber = 1; currentBlockNumber <= totalBlocksCount; currentBlockNumber += 1) {
            var block = Pdftoupload.slice(blockStart, blockEnd);
            await uploadBlock(uploadingMessage, block, index, topicIndex, callback, successCallback);
        }
    };

    const uploadBlock = async (uploadingMessage, currentBlock, index, topicIndex, callback, successCallback) => {
        try {
            var params = {
                file: currentBlock,
                orderId: currentBlockNumber,
                fileName: fileId
            }
            await uploadBlock2(params, async (response) => {
                if (response != undefined) {
                    if (response?.data?.progress?.message === "uploaded") {
                        blockStart = blockEnd;
                        blockEnd += blockSize;
                        console.log("block uploaded", currentBlockNumber, totalBlocksCount);
                        if (currentBlockNumber === totalBlocksCount) {
                            var percent = Math.round((currentBlockNumber / totalBlocksCount) * 100);
                            setProgress(percent);
                            MergeChunk(fileId, (key) => {
                                if (uploadingMessage) {
                                    uploadingMessage()
                                }
                                const updateResourceFile = [...sections]
                                updateResourceFile[index].topics[topicIndex].fileUrl = key;
                                setSections(updateResourceFile)
                            })
                            // await uploadCompleted(callback, successCallback);
                        } else {
                            var percent = Math.round((currentBlockNumber / totalBlocksCount) * 100);
                            setProgress(percent);
                        }
                    }
                }
            })
            // const response = await axios.post(
            //     blockUploadUrl,formData, {headers: { 'Content-Type': 'multipart/form-data' }} 
            // );
        } catch (error) {
            console.log("error in block upload", error);
        }
    }



    const handleRemoveVRTopicField = (index, topicIndex, fromQuiz) => {
        if (fromQuiz) {

            const updatedVideoResourcesTopics = [...sections];

            updatedVideoResourcesTopics[index].topics[topicIndex].name = null
            deleteQuiz(updatedVideoResourcesTopics[index].topics[topicIndex]?.quizId)
            if (updatedVideoResourcesTopics[index].topics.length === 1) {
                updatedVideoResourcesTopics[index].topics[topicIndex].name = null
                updatedVideoResourcesTopics[index].topics[topicIndex].file = null
                updatedVideoResourcesTopics[index].topics[topicIndex].fileUrl = null
                updatedVideoResourcesTopics[index].topics[topicIndex].type = null
            } else {
                delete updatedVideoResourcesTopics[index].topics[topicIndex];
                updatedVideoResourcesTopics[index].topics = updatedVideoResourcesTopics[index].topics.filter(topic => topic !== null && topic !== undefined);
            }
            setSections(updatedVideoResourcesTopics);
        } else {
            const updateVideoResourcesTopics = [...sections];
            deleteCourseDocument(updateVideoResourcesTopics[index].topics[topicIndex].fileUrl)

            if (updateVideoResourcesTopics[index].topics.length == 1) {

                updateVideoResourcesTopics[index].topics[topicIndex].name = null
                updateVideoResourcesTopics[index].topics[topicIndex].file = null
                updateVideoResourcesTopics[index].topics[topicIndex].fileUrl = null
                updateVideoResourcesTopics[index].topics[topicIndex].type = null
                setSections(updateVideoResourcesTopics)
            } else {
                const updateVideoResourcesTopics = [...sections];
                deleteCourseDocument(updateVideoResourcesTopics[index].topics[topicIndex].fileUrl);
                if (updateVideoResourcesTopics[index].topics.length === 1) {
                    updateVideoResourcesTopics[index].topics = [];
                } else {
                    delete updateVideoResourcesTopics[index].topics[topicIndex];
                    updateVideoResourcesTopics[index].topics = updateVideoResourcesTopics[index].topics.filter(topic => topic !== null && topic !== undefined);
                }
                console.log("ss", updateVideoResourcesTopics);
                setSections(updateVideoResourcesTopics);
            }
            setSections(updateVideoResourcesTopics)
        }
    }
    const addLesson = (index) => {
        const updateTopic = [...sections]
        updateTopic[index]?.topics.push({ name: '', preview: false, fileIndex: increase, fileType: null, type: null, quizId: null, })
        setSections(updateTopic)
    }
    const addSection = () => {
        if (sections[sections?.length - 1].name != '') {
            setSections([...sections, { name: "", topics: [{ name: '', preview: false, fileIndex: increase, fileType: null, type: null, quizId: null, }] }])
        }
    }
    let disableStudyMaterials = true;
    let disableVideoSections
    if (courseTypeName == "studyMaterials") {
        disableStudyMaterials = studyMaterialsTopics?.some(
            (item) =>  item?.name === ""
          );            
    } else {
        disableVideoSections = sections?.some((section) => {
            const isSectionNameEmptyOrNull = section?.name === '' || section?.name === null ;
            return isSectionNameEmptyOrNull 
        });
    }
    const actions = [
        <Button
            type="primary"
            disabled={courseTypeName == "studyMaterials" ? disableStudyMaterials : disableVideoSections}
            loading={updateBtnLoader}
            onClick={() => {
                setUpdateBtnLoader(true);
                if (courseTypeName == 'studyMaterials') {
                    postCreateSMCourseContent(
                        studyMaterialsTopics,
                        SMfilesArray,
                        () => { setUpdateBtnLoader(false) },
                        () => { history.push("/course-details") }
                    )
                } else {
                    postCreateVideoResourceContent(
                        sections,
                        () => { setUpdateBtnLoader(false) },
                        () => { console.log("AA-sections"); history.push("/course-details") }
                    )
                }
            }}
        >{"UPDATE"}</Button>,
    ]

    useEffect(() => {
        storeQuizData([])

    }, [])
    useEffect(() => {
        if (location?.state?.fromAddQuizPage) {
            if (courseTypeName == "studyMaterials") {
                const lastItem = location?.state?.retrieveQuizUpdatedTopics.slice(-1)[0];
                if (lastItem?.quizId === null) {
                    location?.state?.retrieveQuizUpdatedTopics.pop();
                }
                setSMfileIndex(location?.state?.SMfileIndex)
                setSMfilesArray([...location?.state?.SMfilesArray])
                setStudyMaterialsTopics(location?.state?.retrieveQuizUpdatedTopics)
            } else {
                if (location.state.retrieveQuizUpdatedTopics.length > 0) {
                    const lastItem = location.state.retrieveQuizUpdatedTopics.slice(-1)[0];
                    if (lastItem.topics && lastItem.topics.length > 0 && lastItem.topics.slice(-1)[0].quizId === null) {
                        lastItem.topics.pop();
                    }
                }
                setIncrease(location?.state?.increase)
                setSections(location?.state?.retrieveQuizUpdatedTopics)
            }
        }
    }, [location?.state?.fromAddQuizPage])
    console.log("studyMaterialsTopics", studyMaterialsTopics)
    return (
        <div>
            <PageHeader title={'Update course content'} actions={actions} onBackPress={() => { history.push('/course-details') }} />
            <div style={{ padding: "70px" }}>
                <div className='m-t-5' style={{ color: "#191919", fontSize: "15px", fontWeight: "bold", }}>Course content</div>
                {courseTypeName == "studyMaterials" ?
                    <div className='m-t-20' style={{ width: '100%', height: 'fit-content', padding: 30, border: '1px solid #d9d9d9', backgroundColor: '#F6F4FE' }}>
                        {studyMaterialsTopics?.map((topic, topicIndex) => (
                            <>
                                {topic?.quizId ?
                                    <div className='m-b-20' style={{ width: '100%', height: 'fit-content', padding: '20px 30px', border: '1px solid #d9d9d9', backgroundColor: '#FFFFFF' }}>
                                        <div className='m-b-5' style={inputHeadingStyle}>Quiz name</div>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ border: "1px solid #E6E6E6", height: 50, width: "100%", padding: "5px 10px", display: "flex", alignItems: "center" }}>
                                                <img src={quiz} style={{ width: "20px", height: "20px", marginRight: 10 }} />
                                                <div style={{ color: "#636363", fontWeight: "bold" }}>QUIZ</div>
                                            </div>
                                            <EditOutlined
                                                className='m-t-5'
                                                style={{ color: '#1089FF', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                            />
                                            <MinusCircleOutlined
                                                className='m-t-5'
                                                style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                onClick={() => handleRemoveSMTopicField(topicIndex, true)}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <>
                                    {topic?.type && topic?.type != "quiz" ?
                                        <div className='m-b-20' style={{ width: '100%', height: 'fit-content', padding: '20px 30px', border: '1px solid #d9d9d9', backgroundColor: '#FFFFFF' }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div className='m-b-5' style={inputHeadingStyle}>Enter resource name</div>
                                                <div style={{ ...inputHeadingStyle, fontSize: 11 }}>
                                                    <Checkbox
                                                        checked={studyMaterialsTopics[topicIndex]?.preview == 1 || studyMaterialsTopics[topicIndex]?.preview == true}
                                                        style={{ marginRight: 10 }}
                                                        onChange={(e) => {
                                                            const updatedPreviw = [...studyMaterialsTopics];
                                                            updatedPreviw[topicIndex].preview = e.target.checked;
                                                            setStudyMaterialsTopics(updatedPreviw);
                                                        }} />
                                                    <span>FREE PREVIEW</span>
                                                </div>
                                            </div>
                                            <Input
                                                placeholder='Enter resource name'
                                                value={topic?.name}
                                                onChange={(e) => {
                                                    const updatedTopics = [...studyMaterialsTopics];
                                                    updatedTopics[topicIndex].name = e.target.value;
                                                    setStudyMaterialsTopics(updatedTopics);
                                                }}
                                            />
                                            <div style={{ marginTop: 20 }}>
                                                {studyMaterialsTopics?.[topicIndex]?.file ?
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <div style={{ border: "1px solid #E6E6E6", height: 50, width: "100%", padding: "5px 10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            <div style={{ display: "flex" }}>
                                                                <FilePdfOutlined style={{ color: "#FF414D", marginRight: 10, fontSize: 20 }} />
                                                                <div style={{ color: "#636363" }}>{studyMaterialsTopics?.[topicIndex]?.file?.name}</div>
                                                            </div>
                                                            <div style={{ color: "#AEAEAE" }}>{studyMaterialsTopics?.[topicIndex]?.pageCount} page(s)</div>
                                                        </div>
                                                        <MinusCircleOutlined
                                                            className='m-t-5'
                                                            style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                            onClick={() => handleRemoveSMTopicField(topicIndex, false)}
                                                        />
                                                    </div>
                                                    :
                                                    <Dragger
                                                        showUploadList={false}
                                                        accept='.pdf'
                                                        onChange={(e) => handleStudyMaterialUpload(e, topicIndex)}
                                                        style={{ borderColor: "#1089FF", cursor: "pointer", width: "100%", margin: "10px 0px" }}
                                                    >
                                                        <div style={{ textAlign: "center", color: "#636363", fontSize: 14, fontWeight: 600 }}>
                                                            Drag and drop or <span style={{ color: "#1089FF", margin: "0px 5px" }}>Choose file</span> to Upload
                                                            <div style={{ display: "flex", justifyContent: "flex-end", margin: "-22px 20px 0px 0px" }}>
                                                                <div style={{ marginRight: 30 }}>Supported file: Pdf</div>
                                                                {/* <div>Max: 10Mb</div> */}
                                                            </div>
                                                        </div>
                                                    </Dragger>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <></>
                                            }
                                    </>
                                }
                            </>
                        ))}
                        <div className='r-c-c'>
                            <div style={{ fontSize: 15, color: "#1089FF", fontWeight: "bold", cursor:(studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.name !== '' && studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.file != null) || (studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.name !== '' && studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.quizId != null) ? "pointer" : "not-allowed", marginRight: 10, opacity:(studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.name !== '' && studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.file != null) || (studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.name !== '' && studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.quizId != null) ? 1 :0.5 }} onClick={addStudyMaterialsTopic}><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD TOPIC</span></div>
                            <div style={{ fontSize: 15, color: "#594099", fontWeight: "bold", cursor: "pointer", marginLeft: 10 }}
                                onClick={() => {
                                    history.push({
                                        pathname: "/course/add-quiz",
                                        state: {
                                            fromStudyMaterial:
                                            {
                                                fromCourseContent: true,
                                                SMfilesArray: [...SMfilesArray],
                                                SMfileIndex: SMfileIndex,
                                                studyMaterialsTopics: [
                                                    ...studyMaterialsTopics,
                                                    {
                                                        type: "quiz",
                                                        quizId: null
                                                    }
                                                ],
                                            }
                                        }
                                    })
                                }}
                            ><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD QUIZ</span></div>
                        </div>
                    </div>
                    :
                    <div>
                        {sections?.map((section, index) => {
                            return (
                                <div className='m-t-10' style={{ width: '100%', height: 'fit-content', padding: 30, border: '1px solid #D9D9D9', backgroundColor: '#F6F4FE' }}>
                                    <div style={{ color: '#636363', fontSize: "12px", fontWeight: "bold" }}>Section name</div>
                                    <div>
                                        <Input
                                            placeholder='Enter section name'
                                            value={section?.name}
                                            onChange={(e) => {
                                                const updateSections = [...sections];
                                                updateSections[index].name = e.target.value;
                                                setSections(updateSections);
                                            }}
                                        /></div>
                                    {section?.topics?.map((item, topicIndex) => {
                                        return (
                                            <>
                                                <div className='m-t-20' style={{ width: '100%', height: 'fit-content', padding: '20px 30px', border: '1px solid #D9D9D9', backgroundColor: '#FFFFFF' }}>
                                                    {item?.type != 'quiz' ?
                                                        (<>
                                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                                                                <div style={{ color: '#636363', fontSize: "12px", fontWeight: "bold" }}>Lecture name</div>
                                                                <div>
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            const updateTopics = [...sections];
                                                                            updateTopics[index].topics[topicIndex].preview = e.target.checked;
                                                                            setSections(updateTopics);
                                                                        }}
                                                                        checked={item?.preview == 1 || item?.preview == true}
                                                                    />
                                                                    <h style={{ color: '#636363', fontSize: "12px", fontWeight: "bold", marginLeft: "5px" }}>FREE PREVIEW</h>
                                                                </div>
                                                            </div>

                                                            <Input
                                                                placeholder='Enter lesson name'
                                                                value={item?.name}
                                                                onChange={(e) => {
                                                                    const updateTopics = [...sections];
                                                                    updateTopics[index].topics[topicIndex].name = e.target.value;
                                                                    setSections(updateTopics);
                                                                }}
                                                            />
                                                        </>)

                                                        : (<></>)}
                                                    {item?.file || item?.fileUrl || item?.type == 'quiz' ?
                                                        <>
                                                            <div style={{ display: "flex" }}>
                                                                <div style={{ height: "35px", width: "100%", border: '1px solid #E6E6E6', marginTop: "13px", alignItems: "center" }}>
                                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                        <div style={{ display: "flex" }}>
                                                                            {
                                                                                item?.type == 'quiz' ?
                                                                                    (<>
                                                                                        <img src={quiz} style={{ width: "25px", height: "25px", marginLeft: "10px", marginTop: "5px" }} />
                                                                                    </>) : (<>
                                                                                        {console.log("Abcd", item)}
                                                                                        {item?.type == 'notes' ?
                                                                                            (<>
                                                                                                <FilePdfOutlined style={{ color: "#FF414D", marginLeft: 10, fontSize: 20, marginTop: "5px" }} />
                                                                                            </>) :
                                                                                            (<>
                                                                                                <img src={video} style={{ width: "30px", height: "30px", marginLeft: "10px" }} />
                                                                                            </>)}</>)}



                                                                            <div style={{ marginTop: "5px" }}>
                                                                                <Typography.Text
                                                                                    ellipsis={{ tooltip: true }}
                                                                                    style={{
                                                                                        fontSize: "14px",
                                                                                        color: "#AEAEAE",
                                                                                        fontWeight: 700,
                                                                                        maxWidth: 600,
                                                                                        width: '200px',
                                                                                        marginLeft: "20px"
                                                                                    }}
                                                                                >
                                                                                    {item?.type == 'quiz' ?
                                                                                        (<>{item?.name}</>) :
                                                                                        (<>
                                                                                            {item?.file?.name ? (<>File : {item?.file?.name}</>) : item?.videoType === 'recorded' ? (<>Broadcast : {item?.file}</>) : item?.type == 'notes' ? 'PDF' : (<>Youtube</>)}
                                                                                        </>)}
                                                                                </Typography.Text>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ color: '#AEAEAE', fontSize: "12px", marginTop: "6px", marginRight: "20px" }}>
                                                                            {item?.file?.size ? (<>{formatFileSize(item?.file?.size)}</>) : (<></>)}

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                {item?.type == 'quiz' ?

                                                                    <div style={{ display: "flex" }}>
                                                                        <EditOutlined
                                                                            onClick={() => {
                                                                                storeQuizData([])
                                                                                getEditQuizData(item?.quizId, () => { })
                                                                                history.push({
                                                                                    pathname: "/course/add-quiz",
                                                                                    state: {
                                                                                        fromVideoResourceSection:
                                                                                        {
                                                                                            fromCourseContent:true,
                                                                                            updatedSections: sections,
                                                                                            editTrue: true,
                                                                                            increase: increase,
                                                                                            isEdit: location?.state?.key?.isEdit,
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }}
                                                                            style={{ color: '#1089FF', cursor: 'pointer', fontSize: 18, marginLeft: 20, marginTop: "20px" }}
                                                                        />
                                                                        < MinusCircleOutlined
                                                                            className='m-t-5'
                                                                            style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20, marginTop: "20px" }}
                                                                            onClick={() => handleRemoveVRTopicField(index, topicIndex, true)}
                                                                        />
                                                                    </div> :
                                                                    <MinusCircleOutlined
                                                                        style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20, marginTop: "20px" }}
                                                                        onClick={() => handleRemoveVRTopicField(index, topicIndex, false)}
                                                                    />}
                                                            </div>
                                                        </> :
                                                        <div className='m-t-20'>
                                                            <div className='m-t-20' style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
                                                                <div style={{ height: "60px" }}>
                                                                    <Dragger
                                                                        showUploadList={false}
                                                                        onChange={(e) => handleResourceUpload(e, index, topicIndex)}
                                                                        accept={".mp4,.avi,.mkv,.pdf,.mov"}
                                                                        style={{ borderColor: "#1089FF", cursor: "pointer", margin: "10px 0px", width: "280px" }}
                                                                    >
                                                                        <div className='r-c-c' style={{ color: "#636363", fontSize: "14", fontWeight: 600, marginTop: "-8px" }}>Drag & drop or <span style={{ color: "#1089FF", margin: "0px 2px " }}> Choose file </span></div>
                                                                        <div><span style={{ color: "#636363", opacity: '0.5' }}>(Pdf,mp4,avi,flv,mov,mkv)</span></div>
                                                                    </Dragger>
                                                                </div>
                                                                <div style={{ height: "60px", width: "280px", border: '1px solid #E6E6E6', marginTop: "10px" }}>
                                                                    <div style={{ textAlign: "center", marginTop: "5px", cursor: 'pointer' }}>
                                                                        <div
                                                                            onClick={(e) => {
                                                                                null
                                                                                // const updateTopics = [...sections];
                                                                                // updateTopics[index].topics[topicIndex].type = 'video';
                                                                                // updateTopics[index].topics[topicIndex].videoType = 'recorded';
                                                                                // setSections(updateTopics);
                                                                                // history.push({
                                                                                //     pathname: "/digital-library/division-card",
                                                                                //     state: {
                                                                                //         fromBrodcastVR:
                                                                                //         {
                                                                                //             redirectingPageDL: false,
                                                                                //             sections: sections,
                                                                                //         }
                                                                                //     }
                                                                                // })
                                                                            }}
                                                                        >
                                                                            <div style={{ color: "#636363", fontSize: "14", fontWeight: 600 }}>Choose files from</div>
                                                                            <div style={{ color: "#1089FF", fontSize: "14", fontWeight: 600 }}> Broadcast Recordings</div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div style={{ marginTop: "7px" }}>
                                                                    <div style={{ color: "#636363", fontSize: "12px", fontWeight: "bold" }}>YouTube Links</div>
                                                                    <Input
                                                                        style={{ width: "280px", marginTop: "8px" }}
                                                                        placeholder='Enter YouTube link'
                                                                        suffix={<PlusCircleOutlined
                                                                            onClick={() => {
                                                                                const updateTopics = [...sections];
                                                                                updateTopics[index].topics[topicIndex].fileUrl = youtubeLink;
                                                                                updateTopics[index].topics[topicIndex].fileIndex = -1;
                                                                                updateTopics[index].topics[topicIndex].type = 'video';
                                                                                updateTopics[index].topics[topicIndex].videoType = 'link';

                                                                                setSections(updateTopics)
                                                                            }} />}
                                                                        onChange={(e) => { setYouTubeLink(e.target.value) }}
                                                                    />
                                                                    <div>
                                                                        {youtubeLink === null || youtubeLink === "" || youtubeLink?.match(regExp) ? null : (<h style={{ color: "#FF414D" }}>Invalid Youtube Link</h>)}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    }

                                                </div >
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    {topicIndex === section.topics.length - 1 && (
                                                        <>

                                                            <div className='m-t-20 m-b-20' style={{ fontSize: 15, color: "#1089FF", fontWeight: "bold", cursor: "pointer", textAlign: "center" }} onClick={() => { addLesson(index) }}><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD LECTURES</span></div>
                                                            <div className='m-t-20 m-b-20' style={{ textAlign: 'center', color: '#594099', fontSize: "14px", fontWeight: "bold", cursor: "pointer" }}
                                                                onClick={() => {
                                                                    let targetSection = sections[index]
                                                                    let newTopic = {
                                                                        name: 'quiz',
                                                                        type: "quiz",
                                                                        quizId: null
                                                                    }
                                                                    let updatedTopics = [...targetSection.topics, newTopic];
                                                                    let updatedSection = {
                                                                        ...targetSection,
                                                                        topics: updatedTopics
                                                                    };


                                                                    history.push({
                                                                        pathname: "/course/add-quiz",
                                                                        state: {
                                                                            fromVideoResourceSection:
                                                                            {
                                                                                videoFile: [...videoFile],
                                                                                increase: increase,
                                                                                fromCourseContent: true,
                                                                                updatedSections: [
                                                                                    ...sections.slice(0, index),
                                                                                    updatedSection,
                                                                                    ...sections.slice(index + 1)
                                                                                ],
                                                                            }
                                                                        }
                                                                    })

                                                                }}><PlusCircleOutlined style={{ color: '#594099', marginLeft: "15px", fontWeight: "bold" }} />{" "}{"  "}{""}ADD QUIZ</div>
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        )
                                    })}


                                </div>
                            )
                        })}
                        <div className='r-c-c m-t-20 m-b-20' style={{ fontSize: 15, color: "#1089FF", fontWeight: "bold", cursor: "pointer" }} onClick={addSection}><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD SECTION</span></div>
                    </div>
                }
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const { courseTypeName, Pdftoupload,newCourseId } = state.courseManagement;
    return {
        courseTypeName, Pdftoupload,newCourseId
    };
};
const mapDispatchToProps = (dispatch) => ({
    postCreateSMCourseContent: (studyMaterialsTopics, SMfilesArray, callBack, successCallBack) => dispatch(postCreateSMCourseContent(studyMaterialsTopics, SMfilesArray, callBack, successCallBack)),
    postCreateVideoResourceContent: (sections, callBack, successCallBack) => dispatch(postCreateVideoResourceContent(sections, callBack, successCallBack)),

    deleteQuiz: (id) => dispatch(deleteQuiz(id)),
    deleteQuiz: (id) => dispatch(deleteQuiz(id)),
    uploadBlock2: (params, callback) => dispatch(uploadBlock2(params, callback)),
    MergeChunk: (fileId, callback) => dispatch(MergeChunk(fileId, callback)),
    fileKeyUpdate: (key) => dispatch(fileKeyUpdate(key)),
    updatepdf: (data) => dispatch(updatepdf(data)),
    deleteCourseDocument: (key) => dispatch(deleteCourseDocument(key)),
    getEditQuizData: (id, callback) => dispatch(getEditQuizData(id, callback)),
    storeQuizData: (val) => dispatch(storeQuizData(val))

});

export default connect(mapStateToProps, mapDispatchToProps)(AddCourseContent);