import React from 'react';
import { connect } from 'react-redux';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';

import { FaRegEdit, FaRegClone } from "react-icons/fa";
import { MdFormatListBulleted, MdCloudDownload, MdMoreVert } from "react-icons/md";
import {
  AiOutlineDownload, AiOutlinePlus, AiOutlineMore, AiFillCaretDown, AiOutlineMinus, AiOutlinePlusCircle,
  AiFillDelete, AiFillCopy, AiOutlineFileText, AiOutlineRight, AiOutlineLeft
} from "react-icons/ai";
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import './styles.css';
import { Color } from 'services';
import { Col, Row, Typography, Select, Menu, Dropdown, Tooltip } from 'antd';
import TopNavigationColorHeader from './localComponent/topNavigationColorHeader';
import TextEditorModal from './localComponent/textEditorModal';
import TestPublishModal from './localComponent/testPublishModal';
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';
import {
  updateCurrentQuestionIndex,
  updateEvaluatedMark,
  updateEvaluatedComment,
  fetchSubmittedTestQuestionsForQueGrid,
  fetchSubmittedTestQuestionUsingQuestionId,
  fetchSubmittedTestByStudentId,
  updateEvaluationQuestionId,
  saveEvaluation,
  increaseQuestionId,
  updateEvaluationQuestionData,
  universalLoader,
  updateTotalMarksObtained,
  updatePalette,
} from 'actions/testAdmin';
import { useEffect, useState } from 'react';
import PageDataLoader from 'components/pageDataLoader';
import Notification from "services/Notification";
import { Api } from 'services';
import ImageViewer from 'components/ImageViewer';
import { BsFillCircleFill } from 'react-icons/bs';


const { TabPane } = Tabs;
const { confirm } = Modal;
const { Title } = Typography;
const { Option } = Select;

const TestDetails = ({ value, text, renderTrigger }) => {
  useEffect(() => {

  }, [renderTrigger]);
  return (
    <div className='r-c-c-c m-r-50'>
      <div className='text-xmd bold-600'>{value}</div>
      <div className='text-xs'>{text}</div>
    </div>
  )
}


const Row1 = ({ history, submittedTestsByStudentId, totalMarksObtained }) => {
  return (
    <div className='r-c-sb p-20'>
      <TestDetails value={`${totalMarksObtained}/${submittedTestsByStudentId?.totalMarks}`} text={`Marks`} renderTrigger={totalMarksObtained} />
      <div onClick={() => history.push('test-evaluation-questions-list')} className='r-c-c-c cursor-pointer'>
        <MdFormatListBulleted className='text-md color-black' />
        <div className='text-xs text-center color-black'>List View</div>
      </div>
    </div>
  )
}

const Row2 = ({ evaluationQuestionData, evaluationPageQuestionGrid, updateEvaluatedComment, updateEvaluationQuestionId,
  updateCurrentQuestionIndex, fetchSubmittedTestQuestionUsingQuestionId, togglePageLoader }) => {
  return (
    <div className='r-ac p-l-20 p-r-20 m-t-20'>
      {evaluationPageQuestionGrid?.map((subject_details, index) => {
        return (
          <>
            <Button
              size="middle"
              key={index}
              disabled={evaluationPageQuestionGrid?.length <= 1}
              onClick={() => {
                if (subject_details?.questions?.length && (subject_details?.subjectId !== evaluationQuestionData?.subjectId) && evaluationPageQuestionGrid?.length > 1) {
                  togglePageLoader(true)
                  updateEvaluatedComment('');
                  updateEvaluationQuestionId(subject_details.questions[0])
                  updateCurrentQuestionIndex(subject_details.questions[0].index - 1);
                  fetchSubmittedTestQuestionUsingQuestionId((bool) => togglePageLoader(bool));
                } else {
                  togglePageLoader(true)
                  updateEvaluatedComment('');
                  updateEvaluationQuestionId(subject_details.sections[0]?.questions[0])
                  updateCurrentQuestionIndex(subject_details.sections[0]?.questions[0]?.index - 1);
                  fetchSubmittedTestQuestionUsingQuestionId((bool) => togglePageLoader(bool));
                }
              }}
              className={subject_details.subjectId === evaluationQuestionData.subjectId && evaluationPageQuestionGrid?.length > 1 ? 'purpleBtnWrap m-r-20 text-xxs purpleButtonHoverEffect' : 'blankGreyBtnWrap m-r-20 text-xs'}>
              {subject_details?.subjectName}
            </Button>
          </>
        );
      })}
    </div>
  )
}

const Row3 = ({ questions,
  currentQuestionIndex,
  updateEvaluatedMark,
  evaluatedMark,
  evaluationQuestionData,
  evaluationQuestionId,
  updateTotalMarksObtained,
  totalMarksObtained,
  isValidMark,
  resultsPublished,
  userType }) => {

  return (
    <div className='p-20 r-c-sb'>
      <div className='text-sm bold-600 color-black'>{currentQuestionIndex + 1}</div>
      {
        evaluationQuestionData.type == "Subjective" || evaluationQuestionData.type == "Numeric"
          ? <div className='r-ac'>
            <input
              class="markBoxWrap m-r-10"
              // type="number"
              placeholder="Enter Mark"
              value={evaluatedMark ?? 0}
              disabled={(evaluationQuestionId?.answerStatus == 'None' && (evaluationQuestionId?.submitStatus == 'Unanswered' || evaluationQuestionId?.submitStatus == 'None' || evaluationQuestionId?.submitStatus == 'NotVisited') || evaluationQuestionId?.submitStatus == 'Bookmarked')
                || (evaluationQuestionData.type == "Numeric") || (userType != 'admin' && resultsPublished === true) ?
                true :
                false
              }
              onChange={(e) => {
                if (isValidMark(e.target.value)) {
                  if (parseFloat(e.target.value) >= parseFloat(evaluationQuestionData.positiveMarks)) {
                    updateEvaluatedMark(evaluationQuestionData.positiveMarks);
                    updateTotalMarksObtained(parseInt(evaluationQuestionData.positiveMarks))
                    e.target.value = evaluationQuestionData.positiveMarks ?? 0;
                  }
                  updateEvaluatedMark(e.target.value);
                  updateTotalMarksObtained(parseInt(e.target.value))
                }
              }}
            />
            <div className='text-xmd bold-600 lowgrey flex-end'>{`/ ${evaluationQuestionData.positiveMarks}`}</div>
          </div>
          : <div className='r-ac'>
            <div className='r-c-c test-count-seq-box m-r-10'>
              <div className='text-xmd m-l-5'>{parseInt(evaluatedMark === '' ? '0' : evaluatedMark) > 0 ? evaluatedMark : 0}</div>
            </div>
            <div className='r-c-c test-count-seq-box' style={{ background: '#FFE4D9', borderColor: '#EC5254' }}>
              <div className='text-xmd m-l-5'>{parseInt(evaluatedMark === '' ? '0' : evaluatedMark) < 0 ? evaluatedMark : 0}</div>
            </div>
          </div>
      }
    </div>
  )
}

const CommentBox = ({ updateEvaluatedComment, evaluatedComment, evaluationQuestionId }) => {
  useEffect(() => {

  }, [evaluatedComment])
  return (
    <>
      <div className='r-c-sb'>
        <div className='m-t-20 text-xmd bold-600 color-black'>Comments</div>
        <div className='m-t-20 text-xmd bold-600 lowgrey flex-end'>Optional</div>
      </div>
      <div className='m-t-10'>
        <input
          class="commentBoxWrap"
          type="text"
          placeholder="Add a comment"
          value={evaluatedComment}
          onChange={(e) => {
            updateEvaluatedComment(e.target.value)
          }}
        />
      </div>
    </>
  )
}
const RowSubjective = ({ evaluatedComment,
  updateEvaluatedComment,
  evaluationQuestionData,
  evaluationQuestionId, setImgUrl, setImageViewerVisibility }) => {
  return (
    <div className='p-20 m-b-40' >
      <div className='r-jsb'>
        <div>
          <div className='text-xmd bold-600 color-black'>Student's Answer</div>
          {evaluationQuestionData?.filename ?
            <div className='r-ac'>
              {evaluationQuestionData?.filename?.map((item, index) => (
                <img src={`${Api._s3_url}${item}`} alt={""} width="160"
                  height="160" text-align="left"
                  style={{ display: 'block' }} className='m-t-10 m-r-10 cursor-pointer'
                  onClick={() => {
                    setImgUrl(`${Api._s3_url}${item}`)
                    setImageViewerVisibility(true);
                  }}
                />
              ))}
            </div>
            : ""
          }
          {evaluationQuestionData?.filename?.length === 0 || evaluationQuestionData.filename === null ? "NA" : null}
        </div>
        {(evaluationQuestionData?.submitStatus === 'Unanswered' || evaluationQuestionData?.submitStatus === 'Bookmarked')
          ?
          <div style={{ display: 'flex', flexDirection: 'row-reverse', color: '#A2A2A2', margin: '10px 0px' }}>Unattempted <BsFillCircleFill style={{ alignSelf: 'center', margin: '0 3px', color: '#AEAEAE' }} /></div>
          :
          evaluationQuestionData?.submitStatus === 'NotVisited'
            ?
            <div style={{ display: 'flex', flexDirection: 'row-reverse', color: '#A2A2A2', margin: '10px 0px' }}>Unseen <RiCheckboxBlankCircleLine style={{ alignSelf: 'center', margin: '0 3px' }} /></div>
            :
            null
        }
      </div>
      <CommentBox evaluatedComment={evaluatedComment} evaluationQuestionId={evaluationQuestionId}
        updateEvaluatedComment={updateEvaluatedComment} />
    </div>
  )
}


const Row6 = ({ evaluationQuestionData, setImgUrl, setImageViewerVisibility }) => {
  const isEmptyHtml = (content) => {
    let pattern = /^(?:\s|<br>|<p>|<\/p>)*$/;
    return pattern.test(content);
  }
  return (
    <div className='p-20 m-b-40'>
      <div className='r-ac m-b-20'>
        <AiOutlineFileText className='color-purple text-sm m-r-5' />
        <div className='text-xmd bold-600 color-purple'>Solution</div>
      </div>
      <div className='text-xmd color-black resize-editor-img'
        style={{ width: '100%', overflowX: "hidden" }}>
        {
          (evaluationQuestionData.solution != null && !isEmptyHtml(evaluationQuestionData.solution))
            ?
            ReactHtmlParser(evaluationQuestionData.solution,
              {
                transform: (node) => {
                  if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                    return <TeX math={`${node.attribs['data-value']}`} />;
                  }
                  if (node.type === 'tag' && node.name === 'img') {
                    return <img alt="question" style={{ maxHeight: '40vh', maxWidth: '40vh' }}
                      className="cursor-pointer"
                      src={node.attribs.src} onClick={() => {
                        setImgUrl(node.attribs.src);
                        setImageViewerVisibility(true);
                      }} />;
                  }
                }
              })
            :
            <div className="m-t-10">NA</div>}
      </div>
    </div>
  )
}

const QueGridSide = ({ submittedTestsByStudentId,
  currentQuestionIndex,
  updateEvaluationQuestionId,
  evaluationPageQuestionGrid,
  togglePageLoader,
  fetchSubmittedTestQuestionUsingQuestionId,
  updateCurrentQuestionIndex,
  evaluationQuestionData,
  reRenderPalette,
  updateEvaluatedComment
}) => {
  useEffect(() => {
  }, [reRenderPalette]);

  console.log('evaluationPageQuestionGrid', evaluationPageQuestionGrid)
  return (
    <div>
      {evaluationPageQuestionGrid?.map((subject_details, sub_index) => (
        <div keys={sub_index}>
          <div className='text-sm bold-600 color-black m-b-10'>{subject_details.subjectName}</div>
          {subject_details?.sections?.length > 0
            ?
            subject_details?.sections?.map((section, index) => (
              <>
                <div className='text-xmd bold-600 color-black m-b-10'>{section?.sectionName}</div>
                <div className='r-ac flex-wrap' style={{ marginLeft: -10 }}>
                  {section?.questions?.map((questionId, index) => {
                    return (<div
                      style={{
                        borderColor: questionId?.index === currentQuestionIndex + 1 ? 'black' :
                          questionId?.answerStatus == 'None' ? '#707070' :
                            questionId?.answerStatus == 'Correct' ? '#28DF99' :
                              questionId?.answerStatus == 'Incorrect' ? '#FF414D' :
                                questionId?.answerStatus == 'PartialCorrect' ? '#94EFCC' :
                                  "AEAEAE",
                        background: (questionId?.answerStatus == 'None') && (questionId?.submitStatus == 'Unanswered' || questionId?.submitStatus === 'Bookmarked') ? '#AEAEAE' :
                          (questionId?.answerStatus == 'None') && (questionId?.submitStatus == 'NotVisited') ? '#fff' :
                            (questionId?.answerStatus == 'None') && (questionId?.submitStatus == 'Attempted' || questionId?.submitStatus === 'AttemptedAndBookmarked') ? 'rgb(246, 244, 255)' :
                              questionId?.answerStatus == 'Correct' ? '#28DF99' :
                                questionId?.answerStatus == 'Incorrect' ? '#FF414D' :
                                  questionId?.answerStatus == 'PartialCorrect' ? 'linear-gradient(36deg, #28DF99 50%, #94EFCC 50%)' :
                                    "#AEAEAE",
                        color: (questionId?.answerStatus == 'None') && (questionId?.submitStatus == 'NotVisited')
                          ?
                          '#707070'
                          :
                          (questionId?.answerStatus == 'None') && (questionId?.submitStatus == 'Attempted' || questionId?.submitStatus === 'AttemptedAndBookmarked')
                            ?
                            'black'
                            :
                            '#fff',
                        borderWidth: questionId?.index === currentQuestionIndex + 1 ? 2 : 1
                      }}
                      className='r-c-c radius-100 color-black test-circle-avatar m-b-10 m-l-15 cursor-pointer'
                      onClick={() => {
                        if (currentQuestionIndex + 1 != questionId?.index) {
                          togglePageLoader(true)
                          updateEvaluatedComment('');
                          updateEvaluationQuestionId(questionId)
                          updateCurrentQuestionIndex(questionId?.index - 1);
                          fetchSubmittedTestQuestionUsingQuestionId((bool) => togglePageLoader(bool));
                        }
                      }}
                    >
                      {questionId?.index}
                    </div>
                    )
                  })
                  }
                </div>
              </>
            ))
            :
            <div className='r-ac flex-wrap' style={{ marginLeft: -10 }}>
              {subject_details?.questions?.map((questionId, index) => {
                return (
                  <div
                    style={{
                      borderColor: questionId?.index === currentQuestionIndex + 1 ? 'black' :
                        questionId?.answerStatus == 'None' ? '#707070' :
                          questionId?.answerStatus == 'Correct' ? '#28DF99' :
                            questionId?.answerStatus == 'Incorrect' ? '#FF414D' :
                              questionId?.answerStatus == 'PartialCorrect' ? '#94EFCC' :
                                "AEAEAE",
                      background: (questionId?.answerStatus == 'None') && (questionId?.submitStatus == 'Unanswered' || questionId?.submitStatus === 'Bookmarked') ? '#AEAEAE' :
                        (questionId?.answerStatus == 'None') && (questionId?.submitStatus == 'NotVisited') ? '#fff' :
                          (questionId?.answerStatus == 'None') && (questionId?.submitStatus == 'Attempted' || questionId?.submitStatus === 'AttemptedAndBookmarked') ? 'rgb(246, 244, 255)' :
                            questionId?.answerStatus == 'Correct' ? '#28DF99' :
                              questionId?.answerStatus == 'Incorrect' ? '#FF414D' :
                                questionId?.answerStatus == 'PartialCorrect' ? 'linear-gradient(36deg, #28DF99 50%, #94EFCC 50%)' :
                                  "#AEAEAE",
                      color: (questionId?.answerStatus == 'None') && (questionId?.submitStatus == 'NotVisited')
                        ?
                        '#707070'
                        :
                        (questionId?.answerStatus == 'None') && (questionId?.submitStatus == 'Attempted' || questionId?.submitStatus === 'AttemptedAndBookmarked')
                          ?
                          'black'
                          :
                          '#fff',
                      borderWidth: questionId?.index === currentQuestionIndex + 1 ? 2 : 1
                    }}
                    className='r-c-c radius-100 color-black test-circle-avatar m-b-10 m-l-15 cursor-pointer'
                    onClick={() => {
                      if (currentQuestionIndex + 1 != questionId?.index) {
                        togglePageLoader(true)
                        updateEvaluatedComment('');
                        updateEvaluationQuestionId(questionId)
                        updateCurrentQuestionIndex(questionId?.index - 1);
                        fetchSubmittedTestQuestionUsingQuestionId((bool) => togglePageLoader(bool));
                      }
                    }}
                  >
                    {questionId?.index}
                  </div>
                )
              })}
            </div>
          }
        </div>
      ))
      }
    </div>
  )
}

const ColorLegend = ({ bgcolor, name, submittedTestsByStudentId, reRenderLegend }) => {
  useEffect(() => {

  }, [reRenderLegend])
  return (
    <div className='r-ac flex-1 m-b-10'>
      <div className='r-c-c radius-100 test-grid-legend-circle text-xs bold-600'
        style={{
          background: name === 'Partially correct' ? 'linear-gradient(36deg, #28DF99 50%, #94EFCC 50%)' : bgcolor,
          borderColor: name === 'Unseen' ? '' : name === 'Partially correct' ? '#fff' : bgcolor,
          color: name === 'Unseen' ? '#707070' : name == "Not evaluated" ? '#000' : '#fff'
        }}>
        {name == 'Correct' ? submittedTestsByStudentId?.correctCount :
          name == 'Incorrect' ? submittedTestsByStudentId?.inCorrectCount :
            name == 'Unattempted' ? submittedTestsByStudentId?.skippedCount :
              name == 'Unseen' ? submittedTestsByStudentId?.notVisitedCount :
                name == 'Partially correct' ? submittedTestsByStudentId?.partialCorrectCount :
                  name == 'Not evaluated' ? submittedTestsByStudentId?.notEvaluatedCount :
                    submittedTestsByStudentId?.partiallyCorrect ? submittedTestsByStudentId?.partiallyCorrect : 0}
      </div>
      <div className='text-xxs color-black m-l-10'>{name}</div>
    </div>
  )
}

const QuesAnsResultContainer = ({ questionData, identifier, setImageViewerVisibility, setImgUrl, type, answerData, answerIndex, answerType, studentAnswer, evaluationQuestionData, orQuestionIndexChar }) => {

  console.log('resultQuestion', questionData)
  const QuestionConatiner = ({ questionContent, setImageViewerVisibility, setImgUrl, type, paragraph }) => {
    console.log('paragraph', paragraph)
    console.log('questionContent', questionContent)
    return (
      <div className='r-jsb m-t-20'>
        <div className='display-flex flex-1'>
          <div className='m-l-20'>{orQuestionIndexChar}.</div>
          <div className='text-xmd color-black resize-editor-img'
            style={{ width: '100%', overflowX: "hidden" }}>
            {ReactHtmlParser(questionContent?.statement,
              {
                transform: (node) => {
                  if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                    return <TeX math={`${node.attribs['data-value']}`} />;
                  }
                  if (node.type === 'tag' && node.name === 'img') {
                    return <img alt="question" style={{ maxHeight: '40vh', maxWidth: '40vh' }}
                      src={node.attribs.src}
                      className="cursor-pointer" onClick={() => {
                        setImgUrl(node.attribs.src);
                        setImageViewerVisibility(true);
                      }} />;
                  }
                }
              })}
          </div>
        </div>
      </div>
    )
  }

  const AnswerContainer = ({ answerType, answerContent, answerIndex, studentAnswerResult, type, questionContent, evaluationQuestionData }) => {
    if (answerType === 'MSQ' || answerType === 'MCQ') {
      return (
        <div className='p-l-20 p-r-20' style={{ borderRadius: 4 }}>
          {evaluationQuestionData?.submitStatus === 'Unanswered'
            ?
            <div style={{ display: 'flex', flexDirection: 'row-reverse', color: '#A2A2A2', margin: '3px 0px' }}>Unattempted <BsFillCircleFill style={{ alignSelf: 'center', margin: '0 3px', color: '#AEAEAE' }} /></div>
            :
            evaluationQuestionData?.submitStatus === 'NotVisited'
              ?
              <div style={{ display: 'flex', flexDirection: 'row-reverse', color: '#A2A2A2', margin: '3px 0px' }}>Unseen <RiCheckboxBlankCircleLine style={{ alignSelf: 'center', margin: '0 3px' }} /></div>
              :
              null
          }
          <div style={{ marginLeft: -20 }}>
            {Object.keys(questionContent)?.length ?

              questionContent?.options?.map((item, index) => {
                // const colorRed =
                console.log('2222', studentAnswerResult?.orQuestionIndex)
                let color = '#ffffff';
                let backgroundColor = '';
                let verdict = 'NA';
                let label = ''
                // let compAnswerArr = []
                if (type == "OrQuestions") {
                  if (answerType == 'MCQ') {
                    if (answerContent[answerIndex]?.answer == index) {
                      console.log('22221')
                      color = '#28df99'
                      backgroundColor = '#E9FCF5'
                      verdict = 'correct';
                    } else {
                      console.log('22222')
                      color = '#FF414D'
                      backgroundColor = '#FFE4D9'
                      verdict = 'incorrect';
                    }
                  }
                  if (answerType == 'MSQ') {
                    if (answerContent[answerIndex]?.answers.includes(index)) {
                      console.log('22223')
                      color = '#28df99'
                      backgroundColor = '#E9FCF5'
                      verdict = 'correct';
                    } else {
                      console.log('22224')
                      color = '#FF414D'
                      backgroundColor = '#FFE4D9'
                      verdict = 'incorrect';
                    }
                  }
                } else if (type == 'Comprehensive') {
                  answerContent?.map((item) => {
                    if (answerType == 'MCQ') {
                      if (item?.answer == index) {
                        verdict = 'correct';
                      } else {
                        verdict = 'incorrect';
                      }
                    }
                    if (answerType == 'MSQ') {
                      if (item?.answers.includes(index)) {
                        verdict = 'correct';
                      } else {
                        verdict = 'correct';
                      }
                    }
                  }
                  )

                } else {
                  if (answerType == 'MCQ') {
                    if (answerContent?.answer == index) {
                      console.log('22225')
                      color = '#28df99'
                      backgroundColor = '#E9FCF5'
                      verdict = 'correct';
                    } else {
                      console.log('22226')
                      color = '#FF414D'
                      backgroundColor = '#FFE4D9'
                      verdict = 'incorrect';
                    }
                  }
                  if (answerType == 'MSQ') {
                    if (answerContent?.answers.includes(index)) {
                      console.log('22227')
                      color = '#28df99'
                      backgroundColor = '#E9FCF5'
                      verdict = 'correct';
                    } else {
                      console.log('22228')
                      color = '#FF414D'
                      backgroundColor = '#FFE4D9'
                      verdict = 'incorrect';
                    }
                  }

                }
                let optionSeleted = false;
                if (answerIndex == studentAnswerResult?.orQuestionIndex) {
                  if (answerType == 'MCQ' && type != 'Comprehensive') {
                    optionSeleted = studentAnswerResult?.answer == index
                  } else if (answerType == 'MSQ' && type != 'Comprehensive') {
                    optionSeleted = studentAnswerResult?.answers.includes(index);
                  }
                } else if (answerIndex == studentAnswerResult?.[answerIndex]?.subQuestionIndex) {
                  if (answerType == 'MCQ') {
                    optionSeleted = studentAnswerResult?.[answerIndex].answer == index
                  } else if (answerType == 'MSQ') {
                    optionSeleted = studentAnswerResult?.[answerIndex].answers.includes(index);
                  }

                }

                if (!optionSeleted) {
                  if (verdict == 'correct') {
                    label = 'Correct Answer'
                  } else if (verdict == 'incorrect') {
                    label = 'Incorrect Answer'
                  }
                } else {
                  label = `Student's Answer`
                }

                console.log('verdict2222', verdict)
                return (
                  <div className='r-ac m-b-10 m-l-20' style={{ width: "100%", overflowX: "hidden" }} key={index}>
                    <div
                      style={{
                        background: verdict == 'correct' ? '#28df99' : verdict == 'incorrect' && label == 'Incorrect Answer' ? '' : '#FF414D',
                        color: verdict == 'incorrect' && label == 'Incorrect Answer' ? '#000000' : verdict != 'NA' ? '#ffffff' : '',
                        borderColor: verdict == 'correct' ? '#28df99' : verdict == 'incorrect' && label == 'Incorrect Answer' ? '' : '#FF414D'
                      }}
                      className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                      {index === 0 ? 'A' : index === 1 ? 'B' : index === 2 ? 'C' : index === 3 ? 'D' : "E"}
                    </div>
                    <div
                      style={{ background: verdict == 'correct' ? '#E9FCF5' : verdict == 'incorrect' && label == 'Incorrect Answer' ? '#FFF' : '#FFE4D9' }}
                      className='cursor-pointer test-ques-window p-10 r-c-sb flex-1'>
                      <div className='resize-editor-img'
                        style={{ width: '100%', overflowX: "hidden" }}>
                        {ReactHtmlParser(questionContent?.options[index].value,
                          {
                            transform: (node) => {
                              if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                return <TeX math={`${node.attribs['data-value']}`} />;
                              }
                              if (node.type === 'tag' && node.name === 'img') {
                                return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src}
                                  className="cursor-pointer"
                                  alt="" onClick={() => {
                                    setImgUrl(node.attribs.src);
                                    setImageViewerVisibility(true);
                                  }} />;
                              }
                            }
                          })}
                      </div>
                      <div keys={index.toString()} className='r-ac m-l-5'>
                        {((verdict === 'correct'))
                          ?
                          <RiCheckboxCircleLine className='text-sm seaGreen' />
                          : verdict == 'incorrect' && label == 'Incorrect Answer'
                            ?
                            null
                            :
                            <RiCloseCircleLine className='text-sm red' />
                        }
                        {verdict == 'incorrect' && label == 'Incorrect Answer'
                          ?
                          null
                          :
                          <div className='m-l-5 text-xs a2grey'>{label}</div>
                        }
                      </div>
                    </div>
                  </div>
                )
              }) : ""
            }
          </div>
        </div>
      )
    } else if (answerType === 'T/F') {
      let tfStudentsAnswer
      let tfCorrectAnswer
      if (type == 'Comprehensive') {
        tfStudentsAnswer = studentAnswerResult?.[answerIndex]?.answer
        tfCorrectAnswer = answerContent?.[answerIndex]?.answer
      } else if (type == 'OrQuestions') {
        tfStudentsAnswer = studentAnswerResult?.answer
        tfCorrectAnswer = answerContent?.[answerIndex]?.answer
      } else {
        tfStudentsAnswer = studentAnswerResult?.answer
        tfCorrectAnswer = answerContent?.answer
      }


      return (
        <div className='m-t-20'>
          {evaluationQuestionData?.submitStatus === 'Unanswered'
            ?
            <div style={{ display: 'flex', flexDirection: 'row-reverse', color: '#A2A2A2', margin: '3px 0px' }}>Unattempted <BsFillCircleFill style={{ alignSelf: 'center', margin: '0 3px', color: '#AEAEAE' }} /></div>
            :
            evaluationQuestionData?.submitStatus === 'NotVisited'
              ?
              <div style={{ display: 'flex', flexDirection: 'row-reverse', color: '#A2A2A2', margin: '3px 0px' }}>Unseen <RiCheckboxBlankCircleLine style={{ alignSelf: 'center', margin: '0 3px' }} /></div>
              :
              null
          }
          <div className='text-xmd bold-600 color-black m-b-5'>Options</div>
          <div style={{ display: 'flex' }}>
            <div className='test-ques-window r-ac m-r-10 p-5' style={{ width: '85%', background: tfCorrectAnswer === true ? '#E9FCF5' : '', }}>
              <div
                style={{ background: tfCorrectAnswer === true ? '#28df99' : '', color: tfCorrectAnswer === true ? '#fff' : '', borderColor: tfCorrectAnswer === true ? '#28df99' : '' }}
                className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                {'A'}
              </div>
              <div
                style={{ width: "90%", overflowX: "hidden" }}
                className='cursor-pointer p-10 r-c-sb'
              ><div>
                  True
                </div>
                {type === 'Comprehensive'
                  ?
                  (tfCorrectAnswer === true && tfStudentsAnswer === true)
                    ?
                    <div className='r-ac m-l-5'>
                      <RiCheckboxCircleLine className='text-sm seaGreen' />
                      <div className='m-l-5 text-xs a2grey'>Student's Answer</div>
                    </div>
                    :
                    (tfCorrectAnswer === true && tfStudentsAnswer !== true)
                      ?
                      <div className='r-ac m-l-5'>
                        <RiCheckboxCircleLine className='text-sm seaGreen' />
                        <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
                      </div>
                      :
                      null
                  :
                  (tfCorrectAnswer === true && tfStudentsAnswer === true)
                    ?
                    <div className='r-ac m-l-5'>
                      <RiCheckboxCircleLine className='text-sm seaGreen' />
                      <div className='m-l-5 text-xs a2grey'>Student's Answer</div>
                    </div>
                    :
                    (tfCorrectAnswer === true && tfStudentsAnswer !== true)
                      ?
                      <div className='r-ac m-l-5'>
                        <RiCheckboxCircleLine className='text-sm seaGreen' />
                        <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
                      </div>
                      :
                      null

                }
              </div>
            </div>
            <div className='test-ques-window r-ac p-5' style={{ width: '85%', background: answerContent?.[answerIndex]?.answer === false ? '#E9FCF5' : '', }}>
              <div
                style={{ background: tfCorrectAnswer === false ? '#28df99' : '', color: tfCorrectAnswer === false ? '#fff' : '', borderColor: tfCorrectAnswer === false ? '#28df99' : '' }}
                className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                {'B'}
              </div>
              <div
                style={{ width: "100%", overflowX: "hidden" }}
                className='cursor-pointer p-10 r-c-sb'
              >
                <div>
                  False
                </div>
                {type === 'Comprehensive'
                  ?
                  (tfCorrectAnswer === false && tfStudentsAnswer === false)
                    ?
                    <div className='r-ac m-l-5'>
                      <RiCheckboxCircleLine className='text-sm seaGreen' />
                      <div className='m-l-5 text-xs a2grey'>Student's Answer</div>
                    </div>
                    :
                    (tfCorrectAnswer === false && tfStudentsAnswer !== false)
                      ?
                      <div className='r-ac m-l-5'>
                        <RiCheckboxCircleLine className='text-sm seaGreen' />
                        <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
                      </div>
                      :
                      null
                  :
                  (tfCorrectAnswer === false && tfStudentsAnswer === false)
                    ?
                    <div className='r-ac m-l-5'>
                      <RiCheckboxCircleLine className='text-sm seaGreen' />
                      <div className='m-l-5 text-xs a2grey'>Student's Answer</div>
                    </div>
                    :
                    (tfCorrectAnswer === false && tfStudentsAnswer !== false)
                      ?
                      <div className='r-ac m-l-5'>
                        <RiCheckboxCircleLine className='text-sm seaGreen' />
                        <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
                      </div>
                      :
                      null

                }
              </div>
            </div>

          </div>
        </div>
      )
    } else if (answerType === 'Numeric') {
      let answerTypeVar
      let studentsDisplayAnswer
      let correctDisplayAnswer
      if (type == 'Comprehensive') {
        answerTypeVar = (studentAnswer?.[answerIndex]?.answer === answerContent?.[answerIndex]?.answer) ? 1 : 0
        studentsDisplayAnswer = studentAnswer?.[answerIndex]?.answer
        correctDisplayAnswer = answerContent?.[answerIndex]?.answer
      } else if (type == 'OrQuestions') {
        answerTypeVar = (studentAnswer?.answer === answerContent?.[answerIndex]?.answer) ? 1 : 0
        studentsDisplayAnswer = studentAnswer?.answer
        correctDisplayAnswer = answerContent?.[answerIndex]?.answer
      } else {
        answerTypeVar = studentAnswer?.answer === answerContent?.answer ? 1 : 0
        studentsDisplayAnswer = studentAnswer?.answer
        correctDisplayAnswer = answerContent?.answer
      }


      return (
        <div className='p-20 m-b-20' style={{ borderRadius: 4 }}>
          {evaluationQuestionData?.submitStatus === 'Unanswered'
            ?
            <div style={{ display: 'flex', flexDirection: 'row-reverse', color: '#A2A2A2', margin: '3px 0px' }}>Unattempted <BsFillCircleFill style={{ alignSelf: 'center', margin: '0 3px', color: '#AEAEAE' }} /></div>
            :
            evaluationQuestionData?.submitStatus === 'NotVisited'
              ?
              <div style={{ display: 'flex', flexDirection: 'row-reverse', color: '#A2A2A2', margin: '3px 0px' }}>Unseen <RiCheckboxBlankCircleLine style={{ alignSelf: 'center', margin: '0 3px' }} /></div>
              :
              null
          }
          {
            answerTypeVar === 1 && evaluationQuestionData?.submitStatus !== 'Unanswered' && evaluationQuestionData?.submitStatus !== 'Bookmarked'
              ?
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className='r-c-sb cursor-pointer m-b-10' style={{ background: "rgb(233, 252, 245)", borderColor: '#EC5254', borderRadius: 5, height: 50 }}>
                  <div className='text-xmd m-l-10'> {studentsDisplayAnswer} </div>
                  <div className="m-r-10 text-xs a2grey">
                    <RiCheckboxCircleLine className='text-sm seaGreen' /> Student's Answer
                  </div>
                </div>
              </div>
              :
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className='r-c-sb cursor-pointer m-b-10' style={{ background: "rgb(233, 252, 245)", borderColor: '#EC5254', borderRadius: 5, height: 50 }}>
                  <div className='text-xmd m-l-10'> {correctDisplayAnswer} </div>
                  <div className="m-r-10 text-xs a2grey">
                    <RiCheckboxCircleLine className='text-sm seaGreen' /> Correct Answer
                  </div>
                </div>
                {
                  evaluationQuestionData?.submitStatus !== 'Unanswered' && evaluationQuestionData?.submitStatus !== 'Bookmarked' && evaluationQuestionData?.submitStatus !== 'NotVisited'
                    ?
                    <div className='r-c-sb cursor-pointer' style={{ background: '#FFE4D9', borderColor: '#EC5254', borderRadius: 5, height: 50 }}>
                      <div className='text-xmd m-l-10'>{studentsDisplayAnswer}</div>
                      <div className="m-r-10 text-xs a2grey">
                        <RiCloseCircleLine className='text-sm red' /> Student's Answer
                      </div>
                    </div>
                    :
                    <div className='r-c-sb cursor-pointer' style={{ background: 'rgb(174 174 174)', borderColor: '#EC5254', borderRadius: 5, height: 50 }}>
                      <div className='text-xs m-l-10' style={{ color: "#fafafa" }}>Unanswered</div>
                      <div className="m-r-10 text-xs a2grey" style={{ color: "#fafafa" }}>
                        Student's Answer
                      </div>
                    </div>
                }
              </div>
          }
        </div>
      )
    } {
      return (
        null
      )
    }
  }
  console.log('questionData', questionData)

  if (type === 'Comprehensive') {
    return (
      <>
        <QuestionConatiner setImgUrl={setImgUrl} setImageViewerVisibility={setImageViewerVisibility} questionContent={questionData?.questionContent} type={type} />
        <AnswerContainer answerContent={answerData} answerIndex={answerIndex} answerType={answerType} studentAnswerResult={studentAnswer} type={type} questionContent={questionData?.questionContent} evaluationQuestionData={evaluationQuestionData} />
      </>
    )
  } else if (type === 'OrQuestions') {
    return (
      <>
        <QuestionConatiner setImgUrl={setImgUrl} setImageViewerVisibility={setImageViewerVisibility} questionContent={questionData?.questionContent} />
        <AnswerContainer answerContent={answerData} answerIndex={answerIndex} answerType={answerType} studentAnswerResult={studentAnswer} type={type} questionContent={questionData?.questionContent} evaluationQuestionData={evaluationQuestionData} />
      </>
    )
  } else {
    return (
      <>
        <QuestionConatiner setImgUrl={setImgUrl} setImageViewerVisibility={setImageViewerVisibility} questionContent={questionData} type={type} />
        <AnswerContainer answerContent={answerData} answerIndex={answerIndex} answerType={answerType} studentAnswerResult={studentAnswer} type={type} questionContent={questionData} evaluationQuestionData={evaluationQuestionData} />
      </>
    )
  }
}

const ParagraphContainer = ({ setImageViewerVisibility, setImgUrl, paragraph }) => {
  console.log('paragraph', paragraph)
  return (
    <div className='r-jsb m-t-20'>
      <div className='display-flex flex-1'>
        <div className='text-xmd color-black resize-editor-img'
          style={{ width: '100%', overflowX: "hidden", paddingLeft: '20px' }}>
          {ReactHtmlParser(paragraph,
            {
              transform: (node) => {
                if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                  return <TeX math={`${node.attribs['data-value']}`} />;
                }
                if (node.type === 'tag' && node.name === 'img') {
                  return <img alt="question" style={{ maxHeight: '40vh', maxWidth: '40vh' }}
                    src={node.attribs.src}
                    className="cursor-pointer" onClick={() => {
                      setImgUrl(node.attribs.src);
                      setImageViewerVisibility(true);
                    }} />;
                }
              }
            })}
        </div>
      </div>
    </div>
  )
}

function StudSubmittedQueScreen({ history,
  submittedTestsByStudentId,
  submittedTestsDetails,
  currentQuestionIndex,
  updateCurrentQuestionIndex,
  fetchSubmittedTestQuestionsForQueGrid,
  fetchSubmittedTestQuestionUsingQuestionId,
  fetchSubmittedTestByStudentId,
  evaluatedMark,
  evaluatedComment,
  updateEvaluatedMark,
  updateEvaluatedComment,
  evaluationPageQuestionGrid,
  updateEvaluationQuestionId,
  evaluationQuestionData,
  saveEvaluation,
  increaseQuestionId,
  getEvaluationPageBool,
  evaluationQuestionId,
  universalLoader,
  totalMarksObtained,
  updateTotalMarksObtained,
  updatePalette,
  reRenderPalette,
  reRenderLegend,
  userType
}) {

  const [pageLoader, togglePageLoader] = useState(false);
  const [isImageViewerVisible, setImageViewerVisibility] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  //regex validators

  const isValidMark = (val) => {
    let pattern1 = /[^\d.]+/;
    let pattern2 = /^[^.]+(\.{0}[^.]*|\.{1}[^.]{0,2})$/;
    console.log("pattern1", pattern1, pattern2, val,pattern2.test(val) && !pattern1.test(val))
    return val === "" || (pattern2.test(val) && !pattern1.test(val));
  }


  useEffect(() => {
    togglePageLoader(true);
    fetchSubmittedTestQuestionsForQueGrid(() => {
      fetchSubmittedTestQuestionUsingQuestionId((bool) => togglePageLoader(bool))
    }, "fromResult");
    return () => {
      setImageViewerVisibility(false);
      setImgUrl("");
    }
  }, [])
  const answerStatus = evaluatedMark == evaluationQuestionData.positiveMarks ? 'Correct'
    : evaluatedMark <= 0 ? 'Incorrect' : evaluatedMark < evaluationQuestionData.positiveMarks
      ? 'PartialCorrect' : '';

  const handleNext = () => {
    if (submittedTestsDetails.testType == "Objective" || (submittedTestsDetails.testType !== "Objective" && submittedTestsByStudentId?.resultsPublished === true && userType != 'admin')) {
      togglePageLoader(true);
      if (currentQuestionIndex < submittedTestsByStudentId?.questionCount - 1) {
        increaseQuestionId(() => {
          updateCurrentQuestionIndex(currentQuestionIndex + 1);
          fetchSubmittedTestQuestionUsingQuestionId((bool) => {
            togglePageLoader(bool)
          })
        })
      }
      else {
        togglePageLoader(false)
        history.goBack()
      }
    } else {
      if (evaluatedMark !== '') {
        togglePageLoader(true)
        saveEvaluation((boolValue) => {
          if (currentQuestionIndex < submittedTestsByStudentId?.questionCount - 1) {
            increaseQuestionId(() => {
              updateCurrentQuestionIndex(currentQuestionIndex + 1);
              updateEvaluatedComment('');
              fetchSubmittedTestQuestionsForQueGrid(() => {
                fetchSubmittedTestQuestionUsingQuestionId((bool) => {
                  togglePageLoader(bool)
                })
              }, "fromEval");
            })
          }
          else {
            togglePageLoader(false)
            history.goBack()
          }
        }, answerStatus)
      }
      else {
        Notification.error("Error", 'Please assign a mark to the question');
      }
    }
  }

  return (
    <div style={{ height: '100%', margin: '-20px' }}>
      <TopNavigationColorHeader history={history} type={'studentAnswer'} />
      <div className='display-flex' style={{ height: 'calc(100% - 20px)' }}>
        <div className='position-relative' style={{ width: "80%" }}>
          <div className='overflow-scroll' style={{ height: 'calc(100% - 20px)' }}>
            <Row1 history={history}
              submittedTestsByStudentId={submittedTestsByStudentId}
              totalMarksObtained={totalMarksObtained} />
            <div className='horizontal-divider'></div>
            <Row3 currentQuestionIndex={currentQuestionIndex}
              evaluationQuestionData={evaluationQuestionData}
              evaluatedMark={evaluatedMark}
              updateEvaluatedMark={updateEvaluatedMark}
              isValidMark={isValidMark}
              evaluationQuestionId={evaluationQuestionId}
              resultsPublished={submittedTestsByStudentId?.resultsPublished}
              userType={userType}
              updateTotalMarksObtained={updateTotalMarksObtained} />
            <Row2 evaluationQuestionData={evaluationQuestionData}
              evaluationPageQuestionGrid={evaluationPageQuestionGrid}
              updateEvaluatedComment={updateEvaluatedComment}
              updateEvaluationQuestionId={updateEvaluationQuestionId}
              fetchSubmittedTestQuestionUsingQuestionId={fetchSubmittedTestQuestionUsingQuestionId}
              updateCurrentQuestionIndex={updateCurrentQuestionIndex}
              togglePageLoader={togglePageLoader} />

            {evaluationQuestionData?.type === 'Comprehensive'
              ?
              <ParagraphContainer setImageViewerVisibility={setImageViewerVisibility} setImgUrl={setImgUrl} paragraph={evaluationQuestionData?.questionContent?.paragraph} />
              :
              null}
            {evaluationQuestionData?.type === 'OrQuestions'
              ?
              evaluationQuestionData?.questionContent?.orQuestions?.map((item, index) => (
                <>
                  <QuesAnsResultContainer setImageViewerVisibility={setImageViewerVisibility} setImgUrl={setImgUrl} answerData={evaluationQuestionData?.answerContent} studentAnswer={evaluationQuestionData?.answerObj} questionData={item} type={evaluationQuestionData?.type} answerType={item?.type} answerIndex={index} evaluationQuestionData={evaluationQuestionData} orQuestionIndexChar={item?.orQuestionIndexChar} />
                  {index != evaluationQuestionData?.questionContent?.orQuestions.length - 1 ?
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20, paddingTop: '30px' }}>
                      <div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6', padding: '0 5px' }}></div>
                      <span style={{ marginTop: -10, padding: '0px 10px', color: '#AEAEAE' }}>OR</span>
                      <div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6' }}></div>
                    </div>
                    : null
                  }
                </>

              ))
              :
              evaluationQuestionData?.type === 'Comprehensive'
                ?
                evaluationQuestionData?.questionContent?.subQuestions?.map((item, index) => (
                  <QuesAnsResultContainer setImageViewerVisibility={setImageViewerVisibility} setImgUrl={setImgUrl} answerData={evaluationQuestionData?.answerContent} studentAnswer={evaluationQuestionData?.answerObj} questionData={item} type={evaluationQuestionData?.type} answerType={item?.type} answerIndex={index} evaluationQuestionData={evaluationQuestionData} />
                ))
                :
                <QuesAnsResultContainer setImageViewerVisibility={setImageViewerVisibility} setImgUrl={setImgUrl} answerData={evaluationQuestionData?.answerContent} studentAnswer={evaluationQuestionData?.answerObj} questionData={evaluationQuestionData?.questionContent} answerType={evaluationQuestionData?.type} evaluationQuestionData={evaluationQuestionData} />
            }

            {evaluationQuestionData.type === "Subjective"

              ? <RowSubjective evaluatedComment={evaluatedComment}
                updateEvaluatedComment={updateEvaluatedComment}
                evaluationQuestionData={evaluationQuestionData}
                evaluationQuestionId={evaluationQuestionId}
                setImageViewerVisibility={setImageViewerVisibility}
                setImgUrl={setImgUrl} />

              :
              null
            }

            <Row6 evaluationQuestionData={evaluationQuestionData} setImgUrl={setImgUrl} setImageViewerVisibility={setImageViewerVisibility} />
          </div>
          <div className='p-20 test-save-sticky-footer r-c-sb'>
            <div className='r-ac' style={{ opacity: currentQuestionIndex == 0 ? 0.5 : 1 }}>
            </div>
            <div className='text-xmd bold-600 blue' style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              {submittedTestsDetails.testType === "Objective" ?
                <div style={{ cursor: currentQuestionIndex === 0 ? 'not-allowed' : 'pointer', opacity: currentQuestionIndex === 0 ? 0.5 : 1 }} onClick={() => {
                  if (currentQuestionIndex > 0) {
                    increaseQuestionId(() => {
                      updateCurrentQuestionIndex(currentQuestionIndex - 1);
                      fetchSubmittedTestQuestionUsingQuestionId((bool) => {
                        togglePageLoader(bool)
                      })
                    })
                  }
                }}>
                  Prev
                </div>
                : " "}
              <div className='r-ac' style={{}}>
                <div className='text-xmd bold-600 blue cursor-pointer' onClick={handleNext} style={{ marginLeft: 380 }}>
                  {
                    submittedTestsDetails.testType != "Objective" ?
                      <>
                        {(submittedTestsDetails.testType != "Objective" && (currentQuestionIndex === submittedTestsByStudentId?.questionCount - 1)) ? "Save" : "Save & Next"}
                      </>
                      :
                      <>
                      </>
                  }
                </div>
              </div>
              {submittedTestsDetails.testType === "Objective" ?
                <div style={{ opacity: currentQuestionIndex === submittedTestsByStudentId?.questionCount - 1 ? 0.5 : 1, cursor: currentQuestionIndex === submittedTestsByStudentId?.questionCount - 1 ? "not-allowed" : "pointer" }} onClick={() => {
                  if (currentQuestionIndex < submittedTestsByStudentId?.questionCount - 1) {
                    increaseQuestionId(() => {
                      updateCurrentQuestionIndex(currentQuestionIndex + 1);
                      fetchSubmittedTestQuestionUsingQuestionId((bool) => {
                        togglePageLoader(bool)
                      })
                    })
                  }
                }}>
                  Next
                </div>
                : ""}
            </div>
            <div className='r-ac' style={{ opacity: currentQuestionIndex == submittedTestsByStudentId?.questions?.length - 1 ? 0.5 : 1 }}>
            </div>
          </div>
        </div>
        <div className='vertical-divider'></div>
        <div className='p-20 overflow-scroll' style={{ width: "20%" }}>
          <div className='r-ac studsubmittedlegend'>
            <ColorLegend reRenderLegend={reRenderLegend} bgcolor={'#28DF99'} name={'Correct'} submittedTestsByStudentId={submittedTestsByStudentId} />
            <ColorLegend reRenderLegend={reRenderLegend} bgcolor={'#FF414D'} name={'Incorrect'} submittedTestsByStudentId={submittedTestsByStudentId} />
          </div>
          <div className='r-ac studsubmittedlegend'>
            <ColorLegend reRenderLegend={reRenderLegend} bgcolor={'#AEAEAE'} name={'Unattempted'} submittedTestsByStudentId={submittedTestsByStudentId} />
            <ColorLegend reRenderLegend={reRenderLegend} bgcolor={'#fff'} name={'Unseen'} submittedTestsByStudentId={submittedTestsByStudentId} />
          </div>
          <div className='r-ac studsubmittedlegend'>
            <ColorLegend reRenderLegend={reRenderLegend} bgcolor={'#AEAEAE'} name={'Partially correct'} submittedTestsByStudentId={submittedTestsByStudentId} />
          </div>
          {
            submittedTestsByStudentId?.testType == 'Subjective' ?
              <>
                {/* <div className='m-b-10'>
                  <ColorLegend reRenderLegend={reRenderLegend} bgcolor={'#AEAEAE'} name={'Partially correct'} submittedTestsByStudentId={submittedTestsByStudentId} />
                </div> */}
                <div className='m-b-10'>
                  <ColorLegend reRenderLegend={reRenderLegend} bgcolor={'rgb(246, 244, 255)'} name={'Not evaluated'} submittedTestsByStudentId={submittedTestsByStudentId} />
                </div>
              </>
              :
              null
          }

          <div className='text-xs a2grey bold-600 m-b-20'>ALL QUESTIONS</div>
          <QueGridSide submittedTestsByStudentId={submittedTestsByStudentId}
            currentQuestionIndex={currentQuestionIndex}
            updateEvaluationQuestionId={updateEvaluationQuestionId}
            evaluationPageQuestionGrid={evaluationPageQuestionGrid}
            fetchSubmittedTestQuestionUsingQuestionId={fetchSubmittedTestQuestionUsingQuestionId}
            togglePageLoader={togglePageLoader}
            updateCurrentQuestionIndex={updateCurrentQuestionIndex}
            evaluationQuestionData={evaluationQuestionData}
            reRenderPalette={reRenderPalette}
            updateEvaluatedComment={updateEvaluatedComment}
          />
        </div>
      </div>
      {
        isImageViewerVisible && (
          <ImageViewer isPreviewModalVisible={isImageViewerVisible}
            image={imgUrl} setIsPreviewModalVisible={setImageViewerVisibility} />
        )
      }
      <TextEditorModal />
      <TestPublishModal />
      <PageDataLoader visible={getEvaluationPageBool} />
    </div>
  );
}


const mapStateToProps = state => {
  const { submittedTestsByStudentId,
    submittedTestsDetails,
    currentQuestionIndex,
    evaluatedMark,
    evaluatedComment,
    evaluationPageQuestionGrid,
    evaluationQuestionData,
    getEvaluationPageBool,
    evaluationQuestionId,
    totalMarksObtained,
    reRenderPalette,
    reRenderLegend, userType } = state.testAdmin;
  return {
    submittedTestsByStudentId,
    submittedTestsDetails,
    currentQuestionIndex,
    evaluatedMark,
    evaluatedComment,
    evaluationPageQuestionGrid,
    evaluationQuestionData,
    getEvaluationPageBool,
    evaluationQuestionId,
    totalMarksObtained,
    reRenderPalette,
    reRenderLegend,
    userType
  }
};

const mapDispatchToProps = dispatch => ({
  increaseQuestionId: (callback) => dispatch(increaseQuestionId(callback)),
  saveEvaluation: (callback, answerstatus) => dispatch(saveEvaluation(callback, answerstatus)),
  fetchSubmittedTestQuestionsForQueGrid: (callback, type) => dispatch(fetchSubmittedTestQuestionsForQueGrid(callback, type)),
  fetchSubmittedTestQuestionUsingQuestionId: (callback) => dispatch(fetchSubmittedTestQuestionUsingQuestionId(callback)),
  updateEvaluationQuestionData: value => dispatch(updateEvaluationQuestionData(value)),
  updateEvaluationQuestionId: value => dispatch(updateEvaluationQuestionId(value)),
  updateCurrentQuestionIndex: value => dispatch(updateCurrentQuestionIndex(value)),
  updateEvaluatedMark: value => dispatch(updateEvaluatedMark(value)),
  updateEvaluatedComment: value => dispatch(updateEvaluatedComment(value)),
  universalLoader: (name, varname, bool) => dispatch(universalLoader(name, varname, bool)),
  updateTotalMarksObtained: (marks) => dispatch(updateTotalMarksObtained(marks)),
  updatePalette: (qInd, newType) => dispatch(updatePalette(qInd, newType))
});

export default connect(mapStateToProps, mapDispatchToProps)(StudSubmittedQueScreen);