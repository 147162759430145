const CONSUMPTION_SKELETON = {
    '360': 0,
    '480': 0,
    '720': 0
};

const MODULES = ['LIVE', 'DIGITALLIBRARY', 'RECORDING'];
const STORAGE_LABEL = 'consumptionData';

const getInitialSkeleton = (module) => {
    if (module){
        return {...CONSUMPTION_SKELETON, type:module, time: new Date().getTime()}
    }
}

export default class ConsumptionTracker {
    constructor() {
        if (ConsumptionTracker._instance) {
            return ConsumptionTracker._instance;
        }
        ConsumptionTracker._instance = this;
        this.reset();
    }

    reset() {
        this.previousConsumptionInBytes = 0;
        this.currentConsumptionInBytes = 0;
        this.videoDurationIncrement = 1000;
        this.currentDuration = {};
        this.previousDuration = {};
        this.isSaved = true;
        this.lastSnapShotTime = 0;
    }
    getDurationData(){
        return this.currentDuration;
    }
    loadFromStorage() {
        const serializedState = localStorage.getItem(STORAGE_LABEL);
        if (serializedState == null || serializedState === false) return;
        const data = JSON.parse(serializedState);
        if (data) {
            this.currentDuration = data.data;
            this.isSaved = data.isSaved;
        }
        console.log("consumptiontrackerloadeddatafromstorage", data)
    }

    saveToStorage(data) {
        const localData = JSON.stringify({isSaved: this.isSaved, data: data});
        localStorage.setItem(STORAGE_LABEL, localData);
        console.log("consumptiontrackersavedtostorage", localData)
    }

    recordStorage() {
        this.saveToStorage(this.currentDuration);
        this.lastSnapShotTime = 0;
    }

    setVideoDurationIncrement(value) {
        this.videoDurationIncrement = value;
    }

    async incrementIntervalProgress({id, module='DIGITALLIBRARY', resolution=720}){
        this.lastSnapShotTime += this.videoDurationIncrement
        if (!(id in this.currentDuration)){
            this.currentDuration[id] = getInitialSkeleton(module)
            this.currentDuration[id].received = 0;
            this.currentDuration[id].sent = 0;
        }
        this.currentDuration[id][resolution] += this.videoDurationIncrement
        this.isSaved = false
        if (this.lastSnapShotTime>15000){
            this.recordStorage()
        }
        console.log("duration is", this.currentDuration, this.lastSnapShotTime)
    }

    recordInterval() {
        this.previousDuration = JSON.parse(JSON.stringify(this.currentDuration));
    }
}
