import React, { useState } from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";

const ConfirmSubmitModal = ({
    setIsSubmitModalVisible,
    isSubmitModalVisible,
    postKycDetails,
    toggleSubmitSuccesModal,
    params,
}) => {

    const [isSubmitYesLoading,setIsSubmitYesLoading]=useState(false);

   const handleOk = () => {
    setIsSubmitModalVisible(false);
  };
  const handleCancel = () => {
    setIsSubmitModalVisible(false);

  };

  return (
    <>
      <Modal
        centered
        className="modal-round-corner"
        visible={isSubmitModalVisible}
        onCancel={handleCancel}
        header={null}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "25px",
          }}
        >
          <div
            style={{ fontSize: "24px", color: "#191919", fontWeight: "bold" }}
          >
            Confirm
          </div>
          <div
            style={{
              fontSize: "16px",
              color: "#636363",
              fontWeight: "bold",
              marginTop: "10px",
            }}
          >
            Are you sure you want to submit ?
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "30px",
          }}
        >
          <div>
            <Button
              style={{
                borderRadius: "19px",
                width: "120px",
                height: "35px",
                border: "1px solid  #636363",
                backgroundColor: "#FFFFFF",
                color: "#594099",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
                onClick={handleOk}
              >
                NO
              </div>
            </Button>
          </div>
          <div>
            <Button
            loading={isSubmitYesLoading}
              style={{
                borderRadius: "19px",
                width: "120px",
                height: "35px",
                border: "1px solid #594099 ",
                backgroundColor: "#594099",
                fontSize: "16px",
                color: "#FFFFFF",
                fontWeight: "bold",
              }}
            //   onClick={(e)=>{
            //       e.stopPropagation()
            //       setIsApproveLoading(true);
            //     setCardId(id);
            //     setActionData("Approved");
            //     requestedActionData(
            //     ()=>{
            //         setIsApproveLoading(false)
            //     },
            //     ()=>{
            //         setIsApproveModalVisible(false);
            //         if(setIsButtonDivVisible){
            //             setIsButtonDivVisible(false)}
            //         togglePageLoader(true)
            //         functionProp(()=>{
            //             togglePageLoader(false)
            //         })
            //         // if(inDifferentPage===0){
            //         //     getPrevilegedViewData(()=>{
            //         //     togglePageLoader(false)})
            //         //  }else if(inDifferentPage===1){
            //         //     getLeaveRequestData(()=>{
            //         //         togglePageLoader(false)});
            //         // }else if(inDifferentPage===2){
            //         //     getLeavesTabData(()=>{
            //         //         togglePageLoader(false)});
            //         // }
            //     } 
            //     )
            //   }}
            onClick={(e)=>{
                e.stopPropagation();
                setIsSubmitYesLoading(true);
                postKycDetails(params,()=>{setIsSubmitYesLoading(false)},()=>{
                    setIsSubmitModalVisible(false)
                    toggleSubmitSuccesModal(true)
                })
            }}
            >
                YES
            </Button>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default ConfirmSubmitModal;