import { Dropdown } from 'antd'
import { InputSearch } from 'components/customInputs'
import React from 'react'

function SearchFilterComp({ style, placeholder, handleSearch, menu, open, handleFilterIconClick ,value, overlayStyle, placement }) {
 
    return (
        <div className='display-flex m-r-10' style={{ width: '100%' }}>
            <InputSearch
                allowClear={false}
                value = {value}
                placeholder={placeholder}
                onEnter={(e) => { handleSearch(e) }}
                style={style} />
            <Dropdown overlay={menu} trigger={['click']} open={open} overlayStyle={overlayStyle || {}} placement={placement}>
                <img style={{ width: 28, marginLeft: -30, zIndex: 100, cursor: "pointer" }} src={require('../../../Assets/common/misc/filter.svg').default} onClick={handleFilterIconClick} />
            </Dropdown>
        </div>
    )
}

export default SearchFilterComp