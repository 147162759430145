import { ReloadOutlined } from '@ant-design/icons';
import { postEmailLogIn, postGenerateOtp, postGenerateRegistrationOtp, postRegisterDetails, postVerifyOtp, sendForgotLink, storePhoneNumber } from 'actions/loginNew';
import { Col, Row, Input } from 'antd'
import { Heading, SmallHeading, SubHeading } from 'components/Typography'
import React, { useEffect, useState } from 'react'
import PhoneInput from "react-phone-number-input";
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Notification } from 'services';
import EmailSignInForm from './emailSignInForm';
import CaptchaComponent from './captchaComponent'
import './styles.css';
import ReCaptchaV3 from './reCaptchaV3';
import { Button } from 'components/Button';
// import { validateCaptcha, loadCaptchaEnginge } from 'react-simple-captcha';

const defaultImg = require("../../Assets/Icon awesome-user-alt.svg").default

const TermsPolicy = () => (
    <div className="m-t-10 text-center" style={{ fontSize: 16 }}>
        <span className='text-xs' style={{ color: "#636363" }}>By continuing, you agree to the</span>
        <a className='text-xs blue m-l-5 m-r-5 bold-600' href="https://aulas.in/terms-conditions" target="_blank" rel="noreferrer">
            Terms & Conditions
        </a>
        <span className='text-xs' style={{ color: "#636363" }}>and</span>
        <a className='text-xs blue m-l-5 m-r-5 bold-600' href="https://aulas.in/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
        </a>
    </div>
);

function SignInForm({ postGenerateOtp, postVerifyOtp, resendOtpTimer, timer, storePhoneNumber, postEmailLogIn, sendForgotLink, showVerifyOtpForm, setShowVerifyOtpForm, userRegister, postRegisterDetails, postGenerateRegistrationOtp, setShowRegisterForm, setPhoneNumber, phoneNumber, isUserRegistered, setUserRegister, setIsEditClicked }) {
    const [verifyLoader, setVerifyLoader] = useState(false)
    const [showInValidOtp, setShowInvalidOtp] = useState(false);
    const [storeTypedOtp, setStoreTypedOtp] = useState('');
    const [sendOtpLoaderToggle, setSendOtpLoaderToggle] = useState(false);
    const [emailIdSignIn, setEmailIdSignIn] = useState(false);
    const [emailLoader, setEmailLoader] = useState(false);
    const [forGotPressed, setForgotPressed] = useState(false);
    const [userEmailRegister, setUserEmailRegister] = useState({
        email: "",
        password: "",
        isWebLogin: true
    });
    const [text, setText] = useState('');
    const history = useHistory();
    useEffect(() => {
        setText('')
    }, [])
    const sitekey = '6LcAkU4pAAAAANllAlCwLHYgaGPsqk4-KF9PKnT5';
    const { executeReCaptcha } = ReCaptchaV3({ sitekey });

    const handleNumberSubmit = async () => {
        if (!phoneNumber) {
        } else {
            setVerifyLoader(true);
            try {
                const recaptchaToken = await executeReCaptcha('mobileLogin');
                postGenerateOtp(
                    recaptchaToken,
                    () => { setVerifyLoader(false); setStoreTypedOtp('') },
                    (res) => {
                        setShowVerifyOtpForm(res);
                    })
            } catch (error) {
                console.error('Error executing ReCaptcha:', error);
                setVerifyLoader(false);
            }
        }
    };
    const handleVerifyOtpSubmit = () => {
        if ((storeTypedOtp?.length > 5)) {
            setVerifyLoader(true)
            postVerifyOtp(
                storeTypedOtp,
                history,
                () => { setVerifyLoader(false); setText('') },
                () => {
                    setUserRegister({
                        role: "",
                        name: "",
                        email: "",
                        preferredClass: "",
                        preferredCourseId: "",
                        image: defaultImg
                    });
                    setIsEditClicked(false);
                    if (!isUserRegistered) {
                        postRegisterDetails(
                            userRegister,
                            () => { },
                            () => { setShowVerifyOtpForm(false); setPhoneNumber('') },
                            history,
                        )
                    } else { }
                },
                (res) => {
                    if (res === 'error1') {
                        setPhoneNumber('');
                        setStoreTypedOtp('')
                        setEmailIdSignIn(false);
                        setShowVerifyOtpForm(false);
                    } else if (res === 'error2') {
                        setShowInvalidOtp(true);
                    } else {
                        setShowInvalidOtp(false)
                    }
                }
            )
        } else { }
    }
    const handleEmailSubmit = async () => {
        if (!userEmailRegister?.email || !userEmailRegister?.password) {
        } else {
            setEmailLoader(true);
            try {
                const recaptchaToken = await executeReCaptcha('emailLogin');
                postEmailLogIn(
                    recaptchaToken,
                    userEmailRegister,
                    history,
                    () => { setEmailLoader(false) })
            } catch (error) {
                console.error('Error executing ReCaptcha:', error);
                setVerifyLoader(false);
            }
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleNumberSubmit()
        }
    };

    const CustomCountrySelect = () => (
        <div className="custom-country-select">
            <span className="indian-flag-icon" />
        </div>
    );
    const handleOtpVerifyKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleVerifyOtpSubmit();
        }
    };

    return (
        <div className={`form-container ${emailIdSignIn ? 'slide-in' : 'slide-out'}`}>
            {emailIdSignIn ?
                <>
                    <Row className='login-signIn-form-parent' justify="center">
                        <Col flex="auto" align="center">
                            <Heading bold style={{ margin: 0, marginTop: 5 }}>
                                Sign in using Email ID
                            </Heading>
                            <SubHeading className='login-subHeading-div' color="#636363">
                                Enter your credentials to access your account
                            </SubHeading>
                        </Col>
                    </Row>
                    <Row justify="center" style={{ paddingBottom: forGotPressed ? 175 : 30 }}>
                        <div className='signIn-emailForm-width'>
                            <EmailSignInForm
                                userEmailRegister={userEmailRegister}
                                setUserEmailRegister={setUserEmailRegister}
                                forGotPressed={forGotPressed}
                                setForgotPressed={setForgotPressed}
                                sendForgotLink={sendForgotLink}
                                handleEmailSubmit={handleEmailSubmit}
                                text={text}
                                setText={setText} />
                        </div>
                    </Row>
                    <Row justify="center">
                        <Col flex="auto" align="center">
                            <div className="m-t-20 m-b-20">
                                {emailIdSignIn && !forGotPressed ?
                                    <Button
                                        loading={emailLoader}
                                        type="primary"
                                        shape="round"
                                        className="r-c-c bold-bold"
                                        style={{ opacity: (userEmailRegister?.email == "" || userEmailRegister?.password == "") ? 0.5 : 1, cursor: (userEmailRegister?.email == "" || userEmailRegister?.password == "") ? "not-allowed" : "pointer", width: "50%", marginTop: -40 }}
                                        onClick={handleEmailSubmit}
                                    >
                                        {"SIGN IN"}
                                    </Button>
                                    :
                                    <></>
                                }
                            </div>
                            <div style={{ fontSize: 14, color: "#636363" }}>Sign in or register using <span className='text-xs blue m-l-5 m-r-5 bold-600 cursor-pointer' style={{ fontSize: 14 }} onClick={() => { setEmailIdSignIn(false); setShowVerifyOtpForm(false); setText('') }}> Mobile number</span></div>
                        </Col>
                    </Row>
                </>
                :
                <>
                    <Row className='login-signIn-heading-div' justify="center" >
                        <Col flex="auto" align="center">
                            <Heading bold style={{ margin: 0, marginTop: 5 }}>
                                {showVerifyOtpForm ? "Verify your mobile number" : "Sign in or Register"}
                            </Heading>
                            <SubHeading className='login-subHeading-div' color="#636363">
                                {showVerifyOtpForm ?
                                    <span>Please enter the verification code we’ve sent you on {phoneNumber}
                                        <span style={{ color: "#1089FF", fontWeight: "bold", cursor: "pointer" }} onClick={() => { setShowVerifyOtpForm(false) }}> Edit</span>
                                    </span> :
                                    "Enter your mobile number"}
                            </SubHeading>
                        </Col>
                    </Row>
                    <Row className='login-mobile-parent-div' justify="center" >
                        <div className='signIn-emailForm-width'>
                            <div>
                                <SubHeading color="#636363">{showVerifyOtpForm ? "Verification code" : "Mobile number"}</SubHeading>
                                {showVerifyOtpForm ?
                                    <div style={{ position: "relative" }} className="r-c-c m-t-10">
                                        <div className="r-c-c" style={{ fontWeight: 700 }}>
                                            <div className="otpInputNew">{storeTypedOtp?.[0] /* ? "*" : null */}</div>
                                            <div className="otpInputNew">{storeTypedOtp?.[1] /* ? "*" : null */}</div>
                                            <div className="otpInputNew">{storeTypedOtp?.[2] /* ? "*" : null */}</div>
                                            <div className="otpInputNew">{storeTypedOtp?.[3] /* ? "*" : null */}</div>
                                            <div className="otpInputNew">{storeTypedOtp?.[4] /* ? "*" : null */}</div>
                                            <div className="otpInputNew">{storeTypedOtp?.[5] /* ? "*" : null */}</div>
                                        </div>
                                        <input type="number" className='aulas-login__input-verify-otp'
                                            style={{ width: "100%" }}
                                            value={storeTypedOtp}
                                            onChange={(em) => {
                                                setStoreTypedOtp(em.target.value.substring(0, 6));
                                                if (!em.target.value) {
                                                    setShowInvalidOtp(false);
                                                }
                                            }}
                                            onKeyDown={handleOtpVerifyKeyDown}
                                        />
                                    </div>
                                    :
                                    <>
                                        <PhoneInput
                                            className="p-10"
                                            style={{
                                                width: "100%",
                                                height: 35,
                                                marginTop: 5,
                                                marginBottom: 30
                                            }}
                                            defaultCountry="IN"
                                            placeholder="Enter mobile number"
                                            onChange={(e) => {
                                                if (e != undefined) {
                                                    setPhoneNumber(e);
                                                    storePhoneNumber(e)
                                                } else {
                                                    setPhoneNumber("");
                                                }
                                            }}
                                            maxLength={phoneNumber?.startsWith('+91') ? 11 : ""}
                                            onKeyDown={handleKeyDown}
                                            value={phoneNumber}
                                            addInternationalOption={true}
                                            // countrySelectComponent={CustomCountrySelect}
                                        />
                                        {/* <CaptchaComponent
                                            text={text}
                                            setText={setText}
                                            handleNumberSubmit={handleNumberSubmit} /> */}
                                    </>
                                }
                                {showInValidOtp && showVerifyOtpForm ?
                                    <div style={{ display: "flex", justifyContent: "flex-end", color: "red", marginTop: 5 }}>
                                        <img src={require("Assets/loginNew/ic-warning.svg").default} alt='' style={{ paddingRight: 3 }} />
                                        Incorrect Otp
                                    </div>
                                    :
                                    <></>}
                            </div>
                        </div>
                    </Row>
                    <Row justify="center" style={{ marginBottom: showVerifyOtpForm ? 65 : 0 }}>
                        <Col flex="auto" align="center">
                            {showVerifyOtpForm ?
                                <span className="r-c-sa" style={{ width:"40%"}}>Didn’t receive a code?
                                    <Button type="link" 
                                        onClick={() => {
                                            setStoreTypedOtp('');
                                            setShowInvalidOtp(false)
                                            setSendOtpLoaderToggle(true);
                                            if (isUserRegistered) {
                                                postGenerateOtp(
                                                    "",
                                                    () => { setSendOtpLoaderToggle(false) },
                                                    (res) => {
                                                        setShowVerifyOtpForm(res);
                                                    })
                                            } else {
                                                postGenerateRegistrationOtp("", () => { setSendOtpLoaderToggle(false) }, "")
                                            }
                                        }}
                                        loading={sendOtpLoaderToggle}
                                        disabled={verifyLoader || resendOtpTimer} >
                                        Resend OTP {timer == '' ? '' : `in ${timer}`}
                                    </Button>
                                </span> :
                                <TermsPolicy />
                            }
                            <div className="m-t-20 m-b-20">
                                {
                                    showVerifyOtpForm ?
                                        <>
                                            <Button
                                                loading={verifyLoader}
                                                type="primary"
                                                shape="round"
                                                className="r-c-c bold-bold"
                                                style={{ opacity: (storeTypedOtp?.length > 5) ? 1 : 0.5, cursor: (storeTypedOtp?.length > 5) ? "pointer" : "not-allowed", width: "50%" }}
                                                onClick={handleVerifyOtpSubmit}
                                            >
                                                {"SUBMIT"}
                                            </Button>
                                            {!isUserRegistered ?
                                                <Button
                                                    type="primary"
                                                    shape="round"
                                                    className="r-c-c bold-bold m-t-20"
                                                    style={{ width: "50%", marginTop:20, backgroundColor:"#FFFFFF", color:"#594099" }}
                                                    onClick={() => { setShowRegisterForm(true); setIsEditClicked(true); }}
                                                >
                                                    {"EDIT DETAILS"}
                                                </Button>
                                                :
                                                <></>
                                            }
                                        </>
                                        :
                                        <Button
                                            loading={verifyLoader}
                                            type="primary"
                                            shape="round"
                                            className="r-c-c bold-bold"
                                            style={{ width: "50%", opacity: !phoneNumber ? 0.5 : 1, cursor: !phoneNumber ? "not-allowed" : "pointer" }}
                                            onClick={handleNumberSubmit}
                                        >
                                            {"VERIFY"}
                                        </Button>
                                }
                            </div>
                            <div style={{ fontSize: 14, color: "#636363" }}>Sign in using <span className='text-xs blue m-l-5 m-r-5 bold-600 cursor-pointer' style={{ fontSize: 14 }} onClick={() => { setEmailIdSignIn(true); setShowVerifyOtpForm(false); setText('') }}> Email ID</span></div>
                        </Col>
                    </Row>
                </>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    const { resendOtpTimer, timer, phoneNo, isUserRegistered } = state.loginNew;
    return { resendOtpTimer, timer, phoneNo, isUserRegistered };
};

const mapDispatchToProps = (dispatch) => ({
    postGenerateOtp: (captchaToken, callBack, successCallBack) => dispatch(postGenerateOtp(captchaToken, callBack, successCallBack)),
    postVerifyOtp: (otp, history, callBack, successCallBack, invalidOtpCallBack) => dispatch(postVerifyOtp(otp, history, callBack, successCallBack, invalidOtpCallBack)),
    storePhoneNumber: (val) => dispatch(storePhoneNumber(val)),
    postEmailLogIn: (captchaToken, userEmailRegister, history, callBack) => dispatch(postEmailLogIn(captchaToken, userEmailRegister, history, callBack)),
    sendForgotLink: (userEmailRegister, history, callBack) => dispatch(sendForgotLink(userEmailRegister, history, callBack)),
    postRegisterDetails: (userRegister, callback, successCallBack, history = null) => dispatch(postRegisterDetails(userRegister, callback, successCallBack, history)),
    postGenerateRegistrationOtp: (email, callback, successCallBack) => dispatch(postGenerateRegistrationOtp(email, callback, successCallBack))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);

// export default SignInForm